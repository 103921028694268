import styled from '@emotion/styled';

export const SuperAccountModalLinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SuperAccountModalDescription = styled.p`
  color: ${({ theme }) => theme.colors.dustyGray};
`;
