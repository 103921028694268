import { SET_ACTIVE_HELP_CARD } from './consts';
import { IHomeReducer } from './types';

const initialState = {
  home: {
    activeHelpCard: null,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const home: IHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_HELP_CARD:
      return {
        ...state,
        activeHelpCard: action.activeHelpCard,
      };
    default:
      return state;
  }
};

export default home;
