import type { ChartContext } from './type';

import { formatNumber } from 'utils/formatters/formatNumber';

export const moveCustomMarker = (chartContext: ChartContext) => {
  const sourceMarker = chartContext.el.querySelector(
    '.apexcharts-series[seriesName="ProjectedxValue"] g circle, ' +
      '.apexcharts-series[seriesName="NilaixAnggaran"] g circle',
  );

  const customMarker = chartContext.el.querySelector('.custom-marker');

  if (sourceMarker && customMarker) {
    customMarker.setAttribute('cx', sourceMarker?.getAttribute?.('cx') ?? '0');

    customMarker.setAttribute('cy', sourceMarker?.getAttribute?.('cy') ?? '0');

    customMarker.setAttribute('r', '6');
  }
};

export const minimizeCustomMarker = (chartContext: ChartContext) => {
  const customMarker = chartContext.el.querySelector('.custom-marker');

  if (customMarker) {
    customMarker.setAttribute('r', '0');
  }
};

export const renderCustomMarker = (chartContext: ChartContext) => {
  const customMarker = chartContext.el.querySelector('.custom-marker');

  if (customMarker) return;

  const circle = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle',
  );

  const sourceMarker: SVGElement | null = chartContext.el.querySelector(
    '.apexcharts-series[seriesName="ProjectedxValue"] g circle, ' +
      '.apexcharts-series[seriesName="NilaixAnggaran"] g circle',
  );

  const cx = sourceMarker?.getAttribute?.('cx') ?? '0';
  const cy = sourceMarker?.getAttribute?.('cy') ?? '0';

  circle.setAttribute('class', 'custom-marker');

  circle.setAttribute('r', '0');

  circle.setAttribute('fill', '#fff');

  circle.setAttribute('stroke', '#fff');

  circle.setAttribute('fill-opacity', '1');

  circle.setAttribute('stroke-opacity', '0.9');

  circle.setAttribute('stroke-width', '2');

  if (cx && cy) {
    circle.setAttribute('cx', cx);

    circle.setAttribute('cy', cy);
  }

  const el = chartContext?.el;

  const svg: SVGElement | null = el.querySelector(
    '.apexcharts-svg .apexcharts-area-series',
  );

  svg?.appendChild(circle);
};

export const calibrationMarker = () => {
  const selector =
    '.apexcharts-series[seriesName="ProjectedxValue"] g circle, ' +
    '.apexcharts-series[seriesName="NilaixAnggaran"] g circle';

  const seriesMain = document.querySelector(selector);

  const seriesBottom = document.querySelector(
    '.apexcharts-series[seriesName="LB99"] g circle',
  );

  const cy = seriesMain?.getAttribute?.('cy') ?? '0';

  if (cy) {
    seriesBottom?.setAttribute?.('cy', cy);
  }
};

export const getDivider = () => 1;

export const formatValue = (value: number) =>
  formatNumber({
    value: Math.ceil(value * getDivider()),
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
