import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import withRouter from '../../routes/withRouter';

import NotFoundView from './NotFoundView';
import '../../styles/pages/not-found.scss';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);

    this.goHome = this.goHome.bind(this);
  }

  componentDidUpdate() {
    const { history } = this.props;

    if (history.location.pathname !== '/not-found') {
      history.push('/not-found');
    }
  }

  goHome() {
    const { history } = this.props;

    history.push('/');
  }

  goBack() {
    const { history } = this.props;

    history.push(-2);
  }

  render() {
    const { t } = this.props;

    return (
      <NotFoundView
        title={t('notFoundPage.title')}
        goHome={this.goHome}
        goBack={this.goBack}
      />
    );
  }
}

NotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    goBack: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(NotFound));
