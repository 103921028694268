import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'styles/layout/logout.scss';

import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

import Loader from '../../elements/Loader/Loader';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const PDF_WORKER_SRC = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

pdfjs.GlobalWorkerOptions.workerSrc = PDF_WORKER_SRC;

export default function StatementsPreviewModal({
  isStatementsPreviewModalOpen,
  statementsUrl,
  openPdfModal,
  isPdfQueryLoading,
}) {
  const [pagesCount, setPagesCount] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPagesCount(numPages);
  };

  return (
    <Modal
      isOpen={isStatementsPreviewModalOpen}
      className="statements__modal"
      onRequestClose={() => {
        openPdfModal(false);
      }}
      contentLabel="statements__modal"
      shouldCloseOnOverlayClick
    >
      <DeprecatedButton
        buttonClass="button__close -grey statements__modal-close"
        handleClick={() => openPdfModal({ condition: false })}
      />

      {isPdfQueryLoading ? (
        <Loader additionalClass="-absolute -top" />
      ) : (
        statementsUrl && (
          <div className="statements__modal-page-wrapper">
            <Document
              file={statementsUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onSourceError={(e) => console.error(e)}
              onLoadError={(e) => console.error(e)}
              loading={<Loader additionalClass="-absolute -top" />}
            >
              {Array.from(new Array(pagesCount), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        )
      )}
    </Modal>
  );
}

StatementsPreviewModal.defaultProps = {
  statementsUrl: '',
  isPdfQueryLoading: false,
};

StatementsPreviewModal.propTypes = {
  isStatementsPreviewModalOpen: PropTypes.bool.isRequired,
  statementsUrl: PropTypes.string,
  isPdfQueryLoading: PropTypes.bool,
  openPdfModal: PropTypes.func.isRequired,
};
