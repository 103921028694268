import WelcomeCard from './WelcomeCard';
import CustomizeYourPortfolioCard from './CustomizeYourPortfolioCard';
import ConstructingYourPortfolioCard from './ConstructingYourPortfolioCard';
import MakeChangesAnytimeCard from './MakeChangesAnytimeCard';
import MaximumWeightingsCard from './MaximumWeightingsCard';
import HeadsUpCard from './HeadsUpCard';
import { PORTFOLIO_HELP_CARD_MAXIMUM_WEIGHTINGS_ID } from './consts';

interface IGetPortfolioPlusHelpCardsProps {
  isSuper: boolean;
}

export const getPortfolioPlusHelpCards = ({
  isSuper,
}: IGetPortfolioPlusHelpCardsProps) => {
  const handleNextButton = () => {
    const nextButton = document.getElementsByClassName(
      'react-multiple-carousel__arrow--right',
    )[0] as HTMLButtonElement;

    if (nextButton) {
      nextButton.click();
    }
  };

  const handleCloseButton = () => {
    const closeButton = document.getElementsByClassName(
      'help-cards__modal-close',
    )[0] as HTMLButtonElement;

    if (closeButton) {
      closeButton.click();
    }
  };

  const className = '-how-works';
  const nextButtonTitle = 'common.next';
  const closeButtonTitle = 'common.close';

  const descriptionKey = isSuper ? 'superDescription' : 'description';

  const portfolioHelpCards = [
    {
      id: 'welcomeToPlus',
      className,
      info: `portfolio.helpCards.welcomeCard.${descriptionKey}`,
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <WelcomeCard />,
    },
    {
      id: 'customizeYourPortfolio',
      className,
      info: `portfolio.helpCards.customizeYourPortfolioCard.${descriptionKey}`,
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <CustomizeYourPortfolioCard isSuper={isSuper} />,
    },
    {
      id: 'constructingYourPortfolio',
      className,
      info: `portfolio.helpCards.constructingYourPortfolioCard.${descriptionKey}`,
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <ConstructingYourPortfolioCard />,
    },
    {
      id: 'makeChangesAnytime',
      className,
      info: `portfolio.helpCards.makeChangesAnytimeCard.${descriptionKey}`,
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <MakeChangesAnytimeCard />,
    },
    {
      id: PORTFOLIO_HELP_CARD_MAXIMUM_WEIGHTINGS_ID,
      className,
      info: `portfolio.helpCards.maximumWeightings.${descriptionKey}`,
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <MaximumWeightingsCard />,
    },
    {
      id: 'headsUp',
      className,
      info: `portfolio.helpCards.headsUpCard.${descriptionKey}`,
      clickEvent: handleCloseButton,
      greenBtn: {
        isBtn: true,
        title: closeButtonTitle,
      },
      customHeader: <HeadsUpCard />,
    },
  ];

  return portfolioHelpCards.filter(
    (card) =>
      isSuper ||
      (!isSuper && card.id !== PORTFOLIO_HELP_CARD_MAXIMUM_WEIGHTINGS_ID),
  );
};
