import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { StatisticsItemWrapper, StatisticsItemLabel } from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

export default function StatisticItem({
  label,
  value,
  isDate,
  isWithoutFormatting,
  placeholder,
  fractionDigits,
  type,
}) {
  if (isDate) {
    return (
      <StatisticsItemWrapper>
        <StatisticsItemLabel>{label}</StatisticsItemLabel>

        {(dayjs(value).isValid() && dayjs(value).format('DD MMM. YYYY')) ||
          placeholder}
      </StatisticsItemWrapper>
    );
  }

  return (
    <StatisticsItemWrapper>
      <StatisticsItemLabel>{label}</StatisticsItemLabel>

      {isWithoutFormatting ? (
        value
      ) : (
        <>
          {formatNumber({
            value: value || placeholder || 0,
            type,
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
          })}
        </>
      )}
    </StatisticsItemWrapper>
  );
}

StatisticItem.defaultProps = {
  value: 0,
  isDate: false,
  isWithoutFormatting: false,
  placeholder: '',
  fractionDigits: 2,
  type: NUMBER_FORMATS.currency,
};

StatisticItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  isDate: PropTypes.bool,
  isWithoutFormatting: PropTypes.bool,
  placeholder: PropTypes.string,
  fractionDigits: PropTypes.number,
  type: PropTypes.string,
};
