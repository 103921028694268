import { useLocation } from 'react-router-dom';

import {
  AfterLine,
  BeforeLine,
  Dot,
  Indicators,
  Item,
  Label,
  Wrapper,
} from './styles';
import { IStepperProps } from './types';

export const Stepper = ({ steps }: IStepperProps) => {
  const { pathname } = useLocation();

  const currentStepIndex = steps.findIndex(({ path }) => path === pathname);

  return (
    <Wrapper>
      {steps.map((step, index) => (
        <Item key={step.path}>
          <Label
            isActiveOrPreviousStep={
              index === currentStepIndex || index < currentStepIndex
            }
          >
            {step.label}
          </Label>

          <Indicators>
            <Dot
              isActiveStep={index === currentStepIndex}
              isPreviousStep={index < currentStepIndex}
            >
              {index !== 0 && (
                <BeforeLine
                  isActiveOrPreviousStep={
                    index === currentStepIndex || index < currentStepIndex
                  }
                />
              )}

              {index !== steps.length - 1 && (
                <AfterLine isPreviousStep={index < currentStepIndex} />
              )}
            </Dot>
          </Indicators>
        </Item>
      ))}
    </Wrapper>
  );
};
