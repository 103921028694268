import { FormSpy, FormSpyRenderProps } from 'react-final-form';

interface IFieldErrorMessageProps {
  className: string;
  name: string;
}

export const FieldErrorMessage = ({
  className,
  name,
}: IFieldErrorMessageProps) => (
  <FormSpy
    render={({ errors, submitFailed }: FormSpyRenderProps) =>
      submitFailed && errors?.[name] ? (
        <div className={className}>{errors?.[name]}</div>
      ) : null
    }
    subscription={{ submitFailed: true, errors: true }}
  />
);
