import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function EmailSentModal({ toggleEmailSentModal }) {
  const { t } = useTranslation();

  const user = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
  );

  const userName = user ? user.dependency_user.name : '';
  const userEmail = user ? user.dependency_user.email : '';

  return (
    <Modal
      isOpen
      onRequestClose={() => toggleEmailSentModal(false)}
      contentLabel="account-strength-modal"
      shouldCloseOnOverlayClick
      className="account-strength__modal -centered raiz-kids__email"
    >
      <DeprecatedButton
        buttonClass="button__close"
        handleClick={() => toggleEmailSentModal(false)}
      />

      <div className="account-strength__modal-header">
        <i className="account-strength__modal-logo" />

        <i className="raiz-kids__email-logo" />
      </div>

      <i className="account-strength__modal-banner -kids-email-sent" />

      <div className="account-strength__modal-title raiz-kids__email-title">
        <Trans
          i18nKey="emailSentModal.title"
          t={t}
          components={{
            name: userName,
          }}
        />
      </div>

      {userEmail ? (
        <div className="account-strength__modal-info raiz-kids__email-info">
          <Trans
            i18nKey="emailSentModal.weHaveSent"
            t={t}
            components={{
              name: userName,
              email: userEmail,
            }}
          />
        </div>
      ) : null}

      <DeprecatedButton
        title={t('common.ok')}
        buttonClass="button__basic-fill -white-inverse -link"
        handleClick={() => toggleEmailSentModal(false)}
      />
    </Modal>
  );
}

EmailSentModal.propTypes = {
  toggleEmailSentModal: PropTypes.func.isRequired,
};
