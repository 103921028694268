import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getAgeFromDateOfBirth } from '../../utils';

import { KidAgeCard } from './styles';

function KidAge({ kid }) {
  const { t } = useTranslation();
  const age = getAgeFromDateOfBirth(kid.date_of_birth);

  return (
    <KidAgeCard>
      {`${kid.name} (${t('raizKidsPage.age', { count: age })})`}
    </KidAgeCard>
  );
}

export default KidAge;

KidAge.defaultProps = {
  kid: {},
};

KidAge.propTypes = {
  kid: PropTypes.shape({
    date_of_birth: PropTypes.string,
    name: PropTypes.string,
  }),
};
