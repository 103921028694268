import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  ProPortfolioContentDetails,
  ProPortfolioContentLeftColumn,
  ProPortfolioContentWrapper,
  ProPortfolioRightColumn,
  ProPortfolioInfoIcon,
} from '../styles';

import ProPortfolioChart from './ProPortfolioChart';
import ProPortfolioLabel from './ProPortfolioLabel';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { usePortfolioNavigationParams } from 'pages/Portfolio/hooks/usePortfolioNavigationParams';
import { FUNDS_TAGS } from 'pages/Portfolio/subpages/PortfolioCustomization/consts';

export default function ProPortfolioContent({
  setIsChartDetailsModalOpen,
  activeFundTags,
  isProPortfolioConfigured,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef();

  const { isRegistration } = useAppSelector(selectPortfolioLocation);

  const { search } = useAppSelector(selectPortfolioParams);

  const { customizationTagsPath, pathParams } = usePortfolioNavigationParams();

  const handleLabelClick = (fundTag) => {
    if (fundTag) {
      if (isRegistration) {
        navigate({
          pathname: generatePath(customizationTagsPath, {
            fundTag,
            ...pathParams,
          }),
          search,
        });
      } else {
        navigate({
          pathname: generatePath(customizationTagsPath, {
            fundTag,
          }),
          search,
        });
      }
    } else if (isRegistration) {
      navigate({
        pathname: generatePath(customizationTagsPath, {
          fundTag: FUNDS_TAGS.basePortfolio,
          ...pathParams,
        }),
        search,
      });
    } else {
      navigate({
        pathname: generatePath(customizationTagsPath, {
          fundTag: FUNDS_TAGS.basePortfolio,
        }),
        search,
      });
    }
  };

  const getChartLabelName = (name) => name.replace(/\s/g, 'x');

  const highlighChartSectionOnMouseOver = useCallback(
    (name) => () => {
      if (ref?.current) {
        const seriesName = getChartLabelName(name);

        const chart = ref?.current?.chart;

        const legendInactiveClass = chart.series?.legendInactiveClass;

        const dataLabels = chart.el.querySelectorAll?.(
          '[class*="apexcharts-pie-series"]',
        );

        dataLabels?.forEach((el) => {
          el.classList.add(legendInactiveClass);
        });

        chart.el.querySelectorAll?.('g[seriesName]')?.forEach((el, elIndex) => {
          const seriesNameAtrribute = el.getAttribute('seriesName');

          if (seriesName === seriesNameAtrribute) {
            el.classList.remove(legendInactiveClass);

            dataLabels[elIndex].classList.remove(legendInactiveClass);
          } else {
            el.classList.add(legendInactiveClass);
          }
        });
      }
    },
    [],
  );

  const clearChartSectionHighlightOnMouseOut = useCallback(() => {
    if (ref?.current) {
      const chart = ref?.current?.chart;

      const legendInactiveClass = chart.series?.legendInactiveClass;

      const dataLabels = chart.el.querySelectorAll?.(
        '[class*="apexcharts-pie-series"]',
      );

      dataLabels?.forEach((el) => {
        el.classList.remove(legendInactiveClass);
      });

      chart.el.querySelectorAll?.('g[seriesName]')?.forEach((el) => {
        el.classList.remove(legendInactiveClass);
      });
    }
  }, []);

  return (
    <ProPortfolioContentWrapper>
      <ProPortfolioContentLeftColumn>
        <ProPortfolioChart activeFundTags={activeFundTags} chartRef={ref} />

        {isProPortfolioConfigured && (
          <ProPortfolioContentDetails
            onClick={() => setIsChartDetailsModalOpen(true)}
          >
            {t('common.details')}

            <ProPortfolioInfoIcon />
          </ProPortfolioContentDetails>
        )}
      </ProPortfolioContentLeftColumn>

      <ProPortfolioRightColumn>
        {activeFundTags.map((fund, index) => (
          <ProPortfolioLabel
            key={fund.key || fund.id || index}
            name={fund.name}
            value={fund.percentage || fund.allocation}
            isModal={false}
            onClick={() => handleLabelClick(fund?.key)}
            isBaseFund={index === 0}
            onMouseOver={highlighChartSectionOnMouseOver(fund.name)}
            onMouseOut={clearChartSectionHighlightOnMouseOut}
            tags={fund.tags}
          />
        ))}
      </ProPortfolioRightColumn>
    </ProPortfolioContentWrapper>
  );
}

ProPortfolioContent.propTypes = {
  setIsChartDetailsModalOpen: PropTypes.func.isRequired,
  activeFundTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isProPortfolioConfigured: PropTypes.bool.isRequired,
};
