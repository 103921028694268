import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';

import BankErrorModal from './BankErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

function isNeedDecimalScale(n) {
  return n % 1 !== 0;
}

class RDVModal extends Component {
  render() {
    const {
      t,
      isOpenRDWModal,
      closeRDWModal,
      handleSubmitRdv,
      amount,
      changeRdvAmount,
      titleRdv,
      descriptionRdv,
      isShowRdvError,
      errorMessage,
      closeRDWModalError,
      isShowRdvSuccess,
      message,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isOpenRDWModal}
          onRequestClose={() => closeRDWModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal time-investment__modal-quick rdv-modal"
        >
          {(isShowRdvError || isShowRdvSuccess) && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowRdvError || isShowRdvSuccess}
              shouldCloseOnOverlayClick
              errorMessage={isShowRdvError ? errorMessage : message}
              handleClick={closeRDWModalError}
            />
          )}

          <div className="time-investment__modal-content">
            <DeprecatedButton
              title={t('common.space')}
              buttonClass="button__close "
              handleClick={() => closeRDWModal()}
            />
          </div>

          <div className="time-investment__modal-title">
            {titleRdv}

            <div className="verification" />
          </div>

          <div className="time-investment__modal-content">
            <div className="rdv-description">{descriptionRdv}</div>

            <div
              className={`modal-basic__input ${
                amount > 0 ? '-active' : '-inactive'
              } time-investment__input`}
            >
              <NumberFormat
                value={amount}
                displayType="input"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                placeholder={`${CURRENCY_SYMBOL}0`}
                decimalScale={2}
                allowNegative={false}
                fixedDecimalScale={isNeedDecimalScale('20')}
                prefix={CURRENCY_SYMBOL}
                onValueChange={changeRdvAmount}
              />
            </div>
          </div>

          <div className="time-investment__modal-footer -single-btn">
            <Button
              label={t('common.confirm')}
              onClick={() => handleSubmitRdv()}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

RDVModal.defaultProps = {};

RDVModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpenRDWModal: PropTypes.bool.isRequired,
  closeRDWModal: PropTypes.func.isRequired,
  handleSubmitRdv: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  changeRdvAmount: PropTypes.func.isRequired,
  titleRdv: PropTypes.string.isRequired,
  descriptionRdv: PropTypes.string.isRequired,
  isShowRdvError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  closeRDWModalError: PropTypes.func.isRequired,
  isShowRdvSuccess: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default withTranslation()(RDVModal);
