import React from 'react';
import Modal from 'react-modal';
import '../../../styles/layout/withdraw.scss';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { REQUIRED } from '../../../constants/errors';
import FinalFormInput from '../../elements/FinalFormInput/FinalFormInput';
import { MY_APP } from '../../../constants/localeConfigs';

import BankErrorModal from './BankErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values, bank = {}) => {
    const errors = {};
    let accountNumber = '';

    if (values.accountNumber) {
      accountNumber = values.accountNumber.replace(/\D/g, '');
    }

    if (!values.accountNumber) {
      errors.accountNumber = REQUIRED;
    }

    if (bank.account_number_lengths) {
      const min = Math.min.apply(null, bank.account_number_lengths).toString();

      if (accountNumber.length < min) {
        errors.accountNumber = t('fields.validation.wrongValue', {
          value: t('fields.accountNumber.label'),
        });
      } else {
        const checkLength = bank.account_number_lengths.indexOf(
          accountNumber.length,
        );

        if (checkLength === -1) {
          errors.accountNumber = t('fields.validation.incorrectLength', {
            fields: t('fields.accountNumber.label'),
          });
        }
      }
    }

    return errors;
  };

  return { validate };
};

export default function WithdrawalSignInModal({
  handleCloseModal,
  bank,
  handleSubmit,
  handleChangeBank,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  showLoader,
  isShowBankModal,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  let modalIcon = '';
  let modalName = '';
  let accountNumberLengths = 0;

  if (bank) {
    if (MY_APP) {
      modalIcon = bank.icon_url;

      modalName = bank.bank_name;
    } else {
      modalIcon = bank.icon;

      modalName = bank.name;
    }
  }

  if (bank.account_number_lengths) {
    accountNumberLengths = Math.max
      .apply(null, bank.account_number_lengths)
      .toString();
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values, bank)}
      render={({ form, values, hasValidationErrors }) => (
        <div>
          {errorMessage && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowBankModalError}
              shouldCloseOnOverlayClick
              modalClassName="bank-sign"
              errorTitle={errorTitle}
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
            />
          )}

          <Modal
            isOpen={isShowBankModal}
            onRequestClose={handleCloseModal}
            contentLabel="withdraw-modal"
            shouldCloseOnOverlayClick
            className="modal-basic bank__modal bank-sign"
          >
            <div>
              <div className="bank__modal-content">
                <DeprecatedButton
                  title={t('common.space')}
                  buttonClass="button__close -black"
                  handleClick={handleCloseModal}
                />

                <div className="bank__modal-title">
                  {t('withdrawalSignInModal.title')}
                </div>

                <div className="bank__modal-sub">
                  <i className="bank__modal-secured-icon" />

                  {t('common.secured')}
                </div>

                <div className="bank__modal-description">
                  {t('withdrawalSignInModal.description')}
                </div>

                <div className="bank__modal-name">
                  <img
                    alt={modalName}
                    className="bank__modal-icon"
                    src={modalIcon}
                  />

                  <span>{modalName}</span>
                </div>

                <div className="bank__modal-row">
                  <form
                    className="withdrawal-form"
                    onSubmit={(e) =>
                      handleSubmit(e, values, form, hasValidationErrors)
                    }
                    onChange={handleChangeBank}
                  >
                    <div className="bank__modal__container-item">
                      <FinalFormInput
                        name="accountNumber"
                        maxLength={
                          accountNumberLengths !== 0 ? accountNumberLengths : ''
                        }
                        isNumberOnly
                        label={t(
                          'settingsPage.withdrawalSignInModal.fields.bankAccount.label',
                        )}
                      />
                    </div>

                    <Button
                      isLoading={showLoader}
                      label={t('common.add')}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    />
  );
}

WithdrawalSignInModal.defaultProps = {
  showLoader: false,
};

WithdrawalSignInModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    icon_url: PropTypes.string,
    icon: PropTypes.string,
    bank_name: PropTypes.string,
    account_number_lengths: PropTypes.number,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeBank: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  isShowBankModal: PropTypes.bool.isRequired,
};
