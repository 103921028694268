import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Firebase from 'firebase/app';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getTransactions,
  getUserData,
  getAccountSummaryRound,
  sendInvests,
  investTransactions,
} from '../../store/user/actions';
import {
  COMPLEMENT_CREDIT,
  MAX_INVEST,
  ROUNDUP_LIMIT,
} from '../../constants/comonConstants';

import RoundUpsView from './RoundUpsView';
import { calculateInvestmentValue } from './utils/utils';

import { useRoundUpsSettingsState } from 'components/layout/Modals/RoundUpsModal/hooks/useRoundUpsSettingsState';
import { useUpdateRoundUpsSettingsMutation } from 'store/roundUpsSettings/roundUpsSettings';

function RoundUps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [offset, setOffset] = useState(1);
  const [status, setStatus] = useState(null);
  const [investSum, setInvestSum] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isRoundUpsModalOpen, setIsRoundUpsModalOpen] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRoundUpHelpCardOpen, setIsRoundUpHelpCardOpen] = useState(false);
  const [isHelpCardInfoOpen, setIsHelpCardInfoOpen] = useState(false);

  const { roundUpsSettings: settings } = useRoundUpsSettingsState();

  const [updateRoundUpsSettings] = useUpdateRoundUpsSettingsMutation();

  const { user, roundUpsSummary, transactions } = useSelector((state) => ({
    user: state.user.user,
    roundUpsSummary: state.user.roundUpsSummary,
    transactions: state.user.transactions,
  }));

  useEffect(() => {
    Firebase.analytics().logEvent('Round_Ups', { eventKey: 'Show Accounts' });

    dispatch(
      getTransactions({ offset: 1, limit: ROUNDUP_LIMIT, status: '' }),
    ).then(() => {
      setIsLoading(false);
    });

    dispatch(getUserData());

    dispatch(getAccountSummaryRound());
  }, [dispatch]);

  const handleOpenRoundUpsModal = (isOpen) => {
    setIsRoundUpsModalOpen(isOpen);
  };

  const toggleRoundUps = (isRoundUpsEnabled) => {
    if (isRoundUpsEnabled) {
      Firebase.analytics().logEvent('Automatic_Round_Ups', {
        eventKey: 'Updated',
        value: 'On',
      });
    } else {
      Firebase.analytics().logEvent('Automatic_Round_Ups', {
        eventKey: 'Updated',
        value: 'Off',
      });
    }

    const roundUpsSettings = {
      ...settings,
      automatic_roundup: isRoundUpsEnabled,
    };

    updateRoundUpsSettings(roundUpsSettings);
  };

  const handleSelectCheckbox = (e, info) => {
    const isChecked = e?.target?.checked;

    e.target.parentElement.classList.toggle('checkbox__check');

    setInvestSum(
      calculateInvestmentValue({ isChecked, investSum, change: info?.change }),
    );

    setSelectedTransactions(
      isChecked
        ? [...selectedTransactions, info.id]
        : selectedTransactions.filter((x) => x !== info.id),
    );
  };

  const handleInvest = (invest = false) => {
    const maxInvest = MAX_INVEST;

    const data = {
      investment: {
        amount: maxInvest - user.round_ups.waiting,
        type: COMPLEMENT_CREDIT,
      },
    };

    Firebase.analytics().logEvent('Round_Ups', {
      eventKey: 'Invest Now. Amount',
      value: data.investment.amount,
    });

    if (user.round_ups.waiting > 0 || invest) {
      if (data.investment.amount < maxInvest && !isConfirmModalOpen) {
        setIsConfirmModalOpen(true);
      } else {
        dispatch(sendInvests(data)).then(() => {
          dispatch(getUserData());

          setIsConfirmModalOpen(false);
        });
      }
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  const changeStatus = (newStatus) => {
    setStatus(newStatus);

    if (newStatus === 'approved') {
      Firebase.analytics().logEvent('Round_Ups', {
        eventKey: 'Invest Transactions',
      });
    }

    setIsLoading(true);

    dispatch(getUserData());

    setOffset(1);

    dispatch(
      getTransactions({ offset: 1, limit: ROUNDUP_LIMIT, status: newStatus }),
    ).then(() => {
      setIsLoading(false);
    });
  };

  const toggleInfoModal = (isOpen) => {
    if (!isOpen) {
      if (window.location.pathname !== location.pathname) {
        navigate(-1);
      } else {
        navigate('/invest');
      }
    }
  };

  const uncheckAll = () => {
    const inputs = [...document.getElementsByTagName('input')];

    inputs.forEach((input) => {
      const currentElem = input;

      if (currentElem.type === 'checkbox') {
        currentElem.checked = false;

        currentElem.parentElement.classList.remove('checkbox__check');
      }
    });

    setInvestSum(0);

    setSelectedTransactions([]);
  };

  const submitTransactions = () => {
    dispatch(
      investTransactions(
        {
          transactions: selectedTransactions,
        },
        () => {
          dispatch(getUserData());

          dispatch(
            getTransactions({ offset: 1, limit: ROUNDUP_LIMIT, status }),
          );

          setOffset(1);

          uncheckAll();
        },
      ),
    );
  };

  const toggleHelpCard = () => {
    if (isRoundUpHelpCardOpen) {
      setIsRoundUpHelpCardOpen(false);
    } else {
      setIsRoundUpHelpCardOpen(true);
    }
  };

  const toggleHelpCardInfo = () => {
    if (isHelpCardInfoOpen) {
      setIsHelpCardInfoOpen(false);
    } else {
      setIsHelpCardInfoOpen(true);
    }
  };

  const setInView = (inView) => {
    if (inView && transactions.total_pages > offset) {
      dispatch(
        getTransactions({ offset: offset + 1, limit: ROUNDUP_LIMIT, status }),
      );

      setOffset(offset + 1);
    }
  };

  return (
    <RoundUpsView
      user={user}
      items={transactions.items}
      status={status}
      settings={settings}
      investSum={investSum}
      isRoundUpsModalOpen={isRoundUpsModalOpen}
      roundUpsSummary={roundUpsSummary}
      uncheckAll={uncheckAll}
      isLoading={isLoading}
      submitTransactions={submitTransactions}
      toggleInfoModal={toggleInfoModal}
      toggleRoundUps={toggleRoundUps}
      handleSelectCheckbox={handleSelectCheckbox}
      handleInvest={handleInvest}
      changeStatus={changeStatus}
      isAutomaticRoundup={settings.automatic_roundup}
      handleOpenRoundUpsModal={handleOpenRoundUpsModal}
      automaticThreshold={settings.automatic_lower_threshold}
      isConfirmModalOpen={isConfirmModalOpen}
      setIsConfirmModalOpen={setIsConfirmModalOpen}
      selectedTransactions={selectedTransactions}
      isRoundUpHelpCardOpen={isRoundUpHelpCardOpen}
      toggleHelpCard={toggleHelpCard}
      toggleHelpCardInfo={toggleHelpCardInfo}
      isHelpCardInfoOpen={isHelpCardInfoOpen}
      setInView={setInView}
    />
  );
}

export default RoundUps;
