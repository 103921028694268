import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import { CHECKBOX_KEY, TERM_KEY } from '../consts';

export default function TermsCheckbox({ index, values, change }) {
  const { t } = useTranslation();

  const name = TERM_KEY + (index + 1);
  const checkboxesArray = Object.values(values.checkboxes);

  if (checkboxesArray.every((checkbox) => checkbox)) {
    change('selectAll', true);
  } else if (checkboxesArray.includes(false)) {
    change('selectAll', false);
  }

  return (
    <CheckboxFF
      name={CHECKBOX_KEY + name}
      type="checkbox"
      defaultChecked={values.checkboxes[name]}
      label={<span>{t(`raizKidsRegistrationPage.terms.${name}`)}</span>}
    />
  );
}

TermsCheckbox.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.shape({
    checkboxes: PropTypes.shape({}),
  }).isRequired,
  change: PropTypes.func.isRequired,
};
