import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { toggleInfoModal } from 'store/modals/actions';

interface IUsePreventPageLeaveProps {
  isBlocked: boolean;
  onPageLeaveConfirmed?: () => void;
}

export const usePreventPageLeave = ({
  isBlocked,
  onPageLeaveConfirmed,
}: IUsePreventPageLeaveProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const blocker = useBlocker(isBlocked);
  const wasBlocked = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isBlocked) {
      blocker.reset();

      wasBlocked.current = 'unblocked';
    }
  }, [blocker, isBlocked]);

  const closeConfirmationModal = useCallback(() => {
    dispatch(toggleInfoModal({ isInfoModalVisible: false }));
  }, [dispatch]);

  const proceedPageLeave = useCallback(() => {
    blocker?.proceed?.();

    onPageLeaveConfirmed?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // need to disable exhaustive deps because it can have unstable reference
    // onPageLeaveConfirmed
    blocker,
  ]);

  useEffect(() => {
    if (wasBlocked.current === 'unblocked' && blocker.state === 'blocked') {
      dispatch(
        toggleInfoModal({
          isInfoModalVisible: true,
          config: {
            title: t('portfolioCustomization.unsavedChangesModal.title'),
            description: t(
              'portfolioCustomization.unsavedChangesModal.description',
            ),
            acceptButtonText: t(
              'portfolioCustomization.unsavedChangesModal.buttons.okay',
            ),
            rejectButtonText: t(
              'portfolioCustomization.unsavedChangesModal.buttons.leave',
            ),
            onAccept: closeConfirmationModal,
            onReject: proceedPageLeave,
          },
        }),
      );
    }
  }, [
    t,
    dispatch,
    blocker,
    wasBlocked,
    proceedPageLeave,
    closeConfirmationModal,
  ]);

  return blocker;
};
