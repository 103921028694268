import { INVEST_ACTIONS } from './consts';
import { IInvestReducer } from './types';

const initialState = {
  goals: {},
  recurringDepositSettings: {},
  errorMessage: '',
  statementsUrl: '',
  errorTitle: '',
  billingAccounts: {},
  fundsList: [],
  allocationProfile: '',
  statementsUrlContent: '',
  type: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const user: IInvestReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVEST_ACTIONS.SHOW_ERROR_SET_SUPER_FUND:
    case INVEST_ACTIONS.SHOW_ERROR_ONE_TIME_INVEST:
    case INVEST_ACTIONS.SHOW_ERROR_GET_STATEMENTS_URL:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
      };
    case INVEST_ACTIONS.ONE_TIME_INVEST_SUCCESS:
    case INVEST_ACTIONS.SHOW_ERROR_BILLING_ACCOUNTS:
      return {
        ...state,
        errorMessage: '',
        errorTitle: '',
      };
    case INVEST_ACTIONS.GET_BILLING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        billingAccounts: action.billingAccounts,
      };
    case INVEST_ACTIONS.GET_STATEMENTS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        type: INVEST_ACTIONS.GET_STATEMENTS_SUCCESS,
        statements: action.statements,
      };
    case INVEST_ACTIONS.GET_SUPER_FUNDS_LIST_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        fundsList: action.fundsList,
      };
    case INVEST_ACTIONS.GET_ALLOCATION_PROFILE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        allocationProfile: action.allocationProfile,
      };
    case INVEST_ACTIONS.DELETE_BILLING_ACCOUNT_SUCCESS:
    case INVEST_ACTIONS.SET_SUPER_FUND_SUCCESS:
      return {
        ...state,
        errorMessage: '',
      };
    case INVEST_ACTIONS.GET_STATEMENTS_URL_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        statementsUrl: action.statementsUrl,
      };
    case INVEST_ACTIONS.GET_STATEMENTS_URL_CONTENT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        statementsUrlContent: action.statementsUrlContent,
      };
    case INVEST_ACTIONS.WITHDRAW_ALL_MONEY:
      return {
        ...state,
        errorMessage: '',
        withdrawAllMoney: true,
      };
    case INVEST_ACTIONS.WITHDRAW_ALL_MONEY_CLOSE:
      return {
        ...state,
        withdrawAllMoney: false,
      };
    default:
      return state;
  }
};

export default user;
