import { CLOSE_MODAL } from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { api, getHeaders } from '../../services/api';

import {
  LUMP_SUM_INVEST_SUCCESS,
  GET_SUPER_FUNDS_SUCCESS,
  SHOW_ERROR_IN_SUPER_MODAL,
  GET_SUPER_SETTINGS_SUCCESS,
  SHOW_ERROR_LUMP_SUM_INVEST,
  SAVE_SUPER_REGISTRATION_SUCCESS,
  GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS,
  CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS,
  SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER,
  ROLLOVER_FUND_SUCCESS,
  BACK_ON_SUPER_REGISTRATION,
  GO_TO_CONSENT,
  SHOW_ERROR_UPDATE_TRANSFER_REWARDS,
  UPDATE_TRANSFER_REWARDS_SUCCESS,
  SHOW_ERROR_GET_SUPER_SETTINGS,
  SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS_SUPER,
  CONSENT_SUCCESS,
} from './consts';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function lumSumInvestSuccess() {
  return { type: LUMP_SUM_INVEST_SUCCESS };
}

export function createRecurringDepositSettingsSuperSuccess() {
  return { type: CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS };
}

export function updateTransferRewardsSuccess() {
  return { type: UPDATE_TRANSFER_REWARDS_SUCCESS };
}

export function showErrorLumpSumInvest(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_LUMP_SUM_INVEST, errorMessage, errorTitle };
}

export function showErrorInSuperModal(errorMessage, step) {
  return { type: SHOW_ERROR_IN_SUPER_MODAL, errorMessage, step };
}

export function saveSuperRegistrationSuccess(superAnnuationUser, step) {
  return { type: SAVE_SUPER_REGISTRATION_SUCCESS, superAnnuationUser, step };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function showErrorGetSuperSettings(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_SUPER_SETTINGS, errorMessage, errorTitle };
}

export function showErrorGetRecurringDepositSettingsSuper(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS_SUPER,
    errorMessage,
    errorTitle,
  };
}

export function showErrorUpdateTransferRewards(errorMessage) {
  return { type: SHOW_ERROR_UPDATE_TRANSFER_REWARDS, errorMessage };
}

export function showErrorCreateRecurringDepositSettingsSuper(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER,
    errorMessage,
    errorTitle,
  };
}

export function getRecurringDepositSettingsSuperSuccess(
  recurringDepositSettings,
) {
  return {
    type: GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS,
    recurringDepositSettings,
  };
}

export function getSuperSettingsSuccess(superSettings) {
  return { type: GET_SUPER_SETTINGS_SUCCESS, superSettings };
}

export function getSuperFundsSuccess(superFunds) {
  return { type: GET_SUPER_FUNDS_SUCCESS, superFunds };
}

export function rolloverFundSuccess(id) {
  return { type: ROLLOVER_FUND_SUCCESS, id };
}

export function backOnSuperRegistration(step) {
  return { type: BACK_ON_SUPER_REGISTRATION, step };
}

export function goToConsent() {
  return { type: GO_TO_CONSENT };
}

export function consentSuccess() {
  return { type: CONSENT_SUCCESS };
}

export function lumSumInvest(requestData) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const data = {
    amount: requestData.amount,
    token,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/${process.env.REACT_APP_API_VERSION}/investments`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorLumpSumInvest(response.error));
        } else {
          dispatch(lumSumInvestSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function setConsent() {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const data = {
    token,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/super_annuation/user/consent`,
      {
        method: 'POST',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(consentSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function saveSuperRegistration(
  step,
  nowTime = '',
  gender = null,
  tfn = null,
) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);
  let method = 'PUT';
  let url = 'user';
  let data = {};

  if (step === 1) {
    data = {
      token,
      user: {
        profile: {
          super_annuation_pds_sent_at: nowTime,
        },
      },
    };
  }

  if (step === 2) {
    data = {
      token,
      user: {
        profile: {
          gender,
        },
      },
    };
  }

  if (step === 3) {
    data = {
      token,
      tfn,
    };

    method = 'POST';

    url = 'super_annuation/user/tfn';
  }

  if (step === 5) {
    data = {
      token,
      user: {
        profile: {
          super_annuation_insurance: true,
        },
      },
    };
  }

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${url}`,
      {
        method,
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorInSuperModal(response.error, step));
        } else {
          dispatch(saveSuperRegistrationSuccess(response, step + 1));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createRecurringDepositSettings(requestData) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const data = {
    ...requestData,
    token,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/` +
        `${process.env.REACT_APP_API_VERSION}/recurring_deposit_settings`,
      {
        method: 'PATCH',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorCreateRecurringDepositSettingsSuper(response.error),
          );
        } else {
          dispatch(createRecurringDepositSettingsSuperSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRecurringDepositSettings() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/` +
        `${process.env.REACT_APP_API_VERSION}/recurring_deposit_settings`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetRecurringDepositSettingsSuper(response.error));
        } else {
          dispatch(getRecurringDepositSettingsSuperSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function stopInvestment() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/` +
        `${process.env.REACT_APP_API_VERSION}/recurring_deposit_settings`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetRecurringDepositSettingsSuper(response.error));
        } else {
          dispatch(getRecurringDepositSettingsSuperSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateTransferRewards(requestData) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const data = {
    transfer_rewards: requestData,
    token,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/` +
        `${process.env.REACT_APP_API_VERSION}/setting`,
      {
        method: 'PATCH',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorUpdateTransferRewards(response.error));
        } else {
          dispatch(updateTransferRewardsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSuperSettings() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/super_annuation/${process.env.REACT_APP_API_VERSION}/` +
        'setting',
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetSuperSettings(response.error));
        } else {
          dispatch(getSuperSettingsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function refreshAccounts() {
  return async (dispatch) => {
    try {
      await api
        .post('/super_annuation/user/refresh_accounts')
        .catch((error) => {
          const errorData = error.response.data;

          if (errorData) {
            dispatch(showGlobalError(errorData.error, false));
          }
        });
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getSuperFunds(searchVal = '') {
  let search = '';

  if (searchVal) {
    search = `&search=${searchVal}`;
  }

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}` +
        `/super_annuation/funds?limit=30${search}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetSuperSettings(response.error));
        } else {
          dispatch(getSuperFundsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function rolloverFund(fund) {
  const data = {
    subaccounts: [
      {
        member_account_id: fund.member_number,
        fund_usi: fund.usi,
      },
    ],
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}` +
        '/super_annuation/user/accounts/multiple',
      {
        method: 'POST',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorCreateRecurringDepositSettingsSuper(response.error),
          );
        } else {
          dispatch(rolloverFundSuccess(fund.id));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
