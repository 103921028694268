import { useTranslation } from 'react-i18next';

import { StatementsTitleWrapper } from './styles';

import {
  STATEMENTS_MONTHLY,
  TAX_REPORTS,
  TRADE_CONFIRMATION,
} from 'constants/comonConstants';
import {
  KIDS_STATEMENTS_MONTHLY,
  KIDS_TAX_REPORTS,
  KIDS_TRADE_CONFIRMATION,
} from 'constants/kidsConstants';

interface IStatementsTitleProps {
  currentType: string;
}

function StatementsTitle({ currentType }: IStatementsTitleProps) {
  const { t } = useTranslation();

  switch (currentType) {
    case STATEMENTS_MONTHLY:
    case KIDS_STATEMENTS_MONTHLY:
      return (
        <StatementsTitleWrapper>
          {t('statements.monthlyStatements')}
        </StatementsTitleWrapper>
      );
    case TRADE_CONFIRMATION:
    case KIDS_TRADE_CONFIRMATION:
      return (
        <StatementsTitleWrapper className="info-panel__title">
          {t('statements.confirmation')}
        </StatementsTitleWrapper>
      );
    case TAX_REPORTS:
    case KIDS_TAX_REPORTS:
      return (
        <StatementsTitleWrapper className="info-panel__title">
          {t('statements.tax_reports')}
        </StatementsTitleWrapper>
      );
    default:
      return null;
  }
}

export default StatementsTitle;
