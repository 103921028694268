import { MY_APP } from 'constants/localeConfigs';
import { AUS, MYS } from 'constants/comonConstants';

export const MINIMUM_FRACTION_DIGITS = MY_APP ? 2 : 0;
export const MAXIMUM_FRACTION_DIGITS = MY_APP ? 4 : 2;

export const NUMBER_FORMATS = {
  percent: 'percent',
  currency: 'currency',
  decimal: 'decimal',
} as const;

interface IPostCodeLength {
  [key: string]: number;
}

export const POST_CODE_LENGTH: IPostCodeLength = {
  [AUS]: 4,
  [MYS]: 5,
};

interface IPhoneNumberLength {
  [key: string]: number;
}

export const PHONE_NUMBER_LENGTH: IPhoneNumberLength = {
  [MYS]: 10,
};

export const MINIMUM_DISPLAY_VALUE = 0.01;
