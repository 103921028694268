export const GET_GOALS_SUCCESS = 'GET_GOALS_SUCCESS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const DELETE_GOALS_SUCCESS = 'DELETE_GOALS_SUCCESS';
export const SHOW_ERROR_UPDATE_GOAL = 'SHOW_ERROR_UPDATE_GOAL';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const STOP_INVESTMENT_SUCCESS = 'STOP_INVESTMENT_SUCCESS';
export const GET_TIPS_BY_PATTERN_SUCCESS = 'GET_TIPS_BY_PATTERN_SUCCESS';
export const GET_TOTALS_BY_CATEGORY_SUCCESS = 'GET_TOTALS_BY_CATEGORY_SUCCESS';
export const UPDATE_TRANSACTION_TYPE_SUCCESS =
  'UPDATE_TRANSACTION_TYPE_SUCCESS';
export const GET_CATEGORIES_AVERAGES_SUCCESS =
  'GET_CATEGORIES_AVERAGES_SUCCESS';
export const GET_UPCOMING_TRANSACTIONS_SUCCESS =
  'GET_UPCOMING_TRANSACTIONS_SUCCESS';
export const SHOW_ERROR_UPDATE_TRANSACTION_TYPE =
  'SHOW_ERROR_UPDATE_TRANSACTION_TYPE';
export const GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS =
  'GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS';
export const GET_TRANSACTION_CATEGORISATION_SUCCESS =
  'GET_TRANSACTION_CATEGORISATION_SUCCESS';
export const CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS =
  'CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS';
export const SHOW_ERROR_GET_TOTALS_BY_CATEGORY =
  'SHOW_ERROR_GET_TOTALS_BY_CATEGORY';
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
export const CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const SHOW_ERROR_GET_GOALS = 'SHOW_ERROR_GET_GOALS';
export const TRACK_EVENTS_SUCCESS = 'TRACK_EVENTS_SUCCESS';
export const SHOW_ERROR_CREATE_GOAL = 'SHOW_ERROR_CREATE_GOAL';
export const SHOW_ERROR_DELETE_GOALS = 'SHOW_ERROR_DELETE_GOALS';
export const SHOW_ERROR_TRACK_EVENTS = 'SHOW_ERROR_TRACK_EVENTS';
export const SHOW_ERROR_GET_SETTINGS = 'SHOW_ERROR_GET_SETTINGS';
export const SHOW_ERROR_UPDATE_SETTINGS = 'SHOW_ERROR_UPDATE_SETTINGS';
export const GET_INVEST_CATEGORIES_SUCCESS = 'GET_INVEST_CATEGORIES_SUCCESS';
export const SHOW_ERROR_GET_TIPS_BY_PATTERN = 'SHOW_ERROR_GET_TIPS_BY_PATTERN';
export const SHOW_ERROR_GET_CATEGORIES_AVERAGES =
  'SHOW_ERROR_GET_CATEGORIES_AVERAGES';
export const SHOW_ERROR_GET_UPCOMING_TRANSACTIONS =
  'SHOW_ERROR_GET_UPCOMING_TRANSACTIONS';
export const SHOW_ERROR_GET_TRANSACTIONS_CATEGORISATION =
  'SHOW_ERROR_GET_TRANSACTIONS_CATEGORISATION';
export const SHOW_PAYMENT_CHOICE_RECURRING_MODAL =
  'SHOW_PAYMENT_CHOICE_RECURRING_MODAL';
