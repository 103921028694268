import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import {
  Wrapper,
  Title,
  Divider,
  BottomBackground,
  Dashboard,
  WidgetButton,
} from './styles';
import { getPerformanceLink } from './utils';

import PerfomanceDetails from 'pages/PerfomanceDetails/PerfomanceDetails';
import { PATHS } from 'constants/paths';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

interface IPerfomanceProps {
  isSuper?: boolean;
}

export const Perfomance = ({ isSuper = false }: IPerfomanceProps) => {
  const isNeedFixBottomValue = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.isNeedFixBottom,
  );

  const isNeedFixBottom =
    isNeedFixBottomValue !== null && isNeedFixBottomValue !== 'false';

  const { t } = useTranslation();
  const isHomepage = Boolean(useMatch(PATHS.root));
  const isCustomWrapperWidth = !isHomepage;

  return (
    <Wrapper isCustomWrapperWidth={isCustomWrapperWidth}>
      <Title>
        {isSuper
          ? t('performanceWidget.superPerfomance')
          : t('performanceWidget.perfomance')}
      </Title>

      <Divider />

      <Dashboard isSuper={isSuper}>
        <PerfomanceDetails isDashboard isSuper={isSuper} />

        <BottomBackground isFixedToBottom={isNeedFixBottom} isSuper={isSuper}>
          <WidgetButton
            href={getPerformanceLink(isSuper)}
            label={t('performanceWidget.button')}
            variant="transparent"
          />
        </BottomBackground>
      </Dashboard>
    </Wrapper>
  );
};
