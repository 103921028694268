import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonContainerItem,
  ButtonSubValue,
  ButtonSubLabel,
  ButtonLabel,
} from './styles';

import ProgressInfo from 'components/elements/ProgressInfo/ProgressInfo';
import { MY_APP } from 'constants/togglesFunctions';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IUser {
  alerts: [];
  round_ups: {
    invested: number;
    waiting: number;
  };
}

interface IRoundUpsManualButtonProps {
  user: IUser;
  handleInvest: (isRecurring: boolean) => void;
}

function RoundUpsManualButton({
  user,
  handleInvest,
}: IRoundUpsManualButtonProps) {
  const { t } = useTranslation();

  if (MY_APP) return null;

  return (
    <div>
      <ButtonLabel>{t('roundUps.manualRound')}</ButtonLabel>

      <ButtonContainerItem
        type="button"
        onClick={() => handleInvest(false)}
        tabIndex={0}
      >
        <ProgressInfo value={user.round_ups.waiting} maxValue={5}>
          <ButtonSubValue>
            {formatNumber({
              value: user.round_ups.waiting,
            })}
          </ButtonSubValue>

          <ButtonSubLabel>{t('roundUps.tapToInvest')}</ButtonSubLabel>
        </ProgressInfo>
      </ButtonContainerItem>
    </div>
  );
}

export default RoundUpsManualButton;
