import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ACCESS_SELECTED,
  REGISTRATION_PORTFOLIO_SELECT,
} from '../../../store/dependentUser/types';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function KidsAccess({
  showLoader,
  goBack,
  handleSubmit,
  handleChange,
  manage,
  child,
  isEditChild,
}) {
  const { t } = useTranslation();

  const accountInfo = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
  );

  const isAccountAccess = isEditChild
    ? child.account_access
    : accountInfo.dependency_user.account_access;

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, ACCESS_SELECTED)}
      render={() => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, ACCESS_SELECTED)}
        >
          <div className="raiz-kids__recurring-page">
            {isEditChild && (
              <div className="raiz-kids__notifications">
                <div>
                  {t('raizKidsEditPage.access.allowAccess')}

                  <div className="raiz-kids__notifications-sub">
                    {child.account_access
                      ? t('raizKidsEditPage.access.allowed.true')
                      : t('raizKidsEditPage.access.allowed.false')}
                  </div>
                </div>

                <Link
                  className="form-field__link raiz-kids__notifications-changed"
                  to={`/raiz-kids/edit/allowedAccess/${child.id}`}
                >
                  {t('common.change')}
                </Link>
              </div>
            )}

            {isEditChild && (
              <div className="raiz-kids__notifications">
                <div>
                  {t('raizKidsEditPage.access.investment.title')}

                  <div className="raiz-kids__notifications-sub">
                    {child.investing &&
                      child.investing_weekly_limit !== null &&
                      t('raizKidsEditPage.access.investment.weeklyLimit')}

                    {child.investing &&
                      child.investing_weekly_limit === null &&
                      t('raizKidsEditPage.access.allowed.true')}

                    {!child.investing &&
                      t('raizKidsEditPage.access.allowed.false')}
                  </div>
                </div>

                <Link
                  className="form-field__link raiz-kids__notifications-changed"
                  to={`/raiz-kids/edit/investingAccess/${child.id}`}
                >
                  {t('common.change')}
                </Link>
              </div>
            )}

            <div className="raiz-kids__notifications">
              <div>{t('raizKidsEditPage.access.allow.portfolio')}</div>

              <Switch
                disabled={!isAccountAccess}
                onChange={(condition) => {
                  handleChange(condition, 'manage_portfolio');
                }}
                checked={manage.manage_portfolio}
                onColor="#0AAE77"
                offColor="#D9D9D9"
                onHandleColor="#ffffff"
                handleDiameter={27}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)"
                activeBoxShadow="0"
                height={31}
                width={51}
                className="react-switch"
                id="material-switch"
              />
            </div>

            <div className="raiz-kids__notifications">
              <div>{t('raizKidsEditPage.access.allow.recurring')}</div>

              <Switch
                disabled={!isAccountAccess}
                onChange={(condition) => {
                  handleChange(condition, 'manage_recurring_and_goals');
                }}
                checked={manage.manage_recurring_and_goals}
                onColor="#0AAE77"
                offColor="#D9D9D9"
                onHandleColor="#ffffff"
                handleDiameter={27}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)"
                activeBoxShadow="0"
                height={31}
                width={51}
                className="react-switch"
                id="material-switch"
              />
            </div>

            <div className="raiz-kids__notifications">
              <div>{t('raizKidsEditPage.access.allow.rewards')}</div>

              <Switch
                disabled={!isAccountAccess}
                onChange={(condition) => {
                  handleChange(condition, 'rewards');
                }}
                checked={manage.rewards}
                onColor="#0AAE77"
                offColor="#D9D9D9"
                onHandleColor="#ffffff"
                handleDiameter={27}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)"
                activeBoxShadow="0"
                height={31}
                width={51}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div>

          <div className="buttons-part">
            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  title={t('common.back')}
                  buttonClass="button back"
                  handleClick={() =>
                    goBack({ path: REGISTRATION_PORTFOLIO_SELECT })
                  }
                />
              </div>
            )}

            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  type="submit"
                  title={t('common.next')}
                  buttonClass="button button--submit"
                />
              </div>
            )}

            {isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  type="submit"
                  disabled={!isAccountAccess}
                  title={t('common.save')}
                  buttonClass="button button--submit"
                />
              </div>
            )}
          </div>
        </form>
      )}
    />
  );
}

KidsAccess.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  manage: PropTypes.shape({
    manage_recurring_and_goals: PropTypes.bool,
    manage_portfolio: PropTypes.bool,
    rewards: PropTypes.bool,
  }),
  isEditChild: PropTypes.bool,
  child: PropTypes.shape({
    id: PropTypes.string,
    investing: PropTypes.bool,
    investing_weekly_limit: PropTypes.number,
    account_access: PropTypes.bool,
  }),
};

KidsAccess.defaultProps = {
  manage: {},
  child: {},
  isEditChild: false,
};
