import { useTranslation } from 'react-i18next';

import { Children } from '../RaizKidsContent/types';

import { KidCardRow, KidCardOpenedPortfolioHeader } from './styles';
import { KidCardInfoItem } from './KidCardInfoItem';
import KidCardOpenedPortfolio from './KidCardOpenedPortfolio';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectCashRewards } from 'store/history/selectors';

interface IKidCardInfoProps {
  kid: Children;
}

export const KidCardInfoMalaysia = ({ kid }: IKidCardInfoProps) => {
  const { t } = useTranslation();
  const cashRewards = useAppSelector(selectCashRewards({ isKids: true }));

  return (
    <KidCardRow>
      {kid.portfolio && (
        <KidCardOpenedPortfolioHeader>
          {t('raizKidsPage.portfolio', { portfolio: kid.portfolio.name })}
        </KidCardOpenedPortfolioHeader>
      )}

      <KidCardInfoItem
        label={t('raizKidsPage.cards.investedByYou')}
        value={kid.summary.deposits.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.withdrawnLabel')}
        value={kid.summary.withdrawals.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.dividend')}
        value={kid.summary.reinvested_dividends.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.cashRewards')}
        value={cashRewards}
      />

      <KidCardOpenedPortfolio kid={kid} />
    </KidCardRow>
  );
};
