import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function InvitationLink({ platformLink }) {
  const { t } = useTranslation();

  return (
    <a target="_blank" href={platformLink} rel="noreferrer">
      {t('inviteModal.here')}
    </a>
  );
}

InvitationLink.propTypes = {
  platformLink: PropTypes.string.isRequired,
};

export default InvitationLink;
