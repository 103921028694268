import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { IStepperArgs } from './types';

export const useStepper = ({
  steps,
  onProcessCompleted = () => {},
}: IStepperArgs) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const stepperPaths = useMemo(
    () => steps.map(({ path }) => path) || [],
    [steps],
  );

  const activeStepIndex = stepperPaths.indexOf(pathname);
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === stepperPaths.length - 1;

  const goNext = useCallback(() => {
    if (isLastStep) {
      onProcessCompleted();
    } else {
      navigate(stepperPaths[activeStepIndex + 1]);
    }
  }, [activeStepIndex, isLastStep, navigate, onProcessCompleted, stepperPaths]);

  const goBack = () => {
    if (isFirstStep) return;

    navigate(stepperPaths[activeStepIndex - 1]);
  };

  return {
    isFirstStep,
    isLastStep,
    goNext,
    goBack,
  };
};
