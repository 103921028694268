import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { AU_APP } from '../../../../../../constants/localeConfigs';
import { getTitleFromDocumentType } from '../../utils';

import { DOCUMENT_TYPES } from 'constants/comonConstants';

function UploadIdContent({ documentType, uploadErrorType }) {
  const { t } = useTranslation();

  const title = getTitleFromDocumentType({ documentType, uploadErrorType, t });

  const isAdditionalStylesNeeded = [
    DOCUMENT_TYPES.idFront,
    DOCUMENT_TYPES.idBack,
  ].includes(documentType);

  return (
    <div className="registration-page__dropzone-content" key={documentType}>
      <div
        className={`registration-page__dropzone-title ${
          AU_APP && isAdditionalStylesNeeded ? 'au-upload' : ''
        }`}
      >
        {title}
      </div>

      <i className="registration-page__dropzone-icon" />

      <div className="registration-page__dropzone-subtitle">
        <Trans
          i18nKey="registrationPage.uploadID.description"
          t={t}
          components={{ span: <span /> }}
        />
      </div>
    </div>
  );
}

UploadIdContent.propTypes = {
  documentType: PropTypes.string.isRequired,
  uploadErrorType: PropTypes.string,
};

UploadIdContent.defaultProps = {
  uploadErrorType: '',
};

export default UploadIdContent;
