import React from 'react';
import PropTypes from 'prop-types';

import { generateKeyForTransactionsGroup } from '../utils/utils';

import RoundUpsElement from './RoundUpsElement';
import RoundUpsSkeleton from './RoundUpsSkeleton';
import { RoundUpsContentDate } from './styles';

function RoundUpsContent({ items, handleSelectCheckbox, isLoading }) {
  if (isLoading) return <RoundUpsSkeleton />;

  return items?.map((information) => {
    const key = generateKeyForTransactionsGroup(information);

    return (
      <div key={key}>
        <RoundUpsContentDate>{information.date}</RoundUpsContentDate>

        {information.transactions.map((info) => (
          <RoundUpsElement
            info={info}
            handleSelectCheckbox={handleSelectCheckbox}
            key={`transaction_${info.id}`}
          />
        ))}
      </div>
    );
  });
}

export default RoundUpsContent;

RoundUpsContent.defaultProps = {
  items: null,
};

RoundUpsContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelectCheckbox: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
