export const MAXIMUM_SAVINGS_RATE = 10000;
export const ACTIVE_TAB = 'activeTab';
export const SEARCH_PARAMS_ACTIVE_TAB = {
  future: 'future',
} as const;
export const SWITCHER_PORTFOLIO_TYPES = {
  emerald: 'emerald',
  sapphire: 'sapphire',
  custom: 'custom',
};
