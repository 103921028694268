import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';

import { IUseStatementProps } from './types';

import {
  STATEMENTS_MONTHLY,
  TAX_REPORTS,
  TRADE_CONFIRMATION,
} from 'constants/comonConstants';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { getStatementsUrl } from 'store/invest/actions';
import { DATE_FORMAT_MONTH_DASH_YEAR } from 'config/date';

export const useDownloadStatement = ({ currentType }: IUseStatementProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentStatementId, setCurrentStatementId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadStatement = ({
    id,
    createdAt,
  }: {
    id: string;
    createdAt: string;
  }) => {
    const filenameCondition =
      currentType === STATEMENTS_MONTHLY ||
      currentType === TRADE_CONFIRMATION ||
      currentType === TAX_REPORTS;

    const filename =
      filenameCondition &&
      `${t(`statements.${currentType}`)}(${dayjs(createdAt).format(
        DATE_FORMAT_MONTH_DASH_YEAR,
      )})`;

    if (id) {
      setIsLoading(true);

      setCurrentStatementId(id);

      dispatch(
        getStatementsUrl({
          id,
          type: currentType,
          responseFunction: (statementsLink: string) => {
            fetch(statementsLink)
              .then((response) => response.blob())
              .then((blob) => fileDownload(blob, `${filename}.pdf`))
              .then(() => {
                setIsLoading(false);

                setCurrentStatementId('');
              })
              .catch((error) => {
                console.error('Request failed.', error);
              });
          },
        }),
      );
    }
  };

  return {
    handleDownloadStatement,
    currentStatementId,
    isLoading,
  };
};
