import Firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import 'react-rangeslider/lib/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../styles/pages/raiz-kids.scss';

import EmailSentModal from '../../components/layout/Modals/EmailSentModal';
import ErrorModal from '../../components/layout/Modals/ErrorModal';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';
import { NEW_KIDS } from '../../constants/comonConstants';
import {
  dependencyUserInvestment,
  getDependencyUsersSummary,
} from '../../store/dependentUser/actions';
import { CREDIT } from '../../store/dependentUser/types';
import { getGiftsIcons } from '../../store/gifts/actions';
import { getUserData } from '../../store/user/actions';
import { checkIsDependentUser } from '../../utils/user';
import KidsInitialInvestment from '../RaizKidsRegistration/components/KidsInitialInvestment';

import EnableKidsModal from './components/Modals/EnableKidsModal/EnableKidsModal';
import RaizKidsBanner from './components/RaizKidsBanner/RaizKidsBanner';
import { RaizKidsContent } from './components/RaizKidsContent/RaizKidsContent';
import RaizKidsSwitch from './components/RaizKidsSwitch/RaizKidsSwitch';
import { RaizKidsWrapper } from './styles';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

function RaizKids() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isHelpCardsModalOpen, setIsHelpCardsModalOpen] = useState(false);
  const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false);

  const [isInitialInvestmentModalOpen, setIsInitialInvestmentModalOpen] =
    useState(false);

  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [oneTimeInvestmentValue, setOneTimeInvestmentValue] = useState(50);
  const [currentChild, setCurrentChild] = useState({});
  const [isActiveListOpen, setIsActiveListOpen] = useState(true);

  const { user, errorMessage, dependencyUsers } = useSelector((state) => ({
    user: state.user.user,
    errorMessage:
      state.gifts.errorMessage || state.dependencyUsers.errorMessage,
    dependencyUsers: state.dependencyUsers.dependency_users,
  }));

  useEffect(() => {
    dispatch(getUserData());

    dispatch(getGiftsIcons());

    Firebase.analytics().logEvent('Raiz_Kids');

    setIsLoading(true);

    dispatch(getDependencyUsersSummary(true)).then((isDependencyUsers) => {
      setIsLoading(false);

      if (!isDependencyUsers) {
        setIsStartModalOpen(true);
      }
    });

    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.get('emailSent') !== null) {
      setIsEmailSentModalOpen(true);
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.kidsRegistration);
    }
  }, [searchParams]);

  useEffect(() => {
    if (checkIsDependentUser(user)) {
      navigate('/not-found');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (errorMessage && errorMessage.length) {
      setIsErrorModalOpen(true);
    } else {
      setIsErrorModalOpen(false);
    }
  }, [errorMessage]);

  const closeStartModal = (isRedirectToKidRegistration) => {
    setIsStartModalOpen(false);

    if (isRedirectToKidRegistration) {
      navigate('/raiz-registration/personal/terms');
    }
  };

  const handleSubmitInvestNow = () => {
    dispatch(
      dependencyUserInvestment(
        { amount: oneTimeInvestmentValue, type: CREDIT },
        currentChild.id,
      ),
    );

    setIsInitialInvestmentModalOpen(false);
  };

  const handleEmailSent = () => {
    setIsEmailSentModalOpen(false);

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.kidsRegistration);

    navigate('/raiz-kids');
  };

  const toggleInvestNow = ({ e, isModalOpen, kid = {} }) => {
    e.preventDefault();

    setIsInitialInvestmentModalOpen(isModalOpen);

    setCurrentChild(kid);

    if (!isModalOpen) {
      setOneTimeInvestmentValue(50);
    }
  };

  const kidsLists = dependencyUsers?.reduce(
    ({ activeAccounts, closedAccounts }, dependencyUser) => {
      if (dependencyUser.closed) {
        return {
          activeAccounts,
          closedAccounts: [...closedAccounts, dependencyUser],
        };
      }

      return {
        activeAccounts: [...activeAccounts, dependencyUser],
        closedAccounts,
      };
    },
    { activeAccounts: [], closedAccounts: [] },
  );

  const isActiveTabVisible =
    kidsLists?.activeAccounts?.length > 0 ||
    (!kidsLists?.activeAccounts?.length > 0 &&
      !kidsLists?.closedAccounts?.length > 0);

  useEffect(() => {
    setIsActiveListOpen(isActiveTabVisible);
  }, [isActiveTabVisible]);

  const kidsList = isActiveListOpen
    ? kidsLists?.activeAccounts
    : kidsLists?.closedAccounts;

  return (
    <RaizKidsWrapper>
      <RaizKidsBanner setIsHelpCardsModalOpen={setIsHelpCardsModalOpen} />

      <RaizKidsSwitch
        isActiveListOpen={isActiveListOpen}
        setIsActiveListOpen={setIsActiveListOpen}
      />

      <RaizKidsContent
        isActiveListOpen={isActiveListOpen}
        isLoading={isLoading}
        toggleInvestNow={toggleInvestNow}
        kidsList={kidsList}
      />

      {isEmailSentModalOpen && (
        <EmailSentModal toggleEmailSentModal={handleEmailSent} />
      )}

      {isInitialInvestmentModalOpen && (
        <KidsInitialInvestment
          isOpenInvestment
          child={currentChild}
          isEditChild
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          goBack={toggleInvestNow}
          changeOneTimeInvestmentValue={setOneTimeInvestmentValue}
          handleSubmit={handleSubmitInvestNow}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          errorMessage={errorMessage}
          isOpenErrorModal={isErrorModalOpen}
          toggleErrorModal={setIsErrorModalOpen}
        />
      )}

      {isStartModalOpen && (
        <EnableKidsModal toggleKindsInfoModal={closeStartModal} />
      )}

      <HelpCardsModalAU
        currentPage={NEW_KIDS}
        toggleHelpCard={setIsHelpCardsModalOpen}
        isOpenHelpCards={isHelpCardsModalOpen}
      />
    </RaizKidsWrapper>
  );
}

export default RaizKids;
