import { useTranslation } from 'react-i18next';

import { ICategoryItem } from './types';
import {
  InfoPanelListItem,
  MyFinanceSpendIcons,
  MyFinanceSpendValue,
} from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCategoryName,
  selectSubCategoryName,
} from 'store/spendingAnalytics/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';
import { AU_APP } from 'constants/localeConfigs';

interface ICategoryItemProps {
  dataItem: ICategoryItem;
  sum: number;
}

export const CategoryItem = ({ dataItem, sum }: ICategoryItemProps) => {
  const { t } = useTranslation();

  const categoryName = useAppSelector(selectCategoryName(dataItem.category));

  const subCategoryName = useAppSelector(
    selectSubCategoryName({
      categoryId: dataItem.parent,
      subCategoryId: dataItem.category,
    }),
  );

  const translatedCategoryName = subCategoryName || categoryName;

  const monthlyPercent =
    sum > 0 ? Math.round((100 * dataItem.dashboard) / sum) : 0;

  return (
    <InfoPanelListItem key={dataItem.category}>
      <MyFinanceSpendIcons>
        <div>{AU_APP ? dataItem.category : translatedCategoryName}</div>
      </MyFinanceSpendIcons>

      <MyFinanceSpendValue>
        {t('myFinanceWidget.monthlyPercent', {
          monthlyPercent,
        })}
      </MyFinanceSpendValue>

      <MyFinanceSpendValue>
        {formatNumber({
          value: dataItem.dashboard,
        })}
      </MyFinanceSpendValue>
    </InfoPanelListItem>
  );
};
