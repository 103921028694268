import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '../../../../styles/pages/invest.scss';
import '../../../../styles/layout/statements.scss';
import SuperHistoryBannerLabelInfo from '../SuperHistoryBannerLabelInfo';

import SuperHistoryBannerSubValue from './components/SuperHistoryBannerSubValue';

export default function SuperHistoryBannerRows({ toggleInfoModal }) {
  const { investmentsOverview, accountSummary } = useSelector((state) => ({
    investmentsOverview: state.history.investmentsOverview,
    accountSummary: state.history.accountSummary,
  }));

  const { user } = accountSummary || {};

  const { total_dividends, total_withdrawn, total_fees } =
    investmentsOverview || {};

  const { reinvested_dividends, gains } = user || {};

  const { value } = reinvested_dividends || {};
  const { total } = gains || {};
  const { net_return_amount } = total || {};
  const { t } = useTranslation();

  return (
    <div className="page-content__banner-row">
      <div className="page-content__banner-col">
        <SuperHistoryBannerLabelInfo
          toggleInfoModal={toggleInfoModal}
          subLabel={t(
            'historyDetailsPage.superHistoryBanner.help.employerContributions.title',
          )}
          recurringModalTitle="historyDetailsPage.superHistoryBanner.help.employerContributions.title"
          recurringModalMsg="historyDetailsPage.superHistoryBanner.help.employerContributions.description"
        />

        <SuperHistoryBannerSubValue
          value={user?.employer_contributions}
          fixedDecimalScale={Number.isInteger(user?.employer_contributions)}
        />
      </div>

      <div className="page-content__banner-col">
        <SuperHistoryBannerLabelInfo
          toggleInfoModal={toggleInfoModal}
          subLabel={t(
            'historyDetailsPage.superHistoryBanner.help.yourContributions.title',
          )}
          recurringModalTitle="historyDetailsPage.superHistoryBanner.help.yourContributions.title"
          recurringModalMsg="historyDetailsPage.superHistoryBanner.help.yourContributions.description"
        />

        <SuperHistoryBannerSubValue
          value={user ? user?.member_contributions : total_dividends}
          fixedDecimalScale={Number.isInteger(
            user ? user?.member_contributions : total_dividends,
          )}
        />
      </div>

      <div className="page-content__banner-col">
        <SuperHistoryBannerLabelInfo
          toggleInfoModal={toggleInfoModal}
          subLabel={t(
            'historyDetailsPage.superHistoryBanner.help.reinvestedDividends.title',
          )}
          recurringModalTitle="historyDetailsPage.superHistoryBanner.help.reinvestedDividends.title"
          recurringModalMsg="historyDetailsPage.superHistoryBanner.help.reinvestedDividends.description"
        />

        <SuperHistoryBannerSubValue
          value={user ? value : total_withdrawn}
          fixedDecimalScale={Number.isInteger(user ? value : total_withdrawn)}
        />
      </div>

      <div className="page-content__banner-col">
        <SuperHistoryBannerLabelInfo
          toggleInfoModal={toggleInfoModal}
          subLabel={t(
            'historyDetailsPage.superHistoryBanner.help.marketReturns.title',
          )}
          recurringModalTitle="historyDetailsPage.superHistoryBanner.help.marketReturns.title"
          recurringModalMsg="historyDetailsPage.superHistoryBanner.help.marketReturns.description"
        />

        <SuperHistoryBannerSubValue
          value={user ? net_return_amount : total_fees}
          fixedDecimalScale={Number.isInteger(
            user ? net_return_amount : total_fees,
          )}
        />
      </div>

      <div className="page-content__banner-col">
        <SuperHistoryBannerLabelInfo
          toggleInfoModal={toggleInfoModal}
          subLabel={t(
            'historyDetailsPage.superHistoryBanner.help.rollover.title',
          )}
          recurringModalTitle="historyDetailsPage.superHistoryBanner.help.rollover.title"
          recurringModalMsg="historyDetailsPage.superHistoryBanner.help.rollover.description"
        />

        <SuperHistoryBannerSubValue
          value={user?.rollover_contributions}
          fixedDecimalScale={Number.isInteger(user?.rollover_contributions)}
        />
      </div>
    </div>
  );
}

SuperHistoryBannerRows.propTypes = {
  toggleInfoModal: PropTypes.func.isRequired,
};
