import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/logout.scss';

export default function RolloverAgreementModal({
  isShowAgreement,
  handleCloseModal,
  rolloverFund,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowAgreement}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert fund__alert fund__agreement rollover-agreement"
    >
      <div className="modal-alert-content agreement">
        <div className="title">
          {t('superPage.rolloverAgreementModal.acknowledgement')}
        </div>

        <div className="subTitle">
          {t('superPage.rolloverAgreementModal.rollingOver')}
        </div>

        <DeprecatedButton
          title={<Trans i18nKey="common.space" t={t} />}
          buttonClass="button__close -black"
          handleClick={handleCloseModal}
        />

        <div className="error-buttons">
          <DeprecatedButton
            title={<Trans i18nKey="common.ok" t={t} />}
            type="button"
            handleClick={rolloverFund}
          />

          <DeprecatedButton
            buttonClass="cancel"
            title={<Trans i18nKey="common.cancel" t={t} />}
            type="button"
            handleClick={handleCloseModal}
          />
        </div>
      </div>
    </Modal>
  );
}

RolloverAgreementModal.propTypes = {
  isShowAgreement: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  rolloverFund: PropTypes.func.isRequired,
};
