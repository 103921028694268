import React from 'react';
import PropTypes from 'prop-types';

import ErrorModal from '../../components/layout/Modals/ErrorModal';
import RecurringModal from '../../components/layout/Modals/RecurringModal';

import RaizPersonal from './components/RaizPersonal';
import RaizPortfolio from './components/RaizPortfolio';
import RaizInvestment from './components/RaizInvestment';
import RaizOther from './components/RaizOther';

export default function RaizKidsRegistrationView({
  messages,
  stepType,
  stepName,
  showLoader,
  goBack,
  handleSubmit,
  handleSubmitQuestions,
  renderIcons,
  receiverIcons,
  dob,
  handleChangeDate,
  allowType,
  changeAccessType,
  dobError,
  handleSubmitInvestment,
  oneTimeInvestmentValue,
  changeOneTimeInvestmentValue,
  allowTypeInvestment,
  changeAccessInvestment,
  isShowErrorModal,
  errorMessage,
  toggleErrorModal,
  changeUnlimitedAmount,
  unlimAmount,
  modalErrorMessage,
  newKidEmail,
  newKidName,
  alert,
  canEditEmail,
  history,
  isProPortfolioEnabled,
}) {
  let registrationStep = '';

  switch (stepType) {
    case 'personal':
      registrationStep = (
        <RaizPersonal
          dob={dob}
          dobError={dobError}
          allowType={allowType}
          changeAccessType={changeAccessType}
          handleChangeDate={handleChangeDate}
          showLoader={showLoader}
          renderIcons={renderIcons}
          receiverIcons={receiverIcons}
          handleSubmit={handleSubmit}
          handleSubmitQuestions={handleSubmitQuestions}
          goBack={goBack}
          stepName={stepName}
          messages={messages}
          newKidEmail={newKidEmail}
          newKidName={newKidName}
          canEditEmail={canEditEmail}
        />
      );

      break;
    case 'investment':
      registrationStep = (
        <RaizInvestment
          showLoader={showLoader}
          changeOneTimeInvestmentValue={changeOneTimeInvestmentValue}
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          handleSubmit={handleSubmitInvestment}
          goBack={goBack}
          changeUnlimitedAmount={changeUnlimitedAmount}
          stepName={stepName}
          unlimAmount={unlimAmount}
          allowTypeInvestment={allowTypeInvestment}
          changeAccessInvestment={changeAccessInvestment}
        />
      );

      break;
    case 'portfolio':
      registrationStep = (
        <RaizPortfolio
          showLoader={showLoader}
          handleSubmit={handleSubmit}
          goBack={goBack}
          stepName={stepName}
          isProPortfolioEnabled={isProPortfolioEnabled}
        />
      );

      break;
    case 'other':
      registrationStep = (
        <RaizOther
          history={history}
          goBack={goBack}
          stepName={stepName}
          messages={messages}
        />
      );

      break;
    default:
      break;
  }

  registrationStep = (
    <div>
      {registrationStep}

      {isShowErrorModal && (
        <ErrorModal
          messages={messages}
          errorMessage={errorMessage || modalErrorMessage}
          isOpenErrorModal={isShowErrorModal}
          toggleErrorModal={toggleErrorModal}
        />
      )}

      {alert.isOpen && (
        <RecurringModal
          isOpen={alert.isOpen}
          modalTitle={alert.title}
          modalContent={alert.text}
          handleClick={alert.handleClick}
          modalFooterClassName="-single-btn"
        />
      )}
    </div>
  );

  return registrationStep;
}

RaizKidsRegistrationView.defaultProps = {
  stepType: '',
  stepName: '',
};

RaizKidsRegistrationView.propTypes = {
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  stepType: PropTypes.string,
  stepName: PropTypes.string,
};
