import {
  usePortfolioCustomizationRoute,
  usePortfolioFunds,
} from '../../../hooks';
import PortfolioCustomizationFooter from '../../PortfolioCustomizationFooter/PortfolioCustomizationFooter';
import { PORTFOLIO_CUSTOMIZATION_TABS } from '../../../consts';

import { FundsListHeader, FundsListWrapper } from './styles';
import FundsListFilter from './FundsListFilter';
import FundsListSkeleton from './Skeleton/FundsListSkeleton';
import Sorting from './Sorting/Sorting';
import FundsListContent from './FundsListContent';
import FundsListSearch from './FundsListSearch';

export default function FundsList() {
  const { funds, isLoading } = usePortfolioFunds();
  const { activeTab } = usePortfolioCustomizationRoute();

  const isFundsListFilterVisible =
    activeTab === PORTFOLIO_CUSTOMIZATION_TABS.explore;

  return (
    <>
      <FundsListWrapper isFooterVisible>
        <FundsListHeader>
          {isFundsListFilterVisible && <FundsListFilter />}

          <Sorting />

          <FundsListSearch />
        </FundsListHeader>

        {isLoading ? <FundsListSkeleton /> : <FundsListContent funds={funds} />}
      </FundsListWrapper>

      <PortfolioCustomizationFooter />
    </>
  );
}
