import { useNavigate } from 'react-router';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectRegistrationPreviousStep } from 'store/user/selectors';
import { setCurrentRegistrationStep } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const useRegistrationPreviousStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const previousStep = useAppSelector(selectRegistrationPreviousStep);

  const navigateToPreviousStep = () => {
    dispatch(setCurrentRegistrationStep(previousStep));

    navigate(previousStep);
  };

  return { navigateToPreviousStep };
};
