import { useLocation } from 'react-router-dom';

import { PATHS } from 'constants/paths';

export const useStatementsPath = () => {
  const { pathname } = useLocation();

  const isRaizKids = pathname.includes(PATHS.kids.index);
  const isRaizSuper = pathname.includes(PATHS.super.index);

  return {
    isRaizKids,
    isRaizSuper,
  };
};
