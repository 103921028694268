import { useSearchParams } from 'react-router-dom';

import { ACCOUNT_ID, BANK_GRID_MODE, BANK_GRID_MODE_KEYS } from '../../consts';

import { initiate } from 'store/banks';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface IOpenBankGridCallback {
  bankGridMode?: keyof typeof BANK_GRID_MODE_KEYS;
  id?: string | null;
}

export const useOpenBankGridView = () => {
  const dispatch = useAppDispatch();
  const [_, setSearchParams] = useSearchParams();

  return ({
    bankGridMode = BANK_GRID_MODE_KEYS.form,
    id = null,
  }: IOpenBankGridCallback) => {
    dispatch(() => dispatch(initiate(undefined, { forceRefetch: true }))).then(
      () => {
        if (bankGridMode) {
          setSearchParams({
            [BANK_GRID_MODE]: bankGridMode,
            ...(id ? { [ACCOUNT_ID]: id } : {}),
          });
        }
      },
    );
  };
};
