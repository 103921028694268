import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCheckSafeAllocationThreshold } from 'pages/Portfolio/hooks';
import { toggleInfoModal } from 'store/modals/actions';
import { updateFundsPreview } from 'store/portfolio/actions';
import { selectIsAllocationExhausted } from 'store/portfolio/selectors';

export const useUpdateFundAllocation = ({
  id,
  setAllocationInputValue,
  combinedAllocation,
}) => {
  const dispatch = useDispatch();

  const { isFundsLoading, isAllocationExhausted } = useSelector((state) => ({
    isFundsLoading: state.portfolio.isFundsLoading,
    isAllocationExhausted: selectIsAllocationExhausted(state),
  }));

  const [isCurrentFundLoading, setIsCurrentFundLoading] = useState(false);

  const { checkSafeAllocationThreshold } = useCheckSafeAllocationThreshold({
    initialAllocation: combinedAllocation,
    setIsCurrentFundLoading,
  });

  const updateFundAllocation = ({ updatedAllocationValue }) => {
    setIsCurrentFundLoading(true);

    dispatch(
      updateFundsPreview({
        fundId: id,
        updatedAllocationValue,
      }),
    ).then((response) => {
      const { title, description } = response?.alerts || {};

      const { allocation } =
        response?.funds?.find((fund) => fund.id === id) || {};

      if (title && description) {
        setAllocationInputValue(`${allocation}`);

        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title,
              description,
            },
          }),
        );
      } else {
        checkSafeAllocationThreshold(allocation);
      }

      setIsCurrentFundLoading(false);
    });
  };

  return {
    updateFundAllocation,
    isFundsLoading,
    isCurrentFundLoading,
    isAllocationExhausted,
  };
};
