import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormattedSuitability } from '../types';
import { useRegistrationFinancialPage } from '../hooks/useRegistrationFinancialPage';

import {
  FinancialFormItemSelectWrapper,
  FinancialSelectWrapper,
  RegistrationFinancialSelectWrapper,
} from './styles';
import AdditionalField from './AdditionalField';

import { checkIsIndustryFieldVisible } from 'pages/Settings/utils';
import { SUITABILITIES_PICKER_ID } from 'pages/Settings/consts';
import { SUITABILITY_NAMES } from 'constants/suitabilityConstants';
import { SelectField } from 'components/form/select/SelectField';

export interface IFinancialFormItem {
  item: IFormattedSuitability;
}

export default function FinancialFormItem({ item }: IFinancialFormItem) {
  const isCurrentPickerIndustry =
    item.question.picker_id === SUITABILITIES_PICKER_ID.industry;

  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();

  const { t } = useTranslation();

  const { watch } = useFormContext();
  const values = watch();

  const isIndustryFieldVisible =
    !isCurrentPickerIndustry ||
    (isCurrentPickerIndustry && checkIsIndustryFieldVisible({ values }));

  const isAdditionalFieldVisible = values?.[item.label]?.[0]?.textInput;

  if (!isIndustryFieldVisible) {
    return null;
  }

  return (
    <Fragment key={item.label}>
      <SelectField
        name={item.label}
        label={item.question.place_holder}
        placeholder={t('common.select')}
        options={item.options}
        CustomSelectWrapper={
          isRegistrationFinancialPage
            ? RegistrationFinancialSelectWrapper
            : FinancialFormItemSelectWrapper
        }
        CustomSelectLabel={FinancialSelectWrapper}
      />

      {isAdditionalFieldVisible && (
        <AdditionalField name={SUITABILITY_NAMES?.[item.label]} />
      )}
    </Fragment>
  );
}
