import { AUS } from 'constants/comonConstants';

export const LANGUAGES = {
  enAu: 'en-AU',
  enMs: 'en-MS',
  msMs: 'ms-MS',
} as const;

export const options = {
  order: [
    'customDetector',
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
  ],
};

export const appVersion = process.env.REACT_APP_CODE_BASE_VERSION || AUS;
