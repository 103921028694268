import 'styles/pages/invest.scss';
import { useTranslation } from 'react-i18next';

import { IStatementsSendMailButtonProps } from '../../types';

import 'styles/layout/statements.scss';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';

const StatementsSendMailButton = ({
  statements,
  sendStatements,
  isCheckboxesVisible,
  toggleSelectStatements,
}: IStatementsSendMailButtonProps) => {
  const { t } = useTranslation();
  const { isRaizKids } = useStatementsPath();

  const isStatements = !!statements.length;

  if (AU_APP && isStatements) {
    return (
      <DeprecatedButton
        title={t('statements.confirmSendDocument')}
        buttonClass="send-data -icon"
        handleClick={sendStatements}
      />
    );
  }

  if (MY_APP && isStatements && isRaizKids) {
    return (
      <DeprecatedButton
        title={isCheckboxesVisible ? t('common.close') : t('statements.select')}
        buttonClass="send-data"
        handleClick={toggleSelectStatements}
      />
    );
  }

  return null;
};

export default StatementsSendMailButton;
