import {
  SHOW_MFA_FIELD,
  REGISTRATION_ACCOUNT_PORTFOLIO,
  CLOSE_CONNECT_SCREEN,
  SHOW_CONNECT_SCREEN,
  GET_WITHDRAWAL_ACCOUNT_SUCCESS,
  GET_FUNDING_PAYMENT_CARD_SUCCESS,
  WAITING_IN_PROCESS,
  SKIP_BANK,
  SHOW_ERROR_POPUP_PAYMENT_CARD,
  RESPONSE_FROM_BANK_SUCCESS,
  SHOW_WAIT_POPUP_BANK_LOGIN,
  GET_FUNDING_ACCOUNT_SUCCESS,
  SHOW_ERROR_POPUP_BANK_LOGIN,
  BACK_FROM_MORE_BANKS,
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  GET_BANKS_SUCCESS,
  GET_MORE_BANKS,
  CLOSE_MODAL,
  RESET_PROPS,
  REGISTRATION_PERSONAL_INFO,
} from '../commonTypes';
import { MY_APP } from '../../constants/localeConfigs';
import { HOME } from '../user/consts';

import {
  CONNECT_ACCOUNT,
  SKIP_BANK_LOGIN,
  SHOW_DIFFERENT_BANK,
  STOP_BANK_LOGIN_REQUEST,
  GET_INSTITUTIONS_SUCCESS,
  ADD_PAYMENT_CARD_SUCCESS,
  GET_SPENDING_PAYMENT_CARD_SUCCESS,
  SHOW_ERROR_SWITHC_PAYMENT_CARD,
  SWITHC_CARD_OTHER_ACCOUNT_SUCCESS,
  SHOW_ERROR_UNLINK_CARD,
  WATING_BANKS,
  FUNDING_PAGE,
  SHOW_WITHDRAWAL_ERROR,
  FUNDING_SOURCE_ERROR,
  SHOW_WITHDRAWAL,
  RESET_MFA_FIELD,
  SHOW_FUNDING_SECURITY_MODAL,
  GET_VISA_PAYMENT_CARD_SUCCESS,
  GET_BANK_INSTITUTIONS_SUCCESS,
  REFRESH_ROUNDUP,
  REFRESH_ROUNDUP_SUCCESS,
  SET_IS_CONNECTION_STEP,
  SET_CONNECTION_STATUS_DATA,
  SET_BANK_ACCOUNT_DATA,
  SET_CURRENT_BANK,
  SET_FASTLINK_FLOW,
  SET_IS_SHOW_CONNECT_MODAL,
  SET_IS_SHOW_CONNECT_STEP,
  SET_IS_LINK_CARD_LOADING,
} from './consts';

import { checkIsDependentUserRegistration } from 'utils/user';
import { FASTLINK_FLOWS } from 'components/modals/Fastlink/consts';

const initialState = {
  credentials: {
    bsb: '',
    LOGIN: '',
    PASSWORD: '',
    accountNumber: '',
    accountNumberConfirm: '',
  },
  isShowBanks: false,
  isShowConnect: false,
  skipBankLogin: false,
  monitoredInstitutions: [],
  isBankAccountLinkingStep: false,
  connectionStatusData: {
    status: '',
    message: '',
  },
  fastlinkFlow: FASTLINK_FLOWS.add,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function user(state = initialState, action) {
  let currentStep = '';
  // let banks = [];
  let monitoredInstitutions = [];
  const isKidsSign = checkIsDependentUserRegistration();

  switch (action.type) {
    case GET_FUNDING_ACCOUNT_SUCCESS:
      if (action.isRegistration) {
        currentStep = REGISTRATION_PERSONAL_INFO;

        if (action.isForLinkFunding) {
          currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;
        }

        return {
          ...state,
          isShowBanks: false,
          isShowDifferentBankModal: false,
          isShowWithdrawalSignIn: false,
          errorMessage: action.message,
          accountData: action.fundingAccount,
          showLoader: false,
          showLoaderFunding: false,
          isShowBankModalError: false,
          isForLinkFunding: false,
          isWithdrawal: false,
          currentStep,
        };
      }

      currentStep = FUNDING_PAGE;

      return {
        ...state,
        isShowBanks: false,
        monitoredInstitutions: [],
        isShowDifferentBankModal: false,
        isShowWithdrawalSignIn: false,
        errorMessage: action.message,
        accountData: action.fundingAccount,
        showLoader: false,
        showLoaderFunding: false,
        isShowConnectModal: false,
        currentStep,
      };

    case GET_WITHDRAWAL_ACCOUNT_SUCCESS:
      if (action.isRegistration) {
        currentStep = REGISTRATION_PERSONAL_INFO;
      }

      if (action.isFromMyKad) {
        currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
      }

      return {
        ...state,
        isShowBanks: false,
        monitoredInstitutions: [],
        isShowDifferentBankModal: false,
        errorMessage: action.message,
        withdrawalAccount: action.fundingAccount,
        accountData: action.fundingAccount,
        showLoader: false,
        showLoaderFunding: false,
        isShowWithdrawalSignIn: false,
        currentStep,
      };
    case GET_BANKS_SUCCESS:
      if (!MY_APP) {
        ({ monitoredInstitutions } = action.monitoredInstitutions);
      }

      return {
        ...state,
        isShowBanks: true,
        banks: action.banks,
        skipBankLogin: false,
        errorMessage: action.message,
        showLoader: false,
        showLoaderFunding: false,
        monitoredInstitutions,
      };
    case SHOW_WAIT_POPUP_BANK_LOGIN:
      return {
        ...state,
        isShowBankModalAlert: action.isShowBankModalAlert,
        showLoaderFunding: false,
      };
    case WAITING_IN_PROCESS:
      return {
        ...state,
        isShowBankModalAlert: false,
        showLoaderFunding: true,
      };
    case SKIP_BANK_LOGIN:
      return {
        ...state,
        skipBankLogin: true,
      };
    case SHOW_ERROR_POPUP_BANK_LOGIN:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isShowBankModalError: true,
        isShowConfirmationConnect: false,
      };
    case SHOW_ERROR_UNLINK_CARD:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        // isShowCreditCardError: true,
        isShowLinkCardError: true,
        showLoaderFunding: false,
      };
    case SHOW_ERROR_POPUP_PAYMENT_CARD:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isShowAddCardModalError: true,
        showLoaderFunding: false,
      };
    case STOP_BANK_LOGIN_REQUEST:
      return {
        ...state,
        errorMessage: '',
        showLoaderFunding: false,
        isShowBankModalAlert: false,
        isShowCreditCardError: false,
      };
    case SHOW_ERROR_SWITHC_PAYMENT_CARD:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isShowCreditCardError: true,
      };
    case RESPONSE_FROM_BANK_SUCCESS:
      if (action.isRegistration) {
        currentStep = REGISTRATION_PERSONAL_INFO;

        if (isKidsSign) {
          currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
        } else if (action.isSpending) {
          currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;
        }

        if (action.isKidsRegistrationPortfolio) {
          currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
        } else if (action.isKidsRegistrationHome) {
          currentStep = HOME;
        }

        return {
          ...state,
          isShowBankModal: false,
          isShowBankModalError: false,
          errorMessage: '',
          isShowBanks: false,
          skipBankLogin: true,
          isShowConnect: true,
          isShowMoreBanks: false,
          isShowBankModalAlert: false,
          monitoredInstitutions: action.newMonitoredInstitution,
          showLoader: false,
          currentStep,
        };
      }

      return {
        ...state,
        errorMessage: '',
        isShowBanks: false,
        skipBankLogin: true,
        isShowConnect: true,
        isShowMoreBanks: false,
        isShowBankModal: false,
        isShowBankModalAlert: false,
        monitoredInstitutions: action.newMonitoredInstitution,
      };
    case SHOW_CONNECT_SCREEN:
      currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;

      return {
        ...state,
        isShowBankModal: false,
        isShowBankModalError: false,
        errorMessage: '',
        isShowBanks: false,
        skipBankLogin: true,
        isShowConnect: true,
        isShowMoreBanks: false,
        isShowBankModalAlert: false,
        monitoredInstitutions: action.newMonitoredInstitution,
        isShowConnectModal: action.isShowConnectModal,
        isSpending: action.isSpending,
        isReconectForFunding: action.isReconectForFunding,
        showLoaderFunding: false,
        showMainLoader: false,
        currentStep,
      };
    case CONNECT_ACCOUNT:
      return {
        ...state,
        errorMessage: '',
        isShowBanks: false,
        isShowConnect: false,
      };
    case RESET_PROPS:
      return {
        ...state,
        isSpending: false,
        isShowBanks: false,
        isShowMoreBanks: false,
        monitoredInstitutions: [],
        accountData: {},
        moreBanks: [],
        showLoaderFunding: false,
        showLoader: false,
        isShowConnectModal: false,
        isShowAddCardModalError: false,
      };
    case GET_INSTITUTIONS_SUCCESS:
      if (action.message) {
        return {
          ...state,
          skipBankLogin: false,
          errorMessage: action.message,
          monitoredInstitutions: action.monitoredInstitutions,
          isReconectForFunding: action.isReconectForFunding,
        };
      }

      return {
        ...state,
        skipBankLogin: false,
        monitoredInstitutions: action.monitoredInstitutions,
        isReconectForFunding: action.isReconectForFunding,
        isShowBanks: false,
      };
    case SHOW_DIFFERENT_BANK:
      return {
        ...state,
        isShowDifferentBankModal: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isShowDifferentBankModal: false,
        isShowBanks: false,
        isFundingErrorVisible: false,
        errorMessage: '',
        fundingStatusCode: 200,
        isShowFundingSecurityModal: false,
        messageFunding: '',
        isShowAddCardModalError: false,
      };
    case GET_MORE_BANKS:
      return {
        ...state,
        isShowBanks: false,
        isShowMoreBanks: !action.isRegistration,
        moreBanks: action.banks,
        addListener: action.addListener,
        isWaitingUpdate: false,
        showLoaderFunding: false,
      };
    case WATING_BANKS:
      return {
        ...state,
        isWaitingUpdate: true,
      };
    case BACK_FROM_MORE_BANKS:
      return {
        ...state,
        isShowMoreBanks: false,
      };
    case SKIP_BANK:
      return {
        ...state,
        isShowMoreBanks: false,
        isShowConnectModal: false,
        showLoader: false,
        showLoaderFunding: false,
        isForBankLinkFunding: false,
      };
    case CLOSE_CONNECT_SCREEN:
      return {
        ...state,
        isShowMoreBanks: false,
        isShowConnectModal: false,
        showLoader: false,
        showLoaderFunding: false,
        isForBankLinkFunding: false,
        showMainLoader: false,
        isShowConnect: false,
        isShowBankModal: false,
      };
    case ADD_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        paymentCard: action.paymentCard,
      };
    case GET_FUNDING_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        fundingPaymentCards: action.fundingPaymentCards,
      };
    case GET_SPENDING_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        spendingPaymentCards: action.spendingPaymentCards,
        isForBankLinkFunding: action.isForBankLinkFunding,
      };
    case SWITHC_CARD_OTHER_ACCOUNT_SUCCESS:
      return {
        ...state,
        switchCardSuccess: true,
        isFromLinkFunding: action.isFromLinkFunding,
      };
    case SHOW_WITHDRAWAL_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case FUNDING_SOURCE_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        fundingStatusCode: action.fundingStatusCode,
        isFundingErrorVisible: true,
      };
    case SHOW_WITHDRAWAL:
      return {
        ...state,
        isSpendingWithdrawal: action.isSpendingWithdrawal,
      };
    case SHOW_MFA_FIELD:
      return {
        ...state,
        isShowMfaField: true,
        showLoader: false,
        showLoaderFunding: false,
        otpData: action.otpData,
      };
    case RESET_MFA_FIELD:
      return {
        ...state,
        isShowMfaField: false,
        otpData: [],
      };
    case SHOW_FUNDING_SECURITY_MODAL:
      return {
        ...state,
        isShowFundingSecurityModal: true,
        isFundingErrorVisible: false,
        messageFunding: action.messages,
      };
    case GET_VISA_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        visaPaymentCards: action.visaPaymentCards,
      };
    case REFRESH_ROUNDUP:
      return {
        ...state,
        refreshSessionId: action.refreshSessionId,
        roundUpRefreshHint: action.roundUpRefreshHint,
        isRoundUpRefreshing: true,
      };
    case REFRESH_ROUNDUP_SUCCESS:
      return {
        ...state,
        showLoader: false,
        isRoundUpRefreshing: false,
      };
    case GET_BANK_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        banksInstitutions: action.banksInstitutions,
      };
    // TODO: https://acornsau.atlassian.net/browse/RAIZ-6458
    case SET_IS_CONNECTION_STEP:
      return {
        ...state,
        isBankAccountLinkingStep: action.isBankAccountLinkingStep,
      };
    case SET_CONNECTION_STATUS_DATA:
      return {
        ...state,
        connectionStatusData: action.connectionStatusData,
      };
    case SET_BANK_ACCOUNT_DATA:
      return {
        ...state,
        bankAccountData: action.bankAccountData,
      };
    case SET_CURRENT_BANK:
      return {
        ...state,
        currentBank: action.currentBank,
      };
    case SET_FASTLINK_FLOW:
      return {
        ...state,
        fastlinkFlow: action.fastlinkFlow,
      };
    case SET_IS_SHOW_CONNECT_MODAL:
      return {
        ...state,
        ...action.data,
      };
    case SET_IS_SHOW_CONNECT_STEP:
      return {
        ...state,
        isShowConnect: action.isShowConnect,
      };
    case SET_IS_LINK_CARD_LOADING:
      return {
        ...state,
        isLinkCardLoading: action.isLinkCardLoading,
      };
    default:
      return state;
  }
}
