import styled from '@emotion/styled';

import { FieldWrapper } from '../styles';

import checkBoxUncheckedIcon from 'assets/images/check_2.png';
import checkBoxCheckedIcon from 'assets/images/ic_check_login.png';

export const CheckboxWrapper = styled(FieldWrapper)`
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  appearance: none;
  top: 0;
  left: 0;
  opacity: ${({ theme }) => theme.opacity.opaque};
  &:active {
    box-shadow: ${({ theme }) => theme.shadows.checkbox};
  }
  transition: 0.2s;
`;

export const Label = styled.label`
  flex: 1;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mirage};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.lineHeights.length['2xl']};
`;

export const Check = styled.div<{ checked: boolean; size?: number }>`
  width: ${({ size }) => size || 32}px;
  height: ${({ size }) => size || 32}px;
  position: relative;
  margin-right: 15px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.niagaraLight : 'transparent'};
  background-image: ${({ checked }) =>
    checked ? `url(${checkBoxCheckedIcon})` : `url(${checkBoxUncheckedIcon})`};
  border-radius: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ checked }) => (checked ? '50% 37.5%' : '100% 100%')};
  transition: 0.2s;
`;

export const ErrorMessage = styled.span`
  align-self: center;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: ${({ theme }) => theme.lineHeights.length['4xl']};
  font-size: ${({ theme }) => theme.fontSizes.md};
  position: absolute;
  bottom: 0;
`;
