import styled from '@emotion/styled';

import { WidgetContent, WidgetHeader } from '../../styles';

export const MyFinanceWidgetWrapper = styled(WidgetContent)`
  background-image: ${({ theme }) => theme.gradients.dodgerBlue};
`;

export const MyFinanceHeader = styled(WidgetHeader)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
`;

export const MyFinanceBanner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 24px;
`;

export const MyFinanceBannerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  padding: 8px 16px 8px 16px;
  color: ${({ theme }) => theme.colors.whiteTransparentLight};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes['2sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['2sm']};
  margin: 0 6px;
  :first-of-type {
    border-radius: 7px 7px 0 0;
  }
  :last-of-type {
    border-radius: 0 0 7px 7px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
   margin: 0;
    background-color: ${theme.colors.whiteTransparentMiddle};
    color: ${theme.colors.white};
    font-family:  ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['2sm']};
    padding: 10px 16px;
  
  `}
`;

export const MyFinanceBannerItemTitle = styled.div`
  width: 50%;
`;
