import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../store/hooks/useAppSelector';
import { selectPortfolioParams } from '../store/portfolio/selectors';

export const ACTIVE_TAB_QUERY_PARAM = 'activeTab';

export const useActiveTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get(ACTIVE_TAB_QUERY_PARAM);
  const { childId, isSuper } = useAppSelector(selectPortfolioParams);

  const setActiveTab = useCallback(
    (newActiveTab) => {
      setSearchParams(
        {
          ...(childId && { childId }),
          ...(isSuper && { isSuper }),
          activeTab: newActiveTab,
        },
        { replace: true },
      );
    },
    [childId, setSearchParams, isSuper],
  );

  const checkIsActiveTab = useCallback(
    (tab) => searchParams.get(ACTIVE_TAB_QUERY_PARAM) === tab,
    [searchParams],
  );

  return { activeTab, setActiveTab, checkIsActiveTab };
};
