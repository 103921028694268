import { FunctionComponent, SVGAttributes } from 'react';

import { Icon } from '../Icon';
import { IIconSet } from '../Icon/iconset';

import { Button } from './styles';

interface IIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: number;
  iconName?: IIconSet;
  icon?: FunctionComponent<SVGAttributes<SVGElement>>;
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  CustomButton?: typeof Button;
}

export const IconButton = ({
  size,
  iconName,
  icon,
  onClick,
  isDisabled,
  type = 'button',
  CustomButton = Button,
}: IIconButtonProps) => (
  <CustomButton size={size} onClick={onClick} disabled={isDisabled} type={type}>
    <Icon size={size} iconName={iconName} icon={icon} />
  </CustomButton>
);
