import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import {
  ConnectionStatusButton,
  ConnectionStatusContent,
  ConnectionStatusDescription,
  ConnectionStatusFooter,
  ConnectionStatusIconWrapper,
  ConnectionStatusItem,
  ConnectionStatusLine,
  ConnectionStatusName,
  ConnectionStatusTitle,
  ConnectionStatusWrapper,
} from './styles';
import BankAccountConnectionStatusIcon from './components/BankAccountConnectionStatusIcon';
import {
  useBankAccountLinkingStatus,
  useLinkAccountLaterModalConfig,
} from './hooks';
import { IBankAccountConnectionStatusProps } from './types';
import { CONNECTIONS_STATUS_PATHS } from './consts';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setIsBankAccountLinkingStep } from 'store/fundingAccount/actions';
import { toggleInfoModal } from 'store/modals/actions';

const BankAccountConnectionStatus = ({
  linkAccountLater,
  onUnmount,
  handleStopBankAccountLinking,
  isSpending,
  isRegistration,
}: IBankAccountConnectionStatusProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { indexOfCurrentState, institutionsLinkStates, isLinked, title } =
    useBankAccountLinkingStatus({
      onUnmount,
      isSpending,
      isRegistration,
    });

  const config = useLinkAccountLaterModalConfig({ linkAccountLater });

  const descriptionParam = useMemo(() => {
    if (
      pathname.includes(CONNECTIONS_STATUS_PATHS.fundingAccount.registration) ||
      pathname.includes(CONNECTIONS_STATUS_PATHS.fundingAccount.inApp)
    )
      return t('registrationPage.bankAccountConnectionStatus.fundingAccount');

    return t('registrationPage.bankAccountConnectionStatus.roundUpAccount');
  }, [pathname, t]);

  const goBack = () => {
    handleStopBankAccountLinking();

    dispatch(setIsBankAccountLinkingStep(false));
  };

  return (
    <ConnectionStatusWrapper>
      <ConnectionStatusTitle>{title}</ConnectionStatusTitle>

      <ConnectionStatusDescription>
        {t('registrationPage.bankAccountConnectionStatus.description', {
          page: descriptionParam,
        })}
      </ConnectionStatusDescription>

      <ConnectionStatusContent>
        {institutionsLinkStates?.map(({ message, state }, index) => (
          <ConnectionStatusItem key={state}>
            <ConnectionStatusIconWrapper>
              <BankAccountConnectionStatusIcon
                indexOfCurrentState={indexOfCurrentState}
                index={index}
                isLinked={isLinked}
              />

              <ConnectionStatusName>{message}</ConnectionStatusName>
            </ConnectionStatusIconWrapper>

            {institutionsLinkStates.length !== index + 1 && (
              <ConnectionStatusLine />
            )}
          </ConnectionStatusItem>
        ))}
      </ConnectionStatusContent>

      <ConnectionStatusFooter>
        <ConnectionStatusButton
          className="-exclude"
          label={t('common.back')}
          onClick={goBack}
          variant="initial"
        />

        <ConnectionStatusButton
          className="-exclude"
          label={t('registrationPage.bankAccountConnectionStatus.button')}
          onClick={() => dispatch(toggleInfoModal(config))}
          variant="initial"
        />
      </ConnectionStatusFooter>
    </ConnectionStatusWrapper>
  );
};

export default BankAccountConnectionStatus;
