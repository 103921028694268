import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';
import Loader from '../../../components/elements/Loader/Loader';
import Time from '../../../components/elements/Time/Time';
import { REWARDS_HISTORY_STATUSES } from '../consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function isNeedDecimalScale(n) {
  return n % 1 !== 0;
}

function historyTransactionRender(
  transaction,
  date,
  isInvested = false,
  isPending = false,
) {
  if (
    (isInvested &&
      transaction.groupedStatus !== REWARDS_HISTORY_STATUSES.invested) ||
    (isPending &&
      transaction.groupedStatus !== REWARDS_HISTORY_STATUSES.pending)
  ) {
    return <div />;
  }

  let dateBlock = '';

  if (date) {
    dateBlock = (
      <div className="rewards__history-item-date">
        <Time format="D MMMM YYYY">{date}</Time>
      </div>
    );
  }

  return (
    <div
      key={
        isInvested
          ? `historyInvestedItem${transaction.id}`
          : `historyPendingItem${transaction.id}`
      }
    >
      {dateBlock}

      <div className="rewards__history-item">
        <div className="rewards__history-icon-placeholder">
          <div
            className="rewards__history-icon"
            style={{
              background: `url(${transaction.imageUrl}) no-repeat`,
              backgroundSize: 'contain',
            }}
          />
        </div>

        <div className="rewards__history-content">
          <div className="rewards__history-name">
            {transaction.campaignName}
          </div>

          <div className="rewards__history-progress">
            <div>
              {transaction.groupedStatus ===
              REWARDS_HISTORY_STATUSES.invested ? (
                <>
                  <div className="rewards__history-invested" />

                  <div className="rewards__history-pending" />
                </>
              ) : (
                <>
                  <div className="rewards__history-pending" />

                  <div className="rewards__history-dot" />
                </>
              )}

              <div className="rewards__history-status">
                <span>{transaction.groupedStatus}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="rewards__history-amount">
          <NumberFormat
            value={
              transaction.amount < 0
                ? Math.abs(transaction.amount)
                : transaction.amount
            }
            displayType="text"
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            fixedDecimalScale={isNeedDecimalScale(transaction.amount)}
            prefix={CURRENCY_SYMBOL}
            decimalScale={2}
            className={transaction.amount < 0 ? 'negative' : ''}
          />

          <div className="purchase">
            <NumberFormat
              value={transaction.purchaseAmount}
              displayType="text"
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              fixedDecimalScale={isNeedDecimalScale(transaction.purchaseAmount)}
              prefix={CURRENCY_SYMBOL}
              decimalScale={2}
            />

            {', '}

            <Time format="D MMMM YYYY">{transaction.created}</Time>
          </div>
        </div>
      </div>
    </div>
  );
}

function isHaveTransaction(
  transactions,
  isInvested = false,
  isPending = false,
) {
  let isEmpty = true;

  transactions.map((transaction) => {
    if (
      isInvested &&
      transaction.groupedStatus === REWARDS_HISTORY_STATUSES.invested
    ) {
      isEmpty = false;
    }

    if (
      isPending &&
      transaction.groupedStatus === REWARDS_HISTORY_STATUSES.pending
    ) {
      isEmpty = false;
    }

    return true;
  });

  return isEmpty;
}

export default function RewardsHistoryView({
  isAll,
  isInvested,
  isPending,
  toggleHistory,
  cashback,
  showMainLoader,
}) {
  let previousDate = '';

  const { t } = useTranslation();

  return (
    <div className="new-rewards">
      <div className="rewards__history">
        <div className="profile-content settings__content-default-container">
          <div className="profile-content__header -with-navigation ">
            <div className="profile-content__title">
              {t('newRewardsPage.rewardsHistory.title')}
            </div>

            <div className="profile-content__navigation">
              <DeprecatedButton
                title={t('common.all')}
                buttonClass={isAll ? 'active' : ''}
                handleClick={() => toggleHistory(true)}
              />

              <DeprecatedButton
                title={t('newRewardsPage.rewardsHistory.invested')}
                buttonClass={isInvested ? 'active' : ''}
                handleClick={() => toggleHistory(false, true)}
              />

              <DeprecatedButton
                title={t('common.status.pending')}
                buttonClass={isPending ? 'active' : ''}
                handleClick={() => toggleHistory(false, false, true)}
              />
            </div>
          </div>

          {isAll && (
            <div className="profile-content__container">
              {cashback &&
                cashback.transactions &&
                cashback.transactions !== 0 &&
                cashback.transactions.map((transaction, key) => {
                  let date = '';

                  const currentDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  if (
                    cashback.transactions[key + 1] &&
                    cashback.transactions[key + 1].created &&
                    previousDate !== transaction.created &&
                    (currentDate !==
                      dayjs(cashback.transactions[key + 1].created).format(
                        'MM/DD/YYYY',
                      ) ||
                      key === 0)
                  ) {
                    if (currentDate !== previousDate) {
                      date = transaction.created;
                    }
                  }

                  previousDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  return historyTransactionRender(transaction, date);
                })}
            </div>
          )}

          {isInvested && (
            <div className="profile-content__container">
              {cashback &&
                cashback.transactions &&
                cashback.transactions !== 0 &&
                cashback.transactions.map((transaction, key) => {
                  let date = '';

                  const currentDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  if (
                    cashback.transactions[key + 1] &&
                    cashback.transactions[key + 1].created &&
                    previousDate !== transaction.created &&
                    (currentDate !==
                      dayjs(cashback.transactions[key + 1].created).format(
                        'MM/DD/YYYY',
                      ) ||
                      key === 0)
                  ) {
                    if (currentDate !== previousDate) {
                      date = transaction.created;
                    }
                  }

                  previousDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  return historyTransactionRender(transaction, date, true);
                })}
            </div>
          )}

          {isPending && (
            <div className="profile-content__container">
              {cashback &&
                cashback.transactions &&
                cashback.transactions.length !== 0 &&
                cashback.transactions.map((transaction, key) => {
                  let date = '';

                  const currentDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  if (
                    cashback.transactions[key + 1] &&
                    cashback.transactions[key + 1].created &&
                    previousDate !== transaction.created &&
                    (currentDate !==
                      dayjs(cashback.transactions[key + 1].created).format(
                        'MM/DD/YYYY',
                      ) ||
                      key === 0)
                  ) {
                    if (currentDate !== previousDate) {
                      date = transaction.created;
                    }
                  }

                  previousDate = dayjs(transaction.created).format(
                    'MM/DD/YYYY',
                  );

                  return historyTransactionRender(
                    transaction,
                    date,
                    false,
                    true,
                  );
                })}

              {showMainLoader && <Loader additionalClass="-absolute -top" />}
            </div>
          )}
        </div>

        {!showMainLoader &&
          cashback &&
          cashback.transactions &&
          ((isHaveTransaction(cashback.transactions, isInvested, isPending) &&
            !isAll) ||
            cashback.transactions.length === 0) && (
            <div className="found-block">
              <div className="icon-not-found" />

              <div className="not-found">
                <div>{t('newRewardsPage.rewardsHistory.noRewards')}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

RewardsHistoryView.propTypes = {
  isAll: PropTypes.bool.isRequired,
  isInvested: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  toggleHistory: PropTypes.func.isRequired,
  cashback: PropTypes.shape({
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        created: PropTypes.string,
      }),
    ),
  }).isRequired,
  showMainLoader: PropTypes.bool.isRequired,
};
