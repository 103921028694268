import {
  KidCardOpenedInfo,
  KidCardOpenedLabel,
  KidCardOpenedValue,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

interface IKidCardInfoItemProps {
  label: string;
  value: number;
}

export const KidCardInfoItem = ({ label, value }: IKidCardInfoItemProps) => (
  <KidCardOpenedInfo>
    <KidCardOpenedLabel>{label}</KidCardOpenedLabel>

    <KidCardOpenedValue>
      {formatNumber({
        value,
      })}
    </KidCardOpenedValue>
  </KidCardOpenedInfo>
);
