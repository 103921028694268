import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { BankSearchItem } from './BankSearchItem';

import FallbackMessage from 'components/elements/FallbackMessage/FallbackMessage';

export default function BanksSearch({
  banks,
  handleClick,
  isRegistration,
  isFunding,
  clearSearch,
  searchStart,
  searchValue,
  changeSearchValue,
  showLoader,
}) {
  const { t } = useTranslation();

  useEffect(
    () => () => {
      changeSearchValue(null);
    },
    [changeSearchValue],
  );

  return (
    <div id="bankSearch" className="settings__spending-account">
      <div className="profile-content settings__content-default-container">
        {!isRegistration && (
          <div className="profile-content__header">
            <div className="profile-content__title">
              {isFunding
                ? t('settingsPage.linkFundingAccount')
                : t('settingsPage.banksSearch.spendingAccountTitle')}
            </div>
          </div>
        )}

        <div className="profile-content__container">
          <div className="profile-content__container-item settings__spending-account-info -without-opacity">
            <div className="form-field grid__input">
              <input
                onChange={(e) => changeSearchValue(e.target.value)}
                id="search-bank"
                type="text"
                placeholder={t('settingsPage.banksSearch.searchMore')}
                value={searchValue}
              />
            </div>

            <div
              className="remove"
              onClick={clearSearch}
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
            />
          </div>
        </div>
      </div>

      {banks.length === 0 && searchValue && !showLoader ? (
        <FallbackMessage
          text={t('settingsPage.banksSearch.fallback', {
            bankName: searchValue,
          })}
        />
      ) : (
        <div className="settings__spending-account-comment">
          {!isRegistration && t('settingsPage.banksSearch.bankSelectionTitle')}

          {isRegistration && (banks.length !== 0 || searchStart) && (
            <div>
              {t('settingsPage.banksSearch.results', {
                count: banks.length,
              })}
            </div>
          )}
        </div>
      )}

      {banks && (banks.length !== 0 || searchStart) && (
        <div className="grid__row-container">
          {banks.map((bank) => (
            <BankSearchItem
              key={bank.institution_id}
              bank={bank}
              handleClick={handleClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}

BanksSearch.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClick: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool,
  isFunding: PropTypes.bool,
  clearSearch: PropTypes.func,
  searchStart: PropTypes.bool,
  searchValue: PropTypes.string,
  changeSearchValue: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
};

BanksSearch.defaultProps = {
  isRegistration: false,
  isFunding: false,
  searchStart: false,
  clearSearch: () => {},
  searchValue: '',
};
