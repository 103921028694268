import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { MIN_SELECTED_VALUES, PHONE_NUMBER_REGEX } from 'constants/validation';

export const useDetailsSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    first_name: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.personal.firstName'),
      }),
    ),
    last_name: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.personal.lastName'),
      }),
    ),
    phone_number: yup
      .string()
      .matches(
        PHONE_NUMBER_REGEX,
        t('fields.validation.invalidField', {
          fieldName: t('fields.phoneNumber.label'),
        }),
      )
      .required(
        t('fields.validation.requiredField', {
          fieldName: t('fields.phoneNumber.label'),
        }),
      ),
    dob: yup.date().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.personal.dateOfBirth'),
      }),
    ),
    us_resident: yup.boolean(),
    question1: yup
      .array()
      .min(
        MIN_SELECTED_VALUES,
        t('fields.validation.requiredField', {
          fieldName: t('registrationPage.personal.securityQuestion'),
        }),
      )
      .required(
        t('fields.validation.requiredField', {
          fieldName: t('registrationPage.personal.securityQuestion'),
        }),
      ),
    answer1: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.personal.securityQuestionAnswer'),
      }),
    ),
  });
};
