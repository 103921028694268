/* eslint-disable react/jsx-newline */
import React, { useMemo, useEffect, useState } from 'react';
import '../../styles/pages/perfomance-details.scss';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Chart from 'react-apexcharts';
import NumberFormat from 'react-number-format';
import ReactDOMServer from 'react-dom/server';

import DeprecatedDotsLoader from '../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import {
  GET_PRESENT_SUCCESS,
  GET_SUMMARY_SUCCESS,
  GET_ALLOCATION_PROFILES_SUCCESS,
} from '../../store/perfomance/types';
import PerfomanceErrorModal from '../../components/layout/Modals/PerfomanceErrorModal';

import { ETF_TYPES, PERIOD_DAYS_NUMBER } from './consts';
import PerfomanceSwitcher from './components/PerfomanceSwitcher';
import { getMarketChange } from './utils';
import { PerfomanceListWrapper } from './styles';
import { ChangeValue } from './components/ChangeValue';
import { MarketValue } from './components/MarketValue';
import { PerfomanceInfo } from './components/PerfomanceInfo';
import { Tooltip } from './components/Tooltip';
import { AccountValue, SharePrice } from './components/styles';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  MY_APP,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';
import { selectPerformanceBalanceByIndex } from 'store/perfomance/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { NUMBER_FORMATS } from 'utils/formatters/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function PerfomanceDetailsView({
  toggleShow,
  periodDays,
  chartData,
  accountValue,
  periodMarketChange,
  periodMarketChangePercent,
  chartDataPresent,
  allocationProfiles,
  toggleEtfShow,
  etfType,
  showSecurities,
  securitiesPricesChartData,
  periodMarketChangeEtf,
  periodMarketChangePercentEtf,
  dailyMarketChange,
  dailyMarketPercent,
  etfName,
  isEtf,
  securitiesFundamentals,
  back,
  isShowErrorModal,
  errorMessage,
  handleCloseModal,
  showModal,
  isDashboard,
  type,
  status,
  previousCloseAmount,
  dividends,
  widthResize,
  resizeUpdate,
  heightResize,
  isSetResize,
  isShowLoader,
  chartDataAdditional,
  isSuper,
  activeAllocationProfile,
  etfIndex,
}) {
  const { t } = useTranslation();

  const [dataPointIndex, setDataPointIndex] = useState(-1);

  function renderInfo(info, index) {
    return (
      <div
        role="button"
        tabIndex="0"
        key={info.name}
        className="perfomance__list-item"
        onClick={() =>
          showSecurities(info.id, info.etf_name, info.share_price, info, index)
        }
        onKeyPress={() => {}}
      >
        <div>{info.name}</div>

        <div className="perfomance__list-value">
          {etfType === ETF_TYPES.dollar &&
            formatNumber({
              value: info.amount,
              minimumFractionDigits: 2,
            })}

          {etfType === ETF_TYPES.percentage &&
            formatNumber({
              value: info.percentage_position,
              type: NUMBER_FORMATS.percent,
            })}

          {etfType === ETF_TYPES.shares && info.shares}
        </div>
      </div>
    );
  }

  function renderFundamentals(fundamentals) {
    return (
      <div>
        <div className="perfomance__list-item">
          <div>{t('performanceDetailsPage.sharesOwned')}</div>

          <div className="perfomance__list-value">
            <NumberFormat
              value={
                activeAllocationProfile && activeAllocationProfile.shares
                  ? activeAllocationProfile.shares
                  : 0
              }
              displayType="text"
            />
          </div>
        </div>

        <div className="perfomance__list-item">
          <div>
            <div>{t('performanceDetailsPage.holdingValue')}</div>
          </div>

          <div className="perfomance__list-value">
            <NumberFormat
              value={
                activeAllocationProfile && activeAllocationProfile.amount
                  ? activeAllocationProfile.amount
                  : 0
              }
              displayType="text"
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              prefix={CURRENCY_SYMBOL}
              decimalScale={MY_APP ? 4 : 2}
            />
          </div>
        </div>

        {!MY_APP && (
          <div className="perfomance__list-item">
            <div>{t('performanceDetailsPage.openingPrice')}</div>

            <div className="perfomance__list-value">
              <NumberFormat
                value={fundamentals.prices.open}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                prefix={CURRENCY_SYMBOL}
                decimalScale={MY_APP ? 4 : 2}
              />
            </div>
          </div>
        )}

        {MY_APP && (
          <div className="perfomance__list-item">
            <div>{t('performanceDetailsPage.todaysPrice')}</div>

            <div className="perfomance__list-value">
              <NumberFormat
                value={fundamentals.prices.current}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                prefix={CURRENCY_SYMBOL}
                decimalScale={MY_APP ? 4 : 2}
              />
            </div>
          </div>
        )}

        {MY_APP &&
          fundamentals &&
          fundamentals.prices &&
          fundamentals.prices.yesterday && (
            <div className="perfomance__list-item">
              <div>{t('performanceDetailsPage.yesterdaysPrice')}</div>

              <div className="perfomance__list-value">
                <NumberFormat
                  value={fundamentals.prices.yesterday}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  prefix={CURRENCY_SYMBOL}
                  decimalScale={MY_APP ? 4 : 2}
                />
              </div>
            </div>
          )}

        {!MY_APP && (
          <div className="perfomance__list-item">
            <div>{t('performanceDetailsPage.previousClosingPrice')}</div>

            <div className="perfomance__list-value">
              {formatNumber({
                value: fundamentals.prices.previous_close,
              })}
            </div>
          </div>
        )}

        {!MY_APP && (
          <div className="perfomance__list-item">
            <div>{t('performanceDetailsPage.wkRange')}</div>

            <div className="perfomance__list-value">
              {t('performanceDetailsPage.wkRangeValue', {
                value: fundamentals.wk_range,
                currency: CURRENCY_SYMBOL,
              })}
            </div>
          </div>
        )}

        {!MY_APP && (
          <div className="perfomance__list-item">
            <div>{t('performanceDetailsPage.volume')}</div>

            <div className="perfomance__list-value">
              {formatNumber({
                value: fundamentals.volume,
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderEtfModal(allocationProfile) {
    return (
      <div key={allocationProfile.id} className="info-panel__list-item">
        <div className="my-finance__spend-icons">
          <div>{allocationProfile.symbol}</div>
        </div>

        <div className="account-val">
          <Trans
            t={t}
            i18nKey="performanceDetailsPage.accountValueEtf"
            components={{
              shares: allocationProfile.shares,
              sharePrice: formatNumber({
                value: allocationProfile.share_price,
                minimumFractionDigits: 2,
              }),
              amount: formatNumber({
                value: allocationProfile.amount,
                minimumFractionDigits: 2,
              }),
              span: <span className="bold" />,
            }}
          />
        </div>
      </div>
    );
  }

  const dataSeries1 = useMemo(
    () => ((chartDataAdditional?.length ?? 0) > 0 ? chartDataAdditional : []),
    [chartDataAdditional],
  );

  const dataSeries2 = useMemo(() => {
    let value = [];

    if (chartData.length !== 0) {
      value = chartData;
    } else if (chartDataPresent.length !== 0) {
      value = chartDataPresent;
    } else if (isEtf && securitiesPricesChartData.length !== 0) {
      value = securitiesPricesChartData;
    }

    return value;
  }, [chartData, chartDataPresent, securitiesPricesChartData, isEtf]);

  let isNeedFixBottom = false;

  if (dataSeries2.length > 0 && isDashboard) {
    if (dataSeries2.slice(-1).pop().y === -1) {
      isNeedFixBottom = true;

      sessionStorage.setItem(SESSION_STORAGE_KEYS.isNeedFixBottom, true);
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.isNeedFixBottom, false);
    }
  }

  const isOneDayChart = periodDays === PERIOD_DAYS_NUMBER.oneDay;
  const isTooltipVisible = !isDashboard && !isOneDayChart && !isEtf;

  const balance = useAppSelector(
    selectPerformanceBalanceByIndex({
      index: dataPointIndex,
      isEtf,
      isOneDayChart,
    }),
  );

  const marketChange = getMarketChange(
    periodMarketChange,
    periodMarketChangeEtf,
    isOneDayChart,
    isEtf,
    dailyMarketChange,
  );

  const marketPercent = getMarketChange(
    periodMarketChangePercent,
    periodMarketChangePercentEtf,
    isOneDayChart,
    isEtf,
    dailyMarketPercent,
  );

  let height = '280px';
  let width = '100%';

  if (isDashboard) {
    height = '180px';

    width = '110%';
  }

  const isSingleSeries = useMemo(
    () => isDashboard || isEtf || isOneDayChart,
    [isDashboard, isEtf, isOneDayChart],
  );

  let offsetY = 0;

  if (isSuper && isDashboard) {
    offsetY = 0;
  } else if (isDashboard) {
    offsetY = 50;
  }

  const series = useMemo(
    () =>
      isSingleSeries
        ? [{ name: 'series2', data: dataSeries2.map((datum) => datum.y) }]
        : [
            { name: 'series2', data: dataSeries2 },
            { name: 'series1', data: dataSeries1 },
          ],
    [dataSeries2, dataSeries1, isSingleSeries],
  );

  const chartOptions = useMemo(() => {
    const isNonDashboard = !isDashboard;

    const opt = {
      chart: {
        offsetX: isNeedFixBottom && isDashboard ? 6 : 4,
        offsetY,
        toolbar: {
          show: false,
        },
        events: {
          mouseMove(event, chartContext, currentData) {
            if (isNonDashboard) {
              setDataPointIndex(currentData?.dataPointIndex);
            }
          },
          mouseLeave() {
            if (isNonDashboard) {
              setDataPointIndex(-1);
            }
          },
        },
      },
      grid: {
        show: false,
        xaxis: {
          show: false,
          lines: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          lines: {
            show: false,
          },
        },
        padding: {
          bottom: 20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        onItemClick: {
          toggleDataSeries: true,
        },
        show: false,
        position: 'top',
        markers: {
          width: 0,
        },
      },
      markers: {
        size: [1, 1],
        discrete: dividends,
        enabledOnSeries: isSingleSeries ? [0] : [0, 1],
        hover: {
          size: 5,
        },
      },
      tooltip: {
        followCursor: true,
        shared: true,
        intersect: false,
        marker: {
          show: false,
        },
        items: {
          display: 'inline',
        },
        enabledOnSeries: isSingleSeries ? [0] : [0, 1],
        custom({ series: data, seriesIndex, dataPointIndex: pointIndex, w }) {
          const amount = data?.[seriesIndex]?.[pointIndex];
          const date = w?.globals?.categoryLabels?.[pointIndex];

          return ReactDOMServer.renderToString(
            <Tooltip
              amount={amount}
              date={date}
              isEtf={isEtf}
              isOneDayChart={isOneDayChart}
            />,
          );
        },
      },
      fill: {
        colors: isSingleSeries ? ['#0aad77'] : ['#794bbe', '#0aad77'],
        opacity: [1, 0.15],
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.9,
          gradientToColors: isSingleSeries
            ? ['#5F1493']
            : ['#634de2', '#5F1493'],
          opacityFrom: isSingleSeries ? [0.3] : [1, 0.3],
          opacityTo: [0, 0],
        },
      },
      y: {
        show: false,
      },
      xaxis: {
        type: 'category',
        labels: {
          showDuplicates: true,
          show: false,
        },
        crosshairs: {
          show: true,
          width: 1,
          position: 'front',
          stroke: {
            color: '#fff',
            width: 2,
            dashArray: 0,
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      stroke: {
        drawOnChartArea: false,
        width: 2,
        color: '#24292e',
        opacity: 1,
        dashArray: 99999,
      },
      colors: isSingleSeries ? ['#fff'] : ['#fff', '#0AAD77'],
    };

    if (isSingleSeries) {
      opt.xaxis.categories = dataSeries2.map((datum) => datum.x);
    }

    if (previousCloseAmount !== '' && isOneDayChart) {
      opt.annotations = {
        yaxis: [
          {
            y: previousCloseAmount,
            label: {
              show: true,
              text: t('performanceDetailsPage.previousClose'),
              position: 'left',
              style: {
                color: '#fff',
                background: 'none',
                fontSize: '12px',
                border: '0',
              },
            },
          },
        ],
      };
    }

    return opt;
  }, [
    dataSeries2,
    dividends,
    isDashboard,
    isNeedFixBottom,
    isOneDayChart,
    isSingleSeries,
    t,
    offsetY,
    previousCloseAmount,
    isEtf,
  ]);

  useEffect(() => {
    function handleResize() {
      if (
        heightResize !== window.innerHeight ||
        widthResize !== window.innerWidth ||
        !isSetResize
      ) {
        resizeUpdate(window.innerWidth, window.innerHeight);

        window.addEventListener('resize', handleResize);
      }
    }

    handleResize();
  });

  return (
    <div
      className={
        MY_APP
          ? `perfomance malaysia-perfomance ${
              !dataSeries2.length ? '-loading' : ''
            }`
          : `perfomance ${!dataSeries2.length ? '-loading' : ''}`
      }
    >
      <div className="hide account-previus" />

      {isEtf && !isDashboard && (
        <div
          className="perfomance-arrow-back"
          role="button"
          tabIndex="0"
          onClick={back}
          onKeyPress={() => {}}
        />
      )}

      {isEtf && !isDashboard && (
        <div className="page-content__banner-title">{etfName}</div>
      )}

      {!isEtf && !isDashboard && (
        <div className="page-content__banner-title">
          {t('performanceDetailsPage.performance')}
        </div>
      )}

      {!isDashboard && (
        <PerfomanceSwitcher
          numberOfDays={periodDays}
          isEtf={isEtf}
          toggleShow={toggleShow}
        />
      )}

      {!isDashboard && (
        <div className="page-content__banner-label">
          {isEtf && t('performanceDetailsPage.sharePrice')}

          {!isEtf && t('performanceDetailsPage.accountValue')}
        </div>
      )}

      {!isDashboard &&
        (!isEtf ||
          !allocationProfiles ||
          !allocationProfiles[0] ||
          !allocationProfiles[0].share_price) && (
          <AccountValue onClick={!isEtf ? showModal : null}>
            {balance
              ? formatNumber({
                  value: balance,
                  maximumFractionDigits: 2,
                })
              : accountValue}
          </AccountValue>
        )}

      {isEtf && !isDashboard && (
        <SharePrice>
          {formatNumber({
            value: allocationProfiles?.[etfIndex]?.share_price || 0,
          })}
        </SharePrice>
      )}

      <div className="change-value">
        <ChangeValue
          periodDays={periodDays}
          isDashboard={isDashboard}
          dataPointIndex={dataPointIndex}
        />
        <MarketValue
          marketChange={marketChange}
          marketPercent={marketPercent}
          dataPointIndex={dataPointIndex}
          isDashboard={isDashboard}
          isEtf={isEtf}
        />

        {dataSeries2.length === 0 && !isShowLoader && (
          <div className="no-data">{t('performanceDetailsPage.noData')}</div>
        )}
      </div>

      <div className="page-content__banner-row">
        <div
          id="perfomance-chart"
          className={
            isDashboard
              ? 'perfomance__col-7 dashboard'
              : 'perfomance__col-7 details'
          }
        >
          {(dataSeries2.length ||
            type === GET_SUMMARY_SUCCESS ||
            type === GET_ALLOCATION_PROFILES_SUCCESS ||
            type === GET_PRESENT_SUCCESS) &&
          !isShowLoader ? (
            <div
              className={`chart ${isOneDayChart && 'one-day'} ${
                isEtf && 'pie-etf'
              }`}
            >
              <Chart
                className={isEtf ? 'pie pie-etf' : 'pie'}
                options={chartOptions}
                series={series}
                type="area"
                height={height}
                width={width}
              />
              {isTooltipVisible && (
                <PerfomanceInfo dataPointIndex={dataPointIndex} />
              )}
            </div>
          ) : (
            <DeprecatedDotsLoader />
          )}
        </div>

        {!isEtf && !isDashboard && (
          <div className="perfomance__col-3">
            <div className="home__switcher">
              <div
                role="button"
                tabIndex="0"
                className={
                  etfType === ETF_TYPES.dollar
                    ? 'home__switcher-item -active'
                    : 'home__switcher-item'
                }
                onClick={() => toggleEtfShow('dollar')}
                onKeyPress={() => {}}
              >
                {t('performanceDetailsPage.currency')}
              </div>

              <div
                role="button"
                tabIndex="0"
                className={
                  etfType === ETF_TYPES.percentage
                    ? 'home__switcher-item -active'
                    : 'home__switcher-item'
                }
                onClick={() => toggleEtfShow('percentage')}
                onKeyPress={() => {}}
              >
                {t('performanceDetailsPage.percentage')}
              </div>

              {!MY_APP && (
                <div
                  role="button"
                  tabIndex="0"
                  className={
                    etfType === ETF_TYPES.shares
                      ? 'home__switcher-item -active'
                      : 'home__switcher-item'
                  }
                  onClick={() => toggleEtfShow('shares')}
                  onKeyPress={() => {}}
                >
                  {t('performanceDetailsPage.shares')}
                </div>
              )}
            </div>

            <PerfomanceListWrapper>
              {allocationProfiles &&
                allocationProfiles.length !== 0 &&
                allocationProfiles.map((info, index) =>
                  renderInfo(info, index),
                )}

              {MY_APP &&
                allocationProfiles.length === 0 &&
                t('performanceDetailsPage.noProfiles')}
            </PerfomanceListWrapper>
          </div>
        )}

        {isEtf && !isDashboard && (
          <div className="perfomance__col-3">
            <div className="perfomance__list">
              {securitiesFundamentals &&
                renderFundamentals(securitiesFundamentals)}
            </div>
          </div>
        )}
      </div>

      <div className="perfomance-comment">
        {status.is_open
          ? t('performanceDetailsPage.marketOpen')
          : t('performanceDetailsPage.marketClosed')}
      </div>

      {isShowErrorModal && !isDashboard && (
        <PerfomanceErrorModal
          isShowErrorModal={isShowErrorModal}
          renderEtfModal={(allocationProfile) =>
            renderEtfModal(allocationProfile)
          }
          handleCloseModal={handleCloseModal}
          errorMessage={errorMessage}
          accountValue={accountValue}
          allocationProfiles={allocationProfiles}
        />
      )}
    </div>
  );
}

PerfomanceDetailsView.defaultProps = {
  securitiesFundamentals: null,
  status: {},
  isShowLoader: false,
  type: '',
  activeAllocationProfile: {},
  periodDays: '',
};

PerfomanceDetailsView.propTypes = {
  toggleShow: PropTypes.func.isRequired,
  periodDays: PropTypes.string,
  chartData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountValue: PropTypes.string.isRequired,
  periodMarketChange: PropTypes.string.isRequired,
  periodMarketChangePercent: PropTypes.string.isRequired,
  chartDataPresent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allocationProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      share_price: PropTypes.number,
    }),
  ).isRequired,
  toggleEtfShow: PropTypes.func.isRequired,
  etfType: PropTypes.string.isRequired,
  showSecurities: PropTypes.func.isRequired,
  securitiesPricesChartData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  periodMarketChangeEtf: PropTypes.string.isRequired,
  periodMarketChangePercentEtf: PropTypes.string.isRequired,
  dailyMarketChange: PropTypes.string.isRequired,
  dailyMarketPercent: PropTypes.number.isRequired,
  etfName: PropTypes.string.isRequired,
  type: PropTypes.string,
  isEtf: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    is_open: PropTypes.bool,
  }),
  securitiesFundamentals: PropTypes.shape({}),
  back: PropTypes.func.isRequired,
  isShowErrorModal: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool.isRequired,
  previousCloseAmount: PropTypes.string.isRequired,
  dividends: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  widthResize: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  heightResize: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  resizeUpdate: PropTypes.func.isRequired,
  isSetResize: PropTypes.bool.isRequired,
  isShowLoader: PropTypes.bool,
  chartDataAdditional: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSuper: PropTypes.bool.isRequired,
  activeAllocationProfile: PropTypes.shape({
    amount: PropTypes.number,
    shares: PropTypes.number,
  }),
  etfIndex: PropTypes.number.isRequired,
};
