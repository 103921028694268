const opacity = {
  transparent: 0,
  extraTransparent: 0.1,
  veryLightTransparent: 0.2,
  lightTransparent: 0.32,
  moderatelyTransparent: 0.44,
  mediumTransparent: 0.54,
  semiTransparent: 0.72,
  almostOpaque: 0.8,
  opaque: 1,
};

export default opacity;
