import styled from '@emotion/styled';

import UploadSvg from 'assets/images/icons/upload.svg';
import DocSvg from 'assets/images/icons/doc.svg';
import TrashSvg from 'assets/images/icons/trash.svg';

export const Wrapper = styled.div<{ isValid: boolean; height: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.athensGray};
  border-color: ${({ theme }) => theme.colors.alto};
  border-radius: 10px;
  border-style: ${({ isValid = false }) => (isValid ? 'solid' : 'dashed')};
  border-width: 1px;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-top: 8px;
  text-align: center;
`;

export const Input = styled.input`
  border: 0 none;
  height: 100%;
  inset: 0;
  margin: 0;
  opacity: ${({ theme }) => theme.opacity.transparent};
  padding: 0;
  position: absolute;
  width: 100%;
`;

export const Description = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  justify-content: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  text-align: left;
`;

export const Browse = styled.i`
  color: ${({ theme }) => theme.colors.niagaraLight};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-style: normal;
`;

export const IconUpload = styled.div`
  background-image: url(${`${UploadSvg}`});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  width: 20px;
`;

export const ClearButton = styled.button`
  background: ${({ theme }) => theme.colors.white} url(${`${TrashSvg}`})
    no-repeat center center;
  border-radius: 12px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
`;

export const IconDocument = styled.div`
  background: transparent url(${`${DocSvg}`}) no-repeat center center;
  display: inline-block;
  height: 40px;
  margin-right: 8px;
  width: 40px;
`;
