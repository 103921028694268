import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { MY_APP } from '../../../constants/localeConfigs';

import BankErrorModal from './BankErrorModal';

import { Button } from 'components/elements/Button';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function subaccountsRender(card, selectLinkCard) {
  return (
    <div className="bank-link" key={`bank-link-${card.id}`}>
      {card.bank && (
        <div className="info-panel__item" key={card.bank.id}>
          <div className="info-panel__title">
            <img alt="" src={card.bank.icon_url} width="35" />

            {card.bank.bank_name}
          </div>
        </div>
      )}

      <div className="info-panel__item" key={card.id}>
        <div className="name">**** **** **** {card.last_4}</div>

        <div className="checkbox__plus ">
          +
          <input type="checkbox" onChange={(e) => selectLinkCard(e, card.id)} />
        </div>
      </div>
    </div>
  );
}

export default function BankLinkModal({
  errorMessage,
  handleCloseModalError,
  isShowModalError,
  paymentCards,
  linkCardForFunding,
  isRegistration,
  selectLinkCard,
  skip,
  showBanks,
}) {
  const { t } = useTranslation();

  return (
    <div className="settings__funding-account">
      {errorMessage && (
        <BankErrorModal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowModalError}
          errorTitle={t('settingsPage.bankLinkModal.errorModalDescription')}
          errorMessage={errorMessage}
          handleClick={handleCloseModalError}
          shouldCloseOnOverlayClick
        />
      )}

      <Modal
        isOpen
        onRequestClose={handleCloseModalError}
        contentLabel="withdraw-modal"
        shouldCloseOnOverlayClick
        className="modal-basic bank__modal bank-sign bank-connect "
      >
        <div className="bank__modal-content">
          {!isRegistration && (
            <DeprecatedButton
              title={t('common.space')}
              buttonClass="button__close -black"
              handleClick={handleCloseModalError}
            />
          )}

          <div className="bank__modal-title">
            {t('settingsPage.fundingAccount.title')}
          </div>

          <div className="bank__modal-sub">
            <i className="bank__modal-secured-icon" />

            {t('common.secured')}
          </div>

          <div className="bank__modal-description">
            {t('bankLinkModal.theSame')}
          </div>

          {paymentCards.payment_cards.map((card) =>
            subaccountsRender(card, selectLinkCard),
          )}

          {MY_APP && (
            <div className="bank-link">
              <div className="info-panel__item">
                <div className="name">
                  {t('settingsPage.bankLinkModal.addDebitCardButton')}
                </div>

                <div className="checkbox__plus ">
                  +
                  <input type="checkbox" onChange={(e) => showBanks(e, true)} />
                </div>
              </div>
            </div>
          )}

          <div className="bank__modal-row" />

          {isRegistration && (
            <div className="alert-buttons">
              <DeprecatedButton
                title={t('common.skip')}
                type="button"
                buttonClass="wait"
                handleClick={() => skip()}
              />

              <DeprecatedButton
                title={t('settingsPage.bankLinkModal.addFundingAccountButton')}
                type="button"
                buttonClass="skip"
                handleClick={linkCardForFunding}
              />
            </div>
          )}

          {!isRegistration && (
            <div className="connect-btn">
              <Button
                title={t('settingsPage.bankLinkModal.addFundingAccountButton')}
                handleClick={linkCardForFunding}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

BankLinkModal.defaultProps = {
  paymentCards: {},
  isRegistration: false,
  skip() {},
};

BankLinkModal.propTypes = {
  paymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(),
  }),
  errorMessage: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  linkCardForFunding: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool,
  selectLinkCard: PropTypes.func.isRequired,
  skip: PropTypes.func,
  showBanks: PropTypes.func.isRequired,
};
