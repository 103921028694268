import { SuperAccountInfoItemWrapper, Label, Value } from './styles';

export interface ISuperInfoItemProps {
  label: string;
  value?: string;
}

export const SuperAccountInfoItem = ({ label, value }: ISuperInfoItemProps) => {
  if (!value) {
    return null;
  }

  return (
    <SuperAccountInfoItemWrapper>
      <Label>{label}</Label>

      <Value>{value}</Value>
    </SuperAccountInfoItemWrapper>
  );
};
