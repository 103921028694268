import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actionsGifts from '../../store/gifts/actions';
import * as dependencyActions from '../../store/dependentUser/actions';

import RaizKidsEditHistoryView from './RaizKidsEditHistoryView';

class RaizKidsEditHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const { actionsDependency, match } = this.props;
    const { childId } = match.params;

    actionsDependency.getDependencyUser(childId).then(() => {
      this.setState({
        showLoader: false,
      });
    });
  }

  render() {
    const { match } = this.props;
    const { showLoader } = this.state;
    const { childId } = match?.params || {};

    return (
      <RaizKidsEditHistoryView showLoader={showLoader} childId={childId} />
    );
  }
}

RaizKidsEditHistory.defaultProps = {
  match: {},
  child: {},
  giftsActions: {},
  receiverIcons: {},
  actionsDependency: {},
};

const mapStateToProps = (state) => ({
  state,
  child: state.dependencyUsers.child?.dependency_user,
  type: state.dependencyUsers.type,
  notifications: state.dependencyUsers.notifications,
  receiverIcons: state.gifts.receiverIcons,
  errorMessage: state.dependencyUsers.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
  giftsActions: bindActionCreators(actionsGifts, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RaizKidsEditHistory);

RaizKidsEditHistory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      childId: PropTypes.string,
    }),
  }),
  child: PropTypes.shape({}),
  receiverIcons: PropTypes.shape({}),
  actionsDependency: PropTypes.shape({
    getDependencyUser: PropTypes.func,
  }),
  giftsActions: PropTypes.shape({}),
};
