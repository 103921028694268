import { Banner } from './components/Banner';
import { List } from './components/List';
import { ListWrapper, Wrapper } from './styles';
import { useFetchReferralList } from './useFetchReferralList';

export const Referrals = () => {
  const { groupedReferralList, isSuccess, isLoading } = useFetchReferralList();

  return (
    <Wrapper>
      <Banner />

      <ListWrapper>
        <List
          items={groupedReferralList}
          isSuccess={isSuccess}
          isLoading={isLoading}
        />
      </ListWrapper>
    </Wrapper>
  );
};
