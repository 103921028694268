import { MAXIMUM_FRACTION_DIGITS } from './consts';

interface IGetMinimumFractionDigits {
  value: number;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  isMinimumFractionDigitsForIntegerSkipped?: boolean;
}

export const getMinimumFractionDigits = ({
  value,
  minimumFractionDigits,
  maximumFractionDigits,
  isMinimumFractionDigitsForIntegerSkipped,
}: IGetMinimumFractionDigits) => {
  const isFractionDigitsAfterRounding =
    Number(Number(value).toFixed(maximumFractionDigits)) % 1;

  const updatedMinimumFractionDigits =
    isFractionDigitsAfterRounding && !minimumFractionDigits
      ? MAXIMUM_FRACTION_DIGITS
      : minimumFractionDigits;

  return value % 1 || !isMinimumFractionDigitsForIntegerSkipped
    ? updatedMinimumFractionDigits
    : 0;
};
