import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import * as spendingAnalyticsActions from '../../../store/spendingAnalytics/actions';
import { CREDIT, DEBIT } from '../../../constants/comonConstants';

import { Button } from 'components/elements/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { PATHS } from 'constants/paths';

class PotentialCash extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      options: {
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: ['#3762fa', '#e7e7e7'],
        },
        stroke: {
          show: false,
          width: 0,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      series: [0, 100],
      freeCash: 0,
    };

    this.setSeries = this.setSeries.bind(this);

    this.setFreeCash = this.setFreeCash.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics.getUpcomingTransactions().then(() => {
      if (this._isMounted) {
        const { upcomingTransactions } = this.props;

        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;

        const transactions = upcomingTransactions.payload
          ? upcomingTransactions.payload
              .map((object) => object.transactions)
              .reduce((acc, val) => acc.concat(val), [])
          : [{}];

        const debitTransactions = transactions.filter(
          (object) => object.movement === DEBIT,
        );

        const debitTransactionsAmounts = debitTransactions.map(
          (object) => object.amount,
        );

        const debitCash =
          debitTransactionsAmounts.length > 0
            ? debitTransactionsAmounts.reduce(reducer)
            : 0;

        const creditTransactions = transactions.filter(
          (object) => object.movement === CREDIT,
        );

        const creditTransactionsAmount = creditTransactions.map(
          (object) => object.amount,
        );

        const creditCash =
          creditTransactionsAmount.length > 0
            ? creditTransactionsAmount.reduce(reducer)
            : 0;

        const freeCash = creditCash - debitCash;

        this.setFreeCash(Math.round(freeCash));

        if (freeCash > 0) {
          this.setSeries((freeCash * 100) / creditCash);
        }
      }
    });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setFreeCash(freeCash) {
    this.setState({ freeCash });
  }

  setSeries(income) {
    this.setState({
      series: [Number(income.toFixed(2)), Number((100 - income).toFixed(2))],
    });
  }

  render() {
    const { t } = this.props;
    const { options, series, freeCash } = this.state;

    return (
      <div className="info-panel -with-padding my-finance__potential">
        <div className="info-panel__title">
          {t('myFinancePage.potentialCash.title')}
        </div>

        <div className="info-panel__divider" />

        <div className="my-finance__potential-row">
          <div className="">
            <div className="my-finance__potential-label">
              {t('myFinancePage.potentialCash.potentialFreeCash.label')}
            </div>

            <div className="my-finance__potential-value">
              {formatNumber({
                value: freeCash,
              })}
            </div>
          </div>
        </div>

        <div className="my-finance__potential-row -bottom-row">
          <div
            className={`my-finance__potential-col ${
              !series[0] ? '-disabled' : ''
            }`}
          >
            <Chart options={options} series={series} type="pie" width="200" />
          </div>

          <div className="my-finance__potential-col">
            <div className="my-finance__potential-label -sub -income">
              {t('myFinancePage.potentialCash.estimatedIncome.label')}
            </div>

            <div className="my-finance__potential-label -sub -expenses">
              {t('myFinancePage.potentialCash.estimatedExpenses.label')}
            </div>
          </div>
        </div>

        <Button
          href={PATHS.potentialFreeCash.index}
          label={t('myFinancePage.potentialCash.button.forecast')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  upcomingTransactions: state.spendingAnalytics.upcomingTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PotentialCash));

PotentialCash.defaultProps = {
  actionsSpendingAnalytics: {},
  upcomingTransactions: {
    payload: [],
  },
};

PotentialCash.propTypes = {
  t: PropTypes.func.isRequired,
  actionsSpendingAnalytics: PropTypes.shape({
    getUpcomingTransactions: PropTypes.func,
  }),
  upcomingTransactions: PropTypes.shape({
    payload: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
