import { CLOSE_MODAL, PORTFOLIO_SAVE_SUCCESS } from '../commonTypes';

import {
  LUMP_SUM_INVEST_SUCCESS,
  GET_SUPER_FUNDS_SUCCESS,
  SHOW_ERROR_IN_SUPER_MODAL,
  GET_SUPER_SETTINGS_SUCCESS,
  SHOW_ERROR_LUMP_SUM_INVEST,
  SAVE_SUPER_REGISTRATION_SUCCESS,
  GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS,
  CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS,
  SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER,
  ROLLOVER_FUND_SUCCESS,
  BACK_ON_SUPER_REGISTRATION,
  GO_TO_CONSENT,
  SHOW_ERROR_UPDATE_TRANSFER_REWARDS,
} from './consts';

const initialState = {};

export default function user(state = initialState, action) {
  let step = 5;

  switch (action.type) {
    case SHOW_ERROR_LUMP_SUM_INVEST:
    case SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
      };
    case SHOW_ERROR_IN_SUPER_MODAL:
      return {
        ...state,
        step: action.step,
        isShowErrorModal: true,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
      };
    case LUMP_SUM_INVEST_SUCCESS:
    case CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
      };
    case GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        recurringDepositSettings: action.recurringDepositSettings,
      };
    case GET_SUPER_SETTINGS_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        superSettings: action.superSettings,
      };
    case SAVE_SUPER_REGISTRATION_SUCCESS:
      return {
        ...state,
        step: action.step,
        superAnnuationUser: action.superAnnuationUser,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        isShowErrorModal: false,
      };
    case PORTFOLIO_SAVE_SUCCESS:
      if (action.isRegistration) {
        step = 1;
      }

      return {
        ...state,
        step,
      };
    case GET_SUPER_FUNDS_SUCCESS:
      return {
        ...state,
        superFunds: action.superFunds,
      };
    case ROLLOVER_FUND_SUCCESS:
      return {
        ...state,
        idRolloverFund: action.id,
      };
    case BACK_ON_SUPER_REGISTRATION:
      return {
        ...state,
        step: action.step,
      };
    case GO_TO_CONSENT:
      return {
        ...state,
        step: 5,
      };
    case SHOW_ERROR_UPDATE_TRANSFER_REWARDS:
      return {
        ...state,
        isShowErrorModal: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}
