import { PERIOD_DAYS_NUMBER } from './consts';

import { CURRENCY_SYMBOL } from 'constants/localeConfigs';

export const changeInValue = (periodDays) => {
  const mShort = 'm';

  const yShort = 'y';

  const allShort = 'all';

  const { oneDay, oneMonth, threeMonths, halfYear, oneYear } =
    PERIOD_DAYS_NUMBER;

  switch (periodDays) {
    case oneDay:
      return '1d';
    case oneMonth:
      return `1${mShort}`;
    case threeMonths:
      return `3${mShort}`;
    case halfYear:
      return `6${mShort}`;
    case oneYear:
      return `1${yShort}`;
    default:
      return !periodDays ? allShort : '1m';
  }
};

export const getMarketChange = (
  periodMarketChange,
  periodMarketChangeEtf,
  isOneDayChart,
  isEtf,
  dailyMarketChange,
) => {
  let marketChange = 0;

  if (
    !isEtf &&
    periodMarketChange !== `${CURRENCY_SYMBOL}0` &&
    periodMarketChange !== ''
  ) {
    marketChange = periodMarketChange;
  } else if (
    isEtf &&
    periodMarketChangeEtf !== `${CURRENCY_SYMBOL}0` &&
    periodMarketChangeEtf !== ''
  ) {
    marketChange = periodMarketChangeEtf;
  }

  if (isOneDayChart && !isEtf) {
    marketChange = dailyMarketChange;
  }

  return marketChange;
};

export const getMarketPercent = (
  periodMarketChangePercent,
  periodMarketChangePercentEtf,
  isOneDayChart,
  isEtf,
  dailyMarketPercent,
) => {
  let marketPercent = 0;

  if (
    periodMarketChangePercent !== `${CURRENCY_SYMBOL}0` &&
    periodMarketChangePercent !== ''
  ) {
    marketPercent = periodMarketChangePercent;
  } else if (
    periodMarketChangePercentEtf !== `${CURRENCY_SYMBOL}0` &&
    periodMarketChangePercentEtf !== ''
  ) {
    marketPercent = periodMarketChangePercentEtf;
  }

  if (isOneDayChart && !isEtf) {
    marketPercent = dailyMarketPercent;
  }

  return marketPercent;
};
