import styled from '@emotion/styled';

import bPayLogo from 'assets/images/ic_bpay_logo.png';
import { Button } from 'components/elements/Button';
import infoSvg from 'assets/images/icons/info_icon.svg';

const BILLER_WIDTH = 320;

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const DescriptionWrapper = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  opacity: 0.54;
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

export const DescriptionIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${`${infoSvg}`});
  margin-right: 8px;
`;

export const SendButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  padding: 0 20px;
  max-width: 300px;
`;

export const SuperAccountInfoWrapper = styled.div`
  padding: 25px 20px 0;
`;

export const InfoPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  padding: 28px 14px 18px;
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

export const SuperAccountInfoItemWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 15px 14px;
`;

export const Label = styled.div`
  ${({ theme }) => `
       font-size: ${theme.fontSizes['4sm']};
       color: ${theme.colors.dustyGray};
  `}
`;

export const Value = styled.div`
  ${({ theme }) => `
      font-size: ${theme.fontSizes.md};
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.mirage};
  `}
`;

export const BPayInfoWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  width: 100%;
  display: flex;
  padding: 25px 15px 25px;
`;

export const BPayImage = styled.div`
  background: url(${bPayLogo}) no-repeat;
  padding: 38px 30px;
  background-size: contain;
`;

export const BillerWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.madison};
  color: ${({ theme }) => theme.colors.madison};
  font-size: ${({ theme }) => theme.fontSizes['3md']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: 12px 15px 0;
  width: ${BILLER_WIDTH}px;
  display: flex;
  flex-direction: column;
`;

export const BillerItem = styled.div`
  flex: 1;
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
`;

export const RaizCode = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  padding-left: 5px;
`;
