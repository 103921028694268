export const BUTTON_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  transparent: 'transparent',
  text: 'text',
  textBlack: 'textBlack',
  initial: 'initial',
};
export const DEFAULT_ICON_SIZE = 16;
export const DEFAULT_ICON_POSITION = 'left';
