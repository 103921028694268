// TODO: https://acornsau.atlassian.net/browse/RAIZ-6610
import Firebase from 'firebase/app';

import {
  CLOSE_MODAL,
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  SHOW_MFA_FIELD,
  GET_WITHDRAWAL_ACCOUNT_SUCCESS,
  CLOSE_CONNECT_SCREEN,
  SHOW_CONNECT_SCREEN,
  REGISTRATION_ACCOUNT_INFORMATION,
  GET_FUNDING_PAYMENT_CARD_SUCCESS,
  REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
  SHOW_WAIT_POPUP_BANK_LOGIN,
  WAITING_IN_PROCESS,
  SHOW_ERROR_POPUP_PAYMENT_CARD,
  SHOW_GLOBAL_ERROR,
  REGISTRATION_ACCOUNT_PORTFOLIO,
  GET_FUNDING_ACCOUNT_SUCCESS,
  RESET_PROPS,
  SHOW_LOADER,
  SHOW_VERIFICATION_MODAL,
  GET_SUPER_USER_DATA_SUCCESS,
  PORTFOLIO_SAVE_SUCCESS,
  GET_BANKS_SUCCESS,
  REGISTRATION_PERSONAL_INFO,
  RESPONSE_FROM_BANK_SUCCESS,
  SHOW_ERROR_POPUP_BANK_LOGIN,
  OPEN_LOGOUT_MODAL,
  CLOSE_LOGOUT_MODAL,
} from '../commonTypes';
import { MY_APP, AU_APP } from '../../constants/localeConfigs';

import {
  HOME,
  SKIP_BANK,
  GO_TO_LOGIN,
  LOGIN_ERROR,
  UNAUTHORIZED,
  LOGIN_SUCCESS,
  GET_MORE_BANKS,
  LOGOUT_SUCCESS,
  USER_UPDATE_ERROR,
  NEW_PASSWORD_ERROR,
  GO_TO_REGISTRATION,
  REGISTRATION_ERROR,
  VERIFICATION_DEVICE,
  NEW_PASSWORD_SUCCESS,
  SEND_INVESTS_SUCCESS,
  VERIFY_QUESTION_ERROR,
  VERIFY_PASSWORD_ERROR,
  GET_USER_DATA_SUCCESS,
  SHOW_ERROR_GET_TRANSACTIONS,
  GET_PORTFOLIOS_SUCCESS,
  REGISTRATION_STEP_AUTH,
  SHOW_ERROR_SEND_INVESTS,
  CHANGE_PASSWORD_SUCCESS,
  GET_TRANSACTIONS_SUCCESS,
  GET_CLOSE_QUESTIONS_SUCCESS,
  GET_REWARDS_SUMMARY_SUCCESS,
  GET_USER_INVITATION_SUCCESS,
  GET_ACCOUNT_STRENGTH_SUCCESS,
  REGISTRATION_PERSONAL_ADDRESS,
  REGISTRATION_ACCOUNT_QUESTIONS,
  GET_SUPER_USER_ACCOUNTS_SUCCESS,
  REGISTRATION_BANK_ACCOUNT_BANKS,
  REGISTRATION_SUPERFUND_INFO,
  REGISTRATION_ACCOUNT_MYKAD,
  REGISTRATION_ACCOUNT_AGEREEMENT,
  GET_ACCOUNT_SUMMARY_ROUND_SUCCESS,
  REGISTRATION_INVESTMENTS_INVESTMENT,
  GO_TO_TERMS,
  ERROR_CREATE_USER_ON_REGISTRATION,
  ERROR_CREATE_USER_ON_KID_REGISTRATION,
  CHANGE_ADDRESS_DATA,
  REGISTRATION_BANKS_SPENDING_ACCOUNT,
  GET_REQUIRED_DOCUMENTS_SUCCESS,
  REGISTRATION_INVESTING_ERROR,
  LINK_FUNDING_NOW,
  REGISTRATION_PERSONAL_TAXRESIDENT,
  GO_TO_FUNDING,
  SET_PORTFOLIO_TYPE,
  UPLOAD_SUCCESS,
  PORTFOLIO_SAVE_ERROR,
  UPLOAD_FAILED,
  GET_SIGNUP_PROGRESS_SUCCESS,
  GET_USER_DATA_ERROR,
  GET_COUNTRIES_SUCCESS,
  CREATE_IDENTIFIER_SUCCESS,
  GET_IDENTIFIERS_SUCCESS,
  SHOW_ADD_TAX,
  GET_SECURITY_QUESTION_SUCCESS,
  REGISTRATION_ACCOUNT_OTHER,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_LOADING,
  GET_BLOG_POSTS_SUCCESS,
  HIDE_BLOG,
  GET_PORTFOLIO_FUNDS,
  GET_CUSTOM_PORTFOLIO_SUCCESS,
  GET_NEW_PORTFOLIO_ID,
  GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS,
  CUSTOM_PORTFOLIO_ERROR,
  REGISTRATION_SUPERFUND_MEMBERS,
  GET_SUPER_FUND_DETAILS_SUCCESS,
  GET_SUPER_FUND_MEMBERS_SUCCESS,
  REGISTRATION_CREATE_MEMBERS_SUCCESS,
  LINK_WITHDRAWAL_NOW,
  OPEN_PORTFOLIO,
  CLOSE_VERIFICATION_MODAL,
  START_BANK_VERIFICATION,
  RE_SEND_INVEST,
  GET_FEATURES_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  GET_CPF_SUCCESS,
  APPLY_CPF_SUCCESS,
  SHOW_RDV_MODAL,
  RDV_ERROR,
  CLOSE_RDV_MODAL_ERROR,
  RDV_VERIFICATION_SUCCESS,
  SHOW_PURE_PROFILE_ERROR,
  REMITTANCES,
  FUNDING_ERROR,
  SET_CLOSE_ACCOUNT_STATUS,
  SET_ZAKAT_TYPES,
  GET_BLOG_POSTS_LOADING,
  SET_SECURITY_QUESTIONS,
  GET_USER_DATA_LOADING,
  GET_PORTFOLIO_CATEGORIES_SUCCESS,
  GET_DEPENDENT_USER_ACCESS_SUCCESS,
  GET_PORTFOLIOS_LOADING,
  SET_IS_USER_LOADING,
  SET_CURRENT_REGISTRATION_STEP,
  SET_IS_SECURITY_QUESTIONS_LOADING,
  GET_USER_STORE,
} from './consts';

import { ADD_VISA_CARD_SUCCESS } from 'store/fundingAccount/consts';
import { checkIsDependentUserRegistration } from 'utils/user';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const initialState = {
  rewards: {},
  accountStrength: {
    achievements: [],
    percentage: null,
  },
  user: {
    allocation_profile_id: '',
    email: '',
    password: '',
    profile: {
      zip: null,
      dob: null,
      city: null,
      state: null,
      answer1: null,
      address2: null,
      employer: null,
      address1: null,
      suitability: [],
      last_name: null,
      question1: null,
      first_name: null,
      us_citizen: false,
      us_resident: false,
      phone_number: null,
      street_number: null,
      registration_complete: false,
      shareholder_or_director: false,
      subject_to_backup_withholding: false,
      employed_affiliated_with_broker_dealer: false,
    },
    round_ups: {
      total: 0,
      waiting: 0,
      invested: 0,
    },
  },
  smsfDetails: {
    details: {
      legal_fund_name: '',
      display_fund_name: '',
      fund_abn: '',
      fund_establishment_date: '',
      fund_address: '',
      legal_company_name: '',
      company_acn: '',
      company_address: '',
    },
  },
  source_attributes: {
    a: null,
    c: null,
    oc: null,
    Other: null,
    Adgroup: null,
    Campaign: null,
    Creative: null,
  },
  udid: '',
  settings: {
    automatic_lower_threshold: null,
    automatic_upper_threshold: null,
    automatic_roundup: false,
    available_thresholds: [],
    default_amount: null,
  },
  loginData: {},
  yearsOld: false,
  changePassword: {},
  source_id: 'Webapp',
  invitation_code: null,
  transactions: {
    items: [],
  },
  us_resident: false,
  credentials: {
    bsb: '',
    LOGIN: '',
    PASSWORD: '',
    accountNumber: '',
    accountNumberConfirm: '',
  },
  remittances: {},
  monitoredInstitutions: {},
  isFundingErrorModalOpen: false,
  fundingErrorMessage: '',
  closeAccountRequesStatus: {},
  roundUpsSummary: {
    automatic: {
      waiting: null,
    },
  },
  isCloseAccountLoading: false,
  isPortfoliosLoading: true,
  isLoading: false,
};

export default function user(state = initialState, action) {
  let currentStep = '';

  const isSmsf =
    sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) === 'superFund';

  let portfolioFunds = [];
  const { features } = action;

  switch (action.type) {
    case SET_IS_USER_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentStep: sessionStorage.getItem(SESSION_STORAGE_KEYS.lastPathName)
          ? sessionStorage.getItem(SESSION_STORAGE_KEYS.lastPathName)
          : HOME,
        unauthorized: false,
        verificationToken: '',
        previousStep: '/login',
        token: action.userData.token,
        showMainLoader: true,
        showLoaderFunding: false,
        type: action.type,
      };
    case VERIFICATION_DEVICE:
      return {
        ...state,
        errorMessage: '',
        verificationToken: action.verificationToken,
        maskedMobileNumber: action.maskedMobileNumber,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isTriedLogin: false,
        unauthorized: true,
        currentStep: '/login',
        user: initialState.user,
        isRegistration: false,
        dependentUserAccess: null,
      };
    case GO_TO_REGISTRATION:
      return {
        ...state,
        currentStep: '/registration',
        user: initialState.user,
      };
    case GO_TO_LOGIN:
      return {
        ...state,
        currentStep: '/login',
        goToLogin: true,
      };
    case REGISTRATION_STEP_AUTH:
      if (MY_APP) {
        Firebase.analytics().logEvent('RegPhase4Funding');
      } else {
        Firebase.analytics().logEvent('Reg_SetSpendActPrompt');
      }

      if (
        AU_APP &&
        sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
          'superFund'
      ) {
        currentStep = REGISTRATION_SUPERFUND_INFO;
      } else {
        const access = JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
        );

        if (action.isKids) {
          if (access?.investing) {
            currentStep = REGISTRATION_BANKS_SPENDING_ACCOUNT;
          } else if (access?.manage_portfolio) {
            currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
          } else {
            currentStep = HOME;
          }
        } else {
          currentStep = MY_APP
            ? REGISTRATION_ACCOUNT_MYKAD
            : REGISTRATION_BANKS_SPENDING_ACCOUNT;
        }
      }

      return {
        ...state,
        unauthorized: false,
        isTriedLogin: true,
        token: action.userData.token,
        previousStep: REGISTRATION_ACCOUNT_AGEREEMENT,
        currentStep,
        showLoader: false,
        showLoaderFunding: false,
      };
    case REGISTRATION_ACCOUNT_AGEREEMENT:
      return {
        ...state,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        currentStep: REGISTRATION_BANK_ACCOUNT_BANKS,
        previousStep: REGISTRATION_ACCOUNT_AGEREEMENT,
      };
    case REGISTRATION_BANK_ACCOUNT_BANKS:
      return {
        ...state,
        currentStep: HOME,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        previousStep: REGISTRATION_BANK_ACCOUNT_BANKS,
      };
    case REGISTRATION_ACCOUNT_INFORMATION:
      Firebase.analytics().logEvent('Reg_SuitabilityTable');

      return {
        ...state,
        showLoader: false,
        currentStep: REGISTRATION_ACCOUNT_OTHER,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        showLoaderProps: false,
        previousStep: REGISTRATION_ACCOUNT_INFORMATION,
      };
    case REGISTRATION_ACCOUNT_MYKAD:
      currentStep = REGISTRATION_ACCOUNT_MYKAD;

      return {
        ...state,
        token: localStorage.getItem('token'),
        previousStep: REGISTRATION_ACCOUNT_OTHER,
        showLoader: false,
        currentStep,
      };
    case REGISTRATION_SUPERFUND_INFO:
      return {
        ...state,
        showLoader: false,
        token: localStorage.getItem('token'),
        previousStep: REGISTRATION_SUPERFUND_INFO,
        currentStep: REGISTRATION_SUPERFUND_MEMBERS,
      };
    case REGISTRATION_SUPERFUND_MEMBERS:
      return {
        ...state,
        showLoader: false,
        showLoaderFunding: false,
        token: localStorage.getItem('token'),
        previousStep: REGISTRATION_SUPERFUND_INFO,
        currentStep: REGISTRATION_BANKS_SPENDING_ACCOUNT,
      };

    case REGISTRATION_ACCOUNT_OTHER: {
      if (AU_APP) {
        Firebase.analytics().logEvent('Reg_SelectPortfolioPrompt');

        Firebase.analytics().logEvent('Reg_SelectPortfolioCarousel');
      }

      if (MY_APP) {
        Firebase.analytics().logEvent('RegPhase6ID');
      }

      return {
        ...state,
        showLoader: false,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        currentStep: REGISTRATION_ACCOUNT_PORTFOLIO,
        previousStep: REGISTRATION_ACCOUNT_OTHER,
      };
    }

    case REGISTRATION_ACCOUNT_QUESTIONS:
      return {
        ...state,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        previousStep: REGISTRATION_ACCOUNT_QUESTIONS,
        currentStep: isSmsf
          ? REGISTRATION_ACCOUNT_PORTFOLIO
          : REGISTRATION_ACCOUNT_OTHER,
        showLoader: false,
      };
    case REGISTRATION_PERSONAL_INFO:
      Firebase.analytics().logEvent('Reg_Address');

      return {
        ...state,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        previousStep: REGISTRATION_PERSONAL_INFO,
        currentStep: REGISTRATION_PERSONAL_ADDRESS,
        showLoader: false,
      };
    case REGISTRATION_PERSONAL_TAXRESIDENT:
      Firebase.analytics().logEvent('Reg_TCs');

      return {
        ...state,
        token: sessionStorage.getItem(SESSION_STORAGE_KEYS.token),
        previousStep: REGISTRATION_PERSONAL_ADDRESS,
        currentStep: REGISTRATION_ACCOUNT_QUESTIONS,
        showLoader: false,
        isNeedClearAuth: false,
      };
    case REGISTRATION_PERSONAL_ADDRESS:
      if (MY_APP) {
        currentStep = REGISTRATION_PERSONAL_TAXRESIDENT;
      } else {
        currentStep = REGISTRATION_ACCOUNT_QUESTIONS;
      }

      return {
        ...state,
        token: localStorage.getItem('token'),
        previousStep: REGISTRATION_PERSONAL_ADDRESS,
        currentStep,
        showLoader: false,
      };
    case REGISTRATION_INVESTMENTS_INVESTMENT:
      return {
        ...state,
        token: localStorage.getItem('token'),
        currentStep: HOME,
        previousStep: REGISTRATION_INVESTMENTS_INVESTMENT,
        step: 1,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        errorType: LOGIN_ERROR,
        errorMessage: action.message,
        isLoginError: true,
        isTriedLogin: action.isRegistration,
        isRegistration: action.isRegistration,
      };
    case UNAUTHORIZED:
      return {
        ...state,
        unauthorized: true,
        errorMessage: action.message,
        isUserDataLoading: false,
      };
    case REGISTRATION_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        isShowErrorModal: true,
        showMainLoader: false,
        showLoader: false,
      };
    case REGISTRATION_INVESTING_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        isShowErrorInvestingModal: true,
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        previousStep: '',
        errorMessage: '',
        user: action.userData.user,
        showMainLoader: false,
        isNeedCheckRedirect: action.isNeedCheckRedirect,
        goToLogin: false,
        isUserDataLoading: false,
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.userData.user,
        showMainLoader: false,
        goToLogin: false,
      };
    case GET_SUPER_FUND_DETAILS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        smsfDetails: action.smsfDetails,
      };
    case GET_SUPER_FUND_MEMBERS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        smsfMembers: action.smsfMembers,
      };
    case GET_ACCOUNT_STRENGTH_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        accountStrength: action.accountStrength.account_strength,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        type: GET_TRANSACTIONS_SUCCESS,
        transactions: action.transactions,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isFunding: action.isFunding,
        type: action.type,
      };
    case REGISTRATION_CREATE_MEMBERS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        type: action.type,
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        type: action.type,
        showLoader: false,
        globalErrorMessage: AU_APP || MY_APP ? action.errorMessage : '',
      };
    case USER_UPDATE_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        // user: action.user,
      };
    case VERIFY_PASSWORD_ERROR:
    case VERIFY_QUESTION_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        changePassword: action.changePassword,
      };
    case CHANGE_PASSWORD_SUCCESS:
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.lastPathName);

      return {
        ...state,
        errorMessage: '',
        changePasswordSuccess: true,
        isShowSuccessModal: true,
      };
    case SEND_INVESTS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
      };
    case GET_REWARDS_SUMMARY_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        rewardsSummary: action.rewardsSummary,
      };
    case GET_USER_INVITATION_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        userInvitation: action.userInvitation,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        isShowBanks: true,
        banks: action.banks,
        skipBankLogin: false,
        errorMessage: action.message,
        monitoredInstitutions: action.monitoredInstitutions,
      };
    case SHOW_ERROR_POPUP_BANK_LOGIN:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isShowBankModalError: true,
        showLoaderFunding: false,
        isShowConfirmationConnect: false,
      };
    case SHOW_ERROR_GET_TRANSACTIONS:
      return {
        ...state,
        type: SHOW_ERROR_GET_TRANSACTIONS,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
      };
    case GET_MORE_BANKS:
      return {
        ...state,
        isShowBanks: false,
        isShowMoreBanks: true,
        moreBanks: action.banks,
        addListener: action.addListener,
      };
    case RESPONSE_FROM_BANK_SUCCESS:
      if (action.isRegistration) {
        currentStep = REGISTRATION_PERSONAL_INFO;

        if (action.isSpending) {
          Firebase.analytics().logEvent('Reg_ChooseSpendAct');

          Firebase.analytics().logEvent('Reg_SetFundActPrompt');

          Firebase.analytics().logEvent('Reg_FundActBanks');

          currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;
        } else {
          Firebase.analytics().logEvent('Reg_ChooseFundAct');
        }

        if (action.isFromInvestments) {
          currentStep = REGISTRATION_INVESTMENTS_INVESTMENT;
        }

        if (action.isKidsRegistrationPortfolio) {
          currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
        } else if (action.isKidsRegistrationHome) {
          currentStep = HOME;
        }

        return {
          ...state,
          isShowBankModal: false,
          isShowBankModalError: false,
          errorMessage: '',
          isShowBanks: false,
          skipBankLogin: true,
          isShowConnect: true,
          isShowMoreBanks: false,
          isShowBankModalAlert: false,
          monitoredInstitutions: action.newMonitoredInstitution,
          showLoader: false,
          isSpending: action.isSpending,
          currentStep,
        };
      }

      return {
        ...state,
        currentStep: HOME,
      };
    case SKIP_BANK:
      if (action.isKidsRegistrationHome) {
        return {
          ...state,
          currentStep: HOME,
          showLoaderFunding: false,
          showLoader: false,
          isForBankLinkFunding: false,
        };
      }

      if (action.isKidsRegistrationPortfolio) {
        return {
          ...state,
          currentStep: REGISTRATION_ACCOUNT_PORTFOLIO,
          showLoaderFunding: false,
          showLoader: false,
          isForBankLinkFunding: false,
        };
      }

      if (action.isFromInvestments) {
        return {
          ...state,
          currentStep: REGISTRATION_INVESTMENTS_INVESTMENT,
          showLoaderFunding: false,
          showLoader: false,
          isForBankLinkFunding: false,
        };
      }

      if (action.isSpending) {
        return {
          ...state,
          currentStep: REGISTRATION_BANKS_FUNDING_ACCOUNT,
          showLoader: false,
          showLoaderFunding: false,
          isForBankLinkFunding: false,
        };
      }

      if (AU_APP) {
        Firebase.analytics().logEvent('Reg_SkipFundAct');
      }

      if (!action.isWithdrawal && MY_APP) {
        return {
          ...state,
          currentStep: REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
          showLoader: false,
          showLoaderFunding: false,
          isForBankLinkFunding: false,
        };
      }

      if (MY_APP) {
        Firebase.analytics().logEvent('RegPhase5Profile');
      }

      return {
        ...state,
        // currentStep: REGISTRATION_PERSONAL_INFO,
        currentStep: isSmsf
          ? REGISTRATION_ACCOUNT_QUESTIONS
          : REGISTRATION_PERSONAL_INFO,
        showLoader: false,
        showLoaderFunding: false,
        isForBankLinkFunding: false,
      };
    case GET_WITHDRAWAL_ACCOUNT_SUCCESS:
      if (action.isRegistration) {
        if (MY_APP) {
          Firebase.analytics().logEvent('RegPhase5Profile');
        }

        // currentStep = REGISTRATION_PERSONAL_INFO;
        currentStep = isSmsf
          ? REGISTRATION_ACCOUNT_QUESTIONS
          : REGISTRATION_PERSONAL_INFO;

        if (action.isFromMyKad) {
          currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
        }
      }

      return {
        ...state,
        isShowBanks: false,
        monitoredInstitutions: [],
        isShowDifferentBankModal: false,
        errorMessage: action.message,
        withdrawalAccount: action.fundingAccount,
        showLoader: false,
        accountData: action.fundingAccount,
        showLoaderFunding: false,
        isShowWithdrawalSignIn: false,
        currentStep,
      };
    case SHOW_ERROR_SEND_INVESTS:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
      };
    case GET_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        portfolios: action.portfolios,
        errorMessage: '',
        isShowErrorModal: false,
        isPortfoliosLoading: false,
      };
    case GET_CLOSE_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.questions,
      };
    case GET_ACCOUNT_SUMMARY_ROUND_SUCCESS:
      return {
        ...state,
        roundUpsSummary: action.roundUpsSummary,
      };
    case GET_SUPER_USER_DATA_SUCCESS:
      return {
        ...state,
        type: action.type,
        superUser: action.superUser.user,
      };
    case GET_SUPER_USER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        type: action.type,
        userAccounts: action.userAccounts,
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        type: action.type,
        message: action.message,
      };
    case NEW_PASSWORD_ERROR:
      return {
        ...state,
        type: action.type,
        errorTitle: action.errorTitle,
        errorMessageReset: action.errorMessage,
      };
    case GO_TO_TERMS:
      return {
        ...state,
        currentStep: '/registration/account/agreement',
        isLoginError: false,
        isTriedLogin: true,
        errorMessage: '',
      };
    case ERROR_CREATE_USER_ON_REGISTRATION:
      return {
        ...state,
        isNeedGoToStart: true,
        isShowErrorModal: true,
        errorMessage: action.errorMessage,
      };
    case ERROR_CREATE_USER_ON_KID_REGISTRATION:
      return {
        ...state,
        isShowErrorModal: true,
        errorMessage: action.errorMessage,
      };
    case '@@redux-form/INITIALIZE':
      return {
        ...state,
        errorMessage: '',
      };
    case CLOSE_MODAL:
      return {
        ...state,
        errorMessage: '',
        isShowErrorModal: false,
        isShowDifferentBankModal: false,
        globalErrorMessage: '',
        showLoader: false,
        errorMessageCustom: '',
        isShowRDVModal: false,
        showLoaderFunding: false,
      };
    case SHOW_WAIT_POPUP_BANK_LOGIN:
      return {
        ...state,
        showLoaderFunding: false,
      };
    case WAITING_IN_PROCESS:
      return {
        ...state,
        isShowBankModalAlert: false,
        showLoaderFunding: true,
      };
    case SHOW_LOADER:
      return {
        ...state,
        showLoaderFunding: true,
        showLoader: true,
      };
    case '@@redux-form/START_SUBMIT':
      return {
        ...state,
        showLoader: true,
      };
    case '@@redux-form/SET_SUBMIT_FAILED':
    case '@@redux-form/STOP_SUBMIT':
    case '@@redux-form/UPDATE_SYNC_ERRORS':
      return {
        ...state,
        showLoader: false,
      };
    case CHANGE_ADDRESS_DATA:
      return {
        ...state,
        addressData: action.addressData,
      };
    case GET_FUNDING_ACCOUNT_SUCCESS:
      if (action.isRegistration) {
        // currentStep = REGISTRATION_PERSONAL_INFO;
        currentStep = isSmsf
          ? REGISTRATION_ACCOUNT_QUESTIONS
          : REGISTRATION_PERSONAL_INFO;

        if (action.isFromInvestments) {
          currentStep = REGISTRATION_INVESTMENTS_INVESTMENT;

          Firebase.analytics().logEvent('Reg_InitialInvestmentSelect');
        }

        if (action.isForLinkFunding) {
          currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;
        }

        return {
          ...state,
          isShowBanks: false,
          isShowDifferentBankModal: false,
          isShowWithdrawalSignIn: false,
          errorMessage: action.message,
          accountData: action.fundingAccount,
          showLoader: false,
          showLoaderFunding: false,
          isForLinkFunding: false,
          isWithdrawal: false,
          currentStep,
        };
      }

      return {
        ...state,
        isShowBanks: false,
        monitoredInstitutions: [],
        isShowDifferentBankModal: false,
        errorMessage: action.message,
        accountData: action.fundingAccount,
        showLoader: false,
        showLoaderFunding: false,
        isSpending: false,
        currentStep,
      };
    case GET_REQUIRED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        requiredDocuments: action.requiredDocuments,
      };
    case PORTFOLIO_SAVE_SUCCESS:
      if (action.isRegistration) {
        if (action.isInvestmentsVisible || (MY_APP && !action.isKids)) {
          currentStep = REGISTRATION_INVESTMENTS_INVESTMENT;
        } else {
          currentStep = HOME;
        }

        return {
          ...state,
          currentStep,
          previousStep: REGISTRATION_ACCOUNT_PORTFOLIO,
          portfolioId: action.portfolioId,
        };
      }

      return {
        ...state,
        portfolioId: action.portfolioId,
      };
    case SHOW_GLOBAL_ERROR:
      return {
        ...state,
        globalErrorMessage: action.globalErrorMessage,
      };
    case LINK_FUNDING_NOW:
      return {
        ...state,
        previousStep: REGISTRATION_INVESTMENTS_INVESTMENT,
        currentStep: REGISTRATION_BANKS_FUNDING_ACCOUNT,
        isFromInvestments: true,
        showLoaderFunding: false,
        showLoader: false,
        isForLinkFunding: true,
      };
    case SHOW_ERROR_POPUP_PAYMENT_CARD:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isShowCreditCardError: true,
        showLoaderFunding: false,
      };
    case GO_TO_FUNDING:
      return {
        ...state,
        currentStep: REGISTRATION_BANKS_FUNDING_ACCOUNT,
      };
    case SET_PORTFOLIO_TYPE:
      return {
        ...state,
        portfolioTypeFuture: action.portfolioTypeFuture,
      };
    case PORTFOLIO_SAVE_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isShowErrorModal: true,
        isShowwithdrawAll: action.isShowwithdrawAll,
      };
    case GET_SIGNUP_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.progress,
      };
    case GET_FUNDING_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        showLoader: false,
      };
    case GET_USER_DATA_ERROR:
      return {
        ...state,
        errorType: '',
        errorMessage: action.errorMessage,
        isUserDataLoading: false,
      };
    case RESET_PROPS:
      return {
        ...state,
        superUser: {},
        portfolioId: '',
        errorMessage: '',
        portfolioTypeFuture: 'moderate',
        changePasswordSuccess: false,
        showLoader: false,
        showLoaderFunding: false,
        portfolioFunds: [],
        isShowSuccessModal: false,
        isShowVerificationModal: false,
        isPortfoliosLoading: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.countries,
      };
    case CREATE_IDENTIFIER_SUCCESS:
      return {
        ...state,
        isShowListTax: true,
        isShowLoader: false,
      };
    case GET_IDENTIFIERS_SUCCESS:
      return {
        ...state,
        identifiers: action.identifiers,
      };
    case SHOW_ADD_TAX:
      return {
        ...state,
        isShowListTax: action.isShowListTax,
        isShowLoader: false,
      };
    case GET_SECURITY_QUESTION_SUCCESS:
      return {
        ...state,
        questionId: action.questionId,
        questionText: action.questionText,
      };
    case CLOSE_ACCOUNT_SUCCESS:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isCloseAccountLoading: false,
      };
    case CLOSE_ACCOUNT_LOADING:
      return {
        ...state,
        isCloseAccountLoading: true,
      };
    case GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.posts,
        isHideBlog: false,
        showLoader: false,
        isBlogPostsLoading: false,
      };
    case HIDE_BLOG:
      return {
        ...state,
        isHideBlog: true,
        showLoader: false,
        isBlogPostsLoading: false,
      };
    case CLOSE_CONNECT_SCREEN:
      return {
        ...state,
        isShowMoreBanks: false,
        isShowConnectModal: false,
        showLoader: false,
        showLoaderFunding: false,
        isForBankLinkFunding: false,
        showMainLoader: false,
        isShowConnect: false,
        isShowBankModal: false,
      };
    case SHOW_CONNECT_SCREEN:
      if (action.isSpending) {
        currentStep = REGISTRATION_BANKS_SPENDING_ACCOUNT;
      } else {
        currentStep = REGISTRATION_BANKS_FUNDING_ACCOUNT;
      }

      return {
        ...state,
        isShowBankModal: false,
        isShowBankModalError: false,
        errorMessage: '',
        isShowBanks: false,
        skipBankLogin: true,
        isShowConnect: true,
        isShowMoreBanks: false,
        isShowBankModalAlert: false,
        monitoredInstitutions: action.newMonitoredInstitution,
        isShowConnectModal: true,
        isSpending: action.isSpending,
        isReconectForFunding: action.isReconectForFunding,
        showLoaderFunding: false,
        showMainLoader: false,
        currentStep,
      };
    case GET_PORTFOLIO_FUNDS:
      if (action.portfolioFunds && action.portfolioFunds.length !== 0) {
        portfolioFunds = action.portfolioFunds.sort((x, y) => {
          if (x.balancing === true) {
            return -1;
          }

          if (y.balancing === true) {
            return 1;
          }

          return 0;
        });
      }

      return {
        ...state,
        portfolioFunds,
      };
    case GET_CUSTOM_PORTFOLIO_SUCCESS:
      return {
        ...state,
        customPortfolio: action.customPortfolio,
      };
    case GET_NEW_PORTFOLIO_ID:
      return {
        ...state,
        customPortfolioId: action.customPortfolioId,
      };
    case GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS:
      return {
        ...state,
        customPortfolioPreview: action.customPortfolioPreview,
      };
    case CUSTOM_PORTFOLIO_ERROR:
      return {
        ...state,
        errorMessageCustom: action.errorMessageCustom,
      };
    case LINK_WITHDRAWAL_NOW:
      return {
        ...state,
        previousStep: REGISTRATION_ACCOUNT_MYKAD,
        currentStep: REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
        isFromMyKad: true,
        showLoaderFunding: false,
        showLoader: false,
      };
    case OPEN_PORTFOLIO:
      return {
        ...state,
        previousStep: REGISTRATION_ACCOUNT_MYKAD,
        currentStep: REGISTRATION_ACCOUNT_PORTFOLIO,
      };
    case GET_FEATURES_SUCCESS:
      if (
        action.features &&
        action.features.raizRewards &&
        !action.features.raizRewards.enabled &&
        action.features.raizRewards.emails &&
        action.features.raizRewards.emails.length !== 0
      ) {
        if (state.user && state.user.email) {
          action.features.raizRewards.emails.map((email) => {
            if (email === state.user.email) {
              features.raizRewards.enabled = true;
            }

            return true;
          });
        }
      }

      return {
        ...state,
        features,
        raizKidsFeature: action.features?.['raizKids2.0'] || {},
        supportLinks: action.supportLinks?.raizCareUrlWhatsapp,
      };
    case SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        isShowVerificationModal: action.isShowVerificationModal,
        titleVerification: action.title,
        descriptionVerification: action.description,
        isShowDiscliamerPaymentModal: false,
      };
    case CLOSE_VERIFICATION_MODAL:
      return {
        ...state,
        isShowVerificationModal: action.isShowVerificationModal,
      };
    case START_BANK_VERIFICATION:
      return {
        ...state,
        isStartBankVerification: action.isStartBankVerification,
      };
    case RE_SEND_INVEST:
      return {
        ...state,
        isReSendInvests: true,
      };
    case GET_CPF_SUCCESS:
      return {
        ...state,
        cpfList: action.cpfList || [],
      };
    case APPLY_CPF_SUCCESS:
      return {
        ...state,
        depositId: action.depositId,
      };
    case SHOW_RDV_MODAL:
      return {
        ...state,
        isShowRDVModal: true,
        titleRdv: action.title,
        descriptionRdv: action.description,
      };
    case RDV_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isShowRdvError: true,
      };
    case CLOSE_RDV_MODAL_ERROR:
      return {
        ...state,
        errorMessage: '',
        isShowRdvError: false,
      };
    case RDV_VERIFICATION_SUCCESS:
      return {
        ...state,
        message: action.message,
        isShowRdvSuccess: true,
      };
    case SHOW_PURE_PROFILE_ERROR:
      return {
        ...state,
      };
    case SHOW_MFA_FIELD:
      return {
        ...state,
        showLoaderFunding: false,
      };
    case REMITTANCES:
      return {
        ...state,
        remittances: action.value,
      };
    case FUNDING_ERROR:
      return {
        ...state,
        isFundingErrorModalOpen: action.value.isFundingErrorModalOpen,
        fundingErrorMessage: action.value.fundingErrorMessage,
      };
    case OPEN_LOGOUT_MODAL:
      return {
        ...state,
        isLogoutModalOpen: true,
      };
    case CLOSE_LOGOUT_MODAL:
      return {
        ...state,
        isLogoutModalOpen: false,
      };
    case SET_CLOSE_ACCOUNT_STATUS:
      return {
        ...state,
        closeAccountRequesStatus: action.closeAccountRequesStatus,
      };
    case SET_ZAKAT_TYPES:
      return {
        ...state,
        zakatTypes: action.zakatTypes,
      };
    case GET_BLOG_POSTS_LOADING:
      return {
        ...state,
        isBlogPostsLoading: true,
      };
    case SET_SECURITY_QUESTIONS:
      return {
        ...state,
        securityQuestions: action.securityQuestions,
        isSecurityQuestionsLoading: false,
      };
    case GET_USER_DATA_LOADING:
      return {
        ...state,
        isUserDataLoading: true,
        errorMessage: '',
      };
    case GET_PORTFOLIO_CATEGORIES_SUCCESS:
      return {
        ...state,
        portfolioCategories: action.portfolioCategories,
      };
    case GET_DEPENDENT_USER_ACCESS_SUCCESS:
      return {
        ...state,
        dependentUserAccess: action.dependentUserAccess,
      };

    case ADD_VISA_CARD_SUCCESS: {
      if (checkIsDependentUserRegistration()) {
        if (action.isKidWithPortfolioAccess) {
          currentStep = REGISTRATION_ACCOUNT_PORTFOLIO;
        } else {
          currentStep = HOME;
        }
      }

      return {
        ...state,
        currentStep,
      };
    }

    case SET_CURRENT_REGISTRATION_STEP: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }

    case GET_PORTFOLIOS_LOADING:
      return {
        ...state,
        isPortfoliosLoading: true,
      };
    case SET_IS_SECURITY_QUESTIONS_LOADING:
      return {
        ...state,
        isSecurityQuestionsLoading: action.isSecurityQuestionsLoading,
      };

    case GET_USER_STORE: {
      return {
        ...state,
        userStore: action.userStore,
      };
    }

    default:
      return state;
  }
}
