import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsSecurityQuestionsLoading,
  selectUserDataLoading,
} from 'store/user/selectors';
import { getScurityQuestion, getUserData } from 'store/user/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useUserData = () => {
  const dispatch = useDispatch();

  const isUserDataLoading = useSelector(selectUserDataLoading);

  const isSecurityQuestionsLoading = useAppSelector(
    selectIsSecurityQuestionsLoading,
  );

  useEffect(() => {
    dispatch(getUserData());

    dispatch(getScurityQuestion());
  }, [dispatch]);

  return {
    isLoading: isUserDataLoading || isSecurityQuestionsLoading,
  };
};
