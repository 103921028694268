import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import FloatingLabelInput from 'react-floating-label-input';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import {
  PERSONAL_INFO,
  REGISTRATION_PERSONAL_TERMS,
  UPDATE_DEPENDENCY_USER_SUCCESS,
} from '../../../store/dependentUser/types';
import { REQUIRED } from '../../../constants/errors';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { INVESTMENT_TYPES } from 'constants/kidsConstants';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = ({ values, allowType }) => {
    const errors = {};

    if (allowType === INVESTMENT_TYPES.allow && !values.email) {
      errors.email = t('raizKidsRegistrationPage.kidsInfo.fields.email.error');
    }

    if (!values.name) {
      errors.name = t('raizKidsRegistrationPage.kidsInfo.fields.name.error');
    }

    return errors;
  };

  return { validate };
};

export default function KidsInfo({
  showLoader,
  goBack,
  handleSubmit,
  renderIcons,
  receiverIcons,
  dob,
  handleChangeDate,
  allowType,
  changeAccessType,
  dobError,
  isEditChild,
  kid,
  newKidEmail,
  newKidName,
  canEditEmail,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();
  const receiverIconsKeys = Object.keys(receiverIcons);
  const receiverIconsVals = Object.values(receiverIcons);
  const isAllow = allowType === INVESTMENT_TYPES.allow;
  const isNotAllow = allowType === INVESTMENT_TYPES.notAllow;
  const isKidOptionVisible = !isEditChild;

  return (
    <Form
      onSubmit={(e) =>
        handleSubmit(
          e,
          isEditChild ? UPDATE_DEPENDENCY_USER_SUCCESS : PERSONAL_INFO,
        )
      }
      validate={(values) => validate({ values, allowType })}
      initialValues={{
        name: newKidName || kid.name,
        email: newKidEmail || kid.email,
      }}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form raiz-kids__profile"
          onSubmit={(e) =>
            handleSubmit(
              e,
              values,
              form,
              hasValidationErrors,
              isEditChild ? UPDATE_DEPENDENCY_USER_SUCCESS : PERSONAL_INFO,
            )
          }
        >
          <div className={'"form-field radio'}>
            <div className="form-field__sub-input-link">
              <div className={`raiz-kids__add ${isEditChild ? '-edit' : ''}`}>
                {!kid.closed ? (
                  <>
                    <div className="raiz-kids__add-label">
                      {t(
                        'raizKidsRegistrationPage.kidsInfo.fields.avatar.label',
                      )}
                    </div>

                    <div className="raiz-kids__avatar-list">
                      {receiverIconsKeys.map((id, index) =>
                        renderIcons(id, receiverIconsVals[index]),
                      )}
                    </div>
                  </>
                ) : null}

                <div className="raiz-kids__modal-info">
                  <div>
                    <div className="raiz-kids__modal-input">
                      <FinalFormInput
                        disabled={kid.closed}
                        key={newKidName}
                        defaultValue={newKidName}
                        name="name"
                        label={t(
                          'raizKidsRegistrationPage.kidsInfo.fields.name.label',
                        )}
                      />
                    </div>
                  </div>

                  <div className="-focused -dob-field">
                    <FloatingLabelInput
                      id="dob"
                      className="raiz-kids__modal-input -disabled"
                      label={t('raizKidsRegistrationPage.kidsInfo.dateOfBirth')}
                      disabled
                    />

                    <DatePicker
                      maxDate={new Date()}
                      onCalendarOpen={() => handleChangeDate(new Date(), true)}
                      className="raiz-datepicker"
                      value={dob && dayjs(dob).isValid() ? dob : ''}
                      name="birthday"
                      onChange={handleChangeDate}
                      format="dd.MM.yyyy"
                      clearIcon={false}
                      locale="au"
                      calendarIcon={false}
                      disabled={kid.closed}
                    />

                    {dobError && (
                      <div className="error-message">{REQUIRED}</div>
                    )}
                  </div>

                  {isEditChild && kid?.email && (
                    <div className="raiz-kids__modal-input -last">
                      <FinalFormInput
                        name="email"
                        disabled
                        defaultValue={kid.email}
                        label={t(
                          'raizKidsRegistrationPage.kidsInfo.fields.email.label',
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isKidOptionVisible && (
              <div className="raiz-kids__access-title">
                {t('raizKidsRegistrationPage.kidsInfo.access.title')}
              </div>
            )}

            {isKidOptionVisible && (
              <div className="raiz-kids__access-description">
                {t('raizKidsRegistrationPage.kidsInfo.access.description')}
              </div>
            )}

            {isKidOptionVisible && (
              <div className="raiz-kids__access-select">
                <div className="checkbox__container-mix">
                  <CheckboxFF
                    basicCheckbox
                    name="allow"
                    defaultChecked={isAllow}
                    onClick={() => {
                      changeAccessType('allow');
                    }}
                    className="checkbox__basic-mix"
                    type="checkbox"
                    label=""
                  />

                  <div className="checkbox__label-mix">
                    {t(
                      'raizKidsRegistrationPage.kidsInfo.access.checkboxes.allow',
                    )}
                  </div>
                </div>

                {isAllow && (
                  <div>
                    <FinalFormInput
                      name="email"
                      defaultValue={newKidEmail}
                      disabled={!canEditEmail || kid.closed}
                      label={t(
                        'raizKidsRegistrationPage.kidsInfo.fields.email.label',
                      )}
                    />
                  </div>
                )}

                <div className="checkbox__container-mix">
                  <CheckboxFF
                    basicCheckbox
                    name="not-allow"
                    defaultChecked={isNotAllow}
                    onClick={() => {
                      changeAccessType('notAllow');
                    }}
                    className="checkbox__basic-mix"
                    type="checkbox"
                    label=""
                  />

                  <div className="checkbox__label-mix">
                    {t(
                      'raizKidsRegistrationPage.kidsInfo.access.checkboxes.disallow',
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="buttons-part">
            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  title={t('common.back')}
                  buttonClass="button back"
                  handleClick={() =>
                    goBack({ path: REGISTRATION_PERSONAL_TERMS })
                  }
                />
              </div>
            )}

            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  type="submit"
                  title={t('common.next')}
                  buttonClass="button button--submit"
                />
              </div>
            )}

            {isEditChild && !kid.closed && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  type="submit"
                  title={t('common.save')}
                  buttonClass="button button--submit"
                />
              </div>
            )}
          </div>
        </form>
      )}
    />
  );
}

KidsInfo.propTypes = {
  kid: PropTypes.objectOf(PropTypes.string),
  receiverIcons: PropTypes.shape({}).isRequired,
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  renderIcons: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  changeAccessType: PropTypes.func.isRequired,
  canEditEmail: PropTypes.bool.isRequired,
  allowType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dob: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dobError: PropTypes.bool,
  isEditChild: PropTypes.bool,
  newKidEmail: PropTypes.string,
  newKidName: PropTypes.string,
};

KidsInfo.defaultProps = {
  allowType: '',
  dob: '',
  kid: {},
  dobError: false,
  isEditChild: false,
  newKidEmail: '',
  newKidName: '',
};
