import styled from '@emotion/styled';

export const Item = styled.button`
  ${({ theme }) => `
    box-shadow: ${theme.shadows.primary};
    background-color: ${theme.colors.white};
  `};
  border-radius: 8px;
  text-align: center;
  animation-name: fadeIn;
  animation-duration: 925ms;
  height: unset;
  padding-bottom: 10px;
  position: relative;
  animation-name: fadein;
  animation-duration: 925ms;
`;

export const IconWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 10px;
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.codGray};
    font-size: ${theme.fontSizes['4sm']};
    line-height: ${theme.lineHeights.length['3sm']};
    font-family: ${theme.fontFamily.semiBold};
    font-weight: ${theme.fontWeights.semibold};
  `};
  padding-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FavouriteIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: ${({ theme }) => theme.zIndexes.elevated};
`;

export const Description = styled(Title)`
  color: ${({ theme }) => theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
