import PropTypes from 'prop-types';

import FundItemBottom from './FundItemBottom';
import FundItemHeader from './FundItemHeader';
import { FundItemWrapper } from './styles';

import { selectPortfolioParams } from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function FundItem({ fund }) {
  const { image_url, name, id, category } = fund || {};
  const { isSuper } = useAppSelector(selectPortfolioParams);

  return (
    <FundItemWrapper isSuper={isSuper}>
      <FundItemHeader
        imageUrl={image_url}
        name={name}
        id={id}
        category={category}
      />

      <FundItemBottom fund={fund} />
    </FundItemWrapper>
  );
}

FundItem.propTypes = {
  fund: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string,
    symbol: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
};
