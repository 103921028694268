import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const currentLang = localStorage.getItem('lang');
const MY_APP = process.env.REACT_APP_CODE_BASE_VERSION === 'mys';
const COUNTRY = process.env.REACT_APP_CODE_BASE_VERSION;

export const MOCK_SERVICE_WORKER_ENABLED =
  process.env.REACT_APP_MOCK_SERVICE_WORKER_ENABLED || false;

export const AUS = 'aus';
export const MYS = 'mys';
export const THA = 'tha';

export const APPROVED = 'approved';
export const UNAPPROVED = 'unapproved';
export const COMPLEMENT_CREDIT = 'complement_credit';
export const MAX_INVEST = 5.0;
export const TWITTER = 'Twitter';
export const FB = 'Facebook';
export const EMAIL = 'Email';
export const WHATS_APP = 'WhatsApp';
export const EMAIL_TARGET = 'email';
export const CONFIRM_EMAIL_TARGET = 'confirmEmail';
export const PASSWORD_TARGET = 'password';
export const CURRENCY = 'AUD';
export const LINK = 'Link';
export const DAILY = currentLang === 'bahasa' ? 'Harian' : 'daily';
export const WEEKLY = currentLang === 'bahasa' ? 'Mingguan' : 'weekly';
export const FORTNIGHTLY =
  currentLang === 'bahasa' ? 'Dua-mingguan' : 'fortnightly';
export const MONTHLY = currentLang === 'bahasa' ? 'Bulanan' : 'monthly';

const currency = {
  [AUS]: '$',
  [MYS]: 'RM',
};

const CURRENCY_SYMBOL = currency[COUNTRY] || currency[AUS];

export const ROUND_UP_MARKS = [
  {
    value: 0,
    label: `${CURRENCY_SYMBOL}0.00`,
  },
  {
    value: 0.25,
    label: `${CURRENCY_SYMBOL}0.25`,
  },
  {
    value: 0.5,
    label: `${CURRENCY_SYMBOL}0.50`,
  },
  {
    value: 0.75,
    label: `${CURRENCY_SYMBOL}0.75`,
  },
  {
    value: 1,
    label: `${CURRENCY_SYMBOL}1.00`,
  },
];

export const FREQUENCY = {
  daily: 'daily',
  Harian: 'daily',
  weekly: 'weekly',
  Mingguan: 'weekly',
  monthly: 'monthly',
  Bulanan: 'monthly',
  fortnightly: 'fortnightly',
  'Dua-mingguan': 'fortnightly',
  'Setiap dua minggu': 'fortnightly',
};

export const HOME_ACTIVE_TAB = 'activeTab';
export const HOME_TABS = {
  past: 'past',
  today: 'today',
  future: 'future',
};

export const MONDAY = 'Monday';
export const ISNIN = 'Isnin';
export const TUESDAY = 'Tuesday';
export const SELASA = 'Selasa';
export const WEDNESDAY = 'Wednesday';
export const RABU = 'Rabu';
export const THURSDAY = 'Thursday';
export const KHAMIS = 'Khamis';
export const FRIDAY = 'Friday';
export const JUMAAT = 'Jumaat';

const carTranslates = {
  [AUS]: { id: 'Car', name: 'Car' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Kereta', name: 'Kereta' }
      : { id: 'Car', name: 'Car' },
};

export const CAR = carTranslates[COUNTRY] || carTranslates[AUS];

const holidayTranslates = {
  [AUS]: { id: 'Holiday', name: 'Holiday' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Percutian', name: 'Percutian' }
      : { id: 'Holiday', name: 'Holiday' },
};

export const HOLIDAY = holidayTranslates[COUNTRY] || holidayTranslates[AUS];

const educationTranslates = {
  [AUS]: { id: 'Education', name: 'Education' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Pendidikan', name: 'Pendidikan' }
      : { id: 'Education', name: 'Education' },
};

export const EDUCATION =
  educationTranslates[COUNTRY] || educationTranslates[AUS];

const birthTranslates = {
  [AUS]: { id: 'Birthday', name: 'Birthday' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Hari Jadi', name: 'Hari Jadi' }
      : { id: 'Birthday', name: 'Birthday' },
};

export const BIRTHDAY = birthTranslates[COUNTRY] || birthTranslates[AUS];

const weddingTranslates = {
  [AUS]: { id: 'Wedding', name: 'Wedding' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Perkahwinan', name: 'Perkahwinan' }
      : { id: 'Wedding', name: 'Wedding' },
};

export const WEDDING = weddingTranslates[COUNTRY] || weddingTranslates[AUS];

const anniversaryTranslates = {
  [AUS]: { id: 'Anniversary', name: 'Anniversary' },
  [MYS]:
    currentLang === 'bahasa'
      ? { id: 'Ulang Tahun', name: 'Ulang Tahun' }
      : { id: 'Anniversary', name: 'Anniversary' },
};

export const ANNIVERSARY =
  anniversaryTranslates[COUNTRY] || anniversaryTranslates[AUS];

export const HOUSE =
  MY_APP && currentLang === 'bahasa'
    ? { id: 'Rumah', name: 'Rumah' }
    : { id: 'House', name: 'House' };
export const RENT =
  MY_APP && currentLang === 'bahasa'
    ? { id: 'Sewa', name: 'Sewa' }
    : { id: 'Rent', name: 'Rent' };
export const DEPOSIT = { id: 'Deposit', name: 'Deposit' };

export const UNCATEGORISED = 'Uncategorised';
export const UNCATEGORIZED = 'Uncategorized';

export const MAX_MONTH_CHART = 6;
export const MAX_MONTH_CHART_TABLET = 4;
export const MAX_MONTH_CHART_MOBILE = 3;

export const CREDIT = 'credit';
export const DEBIT = 'debit';
export const SUPER_FUND_DEBIT = 'super_fund_debit';

export const MIN_INVEST = 5;

export const AMOUNT = 'amount';
export const DATE = 'date';

export const OVERRIDE_SIMILAR = 'override_similar';
export const CUSTOMER = 'customer';
export const CUSTOMER_DESCRIPTION = 'custom_description';

export const ARROW_EXPAND = 'arrow-expand';

export const PAST = 'past';
export const TODAY = 'today';
export const FUTURE = 'future';

export const UPCOMING_TRANSACTION_DAYS = 30;

export const HOMEPAGE = 'Homepage';
export const HOMEPAGE_MONEY_THOR = 'Homepage_Money_Thor';
export const RECURRINGINVESTMENT = 'Recurring_investment';
export const TRANSACTION_CATEGORISATION = 'Transaction_categorisation';
export const WHERE_YOU_SPEND = 'Where_you_spend';
export const REWARDS = 'Rewards';
export const PORTFOLIO = 'Portfolio';
export const KIDS = 'Kids';
export const NEW_KIDS = 'New kids';
export const REGISTRATION = 'Registration';
export const FUNDING_ACCOUNT = 'Funding_account';
export const ROUND_UP_ACCOUNT = 'Round-Up_account';

export const NO_LINKED = 'no-linked';
export const USE_LINK_CARD = 'use-link-card';
export const NO_LINKED_WARN = 'no-linked-warn';

export const STATEMENTS_MONTHLY = 'monthly';
export const TRADE_CONFIRMATION = 'trade_confirmation';
export const TAX_REPORTS = 'tax_reports';

export const CHARGEBACK_FUNDING_ACCOUNT_RELINK =
  'CHARGEBACK_FUNDING_ACCOUNT_RELINK';
export const DEPOSITS_SUSPENSION_REMOVAL = 'DEPOSITS_SUSPENSION_REMOVAL';
export const REGISTRATION_INCOMPLETE_LINKFUNDING =
  'REGISTRATION_INCOMPLETE_LINKFUNDING';
export const REGISTRATION_INCOMPLETE_LINKSPENDING =
  'REGISTRATION_INCOMPLETE_LINKSPENDING';
export const ERRLINKING_SPENDING_ACCOUNT = 'ERRLINKING_SPENDING_ACCOUNT';
export const STATUS_LOGIN_FAILED = 'STATUS_LOGIN_FAILED';
export const NO_SUBACCOUNT = 'NO_SUBACCOUNT';
export const ERRLINKING_FUNDING_ACCOUNT = 'ERRLINKING_FUNDING_ACCOUNT';
export const ERRPERSONALPROFILE = 'ERRPERSONALPROFILE';
export const REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT =
  'REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT';

export const PENDING_INVESTMENT_WITH_PAYMENT_CODE =
  'PENDING_INVESTMENT_WITH_PAYMENT_CODE';
export const FUNDING_RDV = 'FUNDING_RDV';
export const OFFSETTERS = 'Offsetters';
export const ROUND_UP_HELP_CARD = 'ROUND_UP_HELP_CARD';
export const UNANSWERED_SUITABILITY_QUESTIONS =
  'UNANSWERED_SUITABILITY_QUESTIONS';
export const FINANCIAL = 'financial';

export const TIP = 'tip';
export const LIVE = 'live';
export const LIKE = 'like';
export const DISLIKE = 'dislike';

export const GENERIC = 'generic';
export const FREE_CASH = 'free_cash';
export const OFFER_RECOMMENDATION = 'offer_recommendation';
export const CATEGORY_EXPENSES = 'category_expenses_alert';
export const BLOG = 'blog';

export const CUSTOM = 'custom';
export const IMPRESSION = 'impression';
export const FEEDBACK = 'feedback';

export const LOGIN_URL = '/login';

export const MALE = 'male';
export const FEMALE = 'female';

export const AUTOMATIC_ROUNDUPS_ON = '/round-ups';
export const FUNDING_SOURCE_LINKED = '/settings/funding-account';
export const ACCOUNT_VALUE_GTE10 =
  '/invest?investment-show=true&investment-value=10';
export const AUTOMATIC_INVESTMENT_ON = '/recurring-investment';
export const ROUNDUP_ACCOUNT_LINKED = '/settings/spending-account';
export const ACCOUNT_VALUE_GTE100 =
  '/invest?investment-show=true&investment-value=100';

// TODO: to be replaced with FILE_STATUS dictionary
export const REMOVED = 'removed';
export const REJECTED_FILE_TYPE = 'rejected_file_type';
export const REJECTED_MAX_FILES = 'rejected_max_files';
export const MYKAD = 'my_kad';

export const FILE_STATUS = {
  done: 'done',
  removed: 'removed',
  error: {
    maxFilesExceeded: 'rejected_max_files',
    incorrectFileType: 'rejected_file_type',
  },
};

export const KTP = 'ktp';

export const PLATFORM_LINK_MY = 'https://www.raiz.com.my/platform-agreement/';
export const ACCOUNT_AGREEMENT_LINK_MY =
  'https://www.raiz.com.my/account-opening-agreement/';
export const DISCLOSURE_AGREEMENT_LINK_MY =
  'https://www.raiz.com.my/disclosures/';
export const PRIVACY_LINK_MY = 'https://www.raiz.com.my/privacy/';

export const GENDER_LIST =
  MY_APP && currentLang === 'bahasa'
    ? [
        { label: 'Lelaki', value: 'male' },
        { label: 'Perempuan', value: 'female' },
      ]
    : [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ];

export const INDICATE_REASONS = [
  {
    label:
      '• The country/jurisdiction where the Account Holder is resident does not issue TINs to its residents',
    value:
      '• The country/jurisdiction where the Account Holder is resident does not issue TINs to its residents',
  },
  {
    label:
      '• The Account Holder is otherwise unable to obtain a TIN or equivalent number',
    value:
      '• The Account Holder is otherwise unable to obtain a TIN or equivalent number',
  },
  {
    label:
      '• No TIN is required (Only select this reason if the domestic law of the relevant jurisdiction does ' +
      'not require the collection of the TIN issued by such jurisdiction)',
    value:
      '• No TIN is required (Only select this reason if the domestic law of the relevant jurisdiction does ' +
      'not require the collection of the TIN issued by such jurisdiction)',
  },
];

export const INDICATE_REASONS_MS = [
  {
    label:
      '• Negara / bidang kuasa tempat Pemegang Akaun bermastautin tidak mengeluarkan nombor TIN kepada ' +
      'penduduknya.',
    value:
      '• The country/jurisdiction where the Account Holder is resident does not issue TINs to its residents',
  },
  {
    label:
      '• Pemegang Akaun tidak dapat memperoleh nombor TIN atau nombor yang setara.',
    value:
      '• The Account Holder is otherwise unable to obtain a TIN or equivalent number',
  },
  {
    label:
      '• Nombor TIN tidak diperlukan (Pilih sebab ini hanya jika undang-undang domestik bagi bidang kuasa ' +
      'yang berkenaan tidak memerlukan pengumpulan nombor TIN yang dikeluarkan oleh bidang kuasa tersebut).',
    value:
      '• No TIN is required (Only select this reason if the domestic law of the relevant jurisdiction does ' +
      'not require the collection of the TIN issued by such jurisdiction)',
  },
];

export const CLOSE = 'close';

export const WA_NUMBER = '62811925709';
export const LINKAJA_LINK = 'lnk.me/install';

export const INVESTMENTS_REGISTRATION = 'investments-registration';
export const PERSONAL_REGISTRATION = 'personal-registration';
export const SUPER_FUND_REGISTRATION = 'super-fund-registration';
export const ACCOUNT_REGISTRATION = 'account-registration';

export const OTHER = 'other';
export const INVESTMENT_OTHER = 'investment-other';

export const CORPORATE = 'corporate';
export const INDIVIDUAL = 'individual';
export const ENGLISH = 'english';
export const BAHASA = 'bahasa';

export const AGGRESSIVE = 'aggressive';
export const MODERATE = 'moderate';
export const CONSERVATIVE = 'conservative';
export const CUSTOM_PORTFOLIO = 'custom';

export const AVAILABLE = 'available';
export const CURRENT = 'current';

export const RESET_PIN = '/users/pin/edit';
export const RECOVER_PASSWORD = '/recover-password';
export const LOGIN = '/login';
export const HOME_URL = '/';
export const HOME_HISTORY_TAB = `${HOME_URL}?${HOME_ACTIVE_TAB}=${HOME_TABS.past}`;
export const REWARDS_HISTORY_PAGE = '/rewards/history';
export const REWARDS_ONLINE_PAGE = '/rewards/online';
export const IN_STORE_PAGE = '/rewards/in-store';
export const REWARDS_PAGE = '/rewards';
export const INVEST_PAGE = '/invest';
export const REGISTRATION_FUNDING_ACCOUNT_SUCCESS =
  '/registration/banks/fundingAccount-success';
export const REGISTRATION_FUNDING_ACCOUNT_FAILED =
  '/registration/banks/fundingAccount-failed';
export const REGISTRATION_SPENDING_ACCOUNT_SUCCESS =
  '/registration/banks/spendingAccount-success';
export const SETTINGS = '/settings';
export const SPENDING_PAGE = '/settings/spending-account';
export const AUTH_OLD_APP = '/auth/login';
export const AUTH_OLD_APP_FULL = '/auth/login/';
export const REGISTRATION_OLD_APP_FULL = '/registration/c/start/';
export const REGISTRATION_OLD_APP = '/registration/c/start';
export const SUPER_REGISTRATION_PAGE = '/super/registration';
export const OFFSETTERS_PAGE = '/offsetters';
export const MY_FINANCE_PAGE = '/my-finance';
export const SUPER_PAGE = '/super';
export const KIDS_PAGE = '/raiz-kids';
export const PORTFOLIO_PAGE = '/portfolio';
export const PRO_PORTFOLIO_PAGE = '/portfolio';
export const PORTFOLIO_CUSTOMIZATION_PAGE = '/portfolio/customization/*';

export const TOP_ROUNDUP_TRESHOLD = 0.98;
export const BOTTOM_ROUNDUP_TRESHOLD = 0.02;
export const ROUNDUP_LIMIT = 25;

export const USER_TYPES = {
  smsf: 'smsf',
  dependent: 'dependent',
};

export const RAIZ_REWARD_PURE_PROFILE = 'raiz://raiz-rewards/pure_profile';

export const MOBILE_APP_HOME = 'raiz://home';
export const MOBILE_APP_HOME_MYS = 'raiz-my://home';

export const DOCUMENT_TYPES = {
  myKadFront: 'mykad_front',
  myKadBack: 'mykad_back',
  idDocument: 'id',
  idFront: 'id_front',
  idBack: 'id_back',
  selfiePhoto: 'selfie',
  proofOfAddress: 'proof_of_address',
};

export const AGREEMENT_TYPES = { superFund: 'superFund' };

export const IS_KIDS_ACCESS_PROVIDED = sessionStorage.getItem(
  SESSION_STORAGE_KEYS.kidsAccess,
);

export const CURRENT_LANG = localStorage.getItem('lang');

export const IS_DEPENDENT_USER_REGISTRATION = 'IS_DEPENDENT_USER_REGISTRATION';

export const DEFAULT_DEBOUNCE_TIME = 2000;

export const FREQUENCIES = {
  daily: 'daily',
  weekly: 'weekly',
  fortnightly: 'fortnightly',
  monthly: 'monthly',
};

export const FILES_BASE_URL = 'https://files.raizinvest.com.au';

export const CONTENT_MAX_WIDTH = 455;
