import Firebase from 'firebase/app';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';

import { AU_APP } from '../../constants/localeConfigs';
import {
  cancelTransaction,
  cancelTransactionSuper,
  closeModal,
  getAccountSummary,
  getInvestmentsOverview,
  getRewards,
  getTransactionDetails,
  getTransactionsHistory,
} from '../../store/history/actions';
import { getSuperUserData } from '../../store/user/actions';

import { HISTORY_STATUSES } from './consts';
import HistoryDetailsView from './HistoryDetailsView';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function HistoryDetails({ isKidsHistory, childId }) {
  const dispatch = useDispatch();
  const isSuper = Boolean(useMatch('/super/*'));

  const { transactions, historyStatus, offset } = useSelector((state) => ({
    transactions: state.history.transactions || [],
    historyStatus: state.history.historyStatus || HISTORY_STATUSES.unfinished,
    offset: state.history.offset || 0,
  }));

  const userId = isKidsHistory ? childId : '';
  const [isRecurringModalOpen, setIsRecurringModalOpen] = useState(false);

  const [isCancelTransactionLoading, setIsCancelTransactionLoading] =
    useState(false);

  const [isCancelTransactionModalOpen, setIsCancelTransactionModalOpen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [currentInfo, setCurrentInfo] = useState({ title: '', msg: '' });

  const [areFailedTransactionsVisible, setAreFailedTransactionsVisible] =
    useState(true);

  const [isShareRewardModalOpen, setIsShareRewardModalOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const historyParams = {
    limit: 20,
    isSuper,
    areFailedTransactionsVisible,
    isKidsHistory,
    userId,
    offset,
    historyStatus,
  };

  const checkIsLastTransactionInView = (inView) => {
    if (inView) {
      const totalPages = parseInt(
        sessionStorage.getItem(
          SESSION_STORAGE_KEYS.historyTransactionsTotalPages,
        ),
      );

      if (
        historyStatus === HISTORY_STATUSES.finished &&
        (offset === totalPages || totalPages === 0)
      ) {
        return;
      }

      dispatch(getTransactionsHistory(historyParams));
    }
  };

  useEffect(() => {
    dispatch(getInvestmentsOverview());

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactionsTotalPages,
      0,
    );

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactionsStatus,
      HISTORY_STATUSES.unfinished,
    );

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactions,
      JSON.stringify([]),
    );

    sessionStorage.setItem(SESSION_STORAGE_KEYS.isPreviousEventWithNote, false);

    dispatch(
      getTransactionsHistory({
        ...historyParams,
        offset: 0,
        historyStatus: HISTORY_STATUSES.unfinished,
      }),
    ).then((historyTransactions) => {
      if (!isSuper && historyTransactions[0]) {
        dispatch(
          getTransactionDetails({
            id: historyTransactions[0].id,
            isKidsHistory,
            userId,
          }),
        );
      }

      setIsLoading(false);
    });

    if (isSuper) {
      dispatch(getSuperUserData());

      dispatch(getAccountSummary(isSuper));
    }

    if (!AU_APP) {
      dispatch(getRewards());
    }

    window.scrollTo(0, 0);
    // historyParams is updated by the callback. Need to exclude it from the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSuper, userId, isKidsHistory]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const toggleShowFailedTransactions = () => {
    setAreFailedTransactionsVisible(!areFailedTransactionsVisible);

    setIsLoading(true);

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactions,
      JSON.stringify([]),
    );

    dispatch(
      getTransactionsHistory({
        ...historyParams,
        areFailedTransactionsVisible: !areFailedTransactionsVisible,
        offset: 0,
      }),
    ).then(() => {
      setIsLoading(false);
    });
  };

  const showTransactionDetail = (transaction) => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.isPreviousEventWithNote, false);

    Firebase.analytics().logEvent('History', {
      eventKey: 'Did select investment item',
    });

    if (isSuper) {
      return;
    }

    setIsLoading(true);

    dispatch(
      getTransactionDetails({
        id: transaction.id,
        isKidsHistory,
        userId,
      }),
    ).then(() => {
      setIsLoading(false);
    });
  };

  const handleCancelTransaction = (transaction) => {
    setIsCancelTransactionLoading(true);

    Firebase.analytics().logEvent('HistoryCancelConfirm');

    if (isSuper) {
      dispatch(cancelTransactionSuper(transaction.id)).then(() => {
        setIsCancelTransactionModalOpen(false);

        setIsCancelTransactionLoading(false);
      });
    } else {
      dispatch(
        cancelTransaction({
          id: transaction.id,
          isKidsHistory,
          userId,
        }),
      ).then(() => {
        setIsCancelTransactionModalOpen(false);

        setIsCancelTransactionLoading(false);
      });
    }
  };

  const displayCancelTransactionConfirmationAlert = (transactionAlertData) => {
    const { isTransactionModalOpen, transaction, confirm } =
      transactionAlertData;

    Firebase.analytics().logEvent('HistoryCancelInvest');

    if (!isTransactionModalOpen && !confirm) {
      setCurrentTransaction(null);

      setIsCancelTransactionModalOpen(isTransactionModalOpen);
    } else if (isTransactionModalOpen) {
      setCurrentTransaction(transaction);

      setIsCancelTransactionModalOpen(isTransactionModalOpen);
    } else if (!isTransactionModalOpen && confirm) {
      handleCancelTransaction(currentTransaction);
    }
  };

  const toggleInfoModal = (toggleInfoData) => {
    const { shouldOpenRecurringModal, title, msg } = toggleInfoData;

    setIsRecurringModalOpen(shouldOpenRecurringModal);

    setCurrentInfo({ title, msg });
  };

  const toggleShareRewardModal = () => {
    setIsShareRewardModalOpen(!isShareRewardModalOpen);
  };

  return (
    <HistoryDetailsView
      isLoading={isLoading}
      toggleShowFailedTransactions={toggleShowFailedTransactions}
      currentTransaction={currentTransaction}
      areFailedTransactionsVisible={areFailedTransactionsVisible}
      transactions={transactions}
      isCancelTransactionModalOpen={isCancelTransactionModalOpen}
      isCancelTransactionLoading={isCancelTransactionLoading}
      toggleInfoModal={toggleInfoModal}
      showTransactionDetail={showTransactionDetail}
      displayCancelTransactionConfirmationAlert={
        displayCancelTransactionConfirmationAlert
      }
      handleCloseModal={handleCloseModal}
      isSuper={isSuper}
      currentInfo={currentInfo}
      isRecurringModalOpen={isRecurringModalOpen}
      isShareRewardModalOpen={isShareRewardModalOpen}
      toggleShareRewardModal={toggleShareRewardModal}
      checkIsLastTransactionInView={checkIsLastTransactionInView}
    />
  );
}

HistoryDetails.defaultProps = {
  state: {},
  isKidsHistory: false,
  childId: '',
};

HistoryDetails.propTypes = {
  state: PropTypes.shape({}),
  isKidsHistory: PropTypes.bool,
  childId: PropTypes.string,
};
