import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/elements/Button';

class SuccessModal extends Component {
  render() {
    const { t, modalMessage, toggleModal, isOpenModal, modalTitle } =
      this.props;

    return (
      <div>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => toggleModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal time-investment__modal-quick"
        >
          <div className="time-investment__modal-title">{modalTitle}</div>

          <div className="time-investment__modal-content">{modalMessage}</div>

          <div className="time-investment__modal-footer -single-btn">
            <Button label={t('common.ok')} onClick={() => toggleModal(false)} />
          </div>
        </Modal>
      </div>
    );
  }
}

SuccessModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  modalMessage: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default withTranslation()(SuccessModal);
