import styled from '@emotion/styled';

import { Icon } from 'components/elements/Icon';

export const MainWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  text-align: left;
  margin-bottom: 40px;
  flex-wrap: wrap;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows.primary};
    border-radius: 6px;
    display: table;
    width: 100%;
    margin-bottom: 32px;
    padding: 25px 20px 0;
    overflow: hidden;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  flex-wrap: wrap;
  padding: 0;
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.semiBold};
    font-size: ${theme.fontSizes['5md']};
    font-weight: ${theme.fontWeights.extraBold};
  `}
`;

export const ContentContainer = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 14px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  display: flex;
  flex-wrap: wrap;
`;

export const ContentTitle = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin-bottom: 30px;
    color: ${theme.colors.mirage};
    font-size: ${theme.fontSizes['2md']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length['3md']};
    font-family: ${theme.fontFamily.medium};
  `}
`;

export const SecuredIcon = styled(Icon)`
  vertical-align: text-bottom;
  margin-right: 10px;
`;

export const ContentInfo = styled.div`
  max-width: 1150px;
`;

export const InfoIcon = styled(Icon)`
  background-color: transparent;
  min-width: 24px;
  min-height: 24px;
  margin-right: 12px;
  margin-top: -3px;
`;

export const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: self-start;
`;

export const FooterTitle = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes['6sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['3sm']};
    margin-bottom: 3px;
  `}
`;

export const FooterInfo = styled.div`
  ${({ theme }) => `
    opacity: ${theme.opacity.mediumTransparent};
    color: ${theme.colors.mirage};
    font-size: ${theme.fontSizes['4sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['3sm']};
  `}
`;

export const LoaderWrapper = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  margin-right: 20px;
  position: relative;
`;

export const CheckboxMainWrapper = styled.div`
  width: 100%;
  margin-top: 48px;
  margin-bottom: 18px;
  position: relative;
  box-sizing: border-box;
  text-align: left;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}) {
      margin: 50px 0;
    }
  `}
`;

export const CheckboxContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
