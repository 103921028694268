import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function UploadIdSuccessModal({ onClickSuccessModal }) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible
      isOpen
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-title">{t('uploadIdSuccessModal.title')}</div>

        <div className="alert-text">
          {t('uploadIdSuccessModal.description')}
        </div>

        <div className="error-buttons">
          <DeprecatedButton
            title={t('common.ok')}
            type="button"
            handleClick={onClickSuccessModal}
          />
        </div>
      </div>
    </Modal>
  );
}

UploadIdSuccessModal.propTypes = {
  onClickSuccessModal: PropTypes.func.isRequired,
};

export default UploadIdSuccessModal;
