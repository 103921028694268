const calculateCardsNumber = () => {
  const DEFAULT_NUMBER_OF_CARDS = 7;

  const width =
    document.getElementsByClassName('rewards__carousel')?.[0]?.offsetWidth;

  if (width) {
    const cardSize = width < 350 ? 170 : 160;

    return Math.floor(width / cardSize);
  }

  return DEFAULT_NUMBER_OF_CARDS;
};

export default calculateCardsNumber;
