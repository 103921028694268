import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Bathrooms,
  ComfortWrapper,
  ResidentialPropertyFundBottomWrapper,
  ResidentialPropertyFundDescription,
  ResidentialPropertyFundPrice,
  GarageCapacity,
  Rooms,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

export default function ResidentialPropertyFundItemBottom({ fund }) {
  const { t } = useTranslation();

  const {
    number_of_rooms,
    number_of_bathrooms,
    garage_capacity,
    price,
    description,
  } = fund || {};

  return (
    <ResidentialPropertyFundBottomWrapper>
      <ComfortWrapper>
        <Rooms>{number_of_rooms}</Rooms>

        <Bathrooms>{number_of_bathrooms}</Bathrooms>

        <GarageCapacity>{garage_capacity}</GarageCapacity>
      </ComfortWrapper>

      <ResidentialPropertyFundPrice
        i18nKey="portfolioCustomization.residentialPropertyFund.price"
        t={t}
        components={{
          price: formatNumber({
            value: price,
          }),
        }}
      />

      <ResidentialPropertyFundDescription>
        {description}
      </ResidentialPropertyFundDescription>
    </ResidentialPropertyFundBottomWrapper>
  );
}

ResidentialPropertyFundItemBottom.propTypes = {
  fund: PropTypes.shape({
    symbol: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
};
