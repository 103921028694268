import Skeleton from 'react-skeleton-loader';
import { range } from 'lodash';

import { Wrapper } from '../../styles';

export const RewardsSkeleton = () => (
  <Wrapper offersCount={10}>
    {range(10).map(() => (
      <Skeleton width="167px" height="200px" key={window.crypto.randomUUID()} />
    ))}
  </Wrapper>
);
