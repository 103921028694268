import styled from '@emotion/styled';
import Slider from 'react-rangeslider';

import { InputWrapper } from 'components/form/input/styles';
import { Button } from 'components/elements/Button';
import close from 'assets/images/icons/close.svg';
import checkGreen from 'assets/images/icons/check-green.svg';
import {
  GlobalInfoModalButtonClose,
  GlobalInfoModalTitle,
  GlobalInfoModalWrapper,
  GlobalInfoModalAcceptButton,
} from 'components/modals/InfoModal/styles';

export const InvestmentGoalModalWrapper = styled(GlobalInfoModalWrapper)`
  margin: 55px auto;
  padding: 0;
  flex-direction: column;
`;

export const InvestmentGoalModalHeader = styled.div`
  background: ${({ theme }) => theme.gradients.green};
  padding: 30px 0 104px;
`;

export const InvestmentGoalModalTitle = styled(GlobalInfoModalTitle)`
  color: ${({ theme }) => theme.colors.white};
`;

export const InvestmentGoalModalButtonClose = styled(
  GlobalInfoModalButtonClose,
)`
  background-image: url(${`${close}`});
  right: 32px;
`;

export const InvestmentGoalModalContent = styled.div`
  flex: 1;
  padding: 30px;
`;

export const SelectYourGoalWrapper = styled.div`
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  margin: -136px 0 24px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.lightPanel};
`;

export const GoalName = styled.div<{ isSelected: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 22px 0 6px 0;
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  cursor: pointer;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background: url(${`${checkGreen}`}) top 28px right no-repeat;
      color: ${theme.colors.niagara};
    `};
  :last-of-type {
    border: none;
  }
`;

export const AddCustomGoalName = styled.div`
  color: ${({ theme }) => theme.colors.niagara};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  padding: 22px 0 20px 0;
  cursor: pointer;
`;

export const CustomGoalName = styled.div`
  display: flex;
`;

export const CustomInputWrapper = styled(InputWrapper)`
  height: 64px;
  border: none;
  input {
    border: none;
    margin-bottom: 4px;
    padding-top: 34px;
  }
`;

export const SelectCustomName = styled.div`
  color: ${({ theme }) => theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  padding-top: 24px;
  cursor: pointer;
`;

export const SelectedCustomNameIcon = styled.div`
  background: url(${`${checkGreen}`}) top 28px right no-repeat;
  padding: 10px;
`;

export const InvestmentDeleteGoalAcceptButton = styled(
  GlobalInfoModalAcceptButton,
)`
  margin-top: 0;
`;

export const DeleteRecurringInvestmentsWrapper = styled.div`
  padding-top: 16px;
`;

export const RegistrationInvestmentButtonsWrapper = styled.div`
  display: flex;
  position: fixed;
  gap: 16px;
  bottom: 16px;
`;

export const RegistrationInvestmentButton = styled(Button)`
  border: 2px solid ${({ theme }) => theme.colors.alto};
  width: 166px;
`;

export const InvestmentGoalPreviewWrapper = styled.div`
  flex: 1;
  margin-top: 20px;
`;

export const InvestmentGoalSlider = styled(Slider)`
  margin: 60px 20px 6px 20px !important;
  .rangeslider__handle {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const InvestmentGoalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const InvestmentGoalInfoAmount = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
`;

export const RecurringInvestmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RecurringInvestmentPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvestmentPreviewTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  color: ${({ theme }) => theme.colors.grey};
  padding: 0 20px;
`;
export const InvestmentPreviewValue = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  padding: 0 20px;
`;

export const InvestmentAmountPreviewValue = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  padding: 0 20px;
`;

export const InvestmentPreviewRowWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 16px 0 12px 0;
  :last-of-type {
    border: none;
  }
`;

export const InvestmentPreviewFrequency = styled(InvestmentPreviewValue)`
  :first-letter {
    text-transform: capitalize;
  }
`;

export const NextInvestmentWrapper = styled.div`
  padding: 24px 0 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const RecurringInvestmentTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  color: ${({ theme }) => theme.colors.mirage};
`;

export const RecurringInvestmentContent = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.lightPanel};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 20px 0 16px 0;
`;
