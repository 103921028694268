import { useSearchParams } from 'react-router-dom';

import { SUPER_SEARCH_PARAM } from 'pages/Super/components/SuperMenu/consts';
import { CHILD_ID_SEARCH_PARAM } from 'pages/RaizKidsEdit/consts';
import { CUSTOM_HEADER, USER_TYPE } from 'services/api/consts';

export const useDividendsRequestHeaders = () => {
  const [searchParams] = useSearchParams();

  const isSuper = searchParams.get(SUPER_SEARCH_PARAM);

  const childId = searchParams.get(CHILD_ID_SEARCH_PARAM);

  return {
    headers: {
      ...(isSuper && { [CUSTOM_HEADER.userType]: USER_TYPE.superAnnuation }),
      ...(childId && {
        [CUSTOM_HEADER.userType]: USER_TYPE.dependent,
        [CUSTOM_HEADER.userUuid]: childId,
      }),
    },
  };
};
