import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { RaizTheme } from 'theme';
import { BaseButton } from 'components/elements/Deprecated__Button';

export const STATUS_BUTTON_SIZE = 120;

export const ChangeStatusWrapper = styled.div`
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 21px 16px 25px;
  display: flex;
  flex-direction: row;
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.primary};
  `};
`;

export const Title = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.semiBold};
    font-size: ${theme.fontSizes['2md']};
    font-weight: ${theme.fontWeights.semibold}
  `};
`;

export const StatusWrapper = styled.div`
  display: flex;
`;

export const Status = styled.div`
  border-radius: 40px;
  padding: 6px 18px;
  margin-top: 4px;
  ${({ theme }) => `
    font-family: ${theme.fontFamily.medium};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tight};
  `};
`;

export const StatusCreated = styled(Status)`
  ${({ theme }) => `
    background: ${theme.colors.athensGray};
    color: ${theme.colors.topaz};
  `};
`;

export const StatusHandled = styled(Status)`
  ${({ theme }) => `
    background:${theme.colors.bluishGreen};
    color: ${theme.colors.niagara};
`};
`;

export const StatusCanceled = styled(Status)`
  ${({ theme }) => `
    background: ${theme.colors.grenadierTransparent};
    color: ${theme.colors.grenadier};
  `};
`;

export const StatusButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0.5;
  gap: 16px;
  margin-top: 10px;
`;

export const ChangeStatusContent = styled.div`
  flex: 0.5;
`;

export const StatusViewButton = styled(BaseButton)`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.semiBold};
    font-weight: ${theme.fontWeights.semibold};
  `};
  flex: 1;
  max-width: ${STATUS_BUTTON_SIZE}px;
`;

interface ISpinnerStylesProps {
  theme: RaizTheme;
}

const spinnerStyles = ({ theme }: ISpinnerStylesProps) => css`
  .spinner > div {
    background: ${theme.colors.alto};
  }
`;

export const StatusCancelButton = styled(BaseButton)`
  flex: 1;
  max-width: ${STATUS_BUTTON_SIZE}px;
  ${spinnerStyles}
  ${({ theme }) => `
    color: ${theme.colors.grenadier};
    background: ${theme.colors.transparent};
    font-weight: ${theme.fontWeights.semibold};
    font-family: ${theme.fontFamily.semiBold};
    border: 1px solid ${theme.colors.alto};
  `};
`;

export const StatusDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  flex: 0.5;
  gap: 2px;
  margin-top: 8px;
  ${({ theme }) => `
    font-family: ${theme.fontFamily.medium};
    font-weight: ${theme.fontWeights.medium};
  `};
`;

export const Date = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['6sm']};
    color: ${theme.colors.grey};
  `};
`;
