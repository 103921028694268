import { Card, Content, Header, Title, SubAccountName } from './styles';

import { IInstitution } from 'store/institutions/types';
import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';

interface IBankCardProps {
  bank: IInstitution;
}

export const RewardAccountCard = ({ bank }: IBankCardProps) => (
  <Card>
    <Header>
      <ImageWithDefaultIcon
        defaultIcon="defaultBank"
        src={bank.icon || ''}
        width={35}
      />

      <Title>{bank.name}</Title>
    </Header>

    <Content>
      {bank?.subaccounts?.map(
        (account) =>
          account.linked && (
            <SubAccountName key={account?.account_id}>
              {account?.account_nickname}
            </SubAccountName>
          ),
      )}
    </Content>
  </Card>
);
