import { useLocation } from 'react-router-dom';

import { usePortfolioPath } from '../usePortfolioPath';
import { useDividendsPath } from '../useDividendsPath';

import { Item, MenuButton } from './styles';
import { INavigationItem } from './types';

export type ISuperMenuItemProps = {
  navigationItem: INavigationItem;
  openInvestLumpSumModal: (condition: boolean) => void;
  isInvestLumpSumModalOpen: boolean;
  toggleMenuItem: () => void;
};

const SUPER_ICON_SIZE = 20;

export const SuperMenuItem = ({
  navigationItem,
  openInvestLumpSumModal,
  isInvestLumpSumModalOpen,
  toggleMenuItem,
}: ISuperMenuItemProps) => {
  const { pathname: currentPathname } = useLocation();
  const { path, message, icon, isButton } = navigationItem;

  const { isSuperPortfolioActive } = usePortfolioPath();

  const { isSuperDividendsPath } = useDividendsPath();

  const isBasePathActive = (path as string)?.includes(currentPathname);

  const isActive =
    (currentPathname === path && !isInvestLumpSumModalOpen) ||
    (isInvestLumpSumModalOpen && !path) ||
    !!(isBasePathActive && isSuperPortfolioActive) ||
    !!(isBasePathActive && isSuperDividendsPath);

  if (isButton) {
    return (
      <MenuButton
        key={window.crypto.randomUUID()}
        label={message}
        icon={{
          name: icon,
          size: SUPER_ICON_SIZE,
        }}
        onClick={() => openInvestLumpSumModal(true)}
        isActive={isActive}
        variant="initial"
      />
    );
  }

  if (path) {
    return (
      <Item
        key={window.crypto.randomUUID()}
        href={path as string}
        onClick={toggleMenuItem}
        isActive={isActive}
        icon={{
          name: icon,
          size: SUPER_ICON_SIZE,
        }}
        label={message}
        variant="initial"
      />
    );
  }

  return null;
};
