import styled from '@emotion/styled';

import { APP_HEADER_HEIGHT } from './consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export const BUTTON_SIZE = 240;

interface IAmount {
  isSellTransaction?: boolean;
}

export const TransactionContent = styled.div`
  height: calc(100vh - ${APP_HEADER_HEIGHT}px);
  overflow: auto;
`;

export const RewardTransactionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AllocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
`;

export const HeaderLabel = styled.div`
  columns: 4;
  padding: 0 15px;
  justify-content: end;
  overflow: hidden;
  width: 100%;
  display: grid;
  ${({ theme }) => `
    font-size: ${theme.fontSizes['2sm']}!important;
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.length['5xl']};
    opacity: ${theme.opacity.mediumTransparent};
  `}
  :first-of-type {
    justify-content: start;
  }
`;

export const HeaderTypeLabel = styled(HeaderLabel)`
  columns: 2;
`;

export const AllocationItemsWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  :nth-of-type(even) {
    background: ${({ theme }) => theme.colors.athensGray};
  }
`;

export const AllocationItemsLabel = styled(HeaderLabel)`
  opacity: 1;
  font-size: ${({ theme }) => theme.fontSizes.md} !important;
`;

export const LetYourFriendsButton = styled(DeprecatedButton)`
  background-color: ${({ theme }) => theme.colors.niagaraLight};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.white};
  width: ${BUTTON_SIZE}px;
  text-transform: capitalize;
  margin-top: 20px;
`;

export const Amount = styled.div<IAmount>`
  ${({ theme, isSellTransaction }) => `
    color: ${isSellTransaction ? theme.colors.mirage : theme.colors.niagara};
    font-size: ${theme.fontSizes['3md']};
    font-weight: ${theme.fontWeights.semibold};
    line-height: ${theme.lineHeights.length['4xl']};
  `}
  margin: 0 0 2px auto;
`;

export const TransactionContentTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5xl']};
`;

export const TransactionTotal = styled.div`
  margin-top: 10px;
  padding: 0 15px;
`;

export const RebalanceAmount = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.lg}!important;
  font-weight: ${({ theme }) => theme.fontWeights.semibold}!important;
`;
