import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

import { Form } from './components/Form';
import { SuccessInfo } from './components/SuccessInfo';
import { useRecoverPasswordFormSchema } from './useRecoverPasswordFormSchema';
import {
  ExternalLinkStyled,
  Footer,
  Header,
  Instruction,
  Logo,
  Title,
  Wrapper,
} from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  usePostRecoverPasswordMutation,
  IPostRecoverPasswordError,
} from 'store/user/mutations';
import { SUPPORT } from 'constants/localeConfigs';
import { selectLoginEmail } from 'store/user/selectors';

export const RecoverPassword = () => {
  const { t } = useTranslation();

  const email: string = useAppSelector(selectLoginEmail);

  const defaultValues = { email };

  const schema = useRecoverPasswordFormSchema();

  const form = useForm({ defaultValues, resolver: yupResolver(schema) });

  const { handleSubmit, setError } = form;

  const [recoverPassword, { isLoading, isError, isSuccess, data, error }] =
    usePostRecoverPasswordMutation();

  const onSubmit = handleSubmit(recoverPassword);

  useEffect(() => {
    if (isError) {
      const response = error as IPostRecoverPasswordError;
      const message = response.data.error;

      if (message) {
        setError('email', {
          type: 'string',
          message,
        });
      }
    }
  }, [isError, error, setError]);

  const message = isSuccess && data?.message ? data.message : '';

  return (
    <FormProvider {...form}>
      <Wrapper>
        <Header>
          <Title>{t('recoverPasswordPage.title')}</Title>

          <Logo />

          {!isSuccess && (
            <Instruction>{t('recoverPasswordPage.instruction')}</Instruction>
          )}
        </Header>

        {isSuccess ? (
          <SuccessInfo message={message} />
        ) : (
          <Form isLoading={isLoading} onSubmit={onSubmit} />
        )}

        <Footer>
          <ExternalLinkStyled
            href={SUPPORT}
            title={t('recoverPasswordPage.contactUs')}
          />
        </Footer>
      </Wrapper>
    </FormProvider>
  );
};
