import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  height: unset;
  margin-bottom: 20px;
  padding: 30px 35px;
  width: 100%;
`;

export const BankGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(167px, 1fr));
`;

export const AddRoundUpAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 25px 0;
`;
