import { defineMessages } from 'react-intl';

import { KIDS_EDIT as idPrefix } from '../../constants/translates';

const auMessages = {
  title: {
    id: `${idPrefix}.title`,
    defaultMessage: 'Edit',
  },
  save: {
    id: `${idPrefix}.save`,
    defaultMessage: 'Save',
  },
  investNow: {
    id: `${idPrefix}.investNow`,
    defaultMessage: 'Invest Now',
  },
  viewPerformance: {
    id: `${idPrefix}.viewPerformance`,
    defaultMessage: 'View Performance',
  },
  viewHistory: {
    id: `${idPrefix}.viewHistory`,
    defaultMessage: 'View History',
  },
  savingsGoal: {
    id: `${idPrefix}.savingsGoal`,
    defaultMessage: 'Savings Goal',
  },
  portfolio: {
    id: `${idPrefix}.portfolio`,
    defaultMessage: 'Portfolio',
  },
  statements: {
    id: `${idPrefix}.statements`,
    defaultMessage: 'Statements',
  },
  settings: {
    id: `${idPrefix}.settings`,
    defaultMessage: 'Settings',
  },
  profile: {
    id: `${idPrefix}.profile`,
    defaultMessage: 'Profile',
  },
  investing: {
    id: `${idPrefix}.investing`,
    defaultMessage: 'Investing',
  },
  notification: {
    id: `${idPrefix}.notification`,
    defaultMessage: 'Notification',
  },
  withdraw: {
    id: `${idPrefix}.withdraw`,
    defaultMessage: 'Withdraw',
  },
  convertFullAccount: {
    id: `${idPrefix}.convertFullAccount`,
    defaultMessage: 'Convert to Full Account',
  },
  atYears: {
    id: `${idPrefix}.atYears`,
    defaultMessage: '(at 18 years)',
  },
  closeAccount: {
    id: `${idPrefix}.closeAccount`,
    defaultMessage: 'Close Account',
  },
  avatar: {
    id: `${idPrefix}.avatar`,
    defaultMessage: 'avatar',
  },
  name: {
    id: `${idPrefix}.name`,
    defaultMessage: 'Name',
  },
  dateBirth: {
    id: `${idPrefix}.dateBirth`,
    defaultMessage: 'Date of Birth',
  },
  access: {
    id: `${idPrefix}.access`,
    defaultMessage: 'Access',
  },
  savingGoals: {
    id: `${idPrefix}.savingGoals`,
    defaultMessage: 'Saving Goals',
  },
  ageYear: {
    id: `${idPrefix}.ageYear`,
    defaultMessage: '{name}, {age}yr',
  },
  recurringInvestment: {
    id: `${idPrefix}.recurringInvestment`,
    defaultMessage: 'Recurring Investment',
  },
  ageLessYear: {
    id: `${idPrefix}.ageLessYear`,
    defaultMessage: '{name}, < 1yr',
  },
  sendLink: {
    id: `${idPrefix}.sendLink`,
    defaultMessage: 'Send invitation link',
  },
  allowPortfolio: {
    id: `${idPrefix}.allowPortfolio`,
    defaultMessage:
      'Allow your Raiz Kid to manage or change the portfolio selection ',
  },
  allowRecurring: {
    id: `${idPrefix}.allowRecurring`,
    defaultMessage: 'Allow your Raiz Kid to manage this Recurring Investment ',
  },
  allowRewards: {
    id: `${idPrefix}.allowRewards`,
    defaultMessage: 'Allow your Raiz Kid to use Raiz Rewards',
  },
  notifyMe: {
    id: `${idPrefix}.notifyMe`,
    defaultMessage: 'Notify me...',
  },
  chooseAvatar: {
    id: `${idPrefix}.chooseAvatar`,
    defaultMessage: 'Choose an avatar',
  },
  birthday: {
    id: `${idPrefix}.birthday`,
    defaultMessage: 'Date of Birth',
  },
  allowRaizKid: {
    id: `${idPrefix}.allowRaizKid`,
    defaultMessage: 'Allow Raiz Kid to access',
  },
  kidEmail: {
    id: `${idPrefix}.kidEmail`,
    defaultMessage: 'Your Kid’s Email Address',
  },
  doNotAllow: {
    id: `${idPrefix}.doNotAllow`,
    defaultMessage: 'Do not allow Raiz Kid to access',
  },
  decide: {
    id: `${idPrefix}.decide`,
    defaultMessage:
      'Decide if your Raiz Kid can access this sub-account.<br>' +
      'If you enable access you can choose which features are enabled and define ' +
      'limits & notifications',
  },
  raizKidAccess: {
    id: `${idPrefix}.raizKidAccess`,
    defaultMessage: 'Raiz Kid Access',
  },
  investmentByKid: {
    id: `${idPrefix}.investmentByKid`,
    defaultMessage: 'Investment by this Raiz Kid',
  },
  weekly: {
    id: `${idPrefix}.weekly`,
    defaultMessage: 'Weekly limit',
  },
  allowed: {
    id: `${idPrefix}.allowed`,
    defaultMessage: 'Allowed',
  },
  notAllowed: {
    id: `${idPrefix}.notAllowed`,
    defaultMessage: 'Disallowed',
  },
  change: {
    id: `${idPrefix}.change`,
    defaultMessage: 'Change',
  },
  notAllowInvestment: {
    id: `${idPrefix}.notAllowInvestment`,
    defaultMessage: 'Do not allow investment',
  },
  allowUnlimited: {
    id: `${idPrefix}.allowUnlimited`,
    defaultMessage: 'Allow unlimited investments ',
  },
  allowWeekly: {
    id: `${idPrefix}.allowWeekly`,
    defaultMessage: 'Allow with a set weekly limit (min $5)',
  },
  weeklyLimit: {
    id: `${idPrefix}.weeklyLimit`,
    defaultMessage: 'Weekly investment limit',
  },
  raizRecurring: {
    id: `${idPrefix}.raizRecurring`,
    defaultMessage: 'Recurring investment',
  },
  raizRecurringInvAmount: {
    id: `${idPrefix}.raizRecurringInvAmount`,
    defaultMessage: 'Recurring Inv Amount',
  },
  frequency: {
    id: `${idPrefix}.frequency`,
    defaultMessage: 'Frequency',
  },
  select: {
    id: `${idPrefix}.select`,
    defaultMessage: 'Select',
  },
  initialInvestAmount: {
    id: `${idPrefix}.initialInvestAmount`,
    defaultMessage: 'Initial Investment Amount',
  },
  howMuch: {
    id: `${idPrefix}.howMuch`,
    defaultMessage:
      'How much would you like to invest now to get your Raiz Kids started?',
  },
  invest: {
    id: `${idPrefix}.invest`,
    defaultMessage: 'Invest',
  },
  investLumSum: {
    id: `${idPrefix}.investLumSum`,
    defaultMessage: 'Lump Sum Investment for {name}',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'Ok',
  },
  kidUpdate: {
    id: `${idPrefix}.kidUpdate`,
    defaultMessage: 'Successfully updated kid access',
  },
  kidProfileUpdateTitle: {
    id: `${idPrefix}.kidProfileUpdateTitle`,
    defaultMessage: 'Update Profile',
  },
  kidProfileUpdate: {
    id: `${idPrefix}.kidProfileUpdate`,
    defaultMessage: 'Successfully updated kid profile information',
  },
  transferIn: {
    id: `${idPrefix}.transferIn`,
    defaultMessage: 'Transfer In',
  },
  transferOut: {
    id: `${idPrefix}.transferOut`,
    defaultMessage: 'Transfer Out',
  },
  available: {
    id: `${idPrefix}.available`,
    defaultMessage: 'Available: {balance}',
  },
  transferMoneyFromAccount: {
    id: `${idPrefix}.transferMoneyFromAccount`,
    defaultMessage:
      'If you choose to transfer money from your Raiz Account to a Kids Account you may realise material gains or losses, if you believe they are material you should check with your tax advisor before proceeding.',
  },
  transferMoneyToAccount: {
    id: `${idPrefix}.transferMoneyToAccount`,
    defaultMessage:
      'If you choose to transfer money from a Kids Account to your Raiz Account you may realise material gains or losses, if you believe they are material you should check with your tax advisor before proceeding.',
  },
  transferAmount: {
    id: `${idPrefix}.transferAmount`,
    defaultMessage: 'Transfer Amount',
  },
  required: {
    id: `${idPrefix}.required`,
    defaultMessage: 'Transfer Amount is required',
  },
  congrats: {
    id: `${idPrefix}.congrats`,
    defaultMessage: 'Congrats!',
  },
  investmentWillTransferred: {
    id: `${idPrefix}.investmentWillTransferred`,
    defaultMessage:
      'Your investment will be transferred from your main account in 1-3 business days.',
  },
  reactivateAccount: {
    id: `${idPrefix}.reactivateAccount`,
    defaultMessage: 'Re-activate account',
  },
  areYouSureIn: {
    id: `${idPrefix}.areYouSureIn`,
    defaultMessage: 'Are you sure you want to transfer into your Kids account?',
  },
  areYouSureOut: {
    id: `${idPrefix}.areYouSureOut`,
    defaultMessage:
      'Are you sure you want to transfer out of your Kids account?',
  },
};

export default defineMessages(auMessages);
