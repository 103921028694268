import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  getPortfolioTypesIcon,
  getRegistrationPortfolioTypesIcon,
} from '../../utils';
import { ISwitcherItemBaseProps } from '../../types';

import { RaizTheme } from 'theme';
import CustomPortfolioSvg from 'assets/images/icons/custom-portfolio.svg';
import CustomPortfolioActiveSvg from 'assets/images/icons/custom-portfolio-active.svg';
import CustomPortfolioSavedSvg from 'assets/images/icons/custom-portfolio-saved.svg';
import NewCustomSvg from 'assets/images/icons/new-custom.svg';
import BetaBlackSvg from 'assets/images/icons/beta-black.svg';
import BetaWhiteSvg from 'assets/images/icons/beta-white.svg';
import CustomEditSvg from 'assets/images/icons/custom-edit.svg';

interface IThemeProps {
  theme: RaizTheme;
}

interface ICustomPortfolioIconBaseProps {
  isActive?: boolean;
  isSaved?: boolean;
  isWhite?: boolean;
  isEdit?: boolean;
}

type CustomPortfolioIconProps = Pick<
  ICustomPortfolioIconBaseProps,
  'isActive' | 'isSaved'
>;

type CustomBetaIconProps = Pick<
  ICustomPortfolioIconBaseProps,
  'isWhite' | 'isEdit'
>;

const customPortfolioNameInputStyles = ({ theme }: IThemeProps) => css`
  input {
    width: auto;
    max-width: 340px;
    height: 28px;
    padding-top: 0;
    text-align: center;
    border: 0;
    font-size: ${theme.fontSizes['4sm']};
    margin-left: 5px;

    ::placeholder {
      color: ${theme.colors.mirage};
      font-size: ${theme.fontSizes['4sm']};
      font-weight: ${theme.fontWeights.semibold};
      letter-spacing: ${theme.letterSpacing.normal};
      opacity: 1;
    }
  }
`;

export const CustomPortfolioIcon = styled.div<CustomPortfolioIconProps>`
  background-image: url(${`${CustomPortfolioSvg}`});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  height: 16px;
  width: 16px;
  float: left;
  margin-right: 5px;

  ${({ isActive }) =>
    isActive &&
    `
      background-image: url(${`${CustomPortfolioActiveSvg}`});
  `}

  ${({ isSaved }) =>
    isSaved &&
    `
      background-image: url(${`${CustomPortfolioSavedSvg}`});
  `}
`;

export const CustomAddIcon = styled.div`
  background-image: url(${`${NewCustomSvg}`});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  height: 16px;
  float: left;
  padding-left: 20px;
`;

export const ItemName = styled.div`
  float: left;
`;

export const CustomBetaIcon = styled.div<CustomBetaIconProps>`
  background-image: url(${`${BetaBlackSvg}`});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: 16px;
  float: right;
  padding-left: 45px;

  ${({ isWhite }) =>
    isWhite &&
    `
      background-image: url(${`${BetaWhiteSvg}`});
  `}

  ${({ isEdit }) =>
    isEdit &&
    `
      margin-top: 5px;
  `}
`;

export const CustomEditIcon = styled.div`
  background-image: url(${`${CustomEditSvg}`});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
  height: 12px;
  width: 12px;
  float: left;
  margin-top: 8px;
  margin-left: 0;
`;

export const CustomPortfolioName = styled.div`
  display: inline-flex;

  ${customPortfolioNameInputStyles}
`;

const customItemStyles = ({ theme }: IThemeProps) => `
  ${theme.mediaQuery(theme.breakpoints.wide)} {
    width: 100%;
  }
`;

const customPortfolioTypesIconStyles = `
  background-repeat: no-repeat;
  background-position: calc(50% - 36px) 7px;
  width: 116px;
`;

export const ItemWrapper = styled.div<ISwitcherItemBaseProps>`
  background: ${({ theme }) => theme.colors.transparent};
  color: inherit;
  font-weight: inherit;
  padding: 0 12px;

  ${({ theme, isActive }) =>
    isActive &&
    `
      background-color: ${theme.colors.white};
      border-radius: 15px;
      color: ${theme.colors.mirage};
  `}

  ${({ portfolioTypes, isActive, isSaved }) => `
    background-image: url(${`${getPortfolioTypesIcon({
      portfolioTypes,
      isActive,
      isSaved,
    })}`});
    ${customPortfolioTypesIconStyles}
  `}

  ${customItemStyles}
`;

export const ItemButton = styled.button<ISwitcherItemBaseProps>`
  background-color: initial;
  color: ${({ theme }) => theme.colors.white};
  height: 30px;
  line-height: ${({ theme }) => theme.lineHeights.number.lg};

  ${({ theme, isActive }) =>
    isActive &&
    `
      color: ${theme.colors.mirage};
    `}
`;

const customRegistrationPortfolioTypesIconStyles = `
  background-repeat: no-repeat;
  background-position: 6px 5px;
`;

export const RegistrationWrapper = styled.div<ISwitcherItemBaseProps>`
  padding: 0 12px;
  font-weight: inherit;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.mirage};
  background: none;
  padding: 0;
  width: 16.66%;

  ${({ portfolioTypes, isActive }) => `
    background-image: url(${`${getRegistrationPortfolioTypesIcon({
      portfolioTypes,
      isActive,
    })}`});
    ${customRegistrationPortfolioTypesIconStyles}
  `}
`;

export const RegistrationButton = styled.button<ISwitcherItemBaseProps>`
  background-color: initial;
  color: ${({ theme }) => theme.colors.white};
  height: 30px;
  line-height: ${({ theme }) => theme.lineHeights.number.lg};

  ${({ theme, isRegistration }) =>
    isRegistration &&
    `
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.semibold};
    width: 100%!important;
  `}
`;

export const ItemBorder = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.tealGreen};
  height: 20px;
  width: 20px;
  border-radius: 20px;
  margin: 0 auto;
`;

export const ItemBullet = styled.span<ISwitcherItemBaseProps>`
  background-color: ${({ theme }) => theme.colors.tealGreen};
  height: 8px;
  margin-left: 4px;
  margin-top: 4px;
  width: 8px;
  border-radius: 20px;
  display: block;
  padding: 4px;
`;
