import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/elements/Button';

interface IChangePasswordSuccessModal {
  isShowSuccessModal: boolean;
  redirectToLogin: () => void;
}

const ChangePasswordSuccessModal = ({
  isShowSuccessModal,
  redirectToLogin,
}: IChangePasswordSuccessModal) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isShowSuccessModal}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-content">
        {t('changePasswordPage.successModal.title')}
      </div>

      <div className="time-investment__modal-footer -single-btn">
        <Button label={t('common.ok')} onClick={() => redirectToLogin()} />
      </div>
    </Modal>
  );
};

export default ChangePasswordSuccessModal;
