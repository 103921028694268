import { getDependencyUser } from '../actions';

import { api } from 'store/api';

export interface IUploadBirthCertificateParams {
  dependentUserId: string;
  file: File;
}

export type IUploadBirthCertificateResponse = {
  message: string;
};

export type IUploadBirthCertificateError = {
  data: { error?: string };
};

export const uploadBirthCertificateApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadBirthCertificate: build.mutation<
      IUploadBirthCertificateResponse,
      IUploadBirthCertificateParams
    >({
      query: ({ dependentUserId, file }) => {
        const data = new FormData();

        data.append('file', file, file.name);

        data.append('dependent_user_id', dependentUserId);

        data.append('document_type', 'bc');

        return {
          url: '/dependency_users/v1/users/upload',
          method: 'POST',
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      onQueryStarted: async (
        { dependentUserId },
        { dispatch, queryFulfilled },
      ) => {
        try {
          await queryFulfilled;

          dispatch(getDependencyUser(dependentUserId));
        } catch (error) {
          //
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useUploadBirthCertificateMutation } = uploadBirthCertificateApi;

export const {
  endpoints: { uploadBirthCertificate },
} = uploadBirthCertificateApi;
