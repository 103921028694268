import { CLOSE_MODAL } from '../commonTypes';

import {
  SHOW_ERROR_MODAL,
  GET_REWARDS_SUCCESS,
  SHOW_HISTORY_DETAIL_MODAL,
  GET_ACCOUNT_SUMMARY_SUCCESS,
  GET_INVESTMENTS_OVERVIEW_SUCCESS,
  GET_HISTORY_TRANSACTIONS_SUCCESS,
  SET_HISTORY_PARAMS,
} from './consts';

const initialState = {
  accountSummary: {
    deposits: {},
    gain_loss: {},
    withdrawals: {},
    reinvested_dividends: {},
    other_rewards_rewarded: {},
    total_referrals_rewarded: {},
    total_found_money_rewarded: {},
  },
  transactionDetails: { allocations: [] },
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_TRANSACTIONS_SUCCESS:
      if (action.isShowDetails) {
        return {
          ...state,
          type: action.type,
          transactions: action.transactions,
        };
      }

      return {
        ...state,
        type: action.type,
        transactions: action.transactions,
      };

    case SHOW_HISTORY_DETAIL_MODAL:
      return {
        ...state,
        transactionDetails: action.transactionDetails,
      };
    case SHOW_ERROR_MODAL:
      return {
        ...state,
        isShowErrorModal: true,
        errorMessage: action.errorMessage,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        errorMessage: '',
        isShowErrorModal: false,
      };
    case GET_INVESTMENTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        investmentsOverview: action.investmentsOverview,
      };
    case GET_ACCOUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        accountSummary: action.accountSummary,
      };
    case GET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: action.rewards,
      };
    case SET_HISTORY_PARAMS:
      return {
        ...state,
        historyStatus: action.historyStatus,
        offset: action.historyOffset,
      };
    default:
      return state;
  }
}
