import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { useTranslation } from 'react-i18next';

import {
  SuperInsuranceContentWrapper,
  InsuranceDefaultCoverWrapper,
  InsuranceInfoWrapper,
  CoverHeader,
  CoverAmountWrapper,
  InsuranceStatusText,
  InsuranceStatusLabel,
} from '../styles';

import {
  CoverageOfferedSkeletonWrapper,
  InsuranceCoverageInfoSkeleton,
  InsuranceSkeletonButtonWrapper,
  StatusSkeletonWrapper,
} from './styles';

export function SuperInsuranceSkeleton() {
  const { t } = useTranslation();

  return (
    <SuperInsuranceContentWrapper>
      <InsuranceDefaultCoverWrapper>
        <CoverHeader>{t('superInsurancePage.defaultCover.title')}</CoverHeader>

        <CoverAmountWrapper>
          <Skeleton />
        </CoverAmountWrapper>

        <CoverAmountWrapper>
          <Skeleton />
        </CoverAmountWrapper>
      </InsuranceDefaultCoverWrapper>

      <InsuranceInfoWrapper>
        <StatusSkeletonWrapper>
          <InsuranceStatusText>
            <InsuranceStatusLabel>
              <Skeleton />
            </InsuranceStatusLabel>
          </InsuranceStatusText>

          <InsuranceSkeletonButtonWrapper>
            <Skeleton height="44px" borderRadius="40px" />
          </InsuranceSkeletonButtonWrapper>
        </StatusSkeletonWrapper>

        <CoverageOfferedSkeletonWrapper>
          <Skeleton />

          <Skeleton />

          <Skeleton />
        </CoverageOfferedSkeletonWrapper>

        <InsuranceCoverageInfoSkeleton>
          <Skeleton height="30px" />
        </InsuranceCoverageInfoSkeleton>

        <InsuranceCoverageInfoSkeleton>
          <Skeleton height="30px" />
        </InsuranceCoverageInfoSkeleton>
      </InsuranceInfoWrapper>
    </SuperInsuranceContentWrapper>
  );
}
