import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../../store/user/actions';

import '../../../styles/layout/logout.scss';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

class LogoutModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
    };

    this.logoutUser = this.logoutUser.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  logoutUser() {
    this._isMounted = true;

    const { actionsUser } = this.props;

    this.setState({ showLoader: true });

    actionsUser.logout().then(() => {
      sessionStorage.clear();

      localStorage.clear();

      if (this._isMounted) {
        actionsUser.closeLogoutModal();

        this.setState({ showLoader: false });
      }
    });
  }

  render() {
    const { isOpenLogout, toggleLogoutModal, t } = this.props;
    const { showLoader } = this.state;

    return (
      <Modal
        isOpen={isOpenLogout}
        onRequestClose={() => toggleLogoutModal(false)}
        contentLabel="logout-modal"
        shouldCloseOnOverlayClick
        className="modal-basic logout-modal"
      >
        <DeprecatedButton
          buttonClass="button__close -grey"
          handleClick={() => toggleLogoutModal(false)}
        />

        <div className="logout-modal__title">
          <i className="logout-icon" />

          {t('logoutModal.title')}
        </div>

        <div className="logout-modal__info">{t('logoutModal.description')}</div>

        <div className="logout-modal__footer">
          <Button
            isLoading={showLoader}
            label={t('common.logout')}
            onClick={this.logoutUser}
          />

          <DeprecatedButton
            title={t('common.cancel')}
            buttonClass="button__basic-fill -white -grey-border"
            handleClick={() => toggleLogoutModal(false)}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  errorMessage: state.user.errorMessage,
  unauthorized: state.unauthorized,
});

const mapDispatchToProps = (dispatch) => ({
  actionsUser: bindActionCreators(userActions, dispatch),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LogoutModal),
);

LogoutModal.defaultProps = {
  actionsUser: {},
};

LogoutModal.propTypes = {
  toggleLogoutModal: PropTypes.func.isRequired,
  isOpenLogout: PropTypes.bool.isRequired,
  actionsUser: PropTypes.shape({
    logout: PropTypes.func,
    closeLogoutModal: PropTypes.func,
  }),
  t: PropTypes.func.isRequired,
};
