import { useTranslation } from 'react-i18next';

import { usePortfolioCustomizationRoute } from '../../hooks';

import FundsTags from './FundsTags/FundsTags';
import PortfolioCustomizationTabs from './PortfolioCustomizationTabs';
import {
  PortfolioCustomizationHeaderBackgroundImage,
  PortfolioCustomizationHeaderWrapper,
  PortfolioCustomizationTitle,
  PortfolioCustomizationTitleWrapper,
} from './styles';

import { usePortfolioColor } from 'pages/Portfolio/hooks';
import PortfolioBackButton from 'pages/Portfolio/components/PortfolioBackButton/PortfolioBackButton';

export default function PortfolioCustomizationHeader() {
  const { t } = useTranslation();
  const { portfolioColor } = usePortfolioColor();

  const {
    isBitcoinFundPage,
    isResidentialPropertyFundsDetailsPage,
    isResidentialPropertyFundsListPage,
  } = usePortfolioCustomizationRoute();

  const isWithoutBottomRadius =
    isBitcoinFundPage ||
    isResidentialPropertyFundsDetailsPage ||
    isResidentialPropertyFundsListPage;

  return (
    <PortfolioCustomizationHeaderWrapper
      isWithoutBottomRadius={isWithoutBottomRadius}
      portfolioColor={portfolioColor}
    >
      <PortfolioCustomizationHeaderBackgroundImage />

      <PortfolioCustomizationTitleWrapper>
        <PortfolioBackButton />

        <PortfolioCustomizationTitle>
          {t('portfolioCustomization.header')}
        </PortfolioCustomizationTitle>
      </PortfolioCustomizationTitleWrapper>

      <FundsTags />

      <PortfolioCustomizationTabs />
    </PortfolioCustomizationHeaderWrapper>
  );
}
