import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showGlobalError } from 'store/errors/actions';
import { showErrorModal } from 'store/history/actions';
import { Button } from 'components/elements/Button';

function ErrorModal({ errorMessage, toggleErrorModal, isOpenErrorModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    toggleErrorModal(false);

    dispatch(showGlobalError(''));

    dispatch(showErrorModal(''));
  };

  return (
    <div>
      <Modal
        isOpen={isOpenErrorModal}
        onRequestClose={() => toggleErrorModal(false)}
        contentLabel="time-investment-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-content">{errorMessage}</div>

        <div className="time-investment__modal-footer -single-btn">
          <Button label={t('common.ok')} onClick={handleClose} />
        </div>
      </Modal>
    </div>
  );
}

ErrorModal.propTypes = {
  isOpenErrorModal: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  toggleErrorModal: PropTypes.func.isRequired,
};

export default ErrorModal;
