import { StatusContent } from './components/StatusContent';

import { useVerificationStatusQuery } from 'store/fundingAccount/fundingAccount';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectIsIdentityVerificationCOFA,
  selectIsNewFundingChangeable,
} from 'store/firebaseConfig/selectors';

export const FundingAccountChangeStatus = () => {
  const isNewFundingChangeable = useAppSelector(selectIsNewFundingChangeable);

  const isIdentityVerificationCOFA = useAppSelector(
    selectIsIdentityVerificationCOFA,
  );

  const skip = !isNewFundingChangeable || !isIdentityVerificationCOFA;

  const { data } = useVerificationStatusQuery(undefined, {
    skip,
  });

  const status = data?.status;

  if (!status) {
    return null;
  }

  return <StatusContent />;
};
