// TODO Refactor Round Ups Info to Typescript
// https://acornsau.atlassian.net/browse/RAIZ-7000
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAccountSummaryRound,
  getUserData,
  sendInvests,
} from '../../../store/user/actions';
import {
  COMPLEMENT_CREDIT,
  MAX_INVEST,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
  REGISTRATION_INCOMPLETE_LINKSPENDING,
} from '../../../constants/comonConstants';
import { ROUND_UP_AUTO_ON } from '../../../constants/togglesFunctions';
import FundingInfoModal from '../Modals/FundingInfoModal';

import { AutomaticRoundUps } from './components/AutomaticRoundUps';
import { ManualRoundUps } from './components/ManualRoundUps';
import RoundUpsInvestModal from './components/RoundUpsInvestModal';
import {
  Wrapper,
  Content,
  Title,
  Description,
  Value,
  Container,
} from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useRoundUpsSettingsQuery } from 'store/roundUpsSettings/roundUpsSettings';
import { formatNumber } from 'utils/formatters/formatNumber';

function RoundUpsInfo({ accessInvesting, toggleKidsAccessRejectionModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { isLoading } = useRoundUpsSettingsQuery();

  const { user } = useSelector((state) => ({
    user: state.user.user,
  }));

  useEffect(() => {
    dispatch(getAccountSummaryRound());

    dispatch(getUserData());
  }, [dispatch]);

  const handleInvest = (isInvestment = false) => {
    const data = {
      investment: {
        amount: MAX_INVEST - user.round_ups.waiting,
        type: COMPLEMENT_CREDIT,
      },
    };

    if (user.round_ups.waiting > 0 || isInvestment) {
      dispatch(sendInvests(data)).then(() => {
        dispatch(getUserData());

        setIsConfirmModalOpen(false);
      });
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  const isEmptyFunding = user.alerts
    ? user.alerts.find(
        (alertMsg) => alertMsg.type === REGISTRATION_INCOMPLETE_LINKFUNDING,
      )
    : false;

  const isEmptySpending = user.alerts
    ? user.alerts.find(
        (alertMsg) => alertMsg.type === REGISTRATION_INCOMPLETE_LINKSPENDING,
      )
    : false;

  return (
    <Wrapper>
      <Content>
        <Title>{t('roundUps.title')}</Title>

        <Description>{t('roundUps.subtitle')}</Description>

        <Value>
          {formatNumber({
            value:
              user.round_ups && user.round_ups.invested
                ? user.round_ups.invested
                : 0,
          })}
        </Value>

        <Container isCentered={!ROUND_UP_AUTO_ON}>
          <AutomaticRoundUps isLoading={isLoading} />

          <ManualRoundUps
            setIsConfirmModalOpen={setIsConfirmModalOpen}
            accessInvesting={accessInvesting}
            toggleKidsAccessRejectionModal={toggleKidsAccessRejectionModal}
            isLoading={isLoading}
          />
        </Container>

        {accessInvesting ? (
          <DeprecatedButton
            isLink
            to="/round-ups"
            buttonClass="button__basic-fill -white-inverse -link"
            title={t('common.details')}
          />
        ) : (
          <DeprecatedButton
            handleClick={() => toggleKidsAccessRejectionModal(true)}
            buttonClass="button__basic-fill -white-inverse -link"
            title={t('common.details')}
          />
        )}

        {isConfirmModalOpen && (
          <>
            {(isEmptyFunding || isEmptySpending) && (
              <FundingInfoModal
                toggleInfoModal={() => setIsConfirmModalOpen(false)}
                isEmptyFunding={isEmptyFunding}
                isEmptySpending={isEmptySpending}
                isInfoModalOpen={
                  (isEmptyFunding &&
                    Object.keys(isEmptyFunding).length !== 0) ||
                  (isEmptySpending && Object.keys(isEmptySpending).length !== 0)
                }
              />
            )}

            {!isEmptyFunding && !isEmptySpending && (
              <RoundUpsInvestModal
                isConfirmModalOpen={isConfirmModalOpen}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
                handleInvest={handleInvest}
                waitingRoundUps={user.round_ups.waiting}
              />
            )}
          </>
        )}
      </Content>
    </Wrapper>
  );
}

export default RoundUpsInfo;

RoundUpsInfo.defaultProps = {
  toggleKidsAccessRejectionModal: () => {},
  accessInvesting: true,
};

RoundUpsInfo.propTypes = {
  toggleKidsAccessRejectionModal: PropTypes.func,
  accessInvesting: PropTypes.bool,
};
