import { useState } from 'react';
import dayjs from 'dayjs';

import { IToggleFrequencyModal } from '../types';

import { useRecurringInvestment } from './useRecurringInvestment';

import { ISO_DATE_FORMAT } from 'config/date';

export const useInvestmentFrequency = () => {
  const { recurring } = useRecurringInvestment();
  const { day, frequency_identifier, start_date } = recurring || {};

  const [currentFrequency, setCurrentFrequency] =
    useState(frequency_identifier);

  const [isFrequencyModalVisible, setIsFrequencyModalVisible] = useState(false);
  const [frequencyDay, setFrequencyDay] = useState(day);
  const [startDate, setStartDate] = useState(start_date);

  const toggleFrequencyModal: IToggleFrequencyModal = (
    condition,
    frequency,
    dayOfFrequency,
    dateOfStart,
  ) => {
    setIsFrequencyModalVisible(condition);

    if (frequency) {
      setCurrentFrequency(frequency);
    }

    if (dayOfFrequency) {
      setFrequencyDay(dayOfFrequency);
    }

    if (dateOfStart) {
      setStartDate(dayjs(dateOfStart).format(ISO_DATE_FORMAT));
    }
  };

  return {
    currentFrequency,
    setCurrentFrequency,
    isFrequencyModalVisible,
    startDate,
    frequencyDay,
    toggleFrequencyModal,
  };
};
