import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { Button } from 'components/elements/Button';

function InfoAlertModal({
  isInfoAlertModalOpen,
  toggleAlertInfoModal,
  currentAlert,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isInfoAlertModalOpen}
      onRequestClose={() => toggleAlertInfoModal(false, currentAlert)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick -left-align"
    >
      <div className="time-investment__modal-title">{currentAlert.title}</div>

      <div className="time-investment__modal-content">
        {currentAlert.description}
      </div>

      <div className="time-investment__modal-footer -single-btn">
        <Button
          label={t('common.ok')}
          onClick={() => toggleAlertInfoModal(false, currentAlert)}
        />
      </div>
    </Modal>
  );
}

InfoAlertModal.propTypes = {
  isInfoAlertModalOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  currentAlert: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default InfoAlertModal;
