import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';

import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import DeprecatedDotsLoader from '../../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { MY_APP } from '../../../constants/localeConfigs';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (MY_APP && values.email !== values.confirmEmail) {
      errors.confirmEmail = t('fields.validation.bothEmailMustMatch');
    }

    if (!values.password) {
      errors.password = t('fields.validation.cannotBeEmpty');
    } else if (values.password.length < 8) {
      errors.password = t('fields.validation.minimum8Characters');
    } else {
      const patternCapital = /[A-Z]/;
      const patternLower = /[a-z]/;
      const patternNumber = /[0-9]/;

      if (!patternCapital.test(values.password)) {
        errors.password = t('fields.validation.shouldContain');
      } else if (!patternLower.test(values.password)) {
        errors.password = t('fields.validation.shouldContainLetter');
      } else if (!patternNumber.test(values.password)) {
        errors.password = t('fields.validation.shouldContainOne');
      }
    }

    return errors;
  };

  return { validate };
};

export default function LoginForRegistrationForm({
  errorMessage,
  isRegistration,
  handleChange,
  handleSubmit,
  loginData,
  toggleShowPassword,
  isShowPassword,
  goToLogin,
  goToRegistration,
  showLoader,
  showDotsLoader,
  activeFieldInfo,
  validEmail,
  validConfirmEmail,
  isInvite,
  currentInviteCode,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();
  let hideRemember = false;

  if (errorMessage && errorMessage !== 'Unauthorized') {
    hideRemember = true;
  }

  let autoFocusPassword = false;
  let autoFocusEmail = false;

  if (activeFieldInfo === 'email') {
    autoFocusEmail = true;
  }

  if (activeFieldInfo === 'password') {
    autoFocusPassword = true;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          autoComplete="off"
          className={`form ${MY_APP ? '-with-email-confirmation' : ''}`}
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
          onChange={(e) => handleChange(e)}
        >
          {isInvite && (
            <div>
              <div className="invite-content__form-header">
                {t('login.getRaizNow')}
              </div>

              <div className="invite-content__form-title">
                {t('login.inviteCode', { number: currentInviteCode })}
              </div>
            </div>
          )}

          <FinalFormInput
            name="email"
            showValidEmail={validEmail}
            autoFocus={autoFocusEmail}
            defaultValue={loginData.email}
            label={t('login.email')}
          />

          {MY_APP && isRegistration && (
            <FinalFormInput
              name="confirmEmail"
              showValidEmail={validConfirmEmail}
              label={t('login.typeEmailAgain')}
            />
          )}

          <FinalFormInput
            name="password"
            defaultValue={loginData.password}
            autoFocus={autoFocusPassword}
            label={t('login.password')}
            showPassword={toggleShowPassword}
            type={isShowPassword ? 'password' : 'text'}
            isShowPasswordToggle
          />

          <div className={`"form-error login-error show-${hideRemember}`}>
            <span />

            {errorMessage}
          </div>

          {!isRegistration && (
            <div className={`"form-field checkbox hide-${hideRemember}`}>
              <div className="form-field__sub-input-link">
                {!isRegistration && (
                  <CheckboxFF
                    name="remember"
                    classList="checkbox__list"
                    defaultChecked={loginData.remember}
                    label={t('login.remember')}
                  />
                )}
              </div>

              <div className="form-field__sub-input-link">
                {!isRegistration && (
                  <Link
                    className="form-field__link forgot-your-password"
                    to="/reminder"
                  >
                    {t('login.forgot')}
                  </Link>
                )}
              </div>
            </div>
          )}

          {isRegistration && !hideRemember && (
            <div className="login-page__help">
              {t('login.useLettersCapitalNumbers')}
            </div>
          )}

          <div className="form-field">
            {showLoader || (isRegistration && showDotsLoader) ? (
              <DeprecatedButton
                buttonClass="button button--submit button-text"
                handleClick={() => {}}
                title={<DeprecatedDotsLoader additionalClass="btn" />}
              />
            ) : (
              <button
                type="submit"
                disabled={
                  MY_APP ? !(validEmail && validConfirmEmail) : !validEmail
                }
                className="button button--submit button-text"
              >
                {isRegistration &&
                  !showDotsLoader &&
                  !isInvite &&
                  t('login.createAccount')}

                {isRegistration &&
                  !showDotsLoader &&
                  isInvite &&
                  t('common.next')}

                {isRegistration && showDotsLoader && (
                  <DeprecatedDotsLoader additionalClass="btn" />
                )}

                {!isRegistration && t('login.login')}
              </button>
            )}
          </div>

          {!isInvite && (
            <div className="form-field">
              <div className="form-field__submit-hint registration-link">
                {isRegistration && (
                  <Link
                    onClick={goToLogin}
                    className="form-field__link link-text"
                    to="/login"
                  >
                    {t('login.already')}
                  </Link>
                )}

                {!isRegistration && (
                  <Link
                    onClick={goToRegistration}
                    className="form-field__link link-text"
                    to="/registration"
                  >
                    {t('login.register')}
                  </Link>
                )}
              </div>
            </div>
          )}
        </form>
      )}
    />
  );
}

LoginForRegistrationForm.defaultProps = {
  errorMessage: '',
  isRegistration: false,
  validEmail: false,
  validConfirmEmail: false,
  handleSubmit() {},
};

LoginForRegistrationForm.propTypes = {
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  activeFieldInfo: PropTypes.string.isRequired,
  currentInviteCode: PropTypes.string.isRequired,
  isRegistration: PropTypes.bool,
  validEmail: PropTypes.bool,
  validConfirmEmail: PropTypes.bool,
  loginData: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    remember: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showDotsLoader: PropTypes.bool.isRequired,
  isInvite: PropTypes.bool.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  goToRegistration: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
};
