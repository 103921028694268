import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Firebase from 'firebase/app';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../../store/user/actions';
import { AU_APP, COUNTRY, MY_APP } from '../../../constants/localeConfigs';
import {
  ACCOUNT_REGISTRATION,
  AUS,
  BAHASA,
  MYS,
  REGISTRATION,
  DOCUMENT_TYPES,
} from '../../../constants/comonConstants';
import {
  REGISTRATION_ACCOUNT_MYKAD,
  UPLOAD_FAILED,
} from '../../../store/user/consts';
import HelpCardsModalAU from '../../../components/layout/Modals/HelpCardsModalAU';
import { checkIsDependentUser } from '../../../utils/user';
import { getLeftPartIconClassName } from '../utils/utils';

import UploadID from './UploadID';
import ProgressBar from './ProgressBar';
import Information from './Information';
import Agreement from './Agreement';

import FinancialSuitability from 'pages/Settings/components/Forms/FinancialSuitability';
import Portfolio from 'pages/Portfolio/Portfolio';
import PortfolioCustomization from 'pages/Portfolio/subpages/PortfolioCustomization/PortfolioCustomization';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';
import { selectIsSuperFundUserEnabled } from 'store/firebaseConfig/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModal: false,
      errorMessageKadFront: false,
      errorMessageKadBack: false,
      errorMessageKadSelfie: false,
      yearsOld: false,
      superFund: false,
      agreementDisabled: true,
      isShowMyKadModal: false,
      isShowHelpCardInfo: false,
      isOpenChooseModal: false,
      showCheckboxes: false,
    };

    this.updateErrorsBack = this.updateErrorsBack.bind(this);

    this.updateErrorsFront = this.updateErrorsFront.bind(this);

    this.updateErrorsSelfie = this.updateErrorsSelfie.bind(this);

    this.submitDocument = this.submitDocument.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.linkWithdrawal = this.linkWithdrawal.bind(this);

    this.openChooseModal = this.openChooseModal.bind(this);

    this.showHelpCardInfo = this.showHelpCardInfo.bind(this);

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.goToAccountFunding = this.goToAccountFunding.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getRequiredDocuments();

    setTimeout(() => {
      this.setState({ showCheckboxes: true });
    }, 1000);
  }

  handleChangeAgreement = (e) => {
    const checkedFlag = e && e.target ? e.target.checked : !!e;
    const flagType = e && e.target ? e.target.name : e;

    if (checkedFlag) {
      this.setState({
        agreementDisabled: false,
      });
    } else {
      this.setState({
        agreementDisabled: true,
      });
    }

    if (flagType === 'yearsOld') {
      this.setState({
        yearsOld: checkedFlag,
        superFund: checkedFlag ? !checkedFlag : checkedFlag,
      });
    } else {
      this.setState({
        yearsOld: checkedFlag ? !checkedFlag : checkedFlag,
        superFund: checkedFlag,
      });
    }

    sessionStorage.setItem(SESSION_STORAGE_KEYS.agreementType, flagType);
  };

  handleDsagree = () => {
    this.setState({
      isShowModal: true,
    });
  };

  handleCloseModal() {
    this.setState({
      isShowModal: false,
      isShowMyKadModal: false,
    });
  }

  getPdsUrl(requiredDocuments) {
    if (requiredDocuments && requiredDocuments.acceptance_documents) {
      let documentUrl = '';

      requiredDocuments.acceptance_documents.map((document) => {
        if (document.document_type === 'customer_agreement') {
          documentUrl = document.url;
        }

        return documentUrl;
      });

      return documentUrl;
    }

    return false;
  }

  submitDocument = (documentObject) => {
    const { actions, handleSubmit } = this.props;

    if (
      MY_APP &&
      documentObject.documentFront.name &&
      documentObject.documentBack.name &&
      documentObject.selfiePhoto.name
    ) {
      actions.showLoader();

      actions
        .uploadDocument({
          documentObject: documentObject.documentFront,
          documentType: DOCUMENT_TYPES.myKadFront,
        })
        .then(() => {
          const { type } = this.props;

          if (type !== UPLOAD_FAILED) {
            actions
              .uploadDocument({
                documentObject: documentObject.documentBack,
                documentType: DOCUMENT_TYPES.myKadBack,
              })
              .then(() => {
                const { type: newType } = this.props;

                if (newType !== UPLOAD_FAILED) {
                  actions
                    .uploadDocument({
                      documentObject: documentObject.selfiePhoto,
                      documentType: DOCUMENT_TYPES.selfiePhoto,
                    })
                    .then(() => {
                      this.setState({ isShowMyKadModal: true });

                      handleSubmit({}, '', {
                        form: REGISTRATION_ACCOUNT_MYKAD,
                      });
                    });
                }
              });
          }
        });
    } else if (MY_APP) {
      this.setState({
        errorMessageKadFront: !documentObject.documentFront.name,
        errorMessageKadBack: !documentObject.documentBack.name,
        errorMessageKadSelfie: !documentObject.selfiePhoto.name,
      });
    }
  };

  updateErrorsBack = (backError) => {
    this.setState({
      errorMessageKadBack: backError,
    });
  };

  updateErrorsFront = (frontError) => {
    this.setState({
      errorMessageKadFront: frontError,
    });
  };

  updateErrorsSelfie = (selfieError) => {
    this.setState({
      errorMessageKadSelfie: selfieError,
    });
  };

  linkWithdrawal() {
    const { actions } = this.props;

    actions.linkWithdrawalNow();
  }

  openChooseModal(condition) {
    this.setState({
      isOpenChooseModal: condition,
    });
  }

  showHelpCardInfo(val) {
    if (val[0] && !val[0].value) {
      this.setState({ isShowHelpCardInfo: true });
    }
  }

  toggleHelpCard(condition) {
    this.setState({ isShowHelpCardInfo: condition });
  }

  goToAccountFunding() {
    const { actions } = this.props;

    actions.goToFundingAccount();
  }

  render() {
    const {
      handleSubmit,
      stepName,
      errorMessage,
      goBack,
      usResident,
      showLoader,
      handleSubmitAgreement,
      requiredDocuments,
      user,
      showStepAccountError,
      t,
      isPortfolioCustomization,
      isProPortfolioEnabled,
      showSuperFundUser,
    } = this.props;

    const {
      isShowModal,
      errorMessageKadBack,
      errorMessageKadFront,
      errorMessageKadSelfie,
      yearsOld,
      agreementDisabled,
      superFund,
      isShowMyKadModal,
      isOpenChooseModal,
      showCheckboxes,
      isShowHelpCardInfo,
    } = this.state;

    const currentLang = localStorage.getItem('lang');
    let question = '';
    let stepClass = 'step-one';
    let contentFillTitle = '';
    let agreement = false;

    switch (stepName) {
      case 'agreement':
        agreement = true;

        Firebase.analytics().logEvent('Terms');

        question = (
          <Agreement
            showCheckboxes={showCheckboxes}
            showSuperFundUser={showSuperFundUser}
            handleSubmit={(e, values, form, hasValidationErrors) => {
              handleSubmitAgreement(e, values, form, hasValidationErrors);

              this.openChooseModal(false);
            }}
            openChooseModal={this.openChooseModal}
            isOpenChooseModal={isOpenChooseModal}
            showLoader={showLoader}
            errorMessage={errorMessage}
            handleChangeAgreement={this.handleChangeAgreement}
            usResident={usResident}
            superFund={superFund}
            yearsOld={yearsOld}
            handleDsagree={this.handleDsagree}
            handleCloseModal={this.handleCloseModal}
            isShowModal={isShowModal}
            agreementDisabled={agreementDisabled}
          />
        );

        break;
      case 'other':
        stepClass = 'step-account form-question other';

        contentFillTitle = t('registrationPage.other.title');

        question = <FinancialSuitability />;

        break;
      case 'portfolio':
        stepClass = 'step-account form-question other change-portfolio';

        contentFillTitle = '';

        question = isProPortfolioEnabled ? (
          <Portfolio handleGoBack={goBack} />
        ) : (
          <ChangePortfolio
            isRegistration
            goBack={goBack}
            isSmsf={
              sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
              'superFund'
            }
          />
        );

        break;
      case 'uploadId':
        stepClass = 'step-account form-question other';

        contentFillTitle = '';

        question = (
          <UploadID
            goBack={goBack}
            showLoader={showLoader}
            updateErrorsFront={this.updateErrorsFront}
            updateErrorsBack={this.updateErrorsBack}
            updateErrorsSelfie={this.updateErrorsSelfie}
            submitDocument={this.submitDocument}
            errorMessageKadBack={errorMessageKadBack}
            errorMessageKadFront={errorMessageKadFront}
            errorMessageKadSelfie={errorMessageKadSelfie}
            isShowMyKadModal={isShowMyKadModal}
            handleCloseModal={this.handleCloseModal}
            linkWithdrawal={this.linkWithdrawal}
            goToAccountFunding={this.goToAccountFunding}
          />
        );

        break;
      case 'information':
        stepClass = 'step-account form-question other';

        contentFillTitle = '';

        question = (
          <Information
            handleSubmit={handleSubmit}
            isShowHelpCardInfo={isShowHelpCardInfo}
            showHelpCardInfo={this.showHelpCardInfo}
            showLoader={showLoader}
            errorMessage={errorMessage}
            goBack={goBack}
            showStepAccountError={showStepAccountError}
          />
        );

        break;
      default:
    }

    const classIllustration = {
      [AUS]: '-aus-illustration',
      [MYS]:
        currentLang === BAHASA ? '-my-ms-illustration' : '-my-illustration',
    };

    return (
      <div
        className={`registration-page ${
          checkIsDependentUser(user) ? '-kids' : ''
        }`}
      >
        <div className={stepClass}>
          <div className="left-part">
            {agreement && (
              <div className="part-content">
                <div className="logo" />

                <div className="title">{t('registrationPage.pds.title')}</div>

                <div
                  className={`${
                    MY_APP ? '-my-illustration' : ''
                  } ${getLeftPartIconClassName(user)}`}
                >
                  {t('registrationPage.pds.subTitle')}
                </div>

                <div className="bottom-text">
                  {t('registrationPage.pds.bottomText')}
                </div>
              </div>
            )}

            {!agreement && (
              <div className="part-content">
                <div className="logo" />

                <div className="title" />

                <div
                  className={`${
                    classIllustration[COUNTRY]
                  } ${getLeftPartIconClassName(user)}`}
                />

                <div className="bottom-text">
                  {checkIsDependentUser(user)
                    ? t('registrationPage.leftBottom.kids')
                    : t('registrationPage.leftBottom.two')}
                </div>
              </div>
            )}
          </div>

          <div
            className={`right-part ${
              checkIsDependentUser(user) ? '-align-top' : ''
            }`}
          >
            {isPortfolioCustomization ? (
              <PortfolioCustomization />
            ) : (
              <>
                {agreement &&
                  requiredDocuments &&
                  requiredDocuments.acceptance_documents &&
                  requiredDocuments.acceptance_documents.length !== 0 &&
                  this.getPdsUrl(requiredDocuments) && (
                    <iframe
                      src={this.getPdsUrl(requiredDocuments)}
                      width="80%"
                      height="100%"
                      align="center"
                      overflow="hidden"
                      className="iframe"
                      title="terms"
                    />
                  )}

                {!agreement && !checkIsDependentUser(user) && (
                  <ProgressBar
                    stepName={stepName}
                    activeStep={ACCOUNT_REGISTRATION}
                  />
                )}

                <div
                  className={`bottom-part ${
                    AU_APP && agreement ? '-list-checkboxes' : ''
                  }`}
                >
                  <div className="content-fill">
                    <h1>{contentFillTitle}</h1>
                  </div>

                  {question}
                </div>
              </>
            )}
          </div>
        </div>

        {isShowHelpCardInfo && (
          <HelpCardsModalAU
            currentPage={REGISTRATION}
            toggleHelpCard={this.toggleHelpCard}
            isOpenHelpCards={isShowHelpCardInfo}
          />
        )}
      </div>
    );
  }
}

Account.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  stepName: PropTypes.string.isRequired,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  usResident: PropTypes.bool,
  state: PropTypes.shape({}),
  actions: PropTypes.shape({
    getRequiredDocuments: PropTypes.func,
    showLoader: PropTypes.func,
    uploadDocument: PropTypes.func,
    linkWithdrawalNow: PropTypes.func,
    goToFundingAccount: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      user_type: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  showLoader: PropTypes.bool,
  handleSubmitAgreement: PropTypes.func.isRequired,
  requiredDocuments: PropTypes.shape({
    acceptance_documents: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showStepAccountError: PropTypes.string,
  t: PropTypes.func.isRequired,
  isPortfolioCustomization: PropTypes.bool,
  isProPortfolioEnabled: PropTypes.bool,
  showSuperFundUser: PropTypes.bool,
};

Account.defaultProps = {
  type: '',
  showStepAccountError: '',
  errorMessage: '',
  usResident: false,
  state: {},
  showLoader: false,
  requiredDocuments: {},
  isPortfolioCustomization: false,
  isProPortfolioEnabled: false,
  showSuperFundUser: false,
};

const mapStateToProps = (state) => ({
  user: state.user,
  type: state.user.type,
  loginData: state.user.loginData,
  yearsOld: state.user.yearsOld,
  showLoader: state.user.showLoader,
  requiredDocuments: state.user.requiredDocuments,
  state,
  showSuperFundUser: selectIsSuperFundUserEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Account));
