import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Skeleton from 'react-skeleton-loader';
import { withTranslation } from 'react-i18next';

import * as spendingAnalyticsActions from '../../../store/spendingAnalytics/actions';
import { WHERE_YOU_SPEND_LIST_COUNT } from '../consts';

import { CategoryItem } from './CategoryItem';
import { SeeMoreButton } from './styles';

import { PATHS } from 'constants/paths';

class WhereYouSpend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategories: [],
    };
  }

  componentDidMount() {
    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics.getTotalsByCategory().then(() => {
      const { categoryTotals } = this.props;

      if (
        categoryTotals &&
        categoryTotals.payload &&
        categoryTotals.payload.categories
      ) {
        const currentCategories = categoryTotals.payload.categories;

        currentCategories.forEach((currentCategory) => {
          // the same as this: currentCategory.monthly[0][1]
          const copyCurrentCategory = currentCategory;
          const [[, monthly]] = currentCategory.monthly;

          copyCurrentCategory.dashboard = monthly;

          const currentCategoryChildren =
            categoryTotals.payload.categories.filter(
              (categoryInfo) =>
                currentCategory.category === categoryInfo.parent,
            );

          currentCategoryChildren.forEach((categoryChildren) => {
            copyCurrentCategory.dashboard += categoryChildren.monthly[0][1];
          });

          return copyCurrentCategory;
        });

        this.setState({ currentCategories });
      }
    });

    window.scrollTo(0, 0);
  }

  render() {
    const { currentCategories } = this.state;
    const { categoryTotals, t } = this.props;

    const monthlyTotal =
      categoryTotals.payload && categoryTotals.payload.monthly_total
        ? categoryTotals.payload.monthly_total[0][1]
        : 0;

    const sortedCurrentCategories = currentCategories
      .filter((category) => category.category !== '')
      .sort((curr, next) => next.dashboard - curr.dashboard);

    const topCategories = sortedCurrentCategories.slice(
      0,
      WHERE_YOU_SPEND_LIST_COUNT,
    );

    return (
      <div className="info-panel my-finance__spend">
        <div className="info-panel__title">
          {t('myFinancePage.whereYouSpend.title')}
        </div>

        <div className="info-panel__divider" />

        {currentCategories && currentCategories.length
          ? topCategories.map((dataItem) => (
              <CategoryItem
                key={dataItem.category}
                dataItem={dataItem}
                sum={monthlyTotal}
              />
            ))
          : ''}

        {categoryTotals.payload &&
          categoryTotals.payload.categories &&
          !categoryTotals.payload.categories.length &&
          [...Array(5)].map((e, index) => (
            <div className="info-panel__list-item" key={`${index * 2}`}>
              <Skeleton />

              <Skeleton />

              <Skeleton />
            </div>
          ))}

        <SeeMoreButton
          label={t('common.seeMore')}
          href={PATHS.whereSpend.index}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  categoryTotals: state.spendingAnalytics.categoryTotals,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(WhereYouSpend));

WhereYouSpend.defaultProps = {
  actionsSpendingAnalytics: {},
  categoryTotals: {
    payload: {
      categories: [],
    },
  },
};

WhereYouSpend.propTypes = {
  t: PropTypes.func.isRequired,
  categoryTotals: PropTypes.shape({
    payload: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.shape({})),
      monthly_total: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    }),
  }),
  actionsSpendingAnalytics: PropTypes.shape({
    getTotalsByCategory: PropTypes.func,
  }),
};
