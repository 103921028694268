import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function GiftCardModal(props) {
  const { isGiftCardModalVisible, giftCardId, giftCardModalToggle, userUuid } =
    props;

  return (
    <div className="page-content__full">
      <Modal
        isOpen={isGiftCardModalVisible}
        onRequestClose={giftCardModalToggle}
        contentLabel="modal-alert"
        shouldCloseOnOverlayClick
        className="modal-basic bank__modal bank-sign gift-card"
      >
        <DeprecatedButton
          title=""
          buttonClass="button__close -black"
          handleClick={giftCardModalToggle}
        />

        <iframe
          src={`/gift-card.html?giftCardId=${giftCardId}&userUuid=${userUuid}`}
          title="gift-card"
        />
      </Modal>
    </div>
  );
}

GiftCardModal.propTypes = {
  isGiftCardModalVisible: PropTypes.bool.isRequired,
  giftCardId: PropTypes.string.isRequired,
  giftCardModalToggle: PropTypes.func.isRequired,
  userUuid: PropTypes.string.isRequired,
};
