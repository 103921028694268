import styled from '@emotion/styled';

export const Card = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.primary};
  `}
  appearance: none;
  border-radius: 6px;
  height: unset;
  width: 100%;
  margin-bottom: 25px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  text-align: left;
`;

export const Title = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.length.lg};
  `}
  margin-left: 16px;
`;

export const Content = styled.div`
  padding: 0 24px;
`;

export const SubAccountName = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  ${({ theme }) => `
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
  `}
`;
