import styled from '@emotion/styled';

import earnedIcon from 'assets/images/icons/referrals/checked.svg';
import pendingIcon from 'assets/images/icons/referrals/unchecked.svg';
import dotIcon from 'assets/images/icons/referrals/dot.svg';
import smallDotIcon from 'assets/images/icons/referrals/dot_small.svg';

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  margin-left: 8px;
  text-transform: capitalize;
`;

export interface IIconProps {
  type: 'smalldot' | 'dot' | 'check' | 'hollow';
}

export const Icon = styled.div<IIconProps>(({ type }) => {
  const backgroundImage = {
    check: `url(${earnedIcon})`,
    hollow: `url(${pendingIcon})`,
    smalldot: `url(${smallDotIcon})`,
    dot: `url(${dotIcon})`,
  }[type];

  return `
  width: 12px;
  height: 12px;
  background-image: ${backgroundImage};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  position: relative;
  margin-left: 6px;
`;
});
