import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { DOCUMENT_TYPES } from '../../../../constants/comonConstants';
import { IdDocumentUploader } from '../../../../pages/Registration/components/UploadId/components/IdDocumentUploader';
import { ALERT_TYPES } from '../../../../constants/alerts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

function MYSDocumentsUploader({
  currentAlert,
  isUploadInfoAlertOpen,
  toggleAlertInfoModal,
  isLoader,
  handleChangeStatus,
  myKadDocumentBack,
  myKadDocumentFront,
  selfieMyKad,
  submitMyKad,
}) {
  const { t } = useTranslation();

  const isDocumentFront =
    currentAlert.type === ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing ||
    ALERT_TYPES.idUploadErrors.errorUploadInformation;

  const isDocumentBack =
    currentAlert.type === ALERT_TYPES.idUploadErrors.myKadBackUploadMissing ||
    ALERT_TYPES.idUploadErrors.errorUploadInformation;

  const isSelfie =
    currentAlert.type === ALERT_TYPES.idUploadErrors.selfieUploadMissing ||
    ALERT_TYPES.idUploadErrors.errorUploadInformation;

  return (
    <Modal
      isOpen={isUploadInfoAlertOpen}
      onRequestClose={() => toggleAlertInfoModal(false, currentAlert)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic modal-alert -white"
    >
      <div className="modal-basic__header">
        <DeprecatedButton
          buttonClass="button__close -grey"
          handleClick={() => toggleAlertInfoModal(false, currentAlert)}
        />

        {t('alertsPage.uploaderMYSDocuments.proofOfIdentity')}
      </div>

      <div className="modal-basic__modal-description">
        {t('alertsPage.uploaderMYSDocuments.uploadMyKad')}
      </div>

      {isDocumentFront ? (
        <IdDocumentUploader
          handleChangeStatus={handleChangeStatus}
          type={DOCUMENT_TYPES.myKadFront}
        />
      ) : null}

      {isDocumentBack ? (
        <IdDocumentUploader
          handleChangeStatus={handleChangeStatus}
          type={DOCUMENT_TYPES.myKadBack}
        />
      ) : null}

      {isSelfie ? (
        <IdDocumentUploader
          handleChangeStatus={handleChangeStatus}
          type={DOCUMENT_TYPES.selfiePhoto}
        />
      ) : null}

      <Button
        isLoading={isLoader}
        isDisabled={
          (isDocumentBack && !myKadDocumentBack.name) ||
          (isDocumentFront && !myKadDocumentFront.name) ||
          (isSelfie && !selfieMyKad.name) ||
          (!isDocumentFront &&
            !isDocumentBack &&
            !isSelfie &&
            (!myKadDocumentFront.name ||
              !myKadDocumentBack.name ||
              !selfieMyKad.name))
        }
        label={t('common.submit')}
        onClick={() => submitMyKad()}
      />
    </Modal>
  );
}

MYSDocumentsUploader.propTypes = {
  isUploadInfoAlertOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  submitMyKad: PropTypes.func.isRequired,
  isLoader: PropTypes.bool.isRequired,
  currentAlert: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  myKadDocumentFront: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  myKadDocumentBack: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  selfieMyKad: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default MYSDocumentsUploader;
