import {
  KidPortfolioHeaderWrapper,
  KidPortfolioHeaderImageWrapper,
  KidPortfolioHeaderNameWrapper,
} from './styles';
import { CHILD_IMAGE_WIDTH } from './consts';

import { selectChild } from 'store/dependentUser/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function KidPortfolioHeader() {
  const child = useAppSelector(selectChild);

  if (!child.name || !child.icon_url) return null;

  return (
    <KidPortfolioHeaderWrapper>
      <KidPortfolioHeaderImageWrapper>
        <img src={child.icon_url} width={CHILD_IMAGE_WIDTH} alt={child.name} />
      </KidPortfolioHeaderImageWrapper>

      <KidPortfolioHeaderNameWrapper>
        {child.name}
      </KidPortfolioHeaderNameWrapper>
    </KidPortfolioHeaderWrapper>
  );
}
