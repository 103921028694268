import React from 'react';
import '../../styles/pages/history.scss';
import PropTypes from 'prop-types';

import RDVModal from '../../components/layout/Modals/RDVModal';

export default function RDVView({
  showLoader,
  closeRDWModal,
  handleSubmitRdv,
  amount,
  changeRdvAmount,
  titleRdv,
  descriptionRdv,
  isShowRdvError,
  errorMessage,
  closeRDWModalError,
  isShowRdvSuccess,
  message,
}) {
  return (
    <div>
      <RDVModal
        isOpenRDWModal
        closeRDWModal={closeRDWModal}
        handleSubmitRdv={handleSubmitRdv}
        amount={amount}
        changeRdvAmount={changeRdvAmount}
        showLoader={showLoader}
        titleRdv={titleRdv}
        descriptionRdv={descriptionRdv}
        isShowRdvError={isShowRdvError}
        errorMessage={errorMessage}
        closeRDWModalError={closeRDWModalError}
        isShowRdvSuccess={isShowRdvSuccess}
        message={message}
      />
    </div>
  );
}

RDVView.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  handleSubmitRdv: PropTypes.func.isRequired,
  closeRDWModal: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  changeRdvAmount: PropTypes.func.isRequired,
  titleRdv: PropTypes.string.isRequired,
  descriptionRdv: PropTypes.string.isRequired,
  isShowRdvError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  closeRDWModalError: PropTypes.func.isRequired,
  isShowRdvSuccess: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};
