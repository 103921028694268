import { MY_APP } from 'constants/localeConfigs';
import { SUITABILITIES_PICKER_ID } from 'pages/Settings/consts';

const OPTIONS_INCLUDE_ADDITIONAL_FIELD = [
  'Employment',
  'Business',
  'Other',
  'Perkerjaan',
  'Perniagaan',
  'Lain-lain',
];

const EMPLOYMENT_REGEX_PATTERN = /(full|part)[\s-]time/gi;

export interface IIsAdditionalField {
  pickerId: string;
  answer?: string;
  expandOnAnswerIds: string[];
  answerId?: string;
}

const checkIsAdditionalField = ({
  pickerId,
  answer,
  expandOnAnswerIds,
  answerId,
}: IIsAdditionalField) => {
  if (MY_APP) {
    if (expandOnAnswerIds?.some((id) => id === answerId)) {
      return true;
    }
  }

  if (pickerId === SUITABILITIES_PICKER_ID.employment && answer) {
    if (EMPLOYMENT_REGEX_PATTERN.test(answer)) {
      return true;
    }
  }

  if (pickerId === SUITABILITIES_PICKER_ID.sourceOfIncome && !MY_APP) {
    if (
      OPTIONS_INCLUDE_ADDITIONAL_FIELD.some((option) =>
        answer?.includes(option),
      )
    ) {
      return true;
    }
  }

  return false;
};

export default checkIsAdditionalField;
