export const HISTORY_STATUSES = {
  unfinished: 'unfinished',
  finished: 'finished',
};

export const HISTORY_SECTIONS = {
  buy: 'buy',
  sell: 'sell',
};

export const ICON_TYPES = {
  finished: 'icon-finished',
  unfinished: 'icon-unfinished',
  active: 'icon-active',
};

export const TRANSACTION_STATUSES = {
  failed: 'failed',
  pending: 'pending',
  transferring: 'transferring',
  transferred: 'transferred',
  postedIn: 'posted_in',
  settled: 'settled',
};

export const PROCESSING_STATUSES = [
  TRANSACTION_STATUSES.postedIn,
  TRANSACTION_STATUSES.settled,
];

export const TRANSACTION_TYPES = {
  sell: 'Jual',
  buy: 'Beli',
  withdrawal: 'Biaya',
};

export const EVENT_STATUSES = {
  confirmed: 'confirmed',
};

export const RAIZ_REWARD_TYPE = 'Raiz Reward';
