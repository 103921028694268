import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import LoginForRegistrationForm from '../../Login/components/LoginForRegistrationForm';
import {
  AU_APP,
  MY_APP,
  PRIVACY_POLICY,
  SUPPORT,
  TERMS_CONDITIONS,
} from '../../../constants/localeConfigs';

import {
  Description,
  FooterDescription,
  InviteBanner,
  Title,
  Wrapper,
} from './styles';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';

const Link = ({ href, title }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default function StepAuth({
  login,
  errorMessage,
  user,
  loginData,
  handleChangeLogin,
  toggleShowPassword,
  isShowPassword,
  goToRegistration,
  goToLogin,
  showLoader,
  showDotsLoader,
  activeFieldInfo,
  validEmail,
  validConfirmEmail,
  isInvite,
  currentInviteCode,
  validPassword,
  stateMessages,
}) {
  const { t } = useTranslation();

  return (
    <div className={`login-page ${isInvite ? '-with-invite' : ''}`}>
      {isInvite && (
        <div className="invite-content__header">
          <div className="logo" />
        </div>
      )}

      {isInvite && (
        <InviteBanner>
          <Description>
            <Title>
              <Trans i18nKey="registrationPage.auth.tipsOnGrowing" t={t} />
            </Title>

            <Wrapper>
              <LoginForRegistrationForm
                validEmail={validEmail}
                validConfirmEmail={validConfirmEmail}
                validPassword={validPassword}
                isInvite={isInvite}
                currentInviteCode={currentInviteCode}
                activeFieldInfo={activeFieldInfo}
                showLoader={showLoader}
                showDotsLoader={showDotsLoader}
                handleSubmit={login}
                errorMessage={errorMessage}
                isRegistration
                user={user}
                loginData={loginData}
                handleChange={handleChangeLogin}
                toggleShowPassword={toggleShowPassword}
                isShowPassword={isShowPassword}
                goToRegistration={goToRegistration}
                goToLogin={goToLogin}
                stateMessages={stateMessages}
              />
            </Wrapper>
          </Description>
        </InviteBanner>
      )}

      {!isInvite && (
        <div className="get-started">
          <h4>{t('registrationPage.auth.createYourRaizAccount')}</h4>

          <div className="logo" />
        </div>
      )}

      {!isInvite && (
        <div>
          <LoginForRegistrationForm
            validEmail={validEmail}
            validConfirmEmail={validConfirmEmail}
            validPassword={validPassword}
            isInvite={isInvite}
            currentInviteCode={currentInviteCode}
            activeFieldInfo={activeFieldInfo}
            showLoader={showLoader}
            showDotsLoader={showDotsLoader}
            handleSubmit={login}
            errorMessage={errorMessage}
            isRegistration
            user={user}
            loginData={loginData}
            handleChange={handleChangeLogin}
            toggleShowPassword={toggleShowPassword}
            isShowPassword={isShowPassword}
            goToRegistration={goToRegistration}
            goToLogin={goToLogin}
            stateMessages={stateMessages}
          />
        </div>
      )}

      <div>
        <div className="need-help-contact-u">
          <a href={SUPPORT} target="_blank" rel="noopener noreferrer">
            {t('registrationPage.auth.contactUs')}
          </a>
        </div>

        {!AU_APP && !isInvite && (
          <div className="need-help-terms">
            <Trans
              i18nKey="registrationPage.auth.byCreating"
              t={t}
              components={{
                toc: (
                  <Link
                    title={t('registrationPage.auth.conditions')}
                    href={TERMS_CONDITIONS}
                  />
                ),
              }}
            />
          </div>
        )}

        {AU_APP && !isInvite && (
          <div className="need-help-terms">
            <Trans
              i18nKey="registrationPage.auth.bySigning"
              t={t}
              components={{
                toc: (
                  <Link
                    title={t('registrationPage.auth.conditions')}
                    href={TERMS_CONDITIONS}
                  />
                ),
                privacy: (
                  <Link
                    title={t('registrationPage.auth.privacy')}
                    href={PRIVACY_POLICY}
                  />
                ),
              }}
            />
          </div>
        )}
      </div>

      {isInvite && (
        <div className="invite-content__footer">
          <div className="invite-content__footer-block">
            <div className="invite-content__footer-col">
              <div className="invite-content__footer-raiz-logo" />

              <div className="invite-content__footer-copy">
                {MY_APP && t('invite.footer.subtitle.my')}

                {AU_APP && t('invite.footer.subtitle.aus')}
              </div>
            </div>

            <div className="invite-content__footer-info">
              <FooterDescription>
                <Trans
                  i18nKey="invite.footer.description"
                  components={{
                    a: <ExternalLink />,
                  }}
                />
              </FooterDescription>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

StepAuth.propTypes = {
  login: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  loginData: PropTypes.shape({}).isRequired,
  handleChangeLogin: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isInvite: PropTypes.bool.isRequired,
  validEmail: PropTypes.bool,
  validConfirmEmail: PropTypes.bool,
  validPassword: PropTypes.bool,
  showDotsLoader: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  goToRegistration: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
  activeFieldInfo: PropTypes.string.isRequired,
  currentInviteCode: PropTypes.string.isRequired,
  stateMessages: PropTypes.shape({}).isRequired,
};

StepAuth.defaultProps = {
  errorMessage: '',
  validEmail: false,
  validConfirmEmail: false,
  validPassword: false,
};
