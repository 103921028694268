import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import * as actionsFromUser from '../../store/user/actions';
import { SETTINGS } from '../../constants/comonConstants';

import CrsView from './CrsView';

class Crs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryError: '',
      showLoader: false,
    };

    this.deleteTax = this.deleteTax.bind(this);

    this.showAddTax = this.showAddTax.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getCountries();

    actions.getIdentifiers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isShowLoader } = this.state;
    const { actions } = this.props;

    if (prevState.showLoader !== isShowLoader) {
      actions.getIdentifiers().then(() => {
        this.setState({ showLoader: isShowLoader });
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isShowLoader !== prevState.isShowLoader) {
      return { isShowLoader: nextProps.isShowLoader };
    }

    return null;
  }

  handleSubmit = (e, values, form, hasValidationErrors) => {
    const { t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (values.country && values.country.length === 0) {
        this.setState({ countryError: t('fields.validation.required') });
      } else {
        this.setState({ countryError: '' });
      }

      if (
        !hasValidationErrors &&
        values.country[0] &&
        values.country[0].value
      ) {
        const data = {
          tax_identifier: {
            country_code: values.country[0].value,
            number: values.taxIdentifier,
          },
        };

        const { actions } = this.props;

        this.setState({ showLoader: true });

        actions.createIdentifier(data);
      }
    }
  };

  deleteTax(id) {
    const { actions } = this.props;

    this.setState({ showLoader: true });

    actions.deleteIdentifier(id);
  }

  showAddTax(isShowListTax) {
    const { actions } = this.props;

    actions.showAddTax(isShowListTax);
  }

  goToSettings() {
    window.location.href = SETTINGS;
  }

  render() {
    const { user, countries, isShowListTax, identifiers } = this.props;
    const { countryError, showLoader } = this.state;

    return (
      <CrsView
        user={user}
        countries={countries}
        handleSubmit={this.handleSubmit}
        countryError={countryError}
        isShowListTax={isShowListTax}
        identifiers={identifiers}
        deleteTax={this.deleteTax}
        showAddTax={this.showAddTax}
        showLoader={showLoader}
        goToSettings={this.goToSettings}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  countries: state.user.countries,
  identifiers: state.user.identifiers,
  isShowListTax: state.user.isShowListTax,
  isShowLoader: state.user.isShowLoader,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionsFromUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Crs));

Crs.defaultProps = {
  state: {},
  user: {},
  countries: [],
  isShowListTax: true,
  isShowLoader: false,
  identifiers: {},
};

Crs.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({}),
  actions: PropTypes.shape({
    getCountries: PropTypes.func,
    getIdentifiers: PropTypes.func,
    createIdentifier: PropTypes.func,
    deleteIdentifier: PropTypes.func,
    showAddTax: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  isShowListTax: PropTypes.bool,
  isShowLoader: PropTypes.bool,
  identifiers: PropTypes.shape({}),
};
