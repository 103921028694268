import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HomeProgress from './HomeProgress';
import { AccountValue, SeeDetailsButton } from './styles';

import { PATHS } from 'constants/paths';
import { MY_APP } from 'constants/togglesFunctions';
import { checkIsSmsfUser } from 'utils/user';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function HomeTodayBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { smsfDetails, user } = useSelector((state) => ({
    smsfDetails: state.user.smsfDetails || {},
    user: state.user || {},
  }));

  const {
    details: { display_fund_name },
  } = smsfDetails || {};

  const {
    user: { current_balance, first_name, processing_deposits },
  } = user;

  const currentBalance = MY_APP
    ? current_balance + processing_deposits
    : current_balance;

  const navigateToInvest = () => {
    navigate(PATHS.invest.index);
  };

  const isSmsfUser = checkIsSmsfUser(user);

  return (
    <div>
      <div className="page-content__banner-title">
        {t('homePage.homeTodayBanner.welcome', {
          name: isSmsfUser ? display_fund_name : first_name,
        })}
      </div>

      <div className="page-content__banner-label">
        {MY_APP
          ? t('homePage.homeTodayBanner.accountValuePending')
          : t('homePage.homeTodayBanner.accountValue')}
      </div>

      <AccountValue>
        {formatNumber({
          value: currentBalance,
        })}
      </AccountValue>

      {MY_APP && (
        <SeeDetailsButton
          title={t('homePage.homeTodayBanner.seeDetailsButton')}
          handleClick={navigateToInvest}
        />
      )}

      <HomeProgress />
    </div>
  );
}
