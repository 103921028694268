import { useState } from 'react';

import { Icon, IIconSet } from '../Icon';

import { DefaultIconWrapper } from './styles';

interface ImageWithDefaultIconProps {
  src: string;
  alt?: string;
  isDefaultImage?: boolean;
  className?: string;
  defaultIconSize?: number;
  withMargin?: boolean;
  defaultIcon?: IIconSet;
  width?: number;
}

export const ImageWithDefaultIcon = ({
  src,
  alt,
  isDefaultImage,
  className = '',
  defaultIconSize = 35,
  withMargin,
  defaultIcon = 'defaultBank',
  width,
}: ImageWithDefaultIconProps) => {
  const [isImageUrlBroken, setIsImageUrlBroken] = useState(false);

  if (isImageUrlBroken || !src || isDefaultImage) {
    return (
      <DefaultIconWrapper withMargin={withMargin}>
        <Icon iconName={defaultIcon} size={defaultIconSize} />
      </DefaultIconWrapper>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      className={className}
      onError={() => {
        setIsImageUrlBroken(true);
      }}
    />
  );
};
