// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DeprecatedDotsLoader from '../DeprecatedDotsLoader/DeprecatedDotsLoader';

/**
 * @deprecated use components/elements/Button
 */
export default class Button extends Component {
  returnFunction() {
    return false;
  }

  render() {
    const {
      title,
      buttonClass,
      type,
      handleClick,
      disabled,
      isLink,
      to,
      withLoader,
      icon,
    } = this.props;

    const content = icon ? (
      <>
        {icon} {title}
      </>
    ) : (
      title
    );

    const btn =
      type && type === 'submit' ? (
        <button
          disabled={disabled}
          type="submit"
          onClick={withLoader ? this.returnFunction : handleClick}
          className={buttonClass}
        >
          {withLoader ? (
            <DeprecatedDotsLoader additionalClass="btn" />
          ) : (
            content
          )}
        </button>
      ) : (
        <button
          disabled={disabled}
          type="button"
          onClick={withLoader ? this.returnFunction : handleClick}
          className={buttonClass}
        >
          {withLoader ? (
            <DeprecatedDotsLoader additionalClass="btn" />
          ) : (
            content
          )}
        </button>
      );

    return isLink ? (
      <Link
        to={to}
        onClick={
          withLoader
            ? (e) => {
                e.preventDefault();
              }
            : handleClick
        }
        className={buttonClass}
      >
        {withLoader ? <DeprecatedDotsLoader additionalClass="btn" /> : title}
      </Link>
    ) : (
      btn
    );
  }
}

Button.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  buttonClass: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  to: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  withLoader: PropTypes.bool,
  isLink: PropTypes.bool,
};

Button.defaultProps = {
  withLoader: false,
  isLink: false,
  title: null,
  handleClick: null,
  type: 'button',
  disabled: false,
  buttonClass: '',
  to: '',
  icon: null,
};
