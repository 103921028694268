import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Profile from './Profile';
import SpendingAccount from './SpendingAccount';
import SmsfAccountSettings from './SmsfAccountSettings';
import FundingAccount from './FundingAccount';
import WithdrawalAccount from './WithdrawalAccount';
import BanksSearch from './BanksSearch';
import Legal from './Legal';
import Support from './Support';
import ChangePassword from './ChangePassword';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class ProfileContent extends Component {
  contentRender = () => {
    const additionalParams = window.location.search.slice(1);
    const { history, supportStep, isNewRewards, step } = this.props;

    switch (step) {
      case '':
        if (sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser)) {
          return <SmsfAccountSettings />;
        }

        return (
          <div>
            <Profile history={history} />
          </div>
        );
      case 'funding-account':
        return <FundingAccount history={history} />;
      case 'funding-account-failed':
        return <FundingAccount history={history} isPaymentFailed />;
      case 'funding-account-success':
        return <FundingAccount history={history} isPaymentSuccess />;
      case 'spending-account':
        return <SpendingAccount history={history} />;
      case 'spending-account-failed':
        return <SpendingAccount history={history} isPaymentFailed />;
      case 'spending-account-success':
        return <SpendingAccount history={history} isPaymentSuccess />;
      case 'withdrawal-account':
        return <WithdrawalAccount history={history} />;
      case 'change-password':
        return <ChangePassword />;
      case 'smsf-account':
        return <SmsfAccountSettings />;
      case 'banks-search':
        return <BanksSearch />;
      case 'support':
        if (additionalParams.length) {
          return (
            <section className="settings__content-right-flex">
              <Support isNewRewards={isNewRewards} />

              <Legal />
            </section>
          );
        }

        return (
          <Support supportStep={supportStep} isNewRewards={isNewRewards} />
        );
      case 'close':
        return <Support supportStep={supportStep} />;
      default:
        return false;
    }
  };

  render() {
    return (
      <div className="settings__content-right">{this.contentRender()}</div>
    );
  }
}

export default ProfileContent;

ProfileContent.propTypes = {
  step: PropTypes.string.isRequired,
  intl: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  supportStep: PropTypes.string.isRequired,
  isNewRewards: PropTypes.bool.isRequired,
};
