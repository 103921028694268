import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import SelectField from '../../../components/elements/SelectFF/SelectField';
import Loader from '../../../components/elements/Loader/Loader';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    const patternCapital = /[A-Z]/;
    const patternLower = /[a-z]/;
    const patternNumber = /[0-9]/;

    if (!values.password) {
      errors.password = t('fields.validation.required');
    } else if (values.password.length < 8) {
      errors.password = t('fields.validation.minimum8Characters');
    } else if (!patternCapital.test(values.password)) {
      errors.password = t('fields.validation.shouldContain');
    } else if (!patternLower.test(values.password)) {
      errors.password = t('fields.validation.shouldContainLetter');
    } else if (!patternNumber.test(values.password)) {
      errors.password = t('fields.validation.shouldContainOne');
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t('fields.validation.required');
    } else if (values.confirmPassword.length < 8) {
      errors.confirmPassword = t('fields.validation.minimum8Characters');
    } else if (!patternCapital.test(values.confirmPassword)) {
      errors.confirmPassword = t('fields.validation.shouldContain');
    } else if (!patternLower.test(values.confirmPassword)) {
      errors.confirmPassword = t('fields.validation.shouldContainLetter');
    } else if (!patternNumber.test(values.confirmPassword)) {
      errors.confirmPassword = t('fields.validation.shouldContainOne');
    }

    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.password = t('fields.validation.passwordMatch');

      errors.confirmPassword = t('fields.validation.passwordMatch');
    }

    if (!values.answer1) {
      errors.answer1 = t('fields.validation.required');
    }

    return errors;
  };

  return { validate };
};

export default function KidsPassword({
  registrationSecurityQuestion,
  handleSubmit,
  showLoader,
  toggleShowConfirmPassword,
  isShowPassword,
  toggleShowPassword,
  isShowConfirmPassword,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  const defaultQuestion1Value = useMemo(
    () => [registrationSecurityQuestion?.[0]] || [],
    [registrationSecurityQuestion],
  );

  return showLoader ? (
    <div className="profile-content settings__content-default-container raiz-kids__menu-header">
      <Loader additionalClass=" -absolute -center" />
    </div>
  ) : (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className={'"form-field radio'}>
            <div className="form-field__sub-input-link">
              <FinalFormInput
                name="password"
                defaultValue={values.password}
                showPassword={toggleShowPassword}
                label={t('fields.password.label')}
                type={isShowPassword ? 'password' : 'text'}
                isShowPasswordToggle
              />

              <FinalFormInput
                name="confirmPassword"
                defaultValue={values.confirmPassword}
                showPassword={toggleShowConfirmPassword}
                label={t('fields.confirmPassword.label')}
                type={isShowConfirmPassword ? 'password' : 'text'}
                isShowPasswordToggle
              />

              <div className="login-page__help">
                {t('fields.validation.passwordRules')}
              </div>

              <div className="select-first -margin-s">
                <div className="profile-content__container-label">
                  {t('registrationPage.personal.securityQuestion')}
                </div>

                <SelectField
                  name="question1"
                  defaultValue={defaultQuestion1Value}
                  options={registrationSecurityQuestion}
                />
              </div>

              <FinalFormInput
                name="answer1"
                label={t('registrationPage.personal.securityQuestionAnswer')}
              />

              <div className="buttons-part">
                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    type="submit"
                    title={t('common.next')}
                    buttonClass="button button--submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

KidsPassword.propTypes = {
  registrationSecurityQuestion: PropTypes.arrayOf(PropTypes.shape({})),
  handleSubmit: PropTypes.func.isRequired,
  toggleShowConfirmPassword: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  isShowConfirmPassword: PropTypes.bool.isRequired,
};

KidsPassword.defaultProps = {
  registrationSecurityQuestion: [],
};
