import { SwitcherTitle } from './SwitcherTitle';
import {
  ItemWrapper,
  ItemButton,
  ItemBorder,
  ItemBullet,
  RegistrationButton,
  RegistrationWrapper,
} from './styles';

import { IPortfolio } from 'store/user/types';
import { CUSTOM_PORTFOLIO, MODERATE_PLUS } from 'store/user/consts';
import { SWITCHER_PORTFOLIO_TYPES } from 'pages/ChangePortfolio/consts';

interface ISwitcherItemProps {
  portfolio: IPortfolio;
  portfolioType: string;
  toggleShow: (value: string) => void;
  isRegistration: boolean;
  savedPortfolioType: string;
  isEditCustom: boolean;
  customName: string;
  customNameChange: () => void;
  isEmptyCustomPortfolio: boolean;
  activePortfolioId: number;
  customBeta: boolean;
  moderatePlusOn: boolean;
  accessChangePortfolio?: boolean;
}

export const SwitcherItem = ({
  portfolio,
  portfolioType,
  toggleShow,
  isRegistration,
  savedPortfolioType,
  isEditCustom,
  customName,
  customNameChange,
  isEmptyCustomPortfolio = false,
  activePortfolioId,
  customBeta = false,
  moderatePlusOn = false,
  accessChangePortfolio = true,
}: ISwitcherItemProps) => {
  const portfolioName = portfolio.name;

  const isSavedPortfolio = !!(
    savedPortfolioType === portfolioName ||
    savedPortfolioType === portfolio.identifier_key
  );

  const isCustom = CUSTOM_PORTFOLIO === portfolio.name_key;

  if (MODERATE_PLUS === portfolio.identifier_key && !moderatePlusOn) {
    return <div />;
  }

  const portfolioNameForCheck = portfolio.identifier_key
    ?.replace(' ', '_')
    .toLowerCase();

  const portfolioTypes = isCustom
    ? SWITCHER_PORTFOLIO_TYPES.custom
    : portfolioNameForCheck;

  const isPortfolioActive = String(portfolio.id) === String(activePortfolioId);

  const isPortfolioTypesEmerald =
    portfolioTypes === SWITCHER_PORTFOLIO_TYPES.emerald;

  const isPortfolioTypesSapphire =
    portfolioTypes === SWITCHER_PORTFOLIO_TYPES.sapphire;

  const isItemBorder =
    !isPortfolioTypesEmerald && !isPortfolioTypesSapphire && !isCustom;

  if (isRegistration) {
    return (
      <RegistrationWrapper
        isActive={isPortfolioActive}
        isSaved={isSavedPortfolio}
        isRegistration={isRegistration}
        portfolioTypes={portfolioTypes}
      >
        <RegistrationButton
          type="button"
          onClick={() =>
            toggleShow(isCustom ? CUSTOM_PORTFOLIO : portfolioType)
          }
          isActive={isPortfolioActive}
          isRegistration={isRegistration}
        >
          {isItemBorder && (
            <ItemBorder>{isPortfolioActive && <ItemBullet />}</ItemBorder>
          )}
        </RegistrationButton>
      </RegistrationWrapper>
    );
  }

  return (
    <ItemWrapper
      isActive={isPortfolioActive}
      isSaved={isSavedPortfolio}
      portfolioTypes={portfolioTypes}
    >
      <ItemButton
        type="button"
        onClick={() => toggleShow(isCustom ? CUSTOM_PORTFOLIO : portfolioType)}
        isActive={isPortfolioActive}
      >
        <SwitcherTitle
          isCustom={isCustom}
          portfolioType={portfolioType}
          isEditCustom={isEditCustom}
          customNameChange={customNameChange}
          customName={customName}
          portfolioName={portfolioName}
          isRegistration={isRegistration}
          isEmptyCustomPortfolio={isEmptyCustomPortfolio}
          portfolioKey={
            isCustom && accessChangePortfolio
              ? portfolio.name_key
              : portfolio.identifier_key
          }
          savedPortfolioType={savedPortfolioType}
          customBeta={customBeta}
        />
      </ItemButton>
    </ItemWrapper>
  );
};
