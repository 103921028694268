import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Firebase from 'firebase/app';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { matchPath } from 'react-router';

import RegistrationView from './RegistrationView';

import * as userActions from 'store/user/actions';
import '../../styles/pages/registration-page.scss';
import '../../styles/pages/invite-content.scss';
import {
  REGISTRATION_PERSONAL_INFO,
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
} from 'store/commonTypes';
import {
  REGISTRATION_STEP_AUTH,
  REGISTRATION_BANKS_SPENDING_ACCOUNT,
  REGISTRATION_PERSONAL_ADDRESS,
  REGISTRATION_INVESTMENTS_INVESTMENT,
  REGISTRATION_ACCOUNT_QUESTIONS,
  REGISTRATION_ACCOUNT_AGEREEMENT_FORM,
  REGISTRATION_PERSONAL_TAXRESIDENT,
  REGISTRATION_ACCOUNT_MYKAD,
  REGISTRATION_SUPERFUND_INFO,
  REGISTRATION_SUPERFUND_MEMBERS,
} from 'store/user/consts';
import {
  EMAIL_TARGET,
  INVESTMENT_OTHER,
  PASSWORD_TARGET,
  AUTH_OLD_APP,
  AUTH_OLD_APP_FULL,
  REGISTRATION_OLD_APP,
  REGISTRATION_OLD_APP_FULL,
  LOGIN,
  CONFIRM_EMAIL_TARGET,
  OTHER,
} from 'constants/comonConstants';
import { MY_APP, AU_APP } from 'constants/localeConfigs';
import { KIDS_SIGNUP } from 'store/dependentUser/types';
import { EMAIL_REGEX } from 'constants/validation';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import {
  selectIsProPortfolioEnabled,
  selectIsSignUpPromoEnabled,
} from 'store/firebaseConfig/selectors';
import { PATHS } from 'constants/paths';

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryList: [],
      showActiveInput: null,
      show: false,
      viewport: {},
      geocoderInput: null,
      validEmail: false,
      validConfirmEmail: false,
      validPassword: false,
      addressMapBox: null,
      showDotsLoader: false,
      showKTPInfo: false,
      showPertanyaanInfo: false,
      isShowResidentInfoModal: false,
      isShowPassword: true,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      errorMessageMyKad: '',
      activeFieldInfo: '',
      errorMessagePhone: '',
      showStepAccountError: '',
      isSpending: true,
      isWithdrawal: false,
      addressData: {
        street_number: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
      },
      passwordAuth: '',
      emailAuth: '',
      userFormData: {
        my_kad: '',
        dob: '',
        gender: '',
      },
      taxResidentData: {
        no_tin_reason: '',
        ssn: '',
        tin_country: '',
        no_tin: false,
        tin_resident: false,
        pep: false,
      },
      dob: null,
      dobError: false,
      amount: '0',
      isCalendarActive: false,
      pathname: '',
      isLinkWithdrawalAccountModalOpen: false,
    };

    this.checkUser();

    this.getCountryLists = this.getCountryLists.bind(this);

    this.showResidentInfoModal = this.showResidentInfoModal.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.handleChangeInfo = this.handleChangeInfo.bind(this);

    this.handleSubmitInfo = this.handleSubmitInfo.bind(this);

    this.handleSubmitTaxResident = this.handleSubmitTaxResident.bind(this);

    this.goToRegistration = this.goToRegistration.bind(this);

    this.goToLogin = this.goToLogin.bind(this);

    this.checkUserOnLogin = this.checkUserOnLogin.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.compleeteRegistration = this.compleeteRegistration.bind(this);

    this.linkFunding = this.linkFunding.bind(this);

    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.togglePertanyaanInfo = this.togglePertanyaanInfo.bind(this);

    this.toggleKTPInfo = this.toggleKTPInfo.bind(this);
  }

  componentDidMount() {
    const { state, user, history } = this.props;

    if (
      history.location.pathname === '/registration' ||
      history.location.pathname === '/registration/'
    ) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.token);
    }

    if (user && user.user && user.user.dob !== '' && user.user.dob !== null) {
      this.setState({ dob: new Date(user.user.dob) });
    }

    const mail = state.user.loginData ? state.user.loginData.email : '';

    if (mail) {
      const pattern = EMAIL_REGEX;

      this.setState({ validEmail: pattern.test(mail) });
    }

    const password = state.user.loginData ? state.user.loginData.password : '';

    if (password) {
      const patternCapital = /[A-Z]/;
      const patternLower = /[a-z]/;
      const patternNumber = /[0-9]/;

      this.setState({
        validPassword:
          patternCapital.test(password) &&
          patternLower.test(password) &&
          patternNumber.test(password) &&
          password.length >= 8,
      });
    }

    this.getCountryLists();

    this.setUserDefaults();

    const params = queryString.parse(history.location.search);

    if (params && params.utm_source) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.sourceId, params.utm_source);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const returnProps = {};

    if (nextProps.isLoginError !== prevState.isLoginError) {
      returnProps.isLoginError = nextProps.isLoginError;
    }

    if (nextProps.isTriedLogin !== prevState.isTriedLogin) {
      returnProps.isTriedLogin = nextProps.isTriedLogin;
    }

    if (nextProps.isRegistration !== prevState.isRegistration) {
      returnProps.isRegistration = nextProps.isRegistration;
    }

    if (nextProps.isNeedClearAuth !== prevState.isNeedClearAuth) {
      returnProps.isNeedClearAuth = nextProps.isNeedClearAuth;
    }

    if (nextProps.user !== prevState.user) {
      returnProps.user = nextProps.user;
    }

    return returnProps;
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkUser();

    const { actions, history } = this.props;

    const {
      passwordAuth,
      emailAuth,
      isNeedClearAuth,
      isLoginError,
      isTriedLogin,
      user,
      isSpending,
      isWithdrawal,
    } = this.state;

    if (
      history.location.pathname === REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT &&
      !isWithdrawal
    ) {
      this.setState({ isWithdrawal: true });
    }

    const isFundingPage = [
      PATHS.registration.fundingAccount.index,
      PATHS.registration.fundingAccount.status.failure,
      PATHS.registration.fundingAccount.status.success,
    ].some((path) => matchPath(path, history.location.pathname));

    if (isFundingPage && (isWithdrawal || isSpending)) {
      this.setState({ isWithdrawal: false, isSpending: false });
    }

    if (
      prevState.isNeedClearAuth !== isNeedClearAuth &&
      passwordAuth !== '' &&
      emailAuth !== ''
    ) {
      this.setState({ passwordAuth: '', emailAuth: '' });
    }

    if (
      ((prevState.isLoginError !== isLoginError &&
        prevState.isTriedLogin !== isTriedLogin &&
        prevState.user !== user &&
        !user.user.email) ||
        (prevState.isRegistration &&
          (history.location.pathname === AUTH_OLD_APP ||
            history.location.pathname === AUTH_OLD_APP_FULL ||
            history.location.pathname === REGISTRATION_OLD_APP ||
            history.location.pathname === REGISTRATION_OLD_APP ||
            history.location.pathname === REGISTRATION_OLD_APP_FULL ||
            history.location.pathname === REGISTRATION_STEP_AUTH ||
            history.location.pathname === LOGIN))) &&
      AU_APP
    ) {
      actions.goToTerms();
    }

    if (prevState.pathname !== history.location?.pathname) {
      this.setState({ pathname: history.location?.pathname });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  handleCloseModal() {
    const { actions, isNeedGoToStart, errorMessage } = this.props;

    if (isNeedGoToStart && errorMessage !== '') {
      window.location.href = REGISTRATION_STEP_AUTH;
    } else {
      actions.closeModal();
    }
  }

  handleChangeDate(date, isOpen) {
    const { dob } = this.state;

    if (dayjs(dob).isValid() && isOpen) {
      return;
    }

    this.setState({
      dob: date,
      dobError: false,
      isCalendarActive: true,
    });
  }

  setUserDefaults() {
    const { user } = this.props;

    if (user && user.user && user.user.phone_number) {
      this.setState({ phoneNumber: user.user.phone_number });
    }

    if (user && user.user && user.user.first_name) {
      this.setState({ firstName: user.user.first_name });
    }

    if (user && user.user && user.user.last_name) {
      this.setState({ lastName: user.user.last_name });
    }
  }

  getCountryLists() {
    fetch(
      'https://s3-ap-southeast-2.amazonaws.com/app.acornsau.com.au/projection/countries.json',
      { method: 'GET' },
    )
      .then((response) => response.text())
      .then((response) => {
        const res = response;
        let newJson = res.replace(/([a-zA-Z0-9]+?):/g, '"$1":');

        newJson = newJson.replace(/'/g, '"');

        const data = JSON.parse(newJson);

        this.setState({ countryList: data });
      });
  }

  checkUser = () => {
    const { user, history, match } = this.props;
    const { isSpending, isWithdrawal } = this.state;

    const registrationQuestions = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.registrationQuestions,
    );

    let token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

    if (!registrationQuestions && token) {
      this.getQuestions();
    }

    if (!token && user.token) {
      token = { ...user.token };
    }

    if (
      history.location.pathname === REGISTRATION_BANKS_FUNDING_ACCOUNT &&
      isSpending
    ) {
      this.setState({ isSpending: false });
    }

    if (
      history.location.pathname === REGISTRATION_BANKS_SPENDING_ACCOUNT &&
      isWithdrawal
    ) {
      this.setState({ isWithdrawal: false });
    }

    const fundTag = match?.params?.fundTag;

    if (
      (token || user.currentStep === '/registration/account/agreement') &&
      user.currentStep &&
      history.location.pathname !== user.currentStep &&
      !fundTag
    ) {
      history.push(user.currentStep);
    }
  };

  getQuestions = () => {
    const { actions } = this.props;

    actions.getQuestions();
  };

  handleClick = (event) => {
    if (event.target.name === INVESTMENT_OTHER) {
      this.setState({ amount: event.target.value });
    } else {
      this.setState({
        showActiveInput: event.target.id,
        amount: event.target.id === OTHER ? '' : event.target.id,
      });
    }
  };

  onChangeGender = (event) => {
    if (event[0]) {
      // this.setState({ gender: event[0].value });
    }
  };

  toggleShow = (value) => {
    const { show } = this.state;

    this.setState({
      show: value !== undefined ? value : !show,
    });
  };

  addressOnChange = (e) => {
    this.setState({ geocoderInput: e.target, show: false });
  };

  mapOnSelected = (viewport, items) => {
    const { geocoderInput } = this.state;
    const addressData = {};

    if (!items) {
      return;
    }

    if (items.context) {
      items.context.map((item) => {
        if (item.id.indexOf('postcode') !== -1) {
          addressData.zip = item.text;
        }

        if (item.id.indexOf('country') !== -1) {
          addressData.country = item.text;
        }

        if (item.id.indexOf('region') !== -1) {
          addressData.state = item.short_code.replace('AU-', '');
        }

        if (item.id.indexOf('locality') !== -1) {
          addressData.city = item.text;
        }

        return item.id;
      });
    }

    if (items.properties.address && items.properties.address !== '') {
      const addressMach = items.properties.address.match(/\d+/);

      if (addressMach && addressMach.length !== 0) {
        [addressData.street_number] = addressMach;
      }

      addressData.address1 = items.properties.address.replace(
        addressData.street_number,
        '',
      );
    }

    if (items.id.indexOf('address') !== -1) {
      if (
        (!addressData.street_number || addressData.street_number === '') &&
        items.address &&
        items.address !== ''
      ) {
        addressData.street_number = items.address;
      }

      if (
        (!addressData.address1 || addressData.address1 === '') &&
        items.text &&
        items.text !== ''
      ) {
        addressData.address1 = items.text;
      }
    }

    this.setState({ addressData });

    this.setState({ viewport });

    geocoderInput.value = items.place_name;

    this.setState({ addressMapBox: items.place_name, show: true });
  };

  goBack = (pathname = '') => {
    const { user, history } = this.props;

    user.currentStep = user.previousStep;

    if (pathname === REGISTRATION_BANKS_SPENDING_ACCOUNT) {
      this.setState({ isSpending: true });
    }

    if (pathname === REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT) {
      this.setState({ isWithdrawal: true });
    }

    if (pathname === REGISTRATION_PERSONAL_INFO) {
      // this.setState({ firstName: '', lastName: '', phoneNumber: '' });
    }

    if (pathname === REGISTRATION_PERSONAL_ADDRESS) {
      this.setState({
        addressData: {
          street_number: '',
          address1: '',
          city: '',
          state: '',
          zip: '',
        },
      });
    }

    if (pathname) {
      user.currentStep = pathname;

      history.push(pathname);
    } else {
      history.push(user.previousStep);
    }
  };

  handleChangeLogin = (e) => {
    if (e.target.type === 'checkbox') {
      this.setState({ activeFieldInfo: '' });
    } else {
      if (
        e.target.name === EMAIL_TARGET ||
        e.target.name === CONFIRM_EMAIL_TARGET
      ) {
        const mail = e.target.value;
        const pattern = EMAIL_REGEX;

        this.setState(
          e.target.name === EMAIL_TARGET
            ? { validEmail: pattern.test(mail) }
            : { validConfirmEmail: pattern.test(mail) },
        );
      } else if (e.target.name === PASSWORD_TARGET) {
        const password = e.target.value;
        const patternCapital = /[A-Z]/;
        const patternLower = /[a-z]/;
        const patternNumber = /[0-9]/;

        this.setState({
          validPassword:
            patternCapital.test(password) &&
            patternLower.test(password) &&
            patternNumber.test(password) &&
            password.length >= 8,
        });
      }

      this.setState({ activeFieldInfo: e.target.name });
    }
  };

  checkUserOnLogin = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      const { actions } = this.props;

      if (!hasValidationErrors) {
        if (MY_APP) {
          const data = {
            email: values.email,
            password: values.password,
          };

          this.setState({ showDotsLoader: true });

          actions.registrationStepAccount(data, '', {
            form: REGISTRATION_ACCOUNT_AGEREEMENT_FORM,
          });
        }

        if (AU_APP) {
          this.setState({
            showDotsLoader: true,
            passwordAuth: values.password,
            emailAuth: values.email,
          });

          actions.login(values, null, true).then(() => {
            this.setState({ showDotsLoader: false });
          });
        }
      }
    }
  };

  handleChangeInfo = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };

  handleSubmitInfo = (values) => {
    const { phoneNumber, userFormData, dob } = this.state;
    const { actions, user, t } = this.props;

    if (phoneNumber === '') {
      this.setState({ errorMessagePhone: t('fields.validation.required') });
    } else if (
      (AU_APP && phoneNumber.includes('_')) ||
      (MY_APP && phoneNumber.includes('-') && phoneNumber.length < 11) ||
      (MY_APP && !phoneNumber.includes('-') && phoneNumber.length < 10)
    ) {
      this.setState({ phoneNumber: '' });

      this.setState({
        errorMessagePhone: t('fields.validation.invalidField', {
          fieldName: t('fields.phoneNumber.label'),
        }),
      });
    } else {
      this.setState({ errorMessagePhone: '' });

      const formData = values;

      if (MY_APP) {
        formData.gender =
          values.gender_name && values.gender_name.length
            ? values.gender_name[0].value
            : '';
      }

      if (AU_APP && dayjs(dob).isValid()) {
        formData.dob = dob;
      } else if (AU_APP) {
        this.setState({
          dobError: true,
        });

        return;
      }

      formData.phone_number = phoneNumber;

      actions.showLoader();

      Firebase.analytics().logEvent('RegPhase7Profile');

      actions
        .registrationStepAccount(formData, '', {
          form: REGISTRATION_PERSONAL_INFO,
          user,
        })
        .then(() => {
          actions.getUserData(false).then(() => {
            this.setState({
              dobError: false,
              userFormData: {
                ...userFormData,
                gender: user.user.gender,
              },
            });
          });
        });
    }
  };

  handleSubmitAddress = (e, values, form, hasValidationErrors) => {
    const { actions } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        actions.showLoader();

        actions
          .registrationStepAccount(values, '', {
            form: REGISTRATION_PERSONAL_ADDRESS,
          })
          .then(() => {
            actions.getUserData(false);
          });
      } else {
        const { show } = this.state;

        if (!show) {
          this.toggleShow(true);
        }
      }
    }
  };

  handleSubmitTaxResident = (e, values, form, hasValidationErrors) => {
    let taxResidentData = {};
    const { actions } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (values.tin_resident) {
          taxResidentData = {
            tin_country: values.tin_country?.[0]?.value,
            no_tin: values.no_tin,
            no_tin_reason:
              values.no_tin_reason && values.no_tin_reason.length
                ? values.no_tin_reason[0].value
                : '',
            ssn: values.ssn,
            tin_resident: values.tin_resident,
            ...(values.pep && { pep: values.pep }),
          };
        } else {
          taxResidentData = {
            no_tin_reason: '',
            ssn: '',
            tin_country: '',
            no_tin: false,
            tin_resident: false,
            ...(values.pep && { pep: values.pep }),
          };
        }

        this.setState({ taxResidentData });

        actions.showLoader();

        actions
          .registrationStepAccount(values, '', {
            form: REGISTRATION_PERSONAL_TAXRESIDENT,
          })
          .then(() => {
            actions.getUserData(false);
          });
      }
    }
  };

  handleSubmitAccountQuestion = (e, values, form, hasValidationErrors) => {
    const { actions } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        actions.showLoader();

        actions.registrationStepAccount(values, '', {
          form: REGISTRATION_ACCOUNT_QUESTIONS,
        });
      }
    }
  };

  handleSubmitAgreement = (e) => {
    const { actions } = this.props;
    const { passwordAuth, emailAuth } = this.state;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      actions.showLoader();

      const data = {
        email: emailAuth,
        password: passwordAuth,
      };

      const sourceId = sessionStorage.getItem(SESSION_STORAGE_KEYS.sourceId);

      if (sourceId) {
        data.source_id = sourceId;

        sessionStorage.removeItem(SESSION_STORAGE_KEYS.sourceId);
      }

      if (AU_APP) {
        Firebase.analytics().logEvent('Reg_PDS');
      } else {
        Firebase.analytics().logEvent('RegPhase2Agree');
      }

      actions
        .registrationStepAccount(data, '', {
          form: REGISTRATION_ACCOUNT_AGEREEMENT_FORM,
        })
        .then(() => {
          if (
            AU_APP &&
            sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
              'superFund'
          ) {
            // add smsf user type
            actions.setSMSFUserType();
          }
        });
    }
  };

  handleSubmitInvestments = (e, values, form, hasValidationErrors) => {
    const { actions, t } = this.props;
    const { amount } = this.state;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (hasValidationErrors) return;

      if (amount) {
        this.setState({ showStepAccountError: '' });

        actions.showLoader();

        actions.registrationStepAccount({ investment: { amount } }, '', {
          form: REGISTRATION_INVESTMENTS_INVESTMENT,
        });
      } else {
        this.setState({
          showStepAccountError: t('fields.validation.enterAmount'),
        });
      }
    }
  };

  handleSubmitStepAccount = (values, e, form) => {
    const { actions } = this.props;

    if (
      form.form === REGISTRATION_ACCOUNT_MYKAD ||
      form.form === REGISTRATION_SUPERFUND_INFO ||
      form.form === REGISTRATION_SUPERFUND_MEMBERS
    ) {
      actions.registrationStepAccount(values, '', { form: form.form });
    }
  };

  toggleKTPInfo = (condition) => {
    this.setState({
      showKTPInfo: condition,
    });
  };

  togglePertanyaanInfo = (condition) => {
    this.setState({
      showPertanyaanInfo: condition,
    });
  };

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  goToRegistration() {
    const { actions } = this.props;

    actions.goToRegistration();
  }

  goToLogin() {
    const { actions } = this.props;

    actions.goToLogin();
  }

  linkFunding() {
    const { actions } = this.props;

    actions.linkFundingNow();
  }

  showResidentInfoModal(showModal) {
    if (showModal) {
      document.querySelector('input[name="us_citizen"]').click();
    }

    this.setState({ isShowResidentInfoModal: showModal });
  }

  compleeteRegistration() {
    const { actions } = this.props;

    actions.registrationStepAccount(
      {},
      '',
      { form: REGISTRATION_INVESTMENTS_INVESTMENT },
      true,
    );
  }

  render() {
    const {
      user,
      match,
      state,
      showLoader,
      errorMessage,
      isShowErrorModal,
      isShowErrorInvestingModal,
      isShowBankModalError,
      history,
      showSignUpPromo,
      isProPortfolioEnabled,
    } = this.props;

    const { stepName, stepType, inviteCode } = match.params;

    const isKidsRegistration = history?.location?.pathname === KIDS_SIGNUP;

    const {
      showActiveInput,
      show,
      viewport,
      addressMapBox,
      isShowPassword,
      firstName,
      lastName,
      phoneNumber,
      activeFieldInfo,
      errorMessagePhone,
      isSpending,
      showDotsLoader,
      addressData,
      validEmail,
      validConfirmEmail,
      userFormData,
      isShowResidentInfoModal,
      taxResidentData,
      countryList,
      validPassword,
      errorMessageMyKad,
      isWithdrawal,
      dob,
      dobError,
      showStepAccountError,
      showPertanyaanInfo,
      showKTPInfo,
      isCalendarActive,
      amount,
      isLinkWithdrawalAccountModalOpen,
    } = this.state;

    const stateMessages = state.translates.messages[state.translates.locale];
    const invitePattern = /^\/invite\/[a-zA-Z0-9]{3,6}$/;
    const isInvite = invitePattern.test(history.location.pathname);
    const currentInviteCode = inviteCode ? inviteCode.toUpperCase() : '';

    if (isInvite) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.inviteCode,
        currentInviteCode,
      );
    }

    return (
      <RegistrationView
        isKidsRegistration={isKidsRegistration}
        togglePertanyaanInfo={this.togglePertanyaanInfo}
        toggleKTPInfo={this.toggleKTPInfo}
        showPertanyaanInfo={showPertanyaanInfo}
        showKTPInfo={showKTPInfo}
        showSignUpPromo={showSignUpPromo}
        showStepAccountError={showStepAccountError}
        countryList={countryList}
        taxResidentData={taxResidentData}
        isShowResidentInfoModal={isShowResidentInfoModal}
        showResidentInfoModal={this.showResidentInfoModal}
        onChangeGender={this.onChangeGender}
        userFormData={userFormData}
        showDotsLoader={showDotsLoader}
        showLoader={showLoader}
        validEmail={validEmail}
        validConfirmEmail={validConfirmEmail}
        validPassword={validPassword}
        login={this.checkUserOnLogin}
        errorMessage={errorMessage}
        stepType={stepType}
        stepName={stepName}
        user={user}
        stepAccount={this.handleSubmitStepAccount}
        stepInvestments={this.handleSubmitInvestments}
        handleClick={this.handleClick}
        showActiveInput={showActiveInput}
        show={show}
        toggleShow={this.toggleShow}
        viewport={viewport}
        mapOnSelected={this.mapOnSelected}
        addressOnChange={this.addressOnChange}
        addressMapBox={addressMapBox}
        goBack={this.goBack}
        loginData={state.user.loginData}
        handleChangeLogin={this.handleChangeLogin}
        toggleShowPassword={this.toggleShowPassword}
        isShowPassword={isShowPassword}
        handleChangeInfo={this.handleChangeInfo}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        activeFieldInfo={activeFieldInfo}
        handleSubmitInfo={this.handleSubmitInfo}
        errorMessagePhone={errorMessagePhone}
        errorMessageMyKad={errorMessageMyKad}
        goToRegistration={this.goToRegistration}
        goToLogin={this.goToLogin}
        handleCloseModal={this.handleCloseModal}
        isShowErrorModal={isShowErrorModal}
        isSpending={isSpending}
        isInvite={isInvite}
        currentInviteCode={currentInviteCode}
        addressData={addressData}
        handleSubmitAddress={this.handleSubmitAddress}
        isShowErrorInvestingModal={isShowErrorInvestingModal}
        linkFunding={this.linkFunding}
        compleeteRegistration={this.compleeteRegistration}
        isShowBankModalError={isShowBankModalError}
        handleSubmitTaxResident={this.handleSubmitTaxResident}
        handleSubmitAccountQuestion={this.handleSubmitAccountQuestion}
        handleSubmitAgreement={this.handleSubmitAgreement}
        history={history}
        isWithdrawal={isWithdrawal}
        dob={dob}
        handleChangeDate={this.handleChangeDate}
        dobError={dobError}
        stateMessages={stateMessages}
        isCalendarActive={isCalendarActive}
        otherInvestmentValue={amount}
        isLinkWithdrawalAccountModalOpen={isLinkWithdrawalAccountModalOpen}
        isProPortfolioEnabled={isProPortfolioEnabled}
      />
    );
  }
}

Registration.defaultProps = {
  match: {},
  state: {},
  loginData: {},
};

const mapStateToProps = (state) => ({
  user: state.user,
  loginData: state.user.loginData,
  showLoader: state.user.showLoader,
  isLoginError: state.user.isLoginError,
  isTriedLogin: state.user.isTriedLogin,
  isNeedGoToStart: state.user.isNeedGoToStart,
  errorMessage: state.user.errorMessage,
  isShowErrorModal: state.user.isShowErrorModal,
  isShowErrorInvestingModal: state.user.isShowErrorInvestingModal,
  addressData: state.user.addressData,
  isShowBankModalError: state.user.isShowBankModalError,
  isNeedClearAuth: state.user.isNeedClearAuth,
  isRegistration: state.user.isRegistration,
  state,
  showSignUpPromo: selectIsSignUpPromoEnabled(state),
  isProPortfolioEnabled: selectIsProPortfolioEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Registration));

Registration.defaultProps = {
  showLoader: false,
  isNeedGoToStart: false,
  errorMessage: '',
  isShowErrorModal: false,
  isShowErrorInvestingModal: false,
  isShowBankModalError: false,
  isNeedClearAuth: false,
  isLoginError: false,
  isRegistration: false,
  isTriedLogin: false,
  showSignUpPromo: false,
  isProPortfolioEnabled: false,
};

Registration.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    token: PropTypes.string,
    currentStep: PropTypes.string,
    previousStep: PropTypes.string,
    current_balance: PropTypes.number,
    user: PropTypes.shape({
      dob: PropTypes.string,
      city: PropTypes.string,
      gender: PropTypes.string,
      phone_number: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  actions: PropTypes.shape({
    linkFundingNow: PropTypes.func,
    goToLogin: PropTypes.func,
    registrationStepAccount: PropTypes.func,
    goToRegistration: PropTypes.func,
    closeModal: PropTypes.func,
    setSMSFUserType: PropTypes.func,
    getUserData: PropTypes.func,
    showLoader: PropTypes.func,
    login: PropTypes.func,
    getQuestions: PropTypes.func,
    getScurityQuestion: PropTypes.func,
    goToTerms: PropTypes.func,
    registrationStepAuth: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
      stepName: PropTypes.string,
      stepType: PropTypes.string,
      inviteCode: PropTypes.string,
      fundTag: PropTypes.string,
    }),
  }),
  state: PropTypes.shape({
    form: PropTypes.shape({
      REGISTRATION_AUTH_FORM: PropTypes.string,
    }),
    translates: PropTypes.shape({
      messages: PropTypes.shape({}),
      locale: PropTypes.string,
    }),
    user: PropTypes.shape({
      loginData: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
      }),
    }),
  }),
  loginData: PropTypes.shape({}),
  showLoader: PropTypes.bool,
  isNeedGoToStart: PropTypes.bool,
  errorMessage: PropTypes.string,
  isShowErrorModal: PropTypes.bool,
  isShowErrorInvestingModal: PropTypes.bool,
  isShowBankModalError: PropTypes.bool,
  isNeedClearAuth: PropTypes.bool,
  isLoginError: PropTypes.bool,
  isRegistration: PropTypes.bool,
  isTriedLogin: PropTypes.bool,
  showSignUpPromo: PropTypes.bool,
  isProPortfolioEnabled: PropTypes.bool,
};
