import styled from '@emotion/styled';

const MINIMUM_NUMBER_OF_OFFERS_FOR_GRID = 5;

export const Wrapper = styled.div<{ offersCount: number }>`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      167px,
      ${({ offersCount }) =>
        offersCount < MINIMUM_NUMBER_OF_OFFERS_FOR_GRID ? '200px' : '1fr'}
    )
  );
  grid-auto-rows: 1fr;
  padding-bottom: 90px;
`;
