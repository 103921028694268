import styled from '@emotion/styled';

import { CleanInputWrapper } from 'components/form/number/styles';
import { PortfolioCustomizationHeaderBackgroundImage } from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationHeader/styles';
import { SelectField } from 'components/form/select';
import { Button } from 'components/elements/Button';

export const RecurringZakatPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RecurringZakatPaymentBannerWrapper = styled.div`
  border-radius: 12px;
  height: 136px;
  background: ${({ theme }) => theme.gradients.vibrantVerdant};
  overflow: hidden;
  position: relative;
`;

export const RecurringZakatPaymentBannerTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  padding-top: 48px;
`;

export const RecurringZakatPaymentHeaderBackgroundImage = styled(
  PortfolioCustomizationHeaderBackgroundImage,
)`
  transform: rotate(-35deg);
  left: 50%;
  margin-top: -136px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  background-size: auto;
`;

export const RecurringZakatPaymentPanelWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.lightPanel};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  margin-top: 16px;
`;

export const PaymentAmount = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 18px 0 16px;
`;

export const PaymentAmountLabel = styled.div`
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  flex: 1;
  padding-bottom: 6px;
`;

export const AmountInputWrapper = styled(CleanInputWrapper)`
  flex: 1;
  input {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes['5xl']};
  }
  p {
    align-self: center;
  }
`;

export const PaymentFrequencyWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  padding: 2px 0 4px;
`;

export const PaymentFrequencyContent = styled.div`
  display: flex;
`;

export const PaymentFrequencyValue = styled.div`
  color: ${({ theme }) => theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 80px;
`;

export const ZakatTypeWrapper = styled(RecurringZakatPaymentPanelWrapper)`
  text-align: left;
  padding: 16px;
  margin-top: 12px;
`;

export const ZakatType = styled(SelectField)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const SetPaymentButton = styled(Button)`
  width: 340px;
  text-transform: capitalize;
`;

export const RecurringInvestmentPreviewWrapper = styled.div`
  text-align: left;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding-top: 32px;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
`;

export const MonthlyFrequencyLabel = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  text-align: center;
`;
