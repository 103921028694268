import { IFormattedSuitabilityOption } from '../types';

export const getAdditionalSuitabilityFields = (values: {
  [key: string]: IFormattedSuitabilityOption[] | null;
}) =>
  Object.entries(values)?.reduce(
    (mappedAdditionalSuitabilityFields, [key, suitability]) => {
      const isAdditionalField = suitability?.[0]?.textInput || false;

      return {
        ...mappedAdditionalSuitabilityFields,
        [key]: isAdditionalField,
      };
    },
    {},
  );
