import React from 'react';
import Skeleton from 'react-skeleton-loader';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';

export default function CategoryCardsSkeleton() {
  return [...Array(9)].map((e, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="grid__carousel-fake" key={`slider-fake-item-${index}`}>
      <Skeleton width="90%" height="148px" color="rgba(255,255,255,0.8)" />
    </div>
  ));
}
