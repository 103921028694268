import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import { MY_APP } from '../../../constants/localeConfigs';
import FallbackMessage from '../../../components/elements/FallbackMessage/FallbackMessage';

import HistoryTransactionItem from './HistoryTransactionItem';
import TransactionDetails from './TransactionDetails';
import HistoryLoader from './HistoryLoader';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function HistoryInfoPanel({
  isSuper,
  areFailedTransactionsVisible,
  toggleShowFailedTransactions,
  isLoading,
  transactions,
  showTransactionDetail,
  transactionDetails,
  displayCancelTransactionConfirmationAlert,
  toggleShareRewardModal,
  checkIsLastTransactionInView,
}) {
  const { t } = useTranslation();

  return (
    <div className="info-panel" id="history-transactions">
      <div className="main-title">
        {t('historyDetailsPage.historyInfoPanel.title')}
      </div>

      <div className="details">
        {MY_APP && !isSuper && (
          <div className="info-panel__title">
            <div className="info-panel__title-with-btn">
              <DeprecatedButton
                title={
                  areFailedTransactionsVisible
                    ? t('historyDetailsPage.historyInfoPanel.hideFailed')
                    : t('historyDetailsPage.historyInfoPanel.viewFailed')
                }
                buttonClass={`button__basic-fill -hide-show ${
                  areFailedTransactionsVisible ? '-hide-eye' : '-view-eye'
                }`}
                handleClick={() => {
                  toggleShowFailedTransactions();
                }}
              />
            </div>
          </div>
        )}

        {!isLoading && transactions?.length > 0 && (
          <div
            className={`history__element ${!isSuper ? '-history' : '-super'}`}
          >
            <div className="history__left-part">
              {transactions.map((transaction, index) => (
                <HistoryTransactionItem
                  transaction={transaction}
                  showTransactionDetail={showTransactionDetail}
                  displayCancelTransactionConfirmationAlert={
                    displayCancelTransactionConfirmationAlert
                  }
                  transactionDetails={transactionDetails}
                  isSuper={isSuper}
                  checkIsLastTransactionInView={checkIsLastTransactionInView}
                  isLastTransaction={index !== transactions.length - 1}
                  isShowPendingTitle={!index}
                  key={`history-transaction-item${transaction.id}`}
                />
              ))}
            </div>

            {!isSuper && (
              <TransactionDetails
                toggleShareRewardModal={toggleShareRewardModal}
                isLoading={isLoading}
              />
            )}
          </div>
        )}

        {isLoading && <HistoryLoader isSuper={isSuper} />}

        {!isLoading && transactions?.length === 0 && (
          <FallbackMessage
            text={t(
              'historyDetailsPage.historyInfoPanel.noPendingTransactions',
            )}
          />
        )}
      </div>
    </div>
  );
}

HistoryInfoPanel.defaultProps = {
  isSuper: false,
};

HistoryInfoPanel.propTypes = {
  isSuper: PropTypes.bool,
  areFailedTransactionsVisible: PropTypes.bool.isRequired,
  toggleShowFailedTransactions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTransactionDetail: PropTypes.func.isRequired,
  transactionDetails: PropTypes.shape({
    is_perfect_rebalance: PropTypes.bool,
    events: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
  }).isRequired,
  displayCancelTransactionConfirmationAlert: PropTypes.func.isRequired,
  toggleShareRewardModal: PropTypes.func.isRequired,
  checkIsLastTransactionInView: PropTypes.func.isRequired,
};
