import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import {
  CoverageInfo,
  CoverageOfferedLabel,
  CoverageOfferedValue,
  CoverageOfferedWrapper,
  InsuranceCoverageInfoWrapper,
  InsuranceInfoWrapper,
  InsuranceStatusButton,
  InsuranceStatusLabel,
  InsuranceStatusText,
  InsuranceStatusWrapper,
  Link,
} from './styles';
import { useInsuranceStatus } from './hooks/useInsuranceStatus';
import { INSURANCE_STATUS, SUPER_MEMBER_GUIDE_LINK } from './consts';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { toggleInfoModal } from 'store/modals/actions';
import { useUpdateInsuranceMutation } from 'store/superAnnuation/superAnnuation';
import { selectIsInsuranceStatusEnabled } from 'store/firebaseConfig/selectors';

export const InsuranceInfo = () => {
  const { t } = useTranslation();

  const isInsuranceStatusEnabled = useAppSelector(
    selectIsInsuranceStatusEnabled,
  );

  const {
    isOptedIn,
    isRequestedOptedIn,
    statusName,
    isChangedInsuranceDisabled,
  } = useInsuranceStatus();

  const dispatch = useAppDispatch();

  const [updateInsurance, { isLoading }] = useUpdateInsuranceMutation();

  const isOptedOrRequested = isOptedIn || isRequestedOptedIn;

  const handleUpdateInsurance = useCallback(() => {
    const updateInsuranceData = {
      action: isOptedIn
        ? INSURANCE_STATUS.opted_out
        : INSURANCE_STATUS.opted_in,
    };

    updateInsurance(updateInsuranceData)
      .unwrap()
      .then(() => {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: t(
                'superInsurancePage.insuranceInfo.modal.successfullyText',
                {
                  opted: isOptedIn
                    ? t('superInsurancePage.insuranceInfo.modal.optedOutOf')
                    : t('superInsurancePage.insuranceInfo.modal.optedInto'),
                },
              ),
            },
          }),
        );
      });
  }, [dispatch, isOptedIn, updateInsurance, t]);

  const confirmInsuranceUpdate = useCallback(() => {
    if (!isOptedIn) {
      handleUpdateInsurance();

      return;
    }

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t(
            'superInsurancePage.insuranceInfo.modal.confirmationText',
          ),
          acceptButtonText: t('common.yes'),
          rejectButtonText: t('common.no'),
          onAccept: handleUpdateInsurance,
          isButtonHorizontal: true,
        },
      }),
    );
  }, [dispatch, isOptedIn, t, handleUpdateInsurance]);

  return (
    <InsuranceInfoWrapper>
      {isInsuranceStatusEnabled && (
        <InsuranceStatusWrapper>
          <InsuranceStatusText>
            <InsuranceStatusLabel>
              {t('superInsurancePage.insuranceInfo.statusRow.label', {
                statusName,
              })}
            </InsuranceStatusLabel>
          </InsuranceStatusText>

          <InsuranceStatusButton
            type="button"
            isLoading={isLoading}
            label={
              isOptedIn || isRequestedOptedIn
                ? t('superInsurancePage.insuranceInfo.statusRow.buttons.optOut')
                : t('superInsurancePage.insuranceInfo.statusRow.buttons.optIn')
            }
            isOptedOrRequested={isOptedOrRequested}
            onClick={confirmInsuranceUpdate}
            isDisabled={isChangedInsuranceDisabled}
            variant="initial"
          />
        </InsuranceStatusWrapper>
      )}

      <CoverageOfferedWrapper>
        <CoverageOfferedLabel>
          {t('superInsurancePage.coverageOffered.label')}
        </CoverageOfferedLabel>

        <CoverageOfferedValue>
          {t('superInsurancePage.coverageOffered.death')}
        </CoverageOfferedValue>

        <CoverageOfferedValue>
          {t('superInsurancePage.coverageOffered.total')}
        </CoverageOfferedValue>
      </CoverageOfferedWrapper>

      <InsuranceCoverageInfoWrapper>
        <CoverageInfo>
          {t('superInsurancePage.coverageInfo.amountAndPremium')}
        </CoverageInfo>
      </InsuranceCoverageInfoWrapper>

      <InsuranceCoverageInfoWrapper>
        <CoverageInfo>
          <Trans
            t={t}
            i18nKey="superInsurancePage.coverageInfo.moreInformation"
            components={{
              link: (
                <Link
                  title={t(
                    'superInsurancePage.coverageInfo.superMemberGuideLinkText',
                  )}
                  href={SUPER_MEMBER_GUIDE_LINK}
                />
              ),
            }}
          />
        </CoverageInfo>
      </InsuranceCoverageInfoWrapper>
    </InsuranceInfoWrapper>
  );
};
