import ElementButton from './Button';

export interface IButtonProps
  extends Partial<
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'>
  > {
  icon?: React.ReactNode;
  className?: string;
  buttonClass?: string;
  to?: string;
  handleClick?: (...args: never[]) => void;
  disabled?: boolean;
  withLoader?: boolean;
  isLink?: boolean;
  title?: React.ReactNode;
}

/**
 * @deprecated use components/elements/Button
 */
export const BaseButton = ({ className, ...props }: IButtonProps) => (
  <ElementButton {...props} buttonClass={className} />
);
