import { useTranslation } from 'react-i18next';

import { STATUS } from 'constants/api';
import { ALERT_TYPES } from 'constants/alerts';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';

interface IOpenDocumentUploadSuccessModalProps {
  currentAlert: string;
  statusCode?: number;
}

export const useDocumentUploadSuccessModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openDocumentUploadSuccessModal = ({
    currentAlert,
    statusCode,
  }: IOpenDocumentUploadSuccessModalProps) => {
    if (statusCode !== STATUS.created) {
      return;
    }

    const isMultipleDocumentsUpload =
      currentAlert === ALERT_TYPES.idUploadErrors.bankStatementUploadMissing;

    const description = t(
      'alertsPage.documentUploadResultModal.attemptToApproveDoc',
      {
        documentValue: isMultipleDocumentsUpload
          ? t('common.documents')
          : t('common.document'),
      },
    );

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('alertsPage.documentUploadResultModal.title'),
          description,
        },
      }),
    );
  };

  return { openDocumentUploadSuccessModal };
};
