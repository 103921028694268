import { IUseRoundUpsSettings, TThreshold } from '../components/types';
import { DISABLED_MULTIPLIER_VALUE } from '../components/consts';
import getMinimumAvailableMultiplayer from '../utils/getMinimumAvailableMultiplayer';

import { useRoundUpsSettingsState } from './useRoundUpsSettingsState';

import { FIREBASE_EVENTS } from 'constants/firebaseEvents';
import { logFirebaseEvent } from 'utils/logFirebaseEvent';
import { useUpdateRoundUpsSettingsMutation } from 'store/roundUpsSettings/roundUpsSettings';

export const useRoundUpsSettings = ({
  handleCloseModal,
}: IUseRoundUpsSettings) => {
  const { roundUpsSettings, setRoundUpsSettings } = useRoundUpsSettingsState();

  const [updateRoundUpsSettings, { isLoading }] =
    useUpdateRoundUpsSettingsMutation();

  const handleSave = () => {
    updateRoundUpsSettings(roundUpsSettings)
      .unwrap()
      .then(() => {
        handleCloseModal();
      });
  };

  const { available_thresholds, default_amount } = roundUpsSettings || {};

  const toggleAutoRoundUps = (isEnabled: boolean) => {
    if (!isEnabled) {
      logFirebaseEvent(FIREBASE_EVENTS.roundUpsAutoOffClicked);
    } else {
      logFirebaseEvent(FIREBASE_EVENTS.roundUpsAutoOnClicked);
    }

    setRoundUpsSettings({
      ...roundUpsSettings,
      automatic_roundup: isEnabled,
    });
  };

  const handleChangeThreshold = ({ lower, upper }: TThreshold) => {
    if (available_thresholds) {
      setRoundUpsSettings({
        ...roundUpsSettings,
        automatic_lower_threshold: lower,
        automatic_upper_threshold: upper,
      });
    }
  };

  const toggleMultiplier = () => {
    const { amount_multiplier, available_amount_multipliers } =
      roundUpsSettings;

    setRoundUpsSettings({
      ...roundUpsSettings,
      amount_multiplier:
        amount_multiplier && amount_multiplier > DISABLED_MULTIPLIER_VALUE
          ? DISABLED_MULTIPLIER_VALUE
          : getMinimumAvailableMultiplayer(available_amount_multipliers),
    });
  };

  const handleChangeMultiplier = (multiplier: number) => {
    setRoundUpsSettings({
      ...roundUpsSettings,
      amount_multiplier: multiplier,
    });
  };

  const handleChangeDefaultAmount = (value: number) => {
    if (value && default_amount !== value) {
      setRoundUpsSettings({
        ...roundUpsSettings,
        default_amount: value,
      });
    }
  };

  return {
    isLoading,
    roundUpsSettings,
    handleCloseModal,
    handleSave,
    toggleAutoRoundUps,
    handleChangeThreshold,
    handleChangeDefaultAmount,
    handleChangeMultiplier,
    toggleMultiplier,
  };
};
