// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Form } from 'react-final-form';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsInsuranceStatusEnabled } from 'store/firebaseConfig/selectors';

export default function InsuranceCoverForm({
  handleSubmit,
  handleChangeInsurance,
  disclosureChecked,
  insuranceChecked,
  receivingChecked,
  backInChecked,
  agreeChecked,
  pdsErrors,
  isPdsErrors,
  superUser,
  user,
  goBack,
}) {
  const errorClass = 'checkbox__container relative error';
  const checkboxClass = 'checkbox__container relative';
  const { t } = useTranslation();

  const isInsuranceStatusEnabled = useAppSelector(
    selectIsInsuranceStatusEnabled,
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        us_citizen: user.us_citizen,
      }}
      render={({ form, values, hasValidationErrors }) => (
        <form
          onChange={handleChangeInsurance}
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className="content">
            <div className="title">
              {t('superPage.superRegistration.insuranceCoverForm.title')}
            </div>

            {!user.super_annuation_insurance_eligible && (
              <div className="text">
                <div>
                  {t(
                    'superPage.superRegistration.insuranceCoverForm.nonEligible',
                  )}
                </div>
              </div>
            )}

            {user.super_annuation_insurance_eligible && (
              <div className="text">
                <div>
                  {t('superPage.superRegistration.insuranceCoverForm.lifeTpd')}
                </div>

                {superUser && superUser.insurance_coverage && (
                  <div>
                    <Trans
                      i18nKey="superPage.superRegistration.insuranceCoverForm.coverage"
                      t={t}
                      components={{
                        coverage: formatNumber({
                          value: superUser.insurance_coverage,
                        }),
                        premium: formatNumber({
                          value: superUser.insurance_premium,
                        }),
                      }}
                    />
                  </div>
                )}

                <div>
                  {t('superPage.superRegistration.insuranceCoverForm.confirm')}
                </div>
              </div>
            )}

            <div className="form">
              <div className="profile-content__container-item no-border pds">
                <div
                  className={
                    !disclosureChecked && pdsErrors.disclosure && isPdsErrors
                      ? errorClass
                      : checkboxClass
                  }
                >
                  <CheckboxFF
                    classNameInsurance={
                      values.disclosure
                        ? 'checkbox__check'
                        : 'checkbox__uncheck'
                    }
                    name="disclosure"
                    type="checkbox"
                    defaultChecked={values.disclosure}
                  />

                  <div className="checkbox__label">
                    {t(
                      'superPage.superRegistration.insuranceCoverForm.disclosureStatement',
                    )}

                    {!disclosureChecked && pdsErrors.disclosure && (
                      <span>
                        {' ('}
                        {t('fields.validation.required')})
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className={
                    !insuranceChecked && pdsErrors.insurance
                      ? errorClass
                      : checkboxClass
                  }
                >
                  <CheckboxFF
                    classNameInsurance={
                      values.insurance ? 'checkbox__check' : 'checkbox__uncheck'
                    }
                    name="insurance"
                    defaultChecked={insuranceChecked}
                  />

                  <div className="checkbox__label">
                    {t(
                      'superPage.superRegistration.insuranceCoverForm.existingInsurance',
                    )}

                    {!insuranceChecked && pdsErrors.insurance && (
                      <span>
                        {' ('}
                        {t('fields.validation.required')})
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className={
                    !receivingChecked && pdsErrors.receiving
                      ? errorClass
                      : checkboxClass
                  }
                >
                  <CheckboxFF
                    classNameInsurance={
                      values.receiving ? 'checkbox__check' : 'checkbox__uncheck'
                    }
                    name="receiving"
                    defaultChecked={receivingChecked}
                  />

                  <div className="checkbox__label">
                    {t(
                      'superPage.superRegistration.insuranceCoverForm.intoReceiving',
                    )}

                    {!receivingChecked && pdsErrors.receiving && (
                      <span>
                        {' ('}
                        {t('fields.validation.required')})
                      </span>
                    )}
                  </div>
                </div>

                {!isInsuranceStatusEnabled && (
                  <div
                    className={
                      !backInChecked && pdsErrors.back
                        ? errorClass
                        : checkboxClass
                    }
                  >
                    <CheckboxFF
                      classNameInsurance={
                        values.back ? 'checkbox__check' : 'checkbox__uncheck'
                      }
                      name="back"
                      defaultChecked={backInChecked}
                    />

                    <div className="checkbox__label">
                      {t(
                        'superPage.superRegistration.insuranceCoverForm.backIn',
                      )}

                      {!backInChecked && pdsErrors.back && (
                        <span>
                          {' ('}
                          {t('fields.validation.required')})
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div
                  className={
                    !agreeChecked && pdsErrors.agree
                      ? errorClass
                      : checkboxClass
                  }
                >
                  <CheckboxFF
                    classNameInsurance={
                      values.agree ? 'checkbox__check' : 'checkbox__uncheck'
                    }
                    name="agree"
                    defaultChecked={agreeChecked}
                  />

                  <div className="checkbox__label">
                    {t('superPage.superRegistration.insuranceCoverForm.iAgree')}

                    {!agreeChecked && pdsErrors.agree && (
                      <span>
                        {' ('}
                        {t('fields.validation.required')})
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="bottom-content">
            <div className="left-part" />

            <div className="right-part">
              <DeprecatedButton
                title={<Trans i18nKey="common.back" t={t} />}
                buttonClass="back"
                type="button"
                handleClick={goBack}
              />

              <DeprecatedButton
                title={<Trans i18nKey="common.continue" t={t} />}
                type="button"
                handleClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

InsuranceCoverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChangeInsurance: PropTypes.func.isRequired,
  disclosureChecked: PropTypes.bool.isRequired,
  insuranceChecked: PropTypes.bool.isRequired,
  receivingChecked: PropTypes.bool.isRequired,
  backInChecked: PropTypes.bool.isRequired,
  agreeChecked: PropTypes.bool.isRequired,
  pdsErrors: PropTypes.objectOf(PropTypes.bool).isRequired,
  isPdsErrors: PropTypes.bool.isRequired,
  superUser: PropTypes.shape({
    insurance_coverage: PropTypes.string,
    insurance_premium: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    super_annuation_insurance_eligible: PropTypes.bool,
    us_citizen: PropTypes.bool,
    email: PropTypes.string,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
};
