import { DEFAULT_PRO_PORTFOLIO_COLOR, PORTFOLIO_TYPES } from '../consts';

import theme from 'theme';

const getPortfolioColor = ({
  portfolioType,
  portfolioColor,
  isProPortfolio,
  isRegistration,
}) => {
  if (isRegistration && !isProPortfolio) {
    return theme.colors.white;
  }

  if (isProPortfolio) {
    if (portfolioColor?.start && portfolioColor?.end) {
      return `linear-gradient(${portfolioColor.start}, ${portfolioColor.end})`;
    }

    return `linear-gradient(${DEFAULT_PRO_PORTFOLIO_COLOR.start}, ${DEFAULT_PRO_PORTFOLIO_COLOR.end})`;
  }

  switch (portfolioType) {
    case PORTFOLIO_TYPES.moderate:
      return theme.colors.purpleHeart;
    case PORTFOLIO_TYPES.conservative:
      return theme.colors.reddishOrange;
    case PORTFOLIO_TYPES.aggressive:
      return theme.colors.tealishBlue;
    case PORTFOLIO_TYPES.moderatelyConservative:
      return theme.colors.purpleHeartLighter;
    case PORTFOLIO_TYPES.moderatelyAggressive:
      return theme.colors.purpleHeartDark;
    case PORTFOLIO_TYPES.emerald:
      return theme.colors.greenishCyan;
    case PORTFOLIO_TYPES.sapphire:
      return theme.colors.cyanBlue;
    case PORTFOLIO_TYPES.property:
      return theme.colors.purpleHeartDark;
    default:
      return theme.colors.purpleHeartDark;
  }
};

export default getPortfolioColor;
