import React from 'react';
import PropTypes from 'prop-types';

import {
  TopHoldingsItemImage,
  TopHoldingsItemLabel,
  TopHoldingsItemSymbol,
  TopHoldingsItemValue,
  TopHoldingsItemWrapper,
} from './styles';

export default function TopHoldingsItem({ topHolding }) {
  const { name, symbol, image_url, percentage } = topHolding;

  return (
    <TopHoldingsItemWrapper>
      <TopHoldingsItemImage background={image_url} />

      <TopHoldingsItemLabel>
        {name}

        <TopHoldingsItemSymbol>{symbol}</TopHoldingsItemSymbol>
      </TopHoldingsItemLabel>

      <TopHoldingsItemValue>{`${percentage}%`}</TopHoldingsItemValue>
    </TopHoldingsItemWrapper>
  );
}

TopHoldingsItem.propTypes = {
  topHolding: PropTypes.shape({
    name: PropTypes.string,
    symbol: PropTypes.string,
    image_url: PropTypes.string,
    percentage: PropTypes.number,
  }).isRequired,
};
