import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import { useTranslation, Trans } from 'react-i18next';

import { REGISTRATION_SUPERFUND_INFO } from '../../../store/user/consts';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import { CORPORATE, INDIVIDUAL } from '../../../constants/comonConstants';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const onKeyDown = (e) => {
  e.stopPropagation();

  e.preventDefault();
};

const validate = (values, t, fundType) => {
  const errors = {};

  const required = t('fields.validation.required');

  if (!values.legal_fund_name) {
    errors.legal_fund_name = required;
  }

  if (!values.fund_abn) {
    errors.fund_abn = required;
  }

  if (!values.display_fund_name) {
    errors.display_fund_name = required;
  }

  if (!values.fund_address) {
    errors.fund_address = required;
  }

  if (fundType === CORPORATE && !values.legal_company_name) {
    errors.legal_company_name = required;
  }

  if (fundType === CORPORATE && !values.company_acn) {
    errors.company_acn = required;
  }

  if (fundType === CORPORATE && !values.company_address) {
    errors.company_address = required;
  }

  return errors;
};

export default function SMSFInformation({
  handleSubmit,
  fundType,
  changeFundType,
  fundEstablishmentDate,
  handleChangeEstablishmentDate,
  fundEstablishmentDateError,
}) {
  const { t } = useTranslation();
  const maxDate = new Date();
  const isIndividual = fundType === INDIVIDUAL;
  const isCorporate = fundType === CORPORATE;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values, t, fundType)}
      render={({ form, values, hasValidationErrors, submitting }) => (
        <form
          className="form"
          onSubmit={(e) =>
            handleSubmit(
              e,
              values,
              form,
              hasValidationErrors,
              REGISTRATION_SUPERFUND_INFO,
            )
          }
        >
          <div className="radio">
            <div className="">
              <CheckboxFF
                name="individual"
                classList="checkbox__list"
                defaultChecked={isIndividual}
                onClick={() => {
                  changeFundType('individual');
                }}
                label={
                  <Trans
                    i18nKey="registrationPage.smsfInformation.individualsTrustee"
                    t={t}
                  />
                }
              />

              <CheckboxFF
                name="corporate"
                classList="checkbox__list"
                defaultChecked={isCorporate}
                onClick={() => {
                  changeFundType(CORPORATE);
                }}
                label={
                  <Trans
                    i18nKey="registrationPage.smsfInformation.corporateTrustee"
                    t={t}
                  />
                }
              />
            </div>

            {isIndividual || isCorporate ? (
              <div className=" form-field__sub-input-link registration-content__form">
                <FinalFormInput
                  name="legal_fund_name"
                  label={t('registrationPage.smsfInformation.legalNameFund')}
                />

                <FinalFormInput
                  name="display_fund_name"
                  maxLength="20"
                  label={t('registrationPage.smsfInformation.displayNameFund')}
                />

                <FinalFormInput
                  name="fund_abn"
                  label={t('registrationPage.smsfInformation.abnFund')}
                />

                <div
                  className="MuiFormControl-root MuiTextField-root without-underline MuiFormControl-fullWidth
                relative form-field birthday -with-space"
                >
                  <label
                    className="MuiFormLabel-root MuiInputLabel-shrink"
                    htmlFor="establishment_date"
                  >
                    {t('registrationPage.smsfInformation.establishmentDate')}
                  </label>

                  <div className=" relative form-field">
                    <DatePicker
                      maxDate={maxDate}
                      onCalendarOpen={() =>
                        handleChangeEstablishmentDate(maxDate, true)
                      }
                      className="raiz-datepicker"
                      name="establishment_date"
                      value={
                        dayjs(fundEstablishmentDate).isValid()
                          ? fundEstablishmentDate
                          : ''
                      }
                      onChange={handleChangeEstablishmentDate}
                      format="dd.MM.yyyy"
                      clearIcon={false}
                      locale="au"
                      calendarIcon={false}
                      onKeyDown={(e) => onKeyDown(e)}
                      onKeyPress={(e) => onKeyDown(e)}
                    />

                    {fundEstablishmentDateError && submitting && (
                      <div className="error-message">
                        {t('fields.validation.required')}
                      </div>
                    )}
                  </div>
                </div>

                <FinalFormInput
                  name="fund_address"
                  label={t('registrationPage.smsfInformation.addressFund')}
                />

                {isCorporate && <hr className="-spacer" />}

                {isCorporate && (
                  <FinalFormInput
                    name="legal_company_name"
                    label={t(
                      'registrationPage.smsfInformation.legalNameCompany',
                    )}
                  />
                )}

                {isCorporate && (
                  <FinalFormInput
                    name="company_acn"
                    label={t('registrationPage.smsfInformation.acnCompany')}
                  />
                )}

                {isCorporate && (
                  <FinalFormInput
                    name="company_address"
                    label={t('registrationPage.smsfInformation.addressCompany')}
                  />
                )}
              </div>
            ) : (
              <div className="registration-content__space" />
            )}

            <div className="buttons-part">
              <div className="form-field">
                <DeprecatedButton
                  type="submit"
                  disabled={!isIndividual && !isCorporate}
                  title={t('common.next')}
                  buttonClass={`button button--submit ${
                    !isIndividual && !isCorporate ? '-disabled' : ''
                  }`}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

SMSFInformation.propTypes = {
  fundEstablishmentDateError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  fundEstablishmentDate: PropTypes.string.isRequired,
  fundType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeEstablishmentDate: PropTypes.func.isRequired,
  changeFundType: PropTypes.func.isRequired,
  smsfDetails: PropTypes.shape({}).isRequired,
};

SMSFInformation.defaultProps = {};
