import { showGlobalError } from '../errors/actions';
import { createOutRequest, createRequest } from '../user/actions';
import { STATEMENTS_MONTHLY } from '../../constants/comonConstants';
import { checkIsDependentUser } from '../../utils/user';

import { INVEST_ACTIONS } from './consts';
import { selectStatements } from './selectors';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function getBillingAccountsSuccess(billingAccounts) {
  return { type: INVEST_ACTIONS.GET_BILLING_ACCOUNTS_SUCCESS, billingAccounts };
}

export function showErrorBillingAccounts(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_BILLING_ACCOUNTS,
    errorMessage,
    errorTitle,
  };
}

export function getStatementsSuccess(statements) {
  return { type: INVEST_ACTIONS.GET_STATEMENTS_SUCCESS, statements };
}

export function showErrorGetStatements(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_GET_STATEMENTS,
    errorMessage,
    errorTitle,
  };
}

export function getStatementsUrlSuccess(statementsUrl) {
  return { type: INVEST_ACTIONS.GET_STATEMENTS_URL_SUCCESS, statementsUrl };
}

export function showErrorGetStatementsUrl(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_GET_STATEMENTS_URL,
    errorMessage,
    errorTitle,
  };
}

export function getStatementsUrlContentSuccess(statementsUrlContent) {
  return {
    type: INVEST_ACTIONS.GET_STATEMENTS_URL_CONTENT_SUCCESS,
    statementsUrlContent,
  };
}

export function getSuperFundsListSuccess(fundsList) {
  return { type: INVEST_ACTIONS.GET_SUPER_FUNDS_LIST_SUCCESS, fundsList };
}

export function getAllocationProfilesSuccess(allocationProfile) {
  return {
    type: INVEST_ACTIONS.GET_ALLOCATION_PROFILE_SUCCESS,
    allocationProfile,
  };
}

export function deleteBillingAccountSuccess(fundsList) {
  return { type: INVEST_ACTIONS.DELETE_BILLING_ACCOUNT_SUCCESS, fundsList };
}

export function setSuperFundSuccess() {
  return { type: INVEST_ACTIONS.SET_SUPER_FUND_SUCCESS };
}

export function showErrorGetStatementsUrlContent(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_GET_STATEMENTS_URL_CONTENT,
    errorMessage,
    errorTitle,
  };
}

export function showErrorGetSuperFundsList(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_GET_SUPER_FUNDS_LIST,
    errorMessage,
    errorTitle,
  };
}

export function showErrorDeleteBillingAccount(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_DELETE_BILLING_ACCOUNT,
    errorMessage,
    errorTitle,
  };
}

export function showErrorSetSuperFund(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_SET_SUPER_FUND,
    errorMessage,
    errorTitle,
  };
}

export function oneTimeInvestSuccess(goals) {
  return { type: INVEST_ACTIONS.ONE_TIME_INVEST_SUCCESS, goals };
}

export function showErrorOneTimeInvest(errorMessage, errorTitle = '') {
  return {
    type: INVEST_ACTIONS.SHOW_ERROR_ONE_TIME_INVEST,
    errorMessage,
    errorTitle,
  };
}

export function withdrawalAllMoneyClose() {
  return { type: INVEST_ACTIONS.WITHDRAW_ALL_MONEY_CLOSE };
}

export function oneTimeInvest(data) {
  const requestData = JSON.stringify({ investment: data });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'investments')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorOneTimeInvest(response.error));
        } else {
          dispatch(oneTimeInvestSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getBillingAccounts() {
  return (dispatch) =>
    createRequest(null, 'GET', 'billing_accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorBillingAccounts(response.error));
        } else {
          dispatch(getBillingAccountsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getStatements({
  type = '',
  isRaizKids = false,
  childId = '',
  isRaizSuper = false,
  userType = '',
  offset = 0,
}) {
  if (isRaizKids) {
    const kidsType = type.includes(',')
      ? type.replace(',', '&statement_types[]=')
      : type;

    return (dispatch, getState) =>
      createOutRequest(
        null,
        'GET',
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/statements?dependent_user_id=${childId}&statement_types[]=${kidsType}`,
      )
        .then((response) => response.json())
        .then((response) => {
          const state = getState();

          const statements = selectStatements({ isStatementsNested: true })(
            state,
          );

          if (response.error) {
            dispatch(showErrorGetStatements(response.error));
          } else {
            dispatch(
              getStatementsSuccess(
                offset > 1
                  ? {
                      ...response,
                      statements: [...statements, ...response.statements],
                    }
                  : response,
              ),
            );
          }
        })
        .catch((error) => dispatch(showGlobalError(error)));
  }

  if (isRaizSuper) {
    return (dispatch, getState) =>
      createRequest(
        null,
        'GET',
        `user/statements?type=super_annuation_statement&statement_types[]=super_annuation_user${
          offset ? `&offset=${offset}` : ''
        }`,
      )
        .then((response) => response.json())
        .then((response) => {
          const { invest } = getState();

          if (response.error) {
            dispatch(showErrorGetStatements(response.error));
          } else {
            dispatch(
              getStatementsSuccess(
                offset > 1
                  ? {
                      ...response,
                      statements: [
                        ...invest.statements.statements,
                        ...response.statements,
                      ],
                    }
                  : response,
              ),
            );
          }
        })
        .catch((error) => dispatch(showGlobalError(error)));
  }

  if (checkIsDependentUser(userType) && type === STATEMENTS_MONTHLY) {
    return (dispatch) =>
      createRequest(
        null,
        'GET',
        'user/statements?type=regular_user_statement&statement_types[]=dependent_user',
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            dispatch(showErrorGetStatements(response.error));
          } else {
            dispatch(getStatementsSuccess(response));
          }
        })
        .catch((error) => dispatch(showGlobalError(error)));
  }

  return (dispatch) =>
    createRequest(null, 'GET', `investments/statements?type=${type}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetStatements(response.error));
        } else {
          dispatch(getStatementsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getStatementsUrl(data) {
  return (dispatch) =>
    createRequest(null, 'GET', `investments/statement_link?id=${data.id}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error || response.message) {
          dispatch(
            showErrorGetStatementsUrl(response.error || response.message),
          );
        } else {
          dispatch(getStatementsUrlSuccess(response));

          if (data?.responseFunction) {
            data?.responseFunction(response);
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getStatementsUrlContent(data) {
  return (dispatch) =>
    createOutRequest(null, 'GET', `${data}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetStatementsUrlContent(response.error));
        } else {
          dispatch(getStatementsUrlContentSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSuperFundsList() {
  return (dispatch) =>
    createRequest(null, 'GET', 'super_funds?limit=25&offset=1')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetSuperFundsList(response.error));
        } else {
          dispatch(getSuperFundsListSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAllocationProfile() {
  return (dispatch) =>
    createRequest(null, 'GET', 'allocation_profiles')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getAllocationProfilesSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function deleteBillingAccount() {
  return (dispatch) =>
    createRequest(null, 'DELETE', 'billing_accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorDeleteBillingAccount(response.error));
        } else {
          dispatch(deleteBillingAccountSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function setSuperFund(data) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const requestData = JSON.stringify({
    token,
    billing_account: {
      member_id: data.member_id,
      super_fund_id: data.super_fund_id,
    },
  });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'billing_accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorSetSuperFund(response.error));
        } else {
          dispatch(setSuperFundSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
