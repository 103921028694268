import styled from '@emotion/styled';

import { DELAY } from './consts';

export const DotsLoaderWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
`;

export const DotsLoaderItem = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: ${({ gap }) => gap}px;
  background-color: ${({ theme }) => theme.colors.silver};
  opacity: ${({ theme }) => theme.opacity.lightTransparent};
  vertical-align: middle;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation-delay: ${({ index }) => `${index * DELAY}`}s;
`;
