import { useEffect, useMemo, useRef, useCallback } from 'react';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import {
  PortfolioChartProps,
  TooltipXFormatter,
  TooltipYFormatter,
  YaxisLabelsFormatter,
} from './type';
import { defaultOptions, xAxisOptionsIsDasboard } from './options';
import {
  renderCustomMarker,
  calibrationMarker,
  minimizeCustomMarker,
  moveCustomMarker,
  formatValue,
} from './utils';
import useSeries from './useSeries';

const PortfolioChart = ({
  isDashboard,
  isFuture,
  originalBalance,
  chartDataYears,
}: PortfolioChartProps) => {
  const series = useSeries(chartDataYears);

  const windowHeight = window.innerHeight;

  const height =
    isDashboard || (windowHeight <= 900 && isFuture) ? '220px' : '450px';

  const width = isDashboard ? '106%' : '100%';

  const className = 'portfolio-chart';

  const elRef = useRef<HTMLDivElement>(null);

  const chartRef = useRef<ApexCharts>();

  const { t } = useTranslation();

  const tooltipYFormatter: TooltipYFormatter = useCallback(
    (value) => formatValue(value),
    [],
  );

  const tooltipXFormatter: TooltipXFormatter = useCallback(
    (_value, { dataPointIndex }) => {
      const yearText = t('common.date.year');
      const [, , , mean] = series;
      const xLabel = `${mean?.data?.[dataPointIndex]?.x}`.trim();

      return xLabel.includes(yearText) ? xLabel : `${xLabel} ${yearText}`;
    },
    [series, t],
  );

  const yaxisLabelsFormatter: YaxisLabelsFormatter = useCallback((value) => {
    if (Math.sign(value) !== 1 && Math.sign(value) !== 0) return '';

    return formatValue(value);
  }, []);

  const events = useMemo<ApexChart['events']>(
    () => ({
      mouseMove: (_e, chartContext) => {
        calibrationMarker();

        moveCustomMarker(chartContext);
      },
      updated: (chartContext, _options) => {
        calibrationMarker();

        renderCustomMarker(chartContext);
      },
      mouseLeave: (_e, chartContext, _options) => {
        minimizeCustomMarker(chartContext);

        calibrationMarker();
      },
      mounted: (chartContext, _options) => {
        renderCustomMarker(chartContext);
      },
    }),
    [],
  );

  useEffect(() => {
    if (elRef.current) {
      const options: ApexCharts.ApexOptions = {
        ...defaultOptions,
        chart: {
          ...defaultOptions.chart,
          events,
          type: 'area',
          width,
          height,
        },
        tooltip: {
          ...defaultOptions.tooltip,
          y: {
            ...defaultOptions.tooltip?.y,
            formatter: originalBalance ? tooltipYFormatter : undefined,
          },
          x: {
            ...defaultOptions.tooltip?.x,
            formatter: tooltipXFormatter,
          },
        },
        yaxis: {
          ...defaultOptions.yaxis,
          labels: {
            formatter: yaxisLabelsFormatter,
          },
        },
        xaxis: isDashboard ? xAxisOptionsIsDasboard : defaultOptions.xaxis,
        series,
      };

      chartRef.current = new ApexCharts(elRef.current, options);

      chartRef.current.render();
    }

    return () => {
      chartRef.current?.destroy?.();
    };
  }, [
    events,
    height,
    isDashboard,
    originalBalance,
    series,
    width,
    tooltipXFormatter,
    tooltipYFormatter,
    yaxisLabelsFormatter,
  ]);

  return <div ref={elRef} className={className} />;
};

export { PortfolioChart };
