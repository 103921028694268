import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFundsParams } from '../../hooks/useFundsParams';

import { FUNDS_TAGS, PORTFOLIO_CUSTOMIZATION_TABS } from './consts';
import { SEARCH_QUERY_PARAM } from './components/PortfolioCustomizationContent/FundsList/consts';
import { IOpenWeightingCannotBeIncreasedModal } from './types';

import { getSuperPortfolioHeaders } from 'store/portfolio/utils/getSuperPortfolioHeaders';
import { useFundsByTagsQuery } from 'store/portfolio/api';
import { PATHS } from 'constants/paths';
import { useActiveTab } from 'hooks/useActiveTab';
import { getFunds, getFundsTags } from 'store/portfolio/actions';
import {
  selectActiveFundTags,
  selectIsFundsLoading,
  selectIsFundsTagsLoading,
  selectIsProPortfolioLoading,
  selectPortfolioFunds,
  selectPortfolioId,
  selectUserFunds,
  selectFundsTags,
  selectFundSorting,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';

export const usePortfolioCustomizationRoute = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeTab, setActiveTab } = useActiveTab();
  const { fundTag = '', id } = useParams();

  const isBitcoinFundPage = fundTag === FUNDS_TAGS.btc;

  const isResidentialPropertyFundsListPage = [
    FUNDS_TAGS.rpf,
    FUNDS_TAGS.rrpf,
  ].includes(fundTag);

  const isResidentialPropertyFundsDetailsPage =
    isResidentialPropertyFundsListPage &&
    activeTab === PORTFOLIO_CUSTOMIZATION_TABS.explore;

  const isCustomizationTabsVisible = fundTag === FUNDS_TAGS.btc || !!id;
  const isChangeBasePortfolioPage = fundTag === FUNDS_TAGS.basePortfolio;

  useEffect(() => {
    if (
      !fundTag &&
      pathname !== PATHS.registration.portfolio.index &&
      pathname !== PATHS.kids.registration.index
    ) {
      navigate(generatePath(FUNDS_TAGS.etf));
    }
  }, [fundTag, navigate, pathname]);

  return {
    fundTag,
    activeTab,
    setActiveTab,
    isBitcoinFundPage,
    isResidentialPropertyFundsListPage,
    isResidentialPropertyFundsDetailsPage,
    isCustomizationTabsVisible,
    isChangeBasePortfolioPage,
  };
};

export const usePortfolioFundsTags = () => {
  const dispatch = useDispatch();
  const isFundsTagsLoading = useAppSelector(selectIsFundsTagsLoading);

  const { childId, isSuper, isParamsLoaded } = useAppSelector(
    selectPortfolioParams,
  );

  const fundsTags = useAppSelector(selectFundsTags);

  const activeFundTags = useAppSelector(
    selectActiveFundTags({ isRaizKids: !!childId, isSuper }),
  );

  useEffect(() => {
    if (isParamsLoaded) {
      dispatch(getFundsTags());
    }
  }, [dispatch, isSuper, isParamsLoaded]);

  return {
    fundsTags,
    isFundsTagsLoading,
    activeFundTags,
  };
};

export const usePortfolioFunds = () => {
  const dispatch = useDispatch();

  const {
    fundTag,
    activeTab,
    setActiveTab,
    isResidentialPropertyFundsListPage,
  } = usePortfolioCustomizationRoute();

  const isExploreTab = activeTab === PORTFOLIO_CUSTOMIZATION_TABS.explore;
  const { isSuper } = useAppSelector(selectPortfolioParams);

  const portfolioId = useAppSelector(selectPortfolioId);
  const funds = useAppSelector(selectPortfolioFunds({ isSuper }));
  const isFundsLoading = useAppSelector(selectIsFundsLoading);
  const isProPortfolioLoading = useAppSelector(selectIsProPortfolioLoading);
  const userFunds = useAppSelector(selectUserFunds);
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get(SEARCH_QUERY_PARAM) || '';

  const sorting = useAppSelector(selectFundSorting);

  const { isParamsLoaded } = useAppSelector(selectPortfolioParams);

  const params = useFundsParams();
  const headers = getSuperPortfolioHeaders({ isSuper });

  useFundsByTagsQuery({ params, headers });

  useEffect(() => {
    if (
      !activeTab &&
      fundTag &&
      fundTag !== FUNDS_TAGS.btc &&
      !isFundsLoading
    ) {
      setActiveTab(
        userFunds?.length === 0 && !isResidentialPropertyFundsListPage
          ? PORTFOLIO_CUSTOMIZATION_TABS.explore
          : PORTFOLIO_CUSTOMIZATION_TABS.preview,
      );
    }
  }, [
    activeTab,
    setActiveTab,
    fundTag,
    userFunds,
    isFundsLoading,
    isResidentialPropertyFundsListPage,
  ]);

  useEffect(() => {
    if (isParamsLoaded && fundTag && !isProPortfolioLoading) {
      dispatch(
        getFunds({
          activeFundTag: fundTag,
          portfolioId: portfolioId || '',
          searchParams,
          sorting,
          searchValue,
        }),
      );
    }
  }, [
    dispatch,
    fundTag,
    portfolioId,
    isProPortfolioLoading,
    searchValue,
    searchParams,
    sorting,
    isParamsLoaded,
  ]);

  const isLoading = isFundsLoading || isProPortfolioLoading || !isParamsLoaded;

  return {
    funds: isExploreTab ? funds : userFunds,
    isLoading,
  };
};

export const useWeightingCannotBeIncreasedModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const openWeightingCannotBeIncreasedModal = ({
    name,
    symbol,
  }: IOpenWeightingCannotBeIncreasedModal) => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('portfolioCustomization.weightingCannotBeIncreased.title'),
          description: t(
            'portfolioCustomization.weightingCannotBeIncreased.description',
            {
              name: name || '',
              symbol: symbol || '',
            },
          ),
        },
      }),
    );
  };

  return {
    openWeightingCannotBeIncreasedModal,
  };
};
