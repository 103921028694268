import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import QRCode from 'react-qr-code';
import { Trans, withTranslation } from 'react-i18next';
import Firebase from 'firebase/app';

import '../../../styles/layout/invite.scss';
import * as userActions from '../../../store/user/actions';
import {
  TWITTER,
  FB,
  EMAIL,
  WHATS_APP,
  LINK,
  PLATFORM_LINK_MY,
} from '../../../constants/comonConstants';
import {
  CURRENCY_LOCALE,
  CURRENCY_NAME,
  MY_APP,
  MARKETING_SITE,
} from '../../../constants/localeConfigs';
import InvitationLink from '../Links/InvitationLink/InvitationLink';

import { InviteIconButton } from './components/styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { IconButton } from 'components/elements/IconButton';
import { PATHS } from 'constants/paths';
import {
  selectIsInviteQREnabled,
  selectIsSignUpPromoEnabled,
} from 'store/firebaseConfig/selectors';

class InviteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showQr: false,
    };

    this.handleInvite = this.handleInvite.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getRewardsSummary();

    actions.getUserInvitation().then(() => {
      const { userInvitation } = this.props;
      const rewardAmountHTML = document.getElementById('menu-invite__amount');

      const rewardMenuHTML = document.querySelector(
        `[href="${PATHS.referrals.index}"]`,
      );

      const rewardAmount = userInvitation?.invitation?.reward_amount || 0;

      if (rewardAmount === 0 && rewardMenuHTML) {
        rewardMenuHTML.style.display = 'none';
      }

      if (rewardAmountHTML && userInvitation && userInvitation.invitation) {
        if (rewardAmount && rewardAmount > 0) {
          rewardAmountHTML.innerHTML = new Intl.NumberFormat(CURRENCY_LOCALE, {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: CURRENCY_NAME,
            minimumFractionDigits: 2,
          }).format(rewardAmount);
        }
      }
    });
  }

  handleInvite(shareService) {
    const {
      rewardsSummary,
      userInvitation,
      isRewardShareModalVisible,
      rewardName,
    } = this.props;

    const { t } = this.props;
    const rewardAmount = userInvitation.invitation.reward_amount;
    const rewardCode = userInvitation.invitation.code;
    const maximumRewardAmount = rewardsSummary.summary.maximum_reward_amount;
    const rewardUrl = userInvitation.invitation.url;
    const rewardMin = userInvitation.invitation.min_investment_amount;

    const rewardAmountGreaterThanZero =
      maximumRewardAmount && maximumRewardAmount > 0;

    const userEligible = rewardAmount > 0 && rewardUrl;

    const haveRewardAmount = rewardAmountGreaterThanZero && userEligible;

    const shareInviteText = haveRewardAmount
      ? t('inviteModal.investText', {
          rewardAmount: new Intl.NumberFormat(CURRENCY_LOCALE, {
            style: 'currency',
            currency: CURRENCY_NAME,
            minimumFractionDigits: 0,
          }).format(rewardAmount),
          rewardUrl,
          rewardCode,
          rewardMin: new Intl.NumberFormat(CURRENCY_LOCALE, {
            style: 'currency',
            currency: CURRENCY_NAME,
            minimumFractionDigits: 0,
          }).format(rewardMin),
        })
      : t('inviteModal.startInvesting', { rewardUrl });

    const inviteModalTitle = isRewardShareModalVisible
      ? ''
      : t('inviteModal.tipsOnGrowing');

    const redirectUrl = MARKETING_SITE;

    const shareRewardText = t('inviteModal.justInvested', {
      rewardName,
      siteUrl: redirectUrl,
    });

    const shareText = isRewardShareModalVisible
      ? shareRewardText
      : shareInviteText;

    if (shareService === TWITTER) {
      window.open(`https://twitter.com/intent/tweet?text=${shareText}`);

      Firebase.analytics().logEvent('Invite_Friends', {
        eventKey: 'SendTwitter',
      });
    } else if (shareService === FB) {
      const url =
        'https://www.facebook.com/dialog/send?app_id=1656278474604723&ref=site&display=page&' +
        `name=${inviteModalTitle}&caption=${rewardUrl}&` +
        `description=${shareText}&link=${rewardUrl}&redirect_uri=${redirectUrl}`;

      Firebase.analytics().logEvent('Invite_Friends', {
        eventKey: 'SendFacebook',
      });

      window.open(url);
    } else if (shareService === EMAIL) {
      const subject = isRewardShareModalVisible
        ? ''
        : t('inviteModal.tipsOnGrowing');

      Firebase.analytics().logEvent('Invite_Friends', {
        eventKey: 'SendSystemShareSheet',
      });

      window.open(`mailto:?subject=${subject}&body=${shareText}`);
    } else if (shareService === WHATS_APP) {
      const url = `https://api.whatsapp.com/send?text=${shareText}`;

      Firebase.analytics().logEvent('Invite_Friends', { eventKey: 'WhatsApp' });

      window.open(url);
    } else if (shareService === LINK) {
      const copyField = document.body.querySelector(
        '.invite__modal-code',
      ).classList;

      const copyText = document.getElementById('invite__share-link');

      copyField.add('-active');

      copyText.select();

      setTimeout(() => {
        copyField.remove('-active');
      }, 500);

      document.execCommand('copy');

      Firebase.analytics().logEvent('Invite_Friends');
    }
  }

  toggleQr(condition) {
    if (condition) {
      Firebase.analytics().logEvent('SendQRcode');
    }

    this.setState({
      showQr: condition,
    });
  }

  render() {
    const {
      showModal,
      handleCloseModal,
      userInvitation,
      isRewardShareModalVisible,
      showInviteQR,
      showSignUpPromo,
    } = this.props;

    const { showQr } = this.state;
    const { t } = this.props;

    const rewardAmount = userInvitation.invitation
      ? userInvitation.invitation.reward_amount
      : 0;

    const rewardMin = userInvitation.invitation
      ? userInvitation.invitation.min_investment_amount
      : 0;

    const code = userInvitation.invitation
      ? userInvitation.invitation.code
      : false;

    const invitationUrl = userInvitation.invitation
      ? userInvitation.invitation.url
      : '';

    const platformLink = MY_APP ? PLATFORM_LINK_MY : '';

    return (
      <div>
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          contentLabel="invite-modal"
          shouldCloseOnOverlayClick={false}
          className={`modal-basic invite__modal ${showQr ? '-only-qr' : ''}`}
          ariaHideApp={false}
        >
          <div className="modal-basic__content">
            <DeprecatedButton
              buttonClass="button__close"
              handleClick={() => {
                if (showQr) {
                  this.toggleQr(false);
                }

                handleCloseModal();
              }}
            />

            {showInviteQR && !isRewardShareModalVisible && (
              <DeprecatedButton
                buttonClass="invite__modal-qr"
                handleClick={() => this.toggleQr(true)}
              />
            )}

            <div
              className={`modal-basic__title invite__modal-title ${
                isRewardShareModalVisible ? 'invite__modal-share-title' : ''
              }`}
            >
              {isRewardShareModalVisible ? (
                t('inviteModal.letYourFriendsKnow')
              ) : (
                <Trans i18nKey="inviteModal.shareInviteGet" t={t} />
              )}
            </div>

            {!isRewardShareModalVisible && (
              <div
                className={`modal-basic__info ${
                  MY_APP ? '-not-wide-line' : ''
                }`}
              >
                <span className="-label">
                  <Trans
                    i18nKey="inviteModal.shareRaizStory"
                    t={t}
                    components={{
                      inviteAmount: new Intl.NumberFormat(CURRENCY_LOCALE, {
                        style: 'currency',
                        currency: CURRENCY_NAME,
                        minimumFractionDigits: 2,
                      }).format(rewardAmount),
                      rewardMin: new Intl.NumberFormat(CURRENCY_LOCALE, {
                        style: 'currency',
                        currency: CURRENCY_NAME,
                        minimumFractionDigits: 0,
                      }).format(rewardMin),
                      span: <span />,
                      link: <InvitationLink platformLink={platformLink} />,
                    }}
                  />
                </span>
              </div>
            )}
          </div>

          <div className="modal-basic__footer">
            {!MY_APP &&
              code &&
              showSignUpPromo &&
              !isRewardShareModalVisible && (
                <div
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={-1}
                  className="invite__modal-code"
                  onClick={() => {
                    this.handleInvite(LINK);
                  }}
                >
                  <Trans
                    i18nKey="inviteModal.yourInviteCode"
                    t={t}
                    components={{
                      code,
                      span: <span />,
                    }}
                  />

                  <input
                    className="invite__share-link"
                    value={invitationUrl}
                    id="invite__share-link"
                    readOnly
                  />
                </div>
              )}

            <div className="invite__modal-container">
              <div className="invite__modal-item">
                <DeprecatedButton
                  buttonClass="invite__modal-round -contacts"
                  handleClick={() => {
                    this.handleInvite(EMAIL);
                  }}
                />

                <div className="invite__modal-label">
                  {t('inviteModal.email')}
                </div>
              </div>

              <div className="invite__modal-item">
                <DeprecatedButton
                  buttonClass="invite__modal-round -whatsapp"
                  handleClick={() => {
                    this.handleInvite(WHATS_APP);
                  }}
                />

                <div className="invite__modal-label">
                  {t('inviteModal.whatsapp')}
                </div>
              </div>

              <div className="invite__modal-item">
                <IconButton
                  iconName="xLogo"
                  onClick={() => {
                    this.handleInvite(TWITTER);
                  }}
                  CustomButton={InviteIconButton}
                  size={36}
                />

                <div className="invite__modal-label">
                  {t('inviteModal.twitter')}
                </div>
              </div>

              <div className="invite__modal-item">
                <DeprecatedButton
                  buttonClass="invite__modal-round -facebook"
                  handleClick={() => {
                    this.handleInvite(FB);
                  }}
                />

                <div className="invite__modal-label">
                  {t('inviteModal.facebook')}
                </div>
              </div>
            </div>
          </div>

          {showQr &&
          userInvitation &&
          userInvitation.invitation &&
          userInvitation.invitation.url ? (
            <div className="modal-basic__container -qr-container">
              <div className="-qr-container__title">
                <Trans i18nKey="inviteModal.pointCamera" t={t} />

                <DeprecatedButton
                  buttonClass="button__close -white"
                  handleClick={() => {
                    if (showQr) {
                      this.toggleQr(false);
                    }

                    handleCloseModal();
                  }}
                />
              </div>

              <div className="-qr-content">
                <QRCode value={userInvitation.invitation.url} />
              </div>
            </div>
          ) : (
            ''
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  rewardsSummary: state.user.rewardsSummary,
  userInvitation: state.user.userInvitation,
  showInviteQR: selectIsInviteQREnabled(state),
  showSignUpPromo: selectIsSignUpPromoEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InviteModal));

InviteModal.defaultProps = {
  actions: {},
  rewardsSummary: { summary: {} },
  userInvitation: { invitation: {} },
  isRewardShareModalVisible: false,
  rewardName: '',
  showInviteQR: false,
  showSignUpPromo: false,
};

InviteModal.propTypes = {
  actions: PropTypes.shape({
    getRewardsSummary: PropTypes.func,
    getUserInvitation: PropTypes.func,
  }),
  rewardsSummary: PropTypes.shape({
    summary: PropTypes.shape({
      maximum_reward_amount: PropTypes.number,
    }),
  }),
  userInvitation: PropTypes.shape({
    invitation: PropTypes.shape({
      code: PropTypes.string,
      url: PropTypes.string,
      reward_amount: PropTypes.number,
      min_investment_amount: PropTypes.number,
      maximum_reward_amount: PropTypes.number,
    }),
  }),
  handleCloseModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  isRewardShareModalVisible: PropTypes.bool,
  rewardName: PropTypes.string,
  t: PropTypes.func.isRequired,
  showInviteQR: PropTypes.bool,
  showSignUpPromo: PropTypes.bool,
};
