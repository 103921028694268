import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

function ProgressInfo({ children, value, maxValue }) {
  return (
    <CircularProgressbarWithChildren
      value={value}
      maxValue={maxValue || 5}
      strokeWidth={5}
      styles={buildStyles({
        trailColor: 'rgba(255, 255, 255, 0.4)',
        pathColor: '#fff',
      })}
    >
      {children}
    </CircularProgressbarWithChildren>
  );
}

export default ProgressInfo;

ProgressInfo.defaultProps = {
  children: null,
  value: null,
};

ProgressInfo.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
};
