import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

import { MAX_INVEST, MIN_INVEST } from '../../../../constants/comonConstants';
import {
  CURRENCY_LOCALE,
  CURRENCY_NAME,
} from '../../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

function RoundUpsInvestModal({
  isConfirmModalOpen,
  setIsConfirmModalOpen,
  handleInvest,
  waitingRoundUps,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isConfirmModalOpen}
      onRequestClose={() => setIsConfirmModalOpen(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-title">
        {t('roundUps.investNow')}
      </div>

      <div className="time-investment__modal-content">
        <Trans
          t={t}
          i18nKey="roundUps.wantToInvestThreshold"
          components={{
            invest: new Intl.NumberFormat(CURRENCY_LOCALE, {
              style: 'currency',
              currency: CURRENCY_NAME,
            }).format(MIN_INVEST),
            waiting: new Intl.NumberFormat(CURRENCY_LOCALE, {
              style: 'currency',
              currency: CURRENCY_NAME,
            }).format(MAX_INVEST - waitingRoundUps),
          }}
        />
      </div>

      <div className="time-investment__modal-footer">
        <DeprecatedButton
          title={t('roundUps.cancel')}
          buttonClass="button__basic-transparent -black"
          handleClick={() => setIsConfirmModalOpen(false)}
        />

        <Button
          label={t('roundUps.invest')}
          onClick={() => handleInvest(true)}
        />
      </div>
    </Modal>
  );
}

export default RoundUpsInvestModal;

RoundUpsInvestModal.defaultProps = {
  setIsConfirmModalOpen: () => {},
  isConfirmModalOpen: true,
  handleInvest: () => {},
  waitingRoundUps: null,
};

RoundUpsInvestModal.propTypes = {
  setIsConfirmModalOpen: PropTypes.func,
  isConfirmModalOpen: PropTypes.bool,
  handleInvest: PropTypes.func,
  waitingRoundUps: PropTypes.number,
};
