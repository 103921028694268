import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  SwitchButton,
  Switcher,
  SwitchWrapper,
  Title,
  Wrapper,
} from './styles';

import { OFFER_TYPES, REWARDS_SEARCH_PARAMS } from 'pages/NewRewards/consts';
import { useRewardsCurrentTab } from 'pages/NewRewards/hooks/useRewardsCurrentTab';

interface IRewardsListHeaderProps {
  categoryName: string;
  setPageNumber: (pageNumber: number) => void;
  isFetching: boolean;
}

export const RewardsListHeader = ({
  categoryName,
  setPageNumber,
  isFetching,
}: IRewardsListHeaderProps) => {
  const { t } = useTranslation();

  const [_, setSearchParams] = useSearchParams();

  const { currentOfferType } = useRewardsCurrentTab();

  const handleSwitcherClick = () => {
    setSearchParams({
      [REWARDS_SEARCH_PARAMS.offerType]:
        currentOfferType === OFFER_TYPES.auto
          ? OFFER_TYPES.online
          : OFFER_TYPES.auto,
    });

    setPageNumber(1);
  };

  return (
    <Wrapper>
      <Title>
        {categoryName || t('newRewardsPage.rewardsListHeader.title')}
      </Title>

      {/* TODO: https://acornsau.atlassian.net/browse/RAIZ-7765 */}
      <SwitchWrapper>
        <Switcher onClick={handleSwitcherClick} disabled={isFetching}>
          <SwitchButton isActive={currentOfferType === OFFER_TYPES.online}>
            {t('newRewardsPage.rewardsListHeader.switch.online')}
          </SwitchButton>

          <SwitchButton isActive={currentOfferType === OFFER_TYPES.auto}>
            {t('newRewardsPage.rewardsListHeader.switch.auto')}
          </SwitchButton>
        </Switcher>
      </SwitchWrapper>
    </Wrapper>
  );
};
