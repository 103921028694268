import { useTranslation } from 'react-i18next';

import { SUPER_REGISTRATION_STEPS } from '../consts';

import {
  BannerContent,
  BannerSubTitle,
  BannerTitle,
  BannerWrapper,
} from './styles';

interface ISuperRegistrationBannerProps {
  step?: number;
}

export const SuperRegistrationBanner = ({
  step,
}: ISuperRegistrationBannerProps) => {
  const { t } = useTranslation();

  const isSubTitleVisible = step !== SUPER_REGISTRATION_STEPS.terms;

  return (
    <BannerWrapper>
      <BannerContent>
        <BannerTitle>
          {t('superPage.superRegistration.investSuper')}
        </BannerTitle>

        {isSubTitleVisible && (
          <BannerSubTitle>
            {t('superPage.superRegistration.pleaseComplete')}
          </BannerSubTitle>
        )}
      </BannerContent>
    </BannerWrapper>
  );
};
