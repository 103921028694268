import { ApexOptions } from 'apexcharts';

export const defaultOptions: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    stacked: false,
  },
  grid: {
    position: 'front',
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 0,
      left: 10,
      right: 0,
      bottom: 0,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#fff'],
  fill: {
    colors: ['#6241d2'],
  },
  markers: {
    colors: ['#6241d2'],
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    axisBorder: {
      show: true,
      color: '#ffcc00',
      offsetX: 0,
      offsetY: 10,
    },
    labels: {
      show: true,
      hideOverlappingLabels: false,
      showDuplicates: true,
      trim: false,
      rotate: 0,
      offsetY: -10,
    },
    crosshairs: {
      show: true,
      position: 'front',
    },
    tooltip: {
      enabled: true,
      offsetY: -200,
    },
  },
  tooltip: {
    enabled: true,
    followCursor: true,
  },
  yaxis: {
    show: true,
  },
  legend: {
    show: false,
  },
};

export const xAxisOptionsIsDasboard = {
  labels: {
    show: false,
  },
};
