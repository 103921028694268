import { IFunds } from '../../../types';

import FundItem from './FundItem';
import EmptyFundList from './EmptyFundList/EmptyFundList';
import { useFundsFilter } from './Filters/hooks';
import { FundsWrapper } from './styles';

interface IFundsListContentProps {
  funds: IFunds;
}

export default function FundsListContent({ funds }: IFundsListContentProps) {
  const { selectedFilterCount } = useFundsFilter();

  if (funds?.length === 0)
    return <EmptyFundList isListFiltered={selectedFilterCount !== 0} />;

  return (
    <FundsWrapper>
      {funds?.map((fund) => (
        <FundItem key={fund.id} fund={fund} />
      ))}
    </FundsWrapper>
  );
}
