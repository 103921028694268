import styled from '@emotion/styled';

export const AccountMigrationContentWrapper = styled.div`
  padding: 0 32px;
  display: flex;
  flex: 5;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  flex-direction: column;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 0 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  font-weight: ${({ theme }) => theme.fontWeights.ultraBold};
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  color: ${({ theme }) => theme.colors.topaz};
  margin-top: 16px;
`;

export const Body = styled.div`
  padding: 20px 0 80px 0;
`;

export const TermsAndConditionsIFrame = styled.iframe`
  width: 100%;
  margin-top: 55px;
  flex: 1;
  padding-bottom: 80px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    margin-top: 20px;
  }
`;

export const HelpText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: 16px;
`;

export const PDSTitle = styled(Title)`
  display: none;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    display: block;
  }
`;
