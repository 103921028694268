import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFundDetails } from '../FundDetails/hooks';

import { getResidentialPropertyFunds } from 'store/portfolio/actions';

export const useResidentialPropertyFunds = () => {
  const dispatch = useDispatch();
  const { fundId } = useFundDetails();

  const { residentialPropertyFunds, isLoading } = useSelector((state) => ({
    residentialPropertyFunds:
      state.portfolio?.residentialPropertyFunds?.fund_properties || [],
    isLoading: state.portfolio.isResidentialPropertyFundsLoading,
  }));

  useEffect(() => {
    if (fundId) {
      dispatch(
        getResidentialPropertyFunds({
          fundIdByTag: fundId,
        }),
      );
    }
  }, [dispatch, fundId]);

  return { residentialPropertyFunds, isLoading };
};
