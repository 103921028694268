import { useTranslation } from 'react-i18next';

import { FormStyled, ButtonStyled } from './styles';

import { InputField } from 'components/form/input/InputField';

interface IEmailAddressFormProps {
  isLoading: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

export const Form = ({ isLoading, onSubmit }: IEmailAddressFormProps) => {
  const { t } = useTranslation();

  return (
    <FormStyled onSubmit={onSubmit}>
      <InputField name="email" label={t('recoverPasswordPage.email')} />

      <ButtonStyled
        isLoading={isLoading}
        type="submit"
        label={t('recoverPasswordPage.sendEmail')}
      />
    </FormStyled>
  );
};
