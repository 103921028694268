import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useDeleteInvestmentGoal, useStopRecurringInvestment } from '../hooks';

import { GOAL_MODAL_SIZE } from './consts';
import {
  DeleteRecurringInvestmentsWrapper,
  InvestmentDeleteGoalAcceptButton,
} from './styles';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import {
  GlobalInfoModalButtonClose,
  GlobalInfoModalTitle,
  GlobalInfoModalWrapper,
  GlobalInfoRejectButton,
} from 'components/modals/InfoModal/styles';

interface IInvestmentGoalModal {
  isDeleteInvestmentGoalModalVisible: boolean;
  setIsDeleteInvestmentGoalModalVisible: (isVisible: boolean) => void;
  investmentGoalKey: string;
}

const DeleteInvestmentGoalModal = ({
  isDeleteInvestmentGoalModalVisible,
  setIsDeleteInvestmentGoalModalVisible,
  investmentGoalKey,
}: IInvestmentGoalModal) => {
  const { t } = useTranslation();

  const { handleDeleteInvestmentGoal, isDeleteGoalLoading } =
    useDeleteInvestmentGoal({
      investmentGoalKey,
    });

  const { watch } = useFormContext();

  const isDeleteRecurringInvestmentsSelected = watch(
    'isDeleteRecurringInvestments',
  );

  const { handleStopRecurringInvestment, isDependencyUsersLoading } =
    useStopRecurringInvestment();

  const closeInvestmentGoalModal = () => {
    setIsDeleteInvestmentGoalModalVisible(!isDeleteInvestmentGoalModalVisible);
  };

  const deleteInvestmentGoal = () => {
    if (isDeleteRecurringInvestmentsSelected) {
      handleStopRecurringInvestment();
    } else {
      handleDeleteInvestmentGoal();
    }
  };

  return (
    <GlobalInfoModalWrapper
      isOpen={isDeleteInvestmentGoalModalVisible}
      onRequestClose={closeInvestmentGoalModal}
      size={GOAL_MODAL_SIZE}
    >
      <GlobalInfoModalButtonClose onClick={closeInvestmentGoalModal} />

      <GlobalInfoModalTitle>
        {t('raizKidsEditPage.investmentGoal.deleteInvestmentGoalModal.title')}
      </GlobalInfoModalTitle>

      {t(
        'raizKidsEditPage.investmentGoal.deleteInvestmentGoalModal.description',
      )}

      <DeleteRecurringInvestmentsWrapper>
        <CheckBoxField
          name="isDeleteRecurringInvestments"
          label={t(
            'raizKidsEditPage.investmentGoal.deleteInvestmentGoalModal.deleteRecurringInvestmentsCheckbox',
          )}
        />
      </DeleteRecurringInvestmentsWrapper>

      <InvestmentDeleteGoalAcceptButton onClick={deleteInvestmentGoal}>
        {isDeleteGoalLoading || isDependencyUsersLoading ? (
          <DotsLoader />
        ) : (
          t('common.ok')
        )}
      </InvestmentDeleteGoalAcceptButton>

      <GlobalInfoRejectButton onClick={closeInvestmentGoalModal}>
        {t('common.cancel')}
      </GlobalInfoRejectButton>
    </GlobalInfoModalWrapper>
  );
};

export default DeleteInvestmentGoalModal;
