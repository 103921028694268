import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StatisticsItemsWrapper } from './styles';
import StatisticItem from './StatisticItem';

import { selectFundStatistics } from 'store/portfolio/selectors';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

export default function BitcoinStatisticItems() {
  const { t } = useTranslation();
  const statistics = useSelector(selectFundStatistics);

  const bitcoinFundStatistics = useMemo(
    () => [
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.tradingVolume',
        value: statistics.volume,
        fractionDigits: 0,
        type: NUMBER_FORMATS.decimal,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.marketCapitalization',
        value: statistics.market_capital,
        isVisible: statistics?.market_capital,
        isWithoutFormatting: true,
      },
      {
        label: 'portfolioCustomization.fundDetails.statistic.labels.weekHigh',
        value: statistics.annual_high,
      },
      {
        label: 'portfolioCustomization.fundDetails.statistic.labels.weekLow',
        value: statistics.annual_low,
      },
    ],
    [statistics],
  );

  return (
    <StatisticsItemsWrapper>
      {bitcoinFundStatistics?.map(
        ({ value, label, isVisible = true, fractionDigits, type }) =>
          isVisible && (
            <StatisticItem
              label={t(label)}
              key={label}
              value={value}
              fractionDigits={fractionDigits}
              type={type}
            />
          ),
      )}
    </StatisticsItemsWrapper>
  );
}
