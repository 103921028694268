import styled from '@emotion/styled';

export const KidPortfolioHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 20px 0 0;
`;

export const KidPortfolioHeaderImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.gradients.neonLemonade};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IKidPortfolioNameWrapperProps {
  isRegistration?: boolean;
}

export const KidPortfolioHeaderNameWrapper = styled.div<IKidPortfolioNameWrapperProps>`
  font-size: ${({ theme }) => theme.fontSizes['3lg']};
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  margin-left: 20px;
`;
