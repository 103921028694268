import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

import * as userActions from '../../../store/user/actions';
import Loader from '../../../components/elements/Loader/Loader';

import SmsfForm from './Forms/SmsfForm';

class SmsfAccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showInformation: true,
      showMembers: false,
      showMemberEdit: false,
      showMemberAdd: false,
      currentMember: {},
    };

    this.showLoader = this.showLoader.bind(this);

    this.toggleAddEditForm = this.toggleAddEditForm.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getSMSFDetails();

    actions.getSMSFMembers();
  }

  toggleAddEditForm = (condition, member) => {
    this.setState({
      showMemberEdit: member ? condition : !condition,
      showMemberAdd: member ? !condition : condition,
      currentMember: member || {},
    });
  };

  handleSubmit = (e, values, form) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();
    }
  };

  showLoader = (condition) => {
    this.setState({ showLoader: condition });
  };

  toggleShow = () => {
    const { showMembers, showInformation } = this.state;

    this.setState({
      showInformation: !showInformation,
      showMembers: !showMembers,
    });
  };

  render() {
    const { user, smsfDetails, smsfMembers } = this.props;

    const {
      showInformation,
      showMembers,
      showLoader,
      showMemberEdit,
      showMemberAdd,
      currentMember,
    } = this.state;

    return (
      <div>
        {smsfDetails.details &&
        smsfDetails.details.legal_fund_name &&
        user.email ? (
          <SmsfForm
            smsfDetails={smsfDetails.details}
            smsfMembers={smsfMembers.members}
            currentMember={currentMember}
            showMemberEdit={showMemberEdit}
            toggleAddEditForm={this.toggleAddEditForm}
            showMemberAdd={showMemberAdd}
            user={user}
            handleSubmit={this.handleSubmit}
            showLoader={showLoader}
            showMembers={showMembers}
            showInformation={showInformation}
            toggleShow={this.toggleShow}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  smsfDetails: state.user.smsfDetails,
  smsfMembers: state.user.smsfMembers,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmsfAccountSettings);

SmsfAccountSettings.defaultProps = {
  user: {},
  smsfDetails: {},
  smsfMembers: { members: [] },
};

SmsfAccountSettings.propTypes = {
  actions: PropTypes.shape({
    getSMSFDetails: PropTypes.func,
    getSMSFMembers: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  smsfDetails: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
    details: PropTypes.shape({
      legal_fund_name: PropTypes.string,
    }),
  }),
  smsfMembers: PropTypes.shape({
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
