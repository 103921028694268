import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

import { FundsTagItemWrapper, PercentageValue } from './styles';

import { usePortfolioNavigationParams } from 'pages/Portfolio/hooks/usePortfolioNavigationParams';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioParams } from 'store/portfolio/selectors';

export default function FundsTagItem({ tag }) {
  const navigate = useNavigate();

  const { name, key, percentage } = tag;
  const { fundTag } = useParams();
  const { search } = useAppSelector(selectPortfolioParams);

  const { customizationTagsPath, pathParams } = usePortfolioNavigationParams();

  const isActive = key === fundTag;

  const currentFundTagPath = generatePath(customizationTagsPath, {
    fundTag: key,
    ...pathParams,
  });

  const handleFundTagItemClick = () => {
    navigate({
      pathname: currentFundTagPath,
      search,
    });
  };

  return (
    <FundsTagItemWrapper onClick={handleFundTagItemClick} isActive={isActive}>
      {name}

      <PercentageValue>{percentage && ` ${percentage}%`}</PercentageValue>
    </FundsTagItemWrapper>
  );
}

FundsTagItem.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    percentage: PropTypes.number,
  }).isRequired,
};
