import { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';

import {
  REWARDS_FILTER_VALUE,
  REWARDS_FILTER_VALUE_UNFULFILLED,
} from '../consts';
import Loader from '../../../components/elements/Loader/Loader';
import hasTransactionsToDisplay from '../utils/hasTransactionsToDisplay';

import HistoryTransactions from './HistoryTransactions';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useGetRewardsQuery } from 'store/rewards/api';
import { AU_APP, MY_APP } from 'constants/localeConfigs';

export default function RewardsHistory() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(REWARDS_FILTER_VALUE.all);

  const { isLoading, data: rewards } = useGetRewardsQuery({
    fulfilled: AU_APP,
  });

  const tabs = MY_APP ? REWARDS_FILTER_VALUE_UNFULFILLED : REWARDS_FILTER_VALUE;

  return (
    <div className="new-rewards">
      <div className="rewards__history">
        <div className="profile-content settings__content-default-container">
          <div className="profile-content__header -with-navigation ">
            <div className="profile-content__title">
              {t('rewardsPage.history.title')}
            </div>

            <div className="profile-content__navigation">
              {Object.values(tabs).map((tab) => (
                <DeprecatedButton
                  key={`historyTab${tab}`}
                  title={t(`rewardsPage.history.status.${tab}`)}
                  buttonClass={activeTab === tab ? 'active' : ''}
                  handleClick={() => setActiveTab(tab)}
                />
              ))}
            </div>
          </div>

          {isLoading && <Loader additionalClass="-absolute -top" />}

          {!isLoading && hasTransactionsToDisplay({ rewards, activeTab }) ? (
            <div className="profile-content__container">
              {rewards.map((transaction, key) => (
                <HistoryTransactions
                  key={`transation-${transaction.id}`}
                  transaction={transaction}
                  activeTab={activeTab}
                  showMainLoader={isLoading}
                  previousDate={rewards[key - 1]?.origination_date}
                />
              ))}
            </div>
          ) : (
            !isLoading &&
            (!MY_APP ||
              activeTab !== REWARDS_FILTER_VALUE_UNFULFILLED.failed) && (
              <div className="found-block">
                <div className="icon-not-found" />

                <div className="not-found">
                  <div>{t('rewardsPage.history.notFound')}</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

RewardsHistory.defaultProps = {
  rewardsHistory: {},
};

RewardsHistory.propTypes = {
  rewardsHistory: PropTypes.shape({
    rewards: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string,
      }),
    ),
  }),
};
