import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorText, Item, ItemText, UnlinkButton, FixButton } from './styles';
import { IOpenFixAccountModal } from './types';
import { FIXABLE_ERROR_CODES } from './const';
import { useUnlinkConfirmationModal } from './useUnlinkConfirmationModal';

import { useUpdateDebitCardMutation } from 'store/cards';
import { ROUNDUP_SOURCES, IRoundupSources } from 'store/banks';
import { Icon, IIconSet } from 'components/elements/Icon';
import { IAccount, ISubaccount } from 'store/spendingAccounts';
import { useDeleteInstitutionsMutation } from 'store/institutions';
import { getCardNumberPlaceholder } from 'utils/getCardNumberPlaceholder';

interface INonVisaAccountItemProps {
  account: Omit<IAccount, 'subaccounts'>;
  bankName: string | null;
  openFixAccountModal: IOpenFixAccountModal;
  isError?: boolean;
  subAccount?: ISubaccount;
  source: IRoundupSources;
}

export const NonVisaAccountItem = ({
  account,
  isError,
  subAccount,
  openFixAccountModal,
  bankName,
  source,
}: INonVisaAccountItemProps) => {
  const { t } = useTranslation();

  const [deleteInstitution, deleteInstitutionResult] =
    useDeleteInstitutionsMutation();

  const [updateDebitCard] = useUpdateDebitCardMutation();

  const isFixable = FIXABLE_ERROR_CODES.includes(
    account?.error_info?.error_code || '',
  );

  const onAccept = useCallback(() => {
    if (source === ROUNDUP_SOURCES.debitCards) {
      updateDebitCard({ cardId: account.id });
    } else {
      deleteInstitution({ institutionId: account.id });
    }
  }, [account.id, deleteInstitution, updateDebitCard, source]);

  const openUnlinkConfirmationModal = useUnlinkConfirmationModal({
    bankName,
    onAccept,
  });

  const isButtonDisabled = deleteInstitutionResult.isLoading;

  const icon: IIconSet =
    source === ROUNDUP_SOURCES.debitCards ? 'masterCard' : 'bankAccount';

  return (
    <Item>
      <Icon size={32} iconName={icon} />

      {isError ? (
        <>
          <ErrorText>{account?.error_info?.resolve}</ErrorText>

          {isFixable && (
            <FixButton
              label={t('settingsPage.fix')}
              onClick={() => openFixAccountModal(account)}
              isDisabled={isButtonDisabled}
            />
          )}
        </>
      ) : (
        <ItemText>
          {source === ROUNDUP_SOURCES.debitCards && account.last_4
            ? getCardNumberPlaceholder(account.last_4)
            : subAccount?.account_nickname || subAccount?.account_name}
        </ItemText>
      )}

      <UnlinkButton
        isDisabled={isButtonDisabled}
        label={t('common.unlink')}
        onClick={openUnlinkConfirmationModal}
        variant="text"
      />
    </Item>
  );
};
