import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FinalFormInput from '../../../../components/elements/FinalFormInput/FinalFormInput';
import { AU_APP } from '../../../../constants/localeConfigs';
import ChangePasswordSuccessModal from '../../../../components/layout/Modals/ChangePasswordSuccessModal';

import { ConfirmButton } from './styles';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    const patternCapital = /[A-Z]/;
    const patternLower = /[a-z]/;
    const patternNumber = /[0-9]/;

    if (!values.old_password) {
      errors.old_password = t('fields.validation.required');
    } else if (values.old_password.length < 8) {
      errors.old_password = t('fields.validation.minimum8Characters');
    } else if (!patternCapital.test(values.old_password)) {
      errors.old_password = t('fields.validation.shouldContain');
    } else if (!patternLower.test(values.old_password)) {
      errors.old_password = t('fields.validation.shouldContainLetter');
    } else if (!patternNumber.test(values.old_password)) {
      errors.old_password = t('fields.validation.shouldContainOne');
    }

    if (!values.new_password) {
      errors.new_password = t('fields.validation.required');
    } else if (values.new_password.length < 8) {
      errors.new_password = t('fields.validation.minimum8Characters');
    } else if (!patternCapital.test(values.new_password)) {
      errors.new_password = t('fields.validation.shouldContain');
    } else if (!patternLower.test(values.new_password)) {
      errors.new_password = t('fields.validation.shouldContainLetter');
    } else if (!patternNumber.test(values.new_password)) {
      errors.new_password = t('fields.validation.shouldContainOne');
    }

    if (!values.confirm_password) {
      errors.confirm_password = t('fields.validation.required');
    } else if (values.confirm_password.length < 8) {
      errors.confirm_password = t('fields.validation.minimum8Characters');
    } else if (!patternCapital.test(values.confirm_password)) {
      errors.confirm_password = t('fields.validation.shouldContain');
    } else if (!patternLower.test(values.confirm_password)) {
      errors.confirm_password = t('fields.validation.shouldContainLetter');
    } else if (!patternNumber.test(values.confirm_password)) {
      errors.confirm_password = t('fields.validation.shouldContainOne');
    }

    if (
      (AU_APP && !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser)) ||
      !AU_APP
    ) {
      if (!values.answer) {
        errors.answer = t('fields.validation.required');
      }
    }

    if (
      values.new_password &&
      values.confirm_password &&
      values.new_password !== values.confirm_password
    ) {
      errors.confirm_password = t('fields.validation.passwordMatch');

      errors.new_password = t('fields.validation.passwordMatch');
    }

    return errors;
  };

  return { validate };
};

export default function ChangePasswordForm({
  handleSubmit,
  errorMessage,
  user,
  changePasswordSuccess,
  toggleShowPasswordOld,
  toggleShowPasswordNew,
  toggleShowPasswordConfirm,
  isShowOldPassword,
  isShowNewPassword,
  isShowConfirmPassword,
  showLoader,
  isShowSuccessModal,
  redirectToLogin,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          autoComplete="off"
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <ChangePasswordSuccessModal
            isShowSuccessModal={isShowSuccessModal}
            redirectToLogin={redirectToLogin}
          />

          <div className="settings__change-pass">
            {user && (
              <div className="profile-content settings__content-default-container">
                <div className="profile-content__header">
                  <div className="profile-content__title">
                    {t('settingsPage.changePasswordForm.title')}
                  </div>
                </div>

                <div className="profile-content__container">
                  <div className="settings__change-pass-item">
                    <FinalFormInput
                      className="without-underline"
                      name="old_password"
                      type={!isShowOldPassword ? 'password' : 'text'}
                      showPassword={toggleShowPasswordOld}
                      label={t(
                        'settingsPage.changePasswordForm.fields.current.label',
                      )}
                      isShowPasswordToggle
                    />
                  </div>

                  <div className="settings__change-pass-item">
                    <FinalFormInput
                      className="without-underline"
                      name="new_password"
                      type={!isShowNewPassword ? 'password' : 'text'}
                      showPassword={toggleShowPasswordNew}
                      label={t(
                        'settingsPage.changePasswordForm.fields.new.label',
                      )}
                      isShowPasswordToggle
                    />
                  </div>

                  <div className="settings__change-pass-item">
                    <FinalFormInput
                      className="without-underline"
                      name="confirm_password"
                      type={!isShowConfirmPassword ? 'password' : 'text'}
                      showPassword={toggleShowPasswordConfirm}
                      label={t(
                        'settingsPage.changePasswordForm.fields.confirm.label',
                      )}
                      isShowPasswordToggle
                    />
                  </div>

                  {errorMessage && (
                    <div className="settings__change-pass-item">
                      <div className="settings__change-pass-info">
                        {errorMessage}
                      </div>
                    </div>
                  )}

                  {changePasswordSuccess && (
                    <div className="settings__change-pass-item">
                      <div className="settings__change-pass-info">
                        {t(
                          'settingsPage.changePasswordForm.fields.successMessage',
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {user.question1_value && (
              <div className="settings__change-pass-label">
                {t('settingsPage.changePasswordForm.fields.security.label')}
              </div>
            )}

            {user.question1_value && (
              <div className="profile-content settings__content-default-container -without-title">
                <div className="profile-content__container">
                  <div className="settings__change-pass-item">
                    <FinalFormInput
                      className="without-underline"
                      name="answer"
                      autocomplete="off"
                      label={user.question1_value}
                    />
                  </div>
                </div>
              </div>
            )}

            <ConfirmButton
              isLoading={showLoader}
              label={t('common.save')}
              type="submit"
            />
          </div>
        </form>
      )}
    />
  );
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  changePasswordSuccess: PropTypes.bool.isRequired,
  toggleShowPasswordOld: PropTypes.func.isRequired,
  toggleShowPasswordNew: PropTypes.func.isRequired,
  toggleShowPasswordConfirm: PropTypes.func.isRequired,
  isShowOldPassword: PropTypes.bool.isRequired,
  isShowNewPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isShowConfirmPassword: PropTypes.bool.isRequired,
  isShowSuccessModal: PropTypes.bool.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
  errorMessage: '',
  handleSubmit() {},
};
