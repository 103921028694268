import styled from '@emotion/styled';

interface IpercentageBarProps {
  percent: number;
}

export const InfoPanelWrapper = styled.div`
  ${({ theme }) => `
    animation-name: fadein;
    animation-duration: 925ms;
    border-radius: 6px;
    text-align: left;
    overflow: hidden;
    position: relative;
    box-shadow: ${theme.shadows.primary};
    background-color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    width: calc(50% - 10px);
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.length.lg};
    color: ${theme.colors.midnightBlue};

    ${theme.mediaQuery('tablet')} {
      width: 100%;
      margin-bottom: 20px;
    }
  `}
`;

export const InfoPanelHeader = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    margin-bottom: 15px;
    padding: 15px 20px 0;
  `}
`;

export const Spacer = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.mercury};
  margin-bottom: 19px;
  margin-left: -20px;
  width: calc(100% + 40px);
`;

export const PercentageWrapper = styled.div`
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.mercury};
  position: relative;
  margin: 55px 10px 42px 15px;
`;

export const PercentageBar = styled.div<IpercentageBarProps>`
  height: 40px;
  border-radius: 8px 0 0 8px;
  background-color: ${({ theme }) => theme.colors.dodgerBlue};
  position: relative;
  width: ${({ percent }) => (percent > 99 ? '95' : percent)}%;
`;

export const PercentagePoint = styled.div<IpercentageBarProps>`
  ${({ theme, percent }) => `
    position: absolute;
    right: -33px;
    left: ${percent > 5 ? 'auto' : '0'};
    width: 66px;
    height: 24px;
    border-radius: 13px;
    background-color: ${theme.colors.dodgerBlue};
    text-align: center;
    color: ${theme.colors.white};
    top: -34px;
    font-size: ${theme.fontSizes['2md']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['3md']};

    &:after {
      content: '';
      position: absolute;
      left: ${percent > 5 ? '42%' : '15%'};
      top: 100%;
      width: 0;
      height: 0;
      border-left: 5px solid ${theme.colors.transparent};
      border-right: 5px solid ${theme.colors.transparent};
      border-top: 6px solid ${theme.colors.dodgerBlue};
      clear: both;
    }
  `}
`;

export const PercentageMore = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 56px;
    height: 40px;
    border-radius: 0 8px 8px 0;
    background-color: ${theme.colors.silver};
    color: ${theme.colors.bluishPurpleTransparent};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['2sm']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tightest};
    line-height: ${theme.lineHeights.length.xsm};
    padding-left: 5px;
  `}
`;

export const MonthlyTrackerWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    color: ${theme.colors.bluishPurpleTransparent};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tightest};
    line-height: ${theme.lineHeights.length['3sm']};
    margin-bottom: 20px;
  `}
`;

export const MonthlyTrackerCol = styled.div`
  ${({ theme }) => `
    width: 50%;
    padding: 0 5px;
    text-align: center;
    border-right: 1px solid ${theme.colors.mercury};

    ${theme.mediaQuery('tablet')} {
      padding: 0 35px;
    }
  `}
`;

export const MonthlyTrackerRow = styled.div`
  padding-top: 10px;
`;

export const MonthlyTrackerValue = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['5md']};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.wide};
    line-height: ${theme.lineHeights.length.lg};
  `}
`;

export const MonthlyTrackerFill = styled.span`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.dodgerBlue};
`;

export const MonthlyTrackerEmpty = styled(MonthlyTrackerFill)`
  background-color: ${({ theme }) => theme.colors.mercury};
`;
