import styled from '@emotion/styled';

export const ChartDetailsContent = styled.div`
  margin-top: 45px;
`;

export const ChartDetailsExpandedContent = styled.div`
  padding: 10px;
  margin-bottom: 14px;
`;

export const ChardDetailsExpandedLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartDetailsExpandedText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  color: ${({ theme }) => theme.colors.whiteTransparentLight};
`;
