export const INVEST_ACTIONS = {
  GET_STATEMENTS_SUCCESS: 'GET_STATEMENTS_SUCCESS',
  ONE_TIME_INVEST_SUCCESS: 'ONE_TIME_INVEST_SUCCESS',
  SHOW_ERROR_GET_STATEMENTS: 'SHOW_ERROR_GET_STATEMENTS',
  GET_STATEMENTS_URL_SUCCESS: 'GET_STATEMENTS_URL_SUCCESS',
  SHOW_ERROR_ONE_TIME_INVEST: 'SHOW_ERROR_ONE_TIME_INVEST',
  SHOW_ERROR_BILLING_ACCOUNTS: 'SHOW_ERROR_BILLING_ACCOUNTS',
  GET_BILLING_ACCOUNTS_SUCCESS: 'GET_BILLING_ACCOUNTS_SUCCESS',
  SHOW_ERROR_GET_STATEMENTS_URL: 'SHOW_ERROR_GET_STATEMENTS_URL',
  GET_STATEMENTS_URL_CONTENT_SUCCESS: 'GET_STATEMENTS_URL_CONTENT_SUCCESS',
  SHOW_ERROR_GET_STATEMENTS_URL_CONTENT:
    'SHOW_ERROR_GET_STATEMENTS_URL_CONTENT',
  GET_SUPER_FUNDS_LIST_SUCCESS: 'GET_SUPER_FUNDS_LIST_SUCCESS',
  SHOW_ERROR_GET_SUPER_FUNDS_LIST: 'SHOW_ERROR_GET_SUPER_FUNDS_LIST',
  DELETE_BILLING_ACCOUNT_SUCCESS: 'DELETE_BILLING_ACCOUNT_SUCCESS',
  SHOW_ERROR_SET_SUPER_FUND: 'SHOW_ERROR_SET_SUPER_FUND',
  SET_SUPER_FUND_SUCCESS: 'SET_SUPER_FUND_SUCCESS',
  SHOW_ERROR_DELETE_BILLING_ACCOUNT: 'SHOW_ERROR_DELETE_BILLING_ACCOUNT',
  GET_ALLOCATION_PROFILE_SUCCESS: 'GET_SIGNUP_PROGRESS_SUCCESS',
  WITHDRAW_ALL_MONEY_CLOSE: 'WITHDRAW_ALL_MONEY_CLOSE',
  WITHDRAW_ALL_MONEY: 'WITHDRAW_ALL_MONEY',
};
