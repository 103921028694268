import { createSelector } from '@reduxjs/toolkit';

export const selectRecurringDepositSettings = (state) =>
  state.spendingAnalytics.recurringDepositSettings;

export const selectCategoriesList = (state) =>
  state.spendingAnalytics.categoriesList;

export const selectCategoryName = (categoryId) =>
  createSelector(
    [selectCategoriesList],
    (categories) =>
      categories?.filter((category) => category.id === categoryId)?.[0]?.name ||
      categoryId,
  );

export const selectSubCategoryName = ({ categoryId, subCategoryId }) =>
  createSelector([selectCategoriesList], (categories) => {
    if (!subCategoryId) {
      return '';
    }

    return (
      categories
        ?.filter((category) => category.id === categoryId)?.[0]
        ?.subcategories.find((subCategory) => subCategory.id === subCategoryId)
        ?.name || categoryId
    );
  });
