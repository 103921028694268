import React from 'react';
import Skeleton from 'react-skeleton-loader';

export default function OfferSliderSkeleton() {
  return [...Array(3)].map((e, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="grid__carousel-fake" key={`carousel-fake${index}`}>
      <Skeleton width="90%" height="148px" color="rgba(255,255,255,0.8)" />
    </div>
  ));
}
