import styled from '@emotion/styled';

import arrowRight from 'assets/images/icons/arrow-right.svg';
import checkBoxUncheckedIcon from 'assets/images/check.png';
import checkBoxCheckedIcon from 'assets/images/ic_check_login.png';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';

export const FiltersWrapper = styled.div`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.mercury};
  left: 16px;
  top: 64px;
  z-index: 1;
  padding: 16px 0;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
    left: 0;
  }
  min-width: 300px;
`;

export const FiltersTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  padding: 0 16px;
`;

export const PromotedFilters = styled.div`
  padding: 14px 16px 18px 16px;
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-bottom: 10px;
`;

interface IPromotedFiltersItemProps {
  isSelected?: boolean;
}

export const PromotedFiltersItem = styled.div<IPromotedFiltersItemProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.niagara : theme.colors.bluishGreenTransparent}
    no-repeat 8px;
  border-radius: 40px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: right;
  margin-right: 8px;
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.colors.niagara};
`;

interface IFiltersCategoriesLabelWrapperProps {
  isExpanded?: boolean;
}

export const FiltersCategoriesLabelWrapper = styled.div<IFiltersCategoriesLabelWrapperProps>`
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  border-bottom: ${({ isExpanded }) => (isExpanded ? 0 : '1px')} solid
    ${({ theme }) => theme.colors.mercury};
  cursor: pointer;
`;

export const Label = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  text-align: left;
  float: left;
  flex: 1;
`;

interface IArrowProps {
  isExpanded?: boolean;
}

export const Arrow = styled.div<IArrowProps>`
  background: url(${`${arrowRight}`});
  padding: 12px;
  ${({ isExpanded }) => isExpanded && 'transform: rotate(90deg)'};
  cursor: pointer;
`;

export const FilterItemWrapper = styled.div`
  text-align: left;
  padding: 0 16px;
`;

export const CheckboxWrapper = styled(CheckboxFF)`
  clear: both;
  text-align: left;

  & .form-field__label {
    cursor: pointer;
    padding: 0 0 0 30px;
    position: relative;
    width: 100%;

    &:before {
      background-image: url(${checkBoxUncheckedIcon});
      border-radius: 13px;
      content: '';
      height: 22px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.2s;
      width: 22px;
    }

    &:after {
      background-image: url(${checkBoxCheckedIcon});
      background-repeat: no-repeat;
      background-size: 15px;
      border-radius: 10px;
      content: '';
      cursor: pointer;
      display: none;
      height: 20px;
      left: 3px;
      position: absolute;
      top: 6px;
      transition: 0.2s;
      width: 20px;
    }
  }

  & input[type='checkbox'] {
    cursor: pointer;
    height: 20px;
    margin: 0;
    opacity: ${({ theme }) => theme.opacity.transparent};
    position: absolute;
    width: 20px;
    z-index: 99;

    &:focus + .form-field__label {
      &:before {
        box-shadow: ${({ theme }) => theme.shadows.checkbox};
      }
    }

    &:checked + .form-field__label {
      &:before {
        background: ${({ theme }) => theme.colors.niagaraLight} !important;
      }
      &:after {
        display: block;
      }
    }
    &:focus + .form-field__label:before {
      box-shadow: ${({ theme }) => theme.shadows.checkbox};
    }
  }
  height: auto;
  padding: 11px 0 11px 0;
`;

export const FiltersButtonsWrapper = styled.div`
  display: flex;
  float: right;
  margin-top: 16px;
  padding: 0 16px;
`;

export const ClearButton = styled.button`
  color: ${({ theme }) => theme.colors.mirage};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-right: 34px;
  background: none;
`;

export const ApplyButton = styled.button`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 12px;
  min-width: 94px;
`;

export const FilterCloseButton = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

export const EmojiWrapper = styled.span`
  padding-right: 8px;
`;

export const QuickFilters = styled.div`
  color: ${({ theme }) => theme.colors.darkTopaz};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  padding: 16px 16px 0 16px;
  text-transform: uppercase;
`;
