import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getLeftPartIconClassName } from '../utils';

import ProgressBar from './ProgressBar';

import { PORTFOLIO_REGISTRATION } from 'store/dependentUser/types';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioLocation } from 'store/portfolio/selectors';
import Portfolio from 'pages/Portfolio/Portfolio';
import PortfolioCustomization from 'pages/Portfolio/subpages/PortfolioCustomization/PortfolioCustomization';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';

function RaizPortfolio({ stepName, goBack, isProPortfolioEnabled }) {
  const { t } = useTranslation();

  const { isPortfolioCustomization } = useAppSelector(selectPortfolioLocation);

  let question = '';
  let stepClass = 'step-one';
  let contentFillTitle = '';

  switch (stepName) {
    case 'select':
      stepClass = 'step-account form-question other change-portfolio';

      contentFillTitle = t('raizKidsRegistrationPage.title');

      question = isProPortfolioEnabled ? (
        <Portfolio handleGoBack={(path) => goBack({ path })} />
      ) : (
        <ChangePortfolio
          isRegistration
          isKidsRegistration
          goBack={(path) => goBack({ path })}
        />
      );

      break;
    default:
  }

  return (
    <div className="registration-page raiz-kids__registration -kids">
      <div className={stepClass}>
        <div className="left-part">
          <div className="part-content">
            <div className="logo -kids" />

            <div className="title" />

            <div className={getLeftPartIconClassName()} />

            <div className="bottom-text">
              {t('raizKidsRegistrationPage.kidsSimplyWay')}
            </div>
          </div>
        </div>

        <div className="right-part">
          {isPortfolioCustomization ? (
            <PortfolioCustomization />
          ) : (
            <>
              <ProgressBar
                stepName={stepName}
                activeStep={PORTFOLIO_REGISTRATION}
              />

              <div className="bottom-part">
                <div className="content-fill">
                  <h1>{contentFillTitle}</h1>
                </div>

                {question}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RaizPortfolio;

RaizPortfolio.propTypes = {
  stepName: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  isProPortfolioEnabled: PropTypes.bool.isRequired,
};

RaizPortfolio.defaultProps = {};
