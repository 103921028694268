import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import dayjs from 'dayjs';

import * as userActions from '../../../store/user/actions';
import { MY_APP } from '../../../constants/localeConfigs';
import { SUPER_FUND_REGISTRATION } from '../../../constants/comonConstants';
import {
  REGISTRATION_CREATE_MEMBERS_SUCCESS,
  REGISTRATION_SUPERFUND_INFO,
  REGISTRATION_SUPERFUND_MEMBERS,
} from '../../../store/user/consts';

import ProgressBar from './ProgressBar';
import SMSFInformation from './SMSFInformation';
import SMSFMembers from './SMSFMembers';

class SMSFAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smsfMembers: { members: [] },
      fundType: '',
      showAddEditForm: true,
      fundEstablishmentDateError: '',
      birthDateError: '',
      birthDate: null,
      fundEstablishmentDate: null,
      currentMember: {},
    };

    this.handleChangeEstablishmentDate =
      this.handleChangeEstablishmentDate.bind(this);

    this.handleChangeBirthDate = this.handleChangeBirthDate.bind(this);

    this.changeFundType = this.changeFundType.bind(this);

    this.handleSMSFInfo = this.handleSMSFInfo.bind(this);

    this.handleSMSFMembers = this.handleSMSFMembers.bind(this);

    this.saveMemberToList = this.saveMemberToList.bind(this);

    this.removeMember = this.removeMember.bind(this);

    this.toggleAddEditForm = this.toggleAddEditForm.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getSMSFDetails();
  }

  handleChangeEstablishmentDate(date, isOpen) {
    const { fundEstablishmentDate } = this.state;

    if (dayjs(fundEstablishmentDate).isValid() && isOpen) {
      return;
    }

    this.setState({
      fundEstablishmentDate: date,
      fundEstablishmentDateError: false,
    });
  }

  handleChangeBirthDate(date, isOpen) {
    const { birthDate } = this.state;

    if (dayjs(birthDate).isValid() && isOpen) {
      return;
    }

    this.setState({
      birthDate: date,
      birthDateError: false,
    });
  }

  handleSMSFMembers(e, values, form) {
    const { smsfMembers } = this.state;
    const { actions, handleSubmit } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      actions.showLoader();

      actions.createSMSFMembers(smsfMembers).then(() => {
        const { type } = this.props;

        if (type === REGISTRATION_CREATE_MEMBERS_SUCCESS) {
          handleSubmit({}, '', { form: REGISTRATION_SUPERFUND_MEMBERS });
        }
      });
    }
  }

  handleSMSFInfo(e, values, form, hasValidationErrors) {
    const { fundType, fundEstablishmentDate } = this.state;
    const { actions, handleSubmit } = this.props;

    const data = {
      ...values,
      fund_establishment_date: fundEstablishmentDate,
      fund_type: fundType,
    };

    if (fundType === 'individual') {
      delete data.legal_company_name;

      delete data.company_acn;

      delete data.company_address;
    }

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (dayjs(fundEstablishmentDate).isValid()) {
          actions.showLoader();

          actions.createSMSFDetails(data).then(() => {
            const { errorMessage } = this.props;

            if (!errorMessage) {
              handleSubmit({}, '', { form: REGISTRATION_SUPERFUND_INFO });
            }
          });
        } else {
          this.setState({
            fundEstablishmentDateError: true,
          });
        }
      }
    }
  }

  onChangeState = (event) => {
    if (event[0]) {
      this.setState({ currentState: event[0].value });
    }
  };

  changeFundType(fundType) {
    this.setState({
      fundType,
    });
  }

  saveMemberToList(values, key, form, hasValidationErrors) {
    const { smsfMembers, birthDate, currentState } = this.state;
    const currentValues = values;

    currentValues.date_of_birth = dayjs(birthDate).format('YYYY-MM-DD');

    currentValues.state = currentState;

    if (!hasValidationErrors) {
      if (dayjs(birthDate).isValid()) {
        const previousMembersList = smsfMembers.members;
        let newMembers = previousMembersList;

        if (key !== false) {
          previousMembersList[key] = currentValues;

          newMembers = previousMembersList;
        } else {
          newMembers = previousMembersList.concat([currentValues]);
        }

        this.setState({
          birthDateError: false,
          smsfMembers: {
            members: newMembers,
          },
          showAddEditForm: false,
          currentMemberCounter: undefined,
        });
      } else {
        this.setState({
          birthDateError: true,
        });
      }
    } else {
      form.submit();
    }
  }

  toggleAddEditForm(condition, member, key, showEdit) {
    this.setState(
      {
        showAddEditForm: showEdit ? !condition : condition,
        currentMember: member || {},
        birthDate: member ? member.date_of_birth : '',
        currentMemberCounter: member ? key : undefined,
      },
      () => {
        this.setState({
          showAddEditForm: condition,
        });
      },
    );
  }

  removeMember(key) {
    const { smsfMembers } = this.state;
    const previousMembersList = smsfMembers.members;

    previousMembersList.splice(key, 1);

    this.setState({
      smsfMembers: {
        members: previousMembersList,
      },
    });
  }

  render() {
    const {
      fundType,
      fundEstablishmentDate,
      fundEstablishmentDateError,
      smsfMembers,
      showAddEditForm,
      currentMember,
      currentMemberCounter,
      birthDate,
      birthDateError,
    } = this.state;

    const { stepName, goBack, showLoader, smsfDetails, t } = this.props;
    let question = '';
    let stepClass = 'step-one';
    let contentFillTitle = '';

    switch (stepName) {
      case 'information':
        stepClass = 'step-account step-super-fund';

        contentFillTitle = t(
          'registrationPage.smsfAccount.superFundInformation',
        );

        question = (
          <SMSFInformation
            handleChangeEstablishmentDate={this.handleChangeEstablishmentDate}
            fundType={fundType}
            fundEstablishmentDateError={fundEstablishmentDateError}
            fundEstablishmentDate={fundEstablishmentDate}
            smsfDetails={smsfDetails.details}
            changeFundType={this.changeFundType}
            handleSubmit={this.handleSMSFInfo}
          />
        );

        break;
      case 'members':
        stepClass = 'step-account step-super-fund';

        contentFillTitle = t('registrationPage.smsfAccount.memberDirector');

        question = (
          <SMSFMembers
            onChangeState={this.onChangeState}
            showAddEditForm={showAddEditForm}
            removeMember={this.removeMember}
            handleChangeBirthDate={this.handleChangeBirthDate}
            saveMemberToList={this.saveMemberToList}
            toggleAddEditForm={this.toggleAddEditForm}
            smsfMembers={smsfMembers.members}
            goBack={goBack}
            birthDate={birthDate}
            birthDateError={birthDateError}
            currentMember={currentMember}
            currentMemberCounter={currentMemberCounter}
            showLoader={showLoader}
            handleSubmit={this.handleSMSFMembers}
          />
        );

        break;
      default:
    }

    return (
      <div className="registration-page">
        <div className={stepClass}>
          <div className="left-part">
            <div className="part-content">
              <div className="logo" />

              <div className="title" />

              <div
                className={`${MY_APP ? '-my-illustration' : ''} body-text`}
              />

              <div className="bottom-text">
                {t('registrationPage.leftBottom.two')}
              </div>
            </div>
          </div>

          <div className="right-part">
            <ProgressBar activeStep={SUPER_FUND_REGISTRATION} />

            <div className="bottom-part">
              <div className="content-fill">
                <h1>{contentFillTitle}</h1>
              </div>

              {question}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SMSFAccount.propTypes = {
  stepName: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  smsfDetails: PropTypes.shape({
    details: PropTypes.shape({}),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

SMSFAccount.defaultProps = {
  showLoader: false,
  errorMessage: '',
  type: '',
};

const mapStateToProps = (state) => ({
  user: state.user,
  type: state.user.type,
  smsfDetails: state.user.smsfDetails,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SMSFAccount));
