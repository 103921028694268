import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actionsGifts from '../../store/gifts/actions';
import * as dependencyActions from '../../store/dependentUser/actions';
import {
  CLOSE_ACCOUNT_KIDS_SUCCESS,
  CONVERT_FULL_SUCCESS,
  RESENT_LINK_SUCCESS,
  KIDS_CLOSE_ACCOUNT,
  KIDS_CONVERT_ACCOUNT,
  KIDS_INVEST_NOW,
  KIDS_LINK_SENT,
  KIDS_WITHDRAW,
  ACCESS_SELECTED,
  CREDIT,
  DEPENDENT,
  OWNER,
  TRANSFER_IN_SUCCESS,
  ACTIVATE_ACCOUNT_KIDS_SUCCESS,
} from '../../store/dependentUser/types';

import { KIDS_EDIT_STEPS } from './consts';
import messages from './RaizKidsEdit.i18n';
import RaizKidsEditView from './RaizKidsEditView';

import { INVESTMENT_TYPES, KIDS_STATUS } from 'constants/kidsConstants';
import withRouter from 'routes/withRouter';
import { MY_APP } from 'constants/localeConfigs';
import { selectIsProPortfolioEnabled } from 'store/firebaseConfig/selectors';

class RaizKidsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      isShowSentLinkModal: false,
      showSubSettings: false,
      dob: '',
      dobError: false,
      unlimAmount: 0,
      allowType: false,
      investmentLimitType: INVESTMENT_TYPES.notAllow,
      isOpenWithdrawModal: false,
      isShowInvestNow: false,
      currentNotifications: [],
      oneTimeInvestmentValue: 0,
      manage: {
        manage_recurring_and_goals: false,
        manage_portfolio: false,
        rewards: false,
        investing: false,
        investing_weekly_limit: null,
      },
      alert: {
        isOpen: false,
        title: undefined,
        text: '',
        handleClick: null,
      },
      newKidName: '',
      errorTransferMessage: '',
      showCongratsModal: false,
    };

    this.renderIcons = this.renderIcons.bind(this);

    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.handleChangeIcon = this.handleChangeIcon.bind(this);

    this.handleSubmitInvestNow = this.handleSubmitInvestNow.bind(this);

    this.toggleErrorModal = this.toggleErrorModal.bind(this);

    this.changeAccessInvestment = this.changeAccessInvestment.bind(this);

    this.changeUnlimitedAmount = this.changeUnlimitedAmount.bind(this);

    this.changeAccessType = this.changeAccessType.bind(this);

    this.toggleMenu = this.toggleMenu.bind(this);

    this.toggleSentlink = this.toggleSentlink.bind(this);

    this.closeAccount = this.closeAccount.bind(this);

    this.sentLink = this.sentLink.bind(this);

    this.convertToFull = this.convertToFull.bind(this);

    this.toggleConvertToFull = this.toggleConvertToFull.bind(this);

    this.toggleCloseAccount = this.toggleCloseAccount.bind(this);

    this.toggleWithdrawModal = this.toggleWithdrawModal.bind(this);

    this.handleChangeNotifications = this.handleChangeNotifications.bind(this);

    this.handleSubmitNotifications = this.handleSubmitNotifications.bind(this);

    this.handleChangeAccess = this.handleChangeAccess.bind(this);

    this.handleSubmitAccess = this.handleSubmitAccess.bind(this);

    this.handleSubmitInvestmentAccess =
      this.handleSubmitInvestmentAccess.bind(this);

    this.changeOneTimeInvestmentValue =
      this.changeOneTimeInvestmentValue.bind(this);

    this.toggleInvestNow = this.toggleInvestNow.bind(this);

    this.handleSubmitProfile = this.handleSubmitProfile.bind(this);

    this.closeAlert = this.closeAlert.bind(this);

    this.handleSubmitTransfer = this.handleSubmitTransfer.bind(this);

    this.toggleCongrantsModal = this.toggleCongrantsModal.bind(this);

    this.activateAccount = this.activateAccount.bind(this);

    this.saveNotificationsChange = this.saveNotificationsChange.bind(this);
  }

  componentDidMount() {
    const { actionsDependency, match, giftsActions, history } = this.props;
    const { childId } = match.params;

    giftsActions.getGiftsIcons();

    actionsDependency.getDependencyUser(childId).then(() => {
      const { child } = this.props;

      if (child.error) {
        history.push('/not-found');
      } else {
        const weeklyLimit =
          child.dependency_user &&
          child.dependency_user.investing &&
          child.dependency_user.investing_weekly_limit
            ? INVESTMENT_TYPES.allowWeekly
            : INVESTMENT_TYPES.allow;

        this.setState({
          showLoader: false,
          manage: {
            manage_recurring_and_goals:
              child.dependency_user.manage_recurring_and_goals,
            manage_portfolio: child.dependency_user.manage_portfolio,
            rewards: child.dependency_user.rewards,
          },
          allowType: child.dependency_user.account_access,
          investmentLimitType: child.dependency_user.investing
            ? weeklyLimit
            : INVESTMENT_TYPES.notAllow,
          unlimAmount: child.dependency_user.investing_weekly_limit,
          icon: child.dependency_user.avatar_id,
          dob: new Date(child.dependency_user.date_of_birth),
          newKidName: child.dependency_user.name,
        });

        actionsDependency.getNotifications(childId).then(() => {
          const { notifications } = this.props;

          this.setState({
            currentNotifications: notifications,
          });
        });
      }
    });

    if (
      window.location.href.indexOf('/edit/profile') !== -1 ||
      window.location.href.indexOf('/edit/access') !== -1 ||
      window.location.href.indexOf('/edit/notification') !== -1
    ) {
      this.toggleMenu(true);
    }
  }

  handleSubmitInvestNow() {
    const { actionsDependency, child } = this.props;
    const { oneTimeInvestmentValue } = this.state;

    actionsDependency
      .dependencyUserInvestment(
        { amount: oneTimeInvestmentValue, type: CREDIT },
        child.dependency_user.id,
      )
      .then(() => {
        const { errorMessage } = this.props;

        if (errorMessage?.length) {
          this.toggleErrorModal(true);
        } else {
          this.toggleInvestNow({ isModalOpen: false });
        }
      });
  }

  handleChangeIcon(icon) {
    this.setState({ icon });
  }

  handleChangeDate(date, isOpen) {
    if (dayjs(date).isValid() && isOpen) {
      return;
    }

    this.setState({
      dob: dayjs(date).isAfter(dayjs()) ? new Date() : date,
      dobError: false,
    });
  }

  handleSubmitProfile(e, values, form, hasValidationErrors, from) {
    const { actionsDependency, child, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      if (!hasValidationErrors) {
        this.setState({ showLoader: true });

        form.submit();

        const { dob, icon } = this.state;

        const requestData = {
          dependent_user_id: child.dependency_user.id,
          name: values.name,
          date_of_birth: dob,
          dependent_user: {
            avatar_id: icon,
          },
        };

        const onSuccess = () => {
          this.setState({
            alert: {
              isOpen: true,
              title: t('raizKidsEditPage.successModal.title'),
              text: t('raizKidsEditPage.successModal.description'),
              handleClick: this.closeAlert,
            },
          });
        };

        actionsDependency
          .updateDependencyUser({ from }, requestData, onSuccess)
          .then(() => {
            this.setState({
              showLoader: false,
            });
          });
      }
    }
  }

  handleSubmitNotifications(e) {
    const { actionsDependency, child } = this.props;
    const { currentNotifications } = this.state;
    const requestDate = {};

    currentNotifications.map((notification) => {
      requestDate[notification.key] = notification.value;

      return requestDate;
    });

    this.setState({
      showLoader: true,
    });

    if (e.persist) {
      e.persist();

      e.preventDefault();

      actionsDependency
        .updateNotifications(requestDate, child.dependency_user.id)
        .then(() => {
          this.setState({
            showLoader: false,
          });
        });
    }
  }

  handleChangeAccess(condition, key) {
    const { manage } = this.state;
    const manageProperty = { ...manage };

    manageProperty[key] = condition;

    this.setState({ manage: manageProperty });
  }

  handleSubmitAccess(e) {
    const { actionsDependency, child, t } = this.props;
    const { manage } = this.state;

    const requestData = {
      dependent_user_id: child.dependency_user.id,
      dependent_user: manage,
    };

    this.setState({
      showLoader: true,
    });

    if (e.persist) {
      e.persist();

      e.preventDefault();

      actionsDependency
        .updateDependencyUser({ from: ACCESS_SELECTED }, requestData)
        .then(() => {
          this.setState({
            showLoader: false,
            alert: {
              isOpen: true,
              title: t('raizKidsEditPage.access.successModal.title'),
              text: t('raizKidsEditPage.access.successModal.description'),
              handleClick: this.closeAlert,
            },
          });
        });
    }
  }

  handleSubmitInvestmentAccess(e, values) {
    const { actionsDependency, child, match, t } = this.props;
    const { childStep, childId } = match.params;
    const { investmentLimitType, unlimAmount, allowType } = this.state;

    const requestData =
      childStep === KIDS_EDIT_STEPS.investingAccess
        ? {
            dependent_user_id: child.dependency_user.id,
            dependent_user: {
              investing:
                investmentLimitType === INVESTMENT_TYPES.allow ||
                investmentLimitType === INVESTMENT_TYPES.allowWeekly,
              ...(investmentLimitType === INVESTMENT_TYPES.allow && {
                investing_weekly_limit: null,
              }),
              ...(investmentLimitType === INVESTMENT_TYPES.allowWeekly && {
                investing_weekly_limit: unlimAmount || 0,
              }),
            },
            email: values.email,
          }
        : {
            dependent_user_id: child.dependency_user.id,
            dependent_user: {
              account_access: allowType,
              ...(!allowType && {
                investing_weekly_limit: null,
                investing: false,
                manage_recurring_and_goals: false,
                manage_portfolio: false,
                rewards: false,
              }),
            },
            email: values.email,
          };

    this.setState({
      showLoader: true,
    });

    const onSuccess = () => {
      this.setState({
        alert: {
          isOpen: true,
          title: t('raizKidsEditPage.access.successModal.title'),
          text: t('raizKidsEditPage.access.successModal.description'),
          handleClick: this.closeAlert,
        },
      });
    };

    if (e.persist) {
      e.persist();

      e.preventDefault();

      actionsDependency
        .updateDependencyUser({ from: ACCESS_SELECTED }, requestData, onSuccess)
        .then(() => {
          this.setState({
            showLoader: false,
          });

          actionsDependency.getDependencyUser(childId);
        });
    }
  }

  handleChangeNotifications(condition, key) {
    const { currentNotifications } = this.state;

    const index = currentNotifications.findIndex(
      (notification) => notification.key === key,
    );

    this.setState({
      currentNotifications: [
        ...currentNotifications.slice(0, index),
        { ...currentNotifications[index], value: condition },
        ...currentNotifications.slice(index + 1),
      ],
    });
  }

  handleSubmitTransfer(e, transferAmount, isOwner) {
    const { actionsDependency, child, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      if (!transferAmount) {
        this.setState({
          errorTransferMessage: t('raizKidsEditPage.transfer.error'),
        });
      } else {
        const userId = child.dependency_user.id;

        this.setState({ errorTransferMessage: '', showLoader: true });

        const requestData = {
          dependent_user_id: userId,
          amount: transferAmount,
          target: isOwner ? OWNER : DEPENDENT,
        };

        actionsDependency.transferIn(requestData).then(() => {
          const { type } = this.props;

          this.setState({ showLoader: false });

          if (type === TRANSFER_IN_SUCCESS) {
            this.toggleCongrantsModal(true);
          }
        });
      }
    }
  }

  async saveNotificationsChange(value, key) {
    const { actionsDependency, child, t } = this.props;
    const { currentNotifications } = this.state;
    const prevNotifications = currentNotifications;

    const requestData = currentNotifications.reduce(
      (prevNotification, currentNotification) => ({
        ...prevNotification,
        [currentNotification.key]:
          currentNotification.key === key ? value : currentNotification.value,
      }),
      {},
    );

    const nextNotifications = currentNotifications.map((current) => ({
      ...current,
      value: current.key === key ? value : current.value,
    }));

    this.setState({
      showLoader: true,
      currentNotifications: nextNotifications,
    });

    try {
      await actionsDependency.updateNotifications(
        requestData,
        child.dependency_user.id,
      );

      const { errorMessage } = this.props;

      if (errorMessage?.length) {
        this.setState({
          showLoader: false,
          currentNotifications: prevNotifications,
          alert: {
            isOpen: true,
            title: t('raizKidsEditPage.notificationsSaveFailureModal.title'),
            text: t('raizKidsEditPage.notificationsSaveFailureModal.content'),
            handleClick: this.closeAlert,
          },
        });
      } else {
        this.setState({ showLoader: false });
      }
    } catch {
      console.error('Failed To Update Notifications');
    }
  }

  toggleSentlink(condition) {
    this.setState({
      isShowSentLinkModal: condition,
    });
  }

  toggleConvertToFull(condition) {
    this.setState({
      isShowConvertToFull: condition,
    });
  }

  toggleCloseAccount(condition) {
    this.setState({
      isShowCloseAccount: condition,
    });
  }

  toggleWithdrawModal(condition) {
    this.setState({
      isOpenWithdrawModal: condition,
    });
  }

  activateAccount(kidId) {
    const { actionsDependency } = this.props;

    this.setState({
      showLoader: true,
    });

    actionsDependency.activateAccount(kidId).then(() => {
      const { type, history } = this.props;

      this.setState({
        showLoader: false,
      });

      if (type === ACTIVATE_ACCOUNT_KIDS_SUCCESS) {
        history.push(0);
      }
    });
  }

  closeAlert() {
    this.setState({
      alert: {
        isOpen: false,
        title: undefined,
        text: '',
        handleClick: null,
      },
    });
  }

  convertToFull(e, values, form, hasValidationErrors, childId) {
    const { actionsDependency, child } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        this.setState({
          showLoader: true,
        });

        const data = {
          email: child.dependency_user.email || values.email,
          dependent_user_id: childId,
        };

        actionsDependency.convertToFull(data).then(() => {
          const { type, history } = this.props;

          if (!MY_APP) {
            this.setState({
              showLoader: false,
            });
          }

          if (type === CONVERT_FULL_SUCCESS) {
            if (MY_APP) {
              actionsDependency.getDependencyUser(childId).then(() => {
                this.setState({
                  showLoader: false,
                  isShowConvertToFull: false,
                });
              });
            } else {
              history.push('/raiz-kids');
            }
          }
        });
      }
    }
  }

  closeAccount(childId) {
    const { actionsDependency } = this.props;

    this.setState({
      showLoader: true,
    });

    actionsDependency.closeAccount(childId).then(() => {
      const { type, history } = this.props;

      this.setState({
        showLoader: false,
      });

      if (type === CLOSE_ACCOUNT_KIDS_SUCCESS) {
        history.push('/raiz-kids');
      }
    });
  }

  changeAccessInvestment(investmentLimitType) {
    this.setState({
      investmentLimitType,
    });
  }

  changeUnlimitedAmount(unlimAmount) {
    this.setState({
      unlimAmount,
    });
  }

  changeAccessType(allowType) {
    this.setState({
      allowType,
    });
  }

  changeOneTimeInvestmentValue(value = 0) {
    this.setState({ oneTimeInvestmentValue: value });
  }

  toggleInvestNow({ isModalOpen }) {
    this.setState({
      isShowInvestNow: isModalOpen,
      ...(!isModalOpen && { oneTimeInvestmentValue: 50 }),
    });
  }

  toggleErrorModal(condition) {
    this.setState({ isShowErrorModal: condition });
  }

  sentLink() {
    const { actionsDependency, child } = this.props;

    this.setState({
      showLoader: true,
    });

    actionsDependency.resentLink(child.dependency_user.id).then(() => {
      this.setState({
        showLoader: false,
      });

      const { type } = this.props;

      if (type === RESENT_LINK_SUCCESS) {
        this.toggleSentlink(true);
      }
    });
  }

  toggleMenu(condition, step, isConvertable) {
    const { child } = this.props;
    const kidStatus = child?.dependency_user?.status;

    this.setState({
      showSubSettings: condition,
    });

    switch (step) {
      case KIDS_LINK_SENT:
        this.sentLink();

        break;
      case KIDS_CLOSE_ACCOUNT:
        if (kidStatus === KIDS_STATUS.pending_converting) return;

        this.toggleCloseAccount(true);

        break;
      case KIDS_CONVERT_ACCOUNT:
        if (isConvertable) {
          this.toggleConvertToFull(true);
        }

        break;
      case KIDS_WITHDRAW:
        this.toggleWithdrawModal(true);

        break;
      case KIDS_INVEST_NOW:
        this.toggleInvestNow({ isModalOpen: true });

        break;
      default:
        break;
    }
  }

  toggleCongrantsModal(condition) {
    this.setState({
      showCongratsModal: condition,
    });
  }

  renderIcons(id, link) {
    const { icon } = this.state;

    return (
      <div
        className="raiz-kids__avatar-container"
        key={id}
        onClick={() => this.handleChangeIcon(id)}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <img
          alt={id}
          src={link}
          className={`raiz-kids__avatar ${icon === id ? '-active' : ''}`}
        />
      </div>
    );
  }

  render() {
    const {
      match,
      child,
      receiverIcons,
      errorMessage,
      user,
      isProPortfolioEnabled,
      history,
    } = this.props;

    const {
      showLoader,
      showSubSettings,
      isShowSentLinkModal,
      isShowConvertToFull,
      isOpenWithdrawModal,
      dob,
      allowType,
      currentNotifications,
      manage,
      oneTimeInvestmentValue,
      isShowInvestNow,
      isShowErrorModal,
      dobError,
      isShowCloseAccount,
      alert,
      newKidName,
      errorTransferMessage,
      showCongratsModal,
    } = this.state;

    const { childStep, additionalStep } = match.params;

    return (
      <RaizKidsEditView
        showCongratsModal={showCongratsModal}
        user={user}
        errorTransferMessage={errorTransferMessage}
        dob={dob}
        manage={manage}
        messages={messages}
        dobError={dobError}
        childStep={childStep}
        allowType={allowType}
        showLoader={showLoader}
        errorMessage={errorMessage}
        toggleMenu={this.toggleMenu}
        receiverIcons={receiverIcons}
        renderIcons={this.renderIcons}
        additionalStep={additionalStep}
        showSubSettings={showSubSettings}
        isShowInvestNow={isShowInvestNow}
        convertToFull={this.convertToFull}
        closeAccount={this.closeAccount}
        activateAccount={this.activateAccount}
        isShowErrorModal={isShowErrorModal}
        kid={child.dependency_user || {}}
        toggleSentlink={this.toggleSentlink}
        notifications={currentNotifications}
        toggleInvestNow={this.toggleInvestNow}
        handleChangeDate={this.handleChangeDate}
        changeAccessType={this.changeAccessType}
        toggleErrorModal={this.toggleErrorModal}
        isShowConvertToFull={isShowConvertToFull}
        isShowCloseAccount={isShowCloseAccount}
        isOpenWithdrawModal={isOpenWithdrawModal}
        isShowSentLinkModal={isShowSentLinkModal}
        alert={alert}
        newKidName={newKidName}
        handleChangeAccess={this.handleChangeAccess}
        handleSubmitAccess={this.handleSubmitAccess}
        toggleConvertToFull={this.toggleConvertToFull}
        toggleCloseAccount={this.toggleCloseAccount}
        handleSubmitProfile={this.handleSubmitProfile}
        toggleWithdrawModal={this.toggleWithdrawModal}
        oneTimeInvestmentValue={oneTimeInvestmentValue}
        changeUnlimitedAmount={this.changeUnlimitedAmount}
        handleSubmitInvestNow={this.handleSubmitInvestNow}
        changeAccessInvestment={this.changeAccessInvestment}
        handleChangeNotifications={this.handleChangeNotifications}
        handleSubmitNotifications={this.handleSubmitNotifications}
        changeOneTimeInvestmentValue={this.changeOneTimeInvestmentValue}
        handleSubmitInvestmentAccess={this.handleSubmitInvestmentAccess}
        handleSubmitTransfer={this.handleSubmitTransfer}
        handleSaveNotificationsChange={this.saveNotificationsChange}
        toggleCongratsModal={this.toggleCongrantsModal}
        isProPortfolioEnabled={isProPortfolioEnabled}
        history={history}
      />
    );
  }
}

RaizKidsEdit.defaultProps = {
  match: {},
  notifications: [],
  child: {},
  type: '',
  errorMessage: '',
  giftsActions: {},
  receiverIcons: {},
  actionsDependency: {},
  user: {},
  isProPortfolioEnabled: false,
};

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  child: state.dependencyUsers.child,
  type: state.dependencyUsers.type,
  notifications: state.dependencyUsers.notifications,
  receiverIcons: state.gifts.receiverIcons,
  errorMessage: state.dependencyUsers.errorMessage,
  portfolios: state.user.portfolios,
  isProPortfolioEnabled: selectIsProPortfolioEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
  giftsActions: bindActionCreators(actionsGifts, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RaizKidsEdit)),
);

RaizKidsEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      additionalStep: PropTypes.string,
      childId: PropTypes.string,
      childStep: PropTypes.string,
    }),
  }),
  child: PropTypes.shape({
    error: PropTypes.string,
    dependency_user: PropTypes.shape({
      status: PropTypes.string,
      investing_weekly_limit: PropTypes.number,
      id: PropTypes.string,
      email: PropTypes.string,
      avatar_id: PropTypes.string,
      date_of_birth: PropTypes.string,
      name: PropTypes.string,
      investing: PropTypes.bool,
      account_access: PropTypes.bool,
      manage_recurring_and_goals: PropTypes.bool,
      manage_portfolio: PropTypes.bool,
      rewards: PropTypes.bool,
      recurring: PropTypes.shape({
        day: PropTypes.number,
        amount: PropTypes.number,
        start_date: PropTypes.string,
        frequency_identifier: PropTypes.string,
      }),
    }),
  }),
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  receiverIcons: PropTypes.shape({}),
  actionsDependency: PropTypes.shape({
    activateAccount: PropTypes.func,
    getDependencyUser: PropTypes.func,
    getNotifications: PropTypes.func,
    dependencyUserInvestment: PropTypes.func,
    updateNotifications: PropTypes.func,
    resentLink: PropTypes.func,
    dependencyUserReccurring: PropTypes.func,
    convertToFull: PropTypes.func,
    closeAccount: PropTypes.func,
    updateDependencyUser: PropTypes.func,
    transferIn: PropTypes.func,
  }),
  giftsActions: PropTypes.shape({
    getGiftsIcons: PropTypes.func,
  }),
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    allocation_profile_id: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  isProPortfolioEnabled: PropTypes.bool,
};
