import { IAllCategoryDetails } from '../types';

interface IGetTransactionNameObject {
  types: IAllCategoryDetails[];
  value: string;
}

const getTransactionNameObject = ({
  types,
  value,
}: IGetTransactionNameObject) => {
  const currentType = types.find(
    (type) => type.name === value.substring(1).trim(),
  );

  if (currentType) {
    return {
      name: currentType.name,
      parentCategory: currentType.parentCategory || '',
    };
  }

  return { name: '', parentCategory: '' };
};

export default getTransactionNameObject;
