import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function PertanyaanInfoModal({
  showPertanyaanInfo,
  togglePertanyaanInfo,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={showPertanyaanInfo}
      onRequestClose={() => togglePertanyaanInfo(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal modal-basic__common"
    >
      <DeprecatedButton
        buttonClass="button__close -black"
        handleClick={() => togglePertanyaanInfo(false)}
      />

      <div className="time-investment__modal-content">
        {t('registrationPage.pertanyaanInfoModal.content')}
      </div>
    </Modal>
  );
}

PertanyaanInfoModal.propTypes = {
  showPertanyaanInfo: PropTypes.bool.isRequired,
  togglePertanyaanInfo: PropTypes.func.isRequired,
};
