import styled from '@emotion/styled';
import SelectReact from 'react-dropdown-select';

import { ErrorLabel, FieldWrapper } from '../styles';

import dropDownIcon from 'assets/images/icons/arrow_drop_down.svg';

export const Select = styled(SelectReact)`
  border: none !important;
  padding: 0 !important;
  min-height: auto !important;
  box-shadow: none !important;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  .react-dropdown-select-item {
    &:hover {
      background: ${({ theme }) => theme.colors.mutedGreen};
    }
  }

  .react-dropdown-select-item-selected {
    background: ${({ theme }) => theme.colors.greenishCyan} !important;
  }

  .react-dropdown-select-input {
    margin-left: 0;
    padding: 0;
    height: auto;
    font-family: ${({ theme }) => theme.fontFamily.textMedium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    &::placeholder {
      font-family: ${({ theme }) => theme.fontFamily.textRegular};
      font-size: ${({ theme }) => theme.fontSizes.md};
    }

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  .react-dropdown-select-content {
    flex-wrap: nowrap;
    margin-top: 5px;
  }

  .react-dropdown-select-dropdown-handle {
    transform: rotate(180deg);

    &::before {
      content: '';
      display: block;
      background-image: url(${`${dropDownIcon}`});
      width: 10px;
      height: 22px;
      transform: rotate(180deg);
    }

    svg {
      display: none;
    }
  }
`;

export const SelectWrapper = styled(FieldWrapper)`
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  .react-dropdown-select {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }
  height: 70px;
`;

export const SelectError = styled(ErrorLabel)`
  background-color: ${({ theme }) => theme.colors.transparent};
`;
