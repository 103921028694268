import { useState } from 'react';

import { PERIOD_DAYS_NUMBER } from '../../pages/PerfomanceDetails/consts';

import Chart from './components/Chart';
import InfoPanel from './components/InfoPanel';
import NumberOfDaysSwitcher from './components/NumberOfDaysSwitcher';
import { PerformanceChartWrapper } from './styles';

interface IPerformanceChartProps {
  id: string;
}

export default function PerformanceChart({ id }: IPerformanceChartProps) {
  const [numberOfDays, setNumberOfDays] = useState(PERIOD_DAYS_NUMBER.oneYear);

  return (
    <PerformanceChartWrapper>
      <InfoPanel />

      <Chart id={id} numberOfDays={numberOfDays} />

      <NumberOfDaysSwitcher
        setNumberOfDays={setNumberOfDays}
        numberOfDays={numberOfDays}
      />
    </PerformanceChartWrapper>
  );
}
