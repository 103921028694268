import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import FinalFormInput from 'components/elements/FinalFormInput/FinalFormInput';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';
import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function TfnForm({
  handleSubmit,
  handleChange,
  tfn,
  errorTfn,
  errorTfnChecked,
  goBack,
  showLoader,
}) {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form, values, hasValidationErrors }) => (
        <form
          onChange={handleChange}
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className="content">
            <div className="title">
              {t('superPage.superRegistration.taxFileNumber.title')}
            </div>

            <div className="text">
              {t('superPage.superRegistration.taxFileNumber.instruction')}
            </div>

            <div className="form">
              <div className="profile-content__container-item">
                <FinalFormInput
                  name="tfn"
                  defaultValue={tfn}
                  autoFocus
                  label={t('superPage.superRegistration.taxFileNumber.field')}
                />

                {errorTfn !== '' && <div className="error">{errorTfn}</div>}
              </div>

              <div className="profile-content__container-item no-border">
                <div className="checkbox__container relative">
                  <CheckboxFF
                    classNameInsurance={
                      values.tfn_checked
                        ? 'checkbox__check'
                        : 'checkbox__uncheck'
                    }
                    name="tfn_checked"
                    type="checkbox"
                    defaultChecked={values.tfn_checked}
                  />

                  <div className="checkbox__label">
                    {t('superPage.superRegistration.taxFileNumber.radio')}

                    {errorTfnChecked !== '' && (
                      <div className="error">{errorTfnChecked}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="bottom-content">
            <div className="left-part">
              <div className="text">
                {t('superPage.superRegistration.taxFileNumber.footer')}
              </div>
            </div>

            <div className="right-part">
              <DeprecatedButton
                title={
                  showLoader ? (
                    <DeprecatedDotsLoader additionalClass="btn" />
                  ) : (
                    t('common.back')
                  )
                }
                buttonClass="button back"
                handleClick={goBack}
              />

              <DeprecatedButton
                title={
                  showLoader ? (
                    <DeprecatedDotsLoader additionalClass="btn" />
                  ) : (
                    t('common.continue')
                  )
                }
                buttonClass="button button--submit"
                handleClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

TfnForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  tfn: PropTypes.string.isRequired,
  errorTfn: PropTypes.string.isRequired,
  errorTfnChecked: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
};
