import styled from '@emotion/styled';

interface ISwitcherWrapperProps {
  isRegistration: boolean;
}

export const SuperAccountModalLinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SuperAccountModalLink = styled.a`
  color: ${({ theme }) => theme.colors.mirage};
`;

export const SuperAccountModalDescription = styled.p`
  color: ${({ theme }) => theme.colors.dustyGray};
`;

export const SwitcherWrapper = styled.div<ISwitcherWrapperProps>`
  ${({ theme }) => `
    background-color: ${theme.colors.blackTransparent};
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.semibold};
    border-radius: 18px;
    display: inline-flex;
    padding: 3px;
    width: auto;
    margin-bottom: 30px;
    margin-top: 35px;
  `}

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.wide)} {
    flex-wrap: wrap;
    max-width: 100%;
  }

  ${({ theme, isRegistration }) =>
    isRegistration &&
    `
      margin: 10px;
      max-width: 318px;
      background: none;
      width: 100%;

      ${theme.mediaQuery(theme.breakpoints.wide)} {
        flex-wrap: wrap;
        max-width: 318px;
      }

      ${theme.mediaQuery(theme.breakpoints.mobile)} {
        flex-wrap: nowrap;
      }
    }
  `}
`;
