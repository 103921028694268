import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Time from '../../../components/elements/Time/Time';
import '../../../styles/pages/recurring-investment.scss';
import { recurringButtonStyles } from '../styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

function GoalSetTable(props) {
  const {
    currentGoal,
    deleteSavingGoal,
    setEditGoal,
    recurringDepositSettings,
    user,
  } = props;

  const percentage = Math.round(
    (user.current_balance * 100) / currentGoal.target_amount,
  );

  const { t } = useTranslation();

  return (
    <div>
      <div className="info-panel -with-padding perfomance__info-panel">
        <div className="info-panel__title">{currentGoal.name}</div>

        <div className="info-panel__divider" />

        <div className="recurring-investment__label">
          {t('recurringInvestmentPage.goalSetTable.balanceHeader')}
        </div>

        <div>
          <div className="recurring-investment__percent">
            <div
              className="recurring-investment__percent-val"
              style={{ width: `${percentage}%` }}
            >
              <div
                className={`recurring-investment__percent-point ${
                  percentage < 2 ? '-start' : ''
                }`}
              >
                {`${percentage}%`}
              </div>
            </div>

            <div className="recurring-investment__percent-label">
              <div>
                {formatNumber({
                  value: user.current_balance,
                })}
              </div>

              <div>
                {formatNumber({
                  value: currentGoal.target_amount,
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="recurring-investment__item">
          <div className="recurring-investment__label">
            {t('recurringInvestmentPage.goalSetTable.nextInvestment')}
          </div>

          <Time format="MMMM Do dddd">
            {recurringDepositSettings.next_investment_at}
          </Time>
        </div>

        <div className="recurring-investment__item">
          <div className="recurring-investment__label">
            {t('recurringInvestmentPage.goalSetTable.recurringInvAmount')}
          </div>

          {formatNumber({
            value: currentGoal.target_contribution,
          })}
        </div>

        <div className="recurring-investment__item">
          <div className="recurring-investment__label">
            {t('recurringInvestmentPage.goalSetTable.forecastGoalDate')}
          </div>

          <Time format="MMMM Do YYYY">{currentGoal.target_date}</Time>
        </div>
      </div>

      <div className="recurring-investment__btn-container">
        <Button
          label={t('recurringInvestmentPage.goalSetTable.editSavingGoal')}
          onClick={() => setEditGoal(true)}
          customStyles={recurringButtonStyles.styles}
        />

        <DeprecatedButton
          title={t('recurringInvestmentPage.goalSetTable.deleteSavingGoal')}
          buttonClass="button__basic-wtbackground"
          handleClick={() => deleteSavingGoal(currentGoal)}
        />
      </div>
    </div>
  );
}

export default GoalSetTable;

GoalSetTable.defaultProps = {
  currentGoal: {},
};

GoalSetTable.propTypes = {
  currentGoal: PropTypes.shape({
    name: PropTypes.string,
    target_date: PropTypes.string,
    target_amount: PropTypes.number,
    target_contribution: PropTypes.number,
  }),
  deleteSavingGoal: PropTypes.func.isRequired,
  setEditGoal: PropTypes.func.isRequired,
  recurringDepositSettings: PropTypes.shape({
    next_investment_at: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    current_balance: PropTypes.number,
  }).isRequired,
};
