import { BAHASA, ENGLISH } from '../constants/comonConstants';

import { LANGUAGES, appVersion } from './consts';

export const customLanguageDetector = () => {
  const lang = localStorage.getItem('lang');
  const currentURL = new URLSearchParams(window.location.search);
  const isWebView = !!currentURL.get('webView');
  const langFromUrl = currentURL.get('lang') || LANGUAGES.enAu;

  let locale = '';

  if (appVersion === 'aus') {
    locale = LANGUAGES.enAu;
  } else if (appVersion === 'mys') {
    if (isWebView) {
      locale = langFromUrl;
    } else if (lang === BAHASA) {
      locale = LANGUAGES.msMs;
    } else if (lang === ENGLISH || !lang) {
      locale = LANGUAGES.enMs;
    }
  } else {
    locale = appVersion;
  }

  return locale;
};
