import { REWARDS_FILTER_VALUE } from '../consts';

const hasTransactionsToDisplay = (props) => {
  const { rewards, activeTab } = props;

  if (!rewards || rewards?.length === 0) {
    return false;
  }

  if (activeTab === REWARDS_FILTER_VALUE.all && rewards?.length > 0)
    return true;

  return rewards.some(({ status }) => status === activeTab);
};

export default hasTransactionsToDisplay;
