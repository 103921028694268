import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';

import '../../../styles/layout/time-investment.scss';
import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';

import OneTimeInvestmentModal from './OneTimeInvestmentModal';
import OneTimeInvestmentQuickModal from './OneTimeInvestmentQuickModal';
import MinimumInvestmentModal from './MinimumInvestmentModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { HOME_HISTORY_TAB } from 'constants/comonConstants';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

const changeState = (path) => {
  window.location.href = path;
};

const investmentCongratsModal = (
  t,
  isOpenInvestmentCongratsModal,
  openInvestmentCongratsModal,
) => (
  <Modal
    isOpen={isOpenInvestmentCongratsModal}
    onRequestClose={() => openInvestmentCongratsModal(false)}
    contentLabel="time-investment-modal"
    shouldCloseOnOverlayClick
    className="modal-basic time-investment__modal time-investment__modal-quick"
  >
    <div className="time-investment__modal-title">
      {t('oneTimeInvestment.investmentCongratsModal.title')}
    </div>

    <div className="time-investment__modal-content">
      {t('oneTimeInvestment.investmentCongratsModal.description')}
    </div>

    <div className="time-investment__modal-footer -single-btn">
      <DeprecatedButton
        title={t('common.ok')}
        buttonClass="button__basic-transparent -black"
        handleClick={() => openInvestmentCongratsModal(false)}
      />

      <Button
        onClick={() => changeState(HOME_HISTORY_TAB)}
        label={t(
          'oneTimeInvestment.investmentCongratsModal.button.viewHistory',
        )}
        href={HOME_HISTORY_TAB}
      />
    </div>
  </Modal>
);

const investmentInfoModal = (
  t,
  isOpenInvestmentInfoModal,
  openInvestmentInfoModal,
  oneTimeInvestmentValue,
  oneTimeInvest,
  showLoader,
  user,
) => (
  <Modal
    isOpen={isOpenInvestmentInfoModal}
    onRequestClose={() => openInvestmentInfoModal(false)}
    contentLabel="time-investment-modal"
    shouldCloseOnOverlayClick
    className="modal-basic time-investment__modal time-investment__modal-quick"
  >
    <div className="time-investment__modal-title">
      {t('oneTimeInvestment.investmentInfoModal.title')}
    </div>

    <div className="time-investment__modal-content">
      {user && user.round_ups.waiting ? (
        <Trans
          i18nKey="oneTimeInvestment.investmentInfoModal.description.areYouSureWaiting"
          t={t}
          components={{
            value: formatNumber({ value: oneTimeInvestmentValue }),
            additional: formatNumber({ value: user.round_ups.waiting }),
          }}
        />
      ) : (
        <Trans
          i18nKey="oneTimeInvestment.investmentInfoModal.description.areYouSure"
          t={t}
          components={{
            value: formatNumber({ value: oneTimeInvestmentValue }),
          }}
        />
      )}
    </div>

    <div className="time-investment__modal-footer">
      <DeprecatedButton
        withLoader={showLoader}
        title={t('common.cancel')}
        buttonClass="button__basic-transparent -black"
        handleClick={() => openInvestmentInfoModal(false)}
      />

      <Button
        isLoading={showLoader}
        label={t('common.invest')}
        onClick={() => oneTimeInvest(oneTimeInvestmentValue)}
      />
    </div>
  </Modal>
);

function OneTimeInvestmentView(props) {
  const {
    openQuickInvestmentModal,
    isOpenQuickInvestmentModal,
    oneTimeInvestmentValue,
    oneTimeInvest,
    openInvestmentModal,
    isOpenInvestmentModal,
    changeOneTimeInvestmentValue,
    isOpenMinimumInvestmentModal,
    openMinimumInvestmentModal,
    errorMessage,
    openInvestmentInfoModal,
    isOpenInvestmentInfoModal,
    isOpenInvestmentCongratsModal,
    openInvestmentCongratsModal,
    showLoader,
    portfolios,
    user,
    history,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="info-panel -with-padding time-investment">
      <div className="info-panel__title">
        {t('oneTimeInvestment.title.oneTimeInvestment')}
      </div>

      <div className="info-panel__divider" />

      <section className="info-panel__content">
        <div className="info-panel__content-centred">
          <div className="time-investment__title">
            {t('oneTimeInvestment.title.makeRaizWork')}
          </div>

          <div className="time-investment__buttons">
            <DeprecatedButton
              title={
                <NumberFormat
                  value={10}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  prefix={CURRENCY_SYMBOL}
                  decimalScale={2}
                />
              }
              buttonClass="button__rounded-green"
              handleClick={() => openInvestmentModal(true, 10)}
            />

            <DeprecatedButton
              title={
                <NumberFormat
                  value={25}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  prefix={CURRENCY_SYMBOL}
                  decimalScale={2}
                />
              }
              buttonClass="button__rounded-green"
              handleClick={() => openInvestmentModal(true, 25)}
            />

            <DeprecatedButton
              title={
                <NumberFormat
                  value={50}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  prefix={CURRENCY_SYMBOL}
                  decimalScale={2}
                />
              }
              buttonClass="button__rounded-green"
              handleClick={() => openInvestmentModal(true, 50)}
            />

            <DeprecatedButton
              title={t('common.other')}
              buttonClass="button__rounded-green"
              handleClick={() => openInvestmentModal(true, 0, true)}
            />
          </div>
        </div>
      </section>

      {isOpenInvestmentModal && (
        <OneTimeInvestmentModal
          isOpenInvestmentModal={isOpenInvestmentModal}
          oneTimeInvest={oneTimeInvest}
          openInvestmentInfoModal={openInvestmentInfoModal}
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          openInvestmentModal={openInvestmentModal}
          changeOneTimeInvestmentValue={changeOneTimeInvestmentValue}
          portfolios={portfolios}
          history={history}
        />
      )}

      {isOpenQuickInvestmentModal && (
        <OneTimeInvestmentQuickModal
          showLoader={showLoader}
          isOpenQuickInvestmentModal={isOpenQuickInvestmentModal}
          openQuickInvestmentModal={openQuickInvestmentModal}
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          oneTimeInvest={oneTimeInvest}
        />
      )}

      {isOpenMinimumInvestmentModal && (
        <MinimumInvestmentModal
          errorMessage={errorMessage}
          openInvestmentModal={openInvestmentModal}
          isOpenMinimumInvestmentModal={isOpenMinimumInvestmentModal}
          openMinimumInvestmentModal={openMinimumInvestmentModal}
        />
      )}

      {isOpenInvestmentInfoModal &&
        investmentInfoModal(
          t,
          isOpenInvestmentInfoModal,
          openInvestmentInfoModal,
          oneTimeInvestmentValue,
          oneTimeInvest,
          showLoader,
          user,
        )}

      {isOpenInvestmentCongratsModal &&
        investmentCongratsModal(
          t,
          isOpenInvestmentCongratsModal,
          openInvestmentCongratsModal,
        )}
    </div>
  );
}

export default OneTimeInvestmentView;

OneTimeInvestmentView.propTypes = {
  user: PropTypes.shape({}).isRequired,
  errorMessage: PropTypes.string.isRequired,
  openQuickInvestmentModal: PropTypes.func.isRequired,
  openInvestmentInfoModal: PropTypes.func.isRequired,
  oneTimeInvest: PropTypes.func.isRequired,
  changeOneTimeInvestmentValue: PropTypes.func.isRequired,
  openMinimumInvestmentModal: PropTypes.func.isRequired,
  openInvestmentModal: PropTypes.func.isRequired,
  isOpenInvestmentCongratsModal: PropTypes.bool.isRequired,
  oneTimeInvestmentValue: PropTypes.number.isRequired,
  isOpenQuickInvestmentModal: PropTypes.bool.isRequired,
  isOpenMinimumInvestmentModal: PropTypes.bool.isRequired,
  openInvestmentCongratsModal: PropTypes.func.isRequired,
  isOpenInvestmentModal: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  showLoader: PropTypes.bool.isRequired,
  isOpenInvestmentInfoModal: PropTypes.bool.isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({}),
};

OneTimeInvestmentView.defaultProps = {
  isOpenInvestmentModal: false,
  history: {},
};
