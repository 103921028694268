import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useRecurringInvestmentForm } from '../useRecurringInvestmentForm';
import { useInvestmentGoalProgress } from '../hooks';

import {
  InvestmentGoalInfo,
  InvestmentGoalInfoAmount,
  InvestmentGoalSlider,
  InvestmentGoalPreviewWrapper,
  InvestmentPreviewTitle,
  InvestmentPreviewValue,
  InvestmentPreviewRowWrapper,
  NextInvestmentWrapper,
} from './styles';

import {
  DATE_FORMAT_MONTH_DAY_NUMBER_DAY_NAME,
  DATE_FORMAT_MONTH_DAY_NUMBER_YEAR,
} from 'config/date';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectChild,
  selectInvestmentGoal,
} from 'store/dependentUser/selectors';

const InvestmentGoalPreview = () => {
  const { t } = useTranslation();
  const { current_balance } = useAppSelector(selectChild);
  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { amount, nextInvestmentAt } = useRecurringInvestmentForm();

  const { target_amount, target_date } = investmentGoal || {};
  const investmentGoalProgress = useInvestmentGoalProgress();

  return (
    <InvestmentGoalPreviewWrapper>
      <InvestmentPreviewTitle>
        {t('recurringInvestmentPage.currentBalance')}
      </InvestmentPreviewTitle>

      <InvestmentGoalSlider
        value={investmentGoalProgress}
        handleLabel={`${investmentGoalProgress}%`}
        className="range-slider"
        tooltip={false}
      />

      <InvestmentGoalInfo>
        <InvestmentGoalInfoAmount>
          {formatNumber({ value: current_balance })}
        </InvestmentGoalInfoAmount>

        <InvestmentGoalInfoAmount>
          {formatNumber({ value: target_amount })}
        </InvestmentGoalInfoAmount>
      </InvestmentGoalInfo>

      <NextInvestmentWrapper>
        <InvestmentPreviewTitle>
          {t('recurringInvestmentPage.nextInvestmentLabel')}
        </InvestmentPreviewTitle>

        <InvestmentPreviewValue>
          {dayjs(nextInvestmentAt)
            .tz('Australia/Sydney')
            .format(DATE_FORMAT_MONTH_DAY_NUMBER_DAY_NAME)}
        </InvestmentPreviewValue>
      </NextInvestmentWrapper>

      <InvestmentPreviewRowWrapper>
        <InvestmentPreviewTitle>
          {t(
            'raizKidsEditPage.recurringInvestmentPreview.labels.investmentForRecurring',
          )}
        </InvestmentPreviewTitle>

        <InvestmentPreviewValue>
          {formatNumber({ value: amount })}
        </InvestmentPreviewValue>
      </InvestmentPreviewRowWrapper>

      <InvestmentPreviewRowWrapper>
        <InvestmentPreviewTitle>
          {t('recurringInvestmentPage.forecastGoalDate')}
        </InvestmentPreviewTitle>

        <InvestmentPreviewValue>
          {dayjs(target_date).format(DATE_FORMAT_MONTH_DAY_NUMBER_YEAR)}
        </InvestmentPreviewValue>
      </InvestmentPreviewRowWrapper>
    </InvestmentGoalPreviewWrapper>
  );
};

export default InvestmentGoalPreview;
