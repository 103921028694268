import styled from '@emotion/styled';

import validIcon from 'assets/images/icons/check_icon.svg';

export const Wrapper = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  margin: 20px 0;
`;

export const Item = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 40px;
`;

export const Label = styled.span<{ isActiveOrPreviousStep: boolean }>`
  color: ${({ isActiveOrPreviousStep, theme }) =>
    isActiveOrPreviousStep ? theme.colors.niagara : theme.colors.mirage};
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  opacity: ${({ isActiveOrPreviousStep, theme }) =>
    isActiveOrPreviousStep
      ? theme.opacity.opaque
      : theme.opacity.moderatelyTransparent};
`;

export const Indicators = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Dot = styled.div<{
  isPreviousStep: boolean;
  isActiveStep: boolean;
}>`
  align-items: center;
  background-color: ${({ isActiveStep, isPreviousStep, theme }) =>
    isActiveStep || isPreviousStep
      ? theme.colors.white
      : theme.colors.lightSilver};
  background-image: ${({ isPreviousStep }) =>
    isPreviousStep ? `url(${validIcon})` : 'unset'};
  background-position: center center;
  border: ${({ isActiveStep, theme }) =>
    isActiveStep ? `6px solid ${theme.colors.niagara}` : 'unset'};
  border-radius: 100%;
  display: flex;
  height: 18px;
  left: 50%;
  margin-left: -9px;
  position: absolute;
  width: 18px;
  z-index: 3;
`;

export const BeforeLine = styled.div<{ isActiveOrPreviousStep: boolean }>`
  background-color: ${({ isActiveOrPreviousStep, theme }) =>
    isActiveOrPreviousStep ? theme.colors.niagara : theme.colors.lightSilver};
  height: 2px;
  left: auto;
  position: absolute;
  right: 12px;
  width: 100vh;
`;

export const AfterLine = styled.div<{ isPreviousStep: boolean }>`
  background: ${({ isPreviousStep, theme }) =>
    isPreviousStep ? theme.colors.niagara : theme.colors.lightSilver};
  height: 2px;
  left: 12px;
  position: absolute;
  width: 100vh;
`;
