import { useTranslation } from 'react-i18next';

import { useProfilePage } from '../hooks/useProfilePage';

import ProfileTabSwitcher from './ProfileTabSwitcher';
import {
  ProfileHeaderContent,
  ProfileHeaderTitle,
  ProfileHeaderWrapper,
} from './styles';

export default function ProfileHeader() {
  const { t } = useTranslation();
  const { isUpdateProfilePage } = useProfilePage();

  return (
    <ProfileHeaderWrapper>
      <ProfileHeaderContent>
        <ProfileHeaderTitle>
          {isUpdateProfilePage
            ? t('settingsPage.profilePage.updateProfileTitle')
            : t('settingsPage.profilePage.title')}
        </ProfileHeaderTitle>

        <ProfileTabSwitcher />
      </ProfileHeaderContent>
    </ProfileHeaderWrapper>
  );
}
