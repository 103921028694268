import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface IContainerProps {
  isCentered?: boolean;
  isNonCursor?: boolean;
  isBig?: boolean;
}

interface IValueProps {
  isAutoRound?: boolean;
}

const customLabelSubSpan = css`
  span {
    display: block;
  }
`;

const customContainerItemSpan = css`
  .round-ups__label-sub span {
    display: block;
  }
`;

export const Wrapper = styled.div`
  background-image: ${({ theme }) => theme.gradients.lushMeadow};
  border-radius: 6px;
  padding: 20px 22px 25px;
  width: calc(50% - 10px);
`;

export const Content = styled.div`
  max-width: 320px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  line-height: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['2md']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tightest};
    line-height: ${theme.lineHeights.length['3md']};
    margin-bottom: 5px;
  `}
`;

export const Description = styled.div`
  ${({ theme }) => `
    opacity: 0.72;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['6sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    margin-bottom: 5px;
  `}
`;

export const Value = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['5md']};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.wide};
    line-height: ${theme.lineHeights.length.lg};
    margin-bottom: 10px;
  `}
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  margin: 40px auto 20px;

  ${({ isCentered }) =>
    isCentered &&
    `
      justify-content: center;
    `}
`;

export const ContainerItem = styled.button<IContainerProps>`
  ${({ theme }) => `
    width: 115px;
    height: 109px;
    background-color: unset;
    color: ${theme.colors.white};

    &:active, &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  `}

  ${({ isNonCursor }) =>
    isNonCursor &&
    `
      &:hover {
        cursor: default;
      }
    `}

  ${({ isBig }) =>
    isBig &&
    `
      width: 125px;
      height: 120px;

      ${customContainerItemSpan}
    `}
`;

export const Label = styled.div`
  ${({ theme }) => `
    opacity: 0.72;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.length['3sm']};
    letter-spacing: ${theme.letterSpacing.tighter};
    font-family: ${theme.fontFamily.textRegular};
    margin-bottom: 6px;
  `}
`;

export const ValueSubtitle = styled.div<IValueProps>`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['3md']};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.wide};

    &.-narrow {
      margin-bottom: 0;
    }
  `}

  ${({ isAutoRound }) =>
    isAutoRound &&
    `
      justify-content: center;
    `}
`;

export const LabelSub = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['3sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tight};
    padding: 0 4px;
  `}
  ${customLabelSubSpan}
`;
