import React from 'react';

import { HOME_TABS } from '../../../constants/comonConstants';

import HomeTabItem from './HomeTabItem';

export default function HomeTabs() {
  return (
    <div className="home__switcher">
      {Object.keys(HOME_TABS).map((tab) => (
        <HomeTabItem tab={tab} key={`home-switcher-item-${tab}`} />
      ))}
    </div>
  );
}
