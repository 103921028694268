import React from 'react';
import { useTranslation } from 'react-i18next';

import { AllocationWrapper, HeaderTypeLabel, Header } from './styles';
import { RewardItem } from './RewardItem';

import { ITransactionDetails } from 'store/history/types';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectTransactionDetails } from 'store/history/selectors';

export const Reward = () => {
  const { details }: ITransactionDetails = useAppSelector(
    selectTransactionDetails,
  );

  const { t } = useTranslation();

  if (!details?.length) {
    return null;
  }

  return (
    <AllocationWrapper>
      <Header>
        <HeaderTypeLabel>
          {t('historyDetailsPage.rewardTransaction.type')}
        </HeaderTypeLabel>

        <HeaderTypeLabel>
          {t('historyDetailsPage.rewardTransaction.amount')}
        </HeaderTypeLabel>
      </Header>

      {details?.map((rewardItem) => (
        <RewardItem key={rewardItem.id} rewardItem={rewardItem} />
      ))}
    </AllocationWrapper>
  );
};
