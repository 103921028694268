import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { checkIsDependentUser } from '../../../../utils/user';
import { MY_APP } from '../../../../constants/localeConfigs';

import SubFundingAccount from './components/SubFundingAccount';
import { ConfirmButton } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBankAccountData } from 'store/fundingAccount/selectors';
import { useInstitutionByIdQuery } from 'store/fundingAccount/fundingAccount';

export default function SubAccounts({
  monitoredInstitutions,
  handleSubAccount,
  errorMessage,
  handleCloseModalError,
  isShowModalError,
  showBanksFromSubAccount,
  isSpending,
  deleteAccount,
  isShowConfirmation,
  fixAccount,
  isShowConnect,
  connectAccount,
  showConfirmModal,
  isFundingErrorVisible,
  user,
  isUnlinkCard,
  cardNumberSuffix,
}) {
  const [currentBank, setCurrentBank] = useState('');
  const { t } = useTranslation();

  const bankAccountData = useAppSelector(selectBankAccountData);

  const params = {
    ...bankAccountData,
    additionalParams: {
      funding_source: 1,
    },
  };

  const { data } = useInstitutionByIdQuery(
    {
      ...bankAccountData,
      additionalParams: {
        funding_source: 1,
      },
    },
    {
      skip: !params?.id || isSpending,
    },
  );

  const currentMonitoredInstitutions = data
    ? [data?.monitored_institution]
    : monitoredInstitutions;

  return (
    <div className="settings__funding-account">
      {errorMessage && !isFundingErrorVisible && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowModalError}
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            <div className="alert-title">
              {t('settingsPage.linkFundingAccount')}
            </div>

            <div className="alert-text">{errorMessage}</div>

            <div className="error-buttons">
              <DeprecatedButton
                title={t('common.ok')}
                type="button"
                handleClick={handleCloseModalError}
              />
            </div>
          </div>
        </Modal>
      )}

      {isShowConfirmation && !isUnlinkCard && !cardNumberSuffix && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowModalError}
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            <div className="alert-title">
              {t('settingsPage.spendingAccountPage.unlinkModal.title')}
            </div>

            <div className="alert-text">
              {MY_APP
                ? t('settingsPage.unlinkConfirmationWithName', {
                    bankName: currentBank,
                  })
                : t('settingsPage.subAccounts.unlinkConfirmation')}
            </div>

            <div className="alert-buttons">
              <DeprecatedButton
                title={t('common.cancel')}
                type="button"
                buttonClass="wait"
                handleClick={handleCloseModalError}
              />

              <DeprecatedButton
                title={t('common.unlink')}
                type="button"
                buttonClass="skip"
                handleClick={(e) => deleteAccount(e)}
              />
            </div>
          </div>
        </Modal>
      )}

      {currentMonitoredInstitutions.map((account) => (
        <SubFundingAccount
          key={account.id}
          account={account}
          handleSubAccount={handleSubAccount}
          isSpending={isSpending}
          deleteAccount={deleteAccount}
          fixAccount={fixAccount}
          isShowConnect={isShowConnect}
          showConfirmModal={showConfirmModal}
          setCurrentBank={setCurrentBank}
        />
      ))}

      {isSpending && !isShowConnect && (
        <ConfirmButton
          label={t('settingsPage.subAccounts.addMoreSpendingAccount')}
          onClick={showBanksFromSubAccount}
        />
      )}

      {isShowConnect && (
        <ConfirmButton label={t('common.connect')} onClick={connectAccount} />
      )}

      {!isSpending && !isShowConnect && !checkIsDependentUser(user) && (
        <ConfirmButton
          label={t('settingsPage.spendingAccountPage.useDifferentBankButton')}
          onClick={showBanksFromSubAccount}
        />
      )}
    </div>
  );
}

SubAccounts.defaultProps = {
  monitoredInstitutions: [],
  isSpending: false,
  deleteAccount() {},
  fixAccount() {},
  user: {},
  isShowConfirmation: false,
  showConfirmModal() {},
  isFundingErrorVisible: false,
  isUnlinkCard: false,
  cardNumberSuffix: '',
};

SubAccounts.propTypes = {
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    user_type: PropTypes.string,
  }),
  handleSubAccount: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  showBanksFromSubAccount: PropTypes.func.isRequired,
  isSpending: PropTypes.bool,
  deleteAccount: PropTypes.func,
  isShowConfirmation: PropTypes.bool,
  fixAccount: PropTypes.func,
  isShowConnect: PropTypes.bool.isRequired,
  connectAccount: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func,
  isFundingErrorVisible: PropTypes.bool,
  isUnlinkCard: PropTypes.bool,
  cardNumberSuffix: PropTypes.string,
};
