import styled from '@emotion/styled';
import Chart from 'react-apexcharts';

import { Button } from 'components/elements/Button';
import { GlobalInfoModalTitle } from 'components/modals/InfoModal/styles';
import { BaseModalWrapper } from 'components/elements/ModalWrapper';

export const Wrapper = styled(BaseModalWrapper)`
  margin: 60px auto;
  flex-direction: row;
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  background: ${({ theme }) => theme.gradients.purpleBlue};
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 40px 40px;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled(GlobalInfoModalTitle)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-top: 34px;
`;

export const ModalChart = styled(Chart)`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NameWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  text-transform: capitalize;
`;

export const Descriptions = styled.div`
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  opacity: ${({ theme }) => theme.opacity.semiTransparent};
  text-align: center;
  margin-top: 26px;
`;

export const AgreeButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-top: 26px;
`;

export const ChangeButton = styled(AgreeButton)`
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  margin-top: 26px;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
`;
