import React from 'react';
import { createSearchParams, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AU_APP } from '../../../constants/localeConfigs';
import { OFFER_SOURCES, OFFER_TYPES, REWARDS_SEARCH_PARAMS } from '../consts';
import { filterRoundUpAccountsForRewards } from '../utils/filterRoundUpAccountsForRewards';

import { PATHS } from 'constants/paths';
import { useInstitutionsQuery } from 'store/institutions';

export default function RewardsMenuLinks({ checkToggle, isRewardsActivePath }) {
  const { t } = useTranslation();

  const { cards } = useSelector((state) => ({
    cards: state.rewards.cards,
  }));

  const { data } = useInstitutionsQuery();

  const filteredAccounts = data?.monitored_institutions?.filter(
    filterRoundUpAccountsForRewards,
  );

  const linkedPageSearchParams = createSearchParams({
    [REWARDS_SEARCH_PARAMS.tab]: OFFER_SOURCES.card,
  }).toString();

  const rewardsSearchParams = createSearchParams({
    [REWARDS_SEARCH_PARAMS.offerType]: OFFER_TYPES.online,
  }).toString();

  return (
    <div>
      <NavLink
        to={`${PATHS.rewards.index}?${rewardsSearchParams}`}
        className={`page-menu__item ${
          !isRewardsActivePath('/rewards') ? 'inactive' : ''
        }`}
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -rewards-sub" />

        <span>{t('rewardsPage.menu.raizRewards')}</span>
      </NavLink>

      <NavLink
        to="/rewards/history"
        className="page-menu__item"
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -rewards-history" />

        <span>{t('rewardsPage.menu.rewardsHistoryMenu')}</span>
      </NavLink>

      {AU_APP && (
        <div>
          <NavLink
            to={`${PATHS.rewards.linked}?${linkedPageSearchParams}`}
            className="page-menu__item"
            onClick={checkToggle}
          >
            <i className="page-menu__item-icon -rewards-cards" />

            {t('rewardsPage.menu.linkedPaymentMethods')}

            <div className="page-menu__item-cards-count count-spacing">
              {(cards?.cards?.length ?? 0) + (filteredAccounts?.length ?? 0)}
            </div>
          </NavLink>

          <NavLink
            to="/rewards/in-store"
            className="page-menu__item"
            onClick={checkToggle}
          >
            <i className="page-menu__item-icon -rewards-instore" />

            {t('rewardsPage.menu.inStoreRewards')}
          </NavLink>
        </div>
      )}
    </div>
  );
}

RewardsMenuLinks.propTypes = {
  checkToggle: PropTypes.func.isRequired,
  isRewardsActivePath: PropTypes.func.isRequired,
};
