import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getPerformanceChartConfig from './utils/getPerformanceChartConfig';

import { getSecurities } from 'store/perfomance/actions';
import {
  selectIsPerformanceLoading,
  selectPerformanceChartData,
} from 'store/perfomance/selectors';

interface IUsePerformanceChartDataProps {
  id: string;
  numberOfDays: number;
  performanceChartWidth?: number;
}

export const usePerformanceChartData = ({
  id,
  performanceChartWidth,
  numberOfDays,
}: IUsePerformanceChartDataProps) => {
  const dispatch = useDispatch();

  const { chartData, isLoading } = useSelector((state) => ({
    chartData: selectPerformanceChartData(state),
    isLoading: selectIsPerformanceLoading(state),
  }));

  const performanceChartConfig = getPerformanceChartConfig();

  useEffect(() => {
    if (id && performanceChartWidth) {
      dispatch(
        getSecurities({
          securitiesId: id,
          numberOfDays,
          maxPoints: performanceChartWidth,
        }),
      );
    }
  }, [dispatch, id, performanceChartWidth, numberOfDays]);

  return {
    chartData,
    performanceChartConfig,
    isLoading,
  };
};
