import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function NotFoundView({ goBack, goHome }) {
  const { t } = useTranslation();

  return (
    <div className="not-found-content__info">
      <div className="logo" />

      <div className="not-found-content__container">
        <div className="not-found-content__page" />

        <div className="not-found-content__container-col">
          <div className="not-found-content__title">
            {t('notFoundPage.oops')}
          </div>

          <div className="not-found-content__info">
            <Trans i18nKey="notFoundPage.embarrassed" t={t} />
          </div>

          <div className="not-found-content__footer">
            <DeprecatedButton
              title={t('notFoundPage.backButton')}
              buttonClass="button__basic-transparent -white"
              handleClick={() => goBack()}
            />

            <DeprecatedButton
              title={t('notFoundPage.homeButton')}
              buttonClass="button__basic-fill -white"
              handleClick={() => goHome()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundView;

NotFoundView.propTypes = {
  goBack: PropTypes.func.isRequired,
  goHome: PropTypes.func.isRequired,
};
