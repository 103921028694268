import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PERIOD_DAYS_NUMBER } from '../consts';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import { MY_APP } from '../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function PerfomanceSwitcherItem({
  numberOfDay,
  toggleShow,
  isActive,
  numberOfDayName,
  isEtf,
}) {
  const { t } = useTranslation();
  const isAllTabActive = isEtf && !numberOfDay;

  const isOneWeekTabActive =
    !isEtf && numberOfDay === PERIOD_DAYS_NUMBER.oneWeek;

  const isOneDayTabActive = MY_APP && numberOfDay === PERIOD_DAYS_NUMBER.oneDay;

  if (isOneDayTabActive || isAllTabActive || isOneWeekTabActive) {
    return null;
  }

  return (
    <div
      className={
        isActive ? 'home__switcher-item -active' : 'home__switcher-item'
      }
    >
      <DeprecatedButton
        title={t(`perfomanceDetails.perfomanceSwitcher.${numberOfDayName}`)}
        handleClick={() => toggleShow(numberOfDay)}
      />
    </div>
  );
}

PerfomanceSwitcherItem.propTypes = {
  numberOfDay: PropTypes.number.isRequired,
  toggleShow: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  numberOfDayName: PropTypes.string.isRequired,
  isEtf: PropTypes.bool.isRequired,
};
