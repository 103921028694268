import styled from '@emotion/styled';

import { CARD_WIDTH } from './consts';

import iconEmpty from 'assets/images/icons/ic_empty.svg';
import percentMinusDisabled from 'assets/images/icons/percent-minus-disabled.svg';
import percentMinus from 'assets/images/icons/percent-minus.svg';
import percentPlusDisabled from 'assets/images/icons/percent-plus-disabled.svg';
import percentPlus from 'assets/images/icons/percent-plus.svg';
import filterListIcon from 'assets/images/icons/ic_filter_list.svg';
import { Button } from 'components/elements/Button';
import { Icon } from 'components/elements/Icon';

const FUND_ITEM_HEIGHT = 205;
const FUND_SUPER_ITEM_HEIGHT = 236;

export const FundsListWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.primary};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ isFooterVisible }) =>
    isFooterVisible ? '10px 10px 0 0' : '10px'};
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 8px 8px 8px;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    justify-content: center;
  }
  position: relative;
`;

export const FundsListHeader = styled.div`
  width: 100%;
  text-align: left;
  margin: 4px 0 8px;
`;

export const FilterButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: url(${filterListIcon}) ${({ theme }) => theme.colors.wildSand}
    no-repeat 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.mirage};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  text-align: right;
  margin-right: 8px;
  padding: 7px 10px 7px 40px;
  height: auto;
  float: left;
`;

export const FiltersCount = styled.span`
  margin-left: 10px;
  background: ${({ theme }) => theme.colors.niagara};
  border-radius: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const FundItemWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.mercury};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 5px;
  flex: 1;
  height: ${({ isSuper }) =>
    isSuper ? FUND_SUPER_ITEM_HEIGHT : FUND_ITEM_HEIGHT}px;
  position: relative;
`;

export const FundItemHeaderWrapper = styled.div`
  flex: 1;
`;

export const FundItemImage = styled.div`
  padding: 45px 0 45px 0;
  background: url(${({ background }) => background}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

export const FundItemTitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 12px 16px 12px 16px;
  text-align: left;
  height: 66px;
  cursor: pointer;
`;

export const FundItemSuperTitleName = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

export const FundItemSuperTitleWrapper = styled(FundItemTitleWrapper)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: default;
`;

export const FundItemBottomWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: left;
  padding: 0 14px 0 14px;
  display: inline-flex;
  line-height: ${({ theme }) => theme.lineHeights.length['2xl']};
`;

export const FundItemSymbol = styled.div`
  padding-right: 8px;
`;

export const FundItemVerticalLine = styled.div`
  width: 1px;
  height: 15px;
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.silver};
`;

export const FundItemAmount = styled.div`
  padding: 0 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const FundItemYearToDateChanges = styled(FundItemAmount)`
  color: ${({ theme, isNotProfitable }) =>
    isNotProfitable ? theme.colors.grenadier : theme.colors.niagara};
  padding-right: 4px;
`;

export const FundItemPeriod = styled.div`
  padding-right: 8px;
`;

export const ChangeEtfWrapper = styled.div`
  display: inline-flex;
  span {
    line-height: ${({ theme }) => theme.lineHeights.length['2xl']};
    padding: 0 6px 0 6px;
    user-select: none;
  }
  align-items: center;
`;

export const DecreaseButton = styled.button`
  background: url(${percentMinus}) no-repeat;
  padding: 16px;
  float: left;
  cursor: pointer;
  height: 32px;
  :disabled {
    background: url(${percentMinusDisabled}) no-repeat;
    opacity: 1;
  }
`;

export const IncreaseButton = styled.button`
  background: url(${percentPlus}) no-repeat;
  padding: 16px;
  float: left;
  cursor: pointer;
  height: 32px;
  :disabled {
    background: url(${percentPlusDisabled}) no-repeat;
    opacity: 1;
  }
`;

export const AllocationInputWrapper = styled.div`
  width: 60px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  background-color: ${({ theme }) => theme.colors.mercury};
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const AllocationInput = styled.input`
  width: 70%;
  height: 32px;
  appearance: textfield;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  background-color: ${({ theme }) => theme.colors.mercury};
  padding-top: unset;
  padding-bottom: unset;
  border: unset;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: center;
`;

export const CustomizationFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 0 0 10px 10px;
  padding: 18px 32px 18px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  position: sticky;
  bottom: 0;
`;

export const CustomizationFooterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.niagara};
  color: ${({ theme }) => theme.colors.white};
  width: 330px;
`;

export const EmptyFundListWrapper = styled(FundsListWrapper)`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0;
`;

export const EmptyFundListButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.niagara};
  color: ${({ theme }) => theme.colors.white};
  width: 330px;
  margin-top: 10px;
`;

export const EmptyFilteredFundListIcon = styled.div`
  margin: 12px auto;
  background-image: url(${iconEmpty});
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.athensGray};
  width: 70px;
  height: 70px;
`;

export const FundsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(${CARD_WIDTH.min}, ${CARD_WIDTH.max}), 1fr)
  );
  gap: 8px;
`;

export const FundItemCategory = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
`;

export const FundsListSearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
    padding-top: 6px;
    justify-content: center;
  }
`;

export const FundsListSearchIcon = styled(Icon)`
  position: relative;
  top: 12px;
  left: 32px;
`;

export const FundsListSearchField = styled.input`
  width: 309px;
  height: 40px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.athensGray};
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
  padding: 0 40px;
  border-bottom: 0;
`;

export const ItemBottomWrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: center;
`;

export const FundTotalWeightingWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['2xl']};
  display: flex;
  padding: 0 14px 0 14px;
  justify-content: space-between;
  margin-top: 6px;
`;

export const MaxWeightingLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.veryDarkGrey};
`;
