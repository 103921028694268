import dayjs from 'dayjs';
import { createSelector } from '@reduxjs/toolkit';
import { t } from 'i18next';

import {
  DATE_FORMAT_MONTH_DOT_DAY_COMMA_YEAR,
  DATE_FORMAT_WITH_DASHES_SHORT_MONTH,
  DATE_FORMAT_WITH_SHORT_MONTH,
} from 'config/date';
import { APP_DATE_TIME_ZONE, AU_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

const selectPerformancePrices = (state) =>
  state?.perfomance?.securitiesPrices?.prices || [];

const selectChartDataPresent = (state) =>
  state?.perfomance?.present?.graph_data || [];

export const selectIsPerformanceLoading = (state) =>
  state?.perfomance?.isLoading;

export const selectPerformanceChartData = createSelector(
  [selectPerformancePrices],
  (prices) => {
    if (!prices.length) {
      return [];
    }

    const chartData = prices?.map((balance) => {
      const { amount, date } = balance;

      const formattedBalance = formatNumber({
        value: amount,
      });

      const formattedDate = dayjs
        .utc(date)
        .tz(APP_DATE_TIME_ZONE)
        .format(DATE_FORMAT_MONTH_DOT_DAY_COMMA_YEAR);

      return {
        y: amount,
        x: formattedDate,
        z: formattedBalance,
      };
    });

    return [
      {
        data: chartData,
      },
    ];
  },
);

export const selectPerformanceMarketChanges = (state) =>
  state.perfomance?.securitiesPrices?.gain || {};

export function selectPeriodMarketChange(state) {
  const periodMarketChange = AU_APP
    ? state?.perfomance?.summary?.period_market_change
    : state?.perfomance?.summary?.period_market_change_v2;

  return formatNumber({
    value: periodMarketChange || 0,
    minimumFractionDigits: 2,
    isWithSign: true,
  });
}

export const selectPerformanceSummary = (state) =>
  state.perfomance?.summary?.summary || [];

export const selectIsPerformanceDashboard = (state) =>
  state.perfomance?.isDashboard;

export const selectSummaryByIndex = (index) =>
  createSelector(
    [selectPerformanceSummary],
    (summary) => summary?.at(index) || {},
  );

export const selectPerformanceBalanceByIndex = ({
  index,
  isEtf,
  isOneDayChart,
}) =>
  createSelector(
    [selectPerformanceSummary, selectPerformancePrices, selectChartDataPresent],
    (summary, etfPrices, present) => {
      if (isOneDayChart) {
        return present?.at(index)?.amount || 0;
      }

      if (isEtf) {
        return etfPrices?.at(index)?.amount || 0;
      }

      return summary?.at(index)?.balance || 0;
    },
  );

export const selectChartData = (isCreditDebitDiff = false) =>
  createSelector(
    [selectPerformanceSummary, selectIsPerformanceDashboard],
    (summary, IsPerformanceDashboard) => {
      const chartData = summary?.map((data) => {
        const { credit_debit_diff, balance, date, date_dividends } = data;
        const amount = isCreditDebitDiff ? credit_debit_diff : balance;

        const isDividendTooltipVisible =
          IsPerformanceDashboard && date_dividends;

        const dividendTooltip = isDividendTooltipVisible
          ? t('performanceDetailsPage.dividendTooltip', {
              date: dayjs(balance.date).format(
                DATE_FORMAT_WITH_DASHES_SHORT_MONTH,
              ),
              amount: formatNumber({
                value: date_dividends,
              }),
            })
          : '';

        const formattedAmount = formatNumber({
          value: amount || 0,
        });

        return {
          y: Math.floor(amount),
          x:
            dividendTooltip || dayjs(date).format(DATE_FORMAT_WITH_SHORT_MONTH),
          z: formattedAmount,
        };
      });

      return chartData;
    },
  );
