import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';

import { api } from 'services/api';

export type IAxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    baseURL?: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
>;

export const axiosBaseQuery =
  (): IAxiosBaseQuery =>
  async ({
    url,
    method = 'GET',
    data,
    params,
    baseURL = process.env.REACT_APP_API_URL,
    headers,
  }) => {
    try {
      const result = await api({
        url: baseURL + url,
        method,
        data,
        params,
        headers,
      });

      return { data: result };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
      };
    }
  };

export const baseQuery = axiosBaseQuery();
