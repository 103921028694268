import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Skeleton from 'react-skeleton-loader';
import { useTranslation } from 'react-i18next';

import { REQUIRED } from '../../../constants/errors';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

// for old browser
Object.sizeKeys = (obj) => {
  let size = 0;

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) size += 1;
  });

  return size;
};

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.pin) {
      errors.pin = REQUIRED;
    }

    if (!values.pinConfirmation) {
      errors.pinConfirmation = REQUIRED;
    }

    if (
      values.pin &&
      values.pinConfirmation &&
      values.pin !== values.pinConfirmation
    ) {
      errors.pin = t('resetPinPage.fields.validation.pinMatch');

      errors.pinConfirmation = t('resetPinPage.fields.validation.pinMatch');
    }

    if (!values.securityAnswer) {
      errors.securityAnswer = REQUIRED;
    }

    return errors;
  };

  return { validate };
};

export default function ResetPinForm({
  handleSubmit,
  errorMessage,
  showLoader,
  toggleShowPassword,
  isShowPassword,
  toggleShowConfirmPassword,
  isShowConfirmPassword,
  questionText,
  showGlobalLoader,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();
  const errorCount = Object.sizeKeys(errorMessage);
  let isShowTokenError = false;
  let isShowSecurityAnswerError = false;

  if (errorMessage && errorMessage.reset_pin_token) {
    isShowTokenError = true;
  }

  if (errorMessage && errorMessage.security_answer) {
    isShowSecurityAnswerError = true;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className={`form -new-password ${
            Object.keys(errorMessage).length ? '-error-form' : ''
          }`}
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          {showGlobalLoader && (
            <div className="skeleton-content">
              <Skeleton />

              <Skeleton />

              <Skeleton />
            </div>
          )}

          {!showGlobalLoader && (
            <div>
              <FinalFormInput
                name="pin"
                defaultValue={values.pin}
                showPassword={toggleShowPassword}
                label={t('resetPinPage.fields.newPinCode.label')}
                type={isShowPassword ? 'password' : 'text'}
                isShowPasswordToggle
                maxLength="4"
                isNumberOnly
              />

              <FinalFormInput
                name="pinConfirmation"
                defaultValue={values.pinConfirmation}
                showPassword={toggleShowConfirmPassword}
                label={t('resetPinPage.fields.confirmNewPincode.label')}
                type={isShowConfirmPassword ? 'password' : 'text'}
                isShowPasswordToggle
                maxLength="4"
                isNumberOnly
              />

              <FinalFormInput
                name="securityAnswer"
                defaultValue={values.securityAnswer}
                label={
                  questionText !== ''
                    ? questionText
                    : t('resetPinPage.fields.securityQuestionAnswer.label')
                }
              />
            </div>
          )}

          <div className="login-page__help reset-pin-rule">
            {t('resetPinPage.description')}
          </div>

          <div className="login-error__message">
            {isShowTokenError &&
              t('resetPinPage.fields.validation.expiredToken')}

            {isShowSecurityAnswerError &&
              t('resetPinPage.fields.validation.securityQuestion')}

            {!isShowTokenError &&
              !isShowSecurityAnswerError &&
              errorCount !== 0 &&
              Object.values(errorMessage)[0].map((msg) => (
                <div key={msg}>
                  {msg}

                  <br />
                </div>
              ))}
          </div>

          <div className="form-field">
            <DeprecatedButton
              withLoader={showLoader}
              type="submit"
              title={t('resetPinPage.resetNowButton')}
              buttonClass="button__basic-fill -green -size-l -wide"
            />
          </div>
        </form>
      )}
    />
  );
}

ResetPinForm.defaultProps = {
  handleSubmit: () => {},
};

ResetPinForm.propTypes = {
  errorMessage: PropTypes.objectOf(PropTypes.string).isRequired,
  loginData: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func,
  toggleShowPassword: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  isShowConfirmPassword: PropTypes.bool.isRequired,
  toggleShowConfirmPassword: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  showGlobalLoader: PropTypes.bool.isRequired,
};
