import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const HistoryDateItem = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.athensGray};
    opacity: ${theme.opacity.mediumTransparent};
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tight};
  `}
  padding: 4px 20px;
`;

export const SeeMoreButton = styled(Button)`
  width: 330px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 14px 18px 14px -165px;
`;
