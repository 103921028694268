import 'styles/pages/my-finance.scss';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import Time from 'components/elements/Time/Time';
import { CREDIT } from 'constants/comonConstants';
import FallbackMessage from 'components/elements/FallbackMessage/FallbackMessage';
import { formatNumber } from 'utils/formatters/formatNumber';
import { HistoryDateItem } from 'pages/MyFinance/components/styles';
import { DATE_FORMAT_SHORT_MONTH_DAY_YEAR } from 'config/date';

const renderTransaction = (upcomingTransaction, index) => (
  <div key={index}>
    <HistoryDateItem>
      <Time format={DATE_FORMAT_SHORT_MONTH_DAY_YEAR}>
        {upcomingTransaction.date}
      </Time>
    </HistoryDateItem>

    {upcomingTransaction.transactions.map((transaction) => (
      <div
        className="my-finance__categorisation-item my-finance__categorisation-row"
        key={transaction.account_key}
      >
        <div className="my-finance__categorisation-name">
          {transaction.description}
        </div>

        <div
          className={`my-finance__categorisation-value ${
            transaction.movement === CREDIT ? '-pos' : '-neg'
          }`}
        >
          {formatNumber({
            value: transaction.amount,
          })}
        </div>
      </div>
    ))}
  </div>
);

function PotentialFreeCashView(props) {
  const { t } = useTranslation();
  const { options, series, upcomingTransactions } = props;

  return (
    <div>
      <div className="my-finance__banner page-content__banner -without-lines -without-btm-padding">
        <div className="page-content__banner-title">
          {t('potentialFreeCashPage.bannerTitle')}
        </div>

        <Chart options={options} series={series} type="area" height={260} />

        <div className="my-finance__banner-bottom-title">
          {t('potentialFreeCashPage.assumedTransactions')}
        </div>
      </div>

      <div className="info-panel">
        <div className="info-panel__title">
          {t('potentialFreeCashPage.infoPanelTitle')}
        </div>

        {upcomingTransactions.length ? (
          upcomingTransactions.map((upcomingTransaction, index) =>
            renderTransaction(upcomingTransaction, index),
          )
        ) : (
          <FallbackMessage
            text={t('potentialFreeCashPage.noPotentialSpareCash')}
          />
        )}
      </div>
    </div>
  );
}

export default PotentialFreeCashView;

PotentialFreeCashView.defaultProps = {};

PotentialFreeCashView.propTypes = {
  options: PropTypes.shape({}).isRequired,
  upcomingTransactions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]).isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
