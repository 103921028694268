import { API_TAGS } from '../consts';

import transformSuitabilityResponse from './utils/transformSuitabilityResponse';

import { IQuestion } from 'pages/Settings/components/Forms/types';
import { api } from 'store/api';

export const ROUND_UPS_BASE_URL = '/v1/suitability';

export const suitabilityApi = api.injectEndpoints({
  endpoints: (build) => ({
    suitability: build.query<IQuestion[], void>({
      query: () => ({
        url: ROUND_UPS_BASE_URL,
      }),
      transformResponse: (response: IQuestion[]) =>
        transformSuitabilityResponse(response),
      providesTags: [API_TAGS.suitability],
    }),
  }),
  overrideExisting: true,
});
export const { useSuitabilityQuery } = suitabilityApi;

export const {
  endpoints: { suitability },
} = suitabilityApi;
