import React from 'react';
import Skeleton from 'react-skeleton-loader';

import {
  FundItemSymbol,
  FundItemWrapper,
  FundItemBottomWrapper,
} from '../styles';

import {
  CustomizationContentItemImageSkeleton,
  CustomizationContentItemTitleSkeleton,
} from './styles';

function SkeletonItem() {
  return (
    <FundItemWrapper>
      <CustomizationContentItemImageSkeleton>
        <Skeleton />
      </CustomizationContentItemImageSkeleton>

      <CustomizationContentItemTitleSkeleton>
        <Skeleton />
      </CustomizationContentItemTitleSkeleton>

      <FundItemBottomWrapper>
        <FundItemSymbol>
          <Skeleton />
        </FundItemSymbol>

        <FundItemSymbol>
          <Skeleton />
        </FundItemSymbol>
      </FundItemBottomWrapper>
    </FundItemWrapper>
  );
}

export default SkeletonItem;
