import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  usePortfolioCustomizationRoute,
  usePortfolioFunds,
} from '../../../hooks';

import { getFundDetails, getFundStatistics } from 'store/portfolio/actions';
import {
  selectFundDetails,
  selectFundIdByTag,
  selectFundStatistics,
  selectIsFundDetailsLoading,
  selectIsParentProPortfolioPreview,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const useFundId = () => {
  usePortfolioFunds();

  const queryParams = useParams();
  const { fundTag } = usePortfolioCustomizationRoute();

  const fundIdByTag = useAppSelector(selectFundIdByTag({ fundTag }));

  return queryParams?.id || fundIdByTag;
};

export const useFundDetails = () => {
  const fundId = useFundId();
  const dispatch = useAppDispatch();
  const fundDetails = useAppSelector(selectFundDetails);
  const isLoading = useAppSelector(selectIsFundDetailsLoading);

  const isParentProPortfolioPreview = useAppSelector(
    selectIsParentProPortfolioPreview,
  );

  const { childId, isSuper, isParamsLoaded } = useAppSelector(
    selectPortfolioParams,
  );

  useEffect(() => {
    if (!fundId) return;

    if (isParamsLoaded) {
      if (childId && isParentProPortfolioPreview) {
        // If in Kids mode and without crated pro portfolio
        // display parent pro portfolio allocation template as an example for children
        dispatch(getFundDetails({ fundId }));

        return;
      }

      dispatch(getFundDetails({ fundId }));
    }
  }, [
    childId,
    dispatch,
    fundId,
    isParentProPortfolioPreview,
    isSuper,
    isParamsLoaded,
  ]);

  return { fundDetails, isLoading, fundId };
};

export const useFundStatistics = () => {
  const dispatch = useAppDispatch();
  const statistics = useAppSelector(selectFundStatistics);
  const { isParamsLoaded } = useAppSelector(selectPortfolioParams);

  const id = useFundId();

  useEffect(() => {
    if (id && isParamsLoaded) {
      dispatch(getFundStatistics({ id }));
    }
  }, [dispatch, id, isParamsLoaded]);

  return { statistics };
};
