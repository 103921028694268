import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function SuperHistoryBannerLabelInfo({
  toggleInfoModal,
  subLabel,
  recurringModalTitle,
  recurringModalMsg,
}) {
  const { t } = useTranslation();

  return (
    <div className="page-content__banner-label-sub">
      {subLabel}

      <DeprecatedButton
        title={t('common.space')}
        buttonClass="page-content__banner-label-info -grey"
        handleClick={() => {
          toggleInfoModal({
            shouldOpenRecurringModal: true,
            title: recurringModalTitle,
            msg: recurringModalMsg,
          });
        }}
      />
    </div>
  );
}

SuperHistoryBannerLabelInfo.propTypes = {
  toggleInfoModal: PropTypes.func.isRequired,
  subLabel: PropTypes.string.isRequired,
  recurringModalTitle: PropTypes.string.isRequired,
  recurringModalMsg: PropTypes.string.isRequired,
};
