import { PERIOD_DAYS_COUNT } from '../consts';

import NumberOfDaysSwitcherItem from './NumberOfDaysSwitcherItem';
import { SwitcherWrapper } from './styles';

import { Entries } from 'types/utils';

interface INumberOfDaysSwitcherProps {
  numberOfDays: number;
  setNumberOfDays: (numberOfDays: number) => void;
}

export default function NumberOfDaysSwitcher({
  numberOfDays,
  setNumberOfDays,
}: INumberOfDaysSwitcherProps) {
  return (
    <SwitcherWrapper>
      {(
        Object.entries(PERIOD_DAYS_COUNT) as Entries<typeof PERIOD_DAYS_COUNT>
      ).map(([periodKey, periodValue]) => (
        <NumberOfDaysSwitcherItem
          periodValue={periodValue}
          periodKey={periodKey}
          key={periodKey}
          isActive={numberOfDays === periodValue}
          setNumberOfDays={setNumberOfDays}
        />
      ))}
    </SwitcherWrapper>
  );
}
