import {
  GET_REFERRALS_SUMMARY_ERROR,
  GET_REFERRALS_SUMMARY_LOADING,
  GET_REFERRALS_SUMMARY_SUCCESS,
  GET_REFERRALS_LIST_ERROR,
  GET_REFERRALS_LIST_LOADING,
  GET_REFERRALS_LIST_SUCCESS,
  RESET_REFERRALS,
  RESET_REFERRALS_SUMMARY,
  RESET_REFERRALS_LIST,
  INITIAL,
  ERROR,
  SUCCESS,
  LOADING,
} from './consts';
import { IReferralsReducer } from './types';

const initialState = {
  summary: INITIAL,
  list: INITIAL,
};

const referrals: IReferralsReducer = (
  state = initialState, // eslint-disable-line
  action,
) => {
  switch (action.type) {
    case GET_REFERRALS_SUMMARY_ERROR:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...ERROR,
          data: null,
          error: action.error,
        },
      };
    case GET_REFERRALS_SUMMARY_LOADING:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...LOADING,
          data: null,
          error: null,
        },
      };
    case GET_REFERRALS_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...SUCCESS,
          data: action.data,
          error: null,
        },
      };
    case GET_REFERRALS_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          ...ERROR,
          data: null,
          error: action.error,
        },
      };
    case GET_REFERRALS_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          ...LOADING,
          data: null,
          error: null,
        },
      };
    case GET_REFERRALS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          ...SUCCESS,
          data: action.data,
          error: null,
        },
      };
    case RESET_REFERRALS:
      return initialState;
    case RESET_REFERRALS_SUMMARY:
      return {
        ...state,
        summary: INITIAL,
      };
    case RESET_REFERRALS_LIST:
      return {
        ...state,
        list: INITIAL,
      };
    default:
      return state;
  }
};

export default referrals;
