import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const InvestingAccessFormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InvestmentLimitWrapper = styled.div`
  max-width: 375px;
`;

export const SaveChangesButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.niagaraLight};
  border-radius: 40px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  height: 44px;
  width: 240px;
  margin-top: 30px;
`;
