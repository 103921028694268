import {
  CLOSE_MODAL,
  DEFAULT_INFO_MODAL_SIZE,
  INFO_MODAL,
  OPEN_MODAL,
} from './consts';
import { IModalsReducer } from './types';

const initialState = {
  modals: {
    openModals: [],
    infoModal: {
      isOpen: false,
      config: {
        title: '',
        description: '',
        acceptButtonText: '',
        rejectButtonText: '',
        onAccept: () => {},
        onReject: () => {},
        size: DEFAULT_INFO_MODAL_SIZE,
      },
    },
  },
};

const modals: IModalsReducer = (
  state = initialState, // eslint-disable-line
  action,
) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModals: [...state.modals.openModals, action.modalName],
      };

    case CLOSE_MODAL:
      return {
        ...state,
        openModals: state.modals.openModals?.filter(
          (modal) => modal !== action.modalName,
        ),
      };

    case INFO_MODAL:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default modals;
