import React from 'react';

import { FundDetailsWrapper } from './styles';
import FundDetailsHeader from './FundDetailsHeader';
import Widgets from './Widgets/Widgets';
import FundDetailsFooter from './FundDetailsFooter';

export default function FundDetails() {
  return (
    <FundDetailsWrapper>
      <FundDetailsHeader />

      <Widgets />

      <FundDetailsFooter />
    </FundDetailsWrapper>
  );
}
