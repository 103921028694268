import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetTitle, WidgetWrapper } from '../styles';
import { WidgetExternalButton } from '../components/WidgetExternalButton';

import { BlogPosts } from './components/BlogPosts';
import { handleOpenBlogLinkClick } from './utils/handleOpenBlogLinkClick';
import { useBlogPosts } from './hooks';
import { BlogWidgetWrapper } from './components/styles';

export default function BlogWidget() {
  const { t } = useTranslation();

  const { isLoading, posts, blogUrl } = useBlogPosts();

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('blogWidget.title')}</WidgetTitle>

      <BlogWidgetWrapper>
        <BlogPosts isLoading={isLoading} posts={posts} />

        <WidgetExternalButton
          href={blogUrl}
          onClick={handleOpenBlogLinkClick}
          text={t('blogWidget.seeAll')}
        />
      </BlogWidgetWrapper>
    </WidgetWrapper>
  );
}
