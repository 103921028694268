import { useTranslation } from 'react-i18next';
import React from 'react';

import { WidgetText, WidgetTitle, WidgetWrapper } from '../styles';
import { WidgetButton } from '../components/WidgetButton';

import { Content, Header } from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import theme from 'theme';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAccountSummary } from 'store/history/selectors';
import { PATHS } from 'constants/paths';

export const RaizRewardsWidget = () => {
  const { t } = useTranslation();

  const { total_found_money_rewarded } = useAppSelector(selectAccountSummary);

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('raizRewardsWidget.title')}</WidgetTitle>

      <Content>
        <WidgetText>{t('raizRewardsWidget.header')}</WidgetText>

        <Header>
          {formatNumber({
            value: total_found_money_rewarded?.value || 0,
            maximumFractionDigits: 2,
          })}
        </Header>

        <WidgetButton
          href={PATHS.rewards.index}
          text={t('raizRewardsWidget.button')}
          background={theme.colors.whiteTransparent}
          hasBorder
        />
      </Content>
    </WidgetWrapper>
  );
};
