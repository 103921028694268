import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router';

import InfoPanel from './components/InfoPanel';
import TermsInfoPanel from './components/TermsInfoPanel';
import { AccountMigrationLayout } from './styles';
import AccountMigrationContent from './subpages/AccountMigrationContent';

import { PATHS } from 'constants/paths';
import {
  selectUserErrorMessage,
  selectIsUserMigrationCompleted,
} from 'store/user/selectors';
import { displayErrorModal } from 'store/modals/actions';

export default function AccountMigration() {
  const navigate = useNavigate();
  const errorMessage = useSelector(selectUserErrorMessage);
  const isCompleted = useSelector(selectIsUserMigrationCompleted);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const isTermsPage = [
    PATHS.migration.account.pds,
    PATHS.migration.account.superPds,
  ].some((path) => matchPath(path, pathname));

  useEffect(() => {
    if (isCompleted) {
      navigate('/');
    }

    if (errorMessage) {
      dispatch(displayErrorModal({ errorMessage }));
    }
  }, [dispatch, errorMessage, isCompleted, navigate]);

  return (
    <AccountMigrationLayout>
      {isTermsPage ? <TermsInfoPanel /> : <InfoPanel />}

      <AccountMigrationContent />
    </AccountMigrationLayout>
  );
}
