import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Page from '../components/layout/Page/Page';

import Loader from 'components/elements/Loader/Loader';

export const Layout = () => (
  <Suspense fallback={<Loader />}>
    <Page>
      <Outlet />
    </Page>
  </Suspense>
);
