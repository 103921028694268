import styled from '@emotion/styled';

export const ErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.pomegranat};
  position: absolute;
  width: 100%;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.number['3xl']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
  bottom: 0px;
`;

export const Label = styled.div`
  pointer-events: none;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
`;

export const FieldWrapper = styled.div`
  width: 100%;
  position: relative;
`;
