import { DISABLED_MULTIPLIER_VALUE } from '../components/consts';

const getMinimumAvailableMultiplayer = (
  availableAmountMultipliers: number[] = [],
) => {
  if (!availableAmountMultipliers?.length) {
    return DISABLED_MULTIPLIER_VALUE;
  }

  return Math.min(
    ...availableAmountMultipliers.filter(
      (multiplier) => multiplier !== DISABLED_MULTIPLIER_VALUE,
    ),
  );
};

export default getMinimumAvailableMultiplayer;
