import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';

export default function EmptyInsurancesList() {
  const { t } = useTranslation();

  return (
    <div className="my-finance__categorisation-empty">
      <div className="statements-empty__icon" />

      <div className="statements-empty__text">
        {t('statements.empty.noStatements')}
      </div>
    </div>
  );
}
