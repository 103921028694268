import styled from '@emotion/styled';

import raizLogo from 'assets/images/logo.svg';
import { Button } from 'components/elements/Button';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { CONTENT_MAX_WIDTH } from 'constants/comonConstants';
import { iconset } from 'components/elements/Icon/iconset';

export const Wrapper = styled.div`
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 148px;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

export const Logo = styled.div`
  background-size: contain;
  background: url(${`${raizLogo}`}) no-repeat center;
  width: 156px;
  height: 50px;
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 40px;
  border-radius: 12px;
  padding: 20px 40px 30px;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
  }
`;

export const HelpText = styled.div`
  color: ${({ theme }) => theme.colors.silverChalice};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: left;
  margin-top: 20px;
  line-height: ${({ theme }) => theme.lineHeights.number['2xl']};
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
`;

export const HelpIcon = styled.div`
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  display: inline-block;
  background-size: contain;
  background: url(${`${iconset.info}`}) no-repeat center;
`;

export const ConfirmButton = styled(Button)`
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  width: 100%;
`;

export const Footer = styled.div`
  padding: 100px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContactUsLink = styled(ExternalLink)`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const FooterIcon = styled.span`
  padding-top: 4px;
  padding-left: 32px;
  margin-right: 10px;
  background: url(${`${iconset.help}`}) no-repeat;
  padding-bottom: 2px;
`;

export const TokenErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
`;
