import styled from '@emotion/styled';

import logoSvg from '../../assets/images/logo.svg';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';

export const Instruction = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  margin: 45px auto;
  text-align: center;
  width: 100%;
`;

export const Logo = styled.div`
  background-image: url(${`${logoSvg}`});
  background-position: center;
  background-repeat: no-repeat;
  padding: 25px;
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  height: 20px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin: 0 auto;
  text-align: center;
`;

export const Header = styled.div`
  padding-top: 110px;
`;

export const Footer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-top: 20px;
`;

export const ExternalLinkStyled = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 455px;
  margin: 0 auto;
`;
