import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Firebase from 'firebase/app';
import { withTranslation } from 'react-i18next';

import * as spendingAnalyticsActions from '../../store/spendingAnalytics/actions';
import * as actionsUser from '../../store/user/actions';
import * as actionsSuper from '../../store/superAnnuation/actions';
import * as actionsModals from '../../store/modals/actions';
import {
  MIN_INVEST,
  FREQUENCY,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
  ERRLINKING_FUNDING_ACCOUNT,
  FREQUENCIES,
} from '../../constants/comonConstants';

import RecurringInvestmentView from './RecurringInvestmentView';

import { MINIMUM_RECURRING_AMOUNT } from 'pages/RaizKidsEdit/components/RecurringInvestment/consts';
import { formatNumber } from 'utils/formatters/formatNumber';

class RecurringInvestment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invAmount: 0,
      frequencyDay: null,
      targetAmount: 0,
      editGoal: false,
      changePlan: false,
      showLoader: false,
      currentGoalName: '',
      showMainLoader: true,
      startDate: null,
      isOpenNoteModal: false,
      isOpenInfoModal: false,
      isOpenStopModal: false,
      isOpenHelpCards: false,
      currentFrequency: '',
      currentErrorMessage: '',
      showChangeGoalNameState: false,
      savingGoalCondition: false,
      showFrequencyChangeState: false,
      showFundingModal: false,
    };

    this.updateGoal = this.updateGoal.bind(this);

    this.setEditGoal = this.setEditGoal.bind(this);

    this.setChangePlan = this.setChangePlan.bind(this);

    this.stopInvestment = this.stopInvestment.bind(this);

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.changeGoalName = this.changeGoalName.bind(this);

    this.changeInvAmount = this.changeInvAmount.bind(this);

    this.toggleInfoModal = this.toggleInfoModal.bind(this);

    this.toggleStopModal = this.toggleStopModal.bind(this);

    this.toggleNoteModal = this.toggleNoteModal.bind(this);

    this.changeStartDate = this.changeStartDate.bind(this);

    this.toggleMainLoader = this.toggleMainLoader.bind(this);

    this.toggleErrorModal = this.toggleErrorModal.bind(this);

    this.deleteSavingGoal = this.deleteSavingGoal.bind(this);

    this.changeTargetAmount = this.changeTargetAmount.bind(this);

    this.changeFrequencyDay = this.changeFrequencyDay.bind(this);

    this.toggleChangeGoalState = this.toggleChangeGoalState.bind(this);

    this.handleChangeSavingGoal = this.handleChangeSavingGoal.bind(this);

    this.changeCurrentFrequency = this.changeCurrentFrequency.bind(this);

    this.toggleChangeFrequencyState =
      this.toggleChangeFrequencyState.bind(this);

    this.createRecurringDepositSettings =
      this.createRecurringDepositSettings.bind(this);

    this.toggleFundingModal = this.toggleFundingModal.bind(this);

    this.getGoal = this.getGoal.bind(this);
  }

  componentDidMount() {
    Firebase.analytics().logEvent('Recurring_Investment');

    Firebase.analytics().logEvent('RecurringInvestmentOpen');

    const { isSuper, actions, userActions, superActions, user } = this.props;

    if (
      (user && !user.alerts) ||
      (user &&
        user.alerts &&
        !user.alerts.find(
          (alertMsg) =>
            alertMsg.type === REGISTRATION_INCOMPLETE_LINKFUNDING ||
            alertMsg.type === ERRLINKING_FUNDING_ACCOUNT,
        ))
    ) {
      this.setState({ showFundingModal: false });
    } else {
      this.setState({ showFundingModal: true });
    }

    if (isSuper) {
      userActions.getSuperUserData();

      superActions.getRecurringDepositSettings().then(() => {
        this.toggleMainLoader(false);

        const { recurringDepositSettings } = this.props;

        this.changeInvAmount(recurringDepositSettings.amount);
      });
    } else {
      userActions.getUserData();

      actions.getRecurringDepositSettings().then(() => {
        this.toggleMainLoader(false);

        const { recurringDepositSettings } = this.props;

        if (recurringDepositSettings.amount) {
          this.changeInvAmount(recurringDepositSettings.amount);
        }

        if (recurringDepositSettings.day) {
          this.changeFrequencyDay(recurringDepositSettings.day);
        }

        if (recurringDepositSettings.start_date) {
          this.changeStartDate(new Date(recurringDepositSettings.start_date));
        }

        if (
          recurringDepositSettings.frequency_identifier &&
          recurringDepositSettings.service
        ) {
          this.changeCurrentFrequency(
            recurringDepositSettings.frequency_identifier,
          );
        }

        actions.getGoals().then(() => {
          const { goals } = this.props;

          if (goals.payload && goals.payload[0]) {
            const currentGoal = goals.payload[0].goal;

            this.changeTargetAmount(currentGoal.target_amount);

            this.changeGoalName(currentGoal.name);
          }
        });
      });
    }

    const { goals, recurringDepositSettings } = this.props;

    if (!goals.header && !isSuper) {
      this.getGoal();
    }

    const { currentFrequency } = this.state;

    if (!currentFrequency && recurringDepositSettings.frequency_identifier) {
      this.setState({
        currentFrequency: recurringDepositSettings.frequency_identifier,
      });
    }

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.checkoutUrl && newProps.checkoutUrl !== '') {
      this.setState({ showLoader: true });

      window.location.href = newProps.checkoutUrl;
    }
  }

  handleChangeSavingGoal(checked) {
    this.setState({ savingGoalCondition: checked });
  }

  getGoal() {
    const { actions } = this.props;

    actions.getGoals().then(() => {
      const { goals } = this.props;

      if (goals.payload && goals.payload[0]) {
        const currentGoal = goals.payload[0].goal;

        this.changeTargetAmount(currentGoal.target_amount);

        this.changeGoalName(currentGoal.name);
      }
    });
  }

  setEditGoal(condition) {
    this.setState({ editGoal: condition, savingGoalCondition: true });
  }

  setChangePlan(condition) {
    this.setState({ changePlan: condition });
  }

  toggleHelpCard(condition) {
    this.setState({ isOpenHelpCards: condition });
  }

  changeInvAmount(invAmountNew, isNotEditable = false) {
    const { invAmount } = this.state;

    if (!isNotEditable) {
      this.setState({
        invAmount: invAmountNew > 100000000 ? 100000000 : invAmountNew,
      });
    } else {
      this.setState({ invAmount });
    }
  }

  changeTargetAmount(targetAmount) {
    if (targetAmount) {
      this.setState({ targetAmount });
    }
  }

  toggleChangeFrequencyState() {
    const { currentFrequency } = this.state;

    if (!currentFrequency) {
      this.changeCurrentFrequency(FREQUENCIES.daily);
    }

    this.setState({ showFrequencyChangeState: true });
  }

  updateGoal() {
    const { actions, goals, user } = this.props;

    const { invAmount, targetAmount, currentGoalName, currentFrequency } =
      this.state;

    let currentGoal = {};

    if (
      goals &&
      goals.payload &&
      goals.payload[0] &&
      goals.payload[0].goal.status === 'active'
    ) {
      currentGoal = goals.payload[0].goal;
    }

    const requestData = {
      key: currentGoal.key,
      name: currentGoalName,
      target_amount: targetAmount,
      frequency: FREQUENCY[currentFrequency],
      target_contribution: invAmount,
    };

    if (!requestData.frequency) {
      requestData.frequency = currentGoal.frequency;
    }

    if (
      currentGoalName &&
      targetAmount &&
      targetAmount > user.current_balance &&
      invAmount >= MIN_INVEST
    ) {
      actions.updateGoal(requestData).then(() => {
        const { errorMessage } = this.props;

        if (errorMessage) {
          this.toggleErrorModal(true, errorMessage);
        } else {
          actions.getGoals();

          this.createRecurringDepositSettings();

          this.setEditGoal(false);
        }
      });
    } else {
      this.toggleErrorModal(true, this.renderErrorMessage());
    }
  }

  changeStartDate(date) {
    this.setState({ startDate: date });
  }

  changeFrequencyDay(day) {
    this.setState({ frequencyDay: day });
  }

  toggleErrorModal(condition, msg = '') {
    this.setState({ isOpenErrorModal: condition, currentErrorMessage: msg });
  }

  changeCurrentFrequency(frequency, switcherChange = false) {
    const { t } = this.props;
    const { frequencyDay, startDate } = this.state;

    if (!switcherChange) {
      if (!frequencyDay && !startDate && frequency !== FREQUENCIES.daily) {
        this.toggleErrorModal(
          true,
          t('recurringInvestmentPage.errors.chooseFrequency'),
        );
      } else {
        this.setState({
          currentFrequency: frequency,
          showFrequencyChangeState: switcherChange,
        });
      }
    } else {
      this.setState({
        currentFrequency: frequency,
        frequencyDay: null,
        startDate: null,
      });
    }
  }

  changeGoalName(goalName) {
    if (goalName) {
      this.setState({ currentGoalName: goalName });
    }
  }

  renderErrorMessage = () => {
    const { user, t } = this.props;
    const { invAmount, targetAmount, currentGoalName } = this.state;

    if (!currentGoalName) {
      return t('recurringInvestmentPage.errors.noGoalName');
    }

    if (!targetAmount) {
      return t('recurringInvestmentPage.errors.noGoalAmount');
    }

    if (!invAmount) {
      return t('recurringInvestmentPage.errors.noInvestmentAmount');
    }

    if (targetAmount < user.current_balance) {
      return t('recurringInvestmentPage.errors.lessThanCurrentBalance');
    }

    if (invAmount < MIN_INVEST) {
      return t('recurringInvestmentPage.errors.lessThanMinimalAmount', {
        minimalAmmount: formatNumber({ value: MIN_INVEST, type: 'currency' }),
      });
    }

    return '';
  };

  stopInvestment() {
    const { actions, isSuper, superActions } = this.props;

    this.setState({ showLoader: true });

    if (isSuper) {
      superActions.stopInvestment().then(() => {
        this.toggleStopModal(false, true);

        superActions.getRecurringDepositSettings().then(() => {
          this.setState({ showLoader: false });
        });
      });
    } else {
      actions.stopInvestment().then(() => {
        const { errorMessage } = this.props;

        if (errorMessage) {
          this.toggleErrorModal(true, errorMessage);

          this.toggleStopModal(false, false);

          this.setState({ showLoader: false });
        } else {
          this.setState({
            invAmount: 0,
          });

          const { recurringDepositSettings, user } = this.props;

          if (recurringDepositSettings) {
            const hasPending =
              user && user.pending_balance && user.pending_balance > 0;

            this.toggleStopModal(false, hasPending);
          }

          actions.getGoals();

          actions.getRecurringDepositSettings().then(() => {
            this.setState({ showLoader: false });
          });
        }
      });
    }

    this.setState({
      currentFrequency: '',
      currentGoalName: '',
      targetAmount: 0,
      frequencyDay: null,
      startDate: null,
      savingGoalCondition: false,
    });
  }

  deleteSavingGoal(goal) {
    const { actions } = this.props;
    const { key } = goal;

    actions.deleteGoals(key).then(() => {
      actions.getGoals();

      actions.getRecurringDepositSettings();
    });
  }

  toggleChangeGoalState(condition) {
    this.setState({ showChangeGoalNameState: condition });
  }

  format(currentStartDate) {
    const d = currentStartDate.getDate();
    const m = currentStartDate.getMonth() + 1;
    const y = currentStartDate.getFullYear();

    return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
  }

  createRecurringDepositSettings() {
    const { user, actions, isSuper, superActions, modalsActions, t } =
      this.props;

    const {
      editGoal,
      invAmount,
      targetAmount,
      currentGoalName,
      currentFrequency,
      savingGoalCondition,
      frequencyDay,
      startDate,
    } = this.state;

    const requestData = {
      amount: invAmount,
      frequency: FREQUENCY[currentFrequency],
    };

    let goalRequestData = {};

    const currentStartDate = new Date(startDate);

    if (currentFrequency === FREQUENCIES.weekly) {
      requestData.day = frequencyDay;
    }

    if (currentFrequency === FREQUENCIES.fortnightly) {
      requestData.start_date = this.format(currentStartDate);

      requestData.next_investment_at = currentStartDate;
    }

    if (currentFrequency === FREQUENCIES.monthly) {
      requestData.day = frequencyDay;

      requestData.next_investment_at = currentStartDate;
    }

    if (savingGoalCondition) {
      goalRequestData = {
        target_contribution: invAmount,
        frequency: FREQUENCY[currentFrequency],
        start_date: this.format(new Date()),
        target_amount: targetAmount,
        name: currentGoalName,
        currency: 'AUD',
        start_amount: user.current_balance,
      };
    }

    if (!invAmount && !currentFrequency) {
      modalsActions.displayErrorModal({
        errorMessage: t('recurringInvestmentPage.errors.noAmountAndFrequency'),
      });

      return;
    }

    if (!invAmount) {
      modalsActions.displayErrorModal({
        errorMessage: t('recurringInvestmentPage.errors.noInvestmentAmount'),
      });

      return;
    }

    if (invAmount < MINIMUM_RECURRING_AMOUNT) {
      modalsActions.displayErrorModal({
        errorMessage: t('fields.validation.greaterThanOrEqual', {
          name: t('common.amount'),
          amount: formatNumber({
            value: MINIMUM_RECURRING_AMOUNT,
          }),
        }),
      });

      return;
    }

    if (!currentFrequency) {
      modalsActions.displayErrorModal({
        errorMessage: t('recurringInvestmentPage.errors.noFrequency'),
      });

      return;
    }

    this.setState({ showLoader: true });

    if (savingGoalCondition && !editGoal) {
      if (
        currentGoalName &&
        targetAmount &&
        targetAmount > user.current_balance &&
        invAmount
      ) {
        actions.createRecurringDepositSettings(requestData).then(() => {
          this.setState({ showLoader: false });

          const { errorMessage } = this.props;

          if (errorMessage) {
            this.toggleErrorModal(true, errorMessage);
          } else {
            actions.createGoal(goalRequestData).then(() => {
              Firebase.analytics().logEvent('RecurringSetUpValue');

              actions.getGoals();

              actions.getRecurringDepositSettings();

              this.setChangePlan(false);
            });
          }
        });
      } else {
        this.setState({ showLoader: false });

        this.toggleErrorModal(true, this.renderErrorMessage());
      }
    } else if (isSuper) {
      Firebase.analytics().logEvent('RecurringSetUpValue');

      this.toggleInfoModal(false);

      superActions.createRecurringDepositSettings(requestData).then(() => {
        this.setState({ showLoader: false });

        const { errorMessage } = this.props;

        if (errorMessage) {
          this.toggleErrorModal(true, errorMessage);
        } else {
          superActions.getRecurringDepositSettings();

          this.setChangePlan(false);
        }
      });
    } else {
      this.runRecurring(requestData);
    }
  }

  runRecurring(requestData) {
    const { actions } = this.props;

    Firebase.analytics().logEvent('RecurringSetUpValue');

    actions.createRecurringDepositSettings(requestData).then(() => {
      this.setState({ showLoader: false });

      const { errorMessage } = this.props;

      if (errorMessage) {
        this.toggleErrorModal(true, errorMessage);
      } else {
        actions.getGoals();

        actions.getRecurringDepositSettings();

        this.setChangePlan(false);
      }
    });
  }

  toggleInfoModal(condition) {
    this.setState({ isOpenInfoModal: condition });
  }

  toggleNoteModal(condition) {
    this.setState({ isOpenNoteModal: condition });
  }

  toggleStopModal(condition, openNoteModal) {
    if (condition) {
      Firebase.analytics().logEvent('RecurringInvestmentCreated');
    }

    this.setState({
      isOpenStopModal: condition,
      isOpenNoteModal: openNoteModal,
    });
  }

  toggleMainLoader(condition) {
    this.setState({ showMainLoader: condition });
  }

  toggleFundingModal(condition) {
    const { history } = this.props;

    if (!condition) {
      history.goBack();
    } else {
      this.setState({ showFundingModal: condition });
    }
  }

  render() {
    const { user, goals, isSuper, superUser, recurringDepositSettings } =
      this.props;

    const {
      editGoal,
      startDate,
      invAmount,
      changePlan,
      showLoader,
      frequencyDay,
      targetAmount,
      showMainLoader,
      isOpenHelpCards,
      currentGoalName,
      isOpenNoteModal,
      isOpenInfoModal,
      isOpenStopModal,
      currentFrequency,
      isOpenErrorModal,
      showChangeGoalNameState,
      savingGoalCondition,
      currentErrorMessage,
      showFrequencyChangeState,
      showFundingModal,
    } = this.state;

    let currentGoal = {};

    if (
      goals &&
      goals.payload &&
      goals.payload[0] &&
      goals.payload[0].goal.status === 'active'
    ) {
      currentGoal = goals.payload[0].goal;
    }

    const isRecurringEmpty =
      Object.entries(recurringDepositSettings).length === 0 &&
      recurringDepositSettings.constructor === Object;

    const hasRecurringDeposit =
      Object.entries(recurringDepositSettings).length > 0 &&
      recurringDepositSettings.constructor === Object;

    const hasGoals =
      Object.entries(currentGoal).length > 0 &&
      currentGoal.constructor === Object;

    let balance = 0;

    if (isSuper && superUser) {
      balance = superUser.current_balance;
    } else if (user.current_balance) {
      balance = user.current_balance;
    }

    return (
      <RecurringInvestmentView
        user={user}
        isSuper={isSuper}
        editGoal={editGoal}
        hasGoals={hasGoals}
        invAmount={invAmount}
        startDate={startDate}
        goals={goals.payload}
        showLoader={showLoader}
        changePlan={changePlan}
        currentBalance={balance}
        currentGoal={currentGoal}
        targetAmount={targetAmount}
        frequencyDay={frequencyDay}
        updateGoal={this.updateGoal}
        setEditGoal={this.setEditGoal}
        showMainLoader={showMainLoader}
        currentGoalName={currentGoalName}
        isOpenHelpCards={isOpenHelpCards}
        isOpenNoteModal={isOpenNoteModal}
        isOpenInfoModal={isOpenInfoModal}
        isOpenStopModal={isOpenStopModal}
        setChangePlan={this.setChangePlan}
        isRecurringEmpty={isRecurringEmpty}
        currentFrequency={currentFrequency}
        isOpenErrorModal={isOpenErrorModal}
        stopInvestment={this.stopInvestment}
        changeGoalName={this.changeGoalName}
        toggleNoteModal={this.toggleNoteModal}
        toggleHelpCard={this.toggleHelpCard}
        changeInvAmount={this.changeInvAmount}
        changeStartDate={this.changeStartDate}
        toggleStopModal={this.toggleStopModal}
        toggleInfoModal={this.toggleInfoModal}
        deleteSavingGoal={this.deleteSavingGoal}
        toggleErrorModal={this.toggleErrorModal}
        hasRecurringDeposit={hasRecurringDeposit}
        savingGoalCondition={savingGoalCondition}
        showChangeGoalNameState={showChangeGoalNameState}
        currentErrorMessage={currentErrorMessage}
        changeTargetAmount={this.changeTargetAmount}
        changeFrequencyDay={this.changeFrequencyDay}
        toggleChangeGoalState={this.toggleChangeGoalState}
        showFrequencyChangeState={showFrequencyChangeState}
        recurringDepositSettings={recurringDepositSettings}
        handleChangeSavingGoal={this.handleChangeSavingGoal}
        changeCurrentFrequency={this.changeCurrentFrequency}
        toggleChangeFrequencyState={this.toggleChangeFrequencyState}
        createRecurringDepositSettings={this.createRecurringDepositSettings}
        showFundingModal={showFundingModal}
        toggleFundingModal={this.toggleFundingModal}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  state,
  user: state.user.user,
  superUser: state.user.superUser,
  goals: state.spendingAnalytics.goals,
  errorMessage: !props.isSuper
    ? state.spendingAnalytics.errorMessage
    : state.superAnnuation.errorMessage,
  recurringDepositSettings: !props.isSuper
    ? state.spendingAnalytics.recurringDepositSettings
    : state.superAnnuation.recurringDepositSettings,
  checkoutUrl: state.spendingAnalytics.checkoutUrl,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(actionsUser, dispatch),
  superActions: bindActionCreators(actionsSuper, dispatch),
  actions: bindActionCreators(spendingAnalyticsActions, dispatch),
  modalsActions: bindActionCreators(actionsModals, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RecurringInvestment));

RecurringInvestment.defaultProps = {
  user: {},
  actions: {},
  superUser: {},
  isSuper: false,
  userActions: {},
  superActions: {},
  errorMessage: '',
  goals: { payload: [] },
  recurringDepositSettings: {},
  history: {},
};

RecurringInvestment.propTypes = {
  isSuper: PropTypes.bool,
  user: PropTypes.shape({
    pending_balance: PropTypes.number,
    current_balance: PropTypes.number,
    email: PropTypes.string,
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  goals: PropTypes.shape({
    header: PropTypes.shape({}),
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        goal: PropTypes.shape({
          current_balance: PropTypes.number,
          target_amount: PropTypes.number,
          name: PropTypes.string,
          status: PropTypes.string,
        }),
      }),
    ),
  }),
  actions: PropTypes.shape({
    getRecurringDepositSettings: PropTypes.func,
    getGoals: PropTypes.func,
    stopInvestment: PropTypes.func,
    deleteGoals: PropTypes.func,
    createRecurringDepositSettings: PropTypes.func,
    createGoal: PropTypes.func,
    updateGoal: PropTypes.func,
  }),
  superUser: PropTypes.shape({
    current_balance: PropTypes.number,
  }),
  errorMessage: PropTypes.string,
  userActions: PropTypes.shape({
    getSuperUserData: PropTypes.func,
    updateGoal: PropTypes.func,
    getUserData: PropTypes.func,
  }),
  superActions: PropTypes.shape({
    stopInvestment: PropTypes.func,
    getRecurringDepositSettings: PropTypes.func,
    createRecurringDepositSettings: PropTypes.func,
  }),
  recurringDepositSettings: PropTypes.shape({
    day: PropTypes.number,
    amount: PropTypes.number,
    start_date: PropTypes.string,
    service: PropTypes.string,
    frequency_identifier: PropTypes.string,
    frequency: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  modalsActions: PropTypes.shape({
    displayErrorModal: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
