import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PromotedFilters, QuickFilters } from './styles';
import FiltersCategory from './FiltersCategory';
import { useFundsFilter, useFilterFundsActions } from './hooks';
import FiltersButtons from './FiltersButtons';
import FilterItem from './FilterItem';
import { useFilters } from './useFilters';

export default function FiltersCategories() {
  const { t } = useTranslation();
  const { filtersCategories, promotedFilters } = useFundsFilter();
  const { onFormSubmit } = useFilterFundsActions();

  const { fundsFilters } = useFilters();

  const defaultValues = Object.values(fundsFilters)
    ?.flat()
    ?.reduce(
      (acc, activeFilter) => ({
        ...acc,
        [activeFilter]: true,
      }),
      {},
    );

  const form = useForm({
    defaultValues,
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(onFormSubmit);

  const isAnyFilerPromoted = Object.values(promotedFilters)?.length > 0;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        {isAnyFilerPromoted && (
          <QuickFilters>
            {t('portfolioCustomization.filters.quickFilters')}
          </QuickFilters>
        )}

        <PromotedFilters>
          {promotedFilters?.map((filters) =>
            filters?.tags?.map((filter) => (
              <FilterItem key={filter.key} filter={filter} isPromoted />
            )),
          )}
        </PromotedFilters>

        {filtersCategories?.map((filterCategory) => (
          <FiltersCategory
            key={filterCategory.key}
            filterCategory={filterCategory}
          />
        ))}

        <FiltersButtons />
      </form>
    </FormProvider>
  );
}
