import React from 'react';
import Skeleton from 'react-skeleton-loader';

function StatementsSkeleton() {
  return (
    <div>
      <div className="history__item-date">
        <Skeleton />
      </div>

      <div className="info-panel__item statements-item">
        <Skeleton />

        <Skeleton />
      </div>
    </div>
  );
}

export default StatementsSkeleton;
