export const REQUEST_TIMEOUT = 60000;
export const OFFLINE_ERROR = 'offline';

export const BASE_API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

// Error codes 400 - 499
const CLIENT_ERROR_CODES = Array.from({ length: 100 }, (_, i) => i + 400);

export const SERVER_ERROR_CODES = [500, 503, 504];

export const throwableErrors = [...CLIENT_ERROR_CODES, ...SERVER_ERROR_CODES];

export class ResponseError extends Error {
  constructor(response) {
    super(response.statusText);

    this.response = response;
  }
}
