import React from 'react';
import Skeleton from 'react-skeleton-loader';

import { SkeletonButton, SkeletonWrapper } from './styles';
import CheckboxSkeleton from './CheckboxSkeleton';

function InvestingAccessFormSkeleton() {
  return (
    <SkeletonWrapper>
      {[...Array(3)].map(() => (
        <CheckboxSkeleton key={window.crypto.randomUUID()} />
      ))}

      <SkeletonButton>
        <Skeleton height="40px" borderRadius="40px" width="100%" />
      </SkeletonButton>
    </SkeletonWrapper>
  );
}

export default InvestingAccessFormSkeleton;
