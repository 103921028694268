/* eslint-disable react/prop-types */

import usePortfolioUserData from './usePortfolioUserData';

// TODO: remove this headless component after parent class component migrated to function component
const FetchPortfolioUserData = ({ onSuccess, isEditKids, isRegistration }) => {
  usePortfolioUserData({
    onSuccess,
    isEditKids,
    isRegistration,
  });

  return null;
};

export default FetchPortfolioUserData;
