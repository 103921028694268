import {
  MIGRATION_FLOW,
  MIGRATION_SOURCES,
} from '../constants/migrationConstants';

import getMigrationSource from './getMigrationSource';

const getMigrationFlow = () => {
  const migrationSource = getMigrationSource();

  switch (migrationSource) {
    case MIGRATION_SOURCES.superEstate:
      return MIGRATION_FLOW.superEstate;
    default:
      return MIGRATION_FLOW.standard;
  }
};

export default getMigrationFlow;
