import { useNavigateToRecurringZakatPayment } from './useNavigateToRecurringZakatPayment';

import { VIEW_MODE } from 'pages/RaizKidsEdit/components/RecurringInvestment/consts';
import { useDeleteRecurringRemittanceSettingsMutation } from 'store/rewards/api';

export const useStopRecurringZakatPayment = () => {
  const [deleteRecurringRemittanceSettings] =
    useDeleteRecurringRemittanceSettingsMutation();

  const navigateToRecurringZakatPayment = useNavigateToRecurringZakatPayment();

  const handleStopRecurringZakatPayment = async () => {
    await deleteRecurringRemittanceSettings();

    navigateToRecurringZakatPayment({ viewMode: VIEW_MODE.edit });
  };

  return handleStopRecurringZakatPayment;
};
