import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteGreyTransparent};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  padding: 8px 0px 8px 8px;
  text-align: center;
  width: 180px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 150px;
  }

  :first-of-type {
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid ${({ theme }) => theme.colors.whiteGreyTransparent};
    margin-right: 1px;
  }
  :last-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.whiteGreyTransparent};
    border-radius: 0px 8px 8px 0px;
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  text-align: center;
`;

export const Value = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};

  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
`;
