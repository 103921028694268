import React, { useState, useMemo } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { REGISTRATION_PERSONAL_ADDRESS } from '../../../store/user/consts';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import SelectField from '../../../components/elements/SelectFF/SelectField';
import {
  BAHASA,
  INDICATE_REASONS,
  INDICATE_REASONS_MS,
} from '../../../constants/comonConstants';
import InvestmentErrorModal from '../../../components/layout/Modals/InvestmentErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const validate = (values, t) => {
  const errors = {};
  const isTinResidentChecked = values?.tin_resident?.length > 0;
  const isNoTinChecked = values?.no_tin?.length > 0;
  const required = t('fields.validation.required');

  if (
    isTinResidentChecked &&
    values.tin_country &&
    !values.tin_country.length
  ) {
    errors.tin_country = required;
  }

  if (isTinResidentChecked && !isNoTinChecked && !values.ssn) {
    errors.ssn = required;
  }

  if (
    isTinResidentChecked &&
    isNoTinChecked &&
    values.no_tin_reason &&
    !values.no_tin_reason.length
  ) {
    errors.no_tin_reason = required;
  }

  return errors;
};

export default function TaxResident({
  handleSubmit,
  errorMessage,
  goBack,
  showLoader,
  user,
  taxResidentData,
  countryList,
}) {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const [isOpenPepInfo, setIsOpenPepInfo] = useState(false);

  const tinCountryOptions = useMemo(() => countryList || [], [countryList]);

  const noTinReasonOptions = useMemo(
    () => (lang === BAHASA ? INDICATE_REASONS_MS : INDICATE_REASONS),
    [lang],
  );

  const noTinReasonDefaultValue = useMemo(
    () => taxResidentData.no_tin_reason || [],
    [taxResidentData.no_tin_reason],
  );

  const tinCountryDefaultValue = useMemo(
    () => taxResidentData.tin_country || [],
    [taxResidentData.tin_country],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values, t)}
      initialValues={{
        tin_resident: taxResidentData.tin_resident,
        pep: taxResidentData.pep,
        no_tin: taxResidentData.no_tin,
      }}
      render={({ form, values, hasValidationErrors }) => {
        const isTinResidentChecked = values?.tin_resident?.length > 0;
        const isNoTinChecked = values?.no_tin?.length > 0;
        const isPepChecked = values?.pep?.length > 0;

        return (
          <form
            className="form"
            onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
          >
            <div className="-agree-text">
              {t('registrationPage.personal.step.taxResident')}
            </div>

            <div className="-form-field radio">
              <CheckboxFF
                name="tin_resident"
                className="tin-resident"
                defaultChecked={isTinResidentChecked}
                type="checkbox"
                label={
                  <Trans
                    i18nKey="registrationPage.taxResident.areYouTaxResident"
                    t={t}
                  />
                }
              />

              {isTinResidentChecked && (
                <div className="select-first -gender -tax">
                  <div className="profile-content__container-label">
                    {t('registrationPage.taxResident.taxResidencyStatus')}
                  </div>

                  <SelectField
                    name="tin_country"
                    placeholder={t(
                      'registrationPage.taxResident.selectCountry',
                    )}
                    defaultValue={tinCountryDefaultValue}
                    labelField="name"
                    valueField="code"
                    options={tinCountryOptions}
                  />
                </div>
              )}

              {isTinResidentChecked && (
                <FinalFormInput
                  name="ssn"
                  defaultValue={user.user.ssn}
                  label={t('registrationPage.taxResident.indicateTaxpayer')}
                />
              )}

              {isTinResidentChecked && (
                <CheckboxFF
                  name="no_tin"
                  className="no-tin"
                  defaultChecked={isNoTinChecked}
                  type="checkbox"
                  label={
                    <Trans
                      i18nKey="registrationPage.taxResident.noTinNumber"
                      t={t}
                    />
                  }
                />
              )}

              {isTinResidentChecked && isNoTinChecked && (
                <div className="select-first -gender -tax">
                  <div className="profile-content__container-label">
                    {t('registrationPage.taxResident.indicateReason')}
                  </div>

                  <SelectField
                    name="no_tin_reason"
                    placeholder={t('registrationPage.taxResident.selectReason')}
                    defaultValue={noTinReasonDefaultValue}
                    options={noTinReasonOptions}
                  />
                </div>
              )}

              <CheckboxFF
                name="pep"
                className="-pep"
                defaultChecked={isPepChecked}
                type="checkbox"
                label={
                  <Trans
                    i18nKey="registrationPage.taxResident.areYouPoliticallyExposedPerson"
                    components={{
                      button: (
                        <DeprecatedButton
                          buttonClass="button__info"
                          handleClick={() => {
                            setIsOpenPepInfo(true);
                          }}
                        />
                      ),
                    }}
                    t={t}
                  />
                }
              />

              <div>
                <div className="radio-grouped">
                  <div className="hide">{errorMessage}</div>
                </div>

                <div className="buttons-part">
                  <div className="form-field">
                    <DeprecatedButton
                      withLoader={showLoader}
                      title={t('common.back')}
                      buttonClass="button back"
                      handleClick={() => goBack(REGISTRATION_PERSONAL_ADDRESS)}
                    />
                  </div>

                  <div className="form-field">
                    <DeprecatedButton
                      withLoader={showLoader}
                      type="submit"
                      title={t('common.next')}
                      buttonClass="button button--submit"
                    />
                  </div>
                </div>
              </div>
            </div>

            <InvestmentErrorModal
              isOpen={isOpenPepInfo}
              shouldCloseOnOverlayClick
              onRequestClose={() => setIsOpenPepInfo(false)}
              handleClick={() => setIsOpenPepInfo(false)}
              errorMessage={t('registrationPage.taxResident.pepInfo')}
            />
          </form>
        );
      }}
    />
  );
}

TaxResident.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      ssn: PropTypes.string,
    }),
  }).isRequired,
  taxResidentData: PropTypes.shape({
    pep: PropTypes.bool,
    no_tin: PropTypes.bool,
    tin_resident: PropTypes.bool,
    tin_country: PropTypes.string,
    no_tin_reason: PropTypes.string,
  }).isRequired,
  errorMessage: PropTypes.string,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  goBack: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
};

TaxResident.defaultProps = {
  errorMessage: '',
  showLoader: false,
};
