import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { HOME_ACTIVE_TAB, HOME_TABS } from '../../../constants/comonConstants';
import HistoryDetails from '../../HistoryDetails/HistoryDetails';
import { FutureTabWrapper } from '../styles';
import ChangePortfolio from '../../ChangePortfolio/ChangePortfolio';

import HomeWidgets from './HomeWidgets/HomeWidgets';

export default function HomeContent({ isNewRewards }) {
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get(HOME_ACTIVE_TAB);

  switch (activeTab) {
    case HOME_TABS.past:
      return <HistoryDetails />;
    case HOME_TABS.today:
      return <HomeWidgets isNewRewards={isNewRewards} />;

    case HOME_TABS.future:
      return (
        <FutureTabWrapper>
          <ChangePortfolio isFuture />
        </FutureTabWrapper>
      );

    default:
      return null;
  }
}

HomeContent.propTypes = {
  isNewRewards: PropTypes.bool.isRequired,
};
