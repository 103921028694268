import { Component } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { HistoryDateItem } from './styles';

import getTransactionNameObject from 'pages/TransactionCategorisation/utils/getTransactionNameObject';
import { DATE_FORMAT } from 'config/date';
import FallbackMessage from 'components/elements/FallbackMessage/FallbackMessage';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

class Categorisation extends Component {
  renderInfo(transactionCategorisationItem, t) {
    const { categoriesList } = this.props;
    const types = categoriesList;
    const subcategories = [];

    types.forEach((type) => {
      let subcategoriesArray = type.subcategories;

      if (subcategoriesArray) {
        subcategoriesArray = subcategoriesArray.map((el) => {
          const o = { ...el };

          o.parentCategory = type.id;

          return o;
        });

        subcategories.push(subcategoriesArray);
      }
    });

    const allTypeNames = types
      .concat(subcategories)
      .reduce((acc, val) => acc.concat(val), []);

    const transactionNameField =
      transactionCategorisationItem.custom_fields.find(
        (field) => field.name === 'custom_description',
      );

    const transactionNameObject = transactionNameField
      ? getTransactionNameObject({
          types: allTypeNames,
          value: transactionNameField.value,
        })
      : '';

    const { name } = transactionNameObject;

    const transactionName =
      name || t('myFinancePage.categorisation.uncategorised');

    return (
      <div key={transactionCategorisationItem.key}>
        <HistoryDateItem>
          {dayjs(transactionCategorisationItem.date).format(DATE_FORMAT)}
        </HistoryDateItem>

        <div className="my-finance__categorisation-item">
          <div className="my-finance__categorisation-title">
            <div className="my-finance__categorisation-status">
              {transactionName}
            </div>

            <div>
              {formatNumber({
                value: transactionCategorisationItem.amount,
              })}
            </div>
          </div>

          <div className="my-finance__categorisation-comment">
            {transactionCategorisationItem.description}
          </div>
        </div>
      </div>
    );
  }

  static renderFakeInfo(e, index) {
    return (
      <div key={`${index * 2}`}>
        <HistoryDateItem>
          <Skeleton />
        </HistoryDateItem>

        <div className="my-finance__categorisation-item">
          <div className="my-finance__categorisation-title">
            <div className="my-finance__categorisation-status">
              <Skeleton />
            </div>

            <div>
              <Skeleton />
            </div>
          </div>

          <div className="my-finance__categorisation-comment">
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }

  renderContent(transactionCategorisation, t) {
    if (transactionCategorisation && transactionCategorisation.length) {
      return transactionCategorisation.map((transactionCategorisationItem) =>
        this.renderInfo(transactionCategorisationItem, t),
      );
    }

    return (
      <FallbackMessage
        text={t(
          'myFinancePage.categorisation.fallbackMessage.emptyTransactionCategorisation',
        )}
      />
    );
  }

  render() {
    const { t, transactionCategorisation, showLoader } = this.props;

    return (
      <div className="info-panel my-finance__categorisation">
        <div className="info-panel__title">
          {t('myFinancePage.categorisation.title')}
        </div>

        {showLoader
          ? [...Array(3)].map((e, index) =>
              Categorisation.renderFakeInfo(e, index),
            )
          : this.renderContent(transactionCategorisation, t)}

        <div className="my-finance__categorisation-footer">
          <Button
            href="/transaction-categorisation"
            label={t('common.seeMore')}
          />
        </div>
      </div>
    );
  }
}

Categorisation.propTypes = {
  t: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transactionCategorisation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withTranslation()(Categorisation);
