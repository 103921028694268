import { IOpenFixAccountModal } from './types';
import { VisaWithoutBankCard } from './VisaWithoutBankCard';
import { BanksCard } from './BanksCard';
import { SpinnerWrapper } from './styles';

import { Spinner } from 'components/elements/Spinner';
import { useSpendingAccountsQuery } from 'store/spendingAccounts';

interface IAccountListProps {
  openFixAccountModal: IOpenFixAccountModal;
}

export const AccountList = ({ openFixAccountModal }: IAccountListProps) => {
  const { data, isLoading, isUninitialized, isError } =
    useSpendingAccountsQuery();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError || isUninitialized) {
    return null;
  }

  const { visaWithoutBank, banks } = data;

  return (
    <>
      {!!visaWithoutBank.length && (
        <VisaWithoutBankCard visaWithoutBank={visaWithoutBank} />
      )}

      {banks.map((bank) => (
        <BanksCard
          key={bank.id}
          bank={bank}
          openFixAccountModal={openFixAccountModal}
        />
      ))}
    </>
  );
};
