import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

class VerificationModal extends Component {
  render() {
    const {
      t,
      isShowVerificationModal,
      closeVerificationModal,
      titleVerification,
      setupFunding,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isShowVerificationModal}
          onRequestClose={() => closeVerificationModal()}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal time-investment__modal-quick modal-basic__verification"
        >
          <div className="time-investment__modal-title">
            {titleVerification}
          </div>

          <div className="time-investment__modal-content modal-basic__verification-content">
            <div className="modal-basic__verification-info">
              Maaf kami tidak bisa memverifikasi rekening bank yang kamu
              daftarkan.
            </div>

            <div className="modal-basic__verification-info -italic">
              Ikuti langkah berikut untuk memperbaiki:
            </div>

            <div className="modal-basic__verification-info -list">
              <div>{'1. Klik menu “Pengaturan” >> “Akun Bank” >> “Ubah”'}</div>

              <div>2. Pilih rekening yang ingin di daftarkan</div>

              <div>3. Masukkan detail rekening bank</div>

              <div>4. Lakukan konfirmasi perbuhan rekening</div>
            </div>

            <div className="modal-basic__verification-info -bold">
              Pastikan detail rekening terdaftar sesuai dengan buku tabungan dan
              bukan rekening E-Wallet atau Virtual Account.
            </div>

            <div className="modal-basic__verification-info">
              Apabila kamu sudah mendaftarkan rekening yang benar dan masih
              dapat pesan ini, mohon hubungi Customer Support untuk
              memperbaikinya.
            </div>
          </div>

          <div className="time-investment__modal-footer -single-btn">
            <DeprecatedButton
              title={t('common.cancel')}
              buttonClass="button__basic-fill -white"
              handleClick={() => closeVerificationModal(false)}
            />

            <Button label={t('common.repair')} onClick={setupFunding} />
          </div>
        </Modal>
      </div>
    );
  }
}

VerificationModal.propTypes = {
  t: PropTypes.func.isRequired,
  titleVerification: PropTypes.string.isRequired,
  isShowVerificationModal: PropTypes.bool.isRequired,
  closeVerificationModal: PropTypes.func.isRequired,
  setupFunding: PropTypes.func.isRequired,
};

export default withTranslation()(VerificationModal);
