export const GOAL_NAMES = {
  car: 'Car',
  holiday: 'Holiday',
  education: 'Education',
  birthday: 'Birthday',
  wedding: 'Wedding',
  anniversary: 'Anniversary',
  house: 'House',
  rent: 'Rent',
  deposit: 'Deposit',
} as const;

export const GOAL_NAME = 'goalName';
export const INVESTMENT_GOAL_ENABLED_NAME = 'isInvestmentGoalEnabled';
export const TARGET_AMOUNT_NAME = 'targetAmount';
export const GOAL_MODAL_SIZE = 455;
