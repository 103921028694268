import styled from '@emotion/styled';
import DatePicker from 'react-date-picker';

import { FieldWrapper } from '../styles';

export const DatePickerStyled = styled(DatePicker)`
  position: absolute;
  bottom: -7px;
  height: 100%;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};

  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__calendar-button {
    display: none;
  }

  &:active,
  &:focus {
    outline: none;
  }

  .react-date-picker__wrapper,
  .react-date-picker__inputGroup,
  .react-date-picker__calendar {
    &:active,
    &:focus {
      outline: none;
    }
  }

  input {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 0;
  }

  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.colors.niagara};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-date-picker__calendar .react-calendar {
    border-radius: 0;
    width: 270px;
    padding: 2px;
    border: 2px solid ${({ theme }) => theme.colors.niagara};
  }

  .react-calendar__navigation button {
    color: ${({ theme }) => theme.colors.niagara};
    font-size: ${({ theme }) => theme.fontSizes['4sm']};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontFamily.textRegular};

    &[disabled] {
      background: transparent;
      opacity: ${({ theme }) => theme.opacity.transparent};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    color: ${({ theme }) => theme.colors.boulder};
    font-family: ${({ theme }) => theme.fontFamily.textMedium};
  }

  .react-calendar__tile {
    height: 39px;
  }

  .react-calendar__tile:disabled {
    background: transparent;
  }

  .react-date-picker__wrapper {
    border: none;
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamily.textMedium};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    padding: 0;
  }

  .react-date-picker__inputGroup {
    width: 100%;
    padding: 0;
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    align-items: center;
    display: flex;

    input {
      font-family: ${({ theme }) => theme.fontFamily.textMedium};
      font-weight: bold;
      background: none;
    }
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.colors.niagara};
  }

  .react-date-picker--disabled {
    background-color: ${({ theme }) => theme.colors.transparent};
    color: inherit;
  }

  .react-date-picker__inputGroup__month {
    -webkit-appearance: none;
    -moz-appearance: none;
    pointer-events: none;
    background: none;
    text-align: center;
    opacity: ${({ isSelected, theme }) =>
      isSelected ? theme.opacity.opaque : theme.opacity.mediumTransparent};
  }
`;

export const DatePickerWrapper = styled(FieldWrapper)`
  height: 65px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.mercury};
  margin-bottom: 16px;
`;
