import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import { useDispatch } from 'react-redux';

import { Footer, DisagreeButton, NextButton } from '../../components/styles';
import { useMigrationAccountSteps } from '../../consts';

import { useHandleTermsRejection } from './hooks/useHandleTermsRejection';

import {
  setSuperAnnuationPDSAccepted,
  setUserPDSAccepted,
} from 'store/user/actions';
import { PATHS } from 'constants/paths';
import { useStepper } from 'hooks/useStepper';

export interface ITermsMigrationStepFooter {
  isLoading: boolean;
}

export default function TermsMigrationStepFooter({
  isLoading,
}: ITermsMigrationStepFooter) {
  const { t } = useTranslation();
  const steps = useMigrationAccountSteps();
  const { goNext } = useStepper({ steps });
  const dispatch = useDispatch();

  const { handleTermsRejection } = useHandleTermsRejection();
  const isSuperPds = useMatch(PATHS.migration.account.superPds);

  const handleConfirmTerms = () => {
    if (isSuperPds) {
      dispatch(setSuperAnnuationPDSAccepted());
    } else {
      dispatch(setUserPDSAccepted());
    }

    goNext();
  };

  return (
    <Footer>
      <NextButton
        isLoading={isLoading}
        onClick={handleConfirmTerms}
        label={t('common.agree')}
      />

      <DisagreeButton
        onClick={handleTermsRejection}
        label={t('common.disagree')}
        variant="secondary"
      />
    </Footer>
  );
}
