import React from 'react';
import 'styles/pages/history.scss';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WidgetButton } from './styles';

import { GET_HISTORY_TRANSACTIONS_SUCCESS } from 'store/history/consts';
import { Amount } from 'pages/HistoryDetails/components/styles';
import { formatNumber } from 'utils/formatters/formatNumber';

function historyTransactionRender(t, transaction, isSuper) {
  if (transaction.id) {
    let hisoryIcon = 'history__item-icon';

    if (transaction.amount < 0) {
      hisoryIcon = 'history__item-icon up';
    }

    let isRebalance = false;

    if ((transaction.type, transaction.type.search(/rebalance/i) !== -1)) {
      isRebalance = true;
    }

    let pending = '';

    if (isSuper && !transaction.pending && !isRebalance) {
      hisoryIcon = 'history__item-icon point';

      pending = 'item-pending';
    }

    const isSellTransaction = transaction.amount < 0;

    return (
      <div
        key={`history__widget_${transaction.id}`}
        className={
          isRebalance
            ? 'history__item history__item-rebalans'
            : `history__item ${pending}`
        }
      >
        <div className={hisoryIcon} />

        <div className="history__item-info">
          <div className="history__item-type">{transaction.type}</div>

          {!isSuper && transaction.grouped_status === 'pending' && (
            <div className="history__item-processing">
              <div className="history__item-processing-item -active" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-status">
                {t('common.status.pending')}
              </div>
            </div>
          )}

          {!isSuper && transaction.grouped_status === 'transferring' && (
            <div className="history__item-processing">
              <div className="history__item-processing-item  -complete" />

              <div className="history__item-processing-item  -active" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-status">
                {transaction.grouped_status}
              </div>
            </div>
          )}

          {isSuper && !isRebalance && transaction.pending && (
            <div className="history__item-processing">
              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-status">&nbsp;</div>
            </div>
          )}
        </div>

        <Amount isSellTransaction={isSellTransaction}>
          {formatNumber({ value: transaction.amount })}
        </Amount>
      </div>
    );
  }

  return false;
}

function historyTransactionRenderFake(isSuper) {
  return (
    <div
      className={
        isSuper ? 'history-contener super super-dashboard' : 'history-contener'
      }
    >
      {[...Array(4)].map((e, index) => (
        <div key={`history__widget_${index * 2}`} className="history__item">
          <div className="history__item-skeleton">
            <Skeleton />
          </div>

          <div className="history__item-info">
            <div className="history__item-type">
              <Skeleton />
            </div>

            <div className="history__item-processing">
              <Skeleton />

              <div className="history__item-processing-status">
                <Skeleton />
              </div>
            </div>
          </div>

          <Amount>
            <Skeleton width="50px" />
          </Amount>
        </div>
      ))}
    </div>
  );
}

export default function HistoryView({ isSuper }) {
  const { t } = useTranslation();

  const { transactions, type } = useSelector((state) => ({
    transactions: state.history.transactions,
    type: state.history.type,
    accountSummary: state.history.accountSummary,
  }));

  return (
    <div className="info-panel history-widget__panel">
      {isSuper ? (
        <div className="info-panel__title">
          {t('historyWidget.title.history')}
        </div>
      ) : (
        <div className="info-panel__title">
          {t('historyWidget.title.recentHistory')}
        </div>
      )}

      <div className="info-panel__divider" />

      {transactions && transactions.length !== 0 && (
        <div
          className={
            isSuper
              ? 'history-contener super super-dashboard'
              : 'history-contener'
          }
        >
          {transactions.map((transaction) =>
            historyTransactionRender(t, transaction, isSuper),
          )}
        </div>
      )}

      {(!transactions || transactions.length === 0) &&
        type !== GET_HISTORY_TRANSACTIONS_SUCCESS &&
        historyTransactionRenderFake(isSuper)}

      {(!transactions || transactions.length === 0) &&
        type === GET_HISTORY_TRANSACTIONS_SUCCESS && (
          <div className="history-empty">
            {t('historyWidget.noPendingTransactions')}
          </div>
        )}

      {isSuper ? (
        <WidgetButton
          href="/super/history"
          label={t('historyWidget.button.historyDetails')}
        />
      ) : (
        <WidgetButton
          href="/history-details"
          label={t('historyWidget.button.historyDetails')}
        />
      )}
    </div>
  );
}

HistoryView.propTypes = {
  isSuper: PropTypes.bool.isRequired,
};
