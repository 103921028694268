import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';
import { DATE_FORMAT } from '../../../config/date';

import { formatNumber } from 'utils/formatters/formatNumber';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

Modal.setAppElement('#root');

export default function CancelTransactionModal({
  isCancelTransactionModalOpen,
  displayCancelTransactionConfirmationAlert,
  isSuper,
  currentTransaction,
  isCancelTransactionLoading,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isCancelTransactionModalOpen}
      onRequestClose={() =>
        displayCancelTransactionConfirmationAlert({
          isTransactionModalOpen: false,
        })
      }
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-title">
        {t('transactionModal.title')}
      </div>

      <div className="time-investment__modal-content">
        {isSuper ? (
          <Trans
            i18nKey="transactionModal.super.description"
            t={t}
            components={{
              date: dayjs(currentTransaction.created_at).format(DATE_FORMAT),
              amount: formatNumber({
                value: currentTransaction.amount,
              }),
            }}
          />
        ) : (
          t('transactionModal.description')
        )}
      </div>

      <div className="time-investment__modal-footer">
        <DeprecatedButton
          withLoader={isCancelTransactionLoading}
          title={t('common.no')}
          buttonClass="button__basic-transparent -black"
          handleClick={() => {
            displayCancelTransactionConfirmationAlert({
              isTransactionModalOpen: false,
            });
          }}
        />

        <Button
          isLoading={isCancelTransactionLoading}
          label={t('common.yes')}
          onClick={() => {
            displayCancelTransactionConfirmationAlert({
              isTransactionModalOpen: false,
              transaction: '',
              confirm: true,
            });
          }}
        />
      </div>
    </Modal>
  );
}

CancelTransactionModal.defaultProps = {
  isSuper: false,
};

CancelTransactionModal.propTypes = {
  isCancelTransactionModalOpen: PropTypes.bool.isRequired,
  displayCancelTransactionConfirmationAlert: PropTypes.func.isRequired,
  isCancelTransactionLoading: PropTypes.bool.isRequired,
  isSuper: PropTypes.bool,
  currentTransaction: PropTypes.shape({
    created_at: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};
