import React from 'react';
import { useTranslation } from 'react-i18next';

import { changeInValue } from '../utils';

import { ChangeValueWrapper } from './styles';
import { ChangeValueDashboard } from './ChangeValueDashboard';

import { MY_APP } from 'constants/localeConfigs';

interface IChangeValueProps {
  periodDays: number;
  isDashboard: boolean;
  dataPointIndex: number;
}

type TChangePeriodKey = '1d' | '1m' | '3m' | '6m' | '1y' | 'all';

export const ChangeValue = ({
  periodDays,
  isDashboard,
  dataPointIndex,
}: IChangeValueProps) => {
  const { t } = useTranslation();

  const changePeriodKey = changeInValue(periodDays) as TChangePeriodKey;

  if (isDashboard) {
    return <ChangeValueDashboard dataPointIndex={dataPointIndex} />;
  }

  if (MY_APP) {
    return null;
  }

  return (
    <ChangeValueWrapper>
      {t('performanceDetailsPage.changeInValue', {
        period: t(`performanceDetailsPage.changePeriod.${changePeriodKey}`),
      })}
    </ChangeValueWrapper>
  );
};
