import { css } from '@emotion/react';
import styled from '@emotion/styled';

import inviteBackground from 'assets/images/inite-bg.svg';
import inviteMyBackground from 'assets/images/invite-bg-mys.svg';
import phoneImage from 'assets/images/phone-image.png';
import { MY_APP } from 'constants/localeConfigs';
import { RaizTheme } from 'theme';

export interface IAdditionalFieldWrapper {
  isUppercase: boolean;
}

export const AdditionalFieldWrapper = styled.div<IAdditionalFieldWrapper>`
  ${({ isUppercase }) => isUppercase && 'label {text-transform: uppercase;}'}
`;

export const SuitabilitiesQuestionsLabel = styled.div<IAdditionalFieldWrapper>`
  ${({ isUppercase }) => isUppercase && 'text-transform: uppercase;'}
`;

interface IPhoneImage {
  theme: RaizTheme;
}

const PhoneImageStyle = ({ theme }: IPhoneImage) => css`
  > span {
    position: relative;
  }

  > span::after {
    content: '';
    background-image: url(${phoneImage});
    height: 900px;
    width: 1000px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    right: -700px;
    top: -50px;

    ${theme.mediaQuery(theme.breakpoints.wide)} {
      display: none;
    }
  }
`;

export const InviteBanner = styled.div`
  background-image: ${() =>
    MY_APP ? `url(${inviteMyBackground})` : `url(${inviteBackground})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 90px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding-bottom: 40px;
  }
`;

export const Title = styled.div`
  color: white;
  font-size: ${({ theme }) => theme.fontSizes['7xl']};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  position: relative;
  padding-top: 100px;
  margin-right: 500px;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.wide)} {
    margin-right: 0;
    padding-top: 60px;
  }

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    font-size: ${({ theme }) => theme.fontSizes['3lg']};
    padding-top: 50px;
  }

  ${PhoneImageStyle}
`;

export const Wrapper = styled.div`
  align-self: center;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 64px;
  border-radius: 16px;
  margin-right: 500px;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.wide)} {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 80%;
  }

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.extraSmall)} {
    width: 95%;
  }

  .form {
    margin-top: 0;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
`;

export const FooterDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
`;
