import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/invest.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import DOMPurify from 'dompurify';

import Time from '../../../components/elements/Time/Time';
import checkIsRewardShareModal from '../utils/checkIsRewardShareModal';

import {
  TransactionContent,
  RewardTransactionContent,
  LetYourFriendsButton,
} from './styles';
import selectRewardById from './selectors';
import { Allocation } from './Allocation';
import { Reward } from './Reward';

import { AU_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function RewardTransaction({ toggleShareRewardModal }) {
  const { transactionDetails } = useSelector((state) => ({
    transactionDetails: state.history.transactionDetails || {},
  }));

  const { t } = useTranslation();

  const { id, details, type, amount, created_at, type_help, title } =
    transactionDetails;

  const reward = useSelector((state) => selectRewardById(state, id));
  const isRewardShareModal = checkIsRewardShareModal(type);

  const {
    offer: { campaign_owner_name, description },
  } = reward;

  return (
    <div className="history-details">
      <div
        className={
          details?.length > 0
            ? 'history__right-part'
            : 'history__right-part history__right-part-single'
        }
      >
        <Sticky topOffset={-65}>
          <TransactionContent>
            <div className="history__right-part-type">
              {campaign_owner_name || title}
            </div>

            <div className="history__right-part-total">
              {formatNumber({
                value: amount,
              })}
            </div>

            <div className="history__right-part-date">
              <Time>{created_at}</Time>
            </div>

            <RewardTransactionContent>
              <Reward />

              <Allocation />
            </RewardTransactionContent>

            <div
              className="history__right-part-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description || type_help),
              }}
            />

            {isRewardShareModal && !AU_APP && (
              <LetYourFriendsButton
                title={t('historyDetailsPage.transactionItem.letYourFriends')}
                handleClick={toggleShareRewardModal}
              />
            )}
          </TransactionContent>
        </Sticky>
      </div>
    </div>
  );
}

RewardTransaction.propTypes = {
  toggleShareRewardModal: PropTypes.func.isRequired,
};
