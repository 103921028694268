import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

import '../../../styles/layout/logout.scss';

const CustomLink = (props) => {
  const { href, target, title } = props;

  return (
    <a href={href} target={target}>
      {title}
    </a>
  );
};

CustomLink.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default function ChangePortfolioConfirmModal({
  currentPortfolioName,
  isShowConfirm,
  showBtnLoader,
  savePortfolio,
  handleCloseModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirm}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert confirm-modal"
    >
      <div className="modal-alert-content">
        <div className="alert-title">
          {t('changePortfolioPage.confirmModal.title')}
        </div>

        <div className="alert-text">
          <Trans
            i18nKey="changePortfolioPage.confirmModal.content"
            t={t}
            values={{
              currentPortfolioName,
            }}
            components={{
              name: <span className="portfolio-name" />,
              span: <span />,
              b: <b />,
            }}
          />
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            withLoader={showBtnLoader}
            title={t('changePortfolioPage.confirmModal.cancel')}
            type="button"
            buttonClass="wait"
            handleClick={handleCloseModal}
          />

          <DeprecatedButton
            withLoader={showBtnLoader}
            title={t('changePortfolioPage.confirmModal.submitButton')}
            type="button"
            buttonClass="skip"
            handleClick={savePortfolio}
          />
        </div>
      </div>
    </Modal>
  );
}

ChangePortfolioConfirmModal.defaultProps = {
  showBtnLoader: false,
};

ChangePortfolioConfirmModal.propTypes = {
  isShowConfirm: PropTypes.bool.isRequired,
  showBtnLoader: PropTypes.bool,
  savePortfolio: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  currentPortfolioName: PropTypes.string.isRequired,
};
