import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import { PRIVACY_POLICY } from '../../../../constants/localeConfigs';
import { MissingDocumentUploader } from '../MissingDocumentUploaderContent/MissingDocumentUploader';
import { ALERT_TYPES } from '../../../../constants/alerts';
import {
  REJECTED_FILE_TYPE,
  REJECTED_MAX_FILES,
  REMOVED,
} from '../../../../constants/comonConstants';
import { uploadDocument } from '../../../../store/user/actions';
import { UPLOAD_FAILED } from '../../../../store/user/consts';

import {
  DOCUMENT_STATEMENT,
  MISSING_DOCUMENT_TYPE,
  NEW_DOCUMENT_STATEMENT,
} from './consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';

function DocumentMissingAlertModal({
  currentAlert,
  isUploadInfoAlertOpen,
  toggleAlertInfoModal,
  isLoader,
  setIsLoader,
  afterUpload,
  type,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [firstDocument, setFirstDocument] = useState({});
  const [secondDocument, setSecondDocument] = useState({});

  const isProofOfAddressType =
    currentAlert.type ===
    ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress;

  const isBankStatementsAlert = [
    ALERT_TYPES.idUploadErrors.bankStatementUploadMissing,
    ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements,
  ].includes(currentAlert.type);

  const isMultipleDocumentsUpload =
    currentAlert.type !==
      ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements &&
    !isProofOfAddressType;

  const missingDocumentType =
    currentAlert.type ===
      ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements &&
    currentAlert.metadata === NEW_DOCUMENT_STATEMENT
      ? MISSING_DOCUMENT_TYPE.SECOND
      : MISSING_DOCUMENT_TYPE.FIRST;

  const handleChangeStatusStatements = ({ file }, status, documentType) => {
    if (status !== REJECTED_MAX_FILES) {
      const fileObject =
        status === REMOVED || status === REJECTED_FILE_TYPE ? {} : file;

      if (documentType === MISSING_DOCUMENT_TYPE.FIRST) {
        setFirstDocument(fileObject);
      } else if (documentType === MISSING_DOCUMENT_TYPE.SECOND) {
        setSecondDocument(fileObject);
      }
    }
  };

  const areAllFilesLoaded =
    ((firstDocument.name || secondDocument.name) &&
      !isMultipleDocumentsUpload) ||
    (isMultipleDocumentsUpload && firstDocument.name && secondDocument.name);

  const submitDocument = () => {
    const isUploadingBankStatement =
      currentAlert.type ===
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements ||
      isProofOfAddressType;

    const documentType =
      currentAlert.type ===
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements ||
      isProofOfAddressType
        ? currentAlert.metadata
        : DOCUMENT_STATEMENT;

    if (areAllFilesLoaded) {
      setIsLoader(true);

      const documentObject = firstDocument?.name
        ? firstDocument
        : secondDocument;

      dispatch(
        uploadDocument({
          documentObject,
          documentType,
          isFunding: isUploadingBankStatement,
        }),
      ).then((statusCode) => {
        if (type === UPLOAD_FAILED) {
          setIsLoader(false);

          return;
        }

        if (!isMultipleDocumentsUpload) {
          afterUpload(statusCode);

          return;
        }

        dispatch(
          uploadDocument({
            documentObject: secondDocument,
            documentType,
            isFunding: isUploadingBankStatement,
          }),
        ).then(() => {
          afterUpload(statusCode);
        });
      });
    }
  };

  const subTitle = isMultipleDocumentsUpload
    ? t('alertsPage.documentMissingAlertModal.uploadMultipleStatements')
    : t('alertsPage.documentMissingAlertModal.uploadSingleStatement');

  return (
    <Modal
      isOpen={isUploadInfoAlertOpen}
      onRequestClose={() => toggleAlertInfoModal(false, currentAlert)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className={`modal-basic modal-alert -white ${
        isBankStatementsAlert ? 'modal-basic__alert' : ''
      }`}
    >
      <div className="modal-basic__header">
        <DeprecatedButton
          buttonClass="button__close -black"
          handleClick={() => toggleAlertInfoModal(false, currentAlert)}
        />

        {t('alertsPage.documentMissingAlertModal.extendedVerification')}
      </div>

      <div className="modal-basic__modal-description">
        {isProofOfAddressType ? (
          <Trans
            i18nKey="alertsPage.documentMissingAlertModal.proofOfAddressDescription"
            t={t}
            components={{
              link: (
                <ExternalLink
                  title={t('common.privacyPolicy')}
                  href={PRIVACY_POLICY}
                />
              ),
            }}
          />
        ) : (
          <Trans
            i18nKey="alertsPage.documentMissingAlertModal.bankStatementVerificationDescription"
            t={t}
            components={{
              link: (
                <ExternalLink
                  title={t('common.privacyPolicy')}
                  href={PRIVACY_POLICY}
                />
              ),
            }}
          />
        )}
      </div>

      <div className="modal-basic__alert-subtitle">
        {isProofOfAddressType
          ? t('alertsPage.documentMissingAlertModal.proofOfAddress')
          : subTitle}
      </div>

      <MissingDocumentUploader
        handleChangeStatusStatements={handleChangeStatusStatements}
        type={missingDocumentType}
        documentType={currentAlert.type}
      />

      {isMultipleDocumentsUpload && (
        <MissingDocumentUploader
          handleChangeStatusStatements={handleChangeStatusStatements}
          type={MISSING_DOCUMENT_TYPE.SECOND}
        />
      )}

      <DeprecatedButton
        withLoader={isLoader}
        disabled={!areAllFilesLoaded}
        title={t('common.submit')}
        buttonClass={`button__basic-fill ${
          areAllFilesLoaded ? '-green' : '-dark-grey'
        }`}
        handleClick={() => submitDocument()}
      />
    </Modal>
  );
}

DocumentMissingAlertModal.propTypes = {
  isUploadInfoAlertOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  setIsLoader: PropTypes.func.isRequired,
  afterUpload: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isLoader: PropTypes.bool.isRequired,
  currentAlert: PropTypes.shape({
    type: PropTypes.string,
    metadata: PropTypes.string,
  }).isRequired,
};

export default DocumentMissingAlertModal;
