import {
  FORBIDDEN_IDS_FOR_INDUSTRIAL_QUESTION,
  SUITABILITIES_PICKER_ID,
} from './consts';

import { IS_KIDS_ACCESS_PROVIDED } from 'constants/comonConstants';
import { MY_APP } from 'constants/localeConfigs';
import { checkIsDependentUser } from 'utils/user';

interface ICheckIsUserWithInvestAccess {
  user: {
    user_type: 'dependent' | 'regular';
  };
}

interface ICheckIsIndustryFieldVisible {
  value?: string;
  values?: Record<
    typeof SUITABILITIES_PICKER_ID.sourceOfIncome,
    { value: string }[]
  >;
}

export const checkIsUserWithInvestAccess = ({
  user,
}: ICheckIsUserWithInvestAccess) => {
  const accessParsed =
    IS_KIDS_ACCESS_PROVIDED && JSON.parse(IS_KIDS_ACCESS_PROVIDED);

  if (MY_APP && checkIsDependentUser(user)) {
    return true;
  }

  if (
    (!user || checkIsDependentUser(user)) &&
    accessParsed &&
    !accessParsed.investing
  ) {
    return false;
  }

  return true;
};

export const checkIsIndustryFieldVisible = ({
  value,
  values,
}: ICheckIsIndustryFieldVisible) => {
  const currentValue =
    value || values?.[SUITABILITIES_PICKER_ID.sourceOfIncome]?.[0]?.value;

  return (
    currentValue &&
    FORBIDDEN_IDS_FOR_INDUSTRIAL_QUESTION?.includes(currentValue)
  );
};
