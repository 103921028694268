import styled from '@emotion/styled';

import {
  InsuranceStatusWrapper,
  CoverageOfferedWrapper,
  InsuranceCoverageInfoWrapper,
} from '../styles';

export const InsuranceSkeletonButtonWrapper = styled.div`
  text-align: right;
  flex: 0.2;
`;

export const StatusSkeletonWrapper = styled(InsuranceStatusWrapper)`
  padding: 0 16px 10px;
`;

export const CoverageOfferedSkeletonWrapper = styled(CoverageOfferedWrapper)`
  padding: 20px 16px 14px;
`;

export const InsuranceCoverageInfoSkeleton = styled(
  InsuranceCoverageInfoWrapper,
)`
  padding: 18px 16px 14px;
`;
