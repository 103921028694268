import { useTranslation, Trans } from 'react-i18next';
import { Controller } from 'react-hook-form';

import {
  Browse,
  ClearButton,
  Description,
  IconDocument,
  IconUpload,
  Input,
  Label,
  Wrapper,
} from './styles';

interface IFileUploadFieldProps {
  name: string;
  label: string;
  height?: number;
}

const DEFAULT_FILE_UPLOAD_FIELD_HEIGHT = 104;

export const FileUploadField = ({
  name,
  label,
  height = DEFAULT_FILE_UPLOAD_FIELD_HEIGHT,
}: IFileUploadFieldProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange } }) => (
        <Wrapper isValid={!!value?.name} height={height}>
          {value?.name ? (
            <>
              <ClearButton onClick={() => onChange('')} />

              <IconDocument />

              <Label>{label}</Label>
            </>
          ) : (
            <>
              <Description>
                <IconUpload />

                <Trans
                  t={t}
                  i18nKey="fields.upload.instruction"
                  components={{
                    strong: <Browse />,
                  }}
                />
              </Description>

              <Input
                type="file"
                value=""
                onChange={(event) => onChange(event?.target?.files?.[0] || '')}
                accept="image/*"
              />
            </>
          )}
        </Wrapper>
      )}
    />
  );
};
