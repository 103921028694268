import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import superBannerPng from 'assets/images/super-banner.png';

export const BannerWrapper = styled.div<{ isRaizSuper?: boolean }>`
  margin-bottom: 10px;
  padding-top: 49px;
  border-radius: 6px;
  position: relative;
  width: 100%;
  background: ${({ theme, isRaizSuper }) =>
    isRaizSuper
      ? `
    url(${superBannerPng}),
    ${theme.gradients.purple}
    `
      : theme.gradients.green};
  background-size: 100%;
  ${({ isRaizSuper }) =>
    isRaizSuper &&
    `
    padding-bottom: 30px;
    `}
`;

export const BannerTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
`;

export const BannerLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-top: 17px;
`;

export const BannerValue = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  border: none;
  text-align: center;
  margin-bottom: 25px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const BannerSwitcherWrapper = styled.div`
  margin-bottom: 12px;
  display: inline-flex;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.blackTransparent};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 3px;
`;

export const BannerSwitcherItem = styled(Button)<{ isActive: boolean }>`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  -webkit-appearance: button;
  padding: 4px 23px;
  background: transparent;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  height: max-content;
  min-width: 160px;

  ${({ theme, isActive }) =>
    isActive &&
    `
      border-radius: 15px;
      background-color: ${theme.colors.white};
      color: ${theme.colors.mirage};
  `};
`;
