import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../configureStore';
import { selectChildSummary } from '../dependentUser/selectors';

export const selectTransactionDetails = (state: RootState) =>
  state.history?.transactionDetails || {};

export const selectAccountSummary = (state: RootState) =>
  state.history.accountSummary || {};

export const selectUserAccountSummary = ({ isKids }: { isKids: boolean }) =>
  createSelector(
    [isKids ? selectChildSummary : selectAccountSummary],
    (accountSummary) => accountSummary,
  );

export const selectCashRewards = ({ isKids }: { isKids: boolean }) =>
  createSelector([selectUserAccountSummary({ isKids })], (accountSummary) => {
    const {
      total_referrals_rewarded,
      total_found_money_rewarded,
      other_rewards_rewarded,
    } = accountSummary;

    if (
      !total_referrals_rewarded &&
      !total_found_money_rewarded &&
      !other_rewards_rewarded
    ) {
      return 0;
    }

    const cashRewards =
      total_found_money_rewarded.value +
      total_referrals_rewarded.value +
      other_rewards_rewarded.value;

    return cashRewards || 0;
  });
