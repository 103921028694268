import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import WithdrawalSignInModal from '../../../components/layout/Modals/WithdrawalSignInModal';
import Loader from '../../../components/elements/Loader/Loader';

import Banks from './Banks';
import { ButtonWrapper, SeeMoreBanksButton } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectWithdrawalAccount } from 'store/fundingAccount/selectors';
import { getWithdrawalAccountSuccess } from 'store/fundingAccount/actions';

const useWithdrawalAccountPanel = () => {
  const { t } = useTranslation();

  const account = (withdrawalAccount, showConfirm) => (
    <div>
      <div key="info-panel" className="info-panel -company-name -with-padding">
        <div className="info-panel__title">
          <img alt="" src={withdrawalAccount.funding.icon} width="35" />

          {`${withdrawalAccount.funding.name} (${withdrawalAccount.funding.last_4})`}

          <DeprecatedButton
            title={t('common.change')}
            buttonClass="button__basic-wtbackground settings__spending-account-unlink withdrawal-change"
            handleClick={showConfirm}
          />
        </div>

        <div className="info-panel__divider" />

        {withdrawalAccount &&
          withdrawalAccount.funding &&
          withdrawalAccount.funding.alert &&
          withdrawalAccount.funding.alert.description && (
            <div className="info-panel__item settings__spending-account-warning">
              {withdrawalAccount.funding.alert.description}
            </div>
          )}

        <div className="info-panel__item">
          <div className="name">
            {t('settingsPage.fundingAccountPage.verified')}
          </div>

          <div className="checkbox__plus checkbox__check">
            +
            <input disabled type="checkbox" />
          </div>
        </div>
      </div>

      <div className="profile-content__comment">
        <div className="profile-content__comment-info withdrawal-note">
          {t(
            'settingsPage.withdrawalAccountPage.withdrawalPanel.delayWithdrawalAccountMessage',
          )}
        </div>
      </div>
    </div>
  );

  return { account };
};

export default function WithdrawalAccountView({
  showBanks,
  isShowBanks,
  signInBank,
  isShowBankModal,
  handleCloseModal,
  bank,
  bankInSubmit,
  handleChangeBank,
  credentials,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  monitoredInstitutions,
  accountChange,
  openDifferentBank,
  showMainLoader,
  showLoader,
  isShowWithdrawalSignIn,
  isShowAddAccount,
  closeModal,
  showConfirm,
  isShowConfirm,
}) {
  const { t } = useTranslation();
  const { account } = useWithdrawalAccountPanel();
  const dispatch = useAppDispatch();
  const withdrawalAccount = useAppSelector(selectWithdrawalAccount);

  useEffect(() => {
    dispatch(getWithdrawalAccountSuccess());
  }, [dispatch]);

  return (
    <div className="settings__funding-account">
      <div className="profile-content settings__content-default-container">
        <div className="profile-content__header">
          <div className="profile-content__title">
            {isShowBanks
              ? t('settingsPage.withdrawalAccountPage.link')
              : t('settingsPage.withdrawalAccountPage.title')}
          </div>
        </div>

        <div className="profile-content__container">
          <div className="profile-content__container-item settings__funding-account-info">
            {withdrawalAccount?.funding && !isShowBanks && (
              <div>{t('settingsPage.accountFundsInvestments')}</div>
            )}

            {!withdrawalAccount?.funding &&
              monitoredInstitutions.length === 0 &&
              !isShowBanks && (
                <div>{t('settingsPage.accountFundsInvestments')}</div>
              )}

            {isShowBanks && (
              <div>
                {t(
                  'settingsPage.withdrawalAccountPage.chooseBankForMostPurchasesMessage',
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {!showMainLoader && isShowConfirm && (
        <div>
          <Modal
            hasBackdrop={false}
            isVisible
            isOpen
            contentLabel="modal-alert"
            shouldCloseOnOverlayClick
            className="modal-basic bank__alert"
          >
            <div className="modal-alert-content">
              <div className="alert-text">
                {t(
                  'settingsPage.withdrawalAccountPage.withdrawalChangeModal.message',
                )}
              </div>

              <div className="alert-buttons">
                <DeprecatedButton
                  title={t('common.cancel')}
                  type="button"
                  buttonClass="wait"
                  handleClick={closeModal}
                />

                <DeprecatedButton
                  title={t('common.ok')}
                  type="button"
                  buttonClass="skip"
                  handleClick={accountChange}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}

      {withdrawalAccount?.funding &&
        (monitoredInstitutions.length === 0 || isShowWithdrawalSignIn) &&
        !isShowBanks &&
        !showMainLoader &&
        !isShowAddAccount &&
        account(withdrawalAccount, showConfirm)}

      {((!withdrawalAccount?.funding &&
        (monitoredInstitutions.length === 0 || isShowWithdrawalSignIn)) ||
        isShowAddAccount) &&
        !isShowBanks &&
        !showMainLoader && (
          <div className="button-part">
            <div className="empty-content">
              <div className="title">
                <i className="bank__modal-secured-icon" />

                {t('settingsPage.secured')}
              </div>

              <div>
                {t('settingsPage.withdrawalAccount.chooseBankWithdrawal')}
              </div>
            </div>

            <ButtonWrapper>
              <SeeMoreBanksButton
                label={t(
                  'settingsPage.withdrawalAccountPage.addWithdrawal.button',
                )}
                onClick={showBanks}
              />
            </ButtonWrapper>
          </div>
        )}

      {isShowBanks && !showMainLoader && (
        <div>
          <Banks
            handleClick={signInBank}
            openDifferentBank={openDifferentBank}
            isWithdrawal
          />
        </div>
      )}

      {(isShowWithdrawalSignIn || isShowBankModalError) && !isShowBankModal && (
        <WithdrawalSignInModal
          isShowBankModal={isShowWithdrawalSignIn}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={bankInSubmit}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
        />
      )}

      {showMainLoader && <Loader />}
    </div>
  );
}

WithdrawalAccountView.defaultProps = {
  showLoader: false,
  isShowBanks: false,
  bankInSubmit() {},
  handleChangeBank() {},
  monitoredInstitutions: [],
  isShowWithdrawalSignIn: false,
};

WithdrawalAccountView.propTypes = {
  showBanks: PropTypes.func.isRequired,
  isShowBanks: PropTypes.bool,
  signInBank: PropTypes.func.isRequired,
  isShowBankModal: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.shape({}).isRequired,
  bankInSubmit: PropTypes.func,
  handleChangeBank: PropTypes.func,
  credentials: PropTypes.shape({}).isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  accountChange: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  openDifferentBank: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  fundingPaymentCards: PropTypes.shape({}).isRequired,
  isShowWithdrawalSignIn: PropTypes.bool,
  isShowAddAccount: PropTypes.bool.isRequired,
  showConfirm: PropTypes.func.isRequired,
  isShowConfirm: PropTypes.bool.isRequired,
};
