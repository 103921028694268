import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PrivacyPolicyLink from '../../../Links/PrivacyPolicyLink/PrivacyPolicyLink';

export default function UploadDocumentAlertDescription({ alertType }) {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey={`alertsPage.uploadId.alerts.${alertType}`}
      t={t}
      components={{
        link: <PrivacyPolicyLink />,
      }}
    />
  );
}

UploadDocumentAlertDescription.propTypes = {
  alertType: PropTypes.string.isRequired,
};
