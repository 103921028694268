import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const Footer = styled.div`
  padding: 10px 32px 10px 0;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const NextButton = styled(Button)`
  width: auto;
  padding: 0 66px;
  &:hover,
  &[hover] {
    opacity: ${({ theme }) => theme.opacity.almostOpaque};
  }
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 0;
    flex: 1;
  }
`;

export const BackButton = styled(NextButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mirage};
  border: 2px solid ${({ theme }) => theme.colors.alto};
`;

export const DisagreeButton = styled(BackButton)`
  color: ${({ theme }) => theme.colors.niagaraLight};
  border: 0;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 0;
    flex: 1;
  }
`;
