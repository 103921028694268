import { defineMessages } from 'react-intl';

import { RECURRING_INVESTMENT as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.chooseTheStartDay`]: 'Pilih hari permulaan',
  [`${idPrefix}.selectDayOfWeek`]: 'Pilih Hari',
  [`${idPrefix}.chooseFrequency`]: 'Sila pilih kekerapan pelaburan!',
  [`${idPrefix}.viewHistory`]: 'Lihat Lepas',
  [`${idPrefix}.later`]: 'Kemudian',
  [`${idPrefix}.chooseDay`]:
    'Pilih hari dalam sebulan untuk pelaburan berkala anda',
  [`${idPrefix}.stopRecurring`]: 'Hentikan Pelaburan Berkala',
  [`${idPrefix}.youHavePending`]:
    'Anda mempunyai pelaburan yang belum selesai. Anda boleh melihatnya di ' +
    'skrin Sejarah. Harap maklum beberapa pelaburan yang belum selesai mungkin tidak boleh dibatalkan.',
  [`${idPrefix}.ok`]: 'OK',
  [`${idPrefix}.changePlan`]: 'Tukar Pelan',
  [`${idPrefix}.nextInvestment`]: 'Pelaburan Seterusnya',
  [`${idPrefix}.currentBalance`]: 'Baki terkini:',
  [`${idPrefix}.cancel`]: 'Batal',
  [`${idPrefix}.recurringInvAmount`]: 'Jumlah Pelaburan Berkala',
  [`${idPrefix}.recurringInvestment`]: 'Auto-labur',
  [`${idPrefix}.everyDay`]: 'Harian',
  [`${idPrefix}.investmentFrequency`]: 'Kekerapan Pelaburan',
  [`${idPrefix}.setInvestment`]: 'Set',
  [`${idPrefix}.frequency`]: 'Kekerapan',
  [`${idPrefix}.setMonthlyOn`]: 'Tetapkan Bulanan on - {day}',
  [`${idPrefix}.setFortnightlyOn`]: 'Tetapkan Setiap Dua Minggu on - {day}',
  [`${idPrefix}.setFortnightly`]: 'Tetapkan Setiap Dua Minggu',
  [`${idPrefix}.setWeeklyOn`]: 'Tetapkan Mingguan on {day}',
  [`${idPrefix}.setDaily`]: 'Tetapkan Harian',
  [`${idPrefix}.stop`]: 'Hentikan',
  [`${idPrefix}.sureWantInvest`]:
    'Adakah anda pasti mahu menghentikan pelaburan setiap dua {day} {amount}?',
  [`${idPrefix}.fortnightlyOn`]: 'Setiap Dua Minggu pada {day}',
  [`${idPrefix}.monthlyOn`]: 'Bulanan pada {day}',
  [`${idPrefix}.stopInvestment`]: 'Hentikan Pelaburan {frequency}',
  [`${idPrefix}.daily`]: 'Harian',
  [`${idPrefix}.weekly`]: 'Mingguan',
  [`${idPrefix}.fortnightly`]: 'Setiap dua minggu',
  [`${idPrefix}.monthly`]: 'Bulanan',
  [`${idPrefix}.setDailyInvestment`]: 'Set Harian',
  [`${idPrefix}.setmonthlyInvestment`]: 'Set Bulanan',
  [`${idPrefix}.setweeklyInvestment`]: 'Set Mingguan',
  [`${idPrefix}.setfortnightlyInvestment`]: 'Tetapkan Setiap dua minggu',
  [`${idPrefix}.setdailyInvestment`]: 'Set Harian',
  [`${idPrefix}.lastDayOfMonth`]: 'Hari Terakhir Bulan',
  [`${idPrefix}.chooseInvestmentFrequency`]: 'Pilih kekerapan pelaburan',
  [`${idPrefix}.every`]: 'Setiap {day}',
  [`${idPrefix}.setFinancialGoal`]: 'Set target kewangan dan jejakinya',
  [`${idPrefix}.currentGoalNameError`]: 'Sila namakan target simpanan',
  [`${idPrefix}.details`]: 'Perincian',
  [`${idPrefix}.deposit`]: 'Deposit',
  [`${idPrefix}.savingGoal`]: 'Target Simpanan',
  [`${idPrefix}.savingsetFinancialGoalGoal`]:
    'Set target kewangan dan jejakinya',
  [`${idPrefix}.goalAmount`]: 'Jumlah Target',
  [`${idPrefix}.recurringInvestmentAmount`]: 'Jumlah Pelaburan Berkala',
  [`${idPrefix}.chooseGoal`]: 'Pilih Target',
  [`${idPrefix}.selectYourGoal`]: 'Pilih target anda',
  [`${idPrefix}.setGoalName`]: 'Tetapkan Target {goalName}',
  [`${idPrefix}.addCustomGoalName`]: 'Tambah nama target',
  [`${idPrefix}.targetAmountError`]: 'Sila masukkan jumlah target',
  [`${idPrefix}.forecastGoalDate`]: 'Ramalan Tarikh Target',
  [`${idPrefix}.editSavingGoal`]: 'Edit Target Simpanan',
  [`${idPrefix}.deleteSavingGoal`]: 'Padam Target Simpanan',
  [`${idPrefix}.setSavingGoal`]: 'Set Target Simpanan',
  [`${idPrefix}.targetAmountError`]: 'Sila masukkan jumlah target',
  [`${idPrefix}.yourGoal`]: 'Target Anda',
  [`${idPrefix}.nameGoal`]: 'Nama Target',
  [`${idPrefix}.frequencyOn`]: '{frequency} pada hari {day}',
  [`${idPrefix}.invAmountError`]:
    'Jumlah Pelaburan Berkala mesti lebih besar atau sama dengan RM5.',
  [`${idPrefix}.lessThanCurrentBalance`]:
    'Jumlah sasaran tidak boleh kurang daripada baki semasa!',
});

export default messages;
