import { useTranslation } from 'react-i18next';

import {
  IAllCategoryDetails,
  IToggleCategorisationInfo,
  ITransactionCategorisation,
} from '../types';

import TransactionInformation from './TransactionInformation';

import FallbackMessage from 'components/elements/FallbackMessage/FallbackMessage';

interface ITransactionCategorisationListProps {
  transactionCategorisation: ITransactionCategorisation[];
  toggleCategorisationInfo: IToggleCategorisationInfo;
  allCategoryDetails: IAllCategoryDetails[];
}

const TransactionCategorisationList = ({
  transactionCategorisation,
  toggleCategorisationInfo,
  allCategoryDetails,
}: ITransactionCategorisationListProps) => {
  const { t } = useTranslation();

  if (!transactionCategorisation.length)
    return (
      <FallbackMessage
        text={t('transactionCategorisationPage.emptyTransactionCategorisation')}
      />
    );

  return transactionCategorisation.map(
    (transactionCategorisationItem, index) => (
      <TransactionInformation
        key={transactionCategorisationItem.key}
        transaction={transactionCategorisationItem}
        previous={transactionCategorisation[index - 1]}
        toggleCategorisationInfo={toggleCategorisationInfo}
        types={allCategoryDetails}
      />
    ),
  );
};

export default TransactionCategorisationList;
