import React from 'react';

import { InsuranceDefaultCover } from './InsuranceDefaultCover';
import { InsuranceInfo } from './InsuranceInfo';
import { SuperInsuranceContentWrapper } from './styles';
import { SuperInsuranceSkeleton } from './SuperInsuranceSkeleton/SuperInsuranceSkeleton';

import { useSuperUserQuery } from 'store/superAnnuation/superAnnuation';

export const SuperInsuranceContent = () => {
  const { isFetching } = useSuperUserQuery();

  if (isFetching) {
    return <SuperInsuranceSkeleton />;
  }

  return (
    <SuperInsuranceContentWrapper>
      <InsuranceDefaultCover />

      <InsuranceInfo />
    </SuperInsuranceContentWrapper>
  );
};
