import { useTranslation } from 'react-i18next';

import { Container, Icon } from './styles';

export const NoItem = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon />

      {t('referralsPage.noItem')}
    </Container>
  );
};
