import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import SuperRewardContributionView from './SuperRewardContributionView';

import * as superActions from 'store/superAnnuation/actions';

class SuperRewardContribution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenInfoModal: false,
      transferRewards: false,
      showLoader: false,
    };

    this._isMounted = false;

    this.togglePersonalContribution =
      this.togglePersonalContribution.bind(this);

    this.updateSuperSettings = this.updateSuperSettings.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { actionsSuper } = this.props;

    this.setState({ showLoader: true });

    actionsSuper.getSuperSettings().then(() => {
      if (this._isMounted) {
        const { superSettings } = this.props;

        this.setState({ showLoader: false });

        if (superSettings) {
          this.setState({ transferRewards: superSettings.transfer_rewards });
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCloseModal() {
    const { actionsSuper } = this.props;

    actionsSuper.closeModal();

    this.setState({ transferRewards: false });
  }

  togglePersonalContribution(e) {
    const condition = e.target.checked;

    this.setState({ transferRewards: condition });

    if (condition) {
      this.updateSuperSettings(true);
    } else {
      this.updateSuperSettings(false);
    }
  }

  updateSuperSettings(transferRewards) {
    const { actionsSuper } = this.props;

    actionsSuper.updateTransferRewards(transferRewards);
  }

  render() {
    const { isOpenInfoModal, transferRewards, showLoader } = this.state;
    const { errorMessage, isShowErrorModal } = this.props;

    return (
      <SuperRewardContributionView
        showLoader={showLoader}
        togglePersonalContribution={this.togglePersonalContribution}
        isOpenInfoModal={isOpenInfoModal}
        transferRewards={transferRewards}
        errorMessage={errorMessage}
        handleCloseModal={this.handleCloseModal}
        isShowErrorModal={isShowErrorModal}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  superSettings: state.superAnnuation.superSettings,
  errorMessage: state.superAnnuation.errorMessage,
  isShowErrorModal: state.superAnnuation.isShowErrorModal,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSuper: bindActionCreators(superActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuperRewardContribution);

SuperRewardContribution.defaultProps = {
  actionsSuper: {},
  superSettings: {},
  errorMessage: '',
  isShowErrorModal: false,
};

SuperRewardContribution.propTypes = {
  actionsSuper: PropTypes.shape({
    getSuperSettings: PropTypes.func,
    updateTransferRewards: PropTypes.func,
    closeModal: PropTypes.func,
  }),
  superSettings: PropTypes.shape({
    transfer_rewards: PropTypes.bool,
  }),
  errorMessage: PropTypes.string,
  isShowErrorModal: PropTypes.bool,
};
