import React from 'react';
import { useTranslation } from 'react-i18next';

import { TERMS_CONDITIONS } from 'constants/localeConfigs';

function TermsAndConditionsLink() {
  const { t } = useTranslation();

  return <a href={`${TERMS_CONDITIONS}`}>{t('common.termsAndConditions')}</a>;
}

export default TermsAndConditionsLink;
