import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import Time from '../../../../components/elements/Time/Time';
import FinalFormInput from '../../../../components/elements/FinalFormInput/FinalFormInput';
import SelectFF from '../../../../components/elements/SelectFF/SelectFF';
import CheckboxFF from '../../../../components/elements/CheckboxFF/CheckboxFF';
import InvestmentErrorModal from '../../../../components/layout/Modals/InvestmentErrorModal';
import BankErrorModal from '../../../../components/layout/Modals/BankErrorModal';
import { checkIsDependentUser } from '../../../../utils/user';

import {
  ProfileFormContent,
  ProfileFormContentRow,
  ProfileFormContentTFN,
  TaxFileNumberCheckboxWrapper,
  TaxFileNumberDescription,
  TaxFileNumberRow,
  TaxFileNumberTitle,
  TaxFileNumberCheckboxLabel,
  CRSLink,
  UsCitizenWrapper,
  ConfirmButton,
} from './styles';
import { useSettingsTabSwitcher } from './hooks/useSettingsTabSwitcher';
import { PROFILE_TABS } from './hooks/const';
import { useProfilePage } from './hooks/useProfilePage';

import { useGetCountryStatesQuery } from 'store/assets/assets';
import { getPostCodeLength } from 'utils/formatters/getPostCodeLength';
import {
  AU_APP,
  COUNTRY,
  MY_APP,
  PHONE_NUMBER_MASK,
} from 'constants/localeConfigs';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};
    const required = t('fields.validation.required');

    if (!values.first_name) {
      errors.first_name = required;
    }

    if (AU_APP && !values.street_number) {
      errors.street_number = required;
    }

    if (!values.address1) {
      errors.address1 = required;
    }

    if (!values.city) {
      errors.city = required;
    }

    if (!values.state) {
      errors.state = required;
    }

    if (!values.zip) {
      errors.zip = required;
    }

    if (values.zip && values.zip.length !== getPostCodeLength()) {
      errors.zip = t('fields.validation.invalid');
    }

    return errors;
  };

  return { validate };
};

export default function ProfileForm({
  handleSubmit,
  user,
  errorMessage,
  handleChange,
  showLoader,
  errorMessagePhone,
  clickOnTfnExemption,
  isDisabledTfn,
  isShowModalError,
  handleCloseModalError,
  showNetworthInfo,
  toggleNetworthInfo,
  isShowModalSuccess,
  toggleSuccessModal,
}) {
  const { validate } = useValidation();
  const { t } = useTranslation();

  const getGetCountryStatesQuery = useGetCountryStatesQuery();

  const { activeTab } = useSettingsTabSwitcher();
  const { isUpdateProfilePage } = useProfilePage();

  if (activeTab === PROFILE_TABS.financial || isUpdateProfilePage) {
    return null;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          {errorMessage && isShowModalError && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              modalClassName="bank-sign"
              shouldCloseOnOverlayClick
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
            />
          )}

          {isShowModalSuccess && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              shouldCloseOnOverlayClick
              errorTitle={t(
                'settingsPage.profileContent.successUpdateProfileModal.title',
              )}
              errorMessage={t(
                'settingsPage.profileContent.successUpdateProfileModal.message',
              )}
              handleClick={() => toggleSuccessModal(false)}
            />
          )}

          <ProfileFormContent>
            <div className="profile-content__container">
              <div />

              {(AU_APP || MY_APP) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="first_name"
                    disabled
                    defaultValue={user.first_name}
                    label={
                      MY_APP
                        ? t('fields.fullName.label')
                        : t('fields.firstName.label')
                    }
                  />
                </ProfileFormContentRow>
              )}

              {AU_APP && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="last_name"
                    disabled
                    defaultValue={user.last_name}
                    label={t('fields.lastName.label')}
                  />
                </ProfileFormContentRow>
              )}

              {(AU_APP || MY_APP) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="email"
                    disabled
                    defaultValue={user.email}
                    label={
                      AU_APP
                        ? t('fields.email.label')
                        : t('fields.emailAddress.label')
                    }
                  />
                </ProfileFormContentRow>
              )}

              {AU_APP && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <div className="profile-content__container-label">
                    {t('fields.phoneNumber.label')}
                  </div>

                  <div className="profile-content__container-value">
                    <InputMask
                      name="phone_number"
                      defaultValue={user.phone_number}
                      mask="0499 999 999"
                      alwaysShowMask
                      permanents={[2, 5]}
                      onChange={handleChange}
                      formatChars={{
                        8: '[23478]',
                        9: '[0-9]',
                      }}
                    />

                    {errorMessagePhone && (
                      <div className="error-message">{errorMessagePhone}</div>
                    )}
                  </div>
                </ProfileFormContentRow>
              )}

              {AU_APP && !user.verified && (
                <ProfileFormContentRow>
                  <div className="profile-content__container-label">
                    {t('fields.dateOfBirth.label')}
                  </div>

                  <div className="profile-content__container-value">
                    <Time>{user.dob}</Time>
                  </div>
                </ProfileFormContentRow>
              )}

              {MY_APP && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <div className="profile-content__container-label">
                    {t('fields.phoneNumber.label')}
                  </div>

                  <div className="profile-content__container-value">
                    <InputMask
                      name="phone_number"
                      defaultValue={user.phone_number}
                      mask={PHONE_NUMBER_MASK[COUNTRY]}
                      maskChar={null}
                      placeholder="01_-_______"
                      permanents={[0, 1, 3]}
                      onChange={handleChange}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    />

                    {errorMessagePhone && (
                      <div className="error-message">{errorMessagePhone}</div>
                    )}
                  </div>
                </ProfileFormContentRow>
              )}

              {MY_APP && !user.verified && (
                <ProfileFormContentRow>
                  <div className="profile-content__container-label">
                    {t('fields.dateOfBirth.label')}
                  </div>

                  <div className="profile-content__container-value">
                    <Time>{user.dob}</Time>
                  </div>
                </ProfileFormContentRow>
              )}

              {AU_APP && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="street_number"
                    defaultValue={user.street_number}
                    label={t('fields.streetNumber.label')}
                  />
                </ProfileFormContentRow>
              )}

              {!checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="address1"
                    defaultValue={user.address1}
                    label={t('fields.address.label')}
                  />
                </ProfileFormContentRow>
              )}

              {AU_APP && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="address2"
                    defaultValue={user.address2}
                    label={t('fields.addressTwo.label')}
                  />
                </ProfileFormContentRow>
              )}

              {(AU_APP || MY_APP) && !checkIsDependentUser(user) && (
                <ProfileFormContentRow>
                  <FinalFormInput
                    name="city"
                    defaultValue={user.city}
                    label={
                      AU_APP ? t('fields.suburb.label') : t('fields.city.label')
                    }
                  />
                </ProfileFormContentRow>
              )}

              {(AU_APP || MY_APP) &&
                !checkIsDependentUser(user) &&
                !getGetCountryStatesQuery.isLoading && (
                  <div className="profile-content__container-item -inline ">
                    <div className="-part-25">
                      <div className="profile-content__container-label">
                        {t('fields.state.label')}
                      </div>

                      <div className="profile-content__container-value">
                        <SelectFF
                          name="state"
                          placeholder={t('fields.select.defaultMessage')}
                          defaultValue={user?.state}
                          options={getGetCountryStatesQuery.data}
                        />
                      </div>
                    </div>

                    <div className="-part-75">
                      <FinalFormInput
                        name="zip"
                        defaultValue={user.zip}
                        maxLength={getPostCodeLength()}
                        label={t('fields.postCode.label')}
                      />
                    </div>
                  </div>
                )}

              {AU_APP &&
                user &&
                !checkIsDependentUser(user) &&
                (user.tfn_status !== 'pending' ||
                  user.tfn_status !== 'exempt') && (
                  <ProfileFormContentTFN>
                    <div>{t('settingsPage.profileContent.tfnPart.title')}</div>

                    <span>{user.tfn_status}</span>
                  </ProfileFormContentTFN>
                )}

              {AU_APP &&
                user &&
                !checkIsDependentUser(user) &&
                (user.tfn_status === 'pending' ||
                  user.tfn_status === 'exempt') && (
                  <TaxFileNumberRow>
                    <TaxFileNumberTitle>
                      {t('settingsPage.profileContent.tfnPart.noRecords')}
                    </TaxFileNumberTitle>

                    <TaxFileNumberDescription>
                      {t(
                        'settingsPage.profileContent.tfnPart.notProvideTfnMessage',
                      )}
                    </TaxFileNumberDescription>

                    <TaxFileNumberCheckboxWrapper>
                      <div className="checkbox__container checkbox-tfn relative">
                        <CheckboxFF
                          name="exempt"
                          className="checkbox__list"
                          defaultChecked={values.exempt}
                          type="checkbox"
                          onClick={clickOnTfnExemption}
                          label=""
                        />

                        <TaxFileNumberCheckboxLabel>
                          {t(
                            'settingsPage.profileContent.tfnPart.tfnExemptionLabel',
                          )}
                        </TaxFileNumberCheckboxLabel>
                      </div>

                      {isDisabledTfn && (
                        <FinalFormInput
                          name="ssn"
                          disabled
                          label={t(
                            'settingsPage.profileContent.fields.tfn.label',
                          )}
                        />
                      )}

                      {!isDisabledTfn && (
                        <FinalFormInput
                          name="ssn"
                          maxLength="9"
                          isNumberOnly
                          label={t(
                            'settingsPage.profileContent.fields.tfn.label',
                          )}
                        />
                      )}
                    </TaxFileNumberCheckboxWrapper>
                  </TaxFileNumberRow>
                )}

              {AU_APP && !checkIsDependentUser(user) && (
                <UsCitizenWrapper>
                  <div className="checkbox__container">
                    <CheckboxFF
                      basicCheckbox
                      name="us_citizen"
                      className="us-resident checkbox__basic"
                      defaultChecked={user.us_citizen}
                      type="checkbox"
                      label=""
                    />

                    <div className="checkbox__label">
                      {t('settingsPage.profileContent.usCitizenCheckboxLabel')}
                    </div>
                  </div>

                  <CRSLink to="/dashboard/account/crs">
                    {t('settingsPage.profileContent.crsLinkLabel')}
                  </CRSLink>
                </UsCitizenWrapper>
              )}
            </div>
          </ProfileFormContent>

          {!checkIsDependentUser(user) && (
            <ConfirmButton
              isLoading={showLoader}
              type="submit"
              label={t('settingsPage.profileContent.confirmButton')}
            />
          )}

          {showNetworthInfo && (
            <InvestmentErrorModal
              isOpen={showNetworthInfo}
              shouldCloseOnOverlayClick
              onRequestClose={() => toggleNetworthInfo(false)}
              handleClick={() => toggleNetworthInfo(false)}
              errorMessage={t('registrationPage.other.netWorthInfo')}
            />
          )}
        </form>
      )}
    />
  );
}

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  errorMessagePhone: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    email: PropTypes.string,
    last_name: PropTypes.string,
    address1: PropTypes.string,
    phone_number: PropTypes.string,
    city: PropTypes.string,
    address2: PropTypes.string,
    user_type: PropTypes.string,
    us_citizen: PropTypes.bool,
    verified: PropTypes.bool,
    dob: PropTypes.string,
    gender: PropTypes.string,
    street_number: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    tfn_status: PropTypes.string,
    employer: PropTypes.string,
  }).isRequired,
  showLoader: PropTypes.bool.isRequired,
  clickOnTfnExemption: PropTypes.func.isRequired,
  isDisabledTfn: PropTypes.bool.isRequired,
  isShowModalSuccess: PropTypes.bool.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  showNetworthInfo: PropTypes.bool.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  toggleNetworthInfo: PropTypes.func.isRequired,
  toggleSuccessModal: PropTypes.func.isRequired,
};

ProfileForm.defaultProps = {
  errorMessage: '',
  errorMessagePhone: '',
  handleSubmit() {},
};
