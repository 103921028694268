import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

import { AU_APP, COUNTRY, CURRENCY_SYMBOL, MY_APP } from './localeConfigs';
import {
  AUS,
  BAHASA,
  FILES_BASE_URL,
  HOME_HISTORY_TAB,
  MYS,
} from './comonConstants';
import { PATHS } from './paths';

import { AutomaticRewardsWrapper } from 'components/layout/Modals/styles';
import {
  OFFER_SOURCES,
  OFFER_TYPES,
  REWARDS_SEARCH_PARAMS,
} from 'pages/NewRewards/consts';

// TODO: https://acornsau.atlassian.net/browse/RAIZ-5061
const howWorksUrls = {
  [MYS]: `${process.env.REACT_APP_API_ASSETS}/videos/Bagaimana%20Aplikasi%20Raiz%20Berfungsi%20%5BBM%5D.mp4`,
  [AUS]: 'https://www.youtube.com/watch?v=rgHeUt2eP9Y',
};

const wlelcomRaizRewards = {
  [MYS]: 'portfolioDeversification',
  [AUS]: 'welcomeRaizRewardsNew',
};

const KIDS_HELP_CARDS = {
  access: 'access',
  invest: 'invest',
};

const getKidsHelpCardIcon = (card = '') => {
  const lang = localStorage.getItem('lang');

  if (MY_APP) {
    if (card === KIDS_HELP_CARDS.access)
      return lang === BAHASA ? 'mys-raiz-kids-access-card' : '';

    return `mys-raiz-kids${card && `-${card}`}-card`;
  }

  return '';
};

export const HOW_WORKS = {
  id: 'howWorks',
  imgUrl: false,
  className: '-how-works',
  info: 'weMakeItEasyToInvest',
  title: 'weMakeItEasyToInvest',
  externalLink: true,
  greenBtn: {
    title: 'watchNow',
    link: howWorksUrls[COUNTRY] || howWorksUrls[AUS],
  },
  customTitle: 'howRaizWorks',
  customHeader: (
    <div className="help-cards__modal-img -how-works">
      <div className="-how-works-title">
        {t('helpCardsModal.howWorksWidget.title')}
      </div>
    </div>
  ),
};
export const MY_FINANCE = {
  id: 'myFinance',
  imgUrl: false,
  className: MY_APP ? '-my-finance-my' : '-my-finance',
  info: 'personalFinanceConcierge',
  title: 'personalFinanceConcierge',
  greenBtn: {
    title: 'explore',
    link: '/my-finance',
  },
  customHeader: (
    <div className="help-cards__modal-img -my-finance">
      <div className="-my-finance-title">
        {t('helpCardsModal.myFinanceWidget.title')}
      </div>

      <div className="my-finance__top-list">
        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {t('helpCardsModal.myFinanceWidget.bannerTypes.foodDining')}
          </div>

          <div>8%</div>

          {AU_APP && <div>{`${CURRENCY_SYMBOL}834`}</div>}

          {MY_APP && <div>{`${CURRENCY_SYMBOL}834`}</div>}
        </div>

        <div className="my-finance__top-list-item -active">
          <div className="my-finance__top-list-title">
            {t('helpCardsModal.myFinanceWidget.bannerTypes.healthFitness')}
          </div>

          <div>7%</div>

          {AU_APP && <div>{`${CURRENCY_SYMBOL}773`}</div>}

          {MY_APP && <div>{`${CURRENCY_SYMBOL}773`}</div>}
        </div>

        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {t('helpCardsModal.myFinanceWidget.bannerTypes.cash')}
          </div>

          <div>6%</div>

          {AU_APP && <div>{`${CURRENCY_SYMBOL}620`}</div>}

          {MY_APP && <div>{`${CURRENCY_SYMBOL}620`}</div>}
        </div>
      </div>
    </div>
  ),
};
export const RAIZ_REWARDS = {
  id: 'raizRewards',
  imgUrl: false,
  className: '-raiz-rewards',
  info: 'nowBrands',
  title: 'nowBrands',
  greenBtn: {
    title: 'explore',
    link: '/rewards',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-rewards ${MY_APP ? '-mal' : ''}`}
    >
      <div className="-raiz-rewards-title">
        {t('helpCardsModal.raizRewardsWidget.title')}
      </div>
    </div>
  ),
};
export const WELCOME_RAIZ_REWARDS_NEW = {
  id: 'welcomeRaizRewards',
  imgUrl: false,
  className: '-welcome-raiz-rewards',
  info: wlelcomRaizRewards[COUNTRY],
  title: MY_APP ? 'portfolioDeversification' : 'welcomeRaizRewards',
  clickEvent: () => {
    const nextBtn = document.querySelectorAll(
      '.help-cards__modal .react-multiple-carousel__arrow--right',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'nextNewRewards',
    link: '/rewards',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-rewards -raiz-rewards-new ${
        MY_APP ? '-mal' : ''
      }`}
    >
      <div className={`-raiz-rewards-title ${MY_APP ? '-small' : ''}`}>
        {t('helpCardsModal.welcomeRaizRewardsNewWidget.title')}
      </div>
    </div>
  ),
};
export const WELCOME_RAIZ_REWARDS = {
  id: 'welcomeRaizRewards',
  imgUrl: false,
  className: '-welcome-raiz-rewards',
  info: MY_APP ? 'welcomeRaizRewardsMYS' : 'welcomeRaizRewards',
  title: MY_APP ? 'portfolioDeversification' : 'welcomeRaizRewards',
  clickEvent: () => {
    const nextBtn = document.querySelectorAll(
      '.help-cards__modal .react-multiple-carousel__arrow',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'next',
    link: '/rewards',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-rewards ${MY_APP ? '-mal' : ''}`}
    >
      <div className={`-raiz-rewards-title ${MY_APP ? '-small' : ''}`}>
        {t('helpCardsModal.welcomeRaizRewardsWidget.title')}
      </div>
    </div>
  ),
};
export const TRANSFERRING_MONEY = {
  id: 'transferringMoney',
  imgUrl: false,
  className: '-transferring-money',
  info: 'easyToInvest',
  title: 'easyToInvest',
  greenBtn: {
    title: 'investNow',
    link: '/invest',
  },
  customHeader: (
    <div className="help-cards__modal-img -transferring-money">
      <div className="-transferring-money-title">
        {t('helpCardsModal.transferringMoneyWidget.title')}
      </div>

      <div className="-transferring-money-sub">
        {t('helpCardsModal.transferringMoneyWidget.subTitle')}

        {': '}

        {(AU_APP || MY_APP) && (
          <span className="-transferring-money-sum">{`${CURRENCY_SYMBOL}4,027.88`}</span>
        )}
      </div>

      <div className="-transferring-money-main-sum">
        <span className="-transferring-money-symbol">{CURRENCY_SYMBOL}</span>

        {(AU_APP || MY_APP) && <span>500.00</span>}
      </div>

      {(AU_APP || MY_APP) && (
        <div className="-transferring-money-btn">{t('common.withdraw')}</div>
      )}
    </div>
  ),
};
export const ACCOUNT_ACTIVITY = {
  id: 'accountActivity',
  imgUrl: false,
  className: '-account-activity',
  info: 'navigateToHistory',
  title: 'navigateToHistory',
  linkWithAction: true,
  greenBtn: {
    title: 'goToHistory',
    link: HOME_HISTORY_TAB,
  },
  customHeader: (
    <div className="help-cards__modal-img -account-activity">
      <div className="-account-activity-title">
        {t('helpCardsModal.accountActivityWidget.title')}
      </div>

      <div className="my-finance__top-list">
        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon" />

            {t(
              'helpCardsModal.accountActivityWidget.bannerTypes.oneTimeInvest',
            )}
          </div>

          <div>+${CURRENCY_SYMBOL}100</div>
        </div>

        <div className="my-finance__top-list-item -active">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon -revers" />

            {t(
              'helpCardsModal.accountActivityWidget.bannerTypes.withdrawTitle',
            )}
          </div>

          <div>${CURRENCY_SYMBOL}70</div>
        </div>

        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon" />

            {t(
              'helpCardsModal.accountActivityWidget.bannerTypes.oneTimeInvest',
            )}
          </div>

          <div>+${CURRENCY_SYMBOL}20</div>
        </div>
      </div>
    </div>
  ),
};
export const SCHEDULING = {
  id: 'scheduling',
  imgUrl: false,
  className: '-scheduling',
  info: 'scheduling',
  title: 'scheduling',
  greenBtn: {
    title: 'close',
    link: '/recurring-investment',
  },
  customHeader: (
    <div className="help-cards__modal-img -scheduling">
      <div className="-scheduling-title">
        {t('helpCardsModal.schedulingWidget.title')}
      </div>

      <div className="-scheduling-switcher">
        <div className="-scheduling-switcher-item">
          {t('common.frequencies.daily')}
        </div>

        <div className="-scheduling-switcher-item">
          {t('common.frequencies.weekly')}
        </div>

        <div className="-scheduling-switcher-item -active">
          {t('common.frequencies.monthly')}
        </div>
      </div>
    </div>
  ),
};

const transferringClass = {
  [AUS]: '-transferring-money',
  [MYS]: '-transferring-money-my',
};

export const TRANSFERRING_MONEY_INVEST = {
  id: 'transferringMoneyInvest',
  imgUrl: false,
  className: transferringClass[COUNTRY] || transferringClass[AUS],
  info: 'transferingMoney',
  title: 'transferingMoney',

  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'next',
    link: '/recurring-investment',
  },
  customHeader: (
    <div className="help-cards__modal-img -transferring-money">
      <div className="-transferring-money-title">
        {t('helpCardsModal.transferringMoneyWidget.title')}
      </div>

      <div className="-transferring-money-sub">
        {t('helpCardsModal.transferringMoneyWidget.subTitle')}

        {': '}

        {(AU_APP || MY_APP) && (
          <span className="-transferring-money-sum">{`${CURRENCY_SYMBOL}4,027.88`}</span>
        )}
      </div>

      <div className="-transferring-money-main-sum">
        <span className="-transferring-money-symbol">{CURRENCY_SYMBOL}</span>

        {(AU_APP || MY_APP) && <span>500.00</span>}
      </div>

      {(AU_APP || MY_APP) && (
        <div className="-transferring-money-btn">{t('common.withdraw')}</div>
      )}
    </div>
  ),
};
export const SPENDING_CATEGORIES = {
  id: 'spendingCategories',
  imgUrl: false,
  className: MY_APP ? '-spending-categories-my' : '-spending-categories',
  info: 'spendingCategories',
  title: 'spendingCategories',
  greenBtn: {
    title: 'editCategories',
    link: '/transaction-categorisation',
  },
  customHeader: (
    <div className="help-cards__modal-img -spending-categories">
      <div className="-spending-categories-title">
        {t('helpCardsModal.spendingCategoriesWidget.title')}
      </div>

      <div className="my-finance__top-list">
        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {t(
              'helpCardsModal.spendingCategoriesWidget.bannerTypes.foodDining',
            )}
          </div>

          <div>10%</div>

          <div>{`${CURRENCY_SYMBOL}1 237`}</div>
        </div>

        <div className="my-finance__top-list-item -active">
          <div className="my-finance__top-list-title">
            {t(
              'helpCardsModal.spendingCategoriesWidget.bannerTypes.healthFitness',
            )}
          </div>

          <div>7%</div>

          <div>{`${CURRENCY_SYMBOL}773`}</div>
        </div>

        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {t('helpCardsModal.spendingCategoriesWidget.bannerTypes.cash')}
          </div>

          <div>3%</div>

          <div>{`${CURRENCY_SYMBOL}356`}</div>
        </div>
      </div>
    </div>
  ),
};
export const FUTURE_INFO = {
  id: 'kidsInfo',
  imgUrl: false,
  className: false,
  info: 'futureInfo',
  title: 'kidsInfo',
  additionalClass: '-text-only',
};
export const KIDS_INFO = {
  id: 'kidsInfo',
  imgUrl: false,
  className: false,
  info: 'kidsInfo',
  title: 'kidsInfo',
  additionalClass: '-text-only',
};
export const WELCOM_KIDS_NEW = {
  id: 'welcomeToRaizKids',
  imgUrl: false,
  className: '-welcome-raiz-kids',
  info: 'kidsInfoNew',
  title: 'welcomeToRaizKids',
  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'nextNewKids',
    link: '/raiz-kids',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-kids ${getKidsHelpCardIcon()}`}
    >
      <div className="-raiz-kids-title">
        {t('helpCardsModal.welcomeRaizKidsNewWidget.title')}
      </div>
    </div>
  ),
};
export const RAIZ_KIDS_ACCESS = {
  id: 'raizKidsAccess',
  imgUrl: false,
  className: '-welcome-raiz-kids',
  info: 'raizKidsAccessInfo',
  title: 'raizKidsAccess',
  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow',
    )[1];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'nextNewKids',
    link: '/raiz-kids',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-kids access-card ${getKidsHelpCardIcon(
        KIDS_HELP_CARDS.access,
      )}`}
    >
      <div className="-raiz-kids-title">
        {t('helpCardsModal.raizKidsAccessWidget.title')}
      </div>
    </div>
  ),
};
export const RAIZ_KIDS_INVEST = {
  id: 'raizKidsAccess',
  imgUrl: false,
  className: '-welcome-raiz-kids',
  info: 'raizKidsInvestInfo',
  title: 'raizKidsInvest',
  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow',
    )[1];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  greenBtn: {
    isBtn: true,
    title: 'nextNewKids',
    link: '/raiz-kids',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -raiz-kids invest-card ${getKidsHelpCardIcon(
        KIDS_HELP_CARDS.invest,
      )}`}
    >
      <div className="-raiz-kids-title">
        {t('helpCardsModal.raizKidsInvestWidget.title')}
      </div>
    </div>
  ),
};
export const RAIZ_KIDS_ADULT = {
  id: 'raizKidsAdult',
  imgUrl: false,
  className: '-welcome-raiz-kids',
  info: 'raizKidsAdultInfo',
  title: 'raizKidsAdult',
  greenBtn: {
    title: 'nextNewKidsClose',
    link: '/raiz-kids',
  },
  customHeader: (
    <div className="help-cards__modal-img -raiz-kids adult-card">
      <div className="-raiz-kids-title">
        {t('helpCardsModal.raizKidsAdultWidget.title')}
      </div>
    </div>
  ),
};
export const NO_CARD_LINKED_NEW = {
  id: 'noCardLinked',
  imgUrl: false,
  className: '-no-card-linked',
  info: 'noCardLinked',
  title: 'noCardLinked',
  greenBtn: {
    title: 'linkCard',
    link: `${PATHS.rewards.linked}?${createSearchParams({
      [REWARDS_SEARCH_PARAMS.tab]: OFFER_SOURCES.card,
    }).toString()}`,
  },
  customHeader: (
    <div className="help-cards__modal-img -no-card-linked -no-card-linked-new">
      <div className="-no-card-linked-title">
        {t('helpCardsModal.noCardLinkedWidget.title')}
      </div>
    </div>
  ),
};

export const AUTOMATIC_REWARDS = {
  id: 'automaticRewards',
  imgUrl: false,
  className: '-',
  info: 'automaticRewards',
  greenBtn: {
    title: 'next',
    isBtn: true,
  },
  clickEvent: () => {
    // TODO: Refactor Next Button https://acornsau.atlassian.net/browse/RAIZ-7752
    const nextBtn = document.querySelectorAll(
      '.help-cards__modal .react-multiple-carousel__arrow--right',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  customHeader: (
    <AutomaticRewardsWrapper>
      {t('helpCardsModal.automaticRewardsWidget.title')}
    </AutomaticRewardsWrapper>
  ),
};

export const NO_CARD_LINKED = {
  id: 'noCardLinked',
  imgUrl: false,
  className: '-no-card-linked',
  info: 'noCardLinked',
  title: 'noCardLinked',
  greenBtn: {
    title: 'linkCard',
    link: '/settings/spending-account',
  },
  customHeader: (
    <div className="help-cards__modal-img -no-card-linked">
      <div className="-no-card-linked-title">
        {t('helpCardsModal.noCardLinkedWidget.title')}
      </div>
    </div>
  ),
};
export const NO_CARD_LINKED_WARN = {
  id: 'noCardLinkedWarn',
  imgUrl: false,
  className: '-no-card-linked-warn',
  info: 'noCardLinkedWarn',
  title: 'noCardLinkedWarn',
  greenBtn: {
    title: 'linkCard',
    link: '/settings/spending-account',
  },
  customHeader: (
    <div className="help-cards__modal-img -no-card-linked-warn">
      <div className="-no-card-linked-warn-title">
        {t('helpCardsModal.noCardLinkedWidget.title')}
      </div>
    </div>
  ),
};
export const HOW_SEE_REWARDS_NEW = {
  id: 'howSeeRewards',
  imgUrl: false,
  className: '-how-see-rewards',
  info: AU_APP ? 'rewardsWill' : 'howSeeRewards',
  title: 'howSeeRewards',
  greenBtn: {
    title: 'startNow',
    link: `${PATHS.rewards.index}?${createSearchParams({
      [REWARDS_SEARCH_PARAMS.offerType]: OFFER_TYPES.online,
    }).toString()}`,
  },
  customHeader: (
    <div className="help-cards__modal-img -how-see-rewards -how-see-rewards-new">
      <div className="-how-see-rewards-title">
        {AU_APP
          ? t('helpCardsModal.howSeeRewardsNewWidget.title.raizRewardsHistory')
          : t('helpCardsModal.howSeeRewardsNewWidget.title.howSeeRewardsTitle')}
      </div>

      <div className="my-finance__top-list">
        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {!AU_APP && <i className="-account-activity-icon" />}

            {AU_APP
              ? t(
                  'helpCardsModal.howSeeRewardsNewWidget.bannerTypes.theGoodGuys',
                )
              : t(
                  'helpCardsModal.howSeeRewardsNewWidget.bannerTypes.investment',
                )}
          </div>

          <div>
            {AU_APP && `+${CURRENCY_SYMBOL}12.45`}

            {!AU_APP && `+${CURRENCY_SYMBOL}100`}
          </div>
        </div>

        <div className="my-finance__top-list-item -active">
          <div className="my-finance__top-list-title">
            {!AU_APP && <i className="-account-activity-icon -star" />}

            {AU_APP
              ? t('helpCardsModal.howSeeRewardsNewWidget.bannerTypes.asos')
              : t(
                  'helpCardsModal.howSeeRewardsNewWidget.bannerTypes.raizRewards',
                )}
          </div>

          <div>
            {AU_APP && `+${CURRENCY_SYMBOL}4.87`}

            {!AU_APP && `+${CURRENCY_SYMBOL}10`}
          </div>
        </div>

        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            {!AU_APP && <i className="-account-activity-icon" />}

            {AU_APP
              ? t('helpCardsModal.howSeeRewardsNewWidget.bannerTypes.booking')
              : t(
                  'helpCardsModal.howSeeRewardsNewWidget.bannerTypes.investment',
                )}
          </div>

          <div>
            {AU_APP && `+${CURRENCY_SYMBOL}30.45`}

            {!AU_APP && `+${CURRENCY_SYMBOL}20`}
          </div>
        </div>
      </div>
    </div>
  ),
};
export const HOW_SEE_REWARDS = {
  id: 'howSeeRewards',
  imgUrl: false,
  className: '-how-see-rewards',
  info: 'howSeeRewards',
  title: 'howSeeRewards',
  greenBtn: {
    title: 'startNow',
    link: '/rewards',
  },
  customHeader: (
    <div
      className={`help-cards__modal-img -how-see-rewards ${
        MY_APP ? '-how-see-rewards-mal' : ''
      }`}
    >
      <div className="-how-see-rewards-title">
        {t('helpCardsModal.howSeeRewardsWidget.title')}
      </div>

      <div className="my-finance__top-list">
        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon" />

            {t('helpCardsModal.howSeeRewardsWidget.bannerTypes.investment')}
          </div>

          <div>{`+${CURRENCY_SYMBOL}100`}</div>
        </div>

        <div className="my-finance__top-list-item -active">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon -star" />

            {t('helpCardsModal.howSeeRewardsWidget.bannerTypes.raizRewards')}
          </div>

          <div>{`+${CURRENCY_SYMBOL}10`}</div>
        </div>

        <div className="my-finance__top-list-item">
          <div className="my-finance__top-list-title">
            <i className="-account-activity-icon" />

            {t('helpCardsModal.howSeeRewardsWidget.bannerTypes.investment')}
          </div>

          <div>{`+${CURRENCY_SYMBOL}20`}</div>
        </div>
      </div>
    </div>
  ),
};
export const DEPOSIT_SUSPENSION = {
  id: 'depositSuspension',
  imgUrl: false,
  className: '-deposit-suspension',
  info: 'depositSuspension',
  title: 'depositSuspension',
  showCareBtns: false,
  greenBtn: {
    isBtn: true,
    title: 'removeDeposit',
    link: '/settings/funding-account',
  },
  customHeader: (
    <div className="help-cards__modal-img -deposit-suspension">
      <div className="-deposit-suspension-title">
        {t('helpCardsModal.depositSuspensionWidget.title')}
      </div>
    </div>
  ),
};

export const USE_LINK_CARD = {
  id: 'useLinkCard',
  imgUrl: false,
  className: '-use-link-card',
  info: 'useLinkCard',
  title: 'useLinkCard',
  greenBtn: {
    title: 'viewYourCards',
    link: '/settings/spending-account',
  },
  customHeader: (
    <div className="help-cards__modal-img -use-link-card">
      <div className="-use-link-card-title">
        <Trans t={t} i18nKey="helpCardsModal.useLinkCardWidget.title" />
      </div>

      <div className="-use-link-card-name">CBA</div>

      <div className="-use-link-card-number">
        {t('helpCardsModal.useLinkCardWidget.mastercard')}
      </div>
    </div>
  ),
};

export const USE_LINK_CARD_REWARDS = {
  id: 'useLinkCard',
  imgUrl: false,
  className: '-use-link-card',
  info: 'useLinkCardRewards',
  title: 'useLinkCard',
  greenBtn: {
    title: 'viewYourCards',
    link: `${PATHS.rewards.linked}?${createSearchParams({
      [REWARDS_SEARCH_PARAMS.tab]: OFFER_SOURCES.card,
    }).toString()}`,
  },
  customHeader: (
    <div className="help-cards__modal-img -use-link-card -use-link-card-rewards">
      <div className="-use-link-card-title">
        {t('helpCardsModal.useLinkCardWidget.title')}
      </div>

      <div className="-use-link-card-name">CBA</div>

      <div className="-use-link-card-number">
        {t('helpCardsModal.useLinkCardWidget.mastercard')}
      </div>
    </div>
  ),
};

export const EXPLORE_ETFS = {
  id: 'exploreEtfs',
  imgUrl: false,
  className: '-explore-etfs',
  info: 'exploreEtfs',
  title: 'exploreEtfs',
  externalLink: true,
  greenBtn: {
    title: 'watchNow',
    link: `${FILES_BASE_URL}/video/portfolio_diversification.mp4`,
  },
  customHeader: (
    <div className="help-cards__modal-img -explore-etfs">
      <div className="-explore-etfs-title">
        {t('helpCardsModal.exploreEtfsWidget.title')}
      </div>
    </div>
  ),
};
export const POTENTIAL_INVESTMENT_PROJECTION = {
  id: 'potentialInvestmentProjection',
  imgUrl: false,
  className: '-potential-investment-projection',
  info: 'potentialInvestmentProjection',
  title: 'potentialInvestmentProjection',
  externalLink: true,
  greenBtn: {
    title: 'watchNow',
    link: `${FILES_BASE_URL}/video/portfolio_graph.mp4`,
  },
  customHeader: (
    <div className="help-cards__modal-img -potential-investment-projection">
      <div className="-potential-investment-projection-title">
        {t('helpCardsModal.potentialInvestmentProjectionWidget.title')}
      </div>
    </div>
  ),
};
export const ROUND_UP_INFO = {
  id: 'roundUpInfo',
  imgUrl: false,
  className: '-how-works',
  info: 'roundUpsInfo',
  title: 'roundUpsInfo',
  externalLink: true,
  greenBtn: {
    title: 'tontonSekarang',
    link: 'https://assets.raizinvest.id/video/how_to_invest_roundup_igtv_27_8_21.mp4',
  },
  customHeader: <div className="help-cards__modal-img -round-up-info" />,
};
export const SETUP_FUNDING_ACCOUNT = {
  id: 'setupFundingAccount',
  imgUrl: false,
  className: '-setup-funding',
  info: 'setupFundingAccount',
  title: 'setupFundingAccount',
  greenBtn: {
    title: 'relink',
    link: '/settings/funding-account',
  },
  ...{
    transparentBtn: {
      title: 'linkNew',
      link: '/settings/funding-account',
    },
  },
  customHeader: (
    <div className="help-cards__modal-img -setup-funding">
      <div className="-setup-funding-title">
        {t('helpCardsModal.setupFundingAccountWidget.title')}
      </div>
    </div>
  ),
};

export const WHERE_YOU_SPEND_CARD = {
  id: 'whereYouSpend',
  imgUrl: false,
  className: `-where-you-spend ${MY_APP ? '-centered' : ''}`,
  info: 'whereYouSpend',
  title: 'whereYouSpend',
  greenBtn: {
    title: 'viewYourSpending',
    link: '/where-spend',
  },
  customHeader: (
    <div className="help-cards__modal-img -where-you-spend">
      <div className="-where-you-spend-title">
        {t('helpCardsModal.whereYouSpendWidget.title')}
      </div>

      <div className="-where-you-spend-avg">
        <Trans t={t} i18nKey="helpCardsModal.whereYouSpendWidget.avg" />
      </div>

      <div className="-where-you-spend-chart-a">
        {t('helpCardsModal.whereYouSpendWidget.chartA')}
      </div>

      <div className="-where-you-spend-chart-b">
        {t('helpCardsModal.whereYouSpendWidget.chartB')}
      </div>

      <div className="-where-you-spend-chart-c">
        {t('helpCardsModal.whereYouSpendWidget.chartC')}
      </div>
    </div>
  ),
};

export const REG_INFO = {
  id: 'reksaDana',
  imgUrl: false,
  className: '-reksa-dana',
  info: 'reksaDanaInfo',
  title: 'tentangReksaDana',
  externalLink: true,
  greenBtn: {
    title: 'watchNow',
    link: 'https://www.youtube.com/watch?v=weWtqfsiof0',
  },
  customTitle: 'howRaizWorks',
  customHeader: (
    <div className="help-cards__modal-img -reksa-dana">
      <div className="-how-works-title">
        {t('helpCardsModal.reksaDanaWidget.title')}
      </div>
    </div>
  ),
};

export const BANK_FUNDING = {
  className: 'bank-account-help-card',
  info: 'fundingAccount',
  greenBtn: {
    title: 'next',
    isBtn: true,
  },
  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow--right',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  customHeader: (
    <div className="bank-account-help-card -first">
      <div className="-title">
        {t('helpCardsModal.whatIsYourBankAccount.title.fundingAccount')}
      </div>
    </div>
  ),
};

export const BANK_ROUND_UP = {
  className: 'bank-account-help-card',
  info: 'roundUpAccount',
  greenBtn: {
    title: 'next',
    isBtn: true,
  },
  clickEvent: () => {
    const nextBtn = document.getElementsByClassName(
      'react-multiple-carousel__arrow--right',
    )[0];

    if (nextBtn) {
      nextBtn.click();
    }
  },
  customHeader: (
    <div className="bank-account-help-card -first">
      <div className="-title">
        {t('helpCardsModal.whatIsYourBankAccount.title.roundUpAccount')}
      </div>
    </div>
  ),
};

export const BANK_LEVEL_SECURITY = {
  className: 'bank-account-help-card',
  info: 'bankLevelSecurity',
  greenBtn: {
    title: 'close',
    isCloseButton: true,
  },
  customHeader: (
    <div className="bank-account-help-card -second">
      <div className="-title">
        {t('helpCardsModal.whatIsYourBankAccount.title.bankLevelSecurity')}
      </div>
    </div>
  ),
};
