import { useTranslation } from 'react-i18next';
import Skeleton from 'react-skeleton-loader';
import { range } from 'lodash';

import {
  BillerItem,
  BillerWrapper,
  BPayImage,
  BPayInfoWrapper,
  Label,
  RaizCode,
  SuperAccountInfoItemWrapper,
  Value,
} from '../styles';

export function SuperAccountInfoSkeleton() {
  const { t } = useTranslation();

  return (
    <>
      {range(6).map(() => (
        <SuperAccountInfoItemWrapper key={window.crypto.randomUUID()}>
          <Label>
            <Skeleton />
          </Label>

          <Value>
            <Skeleton />
          </Value>
        </SuperAccountInfoItemWrapper>
      ))}

      <BPayInfoWrapper>
        <BPayImage />

        <BillerWrapper>
          <BillerItem>
            {t('superPage.superInfo.billerCode')}

            <RaizCode>
              <Skeleton height="14px" />
            </RaizCode>
          </BillerItem>

          <BillerItem>
            {t('superPage.superInfo.ref')}

            <RaizCode>
              <Skeleton height="14px" />
            </RaizCode>
          </BillerItem>
        </BillerWrapper>
      </BPayInfoWrapper>
    </>
  );
}
