import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EditFund from '../FundsList/FundItemActions/FundItemActions';
import { useUpdateFunds } from '../../PortfolioCustomizationFooter/hooks';

import {
  EditFundWrapper,
  FooterButton,
  FundDetailsFooterWrapper,
} from './styles';
import FundSuperTotal from './FundSuperTotal';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

export default function FundDetailsFooter() {
  const { t } = useTranslation();

  const { isUpdateFundsLoading, handleUpdateFunds, isUpdateButtonDisabled } =
    useUpdateFunds({ isDetailsPage: true });

  const { fundDetails, isLoading } = useSelector((state) => ({
    fundDetails: state.portfolio?.fundDetails || {},
    isLoading: state.portfolio?.isFundDetailsLoading,
  }));

  const {
    id,
    allocation,
    max_allocation,
    inactive_for_super_plus,
    name,
    symbol,
  } = fundDetails || {};

  return (
    <FundDetailsFooterWrapper>
      <EditFundWrapper>
        {isLoading ? (
          <DotsLoader />
        ) : (
          <EditFund
            id={id}
            allocation={allocation}
            max_allocation={max_allocation}
            inactive_for_super_plus={inactive_for_super_plus}
            name={name}
            symbol={symbol}
          />
        )}

        <FundSuperTotal isDetailsPage id={id} />
      </EditFundWrapper>

      <FooterButton
        isLoading={isUpdateFundsLoading}
        isDisabled={isUpdateButtonDisabled}
        label={t('portfolioCustomization.fundDetails.saveChangesButton')}
        onClick={handleUpdateFunds}
      />
    </FundDetailsFooterWrapper>
  );
}
