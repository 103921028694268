import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { PORTFOLIO_PLANS } from '../consts';

import ProPortfolio from './ProPortfolio/ProPortfolio';
import { ProPortfolioLoader } from './styles';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { selectSuperUser, selectUser } from 'store/user/selectors';
import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

export const ActivePortfolioPlan = ({ isRaizKids }) => {
  const isSuperRegistration = useIsSuperRegistration();

  const { isRegistration } = useAppSelector(selectPortfolioLocation);

  const navigate = useNavigate();

  const { portfolioPlan, isSuper } = useAppSelector(selectPortfolioParams);
  const { email } = useAppSelector(selectSuperUser);

  const { super_annuation_user } = useAppSelector(selectUser);

  const isBasicPortfolio = portfolioPlan === PORTFOLIO_PLANS.basic;

  if (isSuper && super_annuation_user && !email) {
    return (
      <ProPortfolioLoader>
        <DotsLoader />
      </ProPortfolioLoader>
    );
  }

  if (isBasicPortfolio) {
    return (
      <ChangePortfolio
        isEditKids={isRaizKids}
        isRegistration={isRegistration}
        isSuper={isSuper}
        isSuperRegistration={isSuperRegistration}
        navigate={navigate}
      />
    );
  }

  return <ProPortfolio />;
};

ActivePortfolioPlan.propTypes = {
  isRaizKids: PropTypes.bool,
};

ActivePortfolioPlan.defaultProps = {
  isRaizKids: false,
};
