import { PATHS } from 'constants/paths';
import { IPortfolioLocation } from 'store/portfolio/types';

export const getParentUrlPathname = ({
  isKids,
  isRegistration,
}: Partial<IPortfolioLocation>) => {
  if (isRegistration) {
    return isKids
      ? PATHS.kids.registration.index
      : PATHS.registration.portfolio.index;
  }

  return PATHS.portfolio.index;
};
