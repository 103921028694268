import styled from '@emotion/styled';

import SelectFF from 'components/elements/SelectFF/SelectFF';

export const SortingWrapper = styled.div`
  float: left;
  background: ${({ theme }) => theme.colors.wildSand};
  position: relative;
  display: flex;
  padding: 8px 10px 8px 16px;
  border-radius: 8px;
`;

export const FormWrapper = styled.form`
  flex: 1;
`;

export const SortingTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  color: ${({ theme }) => theme.colors.mirage};
  padding-right: 8px;
`;

export const Select = styled(SelectFF)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  cursor: pointer;
  .react-dropdown-select-dropdown-handle {
    display: none;
  }
  .react-dropdown-select-dropdown {
    width: 145px;
    left: -55px;
    border: none;
    border-radius: 12px;
    padding: 12px 0 12px 0;
    top: 36px;
  }
`;
