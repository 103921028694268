import React from 'react';
import '../../styles/pages/crs.scss';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SUPPORT } from '../../constants/localeConfigs';
import SelectFF from '../../components/elements/SelectFF/SelectFF';
import FinalFormInput from '../../components/elements/FinalFormInput/FinalFormInput';
import DeprecatedDotsLoader from '../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.country) {
      errors.country = t('fields.validation.required');
    }

    if (!values.taxIdentifier) {
      errors.taxIdentifier = t('fields.validation.required');
    }

    return errors;
  };

  function taxRender(tax, deleteTax, showLoader, countries) {
    const countryName = countries.find((o) => o.value === tax.country_code);

    return (
      <div className="history__item" key={tax.id}>
        <div className="history__item__content">
          <div className="history__item-info">
            <div className="history__item-type">
              {t('crsPage.countryInfo')}

              {countryName.label}
            </div>

            <div className="history__item-type">
              {t('crsPage.taxIdentifierInfo')}

              {tax.number}
            </div>
          </div>

          <div>
            <DeprecatedButton
              title={
                showLoader ? (
                  <DeprecatedDotsLoader additionalClass="grey" />
                ) : (
                  t('common.remove')
                )
              }
              buttonClass="button__basic-fill -grey -cancel remove"
              handleClick={() => deleteTax(tax.id)}
            />
          </div>
        </div>
      </div>
    );
  }

  return { validate, taxRender };
};

export default function CrsView({
  user,
  countries,
  handleSubmit,
  countryError,
  isShowListTax,
  identifiers,
  deleteTax,
  showAddTax,
  showLoader,
  goToSettings,
}) {
  const { t } = useTranslation();
  const { validate, taxRender } = useValidation();

  let isShowList =
    identifiers &&
    identifiers.total &&
    identifiers.total !== 0 &&
    countries &&
    countries.length !== 0;

  if (isShowList === 0) {
    isShowList = false;
  }

  const selectCountry = `${t('fields.select.label')} ${t(
    'fields.country.label',
  )}`;

  return (
    <div>
      <div className="page-content__banner">
        <div className="page-content__banner-title">
          {t('crsPage.banner.title')}
        </div>

        <div className="page-content__banner-label">
          {t('crsPage.banner.label')}
        </div>

        <div className="page-content__banner-value statements__banner-value">
          {formatNumber({ value: user.current_balance || 0 })}
        </div>
      </div>

      {(!isShowListTax ||
        !identifiers ||
        !identifiers.total ||
        identifiers.total === 0) &&
        countries &&
        countries.length !== 0 && (
          <div className="crs">
            <Form
              onSubmit={handleSubmit}
              validate={(values) => validate(values)}
              render={({ form, values, hasValidationErrors }) => (
                <form
                  className="form"
                  onSubmit={(e) =>
                    handleSubmit(e, values, form, hasValidationErrors)
                  }
                >
                  <div className="info-panel" id="history-transactions">
                    <div>
                      <div className="info-panel__title" />

                      <div className="history__element">
                        <div className="container">
                          <div className="text">{t('crsPage.description')}</div>

                          <div className="support">
                            <a
                              href={SUPPORT}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t('crsPage.crsReadLink')}
                            </a>
                          </div>

                          <div className="add-taxt">
                            <div className="profile-content__container-item">
                              <div className="profile-content__container-label" />

                              <div className="profile-content__container-value countries">
                                <SelectFF
                                  name="country"
                                  options={countries}
                                  placeholder={selectCountry}
                                />

                                {countryError !== '' && (
                                  <div className="error">{countryError}</div>
                                )}
                              </div>
                            </div>

                            <div className="profile-content__container-item">
                              <FinalFormInput
                                name="taxIdentifier"
                                maxLength="30"
                                label={t('crsPage.fields.taxIdentifier.label')}
                              />
                            </div>
                          </div>

                          <div className="actions">
                            <DeprecatedButton
                              title={
                                showLoader ? (
                                  <DeprecatedDotsLoader additionalClass="grey" />
                                ) : (
                                  t('common.cancel')
                                )
                              }
                              buttonClass="button__basic-grey cancel"
                              handleClick={
                                !identifiers ||
                                !identifiers.total ||
                                identifiers.total === 0
                                  ? goToSettings
                                  : () => showAddTax(true)
                              }
                            />

                            <DeprecatedButton
                              type="submit"
                              title={
                                showLoader ? (
                                  <DeprecatedDotsLoader additionalClass="grey" />
                                ) : (
                                  t('common.save')
                                )
                              }
                              buttonClass="button__basic-fill -green -size-l save"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        )}

      {isShowListTax && isShowList && (
        <div className="crs crs-list">
          <div className="info-panel" id="history-transactions">
            <div>
              <div className="info-panel__title" />

              <div className="history__element">
                <div className="container">
                  <div className="text">{t('crsPage.description')}</div>

                  <div className="support">
                    <a href={SUPPORT} target="_blank" rel="noopener noreferrer">
                      {t('crsPage.crsReadLink')}
                    </a>
                  </div>

                  <div>
                    <DeprecatedButton
                      title={t('crsPage.addTaxButton')}
                      buttonClass="button-link"
                      handleClick={() => showAddTax(false)}
                    />
                  </div>

                  {identifiers.tax_identifiers.map((tax) =>
                    taxRender(tax, deleteTax, showLoader, countries),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

CrsView.defaultProps = {
  user: {},
  isShowListTax: true,
  showLoader: false,
};

CrsView.propTypes = {
  user: PropTypes.shape({
    current_balance: PropTypes.string,
  }),
  isShowListTax: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  countryError: PropTypes.string.isRequired,
  deleteTax: PropTypes.func.isRequired,
  showAddTax: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  identifiers: PropTypes.shape({
    total: PropTypes.number,
    tax_identifiers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  showLoader: PropTypes.bool,
  goToSettings: PropTypes.func.isRequired,
};
