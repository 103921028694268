import styled from '@emotion/styled';

import inviteIcon from 'assets/images/icons/referrals/invite.svg';

export const Button = styled.button`
  background-image: url(${`${inviteIcon}`});
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  height: 44px;
  width: 44px;
  border-radius: 40px;
`;
