import checkIsAdditionalField from './checkIsAdditionalField';

import { IQuestion } from 'pages/Settings/components/Forms/types';

const transformSuitabilityResponse = (suitabilityResponse: IQuestion[]) => {
  const questions = suitabilityResponse?.map((question) => {
    const answers = question.answers.map((questionAnswer) => {
      const { answer, answer_id } = questionAnswer;

      const isAdditionalField = checkIsAdditionalField({
        pickerId: question.picker_id,
        answer,
        expandOnAnswerIds: question?.expand_on_answer_ids,
        answerId: answer_id,
      });

      return { ...questionAnswer, textInput: isAdditionalField };
    });

    return { ...question, answers };
  });

  return questions;
};

export default transformSuitabilityResponse;
