import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import {
  getBrandsForSlider,
  getRewardsOffers,
  getPaymentAccounts,
  getRewardsTotal,
  getInsuranceCertificates,
  getMonitoredAccounts,
  getCategories,
  getRewardDetails,
  setIsLoading,
} from '../../store/rewards/actions';
import {
  NO_LINKED,
  NO_LINKED_WARN,
  USE_LINK_CARD,
} from '../../constants/comonConstants';
import { MY_APP } from '../../constants/togglesFunctions';

import RewardsView from './RewardsView';
import Insurances from './components/Insurances';
import RewardsHistory from './components/RewardsHistory';
import { REWARDS_ACTIVE_CATEGORY_ID } from './consts';
import RewardDetails from './components/RewardDetails';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function Rewards(props) {
  const { t } = useTranslation();

  const { offers, categories, categoriesType, monitoredAccounts } = useSelector(
    (state) => ({
      offers: state.rewards.offers,
      categories: state.rewards.categories,
      categoriesType: state.rewards.categoriesType,
      monitoredAccounts: state.rewards.monitoredAccounts,
    }),
  );

  const { isNeedSearch } = props;
  const [brandsFilter, setBrandsFilter] = useState([]);

  const [activeCategoryId, setActiveCategoryId] = useState(
    REWARDS_ACTIVE_CATEGORY_ID,
  );

  const [isHelpCardsOpen, setIsHelpCardsOpen] = useState(false);
  const [rewardCard, setRewardCard] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [isPdfQueryLoading, setIsPdfQueryLoading] = useState(false);
  const [statementsUrl, setStatementsUrl] = useState('');
  const [areCertificatesLoading, setAreCertificatesLoading] = useState(false);
  const isHistoryPath = useMatch('/rewards/history');
  const isInsurancesPath = useMatch('/rewards/insurances');

  const isRewardsPath = useMatch('/rewards/:id');

  const isRewardDetailsPath =
    isRewardsPath && !isHistoryPath && !isInsurancesPath;

  const dispatch = useDispatch();

  const getActivePurchasesList = useCallback(() => {
    dispatch(setIsLoading(true));

    if (isRewardDetailsPath) {
      const {
        params: { id },
      } = isRewardDetailsPath;

      dispatch(getRewardDetails(id));
    } else {
      dispatch(getBrandsForSlider());

      dispatch(getRewardsOffers()).then(() => {
        dispatch(setIsLoading(false));
      });

      if (MY_APP) {
        dispatch(getPaymentAccounts()).then(() => {
          if (monitoredAccounts?.payment_cards?.length < 1) {
            setRewardCard(NO_LINKED);
          }
        });
      } else {
        dispatch(getRewardsTotal());

        setAreCertificatesLoading(true);

        dispatch(getInsuranceCertificates()).then(() => {
          setAreCertificatesLoading(false);
        });

        dispatch(getMonitoredAccounts()).then(() => {
          if (!monitoredAccounts || monitoredAccounts?.length <= 0) {
            setRewardCard(NO_LINKED);
          } else {
            const hasLinkingError = monitoredAccounts.map(
              (monitoredAccount) => monitoredAccount.has_linking_error,
            );

            if (hasLinkingError.indexOf(true) === -1) {
              setRewardCard(USE_LINK_CARD);
            } else {
              setRewardCard(NO_LINKED_WARN);
            }
          }
        });
      }

      dispatch(getCategories(t('rewardsPage.all')));

      window.scrollTo(0, 0);
    }
    // monitoredAccount is updated by the callback. Need to exclude it from the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isHistoryPath, isRewardDetailsPath, t]);

  useEffect(() => {
    getActivePurchasesList();
  }, [getActivePurchasesList]);

  const brandFilter = (categoryId) => {
    setSearchValue('');

    dispatch(setIsLoading(true));

    dispatch(getRewardsOffers()).then(() => {
      const brandsFiltered = offers.offers.filter((brand) =>
        brand.categories_ids.includes(categoryId),
      );

      setBrandsFilter(brandsFiltered);

      dispatch(setIsLoading(false));
    });
  };

  const filterByCategory = (e) => {
    const id = Number(e.currentTarget.id);

    setActiveCategoryId(id);

    brandFilter(id);
  };

  const saveInvested = (investedValue) => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.investedValue, investedValue);
  };

  const searchHandler = (e) => {
    dispatch(setIsLoading(true));

    dispatch(getRewardsOffers(e.currentTarget.value)).then(() => {
      dispatch(setIsLoading(false));
    });

    setBrandsFilter([]);

    setActiveCategoryId(REWARDS_ACTIVE_CATEGORY_ID);

    setSearchValue(e.target.value);
  };

  const clearSearch = () => {
    dispatch(setIsLoading(true));

    setSearchValue('');

    dispatch(getRewardsOffers('')).then(() => {
      setSearchValue('');

      dispatch(setIsLoading(false));
    });
  };

  const toggleHelpCard = (isOpen) => {
    setIsHelpCardsOpen(isOpen);
  };

  const downloadPdfModal = (filePath, filename) => {
    setIsPdfQueryLoading(true);

    setStatementsUrl(filePath);

    if (filePath) {
      fetch(filePath, {
        responseType: 'blob',
      })
        .then((response) => response.blob())
        .then((blob) => fileDownload(blob, filename))
        .then(() => {
          setIsPdfQueryLoading(false);
        })
        .catch((error) => {
          console.error('Request failed.', error);
        });
    }
  };

  const openPdfModal = (condition, statementsPdfUrl) => {
    setIsPdfModalOpen(condition);

    setStatementsUrl(statementsPdfUrl);
  };

  if (isInsurancesPath) {
    return (
      <Insurances
        downloadPdfModal={downloadPdfModal}
        isPdfModalOpen={isPdfModalOpen}
        openPdfModal={openPdfModal}
        statementsUrl={statementsUrl}
        isPdfQueryLoading={isPdfQueryLoading}
        areCertificatesLoading={areCertificatesLoading}
      />
    );
  }

  if (isHistoryPath) {
    return <RewardsHistory />;
  }

  if (isRewardDetailsPath) {
    return <RewardDetails />;
  }

  return (
    <RewardsView
      offers={offers}
      rewardCard={rewardCard}
      categories={categories}
      brandsFilter={brandsFilter}
      isNeedSearch={isNeedSearch}
      categoriesType={categoriesType}
      saveInvested={saveInvested}
      isHelpCardsOpen={isHelpCardsOpen}
      searchHandler={searchHandler}
      activeCategoryId={activeCategoryId}
      toggleHelpCard={toggleHelpCard}
      filterByCategory={filterByCategory}
      title={t('rewardsPage.title')}
      categoryTitle={t('rewardsPage.categoryTitle')}
      clearSearch={clearSearch}
      searchValue={searchValue}
      downloadPdfModal={downloadPdfModal}
    />
  );
}

Rewards.defaultProps = {
  isNeedSearch: true,
  state: {},
  history: {},
};

Rewards.propTypes = {
  isNeedSearch: PropTypes.bool,
  state: PropTypes.shape({
    translates: PropTypes.shape({
      messages: PropTypes.shape({}),
      locale: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
