import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { TViewMode } from 'pages/RaizKidsEdit/components/RecurringInvestment/types';
import { PATHS } from 'constants/paths';

export interface INavigateToRecurringZakatPayment {
  viewMode: TViewMode;
}

export const useNavigateToRecurringZakatPayment = () => {
  const navigate = useNavigate();

  const navigateToRecurringZakatPayment = ({
    viewMode,
  }: INavigateToRecurringZakatPayment) => {
    navigate(
      generatePath(PATHS.rewards.recurringZakatPayment, {
        viewMode,
      }),
    );
  };

  return navigateToRecurringZakatPayment;
};
