// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Loader from '../../elements/Loader/Loader';

import BankErrorModal from './BankErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { getBanks, setBankAccountData } from 'store/fundingAccount/actions';
import { AU_APP } from 'constants/localeConfigs';
import { Button } from 'components/elements/Button';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';
import { useInstitutionByIdQuery } from 'store/fundingAccount/fundingAccount';
import { selectBankAccountData } from 'store/fundingAccount/selectors';

function subaccountsRender(
  subaccount,
  handleSubAccount,
  monitoredId,
  isSpending,
  handleSubAccountFunding,
) {
  let checked = '';

  if (isSpending && subaccount.linked) {
    checked = 'checkbox__check';
  }

  return (
    <div className="info-panel__item" key={subaccount.account_id}>
      <div className="name">{subaccount.account_nickname}</div>

      <div className={`checkbox__plus ${checked}`}>
        +
        <input
          type="checkbox"
          onChange={(e) =>
            isSpending
              ? handleSubAccount(
                  e,
                  subaccount.account_id,
                  monitoredId,
                  subaccount.linked,
                )
              : handleSubAccountFunding(
                  e,
                  subaccount.account_id,
                  monitoredId,
                  subaccount.linked,
                  subaccount.account_nickname,
                  false,
                  subaccount.bank_id,
                )
          }
        />
      </div>
    </div>
  );
}

function spendingAccountsRender(
  account,
  handleSubAccount,
  isSpending,
  t,
  handleSubAccountFunding,
) {
  if (account && account.subaccounts && account.subaccounts[0]) {
    return (
      <div className="info-panel -company-name -with-padding" key={account.id}>
        <div className="info-panel__title accounts">
          {t('bankConnectModal.accounts')}
          <div className="info-panel__divider" />
        </div>

        <div className="info-panel__title flex-title">
          <ImageWithDefaultIcon
            src={account.icon}
            className="grid__container-row-icon -bank"
            withMargin
          />

          {account.name}
        </div>

        {account.subaccounts && account.subaccounts.length !== 0 && (
          <div>
            <div className="info-panel__divider" />

            {account.subaccounts.map((subaccount) =>
              subaccountsRender(
                subaccount,
                handleSubAccount,
                account.id,
                isSpending,
                handleSubAccountFunding,
              ),
            )}
          </div>
        )}
      </div>
    );
  }

  return <div />;
}

export default function BankConnectModal({
  monitoredInstitutions,
  handleSubAccount,
  errorMessage,
  handleCloseModalError,
  isShowModalError,
  isSpending,
  skip,
  handleSubAccountFunding,
  isReconectForFunding,
  showLoader,
  confirmationConnect,
  isShowConfirmationConnect,
  openDifferentBank,
  bankData,
  handleCloseModal,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  let errorMessageLoc = errorMessage;
  let isShowModalErrorLoc = isShowModalError;
  let isOpen = true;
  let isEmptySubaccount = false;

  const bankAccountData = useAppSelector(selectBankAccountData);

  const params = useMemo(
    () =>
      isSpending
        ? bankAccountData
        : {
            ...bankAccountData,
            additionalParams: {
              funding_source: 1,
            },
          },
    [bankAccountData, isSpending],
  );

  const { data } = useInstitutionByIdQuery(params, {
    skip: !params?.id,
  });

  const currentMonitoredInstitutions = bankAccountData
    ? [data?.monitored_institution]
    : monitoredInstitutions;

  useEffect(
    () => () => {
      dispatch(setBankAccountData(null));
    },
    [dispatch],
  );

  useEffect(() => {
    if (AU_APP && !bankAccountData && monitoredInstitutions.length === 0) {
      dispatch(getBanks({ isSpending }));
    }
  }, [dispatch, bankAccountData, isSpending, monitoredInstitutions]);

  if (currentMonitoredInstitutions?.[0]?.subaccounts.length === 0) {
    errorMessageLoc = t(
      'settingsPage.bankConnectModal.selectDifferentBankMessage',
    );

    isShowModalErrorLoc = true;

    isOpen = false;

    isEmptySubaccount = true;
  }

  const isFundingAccountFlow = isReconectForFunding || !isSpending;

  return (
    <div className="settings__funding-account">
      {errorMessageLoc && (
        <BankErrorModal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowModalErrorLoc}
          errorTitle={t('settingsPage.linkFundingAccount')}
          errorMessage={errorMessageLoc}
          handleClick={(e) =>
            handleCloseModalError(e, false, isEmptySubaccount)
          }
          shouldCloseOnOverlayClick
        />
      )}

      {isShowConfirmationConnect && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowConfirmationConnect}
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            {isSpending && (
              <div className="alert-title">
                {t(
                  'settingsPage.bankConnectModal.confirmationModal.spendingAccountTitle',
                )}
              </div>
            )}

            {!isSpending && (
              <div className="alert-title">
                {t('settingsPage.fundingAccount.title')}
              </div>
            )}

            <div className="alert-text">
              {t(
                'settingsPage.bankConnectModal.confirmationModal.description',
                {
                  context: isSpending ? 'Round-Up Account' : 'Funding Account',
                },
              )}
            </div>

            <div className="alert-buttons">
              <DeprecatedButton
                title={t('common.cancel')}
                type="button"
                buttonClass="wait"
                handleClick={(e) => handleCloseModal(e, true)}
              />

              <DeprecatedButton
                title={t('common.connect')}
                type="button"
                buttonClass="skip"
                handleClick={(e) =>
                  handleSubAccountFunding(
                    e,
                    bankData.accountId,
                    bankData.monitoredId,
                    bankData.linked,
                    bankData.accountNickname,
                    true,
                  )
                }
              />
            </div>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={
          isFundingAccountFlow ? (e) => handleCloseModal(e, true) : skip
        }
        contentLabel="withdraw-modal"
        shouldCloseOnOverlayClick
        className="modal-basic bank__modal bank-sign bank-connect"
      >
        {showLoader && (
          <div className="bank-loader">
            <div className="bank__modal-content">
              <DeprecatedButton
                title={t('common.space')}
                buttonClass="button__close -black"
                handleClick={
                  isFundingAccountFlow ? (e) => handleCloseModal(e, true) : skip
                }
              />

              <Loader additionalClass="-absolute -top" />
              <div className="title">{t('common.pleaseWait')}</div>

              <div className="sub-title">
                {t('settingsPage.dataBeingProcessedMessage')}
              </div>
            </div>
          </div>
        )}

        {!showLoader && (
          <div className="bank__modal-content">
            <DeprecatedButton
              title={t('common.space')}
              buttonClass="button__close -black"
              handleClick={
                isFundingAccountFlow ? (e) => handleCloseModal(e, true) : skip
              }
            />

            <div className="bank__modal-title">
              {isFundingAccountFlow
                ? t('settingsPage.fundingAccount.title')
                : t('settingsPage.bankConnectModal.roundUpsAccountsTitle')}
            </div>

            <div className="bank__modal-sub">
              <i className="bank__modal-secured-icon" />
              {t('common.secured')}
            </div>

            <div className="bank__modal-description">
              {isFundingAccountFlow
                ? t(
                    'settingsPage.bankConnectModal.selectAccountForInvestmentMessage',
                  )
                : t(
                    'settingsPage.bankConnectModal.selectAccountForRoundUpsMessage',
                  )}
            </div>

            {currentMonitoredInstitutions.map((account) =>
              spendingAccountsRender(
                account,
                handleSubAccount,
                isSpending,
                t,
                handleSubAccountFunding,
              ),
            )}

            <div className="bank__modal-row" />

            <div className="connect-btn">
              {isFundingAccountFlow ? (
                <Button
                  label={t(
                    'settingsPage.bankConnectModal.useDifferentBankButton',
                  )}
                  onClick={openDifferentBank}
                />
              ) : (
                <Button
                  label={t('common.connect')}
                  onClick={confirmationConnect}
                />
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

BankConnectModal.defaultProps = {
  monitoredInstitutions: [],
  isSpending: false,
  showLoader: false,
  isShowConfirmationConnect: false,
  bankData: {},
};

BankConnectModal.propTypes = {
  monitoredInstitutions: PropTypes.arrayOf(
    PropTypes.shape({
      subaccounts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  handleSubAccount: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  isSpending: PropTypes.bool,

  skip: PropTypes.func.isRequired,
  handleSubAccountFunding: PropTypes.func.isRequired,
  isReconectForFunding: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  confirmationConnect: PropTypes.func.isRequired,
  isShowConfirmationConnect: PropTypes.bool,
  openDifferentBank: PropTypes.func.isRequired,
  bankData: PropTypes.shape({
    accountNickname: PropTypes.string,
    accountId: PropTypes.string,
    monitoredId: PropTypes.string,
    linked: PropTypes.bool,
  }),
  handleCloseModal: PropTypes.func.isRequired,
};
