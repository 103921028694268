import React from 'react';

import { InfinitiumFormWrapper } from './styles';

interface IPaymentCardProps {
  request_url?: string;
  form_fields?: {
    [key: string]: string;
  };
}

interface IInfinitiumFormProps {
  paymentCard: IPaymentCardProps;
}

export const InfinitiumForm = ({ paymentCard }: IInfinitiumFormProps) => {
  const { request_url, form_fields } = paymentCard;

  if (!request_url || !form_fields) {
    return null;
  }

  return (
    <InfinitiumFormWrapper name="infinitium" action={request_url} method="post">
      {Object.entries(form_fields).map(([key, value]) => (
        <input type="text" key={key} name={key} value={value} />
      ))}
      ;
    </InfinitiumFormWrapper>
  );
};
