import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export default function CheckboxFF(props) {
  const {
    className,
    label,
    defaultChecked,
    placeholder,
    id,
    name,
    type,
    classList,
    onClick,
    classNameInsurance,
    disabled,
    value,
  } = props;

  const MemoizedCheckboxFF = useCallback(
    ({ input, meta: { touched, error, active } }) => (
      <div className={`form-field  ${className}`}>
        <div
          className={`form-field__input ${classNameInsurance}
        ${touched && error ? ' form-field__input--error' : ''}
        ${
          active || (input && input.value) ? ' form-field__input--active' : ''
        }`}
        >
          <input
            onClick={input.onClick || onClick}
            onChange={input.onChange}
            defaultChecked={defaultChecked}
            value={input.value ?? value}
            disabled={disabled}
            id={id}
            type={type}
            placeholder={placeholder}
            name={name}
          />

          <div className="form-field__label">{label}</div>

          {touched && error && <span className="text-danger">{error}</span>}
        </div>
      </div>
    ),
    // Need to remove onClick from the dependency array to fix autofill login form
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // onClick,
      className,
      classNameInsurance,
      defaultChecked,
      disabled,
      id,
      label,
      name,
      placeholder,
      type,
      value,
    ],
  );

  return (
    <Field
      name={name}
      id={id}
      type={type}
      label={label}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      classList={classList}
      component={MemoizedCheckboxFF}
      value={value}
    />
  );
}

CheckboxFF.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  classList: PropTypes.string,
  classNameInsurance: PropTypes.string,
  value: PropTypes.string,
};

CheckboxFF.defaultProps = {
  input: {},
  onClick: () => {},
  className: '',
  placeholder: '',
  label: null,
  defaultChecked: null,
  disabled: false,
  type: 'checkbox',
  name: null,
  id: null,
  classList: 'checkbox__basic',
  classNameInsurance: '',
  value: '',
};
