import { BAHASA } from '../../constants/comonConstants';
import { MY_APP } from '../../constants/localeConfigs';
import createAxiosInstance from '../../libs/axios';

import { getHeaders } from './headers';

export const requestInterceptor = (request) => {
  const currentLang = localStorage.getItem('lang');

  const requestWithHeaders = {
    ...request,
    headers: {
      ...request.headers,
      ...getHeaders(),
      'RAIZ-Client-Locale': MY_APP && currentLang === BAHASA ? 'ms' : '',
      ...(request.headers['Content-Type'] && {
        'Content-Type': request.headers['Content-Type'],
      }),
      ...(request.headers['User-Type'] && {
        'User-Type': request.headers['User-Type'],
      }),
      ...(request.headers['User-Uuid'] && {
        'User-Uuid': request.headers['User-Uuid'],
      }),
    },
  };

  return requestWithHeaders;
};

export const api = createAxiosInstance({ requestInterceptor });
