import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { RewardAccountCard } from './components/RewardAccountCard';
import { AddAccountButton, Description } from './styles';

import Loader from 'components/elements/Loader/Loader';
import { useInstitutionsQuery } from 'store/institutions';
import { PATHS } from 'constants/paths';
import { filterRoundUpAccountsForRewards } from 'pages/NewRewards/utils/filterRoundUpAccountsForRewards';

export const RewardsAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useInstitutionsQuery();

  // Need to remove when Round Up account connection refactored into rtk query
  // TODO: https://acornsau.atlassian.net/browse/RAIZ-6448
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isFetching) {
    return <Loader />;
  }

  const filteredAccounts = data?.monitored_institutions?.filter(
    filterRoundUpAccountsForRewards,
  );

  return (
    <div>
      <Description>{t('newRewardsPage.accounts.description')}</Description>

      {filteredAccounts?.map((bank) => (
        <RewardAccountCard key={bank.id} bank={bank} />
      ))}

      <AddAccountButton
        label={
          filteredAccounts?.length === 0
            ? t('newRewardsPage.accounts.button.add')
            : t('newRewardsPage.accounts.button.addAdditional')
        }
        onClick={() => navigate(PATHS.settings.spendingAccount.index)}
      />
    </div>
  );
};
