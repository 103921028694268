import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import {
  APPROVED,
  UNAPPROVED,
  MIN_INVEST,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
  REGISTRATION_INCOMPLETE_LINKSPENDING,
  ROUND_UP_HELP_CARD,
  MAX_INVEST,
} from '../../constants/comonConstants';
import FundingInfoModal from '../../components/layout/Modals/FundingInfoModal';
import ModalComponent from '../../components/elements/ModalComponent/ModalComponent';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';
import InvestmentErrorModal from '../../components/layout/Modals/InvestmentErrorModal';

import RoundUpsAutoButton from './components/RoundUpsAutoButton';
import RoundUpsManualButton from './components/RoundUpsManualButton';
import RoundUpsSwitch from './components/RoundUpsSwitch';
import { RoundUpsContentButtons } from './components/RoundUpsContentButtons';
import RoundUpsContent from './components/RoundUpsContent';
import { BannerValue, BannerRow, BannerCol } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import RoundUpsModal from 'components/layout/Modals/RoundUpsModal/RoundUpsModal';
import {
  CURRENCY_LOCALE,
  CURRENCY_NAME,
  AU_APP,
} from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

function RoundUpsView({
  user,
  changeStatus,
  toggleRoundUps,
  status,
  items,
  investSum,
  handleSelectCheckbox,
  submitTransactions,
  setIsConfirmModalOpen,
  uncheckAll,
  selectedTransactions,
  handleInvest,
  handleOpenRoundUpsModal,
  isRoundUpsModalOpen,
  isAutomaticRoundup,
  isConfirmModalOpen,
  roundUpsSummary,
  automaticThreshold,
  isLoading,
  toggleInfoModal,
  isRoundUpHelpCardOpen,
  toggleHelpCard,
  toggleHelpCardInfo,
  isHelpCardInfoOpen,
  setInView,
}) {
  const { t } = useTranslation();

  const isEmptyFunding = !!user?.alerts?.find(
    (alertMsg) => alertMsg.type === REGISTRATION_INCOMPLETE_LINKFUNDING,
  );

  const isEmptySpending = !!user?.alerts?.find(
    (alertMsg) => alertMsg.type === REGISTRATION_INCOMPLETE_LINKSPENDING,
  );

  const waitingInvest = MAX_INVEST - user.round_ups.waiting;

  return (
    <div>
      <div className="page-content__banner round-ups__banner">
        <DeprecatedButton
          buttonClass="page-content__banner-settings"
          handleClick={() => handleOpenRoundUpsModal(true)}
        />

        <div className="page-content__banner-title">
          {isRoundUpHelpCardOpen && (
            <HelpCardsModalAU
              isOpenHelpCards={isRoundUpHelpCardOpen}
              currentPage={ROUND_UP_HELP_CARD}
              toggleHelpCard={toggleHelpCard}
            />
          )}

          {t('roundUps.subtitle')}

          <BannerValue>
            {formatNumber({
              value: user?.round_ups?.invested,
            })}
          </BannerValue>

          <BannerRow>
            <BannerCol>
              <RoundUpsAutoButton
                toggleRoundUps={toggleRoundUps}
                isAutomaticRoundup={isAutomaticRoundup}
                roundUpsSummary={roundUpsSummary}
                automaticThreshold={automaticThreshold}
              />
            </BannerCol>

            <BannerCol>
              <RoundUpsManualButton user={user} handleInvest={handleInvest} />
            </BannerCol>
          </BannerRow>

          {AU_APP && (
            <RoundUpsSwitch status={status} changeStatus={changeStatus} />
          )}
        </div>
      </div>

      <RoundUpsContentButtons
        investSum={investSum}
        submitTransactions={submitTransactions}
        uncheckAll={uncheckAll}
        selectedTransactions={selectedTransactions}
      />

      {!isLoading && items?.length === 0 && (
        <div>
          <div className="icon-not-found" />

          <div className="not-found">
            {status === null && t('roundUps.status.all')}

            {status === APPROVED && t('roundUps.status.invested')}

            {status === UNAPPROVED && t('roundUps.status.available')}
          </div>
        </div>
      )}

      <div className="info-panel">
        <RoundUpsContent
          items={items}
          isLoading={isLoading}
          handleSelectCheckbox={handleSelectCheckbox}
        />
      </div>

      {isRoundUpsModalOpen && (
        <RoundUpsModal
          isRoundUpsModalOpen={isRoundUpsModalOpen}
          handleOpenRoundUpsModal={handleOpenRoundUpsModal}
        />
      )}

      {isConfirmModalOpen && (
        <ModalComponent
          modalClass="modal-basic time-investment__modal time-investment__modal-quick"
          isOpen={isConfirmModalOpen}
          onRequestClose={() => setIsConfirmModalOpen(false)}
          modalTitle={t('roundUps.investNow')}
          modalContent={
            <Trans
              t={t}
              i18nKey="roundUps.wantToInvestThreshold"
              components={{
                invest: new Intl.NumberFormat(CURRENCY_LOCALE, {
                  style: 'currency',
                  currency: CURRENCY_NAME,
                }).format(MIN_INVEST),
                waiting: new Intl.NumberFormat(CURRENCY_LOCALE, {
                  style: 'currency',
                  currency: CURRENCY_NAME,
                }).format(waitingInvest),
              }}
            />
          }
          submitButtonTitle={t('roundUps.invest')}
          submitButtonAction={() => handleInvest(true)}
          cancelButtonTitle={t('roundUps.cancel')}
          cancelButtonAction={() => setIsConfirmModalOpen(false)}
        />
      )}

      {(isEmptyFunding || isEmptySpending) && (
        <FundingInfoModal
          toggleInfoModal={toggleInfoModal}
          isEmptyFunding={isEmptyFunding}
          isEmptySpending={isEmptySpending}
          isInfoModalOpen={isEmptyFunding || isEmptySpending}
        />
      )}

      {isHelpCardInfoOpen && (
        <InvestmentErrorModal
          isOpen={isHelpCardInfoOpen}
          onRequestClose={() => toggleHelpCardInfo(false)}
          handleClick={() => toggleHelpCardInfo(false)}
          modalClassName="-help"
          shouldCloseOnOverlayClick
          errorMessage={
            <Trans
              t={t}
              i18nKey="roundUps.helpInfo"
              components={{
                automaticThreshold: formatNumber({
                  value: automaticThreshold,
                }),
                waiting: formatNumber({
                  value: roundUpsSummary?.automatic?.waiting,
                }),
              }}
            />
          }
        />
      )}

      <InView onChange={setInView} />
    </div>
  );
}

export default RoundUpsView;

RoundUpsView.defaultProps = {
  status: null,
  items: null,
  roundUpsSummary: {
    automatic: {
      waiting: 0,
    },
  },
  isRoundUpsModalOpen: false,
  automaticThreshold: '0',
};

RoundUpsView.propTypes = {
  user: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
    round_ups: PropTypes.shape({
      invested: PropTypes.number,
      waiting: PropTypes.number,
    }),
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  changeStatus: PropTypes.func.isRequired,
  toggleRoundUps: PropTypes.func.isRequired,
  handleInvest: PropTypes.func.isRequired,
  uncheckAll: PropTypes.func.isRequired,
  handleSelectCheckbox: PropTypes.func.isRequired,
  submitTransactions: PropTypes.func.isRequired,
  toggleInfoModal: PropTypes.func.isRequired,
  handleOpenRoundUpsModal: PropTypes.func.isRequired,
  status: PropTypes.string,
  automaticThreshold: PropTypes.string,
  isRoundUpsModalOpen: PropTypes.bool,
  setIsConfirmModalOpen: PropTypes.func.isRequired,
  isConfirmModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAutomaticRoundup: PropTypes.bool.isRequired,
  investSum: PropTypes.number.isRequired,
  settings: PropTypes.shape({}).isRequired,
  roundUpsSummary: PropTypes.shape({
    automatic: PropTypes.shape({
      waiting: PropTypes.number,
    }),
  }),
  selectedTransactions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRoundUpHelpCardOpen: PropTypes.bool.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  toggleHelpCardInfo: PropTypes.func.isRequired,
  isHelpCardInfoOpen: PropTypes.bool.isRequired,
  setInView: PropTypes.func.isRequired,
};
