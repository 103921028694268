import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';

import { APP_DATE_LOCALE } from '../../../constants/localeConfigs';
import { DATE_FORMAT } from '../../../config/date';

function Time({ format, children }) {
  return <time>{dayjs(children).locale(APP_DATE_LOCALE).format(format)}</time>;
}

Time.propTypes = {
  children: PropTypes.string,
  format: PropTypes.string,
};

Time.defaultProps = {
  children: '',
  format: DATE_FORMAT,
};

export default Time;
