import StatementsBanner from './components/StatementsBanner/StatementsBanner';
import StatementsContent from './components/StatementsContent/StatementsContent';
import { useStatements } from './hooks/useStatements';
import { useStatementsPath } from './hooks/useStatementsPath';

import StatementsPreviewModal from 'components/layout/Modals/StatementsPreviewModal';
import ErrorModal from 'components/layout/Modals/ErrorModal';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';

function Statements() {
  useSuperAccountErrorModal();

  const {
    currentType,
    changeCurrentType,
    openPdfModal,
    isStatementsNested,
    childId,
    statementsTotalPages,
    areStatementsLoading,
    isPdfModalOpen,
    statementsUrl,
    isPdfQueryLoading,
    isErrorModalOpen,
    errorMessage,
    toggleErrorModal,
  } = useStatements();

  const { isRaizKids } = useStatementsPath();

  return (
    <>
      <StatementsBanner
        currentType={currentType}
        changeCurrentType={changeCurrentType}
      />

      <StatementsContent
        currentType={currentType}
        openPdfModal={openPdfModal}
        isStatementsNested={isStatementsNested}
        childId={isRaizKids && childId ? childId : ''}
        totalPages={statementsTotalPages || 0}
        areStatementsLoading={areStatementsLoading}
      />

      <StatementsPreviewModal
        isStatementsPreviewModalOpen={isPdfModalOpen}
        openPdfModal={openPdfModal}
        statementsUrl={statementsUrl}
        isPdfQueryLoading={isPdfQueryLoading}
      />

      {isErrorModalOpen && (
        <ErrorModal
          errorMessage={errorMessage}
          isOpenErrorModal={isErrorModalOpen}
          toggleErrorModal={toggleErrorModal}
        />
      )}
    </>
  );
}

export default Statements;
