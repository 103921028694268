import { IPortfolio } from 'store/user/types';

interface IgetRecommendedPortfolioDataProps {
  id: string;
  portfolios: IPortfolio[];
}

export const getRecommendedPortfolioData = ({
  id,
  portfolios,
}: IgetRecommendedPortfolioDataProps) => {
  const recommendedPortfolio = portfolios.filter(
    (portfolio) => portfolio.id === id,
  )?.[0];

  const series =
    recommendedPortfolio?.etfs.map((etf) => etf.percent * 10) || [];

  return { series, name: recommendedPortfolio?.name };
};
