import styled from '@emotion/styled';

import arrowBackWhite from 'assets/images/icons/arrow-back-white.svg';
import arrowBackBlack from 'assets/images/icons/arrow-back.svg';

export const PortfolioBackButtonWrapper = styled.button`
  display: flex;
  position: absolute;
  left: 30px;
  top: 30px;
  cursor: pointer;
  background: transparent;
`;

interface IPortfolioBackButtonStyleProps {
  isRegistrationBasePortfolio?: boolean;
}

export const PortfolioBackButtonIcon = styled.div<IPortfolioBackButtonStyleProps>`
  display: flex;
  background-image: url(${({ isRegistrationBasePortfolio }) =>
    isRegistrationBasePortfolio ? `${arrowBackBlack}` : `${arrowBackWhite}`});
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

export const PortfolioBackButtonTitle = styled.div<IPortfolioBackButtonStyleProps>`
  color: ${({ theme, isRegistrationBasePortfolio }) =>
    isRegistrationBasePortfolio ? theme.colors.mirage : theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-left: 12px;
`;
