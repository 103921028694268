export const defaultErrorHandler = () => {};

// TODO: https://acornsau.atlassian.net/browse/RAIZ-4034
export const createErrorInterceptor =
  (handleError = defaultErrorHandler) =>
  (error) => {
    handleError(error);

    return Promise.reject(error);
  };

export const responseInterceptor = (response) => {
  const { data, headers } = response;

  if (data instanceof ArrayBuffer || data instanceof Blob) {
    return { data, headers };
  }

  return data;
};
