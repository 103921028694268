import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes, useState } from 'react';

import { ICustomWrapper, IFieldProps } from '../types';

import {
  TogglePasswordButton,
  InputErrorMessage,
  InputLabel,
  InputStyled,
  InputWrapper,
} from './styles';

interface IInputFieldProps<T extends FieldValues> extends IFieldProps<T> {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  CustomInputWrapper?: React.FC<ICustomWrapper>;
  isPassword?: boolean;
}

export const InputField = <T extends FieldValues>({
  name,
  label,
  inputProps = {},
  inputLabelProps = {},
  CustomInputWrapper = InputWrapper,
  isPassword,
}: IInputFieldProps<T>) => {
  const { control } = useFormContext();

  const [isPasswordVisible, setIsPasswordVisible] = useState(!!isPassword);

  const toggleTextVisibility = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    setIsPasswordVisible(
      (prevIsPasswordTypeVisible) => !prevIsPasswordTypeVisible,
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CustomInputWrapper>
          <InputStyled
            {...field}
            {...inputProps}
            onChange={(e) => {
              field.onChange(e);

              inputProps?.onChange?.(e);
            }}
            id={field.name}
            type={isPasswordVisible ? 'password' : 'text'}
          />

          {/* Animated absolute label - need to be placed after input */}
          {label && (
            <InputLabel {...inputLabelProps} htmlFor={field.name}>
              {label}
            </InputLabel>
          )}

          {error?.message && (
            <InputErrorMessage>{error?.message}</InputErrorMessage>
          )}

          {isPassword && (
            <TogglePasswordButton
              type="button"
              isPasswordVisible={isPasswordVisible}
              onClick={toggleTextVisibility}
            />
          )}
        </CustomInputWrapper>
      )}
    />
  );
};
