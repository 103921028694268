export const CONNECTIONS_STATUS_PATHS = {
  fundingAccount: {
    registration: 'fundingAccount',
    inApp: 'funding-account',
  },
};

export const BANK_LINKING_STATUS = {
  completed: 'completed',
  failed: 'failed',
};
