import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Button } from 'components/elements/Button';

export const KidCardWrapper = styled(Link)`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 6px;
  padding: ${({ isClosed }) => (isClosed ? '20px 0' : '25px 0 20px')};
  min-height: ${({ isClosed }) => (isClosed ? 'auto' : '150px')};
  color: ${({ theme, isInvestingEligible }) =>
    isInvestingEligible ? theme.colors.grey : theme.colors.mirage};
  text-decoration: none;
  pointer-events: ${({ isInvestingEligible }) =>
    isInvestingEligible ? 'none' : 'unset'};

  :hover {
    color: ${({ theme, isInvestingEligible }) =>
      isInvestingEligible ? theme.colors.grey : theme.colors.mirage};
  }
`;

export const KidCardImage = styled.img`
  width: 52px;
  margin: ${({ isClosed }) => (isClosed ? 'unset' : '0 auto')};
`;

export const KidAgeCard = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  margin-top: 11px;
`;

export const KidCardRow = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${({ isClosed }) =>
    isClosed &&
    `
    justify-content: center;
    align-items: center;
  `}
`;

export const KidCardOpenedHeader = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  min-height: 65px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
`;

export const KidCardOpenedBalance = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-top: 8px;
  margin-bottom: 10px;
`;

export const KidCardOpenedButton = styled(Button)`
  margin: 10px 0 20px;
  width: auto;
  padding: 0 140px;
`;

export const KidCardOpenedPortfolioHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: 14px;
  margin-bottom: 12px;
  width: 100%;
`;

export const KidCardOpenedInfo = styled.div`
  width: 50%;
  min-width: 50%;
  text-align: center;
`;

export const KidCardOpenedLabel = styled.div`
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  margin-bottom: 5px;
`;

export const KidCardOpenedValue = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  display: flex;
  justify-content: center;
  gap: 6px;
`;

export const Frequency = styled.div`
  text-align: left;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const KidCardOpenedLink = styled(Link)`
  background: transparent;
  color: ${({ theme }) => theme.colors.niagaraLight};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  text-decoration: none;
`;
