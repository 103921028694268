import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InvestmentErrorModal from '../../components/layout/Modals/InvestmentErrorModal';
import '../../styles/pages/offsetters.scss';

import { Button } from 'components/elements/Button';

function OffsettersDetailsView({
  showCongrats,
  joinOffsetters,
  toggleCongrats,
  offsetters,
}) {
  const { t } = useTranslation();

  return (
    <div className="page-content offsetters__content">
      <iframe
        src={offsetters.landingUrl}
        width="100%"
        height="100%"
        align="center"
        className="iframe"
        title="offsetters"
      />

      <div className="offsetters__btn-container">
        <Button
          label={t('offsettersDetailsPage.joinOffsettersButton')}
          onClick={joinOffsetters}
        />
      </div>

      {showCongrats && (
        <InvestmentErrorModal
          isOpen={showCongrats}
          shouldCloseOnOverlayClick
          onRequestClose={() => toggleCongrats(false)}
          handleClick={() => {
            toggleCongrats(false);

            window.location.href = '/';
          }}
          errorMessage={t('offsettersDetailsPage.congratulationsBanner')}
        />
      )}
    </div>
  );
}

OffsettersDetailsView.propTypes = {
  showCongrats: PropTypes.bool.isRequired,
  joinOffsetters: PropTypes.func.isRequired,
  toggleCongrats: PropTypes.func.isRequired,
  offsetters: PropTypes.shape({
    landingUrl: PropTypes.string,
    buttonTitle: PropTypes.string,
  }).isRequired,
};

export default OffsettersDetailsView;
