import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RaizKidsToggleButton, RaizKidsSwitchWrapper } from './styles';

function RaizKidsSwitch({ isActiveListOpen, setIsActiveListOpen }) {
  const { t } = useTranslation();

  return (
    <RaizKidsSwitchWrapper>
      <RaizKidsToggleButton
        label={t('common.status.active')}
        isActive={isActiveListOpen}
        onClick={() => setIsActiveListOpen(true)}
        variant="initial"
      />

      <RaizKidsToggleButton
        label={t('common.status.closed')}
        isActive={!isActiveListOpen}
        onClick={() => setIsActiveListOpen(false)}
        variant="initial"
      />
    </RaizKidsSwitchWrapper>
  );
}

export default RaizKidsSwitch;

RaizKidsSwitch.propTypes = {
  isActiveListOpen: PropTypes.bool.isRequired,
  setIsActiveListOpen: PropTypes.func.isRequired,
};
