import { defineMessages } from 'react-intl';

import { RECURRING_INVESTMENT as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  recurringInvestment: {
    id: `${idPrefix}.recurringInvestment`,
    defaultMessage: 'Recurring Investment',
  },
  currentBalance: {
    id: `${idPrefix}.currentBalance`,
    defaultMessage: 'Current balance:',
  },
  savingGoal: {
    id: `${idPrefix}.savingGoal`,
    defaultMessage: 'Savings Goal',
  },
  setFinancialGoal: {
    id: `${idPrefix}.setFinancialGoal`,
    defaultMessage: 'Set a financial goal and keep track of it',
  },
  chooseGoal: {
    id: `${idPrefix}.chooseGoal`,
    defaultMessage: 'Choose the Goal',
  },
  goalAmount: {
    id: `${idPrefix}.goalAmount`,
    defaultMessage: 'Goal Amount',
  },
  recurringInvAmount: {
    id: `${idPrefix}.recurringInvAmount`,
    defaultMessage: 'Recurring Inv Amount',
  },
  frequency: {
    id: `${idPrefix}.frequency`,
    defaultMessage: 'Frequency',
  },
  setDailyInvestment: {
    id: `${idPrefix}.setDailyInvestment`,
    defaultMessage: 'Set Daily Investment',
  },
  investmentFrequency: {
    id: `${idPrefix}.investmentFrequency`,
    defaultMessage: 'Investment Frequency',
  },
  forecastGoalDate: {
    id: `${idPrefix}.forecastGoalDate`,
    defaultMessage: 'Forecast Goal Date',
  },
  nextInvestment: {
    id: `${idPrefix}.nextInvestment`,
    defaultMessage: 'Next Investment',
  },
  editSavingGoal: {
    id: `${idPrefix}.editSavingGoal`,
    defaultMessage: 'Edit Saving Goal',
  },
  deleteSavingGoal: {
    id: `${idPrefix}.deleteSavingGoal`,
    defaultMessage: 'Delete Saving Goal',
  },
  setSavingGoal: {
    id: `${idPrefix}.setSavingGoal`,
    defaultMessage: 'Set Savings Goal',
  },
  recurringInvestmentAmount: {
    id: `${idPrefix}.recurringInvestmentAmount`,
    defaultMessage: 'Recurring Investment Amount',
  },
  changePlan: {
    id: `${idPrefix}.changePlan`,
    defaultMessage: 'Change Plan',
  },
  stopWeeklyInvestment: {
    id: `${idPrefix}.stopWeeklyInvestment`,
    defaultMessage: 'Stop Weekly Investment',
  },
  stopInvestment: {
    id: `${idPrefix}.stopInvestment`,
    defaultMessage: 'Stop {frequency} Investment',
  },
  daily: {
    id: `${idPrefix}.daily`,
    defaultMessage: 'Daily',
  },
  weekly: {
    id: `${idPrefix}.weekly`,
    defaultMessage: 'Weekly',
  },
  fortnightly: {
    id: `${idPrefix}.fortnightly`,
    defaultMessage: 'Fortnightly',
  },
  monthly: {
    id: `${idPrefix}.monthly`,
    defaultMessage: 'Monthly',
  },
  setDaily: {
    id: `${idPrefix}.setDaily`,
    defaultMessage: 'Set Daily',
  },
  selectDayOfWeek: {
    id: `${idPrefix}.selectDayOfWeek`,
    defaultMessage: 'Select day of week',
  },
  chooseTheStartDay: {
    id: `${idPrefix}.chooseTheStartDay`,
    defaultMessage: 'Choose the start day',
  },
  lastDayOfMonth: {
    id: `${idPrefix}.lastDayOfMonth`,
    defaultMessage: 'Last Day of the Month',
  },
  setMonthlyOn: {
    id: `${idPrefix}.setMonthlyOn`,
    defaultMessage: 'Set Monthly - on {day}',
  },
  setFortnightlyOn: {
    id: `${idPrefix}.setFortnightlyOn`,
    defaultMessage: 'Set Fortnightly - {day}',
  },
  setFortnightly: {
    id: `${idPrefix}.setFortnightly`,
    defaultMessage: 'Set Fortnightly',
  },
  setWeeklyOn: {
    id: `${idPrefix}.setWeeklyOn`,
    defaultMessage: 'Set Weekly {day}',
  },
  selectYourGoal: {
    id: `${idPrefix}.selectYourGoal`,
    defaultMessage: 'Select your goal',
  },
  setGoal: {
    id: `${idPrefix}.setGoal`,
    defaultMessage: 'Set goal',
  },
  frequencyOn: {
    id: `${idPrefix}.frequencyOn`,
    defaultMessage: '{frequency} on {day}',
  },
  monthlyOn: {
    id: `${idPrefix}.monthlyOn`,
    defaultMessage: 'Monthly on the {day}',
  },
  fortnightlyOn: {
    id: `${idPrefix}.fortnightlyOn`,
    defaultMessage: 'Fortnightly on {day}',
  },
  every: {
    id: `${idPrefix}.every`,
    defaultMessage: 'Every {day}',
  },
  everyDay: {
    id: `${idPrefix}.everyDay`,
    defaultMessage: 'Every day',
  },
  setInvestment: {
    id: `${idPrefix}.setInvestment`,
    defaultMessage: 'Set Investment',
  },
  setdailyInvestment: {
    id: `${idPrefix}.setdailyInvestment`,
    defaultMessage: 'Set Daily Investment',
  },
  setHarianInvestment: {
    id: `${idPrefix}.setHarianInvestment`,
    defaultMessage: 'Set Harian',
  },
  setmonthlyInvestment: {
    id: `${idPrefix}.setmonthlyInvestment`,
    defaultMessage: 'Set Monthly Investment',
  },
  setBulananInvestment: {
    id: `${idPrefix}.setBulananInvestment`,
    defaultMessage: 'Set Bulanan',
  },
  setweeklyInvestment: {
    id: `${idPrefix}.setweeklyInvestment`,
    defaultMessage: 'Set Weekly Investment',
  },
  setMingguanInvestment: {
    id: `${idPrefix}.setweeklyInvestment`,
    defaultMessage: 'Set Weekly Investment',
  },
  setfortnightlyInvestment: {
    id: `${idPrefix}.setfortnightlyInvestment`,
    defaultMessage: 'Set Fortnightly Investment',
  },
  'setDua-mingguanInvestment': {
    id: `${idPrefix}.setDua-mingguanInvestment`,
    defaultMessage: 'Set Setiap 2 Minggu',
  },
  oops: {
    id: `${idPrefix}.oops`,
    defaultMessage: 'Oops!',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'Ok',
  },
  cancel: {
    id: `${idPrefix}.cancel`,
    defaultMessage: 'Сancel',
  },
  currentGoalNameError: {
    id: `${idPrefix}.currentGoalNameError`,
    defaultMessage: 'Please enter goal name',
  },
  targetAmountError: {
    id: `${idPrefix}.targetAmountError`,
    defaultMessage: 'Please enter goal amount',
  },
  invAmountError: {
    id: `${idPrefix}.invAmountError`,
    defaultMessage: 'Please enter recurring investment amount',
  },
  lessThanCurrentBalance: {
    id: `${idPrefix}.lessThanCurrentBalance`,
    defaultMessage: 'Goal amount cannot be less than current balance',
  },
  minAmountError: {
    id: `${idPrefix}.minAmountError`,
    defaultMessage: 'Recurring investment amount cannot be less than 5',
  },
  noteImportant: {
    id: `${idPrefix}.noteImportant`,
    defaultMessage:
      'Note/Important: Super is intended to fund your retirement, so you can generally only access ' +
      'it or make a withdrawal once you have retired from working or have met a condition of release. ' +
      'Refer FAQs for more information.',
  },
  stopRecurring: {
    id: `${idPrefix}.stopRecurring`,
    defaultMessage: 'Stop Recurring',
  },
  stop: {
    id: `${idPrefix}.stop`,
    defaultMessage: 'Stop',
  },
  later: {
    id: `${idPrefix}.later`,
    defaultMessage: 'Later',
  },
  viewHistory: {
    id: `${idPrefix}.viewHistory`,
    defaultMessage: 'View History',
  },
  sureWantInvest: {
    id: `${idPrefix}.sureWantInvest`,
    defaultMessage:
      'Are you sure you want to stop your {amount} {day} investment?',
  },
  youHavePending: {
    id: `${idPrefix}.youHavePending`,
    defaultMessage:
      'You have pending investments. You can view them on the History screen. Note that some ' +
      'pending investments may not be cancelled.',
  },
  chooseDay: {
    id: `${idPrefix}.chooseDay`,
    defaultMessage: 'Choose a day of the month for your recurring investment',
  },
  chooseFrequency: {
    id: `${idPrefix}.chooseFrequency`,
    defaultMessage: 'Please, choose investment frequency!',
  },
  chooseInvestmentFrequency: {
    id: `${idPrefix}.chooseInvestmentFrequency`,
    defaultMessage: 'Choose investment frequency',
  },
  setGoalName: {
    id: `${idPrefix}.setGoalName`,
    defaultMessage: 'Set Goal {goalName}',
  },
  addCustomGoalName: {
    id: `${idPrefix}.addCustomGoalName`,
    defaultMessage: 'Add custom goal name',
  },
  yourGoal: {
    id: `${idPrefix}.yourGoal`,
    defaultMessage: 'Your goal',
  },
  nameGoal: {
    id: `${idPrefix}.nameGoal`,
    defaultMessage: 'Name goal',
  },
  dana_monthly: {
    id: `${idPrefix}.dana_monthly`,
    defaultMessage: 'Bulanan',
  },
  dana_monthly_full: {
    id: `${idPrefix}.dana_monthly_full`,
    defaultMessage: 'Bulanan pada hari {day}',
  },
  dana_weekly: {
    id: `${idPrefix}.dana_weekly`,
    defaultMessage: 'Mingguan',
  },
  dana_weekly_full: {
    id: `${idPrefix}.dana_weekly`,
    defaultMessage: 'Mingguan pada hari {day}',
  },
  pengaturan: {
    id: `${idPrefix}.pengaturan`,
    defaultMessage: 'Pengaturan Investasi Berkala',
  },
  investasiBerkala: {
    id: `${idPrefix}.investasiBerkala`,
    defaultMessage:
      'Investasi berkala kamu akan dipotong\n' +
      '  dari akun DANA kamu pada tanggal ',
  },
  investasiBerkalaWeekly: {
    id: `${idPrefix}.investasiBerkala`,
    defaultMessage:
      'Investasi berkala kamu akan dipotong\n' +
      '  dari akun DANA kamu pada hari ',
  },
  setiapBulan: {
    id: `${idPrefix}.setiapBulan`,
    defaultMessage: ' setiap bulan.',
  },
  setiapBulanWeekly: {
    id: `${idPrefix}.setiapBulan`,
    defaultMessage: ' setiap minggu.',
  },
  biayaTransaksi: {
    id: `${idPrefix}.biayaTransaksi`,
    defaultMessage: 'Biaya Transfer',
  },
  cndaAkan: {
    id: `${idPrefix}.Anda akan`,
    defaultMessage:
      'Kamu akan dikenakan biaya transfer untuk semua \n' +
      'metode pembayaran sebesar ',
  },
  setuju: {
    id: `${idPrefix}.setuju`,
    defaultMessage: 'Setuju',
  },
  pengaturanTarget: {
    id: `${idPrefix}.pengaturanTarget`,
    defaultMessage: 'Ubah Tujuan Investasi',
  },
  goPay: {
    id: `${idPrefix}.goPay`,
    defaultMessage: 'GoPay',
  },
  investasiBerkalaDipotong: {
    id: `${idPrefix}.investasiBerkalaDipotong`,
    defaultMessage:
      'Investasi berkala kamu akan dipotong dari akun {merchantName} kamu pada tanggal ',
  },
  investasiBerkalaWeeklyBerkala: {
    id: `${idPrefix}.investasiBerkalaWeeklyBerkala`,
    defaultMessage:
      'Investasi berkala kamu akan dipotong\n' +
      '  dari akun {merchantName} kamu pada hari ',
  },
});

export default messages;
