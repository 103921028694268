import React from 'react';

import ResidentialPropertyFundsItemSkeleton from './ResidentialPropertyFundsItemSkeleton';

function ResidentialPropertyFundsSkeleton() {
  return [...Array(8)].map((e, index) => (
    <ResidentialPropertyFundsItemSkeleton key={`${index * 2}`} index={index} />
  ));
}

export default ResidentialPropertyFundsSkeleton;
