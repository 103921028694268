import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useRecoverPasswordFormSchema = () => {
  const { t } = useTranslation();

  return yup
    .object({
      email: yup.string().label(t('recoverPasswordPage.email')).required(),
    })
    .required();
};
