import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

class OneTimeInvestmentQuickModal extends Component {
  render() {
    const { t } = this.props;

    const {
      isOpenQuickInvestmentModal,
      openQuickInvestmentModal,
      oneTimeInvestmentValue,
      oneTimeInvest,
      showLoader,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isOpenQuickInvestmentModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal time-investment__modal-quick"
        >
          <div className="time-investment__modal-title">
            {t('oneTimeInvestment.quickModal.title')}
          </div>

          <div className="time-investment__modal-content">
            <Trans
              i18nKey="oneTimeInvestment.quickModal.description"
              t={t}
              components={{
                value: formatNumber({ value: oneTimeInvestmentValue }),
              }}
            />
          </div>

          <div className="time-investment__modal-footer">
            <DeprecatedButton
              title={t('common.cancel')}
              buttonClass="button__basic-transparent -black"
              handleClick={() => openQuickInvestmentModal(false)}
            />

            <Button
              label={t('common.invest')}
              isLoading={showLoader}
              onClick={() => oneTimeInvest(oneTimeInvestmentValue)}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

OneTimeInvestmentQuickModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpenQuickInvestmentModal: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  oneTimeInvestmentValue: PropTypes.number.isRequired,
  openQuickInvestmentModal: PropTypes.func.isRequired,
  oneTimeInvest: PropTypes.func.isRequired,
};

export default withTranslation()(OneTimeInvestmentQuickModal);
