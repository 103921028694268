import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// INFO: temp wrapper - only to keep previous implementation working after upgrading react router
// will be gradually removed from each Route component
// IMPORTANT: must NOT be used for new components
export const TempRouteWrapper = ({ children, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // INFO: temp - fallback id to null to avoid app crashes
  const match = { id: null, params };

  const history = {
    push: (pathname, routeState = {}) => {
      navigate(pathname, { state: routeState });
    },
    location,
    goBack: () => navigate(-1),
  };

  return (
    <>
      {React.cloneElement(children, {
        history,
        match,
        location,
        ...props,
      })}
    </>
  );
};

TempRouteWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
