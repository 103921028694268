import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Firebase from 'firebase/app';

import * as spendingAnalyticsActions from '../../store/spendingAnalytics/actions';
import { AMOUNT } from '../../constants/comonConstants';

import MyFinanceView from './MyFinanceView';

class MyFinance extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      monthlySpending: 0,
      averageSpending: 0,
      showLoader: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics
      .getTransactionCategorisation({ uncategorised: false }, AMOUNT, 2)
      .then(() => {
        if (this._isMounted) {
          this.setState({ showLoader: false });
        }
      });

    Firebase.analytics().logEvent('Transaction_Categorisation');

    actionsSpendingAnalytics.getCategories();

    actionsSpendingAnalytics
      .getTotalsByCategory({ withMinDate: true })
      .then(() => {
        if (this._isMounted) {
          const { categoryTotals } = this.props;

          if (categoryTotals && categoryTotals.payload) {
            this.getMonthlySpending(categoryTotals);
          }
        }
      });

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isRedirectToHome, history } = this.props;

    if (
      newProps.isRedirectToHome &&
      newProps.isRedirectToHome !== isRedirectToHome
    ) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getMonthlySpending(categoryTotals) {
    const { categories } = categoryTotals.payload;

    const unCategorised = categories
      ? categories.find((element) => element.category === 'Uncategorized')
      : [];

    const categoriesTotalValue =
      categoryTotals.payload &&
      categoryTotals.payload.monthly_total &&
      categoryTotals.payload.monthly_total[0].length
        ? categoryTotals.payload.monthly_total[0][1]
        : 0;

    const unCategorisedValue =
      unCategorised && unCategorised.monthly ? unCategorised.monthly[0][1] : 0;

    const monthlyTotal = categoryTotals.payload.monthly_total;
    const unCategorisedTotal = unCategorised ? unCategorised.monthly : 0;
    let monthlyTotalTmp = 0;

    if (
      monthlyTotal &&
      monthlyTotal.length &&
      monthlyTotal[1] &&
      monthlyTotal[2] &&
      monthlyTotal[3]
    ) {
      monthlyTotalTmp =
        monthlyTotal[1][1] + monthlyTotal[2][1] + monthlyTotal[3][1];
    }

    const totalValueMonthly = monthlyTotal ? monthlyTotalTmp : 0;
    let totalUncategorisedTmp = 0;

    if (
      unCategorisedTotal &&
      unCategorisedTotal[1] &&
      unCategorisedTotal[2] &&
      unCategorisedTotal[3]
    ) {
      totalUncategorisedTmp =
        unCategorisedTotal[1][1] +
        unCategorisedTotal[2][1] +
        unCategorisedTotal[3][1];
    }

    const totalUncategorised =
      unCategorised && unCategorisedTotal ? totalUncategorisedTmp : 0;

    this.setState({
      monthlySpending: Math.round(categoriesTotalValue - unCategorisedValue),
      averageSpending: monthlyTotal
        ? Math.round((totalValueMonthly - totalUncategorised) / 3)
        : 0,
    });
  }

  render() {
    const { monthlySpending, averageSpending, showLoader } = this.state;

    const { categoryTotals, transactionCategorisation, categoriesList } =
      this.props;

    return (
      <MyFinanceView
        transactionCategorisation={transactionCategorisation.payload}
        averageSpending={averageSpending}
        monthlySpending={monthlySpending}
        categoryTotals={categoryTotals}
        categoriesList={categoriesList}
        showLoader={showLoader}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  categoryTotals: state.spendingAnalytics.categoryTotals,
  categoriesList: state.spendingAnalytics.categoriesList,
  transactionCategorisation: state.spendingAnalytics.transactionCategorisation,
  isRedirectToHome: state.spendingAnalytics.isRedirectToHome,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFinance);

MyFinance.defaultProps = {
  transactionCategorisation: { payload: [] },
  categoriesList: [],
  actionsSpendingAnalytics: {},
  categoryTotals: { payload: {} },
  isRedirectToHome: false,
};

MyFinance.propTypes = {
  categoryTotals: PropTypes.shape({
    payload: PropTypes.shape({}),
  }),
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  transactionCategorisation: PropTypes.shape({
    payload: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  actionsSpendingAnalytics: PropTypes.shape({
    getTotalsByCategory: PropTypes.func,
    getTransactionCategorisation: PropTypes.func,
    getCategories: PropTypes.func,
  }),
  isRedirectToHome: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
