import React, { Component } from 'react';
import '../../../styles/elements/fallback-message.scss';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default class FallbackMessage extends Component {
  render() {
    const { message, text } = this.props;

    return (
      <div className="fallback-message__content">
        <div className="fallback-message__content__icon" />

        <div className="fallback-message__content__text">
          {text || <FormattedMessage {...message} />}
        </div>
      </div>
    );
  }
}

FallbackMessage.defaultProps = {
  message: {},
  text: '',
};

FallbackMessage.propTypes = {
  message: PropTypes.shape({}),
  text: PropTypes.string,
};
