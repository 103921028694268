import styled from '@emotion/styled';

import checkBoxUncheckedIcon from 'assets/images/check_2.png';
import checkBoxCheckedIcon from 'assets/images/ic_check_login.png';

export const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  appearance: none;
  opacity: ${({ theme }) => theme.opacity.opaque};
  &:active {
    box-shadow: ${({ theme }) => theme.shadows.checkbox};
  }
  transition: 0.2s;
  cursor: pointer;
`;

export const Check = styled.div<{ isChecked: boolean }>`
  width: 32px;
  height: 32px;
  position: relative;
  margin-right: 15px;
  border-radius: 16px;
  background: ${({ isChecked, theme }) =>
      isChecked
        ? `${theme.colors.niagaraLight} url(${checkBoxCheckedIcon})`
        : `transparent url(${checkBoxUncheckedIcon})`}
    no-repeat center center;
  background-size: ${({ isChecked }) => (isChecked ? '16px 12px' : 'unset')};
  transition: 0.2s;
`;

export const CheckboxLabel = styled.label`
  margin: 0;
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  cursor: pointer;
`;
