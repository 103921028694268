import styled from '@emotion/styled';

export const PerformanceChartWrapper = styled.div`
  cursor: pointer;
  .apexcharts-tooltip {
    padding: 2px 16px 2px 16px;
    border-radius: 40px;
    font-size: ${({ theme }) => theme.fontSizes['5sm']};
    box-shadow: ${({ theme }) => theme.shadows.primary};
    border: 0 !important;
    line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
    div:first-of-type {
      font-weight: ${({ theme }) => theme.fontWeights.semibold};
    }
    div:last-of-type {
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      color: ${({ theme }) => theme.colors.bluishPurpleTransparent};
    }
  }
`;
