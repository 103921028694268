import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import { selectChild } from 'store/dependentUser/selectors';

export const useCurrentBalance = () => {
  const { isRaizKids, isRaizSuper } = useStatementsPath();

  const user = useAppSelector(selectUser);
  const child = useAppSelector(selectChild);

  if (isRaizSuper) {
    return user.super_current_balance;
  }

  if (isRaizKids && child) {
    return child.current_balance;
  }

  return user.current_balance;
};
