import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import SuperHistoryBannerSubValue from './SuperHistoryBannerRows/components/SuperHistoryBannerSubValue';
import SuperHistoryBannerRows from './SuperHistoryBannerRows/SuperHistoryBannerRows';

export default function SuperHistoryBanner({ toggleInfoModal }) {
  const { t } = useTranslation();

  const { investmentsOverview, accountSummary } = useSelector((state) => ({
    investmentsOverview: state.history.investmentsOverview,
    accountSummary: state.history.accountSummary,
  }));

  const { user } = accountSummary || {};
  const { account_balance } = user || {};
  const { gain_loss } = investmentsOverview || {};

  return (
    <div className="page-content__banner history__banner">
      <div className="page-content__banner-title">
        {t('historyDetailsPage.superHistoryBanner.title')}
      </div>

      <SuperHistoryBannerSubValue
        value={account_balance || gain_loss}
        className="page-content__banner-value"
      />

      <SuperHistoryBannerRows toggleInfoModal={toggleInfoModal} />
    </div>
  );
}

SuperHistoryBanner.defaultProps = {
  user: {},
};

SuperHistoryBanner.propTypes = {
  user: PropTypes.shape({
    account_balance: PropTypes.number,
  }),
  toggleInfoModal: PropTypes.func.isRequired,
};
