// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import PropTypes from 'prop-types';
import { useRef, useMemo, useCallback } from 'react';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import { AU_APP, CURRENCY_SYMBOL } from 'constants/localeConfigs';

const CHART_ID = 'PORTOFOLIO_LEGEND';

const replaceAmount = (ratio, description) => {
  const amount = `${CURRENCY_SYMBOL}${ratio / 100} `;

  return description?.replace?.('{amount}', amount);
};

const useData = (activeEtf, portfolioType, portfolios) =>
  useMemo(() => {
    let datums = [];

    if (portfolios.length > 0) {
      const currentPortfolio = portfolios.find(
        (portfolio) =>
          portfolio.identifier_key.replace(' ', '_').toLowerCase() ===
          portfolioType,
      );

      const mapper = (etf, index) => {
        const percent = parseFloat(etf.percent * 100).toFixed(1);

        const symbol = AU_APP ? `${etf.symbol} - ` : '';

        return {
          index,
          active: activeEtf === etf.symbol,
          percent,
          symbol,
          id: etf.symbol,
          label: etf.label,
          value: etf.percent,
        };
      };

      datums = currentPortfolio.etfs.map(mapper);
    }

    return datums;
  }, [activeEtf, portfolioType, portfolios]);

const useOptions = (data, isRegistration) =>
  useMemo(
    () => ({
      labels: data.map((datum) => datum.index),
      legend: {
        show: false,
      },
      fill: { colors: ['transparent'] },
      theme: {
        monochrome: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        id: CHART_ID,
      },
      dataLabels: {
        enabled: !isRegistration,
      },
    }),
    [data, isRegistration],
  );

const useSeries = (data) =>
  useMemo(() => data.map((datum) => datum.value), [data]);

export const BottomChart = (props) => {
  const {
    activeEtf,
    etfs,
    isDashboard,
    isFuture,
    isShowRecommendedPortfolio,
    portfolioETF,
    portfolios,
    portfolioType,
    ratio,
    onChangeEtf,
    isRegistration,
  } = props;

  const ref = useRef();

  const { t } = useTranslation();

  const toggleDataPointSelection = (index) => {
    ApexCharts.exec(CHART_ID, 'toggleDataPointSelection', [index]);
  };

  const data = useData(activeEtf, portfolioType, portfolios);

  const options = useOptions(data, isRegistration);

  const series = useSeries(data);

  const isDescriptionVisible = useMemo(
    () =>
      !isDashboard &&
      !isFuture &&
      etfs &&
      etfs.length !== 0 &&
      portfolioType &&
      !isShowRecommendedPortfolio,
    [etfs, isDashboard, isFuture, isShowRecommendedPortfolio, portfolioType],
  );

  const handleMouseOut = useCallback(() => {
    if (ref?.current) {
      const chart = ref?.current?.chart;

      const legendInactiveClass = chart.series?.legendInactiveClass;

      const dataLabels = chart.el.querySelectorAll?.(
        '[class*="apexcharts-pie-series"]',
      );

      dataLabels?.forEach((el) => {
        el.classList.remove(legendInactiveClass);
      });

      chart.el.querySelectorAll?.('g[seriesName]')?.forEach((el) => {
        el.classList.remove(legendInactiveClass);
      });
    }
  }, []);

  const handleMouseOver = useCallback(
    (index) => () => {
      if (ref?.current) {
        const seriesName = `${index}`;

        const chart = ref?.current?.chart;

        const legendInactiveClass = chart.series?.legendInactiveClass;

        const dataLabels = chart.el.querySelectorAll?.(
          '[class*="apexcharts-pie-series"]',
        );

        dataLabels?.forEach((el) => {
          el.classList.add(legendInactiveClass);
        });

        chart.el.querySelectorAll?.('g[seriesName]')?.forEach((el, elIndex) => {
          const seriesNameAtrribute = el.getAttribute('seriesName');

          if (seriesName === seriesNameAtrribute) {
            el.classList.remove(legendInactiveClass);

            dataLabels[elIndex].classList.remove(legendInactiveClass);
          } else {
            el.classList.add(legendInactiveClass);
          }
        });
      }
    },
    [],
  );

  const noOp = () => null;

  const feature_holdings = etfs?.[portfolioType]?.[activeEtf]?.feature_holdings;

  const description = etfs?.[portfolioType]?.[activeEtf]?.description;

  const ratios = ratio || portfolioETF?.aULargeCap?.ratios?.[portfolioType];

  return (
    <div className="legend-pie">
      <div className="legends">
        {data.map((datum) => (
          <button
            className={datum.active ? 'active' : 'in-active'}
            type="button"
            key={datum.index}
            onClick={() => {
              toggleDataPointSelection(datum.index);

              onChangeEtf(datum.id);
            }}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver(datum.index)}
            onFocus={noOp}
            onBlur={noOp}
          >
            <div>
              <span className="title">
                {datum.symbol} {datum.label}
              </span>

              <span className="value">{datum.percent}%</span>
            </div>
          </button>
        ))}
      </div>

      <div className="bottom">
        <div className="left">
          <div className="chart-size">
            <Chart
              ref={ref}
              width="100%"
              height="auto"
              series={series}
              options={options}
              type="pie"
            />
          </div>
        </div>

        <div className="right">
          {isDescriptionVisible && (
            <div className="description">
              {(feature_holdings?.length ?? 0) > 0 && (
                <div>
                  {t('changePortfolioPage.featuredHoldings')}

                  <ul>
                    {feature_holdings?.map?.((holding, index) => (
                      <li key={`${index}-${holding}`}>{holding}</li> // eslint-disable-line
                    ))}
                  </ul>
                </div>
              )}

              <div>{replaceAmount(ratios, description)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BottomChart.defaultProps = {
  ratio: null,
};

BottomChart.propTypes = {
  activeEtf: PropTypes.string.isRequired,
  etfs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDashboard: PropTypes.bool.isRequired,
  isFuture: PropTypes.bool.isRequired,
  isShowRecommendedPortfolio: PropTypes.bool.isRequired,
  portfolioETF: PropTypes.shape({
    aULargeCap: PropTypes.shape({
      ratios: PropTypes.shape({}),
    }),
  }).isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  portfolioType: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  onChangeEtf: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool.isRequired,
};
