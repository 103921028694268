import dayjs from 'dayjs';

import { Wrapper, Bottom, Left, Amount, Title, Text } from './styles';
import { TransactionStatus } from './TransactionStatus';
import { IHistoryItemProps } from './type';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';
import { DATE_FORMAT } from 'config/date';

export const HistoryItem = ({ transaction }: IHistoryItemProps) => (
  <Wrapper>
    <Left>
      <Title>{transaction.name}</Title>

      <Bottom>
        <Text>{dayjs(transaction.created_at).format(DATE_FORMAT)}</Text>

        {transaction.status ? (
          <TransactionStatus status={transaction.status} />
        ) : null}
      </Bottom>
    </Left>

    {transaction.amount ? (
      <Amount
        displayType="text"
        thousandSeparator={THOUSAND_SEPARATOR}
        decimalSeparator={DECIMAL_SEPARATOR}
        decimalScale={0}
        prefix={CURRENCY_SYMBOL}
        value={transaction.amount}
      />
    ) : null}
  </Wrapper>
);
