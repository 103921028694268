import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { VIEW_MODE } from '../consts';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectChild } from 'store/dependentUser/selectors';
import { PATHS } from 'constants/paths';

export const useRedirectToPreviewMode = () => {
  const navigate = useNavigate();
  const { recurring } = useAppSelector(selectChild);

  const { viewMode, childStep, childId } = useParams();

  useEffect(() => {
    if (!viewMode && recurring?.amount && childStep && childId) {
      navigate(
        generatePath(PATHS.kids.edit, {
          childStep,
          childId,
          viewMode: VIEW_MODE.preview,
        }),
        { replace: true },
      );
    }
  }, [navigate, viewMode, recurring, childStep, childId]);
};
