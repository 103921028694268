import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import Info from './Info';
import Address from './Address';
import AccountQuestions from './AccountQuestions/AccountQuestions';
import TaxResident from './TaxResident';
import ProgressBar from './ProgressBar';

import { PERSONAL_REGISTRATION } from 'constants/comonConstants';
import { MY_APP } from 'constants/localeConfigs';

function Personal({
  stepName,
  errorMessage,
  show,
  toggleShow,
  viewport,
  mapOnSelected,
  addressOnChange,
  addressMapBox,
  goBack,
  handleChangeInfo,
  firstName,
  lastName,
  phoneNumber,
  activeFieldInfo,
  handleSubmitInfo,
  errorMessagePhone,
  addressData,
  handleSubmitAddress,
  showLoader,
  isShowResidentInfoModal,
  handleSubmitAccountQuestion,
  user,
  userFormData,
  onChangeGender,
  showResidentInfoModal,
  handleSubmitTaxResident,
  taxResidentData,
  errorMessageMyKad,
  countryList,
  dob,
  handleChangeDate,
  dobError,
  togglePertanyaanInfo,
  showPertanyaanInfo,
  toggleKTPInfo,
  showKTPInfo,
  isCalendarActive,
}) {
  const { t } = useTranslation();

  let question = '';
  let stepClass = 'step-one';
  let contentFillTitle = '';

  switch (stepName) {
    case 'info':
      stepClass = 'step-account form-info';

      contentFillTitle = t('registrationPage.personal.step.info');

      question = (
        <Info
          togglePertanyaanInfo={togglePertanyaanInfo}
          showPertanyaanInfo={showPertanyaanInfo}
          onChangeGender={onChangeGender}
          userFormData={userFormData}
          handleSubmitInfo={handleSubmitInfo}
          showLoader={showLoader}
          errorMessage={errorMessage}
          goBack={goBack}
          handleChangeInfo={handleChangeInfo}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          activeFieldInfo={activeFieldInfo}
          errorMessagePhone={errorMessagePhone}
          errorMessageMyKad={errorMessageMyKad}
          user={user}
          dob={dob}
          handleChangeDate={handleChangeDate}
          dobError={dobError}
          isCalendarActive={isCalendarActive}
        />
      );

      break;
    case 'address':
      stepClass = 'step-account form-address';

      contentFillTitle = t('registrationPage.personal.step.address');

      question = (
        <Address
          toggleKTPInfo={toggleKTPInfo}
          showKTPInfo={showKTPInfo}
          isShowResidentInfoModal={isShowResidentInfoModal}
          showResidentInfoModal={showResidentInfoModal}
          handleSubmit={handleSubmitAddress}
          showLoader={showLoader}
          errorMessage={errorMessage}
          show={show}
          toggleShow={toggleShow}
          viewport={viewport}
          mapOnSelected={mapOnSelected}
          addressOnChange={addressOnChange}
          addressMapBox={addressMapBox}
          goBack={goBack}
          addressData={addressData}
          user={user}
        />
      );

      break;
    case 'account-questions':
      stepClass = 'step-account form-question -account-questions';

      contentFillTitle = t('registrationPage.personal.step.raizApplication');

      question = (
        <AccountQuestions
          handleSubmit={handleSubmitAccountQuestion}
          showLoader={showLoader}
          errorMessage={errorMessage}
          goBack={goBack}
          user={user}
        />
      );

      break;
    case 'tax-resident':
      stepClass = 'step-account form-question';

      contentFillTitle = '';

      question = (
        <TaxResident
          countryList={countryList}
          handleSubmit={handleSubmitTaxResident}
          showLoader={showLoader}
          taxResidentData={taxResidentData}
          errorMessage={errorMessage}
          user={user}
          goBack={goBack}
        />
      );

      break;
    default:
      stepClass = 'step-account form-info';

      contentFillTitle = t('registrationPage.personal.step.info');
  }

  return (
    <div className="registration-page">
      <div className={stepClass}>
        <div className="left-part">
          <div className="part-content">
            <div className="logo" />

            <div className="title" />

            <div className={`${MY_APP ? '-my-illustration' : ''} body-text`} />

            <div className="bottom-text">
              <Trans t={t} i18nKey="registrationPage.leftBottom.investInfo" />
            </div>
          </div>
        </div>

        <div className="right-part">
          <ProgressBar stepName={stepName} activeStep={PERSONAL_REGISTRATION} />

          <div className="bottom-part">
            <div className="content-fill">
              <h1>{contentFillTitle}</h1>
            </div>

            {question}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;

Personal.propTypes = {
  stepName: PropTypes.string.isRequired,
  taxResidentData: PropTypes.shape({}).isRequired,
  userFormData: PropTypes.shape({}).isRequired,
  errorMessage: PropTypes.string,
  toggleShow: PropTypes.func,
  show: PropTypes.bool,
  showLoader: PropTypes.bool,
  isShowResidentInfoModal: PropTypes.bool,
  viewport: PropTypes.shape({}),
  mapOnSelected: PropTypes.func,
  addressOnChange: PropTypes.func,
  addressMapBox: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  handleChangeInfo: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  activeFieldInfo: PropTypes.string.isRequired,
  handleSubmitInfo: PropTypes.func.isRequired,
  onChangeGender: PropTypes.func.isRequired,
  showResidentInfoModal: PropTypes.func.isRequired,
  errorMessagePhone: PropTypes.string.isRequired,
  errorMessageMyKad: PropTypes.string.isRequired,
  handleSubmitTaxResident: PropTypes.func.isRequired,
  handleSubmitAddress: PropTypes.func.isRequired,
  addressData: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmitAccountQuestion: PropTypes.func.isRequired,
  dob: PropTypes.instanceOf(Date).isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  dobError: PropTypes.bool.isRequired,
  togglePertanyaanInfo: PropTypes.func.isRequired,
  toggleKTPInfo: PropTypes.func.isRequired,
  showKTPInfo: PropTypes.bool,
  showPertanyaanInfo: PropTypes.bool,
  isCalendarActive: PropTypes.bool.isRequired,
};

Personal.defaultProps = {
  errorMessage: '',
  toggleShow: {},
  showLoader: false,
  showKTPInfo: false,
  showPertanyaanInfo: false,
  isShowResidentInfoModal: false,
  show: null,
  viewport: {},
  mapOnSelected: {},
  addressOnChange: {},
  addressMapBox: '',
};
