import { React } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// TODO: this is temp solution only -> should be migrated to usage of router hooks inside component instead
const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        history={{
          push: (pathname, routeState = {}) => {
            navigate(pathname, { state: routeState });
          },
          location,
        }}
        {...props}
      />
    );
  };

  return Wrapper;
};

export default withRouter;
