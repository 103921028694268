import { defineMessages } from 'react-intl';

import { RECURRING_WIDGET as idPrefix } from '../../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.recurringInvestments`]: 'Pelaburan Auto-labur',
  [`${idPrefix}.linkFunding`]:
    'Pertama, Hubungkan Akaun Pembiayaan anda dan kemudian tetapkan pelaburan ' +
    'berkala harian, mingguan, atau bulanan.',
  [`${idPrefix}.linkFundingAccount`]: 'Set Akaun Pembiayaan',
});

export default messages;
