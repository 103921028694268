import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import Time from '../../../components/elements/Time/Time';

import HistoryTransactionItemBody from './HistoryTransactionItemBody';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function HistoryTransactionItem({
  transaction,
  showTransactionDetail,
  displayCancelTransactionConfirmationAlert,
  transactionDetails,
  isSuper,
  isLastTransaction,
  checkIsLastTransactionInView,
  isShowPendingTitle,
}) {
  const {
    id,
    received_at,
    pending,
    type,
    amount,
    is_perfect_rebalance,
    localized_investment_type,
  } = transaction;

  const { t } = useTranslation();
  const [hisoryIcon, setHisoryIcon] = useState('history__item-icon');
  const [isShowRetryPayment] = useState(false);

  const historyPreviusPending = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.historyPreviusPending,
  );

  const transactionDate = dayjs(received_at).format('DD-M-YYYY');

  const previusDate = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.historyPreviusData,
  );

  const isShowDate = !(previusDate && previusDate === transactionDate);
  const isActive = transactionDetails?.id === id;

  const isRebalance =
    is_perfect_rebalance ||
    type?.match(/rebalance/i) ||
    type?.match(/Pengimbangan/i) ||
    localized_investment_type?.match(/rebalance/i) ||
    localized_investment_type?.match(/Pengimbangan/i);

  const noBorder = isShowDate && !isShowPendingTitle ? 'no-border' : '';

  const isItemPending =
    isSuper && !pending && !isRebalance ? 'item-pending' : '';

  useEffect(() => {
    setHisoryIcon(() => {
      if (amount < 0) {
        return 'history__item-icon up';
      }

      if (isSuper && !pending && !isRebalance) {
        return 'history__item-icon point';
      }

      return 'history__item-icon';
    });
  }, [amount, isSuper, pending, isRebalance]);

  if (!id) {
    return null;
  }

  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.historyPreviusData,
    transactionDate,
  );

  if (isShowPendingTitle) {
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyPreviusPending,
      isShowPendingTitle,
    );

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.historyPreviusData);
  } else {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.historyPreviusPending);
  }

  return (
    <div className={isActive ? 'active' : ''} key={id}>
      {!isLastTransaction && <InView onChange={checkIsLastTransactionInView} />}

      {isShowDate && !isShowPendingTitle && (
        <div className="history__item-date">
          <Time>{received_at}</Time>
        </div>
      )}

      {isShowPendingTitle && historyPreviusPending !== 'true' && (
        <div className="history__item-date">{t('common.status.pending')}</div>
      )}

      <HistoryTransactionItemBody
        transaction={transaction}
        showTransactionDetail={showTransactionDetail}
        displayCancelTransactionConfirmationAlert={
          displayCancelTransactionConfirmationAlert
        }
        isSuper={isSuper}
        isRebalance={isRebalance}
        noBorder={noBorder}
        isItemPending={isItemPending}
        hisoryIcon={hisoryIcon}
        isShowRetryPayment={isShowRetryPayment}
      />
    </div>
  );
}

HistoryTransactionItem.defaultProps = {
  isSuper: false,
};

HistoryTransactionItem.propTypes = {
  showTransactionDetail: PropTypes.func.isRequired,
  displayCancelTransactionConfirmationAlert: PropTypes.func.isRequired,
  isSuper: PropTypes.bool,
  transactionDetails: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    received_at: PropTypes.string,
    pending: PropTypes.bool,
    grouped_status: PropTypes.string,
    type: PropTypes.string,
    is_perfect_rebalance: PropTypes.bool,
    localized_investment_type: PropTypes.string,
    campaign_owner_img_url: PropTypes.string,
    reversible: PropTypes.bool,
  }).isRequired,
  checkIsLastTransactionInView: PropTypes.func.isRequired,
  isLastTransaction: PropTypes.bool.isRequired,
  isShowPendingTitle: PropTypes.bool.isRequired,
};
