import { IMultiplierButtons } from './types';
import {
  AvailableMultiplierButton,
  AvailableMultiplierWrapper,
} from './styles';
import { DISABLED_MULTIPLIER_VALUE } from './consts';

import { formatNumber } from 'utils/formatters/formatNumber';

export default function MultiplierButtons({
  roundUpsSettings,
  handleChangeMultiplier,
}: IMultiplierButtons) {
  const { available_amount_multipliers, amount_multiplier } = roundUpsSettings;

  return (
    <AvailableMultiplierWrapper
      isActive={amount_multiplier !== DISABLED_MULTIPLIER_VALUE}
    >
      {available_amount_multipliers?.map((multiplier) => {
        if (multiplier === DISABLED_MULTIPLIER_VALUE) {
          return null;
        }

        return (
          <AvailableMultiplierButton
            type="button"
            isActive={amount_multiplier === multiplier}
            label={formatNumber({ value: multiplier })}
            onClick={() => handleChangeMultiplier(multiplier)}
            key={`multiplier__${multiplier}`}
            variant="initial"
          />
        );
      })}
    </AvailableMultiplierWrapper>
  );
}
