import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { FormWrapper, Select, SortingTitle, SortingWrapper } from './styles';
import { useFundsSorting } from './useFundsSorting';
import {
  FUNDS_TAGS_SORTING_OPTIONS,
  DEFAULT_FUNDS_TAGS_SORTING_ORDER,
} from './consts';

export default function Sorting() {
  const { t } = useTranslation();
  const { handleSortFunds } = useFundsSorting();

  const sortingOptions = Object.entries(FUNDS_TAGS_SORTING_OPTIONS).map(
    ([key, value]) => ({
      label: t(`portfolioCustomization.sorting.labels.${key}`),
      value,
    }),
  );

  return (
    <SortingWrapper>
      <SortingTitle>{t('portfolioCustomization.sorting.title')}</SortingTitle>

      <Form
        onSubmit={handleSortFunds}
        render={() => (
          <FormWrapper>
            <Select
              name={t('portfolioCustomization.sorting.title')}
              defaultValue={DEFAULT_FUNDS_TAGS_SORTING_ORDER}
              options={sortingOptions}
              onChange={(value) => handleSortFunds(value)}
            />
          </FormWrapper>
        )}
      />
    </SortingWrapper>
  );
}
