import React from 'react';
import PropTypes from 'prop-types';

import '../../../../../styles/pages/invest.scss';
import '../../../../../styles/layout/statements.scss';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function SuperHistoryBannerSubValue({ value, className }) {
  return (
    <div className={className}>
      {formatNumber({
        value: Number(value),
      })}
    </div>
  );
}

SuperHistoryBannerSubValue.defaultProps = {
  className: 'page-content__banner-value-sub',
  value: 0,
};

SuperHistoryBannerSubValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
