import { isEqual } from 'lodash';

import { DIVIDENDS_BASE_URL } from './consts';
import {
  IDividendsFundsResponse,
  IDividendsParams,
  IDividendsSummaryResponse,
  IGetDividendsFunds,
  IReinvestedDividendsResponse,
  IUpcomingDividendsResponse,
} from './types';

import { api } from 'store/api';

export const dividendsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDividendsFunds: build.query<
      IDividendsFundsResponse[],
      IGetDividendsFunds
    >({
      query: ({ headers }) => ({
        url: `${DIVIDENDS_BASE_URL}/funds`,
        method: 'GET',
        headers,
      }),
    }),
    getDividendsSummary: build.query<
      IDividendsSummaryResponse,
      IDividendsParams
    >({
      query: ({ headers, ...params }) => ({
        url: `${DIVIDENDS_BASE_URL}/summary`,
        method: 'GET',
        params,
        headers,
      }),
    }),
    getReinvestedDividends: build.query<
      IReinvestedDividendsResponse,
      IDividendsParams
    >({
      query: ({ headers, ...params }) => ({
        url: `${DIVIDENDS_BASE_URL}/reinvested`,
        method: 'GET',
        params,
        headers,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg?.offset && arg.offset > 1) {
          return {
            ...responseData,
            dividends: [
              ...currentCacheData.dividends,
              ...responseData.dividends,
            ],
          };
        }

        return responseData;
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.offset !== previousArg?.offset ||
        !isEqual(currentArg, previousArg),
    }),
    getUpcomingDividends: build.query<
      IUpcomingDividendsResponse[],
      IDividendsParams
    >({
      query: ({ headers, ...params }) => ({
        url: `${DIVIDENDS_BASE_URL}/upcoming`,
        method: 'GET',
        params,
        headers,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDividendsFundsQuery,
  useGetDividendsSummaryQuery,
  useGetReinvestedDividendsQuery,
  useGetUpcomingDividendsQuery,
  endpoints: {
    getReinvestedDividends: { useQueryState: useReinvestedDividendsQueryState },
    getUpcomingDividends: { useQueryState: useUpcomingDividendsQueryState },
  },
} = dividendsApi;
