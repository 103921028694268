import * as yup from 'yup';
import { t } from 'i18next';
import dayjs from 'dayjs';

import { CHECKBOX_VALUE, FORM_FIELDS, SOURCE_TYPE_FIELD_NAME } from '../consts';

import { DATE_FORMAT_DAY_MONTH_YEAR_WITH_SLASH } from 'config/date';
import { ISourceField, ISources } from 'store/identityVerification/types';

interface IGenerateSchemaProps {
  sourceFields?: ISourceField[];
  isSourcesTypeVisible: boolean;
}

export const generateSchema = ({
  sourceFields,
  isSourcesTypeVisible,
}: IGenerateSchemaProps) => {
  const sourceDefaultSchema = {
    [SOURCE_TYPE_FIELD_NAME]: yup.array(
      yup.object({
        value: yup.string().required(),
      }),
    ),
  };

  if (!sourceFields?.length) {
    return yup.object(sourceDefaultSchema);
  }

  const defaultSchema = isSourcesTypeVisible ? sourceDefaultSchema : {};

  const validationSchema = sourceFields?.reduce((objectSchema, field) => {
    let { yupSchema } =
      FORM_FIELDS?.[field.type as keyof typeof FORM_FIELDS] ?? yup.string();

    if (field.type === 'checkbox') {
      yupSchema = yup
        .boolean()
        .required(t('fields.validation.fieldIsRequired'))
        .oneOf([true], t('fields.validation.fieldIsRequired'));
    } else if (field.required) {
      yupSchema = yupSchema.required(
        t('fields.validation.requiredField', { fieldName: field.label }),
      );
    }

    if (field?.validation) {
      const { min, max, regex } = field.validation;

      yupSchema = field?.required
        ? yup
            .string()
            .required(
              t('fields.validation.requiredField', { fieldName: field.label }),
            )
        : yup.string();

      if (min && max && min === max) {
        yupSchema = yupSchema.length(
          min,
          t('fields.validation.shouldContainCharacters', { number: min }),
        );
      }

      if (min) {
        yupSchema = yupSchema.min(
          min,
          t('fields.validation.minCharacters', { number: min }),
        );
      }

      if (max) {
        yupSchema = yupSchema.max(
          max,
          t('fields.validation.maxCharacters', { number: max }),
        );
      }

      if (regex) {
        yupSchema = yupSchema.matches(
          regex,
          t('fields.validation.invalidFields', { name: field.label }),
        );
      }
    }

    return {
      ...objectSchema,
      [field.name]: yupSchema,
    };
  }, defaultSchema);

  return yup.object(validationSchema);
};

export const generateDefaultValues = ({
  sourceItem,
  sourceId,
  isSourcesTypeVisible,
}: {
  sourceItem?: ISources;
  sourceId: string;
  isSourcesTypeVisible: boolean;
}) => {
  const { defaultValue: sourceDefaultValue } = FORM_FIELDS.select;

  if (!sourceId && sourceItem?.source_id && isSourcesTypeVisible) {
    return {
      [SOURCE_TYPE_FIELD_NAME]: [
        { value: sourceItem?.source_id, label: sourceDefaultValue?.[0]?.label },
      ],
    };
  }

  const defaultValues = sourceItem?.fields?.reduce((values, field) => {
    const { defaultValue } =
      FORM_FIELDS?.[field.type as keyof typeof FORM_FIELDS] ?? undefined;

    const isCheckbox = field.type === 'checkbox';

    const isCheckboxDefaultValue = !(
      isCheckbox && field?.default_value === CHECKBOX_VALUE.off
    );

    const autocompleteValue = isCheckbox
      ? isCheckboxDefaultValue
      : field?.default_value;

    if (
      field.type === 'select' ||
      (isCheckbox && !isCheckboxDefaultValue) ||
      (!autocompleteValue && !defaultValue)
    ) {
      return values;
    }

    if (field.type === 'date' && field?.default_value) {
      const dateValue = dayjs(
        field?.default_value,
        DATE_FORMAT_DAY_MONTH_YEAR_WITH_SLASH,
      ).toDate();

      return {
        ...values,
        [field.name]: dateValue,
      };
    }

    return {
      ...values,
      [field.name]: autocompleteValue || defaultValue,
    };
  }, {});

  return {
    ...defaultValues,
    ...(isSourcesTypeVisible && {
      [SOURCE_TYPE_FIELD_NAME]: [
        { value: sourceItem?.source_id, label: sourceItem?.title },
      ],
    }),
  };
};
