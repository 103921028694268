import { useTranslation, Trans } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BackButton, BackButtonWrapper, Info, SuccessText } from './styles';

interface ISuccessInfoProps {
  message: string;
}

export const SuccessInfo = ({ message }: ISuccessInfoProps) => {
  const { t } = useTranslation();
  const email = useWatch({ name: 'email' });
  const navigate = useNavigate();

  return (
    <div>
      <SuccessText>{t('recoverPasswordPage.success')}</SuccessText>

      {message === '' ? (
        <div>
          <Info>
            <Trans
              i18nKey="recoverPasswordPage.sentTo"
              values={{
                email,
              }}
              components={{
                strong: <strong />,
                br: <br />,
              }}
            />
          </Info>

          <Info>{t('recoverPasswordPage.checkSpam')}</Info>
        </div>
      ) : (
        <div>
          <Info>{message}</Info>

          <BackButtonWrapper>
            <BackButton
              label={t('common.back')}
              onClick={() => navigate('/login')}
              variant="initial"
            />
          </BackButtonWrapper>
        </div>
      )}
    </div>
  );
};
