import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MIGRATION, MIGRATION_URL_PARAMS } from 'constants/migrationConstants';

export const useSetMigrationToken = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get(MIGRATION_URL_PARAMS.token);

  const source = searchParams.get(MIGRATION_URL_PARAMS.source);

  useEffect(() => {
    if (typeof token === 'string') {
      sessionStorage.setItem(MIGRATION.token, token);
    }
  }, [token]);

  useEffect(() => {
    if (source) {
      sessionStorage.setItem(MIGRATION.source, source);
    }
  }, [source]);
};
