import { HOME_ACTIVE_TAB, HOME_TABS } from './comonConstants';
import { PATHS } from './paths';

const TARGET_TYPES = [
  {
    id: 'where_you_spend',
    target: '/where-spend',
  },
  {
    id: 'cashflow_forecast',
    target: '/potential-free-cash',
  },
  {
    id: 'offerDetail',
    target: '/rewards',
  },
  {
    id: 'little_acorns',
    target: '/raiz-kids',
  },
  {
    id: 'spending_accounts',
    target: '/settings/spending-account',
  },
  {
    id: 'funding_account',
    target: '/settings/funding-account',
  },
  {
    id: 'found_money',
    target: '/rewards',
  },
  {
    id: 'round_ups',
    target: '/round-ups',
  },
  {
    id: 'invest_withdraw',
    target: '',
  },
  {
    id: 'history',
    target: '/history-details',
  },
  {
    id: 'performance',
    target: '/perfomance-details',
  },
  {
    id: 'my_finance',
    target: '/my-finance',
  },
  {
    id: 'settings',
    target: '/settings',
  },
  {
    id: 'recurring_investment',
    target: '/recurring-investment',
  },
  {
    id: 'super_fund',
    target: PATHS.super.rollovers,
  },
  {
    id: 'portfolio',
    target: '/portfolio',
  },
  {
    id: 'categorise_transactions',
    target: '/transaction-categorisation',
  },
  {
    id: 'goals_management',
    target: '/recurring-investment',
  },
  {
    id: 'raiz_super',
    target: '/super',
  },
  {
    id: 'lump_sum_invest',
    target: '/invest?investment-show=true',
  },
  {
    id: 'invite',
    target: '?invite-friend=true',
  },
  {
    id: 'future',
    target: `/?${HOME_ACTIVE_TAB}=${HOME_TABS.future}`,
  },
  {
    id: 'blog',
    target: '',
  },
];

export default TARGET_TYPES;
