import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import '../../../styles/pages/recurring-investment.scss';
import Switch from 'react-switch';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';
import {
  ANNIVERSARY,
  BIRTHDAY,
  CAR,
  DEPOSIT,
  HOLIDAY,
  HOUSE,
  RENT,
  WEDDING,
} from '../../../constants/comonConstants';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsKidsInvestmentGoalEnabled } from 'store/firebaseConfig/selectors';

function EmptyRecurring(props) {
  const {
    isRecurringEmpty,
    changePlan,
    hasGoals,
    invAmount,
    changeInvAmount,
    toggleChangeFrequencyState,
    handleChangeSavingGoal,
    savingGoalCondition,
    currentFrequency,
    toggleChangeGoalState,
    changeTargetAmount,
    targetAmount,
    currentGoalName,
    editGoal,
    isSuper,
    isDependentUser,
  } = props;

  const { t } = useTranslation();

  const goalNames = [
    CAR,
    HOLIDAY,
    BIRTHDAY,
    WEDDING,
    ANNIVERSARY,
    HOUSE,
    RENT,
    DEPOSIT,
  ];

  const currentGoalNameFilter = goalNames.filter(
    (goal) => goal.id === currentGoalName,
  );

  const currentGoalNameVisible = currentGoalNameFilter.length
    ? currentGoalNameFilter[0].name
    : currentGoalName;

  const isKidsInvestmentGoalEnabled = useAppSelector(
    selectIsKidsInvestmentGoalEnabled,
  );

  const isKidsInvestmentGoalVisible =
    (isDependentUser && isKidsInvestmentGoalEnabled) || !isDependentUser;

  return (
    <div className="page-content__dashboard">
      {isKidsInvestmentGoalVisible && !isSuper && (
        <div
          className={`info-panel perfomance__info-panel
         ${
           (isRecurringEmpty || changePlan) && !savingGoalCondition
             ? '-with-padding'
             : ''
         }`}
        >
          <div className="saving-goal__title">
            {(isRecurringEmpty || changePlan) && !savingGoalCondition && (
              <span>
                <i className="recurring-investment__icon -flag saving-goal__icon" />

                {t('recurringInvestmentPage.emptyRecurring.title')}
              </span>
            )}

            <Switch
              checked={savingGoalCondition}
              onChange={handleChangeSavingGoal}
              onColor="#29ae87"
              offColor="#D2D2D2"
              onHandleColor="#ffffff"
              handleDiameter={26}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)"
              activeBoxShadow="0"
              height={18}
              width={45}
              disabled={editGoal}
              className="react-switch recurring-investment__switch"
              id="material-switch"
            />
          </div>

          {(isRecurringEmpty || changePlan) && !savingGoalCondition && (
            <div className="saving-goal__info">
              {t('recurringInvestmentPage.emptyRecurring.decription')}
            </div>
          )}

          {((!isRecurringEmpty && hasGoals) || savingGoalCondition) && (
            <div>
              <div className="recurring-investment__title">
                <i className="recurring-investment__icon -flag" />

                {t('recurringInvestmentPage.emptyRecurring.goalAmount')}

                <div className="recurring-investment__value">
                  <NumberFormat
                    value={targetAmount}
                    displayType="input"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    placeholder={`${CURRENCY_SYMBOL}0`}
                    prefix={CURRENCY_SYMBOL}
                    onValueChange={(values) => {
                      changeTargetAmount(values.floatValue);
                    }}
                  />
                </div>
              </div>

              <div
                className="recurring-investment__footer"
                onClick={() => toggleChangeGoalState(true)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                <span className="recurring-investment__footer-label">
                  <i className="recurring-investment__icon -goal" />

                  {t('recurringInvestmentPage.emptyRecurring.chooseGoal')}
                </span>

                <span className="recurring-investment__footer-value">
                  {currentGoalNameVisible}

                  <i className="recurring-investment__icon -arrow" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className={`info-panel perfomance__info-panel recurring-investment__info-panel ${
          isSuper || (isDependentUser && !isKidsInvestmentGoalEnabled)
            ? '-super-panel'
            : ''
        }`}
      >
        <div className="recurring-investment__title">
          {t('recurringInvestmentPage.emptyRecurring.recurringInvAmount')}

          <div className="recurring-investment__value">
            <NumberFormat
              value={invAmount}
              displayType="input"
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              placeholder={`${CURRENCY_SYMBOL}0`}
              prefix={CURRENCY_SYMBOL}
              allowNegative={false}
              onValueChange={(values) => {
                changeInvAmount(values.floatValue);
              }}
            />
          </div>
        </div>

        <div
          className="recurring-investment__footer"
          onClick={toggleChangeFrequencyState}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          <span className="recurring-investment__footer-label">
            <i className="recurring-investment__icon -frequency" />

            {t('recurringInvestmentPage.emptyRecurring.frequency.title')}
          </span>

          <span className="recurring-investment__footer-value">
            {currentFrequency
              ? t(`common.frequencies.${currentFrequency}`)
              : t('common.select')}

            <i className="recurring-investment__icon -arrow" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default EmptyRecurring;

EmptyRecurring.defaultProps = {
  currentGoal: {},
  invAmount: null,
  isDependentUser: false,
};

EmptyRecurring.propTypes = {
  isRecurringEmpty: PropTypes.bool.isRequired,
  changePlan: PropTypes.bool.isRequired,
  savingGoalCondition: PropTypes.bool.isRequired,
  hasGoals: PropTypes.bool.isRequired,
  isSuper: PropTypes.bool.isRequired,
  currentGoalName: PropTypes.string.isRequired,
  currentFrequency: PropTypes.string.isRequired,
  currentGoal: PropTypes.shape({}),
  invAmount: PropTypes.number,
  targetAmount: PropTypes.number.isRequired,
  changeInvAmount: PropTypes.func.isRequired,
  toggleChangeGoalState: PropTypes.func.isRequired,
  changeTargetAmount: PropTypes.func.isRequired,
  editGoal: PropTypes.bool.isRequired,
  handleChangeSavingGoal: PropTypes.func.isRequired,
  toggleChangeFrequencyState: PropTypes.func.isRequired,
  isDependentUser: PropTypes.bool,
};
