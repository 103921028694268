const shadows = {
  primary: '0 2px 4px rgba(0, 0, 0, 0.13)',
  light: '0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)',
  lightPanel: '0 2.3px 4.6px 0 rgba(0, 0, 0, 0.13)',
  medium: '0 4px 7px rgba(0, 0, 0, 0.12)',
  checkbox:
    'inset 0 2px 1px rgba(0, 0, 0, 0.1), 0 0 0 3px rgba(255, 255, 0, 0.5)',
  header: '0 3px 17px rgba(0, 0, 0, 0.15)',
  none: 'none',
} as const;

export default shadows;
