import styled from '@emotion/styled';

export const TagsWrapper = styled.div`
  margin: 36px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
`;

export const FundsTagItemWrapper = styled.div`
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.whiteGreyTransparent};
  background: ${({ isBaseFundTag, theme }) =>
    isBaseFundTag
      ? theme.colors.grayTransparent
      : theme.colors.whiteGreyTransparent};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  border: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.colors.white}` : 'none'};
`;

export const PercentageValue = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
