import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
  RaizKidsBannerHelpCardButton,
  RaizKidsBannerDescription,
  RaizKidsBannerTitle,
  RaizKidsBannerWrapper,
  RaizKidsBannerButton,
  RaizKidsBannerButtonIcon,
} from './styles';

import { IconButton } from 'components/elements/IconButton';

function RaizKidsBanner({ setIsHelpCardsModalOpen }) {
  const { t } = useTranslation();

  return (
    <RaizKidsBannerWrapper>
      <RaizKidsBannerHelpCardButton>
        <IconButton
          iconName="help"
          size={28}
          onClick={() => setIsHelpCardsModalOpen(true)}
        />
      </RaizKidsBannerHelpCardButton>

      <RaizKidsBannerTitle>{t('raizKidsPage.title')}</RaizKidsBannerTitle>

      <RaizKidsBannerDescription>
        <Trans t={t} i18nKey="raizKidsPage.kidsDescription" />
      </RaizKidsBannerDescription>

      <RaizKidsBannerButton to="/raiz-registration/personal/terms">
        <RaizKidsBannerButtonIcon />

        {t('raizKidsPage.addKid')}
      </RaizKidsBannerButton>
    </RaizKidsBannerWrapper>
  );
}

export default RaizKidsBanner;

RaizKidsBanner.propTypes = {
  setIsHelpCardsModalOpen: PropTypes.func.isRequired,
};
