import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import * as fundingAccountAction from '../../../store/fundingAccount/actions';
import Grid from '../../../components/elements/Grid/Grid';
import { MY_APP } from '../../../constants/localeConfigs';
import Loader from '../../../components/elements/Loader/Loader';

import { GroupedBanksRegistration } from './GroupedBankList/GroupedBanksRegistration';
import { FooterButtonsWrapper, SeeMoreBanksButton } from './styles';

import { selectIsRoundUpsAccountMfaEnabled } from 'store/firebaseConfig/selectors';

class Banks extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {
      actions,
      isRegistration,
      isSpending,
      banks,
      isWithdrawal,
      isInstitutionsApiEnabled,
    } = this.props;

    if (isRegistration) {
      if (MY_APP) {
        actions.getBanksList({
          isRegistration,
          isWithdrawal,
        });
      } else {
        actions.getBanksList({ isSpending });
      }
    } else if (Object.keys(banks).length === 0 || banks.length === 0) {
      actions.getBanksList({ isSpending });
    }

    if (MY_APP && isInstitutionsApiEnabled) {
      actions.getBankInstitutions();
    }

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { actions, isWithdrawal, isRegistration, isSpending } = this.props;

    if (newProps.isWithdrawal && !isWithdrawal && isRegistration) {
      if (MY_APP) {
        actions.getBanksList({ isRegistration: true });
      }
    }

    if (!newProps.isSpending && isSpending && isRegistration) {
      actions.getBanksList({ isSpending: newProps.isSpending });
    }
  }

  render() {
    const {
      banks,
      handleClick,
      openDifferentBank,
      isSpending,
      showMoreBanks,
      isRegistration,
      showMainLoader,
      isWithdrawal,
      checkMfa,
      showAddCardModal,
      showVisaAllBanks,
      isShowAllVisaBanks,
      visaEnabled,
      banksInstitutions,
      signInBank,
      roundUpsAccountMfaOn,
      t,
    } = this.props;

    const isGroupedBankListVisible = MY_APP && isSpending;

    return (
      <div>
        {!showMainLoader && (
          <div>
            {isGroupedBankListVisible && (
              <GroupedBanksRegistration
                onSelectDebitCard={handleClick}
                onSelectOnlineBanking={signInBank}
              />
            )}

            {(banks.institutions ||
              (MY_APP && banks.banks && !isGroupedBankListVisible)) && (
              <Grid
                items={MY_APP ? banks.banks : banks.institutions}
                handleClick={handleClick}
                isBank
                isRegistration={isRegistration}
                isWithdrawal={isWithdrawal}
                isSpending={isSpending}
                roundUpsAccountMfaOn={roundUpsAccountMfaOn}
                checkMfa={checkMfa}
                showAddCardModal={showAddCardModal}
                showVisaAllBanks={showVisaAllBanks}
                isShowAllVisaBanks={isShowAllVisaBanks}
                visaEnabled={visaEnabled}
                banksInstitutions={banksInstitutions}
                signInBank={signInBank}
              />
            )}

            <FooterButtonsWrapper>
              {!isRegistration && !MY_APP && (
                <SeeMoreBanksButton
                  label={t('settingsPage.seeMoreBanksButton')}
                  onClick={showMoreBanks}
                />
              )}

              {!isSpending && !isRegistration && !MY_APP && (
                <SeeMoreBanksButton
                  label={t('settingsPage.enterBankDetailsManuallyButton')}
                  onClick={openDifferentBank}
                />
              )}
            </FooterButtonsWrapper>
          </div>
        )}

        {showMainLoader && <Loader additionalClass="-absolute -top" />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  banks: state.fundingAccount.banks,
  errorMessage: state.user.errorMessage,
  user: state.user.user,
  banksInstitutions: state.fundingAccount.banksInstitutions,
  state,
  roundUpsAccountMfaOn: selectIsRoundUpsAccountMfaEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(fundingAccountAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Banks));

Banks.defaultProps = {
  banks: {},
  isSpending: false,
  showMoreBanks() {},
  openDifferentBank() {},
  isRegistration: false,
  isWithdrawal: false,
  showMainLoader: false,
  user: {},
  checkMfa() {},
  showVisaAllBanks() {},
  isShowAllVisaBanks: false,
  isInstitutionsApiEnabled: false,
  banksInstitutions: {},
  signInBank() {},
  roundUpsAccountMfaOn: false,
};

Banks.propTypes = {
  actions: PropTypes.shape({
    getBanksList: PropTypes.func,
    getBankInstitutions: PropTypes.func,
  }).isRequired,
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      institutions: PropTypes.arrayOf(PropTypes.shape({})),
      banks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  handleClick: PropTypes.func.isRequired,
  openDifferentBank: PropTypes.func,
  isSpending: PropTypes.bool,
  showMainLoader: PropTypes.bool,
  showMoreBanks: PropTypes.func,
  isRegistration: PropTypes.bool,
  isWithdrawal: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  checkMfa: PropTypes.func,
  showAddCardModal: PropTypes.func.isRequired,
  showVisaAllBanks: PropTypes.func,
  isShowAllVisaBanks: PropTypes.bool,
  visaEnabled: PropTypes.bool.isRequired,
  isInstitutionsApiEnabled: PropTypes.bool,
  banksInstitutions: PropTypes.shape({}),
  signInBank: PropTypes.func,
  roundUpsAccountMfaOn: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
