import styled from '@emotion/styled';

export const Footer = styled.div`
  color: ${({ theme }) => theme.colors.topaz};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  max-width: 100%;
`;

export const FormStyled = styled.form`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;
