import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';

import { InputErrorMessage, InputLabel, InputWrapper } from '../input/styles';

export const NumberFieldInput = styled(NumberFormat)`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  padding: 46px 0 14px;
  background: none;
  z-index: ${({ theme }) => theme.zIndexes.elevated};
  ::placeholder {
    opacity: ${({ theme }) => theme.opacity.extraTransparent};
  }
  border: 0;
`;

export const NumberFieldLabel = styled(InputLabel)`
  top: 0;
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
`;

export const CleanInputWrapper = styled(InputWrapper)`
  height: auto;
  border: 0;
  padding-bottom: 0;
  input {
    border: none;
    height: auto;
    padding: 0;
  }
`;

export const NumberFieldErrorMessage = styled(InputErrorMessage)`
  line-height: unset;
  height: 0;
`;
