import { usePortfolioCustomizationRoute } from '../../hooks';

import ChangeBasePortfolio from './ChangeBasePortfolio/ChangeBasePortfolio';
import FundDetails from './FundDetails/FundDetails';
import FundsList from './FundsList/FundsList';
import ResidentialPropertyFundsList from './ResidentialPropertyFundsList/ResidentialPropertyFundsList';

export default function PortfolioCustomizationContent() {
  const {
    isBitcoinFundPage,
    isResidentialPropertyFundsDetailsPage,
    isResidentialPropertyFundsListPage,
    isChangeBasePortfolioPage,
  } = usePortfolioCustomizationRoute();

  if (isBitcoinFundPage || isResidentialPropertyFundsDetailsPage) {
    return <FundDetails />;
  }

  if (isResidentialPropertyFundsListPage) {
    return <ResidentialPropertyFundsList />;
  }

  if (isChangeBasePortfolioPage) {
    return <ChangeBasePortfolio />;
  }

  return <FundsList />;
}
