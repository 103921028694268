import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/logout.scss';

export default function RolloverRemoveConfirmModal({
  isShowConfirmationModal,
  cancel,
  deleteFund,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirmationModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">
          {t('superPage.rolloverRemoveConfirmModal.please')}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            title={<Trans i18nKey="common.cancel" t={t} />}
            type="button"
            buttonClass="wait"
            handleClick={cancel}
          />

          <DeprecatedButton
            title={<Trans i18nKey="common.delete" t={t} />}
            type="button"
            buttonClass="skip"
            handleClick={deleteFund}
          />
        </div>
      </div>
    </Modal>
  );
}

RolloverRemoveConfirmModal.propTypes = {
  cancel: PropTypes.func.isRequired,
  deleteFund: PropTypes.func.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
};
