import { useTranslation } from 'react-i18next';

import {
  Divider,
  Wrapper,
  SettingsButton,
  Subtitle,
  Title,
  TitleWrapper,
} from './styles';

import { IconButton } from 'components/elements/IconButton';

interface IBankListHeader {
  openSettingsModal: () => void;
}

export const BankListHeader = ({ openSettingsModal }: IBankListHeader) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('settingsPage.bankListHeader.title')}</Title>

        <SettingsButton>
          <IconButton iconName="gear" size={18} onClick={openSettingsModal} />
        </SettingsButton>
      </TitleWrapper>

      <Divider />

      <Subtitle>{t('settingsPage.bankListHeader.subTitle')}</Subtitle>
    </Wrapper>
  );
};
