import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../../styles/layout/portfolio.scss';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';
import { PATHS } from 'constants/paths';
import { usePortfolioPath } from 'routes/hooks';
import { selectIsPortfoliosLoading } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function Portfolio({ isSuper }) {
  const { t } = useTranslation();
  const portfolioPath = usePortfolioPath();
  const isPortfoliosLoading = useAppSelector(selectIsPortfoliosLoading);
  const generatedPath = generatePath(portfolioPath);

  return (
    <div className="info-panel -with-padding portfolio__info-panel">
      <div className="info-panel__title">{t('portfolioWidget.title')}</div>

      <div className="info-panel__divider" />

      <div className="info-panel__dashboard portfolio__dashboard">
        <ChangePortfolio isDashboard />

        <DeprecatedButton
          withLoader={isPortfoliosLoading}
          isLink
          to={isSuper ? PATHS.oldPortfolio.super : generatedPath}
          buttonClass="button__basic-fill -white-inverse -link raiz-kids__dashboard-btn"
          title={t('portfolioWidget.button.changePortfolio')}
        />
      </div>
    </div>
  );
}

Portfolio.defaultProps = {
  isSuper: false,
};

Portfolio.propTypes = {
  isSuper: PropTypes.bool,
};
