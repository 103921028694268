import { COMPANY_LOGO } from './consts';
import { LogoWrapper } from './styles';

import getMigrationFlow from 'utils/getMigrationFlow';

const Logo = () => {
  const migrationFlow = getMigrationFlow();

  return <LogoWrapper image={COMPANY_LOGO[migrationFlow]} />;
};

export default Logo;
