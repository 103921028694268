import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

class FundingModal extends Component {
  render() {
    const { showFundingModal, toggleFundingModal, t } = this.props;

    return (
      <Modal
        isOpen={showFundingModal}
        onRequestClose={() => toggleFundingModal(false)}
        contentLabel="account-strength-modal"
        shouldCloseOnOverlayClick
        className="account-strength__modal"
      >
        <DeprecatedButton
          buttonClass="button__close"
          handleClick={() => toggleFundingModal(false)}
        />

        <div className="account-strength__modal-header">
          <i className="account-strength__modal-logo" />

          {t('fundingModal.title')}
        </div>

        <i className="account-strength__modal-banner -recurring" />

        <div className="account-strength__modal-info">
          {t('fundingModal.description')}
        </div>

        <DeprecatedButton
          isLink
          to="/settings/funding-account"
          buttonClass="button__basic-fill -white-inverse -link"
          title={t('fundingModal.button.linkFundingAccount')}
        />
      </Modal>
    );
  }
}

FundingModal.defaultProps = {
  showFundingModal: false,
};

FundingModal.propTypes = {
  showFundingModal: PropTypes.bool,
  toggleFundingModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FundingModal);
