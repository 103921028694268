import { useTranslation } from 'react-i18next';

import {
  InsuranceDefaultCoverWrapper,
  CoverHeader,
  CoverAmount,
  CoverAmountWrapper,
  CoverAmountLabel,
} from './styles';

import { useSuperUserQuery } from 'store/superAnnuation/superAnnuation';
import { formatNumber } from 'utils/formatters/formatNumber';

export const InsuranceDefaultCover = () => {
  const { t } = useTranslation();
  const { data } = useSuperUserQuery();
  const { insurance_coverage, insurance_premium } = data || {};

  return (
    <InsuranceDefaultCoverWrapper>
      <CoverHeader>{t('superInsurancePage.defaultCover.title')}</CoverHeader>

      <CoverAmountWrapper>
        <CoverAmountLabel>
          {t('superInsurancePage.defaultCover.coverAmountLabel')}
        </CoverAmountLabel>

        <CoverAmount>
          {formatNumber({
            value: insurance_coverage,
          })}
        </CoverAmount>
      </CoverAmountWrapper>

      <CoverAmountWrapper>
        <CoverAmountLabel>
          {t('superInsurancePage.defaultCover.premiumLabel')}
        </CoverAmountLabel>

        <CoverAmount>
          {t('superInsurancePage.defaultCover.perWeekText', {
            amount: formatNumber({
              value: insurance_premium,
            }),
          })}
        </CoverAmount>
      </CoverAmountWrapper>
    </InsuranceDefaultCoverWrapper>
  );
};
