import { api } from 'store/api';
import { API_TAGS } from 'store/consts';

export interface ICreateCardsData {
  number: string;
  bankId?: number;
}

export type ICreateCardsResponse = {
  message: string;
};

export type ICreateCardsError = {
  data: { error?: string };
};

export interface IDeleteCardsData {
  accountId: string;
}

export type IDeleteCardsResponse = {
  message: string;
};

export interface IUpdateCardsSetBankData {
  accountId: string;
  bankId: number;
}

export type IUpdateCardsSetBankResponse = {
  message: string;
};

export interface IRemoveDebitCardData {
  cardId: string;
}

export const cardsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCards: build.mutation<ICreateCardsResponse, ICreateCardsData>({
      query: ({ bankId, number }) => ({
        url: '/v1/cards',
        method: 'POST',
        data: { number, ...(bankId ? { bank_id: bankId } : {}) },
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
    deleteCards: build.mutation<IDeleteCardsResponse, IDeleteCardsData>({
      query: ({ accountId }) => ({
        url: `/v1/cards/${accountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
    updateCardsSetBank: build.mutation<
      IUpdateCardsSetBankResponse,
      IUpdateCardsSetBankData
    >({
      query: ({ accountId, bankId }) => ({
        url: `/v1/cards/${accountId}/set_bank`,
        method: 'PATCH',
        data: {
          bank_id: bankId,
        },
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
    updateDebitCard: build.mutation<
      IUpdateCardsSetBankResponse,
      IRemoveDebitCardData
    >({
      query: ({ cardId }) => ({
        url: '/v1/payment_cards',
        method: 'PATCH',
        data: {
          card_id: cardId,
          spending: false,
        },
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateCardsMutation,
  useDeleteCardsMutation,
  useUpdateCardsSetBankMutation,
  useUpdateDebitCardMutation,
} = cardsApi;

export const {
  endpoints: { createCards, deleteCards, updateCardsSetBank },
} = cardsApi;
