import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { PORTFOLIO_PLANS, PORTFOLIO_SEARCH_PARAMS } from '../consts';
import { FUNDS_TAGS } from '../subpages/PortfolioCustomization/consts';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { PATHS } from 'constants/paths';
import { updatePortfolioLocation } from 'store/portfolio/actions';

export const usePortfolioLocation = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { fundTag } = useParams();
  const dispatch = useAppDispatch();

  const isRegistration =
    pathname.includes(PATHS.registration.portfolio.index) ||
    pathname.includes(PATHS.kids.registration.index);

  const portfolioPlan = searchParams.get(PORTFOLIO_SEARCH_PARAMS.portfolioPlan);
  const isPortfolioCustomization = !!fundTag;

  const isChangeBasePortfolioPage = fundTag === FUNDS_TAGS.basePortfolio;

  const isProPortfolio =
    (portfolioPlan === PORTFOLIO_PLANS.pro || isPortfolioCustomization) &&
    !isChangeBasePortfolioPage;

  const isKids =
    pathname.includes(PATHS.kids.registration.index) ||
    pathname.includes(PATHS.kids.customization.index);

  useEffect(() => {
    dispatch(
      updatePortfolioLocation({
        isRegistration,
        isProPortfolio,
        isPortfolioCustomization,
        isKids,
        isChangeBasePortfolioPage,
      }),
    );
  }, [
    dispatch,
    isRegistration,
    isProPortfolio,
    isPortfolioCustomization,
    isKids,
    isChangeBasePortfolioPage,
  ]);
};
