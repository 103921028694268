// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import 'styles/pages/super-rollover.scss';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import FundsSearch from './FundsSearch';
import { HistoryItem } from './HistoryItem';

import Input from 'components/elements/Input/Input';
import RolloverAgreementModal from 'components/layout/Modals/RolloverAgreementModal';
import RolloverFundModal from 'components/layout/Modals/RolloverFundModal';
import RolloverRemoveConfirmModal from 'components/layout/Modals/RolloverRemoveConfirmModal';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import BankErrorModal from 'components/layout/Modals/BankErrorModal';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';
import { useUpdateStore } from 'store/user/hooks/useUpdateStore';
import { useSuperRefreshAccountErrorModal } from 'store/user/hooks/useSuperRefreshAccountErrorModal';
import { formatNumber } from 'utils/formatters/formatNumber';

function superFundsRender(
  t,
  localFund,
  handleShowAgreement,
  index,
  divRes,
  showConfirmationPopUp,
) {
  return (
    localFund.id &&
    index % 2 === divRes && (
      <div key={localFund.id} className="raiz-kids__card">
        <div className="content">
          <div className="fund__item__title">{localFund.name}</div>

          <div className="fund__item__subtitle">
            {t('superPage.superRollovers.manuallyAdded')}
          </div>

          <div className="fund__item__subtitle">
            {t('superPage.superRollovers.memberNumberCard')}

            {localFund.member_number}
          </div>
        </div>

        <div className="button-part">
          <div>
            <DeprecatedButton
              title={
                <Trans i18nKey="superPage.superRollovers.rollover" t={t} />
              }
              buttonClass="rollover-btn"
              handleClick={() => handleShowAgreement(localFund)}
            />
          </div>

          <div>
            <DeprecatedButton
              title={<Trans i18nKey="common.delete" t={t} />}
              buttonClass="delete-btn"
              handleClick={() => showConfirmationPopUp(localFund.id)}
            />
          </div>
        </div>
      </div>
    )
  );
}

function superSubAccountRender(
  t,
  localFund,
  handleShowAgreement,
  showDetails,
  index,
  divRes,
) {
  return (
    localFund.status === null &&
    index % 2 === divRes && (
      <div
        key={localFund.abn + localFund.usi + localFund.amount}
        className="raiz-kids__card subaccount"
      >
        <div
          onClick={showDetails}
          className="arrow"
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        />

        <div className="content">
          <div className="fund__item__title">{localFund.name}</div>

          <div className="fund__item__subtitle">
            {t('superPage.superRollovers.currentBalance')}

            {formatNumber({
              value: localFund.amount === null ? 0 : localFund.amount,
            })}
          </div>
        </div>

        <div className="button-part">
          <div>
            <DeprecatedButton
              title={
                <Trans i18nKey="superPage.superRollovers.rollover" t={t} />
              }
              buttonClass="rollover-btn"
              handleClick={() => handleShowAgreement(localFund)}
            />
          </div>
        </div>

        <div className="details">
          <div className="title">
            {t('superPage.superRollovers.memberDetails')}
          </div>

          <div>
            {t('superPage.superRollovers.accountNumber')}{' '}
            <span>{localFund.member_account_id}</span>
          </div>

          <div>
            {t('superPage.superRollovers.unique')} <span>{localFund.usi}</span>
          </div>

          <div>
            {t('superPage.superRollovers.identifier')}
            <span>{localFund.member_number}</span>
          </div>

          <div className="title">
            {t('superPage.superRollovers.fundDetails')}
          </div>

          <div>
            {t('superPage.superRollovers.existingProvider')}{' '}
            <span>
              {localFund.fund_accept_government_contributions ? 'Yes' : 'No'}
            </span>
          </div>

          <div>
            {t('superPage.superRollovers.insuranceIndicator')}{' '}
            <span>{localFund.insurance ? 'Yes' : 'No'}</span>
          </div>

          <div>
            {t('superPage.superRollovers.benefitIndicator')}{' '}
            <span>{localFund.defined_benefit ? 'Yes' : 'No'}</span>
          </div>

          <div>
            {t('superPage.superRollovers.acceptedIndication')}{' '}
            <span>{localFund.inward_rollover_accepted ? 'Yes' : 'No'}</span>
          </div>

          <div>
            {t('superPage.superRollovers.activityStatus')}{' '}
            <span>{localFund.activity_status}</span>
          </div>
        </div>
      </div>
    )
  );
}

export default function SuperRolloversView({
  isShowInto,
  toggleShow,
  userAccounts,
  superFunds,
  searchBanks,
  isSearch,
  showSearch,
  clickOnFund,
  isShowFundModal,
  handleCloseModal,
  fund,
  addRollover,
  localFunds,
  rolloverFund,
  deleteFund,
  errorMessage,
  changeMemberNumber,
  isShowAgreement,
  handleShowAgreement,
  showDetails,
  showConfirmationPopUp,
  isShowConfirmationModal,
  cancel,
}) {
  useUpdateStore();

  useSuperAccountErrorModal();

  useSuperRefreshAccountErrorModal();

  const { t } = useTranslation();

  return (
    <div className="super-rollover">
      <div className="super__banner page-content__banner banner-rollover">
        <div className="page-content__banner-title">
          {isSearch
            ? t('superPage.superRollovers.title.search')
            : t('superPage.superRollovers.title.list')}
        </div>

        {!isSearch && (
          <div className="profile-content__header">
            <div className="profile-content__navigation">
              <DeprecatedButton
                title={
                  <Trans
                    i18nKey="superPage.superRollovers.rolloverInto"
                    t={t}
                  />
                }
                buttonClass={isShowInto ? 'active' : ''}
                handleClick={() => toggleShow(true)}
              />

              <DeprecatedButton
                title={
                  <Trans i18nKey="superPage.superRollovers.history" t={t} />
                }
                buttonClass={!isShowInto ? 'active' : ''}
                handleClick={() => toggleShow(false)}
              />
            </div>
          </div>
        )}

        {isSearch && (
          <div className="profile-content__container">
            <Input
              onChange={searchBanks}
              type="text"
              placeholder={t('superPage.superRollovers.searchByName')}
              classList="grid__input"
            />
          </div>
        )}

        {isShowInto && !isSearch && (
          <div className="banner-text">
            {t('superPage.superRollovers.banerText')}
          </div>
        )}

        {isShowInto && isSearch && (
          <div className="banner-text">
            {t('superPage.superRollovers.searchText')}
          </div>
        )}
      </div>

      <div
        className={`info-panel panel-clear ${
          isShowInto ? 'rollover-into-panel' : 'history-panel'
        }`}
      >
        {!isShowInto && (
          <div>
            <div className="info-panel__title">
              {t('superPage.superRollovers.history')}
            </div>

            <div className="history__element">
              <div className="history__left-part">
                {userAccounts?.account?.subaccounts?.map?.((transaction) =>
                  transaction.usi ? (
                    <HistoryItem
                      transaction={transaction}
                      key={`${transaction.usi}-${transaction.member_number}`}
                    />
                  ) : null,
                )}
              </div>
            </div>
          </div>
        )}

        {isShowInto && (
          <div className="rollover-into">
            {isSearch && superFunds && superFunds.super_annuation_funds && (
              <FundsSearch
                funds={superFunds.super_annuation_funds}
                handleClick={clickOnFund}
              />
            )}

            {!isSearch && (
              <div>
                <div className="page-content__left">
                  <div className="raiz-kids__cards">
                    <div className="raiz-kids-old__cards-col">
                      {localFunds &&
                        localFunds.length !== 0 &&
                        localFunds.map((localFund, index) =>
                          superFundsRender(
                            t,
                            localFund,
                            handleShowAgreement,
                            index,
                            0,
                            showConfirmationPopUp,
                          ),
                        )}

                      {userAccounts &&
                        userAccounts.account &&
                        userAccounts.account.subaccounts &&
                        userAccounts.account.subaccounts.map(
                          (subaccount, index) =>
                            superSubAccountRender(
                              t,
                              subaccount,
                              handleShowAgreement,
                              showDetails,
                              index,
                              0,
                            ),
                        )}
                    </div>

                    <div className="raiz-kids-old__cards-col">
                      {localFunds &&
                        localFunds.length !== 0 &&
                        localFunds.map((localFund, index) =>
                          superFundsRender(
                            t,
                            localFund,
                            handleShowAgreement,
                            index,
                            1,
                            showConfirmationPopUp,
                          ),
                        )}

                      {userAccounts &&
                        userAccounts.account &&
                        userAccounts.account.subaccounts &&
                        userAccounts.account.subaccounts.map(
                          (subaccount, index) =>
                            superSubAccountRender(
                              t,
                              subaccount,
                              handleShowAgreement,
                              showDetails,
                              index,
                              1,
                            ),
                        )}
                    </div>
                  </div>
                </div>

                <div className="bottom-part">
                  <DeprecatedButton
                    title={
                      <Trans
                        i18nKey="superPage.superRollovers.addManually"
                        t={t}
                      />
                    }
                    buttonClass={!isShowInto ? 'active' : ''}
                    handleClick={showSearch}
                  />
                </div>
              </div>
            )}

            {isShowConfirmationModal && (
              <RolloverRemoveConfirmModal
                cancel={cancel}
                deleteFund={deleteFund}
                isShowConfirmationModal={isShowConfirmationModal}
              />
            )}

            {isShowFundModal && (
              <RolloverFundModal
                fund={fund}
                isShowFundModal={isShowFundModal}
                changeMemberNumber={changeMemberNumber}
                handleCloseModal={handleCloseModal}
                addRollover={addRollover}
              />
            )}

            {isShowAgreement && (
              <RolloverAgreementModal
                isShowAgreement={isShowAgreement}
                handleCloseModal={handleCloseModal}
                rolloverFund={rolloverFund}
              />
            )}

            {errorMessage !== '' && (
              <BankErrorModal
                hasBackdrop={false}
                isVisible={false}
                isOpen
                shouldCloseOnOverlayClick
                handleClick={handleCloseModal}
                errorMessage={errorMessage}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

SuperRolloversView.defaultProps = {
  localFunds: [],
};

SuperRolloversView.propTypes = {
  isShowInto: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  userAccounts: PropTypes.shape({
    account: PropTypes.shape({
      subaccounts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  superFunds: PropTypes.shape({
    super_annuation_funds: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  searchBanks: PropTypes.func.isRequired,
  isSearch: PropTypes.bool.isRequired,
  showSearch: PropTypes.func.isRequired,
  clickOnFund: PropTypes.func.isRequired,
  isShowFundModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  fund: PropTypes.shape({}).isRequired,
  addRollover: PropTypes.func.isRequired,
  localFunds: PropTypes.arrayOf(PropTypes.shape({})),
  rolloverFund: PropTypes.func.isRequired,
  deleteFund: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  changeMemberNumber: PropTypes.func.isRequired,
  isShowAgreement: PropTypes.bool.isRequired,
  handleShowAgreement: PropTypes.func.isRequired,
  showDetails: PropTypes.func.isRequired,
  showConfirmationPopUp: PropTypes.func.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
};
