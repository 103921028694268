import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterButton, FiltersCount } from './styles';
import Filters from './Filters/Filters';
import { useFundsFilter } from './Filters/hooks';

export default function FundsListFilter() {
  const { t } = useTranslation();

  const {
    isFilterModalVisible,
    toggleIsFilterModalVisible,
    selectedFilterCount,
  } = useFundsFilter();

  return (
    <>
      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <FilterButton className="-exclude" onClick={toggleIsFilterModalVisible}>
        {selectedFilterCount > 0 && (
          <FiltersCount>{selectedFilterCount}</FiltersCount>
        )}

        {t('portfolioCustomization.filters.buttons.filter')}
      </FilterButton>

      {isFilterModalVisible && (
        <Filters closeFilterModal={() => toggleIsFilterModalVisible(false)} />
      )}
    </>
  );
}
