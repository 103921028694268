import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';

export default function RewardDetailsBanner({
  background_image_url,
  campaign_owner_name,
  campaign_owner_image_url,
}) {
  return (
    <div className="reward-internal__banner reward-internal__banner__new">
      <div>
        <img
          className="reward-internal__banner-background reward-internal__banner-background__new"
          src={background_image_url}
          alt={campaign_owner_name}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      </div>

      <div className="reward-internal__banner-icon reward-internal__banner-icon__new">
        <img
          src={campaign_owner_image_url}
          alt={campaign_owner_name}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      </div>
    </div>
  );
}

RewardDetailsBanner.defaultProps = {
  background_image_url: '',
  campaign_owner_name: '',
  campaign_owner_image_url: '',
};

RewardDetailsBanner.propTypes = {
  background_image_url: PropTypes.string,
  campaign_owner_name: PropTypes.string,
  campaign_owner_image_url: PropTypes.string,
};
