import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import FundingAlertsModalItem from './components/FundingAlertsModalItem';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function FundingAlertsModal({
  isModalAlertsOpen,
  setIsModalAlertsOpen,
  fundingAlerts,
  toggleAlertInfoModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isModalAlertsOpen}
      onRequestClose={() => setIsModalAlertsOpen(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic modal-alert"
    >
      <div className="modal-basic__header">
        <DeprecatedButton
          buttonClass="button__close -grey"
          handleClick={() => setIsModalAlertsOpen(false)}
        />

        {t('common.issues')}
      </div>

      <div className="modal-basic__modal-content">
        {fundingAlerts.map((alert) => (
          <FundingAlertsModalItem
            key={`${alert.type}-div`}
            isFunding
            alert={alert}
            toggleAlertInfoModal={toggleAlertInfoModal}
            setIsModalAlertsOpen={setIsModalAlertsOpen}
          />
        ))}
      </div>
    </Modal>
  );
}

FundingAlertsModal.propTypes = {
  isModalAlertsOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  setIsModalAlertsOpen: PropTypes.func.isRequired,
  fundingAlerts: PropTypes.arrayOf().isRequired,
};

export default FundingAlertsModal;
