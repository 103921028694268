import { useMemo } from 'react';
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from 'react-router-dom';

import { getParentUrlPathname } from './utils';

import {
  PORTFOLIO_PLANS,
  PORTFOLIO_SEARCH_PARAMS,
} from 'pages/Portfolio/consts';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

export const usePortfolioBackButtonHandler = () => {
  const {
    isPortfolioCustomization,
    isRegistration,
    isKids,
    isChangeBasePortfolioPage,
  } = useAppSelector(selectPortfolioLocation);

  const navigate = useNavigate();
  const { childId, isSuper } = useAppSelector(selectPortfolioParams);

  const parentUrl = useMemo(
    () => ({
      pathname: generatePath(
        getParentUrlPathname({
          isKids,
          isRegistration,
        }),
        {
          childId,
        },
      ),
      search: createSearchParams({
        ...(childId && { childId }),
        ...(isSuper && { isSuper: isSuper?.toString() }),
        [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: PORTFOLIO_PLANS.pro,
      }).toString(),
    }),
    [childId, isKids, isRegistration, isSuper],
  );

  const handleGoBack = () => {
    if (isPortfolioCustomization && !isChangeBasePortfolioPage) {
      navigate(parentUrl);
    } else {
      navigate(-1);
    }
  };

  return { handleGoBack };
};
