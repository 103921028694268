import { useSearchParams } from 'react-router-dom';

import { selectIsSuperRegistration } from '../selectors';

import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useIsSuperRegistration = () => {
  const [searchParams] = useSearchParams();
  const isSuper = !!searchParams.get(PORTFOLIO_SEARCH_PARAMS.isSuper);
  const isSuperRegistration = useAppSelector(selectIsSuperRegistration);

  if (!isSuper) {
    return false;
  }

  return isSuperRegistration;
};
