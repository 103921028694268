import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OfferItem({ offer, saveInvested }) {
  const { id, invested_value, campaign_owner_image_url, campaign_owner_name } =
    offer;

  const { t } = useTranslation();

  return (
    <Link to={`/rewards/${id}`} onClick={() => saveInvested(invested_value)}>
      <div className="grid__container-item">
        <div className="grid__container-image">
          <img
            src={campaign_owner_image_url}
            alt={campaign_owner_name}
            className="grid__container-logo"
          />
        </div>

        <div className="grid__container-divider" />

        <div className="grid__container-name">{campaign_owner_name}</div>

        {invested_value && (
          <div className="grid__container-invest">
            {t('rewardsPage.offerItem.investedPercentage', {
              amount: invested_value,
            })}
          </div>
        )}
      </div>
    </Link>
  );
}

OfferItem.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    campaign_owner_image_url: PropTypes.string,
    invested_value: PropTypes.number,
    campaign_owner_name: PropTypes.string,
  }).isRequired,
  saveInvested: PropTypes.func.isRequired,
};
