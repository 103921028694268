import { CLOSE_MODAL } from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { createRequest, createOutRequest } from '../user/actions';
import { HISTORY_STATUSES } from '../../pages/HistoryDetails/consts';

import {
  SHOW_ERROR_MODAL,
  GET_REWARDS_SUCCESS,
  SHOW_HISTORY_DETAIL_MODAL,
  GET_ACCOUNT_SUMMARY_SUCCESS,
  GET_INVESTMENTS_OVERVIEW_SUCCESS,
  GET_HISTORY_TRANSACTIONS_SUCCESS,
  SET_HISTORY_PARAMS,
} from './consts';
import removeTransactionById from './utils';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function getTransactionsHistorySuccess(historySuccessParams) {
  const { transactions } = historySuccessParams;

  return {
    type: GET_HISTORY_TRANSACTIONS_SUCCESS,
    transactions,
  };
}

export function showHistoryDetailsModal(transactionDetails) {
  return { type: SHOW_HISTORY_DETAIL_MODAL, transactionDetails };
}

export function showErrorModal(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_MODAL, errorMessage, errorTitle };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function getAccountSummarySuccess(accountSummary) {
  return { type: GET_ACCOUNT_SUMMARY_SUCCESS, accountSummary };
}

export function getInvestmentsOverviewSuccess(investmentsOverview) {
  return { type: GET_INVESTMENTS_OVERVIEW_SUCCESS, investmentsOverview };
}

export function getRewardsSuccess(rewards) {
  return { type: GET_REWARDS_SUCCESS, rewards };
}

export function setTransactionsHistoryParams(transactionsHistoryParams) {
  const { historyStatus, historyOffset } = transactionsHistoryParams;

  return { type: SET_HISTORY_PARAMS, historyStatus, historyOffset };
}

export function getTransactionsHistory(historyParams) {
  const {
    limit,
    isSuper,
    areFailedTransactionsVisible,
    isKidsHistory,
    userId,
    historyStatus,
    offset,
  } = historyParams;

  const historyOffset = offset + 1;
  let raizSuperPath = '';

  if (isSuper) {
    raizSuperPath = 'super_annuation/user/';
  }

  const queryParams = `investments?limit=${limit}&status=${historyStatus}&offset=${historyOffset}${
    !areFailedTransactionsVisible ? '&excluded_statuses[]=failed' : ''
  }`;

  const apiVersion = !isSuper
    ? process.env.REACT_APP_API_VERSION_V2
    : process.env.REACT_APP_API_VERSION;

  const request = isKidsHistory
    ? `${process.env.REACT_APP_API_URL}/dependency_users/v1/${queryParams}&dependent_user_id=${userId}`
    : `${process.env.REACT_APP_API_URL}/${apiVersion}/${raizSuperPath}${queryParams}`;

  return (dispatch) =>
    createOutRequest(null, 'GET', request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          const { investments, total_pages } = response;

          const historyTransactionsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.historyTransactions),
          );

          const sortedTransactions = [
            ...historyTransactionsStore,
            ...investments,
          ];

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.historyTransactions,
            JSON.stringify(sortedTransactions),
          );

          if (
            historyStatus === HISTORY_STATUSES.unfinished &&
            (total_pages === offset + 1 || total_pages === 0)
          ) {
            if (total_pages === 0) {
              dispatch(
                getTransactionsHistory({
                  ...historyParams,
                  offset: 0,
                  historyStatus: HISTORY_STATUSES.finished,
                }),
              );

              return historyParams;
            }

            dispatch(
              setTransactionsHistoryParams({
                historyStatus: HISTORY_STATUSES.finished,
                historyOffset: 0,
              }),
            );
          } else {
            dispatch(
              setTransactionsHistoryParams({
                historyStatus,
                historyOffset,
              }),
            );
          }

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.historyTransactionsTotalPages,
            total_pages,
          );

          dispatch(
            getTransactionsHistorySuccess({
              transactions: sortedTransactions,
            }),
          );

          return sortedTransactions;
        }

        return [];
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function cancelTransaction(cancelTransactionParams) {
  const { id, isKidsHistory, userId } = cancelTransactionParams;

  const request = isKidsHistory
    ? `${process.env.REACT_APP_API_URL}/dependency_users/v1/investments/${id}/cancel?dependent_user_id=${userId}`
    : `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/investments/${id}/cancel`;

  return (dispatch) =>
    createOutRequest(null, 'PUT', request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          const transactionsHistory = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.historyTransactions),
          );

          const updatedTransactionsHistory = removeTransactionById(
            transactionsHistory,
            id,
          );

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.historyTransactions,
            JSON.stringify(updatedTransactionsHistory),
          );

          dispatch(
            getTransactionsHistorySuccess({
              transactions: updatedTransactionsHistory,
            }),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function cancelTransactionSuper(id) {
  return (dispatch) =>
    createRequest(null, 'PUT', `super_annuation/user/investments/${id}/cancel`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          const transactionsHistory = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.historyTransactions),
          );

          const updatedTransactionsHistory = removeTransactionById(
            transactionsHistory,
            id,
          );

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.historyTransactions,
            JSON.stringify(updatedTransactionsHistory),
          );

          dispatch(
            getTransactionsHistorySuccess({
              transactions: updatedTransactionsHistory,
            }),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAccountSummary(isSuper = false) {
  let params = 'account_summary';
  let mobile = '/mobile';

  if (isSuper) {
    params = 'super_annuation/user/account_summary';

    mobile = '';
  }

  return (dispatch) =>
    createRequest(null, 'GET', params, mobile)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(getAccountSummarySuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getInvestmentsOverview() {
  return (dispatch) =>
    createRequest(null, 'GET', 'investments/overview')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(getInvestmentsOverviewSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRewards() {
  return (dispatch) =>
    createRequest(null, 'GET', 'rewards', '/mobile')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(getRewardsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getTransactionDetails(transactionDetailParams) {
  const { id, isKidsHistory, userId } = transactionDetailParams;
  const params = `investments/${id}`;

  const request = isKidsHistory
    ? `${process.env.REACT_APP_API_URL}/dependency_users/v1/${params}?dependent_user_id=${userId}`
    : `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION_V2}/${params}`;

  return (dispatch) =>
    createOutRequest(null, 'GET', request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(showHistoryDetailsModal(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
