import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  IRootReferralsState,
  IReferralsStateList,
  IReferralsStateListStatus,
  IReferralsStateListData,
  IReferralsListGroupHeader,
  IReferralsListGroupItem,
  IInitialValueReduceReferralsListGrouped,
} from './types';

import { DATE_FORMAT_MONTH_YEAR } from 'config/date';

export const selectReferralsList = (
  state: IRootReferralsState,
): IReferralsStateList => state.referrals.list;

export const selectReferralsListData = (
  state: IRootReferralsState,
): IReferralsStateListData => state.referrals.list.data?.referrals || [];

export const selectReferralsListStatus = (
  state: IRootReferralsState,
): IReferralsStateListStatus => ({
  isSuccess: state.referrals.list.isSuccess,
  isError: state.referrals.list.isError,
  isSettled: state.referrals.list.isSettled,
  isLoading: state.referrals.list.isLoading,
});

export const selectReferralsListGroupedData = createSelector(
  [selectReferralsListData, selectReferralsListStatus],
  (referralsListData, referralsListStatus) => ({
    ...referralsListStatus,
    data: referralsListData.reduce<IInitialValueReduceReferralsListGrouped>(
      (accumulation, referralsListItem) => {
        const groupDate = dayjs(referralsListItem.date).format(
          DATE_FORMAT_MONTH_YEAR,
        );

        const isNew = !accumulation.headers.includes(groupDate);

        const headers = isNew
          ? [...accumulation.headers, groupDate]
          : accumulation.headers;

        const counter = isNew ? 1 : accumulation.count[groupDate] + 1;

        const count = {
          ...accumulation?.count,
          [groupDate]: counter,
        };

        const id = `${groupDate}_${counter}`;

        const header: IReferralsListGroupHeader = {
          data: groupDate,
          id: groupDate,
          type: 'header',
        };

        const item: IReferralsListGroupItem = {
          data: referralsListItem,
          id,
          type: 'item',
        };

        const mapped = isNew
          ? [...accumulation.mapped, header, item]
          : [...accumulation.mapped, item];

        return { headers, mapped, count };
      },
      { headers: [], mapped: [], count: {} },
    ).mapped,
  }),
);
