import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { useTranslation } from 'react-i18next';

import Input from '../Input/Input';
import { GET_CATEGORIES_SUCCESS } from '../../../store/rewards/types';
import Loader from '../Loader/Loader';
import { COUNTRY, MY_APP } from '../../../constants/localeConfigs';
import CategoriesView from '../../../pages/NewRewards/components/CategoriesView';
import { AUS, MYS } from '../../../constants/comonConstants';
import OnlineOfferItem from '../../../pages/NewRewards/components/OnlineOfferItem';
import OfferItem from '../../../pages/Rewards/components/OfferItem';

import { SUCCESS_OR_FAILURE_TRANSACTION_PATHS } from 'pages/Settings/components/hooks/const';
import { useSuccessOrFailureModal } from 'pages/Settings/components/hooks/useSuccessOrFailureModal';
import { PATHS } from 'constants/paths';
import { SEARCH_PARAMS } from 'constants/searchParams';
import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';

function categoriesRender(category, index, filterByCategory, activeCategoryId) {
  let active = '';

  if (activeCategoryId === category.id) {
    active = '-active';
  }

  return (
    <button
      className={`grid__categories-item ${active}`}
      id={category.id}
      key={index}
      type="button"
      onClick={filterByCategory}
    >
      {category.name}
    </button>
  );
}

function categoriesRenderFake(e, index) {
  return (
    <button
      className="grid__categories-item"
      key={`${index * 2}`}
      type="button"
      disabled
    >
      <Skeleton />
    </button>
  );
}

function Bank({ brand, handleClick, roundUpsAccountMfaOn, checkMfa }) {
  const id = brand.institution_id ? brand.institution_id : brand.id;
  const linkTo = handleClick ? '#' : `/rewards/${id}`;
  let isMfa = false;

  if (!roundUpsAccountMfaOn && brand.login_form) {
    isMfa = checkMfa(brand);
  }

  if (isMfa) {
    return <div />;
  }

  return (
    <Link to={linkTo} onClick={() => handleClick(id)} key={id}>
      <div className="grid__container-item">
        <div className="grid__container-image">
          <ImageWithDefaultIcon
            src={brand.icon}
            alt={brand.campaign_owner_name}
            className="grid__container-logo"
            defaultIconSize={50}
          />
        </div>

        <div className="grid__container-divider" />

        <div className="grid__container-name">{brand.name}</div>
      </div>
    </Link>
  );
}

const useRenderBankByCountry = () => {
  const { t } = useTranslation();

  function banksRenderMy(
    brand,
    handleClick,
    isVisa,
    isMasterCard,
    index,
    showVisaAllBanks,
    isShowAllVisaBanks,
  ) {
    if (isMasterCard && brand?.financial_institution_code !== 'MBB') {
      return null;
    }

    const linkTo = handleClick ? '#' : `/rewards/${brand.id}`;

    if (isVisa && !isShowAllVisaBanks) {
      if (index === 10) {
        return (
          <Link to={linkTo} onClick={(e) => showVisaAllBanks(e)} key={brand.id}>
            <div className="grid__container-item">
              <div className="grid__container-image">
                <div className="visa-other-banks" />
              </div>

              <div className="grid__container-divider" />

              <div className="grid__container-name">
                {t('settingsPage.grid.showOtherBanksButton')}
              </div>
            </div>
          </Link>
        );
      }

      if (index > 10) {
        return null;
      }
    }

    return (
      <Link
        to={linkTo}
        onClick={(e) => handleClick(brand.id, e)}
        key={brand.id}
      >
        <div className="grid__container-item">
          <div className="grid__container-image">
            {isVisa ? (
              <div className="visa-img" />
            ) : (
              <img
                src={brand.icon_url}
                alt={brand.bank_name}
                className="grid__container-logo"
              />
            )}
          </div>

          <div className="grid__container-divider" />

          <div className="grid__container-name">{brand.bank_name}</div>
        </div>
      </Link>
    );
  }

  function getRenderType(
    brand,
    handleClick,
    roundUpsAccountMfaOn,
    checkMfa,
    isVisa = false,
    isMasterCard = false,
    index = 0,
    showVisaAllBanks = null,
    isShowAllVisaBanks = false,
  ) {
    const content = {
      [AUS]: (
        <Bank
          brand={brand}
          handleClick={handleClick}
          roundUpsAccountMfaOn={roundUpsAccountMfaOn}
          checkMfa={checkMfa}
        />
      ),
      [MYS]: banksRenderMy(
        brand,
        handleClick,
        isVisa,
        isMasterCard,
        index,
        showVisaAllBanks,
        isShowAllVisaBanks,
      ),
    };

    return content[COUNTRY];
  }

  return { getRenderType };
};

export default function Grid(props) {
  const {
    categories,
    items,
    isNeedSearch,
    categoryTitle,
    isBank,
    filterByCategory,
    brandsFilter,
    activeCategoryId,
    searchHandler,
    saveInvested,
    handleClick,
    categoriesType,
    showMainLoader,
    isRegistration,
    clearSearch,
    searchValue,
    isSpending,
    roundUpsAccountMfaOn,
    checkMfa,
    isNewRewards,
    filterCategory,
    toggleLikeOffer,
    showAddCardModal,
    showVisaAllBanks,
    isShowAllVisaBanks,
    visaEnabled,
    banksInstitutions,
    signInBank,
    categoryName,
    categoryId,
  } = props;

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isFromInvestments = searchParams.get(SEARCH_PARAMS.isFromInvestments);

  useSuccessOrFailureModal({
    successUrl: PATHS.registration.fundingAccount.status.success,
    failureUrl: PATHS.registration.fundingAccount.status.failure,
    translationsPath: SUCCESS_OR_FAILURE_TRANSACTION_PATHS.settingsPage,
    navigatePath: isFromInvestments
      ? PATHS.registration.investment.index
      : PATHS.registration.fundingAccount.index,
  });

  const { getRenderType } = useRenderBankByCountry();

  const visaBank = {
    bank_name: t('settingsPage.grid.visa'),
    id: 'visa',
  };

  const institutions = banksInstitutions?.institutions || [];

  return (
    <div className="grid">
      {isNeedSearch && (
        <div className="grid__title">
          {!MY_APP && (
            <span className="search-content__title">
              {!isNewRewards
                ? t('settingsPage.grid.brands')
                : t('settingsPage.grid.title')}
            </span>
          )}

          {!isNewRewards && (
            <div className="search-content">
              <Input
                onChange={
                  isNewRewards
                    ? (e) => {
                        searchHandler(e, true);
                      }
                    : searchHandler
                }
                onClick={
                  isNewRewards
                    ? (e) => {
                        searchHandler(e, true);
                      }
                    : () => {}
                }
                autoFocus={window.innerWidth > 768}
                defaultValue={searchValue}
                type="text"
                placeholder={t('rewardsPage.searchByName')}
                classList="grid__input"
              />
            </div>
          )}

          {!isNewRewards && (
            <div
              onClick={clearSearch}
              className="clear-search"
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            />
          )}
        </div>
      )}

      {isNewRewards && (
        <CategoriesView
          categories={categories}
          filterCategory={filterCategory}
          isSearchFromMainPage
          categoryId={categoryId}
        />
      )}

      <div
        className="grid__categories"
        id={isNewRewards ? 'rewards-online-items' : 'grid-categories'}
      >
        {categoryTitle && !isNewRewards && (
          <div className="grid__categories-title">{categoryTitle}</div>
        )}

        {isNewRewards && <div className="brands-title">{categoryName}</div>}

        {categories &&
          !isRegistration &&
          !isNewRewards &&
          !isSpending &&
          !isBank && (
            <div className="grid__categories-container">
              {categories.length && categoriesType === GET_CATEGORIES_SUCCESS
                ? categories.map((category, index) =>
                    categoriesRender(
                      category,
                      index,
                      filterByCategory,
                      activeCategoryId,
                    ),
                  )
                : [...Array(5)].map((e, index) =>
                    categoriesRenderFake(e, index),
                  )}
            </div>
          )}

        {MY_APP && !isSpending && !isRegistration && !isBank && (
          <div className="grid__categories-title">
            {t('settingsPage.grid.brands')}
          </div>
        )}

        {showMainLoader && <Loader additionalClass="-absolute -top" />}

        {!isBank &&
          items &&
          brandsFilter.length === 0 &&
          !showMainLoader &&
          !isNewRewards && (
            <div className="grid__container">
              {items.map((offer) => (
                <OfferItem
                  key={`offer${offer.id}`}
                  offer={offer}
                  saveInvested={saveInvested}
                />
              ))}
            </div>
          )}

        {!isBank && !showMainLoader && !isNewRewards && (
          <div className="grid__container">
            {brandsFilter.map((offer) => (
              <OfferItem
                key={`offer${offer.id}`}
                offer={offer}
                saveInvested={saveInvested}
              />
            ))}
          </div>
        )}

        {isNewRewards && !showMainLoader && items && items.length !== 0 && (
          <div className="grid__container">
            {items.map((offer) => (
              <OnlineOfferItem
                key={`offer${offer.id}`}
                offer={offer}
                saveInvested={saveInvested}
                toggleLikeOffer={toggleLikeOffer}
                categoryId={categoryId}
              />
            ))}
          </div>
        )}

        {isBank && items && !showMainLoader && (
          <div className="grid__container">
            {visaEnabled && (
              <div>
                {isSpending && (
                  <div>
                    <div className="grid__container-banks-type ">
                      <div>{t('settingsPage.grid.visa')}</div>

                      <div>{t('settingsPage.grid.debitAndCards')}</div>
                    </div>

                    {getRenderType(
                      visaBank,
                      showAddCardModal,
                      roundUpsAccountMfaOn,
                      checkMfa,
                      true,
                      false,
                      0,
                      showVisaAllBanks,
                      isShowAllVisaBanks,
                    )}

                    <div className="grid__container-banks-type -mastercard">
                      <div>{t('settingsPage.grid.mastercard')}</div>

                      <div>{t('settingsPage.grid.debitCard')}</div>
                    </div>
                  </div>
                )}

                {items.map((brand) =>
                  getRenderType(
                    brand,
                    handleClick,
                    roundUpsAccountMfaOn,
                    checkMfa,
                    false,
                    true,
                  ),
                )}

                {institutions.length > 0 && (
                  <div>
                    <div className="grid__container-banks-type -bankAccount">
                      <div>{t('settingsPage.grid.bankAccount')}</div>

                      <div />
                    </div>

                    {institutions.map((institution) => (
                      <Bank
                        key={institution.id}
                        brand={institution}
                        handleClick={signInBank}
                        roundUpsAccountMfaOn={roundUpsAccountMfaOn}
                        checkMfa={checkMfa}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}

            {MY_APP && !visaEnabled && (
              <div>
                {isSpending && (
                  <div className="grid__container-banks-type -mastercard">
                    <div>{t('settingsPage.grid.mastercard')}</div>

                    <div>{t('settingsPage.grid.debitCard')}</div>
                  </div>
                )}

                {items.map((brand) =>
                  getRenderType(
                    brand,
                    handleClick,
                    roundUpsAccountMfaOn,
                    checkMfa,
                    false,
                    isSpending,
                  ),
                )}
              </div>
            )}

            {!MY_APP &&
              items.map((brand) =>
                getRenderType(
                  brand,
                  handleClick,
                  roundUpsAccountMfaOn,
                  checkMfa,
                ),
              )}
          </div>
        )}
      </div>
    </div>
  );
}

Grid.defaultProps = {
  isBank: false,
  filterByCategory: null,
  brandsFilter: [],
  searchHandler: null,
  saveInvested() {},
  activeCategoryId: null,
  categories: [],
  isNeedSearch: false,
  showMainLoader: false,
  categoryTitle: null,
  handleClick: null,
  categoriesType: '',
  isRegistration: false,
  clearSearch() {},
  searchValue: '',
  isSpending: false,
  stateMessages: {},
  roundUpsAccountMfaOn: false,
  checkMfa() {},
  isNewRewards: false,
  filterCategory() {},
  toggleLikeOffer() {},
  showVisaAllBanks() {},
  isShowAllVisaBanks: false,
  banksInstitutions: {},
  signInBank() {},
  categoryName: '',
  categoryId: null,
  visaEnabled: false,
};

Grid.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isNeedSearch: PropTypes.bool,
  categoryTitle: PropTypes.shape({}),
  isBank: PropTypes.bool,
  showMainLoader: PropTypes.bool,
  filterByCategory: PropTypes.func,
  brandsFilter: PropTypes.arrayOf(PropTypes.shape({})),
  activeCategoryId: PropTypes.number,
  searchHandler: PropTypes.func,
  saveInvested: PropTypes.func,
  handleClick: PropTypes.func,
  categoriesType: PropTypes.string,
  isRegistration: PropTypes.bool,
  clearSearch: PropTypes.func,
  searchValue: PropTypes.string,
  isSpending: PropTypes.bool,
  stateMessages: PropTypes.shape({}),
  roundUpsAccountMfaOn: PropTypes.bool,
  checkMfa: PropTypes.func,
  isNewRewards: PropTypes.bool,
  filterCategory: PropTypes.func,
  toggleLikeOffer: PropTypes.func,
  showAddCardModal: PropTypes.func.isRequired,
  showVisaAllBanks: PropTypes.func,
  isShowAllVisaBanks: PropTypes.bool,
  visaEnabled: PropTypes.bool,
  banksInstitutions: PropTypes.shape({
    institutions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  signInBank: PropTypes.func,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
};

Bank.propTypes = {
  brand: PropTypes.shape({
    institution_id: PropTypes.string,
    id: PropTypes.string.isRequired,
    login_form: PropTypes.shape({}),
    icon: PropTypes.string,
    campaign_owner_name: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  roundUpsAccountMfaOn: PropTypes.bool.isRequired,
  checkMfa: PropTypes.func.isRequired,
};
