import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { withTranslation } from 'react-i18next';

import {
  ARROW_EXPAND,
  CREDIT,
  UNCATEGORISED,
} from '../../../constants/comonConstants';
import 'react-accessible-accordion/dist/fancy-example.css';
import * as spendingAnalyticsActions from '../../../store/spendingAnalytics/actions';
import DeprecatedDotsLoader from '../../elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { CURRENCY_SYMBOL } from '../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

class CategorisationInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
    };

    this.updateTransactionType = this.updateTransactionType.bind(this);
  }

  updateTransactionType(type, className) {
    const { actionsSpendingAnalytics, transaction, toggleCategorisationInfo } =
      this.props;

    if ((className && className.indexOf(ARROW_EXPAND) < 0) || !className) {
      this.setState({ showLoader: true });

      actionsSpendingAnalytics
        .updateTransactionType({
          key: transaction.key,
          type: type ? `#${type}` : null,
        })
        .then(() => {
          const { errorMessage } = this.props;

          if (!errorMessage) {
            this.setState({ showLoader: false });

            toggleCategorisationInfo(false);
          }
        });
    }
  }

  renderType(type, key) {
    const { transactionName } = this.props;

    return (
      <AccordionItem
        key={key}
        uuid={type.id}
        className={`my-finance__modal-category ${
          transactionName === type.name ? '-active' : ''
        }`}
      >
        <AccordionItemHeading
          onClick={(e) =>
            this.updateTransactionType(type.id, e.target.className)
          }
        >
          {type.name}

          <AccordionItemButton
            className={`my-finance__modal-category ${
              transactionName === type.id ? '-active' : ''
            }`}
          >
            {type.subcategories && (
              <i className="arrow-dropdown arrow-expand" />
            )}
          </AccordionItemButton>
        </AccordionItemHeading>

        {type.subcategories && (
          <AccordionItemPanel>
            {type.subcategories.map((subcategory) => (
              <div
                onClick={() => this.updateTransactionType(subcategory.id)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
                key={subcategory.id}
                className={`my-finance__modal-category accordion__heading accordion__heading-sub
                      ${transactionName === subcategory.name ? '-active' : ''}`}
              >
                {subcategory.name}
              </div>
            ))}
          </AccordionItemPanel>
        )}
      </AccordionItem>
    );
  }

  render() {
    const {
      showCategorisationInfo,
      toggleCategorisationInfo,
      transactionName,
      transactionAmount,
      transactionDescription,
      transactionMovement,
      types,
      transactionCategory,
      t,
    } = this.props;

    const { showLoader } = this.state;
    const suffix = transactionAmount % 1 > 0 ? '' : ',00';

    return (
      <div>
        <Modal
          isOpen={showCategorisationInfo}
          contentLabel="categorisation-modal"
          shouldCloseOnOverlayClick
          className="modal-basic my-finance__modal"
        >
          <div className="modal-basic__content my-finance__modal-content">
            <DeprecatedButton
              buttonClass="button__close"
              handleClick={() => toggleCategorisationInfo(false)}
            />

            {transactionDescription}

            <div className="my-finance__modal-value">
              {transactionMovement === CREDIT ? '+' : '-'}

              <NumberFormat
                value={transactionAmount}
                displayType="text"
                decimalSeparator=","
                prefix={CURRENCY_SYMBOL}
                suffix={suffix}
                decimalScale={2}
                thousandSeparator=" "
              />
            </div>
          </div>

          {showLoader ? (
            <DeprecatedDotsLoader additionalClass=" -blue" />
          ) : (
            <div>
              <div className="my-finance__modal-sub-title">
                {t('transactionCategorisationPage.subTitle')}
              </div>

              <Accordion preExpanded={[transactionCategory]} allowZeroExpanded>
                {types.map((type, index) => this.renderType(type, index))}

                <div
                  className={`my-finance__modal-category accordion__heading
                              ${
                                transactionName === UNCATEGORISED
                                  ? '-active'
                                  : ''
                              }`}
                  onClick={() => this.updateTransactionType()}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  {t('transactionCategorisationPage.uncategorised')}
                </div>
              </Accordion>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  errorMessage: state.spendingAnalytics.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CategorisationInfoModal));

CategorisationInfoModal.propTypes = {
  t: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  actionsSpendingAnalytics: PropTypes.shape({
    updateTransactionType: PropTypes.func,
  }).isRequired,
  showCategorisationInfo: PropTypes.bool.isRequired,
  toggleCategorisationInfo: PropTypes.func.isRequired,
  transactionAmount: PropTypes.number.isRequired,
  transactionMovement: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  transactionCategory: PropTypes.string.isRequired,
  transactionName: PropTypes.string.isRequired,
  transactionDescription: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CategorisationInfoModal.defaultProps = {
  errorMessage: '',
};
