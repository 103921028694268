import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { SKIP_BUTTON_TIMEOUT } from '../../consts';

import {
  Description,
  SkipButton,
  SpinnerWrapper,
  LoadingModalContentWrapper,
} from './styles';

import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { Spinner } from 'components/elements/Spinner';

interface IWaitingModalContentProps {
  description?: string;
}

export const WaitingModalContent = ({
  description,
}: IWaitingModalContentProps) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, SKIP_BUTTON_TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: false,
      }),
    );
  };

  return (
    <LoadingModalContentWrapper>
      <SpinnerWrapper>
        <Spinner />

        <Description>{description}</Description>
      </SpinnerWrapper>

      <SkipButton
        title={t('common.skip')}
        handleClick={handleCloseModal}
        isButtonVisible={isButtonVisible}
      />
    </LoadingModalContentWrapper>
  );
};
