import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FundMaxWeighting from '../FundDetails/FundMaxWeighting';

import EditFund from './FundItemActions/FundItemActions';
import {
  FundItemAmount,
  FundItemBottomWrapper,
  FundItemPeriod,
  FundItemSymbol,
  FundItemVerticalLine,
  FundItemYearToDateChanges,
  ItemBottomWrapper,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

export default function FundItemBottom({ fund }) {
  const { t } = useTranslation();

  const {
    id,
    symbol,
    allocation,
    current_price,
    max_allocation,
    ytd_return,
    inactive_for_super_plus,
    name,
  } = fund || {};

  return (
    <ItemBottomWrapper>
      <FundItemBottomWrapper>
        <FundItemSymbol>{symbol}</FundItemSymbol>

        <FundItemVerticalLine />

        <FundItemAmount>
          {formatNumber({
            value: current_price,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </FundItemAmount>

        <FundItemVerticalLine />

        <FundItemYearToDateChanges isNotProfitable={ytd_return < 0}>
          {formatNumber({
            value: ytd_return,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            type: NUMBER_FORMATS.percent,
          })}
        </FundItemYearToDateChanges>

        <FundItemPeriod>
          {t('portfolioCustomization.oneYearLabel')}
        </FundItemPeriod>

        <EditFund
          id={id}
          allocation={allocation}
          max_allocation={max_allocation}
          inactive_for_super_plus={inactive_for_super_plus}
          name={name}
          symbol={symbol}
        />
      </FundItemBottomWrapper>

      <FundMaxWeighting id={id} />
    </ItemBottomWrapper>
  );
}

FundItemBottom.propTypes = {
  fund: PropTypes.shape({
    symbol: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
};
