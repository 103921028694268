import { useTranslation } from 'react-i18next';

import {
  SuperAccountModalDescription,
  SuperAccountModalLinksWrapper,
} from './styles';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { SUBMIT_TICKET } from 'constants/localeConfigs';

export const SuperAccountErrorModal = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SuperAccountModalDescription>
        {t('superAccountModal.errorModal.description')}
      </SuperAccountModalDescription>

      <SuperAccountModalLinksWrapper>
        <ExternalLink href={SUBMIT_TICKET}>
          {t('superAccountModal.errorModal.emailButton')}
        </ExternalLink>

        <ExternalLink href={`tel:${t('support.phoneNumber')}`}>
          {t('support.phoneNumber')}
        </ExternalLink>
      </SuperAccountModalLinksWrapper>
    </div>
  );
};
