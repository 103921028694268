import { useTranslation } from 'react-i18next';

import { SwitchButton, Switcher, SwitchWrapper } from './styles';

import { Icon } from 'components/elements/Icon';
import { OFFER_SOURCES } from 'pages/NewRewards/consts';
import { useRewardsCurrentTab } from 'pages/NewRewards/hooks/useRewardsCurrentTab';

// TODO: https://acornsau.atlassian.net/browse/RAIZ-7765
export const PaymentMethodsSwitcher = () => {
  const { t } = useTranslation();

  const { currentTab, setCurrentTab } = useRewardsCurrentTab();

  return (
    <SwitchWrapper>
      <Switcher onClick={setCurrentTab}>
        <SwitchButton isActive={currentTab === OFFER_SOURCES.card}>
          <Icon iconName="rewardInactive" size={14} />

          {t('newRewardsPage.cards.tabs.linkedCards')}
        </SwitchButton>

        <SwitchButton isActive={currentTab === OFFER_SOURCES.account}>
          <Icon iconName="rewardBank" size={14} />

          {t('newRewardsPage.cards.tabs.linkedAccounts')}
        </SwitchButton>
      </Switcher>
    </SwitchWrapper>
  );
};
