import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  KidCardOpenedLabel,
  KidCardOpenedPortfolioHeader,
  KidCardOpenedLink,
  Frequency,
  KidCardOpenedValue,
} from './styles';

import { useInvestmentFrequencyText } from 'pages/RaizKidsEdit/hooks/useInvestmentFrequencyText';
import { formatNumber } from 'utils/formatters/formatNumber';

function KidCardOpenedPortfolio({ kid }) {
  const { t } = useTranslation();

  const { amount } = kid?.recurring || {};

  const frequencyText = useInvestmentFrequencyText({
    recurring: kid?.recurring,
  });

  return (
    <KidCardOpenedPortfolioHeader>
      <KidCardOpenedLabel>
        {t('raizKidsPage.cards.openedPortfolio.title')}
      </KidCardOpenedLabel>

      {kid.recurring ? (
        <KidCardOpenedValue>
          {formatNumber({
            value: amount,
          })}

          <Frequency>{frequencyText}</Frequency>
        </KidCardOpenedValue>
      ) : (
        <KidCardOpenedLink to={`/raiz-kids/edit/recurring/${kid.id}`}>
          {t('raizKidsPage.cards.openedPortfolio.button')}
        </KidCardOpenedLink>
      )}
    </KidCardOpenedPortfolioHeader>
  );
}

export default KidCardOpenedPortfolio;

KidCardOpenedPortfolio.defaultProps = {
  kid: {},
};

KidCardOpenedPortfolio.propTypes = {
  kid: PropTypes.shape({
    id: PropTypes.string,
    recurring: PropTypes.shape({
      frequency: PropTypes.string,
      amount: PropTypes.number,
      day: PropTypes.string,
    }),
  }),
};
