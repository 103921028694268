import { useTranslation, Trans } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import {
  SignInButton,
  Footer,
  InputMaskWrapper,
  Secured,
  SubTitle,
  Title,
  VisaLogo,
  CloseButtonWrapper,
} from './styles';

import { IBank } from 'store/banks';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { Icon } from 'components/elements/Icon';
import { IconButton } from 'components/elements/IconButton';
import { InputMaskField } from 'components/form/inputMask';
import { PRIVACY_POLICY } from 'constants/localeConfigs';
import { useCreateCardsMutation } from 'store/cards/api';
import LockPng from 'assets/images/icons/ic_lock_outline.png';
import { BaseModalWrapper } from 'components/elements/ModalWrapper';

interface IVisaCardModal {
  isOpen: boolean;
  bank: IBank;
  onRequestClose: () => void;
}

export const CARD_NUMBER_MASK = '4999 9999 9999 9999';

export const VisaCardModal = ({
  isOpen,
  onRequestClose,
  bank,
}: IVisaCardModal) => {
  const { t } = useTranslation();
  const [addVisa, { isLoading }] = useCreateCardsMutation();

  const form = useForm({
    defaultValues: {
      number: '',
    },
  });

  const { handleSubmit } = form;

  return (
    <BaseModalWrapper
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(({ number }) => {
            const value = number.replaceAll(/\s/g, '');

            addVisa({
              number: value,
              bankId: bank.id,
            })
              .unwrap()
              .then(onRequestClose);
          })}
        >
          <Title>{t('settingsPage.visaCardModal.title')}</Title>

          <CloseButtonWrapper>
            <IconButton iconName="close" onClick={onRequestClose} size={18} />
          </CloseButtonWrapper>

          <SubTitle>{t('settingsPage.visaCardModal.subTitle')}</SubTitle>

          <Secured>
            <Icon size={24} iconName={LockPng} />

            {t('settingsPage.secured')}
          </Secured>

          <VisaLogo />

          <InputMaskWrapper>
            <InputMaskField
              name="number"
              label={t('fields.cardNumber.label')}
              inputMaskProps={{
                mask: CARD_NUMBER_MASK,
                maskChar: '',
              }}
            />
          </InputMaskWrapper>

          <Footer>
            <Trans
              t={t}
              i18nKey="settingsPage.visaCardModal.footer"
              components={{
                link: (
                  <ExternalLink
                    title={t('common.privacyPolicy')}
                    href={PRIVACY_POLICY}
                  />
                ),
              }}
            />
          </Footer>

          <SignInButton
            type="submit"
            label={t('settingsPage.signIn')}
            isLoading={isLoading}
          />
        </form>
      </FormProvider>
    </BaseModalWrapper>
  );
};
