import {
  createSearchParams,
  generatePath,
  useSearchParams,
  matchRoutes,
  useLocation,
} from 'react-router-dom';

import {
  PORTFOLIO_PLANS,
  PORTFOLIO_SEARCH_PARAMS,
} from 'pages/Portfolio/consts';
import { PATHS } from 'constants/paths';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsSuperPlusPortfolioEnabled } from 'store/firebaseConfig/selectors';
import { selectIsBasicPortfolioActive } from 'store/portfolio/selectors';

export const usePortfolioPath = () => {
  const location = useLocation();

  const routes = [
    ...[{ path: PATHS.portfolio.index }],
    ...Object.values(PATHS.portfolio.customization).map((path) => ({
      path,
    })),
  ];

  const isSuperPlusPortfolioEnabled = useAppSelector(
    selectIsSuperPlusPortfolioEnabled,
  );

  const isBasicPortfolioActive = useAppSelector(
    selectIsBasicPortfolioActive({
      isSuper: true,
    }),
  );

  const portfolioPlusPath = generatePath(
    `${PATHS.portfolio.index}?${createSearchParams({
      [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: isBasicPortfolioActive
        ? PORTFOLIO_PLANS.basic
        : PORTFOLIO_PLANS.pro,
      [PORTFOLIO_SEARCH_PARAMS.isSuper]: 'true',
    }).toString()}`,
  );

  const portfolioPath = isSuperPlusPortfolioEnabled
    ? portfolioPlusPath
    : PATHS.oldPortfolio.super;

  const [searchParams] = useSearchParams();

  const isSuperPortfolioActive = searchParams.get(
    PORTFOLIO_SEARCH_PARAMS.isSuper,
  );

  const isSuperPortfolioPath = !!matchRoutes(routes, location);

  return { portfolioPath, isSuperPortfolioActive, isSuperPortfolioPath };
};
