// Partial State

export const INITIAL = {
  isLoading: false,
  isSettled: false,
  isSuccess: false,
  isError: false,
  error: null,
  data: null,
};

export const LOADING = {
  isLoading: true,
  isSettled: false,
  isSuccess: false,
  isError: false,
};

export const SUCCESS = {
  isLoading: false,
  isSettled: true,
  isSuccess: true,
  isError: false,
};

export const ERROR = {
  isLoading: false,
  isSettled: true,
  isSuccess: false,
  isError: true,
};

// Action Type

export const GET_REFERRALS_SUMMARY_ERROR = 'GET_REFERRALS_SUMMARY_ERROR';
export const GET_REFERRALS_SUMMARY_LOADING = 'GET_REFERRALS_SUMMARY_LOADING';
export const GET_REFERRALS_SUMMARY_SUCCESS = 'GET_REFERRALS_SUMMARY_SUCCESS';

export const GET_REFERRALS_LIST_ERROR = 'GET_REFERRALS_LIST_ERROR';
export const GET_REFERRALS_LIST_LOADING = 'GET_REFERRALS_LIST_LOADING';
export const GET_REFERRALS_LIST_SUCCESS = 'GET_REFERRALS_LIST_SUCCESS';

export const RESET_REFERRALS = 'RESET_REFERRALS';
export const RESET_REFERRALS_SUMMARY = 'RESET_REFERRALS_SUMMARY';
export const RESET_REFERRALS_LIST = 'RESET_REFERRALS_LIST';

// Api Config

export const REFERRALS_LIST_LIMIT = 30;
