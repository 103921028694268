import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { HOME_HISTORY_TAB } from 'constants/comonConstants';
import '../../../styles/layout/logout.scss';

export default function RecurringNoteModal({
  isOpenNoteModal,
  showLoader,
  toggleNoteModal,
  changeState,
  isSuper,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      shouldCloseOnOverlayClick
      contentLabel="time-investment-modal"
      isOpen={isOpenNoteModal && !showLoader}
      onRequestClose={() => toggleNoteModal(false)}
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-title">
        {t('recurringNoteModal.title')}
      </div>

      <div className="time-investment__modal-content">
        {t('recurringNoteModal.description')}
      </div>

      <div className="time-investment__modal-footer">
        <DeprecatedButton
          handleClick={() => toggleNoteModal(false)}
          buttonClass="button__basic-transparent -black"
          title={t('recurringNoteModal.button.later')}
        />

        <Link
          onClick={() =>
            changeState(isSuper ? '/super/history' : HOME_HISTORY_TAB)
          }
          to={isSuper ? '/super/history' : HOME_HISTORY_TAB}
          className="button__basic-fill -green -link"
        >
          {t('recurringNoteModal.viewHistory')}
        </Link>
      </div>
    </Modal>
  );
}

RecurringNoteModal.propTypes = {
  isOpenNoteModal: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  toggleNoteModal: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired,
  isSuper: PropTypes.bool.isRequired,
};
