import { SUPER_PORTFOLIOS_FOR_AGGRESSIVE_PORTFOLIO } from '../consts';

import { IPortfolio } from 'store/user/types';

interface IGetIsModeratelyAggressivePortfolioProps {
  portfolio?: IPortfolio;
}

export const getIsModeratelyAggressivePortfolio = ({
  portfolio,
}: IGetIsModeratelyAggressivePortfolioProps) =>
  portfolio?.name_key ===
    SUPER_PORTFOLIOS_FOR_AGGRESSIVE_PORTFOLIO.property_30 ||
  portfolio?.name_key === SUPER_PORTFOLIOS_FOR_AGGRESSIVE_PORTFOLIO.property_10;
