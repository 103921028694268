import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import RaizSvg from 'assets/images/icons/raiz.svg';

interface ITransactionItem {
  background: string;
}

interface IRoundUpsAmountChange {
  isRoundUpsTemplateDark: boolean;
}

interface IRoundUpsSwitcherItem {
  isActive?: boolean;
}

interface IContentButton {
  isWhite?: boolean;
}

type IRoundUpsAmountOriginalChange = IRoundUpsAmountChange;
type IRoundUpsInfoAmount = IRoundUpsAmountChange;
type IRoundUpsInfoDescription = IRoundUpsAmountChange;
type IMultiplier = IRoundUpsAmountChange;
type IRoundUpsMultiplierWrapper = IRoundUpsAmountChange;
type IAddTransactionWrapper = IRoundUpsAmountChange;

export const RoundUpsTransactionItem = styled.div<ITransactionItem>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brightGray};
  transition: all 0.125s;
  ${({ background }) => background && `background: ${background};`}}
`;

export const RoundUpsActionsWrapper = styled.div`
  display: flex;
`;

export const RoundUpsMultiplierWrapper = styled.div<IRoundUpsMultiplierWrapper>`
  width: 52px;
  height: 28px;
  border-radius: 16px;
  background: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark
      ? theme.colors.bluishGreenTransparent
      : theme.colors.whiteTransparentMiddle};
  text-align: center;
  margin: 4px 8px;
`;

export const Multiplier = styled.span<IMultiplier>`
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark ? theme.colors.niagara : theme.colors.white};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
`;

export const RoundUpsAmountWrapper = styled.div`
  display: flex;
`;
export const RoundUpsAmountChange = styled.div<IRoundUpsAmountChange>`
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark ? theme.colors.niagara : theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  padding-bottom: 5px;
`;
export const RoundUpsAmountOriginalChange = styled.div<IRoundUpsAmountOriginalChange>`
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark
      ? theme.colors.mirage
      : theme.colors.whiteTransparentLight};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  padding: 4px 10px;
`;

export const RoundUpsInfo = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
`;

export const RoundUpsInfoAmount = styled.div<IRoundUpsInfoAmount>`
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark ? theme.colors.mirage : theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const RoundUpsInfoDescription = styled.div<IRoundUpsInfoDescription>`
  margin-left: 5px;
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark
      ? theme.colors.grey
      : theme.colors.whiteTransparentLight};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
`;

export const RoundUpsContentDate = styled.div`
  background-color: ${({ theme }) => theme.colors.athensGray};
  padding: 4px 20px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
`;

export const AddTransactionWrapper = styled.div<IAddTransactionWrapper>`
  min-width: 36px;
  height: 36px;
  border: 2px solid
    ${({ theme, isRoundUpsTemplateDark }) =>
      isRoundUpsTemplateDark ? theme.colors.niagaraLight : theme.colors.white};
  border-radius: 100%;
  display: flex;
  line-height: ${({ theme }) => theme.lineHeights.number.lg};
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  color: ${({ theme, isRoundUpsTemplateDark }) =>
    isRoundUpsTemplateDark ? theme.colors.niagaraLight : theme.colors.white};
  position: relative;
  &.checkbox__check {
    color: ${({ theme, isRoundUpsTemplateDark }) =>
      isRoundUpsTemplateDark
        ? theme.colors.niagaraLight
        : theme.colors.transparent} !important;
    border: 2px solid
      ${({ theme, isRoundUpsTemplateDark }) =>
        isRoundUpsTemplateDark
          ? theme.colors.niagaraLight
          : theme.colors.white} !important;
  }
`;

export const AddTransactionCheckbox = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${({ theme }) => theme.opacity.transparent};
  cursor: pointer;
`;

export const SwitcherWrapper = styled.div`
  ${({ theme }) => `
    display: inline-flex;
    border-radius: 18px;
    background-color: ${theme.colors.blackTransparent};
    width: auto;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['4sm']};;
    font-weight: ${theme.fontWeights.semibold};;
    padding: 3px;
    margin-bottom: 0;
    `}
`;

export const SwitcherItem = styled(Button)<IRoundUpsSwitcherItem>`
  ${({ theme }) => `
    padding: 4px 23px;
    background: ${theme.colors.transparent};
    font-weight: inherit;
    color: inherit;
    height: auto;
    min-width: 162px;

    &:hover {
      cursor: pointer;
    }
    `}

  ${({ theme, isActive }) =>
    isActive &&
    `
      border-radius: 15px;
      background-color: ${theme.colors.white};
      color: ${theme.colors.mirage};
    `}
`;

export const CheckboxRaiz = styled.div`
  ${({ theme }) => `
    width: 36px;
    min-width: 36px;
    height: 36px;
    background: ${
      theme.colors.charcoalGray
    } url(${`${RaizSvg}`}) no-repeat 5px 9px;
    background-size: 22px;
    border-radius: 100%;
  `}
`;

export const ButtonLabel = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['4sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['3sm']};
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    font-weight: ${theme.fontWeights.normal};
    margin-bottom: 6px;
    opacity: 0.72;
  `}
`;

export const ButtonContainerItem = styled.button`
  ${({ theme }) => `
    color: ${theme.colors.white};
    background-color: initial;
    height: 109px;
    width: 115px;
    :hover {
      cursor: default;
    }
  `}
`;

export const ButtonSubValue = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes['3md']};
    letter-spacing: ${theme.letterSpacing.wide};
  `}
`;

export const ButtonSubLabel = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    font-weight: ${theme.fontWeights.normal};
    font-size: ${theme.fontSizes['3sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    padding: 0 4px;
  `}
`;

export const ContentButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
`;

export const ContentButton = styled(Button)<IContentButton>`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['5sm']};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length.md};
    margin-left: 20px;
    padding-left: 50px;
    padding-right: 50px;
    width: auto;
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.niagaraHover};
    }
  `}

  ${({ theme, isWhite }) =>
    isWhite &&
    `
      background-color: ${theme.colors.white};
      color: ${theme.colors.codGray};
      &:hover {
        background-color: ${theme.colors.white};
      }
    `}
`;
