import styled from '@emotion/styled';

export const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  white-space: pre-wrap;
`;

export const SupperAnnuationCheckBoxWrapper = styled.div`
  padding-top: 25px;
`;
