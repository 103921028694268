// RAIZ-4116: adjust empty line - enable once file is refactored
/* eslint-disable react/jsx-newline  */

import { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/logout.scss';

class SuperRegistrationModal extends Component {
  render() {
    const { t } = this.props;

    const linkToInsurance =
      'https://www.ato.gov.au/individuals/super/growing-and-keeping-track-of-your-super/keeping-track-of-your-super/';

    const {
      isShowInsuranceModal,
      errorMessage,
      handleCloseModal,
      handleSubmit,
      isShowProvideTFNModal,
    } = this.props;

    return (
      <Modal
        hasBackdrop={false}
        isVisible={false}
        isOpen
        contentLabel="modal-alert"
        shouldCloseOnOverlayClick
        className={
          isShowInsuranceModal || isShowProvideTFNModal
            ? 'modal-basic bank__alert insurance-modal'
            : 'modal-basic bank__alert'
        }
      >
        <div className="modal-alert-content">
          <div className="alert-text">
            {isShowInsuranceModal && (
              <div>
                <div className="title">
                  {t('superPage.superRegistrationModal.byOpening')}
                </div>

                <ul>
                  <li>{t('superPage.superRegistrationModal.iveRead')}</li>

                  <li>
                    {t('superPage.superRegistrationModal.continueToBeDeducted')}
                  </li>

                  <li>
                    {t('superPage.superRegistrationModal.haveSufficient')}
                  </li>
                </ul>

                <div className="title">
                  {t('superPage.superRegistrationModal.choosine')}
                </div>

                <ul>
                  <li>{t('superPage.superRegistrationModal.myAccount')}</li>

                  <li>{t('superPage.superRegistrationModal.iAmBelow')}</li>

                  <li>
                    {t('superPage.superRegistrationModal.mySuperAccount')}
                  </li>
                </ul>

                <div className="title">
                  {t('superPage.superRegistrationModal.iUnderstandThat')}
                </div>

                <div>
                  {t('superPage.superRegistrationModal.forInformation')}{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={linkToInsurance}
                  >
                    {linkToInsurance}
                  </a>
                </div>
              </div>
            )}

            {isShowProvideTFNModal && (
              <div>
                <p>{t('superPage.superRegistrationModal.listOne.title')}</p>

                <ul>
                  {[...Array(10)].map((_, index) => (
                    <li key={`${index + 1}`}>
                      {t(
                        `superPage.superRegistrationModal.listOne.${index + 1}`,
                      )}
                    </li>
                  ))}
                </ul>

                <p>{t('superPage.superRegistrationModal.listTwo.title')}</p>

                <ul>
                  {[...Array(4)].map((_, index) => (
                    <li key={`${index + 1}`}>
                      {t(
                        `superPage.superRegistrationModal.listTwo.${index + 1}`,
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {!isShowInsuranceModal && !isShowProvideTFNModal && errorMessage}
          </div>

          {(isShowInsuranceModal || isShowProvideTFNModal) && (
            <div className="error-buttons">
              <DeprecatedButton
                title={<Trans i18nKey="common.cancel" t={t} />}
                type="button"
                buttonClass="cancel-btn"
                handleClick={handleCloseModal}
              />

              <DeprecatedButton
                title={<Trans i18nKey="common.ok" t={t} />}
                type="button"
                handleClick={(e) => handleSubmit(e, true)}
              />
            </div>
          )}

          {!isShowInsuranceModal && !isShowProvideTFNModal && (
            <div className="error-buttons">
              <DeprecatedButton
                title={<Trans i18nKey="common.ok" t={t} />}
                type="button"
                handleClick={handleCloseModal}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(SuperRegistrationModal);

SuperRegistrationModal.propTypes = {
  isShowInsuranceModal: PropTypes.bool.isRequired,
  isShowProvideTFNModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
