import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';

import '../../../styles/elements/rangeslider.scss';
import {
  BOTTOM_ROUNDUP_TRESHOLD,
  TOP_ROUNDUP_TRESHOLD,
  ROUND_UP_MARKS,
} from '../../../constants/comonConstants';
import { formatRoundUpValue } from '../utils/utils';

function RoundUpsSlider({ defaultAmount, handleChangeDefaultAmount }) {
  const countrySettings = {
    max: 1,
    step: 0.01,
  };

  return (
    <div
      className={`range-slider
        ${defaultAmount > TOP_ROUNDUP_TRESHOLD ? '-end-of-line' : ''}
        ${defaultAmount < BOTTOM_ROUNDUP_TRESHOLD ? '-start-of-line' : ''}`}
    >
      <Slider
        min={0}
        max={countrySettings.max}
        value={defaultAmount}
        valueLabelFormat={formatRoundUpValue}
        onChange={(_, value) => handleChangeDefaultAmount(value)}
        aria-labelledby="discrete-slider-always"
        step={countrySettings.step}
        marks={ROUND_UP_MARKS}
        valueLabelDisplay="on"
      />
    </div>
  );
}

RoundUpsSlider.defaultProps = {
  defaultAmount: null,
  handleChangeDefaultAmount: () => {},
};

RoundUpsSlider.propTypes = {
  defaultAmount: PropTypes.number,
  handleChangeDefaultAmount: PropTypes.func,
};

export default RoundUpsSlider;
