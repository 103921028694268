import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { Button } from 'components/elements/Button';
import i18next from 'i18n/index';

function RecurringModal({
  isOpen,
  onRequestClose,
  handleClick,
  modalTitle,
  modalContent,
  modalFooter,
  modalClassName,
  modalFooterClassName,
  buttonTitle,
  customButton,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className={`modal-basic time-investment__modal-quick ${modalClassName}`}
    >
      {modalTitle && (
        <div className="time-investment__modal-title">{modalTitle}</div>
      )}

      {modalContent && (
        <div className="time-investment__modal-content">{modalContent}</div>
      )}

      <div className={`time-investment__modal-footer ${modalFooterClassName}`}>
        {modalFooter}

        {customButton || <Button label={buttonTitle} onClick={handleClick} />}
      </div>
    </Modal>
  );
}

RecurringModal.defaultProps = {
  onRequestClose: () => {},
  modalClassName: '',
  modalFooterClassName: '',
  modalFooter: null,
  modalTitle: null,
  modalContent: null,
  customButton: null,
  buttonTitle: i18next.t('common.ok'),
};

RecurringModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
  modalClassName: PropTypes.string,
  modalFooterClassName: PropTypes.string,
  modalFooter: PropTypes.node,
  modalTitle: PropTypes.node,
  modalContent: PropTypes.node,
  customButton: PropTypes.node,
  buttonTitle: PropTypes.node,
};

export default RecurringModal;
