import { useTranslation } from 'react-i18next';

import getMigrationFlow from 'utils/getMigrationFlow';
import { MIGRATION_FLOW } from 'constants/migrationConstants';
import { PATHS } from 'constants/paths';

const STEPS = {
  pds: 'pds',
  superPds: 'superPds',
  details: 'details',
  address: 'address',
  terms: 'terms',
  insurance: 'insurance',
} as const;

const MIGRATION_STEPS = {
  [MIGRATION_FLOW.superEstate]: [
    STEPS.pds,
    STEPS.superPds,
    STEPS.details,
    STEPS.address,
    STEPS.terms,
    STEPS.insurance,
  ],
  [MIGRATION_FLOW.standard]: [
    STEPS.pds,
    STEPS.details,
    STEPS.address,
    STEPS.terms,
    STEPS.insurance,
  ],
} as const;

export const useMigrationAccountSteps = () => {
  const { t } = useTranslation();

  const migrationFlow = getMigrationFlow();

  return MIGRATION_STEPS?.[migrationFlow].map((step) => ({
    label: t(`registrationPage.accountMigration.steps.${step}`),
    path: PATHS.migration.account[step],
  }));
};
