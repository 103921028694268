import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const TextFieldStyledWrapper = styled.div`
  position: relative;
`;

export const FormStyled = styled.form`
  animation-duration: 925ms;
  animation-name: fadein;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px 40px 35px;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: 15px;
  width: 100%;
`;

export const ErrorMessageText = styled.div<{ isError: boolean }>`
  animation-duration: 925ms;
  animation-name: fadein;
  color: ${({ theme }) => theme.colors.grenadier};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: ${({ isError }) => (isError ? '-10px' : '0')};
  margin-top: ${({ isError }) => (isError ? '6px' : '-5px')};
  text-align: left;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-bottom: 25px;
  text-align: center;
`;

export const BackButtonWrapper = styled.div`
  max-width: 150px;
  margin: 0 auto;
`;

export const BackButton = styled(Button)`
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const SuccessText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tightest};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  margin-bottom: 35px;
  margin-top: 80px;
  text-align: center;
`;
