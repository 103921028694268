import React from 'react';
import { useTranslation } from 'react-i18next';

import FundDetailsFooter from '../FundDetails/FundDetailsFooter';
import { useFundDetails } from '../FundDetails/hooks';

import ResidentialPropertyFundsSkeleton from './Skeleton/ResidentialPropertyFundsSkeleton';
import {
  ResidentialPropertyFundLabel,
  ResidentialPropertyFundTitle,
  ResidentialPropertyFundsListWrapper,
  ResidentialPropertyFundsWrapper,
  ResidentialPropertyFundHeaderImage,
} from './styles';
import ResidentialPropertyFundItem from './ResidentialPropertyFundItem';
import { useResidentialPropertyFunds } from './hooks';

export default function ResidentialPropertyFundsList() {
  const { t } = useTranslation();
  const { residentialPropertyFunds, isLoading } = useResidentialPropertyFunds();
  const { fundDetails } = useFundDetails();
  const { image_url } = fundDetails || {};

  return (
    <ResidentialPropertyFundsWrapper isFooterVisible>
      <ResidentialPropertyFundHeaderImage background={image_url} />

      <ResidentialPropertyFundTitle>
        {t('portfolioCustomization.residentialPropertyFund.title.regular')}
      </ResidentialPropertyFundTitle>

      <ResidentialPropertyFundLabel>
        {t('portfolioCustomization.residentialPropertyFund.label.regular')}
      </ResidentialPropertyFundLabel>

      <ResidentialPropertyFundsListWrapper>
        {isLoading ? (
          <ResidentialPropertyFundsSkeleton />
        ) : (
          residentialPropertyFunds?.map((fund) => (
            <ResidentialPropertyFundItem key={fund.id} fund={fund} />
          ))
        )}
      </ResidentialPropertyFundsListWrapper>

      <FundDetailsFooter />
    </ResidentialPropertyFundsWrapper>
  );
}
