import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import {
  InvestingAccessFormWrapper,
  InvestmentPanelsWrapper,
  InvestmentAmount,
  InvestmentAmountTitle,
  InvestmentFrequency,
  FrequencyIcon,
  FrequencyTitle,
  FrequencyValue,
  FrequencyArrow,
  RecurringInvestment,
  CustomNumberFieldWrapper,
} from './styles';
import {
  RecurringInvestmentWrapper,
  RecurringInvestmentContent,
  RecurringInvestmentTitle,
} from './components/styles';
import InvestmentGoal from './InvestmentGoal';
import { useRecurringInvestmentForm } from './useRecurringInvestmentForm';
import { useRecurringInvestmentFrequency } from './utils';
import RecurringInvestmentRegistrationButtons from './components/RecurringInvestmentRegistrationButtons';
import RecurringInvestmentButtons from './components/RecurringInvestmentButtons';

import { PATHS } from 'constants/paths';
import { NumberField } from 'components/form/number/NumberField';
import FrequencyModal from 'components/layout/Modals/FrequencyModal';

export default function RecurringInvestmentForm() {
  const { t } = useTranslation();

  const isRegistrationPage = useMatch(
    PATHS.kids.registration.investment.recurring,
  );

  const {
    form,
    amount,
    startDate,
    frequencyDay,
    isFrequencyModalVisible,
    currentFrequency,
    onSubmit,
    isLoading,
    toggleFrequencyModal,
  } = useRecurringInvestmentForm();

  const { getValues } = form;

  const recurringInvestmentFrequency =
    useRecurringInvestmentFrequency(currentFrequency);

  return (
    <RecurringInvestmentWrapper>
      <FormProvider {...form}>
        <InvestingAccessFormWrapper onSubmit={onSubmit}>
          <RecurringInvestmentContent>
            <RecurringInvestmentTitle>
              {t('raizKidsEditPage.recurringInvestment')}
            </RecurringInvestmentTitle>

            <InvestmentPanelsWrapper>
              <RecurringInvestment>
                <InvestmentAmount>
                  <InvestmentAmountTitle>
                    {t('raizKidsEditPage.recurringInvestmentForm.title')}
                  </InvestmentAmountTitle>

                  <NumberField
                    name="recurringValue"
                    CustomFieldWrapper={CustomNumberFieldWrapper}
                  />
                </InvestmentAmount>

                <InvestmentFrequency
                  onClick={() => {
                    toggleFrequencyModal(!isFrequencyModalVisible);
                  }}
                >
                  <FrequencyIcon />

                  <FrequencyTitle>
                    {t('raizKidsEditPage.recurringInvestmentForm.frequency')}
                  </FrequencyTitle>

                  <FrequencyValue>
                    {recurringInvestmentFrequency || t('fields.select.label')}

                    <FrequencyArrow />
                  </FrequencyValue>
                </InvestmentFrequency>
              </RecurringInvestment>

              <InvestmentGoal />
            </InvestmentPanelsWrapper>

            {isRegistrationPage && (
              <RecurringInvestmentRegistrationButtons isLoading={isLoading} />
            )}

            {isFrequencyModalVisible && (
              // TODO: Refactoring FrequencyModal for Raiz Kids: https://acornsau.atlassian.net/browse/RAIZ-5752
              <FrequencyModal
                currentFrequency={currentFrequency}
                frequencyDay={frequencyDay}
                startDate={startDate}
                isOpenModal={isFrequencyModalVisible}
                toggleModal={toggleFrequencyModal}
                recurringValue={amount || getValues('recurringValue')}
              />
            )}
          </RecurringInvestmentContent>

          {!isRegistrationPage && (
            <RecurringInvestmentButtons
              recurringInvestmentFrequency={recurringInvestmentFrequency}
            />
          )}
        </InvestingAccessFormWrapper>
      </FormProvider>
    </RecurringInvestmentWrapper>
  );
}
