import { IRootFundingAccountState } from './types';

export const selectIsAddCardModalErrorOpen = (
  state: IRootFundingAccountState,
) => state.fundingAccount.isShowAddCardModalError;

export const selectIsBankAccountLinkingStep = (
  state: IRootFundingAccountState,
) => state.fundingAccount.isBankAccountLinkingStep;

export const selectConnectionStatusData = (state: IRootFundingAccountState) =>
  state.fundingAccount.connectionStatusData;

export const selectWithdrawalAccount = (state: IRootFundingAccountState) =>
  state.fundingAccount.withdrawalAccount;

export const selectMonitoredInstitutions = (state: IRootFundingAccountState) =>
  state.fundingAccount.monitoredInstitutions;

export const selectBankAccountData = (state: IRootFundingAccountState) =>
  state.fundingAccount.bankAccountData;

export const selectCurrentBank = (state: IRootFundingAccountState) =>
  Object.keys(state.fundingAccount.currentBank || {}).length > 0
    ? state.fundingAccount.currentBank
    : state.fundingAccount.monitoredInstitutions?.[0];

export const selectFastlinkFlow = (state: IRootFundingAccountState) =>
  state.fundingAccount.fastlinkFlow;

export const selectIsLinkCardLoading = (state: IRootFundingAccountState) =>
  state.fundingAccount.isLinkCardLoading;
