import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MY_APP } from '../../../constants/localeConfigs';
import { INVESTMENTS_REGISTRATION } from '../../../constants/comonConstants';

import Investment from './Investment';
import ProgressBar from './ProgressBar';

function Investments({
  handleSubmit,
  stepName,
  errorMessage,
  handleClick,
  showActiveInput,
  goBack,
  showLoader,
  isShowErrorInvestingModal,
  handleCloseModal,
  compleeteRegistration,
  showStepAccountError,
  linkFunding,
  otherInvestmentValue,
}) {
  let question = '';
  let stepClass = 'step-one';
  let contentFillTitle = '';
  const { t } = useTranslation();

  switch (stepName) {
    case 'investment':
      stepClass = 'step-investments form-investment';

      contentFillTitle = t('registrationPage.investment.title');

      question = (
        <Investment
          handleSubmit={handleSubmit}
          showLoader={showLoader}
          errorMessage={errorMessage}
          handleClick={handleClick}
          showActiveInput={showActiveInput}
          goBack={goBack}
          showStepAccountError={showStepAccountError}
          isShowErrorInvestingModal={isShowErrorInvestingModal}
          handleCloseModal={handleCloseModal}
          compleeteRegistration={compleeteRegistration}
          linkFunding={linkFunding}
          otherInvestmentValue={otherInvestmentValue}
        />
      );

      break;
    default:
  }

  return (
    <div className="registration-page">
      <div className={stepClass}>
        <div className="left-part">
          <div className="part-content">
            <div className="logo" />

            <div className="title" />

            <div className={`${MY_APP ? '-my-illustration' : ''} body-text`} />

            <div className="bottom-text">{t('registrationPage.noMinimum')}</div>
          </div>
        </div>

        <div className="right-part">
          <div>
            <ProgressBar activeStep={INVESTMENTS_REGISTRATION} />

            <div className="content-fill">
              <h1>{contentFillTitle}</h1>
            </div>
          </div>

          <div className="bottom-part">{question}</div>
        </div>
      </div>
    </div>
  );
}

export default Investments;

Investments.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  stepName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  handleClick: PropTypes.func,
  showLoader: PropTypes.bool,
  showStepAccountError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showActiveInput: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  isShowErrorInvestingModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  compleeteRegistration: PropTypes.func.isRequired,
  linkFunding: PropTypes.func.isRequired,
  otherInvestmentValue: PropTypes.string,
};

Investments.defaultProps = {
  errorMessage: '',
  handleClick: {},
  showLoader: false,
  showStepAccountError: false,
  showActiveInput: null,
  otherInvestmentValue: '',
};
