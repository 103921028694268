import { DEFAULT_BANK_PERPAGE } from './const';
import type { IBankParams, IBanksResponse } from './types';

import { API_TAGS } from 'store/consts';
import { api } from 'store/api';
import { getBanksSuccess } from 'store/fundingAccount/actions';

export const banksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBanks: build.query<IBanksResponse, IBankParams | void>({
      query: (params = {}) => ({
        url: '/v1/banks',
        method: 'GET',
        params: {
          limit: DEFAULT_BANK_PERPAGE,
          offset: 1,
          purpose: 'spending',
          ...params,
        },
      }),
      providesTags: [API_TAGS.banks],
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(getBanksSuccess(data));
        } catch {
          //
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetBanksQuery } = banksApi;

export const {
  endpoints: { getBanks },
  util,
} = banksApi;

export const { resetApiState } = util;

export const { initiate, useQueryState } = getBanks;

export const useBankQueryState = useQueryState;
