export const PORTFOLIO_PLANS = {
  basic: 'basic',
  pro: 'pro',
};

export const PORTFOLIO_TYPES = {
  moderate: 'moderate',
  conservative: 'conservative',
  aggressive: 'aggressive',
  moderatelyConservative: 'moderately_conservative',
  moderatelyAggressive: 'moderately_aggressive',
  emerald: 'emerald',
  sapphire: 'sapphire',
  property: 'property',
};

export const PORTFOLIO_SEARCH_PARAMS = {
  portfolioType: 'portfolioType',
  portfolioPlan: 'portfolioPlan',
  childId: 'childId',
  isSuper: 'isSuper',
};

export const DEFAULT_PORTFOLIO_SEARCH_PARAMS = {
  [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: PORTFOLIO_PLANS.basic,
};

export const PORTFOLIO_GRADIENTS = [
  '136.81deg, #54606D -0.79%, #6D7680 44.91%, #1D2126 101.49%',
  '138.82deg, #667789 -0.82%, #8A99AA 43.56%, #141517 97.08%',
  '137.15deg, #3F6289 -0.8%, #16181B 100%',
  '137.27deg, #223568 0.03%, #09083E 100%',
  '138.82deg, #7593B1 -0.82%, #365B7E 97.08%',
  '315.52deg, #111577 -0.48%, #5B7EE2 100.45%',
  '137.27deg, #1DB9EC 0.03%, #0E13AC 100%',
  '137.27deg, #358FFF 0.03%, #8103C5 100%',
  '167.87deg, #EA5F52 2.24%, #DA5298 55.05%, #6253C1 113.56%',
  '130.83deg, #E79B55 7.06%, #8779EC 51.67%, #079EDC 100%',
  '137.27deg, #DB2F60 0.03%, #0E13AC 100%',
  '146.09deg, #EFC129 17.37%, #ED2F5B 94.06%',
  '137.15deg, #FF498C -0.8%, #8B0056 100%',
  '137.27deg, #FFCF43 0.03%, #BC5A00 100%',
  '137.27deg, #C8FF85 0.03%, #07843A 100%',
];

export const DEFAULT_PRO_PORTFOLIO_COLOR = {
  start: '#8B9FB2',
  end: '#1D2126',
};

export const SAFE_ALLOCATION_THRESHOLD = 25;

export const IS_PLUS_PORTFOLIO_HELP_CARD_OPEN =
  'IS_PLUS_PORTFOLIO_HELP_CARD_OPEN';

export const PORTFOLIO_INFORMATION_MODAL_TIMEOUT = 1000;

export const IS_SUPER_PLUS_PORTFOLIO_HELP_CARD_OPEN =
  'IS_SUPER_PLUS_PORTFOLIO_HELP_CARD_OPEN';
