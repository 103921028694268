import styled from '@emotion/styled';

import {
  GlobalInfoModalTitle,
  GlobalInfoModalWrapper,
} from 'components/modals/InfoModal/styles';
import { Button } from 'components/elements/Button';
import autoRenew from 'assets/images/icons/ic_autorenew.svg';
import infoIcon from 'assets/images/icons/info_icon.svg';

export const RoundUpsModalWrapper = styled(GlobalInfoModalWrapper)`
  margin: 50px auto;
  flex-direction: column;
  padding: 25px 40px 32px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const RoundUpsModalTitle = styled(GlobalInfoModalTitle)`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  margin-bottom: 10px;
`;

export const RoundUpsRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-bottom: 12px;
  padding-bottom: 12px;
`;

export const RoundUpsRowTitle = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const AutoRoundUpsIcon = styled.div`
  background-image: url(${`${autoRenew}`});
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const RoundUpsRowDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  color: ${({ theme }) => theme.colors.topaz};
  padding-top: 4px;
`;

export const SmartRoundUpsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-bottom: 12px;
  padding: 20px 0 12px;
`;

export const SmartRoundUpsTitle = styled(RoundUpsRowTitle)`
  margin-bottom: 5px;
  justify-content: space-between;
`;

export const MultiplierRoundUpsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-bottom: 12px;
  padding-bottom: 12px;
`;

export const RoundUpsHelpButton = styled(Button)`
  background: url(${`${infoIcon}`}) transparent no-repeat 50%;
  background-size: contain;
  height: 24px;
  width: 24px;
`;

export const SmartRoundUpsSubTitle = styled(RoundUpsRowDescription)`
  margin-top: 12px;
`;

export const AvailableThresholdsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.alto};
  margin-top: 16px;
  border-radius: 18px;
  padding: 3px;
  display: flex;
`;

interface IAvailableThresholdsButton {
  isActive?: boolean;
}

export const AvailableThresholdsButton = styled(
  Button,
)<IAvailableThresholdsButton>`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.alto};
  height: 30px;
  width: 92px;
`;

export const MultiplierRoundUpsDescription = styled(RoundUpsRowDescription)`
  padding-right: 40px;
`;

interface IAvailableMultiplierWrapper {
  isActive?: boolean;
}
export const AvailableMultiplierWrapper = styled.div<IAvailableMultiplierWrapper>`
  border-radius: 18px;
  padding: 0 16px 4px 16px;
  display: flex;
  gap: 16px;
  opacity: ${({ isActive, theme }) =>
    isActive ? theme.opacity.opaque : theme.opacity.lightTransparent};
`;

export const AvailableMultiplierButton = styled(
  Button,
)<IAvailableThresholdsButton>`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.niagara : theme.colors.white};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.black};
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.niagara : theme.colors.lightSilver};
  width: 102px;
`;

export const MultiplierRoundUpsRow = styled(RoundUpsRow)`
  border-bottom: 0;
  padding-bottom: 8px;
`;

export const RoundUpsModalLoaderWrapper = styled.div`
  padding: 188px 0;
`;
