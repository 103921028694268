import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { Form } from 'react-final-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Time from '../../../components/elements/Time/Time';
import { REGISTRATION_SUPERFUND_MEMBERS } from '../../../store/user/consts';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import { MY_APP } from '../../../constants/localeConfigs';
import SelectFF from '../../../components/elements/SelectFF/SelectFF';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { EMAIL_REGEX } from 'constants/validation';

const onKeyDown = (e) => {
  e.stopPropagation();

  e.preventDefault();
};

const validate = (values, t) => {
  const errors = {};

  const required = t('fields.validation.required');

  if (!values.first_name) {
    errors.first_name = required;
  }

  if (!values.last_name) {
    errors.last_name = required;
  }

  if (!values.email) {
    errors.email = required;
  }

  const pattern = EMAIL_REGEX;

  if (values.email && !pattern.test(values.email)) {
    errors.email = t('fields.validation.invalid');
  }

  if (!values.city) {
    errors.city = required;
  }

  if (!values.postcode) {
    errors.postcode = required;
  }

  if (!values.street_name) {
    errors.street_name = required;
  }

  if (!values.street_number) {
    errors.street_number = required;
  }

  if (!values.state || values.state.length === 0) {
    errors.state = required;
  }

  return errors;
};

const states = MY_APP
  ? [
      { label: 'Johor', value: 'Johor' },
      { label: 'Kedah', value: 'Kedah' },
      { label: 'Kelantan', value: 'Kelantan' },
      { label: 'Melaka', value: 'Melaka' },
      { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
      { label: 'Perak', value: 'Perak' },
      { label: 'Perlis', value: 'Perlis' },
      { label: 'Pahang', value: 'Pahang' },
      { label: 'Pulau Pinang', value: 'Pulau Pinang' },
      { label: 'Putrajaya', value: 'Putrajaya' },
      { label: 'Sabah', value: 'Sabah' },
      { label: 'Sarawak', value: 'Sarawak' },
      { label: 'Selangor', value: 'Selangor' },
      { label: 'Terengganu', value: 'Terengganu' },
      {
        label: 'Wilayah Persekutuan Kuala Lumpur',
        value: 'Wilayah Persekutuan Kuala Lumpur',
      },
      {
        label: 'Wilayah Persekutuan Labuan',
        value: 'Wilayah Persekutuan Labuan',
      },
    ]
  : [
      { label: 'New South Wales', value: 'NSW' },
      { label: 'Queensland', value: 'QLD' },
      { label: 'Victoria', value: 'VIC' },
      { label: 'South Australia', value: 'SA' },
      { label: 'Northern Territory', value: 'NT' },
      { label: 'Tasmania', value: 'TAS' },
      { label: 'Australian Capital Territory', value: 'ACT' },
      { label: 'Western Australia', value: 'WA' },
    ];

const renderMembers = (
  member,
  key,
  removeMember,
  toggleAddEditForm,
  currentMemberCounter,
) => {
  const activeMember = member && key === currentMemberCounter;

  return (
    <div
      className={`grid-editable__container-item ${
        activeMember ? '-active' : ''
      }`}
      key={key}
    >
      <div className="grid-editable__container-top">
        <div className="grid-editable__container-title">
          {`Member/Director ${key + 1}`}
        </div>

        <div className="grid-editable__container-actions">
          <DeprecatedButton
            title=""
            buttonClass="button__basic-transparent -edit"
            handleClick={() => {
              toggleAddEditForm(true, member, key, true);
            }}
          />

          <DeprecatedButton
            title=""
            buttonClass="button__basic-transparent -remove"
            handleClick={() => {
              removeMember(key);
            }}
          />
        </div>
      </div>

      <div className="grid-editable__container-main">
        <div className="grid-editable__container-info -bold">{`${member.first_name} ${member.last_name}`}</div>

        <div className="grid-editable__container-info">{member.email}</div>

        <div className="grid-editable__container-info">
          <Time>{member.date_of_birth}</Time>
        </div>

        <div className="grid-editable__container-info">
          {`${member.street_number} ${member.street_name}`}
        </div>

        <div className="grid-editable__container-info">
          {`${member.city} ${member.state} ${member.postcode}`}
        </div>
      </div>
    </div>
  );
};

export default function SMSFMembers({
  handleSubmit,
  showLoader,
  smsfMembers,
  saveMemberToList,
  removeMember,
  toggleAddEditForm,
  showAddEditForm,
  currentMember,
  currentMemberCounter,
  birthDate,
  birthDateError,
  handleChangeBirthDate,
  onChangeState,
}) {
  const memberCounter =
    currentMember && currentMember.first_name
      ? currentMemberCounter
      : smsfMembers.length;

  const maxDate = dayjs().subtract(18, 'year').toDate();

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values, t)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) =>
            handleSubmit(
              e,
              values,
              form,
              hasValidationErrors,
              REGISTRATION_SUPERFUND_MEMBERS,
            )
          }
        >
          <div>
            <div>
              <div className="profile-content__container-member">
                <div className="grid-editable__container">
                  {smsfMembers && smsfMembers.length
                    ? smsfMembers.map((member, key) =>
                        renderMembers(
                          member,
                          key,
                          removeMember,
                          toggleAddEditForm,
                          currentMemberCounter,
                        ),
                      )
                    : ''}
                </div>

                {(!smsfMembers.length || showAddEditForm) && (
                  <div>
                    <div
                      className={`profile-content__container-member-name
                    ${smsfMembers && smsfMembers.length ? '-margin-top' : ''}`}
                    >
                      {`Member / Director ${memberCounter + 1}`}
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="first_name"
                        defaultValue={currentMember.first_name}
                        label={t('fields.firstName.label')}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="last_name"
                        defaultValue={currentMember.last_name}
                        label={t('fields.lastName.label')}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="email"
                        defaultValue={currentMember.email}
                        label={t('fields.email.label')}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <div
                        className="MuiFormControl-root MuiTextField-root without-underline MuiFormControl-fullWidth
                relative form-field birthday -with-space"
                      >
                        <label
                          className="MuiFormLabel-root MuiInputLabel-shrink"
                          htmlFor="establishment_date"
                        >
                          {t('fields.dateOfBirth.label')}
                        </label>

                        <div className=" relative form-field">
                          <DatePicker
                            maxDate={maxDate}
                            onCalendarOpen={() =>
                              handleChangeBirthDate(maxDate, true)
                            }
                            className="raiz-datepicker"
                            name="establishment_date"
                            value={dayjs(birthDate).isValid() ? birthDate : ''}
                            onChange={handleChangeBirthDate}
                            format="dd.MM.yyyy"
                            clearIcon={false}
                            locale="au"
                            calendarIcon={false}
                            onKeyDown={(e) => onKeyDown(e)}
                            onKeyPress={(e) => onKeyDown(e)}
                          />

                          {birthDateError && (
                            <div className="error-message">
                              {t('fields.validation.required')}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="street_number"
                        defaultValue={currentMember.street_number}
                        label={t('fields.streetNumber.label')}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="street_name"
                        defaultValue={currentMember.street_name}
                        label={t('fields.streetName.label')}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        name="city"
                        defaultValue={currentMember.city}
                        label={t('fields.suburb.label')}
                      />
                    </div>

                    <div className="select-first select-inside">
                      <div className="profile-content__container-label">
                        {t('fields.state.label')}
                      </div>

                      <SelectFF
                        name="state"
                        defaultValue={currentMember.state}
                        options={states}
                        onChange={onChangeState}
                      />
                    </div>

                    <div className="form-field__sub-input-link">
                      <FinalFormInput
                        maxLength="4"
                        name="postcode"
                        defaultValue={currentMember.postcode}
                        label={t('fields.postCode.label')}
                      />
                    </div>

                    <div>
                      <DeprecatedButton
                        title={t(
                          'registrationPage.smsfMembers.saveMemberDirector',
                        )}
                        buttonClass="button__basic-fill -green -exclude -save-member"
                        handleClick={() =>
                          saveMemberToList(
                            values,
                            currentMemberCounter,
                            form,
                            hasValidationErrors,
                          )
                        }
                      />

                      {smsfMembers.length > 0 && (
                        <DeprecatedButton
                          title={t('common.cancel')}
                          buttonClass="button__basic-transparent -black -exclude"
                          handleClick={() =>
                            toggleAddEditForm(false, {}, undefined, false)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}

                {smsfMembers.length &&
                !showAddEditForm &&
                smsfMembers.length < 4 ? (
                  <div>
                    <DeprecatedButton
                      title={t(
                        'registrationPage.smsfMembers.addAnotherMemberDirector',
                      )}
                      buttonClass="button__basic-wtbackground -exclude -another-member"
                      handleClick={() =>
                        toggleAddEditForm(true, currentMemberCounter)
                      }
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="buttons-part">
              {!showAddEditForm && smsfMembers.length > 0 ? (
                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    type="submit"
                    disabled={showAddEditForm}
                    title={t('common.next')}
                    buttonClass="button button--submit"
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
}

SMSFMembers.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  currentMember: PropTypes.objectOf(PropTypes.string),
  toggleAddEditForm: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  saveMemberToList: PropTypes.func.isRequired,
  showAddEditForm: PropTypes.bool,
  showLoader: PropTypes.bool,
  currentMemberCounter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  smsfMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  birthDateError: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
  ]).isRequired,
  birthDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  handleChangeBirthDate: PropTypes.func.isRequired,
};

SMSFMembers.defaultProps = {
  currentMemberCounter: false,
  showLoader: false,
  birthDate: '',
  currentMember: {},
  showAddEditForm: false,
};
