import 'styles/pages/settings.scss';
import { useTranslation } from 'react-i18next';

import { SUPER_FORMS_LINKS } from 'constants/superFormLinks';

export default function SuperFormsView() {
  const { t } = useTranslation();

  return (
    <div className="settings__content">
      <div className="profile-content settings__content-default-container settings__support">
        <div className="profile-content__header">
          <div className="profile-content__title">
            {t('superPage.superForms.superForms')}
          </div>
        </div>

        <div className="profile-content__container">
          {Object.entries(SUPER_FORMS_LINKS).map(([key, value]) => (
            <a
              key={key}
              href={value}
              className="page-menu__item"
              target="_blank"
              rel="noreferrer"
            >
              {t(`superPage.superForms.${key}`)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
