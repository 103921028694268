import React from 'react';
import Skeleton from 'react-skeleton-loader';

import {
  SkeletonRecurringInvestmentWrapper,
  SkeletonInvestmentAmount,
  SkeletonInvestmentAmountTitle,
  SkeletonInvestmentWrapper,
} from './styles';

function SkeletonRecurringInvestment() {
  return (
    <SkeletonRecurringInvestmentWrapper>
      {[...Array(2)].map(() => (
        <SkeletonInvestmentWrapper key={window.crypto.randomUUID()}>
          <SkeletonInvestmentAmountTitle>
            <Skeleton />
          </SkeletonInvestmentAmountTitle>

          <SkeletonInvestmentAmount>
            <Skeleton />
          </SkeletonInvestmentAmount>
        </SkeletonInvestmentWrapper>
      ))}
    </SkeletonRecurringInvestmentWrapper>
  );
}

export default SkeletonRecurringInvestment;
