import { useCallback } from 'react';
import { FormSpy, FormSpyProps, useField } from 'react-final-form';

import { getGenderFromMyKadNumber, getDobFromMyKadNumber } from '../utils';

import { GENDER_LIST } from 'constants/comonConstants';

type IFormValue = {
  my_kad: string;
  dob: string;
  gender_name: Record<string, string>[];
};

type IFormSpyProps = FormSpyProps<IFormValue>;

type IChangeHandler = NonNullable<IFormSpyProps['onChange']>;

export const MyKadFieldUpdater = () => {
  const dobField = useField('dob');
  const genderField = useField('gender_name');

  const handleChange = useCallback<IChangeHandler>(
    ({ values }) => {
      const { my_kad } = values;

      if (my_kad) {
        const gender = getGenderFromMyKadNumber(my_kad);
        const dob = getDobFromMyKadNumber(my_kad);

        if (values.dob !== dob.formatted) {
          dobField.input.onChange(dob.formatted);
        }

        if (values.gender_name[0].value !== gender) {
          genderField.input.onChange(
            GENDER_LIST.filter((object) => object.value === gender),
          );
        }
      }
    },
    [dobField.input, genderField.input],
  );

  return <FormSpy onChange={handleChange} subscription={{ values: true }} />;
};
