import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOffsetters } from 'store/rewards/actions';

export const useOffsetters = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getOffsetters()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  const { offsetters } = useSelector((state) => ({
    offsetters: state.rewards.offsetters || {},
  }));

  return {
    isLoading,
    offsetters,
  };
};
