import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { usePortfolioCustomizationRoute } from '../../hooks';

import { CustomizationTabButtonWrapper } from './styles';

import { useActiveTab } from 'hooks/useActiveTab';

export default function PortfolioCustomizationTabButton({ tab }) {
  const { t } = useTranslation();
  const { fundTag } = usePortfolioCustomizationRoute();
  const { checkIsActiveTab, setActiveTab } = useActiveTab();

  const isActive = checkIsActiveTab(tab);

  return (
    <CustomizationTabButtonWrapper
      isActive={isActive}
      onClick={() => {
        setActiveTab(tab);
      }}
    >
      {t(`portfolioCustomization.tabs.${fundTag}.${tab}`)}
    </CustomizationTabButtonWrapper>
  );
}

PortfolioCustomizationTabButton.propTypes = {
  tab: PropTypes.string.isRequired,
};
