import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { usePortfolioCustomizationRoute } from '../../../../hooks';
import { SEARCH_QUERY_PARAM } from '../consts';

import { getParsedFilters } from './utils/utils';
import { useFilters } from './useFilters';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { getFunds, setFilterModalVisible } from 'store/portfolio/actions';
import {
  selectFiltersCategories,
  selectFundSorting,
  selectPortfolioFundsTags,
} from 'store/portfolio/selectors';

export const useFundsFilter = () => {
  const dispatch = useDispatch();
  const { fundTag } = usePortfolioCustomizationRoute();

  const {
    filtersCategories,
    isFilterModalVisible,
    promotedFilters,
    sourceFiltersCategories,
  } = useAppSelector((state) => ({
    sourceFiltersCategories: selectPortfolioFundsTags(state),
    filtersCategories: selectFiltersCategories({ state, fundTag }),
    isFilterModalVisible: state.portfolio?.isFilterModalVisible || false,
    promotedFilters: selectFiltersCategories({
      state,
      fundTag,
      isPromoted: true,
    }),
  }));

  const toggleIsFilterModalVisible = () => {
    dispatch(setFilterModalVisible(!isFilterModalVisible));
  };

  const { selectedFilterCount } = useFilters();

  return {
    filtersCategories,
    isFilterModalVisible,
    toggleIsFilterModalVisible,
    selectedFilterCount,
    promotedFilters,
    sourceFiltersCategories,
  };
};

export const useFilterFundsActions = () => {
  const dispatch = useDispatch();
  const { fundTag } = usePortfolioCustomizationRoute();

  const { portfolioId, sorting, isLoading, filtersCategories } = useSelector(
    (state) => ({
      portfolioId: state.portfolio?.proPortfolio?.id,
      sorting: selectFundSorting(state),
      isLoading: state.portfolio?.isFundsLoading,
      filtersCategories: selectFiltersCategories({ state, fundTag }),
    }),
  );

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get(SEARCH_QUERY_PARAM);

  const { setFilter } = useFilters();

  const applyFilter = (filtersSearchParams) => {
    dispatch(
      getFunds({
        portfolioId,
        activeFundTag: fundTag,
        sorting,
        searchValue,
        searchParams: filtersSearchParams,
      }),
    );
  };

  const onFormSubmit = async (values) => {
    const parsedFilters = getParsedFilters({ values, filtersCategories });

    await setFilter({ parsedFilters }).then((filtersSearchParams) => {
      applyFilter(filtersSearchParams);
    });
  };

  const clearFilter = async () => {
    await setFilter({ parsedFilters: {} });

    dispatch(
      getFunds({
        portfolioId,
        activeFundTag: fundTag,
        searchValue,
      }),
    );
  };

  return {
    onFormSubmit,
    clearFilter,
    isLoading,
  };
};
