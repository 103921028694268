import React, { Component } from 'react';
import '../../../styles/elements/dots-loader.scss';
import PropTypes from 'prop-types';

export const DOTS_LOADER_ID = 'dots-loader';

/**
 * @deprecated need to use the new ../DotsLoader
 */

export default class DeprecatedDotsLoader extends Component {
  render() {
    const { additionalClass } = this.props;

    return (
      <div
        className={`dots-loader -${additionalClass}`}
        data-testid={DOTS_LOADER_ID}
      >
        <div className="spinner">
          <div className="bounce1" />

          <div className="bounce2" />

          <div className="bounce3" />
        </div>
      </div>
    );
  }
}

DeprecatedDotsLoader.defaultProps = {
  additionalClass: '',
};

DeprecatedDotsLoader.propTypes = {
  additionalClass: PropTypes.string,
};
