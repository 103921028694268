import dayjs from 'dayjs';

import { MYS_MY_KAD_DATE_FORMAT, MYS_DOB_SERVER_FORMAT } from './const';

import { FEMALE, MALE } from 'constants/comonConstants';
import { DATE_FORMAT_MYKAD, ISO_DATE_FORMAT } from 'config/date';

export const getGenderFromMyKadNumber = (myKadNumber) => {
  const genderNumber = myKadNumber.substring(13);

  return genderNumber % 2 === 0 ? FEMALE : MALE;
};

export const getDobFromMyKadNumber = (myKadNumber = '') => {
  const centuryInYears = 100;
  const myKadDateId = myKadNumber.substring(0, 6);

  const myKadDateFormated = dayjs(myKadDateId, DATE_FORMAT_MYKAD, true).format(
    ISO_DATE_FORMAT,
  );

  const isFutureDate = dayjs().isBefore(myKadDateFormated);

  const isoString = isFutureDate
    ? dayjs(myKadDateFormated)
        .subtract(centuryInYears, 'year')
        .format(ISO_DATE_FORMAT)
    : myKadDateFormated;

  const rawDayJs = dayjs(isoString, ISO_DATE_FORMAT, true);

  const isValid = rawDayJs.isValid();

  const formatted = isValid ? rawDayJs.format(MYS_MY_KAD_DATE_FORMAT) : '';

  const maxDate = dayjs().subtract(18, 'year');

  const isUnderAge = isValid ? rawDayJs.isAfter(maxDate, 'date') : false;

  return {
    formatted,
    isValid,
    isUnderAge,
  };
};

export const formatDobFromServer = (date) => {
  const rawDayJs = dayjs(date, MYS_DOB_SERVER_FORMAT, true);

  if (rawDayJs?.isValid()) return rawDayJs.format(MYS_MY_KAD_DATE_FORMAT);

  return '';
};
