import { useState } from 'react';
import { useNavigate } from 'react-router';

import { AU_APP } from 'constants/localeConfigs';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import { PATHS } from 'constants/paths';
import { savePortfolio } from 'store/user/actions';

export const useRecommendedPortfolioModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { recommended_allocation_profile_id, allocation_profile_id } =
    useAppSelector(selectUser);

  const [
    isRecommendedPortfolioModalVisible,
    setIsRecommendedPortfolioModalVisible,
  ] = useState(false);

  const handleOpenRecommendedPortfolioModal = (isOpen?: boolean) => {
    if (AU_APP) {
      return;
    }

    if (
      allocation_profile_id !== recommended_allocation_profile_id &&
      !isRecommendedPortfolioModalVisible
    ) {
      setIsRecommendedPortfolioModalVisible(true);
    } else {
      setIsRecommendedPortfolioModalVisible(!!isOpen);

      if (isOpen) {
        navigate(PATHS.root);
      }
    }
  };

  const updatePortfolio = () => {
    dispatch(
      savePortfolio({
        portfolioId: recommended_allocation_profile_id,
      }),
    );

    navigate(PATHS.root);
  };

  return {
    isRecommendedPortfolioModalVisible,
    handleOpenRecommendedPortfolioModal,
    updatePortfolio,
  };
};
