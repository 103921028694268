import { defineMessages } from 'react-intl';

import { LOGIN as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.privacy`]: 'Dasar Privasi',
  [`${idPrefix}.next`]: 'Seterusnya',
  [`${idPrefix}.register`]: 'Belum Mempunyai Akaun?',
  [`${idPrefix}.login`]: 'Log Masuk',
  [`${idPrefix}.raizCare`]: 'Raiz Care',
  [`${idPrefix}.forgot`]: 'Lupa Kata Laluan?',
  [`${idPrefix}.byCreating`]: 'Dengan membuka akaun, anda bersetuju dengan',
  [`${idPrefix}.and`]: 'dan',
  [`${idPrefix}.conditions`]: 'Terma & Syarat',
  [`${idPrefix}.tipsOnGrowing`]:
    'Daftar sekarang dan dapatkan bonus<br>untuk pelaburan pertama anda',
  [`${idPrefix}.readTerms`]: 'Rujuk Terma dan Syarat',
  [`${idPrefix}.executing`]:
    'Mempunyai pelan simpanan yang baik tidaklah mudah, apatah lagi untuk menempuhi ' +
    'cabaran dunia pelaburan.',
  [`${idPrefix}.executingDescription`]:
    'Menurut Agensi Kaunseling & Pengurusan Kredit (AKPK), hampir dua dari ' +
    'setiap sepuluh' +
    'pekerja dewasa di Malaysia gagal membuat simpanan. ' +
    '<br><br><strong>Mengapa?</strong><br><br> Cabaran seperti kos pelaburan yang tinggi seperti komisen, amaun ' +
    'minimum pelaburan yang tinggi ' +
    'beberapa halangan yang menghalang orang dari membina portfolio pelaburan yang efektif. Caj pembrokeran ' +
    'yang tinggi, ' +
    ' malah kekurangan ilmu tentang pelaburan – semua ini menghalang kita daripada mula melabur dari peringkat ' +
    'awal lagi.' +
    'Untuk berjaya mempunyai pelan simpanan yang baik, pakar kewangan menyarankan adalah lebih penting untuk ' +
    'menjadikan tabiat menyimpan sebagai sebahagian daripada gaya hidup kita ' +
    'Ini melibatkan tabiat menyimpan, walaupun dengan amaun yang kecil, secara kerap.' +
    'Contohnya mengetepikan sebahagian kecil daripada wang gaji bulanan, sentiasa memantau perbelanjaan ' +
    'dan sebagainya.',
  [`${idPrefix}.investSpareChange`]:
    'Labur duit baki dari pembelian harian secara automatic',
  [`${idPrefix}.investSpareChangeDescription`]:
    'Dengan Raiz, saya boleh menyimpan dan melabur dari ' +
    'serendah RM5!.<br><br>' +
    'Tanpa caj jualan mahupun pengeluaran, dan dengan hanya caj penyelenggaraan platform ' +
    'serendah RM1.50/sebulan, Raiz membuatkan kos untuk melabur ' +
    'tidak lagi mahal.',
  [`${idPrefix}.raizSolution`]:
    '3 sebab mudah unutk menyimpan dan melabur dengan Raiz:',
  [`${idPrefix}.takeaway`]: 'Kesimpulannya',
  [`${idPrefix}.getRaizNow`]: 'Daftar Dengan Raiz Sekarang',
  [`${idPrefix}.investAustraliaLimited`]:
    ' Aplikasi/laman web ini dikendalikan oleh Raiz Malaysia Sdn ' +
    'Bhd (No. Syarikat 201801021108) (1283127–W), sebuah entiti berlesen dibawah Suruhanjaya Sekuriti ' +
    'Malaysia (No. Lesen eCMSL/A0363/2020). Terma dan Syarat untuk untuk produk ini ada pada aplikasi dan lamab ' +
    'web Raiz. Anda perlu membaca dan mempertimbangkan Terma & Syarat dalam membuat keputusan sama ada ingin ' +
    'meneruskan penggunaan produk ini atau tidak. Risiko untuk melabur dalam produk ini dinyatakan sepenuhnya ' +
    'di dalam Terma & Syarat, termasuk risiko yang berkait rapat dengan pelaburan.',
  [`${idPrefix}.millennial`]: '"Strategi baru pelaburan golongan milenial."',
  [`${idPrefix}.useLettersCapitalNumbers`]:
    'Gunakan 8 atau lebih askara dengan gabungan nombor, huruf ' +
    'kecil dan huruf besar ',
  [`${idPrefix}.savingAndInvesting`]:
    'Menyimpan dan melabur boleh menjadi lebih mudah jika anda membuat ' +
    'perancangan awal.<br><br>' +
    'Saya gembira melabur secara mikro bersama Raiz ☺ Gunakan pautan ini untuk mendaftar – kita sama-sama ' +
    'akan mendapat ganjaran.',
  [`${idPrefix}.thirdReason`]:
    'Anda juga boleh membuat pelaburan berkala contohnya, RM5/seminggu. Dengan ' +
    'menetapkan pelaburan berkala ini, walaupun dengan amaun yang kecil, secara automatik, anda sebenarnya  ' +
    'mengamalkan Pemurataan Kos Ringgit.',
  [`${idPrefix}.secondReason`]:
    'Laburkan duit baki dari pembelian harian anda secara automatik. Di ' +
    'Australia, sebanyak 75% pengguna Raiz menggunakan auto-bundar untuk melabur secara kerap walaupun dengan ' +
    'amaun yang kecil. Anda hanya perlu set sekali dan lihat bagaimana ia bertambah dari masa ke semasa!',
  [`${idPrefix}.firstReason`]:
    'Raiz di Malaysia adalah sebuah syarikat usahasama di antara Permodalan ' +
    'Nasional Berhad (PNB) dan Raiz Invest Limited, sebuah syarikat tersenarai di bursa saham Australia. Selain ' +
    'data yang dikodkan, Raiz menyimpan semua maklumat setaraf tahap keselamatan di bank-bank. ',
  [`${idPrefix}.getStarted`]: 'Mula Di Sini',
  [`${idPrefix}.inviteCode`]: 'Kod Promosi {number}',
  [`${idPrefix}.confirmNewPassword`]:
    'Gunakan 8 atau lebih aksara dengan gabungan nombor, huruf kecil & huruf besar',
  [`${idPrefix}.loginError`]: 'Ralat Log Masuk',
  [`${idPrefix}.logIn`]: 'Log masuk ke',
  [`${idPrefix}.email`]: 'Alamat E-mel',
  [`${idPrefix}.password`]: 'Kata laluan Raiz',
  [`${idPrefix}.remember`]: 'Ingati saya',
  [`${idPrefix}.contactUs`]: 'Perlukan bantuan? Hubungi kami',
  [`${idPrefix}.createYourRaizAccount`]: 'Buka Akaun Raiz Anda',
  [`${idPrefix}.createAccount`]: 'Buka Akaun',
  [`${idPrefix}.already`]: 'Telah Mempunyai Akaun?',
  [`${idPrefix}.verificationCode`]: 'Kod pengesahan',
  [`${idPrefix}.verify`]: 'Sahkan',
  [`${idPrefix}.verificationText`]:
    'Untuk keselamatan anda, kod ' +
    'pengesahan telah dihantar ke alamat e-mel berdaftar anda. Sila berikan kod dibawah.',
  [`${idPrefix}.newInTime`]:
    'Terdapat lebih dari 400,000 pengguna mendaftar ke Raiz dalam 2 tahun beroperasi. Terdapat' +
    ' lebih 42,000 bonus rujukan yang telah diberikan kepada pengguna baru pada masa itu.',
  [`${idPrefix}.cant`]: 'Tidak boleh kosong',
  [`${idPrefix}.shouldContain`]:
    'Harus mengandungi sekurang-kurangnya satu huruf besar',
  [`${idPrefix}.shouldContainLetter`]:
    'Harus mengandungi sekurang-kurangnya satu huruf kecil',
  [`${idPrefix}.shouldContainOne`]:
    'Harus mengandungi sekurang-kurangnya satu nombor',
  [`${idPrefix}.minimum8Characters`]:
    'Mesti mengandungi sekurang-kurangnya 8 huruf',
  [`${idPrefix}.typeEmailAgain`]: 'Masukkan emel anda sekali lagi',
  [`${idPrefix}.bothEmailMustMatch`]: 'Kedua-dua emel mesti sama',
});

export default messages;
