import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BrandsCarousel from './BrandsCarousel';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function RewardsCarousel({
  totalRewards,
  saveInvested,
  toggleHelpCard,
}) {
  const { type, sliders } = useSelector((state) => ({
    type: state.rewards.type,
    sliders: state.rewards.sliders,
  }));

  const { offers } = sliders || {};
  const { t } = useTranslation();

  return (
    <div className="banner-background__container active">
      <div className="rewards__banner">
        <DeprecatedButton
          buttonClass="rewards__banner-info my-finance__banner-info"
          handleClick={() => toggleHelpCard(true)}
        />

        <div className="rewards__banner-title">
          {t('rewardsPage.carousel.title')}
        </div>

        <div>
          <div className="rewards__banner-label">
            {t('rewardsPage.carousel.totalEarned')}
          </div>

          <div className="rewards__banner-value">
            {formatNumber({
              value: totalRewards,
              maximumFractionDigits: 2,
            })}
          </div>

          <BrandsCarousel
            sliders={offers}
            saveInvested={saveInvested}
            type={type}
          />
        </div>
      </div>
    </div>
  );
}

RewardsCarousel.propTypes = {
  saveInvested: PropTypes.func.isRequired,
  totalRewards: PropTypes.number.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
};
