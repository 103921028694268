import { useTranslation } from 'react-i18next';

import {
  SendButton,
  DescriptionIcon,
  DescriptionWrapper,
  Wrapper,
} from './styles';

import { FIREBASE_EVENTS } from 'constants/firebaseEvents';
import { useSendEmployerChoiceFormMutation } from 'store/superAnnuation/superAnnuation';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserEmail } from 'store/user/selectors';
import { logFirebaseEvent } from 'utils/logFirebaseEvent';

export default function SendEmployerChoice() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const email = useAppSelector(selectUserEmail);

  const [sendEmployerChoiceForm, { isLoading }] =
    useSendEmployerChoiceFormMutation();

  const handleSendCLick = () => {
    logFirebaseEvent(FIREBASE_EVENTS.sendMyEmployerChoiceButtonClicked);

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(
            'superPage.superInfo.sendEmployerChoice.confirmationModal.title',
            {
              email,
            },
          ),
          acceptButtonText: t(
            'superPage.superInfo.sendEmployerChoice.confirmationModal.button.confirm',
          ),
          rejectButtonText: t('common.cancel'),
          onAccept: async () => {
            logFirebaseEvent(
              FIREBASE_EVENTS.sendSuperEmployerChoiceFormConfirmed,
            );

            await sendEmployerChoiceForm()
              .unwrap()
              .then(() => {
                dispatch(
                  toggleInfoModal({
                    isInfoModalVisible: true,
                    config: {
                      title: t(
                        'superPage.superInfo.sendEmployerChoice.successModal.title',
                      ),
                    },
                  }),
                );
              });
          },
          onReject: () => {
            logFirebaseEvent(FIREBASE_EVENTS.sendSuperEmployerChoiceFormCancel);
          },
        },
      }),
    );
  };

  return (
    <Wrapper>
      <DescriptionWrapper>
        <DescriptionIcon />

        {t('superPage.superInfo.sendEmployerChoice.description')}
      </DescriptionWrapper>

      <SendButton
        isLoading={isLoading}
        onClick={handleSendCLick}
        label={t('superPage.superInfo.sendEmployerChoice.button')}
      />
    </Wrapper>
  );
}
