import { API_TAGS } from '../consts';

import { SUPER_ANNUATION_BASE_URL } from './consts';
import {
  ITransformSuperUserResponse,
  ISuperUserResponse,
  IUpdateInsuranceData,
  ITransformSuperUserAccountsResponse,
  ISuperUserAccountsResponse,
} from './types';

import { api } from 'store/api';

export const superAnnuationApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendEmployerChoiceForm: build.mutation<unknown, void>({
      query: () => ({
        url: `${SUPER_ANNUATION_BASE_URL}/user/send_employer_choice_form`,
        method: 'POST',
      }),
    }),
    superUser: build.query<ISuperUserResponse, void>({
      query: () => ({
        url: `${SUPER_ANNUATION_BASE_URL}/user`,
      }),
      transformResponse: (response: ITransformSuperUserResponse) =>
        response?.user,
      providesTags: [API_TAGS.superUser],
    }),
    superUserAccounts: build.query<ISuperUserAccountsResponse, void>({
      query: () => ({
        url: `${SUPER_ANNUATION_BASE_URL}/user/accounts`,
      }),
      transformResponse: (response: ITransformSuperUserAccountsResponse) =>
        response?.account,
      providesTags: [API_TAGS.superUserAccounts],
    }),
    updateInsurance: build.mutation<ISuperUserResponse, IUpdateInsuranceData>({
      query: (data) => ({
        url: `${SUPER_ANNUATION_BASE_URL}/user/insurance`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [API_TAGS.superUser],
    }),
    createRefreshAccount: build.mutation<unknown, void>({
      query: () => ({
        url: `${SUPER_ANNUATION_BASE_URL}/user/refresh_accounts`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: true,
});
export const {
  useSendEmployerChoiceFormMutation,
  useUpdateInsuranceMutation,
  useSuperUserQuery,
  useSuperUserAccountsQuery,
  useCreateRefreshAccountMutation,
} = superAnnuationApi;

export const {
  endpoints: { sendEmployerChoiceForm },
} = superAnnuationApi;
