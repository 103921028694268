import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FAVOURITE_CATEGORY_ID } from '../consts';

export default function OnlineOfferItem({
  offer,
  saveInvested,
  toggleLikeOffer,
  categoryId,
}) {
  const {
    id,
    offerId,
    title,
    offerTitle,
    logo,
    logoUrl,
    isFavourite,
    invested_value,
  } = offer;

  const brandId = id || offerId;
  const brandTitle = title || offerTitle;
  const brandLogo = logo || logoUrl;
  const isFavouriteCategory = categoryId === FAVOURITE_CATEGORY_ID;
  const isFavouriteOffer = isFavourite || isFavouriteCategory;

  return (
    <div className="rewards-block">
      <Link
        to={`/rewards/online/${brandId}`}
        onClick={() => saveInvested(invested_value)}
      >
        <div className="grid__container-item">
          <div className="grid__container-image">
            <img
              src={brandLogo}
              alt={brandTitle}
              className="grid__container-logo"
              onError={(e) => {
                e.target.style.display = 'none';

                return true;
              }}
            />
          </div>

          <div className="grid__container-divider" />

          <div className="grid__container-name">{brandTitle}</div>
        </div>
      </Link>

      <div
        className={isFavouriteOffer ? 'like' : 'dislike'}
        onClick={() => {
          toggleLikeOffer({
            isFavourite: isFavouriteOffer,
            id: brandId,
            isShowFavourite: isFavouriteCategory,
          });
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      />
    </div>
  );
}

OnlineOfferItem.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    offerId: PropTypes.string,
    title: PropTypes.string,
    offerTitle: PropTypes.string,
    logo: PropTypes.string,
    logoUrl: PropTypes.string,
    institution_id: PropTypes.string,
    isFavourite: PropTypes.bool,
    invested_value: PropTypes.number,
  }).isRequired,
  saveInvested: PropTypes.func.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
};
