import { IFormattedSuitability, ISuitability } from '../types';

import { SUITABILITY_NAMES } from 'constants/suitabilityConstants';
import { MY_APP } from 'constants/localeConfigs';

export interface IGetSuitabilityDefaultValues {
  formattedSuitabilities: IFormattedSuitability[];
  user: {
    [key: string]: string;
  };
  isUpdateProfilePage: boolean;
  financialValues: {
    [key: string]: ISuitability[];
  };
  isRegistrationFinancialPage: boolean;
}

const getSuitabilityDefaultValues = ({
  formattedSuitabilities,
  user,
  isUpdateProfilePage,
  financialValues,
  isRegistrationFinancialPage,
}: IGetSuitabilityDefaultValues) => {
  if (isUpdateProfilePage || (MY_APP && isRegistrationFinancialPage)) {
    return {};
  }

  return (
    formattedSuitabilities?.reduce(
      (mappedDefaultValues, suitabilities) => {
        const isTextInput =
          financialValues?.[suitabilities.label]?.[0]?.textInput ??
          suitabilities?.defaultValue?.[0].textInput;

        const isAdditionalField =
          isTextInput && SUITABILITY_NAMES[suitabilities.label];

        return {
          ...mappedDefaultValues,
          [suitabilities.label]: suitabilities.defaultValue,
          ...(isAdditionalField && {
            [SUITABILITY_NAMES[suitabilities.label]]:
              user[SUITABILITY_NAMES[suitabilities.label]],
          }),
        };
      },

      {},
    ) || {}
  );
};

export default getSuitabilityDefaultValues;
