import { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

import SuperInfoView from './SuperRegistrationView';
import { SUPER_REGISTRATION_STEPS } from './consts';

import {
  PORTFOLIO_PLANS,
  PORTFOLIO_SEARCH_PARAMS,
} from 'pages/Portfolio/consts';
import { PATHS } from 'constants/paths';
import {
  selectIsInsuranceStatusEnabled,
  selectIsSuperPlusPortfolioEnabled,
} from 'store/firebaseConfig/selectors';
import * as userActions from 'store/user/actions';
import * as superActions from 'store/superAnnuation/actions';

class SuperRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: 'male',
      tfnChecked: false,
      tfn: '',
      errorTfn: '',
      errorTfnChecked: '',
      disclosureChecked: false,
      insuranceChecked: false,
      receivingChecked: false,
      backInChecked: false,
      agreeChecked: false,
      pdsErrors: {
        disclosure: false,
        insurance: false,
        receiving: false,
        back: false,
        agree: false,
      },
      isPdsErrors: false,
      isShowInsuranceModal: false,
      isShowProvideTFNModal: false,
      showLoader: false,
      showMainLoader: true,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.changeGender = this.changeGender.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.navigateToPlusPortfolio = this.navigateToPlusPortfolio.bind(this);

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getSuperUserData();

    this.setState({ showMainLoader: true });

    actions.getSuperUserAccounts().then(() => {
      const { userAccounts } = this.props;

      if (userAccounts.account && userAccounts.account.alerts) {
        this.checkAlerts(userAccounts.account.alerts);
      } else {
        this.setState({ showMainLoader: false });
      }
    });
  }

  handleChangeInsurance = (e) => {
    if (e.target.name) {
      switch (e.target.name) {
        case 'insurance':
          this.setState({
            insuranceChecked: e.target.checked,
          });

          break;
        case 'receiving':
          this.setState({
            receivingChecked: e.target.checked,
          });

          break;
        case 'back':
          this.setState({
            backInChecked: e.target.checked,
          });

          break;
        case 'agree':
          this.setState({
            agreeChecked: e.target.checked,
          });

          break;
        default:
          this.setState({
            disclosureChecked: e.target.checked,
          });
      }
    }
  };

  handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      this.setState({
        tfnChecked: e.target.checked,
      });
    } else {
      const { tfn, tfnChecked } = this.state;

      if (tfn !== '' || tfn.length >= 8) {
        this.setState({
          errorTfn: '',
        });
      }

      if (tfnChecked) {
        this.setState({
          errorTfnChecked: '',
        });
      }

      this.setState({
        tfn: e.target.value,
      });
    }
  };

  handleSubmit(e, popupAlredyShow = false) {
    const { step, actionsSuper, t } = this.props;
    let isError = false;

    this.setState({ showLoader: true });

    if (step === SUPER_REGISTRATION_STEPS.terms) {
      const nowTime = dayjs().tz('Australia/Sydney').format();

      actionsSuper.saveSuperRegistration(step, nowTime).then(() => {
        this.setState({ showLoader: false });
      });
    }

    if (step === SUPER_REGISTRATION_STEPS.gender) {
      const { gender } = this.state;

      actionsSuper.saveSuperRegistration(step, '', gender).then(() => {
        this.setState({ showLoader: false });
      });
    }

    if (step === SUPER_REGISTRATION_STEPS.taxFileNumber) {
      const { tfn, tfnChecked, isShowProvideTFNModal } = this.state;

      if (tfn === '') {
        this.setState({
          errorTfn: t('fields.validation.required'),
        });

        isError = true;
      } else if (tfn.length < 8) {
        this.setState({
          errorTfn: t('fields.validation.minimum8Characters'),
        });

        isError = true;
      } else if (!tfnChecked) {
        this.setState({
          errorTfnChecked: t('fields.validation.required'),
        });

        isError = true;
      } else if (!isShowProvideTFNModal) {
        this.setState({
          isShowProvideTFNModal: true,
          showLoader: false,
        });
      } else {
        this.setState({
          isShowProvideTFNModal: false,
        });

        actionsSuper.saveSuperRegistration(step, '', '', tfn).then(() => {
          this.navigateToPlusPortfolio(step + 1);

          this.setState({
            showLoader: false,
          });
        });
      }
    }

    if (step === SUPER_REGISTRATION_STEPS.insurance) {
      const {
        pdsErrors,
        disclosureChecked,
        insuranceChecked,
        receivingChecked,
        backInChecked,
        agreeChecked,
      } = this.state;

      const { isInsuranceStatusEnabled } = this.props;

      let isPdsErrors = false;

      if (!disclosureChecked) {
        pdsErrors.disclosure = true;

        isPdsErrors = true;
      }

      if (!insuranceChecked) {
        pdsErrors.insurance = true;

        isPdsErrors = true;
      }

      if (!isInsuranceStatusEnabled && !backInChecked) {
        pdsErrors.back = true;

        isPdsErrors = true;
      }

      if (!agreeChecked) {
        pdsErrors.agree = true;

        isPdsErrors = true;
      }

      if (popupAlredyShow) {
        this.setState({
          isShowInsuranceModal: false,
        });
      }

      if (isPdsErrors) {
        this.setState({
          pdsErrors,
          isPdsErrors,
          showLoader: false,
        });
      } else if (!receivingChecked || (receivingChecked && popupAlredyShow)) {
        actionsSuper.setConsent().then(() => {
          actionsSuper.saveSuperRegistration(step);

          this.setState({ showLoader: false });
        });
      } else {
        this.setState({
          isShowInsuranceModal: true,
          showLoader: false,
        });
      }
    }

    if (isError === true) {
      this.setState({ showLoader: false });
    }
  }

  handleCloseModal() {
    const { actionsSuper } = this.props;
    const { isShowInsuranceModal, isShowProvideTFNModal } = this.state;

    this.setState({
      ...(isShowInsuranceModal && { isShowInsuranceModal: false }),
      ...(isShowProvideTFNModal && { isShowProvideTFNModal: false }),
    });

    actionsSuper.closeModal();
  }

  checkAlerts(alerts) {
    const { actionsSuper } = this.props;

    alerts.map((alert) => {
      if (alert.type === 'CONSENT_PENDING') {
        this.setState({ showMainLoader: false });

        actionsSuper.goToConsent();
      }

      return alert;
    });

    this.setState({ showMainLoader: false });
  }

  changeGender(gender) {
    this.setState({
      gender,
    });
  }

  navigateToPlusPortfolio(step) {
    const { history, isSuperPlusPortfolioEnabled } = this.props;

    if (
      step === SUPER_REGISTRATION_STEPS.portfolio &&
      isSuperPlusPortfolioEnabled
    ) {
      const searchParam = createSearchParams({
        [PORTFOLIO_SEARCH_PARAMS.isSuper]: true,
        [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: PORTFOLIO_PLANS.basic,
      }).toString();

      history.push(`${PATHS.portfolio.index}?${searchParam}`);
    }
  }

  goBack() {
    const { step, actionsSuper } = this.props;

    const newStep = step - 1;

    this.navigateToPlusPortfolio(newStep);

    actionsSuper.backOnSuperRegistration(newStep);
  }

  render() {
    const {
      tfnChecked,
      tfn,
      errorTfn,
      errorTfnChecked,
      disclosureChecked,
      insuranceChecked,
      receivingChecked,
      backInChecked,
      agreeChecked,
      pdsErrors,
      isPdsErrors,
      gender,
      isShowInsuranceModal,
      showLoader,
      showMainLoader,
      isShowProvideTFNModal,
    } = this.state;

    const {
      errorMessage,
      isShowErrorModal,
      step,
      superUser,
      user,
      isSuperPlusPortfolioEnabled,
    } = this.props;

    return (
      <SuperInfoView
        gender={gender}
        handleSubmit={this.handleSubmit}
        errorMessage={errorMessage}
        handleCloseModal={this.handleCloseModal}
        isShowErrorModal={isShowErrorModal}
        step={step}
        changeGender={this.changeGender}
        tfnChecked={tfnChecked}
        tfn={tfn}
        handleChange={this.handleChange}
        errorTfn={errorTfn}
        errorTfnChecked={errorTfnChecked}
        handleChangeInsurance={this.handleChangeInsurance}
        disclosureChecked={disclosureChecked}
        insuranceChecked={insuranceChecked}
        receivingChecked={receivingChecked}
        backInChecked={backInChecked}
        agreeChecked={agreeChecked}
        pdsErrors={pdsErrors}
        isPdsErrors={isPdsErrors}
        superUser={superUser}
        user={user}
        isShowInsuranceModal={isShowInsuranceModal}
        isShowProvideTFNModal={isShowProvideTFNModal}
        goBack={this.goBack}
        showLoader={showLoader}
        showMainLoader={showMainLoader}
        isSuperPlusPortfolioEnabled={isSuperPlusPortfolioEnabled}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  userAccounts: state.user.userAccounts,
  superAnnuationUser: state.superAnnuation.superAnnuationUser,
  isShowErrorModal: state.superAnnuation.isShowErrorModal,
  errorMessage: state.superAnnuation.errorMessage,
  step: state.superAnnuation.step,
  superUser: state.user.superUser,
  user: state.user.user,
  isSuperPlusPortfolioEnabled: selectIsSuperPlusPortfolioEnabled(state),
  isInsuranceStatusEnabled: selectIsInsuranceStatusEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  actionsSuper: bindActionCreators(superActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SuperRegistration));

SuperRegistration.defaultProps = {
  state: {},
  errorMessage: '',
  userAccounts: {},
  actionsSuper: {},
  superAnnuationUser: {},
  isShowErrorModal: false,
  step: SUPER_REGISTRATION_STEPS.terms,
  superUser: {},
  user: {},
};

SuperRegistration.propTypes = {
  state: PropTypes.shape({}),
  errorMessage: PropTypes.string,
  actions: PropTypes.shape({
    getSuperUserData: PropTypes.func,
    getSuperUserAccounts: PropTypes.func,
  }).isRequired,
  userAccounts: PropTypes.shape({
    account: PropTypes.shape({
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  actionsSuper: PropTypes.shape({
    saveSuperRegistration: PropTypes.func,
    setConsent: PropTypes.func,
    goToConsent: PropTypes.func,
    closeModal: PropTypes.func,
    backOnSuperRegistration: PropTypes.func,
  }),
  superAnnuationUser: PropTypes.shape({}),
  isShowErrorModal: PropTypes.bool,
  step: PropTypes.number,
  superUser: PropTypes.shape({}),
  user: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  isSuperPlusPortfolioEnabled: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isInsuranceStatusEnabled: PropTypes.bool.isRequired,
};
