import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/logout.scss';
import Input from 'components/elements/Input/Input';

export default function RolloverFundModal({
  isShowFundModal,
  fund,
  changeMemberNumber,
  handleCloseModal,
  addRollover,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowFundModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert fund__alert"
    >
      <div className="modal-alert-content">
        <div className="title">{fund.name}</div>

        <div className="subTitle">
          {t('superPage.rolloverFundModal.memberNumber')}
        </div>

        <div className="content">
          <Input
            onChange={changeMemberNumber}
            type="text"
            placeholder={t('superPage.rolloverFundModal.memberNumberText')}
            classList="grid__input"
          />
        </div>

        <DeprecatedButton
          title={<Trans i18nKey="common.space" t={t} />}
          buttonClass="button__close -black"
          handleClick={handleCloseModal}
        />

        <div className="error-buttons">
          <DeprecatedButton
            title={
              <Trans i18nKey="superPage.rolloverFundModal.toRollover" t={t} />
            }
            type="button"
            handleClick={addRollover}
          />
        </div>
      </div>
    </Modal>
  );
}

RolloverFundModal.propTypes = {
  fund: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isShowFundModal: PropTypes.bool.isRequired,
  changeMemberNumber: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  addRollover: PropTypes.func.isRequired,
};
