import { SHOW_GLOBAL_ERROR, WRONG_REQUEST_CODE } from './types';

export function showGlobalError(globalError, statusCode = null) {
  if (statusCode === null) {
    const globalErrorMessage = '';

    return { type: SHOW_GLOBAL_ERROR, globalErrorMessage };
  }

  if (statusCode) {
    return { type: WRONG_REQUEST_CODE };
  }

  const globalErrorMessage = globalError.toString().replace('TypeError:', '');

  return { type: SHOW_GLOBAL_ERROR, globalErrorMessage };
}

export function showGlobalErrorMain(globalError) {
  const globalErrorMessage = globalError.toString().replace('TypeError:', '');

  return { type: SHOW_GLOBAL_ERROR, globalErrorMessage };
}
