import styled from '@emotion/styled';

import editWhite from 'assets/images/icons/edit-white.svg';
import arrowRight from 'assets/images/icons/arrow-right-white.svg';
import plus from 'assets/images/icons/plus-white.svg';
import infoIcon from 'assets/images/icons/info-icon-white.svg';
import arrowDown from 'assets/images/icons/arrow-down-white.svg';

export const ProPortfolioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 38px;
  width: 100%;
  height: 100%;
`;

export const ProPortfolioTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProPortfolioName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  color: ${({ theme }) => theme.colors.white};
`;

export const ProPortfolioEditIcon = styled.div`
  background-image: url(${`${editWhite}`});
  width: 15px;
  height: 15px;
  margin-left: 9px;
  cursor: pointer;
`;

export const ProPortfolioContentWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 38px;
  padding: 0 60px 0 140px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    flex-direction: column;
    padding: 16px 16px;
    margin: 0 0 20px;
  }
`;

export const ProPortfolioRightColumn = styled.div`
  width: 60%;
  float: right;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
    margin-top: 16px;
  }
`;

interface IProPortfolioLabelContent {
  isActive?: boolean;
  isModal?: boolean;
}

export const ProPortfolioLabelContent = styled.div<IProPortfolioLabelContent>`
  height: unset;
  width: 100%;
  background: ${({ theme, isModal }) =>
    isModal
      ? theme.colors.whiteTransparentDark
      : theme.colors.whiteTransparent};
  padding: ${({ isModal }) => (isModal ? '14px 16px' : '18px 20px')};
  border-radius: 6px;
  display: inline-grid;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`;

export const ProPortfolioLabelTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
`;

export const ProPortfolioNewBadge = styled.div<{ color: string }>`
  margin-left: 8px;
  background-color: ${({ theme, color }) => color || theme.colors.niagara};
  border-radius: 3px;
  padding: 2px 4px;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
`;

interface IProPortfolioLabelPercent {
  isModal?: boolean;
}

export const ProPortfolioLabelPercent = styled.div<IProPortfolioLabelPercent>`
  font-size: ${({ isModal }) => (isModal ? '15px' : '21px')};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
`;

interface IProPortfolioArrow {
  isExpanded?: boolean;
}

export const ProPortfolioArrow = styled.div<IProPortfolioArrow>`
  background-image: url(${({ isExpanded }) =>
    isExpanded ? `${arrowDown}` : `${arrowRight}`});
  width: 24px;
  height: 24px;
`;

export const ProPortfolioContentLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProPortfolioContentDetails = styled.div`
  padding: 10px 16px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  background: ${({ theme }) => theme.colors.whiteTransparent};
  max-width: 130px;
  display: flex;
  margin-top: 32px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    margin-top: 4px;
  }
  cursor: pointer;
`;

export const ProPortfolioInfoIcon = styled.div`
  background-image: url(${`${infoIcon}`});
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const ProPortfolioLabelText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
`;

export const ProPortfolioPlus = styled.div`
  background-image: url(${`${plus}`});
  width: 24px;
  height: 24px;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
`;
