import React, { Component } from 'react';
import { createSearchParams, NavLink } from 'react-router-dom';
import '../../../styles/layout/main-menu.scss';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Firebase from 'firebase/app';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import InviteModal from '../Modals/InviteModal';
import AccountStrength from '../AccountStrength/AccountStrength';
import {
  CURRENCY_SYMBOL,
  TWITTER_LINK,
  IOS_APP_LINK,
  ANDROID_APP_LINK,
  FACEBOOK_LINK,
  AU_APP,
  MY_APP,
} from '../../../constants/localeConfigs';
import {
  INVITE_FRIENDS_ON,
  SUPER_ON,
} from '../../../constants/togglesFunctions';
import { BUILD_VERSION } from '../../../constants/codebaseVersions';
import ProfileMenu from '../../../pages/Settings/components/ProfileMenu';
import RewardsMenu from '../../../pages/NewRewards/components/RewardsMenu';
import * as rewardsActions from '../../../store/rewards/actions';
import KidsAccessRejectionModal from '../Modals/KidsAccessRejectionModal/KidsAccessRejectionModal';
import { checkIsDependentUser } from '../../../utils/user';
import { KIDS_ACCESS_REJECTION_SOURCE } from '../Modals/KidsAccessRejectionModal/consts';

import { checkIsRewardsEnabled } from './utils';
import { XLink } from './styles';

import { SuperMenu } from 'pages/Super/components/SuperMenu/SuperMenu';
import { Icon } from 'components/elements/Icon';
import { PATHS } from 'constants/paths';
import {
  selectIsHomeOwnershipEnabled,
  selectIsNewRewardsEnabled,
  selectIsReferralsEnabled,
  selectIsRaizRewardsEnabled,
} from 'store/firebaseConfig/selectors';
import { selectDependentUserAccess } from 'store/user/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { OFFER_TYPES, REWARDS_SEARCH_PARAMS } from 'pages/NewRewards/consts';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      currentPath: '',
    };

    this.checkToggle = this.checkToggle.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.toggleKidsAccessRejectionModal =
      this.toggleKidsAccessRejectionModal.bind(this);
  }

  componentDidMount() {
    const { isNewRewards } = this.props;

    if (INVITE_FRIENDS_ON) {
      const currentURL = new URLSearchParams(window.location.search);
      const categoryFromURL = currentURL.get('invite-friend');

      if (categoryFromURL) {
        this.setState({
          showModal: true,
        });
      }
    }

    if (AU_APP && isNewRewards) {
      const { actionsRewards } = this.props;

      actionsRewards.getCards();
    }
  }

  handleOpenModal() {
    this.setState({
      showModal: true,
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
    });
  }

  checkToggle(menuClass, toggleMobileMenu, name = '') {
    this.setState({
      currentPath: window.location.pathname,
    });

    if (
      menuClass &&
      menuClass === '-mobile' &&
      (name === 'settings' || name === 'super')
    ) {
      return;
    }

    if (menuClass && menuClass === '-mobile') {
      toggleMobileMenu(false);
    }

    if (name !== '') {
      switch (name) {
        case 'home':
          Firebase.analytics().logEvent('MenuHome');

          break;
        case 'invest':
          Firebase.analytics().logEvent('MenuInvest');

          break;
        case 'settings':
          Firebase.analytics().logEvent('MenuSettings');

          break;
        case 'raizCare':
          Firebase.analytics().logEvent('MenuRaizCare');

          break;
        default:
          break;
      }
    }
  }

  toggleKidsAccessRejectionModal(condition) {
    this.setState({ isRejectionModalOpen: condition });
  }

  render() {
    const {
      menuClass,
      toggleMobileMenu,
      initials,
      fullName,
      features,
      user,
      isHomeOwnershipEnabled,
      isNewRewards,
      isReferralsEnabled,
      dependentUserAccess,
      t,
      isRaizRewardsEnabled,
    } = this.props;

    const { showModal, currentPath, isRejectionModalOpen } = this.state;
    const isNotRegularUser = !user || checkIsDependentUser(user);

    const isRewardsEnabled = checkIsRewardsEnabled({
      isRewardsFeatureEnabled: isRaizRewardsEnabled,
      isNewRewardsEnabled: isNewRewards,
      user,
      dependentUserRewardsAccess: dependentUserAccess?.rewards,
    });

    const rewardsSearchParams = createSearchParams({
      [REWARDS_SEARCH_PARAMS.offerType]: OFFER_TYPES.online,
    }).toString();

    return (
      <div className={`main-menu ${menuClass}`}>
        <div className="main-menu__header">
          <div className="header__user-photo">{initials}</div>

          <div className="main-menu__header-name">{fullName}</div>
        </div>

        <div>
          <div className="header__strength">
            <AccountStrength toggleMobileMenu={toggleMobileMenu} />
          </div>

          <div className="main-menu__list">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? 'main-menu__list-item -active'
                  : 'main-menu__list-item'
              }
              onClick={() => {
                this.checkToggle(menuClass, toggleMobileMenu, 'home');
              }}
              end
            >
              <div className="main-menu__link">
                <i className="main-menu__link-icon -home" />

                {t('menu.home')}
              </div>
            </NavLink>

            <NavLink
              to="/invest"
              className={({ isActive }) =>
                isActive
                  ? 'main-menu__list-item -active'
                  : 'main-menu__list-item'
              }
              onClick={() => {
                this.checkToggle(menuClass, toggleMobileMenu, 'invest');
              }}
            >
              <div className="main-menu__link">
                <i className="main-menu__link-icon -invest" />

                {t('menu.invest')}
              </div>
            </NavLink>

            {!MY_APP && isRewardsEnabled && (
              <NavLink
                to={PATHS.rewards.index}
                className={({ isActive }) =>
                  isActive
                    ? 'main-menu__list-item -active'
                    : 'main-menu__list-item'
                }
                onClick={() => {
                  this.checkToggle(menuClass, toggleMobileMenu, 'rewards');
                }}
              >
                <div className="main-menu__link">
                  <i className="main-menu__link-icon -rewards" />

                  {t('menu.rewards')}
                </div>
              </NavLink>
            )}

            {((AU_APP && isNewRewards) || (MY_APP && isRewardsEnabled)) && (
              <div>
                <NavLink
                  to={`${PATHS.rewards.index}?${rewardsSearchParams}`}
                  className={({ isActive }) =>
                    isActive
                      ? 'main-menu__list-item -active'
                      : 'main-menu__list-item'
                  }
                  onClick={(e) => {
                    if (isNotRegularUser && !dependentUserAccess?.rewards) {
                      e.preventDefault();

                      this.toggleKidsAccessRejectionModal(true);
                    } else {
                      this.checkToggle(menuClass, toggleMobileMenu, 'rewards');
                    }
                  }}
                >
                  <div className="main-menu__link">
                    <i className="main-menu__link-icon -rewards" />

                    {t('menu.rewards')}
                  </div>
                </NavLink>

                <RewardsMenu
                  checkToggle={() =>
                    this.checkToggle(
                      menuClass,
                      toggleMobileMenu,
                      'sub-settings',
                    )
                  }
                />
              </div>
            )}

            {SUPER_ON &&
              sessionStorage.getItem(SESSION_STORAGE_KEYS.user) &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !isNotRegularUser && (
                <NavLink
                  to="/super"
                  className={({ isActive }) =>
                    isActive
                      ? 'main-menu__list-item -active'
                      : 'main-menu__list-item'
                  }
                  onClick={() => {
                    this.checkToggle(menuClass, toggleMobileMenu);
                  }}
                >
                  <div className="main-menu__link">
                    <i className="main-menu__link-icon -super" />

                    {t('menu.super')}
                  </div>
                </NavLink>
              )}

            {SUPER_ON &&
              sessionStorage.getItem(SESSION_STORAGE_KEYS.user) &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)).user
                .super_annuation_user &&
              !isNotRegularUser && (
                <SuperMenu
                  currentPath={currentPath}
                  toggleMenuItem={() =>
                    this.checkToggle(menuClass, toggleMobileMenu, 'sub-super')
                  }
                />
              )}

            {!(MY_APP && isNotRegularUser) && (
              <NavLink
                to="/my-finance"
                className={({ isActive }) =>
                  isActive
                    ? 'main-menu__list-item -active'
                    : 'main-menu__list-item'
                }
                onClick={() => {
                  this.checkToggle(menuClass, toggleMobileMenu, 'my-finance');
                }}
              >
                <div className="main-menu__link">
                  <i className="main-menu__link-icon -myfinance" />

                  {t('menu.myFinance')}
                </div>
              </NavLink>
            )}

            {AU_APP && isHomeOwnershipEnabled && (
              <a
                href="https://raizinvest.com.au/raiz-home-ownership/"
                className="main-menu__list-item"
                onClick={() => {
                  this.checkToggle(
                    menuClass,
                    toggleMobileMenu,
                    'raizHomeOwnership',
                  );
                }}
                target="_blank"
                rel="noreferrer"
              >
                <div className="main-menu__link">
                  <i className="main-menu__link-icon -raizHomeOwnership" />

                  {t('menu.raizHomeOwnerShip')}
                </div>
              </a>
            )}

            {features && features.CPFOn && (
              <NavLink
                to="/cpf"
                className={({ isActive }) =>
                  isActive
                    ? 'main-menu__list-item -active'
                    : 'main-menu__list-item'
                }
                onClick={() => {
                  this.checkToggle(menuClass, toggleMobileMenu, 'cpf');
                }}
              >
                <div className="main-menu__link">
                  <i className="main-menu__link-icon -cpf" />

                  {t('menu.capitalProtectedFunds')}
                </div>
              </NavLink>
            )}

            <NavLink
              to={PATHS.referrals.index}
              className={({ isActive }) =>
                isActive
                  ? 'main-menu__list-item -active'
                  : 'main-menu__list-item'
              }
              onClick={(e) => {
                if (AU_APP && isReferralsEnabled) return;

                e.preventDefault();

                this.handleOpenModal();

                if (menuClass === '-mobile') {
                  toggleMobileMenu(false);
                }
              }}
            >
              <div className="main-menu__link">
                <i className="main-menu__link-icon -invite" />

                {t('menu.inviteYourFriends')}

                <span className="main-menu__link-info" id="menu-invite__amount">
                  {`${CURRENCY_SYMBOL}0.00`}
                </span>
              </div>
            </NavLink>

            <div className="main-menu__list-divider" />

            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive
                  ? 'main-menu__list-item -active'
                  : 'main-menu__list-item'
              }
              onClick={() => {
                this.checkToggle(menuClass, toggleMobileMenu, 'settings');
              }}
            >
              <div className="main-menu__link">
                <i className="main-menu__link-icon -settings" />

                {t('menu.mySettings')}
              </div>
            </NavLink>

            <ProfileMenu
              user={user}
              checkToggle={() =>
                this.checkToggle(menuClass, toggleMobileMenu, 'sub-settings')
              }
            />
          </div>

          <div className="main-menu__social -top-zero">
            <a
              href={FACEBOOK_LINK}
              className="main-menu__social-icon -fb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span />
            </a>

            <XLink href={TWITTER_LINK}>
              <Icon iconName="XLogoCircle" size={24} />

              <span />
            </XLink>
          </div>

          <div className="main-menu__share">
            <a href={IOS_APP_LINK} target="_blank" rel="noopener noreferrer">
              <div className="main-menu__share-icon -store" />
            </a>

            <a
              href={ANDROID_APP_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="main-menu__share-icon -play" />
            </a>
          </div>

          <div className="main-menu__version">{`v${BUILD_VERSION}`}</div>

          <InviteModal
            showModal={showModal}
            handleCloseModal={this.handleCloseModal}
          />
        </div>

        {isRejectionModalOpen && (
          <KidsAccessRejectionModal
            isRejectionModalOpen={isRejectionModalOpen}
            toggleKidsAccessRejectionModal={this.toggleKidsAccessRejectionModal}
            rejectionSource={KIDS_ACCESS_REJECTION_SOURCE.rewards}
          />
        )}
      </div>
    );
  }
}

Menu.propTypes = {
  menuClass: PropTypes.string,
  initials: PropTypes.string,
  fullName: PropTypes.string,
  toggleMobileMenu: PropTypes.func,
  features: PropTypes.shape({
    telegramUrl: PropTypes.string,
    telegramOn: PropTypes.bool,
    CPFOn: PropTypes.bool,
    raizRewards: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
    homeOwnershipOn: PropTypes.bool,
  }),
  user: PropTypes.shape({
    user_type: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string,
      user_type: PropTypes.string,
    }),
    loginData: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
  actionsRewards: PropTypes.shape({
    getCards: PropTypes.func,
  }).isRequired,
  isNewRewards: PropTypes.bool,
  isHomeOwnershipEnabled: PropTypes.bool,
  isReferralsEnabled: PropTypes.bool,
  dependentUserAccess: PropTypes.shape({
    rewards: PropTypes.bool,
  }),
  t: PropTypes.func.isRequired,
  isRaizRewardsEnabled: PropTypes.bool,
};

Menu.defaultProps = {
  menuClass: '',
  initials: '',
  fullName: '',
  toggleMobileMenu: () => {},
  features: {},
  isNewRewards: false,
  isHomeOwnershipEnabled: false,
  isReferralsEnabled: false,
  dependentUserAccess: {},
  isRaizRewardsEnabled: false,
};

const mapStateToProps = (state) => ({
  features: state.user.features,
  isNewRewards: selectIsNewRewardsEnabled(state),
  isHomeOwnershipEnabled: selectIsHomeOwnershipEnabled(state),
  isReferralsEnabled: selectIsReferralsEnabled(state),
  dependentUserAccess: selectDependentUserAccess(state),
  isRaizRewardsEnabled: selectIsRaizRewardsEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionsRewards: bindActionCreators(rewardsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Menu));
