import { defineMessages } from 'react-intl';

import { BLOG as idPrefix } from '../../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.raizBlog`]: 'Terkini dari Blog Raiz',
  [`${idPrefix}.seeAll`]: 'Lihat Semua',
});

export default messages;
