import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { Button } from 'components/elements/Button';

function InvestmentErrorModal({
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick,
  errorMessage,
  handleClick,
  modalClassName,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`modal-basic time-investment__modal time-investment__modal-quick ${modalClassName}`}
    >
      <div className="time-investment__modal-content">{errorMessage}</div>

      <div className="time-investment__modal-footer -single-btn">
        <Button label={t('common.ok')} onClick={handleClick} />
      </div>
    </Modal>
  );
}

InvestmentErrorModal.defaultProps = {
  modalClassName: '',
};

InvestmentErrorModal.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  modalClassName: PropTypes.string,
};

export default InvestmentErrorModal;
