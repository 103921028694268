import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatNumber } from 'utils/formatters/formatNumber';

export default function SuperBanner(props) {
  const { currentBalance } = props;
  const { t } = useTranslation();

  return (
    <div className="banner-background__container active">
      <div className="super__banner page-content__banner">
        <Link
          to="/super/information"
          className="rewards__banner-info my-finance__banner-info"
        />

        <div className="page-content__banner-title">
          {t('superPage.superBanner.title')}
        </div>

        <div className="page-content__banner-value">
          {formatNumber({
            value: currentBalance || 0,
          })}
        </div>
      </div>
    </div>
  );
}

SuperBanner.defaultProps = {
  currentBalance: 0,
};

SuperBanner.propTypes = {
  currentBalance: PropTypes.number,
};
