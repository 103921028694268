import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  PortfolioThemeEditCloseButton,
  PortfolioThemeEditSubmitButton,
} from '../EditPortfolioThemeModal/styles';
import ProPortfolioLabel from '../ProPortfolio/components/ProPortfolioLabel';

import { ChartDetailsContent } from './styles';
import ChartDetailsExpandedLabel from './components/ChartDetailsExpandedLabel';
import { useChartDetailsModal } from './useChartDetailsModal';

import { ModalWrapper } from 'components/layout/modal';

export default function ChartDetailsModal({
  isChartDetailsModalOpen,
  setIsChartDetailsModalOpen,
  portfolioColor,
  activeFundTags,
}) {
  const { t } = useTranslation();

  const { modalData, handleLabelClick } = useChartDetailsModal({
    activeFundTags,
  });

  return (
    <ModalWrapper
      isOpen={isChartDetailsModalOpen}
      onRequestClose={() => setIsChartDetailsModalOpen(false)}
      shouldCloseOnOverlayClick
      color={portfolioColor}
    >
      <PortfolioThemeEditCloseButton
        className="button__close"
        onClick={() => setIsChartDetailsModalOpen(false)}
      />

      <ChartDetailsContent>
        {modalData.map(
          ({
            percentage,
            allocation,
            name,
            isExpanded,
            funds,
            isExpandable,
            isBaseFund,
          }) => {
            const value = percentage || allocation || '0.0';
            const isActive = isExpandable && !!(percentage || allocation);

            return (
              <div key={name}>
                <ProPortfolioLabel
                  name={name}
                  value={value}
                  isModal
                  isExpanded={isExpanded}
                  isActive={isActive}
                  onClick={() => handleLabelClick(name)}
                  isExpandable={isExpandable}
                  isBaseFund={isBaseFund}
                />

                {isExpanded &&
                  funds.map((fund) => (
                    <ChartDetailsExpandedLabel
                      key={fund.id}
                      name={fund.name}
                      value={fund.allocation}
                    />
                  ))}
              </div>
            );
          },
        )}

        <PortfolioThemeEditSubmitButton
          type="button"
          label={t('common.ok')}
          onClick={() => setIsChartDetailsModalOpen(false)}
        />
      </ChartDetailsContent>
    </ModalWrapper>
  );
}

ChartDetailsModal.propTypes = {
  isChartDetailsModalOpen: PropTypes.bool.isRequired,
  setIsChartDetailsModalOpen: PropTypes.func.isRequired,
  portfolioColor: PropTypes.string.isRequired,
  activeFundTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
