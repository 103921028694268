import { useParams } from 'react-router-dom';
import { generatePath, useNavigate } from 'react-router';

import { PROFILE_TABS } from './const';
import { useProfilePage } from './useProfilePage';
import { useRegistrationFinancialPage } from './useRegistrationFinancialPage';

import { PATHS } from 'constants/paths';

export const useSettingsTabSwitcher = () => {
  const { activeTab } = useParams();

  const { isUpdateProfilePage } = useProfilePage();
  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();

  const navigate = useNavigate();

  if (!activeTab && !isUpdateProfilePage && !isRegistrationFinancialPage) {
    navigate(
      generatePath(PATHS.settings.profile, {
        activeTab: PROFILE_TABS.personal,
      }),
    );
  }

  const setActiveTab = (tab: keyof typeof PROFILE_TABS) => {
    navigate(
      generatePath(PATHS.settings.profile, {
        activeTab: tab,
      }),
    );
  };

  return { activeTab, setActiveTab };
};
