import styled from '@emotion/styled';

export const StatementMonth = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StatementDateRange = styled.span`
  color: ${({ theme }) => theme.colors.dustyGray};
`;
