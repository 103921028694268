import styled from '@emotion/styled';

import checkBoxUncheckedIcon from 'assets/images/icons/unchecked-check-box.svg';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';

export const Row = styled.div`
  clear: both;
  height: 50px;
  text-align: left;
  margin-bottom: 10px;
`;

export const BottomRow = styled(Row)`
  text-align: left;
`;

export const Col = styled.div`
  padding-bottom: 24px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const CheckBoxRow = styled(Row)`
  margin-top: 10px;
  width: 100%;
  display: inline;
`;

export const FormStyled = styled.form`
  width: 100%;
  margin-bottom: 24px;
`;

export const CheckBox = styled(CheckboxFF)`
  clear: both;
  height: 42px;
  text-align: left;

  & .form-field__label {
    cursor: pointer;
    padding: 10px 0 0 52px;
    position: relative;
    width: 100%;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    height: 36px;

    &:before {
      background-image: url(${checkBoxUncheckedIcon});
      border-radius: 20px;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.2s;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
    }
  }

  & input[type='checkbox'] {
    cursor: pointer;
    height: 36px;
    width: 36px;
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 99;

    &:checked + .form-field__label {
      &:before {
        transition: none;
        background: transparent;
        border: 4px solid ${({ theme }) => theme.colors.niagaraLight};
      }
      &:after {
        border: 10px solid ${({ theme }) => theme.colors.niagaraLight} !important;
        width: 20px;
        height: 20px;
        top: 8px;
        left: 8px;
        content: '';
        position: absolute;
        border-radius: 16px;
      }
    }
  }
`;
