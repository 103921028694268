import { useTranslation } from 'react-i18next';

import { useReferralsSummary } from './useReferralsSummary';
import { Wrapper, Column, Title, Value } from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

export const Summary = () => {
  const referralsSummary = useReferralsSummary();
  const { t } = useTranslation();

  const totalEarnedFormatted = formatNumber({
    value: referralsSummary?.total_earned || 0,
    minimumFractionDigits: 1,
  });

  return (
    <Wrapper>
      <Column>
        <Title>{t('referralsPage.summary.earning')}</Title>

        <Value>{totalEarnedFormatted}</Value>
      </Column>

      <Column>
        <Title>{t('referralsPage.summary.invited')}</Title>

        <Value>{referralsSummary?.friends_invited?.toString?.() ?? '0'}</Value>
      </Column>
    </Wrapper>
  );
};
