import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

const theme = createMuiTheme();

const renderTextField = ({
  input,
  label,
  isShowPasswordToggle,
  showPassword,
  showValidEmail,
  autoFillField,
  defaultValue,
  onChange,
  meta: { touched, error },
  // omit values from custom
  trimString: _trimString,
  name: _name,
  ...custom
}) => (
  <div className="relative">
    {autoFillField ? (
      <TextField
        name={input.name}
        type={input.type}
        error={!!(touched && error)}
        helperText={touched && error}
        value={input.value}
        label={label}
        fullWidth
        onChange={onChange || input.onChange}
        {...custom}
        id={custom.id ?? input.name}
      />
    ) : (
      <TextField
        name={input.name}
        type={input.type}
        error={!!(touched && error)}
        helperText={touched && error}
        value={input.value}
        label={label}
        fullWidth
        onChange={onChange || input.onChange}
        // INFO: Fix "Warning: ForwardRef(InputBase) contains an input of type text with both value and defaultValue props."
        // If value is provided - we should not pass defaultValue
        {...(input.value ? undefined : defaultValue)}
        {...custom}
        id={custom.id ?? input.name}
      />
    )}

    {isShowPasswordToggle && (
      <i
        onClick={showPassword}
        tabIndex="0"
        role="button"
        onKeyPress={() => {}}
        className={`show-hide-password ${
          input.type === 'text' ? '-inactive' : '-active'
        }`}
      />
    )}

    {showValidEmail && <i className="-valid-email" />}
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  autoFillField: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  isShowPasswordToggle: PropTypes.bool,
  showPassword: PropTypes.func,
  showValidEmail: PropTypes.bool,
  trimString: PropTypes.bool,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.bool,
};

renderTextField.defaultProps = {
  isShowPasswordToggle: false,
  showPassword: () => {},
  showValidEmail: false,
  trimString: false,
  defaultValue: '',
  name: null,
  onChange: () => {},
};

const onKeyUp = (e, isNumberOnly, isTextOnly) => {
  if (e && e.target && e.target.value) {
    if (isNumberOnly) {
      e.target.value = e.target.value.replace(/\D/g, '');
    } else if (isTextOnly) {
      e.target.value = e.target.value.replace(/[0-9]/g, '');
    }
  }
};

export default function FinalFormInput(props) {
  const {
    defaultValue,
    label,
    type,
    placeholder,
    onClick,
    showValidEmail,
    id,
    name,
    readOnly,
    autoFocus,
    disabled,
    showPassword,
    isShowPasswordToggle,
    fieldsError,
    maxLength,
    isNumberOnly,
    autoFillField,
    isTextOnly,
    trimString,
    onChange,
    key,
  } = props;

  let { className } = props;

  if (fieldsError.length !== 0 && fieldsError.includes(name)) {
    className += ' error';
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Field
        key={key}
        name={name}
        type={type}
        placeholder={placeholder}
        onClick={onClick}
        onChange={onChange}
        showValidEmail={showValidEmail}
        trimString={trimString}
        id={id}
        readOnly={readOnly}
        autoFocus={autoFocus}
        disabled={disabled}
        showPassword={showPassword}
        isShowPasswordToggle={isShowPasswordToggle}
        component={renderTextField}
        label={label}
        defaultValue={defaultValue}
        className={className}
        inputProps={{ maxLength }}
        autoFillField={autoFillField}
        onKeyUp={
          isNumberOnly || isTextOnly
            ? (e) => onKeyUp(e, isNumberOnly, isTextOnly)
            : () => {}
        }
        onMouseOut={
          isNumberOnly || isTextOnly || trimString
            ? (e) => onKeyUp(e, isNumberOnly, isTextOnly, trimString)
            : () => {}
        }
        onBlur={
          isNumberOnly || isTextOnly || trimString
            ? (e) => onKeyUp(e, isNumberOnly, isTextOnly, trimString)
            : () => {}
        }
      />
    </MuiThemeProvider>
  );
}

FinalFormInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}),
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  showValidEmail: PropTypes.bool,
  trimString: PropTypes.bool,
  showPassword: PropTypes.func,
  isShowPasswordToggle: PropTypes.bool,
  fieldsError: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.string,
  key: PropTypes.string,
  isNumberOnly: PropTypes.bool,
  isTextOnly: PropTypes.bool,
  onChange: PropTypes.func,
  autoFillField: PropTypes.bool,
};

FinalFormInput.defaultProps = {
  input: {},
  className: 'without-underline',
  placeholder: '',
  key: '',
  label: null,
  defaultValue: '',
  onClick: null,
  name: null,
  id: null,
  type: 'text',
  readOnly: false,
  autoFocus: false,
  disabled: null,
  showPassword: null,
  isShowPasswordToggle: false,
  showValidEmail: false,
  trimString: false,
  fieldsError: [],
  maxLength: null,
  isNumberOnly: false,
  isTextOnly: false,
  onChange: null,
  autoFillField: false,
};
