import { useTranslation, Trans } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContactLink,
  CreateAccountButton,
  FormStyled,
  Header,
  HelperText,
  TermsWrapper,
  Title,
  Wrapper,
  InfoIcon,
} from './styles';
import { usePasswordMigrationSchema } from './passwordMigrationFormConfig';
import { useSetMigrationToken } from './useSetMigrationToken';
import { useNavigateOnSuccess } from './useNavigateOnSuccess';
import Logo from './components/Logo/Logo';

import getMigrationFlow from 'utils/getMigrationFlow';
import { InputField } from 'components/form/input';
import {
  PRIVACY_POLICY,
  SUPPORT,
  TERMS_CONDITIONS,
} from 'constants/localeConfigs';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { setNewPassword } from 'store/migration/actions';
import { selectSetNewPassword } from 'store/migration/selector';
import { REQUEST_STATUS } from 'constants/requestStatus';
import { MIGRATION_FLOW } from 'constants/migrationConstants';

export const AccountMigrationPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const newPasswordStatus = useSelector(selectSetNewPassword);

  const schema = usePasswordMigrationSchema();

  const defaultValues = {
    confirm_password: '',
    password: '',
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(({ password }) => {
    dispatch(setNewPassword({ password }));
  });

  useSetMigrationToken();

  useNavigateOnSuccess();

  const migrationFlow = getMigrationFlow();

  const titleText =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.auth.createYourRaizAccount')
      : t('registrationPage.accountMigration.passwordPage.setPassword');

  const buttonText =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.passwordPage.signUpButton')
      : t('registrationPage.accountMigration.passwordPage.submit');

  return (
    <Wrapper>
      <Header>
        <Title>{titleText}</Title>

        <Logo />
      </Header>

      <FormProvider {...form}>
        <FormStyled onSubmit={onSubmit}>
          <InputField name="password" label={t('login.password')} isPassword />

          <InputField
            name="confirm_password"
            label={t('fields.confirmPassword.label')}
            isPassword
          />

          <HelperText>
            <InfoIcon />

            {t('registrationPage.accountMigration.passwordPage.helpText')}
          </HelperText>

          <CreateAccountButton
            type="submit"
            isLoading={newPasswordStatus === REQUEST_STATUS.LOADING}
            label={buttonText}
          />
        </FormStyled>
      </FormProvider>

      <ContactLink href={SUPPORT}>
        {t('registrationPage.auth.contactUs')}
      </ContactLink>

      <Trans
        parent={TermsWrapper}
        i18nKey="registrationPage.auth.bySigning"
        t={t}
        components={{
          toc: (
            <ExternalLink
              title={t('registrationPage.auth.conditions')}
              href={TERMS_CONDITIONS}
            />
          ),
          privacy: (
            <ExternalLink
              title={t('registrationPage.auth.privacy')}
              href={PRIVACY_POLICY}
            />
          ),
        }}
      />
    </Wrapper>
  );
};
