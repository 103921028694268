import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EditPortfolioThemeColorItem from './EditPortfolioThemeColorItem';
import {
  PortfolioThemeEditColorContent,
  PortfolioThemeEditGradientTitle,
  PortfolioThemeEditColorWrapper,
} from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectProPortfolioColors } from 'store/portfolio/selectors';

export default function EditPortfolioThemeColor({
  updatedPortfolioColor,
  setUpdatedPortfolioColor,
}) {
  const { t } = useTranslation();
  const proPortfolioColors = useAppSelector(selectProPortfolioColors);

  return (
    <PortfolioThemeEditColorWrapper>
      <PortfolioThemeEditGradientTitle>
        {t('portfolio.editThemeModal.color.title')}
      </PortfolioThemeEditGradientTitle>

      <PortfolioThemeEditColorContent>
        {proPortfolioColors.map((color, index) => {
          const gradient = `${color.start}, ${color.end}`;

          const isSelected = updatedPortfolioColor.id
            ? updatedPortfolioColor.id === color.id
            : index === 0;

          return (
            <EditPortfolioThemeColorItem
              key={gradient}
              gradient={gradient}
              isSelected={isSelected}
              onClick={() => setUpdatedPortfolioColor(color)}
            />
          );
        })}
      </PortfolioThemeEditColorContent>
    </PortfolioThemeEditColorWrapper>
  );
}

EditPortfolioThemeColor.propTypes = {
  updatedPortfolioColor: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setUpdatedPortfolioColor: PropTypes.func.isRequired,
};
