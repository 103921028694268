import { useEffect, useState } from 'react';

import { useRoundUpsSettingsQuery } from 'store/roundUpsSettings/roundUpsSettings';
import { roundUpsSettingsInitialData } from 'store/roundUpsSettings/consts';

export const useRoundUpsSettingsState = () => {
  const { data, isLoading } = useRoundUpsSettingsQuery();

  const [roundUpsSettings, setRoundUpsSettings] = useState({
    ...roundUpsSettingsInitialData,
    ...data,
  });

  useEffect(() => {
    if (data) {
      setRoundUpsSettings(data);
    }
  }, [data]);

  return { roundUpsSettings, setRoundUpsSettings, isLoading };
};
