import { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useUpdateStoreMutation } from 'store/user/mutations';

export const useUpdateStore = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [updateStore] = useUpdateStoreMutation();

  const queryParams = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams],
  );

  const data = useMemo(
    () => ({ path: pathname, value: queryParams }),
    [pathname, queryParams],
  );

  useEffect(() => {
    if (!Object.keys(queryParams).length) return;

    updateStore({ data });
  }, [updateStore, queryParams, data]);
};
