import { useSearchParams } from 'react-router-dom';

import { OFFER_SOURCES, REWARDS_SEARCH_PARAMS } from '../consts';

export const useRewardsCurrentTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(REWARDS_SEARCH_PARAMS.tab);

  const currentOfferType = searchParams.get(REWARDS_SEARCH_PARAMS.offerType);

  const setCurrentTab = () =>
    setSearchParams({
      [REWARDS_SEARCH_PARAMS.tab]:
        currentTab === OFFER_SOURCES.card
          ? OFFER_SOURCES.account
          : OFFER_SOURCES.card,
    });

  return {
    currentTab,
    setCurrentTab,
    currentOfferType,
  };
};
