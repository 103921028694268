import React from 'react';

import { useKids } from '../hooks';

import { KidsWidget } from './styles';
import KidsWidgetItem from './KidsWidgetItem';
import NewChild from './NewChild';

import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';

export default function KidsContent() {
  const { isLoading, isNewChild, kidsList } = useKids();

  if (isLoading) {
    return <DeprecatedDotsLoader />;
  }

  if (isNewChild) {
    return <NewChild />;
  }

  return (
    <KidsWidget>
      {kidsList?.map((child) => (
        <KidsWidgetItem child={child} key={child.id} />
      ))}
    </KidsWidget>
  );
}
