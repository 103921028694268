import React from 'react';
import { useSelector } from 'react-redux';

import { usePortfolioCustomizationRoute } from '../../../hooks';

import { HeaderDescription, HeaderTitle } from './styles';
import FundMaxWeighting from './FundMaxWeighting';

export default function HeaderContent() {
  const { fundDetails } = useSelector((state) => ({
    fundDetails: state.portfolio?.fundDetails || {},
  }));

  const { isBitcoinFundPage, isResidentialPropertyFundsDetailsPage } =
    usePortfolioCustomizationRoute();

  const { name, description, symbol, id } = fundDetails;

  if (isBitcoinFundPage) {
    return <HeaderTitle>{`${name} (${symbol})`}</HeaderTitle>;
  }

  return (
    <>
      <HeaderTitle>{name}</HeaderTitle>

      {!isResidentialPropertyFundsDetailsPage && (
        <HeaderDescription>{description}</HeaderDescription>
      )}

      <FundMaxWeighting isDetailsPage id={id} />
    </>
  );
}
