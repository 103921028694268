import styled from '@emotion/styled';

import arrowRight from 'assets/images/icons/arrow-right.svg';

export const MenuButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mirage};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  height: 56px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  padding-left: 20px;
  padding: 16px 0 16px 16px;
  position: relative;
  width: 100%;
`;

export const ArrowIcon = styled.i`
  background-image: url(${`${arrowRight}`});
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  display: block;
  float: right;
  height: 24px;
  margin-left: auto;
  margin-right: 14px;
  width: 24px;
`;
