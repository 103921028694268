import dayjs from 'dayjs';

import { DATE_FORMAT_WITH_TIME } from '../../../config/date';
import { LOADING_START_TIME } from '../consts';

export const setLoadingStartTime = () => {
  sessionStorage.setItem(
    LOADING_START_TIME,
    dayjs().format(DATE_FORMAT_WITH_TIME),
  );
};

export const getLoadingDuration = () =>
  dayjs().diff(sessionStorage.getItem(LOADING_START_TIME), 'seconds');
