import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import SuperView from './SuperView';

import * as userActions from 'store/user/actions';
import * as superActions from 'store/superAnnuation/actions';
import { SUPER_REGISTRATION_PAGE } from 'constants/comonConstants';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class Super extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      showMainLoader: true,
    };

    this.checkAlerts = this.checkAlerts.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { actions, history } = this.props;
    const userFromStorage = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
    const user = userFromStorage ? JSON.parse(userFromStorage) : {};

    if (user && user.user && !user.user.super_annuation_user) {
      history.push(SUPER_REGISTRATION_PAGE);

      return;
    }

    actions.getSuperUserData();

    this.setState({ showMainLoader: true });

    actions.getSuperUserAccounts().then(() => {
      if (this._isMounted) {
        const { userAccounts } = this.props;

        if (userAccounts.account && userAccounts.account.alerts) {
          this.checkAlerts(userAccounts.account.alerts);
        } else {
          this.setState({ showMainLoader: false });
        }
      }
    });

    actions.getPortfolios(true);

    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let isNeedSetPortfolio = false;

    if (
      nextProps.superUser !== prevState.superUser &&
      nextProps.superUser.allocation_profile_id &&
      nextProps.portfolios &&
      nextProps.portfolios.length !== 0
    ) {
      isNeedSetPortfolio = true;
    }

    if (
      nextProps.portfolios &&
      nextProps.portfolios.length !== 0 &&
      isNeedSetPortfolio
    ) {
      return {
        superUser: nextProps.superUser,
        portfolios: nextProps.portfolios,
      };
    }

    return null;
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetProps();

    this._isMounted = false;
  }

  checkAlerts(alerts) {
    const { actionsSuper, history } = this.props;

    alerts.map((alert) => {
      if (alert.type === 'CONSENT_PENDING') {
        actionsSuper.goToConsent();

        this.setState({ showMainLoader: false });

        history.push(SUPER_REGISTRATION_PAGE);
      }

      return alert;
    });

    this.setState({ showMainLoader: false });
  }

  render() {
    const { errorMessage, showMainLoader } = this.state;

    const { superUser, t } = this.props;

    return (
      <SuperView
        errorMessage={errorMessage}
        currentBalance={superUser.current_balance}
        title={t('superPage.title')}
        showMainLoader={showMainLoader}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  errorMessage: state.user.errorMessage || state.superAnnuation.errorMessage,
  portfolios: state.user.portfolios,
  superUser: state.user.superUser,
  userAccounts: state.user.userAccounts,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  actionsSuper: bindActionCreators(superActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Super));

Super.defaultProps = {
  actionsSuper: {},
  superUser: {},
  userAccounts: {},
  portfolios: [],
};

Super.propTypes = {
  actions: PropTypes.shape({
    getSuperUserData: PropTypes.func,
    getSuperUserAccounts: PropTypes.func,
    getPortfolios: PropTypes.func,
    resetProps: PropTypes.func,
  }).isRequired,
  actionsSuper: PropTypes.shape({
    goToConsent: PropTypes.func,
  }),
  superUser: PropTypes.shape({
    current_balance: PropTypes.number,
    allocation_profile_id: PropTypes.string,
  }),
  userAccounts: PropTypes.shape({
    account: PropTypes.shape({
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  portfolios: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
