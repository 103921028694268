import { useTranslation } from 'react-i18next';

import {
  BillerItem,
  BillerWrapper,
  BPayImage,
  BPayInfoWrapper,
  RaizCode,
} from './styles';

import { useSuperUserQuery } from 'store/superAnnuation/superAnnuation';

export const BPayInfo = () => {
  const superUserQuery = useSuperUserQuery();
  const { raiz_code, raiz_crn_personal } = superUserQuery?.data?.bpay || {};

  const { t } = useTranslation();

  return (
    <BPayInfoWrapper>
      <BPayImage />

      <BillerWrapper>
        <BillerItem>
          {t('superPage.superInfo.billerCode')}

          <RaizCode>{raiz_code}</RaizCode>
        </BillerItem>

        <BillerItem>
          {t('superPage.superInfo.ref')}

          <RaizCode>{raiz_crn_personal}</RaizCode>
        </BillerItem>
      </BillerWrapper>
    </BPayInfoWrapper>
  );
};
