import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';

import { GET_BRANDS_SLIDER_SUCCESS } from '../../../store/rewards/types';
import getCarouselSettings from '../utils/getCarouselSettings';

import OfferSlider from './OfferSlider';
import OfferSliderSkeleton from './OfferSliderSkeleton';

export default function BrandsCarousel({ sliders, saveInvested, type }) {
  const carouselSettings = getCarouselSettings();

  if (sliders?.length || type === GET_BRANDS_SLIDER_SUCCESS) {
    return (
      <Carousel
        responsive={carouselSettings.responsive}
        ssr
        containerClass="grid__carousel"
        slidesToSlide={carouselSettings.slidesToSlide}
      >
        {sliders.map((offer) => (
          <OfferSlider
            key={offer.id}
            offer={offer}
            saveInvested={saveInvested}
          />
        ))}
      </Carousel>
    );
  }

  return <OfferSliderSkeleton />;
}

BrandsCarousel.propTypes = {
  type: PropTypes.string.isRequired,
  saveInvested: PropTypes.func.isRequired,
  sliders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
