import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Form } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import {
  INVESTMENT_INITIAL,
  REGISTRATION_PERSONAL_INFORMATION,
} from '../../../store/dependentUser/types';
import {
  AU_APP,
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  MY_APP,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import { KIDS_INITIAL_INVESTMENTS } from '../consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { INVESTMENT_TYPES } from 'constants/kidsConstants';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function KidsInitialInvestment({
  goBack,
  handleSubmit,
  changeOneTimeInvestmentValue,
  oneTimeInvestmentValue,
  allowType,
  changeAccessInvestment,
  changeUnlimitedAmount,
  unlimAmount,
  isEditChild,
  child,
  isOpenInvestment,
  showLoader,
  isKidsRegistration,
}) {
  const { t } = useTranslation();
  const minInvest = 5;
  const dispatch = useAppDispatch();

  const isAllow = allowType === INVESTMENT_TYPES.allow;
  const isNotAllow = allowType === INVESTMENT_TYPES.notAllow;
  const isAllowUnlimited = allowType === INVESTMENT_TYPES.allowWeekly;

  const accountInfo =
    isEditChild && child
      ? child
      : JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
        );

  const isAccountAccess =
    accountInfo && accountInfo.dependency_user
      ? accountInfo.dependency_user.account_access
      : false;

  const handleConfirmInvestment = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(
            'raizKidsPage.kidsInitialInvestmentModal.confirmation.title',
          ),
          description: t(
            'raizKidsPage.kidsInitialInvestmentModal.confirmation.description',
            {
              amount: formatNumber({
                value: oneTimeInvestmentValue,
                minimumFractionDigits: 0,
              }),
            },
          ),
          acceptButtonText: t('common.invest'),
          rejectButtonText: t('common.cancel'),
          onAccept: handleSubmit,
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpenInvestment}
      onRequestClose={(e) => {
        goBack({
          e,
          path: REGISTRATION_PERSONAL_INFORMATION,
          isModalOpen: !isEditChild,
        });
      }}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      className="modal-basic time-investment__modal"
    >
      {isEditChild && (
        <DeprecatedButton
          buttonClass="button__close -white"
          handleClick={(e) => goBack({ e, isModalOpen: false })}
        />
      )}

      <div className="modal-basic__content -kids radio">
        {AU_APP && (
          <>
            <div className="modal-basic__title">
              {t(
                'raizKidsPage.kidsInitialInvestmentModal.initialInvestAmount',
                { name: child.name },
              )}
            </div>

            <div className="modal-basic__label">
              {isEditChild
                ? t('raizKidsPage.kidsInitialInvestmentModal.investLumpSum', {
                    name: child.name,
                  })
                : t('raizKidsPage.kidsInitialInvestmentModal.description')}
            </div>
          </>
        )}

        {MY_APP &&
          (isKidsRegistration ? (
            <>
              <div className="modal-basic__title">
                {t(
                  'raizKidsPage.kidsInitialInvestmentModal.initialInvestAmount',
                  { name: child.name },
                )}
              </div>

              <div className="modal-basic__label">
                {t('raizKidsPage.kidsInitialInvestmentModal.description')}
              </div>
            </>
          ) : (
            <div className="modal-basic__title">
              {t('raizKidsPage.kidsInitialInvestmentModal.investLumpSum', {
                name: child.name,
              })}
            </div>
          ))}

        <div className="modal-basic__value -with-margin">
          <div
            className={`modal-basic__input ${
              oneTimeInvestmentValue >= minInvest ? '-active' : ''
            } time-investment__input`}
          >
            <NumberFormat
              value={oneTimeInvestmentValue || ''}
              displayType="input"
              thousandSeparator={THOUSAND_SEPARATOR}
              decimalSeparator={DECIMAL_SEPARATOR}
              placeholder={`${CURRENCY_SYMBOL}0.00`}
              decimalScale={2}
              allowNegative={false}
              fixedDecimalScale={oneTimeInvestmentValue % 1 !== 0}
              prefix={CURRENCY_SYMBOL}
              onValueChange={(values) => {
                changeOneTimeInvestmentValue(values.floatValue);
              }}
            />
          </div>
        </div>

        <div className="time-investment__buttons">
          {KIDS_INITIAL_INVESTMENTS.map((investment, index) => (
            <DeprecatedButton
              key={`Initial_investment_button_${investment}`}
              title={
                <NumberFormat
                  value={investment}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  prefix={CURRENCY_SYMBOL}
                  decimalScale={index === 0 ? 4 : 2}
                />
              }
              buttonClass="button__rounded-transparent"
              handleClick={() => changeOneTimeInvestmentValue(investment)}
            />
          ))}
        </div>
      </div>

      {!isEditChild ? (
        <div className="raiz-kids__invest-access">
          <div className="raiz-kids__invest-access-title">
            {t('raizKidsPage.kidsInitialInvestmentModal.form.header')}
          </div>

          <Form
            onSubmit={(e) => handleSubmit(e, INVESTMENT_INITIAL)}
            render={({ form, values, hasValidationErrors }) => (
              <form
                className="form raiz-kids__profile"
                onSubmit={(e) =>
                  handleSubmit(
                    e,
                    values,
                    form,
                    hasValidationErrors,
                    INVESTMENT_INITIAL,
                  )
                }
              >
                <div className="checkbox__container-mix">
                  <CheckboxFF
                    basicCheckbox
                    name="allow"
                    defaultChecked={isNotAllow}
                    onClick={() => {
                      changeAccessInvestment('notAllow');
                    }}
                    className="checkbox__basic-mix"
                    type="checkbox"
                    label=""
                  />

                  <div className="checkbox__label-mix">
                    {t(
                      'raizKidsPage.kidsInitialInvestmentModal.form.limits.notAllowed',
                    )}
                  </div>
                </div>

                <div
                  className={`checkbox__container-mix ${
                    isAccountAccess ? '' : '-disabled'
                  }`}
                >
                  <CheckboxFF
                    basicCheckbox
                    name="allow"
                    defaultChecked={isAllow}
                    onClick={() => {
                      changeAccessInvestment('allow');
                    }}
                    className="checkbox__basic-mix"
                    type="checkbox"
                    disabled={!isAccountAccess}
                    label=""
                  />

                  <div className="checkbox__label-mix">
                    {t(
                      'raizKidsPage.kidsInitialInvestmentModal.form.limits.unlimited',
                    )}
                  </div>
                </div>

                <div
                  className={`checkbox__container-mix ${
                    isAccountAccess ? '' : '-disabled'
                  }`}
                >
                  <CheckboxFF
                    basicCheckbox
                    name="allow"
                    defaultChecked={isAllowUnlimited}
                    onClick={() => {
                      changeAccessInvestment(INVESTMENT_TYPES.allowWeekly);
                    }}
                    className="checkbox__basic-mix"
                    disabled={!isAccountAccess}
                    type="checkbox"
                    label=""
                  />

                  <div className="checkbox__label-mix">
                    {t(
                      'raizKidsPage.kidsInitialInvestmentModal.form.limits.allowWeekly',
                    )}
                  </div>
                </div>

                {isAllowUnlimited && (
                  <div className="raiz-kids__investment-limit">
                    {t(
                      'raizKidsPage.kidsInitialInvestmentModal.form.limits.weekly',
                    )}

                    <div className="raiz-kids__investment-value">
                      <NumberFormat
                        value={unlimAmount}
                        displayType="input"
                        thousandSeparator={THOUSAND_SEPARATOR}
                        decimalSeparator={DECIMAL_SEPARATOR}
                        placeholder={`${CURRENCY_SYMBOL}0`}
                        prefix={CURRENCY_SYMBOL}
                        onValueChange={(val) => {
                          changeUnlimitedAmount(val.floatValue);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="buttons-part">
                  <div className="form-field">
                    <DeprecatedButton
                      withLoader={showLoader}
                      title={t('common.back')}
                      buttonClass="button back"
                      handleClick={(e) =>
                        goBack({ e, path: REGISTRATION_PERSONAL_INFORMATION })
                      }
                    />
                  </div>

                  <div className="form-field">
                    <DeprecatedButton
                      disabled={
                        (isAllowUnlimited && unlimAmount < 5) ||
                        (isAllowUnlimited && !unlimAmount)
                      }
                      withLoader={showLoader}
                      type="submit"
                      title={t(
                        'raizKidsPage.kidsInitialInvestmentModal.submitButton',
                      )}
                      buttonClass="button button--submit"
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      ) : (
        <div className="form-field">
          <DeprecatedButton
            handleClick={handleConfirmInvestment}
            title={t('raizKidsPage.kidsInitialInvestmentModal.submitButton')}
            buttonClass="button button--submit raiz-kids__invest-btn"
          />
        </div>
      )}
    </Modal>
  );
}

KidsInitialInvestment.propTypes = {
  child: PropTypes.shape({
    name: PropTypes.string,
  }),
  showLoader: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeOneTimeInvestmentValue: PropTypes.func.isRequired,
  changeAccessInvestment: PropTypes.func,
  changeUnlimitedAmount: PropTypes.func,
  oneTimeInvestmentValue: PropTypes.number.isRequired,
  unlimAmount: PropTypes.string,
  allowType: PropTypes.string,
  isEditChild: PropTypes.bool,
  isOpenInvestment: PropTypes.bool,
  isKidsRegistration: PropTypes.bool,
};

KidsInitialInvestment.defaultProps = {
  isEditChild: false,
  isOpenInvestment: false,
  child: {},
  allowType: '',
  changeAccessInvestment: () => {},
  unlimAmount: '',
  changeUnlimitedAmount: () => {},
  showLoader: false,
  isKidsRegistration: false,
};
