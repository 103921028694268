import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectChild } from 'store/dependentUser/selectors';
import { useRecurringRemittanceSettingsQuery } from 'store/rewards/api';
import { useIsRecurringZakatPayment } from 'pages/RewardDetails/subpages/RecurringZakatPayment/utils/useIsRecurringZakatPayment';

export const useRecurringInvestment = () => {
  const isRecurringZakatPayment = useIsRecurringZakatPayment();
  const { recurring } = useAppSelector(selectChild);

  const { data } = useRecurringRemittanceSettingsQuery(undefined, {
    skip: !isRecurringZakatPayment,
  });

  return {
    isRecurringZakatPayment,
    recurring: isRecurringZakatPayment ? data : recurring,
  };
};
