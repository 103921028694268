import { defineMessages } from 'react-intl';

import { ROUND_UPS as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.invested`]: 'Setuju',
  [`${idPrefix}.invest`]: 'Dilaburkan',
  [`${idPrefix}.cancel`]: 'Batal',
  [`${idPrefix}.roundUps`]: 'Auto-bundar',
  [`${idPrefix}.manualRound`]: 'Pembundaran manual',
  [`${idPrefix}.autoRound`]: 'Pembundaran automatik',
  [`${idPrefix}.all`]: 'Semua',
  [`${idPrefix}.investedForm`]: 'Dilaburkan dari pembundaran',
  [`${idPrefix}.investedFrom`]: 'Dilaburkan dari pembundaran',
  [`${idPrefix}.until`]: '{start} sehingga {end}',
  [`${idPrefix}.enableAutoRound`]: 'Aktifkan pembundaran automatik',
  [`${idPrefix}.tapToInvest`]: 'klik untuk melabur',
  [`${idPrefix}.available`]: 'Mempunyai',
  [`${idPrefix}.investNow`]: 'Melabur Sekarang',
  [`${idPrefix}.wantToInvestThreshold`]:
    'Adakah anda ingin melabur %@ sekarang untuk mencapai ' +
    'ambang {invest}?<br>Proses pemindahan mungkin mengambil masa 1-5 hari bekerja. Secara umumnya, deposit ' +
    'dilaburkan pada hari bekerja berikutnya.',
  [`${idPrefix}.details`]: 'Perincian',
  [`${idPrefix}.notFoundAll`]: 'Anda delum mempunyai perbelanjaan. ',
  [`${idPrefix}.notFoundInvested`]: 'Anda delum mempunyai perbelanjaan. ',
  [`${idPrefix}.notFoundAvailable`]: 'Anda delum mempunyai perbelanjaan. ',
});

export default messages;
