import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Props } from 'react-input-mask';

import { ErrorLabel, Label } from '../styles';

import { INPUT_MASK } from './consts';
import { InputMaskStyled, InputMaskWrapper } from './styles';

interface IInputMaskFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  inputMaskProps?: Props;
}

export const InputMaskField = <T extends FieldValues>({
  name,
  label,
  inputMaskProps = { mask: INPUT_MASK },
}: IInputMaskFieldProps<T>) => {
  const { control, setFocus } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMaskWrapper>
          <Label>{label}</Label>

          <InputMaskStyled
            {...inputMaskProps}
            {...field}
            alwaysShowMask
            placeholder={label}
            ref={() => {
              field.ref({
                focus: setFocus,
              });
            }}
          />

          {!!error?.message && <ErrorLabel>{error?.message}</ErrorLabel>}
        </InputMaskWrapper>
      )}
    />
  );
};
