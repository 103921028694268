import styled from '@emotion/styled';

import illustration from 'assets/images/reg-illustration_1.png';

export const InfoPanelWrapper = styled.div`
  flex: 4;
  background: ${({ theme }) => theme.gradients.greenishCyan};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.tablet)} {
    display: none;
  }
`;

export const InfoPanelContentWrapper = styled.div`
  padding: 70px 110px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.tablet)} {
    padding: 55px 55px;
  }
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const InfoPanelTitle = styled.div`
  padding: 85px 0 20px 0;
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  text-align: center;
`;

export const InfoPanelDescription = styled.div`
  text-align: justify;
`;

export const InfoPanelContentTop = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InfoPanelBottomText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  bottom: 25px;
  text-align: center;
`;

export const BodyBackground = styled.div`
  background: url(${illustration}) no-repeat center;
  background-size: contain;
  flex: 0.6;
  margin: 86px 0 50px 0;
`;

export const InfoPanelText = styled.div`
  text-align: center;
`;
