import React from 'react';

import { PORTFOLIO_PLANS } from '../consts';

import { PortfolioPlanSwitchWrapper } from './styles';
import PortfolioTypeSwitchButton from './PortfolioPlanSwitchButton';

export default function PortfolioPlanSwitch() {
  return (
    <PortfolioPlanSwitchWrapper>
      {Object.keys(PORTFOLIO_PLANS).map((portfolioPlan) => (
        <PortfolioTypeSwitchButton
          key={portfolioPlan}
          portfolioPlan={portfolioPlan}
        />
      ))}
    </PortfolioPlanSwitchWrapper>
  );
}

PortfolioPlanSwitch.propTypes = {};
