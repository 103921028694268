export const SKIP_BANK = 'SKIP_BANK';
export const GET_MORE_BANKS = 'GET_MORE_BANKS';
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const RESPONSE_FROM_BANK_SUCCESS = 'RESPONSE_FROM_BANK_SUCCESS';
export const SHOW_ERROR_POPUP_BANK_LOGIN = 'SHOW_ERROR_POPUP_BANK_LOGIN';
export const BACK_FROM_MORE_BANKS = 'BACK_FROM_MORE_BANKS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REGISTRATION_BANKS_FUNDING_ACCOUNT =
  '/registration/banks/fundingAccount';
export const GET_FUNDING_ACCOUNT_SUCCESS = 'GET_FUNDING_ACCOUNT_SUCCESS';
export const REGISTRATION_ACCOUNT_PORTFOLIO = '/registration/account/portfolio';
export const SHOW_GLOBAL_ERROR = 'SHOW_GLOBAL_ERROR';
export const SHOW_WAIT_POPUP_BANK_LOGIN = 'SHOW_WAIT_POPUP_BANK_LOGIN';
export const WAITING_IN_PROCESS = 'WAITING_IN_PROCESS';
export const SHOW_ERROR_POPUP_PAYMENT_CARD = 'SHOW_ERROR_POPUP_PAYMENT_CARD';
export const REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT =
  '/registration/banks/withdrawalAccount';
export const GET_FUNDING_PAYMENT_CARD_SUCCESS =
  'GET_FUNDING_PAYMENT_CARD_SUCCESS';
export const REGISTRATION_ACCOUNT_INFORMATION =
  '/registration/account/information';
export const GET_WITHDRAWAL_ACCOUNT_SUCCESS = 'GET_WITHDRAWAL_ACCOUNT_SUCCESS';
export const CLOSE_CONNECT_SCREEN = 'CLOSE_CONNECT_SCREEN';
export const SHOW_CONNECT_SCREEN = 'SHOW_CONNECT_SCREEN';
export const SHOW_MFA_FIELD = 'SHOW_MFA_FIELD';
export const SHOW_LOADER = 'SHOW_LOADER';
export const RESET_PROPS = 'RESET_PROPS';
export const SHOW_VERIFICATION_MODAL = 'SHOW_VERIFICATION_MODAL';
export const GET_SUPER_USER_DATA_SUCCESS = 'GET_SUPER_USER_DATA_SUCCESS';
export const SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS =
  'SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS';
export const SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS =
  'SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS';
export const PORTFOLIO_SAVE_SUCCESS = 'PORTFOLIO_SAVE_SUCCESS';
export const REGISTRATION_PERSONAL_INFO = '/registration/personal/info';
export const ACCESS_SELECTED = 'ACCESS_SELECTED';
export const HIDE_LOADER = 'HIDE_LOADER';
export const OPEN_LOGOUT_MODAL = 'OPEN_LOGOUT_MODAL';
export const CLOSE_LOGOUT_MODAL = 'CLOSE_LOGOUT_MODAL';
