import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function PerfomanceErrorModal({
  isShowErrorModal,
  errorMessage,
  accountValue,
  allocationProfiles,
  renderEtfModal,
  handleCloseModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowErrorModal}
      onRequestClose={handleCloseModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert perfomance__alert"
    >
      <div className="modal-alert-content perfomance-modal">
        {errorMessage !== '' && (
          <div className="alert-text">{errorMessage}</div>
        )}

        {errorMessage === '' && (
          <div>
            <div className="alert-title">
              {t('performanceDetailsPage.accountValue')}
            </div>

            <div className="info-panel my-finance__spend">
              <div className="account-value">{accountValue}</div>

              {allocationProfiles &&
                allocationProfiles.map((allocationProfile) =>
                  renderEtfModal(allocationProfile),
                )}

              <div className="bottom-text">
                {t('performanceDetailsPage.errorModal.content')}
              </div>
            </div>
          </div>
        )}

        <div className="error-buttons">
          <DeprecatedButton
            title={t('common.ok')}
            type="button"
            handleClick={handleCloseModal}
          />
        </div>
      </div>
    </Modal>
  );
}

PerfomanceErrorModal.propTypes = {
  isShowErrorModal: PropTypes.bool.isRequired,
  renderEtfModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  accountValue: PropTypes.number.isRequired,
  allocationProfiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
