import 'styles/pages/settings.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function SuperInformationView() {
  const { t } = useTranslation();

  return (
    <div className="settings__content">
      <div className="profile-content settings__content-default-container settings__support">
        <div className="profile-content__header">
          <div className="profile-content__title">
            {t('superPage.superInformation.superInformation')}
          </div>
        </div>

        <div className="profile-content__container">
          <a
            href="https://raizinvest.com.au/super-product-disclosure-statement/"
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('superPage.superInformation.productDisclosureStatement')}
          </a>

          <a
            href="https://raizinvest.com.au/super-support/"
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('superPage.superInformation.superFAQ')}
          </a>

          <Link to="/super/forms" className="page-menu__item">
            {t('superPage.superInformation.superForms')}
          </Link>
        </div>
      </div>
    </div>
  );
}
