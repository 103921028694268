import Firebase from 'firebase/app';

interface IGetFirebaseConfigForKey<T> {
  key: string;
  remoteConfig: Firebase.remoteConfig.RemoteConfig;
  defaultValue?: T | object;
}

const getFirebaseConfigForKey = <T>({
  key,
  remoteConfig,
  defaultValue = {},
}: IGetFirebaseConfigForKey<T>): { [key: string]: T } => {
  const response = remoteConfig.getValue(key).asString();

  const result = response !== '' ? JSON.parse(response) : defaultValue;

  return result;
};

export default getFirebaseConfigForKey;
