import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

import {
  RoundUpsRowTitle,
  MultiplierRoundUpsDescription,
  MultiplierRoundUpsRow,
  MultiplierRoundUpsWrapper,
} from './styles';
import { IRoundUpsMultiplier } from './types';
import { ROUND_UPS_SWITCH_OPTIONS, DISABLED_MULTIPLIER_VALUE } from './consts';
import MultiplierButtons from './MultiplierButtons';

import shadows from 'theme/shadows';
import colors from 'theme/colors';

export default function RoundUpsMultiplier({
  roundUpsSettings,
  handleChangeMultiplier,
  toggleMultiplier,
}: IRoundUpsMultiplier) {
  const { t } = useTranslation();

  const { amount_multiplier, available_amount_multipliers } = roundUpsSettings;

  if (!amount_multiplier || !available_amount_multipliers?.length) {
    return null;
  }

  return (
    <MultiplierRoundUpsWrapper>
      <MultiplierRoundUpsRow>
        <div>
          <RoundUpsRowTitle>
            {t('roundUps.modal.roundUpsMultiplier.title')}
          </RoundUpsRowTitle>

          <MultiplierRoundUpsDescription>
            {t('roundUps.modal.roundUpsMultiplier.description')}
          </MultiplierRoundUpsDescription>
        </div>

        <Switch
          onChange={toggleMultiplier}
          checked={amount_multiplier > DISABLED_MULTIPLIER_VALUE}
          onColor={colors.niagaraLight}
          offColor={colors.alto}
          onHandleColor={colors.white}
          handleDiameter={ROUND_UPS_SWITCH_OPTIONS.handleDiameter}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow={shadows.light}
          activeBoxShadow={ROUND_UPS_SWITCH_OPTIONS.activeBoxShadow}
          height={ROUND_UPS_SWITCH_OPTIONS.height}
          width={ROUND_UPS_SWITCH_OPTIONS.width}
        />
      </MultiplierRoundUpsRow>

      <MultiplierButtons
        roundUpsSettings={roundUpsSettings}
        handleChangeMultiplier={handleChangeMultiplier}
      />
    </MultiplierRoundUpsWrapper>
  );
}
