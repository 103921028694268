import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useIsStatementsNested } from './useIsStatementsNested';
import { useStatementsPath } from './useStatementsPath';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { KIDS_STATEMENTS_MONTHLY } from 'constants/kidsConstants';
import { getDependencyUser } from 'store/dependentUser/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import {
  selectInvestErrorMessage,
  selectStatementsTotalPages,
  selectStatementsUrl,
} from 'store/invest/selectors';
import { STATEMENTS_MONTHLY } from 'constants/comonConstants';
import { getUserData } from 'store/user/actions';
import { getStatements, getStatementsUrl } from 'store/invest/actions';
import { IStatement } from 'store/invest/types';

export const useStatements = () => {
  const dispatch = useAppDispatch();

  const { isRaizKids, isRaizSuper } = useStatementsPath();

  const user = useAppSelector(selectUser);
  const errorMessage = useAppSelector(selectInvestErrorMessage);
  const statementsUrl = useAppSelector(selectStatementsUrl);
  const statementsTotalPages = useAppSelector(selectStatementsTotalPages);

  const [currentType, setCurrentType] = useState(
    isRaizKids ? KIDS_STATEMENTS_MONTHLY : STATEMENTS_MONTHLY,
  );

  const isStatementsNested = useIsStatementsNested({
    currentType,
  });

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isPdfQueryLoading, setIsPdfQueryLoading] = useState(false);
  const [isButtonOpen, setIButtonOpen] = useState(false);
  const [areStatementsLoading, setAreStatementsLoading] = useState(false);

  const { childId } = useParams();

  useEffect(() => {
    dispatch(getUserData());

    if (isRaizKids) {
      dispatch(getDependencyUser());
    }

    dispatch(
      getStatements({
        type: currentType,
        isRaizKids,
        childId,
        isRaizSuper,
        userType: user.user_type,
      }),
    ).then(() => setAreStatementsLoading(true));

    return () => {
      setAreStatementsLoading(false);
    };
  }, [childId, currentType, dispatch, isRaizKids, isRaizSuper, user.user_type]);

  useEffect(() => {
    if (isButtonOpen && !isPdfQueryLoading) {
      if (!statementsUrl) {
        setIsErrorModalOpen(true);

        setIsPdfModalOpen(false);
      }

      setIButtonOpen(false);
    }
  }, [statementsUrl, isButtonOpen, isPdfQueryLoading]);

  const openPdfModal = ({
    isModalOpen,
    id,
    statement,
  }: {
    isModalOpen: boolean;
    id: string;
    statement: IStatement;
  }) => {
    if (isStatementsNested) {
      window.open(statement.download_url, '_blank');
    } else {
      setIButtonOpen(isModalOpen);

      setIsPdfModalOpen(isModalOpen);

      setIsPdfQueryLoading(true);

      if (id) {
        dispatch(getStatementsUrl({ id, type: currentType })).finally(() =>
          setIsPdfQueryLoading(false),
        );
      }
    }
  };

  const toggleErrorModal = (isOpen: boolean) => {
    setIsErrorModalOpen(isOpen);
  };

  const changeCurrentType = (typeName: string) => {
    setCurrentType(typeName);

    dispatch(
      getStatements({
        type: typeName,
        isRaizKids,
        childId,
        userType: user.user_type,
      }),
    ).then(() => {
      if (errorMessage && errorMessage.length) {
        toggleErrorModal(true);
      }
    });
  };

  return {
    currentType,
    changeCurrentType,
    openPdfModal,
    isStatementsNested,
    childId,
    statementsTotalPages,
    areStatementsLoading,
    isPdfModalOpen,
    statementsUrl,
    isPdfQueryLoading,
    isErrorModalOpen,
    errorMessage,
    toggleErrorModal,
  };
};
