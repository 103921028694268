import { INFO_MODAL, OPEN_MODAL, CLOSE_MODAL } from './consts';
import {
  IDisplayErrorModal,
  IModalAction,
  IToggleInfoModalAction,
} from './types';

export const toggleInfoModal = ({
  isInfoModalVisible,
  config,
}: IToggleInfoModalAction) => ({
  infoModal: {
    isOpen: isInfoModalVisible,
    config,
  },
  type: INFO_MODAL,
});

export const displayErrorModal = ({ errorMessage }: IDisplayErrorModal) =>
  toggleInfoModal({
    isInfoModalVisible: true,
    config: {
      title: errorMessage,
      size: 350,
    },
  });

export const openModal = ({ modalName }: IModalAction) => ({
  type: OPEN_MODAL,
  modalName,
});

export const closeModal = (modalName: string) => ({
  type: CLOSE_MODAL,
  modalName,
});
