import { Dot, StatusIcon, StatusText } from './styles';
import { ITransactionStatusProps } from './type';

export const TransactionStatus = ({ status }: ITransactionStatusProps) => (
  <>
    <Dot />

    <StatusIcon status={status} />

    <StatusText>{status}</StatusText>
  </>
);
