import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import '../../../styles/layout/time-investment.scss';
import Firebase from 'firebase/app';
import ReactPixel from 'react-facebook-pixel';
import { withTranslation } from 'react-i18next';

import * as investActions from '../../../store/invest/actions';
import * as modalsActions from '../../../store/modals/actions';
import { CREDIT, MIN_INVEST } from '../../../constants/comonConstants';
import { AU_APP } from '../../../constants/localeConfigs';
import withRouter from '../../../routes/withRouter';
import { checkIsDependentUser } from '../../../utils/user';

import OneTimeInvestmentView from './OneTimeInvestmentView';
import { FUNDING_ACCOUNT_ERROR_MESSAGE } from './consts';

import { selectDependentUserAccess } from 'store/user/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class OneTimeInvestment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenQuickInvestmentModal: false,
      isOpenInvestmentModal: false,
      isOpenMinimumInvestmentModal: false,
      isOpenInvestmentInfoModal: false,
      isOpenInvestmentCongratsModal: false,
      showLoader: false,
      oneTimeInvestmentValue: 0,
    };

    this.openQuickInvestmentModal = this.openQuickInvestmentModal.bind(this);

    this.openInvestmentModal = this.openInvestmentModal.bind(this);

    this.openInvestmentInfoModal = this.openInvestmentInfoModal.bind(this);

    this.oneTimeInvest = this.oneTimeInvest.bind(this);

    this.changeOneTimeInvestmentValue =
      this.changeOneTimeInvestmentValue.bind(this);

    this.openMinimumInvestmentModal =
      this.openMinimumInvestmentModal.bind(this);

    this.openInvestmentCongratsModal =
      this.openInvestmentCongratsModal.bind(this);
  }

  componentDidMount() {
    const currentURL = new window.URLSearchParams(window.location.search);
    const categoryFromURL = currentURL.get('investment-show');
    const valueFromURL = currentURL.get('investment-value');

    this.openInvestmentModal(!!categoryFromURL);

    if (valueFromURL) {
      this.setState({
        oneTimeInvestmentValue: valueFromURL,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpenInvestmentModal } = this.state;

    if (nextProps.isOpenInvestmentModal !== isOpenInvestmentModal) {
      this.openInvestmentModal(nextProps.isOpenInvestmentModal);
    }
  }

  oneTimeInvest(oneTimeInvestmentValue) {
    const {
      actions,
      handleChangeOpenInvestmentModal,
      user,
      dependentUserAccess,
      actionsModals,
      t,
    } = this.props;

    const requestData = {
      amount: oneTimeInvestmentValue,
      type: CREDIT,
    };

    if (
      checkIsDependentUser(user) &&
      dependentUserAccess?.investing_weekly_limit < oneTimeInvestmentValue
    ) {
      actionsModals.toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('common.oops'),
          description: t('oneTimeInvestmentPage.errors.kidsWeeklyLimit', {
            limit: formatNumber({
              value: dependentUserAccess?.investing_weekly_limit,
            }),
          }),
        },
      });

      this.setState({
        isOpenInvestmentInfoModal: false,
      });

      return;
    }

    Firebase.analytics().logEvent('Deposit_Withdraw', {
      eventKey: 'Deposit',
      label: CREDIT,
      value: oneTimeInvestmentValue,
    });

    if (
      AU_APP &&
      oneTimeInvestmentValue !== 0 &&
      user.pending_transactions === 0 &&
      user.current_balance === 0
    ) {
      ReactPixel.trackCustom('Purchase');
    }

    this.setState({ showLoader: true });

    actions.oneTimeInvest(requestData).then(() => {
      this.setState({ showLoader: false });

      const { errorMessage } = this.props;

      if (errorMessage) {
        if (errorMessage.includes(FUNDING_ACCOUNT_ERROR_MESSAGE)) {
          this.openMinimumInvestmentModal(true);
        } else {
          this.openQuickInvestmentModal(false);

          this.openInvestmentModal(false);

          this.openMinimumInvestmentModal(false);

          this.openInvestmentInfoModal(false, true);

          handleChangeOpenInvestmentModal(false);

          actionsModals.displayErrorModal({ errorMessage });
        }
      } else {
        this.openQuickInvestmentModal(false);

        this.openInvestmentModal(false);

        this.openMinimumInvestmentModal(false);

        this.openInvestmentInfoModal(false, true);

        this.openInvestmentCongratsModal(true);

        handleChangeOpenInvestmentModal(false);
      }
    });
  }

  openQuickInvestmentModal(condition, value = 0) {
    this.setState({
      isOpenQuickInvestmentModal: condition,
      oneTimeInvestmentValue: value,
    });
  }

  changeOneTimeInvestmentValue(value = 0) {
    this.setState({ oneTimeInvestmentValue: value });
  }

  openMinimumInvestmentModal(condition, minInvest) {
    this.setState({
      isOpenMinimumInvestmentModal: condition,
      isOpenQuickInvestmentModal: false,
      isOpenInvestmentInfoModal: false,
      isOpenInvestmentModal: !!minInvest,
    });

    this.openInvestmentModal(!!minInvest, minInvest);
  }

  openInvestmentModal(condition, value = 0, isOther = false) {
    const { handleChangeOpenInvestmentModal, user } = this.props;

    if (value !== 0 || isOther) {
      this.setState({ oneTimeInvestmentValue: value });
    }

    const hassAccess = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
    );

    if (!checkIsDependentUser(user) || (hassAccess && hassAccess.investing)) {
      this.setState({ isOpenInvestmentModal: condition });
    }

    if (handleChangeOpenInvestmentModal) {
      handleChangeOpenInvestmentModal(condition);
    }
  }

  openInvestmentInfoModal(condition, afterSuccess) {
    const { oneTimeInvestmentValue } = this.state;

    if (oneTimeInvestmentValue < MIN_INVEST && !afterSuccess) {
      this.openMinimumInvestmentModal(true);
    } else {
      this.setState({
        isOpenInvestmentInfoModal: condition,
      });
    }
  }

  openInvestmentCongratsModal(condition) {
    this.setState({
      isOpenInvestmentCongratsModal: condition,
    });
  }

  render() {
    const {
      isOpenQuickInvestmentModal,
      oneTimeInvestmentValue,
      isOpenInvestmentModal,
      isOpenMinimumInvestmentModal,
      isOpenInvestmentInfoModal,
      isOpenInvestmentCongratsModal,
      showLoader,
    } = this.state;

    const { errorMessage, portfolios, history, user } = this.props;

    return (
      <OneTimeInvestmentView
        user={user}
        showLoader={showLoader}
        errorMessage={errorMessage}
        oneTimeInvest={this.oneTimeInvest}
        isOpenInvestmentModal={isOpenInvestmentModal}
        openInvestmentModal={this.openInvestmentModal}
        oneTimeInvestmentValue={oneTimeInvestmentValue}
        isOpenInvestmentInfoModal={isOpenInvestmentInfoModal}
        openInvestmentInfoModal={this.openInvestmentInfoModal}
        isOpenQuickInvestmentModal={isOpenQuickInvestmentModal}
        openQuickInvestmentModal={this.openQuickInvestmentModal}
        isOpenMinimumInvestmentModal={isOpenMinimumInvestmentModal}
        openMinimumInvestmentModal={this.openMinimumInvestmentModal}
        isOpenInvestmentCongratsModal={isOpenInvestmentCongratsModal}
        openInvestmentCongratsModal={this.openInvestmentCongratsModal}
        changeOneTimeInvestmentValue={this.changeOneTimeInvestmentValue}
        portfolios={portfolios}
        history={history}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  errorMessage: state.invest.errorMessage,
  portfolios: state.user.portfolios,
  user: state.user.user,
  dependentUserAccess: selectDependentUserAccess(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(investActions, dispatch),
  actionsModals: bindActionCreators(modalsActions, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(OneTimeInvestment)),
);

OneTimeInvestment.defaultProps = {
  history: {},
  actions: {},
  isOpenInvestmentModal: false,
  errorMessage: '',
  handleChangeOpenInvestmentModal: () => {},
  portfolios: [],
  user: {},
  dependentUserAccess: {},
};

OneTimeInvestment.propTypes = {
  history: PropTypes.shape({}),
  actions: PropTypes.shape({
    oneTimeInvest: PropTypes.func,
  }),
  errorMessage: PropTypes.string,
  handleChangeOpenInvestmentModal: PropTypes.func,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    pending_transactions: PropTypes.number,
    current_balance: PropTypes.number,
    allocation_profile_id: PropTypes.string,
    user_type: PropTypes.string,
  }),
  isOpenInvestmentModal: PropTypes.bool,
  t: PropTypes.func.isRequired,
  dependentUserAccess: PropTypes.shape({
    investing_weekly_limit: PropTypes.number,
  }),
  actionsModals: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    displayErrorModal: PropTypes.func,
  }).isRequired,
};
