import { CUSTOM_HEADER, USER_TYPE } from 'services/api/consts';

interface IGetSuperPortfolioHeadersProps {
  isSuper?: boolean;
  childId?: string;
}

// TODO: https://acornsau.atlassian.net/browse/RAIZ-7893
export const getSuperPortfolioHeaders = ({
  isSuper,
  childId,
}: IGetSuperPortfolioHeadersProps) => {
  if (!isSuper && !childId) {
    return {};
  }

  if (childId) {
    return {
      [CUSTOM_HEADER.userType]: USER_TYPE.dependent,
      [CUSTOM_HEADER.userUuid]: childId,
    };
  }

  return {
    [CUSTOM_HEADER.userType]: USER_TYPE.superAnnuation,
  };
};
