import { API_TAGS } from '../../consts';

import {
  ICreateInvestmentGoalData,
  ITransformInvestmentGoalResponse,
  IInvestmentGoalParams,
  IUpdateInvestmentGoalData,
  IInvestmentGoalResponse,
  IDeleteInvestmentGoalData,
  IInvestmentGoalData,
  IInvestmentGoalHeaderResponse,
} from './types';
import { SPENDING_ANALYTICS_URL } from './consts';

import { api } from 'store/api';

export const dependentUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    createInvestmentGoal: build.mutation<
      IInvestmentGoalResponse,
      ICreateInvestmentGoalData
    >({
      query: (data) => ({
        url: `${SPENDING_ANALYTICS_URL}/creategoal`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [API_TAGS.investmentGoal],
    }),
    updateInvestmentGoal: build.mutation<
      IInvestmentGoalResponse,
      IUpdateInvestmentGoalData
    >({
      query: (data) => ({
        url: `${SPENDING_ANALYTICS_URL}/updategoal`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [API_TAGS.investmentGoal],
    }),
    deleteInvestmentGoal: build.mutation<
      { header: IInvestmentGoalHeaderResponse },
      IDeleteInvestmentGoalData
    >({
      query: (data) => ({
        url: `${SPENDING_ANALYTICS_URL}/deletegoal`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [API_TAGS.investmentGoal],
    }),
    investmentGoal: build.query<IInvestmentGoalData, IInvestmentGoalParams>({
      query: ({ dependent_user_id }) => ({
        url: `${SPENDING_ANALYTICS_URL}/getgoals`,
        params: {
          dependent_user_id,
        },
      }),
      transformResponse: (response: ITransformInvestmentGoalResponse) => ({
        goal: response?.payload?.[0]?.goal,
      }),
      providesTags: [API_TAGS.investmentGoal],
    }),
  }),
  overrideExisting: true,
});
export const {
  useCreateInvestmentGoalMutation,
  useUpdateInvestmentGoalMutation,
  useDeleteInvestmentGoalMutation,
  useInvestmentGoalQuery,
} = dependentUserApi;

export const {
  endpoints: { investmentGoal },
} = dependentUserApi;
