export const KIDS_LINK_SENT = 'kids-link-sent';
export const KIDS_CONVERT_ACCOUNT = 'kids-convert-account';
export const KIDS_CLOSE_ACCOUNT = 'kids-close-account';
export const KIDS_WITHDRAW = 'kids-withdraw';
export const KIDS_INVEST_NOW = 'kids-invest-now';
export const KIDS_ACTIVATE_ACCOUNT = 'kids-activate-account';

export const KIDS_STATEMENTS_MONTHLY = 'dependent_user';
export const KIDS_TRADE_CONFIRMATION =
  'daily_user_trade_confirmation,user_trade_confirmation_chargeback';
export const KIDS_TAX_REPORTS = 'tax';

export const INVESTMENT_TYPES = {
  allow: 'allow',
  notAllow: 'notAllow',
  allowWeekly: 'allowWeekly',
};

export const KIDS_STATUS = {
  created: 'created',
  pending_converting: 'pending_converting',
  converted: 'converted',
};
