import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';

export default function RewardsHistoryStatus({ status }) {
  const { t } = useTranslation();

  return (
    <div className="rewards__history-progress">
      <div>
        <div className={`rewards__history-status-icon ${status}`} />

        <div className={`rewards__history-status-dot ${status}`} />

        <div className="rewards__history-status">
          <span>{t(`rewardsPage.history.status.${status}`)}</span>
        </div>
      </div>
    </div>
  );
}

RewardsHistoryStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
