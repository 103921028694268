import styled from '@emotion/styled';

interface IPortfolioWrapperProps {
  portfolioColor: string;
}

export const PortfolioWrapper = styled.div<IPortfolioWrapperProps>`
  background: ${({ portfolioColor }) => portfolioColor};
  min-height: 600px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
`;

interface IPortfolioTitleProps {
  isRegistration?: boolean;
}

export const PortfolioTitle = styled.div<IPortfolioTitleProps>`
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  text-align: center;
  padding-top: ${({ isRegistration }) => !isRegistration && '20px'};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  color: ${({ theme, isRegistration }) =>
    isRegistration ? theme.colors.mirage : theme.colors.white};
`;

export const PortfolioHelpCardButton = styled.div`
  position: absolute;
  top: 22px;
  right: 28px;
`;

export const PortfolioTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    justify-content: flex-end;
    padding: 32px 32px 0;
  }
`;
