import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function RewardDetailsButtons({
  cta_urls,
  cta_title,
  terms_and_conditions_url,
}) {
  const { t } = useTranslation();
  const { web } = cta_urls;

  return (
    <div className="buttons-part">
      {web && (
        <div>
          <DeprecatedButton
            title={cta_title}
            buttonClass="button button--submit"
            handleClick={() => {
              window.open(web, '_blank');
            }}
          />

          {terms_and_conditions_url && (
            <a
              href={terms_and_conditions_url}
              target="_blank"
              rel="noopener noreferrer"
              className="info-panel__terms-link"
            >
              {t('rewardsPage.rewardDetails.termsAndConditionsLink')}
            </a>
          )}
        </div>
      )}
    </div>
  );
}

RewardDetailsButtons.defaultProps = {
  cta_urls: {},
  cta_title: '',
  terms_and_conditions_url: '',
};

RewardDetailsButtons.propTypes = {
  cta_urls: PropTypes.shape({
    web: PropTypes.string,
  }),
  cta_title: PropTypes.string,
  terms_and_conditions_url: PropTypes.string,
};
