import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IUseSendStatementsProps } from '../types';

import { useSendModalDescription } from './useSendModalDescription';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectUserEmail } from 'store/user/selectors';
import { AU_APP } from 'constants/localeConfigs';
import {
  sendStatementsListToEmail,
  sendTradeStatementForDependentUser,
} from 'store/dependentUser/actions';
import { toggleInfoModal } from 'store/modals/actions';
import { sendTradeStatement } from 'store/user/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';

export const useSendStatements = ({
  currentType,
  statements,
  onSendStatementsSuccess,
  selectedStatements,
}: IUseSendStatementsProps) => {
  const { t } = useTranslation();
  const { childId } = useParams();
  const dispatch = useAppDispatch();
  const { isRaizKids, isRaizSuper } = useStatementsPath();
  const userEmail = useAppSelector(selectUserEmail);
  const { getSendMailModalDescription } = useSendModalDescription();

  const sendStatementsMy = ({ statementId }: { statementId: string }) => {
    const filteredStatements = statements.filter(({ uuid }) =>
      statementId ? statementId === uuid : selectedStatements.includes(uuid),
    );

    const config = {
      description: getSendMailModalDescription({
        currentType,
        filteredStatements,
        userEmail,
      }),
      acceptButtonText: t('statements.statementsFooter.button.confirm'),
      rejectButtonText: t('common.cancel'),
      onAccept: () =>
        dispatch(
          sendStatementsListToEmail({
            selectedStatementsIds: filteredStatements.map(({ uuid }) => uuid),
            childId,
            onSuccess: onSendStatementsSuccess,
          }),
        ),
    };

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config,
      }),
    );
  };

  const onSendStatementsAuSuccess = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t('statements.statementWillBeSentViaEmail'),
        },
      }),
    );
  };

  const getSendStatementsActionAu = () => {
    if (isRaizKids)
      return dispatch(
        sendTradeStatementForDependentUser({
          childId,
          onSendStatementsAuSuccess,
        }),
      );

    if (isRaizSuper)
      return dispatch(
        sendTradeStatement({
          target: 'super_annuation',
          onSendStatementsAuSuccess,
        }),
      );

    return dispatch(sendTradeStatement({ onSendStatementsAuSuccess }));
  };

  const sendStatementsAu = () => {
    const config = {
      description: t('statements.sendDocumentDescription', {
        email: userEmail,
      }),
      acceptButtonText: t('common.send'),
      rejectButtonText: t('common.cancel'),
      onAccept: () => getSendStatementsActionAu(),
    };

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config,
      }),
    );
  };

  return AU_APP ? sendStatementsAu : sendStatementsMy;
};
