import PropTypes from 'prop-types';

import ResidentialPropertyFundItemBottom from './ResidentialPropertyFundItemBottom';
import ResidentialPropertyFundItemHeader from './ResidentialPropertyFundItemHeader';
import {
  ResidentialPropertyFundImage,
  ResidentialPropertyFundImageWrapper,
  ResidentialPropertyFundItemWrapper,
} from './styles';

export default function ResidentialPropertyFundItem({ fund }) {
  const { address, city, state, image_url } = fund || {};

  return (
    <ResidentialPropertyFundItemWrapper>
      <ResidentialPropertyFundItemHeader
        address={address}
        city={city}
        state={state}
      />

      <ResidentialPropertyFundImageWrapper>
        <ResidentialPropertyFundImage background={image_url} />
      </ResidentialPropertyFundImageWrapper>

      <ResidentialPropertyFundItemBottom fund={fund} />
    </ResidentialPropertyFundItemWrapper>
  );
}

ResidentialPropertyFundItem.propTypes = {
  fund: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string,
    symbol: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
};
