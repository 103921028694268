import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  RecurringInvestment,
  InvestmentAmount,
  InvestmentFrequency,
  FrequencyTitle,
  FrequencyValue,
  FrequencyArrow,
  InvestmentGoalIcon,
  InvestmentGoalSwitchWrapper,
  CustomNumberFieldWrapper,
} from './styles';
import InvestmentGoalModal from './components/InvestmentGoalModal';
import {
  GOAL_NAME,
  INVESTMENT_GOAL_ENABLED_NAME,
  TARGET_AMOUNT_NAME,
} from './components/consts';
import { useResetInvestmentGoalForm } from './hooks/useResetInvestmentGoalForm';

import { selectInvestmentGoal } from 'store/dependentUser/selectors';
import { selectIsKidsInvestmentGoalEnabled } from 'store/firebaseConfig/selectors';
import { NumberField } from 'components/form/number/NumberField';
import { SwitchField } from 'components/form/switch/SwitchField';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function InvestmentGoal() {
  const { t } = useTranslation();

  useResetInvestmentGoalForm();

  const [isInvestmentGoalModalVisible, setIsInvestmentGoalModalVisible] =
    useState(false);

  const isKidsInvestmentGoalEnabled = useAppSelector(
    selectIsKidsInvestmentGoalEnabled,
  );

  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { key } = investmentGoal || {};
  const { watch } = useFormContext();
  const investmentGoalName = watch(GOAL_NAME);
  const [selectedGoalName, setSelectedGoalName] = useState(investmentGoalName);
  const isInvestmentGoalEnabled = watch(INVESTMENT_GOAL_ENABLED_NAME);

  if (!isInvestmentGoalEnabled && selectedGoalName) {
    setSelectedGoalName('');
  }

  const handleInvestmentGoalModalVisible = () => {
    if (!isInvestmentGoalEnabled) {
      return;
    }

    setIsInvestmentGoalModalVisible(!isInvestmentGoalModalVisible);
  };

  if (!isKidsInvestmentGoalEnabled) {
    return null;
  }

  return (
    <RecurringInvestment>
      <InvestmentAmount>
        <SwitchField
          name={INVESTMENT_GOAL_ENABLED_NAME}
          label={t('raizKidsEditPage.investmentGoal.title')}
          handleDiameter={16}
          height={18}
          width={36}
          CustomSwitchWrapper={InvestmentGoalSwitchWrapper}
          isDisabled={isInvestmentGoalEnabled && key}
        />

        <NumberField
          name={TARGET_AMOUNT_NAME}
          inputProps={{ disabled: !isInvestmentGoalEnabled }}
          CustomFieldWrapper={CustomNumberFieldWrapper}
        />
      </InvestmentAmount>

      <InvestmentFrequency onClick={handleInvestmentGoalModalVisible}>
        <InvestmentGoalIcon />

        <FrequencyTitle>
          {t('raizKidsEditPage.investmentGoal.chooseTheGoal')}
        </FrequencyTitle>

        <FrequencyValue>
          {selectedGoalName || t('fields.select.label')}

          <FrequencyArrow />
        </FrequencyValue>
      </InvestmentFrequency>

      <InvestmentGoalModal
        isInvestmentGoalModalVisible={isInvestmentGoalModalVisible}
        setIsInvestmentGoalModalVisible={setIsInvestmentGoalModalVisible}
        setSelectedGoalName={setSelectedGoalName}
      />
    </RecurringInvestment>
  );
}
