import styled from '@emotion/styled';

import { FundItemImage, FundItemTitleWrapper } from '../styles';

export const CustomizationContentItemImageSkeleton = styled(FundItemImage)`
  padding: 33px 0 33px 0;
`;

export const CustomizationContentItemTitleSkeleton = styled(
  FundItemTitleWrapper,
)`
  padding: 16px 12px 32px 13px;
`;
