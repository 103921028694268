import React from 'react';
import { useParams } from 'react-router-dom';

import RecurringInvestmentFormSkeleton from './RecurringInvestmentFormSkeleton/RecurringInvestmentFormSkeleton';
import { useRedirectToPreviewMode } from './hooks/useRedirectToPreviewMode';
import { VIEW_MODE } from './consts';
import RecurringInvestmentPreview from './components/RecurringInvestmentPreview';
import RecurringInvestmentForm from './RecurringInvestmentForm';

import { selectIsKidsInvestmentGoalEnabled } from 'store/firebaseConfig/selectors';
import { selectChild } from 'store/dependentUser/selectors';
import { useInvestmentGoalQuery } from 'store/dependentUser/api';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function RecurringInvestment() {
  const { id } = useAppSelector(selectChild);

  const isKidsInvestmentGoalEnabled = useAppSelector(
    selectIsKidsInvestmentGoalEnabled,
  );

  const { viewMode } = useParams();

  useRedirectToPreviewMode();

  const { isFetching } = useInvestmentGoalQuery(
    { dependent_user_id: id },
    {
      skip: !id || !isKidsInvestmentGoalEnabled,
    },
  );

  if (!id || isFetching) {
    return <RecurringInvestmentFormSkeleton />;
  }

  if (viewMode === VIEW_MODE.preview) {
    return <RecurringInvestmentPreview />;
  }

  return <RecurringInvestmentForm />;
}
