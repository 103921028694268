import { useTranslation } from 'react-i18next';

import { HelpModalContentWrapper } from './styles';
import { HelpModalContentItem } from './HelpModalContentItem';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserAccountSummary } from 'store/history/selectors';

interface IInvestedByYouContentProps {
  isKids: boolean;
}

export const InvestedByYouContent = ({
  isKids,
}: IInvestedByYouContentProps) => {
  const { lump_sum, recurring, round_ups, transferred_in } = useAppSelector(
    selectUserAccountSummary({ isKids }),
  );

  const { t } = useTranslation();

  return (
    <HelpModalContentWrapper>
      <HelpModalContentItem
        label={t('homePage.homePastBanner.lumpSum')}
        value={lump_sum?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.recurring')}
        value={recurring?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.roundUps')}
        value={round_ups?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.transferIn')}
        value={transferred_in?.value}
      />
    </HelpModalContentWrapper>
  );
};
