import React from 'react';
import PropTypes from 'prop-types';

import { WidgetExternalLinkButtonWrapper } from './styles';

export function WidgetExternalButton({
  href,
  onClick,
  text,
  background,
  hasBorder,
}) {
  return (
    <WidgetExternalLinkButtonWrapper
      href={href}
      onClick={onClick}
      background={background}
      hasBorder={hasBorder}
    >
      {text}
    </WidgetExternalLinkButtonWrapper>
  );
}

WidgetExternalButton.defaultProps = {
  background: '',
  hasBorder: false,
};

WidgetExternalButton.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  background: PropTypes.string,
  hasBorder: PropTypes.bool,
};
