import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const Description = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirageLight};
    font-size: ${theme.fontSizes['4sm']};
    line-height: ${theme.lineHeights.length['3sm']};
  `}
  margin: 16px 0 16px;
  text-align: left;
`;

export const AddAccountButton = styled(Button)`
  width: fit-content;
  margin-bottom: 16px;
`;
