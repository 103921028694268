import { useTranslation } from 'react-i18next';

import { useMigrationAccountSteps } from '../consts';

import { Footer, BackButton, NextButton } from './styles';

import { useStepper } from 'hooks/useStepper';

export default function AccountMigrationFooter() {
  const { t } = useTranslation();
  const steps = useMigrationAccountSteps();
  const { goBack } = useStepper({ steps });

  return (
    <Footer>
      <BackButton onClick={goBack} label={t('common.back')} variant="initial" />

      <NextButton type="submit" label={t('common.next')} />
    </Footer>
  );
}
