import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { REQUIRED } from '../../../constants/errors';
import FinalFormInput from '../../elements/FinalFormInput/FinalFormInput';
import Loader from '../../elements/Loader/Loader';
import CheckboxFF from '../../elements/CheckboxFF/CheckboxFF';
import { MY_APP } from '../../../constants/localeConfigs';

import BankErrorModal from './BankErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const addValidateField = (
    field,
    values,
    errors,
    isFirstStep2factor = false,
  ) => {
    const errorTmp = errors;

    if (field.mfa && isFirstStep2factor) {
      return errorTmp;
    }

    if (field && field.name && field.is_displayed) {
      if (!values[field.name] && !errors[field.name]) {
        errorTmp[field.name] = REQUIRED;
      } else if (
        field.max_length &&
        field.max_length !== '' &&
        values[field.name].length > field.max_length
      ) {
        errorTmp[field.name] = t('fields.validation.maxCharacters', {
          number: field.max_length,
        });
      } else if (
        field.min_length &&
        field.min_length !== '' &&
        values[field.name].length < field.min_length
      ) {
        errorTmp[field.name] = t('fields.validation.maxCharacters', {
          number: field.min_length,
        });
      }
    }

    return errorTmp;
  };

  const validate = (values, fields, isFirstStep2factor = false) => {
    let errors = {};

    if (fields && fields.length !== 0) {
      errors = fields.map((field) =>
        addValidateField(field, values, errors, isFirstStep2factor),
      );

      if (errors[0]) {
        [errors] = errors;
      }
    }

    return errors;
  };

  return { validate };
};

function renderFields(
  field,
  toggleShowPassword,
  isShowPassword,
  isShowMfaField = false,
) {
  if (!field.is_displayed) {
    return <div key={field.name} />;
  }

  if (field.mfa && !isShowMfaField) {
    return <div key={field.name} />;
  }

  if (!field.mfa && isShowMfaField) {
    return <div key={field.name} />;
  }

  const description = <div>{field.description}</div>;

  return (
    <div className="bank__modal__container-item" key={field.name}>
      <FinalFormInput
        name={field.name}
        label={description}
        isShowPasswordToggle={field.is_masked}
        showPassword={toggleShowPassword}
        type={isShowPassword && field.is_masked ? 'password' : 'text'}
      />
    </div>
  );
}

export default function BankSignInModal({
  isShowBankModal,
  handleCloseModal,
  bank,
  handleSubmit,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  showLoader,
  toggleShowPassword,
  isShowPassword,
  changeAutoRoundUps,
  roundUpsCheck,
  isSpending,
  isRegistration,
  isFirstStep2factor,
  isShowMfaField,
  isRoundUpRefreshing,
  isFundingErrorVisible,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) =>
        validate(
          values,
          bank && bank.login_form && !isRoundUpRefreshing
            ? bank.login_form
            : [],
          isFirstStep2factor,
        )
      }
      render={({ form, values, hasValidationErrors }) => (
        <div>
          {errorMessage && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowBankModalError || isFundingErrorVisible}
              errorTitle={errorTitle}
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
              shouldCloseOnOverlayClick
              modalClassName="bank-sign"
            />
          )}

          <Modal
            isOpen={isShowBankModal}
            onRequestClose={handleCloseModal}
            contentLabel="withdraw-modal"
            shouldCloseOnOverlayClick
            className="modal-basic bank__modal bank-sign"
          >
            {showLoader && (
              <div className="bank-loader">
                <div className="bank__modal-content">
                  <DeprecatedButton
                    title={t('common.space')}
                    buttonClass="button__close -black"
                    handleClick={handleCloseModal}
                  />

                  <Loader additionalClass="-absolute -top" />

                  <div className="title">{t('settingsPage.pleaseWait')}</div>

                  <div className="sub-title">
                    {t('settingsPage.dataBeingProcessedMessage')}
                  </div>
                </div>
              </div>
            )}

            {!showLoader && (
              <div>
                <Modal
                  hasBackdrop={false}
                  isVisible={false}
                  isOpen={isShowBankModalAlert}
                  contentLabel="modal-alert"
                  shouldCloseOnOverlayClick
                  className="modal-basic bank__alert"
                >
                  <div className="modal-alert-content">
                    <div className="alert-text">
                      {t('settingsPage.bankSignInModal.continueOrSkipMessage')}
                    </div>

                    <div className="alert-buttons">
                      <DeprecatedButton
                        title={t('common.wait')}
                        type="button"
                        buttonClass="wait"
                        handleClick={bankLoginProcessWait}
                      />

                      <DeprecatedButton
                        title={t('common.skip')}
                        type="button"
                        buttonClass="skip"
                        handleClick={handleStopBankAccountLinking}
                      />
                    </div>
                  </div>
                </Modal>

                <div className="bank__modal-content">
                  <DeprecatedButton
                    title={t('common.space')}
                    buttonClass="button__close -black"
                    handleClick={handleCloseModal}
                  />

                  <div className="bank__modal-title">
                    {MY_APP && t('settingsPage.linkRoundUp')}

                    {t('settingsPage.bankSignInModal.bankSignIn')}
                  </div>

                  <div className="bank__modal-sub">
                    <i className="bank__modal-secured-icon" />

                    {t('common.secured')}
                  </div>

                  <div className="bank__modal-description">
                    {t('settingsPage.pleaseSign')}
                  </div>

                  <div className="bank__modal-name">
                    <img
                      alt={bank.name}
                      className="bank__modal-icon"
                      src={bank.icon}
                    />

                    <span>{bank.name}</span>
                  </div>

                  <div className="bank__modal-row">
                    <form
                      autoComplete="off"
                      className="bank__form form"
                      onSubmit={(e) =>
                        handleSubmit(e, values, form, hasValidationErrors)
                      }
                    >
                      {bank && bank.login_form && !isRoundUpRefreshing
                        ? bank.login_form.map((field) =>
                            renderFields(
                              field,
                              toggleShowPassword,
                              isShowPassword,
                              isShowMfaField,
                            ),
                          )
                        : ''}

                      {isSpending && isRegistration && (
                        <div className="form-field checkbox">
                          <div className="form-field__sub-input-link">
                            <CheckboxFF
                              name="roundUps"
                              classList="checkbox__list"
                              defaultChecked={roundUpsCheck}
                              onClick={changeAutoRoundUps}
                              label={t(
                                'settingsPage.bankSignInModal.autoRoundUps',
                              )}
                            />
                          </div>
                        </div>
                      )}

                      <Button
                        isLoading={showLoader}
                        label={t('common.signIn')}
                        type="submit"
                      />
                    </form>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      )}
    />
  );
}

BankSignInModal.defaultProps = {
  isShowBankModal: false,
  showLoader: false,
  isRegistration: false,
  isSpending: false,
  roundUpsCheck: true,
  changeAutoRoundUps: () => {},
  isFirstStep2factor: false,
  isShowMfaField: false,
  isRoundUpRefreshing: false,
  isFundingErrorVisible: false,
};

BankSignInModal.propTypes = {
  isShowBankModal: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  changeAutoRoundUps: PropTypes.func,
  roundUpsCheck: PropTypes.bool,
  isSpending: PropTypes.bool,
  isRegistration: PropTypes.bool,
  isFirstStep2factor: PropTypes.bool,
  isShowMfaField: PropTypes.bool,
  isRoundUpRefreshing: PropTypes.bool,
  isFundingErrorVisible: PropTypes.bool,
};
