import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

import {
  CheckboxWrapper,
  FormStyled,
  ModalButton,
  CustomDatePickerWrapper,
} from '../styles';
import {
  CHECKBOX_VALUE,
  emptyOption,
  MIN_VALID_AGE,
  SOURCE_TYPE_FIELD_NAME,
} from '../consts';
import { useIdentityVerificationAuto } from '../hooks/useIdentityVerificationAuto';
import { generateSchema, generateDefaultValues } from '../utils/form';

import { Disclaimer } from './components/Disclaimer';

import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import { InputField } from 'components/form/input';
import { SelectField } from 'components/form/select';
import { DatePickerField } from 'components/form/datePicker/DatePickerField';
import {
  ISource,
  ISourceFieldOptions,
  ISources,
  FormData,
} from 'store/identityVerification/types';

interface IVerificationFormProps {
  data: ISource;
  isUserTarget?: boolean;
  resetMainForm: () => void;
  closeRequest: () => void;
}

export const VerificationForm = ({
  data,
  isUserTarget,
  resetMainForm,
  closeRequest,
}: IVerificationFormProps) => {
  const { t } = useTranslation();

  const { handleAutoVerifyIdentity, isLoading } = useIdentityVerificationAuto({
    isUserTarget,
    resetMainForm,
    closeRequest,
  });

  const { sources, sources_name } = data;

  const today = dayjs().toDate();
  const maxValidDOBDate = dayjs().subtract(MIN_VALID_AGE, 'year').toDate();

  const [sourceId, setSourceId] = useState('');
  const isSourcesTypeVisible = sources?.length > 1;

  const sourceItem = sourceId
    ? sources?.find((source) => source?.source_id === sourceId)
    : sources?.[0];

  const defaultValues = {
    ...generateDefaultValues({ sourceItem, sourceId, isSourcesTypeVisible }),
    ...(sources && { [SOURCE_TYPE_FIELD_NAME]: [emptyOption] }),
  };

  const fields = sources?.[0]?.fields || [];

  const sourceFields = isSourcesTypeVisible
    ? (sourceId &&
        sources?.find((source) => source?.source_id === sourceId)?.fields) ||
      []
    : fields;

  const resolver = yupResolver(
    generateSchema({ sourceFields, isSourcesTypeVisible }),
  );

  const form = useForm({
    defaultValues,
    resolver,
  });

  const { handleSubmit, watch, reset } = form;

  const sourceTypeField = watch(SOURCE_TYPE_FIELD_NAME);

  const selectedSourceType = isSourcesTypeVisible
    ? sourceTypeField?.[0]?.value || ''
    : sources?.[0]?.source_id;

  useEffect(() => {
    if (selectedSourceType) {
      setSourceId(selectedSourceType);

      const updatedFields = sources?.find(
        (source) => source?.source_id === selectedSourceType,
      );

      reset(
        generateDefaultValues({
          sourceItem: updatedFields,
          sourceId: selectedSourceType,
          isSourcesTypeVisible,
        }),
      );
    }
  }, [selectedSourceType, reset, sources, isSourcesTypeVisible]);

  const onSubmit = handleSubmit((formData: FormData) => {
    const verificationData = formData;

    if (!selectedSourceType) {
      return;
    }

    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        const selectedOption = formData[
          key
        ] as unknown as ISourceFieldOptions[];

        verificationData[key] = selectedOption[0].value;
      }

      if (typeof formData[key] === 'boolean') {
        verificationData[key] = formData[key]
          ? CHECKBOX_VALUE.on
          : CHECKBOX_VALUE.off;
      }
    });

    if (verificationData[SOURCE_TYPE_FIELD_NAME]) {
      delete verificationData[SOURCE_TYPE_FIELD_NAME];
    }

    const verificationDataProps = {
      [selectedSourceType]: verificationData,
      source_id: selectedSourceType,
    };

    handleAutoVerifyIdentity({
      verificationData: verificationDataProps,
    });
  });

  const documentOptions = sources?.map((documentOption: ISources) => ({
    value: documentOption.source_id,
    label: documentOption.title,
    fields: documentOption.fields,
  }));

  const isDisabled = !sourceFields || isLoading;

  return (
    <FormProvider {...form}>
      <FormStyled onSubmit={onSubmit}>
        {isSourcesTypeVisible && (
          <SelectField
            name={SOURCE_TYPE_FIELD_NAME}
            label={
              sources_name ||
              t('settingsPage.identityVerificationModal.sourceTypeLabel')
            }
            options={documentOptions}
          />
        )}

        {sourceFields?.map((field) => {
          switch (field.type) {
            case 'text':
              return (
                <InputField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                />
              );
            case 'select':
              return (
                <SelectField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  options={field.options}
                />
              );

            case 'date':
              return (
                <DatePickerField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  maxDate={
                    field.name === 'date_of_birth' ? maxValidDOBDate : today
                  }
                  CustomDatePickerWrapper={CustomDatePickerWrapper}
                />
              );
            case 'checkbox':
              return (
                <CheckboxWrapper>
                  <CheckBoxField name={field.name} label={field.label} />
                </CheckboxWrapper>
              );
            case 'disclaimer':
              return <Disclaimer field={field} />;
            default:
              return null;
          }
        })}

        <ModalButton
          type="submit"
          title={t('common.submit')}
          disabled={isDisabled}
          withLoader={isLoading}
        />
      </FormStyled>
    </FormProvider>
  );
};
