import styled from '@emotion/styled';
import { CheckboxProps } from '@material-ui/core';

import { Icon } from 'components/elements/Icon';

export const Wrapper = styled.div`
  ${({ theme }) => `
  border-bottom: 1px solid ${theme.colors.mercury};
  padding: 15px 0;
  color: ${theme.colors.mirage};
  font-family: ${theme.fontFamily.textRegular};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.normal};
  letter-spacing: ${theme.letterSpacing.tighter};
  line-height: ${theme.lineHeights.length.md};
  `}
`;

export const Title = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  float: left;
  width: calc(100% - 36px);
`;

export const CheckboxWrapper = styled.div<CheckboxProps>`
  ${({ theme, checked }) => `
    min-width: 36px;
    height: 36px;
    border: 2px solid ${theme.colors.niagaraLight};
    border-radius: 100%;
    display: flex;
    line-height: ${theme.lineHeights.length.xl};
    justify-content: center;
    font-size: ${theme.fontSizes['4lg']};
    color: ${theme.colors.niagaraLight};
    position: relative;

    ${
      checked &&
      `
      background-color: ${theme.colors.niagaraLight};
      width: 36px;
      height: 36px;
      border-radius: 100%;
    `
    })}
`}
`;

export const CheckedIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  left: 6px;
  pointer-events: none;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: ${({ theme }) => theme.opacity.transparent};
  width: 100%;
  height: 100%;
`;
