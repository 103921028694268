export const HOME_TABS = {
  past: 'past',
  today: 'today',
  future: 'future',
};

export const HOME_WIDGETS = {
  raizRewards: 'raizRewards',
  perfomance: 'perfomance',
  raizKids: 'raizKids',
  blogs: 'blogs',
  mtCards: 'mtCards',
  offsetters: 'offsetters',
  raizSuper: 'raizSuper',
  myFinance: 'myFinance',
  oneTimeInvestments: 'oneTimeInvestments',
};

export const HOME_WIDGETS_COLUMNS = {
  mys: [
    HOME_WIDGETS.raizRewards,
    HOME_WIDGETS.perfomance,
    HOME_WIDGETS.raizKids,
    HOME_WIDGETS.blogs,
    HOME_WIDGETS.oneTimeInvestments,
    HOME_WIDGETS.mtCards,
    HOME_WIDGETS.myFinance,
  ],
  default: [
    HOME_WIDGETS.perfomance,
    HOME_WIDGETS.raizKids,
    HOME_WIDGETS.blogs,
    HOME_WIDGETS.mtCards,
    HOME_WIDGETS.offsetters,
    HOME_WIDGETS.raizSuper,
    HOME_WIDGETS.myFinance,
  ],
  smfsUser: [
    HOME_WIDGETS.raizRewards,
    HOME_WIDGETS.perfomance,
    HOME_WIDGETS.blogs,
    HOME_WIDGETS.mtCards,
    HOME_WIDGETS.offsetters,
    HOME_WIDGETS.myFinance,
    HOME_WIDGETS.raizSuper,
  ],
};
