import { AllocationItemsLabel, AllocationItemsWrapper } from './styles';

import { IDetailsItem, ITransactionDetails } from 'store/history/types';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectTransactionDetails } from 'store/history/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IRewardItemProps {
  rewardItem: IDetailsItem;
}

export const RewardItem = ({ rewardItem }: IRewardItemProps) => {
  const { localized_investment_type }: ITransactionDetails = useAppSelector(
    selectTransactionDetails,
  );

  return (
    <AllocationItemsWrapper>
      <AllocationItemsLabel>{localized_investment_type}</AllocationItemsLabel>

      <AllocationItemsLabel>
        {formatNumber({
          value: rewardItem.amount,
        })}
      </AllocationItemsLabel>
    </AllocationItemsWrapper>
  );
};
