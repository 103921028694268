import NumberFormat from 'react-number-format';
import styled from '@emotion/styled';

import { ITransactionStatusProps } from './type';

import complete from 'assets/images/icons/check.svg';
import failed from 'assets/images/icons/failed.svg';

export const Amount = styled(NumberFormat)`
  color: ${({ theme }) => theme.colors.mirage};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['3md']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-left: 16px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
`;

export const StatusText = styled(Text)`
  margin-left: 8px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-bottom: 8px;
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.platinum};
  margin-left: 8px;
`;

export const StatusIcon = styled.div<ITransactionStatusProps>(
  ({ theme, status }) => {
    const backgroundImage = {
      failed: `url(${failed})`,
      complete: `url(${complete})`,
      pending: 'none',
    }[status || 'pending'];

    return `
  border-style: ${status === 'pending' ? 'solid' : 'none'};
  border-width: ${status === 'pending' ? '3px' : '0'};
  border-color: ${theme.colors.royalBlue};
  width: 12px;
  height: 12px;
  background-color: ${
    status === 'complete' ? theme.colors.royalBlue : theme.colors.white
  } !important;
  background-image: ${backgroundImage};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  position: relative;
  margin-left: 8px;
`;
  },
);

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.brightGray};
  padding: 16px 24px;
  width: 100%;
`;
