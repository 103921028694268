import Skeleton from 'react-skeleton-loader';

import {
  ProfileFieldLabel,
  ProfileFieldValue,
  ProfileFieldWrapper,
} from './styles';

interface IProfileFieldProps {
  value?: string;
  label: string;
}

export const ProfileField = ({ value, label }: IProfileFieldProps) => (
  <ProfileFieldWrapper>
    <ProfileFieldLabel>{label}</ProfileFieldLabel>

    {value ? <ProfileFieldValue>{value}</ProfileFieldValue> : <Skeleton />}
  </ProfileFieldWrapper>
);
