import { HelpModalContentItemWrapper, HelpModalContentLabel } from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

interface IHelpModalContentItemProps {
  label: string;
  value: number;
}

export const HelpModalContentItem = ({
  label,
  value,
}: IHelpModalContentItemProps) => (
  <HelpModalContentItemWrapper>
    <HelpModalContentLabel>{label}</HelpModalContentLabel>

    {formatNumber({
      value: value || 0,
      maximumFractionDigits: 2,
    })}
  </HelpModalContentItemWrapper>
);
