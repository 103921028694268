import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { INVESTMENT_GOAL_ENABLED_NAME } from '../components/consts';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectInvestmentGoal } from 'store/dependentUser/selectors';

export const useResetInvestmentGoalForm = () => {
  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { key } = investmentGoal || {};
  const { watch, reset } = useFormContext();
  const isInvestmentGoalEnabled = watch(INVESTMENT_GOAL_ENABLED_NAME);
  const prevInvestmentGoalKey = useRef(key);

  const isResetForm =
    isInvestmentGoalEnabled && prevInvestmentGoalKey.current && !key;

  useEffect(() => {
    if (isResetForm) {
      reset({
        targetAmount: 0,
        goalName: '',
        isInvestmentGoalEnabled: false,
      });

      prevInvestmentGoalKey.current = '';
    }
  }, [isResetForm, reset, prevInvestmentGoalKey]);
};
