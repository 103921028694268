import styled from '@emotion/styled';

import BankIcon from 'assets/images/icons/bank-icon.svg';
import { Button } from 'components/elements/Button';

export const Item = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.mercury};
  padding: 16px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const ItemText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  flex: 1;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
`;

export const UnlinkButton = styled(Button)`
  appearance: none;
  height: auto;
  width: auto;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  min-width: 56px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
`;

export const FixButton = styled(Button)`
  appearance: none;
  height: auto;
  width: auto;
  background-color: ${({ theme }) => theme.colors.grenadier};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  border-radius: 16px;
  min-width: 56px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  padding: 0;
`;

export const ErrorText = styled(ItemText)`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
`;

export const Card = styled.div`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  height: unset;
  width: 100%;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const Logo = styled.div<{
  source: React.FunctionComponent | string;
}>`
  background: url(${({ source }) => `${source || BankIcon}`}) no-repeat center
    center;
  background-size: contain;
  height: 100%;
  width: auto;
`;

export const LogoWrapper = styled.div`
  height: 48px;
  min-width: 48px;
`;

export const Title = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  margin-left: 16px;
`;

export const Content = styled.div`
  padding: 0 24px;
`;

export const WarningWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

export const WarningTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
`;

export const WarningDescription = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  justify-content: center;
  align-items: center;
`;

export const AddBankButton = styled(Button)`
  align-items: center;
  border-radius: 16px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  gap: 8px;
  height: auto;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  min-width: 56px;
  width: auto;
  padding: 6px 12px;
`;
