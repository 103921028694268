import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { range } from 'lodash';

import StatementsSkeleton from '../StatementsSkeleton/StatementsSkeleton';
import Statement from '../Statement/Statement';

import {
  ContentWrapper,
  EmptyStatementsIcon,
  EmptyStatementsText,
  EmptyStatementsWrapper,
  Header,
} from './styles';
import { IStatementsContentProps } from './types';
import StatementsTitle from './components/StatementsTitle/StatementsTitle';
import StatementsSendMailButton from './components/StatementsSendMailButton/StatementsSendMailButton';
import StatementsFooter from './components/StatementsFooter/StatementsFooter';
import { useStatementsSelection } from './hooks/useStatementsSelection';
import { useStatementsContent } from './hooks/useStatementsContent';
import { useSendStatements } from './hooks/useSendStatements';

import { INVEST_ACTIONS } from 'store/invest/consts';
import { getStatements } from 'store/invest/actions';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';

function StatementsContent({
  currentType,
  openPdfModal,
  childId,
  isStatementsNested,
  totalPages,
  areStatementsLoading,
}: IStatementsContentProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [offset, setOffset] = useState(1);

  const {
    toggleSelectStatements,
    isCheckboxesVisible,
    onStatementSelect,
    selectedStatements,
    setSelectedStatements,
    onSendStatementsSuccess,
  } = useStatementsSelection({
    currentType,
  });

  const { statementType, statements } = useStatementsContent({
    currentType,
  });

  const sendStatements = useSendStatements({
    currentType,
    statements,
    onSendStatementsSuccess,
    selectedStatements,
  });

  const { isRaizKids, isRaizSuper } = useStatementsPath();

  const isStatementsRenderFake =
    !areStatementsLoading &&
    statements.length !== undefined &&
    statementType !== INVEST_ACTIONS.GET_STATEMENTS_SUCCESS
      ? range(10).map((_, index) => (
          <StatementsSkeleton key={`skeleton-${index + 1}`} />
        ))
      : '';

  const setInView = (inView: boolean) => {
    if ((isRaizKids || isRaizSuper) && inView && totalPages > offset) {
      dispatch(
        getStatements({
          type: currentType,
          isRaizKids,
          childId,
          isRaizSuper,
          offset: offset + 1,
        }),
      );

      setOffset(offset + 1);
    }
  };

  return (
    <ContentWrapper>
      <Header>
        <StatementsTitle currentType={currentType} />

        <StatementsSendMailButton
          isCheckboxesVisible={isCheckboxesVisible}
          sendStatements={sendStatements}
          statements={statements}
          toggleSelectStatements={toggleSelectStatements}
        />
      </Header>

      {statements.length && statementType && areStatementsLoading
        ? statements.map((statement, index) => (
            <Statement
              prev={statements[index - 1]}
              statement={statement}
              currentType={currentType}
              openPdfModal={openPdfModal}
              isStatementsNested={isStatementsNested}
              key={`statement-${index + 1}`}
              isCheckboxesVisible={isCheckboxesVisible}
              onStatementSelect={onStatementSelect}
              isChecked={selectedStatements?.some(
                (id) => id === statement.uuid,
              )}
              sendStatements={sendStatements}
            />
          ))
        : isStatementsRenderFake}

      {!statements.length && areStatementsLoading && (
        <EmptyStatementsWrapper>
          <EmptyStatementsIcon />

          <EmptyStatementsText>
            {t('statements.empty.noStatements')}
          </EmptyStatementsText>
        </EmptyStatementsWrapper>
      )}

      {isCheckboxesVisible && (
        <StatementsFooter
          clearSelectedStatements={() => setSelectedStatements([])}
          selectedStatements={selectedStatements}
          sendStatements={sendStatements}
        />
      )}

      {statements.length > 0 && <InView onChange={setInView} />}
    </ContentWrapper>
  );
}

export default StatementsContent;
