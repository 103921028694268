import { handleOpenBlogLinkClick } from '../../utils/handleOpenBlogLinkClick';

import {
  BlogPostArrowIcon,
  BlogPostImage,
  BlogPostRow,
  BlogPostTitle,
} from './styles';
import { IBlogPost } from './types';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { saveUserData } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface IBlogPostItemProps {
  post: IBlogPost;
}

export default function BlogPostItem({
  post: { id, link, img, title, slug },
}: IBlogPostItemProps) {
  const dispatch = useAppDispatch();

  if (!id) {
    return null;
  }

  const handleBlogLinkClick = () => {
    dispatch(
      saveUserData({
        blog_post: slug,
      }),
    );

    handleOpenBlogLinkClick();
  };

  return (
    <ExternalLink
      href={link}
      key={`blog-widget-link-${id}`}
      onClick={handleBlogLinkClick}
    >
      <BlogPostRow>
        <BlogPostImage style={{ background: `url(${img})` }} />

        <BlogPostTitle>{title}</BlogPostTitle>

        <BlogPostArrowIcon />
      </BlogPostRow>
    </ExternalLink>
  );
}
