import { useTranslation } from 'react-i18next';

import { usePortfolioCustomizationRoute } from '../../../../hooks';
import { EmptyFundListWrapper, EmptyFilteredFundListIcon } from '../styles';

export default function EmptyFilteredFundList() {
  const { t } = useTranslation();
  const { fundTag } = usePortfolioCustomizationRoute();

  return (
    <EmptyFundListWrapper>
      <EmptyFilteredFundListIcon />

      {t('portfolioCustomization.emptyList.title.filtered', { fundTag })}
    </EmptyFundListWrapper>
  );
}
