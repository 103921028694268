import { useTranslation } from 'react-i18next';

import { CardTitle, MakeChangesAnytimeCardWrapper } from './styles';

export default function MakeChangesAnytimeCard() {
  const { t } = useTranslation();

  return (
    <MakeChangesAnytimeCardWrapper>
      <CardTitle>
        {t('portfolio.helpCards.makeChangesAnytimeCard.title')}
      </CardTitle>
    </MakeChangesAnytimeCardWrapper>
  );
}
