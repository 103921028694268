import { useTranslation } from 'react-i18next';

import { FinancialFormButton } from '../styles';
import { useRegistrationFinancialPage } from '../hooks/useRegistrationFinancialPage';

import {
  RegistrationBackButton,
  RegistrationButton,
  RegistrationButtonsWrapper,
} from './styles';

import { useRegistrationPreviousStep } from 'pages/Registration/hooks/useRegistrationPreviousStep';
import { selectUser } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { checkIsDependentUser } from 'utils/user';

export const FinancialFormButtons = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);
  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();
  const { navigateToPreviousStep } = useRegistrationPreviousStep();

  if (isRegistrationFinancialPage) {
    return (
      <RegistrationButtonsWrapper>
        <RegistrationBackButton
          withLoader={isLoading}
          title={t('common.back')}
          handleClick={navigateToPreviousStep}
        />

        <RegistrationButton
          withLoader={isLoading}
          type="submit"
          title={t('common.next')}
        />
      </RegistrationButtonsWrapper>
    );
  }

  if (!checkIsDependentUser(user)) {
    return (
      <FinancialFormButton
        isLoading={isLoading}
        type="submit"
        label={t('settingsPage.profilePage.saveButton')}
      />
    );
  }

  return null;
};
