import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import {
  TRANSFER_IN,
  REGISTRATION_PORTFOLIO_SELECT,
  REGISTRATION_NOTIFICATION,
} from '../../../store/dependentUser/types';
import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';
import ConfirmationModal from '../../../components/layout/Modals/ConfirmationModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function TransferIn({
  showLoader,
  goBack,
  handleSubmit,
  child,
  isEditChild,
  user,
  errorMessage,
  childStep,
  additionalClassName,
}) {
  const { t } = useTranslation();

  const accountInfo = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
  );

  const isAccountAccess = isEditChild
    ? child.account_access
    : accountInfo.dependency_user.account_access;

  const [transferAmount, setTransferAmount] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const isOwner = childStep === 'transfer-out';

  useEffect(() => {
    setTransferAmount(0);
  }, [childStep]);

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, TRANSFER_IN)}
      render={() => (
        <form className="form" onSubmit={(e) => handleSubmit(e, TRANSFER_IN)}>
          <div className={`raiz-kids__recurring-page ${additionalClassName}`}>
            <div className="raiz-kids__transfer">
              <div className="raiz-kids__transfer-amount">
                {t('raizKidsEditPage.transfer.available', {
                  balance: formatNumber({
                    value: isOwner
                      ? child?.current_balance
                      : user?.current_balance,
                    minimumFractionDigits: 2,
                  }),
                })}
              </div>

              <div>
                <div className="raiz-kids__transfer-label">
                  {t('raizKidsEditPage.transfer.amount')}
                </div>

                <NumberFormat
                  value={transferAmount}
                  decimalScale={2}
                  displayType="input"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  allowNegative={false}
                  placeholder={`${CURRENCY_SYMBOL}0.00`}
                  prefix={CURRENCY_SYMBOL}
                  onValueChange={(values) => {
                    setTransferAmount(values.floatValue);
                  }}
                />
              </div>

              <div className="raiz-kids__transfer-error">{errorMessage}</div>

              <div className="raiz-kids__transfer-row">
                <i className="raiz-kids__transfer-icon" />

                <div className="raiz-kids__transfer-info">
                  {isOwner
                    ? t('raizKidsEditPage.transfer.out.description')
                    : t('raizKidsEditPage.transfer.in.description')}
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-part">
            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  title={t('common.back')}
                  buttonClass="button back"
                  handleClick={() =>
                    goBack({
                      path: isAccountAccess
                        ? REGISTRATION_NOTIFICATION
                        : REGISTRATION_PORTFOLIO_SELECT,
                    })
                  }
                />
              </div>
            )}

            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  handleClick={(e) => {
                    if (!transferAmount) {
                      handleSubmit(e, transferAmount, isOwner);
                    } else {
                      setIsConfirmationModalOpen(true);
                    }
                  }}
                  title={t('common.next')}
                  buttonClass="button button--submit"
                />
              </div>
            )}

            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  buttonClass="button back next"
                  title={t('common.skip')}
                  handleClick={() => goBack({ path: '/raiz-kids' })}
                />
              </div>
            )}

            {isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  handleClick={(e) => {
                    if (!transferAmount) {
                      handleSubmit(e, transferAmount, isOwner);
                    } else {
                      setIsConfirmationModalOpen(true);
                    }
                  }}
                  title={t(
                    `raizKidsEditPage.transfer.${isOwner ? 'out' : 'in'}.title`,
                  )}
                  buttonClass="button button--submit"
                />
              </div>
            )}

            {isConfirmationModalOpen && (
              <ConfirmationModal
                confirmText={
                  isOwner
                    ? t('raizKidsEditPage.transfer.out.confirmationModal.title')
                    : t('raizKidsEditPage.transfer.in.confirmationModal.title')
                }
                confirmInfo={
                  isOwner
                    ? t('raizKidsEditPage.transfer.out.description')
                    : t('raizKidsEditPage.transfer.in.description')
                }
                isConfirmationModalOpen={isConfirmationModalOpen}
                closeConfirmationModal={() => setIsConfirmationModalOpen(false)}
                confirmAction={(e) => {
                  setTransferAmount(0);

                  setIsConfirmationModalOpen(false);

                  handleSubmit(e, transferAmount, isOwner);
                }}
              />
            )}
          </div>
        </form>
      )}
    />
  );
}

TransferIn.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditChild: PropTypes.bool,
  child: PropTypes.shape({
    id: PropTypes.string,
    investing: PropTypes.bool,
    investing_weekly_limit: PropTypes.number,
    account_access: PropTypes.bool,
    current_balance: PropTypes.number,
  }),
  user: PropTypes.shape({
    current_balance: PropTypes.number,
  }).isRequired,
  errorMessage: PropTypes.string,
  childStep: PropTypes.string,
  additionalClassName: PropTypes.string,
};

TransferIn.defaultProps = {
  additionalClassName: '',
  errorMessage: '',
  childStep: '',
  child: {},
  isEditChild: false,
};
