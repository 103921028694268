import { deviceDetect, isMobile } from 'react-device-detect';

import { MY_APP } from '../../constants/localeConfigs';
import { BUILD_VERSION } from '../../constants/codebaseVersions';
import { BAHASA } from '../../constants/comonConstants';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export const extractVersion = (string) => {
  if (!string) {
    return string;
  }

  const match = string.match(/(\d+\.){2}\d+/gi);

  return (match && match[0]) || string;
};

export const getHeaders = () => {
  const deviceInfo = deviceDetect();

  let platform = 'desktop';

  if (isMobile) {
    platform = 'mobile';
  }

  let token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  if (token) {
    token = `token ${token}`;
  }

  let currentLang = localStorage.getItem('lang');

  if (MY_APP && currentLang === BAHASA) {
    currentLang = 'ms';
  } else if (MY_APP) {
    currentLang = 'en';
  }

  return {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-Client-App': 'webapp',
    Authorization: token,
    'X-Client-Build': extractVersion(BUILD_VERSION),
    'X-Client-Os': deviceInfo.osName,
    'X-Client-Platform': platform,
    'X-Version': process.env.REACT_APP_API_VERSION,
    'X-Client-Browser': deviceInfo.browserName,
    'X-Client-Browser-Version': deviceInfo.browserFullVersion,
    'RAIZ-Client-Locale': currentLang,
  };
};
