import styled from '@emotion/styled';

export const FinancialFormSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinancialFormSkeletonItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 12px 18px 4px 18px;
`;

export const FinancialFormSkeletonItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SkeletonButton = styled.div`
  max-width: 204px;
  margin-top: 24px;
  flex: 1;
`;
