import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import '../../styles/pages/not-found.scss';
import { HOME } from '../../store/user/consts';
import withRouter from '../../routes/withRouter';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

class FailedPage extends Component {
  goHome() {
    window.location.href = HOME;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="not-found-content -failed">
        <div className="not-found-content__info">
          <div className="logo" />

          <div className="not-found-content__container">
            <div className="not-found-content__page -failed" />

            <div className="not-found-content__container-col">
              <div className="not-found-content__title">
                {t('failedPage.title')}
              </div>

              <div className="not-found-content__info">
                <Trans i18nKey="failedPage.description" t={t} />
              </div>

              <div className="not-found-content__footer -failed">
                <DeprecatedButton
                  title={t('failedPage.goHomeButton')}
                  buttonClass="button__basic-fill -white"
                  handleClick={() => this.goHome()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(FailedPage));

FailedPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};
