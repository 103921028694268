import { useTranslation } from 'react-i18next';

type IInvestmentFieldProps = {
  onChange: () => void;
  monthly: string;
  onClick: () => void;
};

export const InvestmentField = ({
  onChange,
  monthly,
  onClick,
}: IInvestmentFieldProps) => {
  const { t } = useTranslation();

  return (
    <div className="page-content__banner-row">
      <div className="page-content__monthly">
        <div className="left-part">
          <div className="page-content__banner-label-sub">
            {t('changePortfolioPage.monthlyAmount')}
          </div>

          <div className="page-content__banner-label-text">
            {t('changePortfolioPage.monthlyProjection')}
          </div>

          <div className="page-content__banner-label-text">
            {t('changePortfolioPage.monthlyInvestment')}
          </div>
        </div>

        <div className="right-part">
          <form>
            <input
              onChange={onChange}
              onKeyDown={onChange}
              type="text"
              value={monthly}
              name="monthly"
            />

            <input onClick={onClick} type="button" value="" />
          </form>
        </div>
      </div>
    </div>
  );
};
