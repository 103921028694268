import React from 'react';
import '../../styles/pages/my-finance.scss';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import Chart from 'react-apexcharts';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';

import { WHERE_YOU_SPEND } from '../../constants/comonConstants';
import DeprecatedDotsLoader from '../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';
import FallbackMessage from '../../components/elements/FallbackMessage/FallbackMessage';

import { TotalAmount } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';

const renderSubcategory = (
  category,
  showCategoryMonthly,
  currentCategory,
  total,
  categoriesList,
  categoryId,
) => {
  const categoryName = categoriesList
    .find((mainCategory) => mainCategory.id === categoryId)
    ?.subcategories.find(
      (subCategory) => subCategory.id === category.category,
    )?.name;

  return (
    <div
      className={`info-panel__list-item ${
        currentCategory === category.category ? '-active' : ''
      }`}
      onClick={() => {
        showCategoryMonthly(category.category);
      }}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
      key={category.category}
    >
      <i className="my-finance__modal-category" />

      <div className="my-finance__spend-icons">
        {categoryName || category.name}
      </div>

      <div className="my-finance__spend-sum">
        {`${Math.round((category.total * 100) / total) || '0'}%`}
      </div>

      <div className="my-finance__spend-sum">
        {formatNumber({
          value: category.total,
        })}
      </div>
    </div>
  );
};

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const dataRender = (
  dataItem,
  total,
  showCategoryMonthly,
  currentCategory,
  categoriesList,
) => {
  const subcategoriesArray = dataItem.subcategories.map(
    (subcategory) => subcategory.total,
  );

  const subcategoriesSum = subcategoriesArray.length
    ? subcategoriesArray.reduce(reducer)
    : 0;

  const categoryName = categoriesList.find(
    (category) => category.id === dataItem.name,
  );

  if (!dataItem.parent && dataItem.name !== 'Uncategorized') {
    const valueInPercents = ((dataItem.total + subcategoriesSum) * 100) / total;

    return (
      <AccordionItem
        key={dataItem.category}
        className="my-finance__accordion-item"
      >
        <div
          className={`info-panel__list-item ${
            currentCategory === dataItem.category ? '-active' : ''
          }`}
        >
          <AccordionItemHeading>
            <AccordionItemButton className="my-finance__modal-category">
              {dataItem.subcategories.length ? (
                <i className="arrow-dropdown arrow-expand" />
              ) : (
                ''
              )}
            </AccordionItemButton>
          </AccordionItemHeading>

          <div
            className="my-finance__spend-icons"
            onClick={() => {
              showCategoryMonthly(dataItem.category);
            }}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            {categoryName ? categoryName.name : dataItem.name}
          </div>

          <div className="my-finance__spend-sum">
            {`${
              (valueInPercents > 0 && valueInPercents < 1 && '<1') ||
              Math.floor(valueInPercents) ||
              '0'
            }%`}
          </div>

          <div className="my-finance__spend-sum">
            {formatNumber({
              value: dataItem.total + subcategoriesSum,
            })}
          </div>
        </div>

        {dataItem.subcategories && (
          <AccordionItemPanel className="my-finance__accordion-panel">
            {dataItem.subcategories.map((category) =>
              renderSubcategory(
                category,
                showCategoryMonthly,
                currentCategory,
                total,
                categoriesList,
                dataItem.category,
              ),
            )}
          </AccordionItemPanel>
        )}
      </AccordionItem>
    );
  }

  return '';
};

const dataRenderFake = (e, index) => (
  <AccordionItem key={`${index * 2}`} className="my-finance__accordion-item">
    <div className="info-panel__list-item">
      <AccordionItemHeading>
        <AccordionItemButton className="my-finance__modal-category">
          <Skeleton />
        </AccordionItemButton>
      </AccordionItemHeading>

      <div className="my-finance__spend-sum">
        <Skeleton />
      </div>
    </div>
  </AccordionItem>
);

function WhereSpendView(props) {
  const { t } = useTranslation();

  const {
    series,
    options,
    currentMonth,
    categoriesByMonth,
    showCategoryMonthly,
    currentCategory,
    showLoader,
    isOpenHelpCards,
    toggleHelpCard,
    scrollLeft,
    allChartData,
    maxMonthChart,
    currentPage,
    scrollRight,
    showMainLoader,
    categoriesList,
  } = props;

  let showPrev = false;
  let showNext = false;

  if (allChartData) {
    showPrev = allChartData.slice(
      maxMonthChart * currentPage,
      maxMonthChart * (currentPage + 1),
    ).length;

    showNext = allChartData.slice(
      maxMonthChart * (currentPage - 2),
      maxMonthChart * (currentPage - 1),
    ).length;
  }

  const isAllCategoriesActive = currentCategory === '';

  return (
    <div>
      <div className="my-finance__banner page-content__banner -without-lines -with-chart">
        <div className="page-content__banner-title">
          {t('whereSpendPage.title')}
        </div>

        <DeprecatedButton
          buttonClass="rewards__banner-info my-finance__banner-info"
          handleClick={() => toggleHelpCard(true)}
        />

        {showPrev ? (
          <div
            className="my-finance__banner-arrow -left"
            onClick={() => {
              scrollLeft();
            }}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />
          </div>
        ) : (
          ''
        )}

        {showNext ? (
          <div
            className="my-finance__banner-arrow -right"
            onClick={() => {
              scrollRight();
            }}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />

            <div className="my-finance__banner-dot" />
          </div>
        ) : (
          ''
        )}

        {showMainLoader ? (
          <DeprecatedDotsLoader />
        ) : (
          allChartData?.length > 0 && (
            <Chart options={options} series={series} type="bar" height={300} />
          )
        )}
      </div>

      <div className="info-panel">
        <div className="info-panel__title">{t('whereSpendPage.title')}</div>

        <div className="info-panel__divider" />

        <div className="info-panel__sub-title">
          {t('whereSpendPage.subTitle')}
        </div>

        <div className="info-panel__list">
          {!showLoader &&
            categoriesByMonth?.[currentMonth]?.categories?.length && (
              <div
                className={`info-panel__list-item -with-padding ${
                  currentCategory === '' ? '-active' : ''
                }`}
                onClick={() => {
                  showCategoryMonthly();
                }}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                {t('whereSpendPage.allCategories')}

                <TotalAmount isActive={isAllCategoriesActive}>
                  {formatNumber({
                    value: categoriesByMonth?.[currentMonth]?.total || 0,
                  })}
                </TotalAmount>
              </div>
            )}

          {showLoader && (
            <Accordion>
              {[...Array(5)].map((e, index) => dataRenderFake(e, index))}
            </Accordion>
          )}

          {!showLoader &&
          categoriesByMonth?.[currentMonth]?.categories?.length ? (
            <Accordion allowZeroExpanded>
              {categoriesByMonth[currentMonth].categories.map((dataItem) =>
                dataRender(
                  dataItem,
                  categoriesByMonth[currentMonth].total,
                  showCategoryMonthly,
                  currentCategory,
                  categoriesList,
                ),
              )}
            </Accordion>
          ) : (
            <FallbackMessage text={t('whereSpendPage.noTransaction')} />
          )}
        </div>
      </div>

      <HelpCardsModalAU
        isOpenHelpCards={isOpenHelpCards}
        currentPage={WHERE_YOU_SPEND}
        toggleHelpCard={toggleHelpCard}
      />
    </div>
  );
}

export default WhereSpendView;

WhereSpendView.defaultProps = {
  allChartData: [],
  categoriesList: [{}],
};

WhereSpendView.propTypes = {
  options: PropTypes.shape({}).isRequired,
  showCategoryMonthly: PropTypes.func.isRequired,
  currentMonth: PropTypes.number.isRequired,
  maxMonthChart: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentCategory: PropTypes.string.isRequired,
  allChartData: PropTypes.arrayOf(PropTypes.number),
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categoriesByMonth: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(),
      total: PropTypes.number,
    }),
  ).isRequired,
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  toggleHelpCard: PropTypes.func.isRequired,
  scrollLeft: PropTypes.func.isRequired,
  scrollRight: PropTypes.func.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
};
