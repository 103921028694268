import { useTranslation } from 'react-i18next';

import {
  CustomPortfolioIcon,
  CustomAddIcon,
  ItemName,
  CustomBetaIcon,
  CustomEditIcon,
  CustomPortfolioName,
} from './styles';

interface IPortfolioNameProps {
  isCustom: boolean;
  portfolioType: string;
  isEditCustom: boolean;
  customNameChange: () => void;
  customName: string;
  portfolioName: string;
  isRegistration: boolean;
  isEmptyCustomPortfolio: boolean;
  portfolioKey: string | null;
  savedPortfolioType: string | null;
  customBeta: boolean;
  accessChangePortfolio?: boolean;
}

export const SwitcherTitle = ({
  isCustom,
  portfolioType,
  isEditCustom,
  customNameChange,
  customName,
  portfolioName = '',
  isRegistration = false,
  isEmptyCustomPortfolio,
  portfolioKey,
  savedPortfolioType,
  customBeta,
  accessChangePortfolio = true,
}: IPortfolioNameProps) => {
  const { t } = useTranslation();

  const customPlaceholder = isRegistration
    ? t('changePortfolioPage.nameYour')
    : t('changePortfolioPage.enterTitle');

  const isCustomIcon =
    isCustom &&
    !isRegistration &&
    !isEditCustom &&
    portfolioType !== portfolioKey &&
    !isEmptyCustomPortfolio;

  const isCustomIconActive =
    isCustom &&
    !isRegistration &&
    !isEditCustom &&
    portfolioType === portfolioKey &&
    portfolioType !== savedPortfolioType &&
    !isEmptyCustomPortfolio;

  const isCustomIconSaved =
    isCustom &&
    !isRegistration &&
    !isEditCustom &&
    portfolioType === portfolioKey &&
    portfolioType === savedPortfolioType &&
    !isEmptyCustomPortfolio;

  const isCustomAdd =
    (!isCustom || portfolioType !== portfolioKey || !isEditCustom) &&
    isEmptyCustomPortfolio;

  const isName =
    (!isCustom || portfolioType !== portfolioKey || !isEditCustom) &&
    !isEmptyCustomPortfolio;

  const isCustomBeta = isCustom && portfolioType === portfolioKey && customBeta;

  const isCustomBetaWhite =
    isCustom && portfolioType !== portfolioKey && customBeta;

  const isCustomEdit = !isRegistration && accessChangePortfolio;

  return (
    <span>
      {isCustomIcon && <CustomPortfolioIcon />}

      {isCustomIconActive && <CustomPortfolioIcon isActive />}

      {isCustomIconSaved && <CustomPortfolioIcon isSaved />}

      {isCustomAdd && <CustomAddIcon>{portfolioName}</CustomAddIcon>}

      {isName && <ItemName>{portfolioName}</ItemName>}

      {isCustom &&
        ((portfolioType === portfolioKey && isEditCustom) ||
          isRegistration) && (
          <CustomPortfolioName>
            {isCustomEdit && <CustomEditIcon />}

            {accessChangePortfolio ? (
              <input
                onChange={customNameChange}
                type="text"
                value={customName}
                placeholder={customPlaceholder}
              />
            ) : (
              <span>{portfolioName}</span>
            )}
          </CustomPortfolioName>
        )}

      {isCustomBeta && <CustomBetaIcon />}

      {isCustomBetaWhite && isEditCustom && <CustomBetaIcon isEdit />}

      {isCustomBetaWhite && <CustomBetaIcon isWhite />}
    </span>
  );
};
