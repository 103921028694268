import { AU_APP } from '../../constants/localeConfigs';

export const KIDS_INITIAL_INVESTMENTS = [50, 100, 500, 2000];

export const IS_EXTENDED_TERMS_LIST = AU_APP;

export const NUMBER_OF_TERMS_QUESTIONS = IS_EXTENDED_TERMS_LIST ? 5 : 2;

export const TERM_KEY = 'termsQuestions_';

export const CHECKBOX_KEY = 'checkboxes.';
