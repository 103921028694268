import { useTranslation } from 'react-i18next';

import { HelpModalContentWrapper } from './styles';
import { HelpModalContentItem } from './HelpModalContentItem';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserAccountSummary } from 'store/history/selectors';

interface ICashRewardsContentProps {
  isKids: boolean;
}

export const CashRewardsContent = ({ isKids }: ICashRewardsContentProps) => {
  const {
    total_referrals_rewarded,
    total_found_money_rewarded,
    other_rewards_rewarded,
  } = useAppSelector(selectUserAccountSummary({ isKids }));

  const { t } = useTranslation();

  return (
    <HelpModalContentWrapper>
      <HelpModalContentItem
        label={t('homePage.homePastBanner.referrals')}
        value={total_referrals_rewarded?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.raizRewards')}
        value={total_found_money_rewarded?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.otherRewards')}
        value={other_rewards_rewarded?.value}
      />
    </HelpModalContentWrapper>
  );
};
