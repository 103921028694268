import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import React from 'react';

import {
  RegistrationInvestmentButton,
  RegistrationInvestmentButtonsWrapper,
} from './styles';

import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectChild } from 'store/dependentUser/selectors';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { goBack } from 'store/dependentUser/actions';
import {
  REGISTRATION_INITIAL_INVESTMENT,
  REGISTRATION_PORTFOLIO_SELECT,
} from 'store/dependentUser/types';
import { Button } from 'components/elements/Button';

export interface IRecurringInvestmentRegistrationButtonsProps {
  isLoading?: boolean;
}

export default function RecurringInvestmentRegistrationButtons({
  isLoading,
}: IRecurringInvestmentRegistrationButtonsProps) {
  const navigate = useNavigate();
  const { id } = useAppSelector(selectChild);
  const dispatch = useAppDispatch();

  const handleNavigate = (path: string) => {
    dispatch(goBack(path));

    navigate({
      pathname: path,
      search: createSearchParams({
        [PORTFOLIO_SEARCH_PARAMS.childId]: id,
      }).toString(),
    });
  };

  const { t } = useTranslation();

  return (
    <RegistrationInvestmentButtonsWrapper>
      <RegistrationInvestmentButton
        isLoading={isLoading}
        label={t('common.back')}
        onClick={() => handleNavigate(REGISTRATION_INITIAL_INVESTMENT)}
        variant="initial"
        className="-exclude"
      />

      <Button isLoading={isLoading} type="submit" label={t('common.next')} />

      <RegistrationInvestmentButton
        isLoading={isLoading}
        label={t('common.skip')}
        onClick={() => handleNavigate(REGISTRATION_PORTFOLIO_SELECT)}
        variant="initial"
        className="-exclude"
      />
    </RegistrationInvestmentButtonsWrapper>
  );
}
