import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import '../../../styles/pages/recurring-investment.scss';
import dayjs from 'dayjs';

import {
  BAHASA,
  FREQUENCY,
  FRIDAY,
  ISNIN,
  JUMAAT,
  KHAMIS,
  MONDAY,
  RABU,
  SELASA,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from '../../../constants/comonConstants';
import { MY_APP } from '../../../constants/localeConfigs';

import { formatNumber } from 'utils/formatters/formatNumber';
import { DATE_FORMAT_DAY_NAME } from 'config/date';

function RecurringBannerInfo(props) {
  const {
    currentBalance,
    invAmount,
    showFrequencyChangeState,
    currentFrequency,
    frequencyDay,
    startDate,
  } = props;

  const { t } = useTranslation();

  const currentLang = localStorage.getItem('lang');

  const week =
    MY_APP && currentLang === BAHASA
      ? [ISNIN, SELASA, RABU, KHAMIS, JUMAAT]
      : [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY];

  return (
    <div
      className={`recurring-investment__banner-label ${
        showFrequencyChangeState ? '-col' : ''
      }`}
    >
      <div className="page-content__banner-label-sub">
        {currentFrequency === FREQUENCY.monthly &&
          showFrequencyChangeState &&
          frequencyDay &&
          t('recurringInvestmentPage.recurringBannerInfo.monthlyOn', {
            day:
              frequencyDay === -1
                ? t(
                    'recurringInvestmentPage.recurringBannerInfo.lastDayOfMonth',
                  )
                : frequencyDay,
          })}

        {currentFrequency === FREQUENCY.fortnightly &&
          showFrequencyChangeState &&
          startDate && (
            <Trans
              i18nKey="recurringInvestmentPage.recurringBannerInfo.fortnightlyOn"
              t={t}
              components={{
                day: dayjs(startDate).format(DATE_FORMAT_DAY_NAME),
              }}
            />
          )}

        {currentFrequency === FREQUENCY.weekly &&
          showFrequencyChangeState &&
          frequencyDay &&
          t('recurringInvestmentPage.recurringBannerInfo.every', {
            day: week[frequencyDay - 2],
          })}

        {currentFrequency === FREQUENCY.daily &&
          showFrequencyChangeState &&
          t('recurringInvestmentPage.recurringBannerInfo.everyDay')}

        {!startDate &&
          !frequencyDay &&
          showFrequencyChangeState &&
          currentFrequency !== FREQUENCY.daily &&
          t(
            'recurringInvestmentPage.recurringBannerInfo.chooseInvestmentFrequency',
          )}

        {!showFrequencyChangeState &&
          t('recurringInvestmentPage.recurringBannerInfo.currentBalance')}
      </div>

      <div className="page-content__banner-value-sub">
        {showFrequencyChangeState &&
          formatNumber({
            value: invAmount,
          })}

        {!showFrequencyChangeState &&
          formatNumber({
            value: currentBalance,
          })}
      </div>
    </div>
  );
}

export default RecurringBannerInfo;

RecurringBannerInfo.defaultProps = {
  frequencyDay: null,
  startDate: null,
  invAmount: null,
};

RecurringBannerInfo.propTypes = {
  recurringDepositSettings: PropTypes.shape({}).isRequired,
  showFrequencyChangeState: PropTypes.bool.isRequired,
  currentBalance: PropTypes.number.isRequired,
  invAmount: PropTypes.number,
  currentFrequency: PropTypes.string.isRequired,
  frequencyDay: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
};
