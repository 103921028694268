import React from 'react';
import Skeleton from 'react-skeleton-loader';

import { HeaderDescription, HeaderImage, HeaderWrapper } from '../styles';

import { TitleSkeleton } from './styles';

function FundDetailsSkeleton() {
  return (
    <HeaderWrapper>
      <HeaderImage />

      <TitleSkeleton>
        <Skeleton />
      </TitleSkeleton>

      <HeaderDescription>
        <Skeleton />
      </HeaderDescription>
    </HeaderWrapper>
  );
}

export default FundDetailsSkeleton;
