import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import '../../../styles/layout/withdraw.scss';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { COUNTRY, CURRENCY_SYMBOL } from '../../../constants/localeConfigs';
import { AUS, BAHASA, MYS } from '../../../constants/comonConstants';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function FundingInfoModal({
  isInfoModalOpen,
  toggleInfoModal,
  isEmptySpending,
  isEmptyFunding,
}) {
  const { t } = useTranslation();
  const currentLang = localStorage.getItem('lang');

  const modalBanner = {
    [AUS]: '',
    [MYS]: currentLang === BAHASA ? '-my-ms-banner' : '-my-banner',
  };

  return (
    <Modal
      isOpen={isInfoModalOpen}
      onRequestClose={() => toggleInfoModal(false)}
      contentLabel="account-strength-modal"
      shouldCloseOnOverlayClick
      className="account-strength__modal"
    >
      <DeprecatedButton
        buttonClass="button__close"
        handleClick={() => toggleInfoModal(false)}
      />

      <div className="account-strength__modal-header">
        <i className="account-strength__modal-logo" />

        {t('fundingInfoModal.roundUps')}
      </div>

      <i className={`account-strength__modal-banner ${modalBanner[COUNTRY]}`} />

      <div className="account-strength__modal-title">
        {t('fundingInfoModal.title')}
      </div>

      <div className="account-strength__modal-info">
        <Trans
          i18nKey="fundingInfoModal.description"
          t={t}
          components={{
            currencySymbol: CURRENCY_SYMBOL,
          }}
        />
      </div>

      {isEmptySpending && isEmptyFunding && (
        <Link
          to="/settings/funding-account"
          className="button__basic-fill -white-inverse -link"
        >
          <span>{t('fundingInfoModal.linkFundingSpending')}</span>
        </Link>
      )}

      {isEmptySpending && !isEmptyFunding && (
        <Link
          to="/settings/spending-account"
          className="button__basic-fill -white-inverse -link"
        >
          <span>{t('fundingInfoModal.linkDebitCard')}</span>
        </Link>
      )}

      {!isEmptySpending && isEmptyFunding && (
        <Link
          to="/settings/funding-account"
          className="button__basic-fill -white-inverse -link"
        >
          <span>{t('fundingInfoModal.linkFunding')}</span>
        </Link>
      )}
    </Modal>
  );
}

FundingInfoModal.defaultProps = {
  isInfoModalOpen: false,
  isEmptySpending: false,
  isEmptyFunding: false,
};

FundingInfoModal.propTypes = {
  isInfoModalOpen: PropTypes.bool,
  isEmptyFunding: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isEmptySpending: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  toggleInfoModal: PropTypes.func.isRequired,
};
