import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { PORTFOLIO_SEARCH_PARAMS } from '../consts';

import { updatePortfolioParams } from 'store/portfolio/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const usePortfolioSearchParams = () => {
  const [searchParams] = useSearchParams();
  const childId = searchParams.get(PORTFOLIO_SEARCH_PARAMS.childId) || '';

  const portfolioPlan =
    searchParams.get(PORTFOLIO_SEARCH_PARAMS.portfolioPlan) || '';

  const dispatch = useAppDispatch();

  const isSuper = searchParams.get(PORTFOLIO_SEARCH_PARAMS.isSuper);

  const search = createSearchParams({
    ...(childId && { [PORTFOLIO_SEARCH_PARAMS.childId]: childId }),
    ...(isSuper && { [PORTFOLIO_SEARCH_PARAMS.isSuper]: isSuper }),
  }).toString();

  useEffect(() => {
    dispatch(
      updatePortfolioParams({
        childId,
        portfolioPlan,
        isSuper: !!isSuper,
        search,
        isParamsLoaded: true,
      }),
    );
  }, [dispatch, childId, isSuper, portfolioPlan, search]);
};
