import styled from '@emotion/styled';

import { SelectWrapper } from 'components/form/select/styles';
import { Button } from 'components/elements/Button';
import { BaseButton } from 'components/elements/Deprecated__Button';
import { MY_APP } from 'constants/localeConfigs';
import { Label } from 'components/form/styles';
import { AdditionalFieldWrapper } from 'pages/Registration/components/styles';

export const ProfileHeaderWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  border-radius: 6px 6px 0 0;
  padding: 25px 20px 0;
`;

export const ProfileHeaderContent = styled.div`
  display: flex;
  margin-bottom: 17px;
  padding-right: 70px;
  flex: 1;
`;

export const ProfileHeaderTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;
export const ProfileTabSwitcherWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.alto};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: ${({ theme }) => theme.fontSizes['4sm']}
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: 18px;
  padding: 3px;
  margin: 0 auto;
`;

export const ProfileTabButton = styled(Button)<{
  isActive: boolean;
}>`
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : 'initial'};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: 15px;
  padding: 5px 25px;
  height: auto;
`;

export const FinancialSelectWrapper = styled(Label)`
  ${MY_APP && 'text-transform: uppercase'}
`;

export const FinancialFormItemSelectWrapper = styled(SelectWrapper)`
  padding: 0 36px 6px 16px;
`;

export const RegistrationFinancialSelectWrapper = styled(SelectWrapper)`
  padding: 0 36px 6px 0;
`;

export const FinancialAdditionalFieldWrapper = styled(AdditionalFieldWrapper)`
  input,
  p {
    padding-left: 16px;
  }
  label {
    padding-left: 16px;
  }
`;

export const RegistrationButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
`;

export const RegistrationButton = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.niagaraLight};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: 21px;
`;

export const RegistrationBackButton = styled(RegistrationButton)`
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.alto};
  color: ${({ theme }) => theme.colors.mirage}!important;
`;
