import { GOOGLE_MAPS_CONFIG } from '../../NewRewards/consts';

const getGoogleMapsUrl = (props) => {
  const { latitude, longitude, locations } = props;

  if (
    locations?.[0]?.latitude &&
    locations?.[0]?.longitude &&
    latitude &&
    longitude
  ) {
    const url = `${GOOGLE_MAPS_CONFIG.urlDestination}${latitude},${longitude}&destination=`;

    return `${url}${locations[0].latitude},${locations[0].longitude}`;
  }

  return '';
};

export default getGoogleMapsUrl;
