const colors = {
  alto: '#D9D9D9',
  altoLight: '#D2D2D2',
  athensGray: '#F1F1F4',
  black: '#000000',
  blackTransparentDark: 'rgba(0, 0, 0, 0.8)',
  blackTransparent: 'rgba(0, 0, 0, 0.35)',
  blackTransparentMiddle: 'rgba(0, 0, 0, 0.20)',
  blackTransparentLight: 'rgba(0, 0, 0, 0.13)',
  blackTransparentMedium: 'rgba(0, 0, 0, 0.54)',
  bluishGreenTransparent: 'rgba(10, 173, 119, 0.12)',
  bluishPurpleTransparent: 'rgba(35, 27, 52, 0.54)',
  boulder: '#797979',
  brightGray: '#ECECEC',
  charcoalGray: '#343030',
  codGray: '#0A0A0A',
  aeroBlue: '#b1e5d4',
  cyanBlue: '#0768B1',
  darkGrey: '#484848',
  darkTopaz: '#817D8B',
  dodgerBlue: '#3762FA',
  dustyGray: '#979797',
  gallery: '#EEEEEE',
  gray: 'rgba(255, 255, 255, 0.5)',
  grayTransparent: 'rgba(255, 255, 255, 0.08)',
  greenishCyan: '#07B185',
  greenishTeal: '#39BB82',
  grenadier: '#CE3000',
  grey: '#808080',
  yellowishGreen: '#ecffe2',
  lighterGreen: '#8FF7B5',
  lightGreen: '#74CD95',
  lightGrey: '#DFDFDF',
  lightSilver: '#D8D8D8',
  madison: '#0E2B67',
  mercury: '#E7E7E7',
  mirage: '#231B34',
  mirageLight: '#888491',
  mistyRose: '#FFE5DC',
  midnightBlue: '#212529',
  moon: '#C1B8F3',
  mutedGreen: 'rgba(57, 187, 130, 0.2)',
  niagara: '#0AAD77',
  niagaraHover: '#52D581',
  niagaraLight: '#0AAE77',
  niagaraTransparentLight: '#A0DFCA',
  orange: '#B43603',
  platinum: '#E4E2EE',
  pomegranat: '#F44336',
  purple: '#AA61F4',
  purpleBlue: '#623BC8',
  purpleHeart: '#9731BA',
  purpleHeartDark: '#6130BA',
  purpleHeartLight: '#6831B4',
  red: '#DC3545',
  reddishOrange: '#E36D3A',
  royalBlue: '#634DE2',
  sharkBlack: '#25292F',
  silver: '#CACACA',
  silverChalice: '#ADADAD',
  tealishBlue: '#5F78E5',
  tealGreen: '#29AE87',
  topaz: '#888491',
  transparent: 'transparent',
  veryDarkGrey: '#545454',
  white: '#FFFFFF',
  whiteGreyTransparent: 'rgba(255, 255, 255, 0.32)',
  whiteTransparent: 'rgba(255, 255, 255, 0.24)',
  whiteTransparentDark: 'rgba(255, 255, 255, 0.16)',
  whiteTransparentLight: 'rgba(255, 255, 255, 0.72)',
  whiteTransparentMiddle: 'rgba(255, 255, 255, 0.4)',
  wildSand: '#F5F5F5',
  purpleHeartLighter: '#BA318B',
  royalPurple: '#6133BD',
  deepLavender: '#7349BE',
  green: '#00B56C',
  navyBlue: '#0e2a47',
  bluishPurple: '#411E7A',
  bluishGreen: '#EBF9F0',
  grenadierTransparent: 'rgba(206, 48, 0, 0.12)',
  greenLight: '#D8FFF2',
};

export type colorKey = keyof typeof colors;

export default colors;
