import { API_TAGS } from '../consts';

import {
  ISourcesResponse,
  IRequestChangeResponse,
  IVerificationAutoResponse,
  IVerification,
  IStatusParams,
  IStatusResponse,
} from './types';

import { api } from 'store/api';

export const identityVerificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    identityVerificationsSources: build.query<ISourcesResponse, void>({
      query: () => ({
        url: '/v1/identity_verifications/sources',
        method: 'GET',
      }),
    }),
    identityVerificationsStatus: build.mutation<IStatusResponse, IStatusParams>(
      {
        query: ({ id }) => ({
          url: `/v1/identity_verifications/${id}/status`,
        }),
      },
    ),
    createFundingSourcesRequestChange: build.mutation<
      IRequestChangeResponse,
      IVerification
    >({
      query: (data) => ({
        url: '/v1/funding_sources/request_change',
        method: 'POST',
        data,
      }),
      invalidatesTags: [API_TAGS.verificationStatus],
    }),
    createIdentityVerificationAuto: build.mutation<
      IVerificationAutoResponse,
      IVerification
    >({
      query: (data) => ({
        url: '/v1/identity_verifications/auto',
        method: 'POST',
        data,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useIdentityVerificationsSourcesQuery,
  useCreateFundingSourcesRequestChangeMutation,
  useCreateIdentityVerificationAutoMutation,
  useIdentityVerificationsStatusMutation,
} = identityVerificationApi;

export const {
  endpoints: {
    identityVerificationsSources,
    createFundingSourcesRequestChange,
    createIdentityVerificationAuto,
    identityVerificationsStatus,
  },
} = identityVerificationApi;
