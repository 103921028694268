import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

import { ICustomWrapper, IFieldProps } from '../types';
import { InputWrapper } from '../input/styles';

import {
  NumberFieldErrorMessage,
  NumberFieldInput,
  NumberFieldLabel,
} from './styles';
import { DEFAULT_DECIMAL_SCALE } from './consts';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';

interface INumberFieldProps<T extends FieldValues> extends IFieldProps<T> {
  thousandSeparator?: string;
  decimalSeparator?: string;
  placeholder?: string;
  prefix?: string;
  inputProps?: object;
  CustomFieldWrapper?: React.FC<ICustomWrapper>;
  decimalScale?: number;
  isErrorsDisplayed?: boolean;
}

export const NumberField = <T extends FieldValues>({
  label,
  name,
  prefix = `${CURRENCY_SYMBOL}`,
  placeholder = `${CURRENCY_SYMBOL}0`,
  decimalSeparator = DECIMAL_SEPARATOR,
  thousandSeparator = THOUSAND_SEPARATOR,
  CustomFieldWrapper = InputWrapper,
  inputProps = {},
  decimalScale = DEFAULT_DECIMAL_SCALE,
  isErrorsDisplayed = true,
}: INumberFieldProps<T>) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CustomFieldWrapper>
          <NumberFieldInput
            value={field.value}
            onValueChange={({ floatValue }) => {
              setValue(
                name,
                (floatValue || 0) as FieldPathValue<FieldValues, Path<T>>,
              );
            }}
            {...inputProps}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            placeholder={placeholder}
            prefix={prefix}
            id={field.name}
            type="text"
            decimalScale={decimalScale}
          />

          {label && (
            <NumberFieldLabel htmlFor={field.name}>{label}</NumberFieldLabel>
          )}

          {isErrorsDisplayed && error?.message && (
            <NumberFieldErrorMessage>{error.message}</NumberFieldErrorMessage>
          )}
        </CustomFieldWrapper>
      )}
    />
  );
};
