export const SUITABILITY_NAMES = {
  employment: 'employer',
  source_of_income: 'source_of_income_industry',
  industry: 'source_of_income_industry',
};

export const SUITABILITY_TRANSLATION_KEYS = {
  [SUITABILITY_NAMES.employment]: 'employer',
  [SUITABILITY_NAMES.source_of_income]: 'sourceOfIncomeIndustry',
  [SUITABILITY_NAMES.industry]: 'additionalFieldLabel',
} as const;
