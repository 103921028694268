import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Loader from '../../../components/elements/Loader/Loader';
import StatementsPreviewModal from '../../../components/layout/Modals/StatementsPreviewModal';

import InsurenceItem from './InsurenceItem';
import InsurancesSkeleton from './InsurancesSkeleton';
import EmptyInsurancesList from './EmptyInsurancesList';

export default function Insurances({
  areCertificatesLoading,
  downloadPdfModal,
  isPdfModalOpen,
  openPdfModal,
  statementsUrl,
  isPdfQueryLoading,
}) {
  const { insuranceCertificates } = useSelector((state) => ({
    insuranceCertificates: state.rewards.insuranceCertificates,
  }));

  const { certificates } = insuranceCertificates || {};
  const { t } = useTranslation();

  return (
    <div className="banner-background__container active rewards__insurances">
      <div className="rewards__banner">
        <div className="rewards__banner-title">
          {t('rewardsPage.insurances.insurance')}
        </div>

        <div className="rewards__banner-subtitle">
          {t('rewardsPage.insurances.typesOfInsurance')}
        </div>
      </div>

      <div className="info-panel">
        {areCertificatesLoading && (
          <div>
            <div className="statements-loader">
              <Loader additionalClass="-absolute -top" />
            </div>

            <InsurancesSkeleton
              areCertificatesLoading={areCertificatesLoading}
            />
          </div>
        )}

        {certificates?.length > 0 ? (
          certificates.map((certificate, index) => (
            <InsurenceItem
              key={window.crypto.randomUUID()}
              certificate={certificate}
              isPdfQueryLoading={isPdfQueryLoading}
              downloadPdfModal={downloadPdfModal}
              index={index}
              openPdfModal={openPdfModal}
              statementsUrl={statementsUrl}
            />
          ))
        ) : (
          <EmptyInsurancesList />
        )}
      </div>

      <StatementsPreviewModal
        isStatementsPreviewModalOpen={isPdfModalOpen}
        openPdfModal={openPdfModal}
        statementsUrl={statementsUrl}
        isPdfQueryLoading={isPdfQueryLoading}
      />
    </div>
  );
}

Insurances.defaultProps = {
  areCertificatesLoading: false,
};

Insurances.propTypes = {
  areCertificatesLoading: PropTypes.bool,
  downloadPdfModal: PropTypes.func.isRequired,
  isPdfModalOpen: PropTypes.bool.isRequired,
  openPdfModal: PropTypes.func.isRequired,
  statementsUrl: PropTypes.string.isRequired,
  isPdfQueryLoading: PropTypes.bool.isRequired,
};
