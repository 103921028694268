import { ADULT_AGE } from './consts';

export const getCurrentEtfValues = ({ portfolios, user }) => {
  if (user && !!portfolios?.length) {
    const currentEtf = portfolios.find(
      (portfolio) => portfolio.id === user.allocation_profile_id,
    ).etfs[0];

    return {
      currentEtfDocument: currentEtf.document_url,
      currentEtfLabel: currentEtf.slice_label,
    };
  }

  return {
    currentEtfDocument: '#',
    currentEtfLabel: '',
  };
};

export const checkIsAdult = (age) => age >= ADULT_AGE;
