export const FORBIDDEN_IDS_FOR_INDUSTRIAL_QUESTION = [
  '7964b164-cfdf-411e-852d-705b55cb6fc1',
  '452de271-9b56-4e38-94ac-040b76383bab',
  'c0b0b2a0-0b1a-4b1e-8b0a-0b9b9b0b0b0b',
  '7c3f9917-6bc7-4175-812a-5977b6a50a40',
];

export const SUITABILITIES_PICKER_ID = {
  industry: 'industry',
  sourceOfIncome: 'source_of_income',
  employment: 'employment',
  inviteCode: 'inviteCode',
};

export const SUITABILITIES_ADDITIONAL_FIELDS = {
  employer: 'employer',
  sourceOfIncomeIndustry: 'source_of_income_industry',
};

export const BANK_GRID_MODE_KEYS = {
  selection: 'selection',
  form: 'form',
} as const;

export const ACCOUNT_ID = 'id';

export const BANK_GRID_MODE = 'bank_grid_mode';

export const YODLEE_FASTLINK_STATUSES = {
  availableDataRetreive: 'AVAILABLE_DATA_RETREIVE',
  disallowUpdate: 'DISALLOW_UPDATE',
  allowUpdateWithCredentials: 'ALLOW_UPDATE_WITH_CREDENTIALS',
  allowUpdate: 'ALLOW_UPDATE',
  consentRevoked: 'CONSENT_REVOKED',
};
