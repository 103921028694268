interface IGetIsFeatureEnabled {
  isFeatureEnabled: boolean;
  enabledForUsers: string[];
  userEmail: string;
}

export const checkIsFeatureEnabled = ({
  isFeatureEnabled,
  enabledForUsers,
  userEmail,
}: IGetIsFeatureEnabled) =>
  Boolean(
    isFeatureEnabled || (userEmail && enabledForUsers?.includes(userEmail)),
  );
