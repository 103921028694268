import { useTranslation } from 'react-i18next';
import React from 'react';

import { BannerInfoLabel, BannerInfoValue, BannerInfoWrapper } from './styles';

import { AU_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';

export const BannerInfo = () => {
  const { t } = useTranslation();

  const { processing_deposits, current_balance } = useAppSelector(selectUser);

  if (AU_APP) {
    return null;
  }

  return (
    <BannerInfoWrapper>
      <BannerInfoLabel>{t('common.accountBalance')}</BannerInfoLabel>

      <BannerInfoValue>
        {formatNumber({
          value: current_balance || 0,
        })}
      </BannerInfoValue>

      <BannerInfoLabel>{t('common.status.processing')}</BannerInfoLabel>

      <BannerInfoValue>
        {formatNumber({
          value: processing_deposits || 0,
        })}
      </BannerInfoValue>
    </BannerInfoWrapper>
  );
};
