import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { usePortfolioCustomizationRoute } from '../../../../hooks';
import { SEARCH_QUERY_PARAM } from '../consts';

import { applyFundsSorting } from 'store/portfolio/actions';
import {
  selectFundSorting,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useFundsSorting = () => {
  const dispatch = useDispatch();
  const { fundTag } = usePortfolioCustomizationRoute();
  const { childId } = useAppSelector(selectPortfolioParams);

  const currentSorting = useSelector(selectFundSorting);
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get(SEARCH_QUERY_PARAM);

  const handleSortFunds = (value) => {
    const sorting = value?.[0]?.value;

    if (sorting !== currentSorting) {
      dispatch(
        applyFundsSorting({
          activeFundTag: fundTag,
          sorting,
          childId,
          searchValue,
          searchParams,
        }),
      );
    }
  };

  return {
    handleSortFunds,
  };
};
