export const PORTFOLIO_ETF = {
  aULargeCap: {
    code: 'STW',
    ETF: 'SPDR S&P 200 ETF (STW)',
    category: 'Australia Large Cap Stocks',
    holdings: [
      'BHP Billiton',
      'CBA',
      'Telstra',
      'Wesfarmers',
      'Woolworths',
      'Westpac',
    ],
    short_name: 'AU Large Cap',
    description:
      'Roughly {amount} of every dollar invested will purchase the SPDR S & P 200 ETF(STW).This ' +
      'ETF provides investors with exposure to a broadly diversified index of stocks of larger Australian companies.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 13,
      'moderately-conservative': 22,
      moderate: 32,
      'moderately-aggressive': 44,
      aggressive: 54,
      emerald: 13,
    },
    pipeData: [13, 22, 32, 44, 54, 13],
  },
  asiaLargeCap: {
    code: 'IAA',
    ETF: 'iShares Asia 50 ETF (IAA)',
    category: 'Asia Large Cap Stocks',
    holdings: [
      'SAMSUNG ELECTRONICS LTD',
      'TAIWAN SEMICONDUCTOR MANUFACTURING',
      'CHINA CONSTRUCTION BANK CORP',
      'AIA GROUP LTD',
      'CHINA MOBILE LTD',
      'INDUSTRIAL & COMMERCIAL BANK OF CHINA',
    ],
    short_name: 'Asia Large Cap',
    description:
      'Roughly {amount} of every dollar invested will purchase the iShares Asia 50 ETF (IAA). ' +
      'This ETF provides investors with exposure to stocks of companies in Asia, ' +
      'including Hong Kong, China, and South Korea.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 3,
      'moderately-conservative': 3,
      moderate: 8,
      'moderately-aggressive': 14,
      aggressive: 24,
      emerald: 3,
    },
    pipeData: [3, 3, 8, 14, 24, 3],
  },
  euroLargeCap: {
    code: 'IEU',
    ETF: 'iShares S+P Europe 350 ETF (IEU)',
    category: 'European Large Cap Stocks',
    holdings: ['Nestle', 'HSBC', 'BP', 'Sanofi', 'Novartis', 'Roche'],
    short_name: 'Euro Large Cap',
    description:
      'Roughly {amount} of every dollar invested will purchase the iShares S+P Europe ' +
      '350 ETF (IEU). This ETF provides investors with exposure to a broadly diversified index ' +
      'of stocks of large European companies.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 3,
      'moderately-conservative': 3,
      moderate: 4,
      'moderately-aggressive': 6,
      aggressive: 7,
      emerald: 3,
    },
    pipeData: [3, 3, 4, 6, 7, 3],
  },
  uSLargeCap: {
    code: 'IVV',
    ETF: 'iShares Core S&P 500 ETF (IVV)',
    category: 'US Large Cap Stocks',
    holdings: ['Apple', 'Exxon', 'Google', 'Microsoft', 'J&J', 'GE'],
    short_name: 'US Large Cap',
    description:
      'Roughly {amount} of every dollar invested will purchase the iShares Core S&P 500 ' +
      'ETF (IVV). This ETF provides investors with exposure to 500 of the largest US companies.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 3,
      'moderately-conservative': 8,
      moderate: 9,
      'moderately-aggressive': 8,
      aggressive: 5,
      emerald: 3,
    },
    pipeData: [3, 8, 9, 8, 5, 3],
  },
  corpBonds: {
    code: 'RCB',
    ETF: 'Russell Aust Select Corp Bond ETF (RCB)',
    category: 'Australia Corporate Bonds',
    holdings: ['CBA', 'ANZ', 'Westpac', 'NAB', 'Telstra'],
    short_name: 'Corp. Bonds',
    description:
      'Roughly {amount} of every dollar invested will purchase the Russell Aust Select' +
      ' Corp Bond ETF (RCB). This ETF provides investors with exposure to a wide pool of corporate bonds.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 23,
      'moderately-conservative': 25,
      moderate: 25,
      'moderately-aggressive': 22,
      aggressive: 4,
      emerald: 23,
    },
    pipeData: [32, 8, 4, 9, 25, 19, 3],
  },
  govBonds: {
    code: 'IAF',
    ETF: 'iShares Composite Bond ETF (IAF)',
    category: 'Australia Government Bonds',
    holdings: [],
    logoGroup: null,
    short_name: 'Gov. Bonds',
    description:
      'Roughly {amount} of every dollar invested will purchase the iShares Composite Bond ' +
      'ETF (IAF). This ETF provides investors with exposure to Australian bonds, including Government Bonds.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 30,
      'moderately-conservative': 30,
      moderate: 19,
      'moderately-aggressive': 3,
      aggressive: 3,
      emerald: 21,
    },
    pipeData: [32, 8, 4, 9, 25, 19, 3],
  },
  auMoneyMarke: {
    code: 'BAH',
    ETF: 'Betashares Australian High Interest Cash ETF',
    category: 'Australian Money Market',
    holdings: [],
    logoGroup: null,
    short_name: 'AU Money Market',
    description:
      'Roughly {amount} of every dollar invested will purchase the Betashares Aust High Int. ' +
      'Cash ETF (AAA). This ETF provides investors with exposure to bank deposits, providing regular ' +
      'income and a high level of capital security.',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 25,
      'moderately-conservative': 9,
      moderate: 3,
      'moderately-aggressive': 3,
      aggressive: 3,
      emerald: 6,
    },
    pipeData: [25, 13, 3, 3, 30, 23, 3],
  },
  globalSocResp: {
    code: 'ETHI',
    ETF: 'Global Sustainability Leaders ETF',
    category: 'Global Socially Responsible Large Cap',
    holdings: ['Apple', 'Visa', 'Intel', 'Medtronic', 'Mastercard'],
    logoGroup: null,
    short_name: 'Global Soc. Resp.',
    description:
      'Roughly {amount} of every dollar invested will purchase the Betashares Global Sustainability' +
      ' Leaders ETF (ETHI ). This ETF provides investors with exposure to Socially Responsible stocks ' +
      'of companies in Europe, Asia, & North America',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 0,
      'moderately-conservative': 0,
      moderate: 0,
      'moderately-aggressive': 0,
      aggressive: 0,
      emerald: 34,
    },
    pipeData: [32, 8, 4, 9, 25, 19, 3],
  },
  aUSocResponsible: {
    code: 'RARI',
    ETF: 'Russell Investments Australian Responsible Investment ETF',
    category: 'Australia Socially Responsible Large Cap',
    holdings: [
      'CSL',
      'QBE Insurance',
      'Ramsay Health Care',
      'Myer',
      'JB Hi-Fi',
      'Medibank',
    ],
    logoGroup: null,
    short_name: 'AU Soc. Responsible',
    description:
      'Roughly {amount} of every dollar invested will purchase the Russell Australian Responsible ' +
      'Investment ETF (RARI). This ETF provides investors with exposure to a broadly diversified index of ' +
      'Socially Responsible Australian companies',
    prospectUrl: 'https://www.acornsau.com.au/product-disclosure-statement/',
    ratios: {
      conservative: 0,
      'moderately-conservative': 0,
      moderate: 0,
      'moderately-aggressive': 0,
      aggressive: 0,
      emerald: 39,
    },
    pipeData: [32, 8, 4, 9, 25, 19, 3],
    supportLinks: {
      supportWhatsapp: '',
      supportEmail: '',
      supportTelephone: '',
    },
  },
};

export const PORTFOLIO_TYPES = {
  aggressive: 'aggressive',
  bitcoin: 'bitcoin',
  conservative: 'conservative',
  emerald: 'emerald',
  moderate: 'moderate',
  moderatelyAggressive: 'moderately_aggressive',
  moderatelyConservative: 'moderately_conservative',
  property10: 'property_10',
  property30: 'property_30',
};

export const PORTFOLIO_NAMES = {
  sapphire: 'sapphire',
  moderat: 'moderat',
  konservatif: 'konservatif',
  agresif: 'agresif',
  moderatePlus: 'moderate_plus',
  sukuk: 'sukuk',
  amanah: 'amanah',
};
