import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function BankErrorModal({
  hasBackdrop,
  isVisible,
  isOpen,
  shouldCloseOnOverlayClick,
  errorTitle,
  errorMessage,
  handleClick,
  modalClassName,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={hasBackdrop}
      isVisible={isVisible}
      isOpen={isOpen}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`modal-basic bank__alert ${modalClassName}`}
    >
      <div className="modal-alert-content">
        {errorTitle && <div className="alert-title">{errorTitle}</div>}

        <div className="alert-text">{errorMessage}</div>

        <div className="error-buttons">
          <DeprecatedButton
            title={t('common.ok')}
            type="button"
            handleClick={handleClick}
          />
        </div>
      </div>
    </Modal>
  );
}

BankErrorModal.defaultProps = {
  modalClassName: '',
};

BankErrorModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  hasBackdrop: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  modalClassName: PropTypes.string,
};

export default BankErrorModal;
