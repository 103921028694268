import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import { AU_APP, MY_APP } from '../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

class WithdrawAcceptModal extends Component {
  componentDidMount() {}

  render() {
    const {
      t,
      withdrawValue,
      isOpenWithdrawAcceptModal,
      openWithdrawAcceptModal,
      isSuperFund,
      sendInvestment,
      showLoader,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isOpenWithdrawAcceptModal}
          onRequestClose={() => openWithdrawAcceptModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal time-investment__modal-quick modal-withdraw__accept"
        >
          {(AU_APP || MY_APP) && (
            <div className="time-investment__modal-title">
              {t('withdrawAcceptModal.title')}
            </div>
          )}

          <div className="time-investment__modal-content">
            {!isSuperFund ? (
              <Trans
                i18nKey="withdrawAcceptModal.description.sureToWithdraw"
                t={t}
                components={{
                  value: formatNumber({ value: withdrawValue }),
                }}
              />
            ) : (
              <Trans
                i18nKey="withDrawAcceptModal.description.sureToWithdrawSuperFund"
                t={t}
                components={{
                  value: formatNumber({ value: withdrawValue }),
                }}
              />
            )}

            <br />

            {isSuperFund && t('withDrawAcceptModal.description.note')}
          </div>

          <div className="time-investment__modal-footer">
            <DeprecatedButton
              withLoader={showLoader}
              title={t('common.cancel')}
              buttonClass="button__basic-fill -white -grey-border"
              handleClick={() => openWithdrawAcceptModal(false)}
            />

            <Button
              isLoading={showLoader}
              label={t('common.confirm')}
              onClick={sendInvestment}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = () => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WithdrawAcceptModal),
);

WithdrawAcceptModal.defaultProps = {};

WithdrawAcceptModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpenWithdrawAcceptModal: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isSuperFund: PropTypes.bool.isRequired,
  openWithdrawAcceptModal: PropTypes.func.isRequired,
  sendInvestment: PropTypes.func.isRequired,
  withdrawValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};
