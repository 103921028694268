import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

import { MY_APP } from 'constants/localeConfigs';

export const InfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bluishPurple};
  border-radius: 12px;
  display: flex;
  margin: 0 20px 4px 20px;
  padding: 6px 20px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    flex-direction: column;
  }
`;

export const InfoItems = styled.div`
  flex: 0.5;
  border-right: 2px solid ${({ theme }) => theme.colors.purpleHeartDark};
  padding: 14px 20px 10px 0;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    border: none;
    padding-right: 0;
  }
  :last-of-type {
    padding: 14px 0 0 20px;
    border: 0;
    ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
      padding-left: 0;
    }
  }
`;

export const InfoContentItems = styled(InfoItems)`
  :last-of-type {
    padding-top: 38px;
  }
`;

export const Date = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-align: left;
  flex: 0.5;
`;

export const InfoItemWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  text-align: left;
  justify-content: space-between;
  display: flex;
`;

export const Label = styled.div`
  font-size: 15px;
`;

export const Value = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const ChangeValueWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChangeValueDashboardWrapper = styled.div`
  display: flex;
`;

export const AccountBalance = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
`;

interface IMarketValueProps {
  isDashboard: boolean;
}

export const MarketValueWrapper = styled(Trans)<IMarketValueProps>`
  font-size: ${({ theme, isDashboard }) =>
    MY_APP || !isDashboard ? theme.fontSizes['6sm'] : theme.fontSizes['2lg']};
`;

export const MarketValueLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const TooltipWrapper = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['6sm']};
    font-weight: ${theme.fontWeights.semibold};
    font-family: ${theme.fontFamily.medium} !important;
  `};
  padding: 2px 12px;
`;

export const SharePrice = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4xl']};
`;

export const AccountValue = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes['4xl']};
    font-family: ${theme.fontFamily.regular};
  `};
`;
