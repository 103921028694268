import { useTranslation } from 'react-i18next';

import {
  InfoPanelHeader,
  InfoPanelWrapper,
  MonthlyTrackerCol,
  MonthlyTrackerEmpty,
  MonthlyTrackerFill,
  MonthlyTrackerRow,
  MonthlyTrackerValue,
  MonthlyTrackerWrapper,
  PercentageBar,
  PercentageMore,
  PercentagePoint,
  PercentageWrapper,
  Spacer,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

interface IMonthlyTrackerProps {
  monthlySpending: number;
  averageSpending: number;
}

export const MonthlyTracker = ({
  monthlySpending,
  averageSpending,
}: IMonthlyTrackerProps) => {
  const { t } = useTranslation();
  const percent = Math.round((monthlySpending * 100) / averageSpending) || 0;

  return (
    <InfoPanelWrapper>
      <InfoPanelHeader>{t('homePage.monthlyTracker.title')}</InfoPanelHeader>

      <Spacer />

      <PercentageWrapper>
        <PercentageBar percent={percent}>
          <PercentagePoint percent={percent}>
            {formatNumber({
              value: percent,
              type: NUMBER_FORMATS.percent,
            })}
          </PercentagePoint>
        </PercentageBar>

        <PercentageMore>{t('homePage.monthlyTracker.moreThan')}</PercentageMore>
      </PercentageWrapper>

      <MonthlyTrackerWrapper>
        <MonthlyTrackerCol>
          {t('homePage.monthlyTracker.spendingThisMonth')}

          <MonthlyTrackerRow>
            <MonthlyTrackerFill />

            <MonthlyTrackerValue>
              {formatNumber({
                value: monthlySpending,
              })}
            </MonthlyTrackerValue>
          </MonthlyTrackerRow>
        </MonthlyTrackerCol>

        <MonthlyTrackerCol>
          {t('homePage.monthlyTracker.averageThisMonth')}

          <MonthlyTrackerRow>
            <MonthlyTrackerEmpty />

            <MonthlyTrackerValue>
              {formatNumber({
                value: averageSpending,
              })}
            </MonthlyTrackerValue>
          </MonthlyTrackerRow>
        </MonthlyTrackerCol>
      </MonthlyTrackerWrapper>
    </InfoPanelWrapper>
  );
};
