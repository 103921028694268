import { useTranslation } from 'react-i18next';

import {
  RoundUpsModalLoaderWrapper,
  RoundUpsModalTitle,
  RoundUpsModalWrapper,
} from './components/styles';
import { IRoundUpsModal } from './components/types';
import RoundUpsModalContent from './components/RoundUpsModalContent';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { useRoundUpsSettingsQuery } from 'store/roundUpsSettings/roundUpsSettings';
import { GlobalInfoModalButtonClose } from 'components/modals/InfoModal/styles';
import { GOAL_MODAL_SIZE } from 'pages/RaizKidsEdit/components/RecurringInvestment/components/consts';

export default function RoundUpsModal({
  handleOpenRoundUpsModal,
  isRoundUpsModalOpen,
}: IRoundUpsModal) {
  const { t } = useTranslation();

  const handleCloseModal = () => {
    handleOpenRoundUpsModal(false);
  };

  const { isFetching } = useRoundUpsSettingsQuery();

  return (
    <RoundUpsModalWrapper
      isOpen={isRoundUpsModalOpen}
      onRequestClose={handleCloseModal}
      size={GOAL_MODAL_SIZE}
    >
      <GlobalInfoModalButtonClose onClick={handleCloseModal} />

      <RoundUpsModalTitle>{t('roundUps.modal.title')}</RoundUpsModalTitle>

      {isFetching ? (
        <RoundUpsModalLoaderWrapper>
          <DotsLoader />
        </RoundUpsModalLoaderWrapper>
      ) : (
        <RoundUpsModalContent handleCloseModal={handleCloseModal} />
      )}
    </RoundUpsModalWrapper>
  );
}
