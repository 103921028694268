import { MODERATELY_AGGRESSIVE_PORTFOLIO } from '../consts';

import { IPortfolio } from 'store/user/types';

interface IGetModeratelyAggressivePortfolioProps {
  portfolios: IPortfolio[];
}

export const getModeratelyAggressivePortfolio = ({
  portfolios,
}: IGetModeratelyAggressivePortfolioProps) =>
  portfolios?.find(
    (portfolio) => portfolio.name_key === MODERATELY_AGGRESSIVE_PORTFOLIO,
  );
