import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PERSONAL_REGISTRATION } from '../../../constants/comonConstants';
import { getLeftPartIconClassName } from '../utils';

import Terms from './Terms';
import KidsInfo from './KidsInfo';
import ProgressBar from './ProgressBar';

function RaizPersonal({
  stepName,
  messages,
  showLoader,
  goBack,
  handleSubmitQuestions,
  renderIcons,
  receiverIcons,
  dob,
  handleChangeDate,
  allowType,
  changeAccessType,
  dobError,
  newKidEmail,
  newKidName,
  canEditEmail,
}) {
  const { t } = useTranslation();
  let question = '';
  let stepClass = 'step-one';
  let contentFillTitle = '';

  switch (stepName) {
    case 'terms':
      stepClass = 'step-account form-question -account-questions';

      contentFillTitle = t('raizKidsRegistrationPage.title');

      question = (
        <Terms
          showLoader={showLoader}
          handleSubmit={handleSubmitQuestions}
          messages={messages}
          goBack={goBack}
        />
      );

      break;
    case 'info':
      stepClass = 'step-account form-info';

      contentFillTitle = t('raizKidsRegistrationPage.title');

      question = (
        <KidsInfo
          allowType={allowType}
          dobError={dobError}
          changeAccessType={changeAccessType}
          dob={dob}
          handleChangeDate={handleChangeDate}
          showLoader={showLoader}
          handleSubmit={handleSubmitQuestions}
          goBack={goBack}
          renderIcons={renderIcons}
          receiverIcons={receiverIcons}
          newKidEmail={newKidEmail}
          newKidName={newKidName}
          canEditEmail={canEditEmail}
        />
      );

      break;
    default:
  }

  return (
    <div className="registration-page raiz-kids__registration -kids">
      <div className={stepClass}>
        <div className="left-part">
          <div className="part-content">
            <div className="logo -kids" />

            <div className="title" />

            <div className={getLeftPartIconClassName()} />

            <div className="bottom-text">
              {t('raizKidsRegistrationPage.kidsSimplyWay')}
            </div>
          </div>
        </div>

        <div className="right-part">
          <ProgressBar stepName={stepName} activeStep={PERSONAL_REGISTRATION} />

          <div className="bottom-part">
            <div className="content-fill">
              <h1>{contentFillTitle}</h1>
            </div>

            {question}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaizPersonal;

RaizPersonal.propTypes = {
  stepName: PropTypes.string.isRequired,
  dobError: PropTypes.bool,
  allowType: PropTypes.string.isRequired,
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  receiverIcons: PropTypes.shape({}).isRequired,
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmitQuestions: PropTypes.func.isRequired,
  renderIcons: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  changeAccessType: PropTypes.func.isRequired,
  dob: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  newKidEmail: PropTypes.string,
  newKidName: PropTypes.string,
  canEditEmail: PropTypes.bool.isRequired,
};

RaizPersonal.defaultProps = {
  dobError: false,
  newKidEmail: '',
  newKidName: '',
};
