/* eslint-disable react/jsx-newline */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { useTranslation } from 'react-i18next';

import BankSignInModal from '../../../components/layout/Modals/BankSignInModal';
import Loader from '../../../components/elements/Loader/Loader';
import BankCardModal from '../../../components/layout/Modals/BankCardModal';
import AddCardModal from '../../../components/layout/Modals/AddCardModal';
import { MY_APP } from '../../../constants/localeConfigs';
import SpendingConnectConfirmModal from '../../../components/layout/Modals/SpendingConnectConfirmModal';
import { checkIsDependentUser } from '../../../utils/user';

import { GroupedBankList } from './GroupedBankList';
import BanksSearch from './BanksSearch';
import Banks from './Banks';
import SubAccounts from './SubAccounts/SubAccounts';
import { useSuccessOrFailureModal } from './hooks/useSuccessOrFailureModal';
import { SUCCESS_OR_FAILURE_TRANSACTION_PATHS } from './hooks/const';
import { useLinkCard } from './hooks/useLinkCard';
import { InfinitiumForm } from './InfinitiumForm';

import { selectIsNewCardBindingFlowEnabled } from 'store/firebaseConfig/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { PATHS } from 'constants/paths';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import RoundUpsModal from 'components/layout/Modals/RoundUpsModal/RoundUpsModal';
import { FastlinkModal } from 'components/modals/Fastlink/FastlinkModal';
import BankAccountConnectionStatus from 'pages/Registration/components/BankAccountConnectionStatus/BankAccountConnectionStatus';
import { PROVIDER_TYPES } from 'store/yodlee/consts';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { IconButton } from 'components/elements/IconButton/IconButton';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import { ROUND_UP_ACCOUNT } from 'constants/comonConstants';
import { useCheckIsAccountLinkable } from 'pages/Registration/hooks/useCheckIsAccountLinkable';

const usePanel = () => {
  const { t } = useTranslation();

  const malaysiaAccount = (spendingPaymentCards, unlinkSpendingCard) => {
    const { bank, last_4 } = spendingPaymentCards.payment_cards[0];
    const cardId = spendingPaymentCards.payment_cards[0].id;

    return (
      <div>
        <div
          key="info-panel"
          className="info-panel -company-name -with-padding"
        >
          <div className="info-panel__title">
            <img alt={bank.bank_name} src={bank.icon_url} width="38" />

            {`${bank.bank_name}`}
          </div>

          <div className="info-panel__divider" />

          <div className="info-panel__item card-item">
            <div className="name">
              {t('settingsPage.fundingAccountPage.cardStars')}{' '}
              {spendingPaymentCards.payment_cards[0].last_4}
            </div>

            <div className="unlink-block">
              <DeprecatedButton
                title={t('common.unlink')}
                buttonClass="button__basic-fill unlink-btn"
                handleClick={() => {
                  unlinkSpendingCard(cardId, last_4);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const visaCard = (visaPaymentCard, unlinkSpendingCard) => (
    <div className="info-panel__item card-item">
      <div className="name">
        {t('settingsPage.fundingAccountPage.cardStars')}

        {` ${visaPaymentCard.last4} `}
      </div>

      <div className="unlink-block">
        <DeprecatedButton
          title={t('common.unlink')}
          buttonClass="button__basic-fill unlink-btn"
          handleClick={() => {
            unlinkSpendingCard(visaPaymentCard.id, visaPaymentCard.last4);
          }}
        />
      </div>
    </div>
  );

  const visaAccountPart = (visaPaymentCards, unlinkSpendingCard) => (
    <div>
      <div key="info-panel" className="info-panel -company-name -with-padding">
        <div className="info-panel__title">
          <div className="visa-logo" />
        </div>

        <div className="info-panel__divider" />

        {visaPaymentCards.cards.map((visaPaymentCard) =>
          visaCard(visaPaymentCard, unlinkSpendingCard),
        )}
      </div>
    </div>
  );

  return { malaysiaAccount, visaAccountPart };
};

export default function SpendingAccountView({
  showBanks,
  isShowBanks,
  signInBank,
  isShowBankModal,
  handleCloseModal,
  bank,
  bankInSubmit,
  handleChangeBank,
  credentials,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  monitoredInstitutions,
  handleSubAccount,
  isShowModalError,
  showBanksFromSubAccount,
  deleteAccount,
  isShowConfirmation,
  fixAccount,
  isShowConnect,
  connectAccount,
  showMoreBanks,
  isShowMoreBanks,
  moreBanks,
  isRoundUpsModalOpen,
  roundUpModalOpen,
  showMainLoader,
  showLoader,
  bankCurrentTarget,
  isShowBankCardModal,
  openBankCardModal,
  cardMonth,
  cardYear,
  cardCvv,
  changeDate,
  linkCard,
  toggleShowPassword,
  isShowPassword,
  handleChangeCardNumber,
  cardNumberError,
  paymentCard,
  spendingPaymentCards,
  unlinkSpendingCard,
  isFirstStep2factor,
  isShowMfaField,
  checkMfa,
  user,
  showAddCardModal,
  isShowAddCardModal,
  addCard,
  visaPaymentCards,
  deleteVisaCard,
  showVisaAllBanks,
  isShowAllVisaBanks,
  isShowAddCardModalError,
  isUnlinkCard,
  cardNumberSuffix,
  currentCardId,
  visaEnabled,
  isRoundUpRefreshing,
  isFundingErrorVisible,
  isInstitutionsApiEnabled,
  toggleFastlinkModal,
  isFastlinkModalOpen,
  isBankAccountLinkingStep,
  searchValue,
  changeSearchValue,
}) {
  const { t } = useTranslation();
  const { malaysiaAccount, visaAccountPart } = usePanel();

  useSuccessOrFailureModal({
    successUrl: PATHS.settings.spendingAccount.status.success,
    failureUrl: PATHS.settings.spendingAccount.status.failure,
    translationsPath: SUCCESS_OR_FAILURE_TRANSACTION_PATHS.settingsPage,
    navigatePath: PATHS.settings.spendingAccount.index,
  });

  const [isHelpCardModalOpen, setIsHelpCardModalOpen] = useState(false);

  let accessInvesting = true;

  const isNewCardBindingFlowEnabled = useAppSelector(
    selectIsNewCardBindingFlowEnabled,
  );

  const { linkBankCardFlowNew, isLinkCardLoading } = useLinkCard({
    isSpendingAccount: true,
  });

  const linkBankCard = isNewCardBindingFlowEnabled
    ? linkBankCardFlowNew
    : openBankCardModal;

  const { handleSignInBank } = useCheckIsAccountLinkable({ signInBank });

  const handleBankClick = MY_APP ? linkBankCard : handleSignInBank;

  if (checkIsDependentUser(user)) {
    const access = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
    );

    if (access && !access.investing) {
      accessInvesting = false;
    }
  }

  if (MY_APP) {
    return (
      <>
        {isNewCardBindingFlowEnabled && (
          <InfinitiumForm paymentCard={paymentCard} />
        )}

        <GroupedBankList
          openFixAccountModal={(account) => fixAccount(undefined, account)}
          onSelectDebitCard={linkBankCard}
          onSelectOnlineBanking={signInBank}
          openSettingsModal={() => roundUpModalOpen(true)}
        />

        {isRoundUpsModalOpen && (
          <RoundUpsModal
            isRoundUpsModalOpen={isRoundUpsModalOpen}
            handleOpenRoundUpsModal={roundUpModalOpen}
          />
        )}

        {(isShowBankModal || isShowBankModalError || isFundingErrorVisible) &&
          !showMainLoader && (
            <BankSignInModal
              toggleShowPassword={toggleShowPassword}
              isShowPassword={isShowPassword}
              showLoader={showLoader}
              isShowBankModal={isShowBankModal}
              handleCloseModal={handleCloseModal}
              bank={bank}
              handleSubmit={bankInSubmit}
              isShowBankModalAlert={isShowBankModalAlert}
              bankLoginProcessWait={bankLoginProcessWait}
              errorMessage={errorMessage}
              errorTitle={errorTitle}
              handleCloseModalError={handleCloseModalError}
              isShowBankModalError={isShowBankModalError}
              isFirstStep2factor={isFirstStep2factor}
              isShowMfaField={isShowMfaField}
              isRoundUpRefreshing={isRoundUpRefreshing}
              isFundingErrorVisible={isFundingErrorVisible}
            />
          )}

        {isShowBankCardModal && (
          <BankCardModal
            isShowBankModal={isShowBankCardModal}
            handleCloseModal={handleCloseModal}
            bank={bank}
            handleSubmit={linkCard}
            showLoader={showLoader}
            handleChangeBank={handleChangeBank}
            credentials={credentials}
            isShowBankModalAlert={isShowBankModalAlert}
            bankLoginProcessWait={bankLoginProcessWait}
            errorMessage={errorMessage}
            errorTitle={errorTitle}
            handleCloseModalError={handleCloseModalError}
            isShowBankModalError={isShowBankModalError}
            bankCurrentTarget={bankCurrentTarget}
            cardMonth={cardMonth}
            cardYear={cardYear}
            cardCvv={cardCvv}
            changeDate={changeDate}
            toggleShowPassword={toggleShowPassword}
            isShowPassword={isShowPassword}
            handleChangeCardNumber={handleChangeCardNumber}
            cardNumberError={cardNumberError}
            paymentCard={paymentCard}
            isSpending
          />
        )}
      </>
    );
  }

  return (
    <div className="settings__funding-account settings__spending-account">
      {isBankAccountLinkingStep ? (
        <BankAccountConnectionStatus
          isSpending
          onUnmount={handleCloseModal}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
        />
      ) : (
        <>
          {!isShowMoreBanks && (
            <div className="profile-content settings__content-default-container">
              <div className="profile-content__header -with-help-card">
                <div className="profile-content__title">
                  {isShowBanks
                    ? t('settingsPage.spendingAccountPage.link')
                    : t('settingsPage.spendingAccountPage.title')}
                </div>
                <div className="-with-help-card">
                  {accessInvesting && (
                    <DeprecatedButton
                      buttonClass="profile-content__setting-icon"
                      handleClick={() => roundUpModalOpen(true)}
                    />
                  )}

                  <IconButton
                    iconName="questionGreen"
                    size={28}
                    onClick={() => setIsHelpCardModalOpen(true)}
                  />
                </div>
              </div>

              <div className="profile-content__container">
                {(!showMainLoader || (isShowBanks && !showMainLoader)) && (
                  <div className="profile-content__container-item settings__funding-account-info">
                    {!showMainLoader && !isShowBanks && (
                      <div>{t('settingsPage.spendingAccount.description')}</div>
                    )}

                    {isShowBanks && !showMainLoader && (
                      <div>
                        {t(
                          'settingsPage.spendingAccountPage.chooseAccountForPurchasesMessage',
                        )}
                      </div>
                    )}

                    {showMainLoader && <Skeleton />}
                  </div>
                )}
              </div>
            </div>
          )}

          {MY_APP &&
            visaPaymentCards?.cards?.length > 0 &&
            !isShowBanks &&
            !showMainLoader &&
            visaAccountPart(visaPaymentCards, deleteVisaCard)}

          {spendingPaymentCards &&
            spendingPaymentCards.payment_cards &&
            spendingPaymentCards.payment_cards.length !== 0 &&
            !isShowBanks &&
            !showMainLoader &&
            MY_APP &&
            malaysiaAccount(spendingPaymentCards, unlinkSpendingCard)}

          {!isShowBanks &&
            !isShowMoreBanks &&
            monitoredInstitutions.length === 0 &&
            !showMainLoader &&
            !isShowBanks && (
              <div className="button-part">
                <div className="empty-content">
                  {!MY_APP && (
                    <div className="title">
                      <i className="bank__modal-secured-icon" />

                      {t('settingsPage.secured')}
                    </div>
                  )}
                </div>

                <DeprecatedButton
                  title={t('settingsPage.spendingAccountPage.addButton')}
                  buttonClass="button__basic-fill -green -size-l -see-more-banks"
                  handleClick={showBanks}
                />
              </div>
            )}

          {isShowBanks && !showMainLoader && (
            <div>
              <Banks
                handleClick={handleBankClick}
                isSpending
                showMoreBanks={showMoreBanks}
                checkMfa={checkMfa}
                showAddCardModal={showAddCardModal}
                showVisaAllBanks={showVisaAllBanks}
                isShowAllVisaBanks={isShowAllVisaBanks}
                visaEnabled={visaEnabled}
                isInstitutionsApiEnabled={isInstitutionsApiEnabled}
                signInBank={signInBank}
                showMainLoader={isLinkCardLoading}
              />
            </div>
          )}
          {monitoredInstitutions &&
            monitoredInstitutions.length !== 0 &&
            !isShowBanks &&
            !isShowMoreBanks &&
            !showMainLoader && (
              <div>
                <SubAccounts
                  monitoredInstitutions={monitoredInstitutions}
                  handleSubAccount={handleSubAccount}
                  errorMessage={errorMessage}
                  handleCloseModalError={handleCloseModalError}
                  isShowModalError={isShowModalError}
                  showBanksFromSubAccount={showBanksFromSubAccount}
                  isSpending
                  deleteAccount={deleteAccount}
                  isShowConfirmation={isShowConfirmation}
                  fixAccount={fixAccount}
                  isShowConnect={isShowConnect}
                  connectAccount={connectAccount}
                  isFundingErrorVisible={isFundingErrorVisible}
                  isUnlinkCard={isUnlinkCard}
                  cardNumberSuffix={cardNumberSuffix}
                />
              </div>
            )}
        </>
      )}

      {MY_APP && isShowConfirmation && cardNumberSuffix && (
        <SpendingConnectConfirmModal
          isShowConfirmation={isShowConfirmation}
          handleCloseModalError={handleCloseModalError}
          unlinkSpendingCard={() => {
            if (isUnlinkCard) {
              deleteVisaCard(currentCardId, cardNumberSuffix);
            } else {
              unlinkSpendingCard(spendingPaymentCards.payment_cards[0].id);
            }
          }}
          cardNumberSuffix={cardNumberSuffix}
        />
      )}
      {(isShowBankModal || isShowBankModalError || isFundingErrorVisible) &&
        !showMainLoader &&
        !isBankAccountLinkingStep && (
          <BankSignInModal
            toggleShowPassword={toggleShowPassword}
            isShowPassword={isShowPassword}
            showLoader={showLoader}
            isShowBankModal={isShowBankModal}
            handleCloseModal={handleCloseModal}
            bank={bank}
            handleSubmit={bankInSubmit}
            handleStopBankAccountLinking={handleStopBankAccountLinking}
            isShowBankModalAlert={isShowBankModalAlert}
            bankLoginProcessWait={bankLoginProcessWait}
            errorMessage={errorMessage}
            errorTitle={errorTitle}
            handleCloseModalError={handleCloseModalError}
            isShowBankModalError={isShowBankModalError}
            isFirstStep2factor={isFirstStep2factor}
            isShowMfaField={isShowMfaField}
            isRoundUpRefreshing={isRoundUpRefreshing}
            isFundingErrorVisible={isFundingErrorVisible}
          />
        )}

      {isShowAddCardModal && (
        <AddCardModal
          isShowBankModal={isShowAddCardModal}
          handleCloseModal={handleCloseModal}
          handleSubmit={addCard}
          showLoader={showLoader}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowAddCardModalError={isShowAddCardModalError}
          handleChangeCardNumber={handleChangeCardNumber}
          cardNumberError={cardNumberError}
        />
      )}

      {isShowBankCardModal && (
        <BankCardModal
          isShowBankModal={isShowBankCardModal}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={linkCard}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          bankCurrentTarget={bankCurrentTarget}
          cardMonth={cardMonth}
          cardYear={cardYear}
          cardCvv={cardCvv}
          changeDate={changeDate}
          toggleShowPassword={toggleShowPassword}
          isShowPassword={isShowPassword}
          handleChangeCardNumber={handleChangeCardNumber}
          cardNumberError={cardNumberError}
          paymentCard={paymentCard}
          isSpending
        />
      )}

      {showMainLoader && <Loader />}

      {isShowMoreBanks && !showMainLoader && !isBankAccountLinkingStep && (
        <BanksSearch
          banks={moreBanks}
          handleClick={handleSignInBank}
          searchValue={searchValue}
          changeSearchValue={changeSearchValue}
        />
      )}

      {isRoundUpsModalOpen && (
        <RoundUpsModal
          isRoundUpsModalOpen={isRoundUpsModalOpen}
          handleOpenRoundUpsModal={roundUpModalOpen}
        />
      )}
      {isFastlinkModalOpen && (
        <FastlinkModal
          isFastlinkModalOpen={isFastlinkModalOpen}
          toggleFastlinkModal={toggleFastlinkModal}
          type={PROVIDER_TYPES.spending}
        />
      )}

      {isHelpCardModalOpen && (
        <HelpCardsModalAU
          isOpenHelpCards={isHelpCardModalOpen}
          currentPage={ROUND_UP_ACCOUNT}
          toggleHelpCard={setIsHelpCardModalOpen}
        />
      )}
    </div>
  );
}

SpendingAccountView.defaultProps = {
  user: {},
  isShowBanks: false,
  bankInSubmit() {},
  handleChangeBank() {},
  monitoredInstitutions: [],
  isShowConfirmation: false,
  showLoader: false,
  isShowConnect: false,
  isRoundUpsModalOpen: false,
  isShowBankCardModal: false,
  searchValue: null,
};

SpendingAccountView.propTypes = {
  showBanks: PropTypes.func.isRequired,
  isShowBanks: PropTypes.bool,
  signInBank: PropTypes.func.isRequired,
  isShowBankModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
  }),
  bankInSubmit: PropTypes.func,
  handleChangeBank: PropTypes.func,
  credentials: PropTypes.shape({}).isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  handleSubAccount: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  showBanksFromSubAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  isShowConfirmation: PropTypes.bool,
  fixAccount: PropTypes.func.isRequired,
  isShowConnect: PropTypes.bool,
  showLoader: PropTypes.bool,
  connectAccount: PropTypes.func.isRequired,
  showMoreBanks: PropTypes.func.isRequired,
  isShowMoreBanks: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRoundUpsModalOpen: PropTypes.bool,
  roundUpModalOpen: PropTypes.func.isRequired,
  bankCurrentTarget: PropTypes.string.isRequired,
  openBankCardModal: PropTypes.func.isRequired,
  isShowBankCardModal: PropTypes.bool,
  cardMonth: PropTypes.string.isRequired,
  cardYear: PropTypes.string.isRequired,
  cardCvv: PropTypes.string.isRequired,
  changeDate: PropTypes.func.isRequired,
  linkCard: PropTypes.func.isRequired,
  handleChangeCardNumber: PropTypes.func.isRequired,
  cardNumberError: PropTypes.string.isRequired,
  paymentCard: PropTypes.shape({}).isRequired,
  spendingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(),
  }).isRequired,
  unlinkSpendingCard: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  isFirstStep2factor: PropTypes.bool.isRequired,
  isShowMfaField: PropTypes.bool.isRequired,
  checkMfa: PropTypes.func.isRequired,
  showAddCardModal: PropTypes.func.isRequired,
  isShowAddCardModal: PropTypes.bool.isRequired,
  addCard: PropTypes.func.isRequired,
  visaPaymentCards: PropTypes.shape({
    cards: PropTypes.arrayOf(),
  }).isRequired,
  deleteVisaCard: PropTypes.func.isRequired,
  showVisaAllBanks: PropTypes.func.isRequired,
  isShowAllVisaBanks: PropTypes.bool.isRequired,
  isShowAddCardModalError: PropTypes.bool.isRequired,
  isUnlinkCard: PropTypes.bool.isRequired,
  cardNumberSuffix: PropTypes.string.isRequired,
  currentCardId: PropTypes.string.isRequired,
  visaEnabled: PropTypes.bool.isRequired,
  isRoundUpRefreshing: PropTypes.bool.isRequired,
  isFundingErrorVisible: PropTypes.bool.isRequired,
  isInstitutionsApiEnabled: PropTypes.bool.isRequired,
  toggleFastlinkModal: PropTypes.func.isRequired,
  isFastlinkModalOpen: PropTypes.bool.isRequired,
  isBankAccountLinkingStep: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  changeSearchValue: PropTypes.func.isRequired,
};
