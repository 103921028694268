import styled from '@emotion/styled';

import { Row } from '../styles';

import FinalFormInput from 'components/elements/FinalFormInput/FinalFormInput';

export const Description = styled(Row)`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.topaz};
  margin-bottom: 0;
`;

export const TextField = styled(FinalFormInput)`
  &.MuiFormControl-root {
    max-width: 500px;
    height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  }
`;
