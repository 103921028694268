import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import * as investActions from '../../store/invest/actions';
import * as actionsFromUser from '../../store/user/actions';
import { checkIsDependentUser } from '../../utils/user';

import InvestView from './InvestView';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class Invest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenInvestmentModal: false,
      isOpenWithdrawModal: false,
      isRejectionModalOpen: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.toggleWithdrawModal = this.toggleWithdrawModal.bind(this);

    this.toggleKidsAccessRejectionModal =
      this.toggleKidsAccessRejectionModal.bind(this);

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { userActions } = this.props;

    userActions.resetProps();
  }

  handleOpenModal(condition) {
    const { user } = this.props;

    if (checkIsDependentUser(user)) {
      const hassAccess = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
      );

      if (hassAccess && hassAccess.investing) {
        this.setState({ isOpenInvestmentModal: condition });
      } else {
        this.setState({ isRejectionModalOpen: condition });
      }
    } else {
      this.setState({ isOpenInvestmentModal: condition });
    }
  }

  toggleWithdrawModal(condition) {
    this.setState({ isOpenWithdrawModal: condition });
  }

  toggleKidsAccessRejectionModal(condition) {
    this.setState({ isRejectionModalOpen: condition });
  }

  render() {
    const { history, user, t } = this.props;

    const { isOpenWithdrawModal, isOpenInvestmentModal, isRejectionModalOpen } =
      this.state;

    return (
      <InvestView
        title={t('investPage.title')}
        isRejectionModalOpen={isRejectionModalOpen}
        toggleKidsAccessRejectionModal={this.toggleKidsAccessRejectionModal}
        history={history}
        isOpenWithdrawModal={isOpenWithdrawModal}
        isOpenInvestmentModal={isOpenInvestmentModal}
        handleOpenModal={this.handleOpenModal}
        toggleWithdrawModal={this.toggleWithdrawModal}
        user={user}
      />
    );
  }
}

Invest.defaultProps = {
  user: {},
  actions: {},
  history: {},
  raizKidsFeature: {},
};

Invest.propTypes = {
  actions: PropTypes.shape({
    withdrawalAllMoneyClose: PropTypes.func,
  }),
  history: PropTypes.shape({}),
  userActions: PropTypes.shape({
    resetProps: PropTypes.func,
  }).isRequired,
  raizKidsFeature: PropTypes.shape({
    enabled: PropTypes.bool,
    emails: PropTypes.arrayOf(PropTypes.string),
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    user_type: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(investActions, dispatch),
  userActions: bindActionCreators(actionsFromUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Invest));
