import { useMemo } from 'react';

import { IUseIsStatementsNestedProps } from '../types';

import { useStatementsPath } from './useStatementsPath';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import { STATEMENTS_MONTHLY } from 'constants/comonConstants';
import { checkIsDependentUser } from 'utils/user';

export const useIsStatementsNested = ({
  currentType,
}: IUseIsStatementsNestedProps) => {
  const { isRaizKids, isRaizSuper } = useStatementsPath();
  const user = useAppSelector(selectUser);

  const isStatementsNested = useMemo(
    () =>
      isRaizKids ||
      isRaizSuper ||
      (checkIsDependentUser(user) && currentType === STATEMENTS_MONTHLY),
    [user, currentType, isRaizKids, isRaizSuper],
  );

  return isStatementsNested;
};
