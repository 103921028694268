import styled from '@emotion/styled';

import blogArrowIcon from 'assets/images/icons/ic_open.svg';

export const BlogPostRow = styled.div`
  padding: 10px 15px;
  width: 100%;
  display: inline-flex;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    min-height: 89px;
    height: auto;
  }

  :last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    margin-bottom: 0;
  }

  :first-of-type {
    border-top: 0;
  }
`;

export const BlogPostImage = styled.div`
  margin-left: 0;
  width: 110px;
  height: 62px;
  border-radius: 5px;
  background-size: cover !important;
`;

export const BlogPostTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  color: ${({ theme }) => theme.colors.mirage};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  width: 100%;
  padding-right: 10px;
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-left: 15px;
  padding-top: 12px;
  float: left;
`;

export const BlogPostArrowIcon = styled.div`
  background-image: url(${blogArrowIcon});
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 68px;
  float: left;
`;
