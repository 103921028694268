import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

import {
  AddCustomGoalName,
  SelectCustomName,
  CustomInputWrapper,
  GoalName,
  InvestmentGoalModalButtonClose,
  InvestmentGoalModalContent,
  InvestmentGoalModalHeader,
  InvestmentGoalModalTitle,
  InvestmentGoalModalWrapper,
  SelectedCustomNameIcon,
  SelectYourGoalWrapper,
  CustomGoalName,
} from './styles';
import { GOAL_NAMES, GOAL_NAME, GOAL_MODAL_SIZE } from './consts';

import { InputField } from 'components/form/input/InputField';
import { Entries } from 'types/utils';
import { GlobalInfoModalAcceptButton } from 'components/modals/InfoModal/styles';

interface IInvestmentGoalModal {
  isInvestmentGoalModalVisible: boolean;
  setIsInvestmentGoalModalVisible: (isVisible: boolean) => void;
  setSelectedGoalName: (goalName: string) => void;
}

const InvestmentGoalModal = ({
  isInvestmentGoalModalVisible,
  setIsInvestmentGoalModalVisible,
  setSelectedGoalName,
}: IInvestmentGoalModal) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();
  const [isCustomNameVisible, setIsCustomNameVisible] = useState(false);
  const [isCustomNameAdded, setIsCustomNameAdded] = useState(false);
  const goalName = watch(GOAL_NAME);

  const closeInvestmentGoalModal = () => {
    setIsInvestmentGoalModalVisible(!isInvestmentGoalModalVisible);
  };

  return (
    <InvestmentGoalModalWrapper
      isOpen={isInvestmentGoalModalVisible}
      onRequestClose={closeInvestmentGoalModal}
      size={GOAL_MODAL_SIZE}
    >
      <InvestmentGoalModalHeader>
        <InvestmentGoalModalButtonClose onClick={closeInvestmentGoalModal} />

        <InvestmentGoalModalTitle>
          {t('raizKidsEditPage.investmentGoal.investmentGoalModal.title')}
        </InvestmentGoalModalTitle>
      </InvestmentGoalModalHeader>

      <InvestmentGoalModalContent>
        <SelectYourGoalWrapper>
          {(Object.entries(GOAL_NAMES) as Entries<typeof GOAL_NAMES>).map(
            ([key, value]) => (
              <GoalName
                key={key}
                isSelected={goalName === value && !isCustomNameVisible}
                onClick={() => {
                  setIsCustomNameVisible(false);

                  setValue(GOAL_NAME, value);
                }}
              >
                {t(
                  `raizKidsEditPage.investmentGoal.investmentGoalNames.${key}`,
                )}
              </GoalName>
            ),
          )}

          {isCustomNameVisible ? (
            <CustomGoalName>
              <InputField
                name={GOAL_NAME}
                CustomInputWrapper={CustomInputWrapper}
                inputProps={{
                  autoFocus: true,
                  onChange: () => setIsCustomNameAdded(false),
                }}
              />

              {isCustomNameAdded ? (
                <SelectedCustomNameIcon />
              ) : (
                <SelectCustomName
                  onClick={() => {
                    setIsCustomNameAdded(!!goalName);
                  }}
                >
                  {t('common.add')}
                </SelectCustomName>
              )}
            </CustomGoalName>
          ) : (
            <AddCustomGoalName onClick={() => setIsCustomNameVisible(true)}>
              {t(
                'raizKidsEditPage.investmentGoal.investmentGoalModal.addCustomGoalName',
              )}
            </AddCustomGoalName>
          )}
        </SelectYourGoalWrapper>

        <GlobalInfoModalAcceptButton
          onClick={() => {
            closeInvestmentGoalModal();

            setSelectedGoalName(goalName);
          }}
          disabled={(isCustomNameVisible && !isCustomNameAdded) || !goalName}
        >
          {goalName
            ? t('raizKidsEditPage.investmentGoal.investmentGoalModal.setGoal', {
                goalName,
              })
            : t('raizKidsEditPage.investmentGoal.investmentGoalModal.button')}
        </GlobalInfoModalAcceptButton>
      </InvestmentGoalModalContent>
    </InvestmentGoalModalWrapper>
  );
};

export default InvestmentGoalModal;
