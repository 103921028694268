import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import checkIsRewardTransaction from '../utils/checkIsRewardTransaction';

import RewardTransaction from './RewardTransaction';
import TransactionItem from './TransactionItem';

export default function TransactionDetails({
  toggleShareRewardModal,
  isLoading,
}) {
  const { transactionDetails } = useSelector((state) => ({
    transactionDetails: state.history.transactionDetails || {},
  }));

  const { localized_investment_type } = transactionDetails;
  const isReward = checkIsRewardTransaction(localized_investment_type);

  if (isReward) {
    return (
      <RewardTransaction toggleShareRewardModal={toggleShareRewardModal} />
    );
  }

  return (
    <TransactionItem
      transactionDetails={transactionDetails}
      isLoading={isLoading}
      toggleShareRewardModal={toggleShareRewardModal}
    />
  );
}

TransactionDetails.propTypes = {
  toggleShareRewardModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
