import { BANK_PROVIDERS, BANK_TYPES } from '../consts';

import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const getPurposeParam = (isSpending, isWithdrawal) => {
  if (isSpending) {
    return 'spending';
  }

  if (isWithdrawal) {
    return 'withdrawal';
  }

  return 'funding';
};

const getBankListRequestParams = (requestData) => {
  const {
    isMoreBanks,
    name,
    limit,
    isOffset,
    isSpending,
    isWithdrawal,
    isYodleeFastlinkEnabled,
  } = requestData;

  const params = {};

  if (limit) {
    params.limit = limit;
  }

  if (name) {
    params.name = name;
  }

  if (MY_APP) {
    params.purpose = getPurposeParam(isSpending, isWithdrawal);
  } else if (AU_APP) {
    params.promoted = true;

    params['provider[]'] = [BANK_PROVIDERS.yodlee];

    if (!isSpending) {
      params.type = BANK_TYPES.funding;
    }
  }

  if (isMoreBanks) {
    params.offset = 1;

    params.limit = 25;

    delete params.promoted;

    params['provider[]'] = isYodleeFastlinkEnabled
      ? [BANK_PROVIDERS.yodlee, BANK_PROVIDERS.yodleeFastlink]
      : [BANK_PROVIDERS.yodlee];

    if (isOffset) {
      const lastOffset = Number(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.bankSearchOffset),
      );

      if (!lastOffset) {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.bankSearchOffset,
          params.offset,
        );
      } else {
        params.offset = lastOffset + 1;

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.bankSearchOffset,
          params.offset,
        );
      }
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.bankSearchOffset, 1);
    }
  }

  const searchParams = new URLSearchParams();

  // This code is needed to pass several providers in one url
  // It creates this url ...?provider[]=1&provider[]=2...
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((item) => {
        searchParams.append(key, item);
      });
    } else {
      searchParams.append(key, params[key]);
    }
  });

  return searchParams.toString();
};

export default getBankListRequestParams;
