import React from 'react';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';
import '../../../styles/layout/withdraw.scss';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Form } from 'react-final-form';
import dayjs from 'dayjs';

import { REQUIRED } from '../../../constants/errors';
import FinalFormInput from '../../elements/FinalFormInput/FinalFormInput';
import SelectFF from '../../elements/SelectFF/SelectFF';
import { MY_APP } from '../../../constants/localeConfigs';
import PrivacyPolicyLink from '../Links/PrivacyPolicyLink/PrivacyPolicyLink';

import WaitSkipModal from './WaitSkipModal';
import BankErrorModal from './BankErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.holderName) {
      errors.holderName = REQUIRED;
    } else if (values.holderName.length < 6) {
      errors.holderName = t(
        'settingsPage.bankCardModal.fields.cardHolder.wrong',
      );
    }

    if (!values.cvvCode) {
      errors.cvvCode = REQUIRED;
    } else if (Number.isNaN(Number(values.cvvCode))) {
      errors.cvvCode = t('fields.validation.mustBeANumber');
    } else if (values.cvvCode.length < 3) {
      errors.cvvCode = t('settingsPage.bankCardModal.fields.cvv.wrong');
    }

    if (!values.cardMonth) {
      errors.cardMonth = REQUIRED;
    }

    if (!values.cardYear) {
      errors.cardYear = REQUIRED;
    }

    return errors;
  };

  return { validate };
};

function renderPaymentCardFields(paymentCard) {
  if (
    paymentCard?.form_fields &&
    Object.entries(paymentCard.form_fields).length > 0
  ) {
    return Object.entries(paymentCard.form_fields).map(([key, value]) => (
      <input type="text" key={key} name={key} value={value} />
    ));
  }

  return null;
}

function getMonth() {
  const monthArray = dayjs.months();
  const monthForSelect = [];
  let i = 1;

  monthArray.map((month) => {
    if (i < 10) {
      monthForSelect.push({ label: month, value: `0${i}` });
    } else {
      monthForSelect.push({ label: month, value: `${i}` });
    }

    i += 1;

    return month;
  });

  return monthForSelect;
}

function getYears() {
  const yearsStart = dayjs().format('YYYY') * 1;
  const endYear = dayjs().add(10, 'year').format('YYYY') * 1;
  const yearsForSelect = [];
  let yearsStartFormated = dayjs().format('YY') * 1;

  for (let i = yearsStart; i <= endYear; i += 1) {
    yearsForSelect.push({ label: i, value: `${yearsStartFormated}` });

    yearsStartFormated += 1;
  }

  return yearsForSelect;
}

export default function BankCardModal({
  isShowBankModal,
  handleCloseModal,
  bank,
  handleSubmit,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  showLoader,
  toggleShowPassword,
  isShowPassword,
  handleChangeCardNumber,
  cardNumberError,
  paymentCard,
  isSpending,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <div>
          {errorMessage && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowBankModalError}
              errorTitle={errorTitle}
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
              shouldCloseOnOverlayClick
              modalClassName="bank-sign"
            />
          )}

          <Modal
            isOpen={isShowBankModal}
            onRequestClose={handleCloseModal}
            contentLabel="withdraw-modal"
            shouldCloseOnOverlayClick
            className="modal-basic bank__modal bank-sign"
          >
            <div>
              <WaitSkipModal
                hasBackdrop={false}
                isVisible={false}
                isOpen={isShowBankModalAlert}
                shouldCloseOnOverlayClick
                waitHandleClick={bankLoginProcessWait}
                skipHandleClick={handleStopBankAccountLinking}
              />

              <div className="bank__modal-content">
                <DeprecatedButton
                  title={t('common.space')}
                  buttonClass="button__close -black"
                  handleClick={handleCloseModal}
                />

                <div className="bank__modal-title">
                  {MY_APP &&
                    isSpending &&
                    t('settingsPage.banksSearch.spendingAccountTitle')}

                  {MY_APP &&
                    !isSpending &&
                    t('settingsPage.linkFundingAccount')}

                  {!MY_APP && t('settingsPage.bankSignInModal.bankSignIn')}
                </div>

                <div className="bank__modal-sub">
                  <i className="bank__modal-secured-icon" />

                  {t('common.secured')}
                </div>

                <div className="bank__modal-description">
                  {isSpending
                    ? t('settingsPage.pleaseSignSpending')
                    : t('settingsPage.pleaseSign')}
                </div>

                <div className="bank__modal-name">
                  <img
                    alt={bank.bank_name}
                    className="bank__modal-icon"
                    src={bank.icon_url}
                  />

                  <span>{bank.bank_name}</span>
                </div>

                <div className="bank__modal-row">
                  <div className="hide">
                    <form
                      name="infinitium"
                      action={
                        paymentCard && paymentCard.request_url
                          ? paymentCard.request_url
                          : ''
                      }
                      method="post"
                    >
                      {paymentCard && renderPaymentCardFields(paymentCard)}
                    </form>
                  </div>

                  <form
                    className="form"
                    onSubmit={(e) =>
                      handleSubmit(e, values, form, hasValidationErrors)
                    }
                  >
                    <div className="bank__modal__container-item">
                      <div className="label -float-label">
                        {t('fields.cardNumber.label')}
                      </div>

                      <InputMask
                        name="cardNumber"
                        mask="9999 9999 9999 9999"
                        alwaysShowMask
                        permanents={[2, 5]}
                        onChange={handleChangeCardNumber}
                        maskChar=""
                        className="-float-input"
                        formatChars={{
                          8: '[23478]',
                          9: '[0-9]',
                        }}
                      />

                      {cardNumberError && (
                        <div className="error-message">{cardNumberError}</div>
                      )}
                    </div>

                    <div className="bank__modal__container-item card-expiration-date">
                      <div className="date-content">
                        <div className="label -float-label">
                          {t('common.date.month')}
                        </div>

                        <SelectFF
                          name="cardMonth"
                          placeholder=""
                          options={getMonth()}
                        />
                      </div>

                      <div className="date-content">
                        <div className="label -float-label">
                          {t('common.date.year')}
                        </div>

                        <SelectFF
                          name="cardYear"
                          placeholder=""
                          options={getYears()}
                        />
                      </div>
                    </div>

                    <div className="bank__modal__container-item">
                      <FinalFormInput
                        name="cvvCode"
                        maxLength="3"
                        isNumberOnly
                        type={isShowPassword ? 'password' : 'text'}
                        showPassword={toggleShowPassword}
                        isShowPasswordToggle
                        label={t('settingsPage.bankCardModal.fields.cvv.label')}
                      />
                    </div>

                    <div className="bank__modal__container-item">
                      <FinalFormInput
                        name="holderName"
                        isTextOnly
                        label={t(
                          'settingsPage.bankCardModal.fields.cardHolder.label',
                        )}
                      />
                    </div>

                    {MY_APP && (
                      <div className="bank__modal__container-note">
                        <div className="bank__modal__container-comment">
                          {t('settingsPage.bankCardModal.description')}
                        </div>

                        {isSpending && (
                          <div className="bank__modal__container-comment-sub">
                            <Trans
                              t={t}
                              i18nKey="settingsPage.agreePolicy"
                              components={{
                                link: <PrivacyPolicyLink />,
                              }}
                            />
                          </div>
                        )}

                        <div className="bank__modal__container-comment-sub">
                          {t(
                            'settingsPage.bankCardModal.infinitiumPartnerMessage',
                          )}
                        </div>
                      </div>
                    )}

                    <Button
                      isLoading={showLoader}
                      label={t('common.signIn')}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    />
  );
}

BankCardModal.defaultProps = {
  isShowBankModal: false,
  showLoader: false,
  isSpending: false,
};

BankCardModal.propTypes = {
  isShowBankModal: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  credentials: PropTypes.shape({}).isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  handleChangeCardNumber: PropTypes.func.isRequired,
  cardNumberError: PropTypes.string.isRequired,
  paymentCard: PropTypes.shape({
    request_url: PropTypes.string,
  }).isRequired,
  isSpending: PropTypes.bool,
};
