import { ITransformedSpendingAccountsResponse } from './types';
import { transformSpendingAccountsResponse } from './transformSpendingAccountsResponse';

import { API_TAGS } from 'store/consts';
import { api } from 'store/api';

export const spendingAccountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    spendingAccounts: build.query<ITransformedSpendingAccountsResponse, void>({
      query: () => ({
        url: '/v1/spending_accounts',
      }),
      transformResponse: transformSpendingAccountsResponse,
      providesTags: [API_TAGS.spendingAccounts],
    }),
  }),
  overrideExisting: true,
});

export const { useSpendingAccountsQuery } = spendingAccountsApi;

export const {
  endpoints: { spendingAccounts },
  util,
} = spendingAccountsApi;

export const { resetApiState } = util;

export const { initiate, useQueryState } = spendingAccounts;
