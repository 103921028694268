import { defineMessages } from 'react-intl';

import { HISTORY as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.reinvestedDividends`]: 'Dividen yang telah Dilaburkan Semula',
  [`${idPrefix}.marketReturns`]: 'Pulangan Pasaran',
  [`${idPrefix}.no`]: 'Tidak',
  [`${idPrefix}.yes`]: 'Ya',
  [`${idPrefix}.amount`]: 'Jumlah',
  [`${idPrefix}.noPendingTransactions`]: 'Tiada pelaburan buat masa ini!',
  [`${idPrefix}.processing`]: 'Sedang diproses',
  [`${idPrefix}.failed`]: 'Gagal',
  [`${idPrefix}.view`]: 'Lihat',
  [`${idPrefix}.seeMore`]: 'Lihat Lebih Lanjut',
  [`${idPrefix}.invest`]: 'Dilaburkan',
  [`${idPrefix}.cancel`]: 'Batal',
  [`${idPrefix}.ok`]: 'Ok',
  [`${idPrefix}.rebalanced`]: 'Akaun anda telah diimbang semula',
  [`${idPrefix}.hideFailed`]: 'Sorok transaksi gagal',
  [`${idPrefix}.viewFailed`]: 'Lihat transaksi gagal',
  [`${idPrefix}.symbol`]: 'Simbol',
  [`${idPrefix}.shares`]: 'Saham',
  [`${idPrefix}.history`]: 'Belum selesai',
  [`${idPrefix}.pending`]: 'Belum selesai',
  [`${idPrefix}.buy`]: 'Beli',
  [`${idPrefix}.sell`]: 'Jual',
  [`${idPrefix}.rebalancedProcess`]:
    'Akaun anda sedang dalam proses pengimbangan semula',
  [`${idPrefix}.rebalanceTitle`]: 'Pengimbangan semula',
  [`${idPrefix}.price`]: 'Harga',
  [`${idPrefix}.letYourFriends`]: 'Kongsi Bersama Rakan Anda',
});

export default messages;
