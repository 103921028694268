import { useEffect, useState } from 'react';

import { NON_EXPANDABLE_FUNDS } from './consts';

export const useChartDetailsModal = ({ activeFundTags }) => {
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    setModalData(
      activeFundTags.map((element, index) => {
        const isExpandable =
          !NON_EXPANDABLE_FUNDS.includes(element.key) && !!element?.funds;

        const isBaseFund = index === 0;

        return {
          ...element,
          isExpanded: isExpandable,
          isExpandable,
          isBaseFund,
        };
      }),
    );
  }, [activeFundTags]);

  const handleLabelClick = (name) => {
    setModalData(
      modalData.map((element) =>
        element.name === name
          ? { ...element, isExpanded: !element.isExpanded }
          : element,
      ),
    );
  };

  return { modalData, handleLabelClick };
};
