import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { MINIMUM_RECURRING_AMOUNT } from './consts';

import { formatNumber } from 'utils/formatters/formatNumber';

export const useRecurringInvestmentSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    recurringValue: yup
      .number()
      .required(t('recurringInvestmentPage.errors.noInvestmentAmount'))
      .min(
        MINIMUM_RECURRING_AMOUNT,
        t('fields.validation.minAmount', {
          amount: formatNumber({
            value: MINIMUM_RECURRING_AMOUNT,
          }),
        }),
      ),
    isInvestmentGoalEnabled: yup.boolean(),
    targetAmount: yup
      .number()
      .when('isInvestmentGoalEnabled', ([isInvestmentGoalEnabled], schema) =>
        isInvestmentGoalEnabled
          ? schema
              .required(t('recurringInvestmentPage.errors.noInvestmentAmount'))
              .min(
                MINIMUM_RECURRING_AMOUNT,
                t('fields.validation.minAmount', {
                  amount: formatNumber({
                    value: MINIMUM_RECURRING_AMOUNT,
                  }),
                }),
              )
          : schema,
      ),
    goalName: yup
      .string()
      .when('isInvestmentGoalEnabled', ([isInvestmentGoalEnabled], schema) =>
        isInvestmentGoalEnabled
          ? schema.required(
              t('raizKidsEditPage.investmentGoal.pleaseEnterGoalName'),
            )
          : schema,
      ),
  });
};
