import { useMatch, generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { PATHS } from 'constants/paths';

export const useProfilePage = () => {
  const isUpdateProfilePage = useMatch(PATHS.settings.updateProfile);
  const { activeTab } = useParams();

  const isProfilePage = useMatch(
    generatePath(PATHS.settings.profile, {
      activeTab: activeTab || null,
    }),
  );

  return { isUpdateProfilePage, isProfilePage };
};
