import styled from '@emotion/styled';

import { WidgetContent, WidgetHeader, WidgetText } from '../../styles';

import avatars from 'assets/images/kids-icons/avatars.svg';

export const KidsWidgetWrapper = styled(WidgetContent)`
  background: ${({ theme }) => theme.gradients.green};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 0 0 6px 6px;
`;

export const KidsWidget = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 13px;
  justify-content: center;
`;

export const ChildWrapper = styled.div`
  width: 25%;
  text-align: center;
`;

export const ChildImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 6px;
`;

export const ChildImage = styled.img`
  vertical-align: middle;
  border-style: none;
  width: 60px;
  height: 60px;
  margin-bottom: 6px;
`;

export const ChildName = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.length['2sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 3px;
`;

export const ChildAmount = styled.div`
  margin-bottom: 16px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['3md']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.white};
`;

export const NewChildWrapper = styled.div`
  width: 100%;
`;

export const NewChildHeader = styled.div`
  background: ${({ theme }) => theme.colors.whiteTransparentMiddle};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-bottom: 14px;
  height: 164px;
`;

export const NewChildHeaderTitle = styled(WidgetHeader)`
  font-size: ${({ theme }) => theme.fontSizes['2lg']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  max-width: 250px;
  margin: 0 auto;
`;

export const NewChildHeaderIcon = styled.div`
  background-image: url(${avatars});
  height: 64px;
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
`;

export const NewChildDescription = styled(WidgetText)`
  opacity: 1;
  text-align: left;
  padding: 2px 0 0 0;
`;

export const NewChildSetupDescription = styled(NewChildDescription)`
  margin-top: 10px;
`;
