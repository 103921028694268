import styled from '@emotion/styled';

import {
  CustomizationFooterButton,
  FundsListWrapper,
} from '../FundsList/styles';

import placeholderFund from 'assets/images/icons/placeholder-fund.svg';

interface IFundDetailsWrapperProps {
  isFooterVisible?: boolean;
}

interface IHeaderImageProps {
  background?: string;
}

interface IFundItemTotalWrapperProps {
  isDetailsPage?: boolean;
}

export const FundDetailsWrapper = styled(
  FundsListWrapper,
)<IFundDetailsWrapperProps>`
  background: ${({ theme }) => theme.colors.wildSand};
  border-radius: ${({ isFooterVisible }) =>
    isFooterVisible ? 0 : '0 0 10px 10px'};
  padding: 16px;
  position: relative;
  margin: 0 0 100px 0;
`;

export const HeaderWrapper = styled.div`
  text-align: left;
  margin-bottom: 22px;
`;

export const HeaderImage = styled.div<IHeaderImageProps>`
  background: ${({ theme, background }) =>
    background
      ? `url(${background}) ${theme.colors.white} no-repeat center center / contain`
      : `url(${placeholderFund}) ${theme.colors.white} no-repeat center center / auto`};
  width: 104px;
  height: 104px;
  margin-top: -68px;
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadows.header};
  border: 16px solid ${({ theme }) => theme.colors.white};
`;

export const HeaderTitle = styled.div`
  margin-top: 14px;
  font-size: ${({ theme }) => theme.fontSizes['6md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.mirage};
`;

export const HeaderDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.mirage};
`;

export const FundDetailsFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  padding: 18px 32px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 16px;
  }
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  position: sticky;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 24px;
  text-align: left;
  right: 0;
  width: 100%;
`;

export const EditFundWrapper = styled.div`
  padding-top: 5px;
  float: left;
  margin-right: 20px;
`;

export const FooterButton = styled(CustomizationFooterButton)`
  float: right;
`;

export const FundItemTotalWrapper = styled.div<IFundItemTotalWrapperProps>`
  display: flex;
  gap: 4px;
  ${({ theme, isDetailsPage }) => `
    font-size: ${
      isDetailsPage ? theme.fontSizes['5sm'] : theme.fontSizes['3sm']
    };
    color: ${theme.colors.grey};
    font-weight: ${theme.fontWeights.normal};
    padding: ${isDetailsPage ? '6px 0 6px 0' : 0};
    line-height: ${theme.lineHeights.length['2xl']};
  `}
  margin-right:  8px;
  align-items: center;
  justify-content: end;
`;

interface IFundItemTotalPercentProps {
  isDetailsPage?: boolean;
}

export const FundItemTotalPercent = styled.span<IFundItemTotalPercentProps>`
  padding-right: ${({ isDetailsPage }) => (isDetailsPage ? 6 : 0)}px;
  ${({ theme }) => `
    font-family: ${theme.fontFamily.medium};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.niagara};
    border: 1px dashed ${theme.colors.silver};
  `}
  padding: 0 4px;
  border-radius: 4px;
  width: 60px;
  display: flex;
  justify-content: center;
`;

export const FundTotalWeightingDetailsWrapper = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.medium};
    font-size: ${theme.fontSizes['6sm']};
    color: ${theme.colors.mirage};
    font-weight: ${theme.fontWeights.medium};
  `}
  text-transform: uppercase;
  padding-top: 10px;
`;

export const FundMaxWeightingValue = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.regular};
    font-weight: ${theme.fontWeights.normal};
  `}
  padding-top: 10px;
`;

interface IHelpIconWrapperProps {
  isDetailsPage?: boolean;
}

export const HelpIconWrapper = styled.div<IHelpIconWrapperProps>`
  display: flex;
  padding: ${({ isDetailsPage }) =>
    isDetailsPage ? '0 0 6px 4px' : '0 0 6px 8px'};
`;
