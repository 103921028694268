import { getHeaders } from './headers';

export default function createRequest(
  data,
  method,
  endpoint,
  platformPrefix = '',
) {
  const requestData = {
    method,
    mode: 'cors',
    headers: getHeaders(),
  };

  if (data) {
    requestData.body = data;
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}${platformPrefix}/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
    requestData,
  );
}
