import {
  IFund,
  IPreviewParams,
  IPreviewResponse,
  IUseFundsParams,
} from '../types';

import { API_TAGS } from 'store/consts';
import { api } from 'store/api';

export const portfolioApi = api.injectEndpoints({
  endpoints: (build) => ({
    fundsByTags: build.query<IFund, IUseFundsParams>({
      query: ({ params, headers }) => ({
        url: '/v1/custom_portfolios/funds/by_tags',
        method: 'POST',
        params,
        headers,
      }),
      providesTags: [API_TAGS.fundsByTags],
    }),
    preview: build.mutation<IPreviewResponse, IPreviewParams>({
      query: ({ data, headers }) => ({
        url: '/v2/custom_portfolios/preview_plus',
        method: 'POST',
        data,
        headers,
      }),
    }),
  }),
  overrideExisting: true,
});
export const { useFundsByTagsQuery, usePreviewMutation } = portfolioApi;

export const {
  endpoints: { fundsByTags },
} = portfolioApi;
