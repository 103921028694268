import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { MINIMUM_WEEKLY_LIMIT } from './consts';

import { formatNumber } from 'utils/formatters/formatNumber';

export const useInvestingAccessSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    investing_weekly_limit: yup
      .number()
      .when('$isWeeklyLimitEnabled', ([isWeeklyLimitEnabled], schema) => {
        if (isWeeklyLimitEnabled) {
          return schema
            .required(
              t('fields.validation.requiredField', {
                fieldName: t('raizKidsEditPage.weeklyLimitLabel'),
              }),
            )
            .min(
              MINIMUM_WEEKLY_LIMIT,
              t('fields.validation.minAmount', {
                amount: formatNumber({
                  value: MINIMUM_WEEKLY_LIMIT,
                }),
              }),
            );
        }

        return schema;
      }),
  });
};
