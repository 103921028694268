// TODO: https://acornsau.atlassian.net/browse/RAIZ-6541
export const SESSION_STORAGE_KEYS = {
  reloginData: 'reloginData',
  user: 'user',
  userSuitability: 'userSuitability',
  isSmsfUser: 'isSmsfUser',
  token: 'token',
  historyTransactions: 'historyTransactions',
  registration: 'registration',
  portfolios: 'portfolios',
  lastActivity: 'lastActivity',
  kidsAccess: 'kidsAccess',
  kidsRegistration: 'kids-registration',
  agreementType: 'agreementType',
  historyTransactionsTotalPages: 'historyTransactionsTotalPages',
  isNeedFixBottom: 'isNeedFixBottom',
  isPreviousEventWithNote: 'isPreviousEventWithNote',
  historyPreviusPending: 'historyPreviusPending',
  historyPreviusData: 'historyPreviusData',
  superUser: 'superUser',
  sourceId: 'sourceId',
  registrationQuestions: 'registrationQuestions',
  stopBankLoginRequest: 'stopBankLoginRequest',
  bankSearchInstitutions: 'bankSearchInstitutions',
  inviteCode: 'inviteCode',
  registrationScurityQuestion: 'registrationScurityQuestion',
  deleteAccountId: 'deleteAccountId',
  localFunds: 'localFunds',
  bankLoginCount: 'bankLoginCount',
  stopBankLogin: 'stopBankLogin',
  loginInBankIntervalId: 'loginInBankIntervalId',
  bankSearchOffset: 'bankSearchOffset',
  rewardsOnlineItem: 'rewardsOnlineItem',
  rewardsInstoreItem: 'rewardsInstoreItem',
  rewardsFavouritesItem: 'rewardsFavouritesItem',
  sessionTripsResponse: 'sessionTripsResponse',
  requiredDocuments: 'requiredDocuments',
  lastPathName: 'lastPathName',
  historyTransactionsOffset: 'historyTransactionsOffset',
  investedValue: 'investedValue',
  store: 'store',
  historyTransactionsStatus: 'historyTransactionsStatus',
  isAutoVerificationModalOpen: 'isAutoVerificationModalOpen',
} as const;
