import React from 'react';
import Switch from 'react-switch';
import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

import { ICustomWrapper, IFieldProps } from '../types';

import { SwitchLabel, SwitchWrapper } from './styles';

import shadows from 'theme/shadows';
import colors from 'theme/colors';

interface ISwitchFieldProps<T extends FieldValues> extends IFieldProps<T> {
  handleDiameter?: number;
  height?: number;
  width?: number;
  CustomSwitchWrapper?: React.FC<ICustomWrapper>;
  isDisabled?: boolean;
}

export const SwitchField = <T extends FieldValues>({
  name,
  label,
  handleDiameter = 26,
  height = 30,
  width = 50,
  CustomSwitchWrapper = SwitchWrapper,
  isDisabled,
}: ISwitchFieldProps<T>) => {
  const { control, getValues, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomSwitchWrapper>
          <SwitchLabel>{label}</SwitchLabel>

          <Switch
            {...field}
            onChange={(value) => {
              setValue(name, value as FieldPathValue<FieldValues, Path<T>>);
            }}
            checked={!!getValues(name)}
            onColor={colors.niagaraLight}
            offColor={colors.alto}
            onHandleColor={colors.white}
            handleDiameter={handleDiameter}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={shadows.light}
            activeBoxShadow="0"
            height={height}
            width={width}
            disabled={isDisabled}
          />
        </CustomSwitchWrapper>
      )}
    />
  );
};
