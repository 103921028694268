import styled from '@emotion/styled';
import Modal from 'react-modal';

import { DEFAULT_MODAL_WIDTH } from './const';

export const BaseModalWrapper = styled(Modal)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  color: ${({ theme }) => theme.colors.mirage};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${DEFAULT_MODAL_WIDTH}px;
  overflow: auto;
  padding: 30px 40px 32px;
  position: relative;
  margin: 100px auto;
  :focus {
    outline: 0;
  }
`;
