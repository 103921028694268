import { ASSETS_BASE_URL } from './consts';
import { ICountryState } from './types';

import { api } from 'store/api';

export const assetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCountryStates: build.query<ICountryState[], void>({
      query: () => ({
        baseURL: ASSETS_BASE_URL,
        url: 'states.json',
      }),
    }),
  }),
  overrideExisting: true,
});
export const { useGetCountryStatesQuery } = assetsApi;
