import styled from '@emotion/styled';
import Modal from 'react-modal';

import logoKids from 'assets/images/logo_kids.svg';
import kidsBannerAu from 'assets/images/illustration.svg';
import kidsBannerMy from 'assets/images/kids-mys-icon.svg';
import { AU_APP } from 'constants/localeConfigs';

interface IModalWrapper {
  isRewards: boolean;
}

export const ModalWrapper = styled(Modal)<IModalWrapper>`
  width: 455px;
  min-height: 220px;
  max-height: 90%;
  margin: 2% auto 0;
  padding: 40px;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  overflow-y: auto;
  overflow-x: hidden;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  text-align: center;
  border-radius: 10px;
  background: ${({ theme, isRewards }) =>
    isRewards ? theme.gradients.fierySunshine : theme.gradients.vibrantVerdant};
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 33px;
`;

export const Logo = styled.div`
  display: block;
  height: 59px;
  background-image: url(${`${logoKids}`});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.div`
  display: block;
  background-image: url(${`${AU_APP ? kidsBannerAu : kidsBannerMy}`});
  height: 295px;
  background-repeat: no-repeat;
  background-position: 30%;
  margin-top: 30px;
  background-size: contain;
  background-position: center;
`;

export const Title = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes['5lg']};
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  line-height: ${({ theme }) => theme.lineHeights.length['4xl']};
  margin-bottom: 25px;
`;

export const Description = styled.div`
  text-align: left;
  opacity: ${({ theme }) => theme.opacity.semiTransparent};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  margin-bottom: 40px;
`;
