import { useTranslation } from 'react-i18next';

import {
  Divider,
  Wrapper,
  SettingsButton,
  Subtitle,
  Title,
  TitleWrapper,
} from './styles';
import { BankGridHeaderRegistration } from './BankGridHeaderRegistration';

import { IconButton } from 'components/elements/IconButton';
import { IBank } from 'store/banks';

interface IBankGridHeader {
  openSettingsModal: () => void;
  isRegistration?: boolean;
  selectedBank: IBank | null;
}

export const BankGridHeader = ({
  openSettingsModal,
  isRegistration,
  selectedBank,
}: IBankGridHeader) => {
  const { t } = useTranslation();

  if (isRegistration) {
    return <BankGridHeaderRegistration selectedBank={selectedBank} />;
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('settingsPage.bankGridHeader.title')}</Title>

        <SettingsButton>
          <IconButton iconName="gear" size={18} onClick={openSettingsModal} />
        </SettingsButton>
      </TitleWrapper>

      <Divider />

      <Subtitle>{t('settingsPage.bankGridHeader.subTitle')}</Subtitle>
    </Wrapper>
  );
};
