import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';

import '../../../styles/layout/withdraw.scss';
import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

function isNeedDecimalScale(n) {
  return n % 1 !== 0;
}

class OneTimeInvestmentModal extends Component {
  render() {
    const { t } = this.props;

    const {
      isOpenInvestmentModal,
      openInvestmentModal,
      changeOneTimeInvestmentValue,
      oneTimeInvestmentValue,
      openInvestmentInfoModal,
    } = this.props;

    const minInvest = 4;

    return (
      <div>
        <Modal
          isOpen={!!isOpenInvestmentModal}
          onRequestClose={() => openInvestmentModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          className="modal-basic time-investment__modal"
        >
          <div className="modal-basic__content">
            <DeprecatedButton
              buttonClass="button__close"
              handleClick={() => openInvestmentModal(false)}
            />

            <div className="modal-basic__title">
              {t('oneTimeInvestment.title.oneTimeInvestment')}
            </div>

            <div className="modal-basic__value -with-margin">
              <div
                className={`modal-basic__input ${
                  oneTimeInvestmentValue > minInvest ? '-active' : ''
                } time-investment__input`}
              >
                <NumberFormat
                  value={oneTimeInvestmentValue || ''}
                  displayType="input"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  placeholder={`${CURRENCY_SYMBOL}0.00`}
                  decimalScale={2}
                  allowNegative={false}
                  fixedDecimalScale={isNeedDecimalScale(
                    oneTimeInvestmentValue || '',
                  )}
                  prefix={CURRENCY_SYMBOL}
                  onValueChange={(values) => {
                    changeOneTimeInvestmentValue(values.floatValue);
                  }}
                />
              </div>
            </div>

            <div className="modal-basic__label">
              {t('oneTimeInvestment.minimumOf.label', {
                CURRENCY_SYMBOL,
              })}
            </div>

            <div className="time-investment__buttons">
              <DeprecatedButton
                title={
                  <NumberFormat
                    value={10}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    prefix={CURRENCY_SYMBOL}
                    decimalScale={4}
                  />
                }
                buttonClass="button__rounded-transparent"
                handleClick={() => changeOneTimeInvestmentValue(10)}
              />

              <DeprecatedButton
                title={
                  <NumberFormat
                    value={25}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    prefix={CURRENCY_SYMBOL}
                    decimalScale={2}
                    suffix=""
                  />
                }
                buttonClass="button__rounded-transparent"
                handleClick={() => changeOneTimeInvestmentValue(25)}
              />

              <DeprecatedButton
                title={
                  <NumberFormat
                    value={50}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    prefix={CURRENCY_SYMBOL}
                    decimalScale={2}
                    suffix=""
                  />
                }
                buttonClass="button__rounded-transparent"
                handleClick={() => changeOneTimeInvestmentValue(50)}
              />

              <DeprecatedButton
                title={
                  <NumberFormat
                    value={100}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    prefix={CURRENCY_SYMBOL}
                    decimalScale={2}
                    suffix=""
                  />
                }
                buttonClass="button__rounded-transparent"
                handleClick={() => changeOneTimeInvestmentValue(100)}
              />
            </div>
          </div>

          <div className="modal-basic__footer">
            <Button
              label={t('common.invest')}
              onClick={() => openInvestmentInfoModal(true)}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

OneTimeInvestmentModal.propTypes = {
  t: PropTypes.func.isRequired,
  openInvestmentModal: PropTypes.func.isRequired,
  openInvestmentInfoModal: PropTypes.func.isRequired,
  changeOneTimeInvestmentValue: PropTypes.func.isRequired,
  oneTimeInvestmentValue: PropTypes.number.isRequired,
  isOpenInvestmentModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
};

export default withTranslation()(OneTimeInvestmentModal);
