import 'styles/pages/super.scss';
import PropTypes from 'prop-types';

import SuperBanner from './components/SuperBanner';

import { Perfomance } from 'components/layout/Perfomance/Perfomance';
import WidgetsHistory from 'components/layout/Widgets/History/History';
import Loader from 'components/elements/Loader/Loader';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';

export default function SuperView({ currentBalance, showMainLoader }) {
  useSuperAccountErrorModal();

  return (
    <div className="super__content page-content">
      {showMainLoader && <Loader additionalClass="-absolute -top" />}

      {!showMainLoader && (
        <div className="super__full">
          <SuperBanner currentBalance={currentBalance} />

          <div className="page-content__dashboard">
            <Perfomance isSuper />

            <WidgetsHistory isSuper />
          </div>
        </div>
      )}
    </div>
  );
}

SuperView.defaultProps = {
  currentBalance: 0,
};

SuperView.propTypes = {
  currentBalance: PropTypes.number,
  showMainLoader: PropTypes.bool.isRequired,
};
