import Skeleton from 'react-skeleton-loader';
import { range } from 'lodash';

import {
  CategoryDescription,
  CategoryStatus,
  CategoryTitle,
  CategoryWrapper,
  EditIcon,
} from '../styles';

const TransactionCategorisationSkeleton = () =>
  range(5).map(() => (
    <div key={window.crypto.randomUUID()}>
      <CategoryWrapper>
        <Skeleton />
      </CategoryWrapper>

      <CategoryWrapper>
        <CategoryTitle>
          <CategoryStatus>
            <EditIcon iconName="edit" size={16} />

            <Skeleton />
          </CategoryStatus>

          <div>
            <Skeleton />
          </div>
        </CategoryTitle>

        <CategoryDescription>
          <Skeleton />
        </CategoryDescription>
      </CategoryWrapper>
    </div>
  ));

export default TransactionCategorisationSkeleton;
