import { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Firebase from 'firebase/app';
import { withTranslation } from 'react-i18next';

import * as perfomance from '../../store/perfomance/actions';
import * as perfomanceSelectors from '../../store/perfomance/reducers';
import * as actionsFromUser from '../../store/user/actions';
import * as dependencyUsers from '../../store/dependentUser/actions';
import {
  CURRENCY_LOCALE,
  CURRENCY_NAME,
  CURRENCY_SYMBOL,
} from '../../constants/localeConfigs';

import { PERIOD_DAYS_NUMBER } from './consts';
import PerfomanceDetailsView from './PerfomanceDetailsView';

import {
  selectChartData,
  selectPeriodMarketChange,
} from 'store/perfomance/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class PerfomanceDetails extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));

    const superUser = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.superUser),
    );

    let currentBalance = `${CURRENCY_SYMBOL}0`;
    let currentBalanceSuper = `${CURRENCY_SYMBOL}0`;
    let dailyMarketChange = `${CURRENCY_SYMBOL}0`;
    let marketPercent = 0;
    const { isEditKids } = this.props;

    if (user && user.user) {
      currentBalance = new Intl.NumberFormat(CURRENCY_LOCALE, {
        style: 'currency',
        currency: CURRENCY_NAME,
      }).format(user.user.current_balance);

      currentBalanceSuper = new Intl.NumberFormat(CURRENCY_LOCALE, {
        style: 'currency',
        currency: CURRENCY_NAME,
      }).format(user.user.super_current_balance);

      if (superUser && superUser.user && props.isSuper) {
        dailyMarketChange = new Intl.NumberFormat(CURRENCY_LOCALE, {
          style: 'currency',
          currency: CURRENCY_NAME,
        }).format(superUser.user.daily_change.account_change);

        marketPercent = superUser.user.daily_change.account_percent;
      } else if (!isEditKids) {
        dailyMarketChange = new Intl.NumberFormat(CURRENCY_LOCALE, {
          style: 'currency',
          currency: CURRENCY_NAME,
        }).format(user.user.daily_change.market_change);

        marketPercent = user.user.daily_change.market_percent;
      }
    }

    this.state = {
      periodDays: null,
      accountValue: props.isSuper ? currentBalanceSuper : currentBalance,
      accountValueStart: props.isSuper ? currentBalanceSuper : currentBalance,
      etfType: 'dollar',
      dailyMarketChange,
      dailyMarketPercent: marketPercent,
      etfName: '',
      isEtf: false,
      etfId: null,
      width: window.innerWidth,
      height: window.innerHeight,
      isSetResize: false,
      isShowLoader: true,
      activeAllocationProfile: {},
      etfIndex: 0,
    };

    this.toggleShow = this.toggleShow.bind(this);

    this.toggleEtfShow = this.toggleEtfShow.bind(this);

    this.showSecurities = this.showSecurities.bind(this);

    this.back = this.back.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.showModal = this.showModal.bind(this);

    this.resizeUpdate = this.resizeUpdate.bind(this);

    this.toggleLoader = this.toggleLoader.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      userActions,
      isSuper,
      isDashboard,
      isEditKids,
      match,
      dependencyActions,
    } = this.props;

    this._isMounted = true;

    document.addEventListener('mouseout', this.setDefaultAccountValue);

    if (this._isMounted) {
      const { childId } =
        match && match.params ? match.params : { childId: '' };

      actions.getSummaryNew({
        numberOfDays: isDashboard ? PERIOD_DAYS_NUMBER.oneMonth : null,
        isSuper,
        isDashboard,
        isEditKids,
        dependentId: childId,
      });

      if (isSuper) {
        userActions.getSuperUserData();
      } else if (isEditKids) {
        dependencyActions.getDependencyUser(childId).then(() => {
          const { child } = this.props;

          const currentChildBalance = new Intl.NumberFormat(CURRENCY_LOCALE, {
            style: 'currency',
            currency: CURRENCY_NAME,
          }).format(child.dependency_user.current_balance);

          if (child && child.dependency_user) {
            this.setState({
              accountValue: currentChildBalance,
              accountValueStart: currentChildBalance,
              dailyMarketChange: child.dependency_user.daily_change.change,
              dailyMarketPercent: child.dependency_user.daily_change.percentage,
            });
          }
        });
      }

      userActions.getUserData();

      actions.getAllocationProfiles(isSuper, isEditKids, childId);

      actions.getMarketStatus();
    }

    this.setState({
      performanceChartWidth: Number.parseInt(
        document.getElementById('perfomance-chart')?.getBoundingClientRect()
          ?.width,
      ),
    });

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showLoader } = this.state;

    if (prevState.isShowLoader !== showLoader) {
      this.toggleLoader(showLoader);
    }

    if (prevState.chartData && prevState.chartData.length !== 0) {
      setTimeout(() => this.checkGraph(), 1000);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const returnProps = {};

    if (nextProps.isShowLoader !== prevState.isShowLoader) {
      returnProps.showLoader = nextProps.isShowLoader;
    }

    if (nextProps.chartData && nextProps.chartData.length !== 0) {
      returnProps.chartData = nextProps.chartData;
    }

    return returnProps;
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.perfomanceUnmount();

    document.removeEventListener('mouseout', this.setDefaultAccountValue);

    this._isMounted = false;
  }

  handleCloseModal() {
    const { actions } = this.props;

    actions.closeModal();
  }

  setDefaultAccountValue = () => {
    const { accountValueStart } = this.state;

    const accountValueObj = document.getElementsByClassName(
      'page-content__banner-value',
    );

    if (accountValueObj && accountValueObj[0] && accountValueObj.length !== 0) {
      if (accountValueStart !== accountValueObj[0].innerHTML) {
        accountValueObj[0].innerHTML = accountValueStart;
      }
    }
  };

  toggleShow = (numberOfDays) => {
    const { actions, isSuper, isEditKids, match } = this.props;
    const { isEtf, etfId, performanceChartWidth } = this.state;

    this.setState({
      isShowLoader: true,
    });

    let changeInValue = '1m';

    if (numberOfDays === PERIOD_DAYS_NUMBER.oneDay) {
      changeInValue = '1 Day';
    }

    if (numberOfDays === PERIOD_DAYS_NUMBER.oneMonth) {
      changeInValue = '1M';
    }

    if (numberOfDays === PERIOD_DAYS_NUMBER.threeMonths) {
      changeInValue = '3M';
    }

    if (numberOfDays === PERIOD_DAYS_NUMBER.halfYear) {
      changeInValue = '6M';
    }

    if (numberOfDays === PERIOD_DAYS_NUMBER.oneYear) {
      changeInValue = '1Y';
    }

    if (!numberOfDays) {
      changeInValue = 'All';
    }

    Firebase.analytics().logEvent('Performance_Graph', {
      event_key: changeInValue,
    });

    if (isEtf && etfId) {
      actions.getSecurities({
        securitiesId: etfId,
        numberOfDays,
        maxPoints: performanceChartWidth,
      });
    } else {
      const { childId } =
        match && match.params ? match.params : { childId: null };

      actions.getSummaryNew({
        numberOfDays,
        isSuper,
        isDashboard: false,
        isEditKids,
        dependentId: childId,
      });
    }

    this.setState({
      periodDays: numberOfDays,
    });
  };

  toggleEtfShow = (etfType) => {
    this.setState({
      etfType,
    });
  };

  showSecurities = (id, etfName, sharePrice, allocationProfile, index) => {
    const { actions } = this.props;
    const { performanceChartWidth } = this.state;

    const sharePriceFormat = formatNumber({
      value: sharePrice,
    });

    this.setState({
      etfName,
      isEtf: true,
      accountValueStart: sharePriceFormat,
      accountValue: sharePriceFormat,
      etfId: id,
      periodDays: PERIOD_DAYS_NUMBER.oneYear,
      activeAllocationProfile: allocationProfile,
      etfIndex: index,
    });

    actions.getSecurities({
      securitiesId: id,
      numberOfDays: PERIOD_DAYS_NUMBER.oneYear,
      maxPoints: performanceChartWidth,
    });
  };

  back = () => {
    const { actions, isSuper, match, isEditKids } = this.props;
    const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));

    const currentBalance = new Intl.NumberFormat(CURRENCY_LOCALE, {
      style: 'currency',
      currency: CURRENCY_NAME,
    }).format(user.user.current_balance);

    this.setState({
      isEtf: false,
      periodDays: null,
      accountValue: currentBalance,
      accountValueStart: currentBalance,
    });

    const { childId } = match ? match.params : '';

    actions.getSummaryNew({
      numberOfDays: null,
      isSuper,
      isDashboard: false,
      isEditKids,
      dependentId: childId,
    });
  };

  toggleLoader(condition) {
    this.setState({
      isShowLoader: condition,
    });
  }

  checkGraph() {
    const markerDividendWrap = document.querySelector(
      '.apexcharts-series-markers-wrap',
    );

    if (
      markerDividendWrap &&
      markerDividendWrap.children &&
      markerDividendWrap.children[0]
    ) {
      const children = [].slice.call(markerDividendWrap.children);
      let cx = 0;
      let cy = 0;
      let activeContainer = {};

      children.map((circle) => {
        if (circle.children && circle.children[0]) {
          if (
            circle.children[0].attributes.r &&
            circle.children[0].attributes.r.value === '8'
          ) {
            cx = circle.children[0].attributes.cx.value - 3.7;

            cy = parseFloat(circle.children[0].attributes.cy.value) + 4.3;

            if (!circle.children[1]) {
              activeContainer = circle;

              activeContainer.innerHTML += `<text id="chk" x=${cx} y=${cy}>&#36;</text>`;
            }
          }
        }

        return circle;
      });
    }
  }

  showModal() {
    const { actions } = this.props;

    actions.showModal();
  }

  resizeUpdate(width, height) {
    const { chartData } = this.props;

    this.setState({
      width,
      height,
      isSetResize: true,
    });

    if (chartData && chartData.length !== 0) {
      setTimeout(() => this.checkGraph(), 500);
    }
  }

  render() {
    const {
      periodDays,
      accountValue,
      etfType,
      dailyMarketChange,
      dailyMarketPercent,
      etfName,
      isEtf,
      width,
      height,
      isSetResize,
      isShowLoader,
      activeAllocationProfile,
      etfIndex,
    } = this.state;

    const {
      chartData,
      periodMarketChange,
      periodMarketChangePercent,
      chartDataPresent,
      allocationProfiles,
      securitiesPricesChartData,
      periodMarketChangeEtf,
      periodMarketChangePercentEtf,
      securitiesFundamentals,
      isShowErrorModal,
      errorMessage,
      isDashboard,
      isSuper,
      type,
      status,
      previousCloseAmount,
      dividends,
      chartDataAdditional,
      t,
    } = this.props;

    return (
      <PerfomanceDetailsView
        status={status}
        title={t('performanceDetailsPage.title')}
        toggleShow={this.toggleShow}
        periodDays={periodDays}
        chartData={chartData}
        type={type}
        accountValue={accountValue}
        periodMarketChange={periodMarketChange}
        periodMarketChangePercent={periodMarketChangePercent}
        chartDataPresent={chartDataPresent}
        allocationProfiles={allocationProfiles}
        toggleEtfShow={this.toggleEtfShow}
        etfType={etfType}
        showSecurities={this.showSecurities}
        securitiesPricesChartData={securitiesPricesChartData}
        periodMarketChangeEtf={periodMarketChangeEtf}
        periodMarketChangePercentEtf={periodMarketChangePercentEtf}
        dailyMarketChange={dailyMarketChange}
        dailyMarketPercent={dailyMarketPercent}
        etfName={etfName}
        isEtf={isEtf}
        securitiesFundamentals={securitiesFundamentals}
        back={this.back}
        isShowErrorModal={isShowErrorModal}
        errorMessage={errorMessage}
        handleCloseModal={this.handleCloseModal}
        showModal={this.showModal}
        isDashboard={isDashboard}
        isSuper={isSuper}
        previousCloseAmount={previousCloseAmount}
        dividends={dividends}
        widthResize={width}
        heightResize={height}
        resizeUpdate={this.resizeUpdate}
        isSetResize={isSetResize}
        isShowLoader={isShowLoader}
        chartDataAdditional={chartDataAdditional}
        activeAllocationProfile={activeAllocationProfile}
        etfIndex={etfIndex}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  summary: state.perfomance.summary,
  present: state.perfomance.present,
  chartData: selectChartData()(state),
  chartDataAdditional: selectChartData(true)(state),
  chartDataPresent:
    perfomanceSelectors.getAccountBalanceChartDataPresent(state),
  periodMarketChange: selectPeriodMarketChange(state),
  periodMarketChangePercent:
    perfomanceSelectors.getPeriodMarketChangePercent(state),
  allocationProfiles: state.perfomance.allocationProfiles,
  securitiesPrices: state.perfomance.securitiesPrices,
  securitiesPricesChartData:
    perfomanceSelectors.getSecuritiesPricesChartData(state),
  periodMarketChangeEtf: perfomanceSelectors.getPeriodMarketChangeEtf(state),
  periodMarketChangePercentEtf:
    perfomanceSelectors.getPeriodMarketChangePercentEtf(state),
  securitiesFundamentals: state.perfomance.securitiesFundamentals,
  isShowErrorModal: state.perfomance.isShowErrorModal,
  errorMessage: state.perfomance.errorMessage,
  superUser: state.perfomance.superUser,
  status: state.perfomance.status,
  type: state.perfomance.type,
  previousCloseAmount: perfomanceSelectors.getPreviousCloseAmount(state),
  dividends: perfomanceSelectors.getDividends(state),
  isShowLoader: state.perfomance.isShowLoader,
  child: state.dependencyUsers.child,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(perfomance, dispatch),
  userActions: bindActionCreators(actionsFromUser, dispatch),
  dependencyActions: bindActionCreators(dependencyUsers, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PerfomanceDetails));

PerfomanceDetails.defaultProps = {
  summary: {},
  chartData: [],
  chartDataPresent: [],
  periodMarketChange: `${CURRENCY_SYMBOL}0`,
  periodMarketChangePercent: '0%',
  allocationProfiles: [],
  securitiesFundamentals: null,
  securitiesPricesChartData: [],
  periodMarketChangeEtf: `${CURRENCY_SYMBOL}0`,
  periodMarketChangePercentEtf: '0%',
  isShowErrorModal: false,
  errorMessage: '',
  type: '',
  isDashboard: false,
  isSuper: false,
  isEditKids: false,
  status: {},
  superUser: {},
  child: {},
  match: {},
  previousCloseAmount: '',
  isShowLoader: true,
  chartDataAdditional: [],
};

PerfomanceDetails.propTypes = {
  actions: PropTypes.shape({
    getSummaryNew: PropTypes.func,
    getAllocationProfiles: PropTypes.func,
    getMarketStatus: PropTypes.func,
    getSecurities: PropTypes.func,
    perfomanceUnmount: PropTypes.func,
    closeModal: PropTypes.func,
    showModal: PropTypes.func,
  }).isRequired,
  userActions: PropTypes.shape({
    getSuperUserData: PropTypes.func,
    getUserData: PropTypes.func,
  }).isRequired,
  dependencyActions: PropTypes.shape({
    getDependencyUser: PropTypes.func,
  }).isRequired,
  summary: PropTypes.shape({}),
  chartData: PropTypes.arrayOf(PropTypes.shape({})),
  chartDataPresent: PropTypes.arrayOf(PropTypes.shape({})),
  periodMarketChange: PropTypes.string,
  periodMarketChangePercent: PropTypes.string,
  allocationProfiles: PropTypes.arrayOf(PropTypes.shape({})),
  securitiesFundamentals: PropTypes.shape({}),
  securitiesPricesChartData: PropTypes.arrayOf(PropTypes.shape({})),
  dividends: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  periodMarketChangeEtf: PropTypes.string,
  periodMarketChangePercentEtf: PropTypes.string,
  isShowErrorModal: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  isDashboard: PropTypes.bool,
  isSuper: PropTypes.bool,
  isEditKids: PropTypes.bool,
  status: PropTypes.shape({}),
  child: PropTypes.shape({
    dependency_user: PropTypes.shape({
      current_balance: PropTypes.number,
      daily_change: PropTypes.shape({
        change: PropTypes.number,
        percentage: PropTypes.number,
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  superUser: PropTypes.shape({}),
  previousCloseAmount: PropTypes.string,
  isShowLoader: PropTypes.bool,
  chartDataAdditional: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
};
