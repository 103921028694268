import { useTranslation } from 'react-i18next';

import { updateBasePortfolio } from 'store/portfolio/actions';
import {
  selectActivePortfolioId,
  selectIsPortfolioLoading,
  selectPortfolioParams,
  selectUserFundsFlattened,
} from 'store/portfolio/selectors';
import { usePortfolioBackButtonHandler } from 'pages/Portfolio/components/PortfolioBackButton/hooks';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { usePreviewMutation } from 'store/portfolio/api';
import { getSuperPortfolioHeaders } from 'store/portfolio/utils/getSuperPortfolioHeaders';
import { toggleInfoModal } from 'store/modals/actions';

export const useUpdateBasePortfolio = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleGoBack } = usePortfolioBackButtonHandler();
  const { isSuper } = useAppSelector(selectPortfolioParams);
  const headers = getSuperPortfolioHeaders({ isSuper });
  const userFunds = useAppSelector(selectUserFundsFlattened) || [];

  const [portfolioPreview] = usePreviewMutation();

  const activePortfolioId = useAppSelector(selectActivePortfolioId);
  const isPortfolioLoading = useAppSelector(selectIsPortfolioLoading);

  const handleChangeBasePortfolio = () => {
    if (isSuper) {
      const funds = userFunds?.map((fund) => ({
        id: fund.id,
        allocation: fund.allocation,
        edited: false,
      }));

      portfolioPreview({
        data: {
          portfolio_id: activePortfolioId,
          funds,
        },
        headers,
      })
        .unwrap()
        .then((response) => {
          dispatch(
            toggleInfoModal({
              isInfoModalVisible: true,
              config: {
                title: t(
                  'portfolioCustomization.tabs.basePortfolioPage.reviewAllocationsModal.title',
                ),
                description: t(
                  'portfolioCustomization.tabs.basePortfolioPage.reviewAllocationsModal.description',
                ),
              },
            }),
          );

          dispatch(
            updateBasePortfolio({
              activePortfolioId,
              onSuccess: handleGoBack,
              updatedFunds: response.funds,
              basePortfolioAllocation: response.portfolio.allocation,
            }),
          );
        });

      return;
    }

    dispatch(
      updateBasePortfolio({
        activePortfolioId,
        onSuccess: handleGoBack,
      }),
    );
  };

  return {
    handleChangeBasePortfolio,
    isPortfolioLoading,
  };
};
