import React from 'react';
import PropTypes from 'prop-types';

import { HomeWidgetsColumn, HomeWidgetsWrapper } from './styles';
import { useWidgetsColumns } from './hooks';
import HomeWidgetItem from './HomeWidgetItem';

export default function HomeWidgets({ isNewRewards }) {
  const { leftColumn, rightColumn } = useWidgetsColumns();

  return (
    <HomeWidgetsWrapper>
      <HomeWidgetsColumn>
        {leftColumn?.map((widgetName) => (
          <HomeWidgetItem
            key={widgetName}
            widgetName={widgetName}
            isNewRewards={isNewRewards}
          />
        ))}
      </HomeWidgetsColumn>

      <HomeWidgetsColumn>
        {rightColumn?.map((widgetName) => (
          <HomeWidgetItem
            key={widgetName}
            widgetName={widgetName}
            isNewRewards={isNewRewards}
          />
        ))}
      </HomeWidgetsColumn>
    </HomeWidgetsWrapper>
  );
}

HomeWidgets.propTypes = {
  isNewRewards: PropTypes.bool.isRequired,
};
