import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from 'components/elements/Button';

interface IWrapperProps {
  isSuper?: boolean;
  isWithSwitcher?: boolean;
}
interface ISwitcherItemProps {
  isActive?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  padding: 49px 0 30px;
  border-radius: 6px;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 30px;
  padding-top: 88px;
  height: 204px;
  background: ${({ theme, isSuper }) =>
    isSuper ? theme.gradients.magentaNebula : theme.gradients.lushMeadow};

  ${({ isWithSwitcher }) =>
    isWithSwitcher &&
    `
      height: auto;
      padding-bottom: 0;
    `}
`;

export const InfoButton = styled.div`
  position: absolute;
  top: 22px;
  right: 28px;
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family:  ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['4lg']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing:  ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};

    ${theme.mediaQuery(theme.breakpoints.mobile)} {
      line-height: ${theme.lineHeights.length.xl};
    }
  `}
`;

export const Sublabel = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['6sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length.md};
    opacity: 0.72;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 11px;
    padding-bottom: 20px;

    ${theme.mediaQuery(theme.breakpoints.mobile)} {
      font-size: ${theme.fontSizes['4sm']};
    }
  `}
`;

export const Switcher = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.semibold};
    background-color: ${theme.colors.blackTransparent};
    display: inline-flex;
    border-radius: 18px;
    width: auto;
    padding: 3px;
    margin-bottom: 35px;
    margin-top: 25px;
  `}
`;

export const SwitcherItem = styled.div<ISwitcherItemProps>`
  ${({ theme, isActive }) => `
    background: ${theme.colors.transparent};
    padding: 4px 23px;
    font-weight: inherit;
    color: inherit;

    ${
      isActive &&
      `
        background-color: ${theme.colors.white};
        color: ${theme.colors.mirage};
        border-radius: 15px;
      `
    }
  `}
`;

export const recurringButtonStyles = css`
  width: auto;
  padding: 0 60px;
`;

export const FrequencyChangeButton = styled(Button)`
  margin-top: 30px;
`;
