import { CHART_BACKGROUND } from 'pages/Portfolio/components/ProPortfolio/consts';

export const RECOMMENDED_PORTFOLIO_CHART_SIZE = 290;

export const RECOMMENDED_PORTFOLIO_CHART_OPTIONS = {
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  colors: CHART_BACKGROUND,
};
