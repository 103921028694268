import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { INSURANCE_QUESTIONS, INSURANCE_QUESTIONS_KEY } from './consts';

import getMigrationFlow from 'utils/getMigrationFlow';

export const useFormValidationSchema = (
  isSuperAnnuationInsuranceAccepted: boolean,
) => {
  const { t } = useTranslation();
  const migrationFlow = getMigrationFlow();

  return yup.object({
    ...Object.fromEntries(
      INSURANCE_QUESTIONS[migrationFlow].map((name) =>
        name === INSURANCE_QUESTIONS_KEY.question_3
          ? []
          : [name, yup.bool().oneOf([true], t('fields.validation.required'))],
      ),
    ),
    ...(!isSuperAnnuationInsuranceAccepted && {
      OPTIONAL_INSURANCE_QUESTION: yup.bool().nullable(),
    }),
  });
};
