import { useTranslation } from 'react-i18next';

import { Amount, Row, SubTitle, Value } from '../styles';

import { DividendsBannerSummaryUpcomingValue } from './DividendsBannerSummaryUpcomingValue';

import { useGetDividendsSummaryQuery } from 'store/dividends/dividends';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useFilteredFunds } from 'pages/Dividends/hooks/useFilteredFunds';
import { useDividendsRequestHeaders } from 'pages/Dividends/hooks/useDividendsRequestHeaders';

export const DividendsBannerSummary = () => {
  const { t } = useTranslation();

  const { fundIds } = useFilteredFunds();

  const { headers } = useDividendsRequestHeaders();

  const dividendsSummaryQuery = useGetDividendsSummaryQuery({
    fund_ids: fundIds,
    headers,
  });

  return (
    <Row>
      <Value>
        <SubTitle>{t('dividendsPage.reinvested')}</SubTitle>

        <Amount>
          {formatNumber({
            value: dividendsSummaryQuery.data?.summary.reinvested_amount,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            isMinimumFractionDigitsForIntegerSkipped: true,
          })}
        </Amount>
      </Value>

      <Value>
        <SubTitle>{t('dividendsPage.upcoming')}</SubTitle>

        <DividendsBannerSummaryUpcomingValue
          upcoming_amount={
            dividendsSummaryQuery.data?.summary.upcoming_amount || 0
          }
          upcoming_status={dividendsSummaryQuery.data?.summary.upcoming_status}
        />
      </Value>
    </Row>
  );
};
