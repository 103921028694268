import React from 'react';
import Skeleton from 'react-skeleton-loader';

import {
  RecurringInvestmentContent,
  RecurringInvestmentTitle,
  RecurringInvestmentWrapper,
} from '../components/styles';

import {
  SkeletonButton,
  SkeletonWrapper,
  SkeletonRecurringInvestmentContent,
  SkeletonButtonsWrapper,
  SkeletonRemoveButton,
} from './styles';
import SkeletonRecurringInvestment from './SkeletonRecurringInvestment';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsKidsInvestmentGoalEnabled } from 'store/firebaseConfig/selectors';

function RecurringInvestmentFormSkeleton() {
  const isKidsInvestmentGoalEnabled = useAppSelector(
    selectIsKidsInvestmentGoalEnabled,
  );

  return (
    <RecurringInvestmentWrapper>
      <RecurringInvestmentContent>
        <RecurringInvestmentTitle>
          <Skeleton />
        </RecurringInvestmentTitle>

        <SkeletonWrapper>
          <SkeletonRecurringInvestmentContent>
            <SkeletonRecurringInvestment />

            {isKidsInvestmentGoalEnabled && <SkeletonRecurringInvestment />}
          </SkeletonRecurringInvestmentContent>
        </SkeletonWrapper>
      </RecurringInvestmentContent>

      <SkeletonButtonsWrapper>
        <SkeletonButton>
          <Skeleton height="42px" borderRadius="40px" width="100%" />
        </SkeletonButton>

        <SkeletonRemoveButton>
          <Skeleton height="20px" width="100%" />
        </SkeletonRemoveButton>
      </SkeletonButtonsWrapper>
    </RecurringInvestmentWrapper>
  );
}

export default RecurringInvestmentFormSkeleton;
