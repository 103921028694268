import { GroupedBankList } from './GroupedBankList';

import { useSuccessOrFailureModal } from 'pages/Settings/components/hooks/useSuccessOrFailureModal';
import { SUCCESS_OR_FAILURE_TRANSACTION_PATHS } from 'pages/Settings/components/hooks/const';
import { selectDependentUserAccess } from 'store/user/selectors';
import { PATHS } from 'constants/paths';
import Loader from 'components/elements/Loader/Loader';
import { useGetBanksQuery } from 'store/banks';
import { useAppSelector } from 'store/hooks/useAppSelector';

interface IGroupedBanksRegistrationProps {
  onSelectDebitCard: (id: number) => void;
  onSelectOnlineBanking: (institutionId: string | null) => void;
}

export const GroupedBanksRegistration = ({
  onSelectDebitCard,
  onSelectOnlineBanking,
}: IGroupedBanksRegistrationProps) => {
  const { isFetching } = useGetBanksQuery();

  const { manage_portfolio } = useAppSelector(selectDependentUserAccess);

  useSuccessOrFailureModal({
    successUrl: PATHS.registration.spendingAccount.status.success,
    failureUrl: PATHS.registration.spendingAccount.status.failure,
    translationsPath: SUCCESS_OR_FAILURE_TRANSACTION_PATHS.settingsPage,
    navigatePath: manage_portfolio
      ? PATHS.registration.portfolio.index
      : PATHS.root,
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <GroupedBankList
      onSelectDebitCard={onSelectDebitCard}
      onSelectOnlineBanking={onSelectOnlineBanking}
      isRegistration
    />
  );
};
