import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  CAR,
  HOLIDAY,
  BIRTHDAY,
  WEDDING,
  ANNIVERSARY,
  HOUSE,
  RENT,
  DEPOSIT,
  EDUCATION,
} from '../../../constants/comonConstants';
import RecurringModal from '../../../components/layout/Modals/RecurringModal';
import { recurringButtonStyles } from '../styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

class GoalNameChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newGoalName: '',
      isOpenAddNameModal: false,
    };

    this.createGoalName = this.createGoalName.bind(this);

    this.toggleAddNameModal = this.toggleAddNameModal.bind(this);
  }

  createGoalName(name) {
    this.setState({
      newGoalName: name,
    });
  }

  toggleAddNameModal(condition, newGoalName) {
    const { changeGoalName } = this.props;

    this.setState({
      isOpenAddNameModal: condition,
    });

    if (newGoalName) {
      changeGoalName(newGoalName);
    }
  }

  renderGoalName(goal, index) {
    const { changeGoalName, currentGoalName } = this.props;
    const isCurrentGoal = goal.id === currentGoalName;

    return (
      <div
        className={`info-panel__item recurring-investment__day ${
          isCurrentGoal ? '-active' : ''
        }`}
        key={index}
        onClick={() => changeGoalName(goal.id)}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        {goal.name}

        {isCurrentGoal && <i className="recurring-investment__icon -check" />}
      </div>
    );
  }

  render() {
    const { t, currentGoalName, toggleChangeGoalState } = this.props;
    const { isOpenAddNameModal, newGoalName } = this.state;

    const goalNames = [
      CAR,
      HOLIDAY,
      EDUCATION,
      BIRTHDAY,
      WEDDING,
      ANNIVERSARY,
      HOUSE,
      RENT,
      DEPOSIT,
    ];

    const goalNameIds = [
      CAR.id,
      HOLIDAY.id,
      EDUCATION.id,
      BIRTHDAY.id,
      WEDDING.id,
      ANNIVERSARY.id,
      HOUSE.id,
      RENT.id,
      DEPOSIT.id,
    ];

    if (currentGoalName && goalNameIds.indexOf(currentGoalName) === -1) {
      goalNames.push({ id: currentGoalName, name: currentGoalName });
    }

    const currentGoalNameVisible = goalNames.filter(
      (goal) => goal.id === currentGoalName,
    );

    const buttonGoalName = currentGoalName.length
      ? ` - ${
          currentGoalNameVisible
            ? currentGoalNameVisible[0].name
            : currentGoalName
        }`
      : '';

    return (
      <div>
        <div className="info-panel -with-padding">
          <div className="info-panel__title-sub">
            {t('recurringInvestmentPage.goalNameChange.subTitle')}
          </div>

          {goalNames.map((goal, index) => this.renderGoalName(goal, index))}
        </div>

        <div className="recurring-investment__btn-container">
          <Button
            label={t(
              'recurringInvestmentPage.goalNameChange.button.setGoalName',
              {
                goalName: buttonGoalName,
              },
            )}
            onClick={() => toggleChangeGoalState(false)}
            customStyles={recurringButtonStyles.styles}
          />

          <DeprecatedButton
            title={t(
              'recurringInvestmentPage.goalNameChange.button.addCustomGoalName',
            )}
            buttonClass="button__basic-wtbackground"
            handleClick={() => this.toggleAddNameModal(true)}
          />
        </div>

        {isOpenAddNameModal && (
          <RecurringModal
            isOpen={isOpenAddNameModal}
            handleClick={() => this.toggleAddNameModal(false, newGoalName)}
            onRequestClose={() => this.toggleAddNameModal(false)}
            modalClassName="recurring-investment__modal"
            modalTitle={t(
              'recurringInvestmentPage.goalNameChange.recurringModal.title',
            )}
            modalContent={
              <input
                type="text"
                onChange={(e) => {
                  this.createGoalName(e.target.value);
                }}
                className="recurring-investment__input"
                placeholder={t(
                  'recurringInvestmentPage.goalNameChange.recurringModal.description',
                )}
              />
            }
            modalFooter={
              <DeprecatedButton
                title={t('common.cancel')}
                buttonClass="button__basic-transparent -black"
                handleClick={() => this.toggleAddNameModal(false)}
              />
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(GoalNameChange);

GoalNameChange.propTypes = {
  t: PropTypes.func.isRequired,
  changeGoalName: PropTypes.func.isRequired,
  toggleChangeGoalState: PropTypes.func.isRequired,
  currentGoalName: PropTypes.string.isRequired,
};
