import styled from '@emotion/styled';

import Sprite60FpsIcon from 'assets/images/icons/sprite_60fps.svg';

export const Spinner = styled.div`
  @keyframes play {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -3360px 0;
    }
  }
  background: url(${`${Sprite60FpsIcon}`}) no-repeat;
  animation-duration: 925ms;
  animation-timing-function: steps(56);
  width: 60px;
  animation-iteration-count: infinite;
  height: 62px;
  position: relative;
  animation-name: play;
`;
