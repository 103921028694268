import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { recurringButtonStyles } from '../styles';

import Time from 'components/elements/Time/Time';
import 'styles/pages/recurring-investment.scss';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { FREQUENCIES } from 'constants/comonConstants';
import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  MY_APP,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';
import { DATE_FORMAT_DAY_NAME } from 'config/date';
import { Button } from 'components/elements/Button';

function FrequencySetTable(props) {
  const {
    recurringDepositSettings,
    setChangePlan,
    toggleStopModal,
    showLoader,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <div className="info-panel perfomance__info-panel -with-padding">
        <div className="info-panel__title">
          {t('recurringInvestmentPage.frequencySetTable.title')}
        </div>

        <div className="info-panel__divider" />

        <div className="recurring-investment__amount">
          <NumberFormat
            value={recurringDepositSettings.amount}
            displayType="text"
            thousandSeparator={THOUSAND_SEPARATOR}
            decimalSeparator={DECIMAL_SEPARATOR}
            placeholder={`${CURRENCY_SYMBOL}0`}
            prefix={CURRENCY_SYMBOL}
          />
        </div>

        <div className="info-panel__divider" />

        <div>
          <div className="recurring-investment__label">
            {t('recurringInvestmentPage.frequencySetTable.frequency.label')}
          </div>

          {recurringDepositSettings.frequency_identifier ===
            FREQUENCIES.weekly && (
            <Trans
              i18nKey="recurringInvestmentPage.frequencySetTable.frequencyOn"
              t={t}
              components={{
                frequency: t(
                  `common.frequencies.${recurringDepositSettings.frequency_identifier}`,
                ),
                day: dayjs(recurringDepositSettings.next_investment_at).format(
                  DATE_FORMAT_DAY_NAME,
                ),
              }}
            />
          )}

          {recurringDepositSettings.frequency_identifier !==
            FREQUENCIES.weekly &&
            t(
              `common.frequencies.${recurringDepositSettings.frequency_identifier}`,
            )}
        </div>

        <div className="recurring-investment__item">
          <div className="recurring-investment__label">
            {t(
              'recurringInvestmentPage.frequencySetTable.nextInvestment.label',
            )}
          </div>

          <Time format={MY_APP ? 'dddd, D MMMM YYYY' : 'MMMM Do dddd'}>
            {dayjs(recurringDepositSettings.next_investment_at).tz(
              'Australia/Sydney',
            )}
          </Time>
        </div>
      </div>

      <div className="recurring-investment__btn-container">
        <Button
          isLoading={showLoader}
          label={t(
            'recurringInvestmentPage.frequencySetTable.button.changePlan',
          )}
          onClick={() => setChangePlan(true)}
          customStyles={recurringButtonStyles.styles}
        />

        <DeprecatedButton
          title={
            <Trans
              i18nKey="recurringInvestmentPage.frequencySetTable.button.stopInvestment"
              t={t}
              components={{
                frequency:
                  t(
                    `common.frequencies.${recurringDepositSettings.frequency_identifier}`,
                  ) ||
                  t(
                    `recurringInvestmentPage.frequencySetTable.frequency.${recurringDepositSettings.frequency_identifier}`,
                  ),
              }}
            />
          }
          buttonClass="button__basic-wtbackground"
          handleClick={() => {
            toggleStopModal(true);
          }}
        />
      </div>
    </div>
  );
}

export default FrequencySetTable;

FrequencySetTable.propTypes = {
  recurringDepositSettings: PropTypes.shape({
    amount: PropTypes.number,
    frequency_identifier: PropTypes.string,
    next_investment_at: PropTypes.string,
  }).isRequired,
  setChangePlan: PropTypes.func.isRequired,
  toggleStopModal: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
};
