import dayjs from 'dayjs';

import { CLOSE_MODAL, GET_SUPER_USER_DATA_SUCCESS } from '../commonTypes';
import { PERFOMANCE as idPrefix } from '../../constants/translates';

import {
  SHOW_MODAL,
  GET_SUMMARY_SUCCESS,
  GET_PRESENT_SUCCESS,
  GET_SECURITIES_PRICES_SUCCESS,
  GET_ALLOCATION_PROFILES_SUCCESS,
  GET_SECURITIES_FUNDAMENTALS_SUCCESS,
  GET_MARKET_STATUS_SUCCESS,
  PERFOMANCE_UNMOUNT,
  GET_SECURITIES_LOADING,
} from './types';

import {
  CURRENCY_LOCALE,
  CURRENCY_NAME,
  CURRENCY_SYMBOL,
  MY_APP,
  AU_APP,
  APP_DATE_TIME_ZONE,
} from 'constants/localeConfigs';
import { PERIOD_DAYS_NUMBER } from 'pages/PerfomanceDetails/consts';
import {
  DATE_FORMAT_HOUR_MINUTE_AM_PM_MARKER,
  DATE_FORMAT_WITH_DASHES_SHORT_MONTH,
  DATE_FORMAT_WITH_SHORT_MONTH,
} from 'config/date';
import { formatNumber } from 'utils/formatters/formatNumber';

const initialState = {};

export default function perfomance(state = initialState, action) {
  switch (action.type) {
    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        type: action.type,
        summary: action.summary,
        isDashboard: action.isDashboard,
        isShowLoader: false,
      };
    case GET_PRESENT_SUCCESS:
      return {
        ...state,
        summary: {},
        type: action.type,
        securitiesPrices: {},
        present: action.present,
        isShowLoader: false,
      };
    case PERFOMANCE_UNMOUNT:
      return {
        ...state,
        chartData: [],
        summary: {},
        present: {},
        isShowLoader: true,
      };
    case GET_ALLOCATION_PROFILES_SUCCESS:
      return {
        ...state,
        type: action.type,
        allocationProfiles: action.allocationProfiles,
      };
    case GET_SECURITIES_PRICES_SUCCESS:
      return {
        ...state,
        summary: {},
        present: {},
        type: action.type,
        periodMarketChange: '',
        periodMarketChangePercent: '',
        securitiesPrices: action.securitiesPrices,
        numberOfDays: action.numberOfDays,
        isLoading: false,
      };
    case GET_SECURITIES_FUNDAMENTALS_SUCCESS:
      return {
        ...state,
        type: action.type,
        securitiesFundamentals: action.securitiesFundamentals,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        errorMessage: '',
        isShowErrorModal: false,
      };
    case SHOW_MODAL:
      return {
        ...state,
        errorMessage: '',
        isShowErrorModal: true,
      };
    case GET_SUPER_USER_DATA_SUCCESS:
      return {
        ...state,
        superUser: action.superUser,
      };
    case GET_MARKET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
      };
    case GET_SECURITIES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}

function convertingMessages(messages) {
  const messageDefault = {};

  Object.entries(messages).map((key) => {
    if (key && key[1] && key[1].id && key[1].defaultMessage) {
      messageDefault[key[1].id] = key[1].defaultMessage;
    }

    return key;
  });

  return messageDefault;
}

export function getAccountBalanceChartDataPresent(state) {
  let chartDataPresent = [];
  let formatingAmount = `${CURRENCY_SYMBOL}0`;

  if (state.perfomance.present && state.perfomance.present.graph_data) {
    chartDataPresent = state.perfomance.present.graph_data.map((balance) => {
      formatingAmount = new Intl.NumberFormat(CURRENCY_LOCALE, {
        style: 'currency',
        currency: CURRENCY_NAME,
      }).format(balance.amount);

      return {
        y: Math.floor(balance.amount),
        x: dayjs
          .utc(balance.time)
          .tz(APP_DATE_TIME_ZONE)
          .format(DATE_FORMAT_HOUR_MINUTE_AM_PM_MARKER),
        z: formatingAmount,
      };
    });
  }

  return chartDataPresent;
}

export function getSecuritiesPricesChartData(state) {
  let chartDataPrices = [];
  let formatingAmount = `${CURRENCY_SYMBOL}0`;

  if (
    state.perfomance.securitiesPrices &&
    state.perfomance.securitiesPrices.prices
  ) {
    chartDataPrices = state.perfomance.securitiesPrices.prices.map(
      (balance) => {
        formatingAmount = formatNumber({
          value: balance.amount,
        });

        const isOneDayChart =
          state?.perfomance?.numberOfDays === PERIOD_DAYS_NUMBER.oneDay;

        const dateFormat = isOneDayChart
          ? DATE_FORMAT_HOUR_MINUTE_AM_PM_MARKER
          : DATE_FORMAT_WITH_SHORT_MONTH;

        const x = dayjs
          .utc(balance.date)
          .tz(APP_DATE_TIME_ZONE)
          .format(dateFormat);

        return {
          y: balance.amount,
          x,
          z: formatingAmount,
        };
      },
    );
  }

  return chartDataPrices;
}

export function getDividends(state) {
  const dividends = [];
  let i = 0;

  if (state.perfomance.summary && state.perfomance.summary.summary) {
    let stateMessages = state.translates.messages[state.translates.locale];

    if (AU_APP || MY_APP) {
      stateMessages = convertingMessages(state.translates.messages.en);
    }

    state.perfomance.summary.summary.map((balance) => {
      let divident = '';

      if (balance.date_dividends !== 0) {
        divident = new Intl.NumberFormat(CURRENCY_LOCALE, {
          style: 'currency',
          currency: CURRENCY_NAME,
        }).format(balance.date_dividends);

        divident = `${dayjs
          .utc(balance.date)
          .tz(APP_DATE_TIME_ZONE)
          .format(DATE_FORMAT_WITH_DASHES_SHORT_MONTH)} -
        ${stateMessages[`${idPrefix}.devidend`]}: ${divident}`;

        dividends.push({
          lablel: divident,
          seriesIndex: 0,
          dataPointIndex: i,
          fillColor: '#fff',
          strokeColor: '#fff',
          size: 8,
        });
      }

      i += 1;

      return divident;
    });
  }

  return dividends;
}

export function getPeriodMarketChangePercent(state) {
  const periodMarketChangePercent = AU_APP
    ? state?.perfomance?.summary?.period_market_change_percent
    : state?.perfomance?.summary?.period_market_change_percent_v2;

  if (periodMarketChangePercent) {
    return `${periodMarketChangePercent}`;
  }

  return `${CURRENCY_SYMBOL}0`;
}

export function getPreviousCloseAmount(state) {
  if (
    state.perfomance.present &&
    state.perfomance.present.previous_close_amount
  ) {
    return state.perfomance.present.previous_close_amount;
  }

  return '';
}

export function getPeriodMarketChangeEtf(state) {
  return formatNumber({
    value: state?.perfomance?.securitiesPrices?.gain?.amount || 0,
    minimumFractionDigits: 2,
    isWithSign: true,
  });
}

export function getPeriodMarketChangePercentEtf(state) {
  if (
    state.perfomance.securitiesPrices &&
    state.perfomance.securitiesPrices.gain
  ) {
    return `${state.perfomance.securitiesPrices.gain.percentage}`;
  }

  return `${CURRENCY_SYMBOL}0`;
}
