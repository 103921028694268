import { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import SuperRolloversView from './SuperRolloversView';

import * as userActions from 'store/user/actions';
import * as superActions from 'store/superAnnuation/actions';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class SuperRollovers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowInto: true,
      isSearch: false,
      isShowFundModal: false,
      fund: {},
      memberNumber: '',
      localFunds: [],
      isShowAgreement: false,
      currentRolloverFund: {},
      activeFundId: null,
      isShowConfirmationModal: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.deleteFund = this.deleteFund.bind(this);

    this.rolloverFund = this.rolloverFund.bind(this);

    this.handleShowAgreement = this.handleShowAgreement.bind(this);

    this.showConfirmationPopUp = this.showConfirmationPopUp.bind(this);

    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    const { actions, actionsSuper } = this.props;

    actions.getSuperUserData();

    actions.getSuperUserAccounts();

    actionsSuper.getSuperFunds();

    const localFunds = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.localFunds),
    );

    this.setState({
      localFunds,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { idRolloverFund } = this.state;

    if (idRolloverFund && prevState.idRolloverFund !== idRolloverFund) {
      this.deleteFund(idRolloverFund);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.idRolloverFund !== prevState.idRolloverFund) {
      return { idRolloverFund: nextProps.idRolloverFund };
    }

    return null;
  }

  handleCloseModal() {
    const { actionsSuper } = this.props;

    this.setState({
      isShowFundModal: false,
      isShowAgreement: false,
    });

    actionsSuper.closeModal();
  }

  handleShowAgreement(fund) {
    this.setState({
      isShowAgreement: true,
      currentRolloverFund: fund,
    });
  }

  toggleShow = (show) => {
    if (show) {
      this.setState({
        isShowInto: true,
      });
    } else {
      this.setState({
        isShowInto: false,
      });
    }
  };

  searchBanks = (e) => {
    const { actionsSuper } = this.props;

    actionsSuper.getSuperFunds(e.target.value);
  };

  clickOnFund = (fund) => {
    this.setState({
      isShowFundModal: true,
      fund,
    });
  };

  showSearch = () => {
    this.setState({
      isSearch: true,
    });
  };

  changeMemberNumber = (e) => {
    this.setState({
      memberNumber: e.target.value,
    });
  };

  addRollover = () => {
    const { memberNumber, fund } = this.state;
    const fundSave = fund;

    fundSave.member_number = memberNumber;

    let localFunds = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.localFunds),
    );

    if (!localFunds) {
      localFunds = [];
    }

    let isAddToFunds = false;

    localFunds = localFunds.map((localFund) => {
      if (localFund.id === fundSave.id) {
        isAddToFunds = true;

        return fundSave;
      }

      return localFund;
    });

    if (!isAddToFunds) {
      localFunds.push(fundSave);
    }

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.localFunds,
      JSON.stringify(localFunds),
    );

    this.setState({
      isSearch: false,
      isShowFundModal: false,
      localFunds,
    });
  };

  showConfirmationPopUp(fundId = null) {
    this.setState({
      activeFundId: fundId,
      isShowConfirmationModal: true,
    });
  }

  rolloverFund() {
    this.setState({
      isShowAgreement: false,
    });

    const { currentRolloverFund } = this.state;
    const { actionsSuper, actions } = this.props;

    actionsSuper.rolloverFund(currentRolloverFund);

    actions.getSuperUserAccounts();
  }

  deleteFund() {
    const { activeFundId } = this.state;

    let localFunds = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.localFunds),
    );

    localFunds = localFunds.filter((fund) => {
      if (fund.id === activeFundId) {
        return false;
      }

      return fund;
    });

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.localFunds,
      JSON.stringify(localFunds),
    );

    this.setState({
      localFunds,
      isShowConfirmationModal: false,
    });
  }

  cancel() {
    this.setState({
      activeFundId: null,
      isShowConfirmationModal: false,
    });
  }

  showDetails(e) {
    if (e.target.parentElement.className === 'raiz-kids__card subaccount') {
      e.target.parentElement.className = 'raiz-kids__card subaccount active';
    } else {
      e.target.parentElement.className = 'raiz-kids__card subaccount';
    }
  }

  render() {
    const {
      isShowInto,
      isSearch,
      isShowFundModal,
      fund,
      localFunds,
      isShowAgreement,
      isShowConfirmationModal,
    } = this.state;

    const { userAccounts, superFunds, errorMessage } = this.props;

    return (
      <SuperRolloversView
        isShowInto={isShowInto}
        toggleShow={this.toggleShow}
        userAccounts={userAccounts}
        superFunds={superFunds}
        searchBanks={this.searchBanks}
        isSearch={isSearch}
        showSearch={this.showSearch}
        clickOnFund={this.clickOnFund}
        isShowFundModal={isShowFundModal}
        handleCloseModal={this.handleCloseModal}
        fund={fund}
        addRollover={this.addRollover}
        localFunds={localFunds}
        rolloverFund={this.rolloverFund}
        deleteFund={this.deleteFund}
        errorMessage={errorMessage}
        changeMemberNumber={this.changeMemberNumber}
        isShowAgreement={isShowAgreement}
        handleShowAgreement={this.handleShowAgreement}
        showDetails={this.showDetails}
        showConfirmationPopUp={this.showConfirmationPopUp}
        isShowConfirmationModal={isShowConfirmationModal}
        cancel={this.cancel}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  userAccounts: state.user.userAccounts,
  superFunds: state.superAnnuation.superFunds,
  errorMessage: state.superAnnuation.errorMessage,
  idRolloverFund: state.superAnnuation.idRolloverFund,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  actionsSuper: bindActionCreators(superActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperRollovers);

SuperRollovers.defaultProps = {
  state: {},
  userAccounts: {},
  superFunds: {},
  actionsSuper: {},
  errorMessage: '',
  idRolloverFund: '',
};

SuperRollovers.propTypes = {
  state: PropTypes.shape({}),
  actions: PropTypes.shape({
    getSuperUserData: PropTypes.func,
    getSuperUserAccounts: PropTypes.func,
  }).isRequired,
  userAccounts: PropTypes.shape({}),
  superFunds: PropTypes.shape({}),
  actionsSuper: PropTypes.shape({
    getSuperFunds: PropTypes.func,
    closeModal: PropTypes.func,
    rolloverFund: PropTypes.func,
  }),
  errorMessage: PropTypes.string,
  idRolloverFund: PropTypes.string,
};
