import styled from '@emotion/styled';

export const StatisticsItemsWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  padding-bottom: 18px;
`;

export const StatisticsItemWrapper = styled.div`
  background: ${({ theme }) => theme.colors.athensGray};
  :nth-of-type(2n) {
    background: ${({ theme }) => theme.colors.white};
  }
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  padding: 14px;
  display: flex;
`;

export const StatisticsItemLabel = styled.div`
  text-align: left;
  float: left;
  flex: 1;
  ::after {
    content: ':';
  }
`;
