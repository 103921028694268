import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../../store/user/actions';
import * as dependencyUsers from '../../../store/dependentUser/actions';

import KidsPassword from './KidsPassword';

import { getLeftPartIconClassName } from 'pages/RaizKidsRegistration/utils';

class KidsSign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowPassword: true,
      showLoader: false,
      isShowConfirmPassword: true,
    };

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.toggleShowConfirmPassword = this.toggleShowConfirmPassword.bind(this);

    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
  }

  componentDidMount() {
    const currentURL = new URLSearchParams(window.location.search);
    const tokenFromURL = currentURL.get('token');
    const { actionsDependency } = this.props;

    this.setState({
      showLoader: true,
    });

    actionsDependency.getSecurity(tokenFromURL).then(() => {
      this.setState({
        showLoader: false,
      });
    });
  }

  handleSubmitPassword(e, values, form, hasValidationErrors) {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      const question1 = values?.question1?.[0]?.value;

      if (!hasValidationErrors) {
        const currentURL = new URLSearchParams(window.location.search);
        const tokenFromURL = currentURL.get('token');
        const parentFromURL = currentURL.get('parent_user_id');
        const { actions } = this.props;

        actions
          .registrationDependentUser({
            password: values.password,
            parent_user_id: parentFromURL,
            token: tokenFromURL,
          })
          .then(() => {
            actions.getUserData().then(() => {
              actions.updateSequrity({
                answer1: values.answer1,
                question1,
                registration_complete: true,
              });
            });
          });
      }
    }
  }

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  toggleShowConfirmPassword() {
    const { isShowConfirmPassword } = this.state;

    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  }

  render() {
    const { registrationSecurityQuestion, t } = this.props;
    const { isShowPassword, isShowConfirmPassword, showLoader } = this.state;
    const stepName = '';
    let question = '';
    let stepClass = 'step-account';
    let contentFillTitle = '';

    switch (stepName) {
      case 'info':
        stepClass = 'step-account form-info';

        contentFillTitle = 'set pass';

        question = <div>set password</div>;

        break;
      default:
        stepClass = 'step-account form-info';

        contentFillTitle = t('registrationPage.kidsSign.title');

        question = (
          <KidsPassword
            toggleShowConfirmPassword={this.toggleShowConfirmPassword}
            isShowPassword={isShowPassword}
            toggleShowPassword={this.toggleShowPassword}
            isShowConfirmPassword={isShowConfirmPassword}
            handleSubmit={this.handleSubmitPassword}
            showLoader={showLoader}
            registrationSecurityQuestion={registrationSecurityQuestion}
          />
        );
    }

    return (
      <div className="registration-page -kids">
        <div className={stepClass}>
          <div className="left-part">
            <div className="part-content">
              <div className="logo" />

              <div className="title" />

              <div className={getLeftPartIconClassName()} />

              <div className="bottom-text">
                {t('registrationPage.kidsSign.bottomText')}
              </div>
            </div>
          </div>

          <div className="right-part -align-top">
            <div className="bottom-part">
              <div className="content-fill">
                <h1>{contentFillTitle}</h1>
              </div>

              {question}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

KidsSign.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  actionsDependency: PropTypes.shape({
    getSecurity: PropTypes.func,
  }).isRequired,
  registrationSecurityQuestion: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
};

KidsSign.defaultProps = {
  registrationSecurityQuestion: [{}],
};

const mapStateToProps = (state) => ({
  user: state.user,
  errorMessage: state.user.errorMessage,
  registrationSecurityQuestion:
    state.dependencyUsers.registrationSecurityQuestion,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  actionsDependency: bindActionCreators(dependencyUsers, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(KidsSign));
