import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const AddButton = styled(Button)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  width: 280px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.niagaraHover};
  }
`;
