import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsLinkCardLoading } from 'store/fundingAccount/selectors';
import { addPaymentCard } from 'store/fundingAccount/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface ILinkBankCardFlowNewProps {
  bankId: string;
}

interface IIsSpendingAccountProps {
  isSpendingAccount: string;
}

export const useLinkCard = ({ isSpendingAccount }: IIsSpendingAccountProps) => {
  const dispatch = useAppDispatch();
  const isLinkCardLoading = useAppSelector(selectIsLinkCardLoading);

  const redirectData = {
    failed_url: `${window.location.href}-failed`,
    success_url: `${window.location.href}-success`,
  };

  const linkBankCardFlowNew = (bankId: ILinkBankCardFlowNewProps) => {
    const paymentCardData = {
      spending: !!isSpendingAccount,
      funding: !isSpendingAccount,
      bank_id: bankId,
    };

    dispatch(addPaymentCard(paymentCardData, redirectData));
  };

  return { linkBankCardFlowNew, isLinkCardLoading };
};
