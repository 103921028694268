export const screenWidths = {
  ulsmall: 320,
  xsmall: 480,
  small: 768,
  tablet: 992,
  large: 1026,
  wide: 1200,
  extrawide: 1400,
  widest: 1920,
} as const;

export const breakpoints = {
  extraSmall: 'extraSmall',
  mobile: 'mobile',
  tablet: 'tablet',
  tabletOnly: 'tabletOnly',
  landscape: 'landscape',
  wide: 'wide',
} as const;

type IBreakpointsKeys = keyof typeof breakpoints;

type IQuery = (typeof breakpoints)[IBreakpointsKeys];

export const mediaQuery = (query: IQuery) => {
  const media = {
    [breakpoints.extraSmall]: `@media only screen and (max-width: ${
      screenWidths.xsmall - 1
    }px)`,
    [breakpoints.mobile]: `@media only screen and (max-width: ${
      screenWidths.small - 1
    }px)`,
    [breakpoints.tablet]: `@media only screen and (max-width: ${
      screenWidths.large - 1
    }px)`,
    [breakpoints.tabletOnly]: `@media only screen and (min-width: ${
      screenWidths.small - 1
    }px) and (max-width: ${screenWidths.tablet - 1}px)`,
    [breakpoints.landscape]: `@media only screen and (min-width: ${screenWidths.small}px) and (max-width: ${screenWidths.large}px) and (orientation: landscape)`,
    [breakpoints.wide]: `@media only screen and (max-width: ${
      screenWidths.wide - 1
    }px)`,
  };

  return media[query];
};
