import { useTranslation } from 'react-i18next';

import { useSettingsTabSwitcher } from '../hooks/useSettingsTabSwitcher';
import { PROFILE_TABS } from '../hooks/const';
import { useProfilePage } from '../hooks/useProfilePage';

import { ProfileTabButton, ProfileTabSwitcherWrapper } from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUser } from 'store/user/selectors';
import { checkIsDependentUser } from 'utils/user';

export default function ProfileTabSwitcher() {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useSettingsTabSwitcher();
  const user = useAppSelector(selectUser);
  const { isUpdateProfilePage } = useProfilePage();

  if (checkIsDependentUser(user) || isUpdateProfilePage) {
    return null;
  }

  return (
    <ProfileTabSwitcherWrapper>
      <ProfileTabButton
        label={t('settingsPage.profilePage.tabSwitcher.personal')}
        isActive={activeTab === PROFILE_TABS.personal}
        onClick={() => setActiveTab(PROFILE_TABS.personal)}
        variant="initial"
      />

      <ProfileTabButton
        label={t('settingsPage.profilePage.tabSwitcher.financial')}
        isActive={activeTab === PROFILE_TABS.financial}
        onClick={() => setActiveTab(PROFILE_TABS.financial)}
        variant="initial"
      />
    </ProfileTabSwitcherWrapper>
  );
}
