import * as yup from 'yup';

import { ISourceFieldOptions } from 'store/identityVerification/types';

export const emptyOption: ISourceFieldOptions = {
  value: '',
  label: '-',
};

export const MEDICARE_EXPIRATION_YEARS = 20;
export const MIN_VALID_AGE = 18;

export const DOCUMENT_TYPES = {
  driversLicence: 'driverslicence',
  medicare: 'medicaredvs',
  visa: 'visadvs',
  passport: 'passportdvs',
} as const;

export const VERIFICATION_TARGETS = {
  user: 'user',
  fundingSourceChangeRequest: 'funding_source_change_request',
};

export const FORM_FIELDS = {
  text: {
    type: 'text',
    defaultValue: '',
    yupSchema: yup.string(),
  },
  select: {
    type: 'select',
    defaultValue: [emptyOption],
    yupSchema: yup.array(
      yup.object({
        value: yup.string().required(),
      }),
    ),
  },
  date: {
    type: 'date',
    defaultValue: undefined,
    yupSchema: yup.date(),
  },
  disclaimer: {
    type: 'text',
    defaultValue: '',
    yupSchema: yup.string(),
  },
  checkbox: {
    type: 'checkbox',
    defaultValue: false,
    yupSchema: yup.boolean(),
  },
} as const;

export const SOURCE_TYPE_FIELD_NAME = 'source_type';

export const CHECKBOX_VALUE = { off: 'off', on: 'on' };

export const VERIFICATION_STATUS = {
  pending: 'pending',
  failed: 'failed',
  success: 'success',
};

export const SKIP_BUTTON_TIMEOUT = 6000;

export const VERIFICATION_STATUS_REQUEST_TIMEOUT = 3000;
