import { API_TAGS } from '../consts';
import formatUserSettings from '../user/utils/formatUserSettings';

import { api } from 'store/api';
import {
  IRoundUpsSettings,
  IRoundUpsSettingsResponse,
} from 'components/layout/Modals/RoundUpsModal/components/types';

export const ROUND_UPS_BASE_URL = '/v1/roundup_settings';

export const roundUpsSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    roundUpsSettings: build.query<IRoundUpsSettings, void>({
      query: () => ({
        url: ROUND_UPS_BASE_URL,
      }),
      transformResponse: (response: IRoundUpsSettingsResponse) =>
        formatUserSettings(response),
      providesTags: [API_TAGS.roundUpsSettings],
    }),
    updateRoundUpsSettings: build.mutation<void, IRoundUpsSettings>({
      query: (data) => ({
        url: ROUND_UPS_BASE_URL,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [API_TAGS.roundUpsSettings],
    }),
  }),
  overrideExisting: true,
});
export const { useRoundUpsSettingsQuery, useUpdateRoundUpsSettingsMutation } =
  roundUpsSettingsApi;

export const {
  endpoints: { roundUpsSettings },
} = roundUpsSettingsApi;
