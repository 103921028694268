import { useParams, useSearchParams } from 'react-router-dom';

// TODO: Moving reusable consts connected with request logic - https://acornsau.atlassian.net/browse/RAIZ-7688
import { SEARCH_QUERY_PARAM } from '../subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundsList/consts';
import { DEFAULT_FUNDS_TAGS_SORTING_ORDER } from '../subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundsList/Sorting/consts';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectFundSorting,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import createTagsParams from 'store/portfolio/utils/createTagsParams';
import { FUNDS_TO_FETCH } from 'store/portfolio/consts';

export const useFundsParams = () => {
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get(SEARCH_QUERY_PARAM) || '';

  const { fundTag = '' } = useParams();

  const sorting = useAppSelector(selectFundSorting);

  const { childId } = useAppSelector(selectPortfolioParams);
  const tags = createTagsParams({ activeFundTag: fundTag, searchParams });

  return {
    limit: FUNDS_TO_FETCH,
    offset: 1,
    sorting: sorting || DEFAULT_FUNDS_TAGS_SORTING_ORDER,
    tags,
    ...(childId && { dependent_user_id: childId }),
    ...(searchValue && { name: searchValue }),
  };
};
