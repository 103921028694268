import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import { RaizTheme } from 'theme';

interface IWrapperProps {
  isCustomWrapperWidth?: boolean;
}

interface IThemeProps {
  theme: RaizTheme;
}
interface IDashboardProps {
  isFixedToBottom?: boolean;
  isSuper?: boolean;
}

const customChartStyles = ({ theme }: IThemeProps) => css`
  svg {
    margin-left: -5%;
  }

  circle.apexcharts-marker[r*='8'] {
    display: block;
  }

  circle.apexcharts-marker[r*='7'],
  circle.apexcharts-marker + text {
    display: block;
  }

  circle.apexcharts-marker + text:focus {
    fill: ${theme.colors.red};
  }

  circle.apexcharts-marker + text {
    fill: ${theme.colors.royalPurple};
    font-weight: ${theme.fontWeights.semibold};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['3sm']};
  }

  circle.apexcharts-marker[r*='8'] {
    fill: ${theme.colors.white};
  }

  .apexcharts-xcrosshairs {
    display: none;
  }
`;

// styles needed for <PerfomanceDetails> component
const customDashboardStyles = ({ theme }: IThemeProps) => css`
  padding-left: 0 !important;
  padding-right: 0 !important;

  .data-chart {
    display: none;
  }

  .perfomance-comment {
    display: none;
  }

  .change-value {
    margin-left: 20px;
  }

  .bottom-background {
    width: 100%;
    background: ${theme.colors.deepLavender};
    padding-bottom: 6px;
    padding-top: 25px;
    margin-top: -12px;
    min-height: 80px;

    .button__basic-fill {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }
  }

  .bottom-fix {
    margin-top: -21px;
  }

  .perfomance {
    width: 100%;
    margin: 0;
    padding: 0;
    background: none;

    .apexcharts-series {
      .apexcharts-area:first-of-type {
        fill: ${theme.colors.deepLavender};
      }
    }

    #perfomance-chart {
      width: 100%;
      margin-bottom: -8px;
      position: relative;
    }
  }

  .invest-details {
    display: none;
  }

  .apexcharts-tooltip {
    height: 27px !important;
  }

  .page-content__banner-row {
    overflow: hidden;
  }

  .no-data {
    margin-bottom: -8em;
  }

  .-loading + div {
    background: ${theme.colors.transparent};
  }
`;

const customSuperDashboardStyles = css`
  #perfomance-chart {
    margin-bottom: -30px !important;
  }
`;

export const Wrapper = styled.div<IWrapperProps>`
  animation-name: fadein;
  animation-duration: 925ms;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  overflow: hidden;
  position: relative;
  padding: 15px 20px;
  padding-bottom: 0;
  width: ${({ isCustomWrapperWidth }) =>
    isCustomWrapperWidth && 'calc(50% - 10px)'};
  ${customChartStyles}

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.tablet)} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length.md};
    margin-bottom: 15px;
    padding: 0;
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => `
    height: 1px;
    background-color: ${theme.colors.mercury};
    margin-bottom: 19px;
    margin-left: -20px;
    width: calc(100% + 40px);
  `}
`;

export const Dashboard = styled.div<IDashboardProps>`
  height: calc(100% - 20px);
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 0 0 6px 6px;
  padding: 30px 24px 16px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: ${({ theme }) => theme.gradients.purpleBlue};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 30px 14px 16px;
  }

  ${({ isSuper }) => isSuper && customSuperDashboardStyles}

  ${customDashboardStyles}
`;

export const BottomBackground = styled.div<IDashboardProps>`
  width: 100%;
  background: ${({ theme }) => theme.colors.deepLavender};
  padding-bottom: 6px;
  padding-top: 25px;
  margin-top: -12px;
  min-height: 80px;
  margin-top: ${({ isFixedToBottom }) => isFixedToBottom && '21px'};

  ${({ isSuper }) =>
    isSuper &&
    `
      padding-top: 53px;
      margin-top: -44px;
    `}
`;

export const WidgetButton = styled(Button)`
  text-align: center;
  margin-top: auto;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
`;
