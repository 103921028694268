import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

export default function RewardDetailsDescription({ description }) {
  const { t } = useTranslation();

  return (
    <div className="reward-internal__details">
      <div className="info-panel -wide">
        <div className="info-panel__block">
          <Accordion allowZeroExpanded>
            <AccordionItem key="aboutTheBrand">
              <AccordionItemHeading>
                <AccordionItemButton className="my-finance__modal-category">
                  <div className="info-panel__block-label">
                    {t('rewardsPage.rewardDetails.about')}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <div className="info-panel__divider" />

                <div className="info-panel__block-content">{description}</div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

RewardDetailsDescription.propTypes = {
  description: PropTypes.string.isRequired,
};
