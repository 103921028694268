import { useTranslation } from 'react-i18next';

import SendEmployerChoice from './components/SendEmployerChoice';
import {
  InfoPanel,
  SuperAccountInfoWrapper,
  Header,
} from './components/styles';
import { SuperInfoContent } from './components/SuperInfoContent';

import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';

export const SuperAccountInfo = () => {
  const { t } = useTranslation();

  useSuperAccountErrorModal();

  return (
    <SuperAccountInfoWrapper>
      <InfoPanel>
        <Header>{t('superPage.superInfo.title')}</Header>

        <SuperInfoContent />
      </InfoPanel>

      <SendEmployerChoice />
    </SuperAccountInfoWrapper>
  );
};
