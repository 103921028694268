import styled from '@emotion/styled';

import backgroundLines from 'assets/images/background-lines.png';
import {
  PortfolioPlanSwitchWrapper,
  SwitchButtonWrapper,
} from 'pages/Portfolio/components/styles';
import { PortfolioTitle } from 'pages/Portfolio/styles';

interface IPortfolioCustomizationHeaderWrapperProps {
  portfolioColor: string;
  isWithoutBottomRadius?: boolean;
}

export const PortfolioCustomizationHeaderWrapper = styled.div<IPortfolioCustomizationHeaderWrapperProps>`
  background: ${({ portfolioColor }) => portfolioColor};
  width: 100%;
  border-radius: ${({ isWithoutBottomRadius }) =>
    isWithoutBottomRadius ? '10px 10px 0 0' : '10px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding-bottom: 32px;
  }
`;

export const PortfolioCustomizationHeaderBackgroundImage = styled.div`
  content: '';
  position: absolute;
  width: 648px;
  height: 900px;
  background: url(${backgroundLines}) no-repeat center top;
  transform: rotate(-10deg);
  left: 40%;
  margin-top: -30px;
  opacity: ${({ theme }) => theme.opacity.moderatelyTransparent};
  background-size: contain;
  pointer-events: none;
`;

export const PortfolioCustomizationTitle = styled(PortfolioTitle)`
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  width: 100%;
  padding-top: 32px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.extraSmall)} {
    text-align: right;
    padding-right: 32px;
  }
`;

export const CustomizationTabButtonWrapper = styled(SwitchButtonWrapper)`
  width: 50%;
`;

export const CustomizationTabsWrapper = styled(PortfolioPlanSwitchWrapper)`
  margin: 0 0 24px;
  display: flex;
  max-width: 340px;
  width: 100%;
`;

export const PortfolioCustomizationTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 32px 0;
  justify-content: center;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    justify-content: flex-end;
  }
`;
