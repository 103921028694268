import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function fundsRender(fund, handleClick) {
  const linkTo = handleClick ? '#' : `/rewards/${fund.id}`;

  return (
    <Link to={linkTo} onClick={() => handleClick(fund)} key={fund.id}>
      <div className="grid__container-row">
        <i className="fund-icon" />

        {fund.name}
      </div>
    </Link>
  );
}

export default function FundsSearch({ funds, handleClick }) {
  return (
    <div id="bankSearch" className="settings__spending-account">
      <div className="grid__row-container">
        {funds.map((fund) => fundsRender(fund, handleClick))}
      </div>
    </div>
  );
}

FundsSearch.propTypes = {
  funds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClick: PropTypes.func.isRequired,
};
