import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import { PERIOD_DAYS_NUMBER } from '../consts';

import PerfomanceSwitcherItem from './PerfomanceSwitcherItem';

export default function PerfomanceSwitcher({
  numberOfDays,
  toggleShow,
  isEtf,
}) {
  const periodDaysNumber = Object.entries(PERIOD_DAYS_NUMBER).filter(
    ([_, daysNumber]) =>
      (isEtf && daysNumber !== PERIOD_DAYS_NUMBER.threeMonths) || !isEtf,
  );

  return (
    <div className="home__switcher">
      {periodDaysNumber.map(([numberOfDayName, numberOfDay]) => (
        <PerfomanceSwitcherItem
          key={numberOfDay}
          numberOfDay={numberOfDay}
          numberOfDayName={numberOfDayName}
          isActive={
            numberOfDays === numberOfDay || (!numberOfDays && !numberOfDay)
          }
          toggleShow={toggleShow}
          isEtf={isEtf}
        />
      ))}
    </div>
  );
}

PerfomanceSwitcher.propTypes = {
  numberOfDays: PropTypes.number.isRequired,
  toggleShow: PropTypes.func.isRequired,
  isEtf: PropTypes.bool.isRequired,
};
