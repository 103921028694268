import { useTranslation } from 'react-i18next';

import { REQUIRED } from 'constants/errors';
import { EMAIL_REGEX } from 'constants/validation';

export const useConvertToFullModalValidation = () => {
  const { t } = useTranslation();

  const validate = ({ values, childEmail }) => {
    const errors = {};

    if (!childEmail && !values?.email) {
      errors.email = REQUIRED;
    }

    if (!childEmail && values?.email && !EMAIL_REGEX.test(values?.email)) {
      errors.email = t('fields.validation.invalid');
    }

    return errors;
  };

  return { validate };
};
