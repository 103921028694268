import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Date, InfoItems, InfoWrapper } from './styles';
import { InfoItem } from './InfoItem';
import { PerfomanceInfoContent } from './PerfomanceInfoContent';

import { DATE_FORMAT } from 'config/date';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectSummaryByIndex } from 'store/perfomance/selectors';
import { MY_APP } from 'constants/localeConfigs';

export interface IPerfomanceInfoProps {
  dataPointIndex: number;
}

export const PerfomanceInfo = ({ dataPointIndex }: IPerfomanceInfoProps) => {
  const { t } = useTranslation();

  const { date, invested_by_you, market_return, rewards, balance, dividends } =
    useAppSelector(selectSummaryByIndex(dataPointIndex));

  if (MY_APP) {
    return <PerfomanceInfoContent dataPointIndex={dataPointIndex} />;
  }

  return (
    <InfoWrapper>
      <InfoItems>
        <Date>{dayjs(date).format(DATE_FORMAT)}</Date>

        <InfoItem
          label={t('performanceDetailsPage.investedYou')}
          value={invested_by_you}
        />

        <InfoItem
          label={t('performanceDetailsPage.marketReturn')}
          value={market_return}
        />
      </InfoItems>

      <InfoItems>
        <InfoItem
          label={t('performanceDetailsPage.dividends')}
          value={dividends}
        />

        <InfoItem label={t('performanceDetailsPage.rewards')} value={rewards} />

        <InfoItem label={t('performanceDetailsPage.balance')} value={balance} />
      </InfoItems>
    </InfoWrapper>
  );
};
