import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ACCOUNT_QUESTIONS } from './consts';

import getMigrationFlow from 'utils/getMigrationFlow';

export const useFormValidationSchema = () => {
  const { t } = useTranslation();
  const migrationFlow = getMigrationFlow();

  return yup.object(
    Object.fromEntries(
      ACCOUNT_QUESTIONS[migrationFlow].map((name) => [
        name,
        yup.bool().oneOf([true], t('fields.validation.required')),
      ]),
    ),
  );
};
