import {
  ConfigureStoreOptions,
  configureStore,
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';

import rootReducer from './reducers';
import { api } from './api';
import { displayErrorModal } from './modals/actions';

const apiErrorHandler: Middleware =
  (rtkQueryApi: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const errorMessage = action?.payload?.data?.error;

      if (errorMessage) {
        rtkQueryApi.dispatch(displayErrorModal({ errorMessage }));
      }
    }

    return next(action);
  };

const createStore = (options?: ConfigureStoreOptions['preloadedState']) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([api.middleware, apiErrorHandler]),
    devTools: process.env.NODE_ENV !== 'production',
    ...options,
  });

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;

export type GetState = () => RootState;

export default createStore;
