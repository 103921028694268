import React from 'react';
import { useTranslation } from 'react-i18next';

import { PERIOD_DAYS_COUNT } from '../consts';

import { ChartButton, NumberOfDaysSwitcherItemWrapper } from './styles';

interface INumberOfDaysSwitcherItemProps {
  periodValue: number;
  setNumberOfDays: (numberOfDays: number) => void;
  isActive: boolean;
  periodKey: keyof typeof PERIOD_DAYS_COUNT;
}

export default function NumberOfDaysSwitcherItem({
  periodValue,
  setNumberOfDays,
  isActive,
  periodKey,
}: INumberOfDaysSwitcherItemProps) {
  const { t } = useTranslation();

  return (
    <NumberOfDaysSwitcherItemWrapper isActive={isActive}>
      <ChartButton
        onClick={() => setNumberOfDays(periodValue)}
        isActive={isActive}
        // TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548
        className="-exclude"
      >
        {t(`perfomanceChart.numberOfDaysSwitcher.${periodKey}`)}
      </ChartButton>
    </NumberOfDaysSwitcherItemWrapper>
  );
}
