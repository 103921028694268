import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

import { ACCOUNT_ID, BANK_GRID_MODE, BANK_GRID_MODE_KEYS } from '../../consts';

import { Logo, LogoWrapper, Card, Title, Divider } from './styles';

import { IBank, banksApi } from 'store/banks';
import { useUpdateCardsSetBankMutation } from 'store/cards';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface IBankGridItemProps {
  bank: IBank;
  onClick: (bank: IBank) => void;
}

export const BankGridItem = ({ bank, onClick }: IBankGridItemProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [setCardBank] = useUpdateCardsSetBankMutation();
  const dispatch = useAppDispatch();

  const handleSelectBank = useCallback(() => {
    const bankGridMode = searchParams.get(BANK_GRID_MODE);
    const accountId = searchParams.get(ACCOUNT_ID);

    if (bankGridMode === BANK_GRID_MODE_KEYS.selection) {
      if (accountId) {
        setCardBank({ accountId, bankId: bank.id })
          .unwrap()
          .then(() => {
            dispatch(banksApi.util.resetApiState());
          });
      }
    } else {
      onClick(bank);
    }

    if (searchParams.has(BANK_GRID_MODE)) {
      searchParams.delete(BANK_GRID_MODE);
    }

    if (searchParams.has(ACCOUNT_ID)) {
      searchParams.delete(ACCOUNT_ID);
    }

    setSearchParams(searchParams);
  }, [bank, dispatch, onClick, searchParams, setCardBank, setSearchParams]);

  return (
    <Card onClick={handleSelectBank}>
      <LogoWrapper>
        <Logo source={bank.icon_url} />
      </LogoWrapper>

      <Divider />

      <Title>{bank.bank_name}</Title>
    </Card>
  );
};
