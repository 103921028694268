/* eslint-disable react/jsx-newline */
import dayjs from 'dayjs';

import { StatementMonth, StatementDateRange } from './styles';

import { TRADE_CONFIRMATION } from 'constants/comonConstants';
import { KIDS_TRADE_CONFIRMATION } from 'constants/kidsConstants';
import { useStatementsPath } from 'pages/Statements/hooks/useStatementsPath';
import Time from 'components/elements/Time/Time';
import {
  DATE_FORMAT_DAY_DOT_MONTH_DOT_YEAR,
  DATE_FORMAT_MONTH_NAME,
} from 'config/date';

interface IStatementDate {
  currentType: string;
  statementDate: string;
}

export const StatementDate = ({
  currentType,
  statementDate,
}: IStatementDate) => {
  const { isRaizSuper } = useStatementsPath();

  const startDateOfMonth = dayjs(statementDate).startOf('month').toString();
  const endDateOfMonth = dayjs(statementDate).endOf('month').toString();

  if (
    currentType === TRADE_CONFIRMATION ||
    currentType === KIDS_TRADE_CONFIRMATION ||
    isRaizSuper
  ) {
    return (
      <div>
        <StatementMonth>
          <Time format={DATE_FORMAT_MONTH_NAME}>{statementDate}</Time>
        </StatementMonth>{' '}
        <StatementDateRange>
          &#40;
          <Time format={DATE_FORMAT_DAY_DOT_MONTH_DOT_YEAR}>
            {startDateOfMonth}
          </Time>
          &#45;
          <Time format={DATE_FORMAT_DAY_DOT_MONTH_DOT_YEAR}>
            {endDateOfMonth}
          </Time>
          &#41;
        </StatementDateRange>
      </div>
    );
  }

  return <Time format={DATE_FORMAT_MONTH_NAME}>{statementDate}</Time>;
};
