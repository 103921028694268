import { useRef } from 'react';

import { Content, PDSTitle, TermsAndConditionsIFrame } from '../styles';
import { useTerms } from '../../hooks/useTerms';

import TermsMigrationStepFooter from './components/TermsMigrationStepFooter';

import { useIsIFrameLoading } from 'hooks/useIsIFrameLoading';

export default function AccountMigrationPDS() {
  const { url, name, title } = useTerms();
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const isIFrameLoading = useIsIFrameLoading(iFrameRef);

  return (
    <Content>
      <PDSTitle>{title}</PDSTitle>

      <TermsAndConditionsIFrame src={url} title={name} ref={iFrameRef} />

      <TermsMigrationStepFooter isLoading={isIFrameLoading} />
    </Content>
  );
}
