import type { RefObject } from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

export const useIsIFrameLoading = (iFrameRef: RefObject<HTMLIFrameElement>) => {
  const [isIFrameLoading, setIsIFrameLoading] = useState(true);
  const { pathname } = useLocation();
  const iFrameCurrent = iFrameRef.current;

  useEffect(() => {
    if (iFrameCurrent) {
      iFrameCurrent?.addEventListener('load', () => setIsIFrameLoading(false));

      if (document.readyState === 'complete') {
        setIsIFrameLoading(false);
      }
    }

    return () => {
      iFrameCurrent?.removeEventListener('load', () =>
        setIsIFrameLoading(false),
      );
    };
  }, [iFrameCurrent]);

  useEffect(() => {
    setIsIFrameLoading(true);
  }, [pathname]);

  return isIFrameLoading;
};
