import { sortBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { IGetParsedFilters, ISortFundsTagsProps } from '../types';

import { FUND_TAGS_KEY, FUND_TAGS_ORDER } from 'store/portfolio/consts';

export const getParsedFilters = ({
  values,
  filtersCategories,
}: IGetParsedFilters) => {
  if (!filtersCategories?.length || !values) {
    return {};
  }

  const parsedFilters = Object.entries(values).reduce(
    (prevValues: Record<string, string[]>, [filter, value]) => {
      const categoryNameFiltered = Object.values(filtersCategories)?.reduce(
        (prev, filters) =>
          filters?.tags?.some(({ key }) => value && key.includes(filter))
            ? filters?.key
            : prev,
        '',
      );

      return value
        ? {
            ...prevValues,
            [categoryNameFiltered]: prevValues[categoryNameFiltered]
              ? [...prevValues[categoryNameFiltered], filter]
              : [filter],
          }
        : prevValues;
    },
    {},
  );

  return parsedFilters;
};

export const sortFundsTags = ({ fundsTags }: ISortFundsTagsProps) =>
  fundsTags?.map((fund) => {
    if (fund.key === FUND_TAGS_KEY) {
      return {
        ...fund,
        tags: sortBy(fund?.tags, ({ key }) => FUND_TAGS_ORDER.indexOf(key)),
      };
    }

    return fund;
  });

interface IGetFundsFiltersProps {
  searchParams?: URLSearchParams;
}

export const getFundsFilters = ({ searchParams }: IGetFundsFiltersProps) => {
  if (!searchParams) {
    return {};
  }

  const searchFiltersParams = searchParams.get('filters') || '';

  const fundsFilters: { [key: string]: string[] } = {};

  createSearchParams(searchFiltersParams)?.forEach((_, key, params) => {
    fundsFilters[key] = params.getAll(key);
  });

  return fundsFilters;
};
