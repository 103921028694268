import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MY_APP } from '../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { selectIsUserAlertOpen } from 'store/user/selectors';
import { ALERT_TYPES } from 'constants/alerts';
import { Button } from 'components/elements/Button';

function WithdrawErrorModal({
  errorMessage,
  isWithdrawErrorModalOpen,
  openWithdrawErrorModal,
}) {
  const { t } = useTranslation();

  const isIncompleteWithdrawalAlertOpen = useSelector(
    selectIsUserAlertOpen(ALERT_TYPES.registration.incompleteWithdrawalAccount),
  );

  const isInvalidInvestmentType =
    errorMessage === 'investment[type] is invalid';

  return (
    <Modal
      isOpen={isWithdrawErrorModalOpen}
      onRequestClose={() => openWithdrawErrorModal(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-title">
        {t('withdrawModal.errorModal.title')}
      </div>

      <div className="time-investment__modal-content">
        {isInvalidInvestmentType
          ? t('withdrawModal.errorModal.description')
          : errorMessage}
      </div>

      {MY_APP && isIncompleteWithdrawalAlertOpen ? (
        <div className="withdraw-error-buttons">
          <DeprecatedButton
            title={t('withdrawModal.errorModal.cancelButton')}
            type="button"
            buttonClass="not-now"
            handleClick={() => openWithdrawErrorModal(false)}
          />

          <DeprecatedButton
            title={t('withdrawModal.errorModal.confirmButton')}
            type="button"
            buttonClass="add"
            handleClick={() =>
              openWithdrawErrorModal({
                isWithdrawErrorModalOpen: false,
                isAddWithdrawal: true,
              })
            }
          />
        </div>
      ) : (
        <div className="time-investment__modal-footer -single-btn">
          <Button
            label={t('common.ok')}
            onClick={() => openWithdrawErrorModal(false)}
          />
        </div>
      )}
    </Modal>
  );
}

WithdrawErrorModal.defaultProps = {
  errorMessage: '',
};

WithdrawErrorModal.propTypes = {
  isWithdrawErrorModalOpen: PropTypes.bool.isRequired,
  openWithdrawErrorModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default WithdrawErrorModal;
