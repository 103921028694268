import styled from '@emotion/styled';

import { CleanInputWrapper } from 'components/form/number/styles';
import { SwitchWrapper } from 'components/form/switch/styles';
import arrowRight from 'assets/images/icons/arrow-right.svg';
import { Button } from 'components/elements/Button';
import frequency from 'assets/images/icons/frequency.svg';
import investmentGoal from 'assets/images/icons/savings_goal.svg';

export const InvestingAccessFormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
`;

export const RecurringInvestment = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.mercury};
  border-radius: 12px;
  padding: 16px 16px 4px;
  display: flex;
  flex-direction: column;
  flex: 0.5;
`;

export const InvestmentAmount = styled.div`
  display: flex;
  align-items: center;
`;

export const InvestmentFrequency = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  cursor: pointer;
`;

export const FrequencyIcon = styled.div`
  background: url(${`${frequency}`}) no-repeat center 10px;
  padding: 12px;
`;
export const FrequencyTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  padding: 10px;
`;

export const FrequencyValue = styled.div`
  color: ${({ theme }) => theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  margin-left: auto;
  align-items: center;
  text-transform: capitalize;
`;

export const FrequencyArrow = styled.div`
  background: url(${`${arrowRight}`}) no-repeat center;
  padding: 12px 6px;
  margin-left: 14px;
`;

export const InvestmentAmountTitle = styled.div`
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  flex: 0.4;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  padding-top: 24px;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
`;

export const SaveChangesButton = styled(Button)`
  width: 240px;
  text-transform: capitalize;
`;

export const StopInvestmentButtonWrapper = styled(Button)`
  color: ${({ theme }) => theme.colors.niagaraLight};
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 240px;
  text-transform: capitalize;
`;

export const InvestmentPanelsWrapper = styled.div`
  display: flex;
  gap: 18px;
  padding: 10px 20px;
`;

export const InvestmentGoalIcon = styled.div`
  background: url(${`${investmentGoal}`}) no-repeat center 10px;
  padding: 12px;
`;

export const InvestmentGoalSwitchWrapper = styled(SwitchWrapper)`
  justify-content: flex-start;
  gap: 12px;
  flex: 0.4;
`;

export const CustomNumberFieldWrapper = styled(CleanInputWrapper)`
  flex: 0.6;
  input {
    text-align: right;
    font-family: ${({ theme }) => theme.fontFamily.textRegular};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes['5md']};
  }
`;
