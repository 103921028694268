import { useCallback, useMemo } from 'react';

import {
  ISuitability,
  IFormattedSuitability,
} from '../pages/Settings/components/Forms/types';

import { useSuitability } from 'pages/Settings/components/Forms/hooks/useSuitability';

export const useFormattedSuitabilities = () => {
  const suitabilities: ISuitability[] = useSuitability();

  const formatDefaultValue = useCallback((item: ISuitability) => {
    const defaultValue = item.value || item.question.default_answer.answer_id;

    const value = item.question.answers.find(
      (answer) => answer.answer_id === defaultValue,
    );

    return [
      {
        label: value?.answer || '',
        value: value?.answer_id || '',
        question_id: value?.question_id || item.question.question_id,
        textInput: !!value?.textInput || false,
      },
    ];
  }, []);

  const formatOptions = useCallback(
    (item: ISuitability) =>
      item.question.answers.map((answer) => ({
        label: answer.answer,
        value: answer.answer_id,
        question_id: answer.question_id || item.question.question_id,
        textInput: !!answer.textInput,
      })),
    [],
  );

  const formattedSuitabilities: IFormattedSuitability[] = useMemo(
    () =>
      suitabilities?.map((suitability) => ({
        ...suitability,
        defaultValue: formatDefaultValue(suitability),
        options: formatOptions(suitability),
      })),
    [formatDefaultValue, formatOptions, suitabilities],
  );

  return {
    formattedSuitabilities,
  };
};
