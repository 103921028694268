import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Body, Description, Title } from '../styles';
import AccountMigrationFooter from '../components/AccountMigrationFooter';
import { useMigrationAccountSteps } from '../consts';

import { Footer, FormStyled } from './styles';
import { ACCOUNT_QUESTIONS } from './consts';
import { useFormValidationSchema } from './useFormValidationSchema';

import getMigrationFlow from 'utils/getMigrationFlow';
import { useStepper } from 'hooks/useStepper';
import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import { MIGRATION_FLOW } from 'constants/migrationConstants';

export default function AccountMigrationPDS() {
  const { t } = useTranslation();
  const steps = useMigrationAccountSteps();
  const { goNext } = useStepper({ steps });

  const schema = useFormValidationSchema();
  const migrationFlow = getMigrationFlow();

  const defaultValues = Object.fromEntries(
    ACCOUNT_QUESTIONS[migrationFlow].map((name) => [name, false]),
  );

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(goNext);

  const title =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.PDSPage.superEstateTitle')
      : t('registrationPage.accountMigration.PDSPage.title');

  const description =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.PDSPage.superEstateDescription')
      : t('registrationPage.accountMigration.PDSPage.description');

  return (
    <FormProvider {...form}>
      <FormStyled onSubmit={onSubmit}>
        <Title>{title}</Title>

        <Description>{description}</Description>

        <Body>
          {ACCOUNT_QUESTIONS[migrationFlow].map((question) => (
            <CheckBoxField
              key={question}
              name={question}
              label={t(
                `registrationPage.accountQuestions.questions.${question}`,
              )}
            />
          ))}

          <Footer>
            {t('registrationPage.accountQuestions.disclaimerBottom')}
          </Footer>
        </Body>

        <AccountMigrationFooter />
      </FormStyled>
    </FormProvider>
  );
}
