import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';

interface BankSearchItemProps {
  bank: {
    institution_id: string;
    name: string;
    icon: string;
  };
  handleClick: (id: string) => void;
}

export const BankSearchItem = ({ bank, handleClick }: BankSearchItemProps) => {
  const id = bank.institution_id;

  return (
    <button
      type="button"
      className="-exclude search-item"
      onClick={() => handleClick(id)}
      key={id}
    >
      <div className="grid__container-row">
        <ImageWithDefaultIcon
          src={bank.icon}
          alt={bank.name}
          className="grid__container-row-icon -morebank "
          isDefaultImage={bank.icon === ''}
          withMargin
          defaultIconSize={32}
        />

        {bank.name}
      </div>
    </button>
  );
};
