import { useSearchParams, createSearchParams } from 'react-router-dom';

import { getFundsFilters } from './utils/utils';

interface ISetFilterProps {
  parsedFilters: { [key: string]: string[] };
}

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const fundsFilters = getFundsFilters({ searchParams });

  const setFilter = async ({ parsedFilters }: ISetFilterProps) => {
    const filtersParams = createSearchParams(parsedFilters).toString();

    const filtersSearchParams = {
      ...Object.fromEntries(searchParams),
      ...{ filters: filtersParams },
    };

    setSearchParams(filtersSearchParams, { replace: true });

    return createSearchParams(filtersSearchParams);
  };

  const selectedFilterCount = Object.values(fundsFilters)?.reduce(
    (count, current) => (current?.length ? count + current.length : count),
    0,
  );

  return {
    fundsFilters,
    setFilter,
    selectedFilterCount,
  };
};
