import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import KidImage from './KidImage';
import KidAge from './KidAge';
import {
  KidCardOpenedButton,
  KidCardOpenedHeader,
  KidCardRow,
  KidCardOpenedBalance,
} from './styles';
import { KidCardInfo } from './KidCardInfo';

import { formatNumber } from 'utils/formatters/formatNumber';

function KidCardOpened({ kid, toggleInvestNow }) {
  const { t } = useTranslation();

  return (
    <>
      <KidCardRow>
        <KidImage iconUrl={kid.icon_url} />

        <KidCardOpenedHeader>
          <KidAge kid={kid} />

          <KidCardOpenedBalance>
            {formatNumber({
              value: kid.current_balance,
            })}
          </KidCardOpenedBalance>

          <KidCardOpenedButton
            onClick={(e) => toggleInvestNow({ e, isModalOpen: true, kid })}
            label={t('raizKidsPage.investNow')}
          />
        </KidCardOpenedHeader>
      </KidCardRow>

      <KidCardInfo kid={kid} />
    </>
  );
}

export default KidCardOpened;

KidCardOpened.defaultProps = {
  kid: {},
};

KidCardOpened.propTypes = {
  kid: PropTypes.shape({
    id: PropTypes.string,
    closed: PropTypes.bool,
    investing_eligible: PropTypes.bool,
    date_of_birth: PropTypes.string,
    icon_url: PropTypes.string,
    name: PropTypes.string,
    current_balance: PropTypes.number,
    portfolio: PropTypes.shape({
      name: PropTypes.string,
    }),
    summary: PropTypes.shape({
      invested_by_you: PropTypes.shape({
        value: PropTypes.number,
      }),
      reinvested_dividends: PropTypes.shape({
        value: PropTypes.number,
      }),
      gain_loss: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_found_money_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_referrals_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      withdrawals: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    recurring: PropTypes.shape({
      frequency: PropTypes.string,
      amount: PropTypes.number,
      day: PropTypes.string,
    }),
  }),
  toggleInvestNow: PropTypes.func.isRequired,
};
