import Skeleton from 'react-skeleton-loader';

import { FinancialFormContent } from '../../styles';
import { useRegistrationFinancialPage } from '../../hooks/useRegistrationFinancialPage';

import {
  FinancialFormSkeletonItemWrapper,
  FinancialFormSkeletonWrapper,
  FinancialFormSkeletonItem,
  SkeletonButton,
} from './styles';

import { AU_APP } from 'constants/localeConfigs';

const SKELETON_ITEMS_COUNT = AU_APP ? 5 : 6;

function FinancialFormSkeleton() {
  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();

  return (
    <>
      <FinancialFormContent
        isRegistrationFinancialPage={isRegistrationFinancialPage}
      >
        <FinancialFormSkeletonWrapper>
          {[...Array(SKELETON_ITEMS_COUNT)].map(() => (
            <FinancialFormSkeletonItemWrapper key={window.crypto.randomUUID()}>
              <FinancialFormSkeletonItem>
                <Skeleton height={16} />

                <Skeleton height={28} />
              </FinancialFormSkeletonItem>
            </FinancialFormSkeletonItemWrapper>
          ))}
        </FinancialFormSkeletonWrapper>
      </FinancialFormContent>

      <SkeletonButton>
        <Skeleton height="42px" borderRadius="40px" width="100%" />
      </SkeletonButton>
    </>
  );
}

export default FinancialFormSkeleton;
