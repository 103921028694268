import styled from '@emotion/styled';

export const WidgetsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export const WidgetContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 10px;
  width: calc(50% - 8px);
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
  }
  height: fit-content;
`;

export const WidgetTitle = styled.div`
  height: 50px;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  padding: 14px 0 0 16px;
`;
