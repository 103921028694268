import { defineMessages } from 'react-intl';

import { POTENTIAL_FREE_CASH as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.potentialSpareDays`]: 'Potensi wang lebihan anda dalam 30 hari',
  [`${idPrefix}.potentialSpare`]: 'Potensi wang lebihan anda',

  [`${idPrefix}.noPotentialSpareCash`]: 'Tidak ada potensi wang lebihan anda',
  [`${idPrefix}.assumedTransactions`]: 'Transaksi yang diramalka',
});

export default messages;
