import styled from '@emotion/styled';

export const WidgetWrapper = styled.div`
  animation-name: fadein;
  animation-duration: 925ms;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  overflow: hidden;
`;

export const WidgetTitle = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  padding: 15px 20px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const WidgetContent = styled.div`
  background: ${({ background }) =>
    background ? `url(${background})` : 'none'};
  padding-block: 30px 20px;
  padding-inline: 24px;
`;

export const WidgetHeader = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`;

export const WidgetText = styled.div`
  opacity: ${({ theme }) => theme.opacity.almostOpaque};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  text-align: center;
  width: 100%;
  padding: 0 10%;
`;
