import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as userActions from '../../store/user/actions';
import { FUNDING_RDV } from '../../constants/comonConstants';

import RDVView from './RDVView';

class RDV extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: '0.00',
    };

    this.closeRDWModal = this.closeRDWModal.bind(this);

    this.handleSubmitRdv = this.handleSubmitRdv.bind(this);

    this.changeRdvAmount = this.changeRdvAmount.bind(this);

    this.closeRDWModalError = this.closeRDWModalError.bind(this);
  }

  handleSubmitRdv() {
    const { actions } = this.props;
    const { amount } = this.state;

    actions.rdvVerification({ amount });
  }

  closeRDWModal() {
    const { actions } = this.props;

    actions.closeModal();
  }

  changeRdvAmount(e) {
    if (e.value) {
      this.setState({ amount: e.value });
    } else {
      this.setState({ amount: '0.00' });
    }
  }

  closeRDWModalError() {
    const { actions, isShowRdvSuccess } = this.props;

    if (isShowRdvSuccess) {
      actions.closeModal();
    } else {
      actions.closeRDWModalError();

      actions.getUserData().then(() => {
        const { user } = this.props;
        let ishaveRdvModal = false;

        if (user && user.alerts && user.alerts.length !== 0) {
          user.alerts.map((alert) => {
            if (alert.type === FUNDING_RDV) {
              ishaveRdvModal = true;

              actions.showRDVModal(alert.title, alert.description);
            }

            return true;
          });

          if (!ishaveRdvModal) {
            actions.closeModal();
          }
        }
      });
    }
  }

  render() {
    const { amount } = this.state;

    const {
      titleRdv,
      descriptionRdv,
      isShowRdvError,
      errorMessage,
      isShowRdvSuccess,
      message,
    } = this.props;

    return (
      <RDVView
        showLoader
        closeRDWModal={this.closeRDWModal}
        handleSubmitRdv={this.handleSubmitRdv}
        amount={amount}
        changeRdvAmount={this.changeRdvAmount}
        titleRdv={titleRdv}
        descriptionRdv={descriptionRdv}
        isShowRdvError={isShowRdvError}
        errorMessage={errorMessage}
        closeRDWModalError={this.closeRDWModalError}
        isShowRdvSuccess={isShowRdvSuccess}
        message={message}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  titleRdv: state.user.titleRdv,
  descriptionRdv: state.user.descriptionRdv,
  isShowRdvError: state.user.isShowRdvError,
  errorMessage: state.user.errorMessage,
  isShowRdvSuccess: state.user.isShowRdvSuccess,
  message: state.user.message,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RDV);

RDV.defaultProps = {
  state: {},
  titleRdv: '',
  descriptionRdv: '',
  isShowRdvError: false,
  errorMessage: '',
  isShowRdvSuccess: false,
  message: '',
  user: {},
};

RDV.propTypes = {
  state: PropTypes.shape({}),
  actions: PropTypes.shape({
    showRDVModal: PropTypes.func,
    getUserData: PropTypes.func,
    closeModal: PropTypes.func,
    closeRDWModalError: PropTypes.func,
    rdvVerification: PropTypes.func,
  }).isRequired,
  titleRdv: PropTypes.string,
  descriptionRdv: PropTypes.string,
  isShowRdvError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isShowRdvSuccess: PropTypes.bool,
  message: PropTypes.string,
  user: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
