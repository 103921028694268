import styled from '@emotion/styled';

export const DetailsForm = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.extraSmall)} {
    width: auto;
  }
`;
