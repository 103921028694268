import Skeleton from 'react-skeleton-loader';

import { HistoryDateItem } from 'pages/MyFinance/components/styles';

function RoundUpsSkeleton() {
  return (
    <div>
      <HistoryDateItem>
        <Skeleton />
      </HistoryDateItem>

      {[...Array(10)].map((e, index) => (
        <div className="round-ups__history-item" key={`${index * 2}`}>
          <div className="">
            <div className="round-ups__history-value">
              <Skeleton />
            </div>

            <div className="round-ups__history-comment">
              <span className="round-ups__history-comment-value">
                <Skeleton />
              </span>

              <span className="round-ups__history-comment-label">
                <Skeleton />
              </span>
            </div>
          </div>

          <div className="checkbox__plus -skeleton">
            <Skeleton />
          </div>
        </div>
      ))}
    </div>
  );
}

export default RoundUpsSkeleton;
