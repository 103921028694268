import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import * as userActions from '../../store/user/actions';
import * as dependencyActions from '../../store/dependentUser/actions';
import * as actionsGifts from '../../store/gifts/actions';
import * as modalsActions from '../../store/modals/actions';
import '../../styles/pages/registration-page.scss';
import '../../styles/pages/invite-content.scss';
import {
  CREDIT,
  INVESTMENT_INITIAL,
  PERSONAL_INFO,
  PERSONAL_TERMS,
} from '../../store/dependentUser/types';

import messages from './RaizKidsRegistration.i18n';
import RaizKidsRegistrationView from './RaizKidsRegistrationView';

import { INVESTMENT_TYPES } from 'constants/kidsConstants';
import { selectIsProPortfolioEnabled } from 'store/firebaseConfig/selectors';
import { selectDependentUserId } from 'store/dependentUser/selectors';
import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class RaizKidsRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: '',
      dob: '',
      oneTimeInvestmentValue: 50,
      allowType: 'notAllow',
      allowTypeInvestment: 'notAllow',
      showLoader: false,
      isShowErrorModal: false,
      dobError: false,
      unlimAmount: 0,
      modalErrorMessage: '',
      newKidEmail: '',
      newKidName: '',
      alert: {
        isOpen: false,
        title: null,
        text: null,
        handleClick: null,
      },
      canEditEmail: true,
    };

    this.renderIcons = this.renderIcons.bind(this);

    this.changeUnlimitedAmount = this.changeUnlimitedAmount.bind(this);

    this.handleChangeDate = this.handleChangeDate.bind(this);

    this.changeAccessType = this.changeAccessType.bind(this);

    this.changeAccessInvestment = this.changeAccessInvestment.bind(this);

    this.toggleErrorModal = this.toggleErrorModal.bind(this);

    this.handleChangeIcon = this.handleChangeIcon.bind(this);

    this.changeOneTimeInvestmentValue =
      this.changeOneTimeInvestmentValue.bind(this);

    this.handleCloseAvatarWarning = this.handleCloseAvatarWarning.bind(this);
  }

  componentDidMount() {
    const { giftsActions, actionsDependency } = this.props;

    giftsActions.getGiftsIcons();

    const user = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
    );

    const userId = user?.dependency_user?.id;

    if (user?.dependency_user) {
      this.setState({
        newKidEmail: user.dependency_user.email,
        newKidName: user.dependency_user.name,
        dob: new Date(user.dependency_user.date_of_birth),
        allowType: user.dependency_user.account_access
          ? INVESTMENT_TYPES.allow
          : INVESTMENT_TYPES.notAllow,
        icon: user.dependency_user.avatar_id,
      });
    }

    if (userId) {
      actionsDependency.getDependencyUser(userId);
    }
  }

  componentDidUpdate() {
    const { history, currentStep, match } = this.props;

    const user = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
    );

    const fundTag = match?.params?.fundTag;

    if (
      (user || currentStep === '/raiz-registration/personal/terms') &&
      currentStep &&
      history.location.pathname !== currentStep &&
      !fundTag
    ) {
      history.push(currentStep);
    }
  }

  handleCloseAvatarWarning() {
    this.setState({
      alert: {
        isOpen: false,
        title: null,
        text: null,
        handleClick: null,
      },
    });
  }

  handleChangeDate(date, isOpen) {
    if (dayjs(date).isValid() && isOpen) {
      return;
    }

    this.setState({
      dob: dayjs(date).isAfter(dayjs()) ? new Date() : date,
      dobError: false,
    });
  }

  handleChangeIcon(icon) {
    this.setState({ icon });
  }

  handleSubmitQuestions = (e, values, form, hasValidationErrors, from) => {
    const { actionsDependency, t } = this.props;
    const { dob, icon, allowType, canEditEmail } = this.state;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (from === PERSONAL_INFO && !icon) {
        this.setState({
          alert: {
            isOpen: true,
            title: null,
            text: t('raizKidsRegistrationPage.kidsInfo.fields.avatar.error'),
            handleClick: this.handleCloseAvatarWarning,
          },
        });
      }

      if (from === PERSONAL_INFO && !dob) {
        this.setState({
          dobError: true,
        });
      } else if (
        !hasValidationErrors &&
        ((from === PERSONAL_INFO && icon) || from === PERSONAL_TERMS)
      ) {
        this.setState({
          dobError: false,
        });

        const requestData =
          from === PERSONAL_INFO
            ? {
                avatar_id: icon,
                name: values.name,
                date_of_birth: dob,
                ...(allowType === INVESTMENT_TYPES.allow &&
                  canEditEmail && {
                    email: values.email,
                    dependent_user: {
                      account_access: true,
                    },
                  }),
                ...(allowType === INVESTMENT_TYPES.notAllow && {
                  dependent_user: {
                    account_access: false,
                  },
                }),
              }
            : {};

        const accountInfo = JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
        );

        if (from === PERSONAL_TERMS) {
          if (!accountInfo) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.kidsRegistration,
              JSON.stringify({ dependency_user: { account_access: false } }),
            );
          } else {
            this.setState({
              newKidEmail: accountInfo.dependency_user.email,
              newKidName: accountInfo.dependency_user.name,
              dob: new Date(accountInfo.dependency_user.date_of_birth),
            });
          }
        }

        if (
          from === PERSONAL_INFO &&
          accountInfo &&
          accountInfo.dependency_user.id
        ) {
          requestData.dependent_user_id = accountInfo.dependency_user.id;

          this.setState({
            showLoader: true,
            newKidEmail: values.email,
            newKidName: requestData.name,
          });

          actionsDependency
            .updateDependencyUser({ from }, requestData)
            .then(() => {
              const { globalErrorMessage } = this.props;
              const { newKidEmail } = this.state;

              if (globalErrorMessage) {
                this.setState({ canEditEmail: true });
              } else if (!globalErrorMessage && !!newKidEmail) {
                this.setState({ canEditEmail: false });
              }

              this.setState({ showLoader: false });
            });
        } else {
          this.setState({
            ...(values.email && { newKidEmail: values.email }),
            ...(values.name && { newKidName: values.name }),
          });

          const actionResult = actionsDependency.registrationDependencyUser(
            { from },
            requestData,
          );

          if (actionResult.then) {
            actionResult.then(() => {
              const { globalErrorMessage } = this.props;
              const { newKidEmail } = this.state;

              if (globalErrorMessage) {
                this.setState({ canEditEmail: true });
              } else if (!globalErrorMessage && !!newKidEmail) {
                this.setState({ canEditEmail: false });
              }
            });
          }
        }
      }
    }
  };

  handleSubmitInvestment = (e, values, form, hasValidationErrors, from) => {
    const { oneTimeInvestmentValue, allowTypeInvestment, unlimAmount } =
      this.state;

    const { actionsDependency } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (from === INVESTMENT_INITIAL) {
          const requestDataInvestment = {
            type: CREDIT,
            amount: oneTimeInvestmentValue,
            investing:
              allowTypeInvestment === INVESTMENT_TYPES.allow ||
              allowTypeInvestment === INVESTMENT_TYPES.allowWeekly,
            investing_weekly_limit: unlimAmount,
            isRegistration: true,
          };

          const user = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
          );

          const userId = user.dependency_user.id;

          if (oneTimeInvestmentValue > 0) {
            this.setState({ showLoader: true });

            actionsDependency
              .dependencyUserInvestment(requestDataInvestment, userId)
              .then(() => {
                const { errorMessage } = this.props;

                if (errorMessage && errorMessage.length) {
                  this.toggleErrorModal(true);

                  this.setState({ modalErrorMessage: errorMessage });
                }

                this.setState({ showLoader: false });
              });
          } else {
            actionsDependency.dependencyUserInvestmentSuccess();
          }

          actionsDependency.updateDependencyUser(
            { from: INVESTMENT_INITIAL },
            {
              dependent_user_id: userId,

              dependent_user: {
                investing:
                  allowTypeInvestment === INVESTMENT_TYPES.allow ||
                  allowTypeInvestment === INVESTMENT_TYPES.allowWeekly,
                investing_weekly_limit:
                  allowTypeInvestment === INVESTMENT_TYPES.allowWeekly
                    ? unlimAmount
                    : null,
              },
            },
          );
        }
      }
    }
  };

  goBack = ({ path = '' }) => {
    const { user, history, actionsDependency, childId } = this.props;

    if (path) {
      const generatedPath = generatePath(path, {
        childId,
      });

      actionsDependency.goBack(generatedPath);

      history.push({
        pathname: generatedPath,
        search: `?${PORTFOLIO_SEARCH_PARAMS.childId}=${childId}`,
      });
    } else {
      history.push(user.previousStep);
    }
  };

  handleSubmit = (e, values, form, hasValidationErrors) => {
    const { actionsDependency } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      if (!hasValidationErrors) {
        form.submit();

        actionsDependency.registrationDependencyUser({ from: form });
      }
    }
  };

  changeAccessType(allowType) {
    this.setState({
      allowType,
    });
  }

  changeAccessInvestment(allowTypeInvestment) {
    this.setState({
      allowTypeInvestment,
    });
  }

  changeOneTimeInvestmentValue(value = 0) {
    this.setState({ oneTimeInvestmentValue: value });
  }

  toggleErrorModal(condition) {
    this.setState({ isShowErrorModal: condition });
  }

  changeUnlimitedAmount(unlimAmount) {
    this.setState({
      unlimAmount,
    });
  }

  renderIcons(id, link) {
    const { icon } = this.state;

    return (
      <div
        className="raiz-kids__avatar-container"
        key={id}
        onClick={() => this.handleChangeIcon(id)}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      >
        <img
          alt={id}
          src={link}
          className={`raiz-kids__avatar ${icon === id ? '-active' : ''}`}
        />
      </div>
    );
  }

  render() {
    const {
      match,
      receiverIcons,
      errorMessage,
      history,
      isProPortfolioEnabled,
    } = this.props;

    const { stepType, stepName } = match.params;

    const {
      showLoader,
      dob,
      allowType,
      dobError,
      oneTimeInvestmentValue,
      allowTypeInvestment,
      isShowErrorModal,
      unlimAmount,
      modalErrorMessage,
      newKidEmail,
      newKidName,
      alert,
      canEditEmail,
    } = this.state;

    return (
      <RaizKidsRegistrationView
        unlimAmount={unlimAmount}
        isShowErrorModal={isShowErrorModal}
        errorMessage={errorMessage}
        modalErrorMessage={modalErrorMessage}
        toggleErrorModal={this.toggleErrorModal}
        allowType={allowType}
        changeAccessType={this.changeAccessType}
        allowTypeInvestment={allowTypeInvestment}
        changeAccessInvestment={this.changeAccessInvestment}
        dobError={dobError}
        oneTimeInvestmentValue={oneTimeInvestmentValue}
        messages={messages}
        dob={dob}
        handleChangeDate={this.handleChangeDate}
        changeOneTimeInvestmentValue={this.changeOneTimeInvestmentValue}
        handleSubmitInvestment={this.handleSubmitInvestment}
        handleSubmit={this.handleSubmit}
        handleSubmitQuestions={this.handleSubmitQuestions}
        goBack={this.goBack}
        changeUnlimitedAmount={this.changeUnlimitedAmount}
        stepType={stepType}
        stepName={stepName}
        showLoader={showLoader}
        renderIcons={this.renderIcons}
        receiverIcons={receiverIcons}
        newKidEmail={newKidEmail}
        newKidName={newKidName}
        alert={alert}
        canEditEmail={canEditEmail}
        history={history}
        isProPortfolioEnabled={isProPortfolioEnabled}
      />
    );
  }
}

RaizKidsRegistration.defaultProps = {
  currentStep: '',
  errorMessage: '',
  user: {},
  match: {},
  giftsActions: {},
  receiverIcons: {},
  actionsDependency: {},
  globalErrorMessage: '',
  isProPortfolioEnabled: false,
  childId: '',
};

const mapStateToProps = (state) => ({
  state,
  user: state.user,
  isShowErrorModal: state.dependencyUsers.isShowErrorModal,
  errorMessage: state.dependencyUsers.errorMessage,
  currentStep: state.dependencyUsers.currentStep,
  receiverIcons: state.gifts.receiverIcons,
  canEditEmail: state.dependencyUsers.canEditEmail,
  globalErrorMessage: state.dependencyUsers.globalErrorMessage,
  isProPortfolioEnabled: selectIsProPortfolioEnabled(state),
  childId: selectDependentUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  giftsActions: bindActionCreators(actionsGifts, dispatch),
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
  actionsModals: bindActionCreators(modalsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RaizKidsRegistration));

RaizKidsRegistration.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      stepType: PropTypes.string,
      stepName: PropTypes.string,
      fundTag: PropTypes.string,
    }),
  }),
  currentStep: PropTypes.string,
  errorMessage: PropTypes.string,
  user: PropTypes.shape({
    previousStep: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  giftsActions: PropTypes.shape({
    getGiftsIcons: PropTypes.func,
  }),
  receiverIcons: PropTypes.shape({}),
  actionsDependency: PropTypes.shape({
    dependencyUserInvestmentSuccess: PropTypes.func,
    updateDependencyUser: PropTypes.func,
    registrationDependencyUser: PropTypes.func,
    dependencyUserInvestment: PropTypes.func,
    dependencyUserRecurring: PropTypes.func,
    goBack: PropTypes.func,
    getDependencyUser: PropTypes.func,
  }),
  globalErrorMessage: PropTypes.string,
  isProPortfolioEnabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
  actionsModals: PropTypes.shape({
    displayErrorModal: PropTypes.func,
  }).isRequired,
  childId: PropTypes.string,
};
