import styled from '@emotion/styled';
import Modal from 'react-modal';

import logoKids from 'assets/images/logo_kids.svg';
import KidsCardAu from 'assets/images/illustration.svg';
import { Button } from 'components/elements/Button';

export const EnableKidsModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.gradients.green};
  border-radius: 10px;
  width: 455px;
  min-height: 220px;
  margin: 2% auto 0;
  padding: 40px;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  font-family: ${({ theme }) => theme.fontFamily.medium};
`;

export const EnableKidsModalLogo = styled.div`
  background-image: url(${logoKids});
  height: 59px;
  width: 100%;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: -8px;
`;

export const EnableKidsModalImage = styled.div`
  background-image: url(${KidsCardAu});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 295px;
  margin-top: 30px;
`;

export const EnableKidsModalTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5lg']};
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  line-height: ${({ theme }) => theme.lineHeights.length['4xl']};
  margin-bottom: 25px;
`;

export const EnableKidsModalDescription = styled.div`
  opacity: ${({ theme }) => theme.opacity.semiTransparent};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  margin-bottom: 40px;
`;

export const EnableKidsModalButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;
