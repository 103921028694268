import React from 'react';
import PropTypes from 'prop-types';
import { Form as ReactFinalForm } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';

import { Col, CheckBoxRow, BottomRow, FormStyled, CheckBox } from '../styles';
import { SaveChangesButton } from '../SaveChangesButton';

import { Description, TextField } from './styles';

export default function InvestmentLimitForm({
  handleSubmit,
  child,
  showLoader,
  allowType,
  changeAccessType,
}) {
  const { t } = useTranslation();

  return (
    <ReactFinalForm
      onSubmit={handleSubmit}
      render={({ form, values, hasValidationErrors }) => (
        <FormStyled
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <Description>
            <Trans
              t={t}
              i18nKey="raizKidsEditPage.investmentLimitForm.description"
            />
          </Description>

          <CheckBoxRow>
            <Col>
              <CheckBox
                basicCheckbox
                name="allow"
                defaultChecked={allowType}
                onClick={() => {
                  changeAccessType(true);
                }}
                type="checkbox"
                label={t(
                  'raizKidsEditPage.investmentLimitForm.allowKidAccessCheckbox.true',
                )}
              />
            </Col>

            {allowType && (
              <Col>
                <TextField
                  name="email"
                  disabled={Boolean(child.email)}
                  defaultValue={child.email}
                  label={t(
                    'raizKidsEditPage.investmentLimitForm.kidEmailLabel',
                  )}
                />
              </Col>
            )}

            <Col>
              <CheckBox
                basicCheckbox
                name="not-allow"
                defaultChecked={!allowType}
                onClick={() => {
                  changeAccessType(false);
                }}
                type="checkbox"
                label={t(
                  'raizKidsEditPage.investmentLimitForm.allowKidAccessCheckbox.false',
                )}
              />
            </Col>
          </CheckBoxRow>

          <BottomRow>
            <SaveChangesButton
              isLoading={showLoader}
              type="submit"
              label={t('common.save')}
            />
          </BottomRow>
        </FormStyled>
      )}
    />
  );
}

InvestmentLimitForm.propTypes = {
  child: PropTypes.shape({
    email: PropTypes.string,
    account_access: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  allowType: PropTypes.bool,
  changeAccessType: PropTypes.func.isRequired,
};

InvestmentLimitForm.defaultProps = {
  allowType: false,
};
