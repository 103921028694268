import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Firebase from 'firebase/app';

import { PORTFOLIO_PLANS } from '../consts';

import { SwitchButtonWrapper } from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioParams } from 'store/portfolio/selectors';

export default function PortfolioTypeSwitchButton({ portfolioPlan }) {
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();

  const {
    childId,
    portfolioPlan: portfolioPlanParam,
    isSuper,
  } = useAppSelector(selectPortfolioParams);

  const isActive = portfolioPlanParam === portfolioPlan;

  return (
    <SwitchButtonWrapper
      isActive={isActive}
      onClick={() => {
        if (portfolioPlan === PORTFOLIO_PLANS.pro) {
          Firebase.analytics().logEvent('PortfolioPlus');
        }

        setSearchParams({
          ...(childId && { childId }),
          ...(isSuper && { isSuper }),
          portfolioPlan,
        });
      }}
    >
      {t(`portfolio.planSwitch.${portfolioPlan}`)}
    </SwitchButtonWrapper>
  );
}

PortfolioTypeSwitchButton.propTypes = {
  portfolioPlan: PropTypes.string.isRequired,
};
