// TODO Refactor Round Ups Info to Typescript
// https://acornsau.atlassian.net/browse/RAIZ-7000
import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import colors from '../../../../theme/colors';
import { ValueSubtitle, LabelSub } from '../styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { useUpdateRoundUpsSettingsMutation } from 'store/roundUpsSettings/roundUpsSettings';

function AutomaticRoundUpsView({ settings, roundUpsSummary }) {
  const { t } = useTranslation();
  const isAutomaticRoundUpEnabled = settings?.automatic_roundup;
  const [updateRoundUpsSettings] = useUpdateRoundUpsSettingsMutation();

  const updateAutomaticRoundUpsSettings = (isAutomaticRoundUp) => {
    const roundUpsSettings = {
      ...settings,
      automatic_roundup: isAutomaticRoundUp,
    };

    updateRoundUpsSettings(roundUpsSettings);
  };

  return (
    <>
      {isAutomaticRoundUpEnabled && (
        <ValueSubtitle>
          {formatNumber({
            value: roundUpsSummary?.automatic?.waiting ?? 0,
          })}
        </ValueSubtitle>
      )}

      {isAutomaticRoundUpEnabled && roundUpsSummary && (
        <LabelSub>
          <Trans
            t={t}
            i18nKey="roundUps.until"
            components={{
              start: formatNumber({
                value:
                  Number(settings.automatic_lower_threshold) -
                  roundUpsSummary.automatic.waiting,
              }),
              end: formatNumber({
                value: Number(settings?.automatic_lower_threshold),
              }),
            }}
          />
        </LabelSub>
      )}

      {!isAutomaticRoundUpEnabled && (
        <LabelSub>
          {t('roundUps.enableAutoRound')}

          {settings && (
            <Switch
              checked={false}
              onChange={updateAutomaticRoundUpsSettings}
              onColor={colors.lighterGreen}
              offColor={colors.lightGreen}
              onHandleColor={colors.white}
              handleDiameter={26}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0"
              activeBoxShadow="0"
              height={18}
              width={45}
              className="react-switch"
              id="material-switch"
            />
          )}
        </LabelSub>
      )}
    </>
  );
}

export default AutomaticRoundUpsView;

AutomaticRoundUpsView.defaultProps = {
  settings: {},
  roundUpsSummary: {},
};

AutomaticRoundUpsView.propTypes = {
  settings: PropTypes.shape({
    automatic_roundup: PropTypes.bool,
    automatic_lower_threshold: PropTypes.number,
  }),
  roundUpsSummary: PropTypes.shape({
    automatic: PropTypes.shape({
      waiting: PropTypes.number,
    }),
  }),
};
