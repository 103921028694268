import Skeleton from 'react-skeleton-loader';

import { CheckboxContent, LoaderWrapper } from './styles';

export const CheckboxSkeleton = () => (
  <CheckboxContent>
    <LoaderWrapper>
      <Skeleton width="42px" borderRadius="100%" />
    </LoaderWrapper>

    <Skeleton width="300px" />
  </CheckboxContent>
);
