import styled from '@emotion/styled';

const BANNER_HEIGHT = 224;

export const BannerWrapper = styled.div`
  display: flex;
  margin-top: 26px;
`;

export const BannerContent = styled.div`
  height: ${BANNER_HEIGHT}px;
  padding-top: 74px;
  background: ${({ theme }) => theme.gradients.purple};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  width: 100%;
`;

export const BannerTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.xl};
    font-weight: ${theme.fontWeights.ultraBold};
    font-family: ${theme.fontFamily.medium};
    letter-spacing: ${theme.letterSpacing.wide};
  `}
`;

export const BannerSubTitle = styled.div`
  padding-top: 5px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
