import styled from '@emotion/styled';

export const ProfileFieldWrapper = styled.div`
  padding-bottom: 24px;
`;

export const ProfileFieldLabel = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-bottom: 5px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
`;

export const ProfileFieldValue = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  width: 100%;
  padding-right: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
