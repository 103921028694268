import styled from '@emotion/styled';

import iconEmpty from '../../../../assets/images/icons/ic_empty.svg';

import { RaizKidsContentProps } from './types';

import { CARDS_GAP } from 'pages/RaizKids/const';

export const RaizKidsEmptyListWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 10px 0;
  text-align: center;
  width: 100%;
`;

export const RaizKidsEmptyListIcon = styled.div`
  margin: 12px auto;
  background-image: url(${`${iconEmpty}`});
  background-size: 30px 27px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.athensGray};
  width: 70px;
  height: 70px;
`;

export const RaizKidsEmptyListTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.darkTopaz};
`;

export const RaizKidsListWrapper = styled.div<Partial<RaizKidsContentProps>>`
  display: grid;
  grid-template-columns: ${({ isActiveListOpen }) =>
    isActiveListOpen ? '1fr 1fr' : '1fr 1fr 1fr'};
  grid-gap: ${CARDS_GAP}px;
  margin-top: 20px;
  width: 100%;
`;
