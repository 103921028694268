import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import { CloseAccountModal } from 'components/layout/Modals/CloseAccountModal';
import OffsettersUnsubscribeModal from 'components/layout/Modals/OffsettersUnsubscribeModal';
import {
  FEES,
  SECURITY,
  SUBMIT_TICKET,
  SUPPORT,
} from 'constants/localeConfigs';
import * as rewardsActions from 'store/rewards/actions';
import { checkIsDependentUser } from 'utils/user';

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenUnsubscribeModal: false,
    };

    this.offsettersUnsubscribe = this.offsettersUnsubscribe.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);

    this.openUnsubscribeModal = this.openUnsubscribeModal.bind(this);
  }

  componentDidMount() {
    const { actions, isNewRewards } = this.props;

    if (isNewRewards) {
      actions.getOffsetters();
    }
  }

  handleCloseModalError() {
    this.setState({ isOpenUnsubscribeModal: false });
  }

  offsettersUnsubscribe() {
    const { actions } = this.props;

    actions.joinOffsetters().then(() => {
      this.setState({ isOpenUnsubscribeModal: false });

      actions.getOffsetters();
    });
  }

  openUnsubscribeModal() {
    this.setState({ isOpenUnsubscribeModal: true });
  }

  render() {
    const { isNewRewards, offsetters, user, supportStep, t } = this.props;
    const { isOpenUnsubscribeModal } = this.state;

    return (
      <div className="profile-content settings__content-default-container settings__support">
        <div className="profile-content__header">
          <div className="profile-content__title">
            {t('settingsPage.support.title')}
          </div>
        </div>

        <div className="profile-content__container">
          <a
            href={SUPPORT}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.support.links.faq')}
          </a>

          <a
            href={SUBMIT_TICKET}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.support.links.contact')}
          </a>

          <a
            href={SECURITY}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.support.links.security')}
          </a>

          <Link to="/settings/support?legal" className="page-menu__item">
            {t('settingsPage.support.links.legal')}
          </Link>

          {isNewRewards && offsetters && offsetters.subscriptionStatus && (
            <Link
              to="/"
              className="page-menu__item"
              onClick={(e) => {
                e.preventDefault();

                this.openUnsubscribeModal();
              }}
            >
              {t('settingsPage.support.links.offsettersUnsubscribe')}
            </Link>
          )}

          <a
            href={FEES}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.support.links.fees')}
          </a>

          {!checkIsDependentUser(user) && (
            <CloseAccountModal supportStep={supportStep} />
          )}
        </div>

        {isOpenUnsubscribeModal && (
          <OffsettersUnsubscribeModal
            isShowConfirmation
            handleCloseModalError={this.handleCloseModalError}
            offsettersUnsubscribe={this.offsettersUnsubscribe}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  offsetters: state.rewards.offsetters,
  user: state.user.user,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rewardsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Support));

Support.defaultProps = {
  supportStep: '',
  isNewRewards: false,
  offsetters: {},
  user: {},
};

Support.propTypes = {
  supportStep: PropTypes.string,
  isNewRewards: PropTypes.bool,
  user: PropTypes.shape({
    user_type: PropTypes.string,
  }),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  offsetters: PropTypes.shape({
    subscriptionStatus: PropTypes.bool,
  }),
  t: PropTypes.func.isRequired,
};
