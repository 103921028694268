import { useTranslation } from 'react-i18next';

import { ChangeStatusContent, ChangeStatusWrapper, Title } from './styles';
import { VerificationStatus } from './VerificationStatus';
import { StatusButtons } from './StatusButtons';
import { StatusDate } from './StatusDate';
import { StatusContentSkeleton } from './StatusContentSkeleton/StatusContentSkeleton';

import {
  useCancelVerificationMutation,
  useVerificationStatusQuery,
} from 'store/fundingAccount/fundingAccount';

export const StatusContent = () => {
  const { t } = useTranslation();

  const verificationStatusQuery = useVerificationStatusQuery();

  const [_, cancelVerificationMutation] = useCancelVerificationMutation();

  if (
    verificationStatusQuery.isFetching ||
    cancelVerificationMutation.isLoading
  ) {
    return <StatusContentSkeleton />;
  }

  return (
    <ChangeStatusWrapper>
      <ChangeStatusContent>
        <Title>{t('settingsPage.fundingAccountPage.changeStatus.title')}</Title>

        <VerificationStatus />
      </ChangeStatusContent>

      <StatusButtons />

      <StatusDate />
    </ChangeStatusWrapper>
  );
};
