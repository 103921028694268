import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Autocomplete from 'react-google-autocomplete';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import * as rewardsActions from '../../../store/rewards/actions';
import { REWARDS_IN_STORE_SETTINGS } from '../../../pages/NewRewards/consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

class ChangeLocationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: null,
      lat: -33.8691991,
      lng: 151.2059639,
      isConfirmButtonDisabled: true,
    };

    this.clear = this.clear.bind(this);

    this.changeValue = this.changeValue.bind(this);

    this.onPlaceSelected = this.onPlaceSelected.bind(this);

    this.getCurrentAddress = this.getCurrentAddress.bind(this);

    this.setLocation = this.setLocation.bind(this);
  }

  onPlaceSelected(place) {
    if (place && place.formatted_address) {
      this.setState({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        isConfirmButtonDisabled: false,
      });
    }
  }

  getCurrentAddress() {
    navigator.geolocation.getCurrentPosition((position) => {
      const { google } = window;

      const latlng = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude,
      );

      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          console.error(status);
        }

        if (status === google.maps.GeocoderStatus.OK) {
          this.setState({
            address: results[0].formatted_address,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            isConfirmButtonDisabled: false,
          });
        }
      });
    });
  }

  setLocation() {
    const { close, actions, addressSave } = this.props;
    const { address, lat, lng } = this.state;

    if (address && lat && lng) {
      actions.setLocationSuccess(address, lat, lng);
    } else {
      actions.setLocationSuccess(addressSave, lat, lng);
    }

    const offersParams = {
      latitude: lat,
      longitude: lng,
      distance: REWARDS_IN_STORE_SETTINGS.distance,
    };

    actions.getInstoreOffers(offersParams);

    close();
  }

  changeValue(val) {
    this.setState({
      address: val,
    });
  }

  clear() {
    this.setState({
      address: '',
    });
  }

  render() {
    const { isShowChangeLocation, t, close, addressSave } = this.props;
    const { address, isConfirmButtonDisabled } = this.state;

    return (
      <Modal
        isOpen={isShowChangeLocation}
        onRequestClose={() => close(false)}
        contentLabel="withdraw-modal"
        shouldCloseOnOverlayClick
        className="modal-basic bank__modal bank-sign modal-location"
      >
        <div className="bank__modal-content">
          <div className="time-investment__modal-title">
            {t('newRewardsPage.changeLocationModal.showOffers')}
          </div>

          <div>
            <DeprecatedButton
              title={t('common.space')}
              buttonClass="button__close -black"
              handleClick={() => close(false)}
            />

            <div className="label">
              {t('newRewardsPage.changeLocationModal.choose')}
            </div>

            <div className="autocomplete">
              <Autocomplete
                apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                onPlaceSelected={(place) => this.onPlaceSelected(place)}
                options={{
                  types: ['(regions)'],
                  componentRestrictions: { country: 'au' },
                }}
                onChange={(evt) => {
                  this.changeValue(evt.target.value);
                }}
                placeholder=""
                value={address === null ? addressSave : address}
                defaultValue={addressSave}
              />

              <div
                className="clear"
                onClick={this.clear}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              />
            </div>
          </div>

          <div
            className="current-location"
            onClick={this.getCurrentAddress}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            {t('newRewardsPage.changeLocationModal.getCurrent')}
          </div>
        </div>

        <div className="-single-btn">
          <Button
            label={t('newRewardsPage.changeLocationModal.setLocation')}
            onClick={() => this.setLocation()}
            isDisabled={isConfirmButtonDisabled}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rewardsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ChangeLocationModal));

ChangeLocationModal.defaultProps = {
  isShowChangeLocation: false,
};

ChangeLocationModal.propTypes = {
  isShowChangeLocation: PropTypes.bool,
  t: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  addressSave: PropTypes.string.isRequired,
};
