import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';

import {
  OTHER_NOTIFICATION,
  REGISTRATION_ACCESS_SELECT,
} from '../../../store/dependentUser/types';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function KidsNotification({
  showLoader,
  goBack,
  handleSubmit,
  notifications,
  handleChange,
  isEditChild,
}) {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={(e) => handleSubmit(e, OTHER_NOTIFICATION)}
      render={() => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, OTHER_NOTIFICATION)}
        >
          <div className="text-left">
            {t('raizKidsEditPage.notification.notifyMe')}
          </div>

          <div className={'"form-field radio'}>
            {notifications &&
              notifications.map((notification) => (
                <div
                  className="raiz-kids__notifications"
                  key={notification.key}
                >
                  <div>{notification.name}</div>

                  <Switch
                    onChange={(condition) => {
                      handleChange(condition, notification.key);
                    }}
                    checked={notification.value}
                    onColor="#0AAE77"
                    offColor="#D9D9D9"
                    onHandleColor="#ffffff"
                    handleDiameter={27}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)"
                    activeBoxShadow="0"
                    height={31}
                    width={51}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              ))}
          </div>

          <div className="buttons-part">
            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  title={t('common.back')}
                  buttonClass="button back"
                  handleClick={() =>
                    goBack({ path: REGISTRATION_ACCESS_SELECT })
                  }
                />
              </div>
            )}

            {!isEditChild && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showLoader}
                  type="submit"
                  title={t('common.next')}
                  buttonClass="button button--submit"
                />
              </div>
            )}
          </div>
        </form>
      )}
    />
  );
}

KidsNotification.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  isEditChild: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
};

KidsNotification.defaultProps = {
  notifications: [],
  isEditChild: false,
};
