import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const CloseButton = styled(Button)`
  flex: 1;
`;

export const LogoutButton = styled(Button)`
  color: ${({ theme }) => theme.colors.mirage};
  flex: 1;
`;

export const SuccessTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
  margin-bottom: 0;
  padding: 0 25px;
`;

export const SuccessViewButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
`;

export const SuccessViewFooter = styled.div`
  text-align: center;
  padding: 20px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
