import { RootState } from '../configureStore';

import { recurringRemittanceSettings, remittancesZakatTypes } from './api';

export const selectRewardBrand = (state: RootState) => state.rewards.brand;

export const selectZakatTypeLabel = (state: RootState) => {
  const { data } = recurringRemittanceSettings.select()(state);
  const { data: zakatTypes } = remittancesZakatTypes.select()(state);

  const { zakat_type } = data || {};

  return zakatTypes?.find((zakatType) => zakatType.value === zakat_type)?.label;
};

export const selectRewardType = (state: RootState) => state.rewards.type;
