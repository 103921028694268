import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetText, WidgetTitle, WidgetWrapper } from '../styles';
import { WidgetButton } from '../components/WidgetButton';

import { MyFinanceWidgetWrapper, MyFinanceHeader } from './components/styles';
import MyFinanceWidgetBanner from './components/MyFinanceWidgetBanner';

import theme from 'theme';
import { MY_FINANCE_PAGE } from 'constants/comonConstants';

export default function MyFinanceWidget() {
  const { t } = useTranslation();

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('myFinanceWidget.title')}</WidgetTitle>

      <MyFinanceWidgetWrapper>
        <MyFinanceWidgetBanner />

        <MyFinanceHeader>{t('myFinanceWidget.header')}</MyFinanceHeader>

        <WidgetText>{t('myFinanceWidget.dailySpendingLabel')}</WidgetText>

        <WidgetButton
          href={MY_FINANCE_PAGE}
          text={t('myFinanceWidget.button')}
          background={theme.colors.whiteTransparent}
          hasBorder
        />
      </MyFinanceWidgetWrapper>
    </WidgetWrapper>
  );
}
