import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useTermsFileUrl } from './useTermsFileUrl';

import { getRequiredDocuments } from 'store/user/actions';
import { selectTermsDocument } from 'store/user/selectors';
import { PATHS } from 'constants/paths';

export const useTerms = () => {
  const dispatch = useDispatch();
  const termsDocument = useSelector(selectTermsDocument);
  const { t } = useTranslation();
  const isSuperPds = useMatch(PATHS.migration.account.superPds);

  const title = isSuperPds
    ? t('registrationPage.pds.superTitle')
    : t('registrationPage.pds.title');

  const { name } = termsDocument;
  const url = useTermsFileUrl();

  useEffect(() => {
    dispatch(getRequiredDocuments());
  }, [dispatch]);

  return { url, name, title };
};
