import { PROVIDER_TYPES, YODLEE_API_URL } from './consts';
import { IYodleeFastLinkData } from './types';

import { IBankAccountData } from 'store/fundingAccount/types';
import { api } from 'store/api';

export const yodlee = api.injectEndpoints({
  endpoints: (build) => ({
    yodleeFastLink: build.query<IYodleeFastLinkData, void>({
      query: () => ({
        url: `${YODLEE_API_URL}/fastlink`,
      }),
    }),
    yodleeProviderAccounts: build.mutation<
      IBankAccountData,
      {
        institutionId?: string;
        providerAccountId: string;
        type: keyof typeof PROVIDER_TYPES;
      }
    >({
      query: ({ institutionId, providerAccountId, type }) => ({
        url: `${YODLEE_API_URL}/provider_accounts`,
        method: 'POST',
        data: {
          id: institutionId,
          provider_account_id: providerAccountId,
          type,
        },
      }),
    }),
  }),
  overrideExisting: true,
});
export const { useYodleeFastLinkQuery, useYodleeProviderAccountsMutation } =
  yodlee;

export const {
  endpoints: { yodleeFastLink, yodleeProviderAccounts },
} = yodlee;
