import styled from '@emotion/styled';

import bannerBackground from 'assets/images/icons/referrals/banner.svg';
import { Button } from 'components/elements/Button';
import { Icon } from 'components/elements/Icon';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 22px;
  padding: 40px 0 32px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: url(${`${bannerBackground}`}) left 10px top -10px no-repeat,
    ${({ theme }) => theme.gradients.vibrantVerdant};
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['4lg']};
    font-family: ${theme.fontFamily.textRegular};
    line-height: ${theme.lineHeights.length.md};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.tighter};
  `}
  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Value = styled.div`
  margin: 0 30px;
`;

export const SubTitle = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.textRegular};
    line-height: ${theme.lineHeights.length.md};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tight};
    opacity: ${theme.opacity.semiTransparent};
  `}
  margin-bottom: 8px;
`;

export const Amount = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['5md']};
    font-family: ${theme.fontFamily.regular};
    line-height: ${theme.lineHeights.length.lg};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.tighter};
  `}
  margin-bottom: 24px;
`;

export const FilterIcon = styled(Icon)`
  margin-right: 10px;
`;

export const Filter = styled(Button)`
  ${({ theme }) => `
    background: ${theme.colors.whiteTransparent};
    border: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};
  `}
  padding: 10px 130px;
  display: flex;
  width: auto;
`;

export const HelpIcon = styled.div`
  position: absolute;
  top: 22px;
  right: 28px;
`;
