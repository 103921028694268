import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function CardRemoveConfirmModal({
  isShowConfirmationModal,
  closeConfirmationModal,
  removeCard,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirmationModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">
          {t('cardRemoveConfirmModal.areYouSure')}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            title={t('common.cancel')}
            type="button"
            buttonClass="wait"
            handleClick={closeConfirmationModal}
          />

          <DeprecatedButton
            title={t('common.confirm')}
            type="button"
            buttonClass="skip"
            handleClick={removeCard}
          />
        </div>
      </div>
    </Modal>
  );
}

CardRemoveConfirmModal.propTypes = {
  closeConfirmationModal: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
};
