import {
  ISpendingAccountsResponse,
  ITransformedSpendingAccountsResponse,
} from './types';

export const initialMappedAccount: ITransformedSpendingAccountsResponse = {
  visaWithoutBank: [],
  banks: [],
};

export const transformSpendingAccountsResponse = (
  data: ISpendingAccountsResponse,
): ITransformedSpendingAccountsResponse =>
  data.reduce((mappedAccount, currentAccount) => {
    if (currentAccount.id === null) {
      return {
        ...mappedAccount,
        visaWithoutBank: currentAccount.accounts.visa,
      };
    }

    return {
      ...mappedAccount,
      banks: [...mappedAccount.banks, currentAccount],
    };
  }, initialMappedAccount);
