import React from 'react';
import '../../styles/pages/my-finance.scss';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { MonthlyTracker } from './components/MonthlyTracker';
import Categorisation from './components/Categorisation';
import PotentialCash from './components/PotentialCash';
import WhereYouSpend from './components/WhereYouSpend';
import ReceiveNotification from './components/ReceiveNotification';

import { formatNumber } from 'utils/formatters/formatNumber';

function MyFinanceView(props) {
  const {
    monthlySpending,
    averageSpending,
    transactionCategorisation,
    showLoader,
    categoriesList,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="super__content page-content">
      <div className="page-content__full">
        <div className="banner-background__container active">
          <div className="my-finance__banner page-content__banner">
            <div className="page-content__banner-title">
              {t('myFinancePage.title')}
            </div>

            <div className="my-finance__banner-row">
              <div className="my-finance__banner-col">
                <div className="my-finance__banner-label">
                  <Trans
                    i18nKey="myFinancePage.spendingThisMonth.label"
                    t={t}
                  />
                </div>

                <div className="my-finance__banner-val">
                  {formatNumber({
                    value: monthlySpending,
                  })}
                </div>
              </div>

              <div className="my-finance__banner-col">
                <div className="my-finance__banner-label">
                  <Trans
                    i18nKey="myFinancePage.averageMonthlySpending.label"
                    t={t}
                  />
                </div>

                <div className="my-finance__banner-val">
                  {formatNumber({
                    value: averageSpending,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content__dashboard">
          <MonthlyTracker
            monthlySpending={monthlySpending}
            averageSpending={averageSpending}
          />

          <PotentialCash />
        </div>

        <div className="page-content__dashboard">
          <div className="page-content__dashboard-col">
            <WhereYouSpend />

            <ReceiveNotification />
          </div>

          <div className="page-content__dashboard-col">
            <Categorisation
              categoriesList={categoriesList}
              showLoader={showLoader}
              transactionCategorisation={transactionCategorisation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFinanceView;

MyFinanceView.defaultProps = {
  transactionCategorisation: [],
  categoriesList: [],
};

MyFinanceView.propTypes = {
  categoryTotals: PropTypes.shape({}).isRequired,
  transactionCategorisation: PropTypes.arrayOf(PropTypes.shape({})),
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  monthlySpending: PropTypes.number.isRequired,
  showLoader: PropTypes.bool.isRequired,
  averageSpending: PropTypes.number.isRequired,
};
