import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { UploadBirthCertificateModal } from '../UploadBirthCertificateModal';

import { Wrapper, Icon, Content, UploadButton } from './styles';

export const BirthCertificateAlert = () => {
  const { t } = useTranslation();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  return (
    <Wrapper>
      <Icon />

      <Content>
        {t('raizKidsEditPage.birthCertificateAlert.description')}
      </Content>

      <UploadButton
        label={t('raizKidsEditPage.birthCertificateAlert.button')}
        onClick={() => setIsUploadModalOpen(true)}
        variant="initial"
      />

      <UploadBirthCertificateModal
        isOpen={isUploadModalOpen}
        onRequestClose={() => setIsUploadModalOpen(false)}
      />
    </Wrapper>
  );
};
