export const MY_APP = process.env.REACT_APP_CODE_BASE_VERSION === 'mys';
export const AU_APP = process.env.REACT_APP_CODE_BASE_VERSION === 'aus';

export const SUPER_ON = AU_APP;
export const INVITE_FRIENDS_ON = AU_APP || MY_APP;
export const KIDS_ON = AU_APP || MY_APP;
// TODO: https://acornsau.atlassian.net/browse/RAIZ-5391
export const ROUND_UP_AUTO_ON = AU_APP;
export const ROUND_UP_MANUAL_ON = AU_APP;
export const ROUND_UP_WHOLE_DOLLAR_ON = AU_APP;
export const ROUND_UP_AUTO_APPROVE_DESC_ON = AU_APP || MY_APP;
export const STATEMETNS_TAX_ON = AU_APP;
export const STATEMENTS_ON = AU_APP || MY_APP;
export const MULTI_LANG = MY_APP;
