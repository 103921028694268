import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueSubtitle, LabelSub } from '../styles';

import { formatNumber } from 'utils/formatters/formatNumber';

interface IManualRoundUpsViewProps {
  user: {
    round_ups: {
      waiting: number;
    };
  };
}

export const ManualRoundUpsView = ({ user }: IManualRoundUpsViewProps) => {
  const { t } = useTranslation();
  const roundUpsValue = formatNumber({ value: user?.round_ups?.waiting ?? 0 });

  return (
    <>
      <ValueSubtitle>{roundUpsValue}</ValueSubtitle>

      <LabelSub>{t('roundUps.tapToInvest')}</LabelSub>
    </>
  );
};
