import styled from '@emotion/styled';

import { COVERAGE_INFO_MAX_WIDTH } from './consts';

import { Button } from 'components/elements/Button';
import { PortfolioCustomizationHeaderBackgroundImage } from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationHeader/styles';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';

export const SuperInsuranceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SuperInsuranceBannerWrapper = styled.div`
  border-radius: 12px;
  padding: 58px 0 106px;
  margin-top: 20px;
  background: ${({ theme }) => theme.gradients.purple};
  overflow: hidden;
  position: relative;
`;

export const SuperInsuranceBannerTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  padding-top: 48px;
`;

export const SuperInsuranceHeaderBackgroundImage = styled(
  PortfolioCustomizationHeaderBackgroundImage,
)`
  transform: rotate(-35deg);
  margin-top: -136px;
  opacity: 0.6;
  background-size: auto;
`;

export const SuperInsuranceContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    flex-direction: column;
  }
  gap: 12px;
`;

export const InsuranceDefaultCoverWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.lightPanel};
  background: ${({ theme }) => theme.colors.white};
  margin-top: 16px;
  flex: 0.5;
  border-radius: 12px;
  text-align: left;
`;

export const InsuranceInfoWrapper = styled(InsuranceDefaultCoverWrapper)`
  padding: 16px 0 6px;
`;

export const CoverHeader = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.navyBlue};
  border-radius: 12px 12px 0 0;
  padding: 10px 16px;
`;

export const CoverAmountWrapper = styled.div`
  display: flex;
  padding: 13px 16px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  :last-of-type {
    border: none;
  }
`;

export const CoverAmountLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const CoverAmount = styled.div`
  padding-left: 6px;
`;

export const InsuranceStatusWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 0 16px 16px;
`;

export const InsuranceStatusText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.8;
`;

export const InsuranceStatusLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding-top: 8px;
`;

interface IInsuranceStatusButton {
  isOptedOrRequested?: boolean;
}
export const InsuranceStatusButton = styled(Button)<IInsuranceStatusButton>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ isOptedOrRequested, theme }) =>
    isOptedOrRequested ? theme.colors.grenadier : theme.colors.niagaraLight};
  flex: 0.2;
`;

export const CoverageOfferedWrapper = styled.div`
  display: flex;
  padding: 14px 16px 12px;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  flex-direction: column;
`;

export const CoverageOfferedLabel = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-bottom: 2px;
`;

export const CoverageOfferedValue = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
`;

export const InsuranceCoverageInfoWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 14px 16px 16px;
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  :last-of-type {
    border: none;
  }
`;

export const CoverageInfo = styled.div`
  max-width: ${COVERAGE_INFO_MAX_WIDTH}px;
`;

export const Link = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.niagaraLight};
`;
