import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { usePortfolio } from '../../hooks';
import { usePortfolioSearchParams } from '../../hooks/usePortfolioSearchParams';
import { usePortfolioLocation } from '../../hooks/usePortfolioLocation';

import PortfolioCustomizationHeader from './components/PortfolioCustomizationHeader/PortfolioCustomizationHeader';
import { PortfolioCustomizationWrapper } from './styles';
import { usePortfolioCustomizationRoute } from './hooks';

import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import { SuperRegistrationBanner } from 'pages/Super/components/SuperRegistration/components/SuperRegistrationBanner';
import { usePreventPageLeave } from 'hooks/usePreventPageLeave';
import { setFundsToUpdate } from 'store/portfolio/actions';
import { selectFundsToUpdate } from 'store/portfolio/selectors';
import KidPortfolioHeader from 'pages/Portfolio/components/KidPortfolioHeader/KidPortfolioHeader';

export default function PortfolioCustomization() {
  const dispatch = useDispatch();

  usePortfolioSearchParams();

  usePortfolioLocation();

  const { isRaizKids } = usePortfolio();
  const { isChangeBasePortfolioPage } = usePortfolioCustomizationRoute();

  const fundsToUpdate = useSelector(selectFundsToUpdate);

  const isSuperRegistration = useIsSuperRegistration();

  const onPortfolioCustomizationLeave = () => dispatch(setFundsToUpdate([]));

  usePreventPageLeave({
    isBlocked: fundsToUpdate?.length > 0,
    onPageLeaveConfirmed: onPortfolioCustomizationLeave,
  });

  return (
    <>
      {isRaizKids && <KidPortfolioHeader />}

      {isSuperRegistration && <SuperRegistrationBanner />}

      <PortfolioCustomizationWrapper>
        {!isChangeBasePortfolioPage && <PortfolioCustomizationHeader />}

        <Outlet />
      </PortfolioCustomizationWrapper>
    </>
  );
}
