export const REWARDS_FILTER_VALUE = {
  all: 'all',
  waiting: 'waiting',
  invested: 'invested',
  pending: 'pending',
  failed: 'failed',
} as const;

export const REWARDS_FILTER_VALUE_UNFULFILLED = {
  all: 'all',
  fulfilled: 'fulfilled',
  pending: 'pending',
  failed: 'failed',
} as const;

export const REWARDS_ACTIVE_CATEGORY_ID = 999;
