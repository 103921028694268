// TODO: Refactor migration to RTK Query - https://acornsau.atlassian.net/browse/RAIZ-5583

import { ACTIONS_TYPE } from './consts';
import { IMigrationReducer } from './types';

import { REQUEST_STATUS } from 'constants/requestStatus';

const initialState = {
  setNewPassword: REQUEST_STATUS.INITIAL,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const migration: IMigrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPE.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setNewPassword: REQUEST_STATUS.SUCCESS,
      };
    case ACTIONS_TYPE.SET_NEW_PASSWORD_ERROR:
      return {
        ...state,
        setNewPassword: REQUEST_STATUS.ERROR,
      };
    case ACTIONS_TYPE.SET_NEW_PASSWORD_LOADING:
      return {
        ...state,
        setNewPassword: REQUEST_STATUS.LOADING,
      };
    case ACTIONS_TYPE.RESET_SET_NEW_PASSWORD:
      return {
        ...state,
        setNewPassword: REQUEST_STATUS.INITIAL,
      };
    default:
      return state;
  }
};

export default migration;
