import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';

const linkButtonStyle = ({ theme, background, hasBorder }) =>
  css`
    margin: 16px auto 0;
    max-width: 335px;
    width: 100%;
    height: 44px;
    line-height: ${theme.lineHeights.length['5xl']};
    text-align: center;
    display: block;
    border-radius: 21px;
    background: ${background || theme.colors.niagara};
    border: ${hasBorder ? `1px solid ${theme.colors.white};` : 'none'};

    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textRegular};

    font-size: ${theme.fontSizes['5sm']};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: ${theme.letterSpacing.tighter};

    :hover {
      color: ${theme.colors.white};
    }
  `;

export const WidgetExternalLinkButtonWrapper = styled(ExternalLink, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'hasBorder',
})`
  ${linkButtonStyle}
`;

export const WidgetLinkButtonWrapper = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'hasBorder',
})`
  ${linkButtonStyle}
`;
