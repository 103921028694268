import CloseBlackIcon from 'assets/images/icons/close-black.svg';
import VisaAddIcon from 'assets/images/icons/visa-add.svg';
import BankAccountIcon from 'assets/images/icons/bank-account.svg';
import MastercardIcon from 'assets/images/icons/mastercard.svg';
import WarningIcon from 'assets/images/icons/warning.svg';
import AddIcon from 'assets/images/icons/add.svg';
import MailIcon from 'assets/images/icons/ic_mail.svg';
import VisaAlternativeIcon from 'assets/images/icons/visa-alternative.svg';
import HelpPng from 'assets/images/contact-us.png';
import InfoSvg from 'assets/images/icons/info_icon.svg';
import QuestionIcon from 'assets/images/icons/question.svg';
import QuestionBlackIcon from 'assets/images/icons/questionBlack.svg';
import RoundUpsIcon from 'assets/images/icons/ic_roundups.svg';
import SearchIcon from 'assets/images/icons/search-icon.svg';
import CloseWhiteIcon from 'assets/images/icons/close-wh.svg';
import ShieldIcon from 'assets/images/icons/shield.svg';
import EditSvg from 'assets/images/icons/edit.svg';
import SortPng from 'assets/images/icons/sort.png';
import FilterListIcon from 'assets/images/icons/ic_filter_list_white.svg';
import ArrowDownIcon from 'assets/images/icons/rewards-arrow-bottom.svg';
import CheckIcon from 'assets/images/icons/check.svg';
import CheckboxCheckIcon from 'assets/images/ic_check_login.png';
import SecuredIcon from 'assets/images/icons/ic_lock_outline.png';
import IcInfoIcon from 'assets/images/icons/ic_info_outline.png';
import XLogoIcon from 'assets/images/icons/x-logo.svg';
import XLogoCircleIcon from 'assets/images/icons/x-logo-circle.svg';
import EmptyIcon from 'assets/images/icons/ic_empty.svg';
import ClockIcon from 'assets/images/icons/clock.svg';
import OpenedEyeIcon from 'assets/images/icons/view-eye.svg';
import ClosedEyeIcon from 'assets/images/icons/hide-eye.svg';
import MoneyInvestIcon from 'assets/images/icons/money-invest.svg';
import MoneyInvestGreenIcon from 'assets/images/icons/money-invest-green.svg';
import MoneyCircleIcon from 'assets/images/icons/money-circle.svg';
import SuperIcon from 'assets/images/icons/super.svg';
import PersonIcon from 'assets/images/icons/person-icon.svg';
import RollArrowIcon from 'assets/images/icons/roll-arrow.svg';
import PortfolioIcon from 'assets/images/icons/portfolio_icon.svg';
import RewardIcon from 'assets/images/icons/reward.svg';
import RecurringIcon from 'assets/images/icons/recurring.svg';
import StatementsIcon from 'assets/images/icons/ic_statm.svg';
import SuperInsuranceIcon from 'assets/images/icons/super-insurance-icon.svg';
import DividendsIcon from 'assets/images/icons/dividends.svg';
import GoalFlagIcon from 'assets/images/icons/goal_flag.svg';
import GearIcon from 'assets/images/icons/gear.svg';
import AddGreenPng from 'assets/images/icons/ic_add_copy-ic_add.png';
import DataSharingIcon from 'assets/images/icons/ic_datasharing.svg';
import DefaultBankIcon from 'assets/images/icons/bank-icon.svg';
import HelpWhiteIcon from 'assets/images/icons/help-white.svg';
import InfoWhiteIconSvg from 'assets/images/icons/info-icon.svg';
import QuestionGreenIconSvg from 'assets/images/icons/menu-support.svg';
import RewardInactiveSvg from 'assets/images/icons/reward-inactive.svg';
import PlaceholderSvg from 'assets/images/icons/placeholder-fund.svg';
import LikeActiveSvg from 'assets/images/icons/like-active.svg';
import LikeInactiveSvg from 'assets/images/icons/like-inactive.svg';
import RewardBankSvg from 'assets/images/icons/reward-bank.svg';
import SearchIconWhiteSvg from 'assets/images/icons/search-icon-white.svg';
import TrashSvg from 'assets/images/icons/rewards-remove-card.svg';
import VisaSvg from 'assets/images/icons/visa.svg';
import CardWarningSvg from 'assets/images/icons/card-warning.svg';
import InfoGreyIcon from 'assets/images/icons/info-grey.svg';
import BankGreenIcon from 'assets/images/icons/fundingacc.svg';
import RewardCardGreenIcon from 'assets/images/icons/reward-card-green.svg';

export const iconset = {
  close: CloseBlackIcon,
  visa: VisaAddIcon,
  bankAccount: BankAccountIcon,
  masterCard: MastercardIcon,
  warning: WarningIcon,
  add: AddIcon,
  mail: MailIcon,
  visaAlt: VisaAlternativeIcon,
  help: HelpPng,
  info: InfoSvg,
  question: QuestionIcon,
  questionBlack: QuestionBlackIcon,
  roundUps: RoundUpsIcon,
  searchIcon: SearchIcon,
  closeWhite: CloseWhiteIcon,
  shield: ShieldIcon,
  edit: EditSvg,
  sort: SortPng,
  filterListWhite: FilterListIcon,
  arrowDown: ArrowDownIcon,
  check: CheckIcon,
  checkboxCheck: CheckboxCheckIcon,
  secured: SecuredIcon,
  infoOutlined: IcInfoIcon,
  xLogo: XLogoIcon,
  XLogoCircle: XLogoCircleIcon,
  empty: EmptyIcon,
  clock: ClockIcon,
  openedEye: OpenedEyeIcon,
  closedEye: ClosedEyeIcon,
  moneyInvest: MoneyInvestIcon,
  moneyInvestGreen: MoneyInvestGreenIcon,
  moneyCircle: MoneyCircleIcon,
  super: SuperIcon,
  person: PersonIcon,
  rollArrow: RollArrowIcon,
  portfolio: PortfolioIcon,
  reward: RewardIcon,
  recurring: RecurringIcon,
  statements: StatementsIcon,
  superInsurance: SuperInsuranceIcon,
  goalFlag: GoalFlagIcon,
  dividends: DividendsIcon,
  gear: GearIcon,
  addGreen: AddGreenPng,
  dataSharing: DataSharingIcon,
  defaultBank: DefaultBankIcon,
  helpWhite: HelpWhiteIcon,
  infoWhite: InfoWhiteIconSvg,
  questionGreen: QuestionGreenIconSvg,
  rewardInactive: RewardInactiveSvg,
  placeholder: PlaceholderSvg,
  likeActive: LikeActiveSvg,
  likeInactive: LikeInactiveSvg,
  rewardBank: RewardBankSvg,
  searchIconWhite: SearchIconWhiteSvg,
  trash: TrashSvg,
  visaCard: VisaSvg,
  cardWarning: CardWarningSvg,
  infoGrey: InfoGreyIcon,
  bankGreen: BankGreenIcon,
  rewardCardGreen: RewardCardGreenIcon,
} as const;

export type IIconSet = keyof typeof iconset;
