import { GET_GIFTS_ICONS_SUCCESS, SHOW_ERROR_GET_GIFTS_ICONS } from './types';

const initialState = {
  gifts: {},
  receiverIcons: {},
};

export default function gifts(state = initialState, action) {
  switch (action.type) {
    case GET_GIFTS_ICONS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        receiverIcons: action.receiverIcons,
      };
    case SHOW_ERROR_GET_GIFTS_ICONS:
    default:
      return state;
  }
}
