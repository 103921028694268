import { useTranslation } from 'react-i18next';

import { StatusCanceled, StatusHandled, StatusCreated } from './styles';

import { useVerificationStatusQuery } from 'store/fundingAccount/fundingAccount';
import { VERIFICATION_STATUS } from 'store/fundingAccount/consts';

export const Status = () => {
  const { t } = useTranslation();
  const { data } = useVerificationStatusQuery();

  const status = data?.status;

  if (status === VERIFICATION_STATUS.handled) {
    return <StatusHandled>{t('common.status.completed')}</StatusHandled>;
  }

  if (status === VERIFICATION_STATUS.canceled) {
    return <StatusCanceled>{t('common.status.canceled')}</StatusCanceled>;
  }

  return <StatusCreated>{t('common.status.pending')}</StatusCreated>;
};
