import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import { PATHS } from 'constants/paths';
import { useCreateRefreshAccountMutation } from 'store/superAnnuation/superAnnuation';

export const useSuperRefreshAccountErrorModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [createRefreshAccount] = useCreateRefreshAccountMutation();

  const onModalClose = useCallback(() => {
    navigate(PATHS.super.registration);
  }, [navigate]);

  useEffect(() => {
    createRefreshAccount()
      .unwrap()
      .catch((error) => {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: error?.data?.error,
              onAccept: onModalClose,
              onReject: onModalClose,
            },
          }),
        );
      });
  }, [createRefreshAccount, dispatch, navigate, onModalClose]);
};
