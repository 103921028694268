import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['6md']};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.semiBold};
  `}
  padding: 5px 25px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
`;

export const Switcher = styled.button`
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.alto};
  padding: 3px;
  height: unset;
  display: flex;
`;

export const SwitchButton = styled.div<{ isActive?: boolean }>`
  ${({ theme, isActive }) => `
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.semiBold};
    background-color: ${
      isActive ? theme.colors.white : theme.colors.transparent
    };
  `}
  border-radius: 15px;
  padding: 5px 25px;
  transition: 0.4s;
`;
