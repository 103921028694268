import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Label, ContainerItem } from '../styles';

import { ManualRoundUpsView } from './ManualRoundUpsView';

import { ROUND_UP_MANUAL_ON } from 'constants/togglesFunctions';
import ProgressInfo from 'components/elements/ProgressInfo/ProgressInfo';
import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { COMPLEMENT_CREDIT, MAX_INVEST } from 'constants/comonConstants';
import { getUserData, sendInvests } from 'store/user/actions';
import { RootState } from 'store/configureStore';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface IManualRoundUpsViewProps {
  setIsConfirmModalOpen: (isOpen: boolean) => void;
  accessInvesting: boolean;
  toggleKidsAccessRejectionModal: (isOpen: boolean) => void;
  isLoading: boolean;
}

export const ManualRoundUps = ({
  setIsConfirmModalOpen,
  accessInvesting,
  toggleKidsAccessRejectionModal,
  isLoading,
}: IManualRoundUpsViewProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { user } = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const handleInvest = (isInvestment = false) => {
    const data = {
      investment: {
        amount: MAX_INVEST - user.round_ups.waiting,
        type: COMPLEMENT_CREDIT,
      },
    };

    if (user.round_ups.waiting > 0 || isInvestment) {
      dispatch(sendInvests(data)).then(() => {
        dispatch(getUserData());

        setIsConfirmModalOpen(false);
      });
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  return (
    ROUND_UP_MANUAL_ON && (
      <div>
        <Label>{t('roundUps.manualRound')}</Label>

        <ContainerItem
          type="button"
          onClick={() => {
            if (accessInvesting) {
              handleInvest(false);
            } else {
              toggleKidsAccessRejectionModal(true);
            }
          }}
          tabIndex={0}
        >
          <ProgressInfo value={user.round_ups.waiting} maxValue={5}>
            {isLoading ? (
              <DeprecatedDotsLoader additionalClass="btn" />
            ) : (
              <ManualRoundUpsView user={user} />
            )}
          </ProgressInfo>
        </ContainerItem>
      </div>
    )
  );
};
