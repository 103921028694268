import Firebase from 'firebase/app';
import { Dispatch } from 'redux';

import { SET_FIREBASE_CONFIG } from './consts';
import { IFirebaseConfig } from './types';

import getFirebaseConfigForKey from 'utils/firebase/getFirebaseConfigForKey';

const setFirebaseConfig = (firebaseConfig: IFirebaseConfig) => ({
  type: SET_FIREBASE_CONFIG,
  firebaseConfig,
});

export const getFirebaseConfig = () => {
  const remoteConfig = Firebase.remoteConfig();

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 60000,
    fetchTimeoutMillis: 60000,
  };

  return async (dispatch: Dispatch) => {
    try {
      await remoteConfig.fetchAndActivate();

      const featuresConfig = getFirebaseConfigForKey({
        key: 'features',
        remoteConfig,
      });

      const supportConfig = getFirebaseConfigForKey({
        key: 'support',
        remoteConfig,
      });

      const paymentsConfig = getFirebaseConfigForKey({
        key: 'payments',
        remoteConfig,
      });

      dispatch(
        setFirebaseConfig({
          featuresConfig,
          supportConfig,
          paymentsConfig,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };
};
