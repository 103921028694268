import * as yup from 'yup';

export const useRequestFormSchema = () =>
  yup.object({
    answer: yup.string().required(),
    primaryReason: yup.array(
      yup.object({
        value: yup.string().required(),
      }),
    ),
    secondaryReason: yup.array(
      yup.object({
        value: yup.string().required(),
      }),
    ),
  });
