import { useTranslation } from 'react-i18next';

import { WidgetTitle, WidgetWrapper } from '../styles';
import { WidgetButton } from '../components/WidgetButton';

import { Content } from './styles';

import { PATHS } from 'constants/paths';
import { DividendsBannerSummary } from 'pages/Dividends/components/DividendsBanner/components/DividendsBannerSummary';

export const DividendsWidget = () => {
  const { t } = useTranslation();

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('dividendsPage.title')}</WidgetTitle>

      <Content>
        <DividendsBannerSummary />

        <WidgetButton href={PATHS.dividends.index} text={t('common.details')} />
      </Content>
    </WidgetWrapper>
  );
};
