export const DIVIDENDS_BASE_URL = '/v1/dividends';

export const FUND_TAGS = {
  etf: 'etf',
  stock: 'stock',
  btc: 'btc',
  rpf: 'rpf',
  rrpf: 'rrpf',
} as const;

export const SET_DIVIDENDS_FILTERED_FUNDS_SYMBOLS =
  'SET_DIVIDENDS_FILTERED_FUNDS_SYMBOLS';

export const UPCOMING_VALUE_STATUSES = {
  none: 'none',
  tbc: 'tbc',
  estimated: 'estimated',
};
