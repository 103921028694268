import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  GlobalInfoModalAcceptButton,
  GlobalInfoModalButtonClose,
  GlobalInfoModalContent,
  GlobalInfoModalTitle,
  GlobalInfoModalWrapper,
  GlobalInfoRejectButton,
  GlobalInfoModalButtonsWrapper,
} from './styles';

import { DEFAULT_INFO_MODAL_SIZE } from 'store/modals/consts';
import { selectInfoModal } from 'store/modals/selectors';
import { toggleInfoModal } from 'store/modals/actions';
import theme from 'theme';

export default function GlobalInfoModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: false,
      }),
    );
  };

  const { isInfoModalVisible, config } = useSelector(selectInfoModal);

  const {
    title,
    description,
    acceptButtonText,
    size,
    rejectButtonText,
    onAccept,
    onReject,
    isButtonHorizontal,
    isCloseButtonVisible = true,
    customStyles,
  } = {
    acceptButtonText: t('common.ok'),
    size: DEFAULT_INFO_MODAL_SIZE,
    ...config,
  };

  const handleOnAccept = () => {
    onAccept?.();

    closeModal();
  };

  const handleOnReject = () => {
    onReject?.();

    closeModal();
  };

  return (
    <GlobalInfoModalWrapper
      isOpen={isInfoModalVisible}
      onRequestClose={handleOnReject}
      size={size}
      shouldCloseOnOverlayClick={isCloseButtonVisible}
      style={{
        overlay: {
          zIndex: theme.zIndexes.infoModal,
        },
      }}
      customStyles={customStyles}
    >
      {isCloseButtonVisible && (
        <GlobalInfoModalButtonClose onClick={handleOnReject} />
      )}

      <GlobalInfoModalTitle>{title}</GlobalInfoModalTitle>

      <GlobalInfoModalContent>{description}</GlobalInfoModalContent>

      <GlobalInfoModalButtonsWrapper isButtonHorizontal={isButtonHorizontal}>
        {acceptButtonText && (
          <GlobalInfoModalAcceptButton onClick={handleOnAccept}>
            {acceptButtonText}
          </GlobalInfoModalAcceptButton>
        )}

        {rejectButtonText && (
          <GlobalInfoRejectButton onClick={handleOnReject}>
            {rejectButtonText}
          </GlobalInfoRejectButton>
        )}
      </GlobalInfoModalButtonsWrapper>
    </GlobalInfoModalWrapper>
  );
}
