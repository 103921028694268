import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';

export default function MarkerDistance({ markerDistance }) {
  const { t } = useTranslation();

  if (markerDistance < 1) {
    return (
      <span>
        {markerDistance * 1000}

        {t('common.metric.meter')}
      </span>
    );
  }

  return (
    <span>
      {parseFloat(markerDistance).toFixed(1)}

      {t('common.metric.kilometerShort')}
    </span>
  );
}

MarkerDistance.propTypes = {
  markerDistance: PropTypes.number.isRequired,
};
