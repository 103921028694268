import { IPortfolio } from 'store/user/types';

interface IGetUserPortfolioByIdProps {
  portfolios: IPortfolio[];
  id: string;
}

export const getUserPortfolioById = ({
  portfolios,
  id,
}: IGetUserPortfolioByIdProps) =>
  portfolios?.find((portfolio) => portfolio.id === id);
