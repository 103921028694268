import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router';

import { FundsTagItemWrapper, PercentageValue } from './styles';

import { usePortfolioNavigationParams } from 'pages/Portfolio/hooks/usePortfolioNavigationParams';
import { FUNDS_TAGS } from 'pages/Portfolio/subpages/PortfolioCustomization/consts';
import {
  selectBaseFundTag,
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function BaseFundTag() {
  const navigate = useNavigate();

  const { childId, search } = useAppSelector(selectPortfolioParams);

  const activePortfolio = useSelector(
    selectBaseFundTag({ isRaizKids: !!childId }),
  );

  const { name, id, allocation } = activePortfolio;

  const { isRegistration } = useAppSelector(selectPortfolioLocation);

  const { customizationTagsPath, pathParams } = usePortfolioNavigationParams();

  const openBasicPortfolioPlan = () => {
    navigate({
      pathname: generatePath(customizationTagsPath, {
        fundTag: FUNDS_TAGS.basePortfolio,
        ...(isRegistration && pathParams),
      }),
      search,
    });
  };

  return (
    <FundsTagItemWrapper
      onClick={openBasicPortfolioPlan}
      isBaseFundTag
      key={id}
    >
      {name}

      {/* Todo: use formatNumber after implement in this task https://acornsau.atlassian.net/browse/RAIZ-5293 */}
      <PercentageValue>{` ${allocation}%`}</PercentageValue>
    </FundsTagItemWrapper>
  );
}
