import { useSuperAccountInfoItems } from '../hooks/useSuperAccountInfoItems';

import { SuperAccountInfoItem } from './SuperAccountInfoItem';
import { BPayInfo } from './BPayInfo';
import { SuperAccountInfoSkeleton } from './SuperAccountInfoSkeleton/SuperAccountInfoSkeleton';

import {
  useSuperUserAccountsQuery,
  useSuperUserQuery,
} from 'store/superAnnuation/superAnnuation';

export const SuperInfoContent = () => {
  const superUserQuery = useSuperUserQuery();

  const superUserAccountsQuery = useSuperUserAccountsQuery();

  const { superInfoItems } = useSuperAccountInfoItems();

  if (superUserQuery?.isFetching || superUserAccountsQuery?.isFetching) {
    return <SuperAccountInfoSkeleton />;
  }

  return (
    <>
      {superInfoItems?.map(({ label, value }) => (
        <SuperAccountInfoItem
          key={window.crypto.randomUUID()}
          label={label}
          value={value}
        />
      ))}

      <BPayInfo />
    </>
  );
};
