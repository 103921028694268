type ICopyButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  copyValue: string;
};

export const CopyButton = ({
  children,
  copyValue,
  ...rest
}: ICopyButtonProps) => (
  <button
    type="button"
    {...rest}
    onClick={() => navigator.clipboard.writeText(copyValue)}
  >
    {children}
  </button>
);
