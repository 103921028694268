import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { IGetSendMailModalDescriptionsProps } from '../types';

import { APP_DATE_LOCALE, AU_APP, MY_APP } from 'constants/localeConfigs';
import { getSendMailModalKey } from 'pages/Statements/components/StatementsContent/utils';
import {
  DATE_FORMAT_MONTH_DAY_NUMBER,
  DATE_FORMAT_MONTH_YEAR,
} from 'config/date';
import { KIDS_TRADE_CONFIRMATION } from 'constants/kidsConstants';

export const useSendModalDescription = () => {
  const { t } = useTranslation();

  const getSendMailModalDescription = ({
    filteredStatements,
    userEmail,
    currentType,
  }: IGetSendMailModalDescriptionsProps) => {
    if (AU_APP) {
      return t('statements.sendDocumentDescription', { email: userEmail });
    }

    if (MY_APP) {
      const key = getSendMailModalKey({
        isSeveralStatementsSelected: filteredStatements.length > 1,
        currentType,
      });

      if (key) {
        const statementsDate = dayjs(filteredStatements[0].date)
          .locale(APP_DATE_LOCALE)
          .format(DATE_FORMAT_MONTH_YEAR);

        const tradeConfirmationDate = dayjs(filteredStatements[0].date)
          .locale(APP_DATE_LOCALE)
          .format(DATE_FORMAT_MONTH_DAY_NUMBER);

        return t(key, {
          date:
            currentType === KIDS_TRADE_CONFIRMATION
              ? tradeConfirmationDate
              : statementsDate,
          email: userEmail,
        });
      }
    }

    return '';
  };

  return { getSendMailModalDescription };
};
