export const LUMP_SUM_INVEST_SUCCESS = 'LUMP_SUM_INVEST_SUCCESS';
export const GET_SUPER_FUNDS_SUCCESS = 'GET_SUPER_FUNDS_SUCCESS';
export const SHOW_ERROR_IN_SUPER_MODAL = 'SHOW_ERROR_IN_SUPER_MODAL';
export const GET_SUPER_SETTINGS_SUCCESS = 'GET_SUPER_SETTINGS_SUCCESS';
export const SHOW_ERROR_LUMP_SUM_INVEST = 'SHOW_ERROR_LUMP_SUM_INVEST';
export const SAVE_SUPER_REGISTRATION_SUCCESS =
  'SAVE_SUPER_REGISTRATION_SUCCESS';
export const GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS =
  'GET_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS';
export const CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS =
  'CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER_SUCCESS';
export const SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER =
  'SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS_SUPER';
export const ROLLOVER_FUND_SUCCESS = 'ROLLOVER_FUND_SUCCESS';
export const BACK_ON_SUPER_REGISTRATION = 'BACK_ON_SUPER_REGISTRATION';
export const GO_TO_CONSENT = 'GO_TO_CONSENT';
export const SHOW_ERROR_UPDATE_TRANSFER_REWARDS =
  'SHOW_ERROR_UPDATE_TRANSFER_REWARDS';
export const UPDATE_TRANSFER_REWARDS_SUCCESS =
  'UPDATE_TRANSFER_REWARDS_SUCCESS';
export const SHOW_ERROR_GET_SUPER_SETTINGS = 'SHOW_ERROR_GET_SUPER_SETTINGS';
export const SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS_SUPER =
  'SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS_SUPER';
export const CONSENT_SUCCESS = 'CONSENT_SUCCESS';
export const SUPER_ANNUATION_BASE_URL = '/v1/super_annuation';
