import { defineMessages } from 'react-intl';

import { REGISTRATION as idPrefix } from '../../constants/translates';
import { MY_APP } from '../../constants/localeConfigs';

const auMessages = {
  title: {
    id: `${idPrefix}.title`,
    defaultMessage: 'Registration',
  },
  kidsSimplyWay: {
    id: `${idPrefix}.kidsSimplyWay`,
    defaultMessage:
      'Raiz Kids is a simple way to save and invest small amounts regularly for your ' +
      'children/grandchildren.' +
      'Setup guidelines to help each child manage their own account and gain important saving skills.',
  },
  personal: {
    id: `${idPrefix}.personal`,
    defaultMessage: 'Personal',
  },
  investments: {
    id: `${idPrefix}.investments`,
    defaultMessage: 'Investments',
  },
  account: {
    id: `${idPrefix}.account`,
    defaultMessage: 'Other',
  },
  weJust: {
    id: `${idPrefix}.weJust`,
    defaultMessage: 'We just need a few more things.',
  },
  portfolio: {
    id: `${idPrefix}.portfolio`,
    defaultMessage: 'Portfolio',
  },
  raizKids: {
    id: `${idPrefix}.raizKids`,
    defaultMessage: 'Raiz Kids',
  },
  next: {
    id: `${idPrefix}.next`,
    defaultMessage: 'Next',
  },
  back: {
    id: `${idPrefix}.back`,
    defaultMessage: 'Back',
  },
  infoTrue: {
    id: `${idPrefix}.infoTrue`,
    defaultMessage:
      'Each child may have a different portfolio performance, depending on when investments are made, ' +
      'and which portfolio option they are invested in.',
  },
  confirm: {
    id: `${idPrefix}.confirm`,
    defaultMessage:
      'Confirm that you agree to all the ' +
      '<a href="{terms}" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>',
  },
  fundingNeverCredit: {
    id: `${idPrefix}.fundingNeverCredit`,
    defaultMessage:
      'When your kids turn 18, you can still decide what you want to do with the funds as the funds ' +
      'belong to you.',
  },
  acknowledge: {
    id: `${idPrefix}.acknowledge`,
    defaultMessage:
      'It is possible for different children to have different values in their Raiz account.',
  },
  productDisclosure: {
    id: `${idPrefix}.productDisclosure`,
    defaultMessage:
      'Any tax events, including but not limited to Capital Gains Tax, are your responsibility.',
  },
  agreeUnderstand: {
    id: `${idPrefix}.agreeUnderstand`,
    defaultMessage:
      'There could be an increased risk of fraud and cyber risk by using Raiz Kids.',
  },
  chooseAvatar: {
    id: `${idPrefix}.chooseAvatar`,
    defaultMessage: 'Choose an avatar',
  },
  name: {
    id: `${idPrefix}.name`,
    defaultMessage: 'Name',
  },
  birthday: {
    id: `${idPrefix}.birthday`,
    defaultMessage: 'Date of Birth',
  },
  raizKidAccess: {
    id: `${idPrefix}.raizKidAccess`,
    defaultMessage: 'Raiz Kid Access',
  },
  decide: {
    id: `${idPrefix}.decide`,
    defaultMessage:
      'Decide if your Raiz Kid can access this sub-account.<br>' +
      'If you enable access you can choose which features are enabled and define ' +
      'limits & notifications',
  },
  allowRaizKid: {
    id: `${idPrefix}.allowRaizKid`,
    defaultMessage: 'Allow Raiz Kid to access',
  },
  kidEmail: {
    id: `${idPrefix}.kidEmail`,
    defaultMessage: 'Your Kid’s Email Address',
  },
  doNotAllow: {
    id: `${idPrefix}.doNotAllow`,
    defaultMessage: 'Do not allow Raiz Kid to access',
  },
  initialInvestAmount: {
    id: `${idPrefix}.initialInvestAmount`,
    defaultMessage: 'Initial Investment Amount',
  },
  howMuch: {
    id: `${idPrefix}.howMuch`,
    defaultMessage:
      'How much would you like to invest now to get your Raiz Kids started?',
  },
  invest: {
    id: `${idPrefix}.invest`,
    defaultMessage: 'Invest',
  },
  investmentByKid: {
    id: `${idPrefix}.investmentByKid`,
    defaultMessage: 'Investment by this Raiz Kid',
  },
  notAllowInvestment: {
    id: `${idPrefix}.notAllowInvestment`,
    defaultMessage: 'Do not allow investment',
  },
  allowUnlimited: {
    id: `${idPrefix}.allowUnlimited`,
    defaultMessage: 'Allow unlimited investments ',
  },
  allowWeekly: {
    id: `${idPrefix}.allowWeekly`,
    defaultMessage: 'Allow with a set weekly limit (min $5)',
  },
  oneTimeInvestMinimum: {
    id: `${idPrefix}.oneTimeInvestMinimum`,
    defaultMessage: '{minimum} Minimum',
  },
  weeklyLimit: {
    id: `${idPrefix}.weeklyLimit`,
    defaultMessage: 'Weekly investment limit',
  },
  pushNotifications: {
    id: `${idPrefix}.pushNotifications`,
    defaultMessage: 'Push Notifications',
  },
  notifyMe: {
    id: `${idPrefix}.notifyMe`,
    defaultMessage: 'Notify me...',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'Ok',
  },
  raizRecurring: {
    id: `${idPrefix}.raizRecurring`,
    defaultMessage: 'Recurring investment',
  },
  raizRecurringInvAmount: {
    id: `${idPrefix}.raizRecurringInvAmount`,
    defaultMessage: 'Recurring Inv Amount',
  },
  frequency: {
    id: `${idPrefix}.frequency`,
    defaultMessage: 'Frequency',
  },
  allowRecurring: {
    id: `${idPrefix}.allowRecurring`,
    defaultMessage: 'Allow your Raiz Kid to manage this Recurring Investment ',
  },
  allowRewards: {
    id: `${idPrefix}.allowRewards`,
    defaultMessage: 'Allow your Raiz Kid to use Raiz Rewards',
  },
  select: {
    id: `${idPrefix}.select`,
    defaultMessage: 'Select',
  },
  kidsAccess: {
    id: `${idPrefix}.kidsAccess`,
    defaultMessage: 'Raiz Kid Access',
  },
  allowPortfolio: {
    id: `${idPrefix}.allowPortfolio`,
    defaultMessage:
      'Allow your Raiz Kid to manage or change the portfolio selection ',
  },
  chooseAvatarWarning: {
    id: `${idPrefix}.chooseAvatarWarning`,
    defaultMessage: 'Please, choose an avatar!',
  },
  skip: {
    id: `${idPrefix}.skip`,
    defaultMessage: 'Skip',
  },
  transferIn: {
    id: `${idPrefix}.transferIn`,
    defaultMessage: 'Transfer In',
  },
  available: {
    id: `${idPrefix}.available`,
    defaultMessage: 'Available: {balance}',
  },
  transferMoneyFromAccount: {
    id: `${idPrefix}.transferMoneyFromAccount`,
    defaultMessage:
      'If you choose to transfer money from your Raiz Account to a Kids Account you may realise material gains or losses, if you believe they are material you should check with your tax advisor before proceeding.',
  },
  transferMoneyToAccount: {
    id: `${idPrefix}.transferMoneyToAccount`,
    defaultMessage:
      'If you choose to transfer money from a Kids Account to your Raiz Account you may realise material gains or losses, if you believe they are material you should check with your tax advisor before proceeding.',
  },
  transferAmount: {
    id: `${idPrefix}.transferAmount`,
    defaultMessage: 'Transfer Amount',
  },
  required: {
    id: `${idPrefix}.required`,
    defaultMessage: 'Transfer Amount is required',
  },
  areYouSureIn: {
    id: `${idPrefix}.areYouSureIn`,
    defaultMessage: 'Are you sure you want to transfer into your Kids account?',
  },
  areYouSureOut: {
    id: `${idPrefix}.areYouSureOut`,
    defaultMessage:
      'Are you sure you want to transfer out of your Kids account?',
  },
  congrats: {
    id: `${idPrefix}.congrats`,
    defaultMessage: 'Congrats!',
  },
  investmentWillTransferred: {
    id: `${idPrefix}.investmentWillTransferred`,
    defaultMessage:
      'Your investment will be transferred from your main account in 1-3 business days.',
  },
};

const myMessages = {};

const messages = MY_APP ? { ...auMessages, ...myMessages } : auMessages;

export default defineMessages(messages);
