import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  ACCOUNT_OPENNING_AGREEMENT,
  DISCLOSURE_STATEMENTS,
  DISCLOSURES,
  MY_APP,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
} from '../../../constants/localeConfigs';

class Legal extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="profile-content settings__content-default-container settings__support">
        <div className="profile-content__header">
          <div className="profile-content__title">
            {t('settingsPage.legal.title')}
          </div>
        </div>

        <div className="profile-content__container">
          <a
            href={TERMS_CONDITIONS}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.legal.links.platformAgreement')}
          </a>

          {MY_APP ? (
            <a
              href={ACCOUNT_OPENNING_AGREEMENT}
              className="page-menu__item"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('settingsPage.legal.links.accountOpeningAgreement')}
            </a>
          ) : (
            <a
              href={DISCLOSURE_STATEMENTS}
              className="page-menu__item"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('settingsPage.legal.links.productDisclosure')}
            </a>
          )}

          <a
            href={PRIVACY_POLICY}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.legal.links.privacyPolicy')}
          </a>

          <a
            href={DISCLOSURES}
            className="page-menu__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('settingsPage.legal.links.disclosures')}
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Legal);

Legal.propTypes = {
  t: PropTypes.func.isRequired,
};
