import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router';

import {
  getTransactionsHistory,
  getAccountSummary,
} from '../../../../store/history/actions';
import { HISTORY_STATUSES } from '../../../../pages/HistoryDetails/consts';

import HistoryView from './HistoryView';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function History() {
  const dispatch = useDispatch();
  const isSuper = Boolean(useMatch('/super/*'));

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.historyTransactionsOffset, 0);

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactionsTotalPages,
      0,
    );

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactionsStatus,
      HISTORY_STATUSES.unfinished,
    );

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.historyTransactions,
      JSON.stringify([]),
    );

    const historyParams = {
      isOffset: false,
      limit: 4,
      isSuper,
      areFailedTransactionsVisible: false,
      isKidsHistory: false,
      userId: null,
      offset: 0,
      historyStatus: HISTORY_STATUSES.unfinished,
    };

    dispatch(getTransactionsHistory(historyParams));

    dispatch(getAccountSummary(isSuper));
  }, [dispatch, isSuper]);

  return <HistoryView isSuper={isSuper} />;
}
