import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OfferSlider({ offer, saveInvested }) {
  const { t } = useTranslation();

  const {
    id,
    cta_urls,
    invested_value,
    background_image_url,
    campaign_owner_name,
    campaign_owner_image_url,
  } = offer;

  if (!cta_urls?.web) {
    return null;
  }

  return (
    <Link to={`/rewards/${id}`} onClick={() => saveInvested(invested_value)}>
      <div className="grid__carousel-item">
        <img src={background_image_url} alt="" className="-background" />

        <div className="grid__carousel-feature">
          {t('rewardsPage.brandsCarousel.featured')}
        </div>

        <div className="grid__carousel-info">
          <div className="grid__carousel-info-container">
            <div className="grid__carousel-info-icon">
              <img
                className="-logo"
                alt={campaign_owner_name}
                src={campaign_owner_image_url}
              />
            </div>

            <div className="grid__carousel-info-content">
              <div className="grid__carousel-info-name">
                {`  ${campaign_owner_name}`}
              </div>

              <div className="grid__carousel-info-action">
                {t('rewardsPage.brandsCarousel.investedPercentage', {
                  amount: invested_value,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

OfferSlider.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    cta_urls: PropTypes.string,
    invested_value: PropTypes.number,
    campaign_owner_name: PropTypes.string,
    background_image_url: PropTypes.string,
    campaign_owner_image_url: PropTypes.string,
  }).isRequired,
  saveInvested: PropTypes.func.isRequired,
};
