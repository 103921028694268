import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  HOME_ACTIVE_TAB,
  HOME_TABS,
  HOMEPAGE,
} from '../../../constants/comonConstants';

import HomeTabs from './HomeTabs';
import HomeBannerContent from './HomeBannerContent';
import { useActiveHelpCard } from './useActiveHelpCard';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function HomeBanner() {
  const [searchParams] = useSearchParams();
  const { toggleHelpCard } = useActiveHelpCard(HOMEPAGE);

  const activeTab = searchParams.get(HOME_ACTIVE_TAB);
  const isFutureTab = activeTab === HOME_TABS.future;

  const bannerClass = `page-content__banner home__banner ${
    isFutureTab ? '-future' : '-today'
  }`;

  return (
    <div className="banner-background__container">
      <div className={bannerClass}>
        <DeprecatedButton
          buttonClass="rewards__banner-info my-finance__banner-info"
          handleClick={() => toggleHelpCard(true)}
        />

        <HomeTabs />

        <HomeBannerContent />
      </div>
    </div>
  );
}
