import styled from '@emotion/styled';

import { Input, Check } from '../checkbox/styles';

import checkBoxCheckedIcon from 'assets/images/icons/radio-checked.svg';
import checkBoxUncheckedIcon from 'assets/images/icons/unchecked-check-box.svg';

export const RadioWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

export const RadioInput = styled(Input)`
  cursor: pointer;
  border: 0;
`;

export const RadioButton = styled(Check)<{ checked: boolean }>`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.transparent};
  background-image: ${({ checked }) =>
    checked ? `url(${checkBoxCheckedIcon})` : `url(${checkBoxUncheckedIcon})`};
  background-size: auto;
`;
