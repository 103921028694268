import { useTranslation } from 'react-i18next';

import { Wrapper, Title, SubTitle } from './styles';

import {
  IRoundupSources,
  ROUNDUP_SOURCES,
  IRoundupSourcesKey,
} from 'store/banks';

interface IRadioItemProps {
  source: IRoundupSources;
}

export const RadioLabel = ({ source }: IRadioItemProps) => {
  const { t } = useTranslation();

  const sourceKey = Object.keys(ROUNDUP_SOURCES)[
    Object.values(ROUNDUP_SOURCES).indexOf(source)
  ] as IRoundupSourcesKey;

  const title = t(`settingsPage.source.${sourceKey}.title`);

  return (
    <Wrapper>
      <Title>{title}</Title>

      {sourceKey === ROUNDUP_SOURCES.visa && (
        <SubTitle>{t(`settingsPage.source.${sourceKey}.subTitle`)}</SubTitle>
      )}
    </Wrapper>
  );
};
