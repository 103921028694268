import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import RDV from '../../RDV/RDV';
import { selectActiveHelpCard } from '../../../store/home/selectors';

import { useActiveHelpCard } from './useActiveHelpCard';

import { IdentityVerificationModal } from 'pages/Settings/components/IdentityVerificationModal';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsUserAlertOpen } from 'store/user/selectors';
import { ALERT_TYPES } from 'constants/alerts';
import { AU_APP } from 'constants/localeConfigs';
import RecurringModal from 'components/layout/Modals/RecurringModal';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import { HOMEPAGE } from 'constants/comonConstants';

export default function HomeModals({
  isSuspensionSuccessOpen,
  toggleSuspensionSuccess,
  isInfoOpen,
  toggleRecurringModal,
  currentInfo,
  isNewRewards,
}) {
  const { t } = useTranslation();
  const { toggleHelpCard } = useActiveHelpCard(HOMEPAGE);

  const { isRDVModalVisible, user, tips, features, activeHelpCard } =
    useSelector((state) => ({
      isRDVModalVisible: state.user.isRDVModalVisible,
      user: state.user.user || {},
      tips: state.spendingAnalytics.tips,
      features: state.user.features,
      activeHelpCard: selectActiveHelpCard(state),
    }));

  const areRewardsEnabled = AU_APP || features?.raizRewards?.enabled;

  const isAutoVerificationModalOpen = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.isAutoVerificationModalOpen,
  );

  const isAutoVerificationAlert =
    useAppSelector(selectIsUserAlertOpen(ALERT_TYPES.autoVerification)) &&
    !isAutoVerificationModalOpen;

  const [
    isAdditionalVerificationModalOpen,
    setIsAdditionalVerificationModalOpen,
  ] = useState(isAutoVerificationAlert);

  return (
    <div>
      {isRDVModalVisible && <RDV />}

      {isAdditionalVerificationModalOpen && (
        <IdentityVerificationModal
          isOpen={isAdditionalVerificationModalOpen}
          closeRequest={() => setIsAdditionalVerificationModalOpen(false)}
          isUserTarget
        />
      )}

      <RecurringModal
        isOpen={isSuspensionSuccessOpen}
        onRequestClose={() => toggleSuspensionSuccess(false)}
        handleClick={() => toggleSuspensionSuccess(false)}
        modalClassName="time-investment__modal -centered"
        modalContent={t('homePage.homeModals.depositSuspensionRemoved')}
        modalFooterClassName="-single-btn"
      />

      <RecurringModal
        isOpen={isInfoOpen}
        onRequestClose={() =>
          toggleRecurringModal({ isReccuringModalOpen: false })
        }
        handleClick={() =>
          toggleRecurringModal({ isReccuringModalOpen: false })
        }
        modalClassName="time-investment__modal"
        modalContent={currentInfo.msg}
        modalFooterClassName="-single-btn"
      />

      {activeHelpCard && (
        <HelpCardsModalAU
          rewardsEnable={areRewardsEnabled}
          isOpenHelpCards={!!activeHelpCard}
          currentPage={activeHelpCard}
          user={user}
          tips={tips}
          toggleHelpCard={toggleHelpCard}
          toggleSuspensionSuccess={toggleSuspensionSuccess}
          isNewRewards={isNewRewards}
        />
      )}
    </div>
  );
}

HomeModals.propTypes = {
  isSuspensionSuccessOpen: PropTypes.bool.isRequired,
  toggleSuspensionSuccess: PropTypes.func.isRequired,
  isInfoOpen: PropTypes.bool.isRequired,
  toggleRecurringModal: PropTypes.func.isRequired,
  currentInfo: PropTypes.shape({
    title: PropTypes.string,
    msg: PropTypes.string,
  }).isRequired,
  isNewRewards: PropTypes.bool.isRequired,
};
