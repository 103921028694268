import { useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import { DEFAULT_BLOG_URL } from 'constants/localeConfigs';
import { getBlogPosts, getContentfulBlogPosts } from 'store/user/actions';
import { selectBlogPosts } from 'store/user/selectors';
import { selectContentfulBlogConfig } from 'store/firebaseConfig/selectors';

export const useBlogPosts = () => {
  const dispatch = useDispatch();

  const { posts, isLoading } = useSelector((state: RootStateOrAny) => ({
    posts: selectBlogPosts(state),
    isLoading: state.user.isBlogPostsLoading,
  }));

  const contentfulBlogConfig = useSelector(selectContentfulBlogConfig);

  const { isContentfulBlogEnabled, blogURL } = contentfulBlogConfig || {};
  const blogUrl = (isContentfulBlogEnabled && blogURL) || DEFAULT_BLOG_URL;

  useEffect(() => {
    if (isContentfulBlogEnabled) {
      dispatch(getContentfulBlogPosts(contentfulBlogConfig));
    } else {
      dispatch(getBlogPosts());
    }
  }, [dispatch, contentfulBlogConfig, isContentfulBlogEnabled]);

  return {
    isLoading,
    posts,
    blogUrl,
  };
};
