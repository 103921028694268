import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';

import { AU_APP } from '../../../../../constants/localeConfigs';

import UploadIdContent from './UploadIdContent/UploadIdContent';

import { ALLOWED_UPLOAD_IMAGES_EXTENSIONS } from 'constants/fileExtensions';

export const IdDocumentUploader = ({ type, handleChangeStatus }) => (
  <Dropzone
    onChangeStatus={({ file }, status) =>
      handleChangeStatus({ file, status, side: type })
    }
    accept={
      AU_APP
        ? `${ALLOWED_UPLOAD_IMAGES_EXTENSIONS}, application/pdf`
        : ALLOWED_UPLOAD_IMAGES_EXTENSIONS
    }
    maxFiles={1}
    classNames={{ dropzone: 'registration-page__dropzone -centered' }}
    canCancel
    canRestart
    inputContent={<UploadIdContent documentType={type} />}
    canRemove
  />
);

IdDocumentUploader.propTypes = {
  type: PropTypes.string.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
};
