import React from 'react';
import PropTypes from 'prop-types';

import { KidCardImage } from './styles';

function KidImage({ iconUrl, isClosed }) {
  return <KidCardImage src={iconUrl} alt="" isClosed={isClosed} />;
}

export default KidImage;

KidImage.defaultProps = {
  isClosed: false,
};

KidImage.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  isClosed: PropTypes.bool,
};
