import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SelectReact from 'react-dropdown-select';

const renderTextField = ({
  input,
  values,
  onChange,
  options,
  labelField,
  clearable,
  meta: { touched, error },
  onDropdownOpen,
  // omit values from custom
  label: _label,
  ...custom
}) => (
  <div className="relative">
    <SelectReact
      labelField={labelField}
      errorText={touched && error}
      searchable={false}
      clearable={clearable}
      onChange={(val) => {
        input.onChange(val);

        onChange(val);
      }}
      options={options}
      values={values}
      {...custom}
      readonly
      onDropdownOpen={onDropdownOpen}
    />

    {touched && error && <div className="-error">{touched && error}</div>}
  </div>
);

renderTextField.defaultProps = {
  label: null,
};

renderTextField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  labelField: PropTypes.string.isRequired,
  clearable: PropTypes.bool.isRequired,
};

export default function SelectFF(props) {
  const {
    className,
    label,
    defaultValue,
    placeholder,
    onChange,
    id,
    name,
    isAnswer,
    labelField,
    valueField,
    clearable,
    onDropdownOpen,
  } = props;

  let { options } = props;
  let values = [];

  if (isAnswer) {
    options = options.map((option) => ({
      label: option.answer,
      value: option.answer_id,
      question_id: option.question_id,
      textInput: option.textInput,
    }));
  }

  if (defaultValue && options && options.length) {
    values = options.filter((option) => option.value === defaultValue);
  }

  return (
    <Field
      name={name}
      labelField={labelField}
      valueField={valueField}
      id={id}
      clearable={clearable}
      defaultValue={defaultValue}
      label={label}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      values={values}
      multiple
      component={renderTextField}
      onDropdownOpen={onDropdownOpen}
    />
  );
}

SelectFF.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}),
  label: PropTypes.element,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAnswer: PropTypes.bool,
  clearable: PropTypes.bool,
  onDropdownOpen: PropTypes.func,
};

SelectFF.defaultProps = {
  input: {},
  className: 'dropdown__basic',
  placeholder: '',
  label: null,
  defaultValue: null,
  onChange: () => {},
  name: null,
  id: null,
  valueField: 'value',
  labelField: 'label',
  isAnswer: false,
  clearable: false,
  onDropdownOpen: () => {},
};
