import { useTranslation } from 'react-i18next';

import { WelcomeCardWrapper, WelcomeCardTitle } from './styles';

export default function WelcomeCard() {
  const { t } = useTranslation();

  return (
    <WelcomeCardWrapper>
      <WelcomeCardTitle>
        {t('portfolio.helpCards.welcomeCard.title')}
      </WelcomeCardTitle>
    </WelcomeCardWrapper>
  );
}
