import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import {
  EnableKidsModalButton,
  EnableKidsModalDescription,
  EnableKidsModalImage,
  EnableKidsModalLogo,
  EnableKidsModalTitle,
  EnableKidsModalWrapper,
} from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function EnableKidsModal({ toggleKindsInfoModal }) {
  const { t } = useTranslation();

  return (
    <EnableKidsModalWrapper
      isOpen
      onRequestClose={() => toggleKindsInfoModal()}
      shouldCloseOnOverlayClick
    >
      <DeprecatedButton
        buttonClass="button__close"
        handleClick={() => toggleKindsInfoModal()}
      />

      <EnableKidsModalLogo />

      <EnableKidsModalImage />

      <EnableKidsModalTitle>
        {t('raizKidsPage.enableKidsModal.title')}
      </EnableKidsModalTitle>

      <EnableKidsModalDescription>
        <Trans t={t} i18nKey="raizKidsPage.enableKidsModal.description" />
      </EnableKidsModalDescription>

      <EnableKidsModalButton
        label={t('raizKidsPage.enableKidsModal.button')}
        onClick={() => toggleKindsInfoModal(true)}
        variant="transparent"
      />
    </EnableKidsModalWrapper>
  );
}

EnableKidsModal.propTypes = {
  toggleKindsInfoModal: PropTypes.func.isRequired,
};
