import { useTranslation } from 'react-i18next';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import {
  ACCOUNT_AGREEMENT_LINK_MY,
  DISCLOSURE_AGREEMENT_LINK_MY,
  PLATFORM_LINK_MY,
  PRIVACY_LINK_MY,
} from 'constants/comonConstants';

export const useRegistrationQuestionsTransComponent = () => {
  const { t } = useTranslation();

  return (index) => {
    if (index === 0) {
      return {
        platform_link: (
          <ExternalLink
            href={PLATFORM_LINK_MY}
            title={t('registrationPage.accountQuestions.boundByTerms.platform')}
          />
        ),
        account_agreement_link: (
          <ExternalLink
            href={ACCOUNT_AGREEMENT_LINK_MY}
            title={t(
              'registrationPage.accountQuestions.boundByTerms.accountAgreement',
            )}
          />
        ),
        disclosure_agreement_link: (
          <ExternalLink
            href={DISCLOSURE_AGREEMENT_LINK_MY}
            title={t(
              'registrationPage.accountQuestions.boundByTerms.disclosureAgreement',
            )}
          />
        ),
        privacy_link: (
          <ExternalLink
            href={PRIVACY_LINK_MY}
            title={t('registrationPage.accountQuestions.boundByTerms.privacy')}
          />
        ),
      };
    }

    if (index === 1) {
      return {
        privacy_link: (
          <ExternalLink
            href={PRIVACY_LINK_MY}
            title={t('registrationPage.accountQuestions.boundByTerms.privacy')}
          />
        ),
      };
    }

    return {};
  };
};
