import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StatisticsItemsWrapper } from './styles';
import StatisticItem from './StatisticItem';

import { usePortfolioCustomizationRoute } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';
import { selectFundStatistics } from 'store/portfolio/selectors';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

export default function FundStatisticItems() {
  const { t } = useTranslation();
  const statistics = useSelector(selectFundStatistics);

  const { isResidentialPropertyFundsDetailsPage } =
    usePortfolioCustomizationRoute();

  const fundStatistics = useMemo(
    () => [
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.yearToDateReturn',
        value: statistics.ytd_return,
        type: NUMBER_FORMATS.percent,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.openingPrice',
        value: statistics.prices?.open,
        placeholder: '-',
        isVisible: !isResidentialPropertyFundsDetailsPage,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.previousPrice',
        value: statistics.prices?.previous_close,
        isVisible: !isResidentialPropertyFundsDetailsPage,
      },
      {
        label: 'portfolioCustomization.fundDetails.statistic.labels.weekHigh',
        value: statistics.annual_high,
      },
      {
        label: 'portfolioCustomization.fundDetails.statistic.labels.weekLow',
        value: statistics.annual_low,
      },
      {
        label: 'portfolioCustomization.fundDetails.statistic.labels.volume',
        value: statistics.volume,
        fractionDigits: 0,
        type: NUMBER_FORMATS.decimal,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.marketCapitalization',
        value: statistics.net_assets,
        isVisible: statistics?.net_assets,
        isWithoutFormatting: true,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.dividendYield',
        value: statistics.dividend_yield,
        type: NUMBER_FORMATS.percent,
      },
      {
        label:
          'portfolioCustomization.fundDetails.statistic.labels.dividendPayDate',
        value: statistics.dividend_pay_date,
        isDate: true,
        placeholder: '-',
      },
    ],
    [statistics, isResidentialPropertyFundsDetailsPage],
  );

  return (
    <StatisticsItemsWrapper>
      {fundStatistics?.map(
        ({
          value,
          label,
          isDate,
          isVisible = true,
          isWithoutFormatting,
          placeholder,
          fractionDigits,
          type,
        }) =>
          isVisible && (
            <StatisticItem
              label={t(label)}
              key={label}
              value={value}
              isDate={isDate}
              isWithoutFormatting={isWithoutFormatting}
              placeholder={placeholder}
              fractionDigits={fractionDigits}
              type={type}
            />
          ),
      )}
    </StatisticsItemsWrapper>
  );
}
