import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const StatementsInfoPaneLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Header = styled.div`
  height: 28px;
  background-color: ${({ theme }) => theme.colors.athensGray};
  padding: 4px 20px;
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 10px 25px;
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
`;

export const StatementsButtonWrapper = styled.div`
  display: flex;
`;

const StatementsActionButton = styled(Button)`
  padding: 8px 20px;
  border-radius: 18px;
  height: auto;
  width: auto;
  color: ${({ theme }) => theme.colors.boulder};
  font-size: ${({ theme }) => theme.fontSizes.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
  line-height: ${({ theme }) => theme.lineHeights.length['4sm']};
`;

export const DownloadButton = styled(StatementsActionButton)`
  margin-right: 10px;
  background: ${({ theme }) => theme.colors.transparent};
  border: 2px solid ${({ theme }) => theme.colors.athensGray};
`;

export const OpenButton = styled(StatementsActionButton)`
  background: ${({ theme }) => theme.colors.athensGray};
`;
