import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';

import showPasswordIcon from 'assets/images/icons/open-eye.svg';
import hidePasswordIcon from 'assets/images/icons/close-eye.svg';

export const TextFieldStyled = styled(TextField)<{ multiline?: boolean }>`
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.mirage};
  }

  & .MuiInputLabel-shrink {
    color: ${({ theme }) => theme.colors.mirage} !important;
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
    transform: translate(0, 10px) scale(0.75);
  }

  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.mirage} !important;
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  }

  &.MuiFormControl-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    height: ${({ multiline }) => (multiline ? 'auto' : '70px')};
  }

  & .MuiInputBase-input {
    font-weight: ${({ theme }) => theme.fontWeights.normal} !important;
  }

  & .MuiInput-formControl {
    margin-top: 32px;
  }

  & .MuiInputLabel-formControl {
    margin-top: 4px;
  }

  & input[type='password'] {
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  & .MuiInput-formControl::before {
    display: none;
  }

  & .MuiInput-underline::after {
    display: none;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  & .MuiFormHelperText-filled {
    width: fit-content;
    background-color: ${({ theme }) => theme.colors.white};
    padding-right: 14px;
    margin-top: 58px;
  }

  & .Mui-error {
    text-align: left;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.colors.mercury};
  padding-bottom: 10px;

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:focus + label,
  input[value]:not([value='']) + label,
  input:-webkit-autofill + label {
    font-size: ${({ theme }) => theme.fontSizes['4sm']};
    opacity: ${({ theme }) => theme.opacity.moderatelyTransparent};
    top: 10px;
  }
`;

export const InputLabel = styled.label`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  top: 24px;
  left: 0;
  position: absolute;
  font-size: ${({ theme }) => theme.fontSizes.md};

  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    top 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const InputStyled = styled.input`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.md};
  background: none;
  z-index: ${({ theme }) => theme.zIndexes.elevated};
  padding: 46px 0 14px;
  border: 0;
`;

export const InputErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.pomegranat};
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
  margin: 0;
  align-self: flex-start;
  line-height: 0;
`;

export const TogglePasswordButton = styled.button<{
  isPasswordVisible: boolean;
}>`
  background: url(${({ isPasswordVisible }) =>
      isPasswordVisible ? `${showPasswordIcon}` : `${hidePasswordIcon}`})
    no-repeat;
  bottom: 10px;
  cursor: pointer;
  padding: 7px 11px 13px;
  position: absolute;
  right: 0;
  height: unset;
  width: unset;
  z-index: ${({ theme }) => theme.zIndexes.infoModal};
  :hover,
  :active,
  :focus {
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  }
`;
