import { useMemo } from 'react';

import { MINIMUM_WEEKLY_LIMIT } from './consts';
import InvestingAccessForm from './InvestingAccessForm';
import InvestingAccessFormSkeleton from './InvestingAccessFormSkeleton/InvestingAccessFormSkeleton';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { INVESTMENT_TYPES } from 'constants/kidsConstants';
import { selectChild } from 'store/dependentUser/selectors';

export default function InvestingAccess() {
  const dependencyUser = useAppSelector(selectChild);

  const {
    id,
    investing: isInvestingEnabled,
    investing_weekly_limit,
  } = dependencyUser;

  const activeInvestmentAccessType = useMemo(() => {
    if (isInvestingEnabled && investing_weekly_limit)
      return INVESTMENT_TYPES.allowWeekly;

    if (isInvestingEnabled) return INVESTMENT_TYPES.allow;

    return INVESTMENT_TYPES.notAllow;
  }, [investing_weekly_limit, isInvestingEnabled]);

  const defaultValues = {
    investmentAccessType: activeInvestmentAccessType,
    investing_weekly_limit: investing_weekly_limit || MINIMUM_WEEKLY_LIMIT,
  };

  if (!id) {
    return <InvestingAccessFormSkeleton />;
  }

  return <InvestingAccessForm defaultValues={defaultValues} />;
}
