import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function InsurenceItem({
  certificate,
  isPdfQueryLoading,
  downloadPdfModal,
  index,
  openPdfModal,
  statementsUrl,
}) {
  const { t } = useTranslation();
  const { url } = certificate;
  const isLoading = isPdfQueryLoading && url === statementsUrl;

  const pdfFileName = `${t('rewardsPage.insurances.insurancePdfFileName', {
    number: index + 1,
  })}.pdf`;

  return (
    <div key={pdfFileName}>
      {index === 0 && (
        <div className="info-panel__item statements-item">
          {t('rewardsPage.insurances.downloadInsurance')}
        </div>
      )}

      <div className="info-panel__item statements-item">
        {t('rewardsPage.insurances.insuranceDocName', { number: index + 1 })}

        <div className="statements-item__btn-container">
          <DeprecatedButton
            title={t('rewardsPage.insurances.downloadPDF')}
            withLoader={isLoading}
            buttonClass="statements-item__button"
            handleClick={() => downloadPdfModal(url, pdfFileName)}
          />

          <DeprecatedButton
            title={t('rewardsPage.insurances.openPDF')}
            withLoader={isLoading}
            buttonClass="statements-item__button"
            handleClick={() => openPdfModal(true, url)}
          />
        </div>
      </div>
    </div>
  );
}

InsurenceItem.propTypes = {
  certificate: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
  isPdfQueryLoading: PropTypes.bool.isRequired,
  downloadPdfModal: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  openPdfModal: PropTypes.func.isRequired,
  statementsUrl: PropTypes.string.isRequired,
};
