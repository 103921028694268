import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useUpdateBasePortfolio } from '../hooks';

import {
  PortfolioPlanFooterButton,
  PortfolioPlanFooterWrapper,
} from 'pages/Portfolio/components/styles';
import {
  selectIsBasePortfolioActive,
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function ChangeBasePortfolioFooter() {
  const { t } = useTranslation();

  const { isRegistration } = useAppSelector(selectPortfolioLocation);

  const { childId, isSuper } = useAppSelector(selectPortfolioParams);

  const { handleChangeBasePortfolio, isPortfolioLoading } =
    useUpdateBasePortfolio();

  const isBaseProPortfolioActive = useSelector(
    selectIsBasePortfolioActive({
      isRaizKids: !!childId,
      isSuper,
    }),
  );

  return (
    <PortfolioPlanFooterWrapper isRegistration={isRegistration}>
      <PortfolioPlanFooterButton
        className="-exclude"
        title={t(
          `portfolioCustomization.tabs.basePortfolioPage.footer.button.${
            isBaseProPortfolioActive ? 'disabled' : 'enabled'
          }`,
        )}
        disabled={isBaseProPortfolioActive}
        handleClick={handleChangeBasePortfolio}
        withLoader={isPortfolioLoading}
      />
    </PortfolioPlanFooterWrapper>
  );
}
