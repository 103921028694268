import { useTranslation } from 'react-i18next';

import {
  FundsListSearchField,
  FundsListSearchIcon,
  FundsListSearchWrapper,
} from './styles';
import { useSearchFunds } from './useSearchFunds';

import { usePortfolioCustomizationRoute } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';
import { PORTFOLIO_CUSTOMIZATION_TABS } from 'pages/Portfolio/subpages/PortfolioCustomization/consts';

export default function FundsListSearch() {
  const { t } = useTranslation();
  const { activeTab } = usePortfolioCustomizationRoute();
  const updateSearchFundsQueryParams = useSearchFunds();

  if (activeTab === PORTFOLIO_CUSTOMIZATION_TABS.preview || !activeTab) {
    return null;
  }

  return (
    <FundsListSearchWrapper>
      <FundsListSearchIcon size={18} iconName="searchIcon" />

      <FundsListSearchField
        onChange={(event) =>
          updateSearchFundsQueryParams(event.currentTarget.value)
        }
        placeholder={t('common.search')}
      />
    </FundsListSearchWrapper>
  );
}
