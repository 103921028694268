import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

import { FundItemWrapper } from '../FundsList/styles';
import { HeaderImage } from '../FundDetails/styles';
import { CARD_WIDTH } from '../FundsList/consts';

import rooms from 'assets/images/icons/rooms.svg';
import bathrooms from 'assets/images/icons/bathrooms.svg';
import garage from 'assets/images/icons/garage.svg';

export const ResidentialPropertyFundsWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.primary};
  background: ${({ theme }) => theme.colors.wildSand};
  border-radius: ${({ isFooterVisible }) =>
    isFooterVisible ? 0 : '0 0 10px 10px'};
  margin-top: 0;
  text-align: left;
  position: relative;
`;

export const ResidentialPropertyFundsListWrapper = styled.div`
  background: ${({ theme }) => theme.colors.wildSand};
  margin-top: 16px;
  padding: 0 8px 0 8px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(${CARD_WIDTH.min}, ${CARD_WIDTH.propertyMax}), 1fr)
  );
  gap: 8px;
`;

export const ResidentialPropertyFundItemWrapper = styled(FundItemWrapper)`
  height: auto;
  flex: 300px;
  padding-bottom: 16px;
`;

export const ResidentialPropertyFundHeaderImage = styled(HeaderImage)`
  top: -52px;
  left: 16px;
  position: absolute;
  margin: 0;
`;

export const ResidentialPropertyFundTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6md']};
  color: ${({ theme }) => theme.colors.mirage};
  text-align: left;
  padding: 64px 8px 0 16px;
`;

export const ResidentialPropertyFundLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  color: ${({ theme }) => theme.colors.mirage};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  text-align: left;
  padding: 10px 8px 0 24px;
`;

export const ResidentialPropertyFundAddress = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  color: ${({ theme }) => theme.colors.mirage};
  padding: 16px 16px 0 16px;
`;

export const ResidentialPropertyFundLocation = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  color: ${({ theme }) => theme.colors.bluishPurpleTransparent};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 4px 16px 10px 16px;
`;

export const ResidentialPropertyFundImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ResidentialPropertyFundImage = styled.div`
  width: 100%;
  padding: 98px 0 45px 0;
  background: url(${({ background }) => background}) no-repeat center center;
  background-size: cover;
  max-width: 308px;
  border-radius: 14px;
  margin: 8px 14px 8px 14px;
`;

export const ResidentialPropertyFundBottomWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: left;
  padding: 0 14px 0 14px;
  line-height: ${({ theme }) => theme.lineHeights.length['2xl']};
`;

export const ComfortWrapper = styled.div`
  padding-right: 8px;
  margin: 16px 0 8px 0;
  display: flex;
`;

export const Rooms = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 52px;
  height: 24px;
  background: url(${rooms})
    ${({ theme }) => theme.colors.bluishGreenTransparent} no-repeat 8px;
  border-radius: 40px;
  color: ${({ theme }) => theme.colors.niagara};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  text-align: right;
  padding-right: 8px;
  margin-right: 8px;
`;

export const Bathrooms = styled(Rooms)`
  background: url(${bathrooms})
    ${({ theme }) => theme.colors.bluishGreenTransparent} no-repeat 8px;
`;

export const GarageCapacity = styled(Rooms)`
  background: url(${garage})
    ${({ theme }) => theme.colors.bluishGreenTransparent} no-repeat 8px;
`;

export const ResidentialPropertyFundPrice = styled(Trans)`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const ResidentialPropertyFundDescription = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  color: ${({ theme }) => theme.colors.bluishPurpleTransparent};
  height: 66px;
`;
