import { useTranslation } from 'react-i18next';

import { Children } from '../RaizKidsContent/types';

import { KidCardRow, KidCardOpenedPortfolioHeader } from './styles';
import KidCardOpenedPortfolio from './KidCardOpenedPortfolio';
import { KidCardInfoMalaysia } from './KidCardInfoMalaysia';
import { KidCardInfoItem } from './KidCardInfoItem';

import { MY_APP } from 'constants/localeConfigs';

interface IKidCardInfoProps {
  kid: Children;
}

export const KidCardInfo = ({ kid }: IKidCardInfoProps) => {
  const { t } = useTranslation();

  if (MY_APP) {
    return <KidCardInfoMalaysia kid={kid} />;
  }

  return (
    <KidCardRow>
      {kid.portfolio && (
        <KidCardOpenedPortfolioHeader>
          {t('raizKidsPage.portfolio', { portfolio: kid.portfolio.name })}
        </KidCardOpenedPortfolioHeader>
      )}

      <KidCardInfoItem
        label={t('raizKidsPage.cards.amountInvestedLabel')}
        value={kid.summary.invested_by_you.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.reinvestedDividendsLabel')}
        value={kid.summary.reinvested_dividends.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.marketReturnsLabel')}
        value={kid.summary.gain_loss.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.rewardsLabel')}
        value={kid.summary.total_found_money_rewarded.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.referralsLabel')}
        value={kid.summary.total_referrals_rewarded.value}
      />

      <KidCardInfoItem
        label={t('raizKidsPage.cards.withdrawnLabel')}
        value={kid.summary.withdrawals.value}
      />

      <KidCardOpenedPortfolio kid={kid} />
    </KidCardRow>
  );
};
