import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useMemo } from 'react';

import {
  FundItemTotalWrapper,
  FundItemTotalPercent,
  HelpIconWrapper,
} from './styles';

import { FUNDS_TAGS } from 'pages/Portfolio/subpages/PortfolioCustomization/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';
import { IconButton } from 'components/elements/IconButton';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectFundById,
  selectFundsToUpdate,
  selectFundToUpdateById,
  selectPortfolioParams,
  selectBasePortfolioFunds,
} from 'store/portfolio/selectors';
import { IFund } from 'store/portfolio/types';

interface IFundSuperTotalProps {
  isDetailsPage?: boolean;
  id: string;
}

export default function FundSuperTotal({
  isDetailsPage,
  id,
}: IFundSuperTotalProps) {
  const { t } = useTranslation();
  const { isSuper } = useAppSelector(selectPortfolioParams);
  const { fundTag } = useParams();
  const dispatch = useAppDispatch();

  const {
    allocation = 0,
    name,
    max_allocation,
    symbol,
    total_allocation: savedTotal,
  } = useAppSelector(selectFundById({ id, isSuper })) || {};

  const fundToUpdate = useAppSelector(selectFundToUpdateById({ id }));

  const { total_allocation: previewTotal } = fundToUpdate || {};

  const fundsToUpdate = useAppSelector(selectFundsToUpdate);

  // Type assertion is needed because of the different types of the selector
  const basePortfolioFunds = useAppSelector(
    selectBasePortfolioFunds(isSuper),
  ) as IFund[];

  const isFundInBasePortfolio = basePortfolioFunds?.find(
    (fund) => fund.id === id,
  );

  const totalAllocation = previewTotal || savedTotal;

  const isFundRemoved =
    (fundsToUpdate?.length > 0 && !fundToUpdate?.allocation) ||
    (!allocation && !fundToUpdate?.allocation);

  const isSuperTotalFieldVisible = useMemo(() => {
    if (isSuper && fundTag === FUNDS_TAGS.etf) {
      if (isDetailsPage && isFundInBasePortfolio) {
        return true;
      }

      if (isFundRemoved) {
        return false;
      }

      if (isFundInBasePortfolio && (previewTotal || allocation > 0)) {
        return true;
      }
    }

    return false;
  }, [
    allocation,
    fundTag,
    isDetailsPage,
    isFundInBasePortfolio,
    isFundRemoved,
    isSuper,
    previewTotal,
  ]);

  if (!isSuperTotalFieldVisible) {
    return null;
  }

  const openHelpModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('portfolioCustomization.totalWeightingModal.title'),
          description: t(
            'portfolioCustomization.totalWeightingModal.description',
            {
              name: `${name} (${symbol})`,
              value: formatNumber({
                value: max_allocation,
                type: NUMBER_FORMATS.percent,
                minimumFractionDigits: 1,
              }),
            },
          ),
        },
      }),
    );
  };

  return (
    <FundItemTotalWrapper isDetailsPage={isDetailsPage}>
      <FundItemTotalPercent isDetailsPage={isDetailsPage}>
        {formatNumber({
          value: totalAllocation,
          type: NUMBER_FORMATS.percent,
        })}
      </FundItemTotalPercent>

      <HelpIconWrapper isDetailsPage={isDetailsPage}>
        <IconButton
          iconName="infoGrey"
          size={isDetailsPage ? 20 : 16}
          onClick={openHelpModal}
        />
      </HelpIconWrapper>
    </FundItemTotalWrapper>
  );
}
