import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetWrapper, WidgetTitle } from '../styles';

import OffsettersWidgetContent from './components/OffsettersWidgetContent';

export default function OffsettersWidget() {
  const { t } = useTranslation();

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('offsettersWidget.title')}</WidgetTitle>

      <OffsettersWidgetContent />
    </WidgetWrapper>
  );
}
