import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { DOCUMENT_TYPES } from '../../../../constants/comonConstants';
import { IdDocumentUploader } from '../../../../pages/Registration/components/UploadId/components/IdDocumentUploader';
import UploadDocumentAlertDescription from '../UploaderDocumentsModal/components/UploadDocumentAlertDescription';

import { ALERT_TYPES } from 'constants/alerts';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

function AUSDocumentsUploader({
  currentAlert,
  isUploadInfoAlertOpen,
  toggleAlertInfoModal,
  isLoader,
  submitAusIdDocuments,
  handleChangeStatus,
  documents,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isUploadInfoAlertOpen}
      onRequestClose={() => toggleAlertInfoModal(false, currentAlert)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic modal-alert -white"
    >
      <div className="modal-basic__header">
        <DeprecatedButton
          buttonClass="button__close -grey"
          handleClick={() => toggleAlertInfoModal(false, currentAlert)}
        />

        {t('alertsPage.uploadId.documents.title')}
      </div>

      <div className="modal-basic__modal-description">
        <UploadDocumentAlertDescription alertType={currentAlert.type} />
      </div>

      <IdDocumentUploader
        handleChangeStatus={handleChangeStatus}
        type={
          [
            ALERT_TYPES.idUploadErrors.idBackUploadMissing,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
          ].includes(currentAlert.type)
            ? DOCUMENT_TYPES.idBack
            : DOCUMENT_TYPES.idFront
        }
      />

      <Button
        isLoading={isLoader}
        isDisabled={!documents.name}
        label={t('common.submit')}
        onClick={submitAusIdDocuments}
      />
    </Modal>
  );
}

AUSDocumentsUploader.propTypes = {
  isUploadInfoAlertOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  submitAusIdDocuments: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  isLoader: PropTypes.bool.isRequired,
  currentAlert: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    metadata: PropTypes.string,
  }).isRequired,
  documents: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default AUSDocumentsUploader;
