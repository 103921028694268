import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ICloseAccountData } from '../../types';
import { RequestForm } from '../RequestForm';
import { SuccessView } from '../SuccessView';
import { CloseIconButton, ContentWrapper, FooterWrapper } from '../styles';

import {
  RequestModalBalanceLabel,
  RequestModalBalanceValue,
  RequestModalDescription,
  RequestModalTitle,
  RequestModalWarning,
  RequestModalWrapper,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { selectCloseAccountData } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

interface IRequestModalProps {
  isRequestModalVisible: boolean;
  isErrorModalVisible: boolean;
  closeRequestModal: () => void;
}

export const RequestModal = ({
  isRequestModalVisible,
  isErrorModalVisible,
  closeRequestModal,
}: IRequestModalProps) => {
  const { t } = useTranslation();

  const closeAccountData = useAppSelector<ICloseAccountData>(
    selectCloseAccountData,
  );

  const {
    errorMessage,
    isAccountClosed,
    isCloseAccountLoading,
    isCloseAccountRequestCreated,
    isFundingAccountLinked,
    userBalance,
    userName,
  } = closeAccountData;

  const isFormHidden = useMemo(
    () =>
      (isCloseAccountRequestCreated || isAccountClosed) &&
      (isFundingAccountLinked || userBalance === 0) &&
      !isCloseAccountLoading &&
      !isErrorModalVisible,
    [
      isAccountClosed,
      isCloseAccountLoading,
      isCloseAccountRequestCreated,
      isErrorModalVisible,
      isFundingAccountLinked,
      userBalance,
    ],
  );

  const formattedUserBalance = formatNumber({
    value: userBalance,
  });

  return (
    <RequestModalWrapper
      isOpen={isRequestModalVisible}
      onRequestClose={closeRequestModal}
      contentLabel="close-account-request-modal"
      shouldCloseOnOverlayClick
    >
      {isFormHidden ? (
        <SuccessView closeRequestModal={closeRequestModal} />
      ) : (
        <>
          <ContentWrapper>
            <CloseIconButton onClick={closeRequestModal} />

            <RequestModalTitle>
              {t('closeAccountModal.modalTitle')}
            </RequestModalTitle>

            <RequestModalDescription>
              {t('closeAccountModal.description')}
            </RequestModalDescription>

            <RequestModalBalanceLabel>
              {t('closeAccountModal.accountValue')}
            </RequestModalBalanceLabel>

            <RequestModalBalanceValue>
              {userName ? formattedUserBalance : <DotsLoader />}
            </RequestModalBalanceValue>
          </ContentWrapper>

          <FooterWrapper>
            <RequestForm closeRequestModal={closeRequestModal} />

            <RequestModalWarning>
              {t('closeAccountModal.warning')}
            </RequestModalWarning>

            <div>{errorMessage}</div>
          </FooterWrapper>
        </>
      )}
    </RequestModalWrapper>
  );
};
