import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BANK_PROVIDERS } from 'store/fundingAccount/consts';

interface IUseConnectionStatusTitle {
  isSpending?: boolean;
  provider: string;
}

export const useConnectionStatusTitle = ({
  isSpending,
  provider,
}: IUseConnectionStatusTitle) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (provider === BANK_PROVIDERS.yodleeFastlink) {
      return t(
        `registrationPage.bankAccountConnectionStatus.title.${
          isSpending ? 'roundUps' : 'funding'
        }`,
      );
    }

    return t('registrationPage.bankAccountConnectionStatus.title.default');
  }, [isSpending, provider, t]);

  return title;
};
