import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Summary } from '../Summary';
import { CopyButton } from '../CopyButton';
import { InviteButton } from '../InviteButton';

import { Background, Title, SubTitle, Row } from './styles';

import { selectUserInvitation } from 'store/user/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';
import { UserInvitationInvitation, IRootUserState } from 'store/user/types';

export const Banner = () => {
  const { t } = useTranslation();

  const rewardAmount =
    useSelector<IRootUserState, UserInvitationInvitation>(selectUserInvitation)
      ?.reward_amount || 0;

  const formattedRewardAmount = formatNumber({
    value: rewardAmount,
    minimumFractionDigits: 2,
  });

  return (
    <Background>
      <Title>{t('referralsPage.banner.title')}</Title>

      <SubTitle>
        <Trans
          i18nKey="referralsPage.banner.subTitle"
          t={t}
          values={{
            amount: formattedRewardAmount,
          }}
        />
      </SubTitle>

      <Row>
        <CopyButton />

        <InviteButton />
      </Row>

      <Summary />
    </Background>
  );
};
