import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { toggleInfoModal } from 'store/modals/actions';

export const useHandleTermsRejection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleTermsRejection = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t(
            'registrationPage.accountMigration.PDSPage.errorModal.termsRejectionText',
          ),
        },
      }),
    );
  };

  return {
    handleTermsRejection,
  };
};
