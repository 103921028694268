export const FUNDS_TO_FETCH = 100;
export const CUSTOM_PORTFOLIO_BASE_URL = 'custom_portfolios';
export const MAX_ALLOCATION_STATUS_CODE = 409;
export const UNPROCESSABLE_CONTENT_STATUS_CODE = 422;
export const FUND_TAGS_KEY = 'fund_tags';
export const DEFAULT_PORTFOLIO_PRO_NAME = 'Plus';
export const FUND_TAGS_ORDER = ['etf', 'stock', 'rpf', 'rrpf', 'btc'];
export const DEFAULT_BASIC_PORTFOLIO = {
  allocation: 100,
  name: 'Portfolio',
};
export const DEFAULT_PORTFOLIO = 'conservative';

export const PORTFOLIO_ACTIONS = {
  GET_FUNDS_TAGS_LOADING: 'GET_FUNDS_TAGS_LOADING',
  GET_FUNDS_TAGS_SUCCESS: 'GET_FUNDS_TAGS_SUCCESS',
  GET_FUNDS_LOADING: 'GET_FUNDS_LOADING',
  GET_FUNDS_SUCCESS: 'GET_FUNDS_SUCCESS',
  GET_PRO_PORTFOLIO_LOADING: 'GET_PRO_PORTFOLIO_LOADING',
  GET_PRO_PORTFOLIO_SUCCESS: 'GET_PRO_PORTFOLIO_SUCCESS',
  GET_FUND_DETAILS_LOADING: 'GET_FUND_DETAILS_LOADING',
  GET_FUND_DETAILS_SUCCESS: 'GET_FUND_DETAILS_SUCCESS',
  GET_RESIDENTIAL_PROPERTY_FUNDS_LOADING:
    'GET_RESIDENTIAL_PROPERTY_FUNDS_LOADING',
  GET_RESIDENTIAL_PROPERTY_FUNDS_SUCCESS:
    'GET_RESIDENTIAL_PROPERTY_FUNDS_SUCCESS',
  SET_FILTER_MODAL_VISIBLE: 'SET_FILTER_MODAL_VISIBLE',
  GET_PRO_PORTFOLIO_COLORS_LOADING: 'GET_PRO_PORTFOLIO_COLORS_LOADING',
  GET_PRO_PORTFOLIO_COLORS_SUCCESS: 'GET_PRO_PORTFOLIO_COLORS_SUCCESS',
  SET_IS_UPDATE_FUNDS_LOADING: 'SET_IS_UPDATE_FUNDS_LOADING',
  SET_FUNDS_TO_UPDATE: 'SET_FUNDS_TO_UPDATE',
  FUNDS_SORTING: 'FUNDS_SORTING',
  SET_ACTIVE_PORTFOLIO_ID: 'SET_ACTIVE_PORTFOLIO_ID',
  GET_FUND_STATISTICS_SUCCESS: 'GET_FUND_STATISTICS_SUCCESS',
  GET_FUND_STATISTICS_LOADING: 'GET_FUND_STATISTICS_LOADING',
  SET_IS_PORTFOLIO_LOADING: 'SET_IS_PORTFOLIO_LOADING',
  SET_IS_BASE_PORTFOLIO_FULLY_ALLOCATED:
    'SET_IS_BASE_PORTFOLIO_FULLY_ALLOCATED',
  SET_IS_PARENT_PRO_PORTFOLIO_PREVIEW: 'SET_IS_PARENT_PRO_PORTFOLIO_PREVIEW',
  UPDATE_PORTFOLIO_PARAMS: 'UPDATE_PORTFOLIO_PARAMS',
  UPDATE_PORTFOLIO_LOCATION: 'UPDATE_PORTFOLIO_LOCATION',
} as const;

export const ALL_FUND_TAGS_FILTER = 'everything';
export const BASE_PORTFOLIO_FULLY_ALLOCATED = 100;

export const SUPER_PORTFOLIOS_FOR_AGGRESSIVE_PORTFOLIO = {
  property_10: 'property_10',
  property_30: 'property_30',
};
export const MODERATELY_AGGRESSIVE_PORTFOLIO = 'moderately_aggressive';
