import dayjs from 'dayjs';

import { Status } from '../Status';

import { Container, Bottom, Left, Amount, Name, Text, Avatar } from './styles';
import { getFullName, getAvatarText } from './utils';

import { IReferralsListItemDto } from 'store/referrals/types';
import { DATE_FORMAT } from 'config/date';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IItemProps {
  item: IReferralsListItemDto;
}

export const Item = ({ item }: IItemProps) => {
  const name = getFullName(item);

  const avatarText = getAvatarText(item);

  const formattedRewardAmmount = formatNumber({
    value: item.reward_amount,
    minimumFractionDigits: 2,
    isWithSign: true,
  });

  return (
    <Container>
      <Avatar>{avatarText}</Avatar>

      <Left>
        <Name>{name}</Name>

        <Bottom>
          <Text>{dayjs(item.date).format(DATE_FORMAT)}</Text>

          <Status status={item.status} />
        </Bottom>
      </Left>

      <Amount>{formattedRewardAmmount}</Amount>
    </Container>
  );
};
