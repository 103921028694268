import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function RelSentModal({ toggleSentlinkModal, childEmail }) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen
      onRequestClose={() => toggleSentlinkModal(false)}
      contentLabel="kids-modal"
      shouldCloseOnOverlayClick
      className="modal-basic raiz-kids__link-sent"
    >
      <div className="raiz-kids__link-sent-header">
        {t('raizKidsEditPage.sendInvitationLink.title')}
      </div>

      <div className="raiz-kids__link-sent-content">
        {t('raizKidsEditPage.sendInvitationLink.description', {
          email: childEmail,
        })}

        <DeprecatedButton
          buttonClass="button__close -black"
          handleClick={() => toggleSentlinkModal(false)}
        />

        <Button
          label={t('common.ok')}
          onClick={() => toggleSentlinkModal(false)}
        />
      </div>
    </Modal>
  );
}

RelSentModal.propTypes = {
  toggleSentlinkModal: PropTypes.func.isRequired,
  childEmail: PropTypes.string.isRequired,
};
