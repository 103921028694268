import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Animate } from 'react-show';
import { useTranslation } from 'react-i18next';

import Time from '../../../../components/elements/Time/Time';
import FinalFormInput from '../../../../components/elements/FinalFormInput/FinalFormInput';

import { ProfileContentButton } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = t('fields.validation.required');
    }

    return errors;
  };

  return { validate };
};

const apiForEditing = false;

const renderMembers = (member, key, toggleAddEditForm, currentMember) => {
  const activeMember = currentMember && currentMember.id === member.id;

  return (
    <div
      className={`grid-editable__container-item ${
        activeMember ? '-active' : ''
      }`}
      key={key}
    >
      <div className="grid-editable__container-top">
        <div className="grid-editable__container-title">
          {`Member/Director ${key + 1}`}
        </div>

        {apiForEditing && (
          <div className="grid-editable__container-actions">
            {!activeMember && (
              <DeprecatedButton
                title=""
                buttonClass="button__basic-transparent -edit"
                handleClick={() => {
                  toggleAddEditForm(true, member);
                }}
              />
            )}

            <DeprecatedButton
              title=""
              buttonClass="button__basic-transparent -remove"
              handleClick={() => {}}
            />
          </div>
        )}
      </div>

      <div className="grid-editable__container-main">
        <div className="grid-editable__container-info -bold">{`${member.first_name} ${member.last_name}`}</div>

        <div className="grid-editable__container-info">{member.email}</div>

        <div className="grid-editable__container-info">
          <Time>{member.date_of_birth}</Time>
        </div>

        <div className="grid-editable__container-info">
          {`${member.street_number} ${member.street_name}`}
        </div>

        <div className="grid-editable__container-info">
          {`${member.city} ${member.state} ${member.postcode}`}
        </div>
      </div>
    </div>
  );
};

export default function SmsfForm({
  handleSubmit,
  toggleShow,
  showInformation,
  showMembers,
  smsfMembers,
  smsfDetails,
  toggleAddEditForm,
  showMemberEdit,
  showMemberAdd,
  currentMember,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className="profile-content settings__content-default-container">
            <div className="profile-content__header -with-navigation ">
              <div className="profile-content__title">
                {t('settingsPage.profileContent.smsfContent.title')}
              </div>

              <div className="profile-content__navigation">
                <DeprecatedButton
                  title={t(
                    'settingsPage.profileContent.smsfContent.toggleInformationModalButton',
                  )}
                  buttonClass={showInformation ? 'active' : ''}
                  handleClick={() => toggleShow()}
                />

                <DeprecatedButton
                  title={t(
                    'settingsPage.profileContent.smsfContent.toggleMemberModalButton',
                  )}
                  buttonClass={showMembers ? 'active' : ''}
                  handleClick={() => toggleShow(false)}
                />
              </div>
            </div>

            <div className="profile-content__container">
              <Animate
                show={showInformation}
                start={{
                  display: 'none',
                }}
              >
                <div className="profile-content__container-item">
                  <FinalFormInput
                    name="legal_fund_name"
                    readOnly
                    disabled
                    defaultValue={smsfDetails.legal_fund_name}
                    label={t(
                      'settingsPage.profileContent.smsfContent.fields.legalName.label',
                    )}
                  />
                </div>

                <div className="profile-content__container-item">
                  <FinalFormInput
                    name="display_fund_name"
                    readOnly
                    disabled
                    defaultValue={smsfDetails.display_fund_name}
                    label={t(
                      'settingsPage.profileContent.smsfContent.fields.displayName.label',
                    )}
                  />
                </div>

                <div className="profile-content__container-item">
                  <FinalFormInput
                    name="fund_abn"
                    readOnly
                    disabled
                    defaultValue={smsfDetails.fund_abn}
                    label={t(
                      'settingsPage.profileContent.smsfContent.fields.ABNFund.label',
                    )}
                  />
                </div>

                <div className="profile-content__container-item">
                  <div className="profile-content__container-label">
                    {t(
                      'settingsPage.profileContent.smsfContent.establishmentDate',
                    )}
                  </div>

                  <div className="profile-content__container-value">
                    <Time>{smsfDetails.fund_establishment_date}</Time>
                  </div>
                </div>

                <div className="profile-content__container-item">
                  <FinalFormInput
                    name="fund_address"
                    readOnly
                    disabled
                    defaultValue={smsfDetails.fund_address}
                    label={t(
                      'settingsPage.profileContent.smsfContent.fields.address.label',
                    )}
                  />
                </div>

                {smsfDetails.fund_type === 'corporate' && (
                  <div className="profile-content__container-item">
                    <FinalFormInput
                      name="legal_company_name"
                      readOnly
                      disabled
                      defaultValue={smsfDetails.legal_company_name}
                      label={t(
                        'settingsPage.profileContent.smsfContent.fields.trusteeCompany.label',
                      )}
                    />
                  </div>
                )}

                {smsfDetails.fund_type === 'corporate' && (
                  <div className="profile-content__container-item">
                    <FinalFormInput
                      name="company_acn"
                      readOnly
                      disabled
                      defaultValue={smsfDetails.company_acn}
                      label={t(
                        'settingsPage.profileContent.smsfContent.fields.acnTrusteeCompany.label',
                      )}
                    />
                  </div>
                )}

                {smsfDetails.fund_type === 'corporate' && (
                  <div className="profile-content__container-item">
                    <FinalFormInput
                      name="company_address"
                      readOnly
                      disabled
                      defaultValue={smsfDetails.company_address}
                      label={t(
                        'settingsPage.profileContent.smsfContent.fields.addressTrusteeCompany.label',
                      )}
                    />
                  </div>
                )}
              </Animate>

              <Animate
                show={showMembers}
                start={{
                  display: 'none',
                }}
              >
                <div className="grid-editable__container">
                  {smsfMembers && smsfMembers.length
                    ? smsfMembers.map((member, key) =>
                        renderMembers(
                          member,
                          key,
                          toggleAddEditForm,
                          currentMember,
                        ),
                      )
                    : ''}

                  {apiForEditing && !showMemberAdd && (
                    <div
                      className="grid-editable__container-item -additional"
                      role="button"
                      tabIndex="0"
                      onKeyPress={() => {}}
                      onClick={() => {
                        toggleAddEditForm(true);
                      }}
                    >
                      <div>
                        <div className="grid__container-image checkbox__plus">
                          +
                        </div>

                        {t(
                          'settingsPage.profileContent.smsfContent.addMemberButton',
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {(showMemberEdit || showMemberAdd) && (
                  <div className="profile-content__container-member">
                    <div className="profile-content__container-member-name">
                      {`Member / Director ${smsfMembers.length + 1}`}
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="first_name"
                        defaultValue={currentMember.first_name}
                        label={t('fields.firstName.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="last_name"
                        defaultValue={currentMember.last_name}
                        label={t('fields.lastName.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="email"
                        defaultValue={currentMember.email}
                        label={t('fields.email.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="date_of_birth"
                        defaultValue={currentMember.date_of_birth}
                        label={t('fields.dateOfBirth.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="state"
                        defaultValue={currentMember.state}
                        label={t('fields.state.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="city"
                        defaultValue={currentMember.city}
                        label={t('fields.city.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="postcode"
                        defaultValue={currentMember.postcode}
                        label={t('fields.postCode.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="street_name"
                        defaultValue={currentMember.street_name}
                        label={t('fields.streetName.label')}
                      />
                    </div>

                    <div className="profile-content__container-item">
                      <FinalFormInput
                        name="street_number"
                        defaultValue={currentMember.street_number}
                        label={t('fields.streetNumber.label')}
                      />
                    </div>

                    <ProfileContentButton
                      label={t('common.save')}
                      type="button"
                    />
                  </div>
                )}
              </Animate>
            </div>
          </div>
        </form>
      )}
    />
  );
}

SmsfForm.propTypes = {
  handleSubmit: PropTypes.func,
  currentMember: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.shape({}).isRequired,
  smsfDetails: PropTypes.objectOf(PropTypes.string).isRequired,
  showInformation: PropTypes.bool.isRequired,
  showMemberEdit: PropTypes.bool.isRequired,
  showMemberAdd: PropTypes.bool.isRequired,
  showMembers: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func,
  toggleAddEditForm: PropTypes.func,
  smsfMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SmsfForm.defaultProps = {
  handleSubmit() {},
  toggleShow() {},
  toggleAddEditForm() {},
};
