import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
  getReferralsSummary,
  resetReferralsSummary,
} from 'store/referrals/actions';
import { IReferralsState } from 'store/referrals/types';

type IRootState = {
  referrals: IReferralsState;
};

type IReferralsSummaryState = IReferralsState['summary'];

export const useReferralsSummary = () => {
  const dispatch = useDispatch();

  const { isSettled, isLoading, data } = useSelector<
    IRootState,
    IReferralsSummaryState
  >((state) => state.referrals.summary);

  useEffect(() => {
    if (!isLoading && !isSettled) {
      dispatch(getReferralsSummary());
    }

    return () => {
      if (isSettled) {
        dispatch(resetReferralsSummary());
      }
    };
  }, [dispatch, isLoading, isSettled]);

  return data;
};
