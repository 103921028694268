import 'styles/pages/settings.scss';
import PropTypes from 'prop-types';

import { SuperRewardContent } from './components/SuperRewardContent';
import { MainWrapper } from './components/styles';

import BankErrorModal from 'components/layout/Modals/BankErrorModal';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';

export default function SuperRewardContributionView({
  showLoader,
  transferRewards,
  togglePersonalContribution,
  errorMessage,
  handleCloseModal,
  isShowErrorModal,
}) {
  useSuperAccountErrorModal();

  return (
    <MainWrapper>
      {errorMessage !== '' && isShowErrorModal && (
        <BankErrorModal
          hasBackdrop={false}
          isVisible={false}
          isOpen
          shouldCloseOnOverlayClick
          handleClick={handleCloseModal}
          errorMessage={errorMessage}
        />
      )}

      <SuperRewardContent
        showLoader={showLoader}
        transferRewards={transferRewards}
        togglePersonalContribution={togglePersonalContribution}
      />
    </MainWrapper>
  );
}

SuperRewardContributionView.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  transferRewards: PropTypes.bool.isRequired,
  togglePersonalContribution: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isShowErrorModal: PropTypes.bool.isRequired,
};
