import { Label, InfoItemWrapper, Value } from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

interface IInfoItemProps {
  label: string;
  value: number;
}

export const InfoItem = ({ label, value }: IInfoItemProps) => (
  <InfoItemWrapper>
    <Label>{label}</Label>

    <Value>
      {formatNumber({
        value: value || 0,
        maximumFractionDigits: 2,
      })}
    </Value>
  </InfoItemWrapper>
);
