import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsSignUpPromoEnabled } from 'store/firebaseConfig/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export const useInviteCode = () => {
  const isSignUpPromoEnabled = useAppSelector(selectIsSignUpPromoEnabled);

  const inviteCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.inviteCode);

  return { isSignUpPromoEnabled, inviteCode };
};
