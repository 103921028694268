import { IRootModalsState } from './types';

export const selectInfoModal = (state: IRootModalsState) => ({
  isInfoModalVisible: state.modals?.infoModal?.isOpen || false,
  config: state.modals?.infoModal?.config || {},
});

export const selectIsModalOpen =
  (modalName: string) => (state: IRootModalsState) =>
    state.modals?.openModals?.includes(modalName) || false;
