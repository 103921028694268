import { ISuperPdsDocumentData } from './types';
import { ACCEPTANCE_DOCUMENTS_URL } from './consts';

import { api } from 'store/api';

export const acceptanceDocumentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    superPdsDocument: build.query<ISuperPdsDocumentData, void>({
      query: () => ({
        url: `${ACCEPTANCE_DOCUMENTS_URL}/super_pds`,
      }),
    }),
  }),
  overrideExisting: true,
});
export const { useSuperPdsDocumentQuery } = acceptanceDocumentsApi;

export const {
  endpoints: { superPdsDocument },
} = acceptanceDocumentsApi;
