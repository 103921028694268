import { useTranslation } from 'react-i18next';

import { CustomizeYourPortfolioCardWrapper, CardTitle } from './styles';

interface ICustomizeYourPortfolioCardProps {
  isSuper: boolean;
}

export default function CustomizeYourPortfolioCard({
  isSuper,
}: ICustomizeYourPortfolioCardProps) {
  const { t } = useTranslation();

  return (
    <CustomizeYourPortfolioCardWrapper isSuper={isSuper}>
      <CardTitle>
        {t('portfolio.helpCards.customizeYourPortfolioCard.title')}
      </CardTitle>
    </CustomizeYourPortfolioCardWrapper>
  );
}
