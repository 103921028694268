import styled from '@emotion/styled';
import React from 'react';

export const LinkStyled = styled.a`
  :hover {
    opacity: ${({ theme }) => theme.opacity.almostOpaque};
  }
`;

interface IExternalLinkProps {
  href: string;
  onClick?: () => void;
  title?: string;
  children?: React.ReactNode;
}

export const ExternalLink = ({
  href,
  onClick,
  title,
  children,
  ...props
}: IExternalLinkProps) => (
  <LinkStyled
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    {...props}
  >
    {children || title}
  </LinkStyled>
);
