import { useTranslation } from 'react-i18next';

import { CardTitle, HeadsUpCardWrapper } from './styles';

export default function HeadsUpCard() {
  const { t } = useTranslation();

  return (
    <HeadsUpCardWrapper>
      <CardTitle>{t('portfolio.helpCards.headsUpCard.title')}</CardTitle>
    </HeadsUpCardWrapper>
  );
}
