import { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import 'styles/layout/withdraw.scss';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

import InvestmentErrorModal from './InvestmentErrorModal';
import LumpSumNoteModal from './LumpSumNoteModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import * as superActions from 'store/superAnnuation/actions';
import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

class InvestLumpSumModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lumpSumValue: 0,
      isOpenInvestLumpSumInfoModal: false,
      isOpenInvestmentErrorModal: false,
      showLoader: false,
    };

    this.lumpSumInvest = this.lumpSumInvest.bind(this);

    this.changeLumpSumValue = this.changeLumpSumValue.bind(this);

    this.openInvestLumpSumInfoModal =
      this.openInvestLumpSumInfoModal.bind(this);

    this.openInvestmentErrorModal = this.openInvestmentErrorModal.bind(this);
  }

  openInvestLumpSumInfoModal(condition) {
    this.setState({
      isOpenInvestLumpSumInfoModal: condition,
    });
  }

  openInvestmentErrorModal(condition) {
    this.setState({
      isOpenInvestmentErrorModal: condition,
      isOpenInvestLumpSumInfoModal: false,
    });
  }

  changeLumpSumValue(value = 0) {
    this.setState({
      lumpSumValue: value,
    });
  }

  lumpSumInvest() {
    const { actionsSuper, openInvestLumpSumModal } = this.props;
    const { lumpSumValue } = this.state;

    const requestData = {
      amount: lumpSumValue,
    };

    this.setState({ showLoader: true });

    actionsSuper.lumSumInvest(requestData).then(() => {
      this.setState({ showLoader: false });

      const { errorMessage } = this.props;

      if (errorMessage) {
        this.openInvestmentErrorModal(true);
      } else {
        openInvestLumpSumModal(false);

        this.openInvestLumpSumInfoModal(false);

        this.changeLumpSumValue();
      }
    });
  }

  render() {
    const {
      isOpenInvestLumpSumModal,
      openInvestLumpSumModal,
      errorMessage,
      t,
    } = this.props;

    const {
      lumpSumValue,
      showLoader,
      isOpenInvestLumpSumInfoModal,
      isOpenInvestmentErrorModal,
    } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpenInvestLumpSumModal}
          onRequestClose={() => openInvestLumpSumModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic time-investment__modal -super"
        >
          <div className="modal-basic__content">
            <DeprecatedButton
              buttonClass="button__close"
              handleClick={() => openInvestLumpSumModal(false)}
            />

            <div className="modal-basic__title">
              {t('superPage.investLumpSumModal.title')}
            </div>

            <div className="modal-basic__value -with-margin">
              <div
                className={`modal-basic__input ${
                  lumpSumValue > 0 ? '-active' : ''
                }`}
              >
                <NumberFormat
                  value={lumpSumValue || ''}
                  displayType="input"
                  thousandSeparator={THOUSAND_SEPARATOR}
                  decimalSeparator={DECIMAL_SEPARATOR}
                  allowNegative={false}
                  placeholder={`${CURRENCY_SYMBOL}0.00`}
                  decimalScale={2}
                  prefix={CURRENCY_SYMBOL}
                  disabled={showLoader}
                  onValueChange={(values) => {
                    this.changeLumpSumValue(values.floatValue);
                  }}
                />
              </div>
            </div>

            <div className="modal-basic__label">
              <Trans
                i18nKey="superPage.investLumpSumModal.minimumInvestment"
                t={t}
                values={{ currencySymbol: CURRENCY_SYMBOL }}
              />
            </div>

            <div className="time-investment__buttons">
              <DeprecatedButton
                title={formatNumber({ value: 10 })}
                disabled={showLoader}
                buttonClass="button__rounded-transparent"
                handleClick={() => this.changeLumpSumValue(10)}
              />

              <DeprecatedButton
                title={formatNumber({ value: 25 })}
                disabled={showLoader}
                buttonClass="button__rounded-transparent"
                handleClick={() => this.changeLumpSumValue(25)}
              />

              <DeprecatedButton
                title={formatNumber({ value: 50 })}
                disabled={showLoader}
                buttonClass="button__rounded-transparent"
                handleClick={() => this.changeLumpSumValue(50)}
              />

              <DeprecatedButton
                title={formatNumber({ value: 100 })}
                disabled={showLoader}
                buttonClass="button__rounded-transparent"
                handleClick={() => this.changeLumpSumValue(100)}
              />
            </div>
          </div>

          <div className="modal-basic__footer">
            <Button
              isLoading={showLoader}
              isDisabled={!lumpSumValue}
              label={
                <Trans i18nKey="superPage.investLumpSumModal.button" t={t} />
              }
              onClick={() => this.openInvestLumpSumInfoModal(true)}
            />
          </div>
        </Modal>

        {isOpenInvestLumpSumInfoModal && (
          <LumpSumNoteModal
            isOpenInvestLumpSumInfoModal={isOpenInvestLumpSumInfoModal}
            showLoader={showLoader}
            openInvestLumpSumInfoModal={this.openInvestLumpSumInfoModal}
            lumpSumInvest={this.lumpSumInvest}
            lumpSumValue={lumpSumValue}
          />
        )}

        {isOpenInvestmentErrorModal && (
          <InvestmentErrorModal
            isOpen={isOpenInvestmentErrorModal}
            errorMessage={errorMessage}
            onRequestClose={() => this.openInvestmentErrorModal(false)}
            handleClick={() => this.openInvestmentErrorModal(false)}
            shouldCloseOnOverlayClick
          />
        )}
      </div>
    );
  }
}

InvestLumpSumModal.propTypes = {
  openInvestLumpSumModal: PropTypes.func.isRequired,
  isOpenInvestLumpSumModal: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  errorMessage: state.user.errorMessage || state.superAnnuation.errorMessage,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSuper: bindActionCreators(superActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InvestLumpSumModal));

InvestLumpSumModal.defaultProps = {
  errorMessage: '',
  actionsSuper: {},
};

InvestLumpSumModal.propTypes = {
  actionsSuper: PropTypes.shape({
    lumSumInvest: PropTypes.func,
  }),
  errorMessage: PropTypes.string,
};
