import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as userActions from '../../store/user/actions';
import '../../styles/pages/login-page.scss';
import { NEW_PASSWORD_SUCCESS } from '../../store/user/consts';

import ResetPinView from './ResetPinView';

class ResetPin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowPassword: true,
      isShowConfirmPassword: true,
      errorMessage: {},
      showLoader: false,
      showGlobalLoader: false,
    };

    this.toggleShowConfirmPassword = this.toggleShowConfirmPassword.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.toggleLoader = this.toggleLoader.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    const currentLocation = window.location.href;
    const currentLocationUrl = new URL(currentLocation);

    this.setState({ showLoader: true, showGlobalLoader: true });

    actions
      .getSecurityQuestion(
        currentLocationUrl.searchParams.get('reset_pin_token'),
      )
      .then(() => {
        this.setState({ showLoader: false, showGlobalLoader: false });
      });
  }

  handleSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      const { actions, history } = this.props;
      const currentLocation = window.location.href;
      const currentLocationUrl = new URL(currentLocation);
      const { pin } = values;
      const { pinConfirmation } = values;

      const requestData = {
        user: {
          pin,
          pin_confirmation: pinConfirmation,
          security_answer: values.securityAnswer,
          reset_pin_token:
            currentLocationUrl.searchParams.get('reset_pin_token'),
        },
      };

      if (pin && pinConfirmation && !hasValidationErrors) {
        this.toggleLoader(true);

        actions.newPin(requestData).then(() => {
          this.toggleLoader(false);

          const { type, errorMessageReset } = this.props;

          if (type === NEW_PASSWORD_SUCCESS) {
            history.push('/login');
          } else {
            this.setState({ errorMessage: errorMessageReset });
          }
        });
      }
    }
  };

  toggleLoader = (condition) => {
    this.setState({ showLoader: condition });
  };

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  toggleShowConfirmPassword() {
    const { isShowConfirmPassword } = this.state;

    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  }

  render() {
    const { state, questionId, questionText } = this.props;

    const {
      errorMessage,
      isShowPassword,
      isShowConfirmPassword,
      showLoader,
      showGlobalLoader,
    } = this.state;

    return (
      <ResetPinView
        showLoader={showLoader}
        isShowPassword={isShowPassword}
        isShowConfirmPassword={isShowConfirmPassword}
        toggleShowPassword={this.toggleShowPassword}
        toggleShowConfirmPassword={this.toggleShowConfirmPassword}
        errorMessage={errorMessage}
        loginData={state.user.loginData}
        handleSubmit={this.handleSubmit}
        questionText={questionText}
        questionId={questionId}
        showGlobalLoader={showGlobalLoader}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  type: state.user.type,
  loginData: state.user.loginData,
  errorMessageReset: state.user.errorMessageReset,
  questionId: state.user.questionId,
  questionText: state.user.questionText,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPin);

ResetPin.defaultProps = {
  state: {},
  type: '',
  errorMessageReset: {},
  history: {},
  loginData: {},
  questionId: '',
  questionText: '',
};

ResetPin.propTypes = {
  user: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({
    getSecurityQuestion: PropTypes.func,
    newPin: PropTypes.func,
  }).isRequired,
  state: PropTypes.shape({
    user: PropTypes.string,
  }),
  type: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loginData: PropTypes.shape({}),
  errorMessageReset: PropTypes.shape({}),
  questionId: PropTypes.string,
  questionText: PropTypes.string,
};
