import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TransactionCategorisationSkeleton from './components/TransactionCategorisationSkeleton';
import TransactionCategorisationList from './components/TransactionCategorisationList';
import {
  Switcher,
  InfoButton,
  InfoPanel,
  InfoPanelTitle,
  MyFinanceBanner,
  SortButton,
  Title,
  Wrapper,
  SwitcherButton,
  SortButtonWrapper,
  SortIcon,
} from './styles';

import CategorisationInfo from 'components/layout/Modals/CategorisationInfoModal';
import {
  UNCATEGORISED,
  AMOUNT,
  DATE,
  TRANSACTION_CATEGORISATION,
} from 'constants/comonConstants';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';

function TransactionCategorisationView(props) {
  const {
    status,
    changeStatus,
    transactionCategorisation,
    toggleCategorisationInfo,
    showCategorisationInfo,
    transactionAmount,
    transactionDescription,
    transactionMovement,
    types,
    transactionName,
    toggleSort,
    sortType,
    allCategoryDetails,
    transactionCategory,
    transaction,
    isOpenHelpCards,
    toggleHelpCard,
    showLoader,
  } = props;

  const { t } = useTranslation();

  return (
    <Wrapper>
      <MyFinanceBanner>
        <InfoButton handleClick={() => toggleHelpCard(true)} />

        <Title>{t('transactionCategorisationPage.title')}</Title>

        <Switcher>
          <SwitcherButton
            title={t('common.all')}
            active={status === ''}
            handleClick={() => changeStatus('')}
          />

          <SwitcherButton
            title={t('transactionCategorisationPage.uncategorised')}
            active={status === UNCATEGORISED}
            handleClick={() => changeStatus(UNCATEGORISED)}
          />
        </Switcher>

        {sortType === AMOUNT && (
          <SortButtonWrapper>
            <SortIcon iconName="sort" size={50} />

            <SortButton
              title={t('transactionCategorisationPage.byAmountButton')}
              handleClick={() => toggleSort(DATE)}
            />
          </SortButtonWrapper>
        )}

        {sortType === DATE && (
          <SortButtonWrapper>
            <SortIcon iconName="sort" size={50} />

            <SortButton
              title={t('transactionCategorisationPage.byDateButton')}
              handleClick={() => toggleSort(AMOUNT)}
            />
          </SortButtonWrapper>
        )}
      </MyFinanceBanner>

      <InfoPanel>
        <InfoPanelTitle>
          {t('transactionCategorisationPage.info.title')}
        </InfoPanelTitle>

        {showLoader ? (
          <TransactionCategorisationSkeleton />
        ) : (
          <TransactionCategorisationList
            transactionCategorisation={transactionCategorisation}
            toggleCategorisationInfo={toggleCategorisationInfo}
            allCategoryDetails={allCategoryDetails}
          />
        )}
      </InfoPanel>

      <CategorisationInfo
        types={types}
        transaction={transaction}
        transactionMovement={transactionMovement}
        transactionAmount={transactionAmount}
        transactionName={transactionName}
        transactionDescription={transactionDescription}
        transactionCategory={transactionCategory}
        toggleCategorisationInfo={toggleCategorisationInfo}
        showCategorisationInfo={showCategorisationInfo}
      />

      <HelpCardsModalAU
        isOpenHelpCards={isOpenHelpCards}
        currentPage={TRANSACTION_CATEGORISATION}
        toggleHelpCard={toggleHelpCard}
      />
    </Wrapper>
  );
}

export default TransactionCategorisationView;

TransactionCategorisationView.propTypes = {
  transaction: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({}).isRequired,
  changeStatus: PropTypes.func.isRequired,
  transactionAmount: PropTypes.number.isRequired,
  transactionCategorisation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allCategoryDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showCategorisationInfo: PropTypes.bool.isRequired,
  toggleCategorisationInfo: PropTypes.func.isRequired,
  toggleSort: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  transactionCategory: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  transactionMovement: PropTypes.string.isRequired,
  transactionName: PropTypes.string.isRequired,
  transactionDescription: PropTypes.string.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
};
