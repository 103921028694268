import React from 'react';
import PropTypes from 'prop-types';

import {
  ResidentialPropertyFundAddress,
  ResidentialPropertyFundLocation,
} from './styles';

export default function ResidentialPropertyFundItemHeader({
  address,
  city,
  state,
}) {
  return (
    <>
      <ResidentialPropertyFundAddress>{address}</ResidentialPropertyFundAddress>

      <ResidentialPropertyFundLocation>{`${city}, ${state}`}</ResidentialPropertyFundLocation>
    </>
  );
}

ResidentialPropertyFundItemHeader.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};
