import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SUPPORT } from '../../constants/localeConfigs';

import ResetPinForm from './components/ResetPinForm';

export default function ResetPinView({
  handleSubmit,
  showLoader,
  errorMessage,
  loginData,
  toggleShowPassword,
  isShowPassword,
  toggleShowConfirmPassword,
  isShowConfirmPassword,
  questionText,
  questionId,
  showGlobalLoader,
}) {
  const { t } = useTranslation();

  return (
    <div className="login-page">
      <div className="verification-step">
        <div className="get-started">
          <div className="logo" />
        </div>

        <ResetPinForm
          isShowConfirmPassword={isShowConfirmPassword}
          toggleShowConfirmPassword={toggleShowConfirmPassword}
          isShowPassword={isShowPassword}
          toggleShowPassword={toggleShowPassword}
          showLoader={showLoader}
          loginData={loginData}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          questionText={questionText}
          questionId={questionId}
          showGlobalLoader={showGlobalLoader}
        />

        <div className="need-help-contact-u">
          <a href={SUPPORT} target="_blank" rel="noopener noreferrer">
            {t('resetPinPage.contactUsLink')}
          </a>
        </div>
      </div>
    </div>
  );
}

ResetPinView.defaultProps = {};

ResetPinView.propTypes = {
  errorMessage: PropTypes.shape({}).isRequired,
  loginData: PropTypes.shape({}).isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isShowConfirmPassword: PropTypes.bool.isRequired,
  toggleShowConfirmPassword: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  showGlobalLoader: PropTypes.bool.isRequired,
};
