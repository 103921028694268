import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AccountBalance, ChangeValueDashboardWrapper } from './styles';

import { selectSummaryByIndex } from 'store/perfomance/selectors';
import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IChangeValueDashboardProps {
  dataPointIndex: number;
}

export const ChangeValueDashboard = ({
  dataPointIndex,
}: IChangeValueDashboardProps) => {
  const { t } = useTranslation();

  const { balance } = useAppSelector(selectSummaryByIndex(dataPointIndex));

  return (
    <ChangeValueDashboardWrapper>
      {AU_APP && t('performanceDetailsPage.changeInValueM')}

      {MY_APP && (
        <AccountBalance>
          <Trans
            t={t}
            i18nKey="performanceDetailsPage.accountBalance"
            components={{
              span: <span />,
            }}
            values={{
              value: formatNumber({
                value: balance || 0,
                minimumFractionDigits: 2,
              }),
            }}
          />
        </AccountBalance>
      )}
    </ChangeValueDashboardWrapper>
  );
};
