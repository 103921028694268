export const REQUEST_STATUS = {
  INITIAL: {
    isLoading: false,
    isSettled: false,
    isSuccess: false,
    isError: false,
  },
  LOADING: {
    isLoading: true,
    isSettled: false,
    isSuccess: false,
    isError: false,
  },
  SUCCESS: {
    isLoading: false,
    isSettled: true,
    isSuccess: true,
    isError: false,
  },
  ERROR: {
    isLoading: false,
    isSettled: true,
    isSuccess: false,
    isError: true,
  },
};
