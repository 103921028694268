import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import checkBoxCheckedIcon from 'assets/images/ic_check_login.png';
import { Button } from 'components/elements/Button';

export const ConnectionStatusWrapper = styled.div`
  text-align: left;
`;

export const ConnectionStatusContent = styled.div`
  position: relative;
  margin-top: 32px;
`;

export const ConnectionStatusTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  font-weight: ${({ theme }) => theme.fontWeights.ultraBold};
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
`;

export const ConnectionStatusDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
  color: ${({ theme }) => theme.colors.topaz};
  margin-top: 16px;
`;

export const ConnectionStatusCompleted = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.niagaraLight};
  background-image: url(${checkBoxCheckedIcon});
  border-radius: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 12px;
  z-index: ${({ theme }) => theme.zIndexes.elevated};
  flex-shrink: 0;
`;

export const ConnectionStatusNotStarted = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.alto};
  border-radius: 16px;
  flex-shrink: 0;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ConnectionStatusLoading = styled.div`
  background-image: ${({ theme }) => theme.gradients.niagaraConic};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  animation: ${spin} 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.elevated};
  flex-shrink: 0;
`;

export const ConnectionStatusLoadingInside = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 24px;
  width: 24px;
  border-radius: 16px;
`;

export const ConnectionStatusLine = styled.div`
  width: 4px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.alto};
  margin-left: 14px;
`;

export const ConnectionStatusFooter = styled.div`
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 100%;
  display: flex;
`;

export const ConnectionStatusButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mirage};
  border: 2px solid ${({ theme }) => theme.colors.alto};

  width: auto;
  padding: 0 66px;
  margin-right: 16px;
  &:hover,
  &[hover] {
    opacity: ${({ theme }) => theme.opacity.almostOpaque};
  }

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    margin-top: 10px;
  }
`;

export const ConnectionStatusItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ConnectionStatusName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  color: ${({ theme }) => theme.colors.mirage};
  margin-left: 16px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
`;

export const ConnectionStatusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;
