import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  AllocationInputWrapper,
  ChangeEtfWrapper as EditFundWrapper,
  DecreaseButton,
  IncreaseButton,
  AllocationInput,
} from '../styles';

import {
  ALLOCATION_FIELD_REGEX,
  ASSET_PERCENTAGE_CHANGE_VALUE,
  MAX_FUND_ALLOCATION,
  MIN_FUND_ALLOCATION,
} from './consts';
import { useUpdateFundAllocation } from './hooks';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBasePortfolioAllocation,
  selectFundsToUpdate,
  selectFundToUpdateById,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/comonConstants';
import { useWeightingCannotBeIncreasedModal } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';

export default function EditFund({
  id,
  allocation,
  max_allocation,
  inactive_for_super_plus,
  name,
  symbol,
}) {
  const fundToUpdate = useAppSelector(selectFundToUpdateById({ id }));

  const { openWeightingCannotBeIncreasedModal } =
    useWeightingCannotBeIncreasedModal();

  const basePortfolioAllocation = useAppSelector(selectBasePortfolioAllocation);

  const combinedMaxAllocation = fundToUpdate?.max_allocation || max_allocation;
  const fundsToUpdate = useAppSelector(selectFundsToUpdate);
  const isFundRemoved = fundsToUpdate?.length > 0 && !fundToUpdate?.allocation;

  const { isSuper } = useAppSelector(selectPortfolioParams);

  const inputRef = useRef(null);

  const combinedAllocation = isFundRemoved
    ? 0
    : fundToUpdate?.allocation || allocation;

  const allocationWithBaseAllocation = allocation + basePortfolioAllocation;

  const maxAllocation =
    allocationWithBaseAllocation === 0
      ? combinedMaxAllocation
      : Math.min(combinedMaxAllocation, allocationWithBaseAllocation);

  const [currentAllocation, setCurrentAllocation] =
    useState(combinedAllocation);

  const [allocationInputValue, setAllocationInputValue] = useState(
    `${currentAllocation}`,
  );

  const [isEdited, setIsEdited] = useState(false);

  const prevAllocationRef = useRef(currentAllocation);

  const {
    isFundsLoading,
    isCurrentFundLoading,
    updateFundAllocation,
    isAllocationExhausted,
  } = useUpdateFundAllocation({
    id,
    setAllocationInputValue,
    combinedAllocation,
  });

  useEffect(() => {
    if (currentAllocation !== combinedAllocation && !isEdited) {
      setAllocationInputValue(`${combinedAllocation}`);
    }
  }, [
    currentAllocation,
    combinedAllocation,
    setAllocationInputValue,
    isEdited,
  ]);

  const isDisabled = isCurrentFundLoading || isFundsLoading;

  const isMinusButtonDisabled =
    isDisabled || currentAllocation <= MIN_FUND_ALLOCATION;

  const isPlusButtonDisabled =
    isDisabled ||
    isAllocationExhausted ||
    currentAllocation >= MAX_FUND_ALLOCATION ||
    currentAllocation >= maxAllocation ||
    (isSuper && inactive_for_super_plus);

  // Info: we need empty deps array here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAllocationUpdate = useCallback(
    debounce((updatedAllocationValue) => {
      if (updatedAllocationValue !== combinedAllocation) {
        updateFundAllocation({
          updatedAllocationValue,
        });
      }

      setIsEdited(false);

      setAllocationInputValue(`${updatedAllocationValue}`);
    }, DEFAULT_DEBOUNCE_TIME),
    [combinedAllocation],
  );

  useEffect(() => {
    if (currentAllocation !== prevAllocationRef.current) {
      debounceAllocationUpdate(currentAllocation);

      prevAllocationRef.current = currentAllocation;
    }
  }, [currentAllocation, debounceAllocationUpdate, combinedAllocation]);

  useEffect(() => {
    const newAllocation = Number(allocationInputValue);

    setCurrentAllocation(newAllocation);
  }, [allocationInputValue, setCurrentAllocation]);

  const handleDecreaseButtonClick = () => {
    const decreasedAllocationValue =
      currentAllocation - ASSET_PERCENTAGE_CHANGE_VALUE;

    setIsEdited(true);

    setAllocationInputValue(decreasedAllocationValue);
  };

  const handleIncreaseButtonClick = () => {
    const increasedAllocationValue =
      currentAllocation + ASSET_PERCENTAGE_CHANGE_VALUE;

    setIsEdited(true);

    setAllocationInputValue(increasedAllocationValue);
  };

  const handleAllocationInputChange = (e) => {
    const newAllocationString = e.target?.value;

    if (!ALLOCATION_FIELD_REGEX.test(newAllocationString)) {
      return;
    }

    if (
      Number(newAllocationString) < MIN_FUND_ALLOCATION ||
      Number(newAllocationString) > MAX_FUND_ALLOCATION
    ) {
      return;
    }

    setIsEdited(true);

    setAllocationInputValue(newAllocationString);
  };

  return (
    <EditFundWrapper>
      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <DecreaseButton
        className="-exclude"
        onClick={handleDecreaseButtonClick}
        disabled={isMinusButtonDisabled}
      />

      {isCurrentFundLoading ? (
        <DotsLoader size={13.5} />
      ) : (
        <AllocationInputWrapper>
          <AllocationInput
            type="text"
            value={allocationInputValue}
            disabled={isDisabled}
            onChange={(e) => {
              if (isSuper && inactive_for_super_plus) return;

              handleAllocationInputChange(e);
            }}
            onClick={() => {
              if (isSuper && inactive_for_super_plus) {
                openWeightingCannotBeIncreasedModal({ name, symbol });

                inputRef.current.blur();
              }
            }}
            ref={inputRef}
          />
          %
        </AllocationInputWrapper>
      )}

      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <IncreaseButton
        className="-exclude"
        onClick={handleIncreaseButtonClick}
        disabled={isPlusButtonDisabled}
      />
    </EditFundWrapper>
  );
}

EditFund.defaultProps = {
  allocation: 0,
  max_allocation: MAX_FUND_ALLOCATION,
  inactive_for_super_plus: false,
};

EditFund.propTypes = {
  allocation: PropTypes.number,
  id: PropTypes.string.isRequired,
  max_allocation: PropTypes.number,
  inactive_for_super_plus: PropTypes.bool,
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
};
