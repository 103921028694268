import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { Label } from '../styles';
import { ICustomWrapper } from '../types';

import { Select, SelectError, SelectWrapper } from './styles';

interface ISelectFieldProps<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  options: { value: string; label: string }[];
  CustomSelectWrapper?: React.FC<ICustomWrapper>;
  CustomSelectLabel?: React.FC<ICustomWrapper>;
  placeholder?: string;
  searchable?: boolean;
  onDropdownOpen?: () => void;
}

export const SelectField = <T extends FieldValues>({
  name,
  label,
  options,
  CustomSelectWrapper = SelectWrapper,
  CustomSelectLabel = Label,
  placeholder,
  searchable = true,
  onDropdownOpen,
}: ISelectFieldProps<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <CustomSelectWrapper>
          {label && <CustomSelectLabel>{label}</CustomSelectLabel>}

          <Select
            name={name}
            onChange={onChange}
            options={options}
            values={value || []}
            placeholder={placeholder}
            searchable={searchable}
            onDropdownOpen={onDropdownOpen}
          />

          {!!error?.message && <SelectError>{error?.message}</SelectError>}
        </CustomSelectWrapper>
      )}
    />
  );
};
