import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePasswordMigrationSchema = () => {
  const { t } = useTranslation();

  return yup
    .object({
      password: yup
        .string()
        .min(8, t('fields.validation.minimum8Characters'))
        .matches(/[A-Z]/, t('fields.validation.shouldContain'))
        .matches(/[a-z]/, t('fields.validation.shouldContainLetter'))
        .matches(/[0-9]/, t('fields.validation.shouldContainOne'))
        .required(),
      confirm_password: yup
        .string()
        .label(t('fields.confirmPassword.label'))
        .required()
        .oneOf([yup.ref('password')], t('fields.validation.passwordMatch')),
    })
    .required();
};
