import { showGlobalError } from '../errors/actions';
import { getHeaders } from '../../services/api/headers';

import { GET_GIFTS_ICONS_SUCCESS, SHOW_ERROR_GET_GIFTS_ICONS } from './types';

export function getGiftsIconsSuccess(receiverIcons) {
  return { type: GET_GIFTS_ICONS_SUCCESS, receiverIcons };
}

export function showErrorGetGiftsIcons(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_GIFTS_ICONS, errorMessage, errorTitle };
}

export function getGiftsIcons() {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/v2/gifts/receiver_icons`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetGiftsIcons(response.error));
        } else {
          dispatch(getGiftsIconsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
