export const INSURANCE_STATUS = {
  opted_in: 'opted_in',
  opted_out: 'opted_out',
  requested_opted_in: 'requested_opted_in',
  requested_opted_out: 'requested_opted_out',
} as const;

export const INSURANCE_STATUS_TRANSLATE_KEYS = {
  [INSURANCE_STATUS.opted_in]: 'optedIn',
  [INSURANCE_STATUS.opted_out]: 'optedOut',
  [INSURANCE_STATUS.requested_opted_in]: 'requestedOptedIn',
  [INSURANCE_STATUS.requested_opted_out]: 'requestedOptedOut',
} as const;

export const SUPER_MEMBER_GUIDE_LINK =
  'https://raizinvest.com.au/super-product-disclosure-statement';

export const COVERAGE_INFO_MAX_WIDTH = 340;
