import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.dustyGray};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['3sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
`;
