import { defineMessages } from 'react-intl';

import { RECURRING_WIDGET as idPrefix } from '../../../../constants/translates';

const messages = defineMessages({
  recurringInvestments: {
    id: `${idPrefix}.recurringInvestments`,
    defaultMessage: 'Recurring Investments',
  },
  linkFunding: {
    id: `${idPrefix}.linkFunding`,
    defaultMessage:
      'First, link your Funding Account and then set up a daily, weekly, or monthly recurring investment',
  },
  linkFundingAccount: {
    id: `${idPrefix}.linkFundingAccount`,
    defaultMessage: 'Link Funding Account',
  },
});

export default messages;
