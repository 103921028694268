import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import lightningHelpCard from 'assets/images/icons/lightning-help-card.svg';

const AUTOMATIC_REWARDS_HEIGHT = 200;

export const BankSignInButton = styled(Button)`
  margin-bottom: 32px;
`;

export const AutomaticRewardsWrapper = styled.div`
  height: ${AUTOMATIC_REWARDS_HEIGHT}px;
  padding-top: 17px;
  margin-bottom: 20px;
  text-align: center;
  ${({ theme }) => `
    background: url(${lightningHelpCard}) no-repeat center 74px,
    ${theme.gradients.fierySunshine};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.lg};
    font-family: ${theme.fontFamily.textMedium};
  `}
`;
