import styled from '@emotion/styled';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkeletonCheckBox = styled.div`
  padding: 16px 0;
  height: 68px;
  width: 40px;
  float: left;
  margin-right: 14px;
`;

export const SkeletonCheckboxWrapper = styled.div`
  height: 56px;
  :first-of-type {
    margin-top: -16px;
  }
`;

export const SkeletonButton = styled.div`
  max-width: 240px;
  margin-top: 46px;
`;

export const SkeletonCheckBoxLabel = styled.div`
  padding: 24px 0;
  height: 62px;
`;
