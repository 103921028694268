import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { StopInvestmentButtonWrapper } from '../styles';

import DeleteInvestmentGoalModal from './DeleteInvestmentGoalModal';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectDependencyUsersLoading } from 'store/dependentUser/selectors';

interface IStopInvestmentButton {
  investmentGoalKey: string;
}

export default function StopInvestmentGoalButton({
  investmentGoalKey,
}: IStopInvestmentButton) {
  const { t } = useTranslation();

  const isLoading = useAppSelector(selectDependencyUsersLoading);

  const [
    isDeleteInvestmentGoalModalVisible,
    setIsDeleteInvestmentGoalModalVisible,
  ] = useState(false);

  return (
    <>
      <StopInvestmentButtonWrapper
        isLoading={isLoading}
        onClick={() => setIsDeleteInvestmentGoalModalVisible(true)}
        label={t('raizKidsEditPage.investmentGoal.deleteInvestmentGoalButton')}
        variant="initial"
      />

      <DeleteInvestmentGoalModal
        isDeleteInvestmentGoalModalVisible={isDeleteInvestmentGoalModalVisible}
        setIsDeleteInvestmentGoalModalVisible={
          setIsDeleteInvestmentGoalModalVisible
        }
        investmentGoalKey={investmentGoalKey}
      />
    </>
  );
}
