import styled from '@emotion/styled';

const CARD_WIDTH = 320;
const CARD_HEIGHT = 200;

export const Card = styled.div`
  background: ${({ theme }) => theme.gradients.lightGray};
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  border-radius: 12px;
  margin-top: 20px;
  margin-left: 20px;
  font-family: ${({ theme }) => theme.fontFamily.card};
  color: ${({ theme }) => theme.colors.white};
  float: left;
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardNumber = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 25px;
`;

export const CardExpiration = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  padding: 8px;
`;

export const CardName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  text-align: left;
`;

export const Badge = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.textRegular};
    color: ${theme.colors.white};
    background-color: ${theme.colors.grenadier};
    font-size: ${theme.fontSizes.sm};
  `}
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;
