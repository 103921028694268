import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'constants/paths';
import { resetSetNewPassword } from 'store/migration/actions';
import { selectSetNewPassword } from 'store/migration/selector';

export const useNavigateOnSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSettled, isSuccess } = useSelector(selectSetNewPassword);

  useEffect(() => {
    if (isSettled) {
      dispatch(resetSetNewPassword());

      if (isSuccess) {
        navigate(PATHS.migration.account.pds);
      }
    }
  }, [isSuccess, dispatch, navigate, isSettled]);
};
