import { useTranslation } from 'react-i18next';
import { useMatch, generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { useRecurringInvestmentFrequency } from '../utils';
import { useStopRecurringInvestment } from '../hooks';

import { useRecurringInvestment } from './useRecurringInvestment';

import { PATHS } from 'constants/paths';
import { toggleInfoModal } from 'store/modals/actions';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useStopRecurringZakatPayment } from 'pages/RewardDetails/subpages/RecurringZakatPayment/hooks/useStopRecurringZakatPayment';

export const useStopInvestmentConfirmModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { viewMode } = useParams();

  const { recurring } = useRecurringInvestment();
  const { frequency_identifier, amount } = recurring || {};

  const recurringInvestmentFrequency =
    useRecurringInvestmentFrequency(frequency_identifier);

  const handleStopRecurringZakatPayment = useStopRecurringZakatPayment();
  const { handleStopRecurringInvestment } = useStopRecurringInvestment();

  const isRecurringZakatPayment = !!useMatch(
    generatePath(PATHS.rewards.recurringZakatPayment, {
      viewMode: viewMode || null,
    }),
  );

  const translationKey = isRecurringZakatPayment
    ? 'recurringZakatPaymentPage.recurringZakatPaymentForm'
    : 'raizKidsEditPage.recurringInvestmentForm';

  return () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(`${translationKey}.stopInvestmentConfirmModal.title`),
          description: t(
            `${translationKey}.stopInvestmentConfirmModal.description`,
            {
              amount: formatNumber({ value: amount }),
              frequency: recurringInvestmentFrequency,
            },
          ),
          acceptButtonText: t('common.stop'),
          rejectButtonText: t('common.cancel'),
          onAccept: () =>
            isRecurringZakatPayment
              ? handleStopRecurringZakatPayment()
              : handleStopRecurringInvestment(),
        },
      }),
    );
  };
};
