import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { KIDS_ACCESS_REJECTION_SOURCE } from './consts';

export const useKidsAccessRejectionReason = (rejectionSource: string) => {
  const { t } = useTranslation();

  const getKidsAccessRejectionReason = useMemo(() => {
    switch (rejectionSource) {
      case KIDS_ACCESS_REJECTION_SOURCE.recurring:
        return t('kidsAccessRejectionModal.description', {
          action: t(
            `kidsAccessRejectionModal.rejectionReasons.${KIDS_ACCESS_REJECTION_SOURCE.recurring}`,
          ),
        });
      case KIDS_ACCESS_REJECTION_SOURCE.invest:
        return t('kidsAccessRejectionModal.description', {
          action: t(
            `kidsAccessRejectionModal.rejectionReasons.${KIDS_ACCESS_REJECTION_SOURCE.invest}`,
          ),
        });
      case KIDS_ACCESS_REJECTION_SOURCE.rewards:
        return t('kidsAccessRejectionModal.description', {
          action: t(
            `kidsAccessRejectionModal.rejectionReasons.${KIDS_ACCESS_REJECTION_SOURCE.rewards}`,
          ),
        });
      default:
        return '';
    }
  }, [rejectionSource, t]);

  return { getKidsAccessRejectionReason };
};
