import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { ACTIONS_TYPE } from './consts';
import { IChangePasswordResponse } from './types';

import { displayErrorModal } from 'store/modals/actions';
import { BASE_API_URL } from 'libs/axios/consts';
import { MIGRATION } from 'constants/migrationConstants';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export const setNewPasswordLoading = () => ({
  type: ACTIONS_TYPE.SET_NEW_PASSWORD_LOADING,
});

export const setNewPasswordSuccess = () => ({
  type: ACTIONS_TYPE.SET_NEW_PASSWORD_SUCCESS,
});

export const setNewPasswordError = (error: unknown) => ({
  type: ACTIONS_TYPE.SET_NEW_PASSWORD_ERROR,
  error,
});

export const resetSetNewPassword = () => ({
  type: ACTIONS_TYPE.RESET_SET_NEW_PASSWORD,
});

export const setNewPassword =
  ({ password }: { password: string }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setNewPasswordLoading());

      const migrationToken = sessionStorage.getItem(MIGRATION.token);

      if (!migrationToken) {
        throw new Error('Migration token is not defined');
      }

      await axios({
        method: 'put',
        url: `${BASE_API_URL}/user/change_password`,
        data: {
          password,
          udid: window.crypto.randomUUID(),
        },
        headers: {
          Authorization: `token ${migrationToken}`,
        },
      }).then((response: IChangePasswordResponse) => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.token, response.data.token);
      });

      dispatch(setNewPasswordSuccess());
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          displayErrorModal({ errorMessage: error.response?.data?.error }),
        );
      } else if (error instanceof Error) {
        dispatch(displayErrorModal({ errorMessage: error.message }));
      }

      dispatch(setNewPasswordError(error));
    }
  };
