import Axios from 'axios';
import QueryString from 'qs';

import {
  createErrorInterceptor,
  responseInterceptor,
} from './axiosInterceptors';
import validateStatus from './utils/validateStatus';
import { BASE_API_URL, OFFLINE_ERROR, REQUEST_TIMEOUT } from './consts';

const createAxiosInstance = ({
  baseURL,
  timeout,
  handleError,
  requestInterceptor,
} = {}) => {
  if (!navigator.onLine) throw new Error(OFFLINE_ERROR);

  const axiosInstance = Axios.create({
    baseURL: baseURL || BASE_API_URL,
    timeout: timeout || REQUEST_TIMEOUT,
    validateStatus,
    paramsSerializer: {
      serialize: (params) =>
        QueryString.stringify(params, {
          arrayFormat: 'brackets',
        }),
    },
  });

  // Request handling
  axiosInstance.interceptors.request.use(
    requestInterceptor,
    createErrorInterceptor(handleError),
  );

  // Response handling
  axiosInstance.interceptors.response.use(
    responseInterceptor,
    createErrorInterceptor(handleError),
  );

  return axiosInstance;
};

export default createAxiosInstance;
