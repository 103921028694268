import 'styles/pages/change-portfolio.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import Slider from 'react-rangeslider';
import { Animate } from 'react-animate-mount';
import Modal from 'react-modal';
import { Form } from 'react-final-form';

import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { MY_APP, CURRENCY_SYMBOL, AU_APP } from 'constants/localeConfigs';
import 'react-rangeslider/lib/index.css';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';
import {
  selectCustomPortfolioCategories,
  selectCustomPortfolioEtfs,
} from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

function getSeriesCustom(categories) {
  let series = [100];

  if (categories.length !== 0) {
    series = categories.map((etf) => etf.percentage);

    return series;
  }

  return series;
}

function getColors(categories) {
  let colors = ['#285bc6'];

  if (categories.length !== 0) {
    colors = categories.map((etf) => etf.colour_background);

    return colors;
  }

  return colors;
}

function getMeasurements(isRegistration, isShowRecommendedPortfolio) {
  const windowWidth = window.innerWidth;
  let measurements = {
    width: '100%',
    height: '100%',
  };

  if (isRegistration) {
    measurements = {
      width: '80%',
      height: 'auto',
    };
  } else if (windowWidth > 1600) {
    measurements = {
      width: '276px',
      height: '276px',
    };
  } else if (windowWidth < 1100) {
    measurements = {
      width: '276px',
      height: '276px',
    };
  } else if (windowWidth < 768) {
    measurements = {
      width: '420px',
      height: '420px',
    };
  } else if (windowWidth < 320) {
    measurements = {
      width: '400px',
      height: '400px',
    };
  } else {
    measurements = {
      width: '276px',
      height: '276px',
    };
  }

  if (isRegistration && isShowRecommendedPortfolio) {
    measurements = {
      width: '276px',
      height: 'auto',
    };
  }

  return measurements;
}

function getCustomEtf(
  etf,
  activeFunds,
  clickOnFund,
  t,
  changeFund,
  onChangeComplete,
  isEditCustom,
) {
  if (!activeFunds || !etf.id || !activeFunds[etf.id]) {
    return <div />;
  }

  const symb = '$';
  const string = 'amount';
  const replaceString = `${symb}{${string}}`;

  return (
    <div
      className={etf.balancing ? 'fund balancing' : 'fund'}
      key={etf.id}
      style={{
        background: ` linear-gradient(135deg, ${etf.colour_background} 0%, ${etf.colour_gradient} 100%)`,
      }}
    >
      <div className="header-fund">
        <div className="name">
          {etf.symbol}

          {' - '}

          {etf.label}
        </div>

        <div className="percent">{activeFunds[etf.id].percent.toFixed(0)}%</div>
      </div>

      {isEditCustom && (
        <div className="body-fund">
          <Animate show={activeFunds[etf.id].show}>
            <div>
              <div className="risk-panel">
                <div>
                  {t('changePortfolioPage.class')}

                  <br />

                  <span>{etf.category}</span>
                </div>

                <div>
                  {t('changePortfolioPage.risk')}

                  <br />

                  <span>{etf.risk}</span>
                </div>

                {!AU_APP &&
                  activeFunds[etf.id].min &&
                  activeFunds[etf.id].min !== 100 && (
                    <div>
                      {t('common.min')}

                      <br />

                      <span>{activeFunds[etf.id].min}%</span>
                    </div>
                  )}

                <div>
                  {t('common.max')}

                  <br />

                  <span>{etf.max_allocation}%</span>
                </div>
              </div>

              <div />
            </div>

            <div className="description">
              {etf.description.replace(
                replaceString,
                CURRENCY_SYMBOL + activeFunds[etf.id].percent / 100,
              )}
            </div>

            <div>
              <Slider
                value={parseFloat(activeFunds[etf.id].percent).toFixed(0) * 1}
                orientation="horizontal"
                min={0}
                max={activeFunds[etf.id].max}
                onChange={(value) => changeFund(value, etf.id)}
                onChangeComplete={() =>
                  onChangeComplete({ isEditCustom: false })
                }
                onKeyPress={() => {}}
                className={`range-slider -without-steps ${
                  parseFloat(activeFunds[etf.id].percent) > 70
                    ? '-right-align'
                    : ''
                }`}
                tooltip={false}
              />
            </div>
          </Animate>
        </div>
      )}

      {isEditCustom && (
        <div
          key={etf.id}
          onKeyPress={() => {}}
          tabIndex="0"
          onClick={() => clickOnFund(etf.id)}
          role="button"
          className="arrow-btn"
        >
          <div
            className={activeFunds[etf.id].show ? 'arrow-up' : 'arrow-down'}
          />
        </div>
      )}
    </div>
  );
}

function getCustomEtfs(
  portfolioFunds,
  activeFunds,
  clickOnFund,
  t,
  changeFund,
  onChangeComplete,
  isEditCustom,
) {
  if (portfolioFunds.length !== 0) {
    return (
      <div>
        {portfolioFunds.map((etf) =>
          getCustomEtf(
            etf,
            activeFunds,
            clickOnFund,
            t,
            changeFund,
            onChangeComplete,
            isEditCustom,
          ),
        )}
      </div>
    );
  }

  return <div />;
}

function getLabel(categories, balancingEtf) {
  let category = [];

  if (categories.length !== 0) {
    category = categories.map((cat) => cat.category);

    return category;
  }

  if (balancingEtf !== '') {
    return [balancingEtf];
  }

  return category;
}

export default function ChangePortfolioCustomView({
  portfolioType,
  portfolios,
  isDashboard,
  showLoader,
  isFuture,
  isRegistration,
  isShowRecommendedPortfolio,
  portfolioFunds,
  customPortfolio,
  activeFunds,
  clickOnFund,
  changeFund,
  onChangeComplete,
  isEditCustom,
  customEtfs,
  errorMessageCustom,
  handleCloseModal,
  isShowModalAllocation,
  changeModalAllocation,
  dontShowModalAllocation,
  balancingEtf,
}) {
  const { t } = useTranslation();

  const customPortfolioCategories = useAppSelector(
    selectCustomPortfolioCategories,
  );

  const customPortfolioEtfs = useAppSelector(selectCustomPortfolioEtfs);

  const measurements = getMeasurements(
    isRegistration,
    isShowRecommendedPortfolio,
  );

  const pieWidth = measurements.width;
  const pieHeight = measurements.height;
  const pie = document.querySelector('.portfolio-page');
  let pieFieldWidth = '100%';

  if (pie) {
    pieFieldWidth = pie.offsetWidth;
  }

  const customCategories = customPortfolio?.portfolio?.categories || [];

  const defaultCategories = customPortfolioEtfs.length
    ? customPortfolioEtfs
    : customPortfolioCategories;

  const categories =
    customCategories?.length > 0 ? customCategories : defaultCategories;

  let risk = 0;

  if (
    customPortfolio &&
    customPortfolio.portfolio &&
    customPortfolio.portfolio.risk
  ) {
    ({ risk } = customPortfolio.portfolio);
  }

  return (
    <div className={`portfolio ${!isDashboard ? portfolioType : ''}`}>
      {portfolios.length !== 0 && activeFunds.length !== 0 && !showLoader ? (
        <div className={isRegistration ? 'registration-portfolio' : ''}>
          {activeFunds && (
            <div className="chart-content chart-custom">
              {!isDashboard &&
                !isFuture &&
                ((MY_APP && !isShowRecommendedPortfolio) || !MY_APP) && (
                  <Chart
                    options={{
                      labels: getLabel(categories, balancingEtf),
                      legend: {
                        show: false,
                        position: isRegistration ? 'bottom' : 'top',
                        markers: {
                          width: 0,
                        },
                        width: pieFieldWidth,
                      },
                      tooltip: {
                        shared: false,
                        marker: {
                          show: false,
                        },
                      },
                      colors: getColors(categories),
                      fill: {
                        colors: getColors(categories),
                      },
                    }}
                    series={getSeriesCustom(
                      categories,
                      portfolioType,
                      portfolios,
                    )}
                    type="pie"
                    width={pieWidth}
                    height={pieHeight}
                  />
                )}

              <div className="risk-block">
                <div className="risk-scale">
                  {t('changePortfolioPage.riskScale')}
                </div>

                <Slider
                  value={parseFloat(risk)}
                  orientation="horizontal"
                  min={0}
                  max={100}
                  labels={{
                    0: t('common.low'),
                    100: t('common.high'),
                  }}
                  className={`range-slider -without-steps ${
                    parseFloat(risk) > 70 ? '-right-align' : ''
                  }
                  `}
                  tooltip={false}
                />
              </div>
            </div>
          )}

          <div className="custom-etf">
            {getCustomEtfs(
              !isEditCustom ? customEtfs : portfolioFunds,
              activeFunds,
              clickOnFund,
              t,
              changeFund,
              onChangeComplete,
              isEditCustom,
            )}
          </div>
        </div>
      ) : (
        <DeprecatedDotsLoader />
      )}

      {isShowModalAllocation && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            <div className="alert-text">
              {t('changePortfolioPage.beforeYou')}

              <Form
                onSubmit={() => {}}
                render={() => (
                  <form
                    className="form allocation-modal"
                    onSubmit={handleCloseModal}
                  >
                    <div className="form-field radio">
                      <CheckboxFF
                        name="modalAllocation"
                        className="modal-allocation"
                        defaultChecked={dontShowModalAllocation}
                        type="checkbox"
                        label={t('changePortfolioPage.dontShow')}
                        onClick={changeModalAllocation}
                      />
                    </div>
                  </form>
                )}
              />
            </div>

            <div className="error-buttons">
              <DeprecatedButton
                title={t('common.ok')}
                type="button"
                handleClick={handleCloseModal}
              />
            </div>
          </div>
        </Modal>
      )}

      {errorMessageCustom !== '' && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            <div className="alert-text">{errorMessageCustom}</div>

            <div className="error-buttons">
              <DeprecatedButton
                title={t('common.ok')}
                type="button"
                handleClick={handleCloseModal}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

ChangePortfolioCustomView.defaultProps = {
  showLoader: true,
};

ChangePortfolioCustomView.propTypes = {
  portfolioType: PropTypes.string.isRequired,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDashboard: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  isFuture: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool.isRequired,
  isShowRecommendedPortfolio: PropTypes.bool.isRequired,
  portfolioFunds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customPortfolio: PropTypes.shape({
    portfolio: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.shape({})),
      risk: PropTypes.number,
      etfs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  activeFunds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clickOnFund: PropTypes.func.isRequired,
  changeFund: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
  isEditCustom: PropTypes.bool.isRequired,
  customEtfs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorMessageCustom: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isShowModalAllocation: PropTypes.bool.isRequired,
  changeModalAllocation: PropTypes.func.isRequired,
  dontShowModalAllocation: PropTypes.bool.isRequired,
  balancingEtf: PropTypes.string.isRequired,
};
