import { useSelector } from 'react-redux';

import { HOME_WIDGETS_COLUMNS } from '../../consts';
import { SUPER_ON } from '../../../../constants/togglesFunctions';
import { GET_TIPS_BY_PATTERN_SUCCESS } from '../../../../store/spendingAnalytics/types';

import { KIDS_ACCESS } from './consts';
import { getWidgetColumns } from './utils';

import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { checkIsDependentUser, checkIsSmsfUser } from 'utils/user';

export const useWidgetsOrder = () => {
  const { user } = useSelector((state) => ({
    user: state.user.user || {},
  }));

  const isDependentUser = checkIsDependentUser(user);
  const isSmsfUser = checkIsSmsfUser(user);

  if (isSmsfUser || isDependentUser) {
    return HOME_WIDGETS_COLUMNS.smfsUser;
  }

  if (MY_APP) {
    return HOME_WIDGETS_COLUMNS.mys;
  }

  return HOME_WIDGETS_COLUMNS.default;
};

export const useWidgetsColumns = () => {
  const { user, features, isBlogWidgetEnabled, tips, type } = useSelector(
    (state) => ({
      user: state.user.user || {},
      kidsList: state.dependencyUsers.dependency_users || [],
      offsetters: state.rewards.offsetters || {},
      features: state.user.features || {},
      tips: state.spendingAnalytics.tips || [],
      type: state.spendingAnalytics.type || '',
      isBlogWidgetEnabled: !state.user.isHideBlog || true,
    }),
  );

  const { sales, super_annuation_user, email } = user;
  const { mtCardsOn } = features;
  const kidsAccessData = JSON.parse(sessionStorage.getItem(KIDS_ACCESS));
  const isDependentUser = checkIsDependentUser(user);
  const isSmsfUser = checkIsSmsfUser(user);

  const areOffsettersEnabled =
    !isDependentUser &&
    !sales?.includes(process.env.REACT_APP_OFFSETTERS_CAMPAIGN_ID);

  const isDependentAccess = !isDependentUser || kidsAccessData?.investing;
  const hasTips = tips?.length > 0;

  const isRaizSuperEnabled =
    SUPER_ON && !isDependentUser && !isSmsfUser && !super_annuation_user;

  const isMTCardEnabled =
    mtCardsOn && email && type === GET_TIPS_BY_PATTERN_SUCCESS && hasTips;

  const isMyFinanceEnabled = isDependentAccess && !(MY_APP && isDependentUser);

  const widgetsOrder = useWidgetsOrder();

  const isPerfomanceEnabled = AU_APP;

  const isRaizRewardsEnabled = MY_APP;

  const config = {
    isMTCardEnabled,
    isRaizSuperEnabled,
    isMyFinanceEnabled,
    areOffsettersEnabled,
    isBlogWidgetEnabled,
    isPerfomanceEnabled,
    isRaizRewardsEnabled,
  };

  return getWidgetColumns({ widgetsOrder, config });
};
