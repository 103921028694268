import { HOME_WIDGETS } from 'pages/Home/consts';
import { screenWidths } from 'theme/mediaQuery';

export const checkIsWidgetEnabled = ({
  widgetName,
  config: {
    isMTCardEnabled,
    isRaizSuperEnabled,
    isMyFinanceEnabled,
    areOffsettersEnabled,
    isBlogWidgetEnabled,
    isPerfomanceEnabled,
    isRaizRewardsEnabled,
  } = {},
}) => {
  switch (widgetName) {
    case HOME_WIDGETS.blogs:
      return isBlogWidgetEnabled;

    case HOME_WIDGETS.perfomance:
      return isPerfomanceEnabled;

    case HOME_WIDGETS.raizRewards:
      return isRaizRewardsEnabled;

    case HOME_WIDGETS.raizKids:
      return true;

    case HOME_WIDGETS.oneTimeInvestments:
      return true;

    case HOME_WIDGETS.mtCards:
      return isMTCardEnabled;

    case HOME_WIDGETS.offsetters:
      return areOffsettersEnabled;

    case HOME_WIDGETS.raizSuper:
      return isRaizSuperEnabled;

    case HOME_WIDGETS.myFinance:
      return isMyFinanceEnabled;

    default:
      return true;
  }
};

export const getWidgetColumns = ({ widgetsOrder, config }) =>
  widgetsOrder.reduce(
    (columns, widgetName) => {
      const isWidgetEnabled = checkIsWidgetEnabled({ widgetName, config });
      const { leftColumn, rightColumn } = columns;
      const isInOneColumn = window.innerWidth <= screenWidths.small;

      if (!isWidgetEnabled) return columns;

      if (isInOneColumn) {
        return {
          leftColumn: [...leftColumn, widgetName],
        };
      }

      if (leftColumn.length <= rightColumn.length) {
        return {
          leftColumn: [...leftColumn, widgetName],
          rightColumn,
        };
      }

      return {
        leftColumn,
        rightColumn: [...rightColumn, widgetName],
      };
    },
    {
      leftColumn: [],
      rightColumn: [],
    },
  );
