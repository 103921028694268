import styled from '@emotion/styled';

import { WidgetContent, WidgetHeader } from '../styles';

export const Content = styled(WidgetContent)`
  background: ${({ theme }) => theme.gradients.fierySunshine};
`;

export const Header = styled(WidgetHeader)`
  margin: 16px 0 30px;
`;
