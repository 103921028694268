import { useEffect, useCallback } from 'react';
import { useMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SUCCESS_OR_FAILURE_TRANSACTION_PATHS } from './const';

import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export type ITranslationsPath =
  keyof typeof SUCCESS_OR_FAILURE_TRANSACTION_PATHS;
interface IUseSpendingAccountPaymentLinkProps {
  successUrl: string;
  failureUrl: string;
  translationsPath: ITranslationsPath;
  navigatePath: string;
}

export const useSuccessOrFailureModal = ({
  successUrl,
  failureUrl,
  translationsPath,
  navigatePath,
}: IUseSpendingAccountPaymentLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSuccess = !!useMatch(successUrl);

  const isFailed = !!useMatch(failureUrl);

  const isInfoModalVisible = isSuccess || isFailed;

  const handleCloseModal = useCallback(() => {
    navigate({
      pathname: navigatePath,
    });
  }, [navigate, navigatePath]);

  const linkAccountTranslateKey = isSuccess
    ? 'paymentSuccessModal'
    : 'paymentFailedModal';

  useEffect(() => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible,
        config: {
          title: t(`${translationsPath}.${linkAccountTranslateKey}.title`),
          description: t(
            `${translationsPath}.${linkAccountTranslateKey}.description`,
          ),
          onAccept: handleCloseModal,
          onReject: handleCloseModal,
        },
      }),
    );
  }, [
    dispatch,
    isInfoModalVisible,
    linkAccountTranslateKey,
    handleCloseModal,
    t,
    translationsPath,
  ]);
};
