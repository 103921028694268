import { useTranslation } from 'react-i18next';

import { AddBankButton, Item, ItemText, UnlinkButton } from './styles';
import { useUnlinkConfirmationModal } from './useUnlinkConfirmationModal';
import { useOpenBankGridView } from './useOpenBankGridView';

import { IAccount } from 'store/spendingAccounts';
import { Icon } from 'components/elements/Icon';
import { useDeleteCardsMutation } from 'store/cards';
import { getCardNumberPlaceholder } from 'utils/getCardNumberPlaceholder';
import { BANK_GRID_MODE_KEYS } from 'pages/Settings/consts';

interface IVisaAccountItemProps {
  account: IAccount;
  hasNoBank?: boolean;
  bankName: string | null;
}

export const VisaAccountItem = ({
  account,
  bankName,
  hasNoBank,
}: IVisaAccountItemProps) => {
  const [deleteCards, { isLoading }] = useDeleteCardsMutation();

  const openUnlinkConfirmationModal = useUnlinkConfirmationModal({
    bankName,
    onAccept: () => deleteCards({ accountId: account.id }),
  });

  const openBankGridView = useOpenBankGridView();

  const { t } = useTranslation();

  return (
    <Item>
      <Icon iconName="visaAlt" />

      <ItemText>
        {account.last_4 ? getCardNumberPlaceholder(account.last_4) : null}
      </ItemText>

      {hasNoBank && (
        <AddBankButton
          icon={{
            name: 'add',
            size: 16,
          }}
          label={t('settingsPage.bank')}
          onClick={() =>
            openBankGridView({
              bankGridMode: BANK_GRID_MODE_KEYS.selection,
              id: account.id,
            })
          }
          isDisabled={isLoading}
        />
      )}

      <UnlinkButton
        label={t('common.unlink')}
        onClick={openUnlinkConfirmationModal}
        isDisabled={isLoading}
        variant="text"
      />
    </Item>
  );
};
