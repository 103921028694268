import { useTranslation } from 'react-i18next';

import { PORTFOLIO_CUSTOMIZATION_TABS } from '../../../../consts';
import { usePortfolioCustomizationRoute } from '../../../../hooks';
import { EmptyFundListButton, EmptyFundListWrapper } from '../styles';

import EmptyFilteredFundList from './EmptyFilteredFundList';

import { useActiveTab } from 'hooks/useActiveTab';

interface EmptyFundListProps {
  isListFiltered: boolean;
}

export default function EmptyFundList({ isListFiltered }: EmptyFundListProps) {
  const { t } = useTranslation();
  const { setActiveTab } = useActiveTab();
  const { fundTag } = usePortfolioCustomizationRoute();

  if (isListFiltered) return <EmptyFilteredFundList />;

  return (
    <EmptyFundListWrapper>
      {t('portfolioCustomization.emptyList.title.nonFiltered', { fundTag })}

      <EmptyFundListButton
        label={t('portfolioCustomization.emptyList.button.title', {
          fundTag,
        })}
        onClick={() => setActiveTab(PORTFOLIO_CUSTOMIZATION_TABS.explore)}
      />
    </EmptyFundListWrapper>
  );
}
