import React from 'react';
import { useTranslation } from 'react-i18next';

import { IContentProps } from './types';
import { CheckboxContent, CheckboxMainWrapper } from './styles';
import { CheckboxSkeleton } from './CheckboxSkeleton';

import Checkbox from 'components/elements/Checkbox/Checkbox';

export const CheckboxComponent = ({
  showLoader,
  transferRewards,
  togglePersonalContribution,
}: IContentProps) => {
  const { t } = useTranslation();

  return (
    <CheckboxMainWrapper>
      {showLoader ? (
        <CheckboxSkeleton />
      ) : (
        <CheckboxContent>
          <Checkbox
            onClick={togglePersonalContribution}
            isChecked={transferRewards}
            label={t('superPage.superRewardContribution.checkbox')}
          />
        </CheckboxContent>
      )}
    </CheckboxMainWrapper>
  );
};
