import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { ErrorLabel, Label } from '../styles';
import { ICustomWrapper, IFieldProps } from '../types';

import { DatePickerStyled, DatePickerWrapper } from './styles';
import { MONTH_PLACEHOLDER, DATE_FORMAT } from './consts';

import { APP_DATE_LOCALE } from 'constants/localeConfigs';

interface IDatePickerFieldProps<T extends FieldValues> extends IFieldProps<T> {
  maxDate: Date;
  onCalendarOpen?: () => void;
  minDate?: Date;
  format?: string;
  CustomDatePickerWrapper?: React.FC<ICustomWrapper>;
}

export const DatePickerField = <T extends FieldValues>({
  name,
  label,
  minDate,
  maxDate,
  onCalendarOpen,
  format = DATE_FORMAT,
  CustomDatePickerWrapper = DatePickerWrapper,
}: IDatePickerFieldProps<T>) => {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CustomDatePickerWrapper>
          <Label>{label}</Label>

          <DatePickerStyled
            {...field}
            isSelected={!!getValues(name)}
            maxDate={maxDate}
            minDate={minDate}
            onCalendarOpen={onCalendarOpen}
            monthPlaceholder={MONTH_PLACEHOLDER}
            format={format}
            locale={APP_DATE_LOCALE}
            onChange={(date: string) => {
              if (dayjs(date).isValid()) {
                field.onChange(date);
              }
            }}
          />

          {!!error?.message && <ErrorLabel>{error?.message}</ErrorLabel>}
        </CustomDatePickerWrapper>
      )}
    />
  );
};
