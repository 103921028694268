import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { Body, Content, Title } from '../styles';
import AccountMigrationFooter from '../components/AccountMigrationFooter';
import { useMigrationAccountSteps } from '../consts';

import { DetailsForm } from './styles';
import { useDetailsSchema } from './detailsMigrationFormConfig';

import { useStepper } from 'hooks/useStepper';
import {
  selectSecurityQuestions,
  selectUserDetails,
} from 'store/user/selectors';
import { saveUserData } from 'store/user/actions';
import { ISO_DATE_FORMAT } from 'config/date';
import { InputField } from 'components/form/input/InputField';
import { InputMaskField } from 'components/form/inputMask/InputMaskField';
import { DatePickerField } from 'components/form/datePicker/DatePickerField';
import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import { SelectField } from 'components/form/select/SelectField';

export default function AccountMigrationDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const steps = useMigrationAccountSteps();
  const { goNext } = useStepper({ steps });

  const { userDetails, securityQuestions } = useSelector((state) => ({
    userDetails: selectUserDetails(state),
    securityQuestions: selectSecurityQuestions(state),
  }));

  const maxDate = dayjs().subtract(18, 'year').toDate();

  const schema = useDetailsSchema();

  const getDefaultQuestionValue = () => {
    if (userDetails?.question1.length !== 0) return userDetails.question1;

    if (securityQuestions[0].value) return [securityQuestions[0]];

    return [];
  };

  const form = useForm({
    defaultValues: {
      ...userDetails,
      question1: getDefaultQuestionValue(),
      phone_number: userDetails.phone_number ?? '',
      dob: userDetails.dob && dayjs(userDetails.dob).toDate(),
    },
    resolver: yupResolver(schema),
  });

  const { getValues, setValue, handleSubmit } = form;

  const onSubmit = handleSubmit((values) => {
    dispatch(
      saveUserData({
        ...values,
        question1: values.question1?.[0]?.value,
        dob: dayjs(values.dob).format(ISO_DATE_FORMAT),
        us_resident: !!values.us_resident,
      }),
    );

    goNext();
  });

  const onCalendarOpen = () => {
    if (!getValues('dob')) {
      setValue('dob', maxDate);
    }
  };

  return (
    <Content>
      <Title>{t('registrationPage.accountMigration.detailsPage.title')}</Title>

      <Body>
        <FormProvider {...form}>
          <DetailsForm onSubmit={onSubmit}>
            <InputField
              name="first_name"
              label={t('registrationPage.personal.firstName')}
            />

            <InputField
              name="last_name"
              label={t('registrationPage.personal.lastName')}
            />

            <InputMaskField
              name="phone_number"
              label={t('fields.phoneNumber.label')}
            />

            <DatePickerField
              name="dob"
              label={t('fields.dateOfBirth.label')}
              onCalendarOpen={onCalendarOpen}
              maxDate={maxDate}
            />

            <CheckBoxField
              name="us_resident"
              label={t('registrationPage.personal.usResident')}
            />

            <SelectField
              name="question1"
              label={t('registrationPage.personal.securityQuestion')}
              options={securityQuestions}
            />

            <InputField
              name="answer1"
              label={t('registrationPage.personal.securityQuestionAnswer')}
            />

            <AccountMigrationFooter />
          </DetailsForm>
        </FormProvider>
      </Body>
    </Content>
  );
}
