import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';

export const RaizKidsSwitchWrapper = styled.div`
  display: inline-flex;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.colors.blackTransparent};
  width: auto;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 3px;
  margin-bottom: 12px;
`;

export const RaizKidsToggleButton = styled(Button)`
  border: 0;
  padding: 4px 23px;
  background: transparent;
  font-weight: inherit;
  height: max-content;
  min-width: 160px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.mirage : 'inherit'};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.transparent};
  border-radius: ${({ isActive }) => (isActive ? '15px' : '21px')};
`;
