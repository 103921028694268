import { Icon, Text } from './styles';

import { IReferralsListItemDto } from 'store/referrals/types';

interface IStatusProps {
  status: IReferralsListItemDto['status'];
}

export const Status = ({ status }: IStatusProps) =>
  status === 'earned' ? (
    <>
      <Icon type="smalldot" />

      <Icon type="check" />

      <Icon type="hollow" />

      <Text>{status}</Text>
    </>
  ) : (
    <>
      <Icon type="smalldot" />

      <Icon type="hollow" />

      <Icon type="dot" />

      <Text>{status}</Text>
    </>
  );
