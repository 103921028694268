import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import {
  InfoPanelBottomText,
  InfoPanelContentTop,
  InfoPanelContentWrapper,
  InfoPanelDescription,
  InfoPanelTitle,
  InfoPanelWrapper,
} from './styles';

import { MIGRATION_FLOW } from 'constants/migrationConstants';
import { PATHS } from 'constants/paths';
import Logo from 'pages/AccountMigrationPassword/components/Logo/Logo';
import getMigrationFlow from 'utils/getMigrationFlow';

export default function TermsInfoPanel() {
  const { t } = useTranslation();
  const isSuperTerms = useMatch(PATHS.migration.account.superPds);
  const migrationFlow = getMigrationFlow();

  const title =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.termsPage.title')
      : t('registrationPage.pds.title');

  const superAccountTitle =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.termsPage.superAccountTitle')
      : t('registrationPage.pds.superTitle');

  const description =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.termsPage.description')
      : t('registrationPage.pds.subTitleElevateMoneyUser');

  const superAccountDescription =
    migrationFlow === MIGRATION_FLOW.superEstate
      ? t('registrationPage.accountMigration.termsPage.superAccountDescription')
      : t('registrationPage.pds.subTitleElevateMoneyUser');

  return (
    <InfoPanelWrapper>
      <InfoPanelContentWrapper>
        <InfoPanelContentTop>
          <Logo />

          <InfoPanelTitle>
            {isSuperTerms ? superAccountTitle : title}
          </InfoPanelTitle>

          <InfoPanelDescription>
            {isSuperTerms ? superAccountDescription : description}
          </InfoPanelDescription>
        </InfoPanelContentTop>

        <InfoPanelBottomText>
          {migrationFlow === MIGRATION_FLOW.superEstate &&
            t('registrationPage.pds.bottomText')}
        </InfoPanelBottomText>
      </InfoPanelContentWrapper>
    </InfoPanelWrapper>
  );
}
