import React from 'react';
import { useTranslation } from 'react-i18next';

import { SwitcherWrapper, SwitcherItem } from './styles';

import { APPROVED, UNAPPROVED } from 'constants/comonConstants';

interface IRoundUpsSwitchProps {
  changeStatus: (status: string | null) => void;
  status: string;
}

function RoundUpsSwitch({ changeStatus, status }: IRoundUpsSwitchProps) {
  const { t } = useTranslation();

  return (
    <SwitcherWrapper>
      <SwitcherItem
        label={t('common.all')}
        onClick={() => changeStatus(null)}
        isActive={status === null}
        variant="initial"
      />

      <SwitcherItem
        onClick={() => changeStatus(APPROVED)}
        label={t('roundUps.switcher.invested')}
        isActive={status === APPROVED}
        variant="initial"
      />

      <SwitcherItem
        onClick={() => changeStatus(UNAPPROVED)}
        label={t('roundUps.switcher.available')}
        isActive={status === UNAPPROVED}
        variant="initial"
      />
    </SwitcherWrapper>
  );
}

export default RoundUpsSwitch;
