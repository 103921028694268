import styled from '@emotion/styled';

import { CopyButton } from 'components/elements/CopyButton';
import copyIcon from 'assets/images/icons/referrals/copy.svg';

export const CopyButtonStyled = styled(CopyButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: 8px;
  width: 312px;
  &:active {
    opacity: ${({ theme }) => theme.opacity.semiTransparent};
  }
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 260px;
  }
`;

export const Icon = styled.div`
  background-image: url(${`${copyIcon}`});
  background-position: center center;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.transparent};
  margin-left: 4px;
`;

export const Code = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
`;
