import { generatePath, useMatch } from 'react-router';
import { useParams, useNavigate, createSearchParams } from 'react-router-dom';

import { INavigateToRecurringInvestment } from '../types';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectChild } from 'store/dependentUser/selectors';
import { PATHS } from 'constants/paths';
import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';

export const useNavigateToRecurringInvestment = () => {
  const navigate = useNavigate();

  const isRegistrationPage = useMatch(
    PATHS.kids.registration.investment.recurring,
  );

  const { id } = useAppSelector(selectChild);
  const { childStep, childId } = useParams();

  const navigateToRecurringInvestment = ({
    viewMode,
  }: INavigateToRecurringInvestment) => {
    if (isRegistrationPage) {
      navigate({
        pathname: PATHS.kids.registration.index,
        search: createSearchParams({
          [PORTFOLIO_SEARCH_PARAMS.childId]: id,
        }).toString(),
      });
    } else if (childStep && childId) {
      navigate(
        generatePath(PATHS.kids.edit, {
          childStep,
          childId,
          viewMode,
        }),
      );
    }
  };

  return navigateToRecurringInvestment;
};
