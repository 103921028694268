import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import WarningSign from 'assets/images/icons/alert-icon.svg';

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mistyRose};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0 0 8px;
  padding: 6px 16px;
`;

export const Content = styled.p`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin: 0 8px;
  padding: 0;
`;

export const Icon = styled.i`
  background-image: url(${`${WarningSign}`});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  height: 24px;
  width: 24px;
`;

export const UploadButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black};
  width: auto;
`;
