import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function RaizKidsEditStatementsLink({ toggleMenu, kidId }) {
  const { t } = useTranslation();

  return (
    <NavLink
      to={`/raiz-kids/statements/${kidId}`}
      className={({ isActive }) =>
        isActive
          ? 'page-menu__item raiz-kids__menu-item -active'
          : 'page-menu__item raiz-kids__menu-item'
      }
      onClick={() => toggleMenu(false)}
    >
      <div className="-centered">
        <i className="page-menu__item-icon -statements-kids" />

        {t('raizKidsEditPage.statements')}
      </div>
    </NavLink>
  );
}

RaizKidsEditStatementsLink.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  kidId: PropTypes.string.isRequired,
};
