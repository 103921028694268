import { useTranslation } from 'react-i18next';

import { useRoundUpsSettings } from '../hooks/useRoundUpsSettings';

import SmartRoundUps from './SmartRoundUps';
import RoundUpWholeDollar from './RoundUpWholeDollar';
import AutoRoundUps from './AutoRoundUps';
import RoundUpsMultiplier from './RoundUpsMultiplier';
import { IRoundUpsModalContent } from './types';

import { Button } from 'components/elements/Button';

export default function RoundUpsModalContent({
  handleCloseModal,
}: IRoundUpsModalContent) {
  const { t } = useTranslation();

  const {
    isLoading,
    roundUpsSettings,
    handleSave,
    toggleAutoRoundUps,
    handleChangeThreshold,
    handleChangeDefaultAmount,
    handleChangeMultiplier,
    toggleMultiplier,
  } = useRoundUpsSettings({
    handleCloseModal,
  });

  const { default_amount: defaultAmount, automatic_roundup: automaticRoundup } =
    roundUpsSettings;

  return (
    <>
      <AutoRoundUps
        automaticRoundup={automaticRoundup}
        toggleAutoRoundUps={toggleAutoRoundUps}
      />

      <RoundUpsMultiplier
        roundUpsSettings={roundUpsSettings}
        handleChangeMultiplier={handleChangeMultiplier}
        toggleMultiplier={toggleMultiplier}
      />

      <SmartRoundUps
        roundUpsSettings={roundUpsSettings}
        handleChangeThreshold={handleChangeThreshold}
      />

      <RoundUpWholeDollar
        defaultAmount={defaultAmount}
        handleChangeDefaultAmount={handleChangeDefaultAmount}
      />

      <Button
        isLoading={isLoading}
        label={t('roundUps.modal.submitButton')}
        onClick={handleSave}
      />
    </>
  );
}
