import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  NewChildHeader,
  NewChildHeaderIcon,
  NewChildHeaderTitle,
  NewChildDescription,
  NewChildSetupDescription,
  NewChildWrapper,
} from './styles';

export default function NewChild() {
  const { t } = useTranslation();

  return (
    <NewChildWrapper>
      <NewChildHeader>
        <NewChildHeaderIcon />

        <NewChildHeaderTitle>{t('kidsWidget.header')}</NewChildHeaderTitle>
      </NewChildHeader>

      <NewChildDescription>
        {t('kidsWidget.newChild.description')}
      </NewChildDescription>

      <NewChildSetupDescription>
        {t('kidsWidget.newChild.setupDescription')}
      </NewChildSetupDescription>
    </NewChildWrapper>
  );
}
