import { createGlobalStyle } from 'styled-components';

import theme, { RaizTheme } from './theme';

export const GlobalStyle = createGlobalStyle<{ theme: RaizTheme }>`
.ReactModal__Overlay {
  z-index: ${theme.zIndexes.modalOverlay};
  background: ${theme.colors.blackTransparentDark} !important;
}

button:disabled,
button[disabled] {
  opacity: ${theme.opacity.mediumTransparent};

  &:hover {
    opacity: ${theme.opacity.mediumTransparent};
  }
}
`;
