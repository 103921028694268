import { useSearchParams } from 'react-router-dom';

import { IAnswer } from '../types';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { useSuitabilityQuery } from 'store/suitability/suitability';
import { selectUser } from 'store/user/selectors';
import {
  FINANCIAL,
  UNANSWERED_SUITABILITY_QUESTIONS,
} from 'constants/comonConstants';
import { useAppSelector } from 'store/hooks/useAppSelector';

const PROFILE_PAGE_TYPE_PARAM = 'type';

export const useSuitability = () => {
  const [searchParams] = useSearchParams();

  const user = useAppSelector(selectUser);

  const isFinancialPage =
    searchParams?.get(PROFILE_PAGE_TYPE_PARAM) === FINANCIAL;

  const { data: questions } = useSuitabilityQuery();

  const userSuitability: IAnswer[] = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.userSuitability) || '[]',
  );

  const suitabilityAlert = user?.alerts?.find(
    (alert: { type: string }) =>
      alert.type === UNANSWERED_SUITABILITY_QUESTIONS,
  );

  if (!userSuitability && !questions) {
    return [];
  }

  if (suitabilityAlert && isFinancialPage) {
    const unansweredQuestions = suitabilityAlert.metadata.split(',');

    return unansweredQuestions.map((questionId: string) => {
      const question = questions?.find(
        (questionFinde) => questionFinde.question_id === questionId,
      );

      const { picker_id } = question || {};

      const answer = userSuitability.find(
        (userSuitabilityFind) => userSuitabilityFind.picker_id === picker_id,
      );

      return {
        label: picker_id,
        question,
        answer,
        value: answer?.user_answer_id || '',
      };
    });
  }

  return questions?.map((question) => {
    const { picker_id, expand_on_answer_ids } = question || {};

    const answer = userSuitability.find(
      (userSuitabilityFind) => userSuitabilityFind.picker_id === picker_id,
    );

    return {
      label: picker_id,
      question,
      answer,
      expand_on_answer_ids,
      value: answer?.user_answer_id || '',
    };
  });
};
