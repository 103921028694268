import styled from '@emotion/styled';

import emptyIcon from 'assets/images/icons/ic_empty.svg';

export const ContentWrapper = styled.div`
  animation-name: fadein;
  animation-duration: 925ms;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyStatementsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  padding: 10px 0;
  text-align: center;
`;

export const EmptyStatementsText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.darkTopaz};
`;

export const EmptyStatementsIcon = styled.div`
  background: url(${`${emptyIcon}`}) no-repeat center;
  background-size: 30px 27px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.athensGray};
  width: 70px;
  height: 70px;
  margin: 12px auto;
`;
