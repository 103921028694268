import { Card, Content, Header, Logo, LogoWrapper, Title } from './styles';
import { VisaAccountItem } from './VisaAccountItem';
import { NonVisaAccountItem } from './NonVisaAccountItem';
import { IOpenFixAccountModal } from './types';

import { ISpendingAccounts } from 'store/spendingAccounts';
import { ROUNDUP_SOURCES } from 'store/banks';

interface IBanksCardProps {
  bank: ISpendingAccounts;
  openFixAccountModal: IOpenFixAccountModal;
}

export const BanksCard = ({ bank, openFixAccountModal }: IBanksCardProps) => (
  <Card key={bank.id}>
    <Header>
      <LogoWrapper>
        <Logo source={bank.icon_url} />
      </LogoWrapper>

      <Title>{bank.name}</Title>
    </Header>

    <Content>
      {bank.accounts.visa.map((account) => (
        <VisaAccountItem
          key={account.id}
          account={account}
          bankName={bank.name}
        />
      ))}

      {bank.accounts.online_banking.map(({ subaccounts, ...account }) =>
        subaccounts?.length ? (
          subaccounts.map((subaccount) => (
            <NonVisaAccountItem
              key={`${account.id}-${subaccount.id}`}
              account={account}
              subAccount={subaccount}
              openFixAccountModal={openFixAccountModal}
              bankName={bank.name}
              source={ROUNDUP_SOURCES.onlineBanking}
            />
          ))
        ) : (
          <NonVisaAccountItem
            key={account.id}
            account={account}
            openFixAccountModal={openFixAccountModal}
            isError
            bankName={bank.name}
            source={ROUNDUP_SOURCES.onlineBanking}
          />
        ),
      )}

      {bank.accounts.debit_cards.map(({ subaccounts, ...account }) =>
        subaccounts?.length ? (
          subaccounts.map((subaccount) => (
            <NonVisaAccountItem
              key={`${account.id}-${subaccount.id}`}
              account={account}
              subAccount={subaccount}
              openFixAccountModal={openFixAccountModal}
              bankName={bank.name}
              source={ROUNDUP_SOURCES.debitCards}
            />
          ))
        ) : (
          <NonVisaAccountItem
            key={account.id}
            account={account}
            openFixAccountModal={openFixAccountModal}
            isError={!!account?.error_info}
            bankName={bank.name}
            source={ROUNDUP_SOURCES.debitCards}
          />
        ),
      )}
    </Content>
  </Card>
);
