import { useTranslation, Trans } from 'react-i18next';
import Switch from 'react-switch';

import {
  AutoRoundUpsIcon,
  RoundUpsRowDescription,
  RoundUpsRowTitle,
  RoundUpsRow,
} from './styles';
import { IAutoRoundUps } from './types';
import { ROUND_UPS_SWITCH_OPTIONS } from './consts';

import colors from 'theme/colors';
import shadows from 'theme/shadows';

export default function AutoRoundUps({
  automaticRoundup,
  toggleAutoRoundUps,
}: IAutoRoundUps) {
  const { t } = useTranslation();

  return (
    <RoundUpsRow>
      <div>
        <RoundUpsRowTitle>
          <AutoRoundUpsIcon />

          {t('roundUps.modal.automaticRoundUps')}
        </RoundUpsRowTitle>

        <RoundUpsRowDescription>
          <Trans t={t} i18nKey="roundUps.modal.automaticallyApproved" />
        </RoundUpsRowDescription>
      </div>

      <Switch
        onChange={toggleAutoRoundUps}
        checked={!!automaticRoundup}
        onColor={colors.niagaraLight}
        offColor={colors.alto}
        onHandleColor={colors.white}
        handleDiameter={ROUND_UPS_SWITCH_OPTIONS.handleDiameter}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={shadows.light}
        activeBoxShadow={ROUND_UPS_SWITCH_OPTIONS.activeBoxShadow}
        height={ROUND_UPS_SWITCH_OPTIONS.height}
        width={ROUND_UPS_SWITCH_OPTIONS.width}
      />
    </RoundUpsRow>
  );
}
