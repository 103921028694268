import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import Grid from '../../components/elements/Grid/Grid';
import '../../styles/pages/rewards.scss';
import { REWARDS } from '../../constants/comonConstants';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';

import RewardsCarousel from './components/RewardsCarousel';

export default function RewardsView({
  categories,
  offers,
  isNeedSearch,
  categoryTitle,
  filterByCategory,
  brandsFilter,
  activeCategoryId,
  searchHandler,
  saveInvested,
  isHelpCardsOpen,
  toggleHelpCard,
  rewardCard,
  categoriesType,
  clearSearch,
  searchValue,
  rewardsTotals,
}) {
  const { isLoading } = useSelector((state) => ({
    isLoading: state.rewards.isLoading,
  }));

  const { t } = useTranslation();

  return (
    <div className="rewards">
      <RewardsCarousel
        saveInvested={saveInvested}
        toggleHelpCard={toggleHelpCard}
        totalRewards={
          offers.total_found_money_rewarded
            ? offers.total_found_money_rewarded.value
            : 0
        }
        rewardsTotals={rewardsTotals}
      />

      <Grid
        items={offers.offers}
        categories={categories}
        isNeedSearch={isNeedSearch}
        brandsFilter={brandsFilter}
        saveInvested={saveInvested}
        categoryTitle={categoryTitle}
        searchHandler={searchHandler}
        showMainLoader={isLoading}
        categoriesType={categoriesType}
        filterByCategory={filterByCategory}
        activeCategoryId={activeCategoryId}
        clearSearch={clearSearch}
        searchValue={searchValue}
      />

      {!isLoading && offers?.offers.length === 0 && (
        <div>
          <div className="icon-not-found" />

          <div className="not-found">
            <Trans
              i18nKey="rewardsPage.notFound"
              values={{ name: searchValue }}
              t={t}
            />
          </div>
        </div>
      )}

      <HelpCardsModalAU
        currentPage={REWARDS}
        rewardCard={rewardCard}
        toggleHelpCard={toggleHelpCard}
        isOpenHelpCards={isHelpCardsOpen}
      />
    </div>
  );
}

RewardsView.defaultProps = {
  rewardCard: null,
  categoriesType: '',
  searchValue: '',
  offers: { total_rewards_dollar_value: 0, offers: [] },
  rewardsTotals: {},
  insuranceCertificates: { certificates: [], active: false },
};

RewardsView.propTypes = {
  offers: PropTypes.shape({
    total_found_money_rewarded: PropTypes.shape({
      value: PropTypes.number,
    }),
    offers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isNeedSearch: PropTypes.bool.isRequired,
  categoryTitle: PropTypes.shape({}).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterByCategory: PropTypes.func.isRequired,
  brandsFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeCategoryId: PropTypes.number.isRequired,
  searchHandler: PropTypes.func.isRequired,
  saveInvested: PropTypes.func.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  isHelpCardsOpen: PropTypes.bool.isRequired,
  rewardCard: PropTypes.string,
  categoriesType: PropTypes.string,
  clearSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  rewardsTotals: PropTypes.shape({}),
  insuranceCertificates: PropTypes.shape({
    active: PropTypes.bool.isRequired,
  }),
};
