import type {
  BaseQueryMeta,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import {
  IRemittancesZakatTypesResponse,
  IZakatTypeRequest,
  IZakatTypeTransformed,
} from '../types';
import { ZAKAT_TYPES } from '../consts';

export type TTransformZakatTypesResponseProps = (
  response: IRemittancesZakatTypesResponse,
  _: BaseQueryMeta<BaseQueryFn>,
  params: IZakatTypeRequest,
) => IZakatTypeTransformed[];

const transformZakatTypesResponse: TTransformZakatTypesResponseProps = (
  response,
  _,
  params,
) => {
  const zakatTypesData = params?.isRecurring
    ? response?.zakat_types?.filter(
        (zakatType) => zakatType.key !== ZAKAT_TYPES.fitrah,
      )
    : response?.zakat_types;

  return zakatTypesData?.reduce(
    (zakatTypes: IZakatTypeTransformed[], zakatType) => {
      if (!zakatType.enabled) {
        return zakatTypes;
      }

      return [...zakatTypes, { value: zakatType.key, label: zakatType.title }];
    },
    [],
  );
};

export default transformZakatTypesResponse;
