import { IGetSendMailModalDescriptionsProps } from './types';

import {
  KIDS_STATEMENTS_MONTHLY,
  KIDS_TRADE_CONFIRMATION,
} from 'constants/kidsConstants';

export const getSendMailModalKey = ({
  currentType,
  isSeveralStatementsSelected,
}: Pick<
  IGetSendMailModalDescriptionsProps,
  'currentType' | 'isSeveralStatementsSelected'
>) => {
  const quantityOfStatements = isSeveralStatementsSelected
    ? 'severalStatements'
    : 'oneStatement';

  if (currentType === KIDS_TRADE_CONFIRMATION)
    return `statements.sendStatementsConfirmModal.${quantityOfStatements}.title.tradeConfirmation` as const;

  if (currentType === KIDS_STATEMENTS_MONTHLY)
    return `statements.sendStatementsConfirmModal.${quantityOfStatements}.title.statements` as const;

  return '';
};
