import styled from '@emotion/styled';
import Modal from 'react-modal';

import CloseIcon from 'assets/images/icons/close-black.svg';
import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import { Button } from 'components/elements/Button';

export const ModalWrapper = styled(Modal)<{ size: number }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  left: 50%;
  max-width: ${({ size }) => size}px;
  min-width: auto;
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Header = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  text-align: center;
  flex: 1;
  margin-left: 24px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-align: center;
  color: ${({ theme }) => theme.colors.dustyGray};
  padding: 0 40px;
`;

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  background-image: url(${`${CloseIcon}`});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  height: 24px;
  width: 24px;
`;

export const UploadButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  width: 340px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.alto};
    color: ${({ theme }) => theme.colors.mirage};
  }
`;

export const ExternalLinkStyled = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.dustyGray};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  text-align: center;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.dustyGray};
    text-decoration: underline;
  }
`;

export const FileUploadFieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 24px 0;
  width: 100%;
`;
