import styled from '@emotion/styled';
import Modal from 'react-modal';

export const RequestModalWrapper = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  display: block;
  margin: 2% auto 0;
  max-height: 90%;
  overflow: auto;
  position: relative;
  max-width: 455px;
  min-height: 220px;
  min-width: 455px;
`;

export const RequestModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.lineHeights.length.xl};
  margin-bottom: 10px;
`;

export const RequestModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  opacity: ${({ theme }) => theme.opacity.semiTransparent};
  text-align: center;
`;

export const RequestModalBalanceLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-bottom: -10px;
  margin-top: 17px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  text-align: center;
`;

export const RequestModalBalanceValue = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['6xl']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({ theme }) => theme.lineHeights.length['7xl']};
  margin: 10px auto 0;
  text-align: center;
`;

export const RequestModalWarning = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-bottom: 30px;
  margin-top: 24px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  text-align: center;
`;
