import { AUS, MYS } from './comonConstants';

export const MY_APP = process.env.REACT_APP_CODE_BASE_VERSION === 'mys';
export const AU_APP = process.env.REACT_APP_CODE_BASE_VERSION === 'aus';

export const COUNTRY = process.env.REACT_APP_CODE_BASE_VERSION;

const currentLang = localStorage.getItem('lang');

const currency = {
  [AUS]: '$',
  [MYS]: 'RM',
};

export const CURRENCY_SYMBOL = currency[COUNTRY] || currency[AUS];

const currencyName = {
  [AUS]: 'USD',
  [MYS]: 'MYR',
};

export const CURRENCY_NAME = currencyName[COUNTRY] || currencyName[AUS];

const currencyLocale = {
  [AUS]: 'en-US',
  [MYS]: 'ms-MY',
};

export const CURRENCY_LOCALE = currencyLocale[COUNTRY] || currencyLocale[AUS];

const currencySymbol = {
  [AUS]: 'AUD',
  [MYS]: 'MYR',
};

export const CURRENCY = currencySymbol[COUNTRY] || currencySymbol[AUS];

const dateLocale = {
  [AUS]: 'en',
  [MYS]: currentLang === 'bahasa' ? 'ms-my' : 'en',
};

const TIME_ZONES = {
  [AUS]: 'Australia/Sydney',
  [MYS]: 'Asia/Kuala_Lumpur',
};

export const APP_DATE_LOCALE = dateLocale[COUNTRY];

export const APP_DATE_TIME_ZONE = TIME_ZONES[COUNTRY];

const conditions = {
  [AUS]: {
    terms: 'https://raizinvest.com.au/terms',
    policy: 'https://raizinvest.com.au/privacy',
    disclosure: 'https://raizinvest.com.au/product-disclosure-statement',
    agreement: 'https://raizinvest.com.au/account-opening-agreement',
    statements:
      'https://raizinvest.com.au/external-product-disclosure-statement',
    disclosures: 'https://raizinvest.com.au/disclosures',
    support: 'https://raizinvest.com.au/support/',
    submit: 'https://raizinvest.com.au/support/#submit-a-ticket',
    security: 'https://raizinvest.com.au/security',
    fee: 'https://raizinvest.com.au/fees',
    blog: 'https://raizinvest.com.au/investors/wp-json/wp/v2/posts/',
    blog_link: 'https://raizinvest.com.au/blog/',
    twitter: 'https://www.twitter.com/raizaustralia',
    facebook: 'https://www.facebook.com/raizapp',
    android: 'https://app.adjust.io/kn0syi',
    ios: 'https://app.adjust.io/a2xqjx',
    marketingSite: 'https://raizinvest.com.au',
  },
  [MYS]: {
    terms: 'https://www.raiz.com.my/platform-agreement',
    policy: 'https://www.raiz.com.my/privacy',
    disclosure: 'https://www.raiz.com.my/product-disclosure-statement',
    agreement: 'https://www.raiz.com.my/account-opening-agreement',
    statements: 'https://www.raiz.com.my/external-product-disclosure-statement',
    disclosures:
      currentLang === 'bahasa'
        ? 'https://raiz.com.my/my-support/'
        : 'https://www.raiz.com.my/disclosures',
    support:
      currentLang === 'bahasa'
        ? 'https://raiz.com.my/my-support/'
        : 'https://www.raiz.com.my/support/',
    submit: 'https://www.raiz.com.my/support/#submit-a-ticket',
    security: 'https://www.raiz.com.my/security/',
    fee: 'https://www.raiz.com.my/fees',
    blog: 'https://raiz.com.my/blog/wp-json/wp/v2/posts/',
    blog_link: 'https://raiz.com.my/blog/',
    twitter: 'https://twitter.com/raizmalaysia',
    facebook: 'https://www.facebook.com/raizapp',
    android: 'https://app.adjust.com/7qe2ibl',
    ios: 'https://app.adjust.com/uvfr6he',
    marketingSite: 'https://raiz.com.my',
  },
};

export const TERMS_CONDITIONS =
  conditions[COUNTRY].terms || conditions[AUS].terms;
export const PRIVACY_POLICY =
  conditions[COUNTRY].policy || conditions[AUS].policy;
export const DISCLOSURE_STATEMENTS =
  conditions[COUNTRY].disclosure || conditions[AUS].disclosure;
export const ACCOUNT_OPENNING_AGREEMENT =
  conditions[COUNTRY].agreement || conditions[AUS].agreement;
export const DISCLOSURES =
  conditions[COUNTRY].disclosures || conditions[AUS].disclosures;
export const SUPPORT = conditions[COUNTRY].support || conditions[AUS].support;
export const SUBMIT_TICKET =
  conditions[COUNTRY].submit || conditions[AUS].submit;
export const SECURITY =
  conditions[COUNTRY].security || conditions[AUS].security;
export const FEES = conditions[COUNTRY].fee || conditions[AUS].fee;
export const BLOG_API = conditions[COUNTRY].blog || conditions[AUS].blog;
export const DEFAULT_BLOG_URL =
  conditions[COUNTRY].blog_link || conditions[AUS].blog_link;
export const TWITTER_LINK =
  conditions[COUNTRY].twitter || conditions[AUS].twitter;
export const FACEBOOK_LINK =
  conditions[COUNTRY].facebook || conditions[AUS].facebook;
export const ANDROID_APP_LINK =
  conditions[COUNTRY].android || conditions[AUS].android;
export const IOS_APP_LINK = conditions[COUNTRY].ios || conditions[AUS].ios;
export const FAQ = conditions[COUNTRY].faq || null;

export const THOUSAND_SEPARATOR = ',';
export const DECIMAL_SEPARATOR = '.';
export const FIREBASE_INVEST = 'Invest';
export const MARKETING_SITE =
  conditions[COUNTRY].marketingSite || conditions[AUS].marketingSite;

export const PHONE_NUMBER_MASK = {
  [MYS]: '019-99999999',
};
