export const PERIOD_PARAMS = {
  week: 'week',
  month: 'month',
  year: 'year',
  day: 'day',
  all: 'all',
};

export const PERIOD_DAYS_NUMBER = {
  oneDay: 1,
  oneWeek: 7,
  oneMonth: 30,
  threeMonths: 91,
  halfYear: 182,
  oneYear: 365,
  all: 0,
};

export const ETF_TYPES = {
  percentage: 'percentage',
  dollar: 'dollar',
  shares: 'shares',
};
