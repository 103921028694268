import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { MY_APP } from '../../../../constants/localeConfigs';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { openPortfolio, linkWithdrawalNow } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const LinkWithdrawalAccountModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlePortfolioOpen = () => {
    dispatch(openPortfolio());
  };

  const handleLinkWithdrawalNow = () => {
    dispatch(linkWithdrawalNow());
  };

  return (
    <Modal
      hasBackdrop={false}
      isVisible
      isOpen
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">
          {t('linkWithdrawalAccountModal.description')}
        </div>

        <div className={`alert-buttons ${MY_APP ? '-centered' : ''}`}>
          <DeprecatedButton
            title={t('common.skip')}
            type="button"
            buttonClass="wait"
            handleClick={handlePortfolioOpen}
          />

          <DeprecatedButton
            title={t('linkWithdrawalAccountModal.button.title')}
            type="button"
            buttonClass="skip"
            handleClick={handleLinkWithdrawalNow}
          />
        </div>
      </div>
    </Modal>
  );
};
