import styled from '@emotion/styled';

export const SwitchLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamily.medium};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
