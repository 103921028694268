export const ACCESS_SELECTED = 'ACCESS_SELECTED';
export const GO_BACK = 'GO_BACK';
export const INVESTMENT_INITIAL = 'INVESTMENT_INITIAL';
export const INVESTMENT_RECURRING = 'INVESTMENT_RECURRING';
export const PERSONAL_INFO = 'PERSONAL_INFO';
export const PERSONAL_TERMS = 'PERSONAL_TERMS';
export const OTHER_NOTIFICATION = 'OTHER_NOTIFICATION';
export const PORTFOLIO_SELECTED = 'PORTFOLIO_SELECTED';

export const CLOSE_ACCOUNT_KIDS_SUCCESS = 'CLOSE_ACCOUNT_KIDS_SUCCESS';
export const ACTIVATE_ACCOUNT_KIDS_SUCCESS = 'ACTIVATE_ACCOUNT_KIDS_SUCCESS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CONVERT_FULL_SUCCESS = 'CONVERT_FULL_SUCCESS';
export const GET_DEPENDENT_ACCESS_SUCCESS = 'GET_DEPENDENT_ACCESS_SUCCESS';
export const ERROR_CREATE_USER_ON_KID_REGISTRATION =
  'ERROR_CREATE_USER_ON_KID_REGISTRATION';
export const GET_DEPENDENCY_USER_SUMMARY_SUCCESS =
  'GET_DEPENDENCY_USER_SUMMARY_SUCCESS';
export const UPDATE_DEPENDENCY_USER_SUCCESS = 'UPDATE_DEPENDENCY_USER_SUCCESS';
export const SHOW_ERROR_GET_DEPENDENCY_USER_SUMMARY =
  'SHOW_ERROR_GET_DEPENDENCY_USER_SUMMARY';
export const GET_DEPENDENCY_USER_SUCCESS = 'GET_DEPENDENCY_USER_SUCCESS';
export const GET_SECURITY_SUCCESS = 'GET_SECURITY_SUCCESS';
export const DEPENDENCY_USER_INVESTMENT_SUCCESS =
  'DEPENDENCY_USER_INVESTMENT_SUCCESS';
export const DEPENDENCY_USER_PORTFOLIO_SUCCESS =
  'DEPENDENCY_USER_PORTFOLIO_SUCCESS';
export const RESENT_LINK_SUCCESS = 'RESENT_LINK_SUCCESS';
export const DEPENDENCY_USER_RECCURRING_SUCCESS =
  'DEPENDENCY_USER_RECCURRING_SUCCESS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const SHOW_ERROR_CREATE_USER = 'SHOW_ERROR_CREATE_USER';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const TRANSFER_IN = 'TRANSFER_IN';
export const TRANSFER_IN_SUCCESS = 'TRANSFER_IN_SUCCESS';
export const REGISTRATION_PORTFOLIO_SELECT =
  '/raiz-registration/portfolio/select';

export const LINK_SENT = 'link-sent';
export const KIDS_SIGNUP = '/kids-signup/';
export const KIDS_SIGNUP_BANKS = '/kids-signup/banks/';

export const CREDIT = 'credit';
export const PORTFOLIO_REGISTRATION = 'portfolio-registration';
export const OTHER_REGISTRATION = 'other-registration';
export const INVESTMENT_REGISTRATION = 'investment-registration';
export const INVESTMENTS_REGISTRATION = 'investments-registration';
export const PERSONAL_REGISTRATION = 'personal-registration';
export const SUPER_FUND_REGISTRATION = 'super-fund-registration';
export const ACCOUNT_REGISTRATION = 'account-registration';
export const REGISTRATION_INITIAL_INVESTMENT =
  '/raiz-registration/investment/initial';
export const REGISTRATION_PERSONAL_TERMS = '/raiz-registration/personal/terms';
export const REGISTRATION_PERSONAL_INFORMATION =
  '/raiz-registration/personal/info';
export const REGISTRATION_ACCESS_SELECT = '/raiz-registration/other/access';
export const REGISTRATION_NOTIFICATION =
  '/raiz-registration/other/notification';

export const KIDS_LINK_SENT = 'kids-link-sent';
export const KIDS_CONVERT_ACCOUNT = 'kids-convert-account';
export const KIDS_CLOSE_ACCOUNT = 'kids-close-account';
export const KIDS_WITHDRAW = 'kids-withdraw';
export const KIDS_INVEST_NOW = 'kids-invest-now';

export const DEPENDENT = 'dependent';
export const OWNER = 'owner';
export const GET_DEPENDENCY_USER_LOADING = 'GET_DEPENDENCY_USER_LOADING';

export type IActionsType =
  | 'SHOW_ERROR_CREATE_USER'
  | 'PERSONAL_TERMS'
  | 'CLOSE_MODAL'
  | 'PERSONAL_INFO'
  | 'DEPENDENCY_USER_INVESTMENT_SUCCESS'
  | 'INVESTMENT_INITIAL'
  | 'DEPENDENCY_USER_RECCURRING_SUCCESS'
  | 'INVESTMENT_RECURRING'
  | 'PORTFOLIO_SELECTED'
  | 'DEPENDENCY_USER_PORTFOLIO_SUCCESS'
  | 'ACCESS_SELECTED'
  | 'OTHER_NOTIFICATION'
  | 'UPDATE_NOTIFICATIONS_SUCCESS'
  | 'GO_BACK'
  | 'GET_DEPENDENCY_USER_SUMMARY_SUCCESS'
  | 'GET_DEPENDENCY_USER_SUCCESS'
  | 'UPDATE_DEPENDENCY_USER_SUCCESS'
  | 'GET_NOTIFICATIONS_SUCCESS'
  | 'RESENT_LINK_SUCCESS'
  | 'CLOSE_ACCOUNT_KIDS_SUCCESS'
  | 'ACTIVATE_ACCOUNT_KIDS_SUCCESS'
  | 'CONVERT_FULL_SUCCESS'
  | 'TRANSFER_IN_SUCCESS'
  | 'SHOW_ERROR_MODAL'
  | 'GET_SECURITY_SUCCESS'
  | 'GET_DEPENDENT_ACCESS_SUCCESS'
  | 'GET_DEPENDENCY_USER_LOADING';

export type IRootDependencyUsersState = (
  state: IDependencyUsersState,
  action: {
    type: IActionsType;
    errorMessage: string;
    response: object;
    step: string;
    res: { dependency_users: object };
    dependentAccess: object;
    isLoading: boolean;
  },
) => void;

export type IFrequencyIdentifier =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'fortnightly'
  | null;

export type ISummary = {
  deposits: {
    value: number;
    currency: string;
  };
  withdrawals: {
    value: number;
    currency: string;
  };
  reinvested_dividends: {
    value: number;
    currency: string;
  };
  total_found_money_rewarded: {
    value: number;
    currency: string;
  };
  total_referrals_rewarded: {
    value: number;
    currency: string;
  };
  other_rewards_rewarded: {
    value: number;
    currency: string;
  };
  lump_sum: {
    value: number;
    currency: string;
  };
  recurring: {
    value: number;
    currency: string;
  };
};

export type IRecurring = {
  day: number;
  amount: number;
  frequency: string;
  frequency_identifier?: IFrequencyIdentifier;
  start_date: string;
  next_investment_at: string;
};

export type IDependencyUsers = {
  dependency_user: {
    id: string;
    account_access: boolean;
    investing: boolean;
    investing_weekly_limit: number;
    current_balance: number;
    portfolio: {
      id: string;
    };
    recurring?: IRecurring;
    icon_url: string;
    name: string;
    summary: ISummary;
  };
};

export type IDependencyUsersState = {
  dependencyUsers: {
    child: IDependencyUsers;
    isLoading?: boolean;
  };
};
