import { IAvailableThresholds } from './types';
import {
  AvailableThresholdsButton,
  AvailableThresholdsWrapper,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

export default function AvailableThresholds({
  roundUpsSettings,
  handleChangeThreshold,
}: IAvailableThresholds) {
  const { available_thresholds, automatic_lower_threshold } = roundUpsSettings;

  return (
    <AvailableThresholdsWrapper>
      {available_thresholds?.map((threshold) => {
        const { lower, upper } = threshold;

        return (
          <AvailableThresholdsButton
            type="button"
            isActive={automatic_lower_threshold === lower}
            label={formatNumber({ value: lower })}
            onClick={() =>
              handleChangeThreshold({
                lower,
                upper,
              })
            }
            key={`threshold__${lower}__${upper}`}
            variant="initial"
          />
        );
      })}
    </AvailableThresholdsWrapper>
  );
}
