import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import * as spendingAnalyticsActions from '../../../store/spendingAnalytics/actions';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

class ReceiveNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiveNotifications: false,
    };

    this.updateReceivingNotifications =
      this.updateReceivingNotifications.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics.getSettings().then(() => {
      if (this._isMounted) {
        const { settings } = this.props;

        this.setState({ receiveNotifications: settings.tips_enabled });
      }
    });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateReceivingNotifications(condition) {
    this.setState({ receiveNotifications: condition, showLoader: true });

    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics.updateSettings(condition).then(() => {
      this.setState({
        showLoader: false,
      });
    });
  }

  render() {
    const { t, settings } = this.props;
    const { receiveNotifications, showLoader } = this.state;

    return (
      <div className="info-panel my-finance__receive">
        <div className="info-panel__title -size-s">
          {t('myFinancePage.receiveNotification.title')}
        </div>

        <div className="info-panel__divider" />

        <div className="info-panel__button-row">
          <DeprecatedButton
            withLoader={showLoader}
            title={t('common.no')}
            buttonClass={
              receiveNotifications || Object.keys(settings).length === 0
                ? 'button__basic-fill -white -grey-border'
                : 'button__basic-fill -green'
            }
            handleClick={() => {
              this.updateReceivingNotifications(false);
            }}
          />

          <DeprecatedButton
            withLoader={showLoader}
            title={t('common.yes')}
            buttonClass={
              !receiveNotifications
                ? 'button__basic-fill -white -grey-border'
                : 'button__basic-fill -green'
            }
            handleClick={() => {
              this.updateReceivingNotifications(true);
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  type: state.spendingAnalytics.type,
  settings: state.spendingAnalytics.settings,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ReceiveNotification));

ReceiveNotification.defaultProps = {
  actionsSpendingAnalytics: {},
  settings: {},
};

ReceiveNotification.propTypes = {
  t: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    tips_enabled: PropTypes.bool,
  }),
  actionsSpendingAnalytics: PropTypes.shape({
    getSettings: PropTypes.func,
    updateSettings: PropTypes.func,
  }),
};
