import styled from '@emotion/styled';

import { BaseButton } from 'components/elements/Deprecated__Button';

export const PortfolioPlanSwitchWrapper = styled.div`
  width: 208px;
  height: 41px;
  border-radius: 57px;
  background: ${({ theme }) => theme.colors.blackTransparent};
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

interface ISwitchButtonWrapperProps {
  isActive: boolean;
}

export const SwitchButtonWrapper = styled.div<ISwitchButtonWrapperProps>`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  background: none;
  border-radius: 50px;
  width: 100px;
  height: 31px;
  margin: auto 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.colors.mirage};
      background: ${theme.colors.white};
  `}
`;

interface IPortfolioPlanFooterWrapperProps {
  isRegistration?: boolean;
}

export const PortfolioPlanFooterWrapper = styled.div<IPortfolioPlanFooterWrapperProps>`
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 15px 10px;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.wildSand};
  width: 100%;
  ${({ isRegistration, theme }) =>
    isRegistration &&
    `
      padding: 15px 10px 15px 130px;
      justify-content: flex-start;
      background-color: ${theme.colors.white};
      width: 60%;
    `}
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
    padding-left: 16px;
  }
`;

export const PortfolioPlanFooterButton = styled(BaseButton)`
  width: auto;
  padding: 0 50px;

  ${({ theme }) =>
    `
      color:  ${theme.colors.white};

      :disabled {
        background-color: ${theme.colors.lightGrey};
        color: ${theme.colors.mirage};
      }

      ${theme.mediaQuery(theme.breakpoints.mobile)} {
        padding: 0 20px;
      }
  `}
`;

export const ProPortfolioLoader = styled.div`
  height: 30vh;
  display: flex;
  align-items: center;
`;

export const PortfolioPlanFooterBackButton = styled(BaseButton)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mirage};
  border: 1px solid ${({ theme }) => theme.colors.alto};
  padding: 0px 50px;
  margin-right: 15px;
`;
