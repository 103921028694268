import { FilterItemWrapper } from './styles';
import FilterItemPromoted from './FilterItemPromoted';

import { CheckBoxField } from 'components/form/checkbox/CheckboxField';
import { IFundTag } from 'store/portfolio/types';

interface IFilterItemProps {
  filter: IFundTag;
  isPromoted?: boolean;
}

export default function FilterItem({ filter, isPromoted }: IFilterItemProps) {
  const { name, key } = filter;

  if (isPromoted) {
    return <FilterItemPromoted filter={filter} />;
  }

  return (
    <FilterItemWrapper>
      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <CheckBoxField name={key} label={name} />
    </FilterItemWrapper>
  );
}
