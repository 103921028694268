import React from 'react';
import PropTypes from 'prop-types';

import {
  ChardDetailsExpandedLabel,
  ChartDetailsExpandedContent,
  ChartDetailsExpandedText,
} from '../styles';

import { NUMBER_FORMATS } from 'utils/formatters/consts';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function ChartDetailsExpandedLabel({ name, value }) {
  return (
    <ChartDetailsExpandedContent>
      <ChardDetailsExpandedLabel>
        <ChartDetailsExpandedText>{name}</ChartDetailsExpandedText>

        <ChartDetailsExpandedText>
          {formatNumber({
            type: NUMBER_FORMATS.percent,
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
            value,
          })}
        </ChartDetailsExpandedText>
      </ChardDetailsExpandedLabel>
    </ChartDetailsExpandedContent>
  );
}

ChartDetailsExpandedLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
