import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { ExternalLink } from 'components/elements/ExternalLink/ExternalLink';
import validIcon from 'assets/images/icons/check_icon.svg';
import infoIcon from 'assets/images/icons/info_icon.svg';
import wrapperBackgroundImage from 'assets/images/pattern.svg';
import { Button } from 'components/elements/Button';

export const FormStyled = styled.form`
  animation-duration: 925ms;
  animation-name: fadein;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
  padding: 20px 40px 40px 40px;
  width: 455px;

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.extraSmall)} {
    width: auto;
  }
`;

export const CreateAccountButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.niagaraLight};
  border-radius: 21px;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  height: 44px;
  width: 100%;

  :hover,
  :active,
  :disabled,
  :focus {
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  }
`;

export const ValidEmailIcon = styled.i`
  background-image: url(${`${validIcon}`});
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 30%;
  cursor: pointer;
  height: 20px;
  padding: 7px 11px 7px 11px;
  position: absolute;
  right: 0;
  width: 20px;
`;

export const HelperText = styled.div`
  color: ${({ theme }) => theme.colors.silverChalice};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 30px;
  position: relative;
  text-align: left;
`;

export const InfoIcon = styled.i`
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${`${infoIcon}`});
`;

export const ContactLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin: 0;
  margin-top: 20px;
  display: inline-flex;
  width: auto;
  align-self: center;

  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TermsWrapper = styled.p`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  max-width: 50%;
  color: ${({ theme }) => theme.colors.white};
  margin: 40px auto 0;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    font-weight: bold;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  height: 20px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin: 0 auto;
  text-align: center;
  flex: 1;
`;

export const LoginLink = styled(Link)`
  margin-top: -3px;
  text-align: center;
  color: ${({ theme }) => theme.colors.niagaraLight};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};

  :hover {
    color: ${({ theme }) => theme.colors.niagaraLight};
  }
`;

export const Header = styled.div`
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding-top: 50px;
  }
`;

export const Wrapper = styled.div`
  background-image: url(${`${wrapperBackgroundImage}`}),
    ${({ theme }) => theme.gradients.greenishCyan};
  background-position: 15% 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  overflow: initial;
  padding: 0 10px;
  width: 100%;
`;
