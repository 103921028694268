import React from 'react';

import { MY_FINANCE_WIDGET_BANNER_ITEMS } from '../consts';

import MyFinanceWidgetBannerItem from './MyFinanceWidgetBannerItem';
import { MyFinanceBanner } from './styles';

export default function MyFinanceWidgetBanner() {
  return (
    <MyFinanceBanner>
      {MY_FINANCE_WIDGET_BANNER_ITEMS.map(
        ({ type, percent, amount, isActive }) => (
          <MyFinanceWidgetBannerItem
            key={type}
            type={type}
            percent={percent}
            amount={amount}
            isActive={isActive}
          />
        ),
      )}
    </MyFinanceBanner>
  );
}
