const POSTAL_CODE_REGEX = /^\d+$/;

export type IValidatePostalCode = {
  postalCode: string;
  requiredNumberOfDigits: number;
};

const validatePostalCode = ({
  postalCode,
  requiredNumberOfDigits,
}: IValidatePostalCode) => {
  if (postalCode?.length !== requiredNumberOfDigits) return false;

  return POSTAL_CODE_REGEX.test(postalCode);
};

export default validatePostalCode;
