import { useSuperPdsDocumentQuery } from 'store/acceptanceDocumentsApi/acceptanceDocumentsApi';

const SuperPdsIframe = () => {
  const { data } = useSuperPdsDocumentQuery();
  const { url, name } = data?.acceptance_document || {};

  return <iframe src={url} width="100%" height="100%" title={name} />;
};

export default SuperPdsIframe;
