import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import { Icon } from 'components/elements/Icon';

export const Wrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    border-radius: 6px;
    box-shadow: ${theme.shadows.primary};
    font-family: ${theme.fontFamily.regular};
    overflow: hidden;
    position: relative;
    text-align: left;
    height: fit-content;
    animation-name: fadein;
    animation-duration: 925ms;
    margin-bottom: 20px;
    width: 100%;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    margin-bottom: 15px;
    padding: 15px 20px 0;
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => `
    height: 1px;
    background-color: ${theme.colors.mercury};
    margin-bottom: 19px;
    margin-left: -20px;
    width: calc(100% + 40px);
  `}
`;

export const Header = styled.div`
  ${({ theme }) => `
    text-align: center;
    margin-top: 24px;
    margin-bottom: 40px;
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes.md};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.fontSizes['3md']};
  `}
`;

export const BalanceText = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['5lg']};
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length['3sm']};
    margin-top: 20px;
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    flex-wrap: wrap;
  }
`;
export const Column = styled.div`
  width: 50%;
  text-align: center;

  :first-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.mercury};
  }

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
  }
`;

export const Subtitle = styled.div`
  ${({ theme }) => `
    align-items: center;
    color: ${theme.colors.mirage};
    display: flex;
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['6sm']};
    justify-content: center;
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length.md};
    text-align: center;
  `}
`;

export const ValueText = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['5xl']};
    letter-spacing: ${theme.letterSpacing.wide};
    line-height: ${theme.lineHeights.length['6xl']};
  `}
`;

export const FlagIcon = styled(Icon)`
  margin-bottom: -5px;
`;

export const RecurringWidgetButton = styled(Button)`
  margin: 0 auto 15px;
  width: 335px;
`;
