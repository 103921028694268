import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { checkIsDependentUser } from 'utils/user';

interface ICheckIsRewardsEnabled {
  isRewardsFeatureEnabled: boolean;
  isNewRewardsEnabled: boolean;
  user: Record<string, unknown>;
  dependentUserRewardsAccess: boolean;
}

export const checkIsRewardsEnabled = ({
  isRewardsFeatureEnabled,
  isNewRewardsEnabled,
  user,
  dependentUserRewardsAccess,
}: ICheckIsRewardsEnabled) => {
  if (AU_APP && !isNewRewardsEnabled) return true;

  if (MY_APP && isRewardsFeatureEnabled) {
    if (checkIsDependentUser(user)) {
      if (dependentUserRewardsAccess) return true;

      return false;
    }

    return true;
  }

  return false;
};
