import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

import '../../../styles/layout/logout.scss';

export default function KTPInfoModal({ showKTPInfo, toggleKTPInfo }) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={showKTPInfo}
      onRequestClose={() => toggleKTPInfo(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal modal-basic__information"
    >
      <DeprecatedButton
        buttonClass="button__close -black"
        handleClick={() => toggleKTPInfo(false)}
      />

      <div className="time-investment__modal-title">
        {t('ktpInfoModal.title')}
      </div>

      <div className="time-investment__modal-content">
        <div>
          <div className="modal-basic__information-img" />
        </div>

        <div className="modal-basic__information-content">
          {t('ktpInfoModal.content')}
        </div>
      </div>
    </Modal>
  );
}

KTPInfoModal.propTypes = {
  showKTPInfo: PropTypes.bool.isRequired,
  toggleKTPInfo: PropTypes.func.isRequired,
};
