import styled from '@emotion/styled';

import BankIcon from 'assets/images/icons/bank-icon.svg';
import { Button } from 'components/elements/Button';
import closeBlack from 'assets/images/icons/close-black.svg';

export const Card = styled.div`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  height: unset;
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const Logo = styled.div<{
  source: React.FunctionComponent | string;
}>`
  background: url(${({ source }) => `${source || BankIcon}`}) no-repeat center
    center;
  background-size: contain;
  height: 100%;
  width: auto;
`;

export const LogoWrapper = styled.div`
  height: 48px;
  min-width: 48px;
`;

export const Title = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  margin-left: 20px;
`;

export const Content = styled.div`
  padding: 20px;
`;

export const NextButton = styled(Button)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  width: 320px;
  margin: 20px 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.niagaraHover};
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 10px;
`;

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  background-image: url(${`${closeBlack}`});
  background-repeat: no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
`;
