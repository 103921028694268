import 'styles/pages/super.scss';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import InsuranceCoverForm from './Forms/InsuranceCoverForm';
import TfnForm from './Forms/TfnForm';
import SuperPdsIframe from './components/SuperPdsIframe';
import { SuperRegistrationBanner } from './components/SuperRegistrationBanner';
import { SUPER_REGISTRATION_STEPS } from './consts';

import { PATHS } from 'constants/paths';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';
import { FEMALE, MALE } from 'constants/comonConstants';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import Loader from 'components/elements/Loader/Loader';
import SuperRegistrationModal from 'components/layout/Modals/SuperRegistrationModal';

export default function SuperRegistrationView({
  handleSubmit,
  errorMessage,
  handleCloseModal,
  isShowErrorModal,
  step,
  changeGender,
  tfnChecked,
  tfn,
  handleChange,
  errorTfn,
  errorTfnChecked,
  handleChangeInsurance,
  disclosureChecked,
  insuranceChecked,
  receivingChecked,
  backInChecked,
  agreeChecked,
  pdsErrors,
  isPdsErrors,
  gender,
  goBack,
  superUser,
  user,
  isShowInsuranceModal,
  showLoader,
  showMainLoader,
  isShowProvideTFNModal,
}) {
  const { t } = useTranslation();

  return (
    <div className="super-registration">
      {showMainLoader && <Loader additionalClass="-absolute -top" />}

      {!showMainLoader && (
        <div>
          <SuperRegistrationBanner step={step} />

          {step === SUPER_REGISTRATION_STEPS.terms && (
            <div className="container first-step">
              <div className="terms-scroll">
                <SuperPdsIframe />
              </div>

              <div className="bottom-content">
                <div className="left-part">
                  <div className="title">
                    {t('superPage.superRegistration.contactDetails')}
                  </div>

                  <div className="text">
                    {t('superPage.superRegistration.diversa')}
                  </div>
                </div>

                <div className="right-part">
                  <DeprecatedButton
                    title={
                      showLoader ? (
                        <DeprecatedDotsLoader additionalClass="btn" />
                      ) : (
                        t('common.continue')
                      )
                    }
                    buttonClass="button button--submit"
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          )}

          {step === SUPER_REGISTRATION_STEPS.gender && (
            <div className="container">
              <div className="content">
                <div className="title">
                  {t('superPage.superRegistration.gender.title')}
                </div>

                <DeprecatedButton
                  title={<Trans i18nKey="common.gender.male" t={t} />}
                  type="button"
                  handleClick={() => changeGender(MALE)}
                  buttonClass={gender === MALE ? '-active-btn' : ''}
                />

                <DeprecatedButton
                  title={<Trans i18nKey="common.gender.female" t={t} />}
                  type="button"
                  handleClick={() => changeGender(FEMALE)}
                  buttonClass={gender === FEMALE ? '-active-btn' : ''}
                />
              </div>

              <hr />

              <div className="bottom-content">
                <div className="left-part">
                  <div className="text">
                    {t('superPage.superRegistration.gender.footer')}
                  </div>
                </div>

                <div className="right-part">
                  <DeprecatedButton
                    title={
                      showLoader ? (
                        <DeprecatedDotsLoader additionalClass="btn" />
                      ) : (
                        t('common.back')
                      )
                    }
                    buttonClass="button back"
                    handleClick={goBack}
                  />

                  <DeprecatedButton
                    title={
                      showLoader ? (
                        <DeprecatedDotsLoader additionalClass="btn" />
                      ) : (
                        t('common.continue')
                      )
                    }
                    buttonClass="button button--submit"
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          )}

          {step === SUPER_REGISTRATION_STEPS.taxFileNumber && (
            <div className="container">
              <TfnForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                tfn={tfn}
                errorTfn={errorTfn}
                tfnChecked={tfnChecked}
                errorTfnChecked={errorTfnChecked}
                goBack={goBack}
                showLoader={showLoader}
              />
            </div>
          )}

          {step === SUPER_REGISTRATION_STEPS.portfolio && (
            <ChangePortfolio isSuper isSuperRegistration />
          )}

          {step === SUPER_REGISTRATION_STEPS.insurance && (
            <div className="container">
              <InsuranceCoverForm
                handleSubmit={handleSubmit}
                handleChangeInsurance={handleChangeInsurance}
                disclosureChecked={disclosureChecked}
                insuranceChecked={insuranceChecked}
                receivingChecked={receivingChecked}
                backInChecked={backInChecked}
                agreeChecked={agreeChecked}
                pdsErrors={pdsErrors}
                isPdsErrors={isPdsErrors}
                superUser={superUser}
                user={user}
                goBack={goBack}
              />
            </div>
          )}

          {((isShowErrorModal && errorMessage) ||
            isShowInsuranceModal ||
            isShowProvideTFNModal) && (
            <SuperRegistrationModal
              isShowInsuranceModal={isShowInsuranceModal}
              isShowProvideTFNModal={isShowProvideTFNModal}
              handleCloseModal={handleCloseModal}
              handleSubmit={handleSubmit}
              errorMessage={errorMessage}
            />
          )}

          {step === SUPER_REGISTRATION_STEPS.completed && (
            <div className="container">
              <div className="bottom-content">
                <div className="left-part">
                  <div className="text">
                    <div>{t('superPage.superRegistration.thankYou')}</div>

                    <div>{t('superPage.superRegistration.youCan')}</div>
                  </div>
                </div>

                <div className="right-part">
                  <a href={PATHS.super.rollovers}>
                    <DeprecatedButton
                      title={
                        <Trans
                          i18nKey="superPage.superRegistration.existingFund"
                          t={t}
                        />
                      }
                      type="button"
                      buttonClass="existing-rollover-btn"
                    />
                  </a>

                  <a href="/super/">
                    <DeprecatedButton
                      title={<Trans i18nKey="common.finish" t={t} />}
                      type="button"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SuperRegistrationView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isShowErrorModal: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  changeGender: PropTypes.func.isRequired,
  tfnChecked: PropTypes.bool.isRequired,
  tfn: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errorTfn: PropTypes.string.isRequired,
  errorTfnChecked: PropTypes.string.isRequired,
  handleChangeInsurance: PropTypes.func.isRequired,
  disclosureChecked: PropTypes.bool.isRequired,
  insuranceChecked: PropTypes.bool.isRequired,
  receivingChecked: PropTypes.bool.isRequired,
  backInChecked: PropTypes.bool.isRequired,
  agreeChecked: PropTypes.bool.isRequired,
  pdsErrors: PropTypes.shape({}).isRequired,
  isPdsErrors: PropTypes.bool.isRequired,
  superUser: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  isShowInsuranceModal: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  isShowProvideTFNModal: PropTypes.bool.isRequired,
};
