import { useTranslation } from 'react-i18next';

import { ContentButton, ContentButtonWrapper } from './styles';

import { MY_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IRoundUpsContentButtonsProps {
  uncheckAll: () => void;
  submitTransactions: () => void;
  investSum: number;
  selectedTransactions: string[];
}

export const RoundUpsContentButtons = ({
  investSum,
  submitTransactions,
  uncheckAll,
  selectedTransactions,
}: IRoundUpsContentButtonsProps) => {
  const { t } = useTranslation();

  const investSumValue = formatNumber({ value: investSum });

  if (MY_APP) {
    return null;
  }

  return (
    <ContentButtonWrapper>
      <ContentButton
        label={t('roundUps.invest', {
          value: investSumValue,
        })}
        onClick={submitTransactions}
        isDisabled={!investSum}
      />

      <ContentButton
        isWhite
        label={t('roundUps.cancel')}
        onClick={uncheckAll}
        isDisabled={!selectedTransactions.length}
        variant="initial"
      />
    </ContentButtonWrapper>
  );
};
