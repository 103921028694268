import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Date, InfoContentItems, InfoWrapper } from './styles';
import { InfoItem } from './InfoItem';

import { DATE_FORMAT_WITH_SHORT_MONTH } from 'config/date';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectSummaryByIndex } from 'store/perfomance/selectors';

export interface IPerfomanceInfoContentProps {
  dataPointIndex: number;
}

export const PerfomanceInfoContent = ({
  dataPointIndex,
}: IPerfomanceInfoContentProps) => {
  const { t } = useTranslation();

  const { date, invested_by_you, withdrawn, rewards, dividends } =
    useAppSelector(selectSummaryByIndex(dataPointIndex));

  return (
    <InfoWrapper>
      <InfoContentItems>
        <Date>{dayjs(date).format(DATE_FORMAT_WITH_SHORT_MONTH)}</Date>

        <InfoItem
          label={t('performanceDetailsPage.investedYou')}
          value={invested_by_you}
        />

        <InfoItem
          label={t('performanceDetailsPage.dividendLabel')}
          value={dividends}
        />
      </InfoContentItems>

      <InfoContentItems>
        <InfoItem
          label={t('performanceDetailsPage.withdrawn')}
          value={withdrawn}
        />

        <InfoItem
          label={t('performanceDetailsPage.cashRewards')}
          value={rewards}
        />
      </InfoContentItems>
    </InfoWrapper>
  );
};
