import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';
import { STATUS } from 'constants/api';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { PATHS } from 'constants/paths';
import { closeModal as closeModalAction } from 'store/fundingAccount/actions';

export default function FundingErrorModal({
  isFundingErrorVisible,
  closeModal,
  errorMessage,
  fundingStatusCode,
  requestChange,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeModalWithRedirect = () => {
    dispatch(closeModalAction());

    navigate(PATHS.alerts.index);
  };

  const handleCloseModal =
    fundingStatusCode === STATUS.locked ? closeModalWithRedirect : closeModal;

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isFundingErrorVisible}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">{errorMessage}</div>

        {fundingStatusCode && fundingStatusCode === 428 && (
          <div className="alert-buttons">
            <DeprecatedButton
              title={t('common.cancel')}
              type="button"
              buttonClass="wait"
              handleClick={closeModal}
            />

            <DeprecatedButton
              title={t('common.confirm')}
              type="button"
              buttonClass="skip"
              handleClick={requestChange}
            />
          </div>
        )}

        {(!fundingStatusCode || fundingStatusCode !== 428) && (
          <div className="error-buttons">
            <DeprecatedButton
              title={t('common.ok')}
              type="button"
              handleClick={handleCloseModal}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

FundingErrorModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  isFundingErrorVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  fundingStatusCode: PropTypes.number.isRequired,
  requestChange: PropTypes.func.isRequired,
};
