import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import getPortfolioColor from '../../utils/getPortfolioColor';

import EditPortfolioThemeForm from './EditPortfolioThemeForm';

import { ModalWrapper } from 'components/layout/modal';

export default function EditPortfolioThemeModal({
  isThemeEditModalOpen,
  setIsThemeEditModalOpen,
  updatePortfolioTheme,
  savedPortfolioColor,
}) {
  const [updatedPortfolioColor, setUpdatedPortfolioColor] =
    useState(savedPortfolioColor);

  const wrapperColor = getPortfolioColor({
    isProPortfolio: true,
    portfolioColor: updatedPortfolioColor,
  });

  return (
    <ModalWrapper
      isOpen={isThemeEditModalOpen}
      onRequestClose={() => setIsThemeEditModalOpen(false)}
      shouldCloseOnOverlayClick
      color={wrapperColor}
    >
      <Form
        onSubmit={updatePortfolioTheme}
        render={({ values }) => (
          <EditPortfolioThemeForm
            values={values}
            updatePortfolioTheme={updatePortfolioTheme}
            handleClose={() => setIsThemeEditModalOpen(false)}
            setIsThemeEditModalOpen={setIsThemeEditModalOpen}
            updatedPortfolioColor={updatedPortfolioColor}
            setUpdatedPortfolioColor={setUpdatedPortfolioColor}
          />
        )}
      />
    </ModalWrapper>
  );
}

EditPortfolioThemeModal.propTypes = {
  isThemeEditModalOpen: PropTypes.bool.isRequired,
  setIsThemeEditModalOpen: PropTypes.func.isRequired,
  updatePortfolioTheme: PropTypes.func.isRequired,
  savedPortfolioColor: PropTypes.shape({}).isRequired,
};
