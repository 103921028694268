import React from 'react';
import PropTypes from 'prop-types';

import KidImage from './KidImage';
import KidAge from './KidAge';
import { KidCardRow } from './styles';

function KidCardClosed({ kid }) {
  return (
    <KidCardRow isClosed={kid.closed}>
      <KidImage iconUrl={kid.icon_url} isClosed={kid.closed} />

      <KidAge kid={kid} />
    </KidCardRow>
  );
}

export default KidCardClosed;

KidCardClosed.defaultProps = {
  kid: {},
};

KidCardClosed.propTypes = {
  kid: PropTypes.shape({
    closed: PropTypes.bool,
    icon_url: PropTypes.string,
  }),
};
