import { useTranslation } from 'react-i18next';

import { IFrequencyIdentifier } from 'store/dependentUser/types';

export const useRecurringInvestmentFrequency = (
  currentFrequency?: IFrequencyIdentifier,
) => {
  const { t } = useTranslation();

  return currentFrequency && t(`common.frequencies.${currentFrequency}`);
};
