import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FrequencyChangeButton, recurringButtonStyles } from '../styles';

import Time from 'components/elements/Time/Time';
import {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  FREQUENCY,
  BAHASA,
  ISNIN,
  SELASA,
  RABU,
  KHAMIS,
  JUMAAT,
} from 'constants/comonConstants';
import { APP_DATE_LOCALE, MY_APP } from 'constants/localeConfigs';
import { DATE_FORMAT_WEEK_DAY_MONTH } from 'config/date';

const renderWeek = (day, index, changeFrequencyDay, frequencyDay) => {
  const activeDate = frequencyDay === index + 2;

  return (
    <div
      className={`info-panel__item recurring-investment__day ${
        activeDate ? '-active' : ''
      }`}
      key={index}
      onClick={() => changeFrequencyDay(index + 2)}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      {day}

      {activeDate && <i className="recurring-investment__icon -check" />}
    </div>
  );
};

const renderFortnight = (day, index, changeStartDate, startDate) => {
  const activeDate =
    new Date(startDate).getFullYear() === new Date(day).getFullYear() &&
    new Date(startDate).getMonth() === new Date(day).getMonth() &&
    new Date(startDate).getDate() === new Date(day).getDate();

  return (
    <div
      className={`info-panel__item recurring-investment__day ${
        activeDate ? '-active' : ''
      }`}
      key={index}
      onClick={() => changeStartDate(day)}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <Time format="dddd DD MMMM">{day}</Time>

      {activeDate && <i className="recurring-investment__icon -check" />}
    </div>
  );
};

const renderDay = (day, index, frequencyDay, changeFrequencyDay) => (
  <span
    className={`recurring-investment__month-day ${
      frequencyDay === day + 1 ? '-active' : ''
    }`}
    key={index}
    onClick={() => changeFrequencyDay(day + 1)}
    onKeyPress={() => {}}
    role="button"
    tabIndex="0"
  >
    {day + 1}
  </span>
);

const getDateArray = () => {
  const start = new Date();
  const arr = [];
  const dt = new Date(start);
  const saturday = 6;
  const sunday = 0;

  while (arr.length < 10) {
    dt.setDate(dt.getDate() + 1);

    if (dt.getDay() !== saturday && dt.getDay() !== sunday) {
      const date = new Date(dt);

      arr.push(date);
    }
  }

  return arr;
};

function FrequencyChange(props) {
  const {
    currentFrequency,
    changeCurrentFrequency,
    frequencyDay,
    changeFrequencyDay,
    changeStartDate,
    startDate,
    isModal,
  } = props;

  const { t } = useTranslation();
  const currentLang = localStorage.getItem('lang');

  const month = Array(28).fill(1).map(Number.call, Number);

  const week =
    MY_APP && currentLang === BAHASA
      ? [ISNIN, SELASA, RABU, KHAMIS, JUMAAT]
      : [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY];

  const fortnightly = getDateArray();
  const currentFrequencyValue = FREQUENCY[currentFrequency] || FREQUENCY.daily;

  return (
    <div>
      {currentFrequencyValue === FREQUENCY.weekly && (
        <div className="info-panel -with-padding">
          <div className="info-panel__title-sub">
            {t(
              'recurringInvestmentPage.frequencyChange.subTitle.selectDayOfWeek',
            )}
          </div>

          {week.map((day, index) =>
            renderWeek(day, index, changeFrequencyDay, frequencyDay),
          )}
        </div>
      )}

      {currentFrequencyValue === FREQUENCY.fortnightly && (
        <div className="info-panel -with-padding">
          <div className="info-panel__title-sub">
            {t(
              'recurringInvestmentPage.frequencyChange.subTitle.chooseTheStartDay',
            )}
          </div>

          {fortnightly.map((day, index) =>
            renderFortnight(day, index, changeStartDate, startDate),
          )}
        </div>
      )}

      {currentFrequencyValue === FREQUENCY.monthly && (
        <div className="info-panel -with-padding">
          <div className="recurring-investment__month">
            {month.map((day, index) =>
              renderDay(day, index, frequencyDay, changeFrequencyDay),
            )}
          </div>

          <div
            className={`recurring-investment__month-last ${
              frequencyDay === -1 ? '-active' : ''
            }`}
            onClick={() => changeFrequencyDay(-1)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <span>
              {t('recurringInvestmentPage.frequencyChange.lastDayOfMonth')}
            </span>
          </div>
        </div>
      )}

      {(currentFrequencyValue === FREQUENCY.daily ||
        !currentFrequencyValue) && (
        <FrequencyChangeButton
          label={t('recurringInvestmentPage.frequencyChange.button.setDaily')}
          onClick={() => changeCurrentFrequency(FREQUENCY.daily)}
          customStyles={!isModal && recurringButtonStyles.styles}
        />
      )}

      {currentFrequencyValue === FREQUENCY.monthly && (
        <FrequencyChangeButton
          label={t(
            'recurringInvestmentPage.frequencyChange.button.setMonthlyOn',
            {
              day:
                frequencyDay === -1
                  ? t('recurringInvestmentPage.frequencyChange.lastDayOfMonth')
                  : frequencyDay,
            },
          )}
          onClick={() => changeCurrentFrequency(currentFrequency)}
          customStyles={!isModal && recurringButtonStyles.styles}
        />
      )}

      {currentFrequencyValue === FREQUENCY.fortnightly && (
        <FrequencyChangeButton
          label={
            startDate ? (
              <Trans
                i18nKey="recurringInvestmentPage.frequencyChange.button.setFortnightlyOn"
                t={t}
                components={{
                  day: dayjs(startDate)
                    .locale(APP_DATE_LOCALE)
                    .format(DATE_FORMAT_WEEK_DAY_MONTH),
                }}
              />
            ) : (
              t('recurringInvestmentPage.frequencyChange.button.setFortnightly')
            )
          }
          onClick={() => changeCurrentFrequency(currentFrequency)}
          customStyles={!isModal && recurringButtonStyles.styles}
        />
      )}

      {currentFrequencyValue === FREQUENCY.weekly && (
        <FrequencyChangeButton
          label={t(
            'recurringInvestmentPage.frequencyChange.button.setWeeklyOn',
            {
              day: week[frequencyDay - 2] ? `- ${week[frequencyDay - 2]}` : '',
            },
          )}
          onClick={() => changeCurrentFrequency(currentFrequency)}
          customStyles={!isModal && recurringButtonStyles.styles}
        />
      )}
    </div>
  );
}

export default FrequencyChange;

FrequencyChange.defaultProps = {
  frequencyDay: null,
  startDate: null,
  isModal: false,
};

FrequencyChange.propTypes = {
  changeCurrentFrequency: PropTypes.func.isRequired,
  changeFrequencyDay: PropTypes.func.isRequired,
  changeStartDate: PropTypes.func.isRequired,
  frequencyDay: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  currentFrequency: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  isModal: PropTypes.bool,
};
