import styled from '@emotion/styled';

import HelpWhiteSvg from 'assets/images/icons/help-white.svg';
import { BaseButton } from 'components/elements/Deprecated__Button';
import { Icon } from 'components/elements/Icon';

interface StyledButtonProps {
  active?: boolean;
}

export const Wrapper = styled.div`
  text-align: center;
`;

export const MyFinanceBanner = styled.div`
  ${({ theme }) => `
    background: ${theme.gradients.mysticSunrise} no-repeat center;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 40px;
    padding-top: 68px;
    animation-name: fadein;
    animation-duration: 925ms;
    border-radius: 6px;
    position: relative;
    width: 100%;
  `}
`;

export const InfoButton = styled(BaseButton)`
  ${({ theme }) => `
    background: ${theme.colors.transparent} url(${`${HelpWhiteSvg}`}) no-repeat;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 22px;
    right: 28px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-family:  ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['4lg']};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing:  ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};

    ${theme.mediaQuery(theme.breakpoints.mobile)} {
      line-height: ${theme.lineHeights.length.xl};
    }
  `}
`;

export const Switcher = styled.div`
  ${({ theme }) => `
    margin-top: 30px;
    display: inline-flex;
    border-radius: 18px;
    background-color: ${theme.colors.blackTransparent};
    width: auto;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.medium};
    padding: 3px;
    margin-bottom: 35px;

    ${theme.mediaQuery(theme.breakpoints.mobile)} {
      margin-bottom: 0;
    }
  `}
`;

export const SwitcherButton = styled(BaseButton)<StyledButtonProps>`
  ${({ theme, active }) => `
    background-color: ${active ? theme.colors.white : 'transparent'};
    border-radius: 18px;
    color: ${active ? theme.colors.black : theme.colors.white};
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['4sm']};
    margin: 0;
    padding: 4px 23px;
    min-width: 168px;
    height: auto;
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const SortButtonWrapper = styled.div`
  background: transparent;
  display: flex;
`;

export const SortButton = styled(BaseButton)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    background: ${theme.colors.transparent};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    position: absolute;
    bottom: 20px;
    left: 50px;
  `}
`;

export const SortIcon = styled(Icon)`
  display: block;
  position: absolute;
  bottom: 16px;
  left: 4px;
  bottom: 17px;
`;

export const InfoPanel = styled.div`
  ${({ theme }) => `
    animation-name: fadein;
    animation-duration: 925ms;
    box-shadow: ${theme.shadows.primary};
    border-radius: 6px;
    background-color: ${theme.colors.white};
    text-align: left;
    overflow: hidden;
    position: relative;
  `}
`;

export const InfoPanelTitle = styled.div`
  ${({ theme }) => `
    padding: 15px 20px 0;
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    margin-bottom: 15px;
  `}
`;

export const HistoryDateWrapper = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.athensGray};
    padding: 4px 20px;
    opacity: ${theme.opacity.mediumTransparent};
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    text-align: left;
  `}
`;

export const CategoryWrapper = styled.div`
  ${({ theme }) => `
    padding: 14px 19px 12px;
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textMedium};
    font-size: ${theme.fontSizes.md};
    font-weight: ${theme.fontWeights.medium};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    border-bottom: 1px solid ${theme.colors.mercury};
    cursor: pointer;
    text-align: left;
  `}
`;

export const CategoryTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CategoryDescription = styled.div`
  ${({ theme }) => `
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    opacity: ${theme.opacity.mediumTransparent};
    color: ${theme.colors.mirage};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['4sm']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.length.md};
    text-transform: lowercase;
  `}
`;

export const CategoryStatus = styled.div`
  padding-left: 25px;
  position: relative;
`;

export const EditIcon = styled(Icon)`
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px;
`;
