import React from 'react';

import { usePortfolioCustomizationRoute } from '../../../../../hooks';
import { useFundStatistics } from '../../hooks';

import BitcoinStatisticItems from './BitcoinStatisticItems';
import FundStatisticItems from './FundStatisticItems';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

export default function StatisticContent() {
  const { statistics } = useFundStatistics();
  const { isBitcoinFundPage } = usePortfolioCustomizationRoute();

  if (!statistics) return <DotsLoader />;

  if (isBitcoinFundPage) return <BitcoinStatisticItems />;

  return <FundStatisticItems />;
}
