import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BAHASA } from '../../../../constants/comonConstants';
import { MY_APP } from '../../../../constants/localeConfigs';
import DeprecatedDotsLoader from '../../../elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { ROUND_UP_AUTO_ON } from '../../../../constants/togglesFunctions';
import ProgressInfo from '../../../elements/ProgressInfo/ProgressInfo';
import { Label, ContainerItem } from '../styles';

import AutomaticRoundUpsView from './AutomaticRoundUpsView';

import { useRoundUpsSettingsState } from 'components/layout/Modals/RoundUpsModal/hooks/useRoundUpsSettingsState';
import { RootState } from 'store/configureStore';

interface isAutomaticRoundUpsProps {
  isLoading: boolean;
}

export const AutomaticRoundUps = ({ isLoading }: isAutomaticRoundUpsProps) => {
  const { t } = useTranslation();

  const { roundUpsSummary } = useSelector((state: RootState) => ({
    roundUpsSummary: state.user.roundUpsSummary,
  }));

  const { roundUpsSettings: settings } = useRoundUpsSettingsState();

  const currentLang = localStorage.getItem('lang');

  return (
    <div>
      {ROUND_UP_AUTO_ON && (
        <Label>{t('roundUps.automaticRoundUps.title')}</Label>
      )}

      <ContainerItem
        type="button"
        isNonCursor
        isBig={MY_APP && currentLang === BAHASA}
      >
        <ProgressInfo
          value={ROUND_UP_AUTO_ON ? roundUpsSummary.automatic.waiting : 0}
          maxValue={Number(settings.automatic_lower_threshold)}
        >
          {isLoading ? (
            <DeprecatedDotsLoader additionalClass="btn" />
          ) : (
            <AutomaticRoundUpsView
              settings={settings}
              roundUpsSummary={roundUpsSummary}
            />
          )}
        </ProgressInfo>
      </ContainerItem>
    </div>
  );
};
