import dayjs from 'dayjs';
import { t } from 'i18next';

import { showGlobalError } from '../errors/actions';
import { getHeaders } from '../../services/api/headers';
import { showErrorModal } from '../history/actions';
import { ISO_DATE_FORMAT } from '../../config/date';
import { api } from '../../services/api';
import { INVESTMENT_TYPES } from '../../constants/kidsConstants';
import { displayErrorModal, toggleInfoModal } from '../modals/actions';
import { selectPortfolioParams } from '../portfolio/selectors';

import {
  ACCESS_SELECTED,
  GO_BACK,
  INVESTMENT_INITIAL,
  INVESTMENT_RECURRING,
  PERSONAL_INFO,
  PERSONAL_TERMS,
  CLOSE_ACCOUNT_KIDS_SUCCESS,
  CLOSE_MODAL,
  CONVERT_FULL_SUCCESS,
  DEPENDENCY_USER_INVESTMENT_SUCCESS,
  DEPENDENCY_USER_PORTFOLIO_SUCCESS,
  DEPENDENCY_USER_RECCURRING_SUCCESS,
  GET_DEPENDENCY_USER_SUCCESS,
  GET_DEPENDENCY_USER_SUMMARY_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_SECURITY_SUCCESS,
  RESENT_LINK_SUCCESS,
  SHOW_ERROR_CREATE_USER,
  SHOW_ERROR_GET_DEPENDENCY_USER_SUMMARY,
  UPDATE_NOTIFICATIONS_SUCCESS,
  TRANSFER_IN_SUCCESS,
  ACTIVATE_ACCOUNT_KIDS_SUCCESS,
  SHOW_ERROR_MODAL,
  GET_DEPENDENCY_USER_LOADING,
} from './types';

import { MY_APP } from 'constants/localeConfigs';
import { FREQUENCY } from 'constants/comonConstants';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function registrationDependencyUserSuccess(response, formObj) {
  return { type: formObj.from, response };
}

export function updateDependencyUserSuccess(res, formObj) {
  return { type: formObj.from, res };
}

export function getDependencyUsersSummarySuccess(res) {
  return { type: GET_DEPENDENCY_USER_SUMMARY_SUCCESS, res };
}

export function getNotificationsSuccess(res) {
  return { type: GET_NOTIFICATIONS_SUCCESS, res };
}

export function getDependencyUserSuccess(res) {
  return { type: GET_DEPENDENCY_USER_SUCCESS, res };
}

export function getSecuritySuccess(res) {
  return { type: GET_SECURITY_SUCCESS, res };
}

export function dependencyUserPortfolioSuccess(res) {
  return { type: DEPENDENCY_USER_PORTFOLIO_SUCCESS, res };
}

export function resentLinkSuccess(res) {
  return { type: RESENT_LINK_SUCCESS, res };
}

export function convertToFullSuccess(res) {
  return { type: CONVERT_FULL_SUCCESS, res };
}

export function closeAccountSuccess(res) {
  return { type: CLOSE_ACCOUNT_KIDS_SUCCESS, res };
}

export function reactivateAccountSuccess(res) {
  return { type: ACTIVATE_ACCOUNT_KIDS_SUCCESS, res };
}

export function transferInSuccess(res) {
  return { type: TRANSFER_IN_SUCCESS, res };
}

export function dependencyUserInvestmentSuccess(res) {
  return { type: DEPENDENCY_USER_INVESTMENT_SUCCESS, res };
}

export function dependencyUserReccurringSuccess(res) {
  return { type: DEPENDENCY_USER_RECCURRING_SUCCESS, res };
}

export function updateNotificationsSuccess(res, formObj) {
  return { type: UPDATE_NOTIFICATIONS_SUCCESS, res, formObj };
}

export function updateNotificationsFailure(errorMessage) {
  return { type: SHOW_ERROR_MODAL, errorMessage };
}

export function showErrorgetDependencyUsersSummary(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_GET_DEPENDENCY_USER_SUMMARY,
    errorMessage,
    errorTitle,
  };
}

export function showErrorRegistrationDependencyUser(
  errorMessage,
  errorTitle = '',
) {
  return { type: SHOW_ERROR_CREATE_USER, errorMessage, errorTitle };
}

export function goBack(step) {
  return { type: GO_BACK, step };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function getDependencyUserLoading(isLoading) {
  return { type: GET_DEPENDENCY_USER_LOADING, isLoading };
}

export function registrationDependencyUser(formObj, requestData) {
  const step = formObj.from;

  switch (formObj.form) {
    case PERSONAL_TERMS:
      return { type: step };
    case PERSONAL_INFO:
      return { type: step };
    default:
      break;
  }

  const data = JSON.stringify({
    ...requestData,
    date_of_birth: dayjs(requestData.date_of_birth).format(ISO_DATE_FORMAT),
  });

  if (step !== PERSONAL_TERMS) {
    return (dispatch) =>
      fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/users`, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: getHeaders(),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            dispatch(showErrorRegistrationDependencyUser(response.error));
          } else {
            const oldData = sessionStorage.getItem(
              SESSION_STORAGE_KEYS.kidsRegistration,
            );

            if (oldData) {
              sessionStorage.setItem(
                SESSION_STORAGE_KEYS.kidsRegistration,
                JSON.stringify({
                  dependency_user: {
                    ...response.dependency_user,
                    ...JSON.parse(oldData).dependency_user,
                    account_access: response.dependency_user.account_access,
                  },
                }),
              );
            } else {
              sessionStorage.setItem(
                SESSION_STORAGE_KEYS.kidsRegistration,
                JSON.stringify(response),
              );
            }

            dispatch(registrationDependencyUserSuccess(response, formObj));
          }
        })
        .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
  }

  return registrationDependencyUserSuccess({}, formObj);
}

export function updateDependencyUser(formObj, requestData, onSuccess) {
  const step = formObj.from;

  switch (formObj.form) {
    case INVESTMENT_INITIAL:
      return { type: step };
    case INVESTMENT_RECURRING:
      return { type: step };
    case ACCESS_SELECTED:
      return { type: step };
    default:
      break;
  }

  const data = JSON.stringify({
    ...requestData,
    ...(requestData?.date_of_birth && {
      date_of_birth: dayjs(requestData.date_of_birth).format(ISO_DATE_FORMAT),
    }),
  });

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/users`, {
      method: 'PUT',
      mode: 'cors',
      body: data,
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          const oldData = sessionStorage.getItem(
            SESSION_STORAGE_KEYS.kidsRegistration,
          );

          if (oldData) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.kidsRegistration,
              JSON.stringify({ ...JSON.parse(oldData), ...response }),
            );
          } else {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.kidsRegistration,
              JSON.stringify(response),
            );
          }

          dispatch(updateDependencyUserSuccess(response, formObj));

          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getDependencyUsersSummary(withClosed) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/users/summary${
        withClosed ? '?with_closed=true' : ''
      }`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getDependencyUsersSummarySuccess(response));

        return response.dependency_users.length > 0;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getNotifications(userId) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/notifications/${userId}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getNotificationsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getDependencyUser(dependentUserId = '') {
  return async (dispatch, getState) => {
    dispatch(getDependencyUserLoading(true));

    try {
      const state = getState();
      const { childId } = selectPortfolioParams(state);
      const userId = dependentUserId || childId;

      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/users/${userId}`,
      );

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        dispatch(getDependencyUserSuccess(response));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(getDependencyUserLoading(false));
    }
  };
}

export function getSecurity(token) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/security_questions?access_token=${token}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        let selects = [];

        selects = response.map((question) => {
          selects.push({ label: question.text, value: question.id });

          return { label: question.text, value: question.id };
        });

        dispatch(getSecuritySuccess(selects));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function dependencyUserInvestment(requestData, userId) {
  const data = {
    amount: requestData.amount,
    type: requestData.type,
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/investments`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(dependencyUserInvestmentSuccess(response));

          const description = MY_APP
            ? t(
                'raizKidsPage.kidsInitialInvestmentModal.investmentSuccess.investNowDescription',
              )
            : t(
                'raizKidsPage.kidsInitialInvestmentModal.investmentSuccess.description',
              );

          if (!requestData?.isRegistration) {
            dispatch(
              toggleInfoModal({
                isInfoModalVisible: true,
                config: {
                  title: t(
                    'raizKidsPage.kidsInitialInvestmentModal.investmentSuccess.title',
                  ),
                  description,
                },
              }),
            );
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function dependencyUserRecurring(requestData) {
  const data = {
    amount: requestData.recurringValue,
    frequency: requestData.frequency,
    ...(requestData.frequency === FREQUENCY.weekly && { day: requestData.day }),
    ...(requestData.frequency === FREQUENCY.monthly && {
      day: requestData.day,
    }),
    ...(requestData.frequency === FREQUENCY.fortnightly && {
      start_date: requestData.start_date,
    }),

    dependent_user_id: requestData.userId,
  };

  return async (dispatch) => {
    dispatch(getDependencyUserLoading(true));

    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/recurring_deposit_settings`,
        data,
      );

      dispatch(dependencyUserReccurringSuccess(response));

      dispatch(getDependencyUser(requestData.userId));
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || error?.response?.data?.errors?.[0];

      if (errorMessage) {
        dispatch(
          displayErrorModal({
            errorMessage,
          }),
        );
      }
    } finally {
      dispatch(getDependencyUserLoading(false));
    }
  };
}

export function stopRecurringInvestment(userId) {
  return async (dispatch) => {
    dispatch(getDependencyUserLoading(true));

    try {
      await api.delete(
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/recurring_deposit_settings`,
        { data: { dependent_user_id: userId } },
      );

      await dispatch(getDependencyUser(userId));

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: error?.response?.data?.error,
            },
          }),
        );
      }

      return false;
    } finally {
      dispatch(getDependencyUserLoading(false));
    }
  };
}

export function updateNotifications(requestDate, userId) {
  const data = {
    notifications: requestDate,
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/notifications`,
      {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(updateNotificationsFailure(response.error));
        } else {
          dispatch(updateNotificationsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function savePortfolio({ portfolioId, childId }) {
  const user = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
  );

  const userId = user ? user.dependency_user.id : childId;

  const data = {
    portfolio_id: portfolioId,
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/portfolios`, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(getDependencyUser());

          dispatch(dependencyUserPortfolioSuccess(response || portfolioId));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function resentLink(userId) {
  const data = {
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/users/resend_signup_email`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(resentLinkSuccess(response));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function convertToFull(data) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/users/convert_to_full`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(convertToFullSuccess(response));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function closeAccount(userId) {
  const data = {
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/users/close`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(closeAccountSuccess(response));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function transferIn(requestData) {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/transfers`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(requestData),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(transferInSuccess(response));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function activateAccount(userId) {
  const data = {
    dependent_user_id: userId,
  };

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/dependency_users/v1/users/reopen`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorRegistrationDependencyUser(response.error));
        } else {
          dispatch(reactivateAccountSuccess(response));
        }
      })
      .catch((error) => dispatch(showErrorRegistrationDependencyUser(error)));
}

export function sendTradeStatementForDependentUser({
  childId,
  onSendStatementsAuSuccess,
}) {
  const data = {
    dependent_user_id: childId,
  };

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/dependency_users/v1/statements/send_trade_statement`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error, false));
        } else {
          onSendStatementsAuSuccess?.();
        }
      })
      .catch((error) => {
        dispatch(showGlobalError(error));
      });
}

export function updateInvestmentAccess({ data, dependentUserId }) {
  const isInvestingEnabled =
    data.investmentAccessType !== INVESTMENT_TYPES.notAllow;

  const investingWeeklyLimit =
    data.investmentAccessType === INVESTMENT_TYPES.allowWeekly
      ? data.investing_weekly_limit
      : null;

  const requestData = {
    dependent_user: {
      investing: isInvestingEnabled,
    },
    dependent_user_id: dependentUserId,
  };

  if (isInvestingEnabled) {
    requestData.dependent_user.investing_weekly_limit = investingWeeklyLimit;
  }

  return async (dispatch) => {
    try {
      dispatch(getDependencyUserLoading(true));

      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/users`,
        requestData,
      );

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title: t('raizKidsEditPage.access.successModal.title'),
              description: t(
                'raizKidsEditPage.access.successModal.description',
              ),
            },
          }),
        );

        dispatch(getDependencyUserSuccess(response));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(getDependencyUserLoading(false));
    }
  };
}

export function sendStatementsListToEmail({
  selectedStatementsIds,
  childId,
  onSuccess,
}) {
  const data = {
    statement_uuids: selectedStatementsIds,
    dependent_user_id: childId,
  };

  return async (dispatch) => {
    try {
      await api.post(
        `${process.env.REACT_APP_API_URL}/dependency_users/v1/statements/mail`,
        data,
      );

      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        displayErrorModal({
          errorMessage: error.message,
        }),
      );
    }
  };
}
