import { useTranslation } from 'react-i18next';

import { useInviteCode } from '../hooks/useInviteCode';
import { useRegistrationFinancialPage } from '../hooks/useRegistrationFinancialPage';

import { INVITE_CODE_FIELD_NAME } from './const';

import { AU_APP } from 'constants/localeConfigs';
import { InputField } from 'components/form/input/InputField';

export const InviteCode = () => {
  const { t } = useTranslation();
  const { isSignUpPromoEnabled, inviteCode } = useInviteCode();

  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();

  if (AU_APP && isSignUpPromoEnabled && isRegistrationFinancialPage) {
    return (
      <InputField
        name={INVITE_CODE_FIELD_NAME}
        inputProps={{ disabled: !!inviteCode }}
        label={t('common.promoCode')}
      />
    );
  }

  return null;
};
