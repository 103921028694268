import { useTranslation } from 'react-i18next';

import {
  ConstructingTitle,
  ConstructingYourPortfolioCardWrapper,
} from './styles';

export default function ConstructingYourPortfolioCard() {
  const { t } = useTranslation();

  return (
    <ConstructingYourPortfolioCardWrapper>
      <ConstructingTitle>
        {t('portfolio.helpCards.constructingYourPortfolioCard.title')}
      </ConstructingTitle>
    </ConstructingYourPortfolioCardWrapper>
  );
}
