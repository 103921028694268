interface IBlogPostsItems {
  items?: IFields[];
  includes: {
    Asset: IAsset[];
  };
}

interface IMapBlogPostsResponse {
  blogPosts: IBlogPostsItems;
  blogURL: string;
}

interface IFields {
  fields?: { slug: string; title: string; postImage: { sys: { id: string } } };
}

interface IAsset {
  sys: { id: string };
  fields: { file: { url: string } };
}

const mapBlogPostsResponse = ({ blogPosts, blogURL }: IMapBlogPostsResponse) =>
  blogPosts?.items?.map(({ fields }: IFields) => {
    const assetImage = blogPosts?.includes?.Asset.find(
      (asset: IAsset) => asset?.sys?.id === fields?.postImage?.sys?.id,
    );

    return {
      id: fields?.postImage?.sys?.id,
      img: assetImage?.fields?.file?.url,
      link: `${blogURL}${fields?.slug}`,
      title: fields?.title,
      slug: fields?.slug,
    };
  }) || [];

export default mapBlogPostsResponse;
