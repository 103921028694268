import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

import * as fundingAccountAction from '../../../store/fundingAccount/actions';
import * as actionsUser from '../../../store/user/actions';
import { FUNDING_PAGE } from '../../../store/fundingAccount/consts';

import WithdrawalAccountView from './WithdrawalAccountView';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class WithdrawalAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModalError: false,
      isShowBankModal: false,
      isShowBankModalError: false,
      showMainLoader: true,
      bank: {},
      isShowWithdrawalSignIn: false,
      isShowBanks: false,
      isChangeBank: false,
      isShowAddAccount: false,
      isShowConfirm: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleStopBankAccountLinking =
      this.handleStopBankAccountLinking.bind(this);

    this.bankLoginProcessWait = this.bankLoginProcessWait.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);

    this.accountChange = this.accountChange.bind(this);

    this.openDifferentBank = this.openDifferentBank.bind(this);

    this.closeModal = this.closeModal.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.linkAnFunding = this.linkAnFunding.bind(this);

    this.showConfirm = this.showConfirm.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getFundingAccount(false, false, true).then(() => {
      actions.getPaymentCard();

      this.setState({ showMainLoader: false });
    });

    window.scrollTo(0, 0);
  }

  // TODO: Check new "UNSAFE_componentWillReceiveProps" => "componentDidUpdate + getDerivedStateFromProps"
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const newData = {};
  //   if (nextProps.skipBankLoginProps !== prevState.skipBankLoginProps) {
  //     newData.skipBankLoginProps = nextProps.skipBankLoginProps;
  //   }
  //   if (nextProps.banks !== prevState.banks) {
  //     newData.banks = nextProps.banks;
  //   }
  //   if (nextProps.isShowWithdrawalSignIn !== prevState.isShowWithdrawalSignIn) {
  //     newData.isShowWithdrawalSignIn = nextProps.isShowWithdrawalSignIn;
  //   }
  //   if (nextProps.isShowBanks !== prevState.isShowBanks) {
  //     newData.isShowBanks = nextProps.isShowBanks;
  //   }
  //   if (nextProps.errorMessageProps !== prevState.errorMessageProps) {
  //     newData.errorMessageProps = nextProps.errorMessageProps;
  //   }
  //   return newData;
  // }
  //
  // componentDidUpdate(prevProps, prevState) {
  //   const { skipBankLogin, errorMessage } = this.props;
  //   const {
  //     bank, isShowWithdrawalSignIn, isShowBanks, errorMessageProps, banks,
  //     skipBankLoginProps
  //   } = this.state;
  //   if (skipBankLoginProps && prevState.skipBankLoginProps !== skipBankLoginProps
  //     && skipBankLoginProps !== skipBankLogin) {
  //     this.setState({
  //       isShowBankModal: false
  //     });
  //   }
  //   if (bank && banks && banks.length !== 0) {
  //     this.setState({
  //       bank: banks.banks[0]
  //     });
  //   }
  //   if (isShowWithdrawalSignIn && prevState.isShowWithdrawalSignIn !== isShowWithdrawalSignIn) {
  //     this.setState({
  //       isShowWithdrawalSignIn
  //     });
  //   }
  //   if (isShowBanks && prevState.isShowBanks !== isShowBanks) {
  //     this.setState({
  //       isShowBanks
  //     });
  //   }
  //   const stopBankLoginRequest = parseInt(sessionStorage.getItem('stopBankLoginRequest'));
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps && errorMessageProps !== errorMessage
  //     && !stopBankLoginRequest)) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //     localStorage.setItem('stopBankLoginRequest', 1);
  //   }
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps
  //     && errorMessageProps !== errorMessage)) {
  //     this.setState({
  //       isShowModalError: true
  //     });
  //   }
  // }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { skipBankLogin, errorMessage } = this.props;
    const { isShowWithdrawalSignIn, isShowBanks } = this.state;

    if (newProps.skipBankLogin && newProps.skipBankLogin !== skipBankLogin) {
      this.setState({
        isShowBankModal: false,
      });
    }

    if (
      isShowWithdrawalSignIn &&
      newProps.isShowWithdrawalSignIn !== isShowWithdrawalSignIn
    ) {
      this.setState({
        isShowWithdrawalSignIn: newProps.isShowWithdrawalSignIn,
      });
    }

    if (isShowBanks && newProps.isShowBanks !== isShowBanks) {
      this.setState({
        isShowBanks: newProps.isShowBanks,
      });
    }

    const stopBankLoginRequest = parseInt(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLoginRequest),
    );

    if (
      newProps.errorMessage &&
      newProps.errorMessage !== errorMessage &&
      !stopBankLoginRequest
    ) {
      this.setState({
        isShowBankModalError: true,
      });

      localStorage.setItem('stopBankLoginRequest', 1);
    }

    if (newProps.errorMessage && newProps.errorMessage !== errorMessage) {
      this.setState({
        isShowModalError: true,
      });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetProps();
  }

  handleCloseModal() {
    this.setState({
      isShowBankModal: false,
      isShowBanks: false,
      isShowModalError: false,
      isChangeBank: false,
      isShowWithdrawalSignIn: false,
      isShowConfirm: false,
    });
  }

  handleCloseModalError() {
    const { actions } = this.props;

    actions.stopLoginRequest();

    this.setState({ isShowBankModalError: false });
  }

  handleStopBankAccountLinking() {
    const { actions } = this.props;

    actions.showWaitPopUpBankLogin(false);

    localStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 1);
  }

  showBanks = () => {
    const { actions } = this.props;

    actions.getPaymentCard().then(() => {
      actions
        .getBanks({
          isWithdrawal: true,
        })
        .then(() => {
          this.setState({ showMainLoader: false, isShowBanks: true });
        });
    });
  };

  showBanksFromSubAccount = () => {
    const { actions } = this.props;

    actions.getBanksList({});
  };

  signInBank = (bankId) => {
    const { state } = this.props;

    const bank = state.fundingAccount.banks.banks.filter(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    this.setState({
      isShowWithdrawalSignIn: true,
    });
  };

  bankInSubmit = (e, values, form, hasValidationErrors) => {
    const { bank } = this.state;
    const { actions, userActions } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const data = {
          account_number: values.accountNumber,
          bank_id: bank.id,
        };

        userActions.showLoader();

        this.setState({ isShowAddAccount: false });

        actions.bankSignDifferentIn(data, false, false, true);
      }
    }
  };

  bankInDifferentSubmit = () => {
    const { actions, state } = this.props;

    if (
      state.form.BANK_SIGN_IN_DIFFERENT &&
      state.form.BANK_SIGN_IN_DIFFERENT.initial
    ) {
      actions.bankSignDifferentIn(state.form.BANK_SIGN_IN_DIFFERENT.values);
    }
  };

  handleSubAccount = (e, accountId, monitoredId, _, bankId) => {
    const { actions } = this.props;

    e.target.parentElement.classList.toggle('checkbox__check');

    actions.addAccountForInvestments(accountId, monitoredId, bankId);
  };

  connectAccount = () => {
    const { actions } = this.props;

    actions.getBanks();

    actions.connectAccount();
  };

  bankLoginProcessWait() {
    const { actions } = this.props;

    actions.waitingInProcess();
  }

  accountChange() {
    const { actions } = this.props;

    this.setState({ isShowAddAccount: true, isShowConfirm: false });

    actions.getBanks();
  }

  openDifferentBank() {
    const { actions } = this.props;

    this.setState({
      bank: {},
    });

    actions.showDifferetnBank();
  }

  closeModal() {
    const { actions } = this.props;

    this.setState({
      isShowBankModal: false,
      isShowBanks: false,
      isShowModalError: false,
      isChangeBank: false,
      isShowAddAccount: false,
      isShowConfirm: false,
    });

    actions.closeModal();
  }

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  linkAnFunding() {
    const { history } = this.props;

    history.push(FUNDING_PAGE);
  }

  showConfirm() {
    this.setState({ isShowConfirm: true });
  }

  render() {
    const {
      isShowBankModal,
      isShowBankModalError,
      bank,
      isShowModalError,
      showMainLoader,
      isShowWithdrawalSignIn,
      isShowBanks,
      isChangeBank,
      isShowAddAccount,
      isShowConfirm,
    } = this.state;

    const {
      state,
      isShowBankModalAlert,
      errorMessage,
      errorTitle,
      withdrawalAccount,
      monitoredInstitutions,
      isShowConnect,
      isShowDifferentBankModal,
      showLoaderFunding,
      fundingPaymentCards,
    } = this.props;

    return (
      <WithdrawalAccountView
        showMainLoader={showMainLoader}
        showLoader={showLoaderFunding}
        showLoaderFunding={showLoaderFunding}
        withdrawalAccount={withdrawalAccount}
        showBanks={this.showBanks}
        isShowBanks={isShowBanks}
        signInBank={this.signInBank}
        isShowBankModal={isShowBankModal}
        handleCloseModal={this.handleCloseModal}
        bank={bank}
        bankInSubmit={this.bankInSubmit}
        credentials={state.fundingAccount.credentials}
        handleStopBankAccountLinking={this.handleStopBankAccountLinking}
        bankLoginProcessWait={this.bankLoginProcessWait}
        isShowBankModalAlert={isShowBankModalAlert}
        errorMessage={errorMessage}
        errorTitle={errorTitle}
        handleCloseModalError={this.handleCloseModalError}
        isShowBankModalError={isShowBankModalError}
        monitoredInstitutions={monitoredInstitutions}
        handleSubAccount={this.handleSubAccount}
        isShowModalError={isShowModalError}
        accountChange={this.accountChange}
        showBanksFromSubAccount={this.showBanksFromSubAccount}
        isShowConnect={isShowConnect}
        connectAccount={this.connectAccount}
        isShowDifferentBankModal={isShowDifferentBankModal}
        bankInDifferentSubmit={this.bankInDifferentSubmit}
        openDifferentBank={this.openDifferentBank}
        closeModal={this.closeModal}
        fundingPaymentCards={fundingPaymentCards}
        linkAnFunding={this.linkAnFunding}
        isShowWithdrawalSignIn={isShowWithdrawalSignIn}
        isChangeBank={isChangeBank}
        isShowAddAccount={isShowAddAccount}
        showConfirm={this.showConfirm}
        isShowConfirm={isShowConfirm}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  withdrawalAccount: state.fundingAccount.withdrawalAccount,
  errorMessage: state.fundingAccount.errorMessage,
  errorTitle: state.fundingAccount.errorTitle,
  credentials: state.fundingAccount.credentials,
  isShowBankModalAlert: state.fundingAccount.isShowBankModalAlert,
  skipBankLogin: state.fundingAccount.skipBankLogin,
  monitoredInstitutions: state.fundingAccount.monitoredInstitutions,
  isShowConnect: state.fundingAccount.isShowConnect,
  isShowDifferentBankModal: state.fundingAccount.isShowDifferentBankModal,
  showLoaderFunding: state.user.showLoaderFunding,
  fundingPaymentCards: state.fundingAccount.fundingPaymentCards,
  banks: state.fundingAccount.banks,
  isShowWithdrawalSignIn: state.fundingAccount.isShowWithdrawalSignIn,
  isShowBanks: state.fundingAccount.isShowBanks,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(fundingAccountAction, dispatch),
  userActions: bindActionCreators(actionsUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalAccount);

WithdrawalAccount.defaultProps = {
  withdrawalAccount: {},
  state: {},
  isShowBankModalAlert: false,
  skipBankLogin: false,
  errorMessage: '',
  errorTitle: '',
  monitoredInstitutions: [],
  isShowConnect: false,
  isShowDifferentBankModal: false,
  showLoaderFunding: false,
  fundingPaymentCards: { payment_cards: [] },
};

WithdrawalAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    getFundingAccount: PropTypes.func,
    getPaymentCard: PropTypes.func,
    resetProps: PropTypes.func,
    getBanks: PropTypes.func,
    getBanksList: PropTypes.func,
    addAccountForInvestments: PropTypes.func,
    connectAccount: PropTypes.func,
    stopLoginRequest: PropTypes.func,
    showWaitPopUpBankLogin: PropTypes.func,
    waitingInProcess: PropTypes.func,
    closeModal: PropTypes.func,
    showDifferetnBank: PropTypes.func,
    bankSignDifferentIn: PropTypes.func,
  }).isRequired,
  withdrawalAccount: PropTypes.shape({}),
  state: PropTypes.shape({
    form: PropTypes.shape({
      BANK_SIGN_IN_DIFFERENT: PropTypes.string,
    }),
    fundingAccount: PropTypes.shape({
      credentials: PropTypes.shape({}),
      banks: PropTypes.shape({
        banks: PropTypes.arrayOf(),
      }),
    }),
  }),
  isShowBankModalAlert: PropTypes.bool,
  skipBankLogin: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  isShowConnect: PropTypes.bool,
  isShowDifferentBankModal: PropTypes.bool,
  showLoaderFunding: PropTypes.bool,
  userActions: PropTypes.shape({
    showLoader: PropTypes.func,
  }).isRequired,
  fundingPaymentCards: PropTypes.shape({}),
};
