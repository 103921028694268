import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../styles/layout/account-strength.scss';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../../store/user/actions';

class AccountStrength extends Component {
  componentDidMount() {
    const { actions } = this.props;

    actions.getAccountStrength();
  }

  render() {
    const { accountStrength, toggleMobileMenu, t } = this.props;
    const strength = accountStrength || { percentage: 0 };
    let strengthColor = '';

    if (strength.percentage < 35) {
      strengthColor = '-red';
    } else if (strength.percentage < 55) {
      strengthColor = '-orange';
    } else if (strength.percentage < 75) {
      strengthColor = '-yellow';
    } else {
      strengthColor = '-green';
    }

    return (
      <Link
        to="/account-strength"
        onClick={() => {
          toggleMobileMenu(false);
        }}
      >
        <div className="account-strength">
          <div className="account-strength__title">
            <i className="account-strength__icon" />

            <div className="account-strength__title-name">
              {t('accountStrength.title')}

              {` (${strength.percentage}%)`}
            </div>
          </div>

          <div className="account-strength__percent">
            <div
              className={`account-strength__percent-value ${strengthColor}`}
              style={{ width: `${strength.percentage}%` }}
            />
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  accountStrength: state.user.accountStrength,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AccountStrength));

AccountStrength.defaultProps = {
  accountStrength: { percentage: 0, achievements: [] },
  toggleMobileMenu: () => {},
};

AccountStrength.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    getAccountStrength: PropTypes.func,
  }).isRequired,
  accountStrength: PropTypes.shape({}),
  toggleMobileMenu: PropTypes.func,
};
