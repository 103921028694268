import { useSearchParams } from 'react-router-dom';

import { SUPER_SEARCH_PARAM } from './consts';

export const useDividendsPath = () => {
  const [searchParams] = useSearchParams();

  const isSuper = searchParams.get(SUPER_SEARCH_PARAM);

  return {
    isSuperDividendsPath: !!isSuper,
  };
};
