import { useTranslation } from 'react-i18next';

import { Amount } from '../styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { MINIMUM_DIVIDEND_AMOUNT } from 'pages/Dividends/consts';
import { TUpcomingValueStatus } from 'store/dividends/types';
import { UPCOMING_VALUE_STATUSES } from 'store/dividends/consts';

interface IDividendsBannerSummaryUpcomingValueProps {
  upcoming_amount: number;
  upcoming_status?: TUpcomingValueStatus;
}

export const DividendsBannerSummaryUpcomingValue = ({
  upcoming_amount,
  upcoming_status,
}: IDividendsBannerSummaryUpcomingValueProps) => {
  const { t } = useTranslation();

  if (upcoming_status === UPCOMING_VALUE_STATUSES.none)
    return <Amount>{t('dividendsPage.value.none')}</Amount>;

  if (upcoming_status === UPCOMING_VALUE_STATUSES.tbc)
    return <Amount>{t('dividendsPage.value.tbc')}</Amount>;

  if (upcoming_amount < MINIMUM_DIVIDEND_AMOUNT && upcoming_amount > 0)
    return <Amount>{t('dividendsPage.value.lessThan1c')}</Amount>;

  return (
    <Amount>
      {formatNumber({
        value: upcoming_amount,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        isMinimumFractionDigitsForIntegerSkipped: true,
      })}
    </Amount>
  );
};
