import { TFunction } from 'i18next';

import {
  REGISTRATION_ACCOUNT_OTHER,
  REGISTRATION_ACCOUNT_QUESTIONS,
  REGISTRATION_BANKS_SPENDING_ACCOUNT,
  REGISTRATION_INVESTMENT_RECURRING,
} from '../../store/user/consts';

import { PORTFOLIO_TYPES, PORTFOLIO_NAMES } from './constants';
import { MAXIMUM_SAVINGS_RATE, SWITCHER_PORTFOLIO_TYPES } from './consts';
import { ISwitcherItemBaseProps } from './types';

import { formatNumber } from 'utils/formatters/formatNumber';
import { AU_APP } from 'constants/localeConfigs';
import {
  AGREEMENT_TYPES,
  FREQUENCIES,
  FILES_BASE_URL,
} from 'constants/comonConstants';
import { PATHS } from 'constants/paths';
import { checkIsDependentUserRegistration } from 'utils/user';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import DiaOnSvg from 'assets/images/icons/dia_on.svg';
import DiaOnActiveTabSvg from 'assets/images/icons/dia_on_active_tab.svg';
import DiaOnSavedSvg from 'assets/images/icons/dia_on_saved.svg';
import BitcoinBrandsSvg from 'assets/images/icons/bitcoin-brands.svg';
import BitcoinBrandsActiveSvg from 'assets/images/icons/bitcoin-brands-active.svg';
import BitcoinBrandsSavedSvg from 'assets/images/icons/bitcoin-brands-saved.svg';
import NewCustomPortfolioSvg from 'assets/images/icons/new-custom-portfolio.svg';
import NewCustomPortfolioActiveSvg from 'assets/images/icons/new-custom-portfolio-active.svg';
import EmeraldRegistrationSvg from 'assets/images/icons/emerald-registration.svg';
import EmeraldRegistrationActiveSvg from 'assets/images/icons/emerald-registration-active.svg';
import BitcoinRegistrationSvg from 'assets/images/icons/bitcoin-registration.svg';
import BitcoinRegistrationActiveSvg from 'assets/images/icons/bitcoin-registration-active.svg';

export const getPortfolioPreviousPage = (isKidsRegistration?: boolean) => {
  const isSmsf =
    sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
    AGREEMENT_TYPES.superFund;

  if (AU_APP && isSmsf) {
    return REGISTRATION_ACCOUNT_QUESTIONS;
  }

  if (isKidsRegistration) {
    return REGISTRATION_INVESTMENT_RECURRING;
  }

  if (checkIsDependentUserRegistration()) {
    return REGISTRATION_BANKS_SPENDING_ACCOUNT;
  }

  return REGISTRATION_ACCOUNT_OTHER;
};

export const mapPortfolioTypeToStrategyImage = (portfolioType: string) => {
  const baseUrl = `${FILES_BASE_URL}/images/investment-objectives`;

  switch (portfolioType) {
    case PORTFOLIO_TYPES.aggressive:
      return `${baseUrl}/Agg_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.moderatelyConservative:
      return `${baseUrl}/Mod_Con_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.moderate:
      return `${baseUrl}/Mod_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.moderatelyAggressive:
      return `${baseUrl}/Mod_Agg_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.emerald:
      return `${baseUrl}/Emerald_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.property10:
      return `${baseUrl}/Property10_Portfolio_Table.png`;
    case PORTFOLIO_TYPES.property30:
      return `${baseUrl}/Property30_Portfolio_Table.png`;
    default:
      return `${baseUrl}/Con_Portfolio_Table.png`;
  }
};

export const mapPortfolioType = (name: string) => {
  switch (name) {
    case PORTFOLIO_NAMES.sapphire:
      return PORTFOLIO_TYPES.bitcoin;
    case PORTFOLIO_NAMES.moderat:
      return PORTFOLIO_TYPES.moderate;
    case PORTFOLIO_NAMES.konservatif:
      return PORTFOLIO_TYPES.conservative;
    case PORTFOLIO_NAMES.agresif:
      return PORTFOLIO_TYPES.aggressive;
    case PORTFOLIO_NAMES.moderatePlus:
      return PORTFOLIO_TYPES.moderate;
    case PORTFOLIO_NAMES.sukuk:
      return PORTFOLIO_TYPES.moderate;
    case PORTFOLIO_NAMES.amanah:
      return PORTFOLIO_TYPES.moderatelyConservative;
    default:
      return name;
  }
};

export const getPortfolioKey = (key: string) => {
  const keyReplace = key.replace('_', '-').toLowerCase();

  return Object.values(PORTFOLIO_TYPES).includes(keyReplace)
    ? keyReplace
    : PORTFOLIO_TYPES.moderatelyAggressive;
};

export const getStartingValueKey = (value: string) => {
  const floatValue = parseFloat(value);

  if (floatValue >= 1 && floatValue < 100) return 1;

  if (floatValue >= 100 && floatValue <= 10000) return 100;

  if (floatValue > 10000) return 10000;

  return 1;
};

export const getMonthlyInvestmentIndexKey = (value: string) => {
  const floatValue = parseFloat(value);

  if (floatValue === 0) return 0;

  if (floatValue === 1) return 1;

  if (floatValue >= 2 && floatValue <= 100) return 2;

  if (floatValue > 100) return 3;

  return 0;
};

export const getPowerForCoefficientName = (coefficient?: string | null) => {
  if (
    typeof coefficient === 'undefined' ||
    coefficient === null ||
    coefficient === ''
  ) {
    return 0;
  }

  const indexOfCaret = coefficient.indexOf('^');

  if (indexOfCaret === -1) return 1;

  return parseFloat(coefficient.substring(indexOfCaret + 1));
};

export const getNameForCoefficients = (coefficient?: string | null) => {
  if (
    typeof coefficient === 'undefined' ||
    coefficient === null ||
    coefficient === ''
  ) {
    return '';
  }

  const indexOfCaret = coefficient.indexOf('^');

  if (indexOfCaret === -1) return coefficient;

  return coefficient.substring(0, indexOfCaret);
};

export const getPortfolioPath = (isProPortfolioEnabled: boolean) =>
  isProPortfolioEnabled ? PATHS.portfolio.index : PATHS.oldPortfolio.index;

interface ITransformChartData {
  age: number;
  data?: number[];
  t: TFunction;
}

interface ITransformedChartData {
  year: number;
  month: number;
  lines: { x: string; y: number; z: string }[];
}

export const transformChartData = ({
  age,
  data,
  t,
}: ITransformChartData): ITransformedChartData['lines'] => {
  if (!data) return [];

  const [_firstChartData, ...restChartData] = data;

  const yearText = t('common.date.year');
  const monthsText = t('common.date.month');

  return restChartData.reduce<ITransformedChartData>(
    (accumulatedChartData, currentChartData) => {
      const isTheEleventhMonth = accumulatedChartData.month === 11;
      const month = isTheEleventhMonth ? 0 : accumulatedChartData.month + 1;

      const year =
        month === 0 && isTheEleventhMonth
          ? accumulatedChartData.year + 1
          : accumulatedChartData.year;

      const x =
        month === 0 ? `${year}` : `${year} ${yearText}, ${month} ${monthsText}`;

      const y = Math.floor(currentChartData);

      const z = formatNumber({
        value: currentChartData,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      const axis = { x, y, z };

      return {
        year,
        month,
        lines: [...accumulatedChartData.lines, axis],
      };
    },
    {
      year: age,
      month: 0,
      lines: [],
    },
  ).lines;
};

const getConvertFrequencyToMonthly = (frequency: string) => {
  switch (frequency) {
    case FREQUENCIES.daily:
      return 30;
    case FREQUENCIES.weekly:
      return 4;
    case FREQUENCIES.fortnightly:
      return 2;
    default:
      return 1;
  }
};

export const getMonthlyValues = (amount: number, frequency: string) => {
  const monthlyValue = amount * getConvertFrequencyToMonthly(frequency);

  if (monthlyValue > MAXIMUM_SAVINGS_RATE) return MAXIMUM_SAVINGS_RATE;

  return monthlyValue;
};

const getPortfolioTypesBaseIcon = (portfolioTypes?: string) => {
  switch (portfolioTypes) {
    case SWITCHER_PORTFOLIO_TYPES.emerald:
      return DiaOnSvg;
    case SWITCHER_PORTFOLIO_TYPES.sapphire:
      return BitcoinBrandsSvg;
    default:
      return null;
  }
};

const getPortfolioTypesActiveIcon = (portfolioTypes?: string) => {
  switch (portfolioTypes) {
    case SWITCHER_PORTFOLIO_TYPES.emerald:
      return DiaOnActiveTabSvg;
    case SWITCHER_PORTFOLIO_TYPES.sapphire:
      return BitcoinBrandsActiveSvg;
    default:
      return null;
  }
};

const getPortfolioTypesSavedIcon = (portfolioTypes?: string) => {
  switch (portfolioTypes) {
    case SWITCHER_PORTFOLIO_TYPES.emerald:
      return DiaOnSavedSvg;
    case SWITCHER_PORTFOLIO_TYPES.sapphire:
      return BitcoinBrandsSavedSvg;
    default:
      return null;
  }
};

export const getPortfolioTypesIcon = ({
  portfolioTypes,
  isActive,
  isSaved,
}: ISwitcherItemBaseProps) => {
  if (isActive) {
    return getPortfolioTypesActiveIcon(portfolioTypes);
  }

  if (isSaved) {
    return getPortfolioTypesSavedIcon(portfolioTypes);
  }

  return getPortfolioTypesBaseIcon(portfolioTypes);
};

const getRegistrationPortfolioTypesBaseIcon = (portfolioTypes?: string) => {
  switch (portfolioTypes) {
    case SWITCHER_PORTFOLIO_TYPES.emerald:
      return EmeraldRegistrationSvg;
    case SWITCHER_PORTFOLIO_TYPES.sapphire:
      return BitcoinRegistrationSvg;
    case SWITCHER_PORTFOLIO_TYPES.custom:
      return NewCustomPortfolioSvg;
    default:
      return null;
  }
};

const getRegistrationPortfolioTypesActiveIcon = (portfolioTypes?: string) => {
  switch (portfolioTypes) {
    case SWITCHER_PORTFOLIO_TYPES.emerald:
      return EmeraldRegistrationActiveSvg;
    case SWITCHER_PORTFOLIO_TYPES.sapphire:
      return BitcoinRegistrationActiveSvg;
    case SWITCHER_PORTFOLIO_TYPES.custom:
      return NewCustomPortfolioActiveSvg;
    default:
      return null;
  }
};

export const getRegistrationPortfolioTypesIcon = ({
  portfolioTypes,
  isActive,
}: ISwitcherItemBaseProps) => {
  if (isActive) {
    return getRegistrationPortfolioTypesActiveIcon(portfolioTypes);
  }

  return getRegistrationPortfolioTypesBaseIcon(portfolioTypes);
};
