import { useTranslation } from 'react-i18next';

import {
  MaximumWeightingsCardTitle,
  MaximumWeightingsCardWrapper,
} from './styles';

export default function MaximumWeightingsCard() {
  const { t } = useTranslation();

  return (
    <MaximumWeightingsCardWrapper>
      <MaximumWeightingsCardTitle>
        {t('portfolio.helpCards.maximumWeightings.title')}
      </MaximumWeightingsCardTitle>
    </MaximumWeightingsCardWrapper>
  );
}
