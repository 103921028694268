import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetTitle, WidgetContent } from '../styles';

import StatisticsItems from './StatisticContent';

export default function Statistics() {
  const { t } = useTranslation();

  return (
    <WidgetContent>
      <WidgetTitle>
        {t('portfolioCustomization.fundDetails.statistic.title')}
      </WidgetTitle>

      <StatisticsItems />
    </WidgetContent>
  );
}
