import styled from '@emotion/styled';

export const InfoPanelListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 13px 13px 11px 18px;
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};

  &:nth-of-type(2n-1) {
    background-color: ${({ theme }) => theme.colors.athensGray};
  }
`;

export const MyFinanceSpendIcons = styled.div`
  display: flex;
  width: 50%;
`;

export const MyFinanceSpendValue = styled.div`
  width: 25%;
  text-align: right;
`;
