import React from 'react';
import Skeleton from 'react-skeleton-loader';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RewardDetailsButtons from './RewardDetailsButtons';
import RewardDetailsDescription from './RewardDetailsDescription';
import RewardDetailsBanner from './RewardDetailsBanner';

export default function RewardDetails() {
  const { t } = useTranslation();

  const { reward } = useSelector((state) => ({
    reward: state.rewards.brand || {},
  }));

  if (Object.keys(reward).length === 0) {
    return (
      <div className="rewards-internal-new">
        <div className="reward-internal__banner">
          <Skeleton width="150%" borderRadius="0" />
        </div>
      </div>
    );
  }

  const {
    background_image_url,
    campaign_owner_name,
    campaign_owner_image_url,
    description,
    invested_value,
    campaign_owner_description,
    cta_urls,
    cta_title,
    terms_and_conditions_url,
  } = reward;

  return (
    <div className="rewards-internal-new">
      <RewardDetailsBanner
        background_image_url={background_image_url}
        campaign_owner_name={campaign_owner_name}
        campaign_owner_image_url={campaign_owner_image_url}
      />

      <div className="reward-internal__banner__title">
        <div className="brand">{campaign_owner_name}</div>

        <div className="invest">
          {t('rewardsPage.rewardDetails.investedAmount', { invested_value })}
        </div>

        <div>{campaign_owner_description}</div>
      </div>

      {description && <RewardDetailsDescription description={description} />}

      <RewardDetailsButtons
        cta_urls={cta_urls}
        cta_title={cta_title}
        terms_and_conditions_url={terms_and_conditions_url}
      />
    </div>
  );
}
