import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import { FiltersButtonsWrapper, ClearButton, ApplyButton } from './styles';
import { useFilterFundsActions } from './hooks';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

export default function FiltersButtons() {
  const { t } = useTranslation();
  const { clearFilter, isLoading } = useFilterFundsActions();

  const {
    formState: { isDirty },
    control,
  } = useFormContext();

  const checkboxGroupValue = useWatch({ control });

  const isAnyValueSelected = Object.values(checkboxGroupValue).some(
    (value) => value,
  );

  return (
    <FiltersButtonsWrapper>
      {isAnyValueSelected && (
        <ClearButton
          disabled={isLoading}
          onClick={clearFilter}
          type="button"
          // TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548
          className="-exclude"
        >
          {t('portfolioCustomization.filters.buttons.clear')}
        </ClearButton>
      )}

      <ApplyButton disabled={isLoading || !isDirty} type="submit">
        {isLoading ? (
          <DotsLoader size={14} />
        ) : (
          t('portfolioCustomization.filters.buttons.apply')
        )}
      </ApplyButton>
    </FiltersButtonsWrapper>
  );
}
