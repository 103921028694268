import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { useRecurringInvestmentFrequency } from '../utils';

import {
  RecurringInvestmentWrapper,
  RecurringInvestmentPreviewWrapper,
  RecurringInvestmentTitle,
  RecurringInvestmentContent,
} from './styles';
import RecurringInvestmentButtons from './RecurringInvestmentButtons';
import InvestmentGoalPreview from './InvestmentGoalPreview';
import RecurringPreview from './RecurringPreview';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectChild,
  selectInvestmentGoal,
} from 'store/dependentUser/selectors';

export default function RecurringInvestmentPreview() {
  const { t } = useTranslation();
  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { name } = investmentGoal || {};

  const form = useForm();

  const { recurring } = useAppSelector(selectChild);
  const { frequency_identifier } = recurring || {};

  const isInvestmentGoalEnabled = !!investmentGoal?.key;

  const recurringInvestmentFrequency =
    useRecurringInvestmentFrequency(frequency_identifier);

  return (
    <RecurringInvestmentWrapper>
      <RecurringInvestmentContent>
        <RecurringInvestmentTitle>
          {name || t('raizKidsEditPage.recurringInvestment')}
        </RecurringInvestmentTitle>

        <RecurringInvestmentPreviewWrapper>
          {isInvestmentGoalEnabled ? (
            <InvestmentGoalPreview />
          ) : (
            <RecurringPreview />
          )}
        </RecurringInvestmentPreviewWrapper>
      </RecurringInvestmentContent>

      <FormProvider {...form}>
        <RecurringInvestmentButtons
          recurringInvestmentFrequency={recurringInvestmentFrequency}
        />
      </FormProvider>
    </RecurringInvestmentWrapper>
  );
}
