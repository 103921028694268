import {
  CLOSE_MODAL,
  SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS,
} from '../commonTypes';

import {
  GET_GOALS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  DELETE_GOALS_SUCCESS,
  SHOW_ERROR_UPDATE_GOAL,
  UPDATE_SETTINGS_SUCCESS,
  STOP_INVESTMENT_SUCCESS,
  GET_TIPS_BY_PATTERN_SUCCESS,
  GET_TOTALS_BY_CATEGORY_SUCCESS,
  UPDATE_TRANSACTION_TYPE_SUCCESS,
  GET_CATEGORIES_AVERAGES_SUCCESS,
  GET_UPCOMING_TRANSACTIONS_SUCCESS,
  SHOW_ERROR_UPDATE_TRANSACTION_TYPE,
  GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
  GET_TRANSACTION_CATEGORISATION_SUCCESS,
  CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
  SHOW_ERROR_GET_TOTALS_BY_CATEGORY,
  GET_CATEGORIES_LIST_SUCCESS,
} from './types';

const initialState = {
  tips: [],
  goals: {},
  transactionCategorisation: {
    payload: [],
  },
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case GET_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.goals,
      };
    case GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS:
      return {
        ...state,
        recurringDepositSettings: action.recurringDepositSettings,
      };
    case GET_TRANSACTION_CATEGORISATION_SUCCESS:
      return {
        ...state,
        addListener: action.addListener,
        transactionCategorisation: action.transactionCategorisation,
      };
    case GET_TOTALS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryTotals: action.categoryTotals,
      };
    case GET_UPCOMING_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        upcomingTransactions: action.upcomingTransactions,
      };
    case DELETE_GOALS_SUCCESS:
    case UPDATE_SETTINGS_SUCCESS:
    case STOP_INVESTMENT_SUCCESS:
    case UPDATE_TRANSACTION_TYPE_SUCCESS:
    case CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS:
      return {
        ...state,
        errorTitle: '',
        errorMessage: '',
        checkoutUrl: action.checkoutUrl,
      };
    case SHOW_ERROR_UPDATE_GOAL:
    case SHOW_ERROR_UPDATE_TRANSACTION_TYPE:
    case SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS:
      return {
        ...state,
        errorTitle: action.errorTitle,
        errorMessage: action.errorMessage,
        isRedirectToHome: false,
      };
    case GET_CATEGORIES_AVERAGES_SUCCESS:
      return {
        ...state,
        categoriesAverages: action.categoriesAverages,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        type: action.type,
        settings: action.settings,
      };
    case GET_TIPS_BY_PATTERN_SUCCESS:
      return {
        ...state,
        type: action.type,
        tips: action.tips,
      };
    case SHOW_ERROR_GET_TOTALS_BY_CATEGORY:
      return {
        ...state,
        isShowErrorModal: true,
        errorMessage: action.errorMessage,
        globalErrorMessage: action.errorMessage,
        isRedirectToHome: false,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        errorMessage: '',
        isShowErrorModal: false,
        isShowDifferentBankModal: false,
        globalErrorMessage: '',
        showLoader: false,
        errorMessageCustom: '',
        isRedirectToHome: true,
      };
    case GET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoriesList: action.categoriesList,
      };
    default:
      return state;
  }
}
