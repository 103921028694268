import { useDividendsRequestHeaders } from './useDividendsRequestHeaders';

import { useGetDividendsFundsQuery } from 'store/dividends/dividends';
import { selectDividendsFilteredFundsSymbols } from 'store/dividends/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useFilteredFunds = () => {
  const dividendsFilteredFundsSymbols = useAppSelector(
    selectDividendsFilteredFundsSymbols,
  );

  const { headers } = useDividendsRequestHeaders();

  const { data } = useGetDividendsFundsQuery({
    headers,
  });

  const funds =
    dividendsFilteredFundsSymbols.length > 0 && data
      ? data.filter((item) =>
          dividendsFilteredFundsSymbols.includes(item.symbol),
        )
      : [];

  const fundIds = funds.map((item) => item.id) || [];

  return {
    fundIds,
    data,
  };
};
