import FinancialForm from './components/FinancialForm';
import { useSettingsTabSwitcher } from './hooks/useSettingsTabSwitcher';
import { PROFILE_TABS } from './hooks/const';
import FinancialFormSkeleton from './components/FinancialFormSkeleton/FinancialFormSkeleton';
import { useProfilePage } from './hooks/useProfilePage';

import { useSuitabilityQuery } from 'store/suitability/suitability';

export default function FinancialSuitability() {
  const { activeTab } = useSettingsTabSwitcher();
  const { isUpdateProfilePage } = useProfilePage();
  const { isFetching } = useSuitabilityQuery();

  if (activeTab === PROFILE_TABS.personal && !isUpdateProfilePage) {
    return null;
  }

  if (isFetching) {
    return <FinancialFormSkeleton />;
  }

  return <FinancialForm />;
}
