import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import '../../../../styles/layout/logout.scss';
import FinalFormInput from '../../../elements/FinalFormInput/FinalFormInput';

import { useConvertToFullModalValidation } from './utils';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function ConvertToFullModal({
  toggleConvertToFull,
  child,
  convertToFull,
  showLoader,
}) {
  const { t } = useTranslation();
  const { validate } = useConvertToFullModalValidation();

  return (
    <Form
      onSubmit={convertToFull}
      validate={(values) => validate({ values, childEmail: child?.email })}
      render={({ form, values, hasValidationErrors }) => (
        <Modal
          isOpen
          onRequestClose={() => toggleConvertToFull(false)}
          contentLabel="kids-modal"
          shouldCloseOnOverlayClick
          className="modal-basic raiz-kids__link-sent"
        >
          <form
            className="withdrawal-form"
            onSubmit={(e) =>
              convertToFull(e, values, form, hasValidationErrors, child.id)
            }
          >
            <div className="raiz-kids__menu-title">
              {t('raizKidsEditPage.convertToFullModal.title')}
            </div>

            <div className="raiz-kids__link-sent-info">
              {t('raizKidsEditPage.convertToFullModal.description', {
                name: child.name,
              })}
            </div>

            <DeprecatedButton
              buttonClass="button__close -black"
              handleClick={() => toggleConvertToFull(false)}
            />

            {!child.email && (
              <div className="raiz-kids__modal-input">
                <FinalFormInput
                  name="email"
                  label={t('fields.emailAddress.label')}
                />
              </div>
            )}

            <div className="raiz-kids__link-sent-footer">
              <Button
                type="submit"
                isLoading={showLoader}
                label={t('raizKidsEditPage.convertToFullModal.submitButton')}
              />

              <DeprecatedButton
                title={t('common.cancel')}
                buttonClass="button__basic-wtbackground -black"
                handleClick={() => toggleConvertToFull(false)}
              />
            </div>
          </form>
        </Modal>
      )}
    />
  );
}

ConvertToFullModal.propTypes = {
  toggleConvertToFull: PropTypes.func.isRequired,
  convertToFull: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  child: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};
