import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function InvestmentObjectiveModal({
  isShowPortfolioStrategy,
  strategyImage,
  handleCloseModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowPortfolioStrategy}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert modal-strategy"
      style={{
        content: {
          top: '7.5%',
        },
      }}
    >
      <div className="modal-alert-content">
        <Scrollbars
          className="terms-scroll"
          autoHide={false}
          style={{
            width: '100%',
            height: 'calc(100vh - 100px)',
            overflow: 'hidden',
          }}
        >
          <div className="img-content">
            <img
              alt={t('changePortfolioPage.investmentObjective')}
              src={strategyImage}
            />
          </div>
        </Scrollbars>

        <DeprecatedButton
          title={<Trans i18nKey="common.space" t={t} />}
          buttonClass="button__close -black"
          handleClick={handleCloseModal}
        />
      </div>
    </Modal>
  );
}

InvestmentObjectiveModal.propTypes = {
  isShowPortfolioStrategy: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  strategyImage: PropTypes.string.isRequired,
};
