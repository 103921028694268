import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Firebase from 'firebase/app';
import dayjs from 'dayjs';

import * as spendingAnalyticsActions from '../../store/spendingAnalytics/actions';
import {
  MAX_MONTH_CHART,
  UNCATEGORIZED,
  MAX_MONTH_CHART_TABLET,
  MAX_MONTH_CHART_MOBILE,
} from '../../constants/comonConstants';
import {
  APP_DATE_TIME_ZONE,
  CURRENCY_LOCALE,
  CURRENCY_NAME,
} from '../../constants/localeConfigs';

import WhereSpendView from './WhereSpendView';

class WhereSpend extends Component {
  constructor(props) {
    super(props);

    this._isMounted = true;

    const currentURL = new URLSearchParams(window.location.search);
    const categoryFromURL = currentURL.get('category');

    this.state = {
      currentPage: 1,
      showMainLoader: true,
      maxMonthChart: MAX_MONTH_CHART,
      isOpenHelpCards: false,
      showLoader: true,
      categoriesByMonth: [{ categories: [] }],
      currentMonth: 0,
      options: {
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'lighten',
              value: 1,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#ffffff',
              strokeDashArray: 3,
              label: {
                show: true,
                text: `${new Intl.NumberFormat(CURRENCY_LOCALE, {
                  style: 'currency',
                  currency: CURRENCY_NAME,
                }).format(0)}`,
                position: 'left',
                borderWidth: 0,
                offsetX: 100,
                style: {
                  fontFamily: 'SF Text Medium',
                  fontSize: '12px',
                  color: '#ffffff',
                  background: 'transparent',
                },
                formatter: (val) =>
                  `AVG ${new Intl.NumberFormat(CURRENCY_LOCALE, {
                    style: 'currency',
                    currency: CURRENCY_NAME,
                  }).format(val)}`,
              },
            },
          ],
        },
        chart: {
          height: 800,
          type: 'bar',
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.changeCurrentMonth(config.dataPointIndex);
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) =>
            `${new Intl.NumberFormat(CURRENCY_LOCALE, {
              style: 'currency',
              currency: CURRENCY_NAME,
            }).format(val)}`,
          offsetY: -40,
          style: {
            fontFamily: 'SF Text Medium',
            fontSize: '17px',
            colors: ['#ffffff'],
          },
        },
        grid: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          labels: {
            show: true,
            style: {
              colors: 'rgba(255,255,255,0.54)',
              fontSize: '13px',
              fontFamily: 'SF Text Medium',
              cssClass: 'my-finance__chart-label',
            },
          },
          type: 'categories',
          tickAmount: 'dataPoints',
          axisBorder: {
            show: false,
          },
          style: {
            colors: ['#ffffff'],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            cssClass: 'apexcharts-xaxis-label',
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
            offsetY: 0,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0,
            gradientToColors: '#ffffff',
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0],
            colorStops: [
              {
                offset: 0,
                color: '#ffffff',
                opacity: 0.4,
              },
              {
                offset: 100,
                color: '#ffffff',
                opacity: 0.1,
              },
            ],
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          data: [],
        },
      ],
      defaultChartData: [],
      currentCategory: categoryFromURL || '',
    };

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.scrollRight = this.scrollRight.bind(this);

    this.scrollLeft = this.scrollLeft.bind(this);

    this.setCategoriesByMonth = this.setCategoriesByMonth.bind(this);

    this.updateChartData = this.updateChartData.bind(this);

    this.showCategoryMonthly = this.showCategoryMonthly.bind(this);
  }

  componentDidMount() {
    Firebase.analytics().logEvent('Where_You_Spend');

    const { actionsSpendingAnalytics } = this.props;
    const { currentCategory } = this.state;

    actionsSpendingAnalytics.getCategories();

    if (window.innerWidth < 1100 && window.innerWidth > 570) {
      this.setState({ maxMonthChart: MAX_MONTH_CHART_TABLET });
    } else if (window.innerWidth < 570) {
      this.setState({ maxMonthChart: MAX_MONTH_CHART_MOBILE });
    }

    actionsSpendingAnalytics.getCategoriesAverages().then(() => {
      if (this._isMounted) {
        const { categoriesAverages } = this.props;

        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;

        const amounts = [];

        categoriesAverages.categories.map((categoryAverage) => {
          amounts.push(parseFloat(categoryAverage.amount));

          return categoryAverage;
        });

        if (amounts.length) {
          this.updateAverage(amounts.reduce(reducer) / amounts.length);
        }
      }
    });

    this.setState({ showMainLoader: true });

    actionsSpendingAnalytics.getTotalsByCategory().then(() => {
      if (this._isMounted) {
        const { maxMonthChart } = this.state;
        const { categoryTotals } = this.props;

        if (categoryTotals.payload) {
          const { categories } = categoryTotals.payload;

          const monthArray =
            (categories && categories.length) > 0 ? categories[0].monthly : [];

          const categoriesByMonth = [];

          const labels = monthArray.map((month) => {
            const monthName = dayjs(month[0])
              .tz(APP_DATE_TIME_ZONE)
              .format('MMM, YYYY');

            categoriesByMonth.push({ date: monthName, categories: [] });

            return monthName;
          });

          this.setState({ allLabels: labels });

          this.updateLabels(labels.slice(0, maxMonthChart));

          if (categories) {
            categories.map((category) => {
              categoriesByMonth.map((data, index) => {
                data.categories.push({
                  category: category.category,
                  name: category.category,
                  total: category.monthly[index][1],
                  parent: category.parent,
                  subcategories: [],
                });

                return data;
              });

              return category;
            });
          }

          categoriesByMonth.map((categoryMonth, index) => {
            const totals = categoryTotals.payload.monthly_total[index][1];
            const copyCategoryMonth = categoryMonth;

            const uncategorised = copyCategoryMonth.categories.find(
              (category) => category.name === UNCATEGORIZED,
            );

            const uncategorisedTotal = uncategorised ? uncategorised.total : 0;

            copyCategoryMonth.total = (totals - uncategorisedTotal).toFixed(2);

            copyCategoryMonth.categories.map((data) => {
              if (data.parent) {
                const parentCategory = copyCategoryMonth.categories.find(
                  (category) => category.category === data.parent,
                );

                if (parentCategory) {
                  parentCategory.subcategories.push(data);
                } else if (data.parent) {
                  copyCategoryMonth.categories.push({
                    category: data.parent,
                    name: data.parent,
                    subcategories: [data],
                    total: 0,
                    parent: '',
                  });
                }
              }

              return data;
            });

            return copyCategoryMonth;
          });

          const chartData = categoriesByMonth.map((data) => data.total);

          this.setCategoriesByMonth(categoriesByMonth);

          this.setDefaultChartData(
            chartData.reduce((acc, val) => acc.concat(val), []),
          );

          if (currentCategory.length) {
            this.showCategoryMonthly(currentCategory);
          } else {
            this.updateChartData(
              chartData.reduce((acc, val) => acc.concat(val), []),
            );
          }

          this.setState({
            showMainLoader: false,
            showLoader: false,
          });
        } else {
          this.setState({
            showMainLoader: false,
            showLoader: false,
          });
        }
      }
    });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setCategoriesByMonth(categoriesByMonth) {
    this.setState({ categoriesByMonth });
  }

  setDefaultChartData(chartData) {
    const { maxMonthChart } = this.state;

    this.setState({
      defaultChartData: chartData.slice(0, maxMonthChart),
      allChartData: chartData,
    });
  }

  updateAverage(value) {
    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        yaxis: {
          ...prevState.options.yaxis,
        },
        annotations: {
          ...prevState.options.annotations,
          yaxis: [
            {
              ...prevState.options.annotations.yaxis[0],
              y: value,
              label: {
                ...prevState.options.annotations.yaxis[0].label,
                text: `AVG ${new Intl.NumberFormat(CURRENCY_LOCALE, {
                  style: 'currency',
                  currency: CURRENCY_NAME,
                }).format(value)} `,
              },
            },
          ],
        },
      },
    }));
  }

  updateChartData(chartData) {
    const { maxMonthChart } = this.state;

    this.setState({
      series: [{ data: chartData.slice(0, maxMonthChart).reverse() }],
      showMainLoader: false,
    });
  }

  updateLabels(labels) {
    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: labels.reverse(),
        },
      },
    }));
  }

  changeCurrentMonth(index) {
    const { maxMonthChart, allChartData, currentPage } = this.state;

    const currentChartData = allChartData.slice(
      maxMonthChart * (currentPage - 1),
      maxMonthChart * currentPage,
    );

    const currentIndex =
      currentChartData.length < maxMonthChart
        ? maxMonthChart - currentChartData.length
        : index;

    this.setState({
      currentMonth: maxMonthChart * currentPage - currentIndex - 1,
    });

    this.updateChartData(currentChartData);
  }

  showCategoryMonthly(categoryName) {
    const { categoryTotals } = this.props;
    const { defaultChartData, maxMonthChart, currentPage } = this.state;

    if (
      categoryName &&
      categoryTotals.payload &&
      categoryTotals.payload.categories
    ) {
      const currentCategoryInfo = categoryTotals.payload.categories.find(
        (categoryInfo) => categoryName === categoryInfo.category,
      );

      const currentCategoryChildren = categoryTotals.payload.categories.filter(
        (categoryInfo) => categoryName === categoryInfo.parent,
      );

      if (currentCategoryInfo) {
        const currentCategoryMonthly = currentCategoryInfo.monthly.map(
          (month) => month[1],
        );

        this.setState({ currentCategory: categoryName });

        let currentCategoryMonthlyWithChildren = currentCategoryMonthly;

        currentCategoryChildren.forEach((categoryChildren) => {
          const childMonthly = categoryChildren.monthly.map(
            (month) => month[1],
          );

          currentCategoryMonthlyWithChildren = childMonthly.map(
            (num, idx) => num + currentCategoryMonthlyWithChildren[idx],
          );
        });

        this.updateChartData(
          currentCategoryMonthlyWithChildren.slice(
            maxMonthChart * (currentPage - 1),
            maxMonthChart * currentPage,
          ),
        );
      } else {
        this.setState({ currentCategory: '' });

        this.updateChartData(defaultChartData);
      }
    } else {
      this.setState({ currentCategory: '' });

      this.updateChartData(defaultChartData);
    }
  }

  toggleHelpCard(condition) {
    this.setState({ isOpenHelpCards: condition });
  }

  scrollLeft() {
    const { allChartData, maxMonthChart, currentPage, allLabels } = this.state;

    this.setState({ currentPage: currentPage + 1, showMainLoader: true });

    this.showCategoryMonthly();

    this.updateLabels(
      allLabels.slice(
        maxMonthChart * currentPage,
        maxMonthChart * (currentPage + 1),
      ),
    );

    this.setState({ currentMonth: maxMonthChart * currentPage });

    this.updateChartData(
      allChartData.slice(
        maxMonthChart * currentPage,
        maxMonthChart * (currentPage + 1),
      ),
    );
  }

  scrollRight() {
    const { allChartData, maxMonthChart, currentPage, allLabels } = this.state;

    this.setState({ currentPage: currentPage - 1, showMainLoader: true });

    this.showCategoryMonthly();

    this.updateLabels(
      allLabels.slice(
        maxMonthChart * (currentPage - 2),
        maxMonthChart * (currentPage - 1),
      ),
    );

    this.setState({ currentMonth: maxMonthChart * (currentPage - 2) });

    this.updateChartData(
      allChartData.slice(
        maxMonthChart * (currentPage - 2),
        maxMonthChart * (currentPage - 1),
      ),
    );
  }

  render() {
    const { categoryTotals, categoriesList } = this.props;

    const {
      options,
      series,
      categoriesByMonth,
      currentMonth,
      currentCategory,
      isOpenHelpCards,
      allChartData,
      maxMonthChart,
      currentPage,
      showMainLoader,
      showLoader,
    } = this.state;

    return (
      <WhereSpendView
        options={options}
        categoriesList={categoriesList}
        series={series}
        showLoader={showLoader}
        showMainLoader={showMainLoader}
        scrollLeft={this.scrollLeft}
        scrollRight={this.scrollRight}
        currentCategory={currentCategory}
        showCategoryMonthly={this.showCategoryMonthly}
        currentMonth={currentMonth}
        categoriesByMonth={categoriesByMonth}
        categoryTotals={categoryTotals.payload}
        maxMonthChart={maxMonthChart}
        currentPage={currentPage}
        allChartData={allChartData}
        toggleHelpCard={this.toggleHelpCard}
        isOpenHelpCards={isOpenHelpCards}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  categoryTotals: state.spendingAnalytics.categoryTotals,
  categoriesList: state.spendingAnalytics.categoriesList,
  categoriesAverages: state.spendingAnalytics.categoriesAverages,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhereSpend);

WhereSpend.defaultProps = {
  actionsSpendingAnalytics: {},
  categoriesList: [{}],
  categoryTotals: {
    payload: {
      categories: [],
    },
  },
  categoriesAverages: {
    categories: [],
  },
};

WhereSpend.propTypes = {
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  categoryTotals: PropTypes.shape({
    payload: PropTypes.shape({
      categories: PropTypes.arrayOf(),
      monthly_total: PropTypes.number,
    }),
  }),
  categoriesAverages: PropTypes.shape({
    categories: PropTypes.arrayOf(),
  }),
  actionsSpendingAnalytics: PropTypes.shape({
    getCategories: PropTypes.func,
    getCategoriesAverages: PropTypes.func,
    getTotalsByCategory: PropTypes.func,
  }),
};
