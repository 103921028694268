import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import '../../../styles/layout/account-strength.scss';

import 'react-dropzone-uploader/dist/styles.css';
import {
  REMOVED,
  KTP,
  REJECTED_FILE_TYPE,
  REJECTED_MAX_FILES,
  PENDING_INVESTMENT_WITH_PAYMENT_CODE,
  DOCUMENT_TYPES,
} from '../../../constants/comonConstants';
import { UPLOAD_FAILED } from '../../../store/user/consts';
import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import { getUserData, uploadDocument } from '../../../store/user/actions';
import { getTransactionDetails } from '../../../store/history/actions';
import { ALERT_TYPES } from '../../../constants/alerts';

import DocumentMissingAlertModal from './DocumentMissingAlertModal/DocumentMissingAlertModal';
import UploaderDocumentsModal from './UploaderDocumentsModal/UploaderDocumentsModal';
import MYSDocumentsUploader from './MYSDocumentsUploader/MYSDocumentsUploader';
import { useDocumentUploadSuccessModal } from './hooks/useDocumentUploadSuccessModal';
import InfoAlertModal from './InfoAlertModal/InfoAlertModal';
import FundingAlertsModal from './FundingAlertsModal/FundingAlertsModal';
import FundingAlertsModalItem from './FundingAlertsModal/components/FundingAlertsModalItem';
import AUSDocumentsUploader from './AUSDocumentsUploader/AUSDocumentsUploader';

import { toggleInfoModal } from 'store/modals/actions';
import { IdentityVerificationModal } from 'pages/Settings/components/IdentityVerificationModal';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

function Alerts({ history, openUploadModal, isHeader }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user, type } = useSelector((state) => ({
    user: state.user.user,
    type: state.user.type,
    errorMessage: state.user.errorMessage,
  }));

  const isHome = history.location.pathname === '/';
  const navigate = useNavigate();

  const [fundingAlerts, setFundingAlerts] = useState([]);
  const [otherAlerts, setOtherAlerts] = useState([]);
  const [isModalAlertsOpen, setIsModalAlertsOpen] = useState(false);
  const [isInfoAlertModalOpen, setIsInfoAlertModalOpen] = useState(false);
  const [isUploadInfoAlertOpen, setIsUploadInfoAlertOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [currentAlert, setCurrentAlert] = useState({});
  const [document, setDocument] = useState({});
  const [selfieMyKad, setSelfieMyKad] = useState({});
  const [myKadDocumentFront, setMyKadDocumentFront] = useState({});
  const [myKadDocumentBack, setMyKadDocumentBack] = useState({});
  const [ausIdDocuments, setAusIdDocuments] = useState({});

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    setIsUploadInfoAlertOpen(openUploadModal);

    const storageUser = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.user),
    );

    const alerts = user?.alerts ?? storageUser?.user?.alerts;

    setCurrentAlert(
      alerts
        ? alerts.find((alert) =>
            [
              ALERT_TYPES.idUploadErrors.errorUploadInformation,
              ALERT_TYPES.idUploadErrors.ktpUploadMissing,
              ALERT_TYPES.idUploadErrors.additionalUploadMissing,
              ALERT_TYPES.idUploadErrors.bankStatementUploadMissing,
              ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing,
              ALERT_TYPES.idUploadErrors.myKadBackUploadMissing,
              PENDING_INVESTMENT_WITH_PAYMENT_CODE,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissing,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements,
              ALERT_TYPES.idUploadErrors.idBackUploadMissing,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
              ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress,
            ].includes(alert.type),
          )
        : {},
    );

    if (AU_APP && user?.alerts?.length !== 0) {
      const fundingAlertsList = [];
      const otherAlertsList = [];

      user.alerts?.find((alert) => {
        if (
          [
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissing,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress,
          ].includes(alert.type)
        ) {
          fundingAlertsList.push(alert);
        } else {
          otherAlertsList.push(alert);
        }

        return false;
      });

      setIsModalAlertsOpen(false);

      if (
        history.location.pathname !== '/alerts' &&
        fundingAlertsList.length !== 0
      ) {
        setIsModalAlertsOpen(true);
      }

      setFundingAlerts(fundingAlertsList);

      setOtherAlerts(otherAlertsList);

      setIsModalAlertsOpen(isModalAlertsOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AU_APP && user?.alerts?.length !== 0) {
      const fundingAlertsList = [];
      const otherAlertsList = [];

      user.alerts?.find((alert) => {
        if (
          [
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissing,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress,
          ].includes(alert.type)
        ) {
          fundingAlertsList.push(alert);
        } else {
          otherAlertsList.push(alert);
        }

        return false;
      });

      setFundingAlerts(fundingAlertsList);

      setOtherAlerts(otherAlertsList);

      if (user.alerts === null) {
        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    }
  }, [user.alerts, navigate]);

  const toggleAlertInfoModal = (isOpen, alert) => {
    if (
      AU_APP &&
      [
        ALERT_TYPES.idUploadErrors.errorUploadInformation,
        ALERT_TYPES.idUploadErrors.idBackUploadMissing,
      ].includes(alert.type)
    ) {
      setIsInfoAlertModalOpen(false);
    } else {
      setIsInfoAlertModalOpen(
        alert.type === PENDING_INVESTMENT_WITH_PAYMENT_CODE ? false : isOpen,
      );
    }

    setCurrentAlert(isOpen ? alert : {});

    if (
      [
        ALERT_TYPES.idUploadErrors.errorUploadInformation,
        ALERT_TYPES.idUploadErrors.ktpUploadMissing,
        ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing,
        ALERT_TYPES.idUploadErrors.myKadBackUploadMissing,
        ALERT_TYPES.idUploadErrors.bankStatementUploadMissing,
        ALERT_TYPES.idUploadErrors.additionalUploadMissing,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissing,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements,
        ALERT_TYPES.idUploadErrors.selfieUploadMissing,
        ALERT_TYPES.idUploadErrors.idBackUploadMissing,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress,
      ].includes(alert.type)
    ) {
      setIsUploadInfoAlertOpen(isOpen);

      setIsModalAlertsOpen(false);

      setCurrentAlert(isOpen ? alert : {});
    } else if (alert.type === PENDING_INVESTMENT_WITH_PAYMENT_CODE) {
      dispatch(
        getTransactionDetails({
          id: alert.metaobject.investment_id,
          isKidsHistory: false,
        }),
      ).then(() => {
        setIsModalAlertsOpen(false);

        setCurrentAlert(isOpen ? alert : {});
      });
    } else {
      setCurrentAlert(isOpen ? alert : {});
    }
  };

  const { openDocumentUploadSuccessModal } = useDocumentUploadSuccessModal();

  const afterUpload = (statusCode) => {
    if (type !== UPLOAD_FAILED) {
      dispatch(getUserData());

      openDocumentUploadSuccessModal({
        currentAlert,
        statusCode,
      });

      toggleAlertInfoModal(false, currentAlert);

      setIsLoader(false);

      setMyKadDocumentFront({});

      setMyKadDocumentBack({});

      setAusIdDocuments({});
    }
  };

  const handleChangeStatus = ({ file, status, side }) => {
    if (status !== REJECTED_MAX_FILES) {
      const fileObject =
        status === REMOVED || status === REJECTED_FILE_TYPE ? {} : file;

      if (side === DOCUMENT_TYPES.myKadFront) {
        setMyKadDocumentFront(fileObject);
      } else if (side === DOCUMENT_TYPES.myKadBack) {
        setMyKadDocumentBack(fileObject);
      } else if (side === DOCUMENT_TYPES.selfiePhoto) {
        setSelfieMyKad(fileObject);
      }
    }
  };

  const submitDocument = () => {
    let submitIsFunding = false;
    let auType = '';

    switch (currentAlert.type) {
      case ALERT_TYPES.idUploadErrors.myKadBackUploadMissing:
        auType = DOCUMENT_TYPES.idDocument;

        break;
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress:
        auType = DOCUMENT_TYPES.proofOfAddress;

        break;
      case ALERT_TYPES.idUploadErrors.bankStatementUploadMissing:
        auType = 'bank_statement';

        break;
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissing:
        auType = 'new_bank_statement';

        submitIsFunding = true;

        break;
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId:
        submitIsFunding = true;

        auType = 'id';

        break;
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId:
        submitIsFunding = true;

        auType = 'selfie_with_id';

        break;
      default:
        auType = 'additional';
    }

    const docType = AU_APP ? auType : KTP;

    if (document.name) {
      setIsLoader(true);

      dispatch(
        uploadDocument({
          documentObject: document,
          documentType: docType,
          isFunding: submitIsFunding,
        }),
      ).then((statusCode) => {
        if (type !== UPLOAD_FAILED) {
          toggleAlertInfoModal(false, currentAlert);

          openDocumentUploadSuccessModal({
            currentAlert,
            statusCode,
          });
        } else {
          dispatch(getUserData());

          toggleAlertInfoModal(false, currentAlert);
        }

        setIsLoader(false);

        setDocument({});
      });
    }
  };

  const submitMyKad = () => {
    const isDocumentFront =
      currentAlert.type === ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing;

    const isDocumentBack =
      currentAlert.type === ALERT_TYPES.idUploadErrors.myKadBackUploadMissing;

    const isDocumentSelfie =
      currentAlert.type === ALERT_TYPES.idUploadErrors.selfieUploadMissing ||
      ALERT_TYPES.idUploadErrors.errorUploadInformation;

    if (isDocumentFront && myKadDocumentFront.name) {
      setIsLoader(true);

      dispatch(
        uploadDocument({
          documentObject: myKadDocumentFront,
          documentType: DOCUMENT_TYPES.myKadFront,
        }),
      ).then((statusCode) => {
        afterUpload(statusCode);
      });
    } else if (isDocumentBack && myKadDocumentBack.name) {
      setIsLoader(true);

      dispatch(
        uploadDocument({
          documentObject: myKadDocumentBack,
          documentType: DOCUMENT_TYPES.myKadBack,
        }),
      ).then((statusCode) => {
        afterUpload(statusCode);
      });
    } else if (myKadDocumentFront.name && myKadDocumentBack.name) {
      setIsLoader(true);

      dispatch(
        uploadDocument({
          documentObject: myKadDocumentFront,
          documentType: DOCUMENT_TYPES.myKadFront,
        }),
      ).then(() => {
        if (type !== UPLOAD_FAILED) {
          dispatch(
            uploadDocument({
              documentObject: myKadDocumentBack,
              documentType: DOCUMENT_TYPES.myKadBack,
            }),
          ).then(() => {
            if (type !== UPLOAD_FAILED) {
              dispatch(
                uploadDocument({
                  documentObject: selfieMyKad,
                  documentType: DOCUMENT_TYPES.selfiePhoto,
                }),
              ).then((statusCode) => {
                afterUpload(statusCode);
              });
            }
          });
        }
      });
    } else if (isDocumentSelfie && selfieMyKad.name) {
      setIsLoader(true);

      dispatch(
        uploadDocument({
          documentObject: selfieMyKad,
          documentType: DOCUMENT_TYPES.selfiePhoto,
        }),
      ).then((statusCode) => {
        afterUpload(statusCode);
      });
    }
  };

  const handleChangeAusId = ({ file, status }) => {
    if (status === REMOVED) {
      setAusIdDocuments({});
    } else if (status !== REJECTED_MAX_FILES) {
      const fileObject =
        status === REMOVED || status === REJECTED_FILE_TYPE ? {} : file;

      setAusIdDocuments(fileObject);
    }
  };

  const submitAusIdDocuments = () => {
    setIsLoader(true);

    dispatch(
      uploadDocument({
        documentObject: ausIdDocuments,
        documentType:
          currentAlert.metadata ||
          (currentAlert.type ===
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId &&
            DOCUMENT_TYPES.idDocument) ||
          (currentAlert.type ===
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack &&
            DOCUMENT_TYPES.idBack),
        isFunding: [
          ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
          ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
        ].includes(currentAlert.type),
      }),
    ).then((statusCode) => {
      afterUpload(statusCode);
    });
  };

  const isIdUploadingError = [
    ALERT_TYPES.idUploadErrors.errorUploadInformation,
    ALERT_TYPES.idUploadErrors.idBackUploadMissing,
    ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId,
    ALERT_TYPES.idUploadErrors.fsChangeUploadMissingIdBack,
  ].includes(currentAlert?.type);

  const [
    isAdditionalVerificationModalOpen,
    setIsAdditionalVerificationModalOpen,
  ] = useState(false);

  const handleVerificationModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t('settingsPage.explanationModal.description'),
          onAccept: () => {
            setIsAdditionalVerificationModalOpen(true);
          },
        },
      }),
    );
  };

  return (
    <div className="alerts">
      {isHeader && (
        <div className="header__alert">
          <div
            role="button"
            className="button__alert"
            tabIndex="0"
            onKeyPress={() => {}}
            onClick={() => navigate('/alerts')}
          />
        </div>
      )}

      {!openUploadModal &&
        !isHeader &&
        user.alerts &&
        user.alerts.length !== 0 && (
          <div className="info-panel">
            <div className="profile-content__header -with-navigation">
              <div className="alerts__main-title">{t('common.issues')}</div>
            </div>

            {AU_APP && fundingAlerts && fundingAlerts.length !== 0 && (
              <div>
                <div className="alerts__main-subtitle">
                  {t('alertsPage.funding.title')}
                </div>

                {fundingAlerts.map((alert, currentIndex) => (
                  <FundingAlertsModalItem
                    currentIndex={currentIndex}
                    length={fundingAlerts.length}
                    alert={alert}
                    toggleAlertInfoModal={toggleAlertInfoModal}
                    setIsModalAlertsOpen={setIsModalAlertsOpen}
                    key={window.crypto.randomUUID()}
                  />
                ))}
              </div>
            )}

            {AU_APP && otherAlerts && otherAlerts.length !== 0 && (
              <div>
                <div className="alerts__main-subtitle">
                  {t('alertsPage.other.title')}
                </div>

                {otherAlerts.map((alert, currentIndex) => (
                  <FundingAlertsModalItem
                    currentIndex={currentIndex}
                    length={otherAlerts.length}
                    alert={alert}
                    toggleAlertInfoModal={toggleAlertInfoModal}
                    setIsModalAlertsOpen={setIsModalAlertsOpen}
                    key={window.crypto.randomUUID()}
                    handleVerificationModal={handleVerificationModal}
                  />
                ))}
              </div>
            )}

            {!AU_APP && (
              <div>
                <hr className="header-hr" />

                {user.alerts.map((alert, currentIndex) => (
                  <FundingAlertsModalItem
                    currentIndex={currentIndex}
                    length={alert.length}
                    alert={alert}
                    toggleAlertInfoModal={toggleAlertInfoModal}
                    setIsModalAlertsOpen={setIsModalAlertsOpen}
                    key={window.crypto.randomUUID()}
                  />
                ))}
              </div>
            )}
          </div>
        )}

      {isHome &&
        fundingAlerts &&
        fundingAlerts.length !== 0 &&
        isModalAlertsOpen && (
          <FundingAlertsModal
            isModalAlertsOpen={isModalAlertsOpen}
            setIsModalAlertsOpen={setIsModalAlertsOpen}
            toggleAlertInfoModal={toggleAlertInfoModal}
            fundingAlerts={fundingAlerts}
          />
        )}

      {isInfoAlertModalOpen && !isHeader && (
        <InfoAlertModal
          currentAlert={currentAlert}
          toggleAlertInfoModal={toggleAlertInfoModal}
          isInfoAlertModalOpen={isInfoAlertModalOpen}
        />
      )}

      {isUploadInfoAlertOpen &&
        !isModalAlertsOpen &&
        AU_APP &&
        (currentAlert.type ===
          ALERT_TYPES.idUploadErrors.bankStatementUploadMissing ||
          currentAlert.type ===
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements ||
          currentAlert.type ===
            ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress) && (
          <DocumentMissingAlertModal
            isLoader={isLoader}
            type={type}
            setIsLoader={setIsLoader}
            currentAlert={currentAlert}
            afterUpload={afterUpload}
            isUploadInfoAlertOpen={isUploadInfoAlertOpen}
            toggleAlertInfoModal={toggleAlertInfoModal}
          />
        )}

      {AU_APP &&
        isUploadInfoAlertOpen &&
        !isModalAlertsOpen &&
        isIdUploadingError && (
          <AUSDocumentsUploader
            currentAlert={currentAlert}
            isUploadInfoAlertOpen={isUploadInfoAlertOpen}
            toggleAlertInfoModal={toggleAlertInfoModal}
            isLoader={isLoader}
            submitAusIdDocuments={submitAusIdDocuments}
            handleChangeStatus={handleChangeAusId}
            documents={ausIdDocuments}
          />
        )}

      {AU_APP &&
        isUploadInfoAlertOpen &&
        !isModalAlertsOpen &&
        currentAlert.type !==
          ALERT_TYPES.idUploadErrors.bankStatementUploadMissing &&
        currentAlert.type !==
          ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements &&
        currentAlert.type !==
          ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress &&
        !isIdUploadingError && (
          <UploaderDocumentsModal
            currentAlert={currentAlert}
            isUploadInfoAlertOpen={isUploadInfoAlertOpen}
            toggleAlertInfoModal={toggleAlertInfoModal}
            isLoader={isLoader}
            submitDocument={submitDocument}
            setDocument={setDocument}
          />
        )}

      {isUploadInfoAlertOpen && !isModalAlertsOpen && MY_APP && (
        <MYSDocumentsUploader
          currentAlert={currentAlert}
          handleChangeStatus={handleChangeStatus}
          myKadDocumentBack={myKadDocumentBack}
          isUploadInfoAlertOpen={isUploadInfoAlertOpen}
          selfieMyKad={selfieMyKad}
          toggleAlertInfoModal={toggleAlertInfoModal}
          myKadDocumentFront={myKadDocumentFront}
          isLoader={isLoader}
          submitMyKad={submitMyKad}
        />
      )}

      {isAdditionalVerificationModalOpen && (
        <IdentityVerificationModal
          isOpen={isAdditionalVerificationModalOpen}
          closeRequest={() => setIsAdditionalVerificationModalOpen(false)}
          isUserTarget
        />
      )}
    </div>
  );
}

export default Alerts;

Alerts.propTypes = {
  actions: PropTypes.shape({
    uploadDocument: PropTypes.func,
    getUserData: PropTypes.func,
  }),
  actionsHistory: PropTypes.shape({
    getTransactionDetails: PropTypes.func,
  }),
  user: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  openUploadModal: PropTypes.bool,
  transactionDetails: PropTypes.shape({}),
  isHeader: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  previewProps: PropTypes.shape({
    file: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Alerts.defaultProps = {
  user: {},
  openUploadModal: false,
  transactionDetails: {},
  isHeader: false,
  history: {},
  previewProps: {},
  actions: {},
  actionsHistory: {},
};
