import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { REGISTRATION_ACCOUNT_INFORMATION } from '../../../store/commonTypes';
import { REGISTRATION_PERSONAL_ADDRESS } from '../../../store/user/consts';
import SelectFF from '../../../components/elements/SelectFF/SelectFF';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function Information({
  handleSubmit,
  goBack,
  showLoader,
  showStepAccountError,
  showHelpCardInfo,
}) {
  const { t } = useTranslation();

  const questions = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.registrationQuestions),
  );

  const questionEducation = questions.find(
    (questionFind) => questionFind.key === 'educational_background',
  );

  const questionReligion = questions.find(
    (questionFind) => questionFind.key === 'religion',
  );

  const questionOccupation = questions.find(
    (questionFind) => questionFind.key === 'occupation',
  );

  const questionReska = {
    answers: [
      { key: true, display_name: t('common.yes') },
      { key: false, display_name: t('common.no') },
    ],
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) =>
            handleSubmit(
              values,
              e,
              form,
              hasValidationErrors,
              REGISTRATION_ACCOUNT_INFORMATION,
            )
          }
        >
          <div>
            <div>
              <div className="select-first -gender -tax">
                <div className="profile-content__container-label">
                  {questionEducation.hint}
                </div>

                <SelectFF
                  name="educational_background"
                  options={questionEducation.answers}
                />
              </div>

              <div className="select-first -gender -tax">
                <div className="profile-content__container-label">
                  {questionReligion.hint}
                </div>

                <SelectFF name="religion" options={questionReligion.answers} />
              </div>

              <div className="select-first -gender -tax">
                <div className="profile-content__container-label">
                  {questionOccupation.hint}
                </div>

                <SelectFF
                  name="occupation"
                  options={questionOccupation.answers}
                />
              </div>

              <div className="select-first -gender -tax">
                <div className="profile-content__container-label">
                  {t('registrationPage.account.knowledge')}
                </div>

                <SelectFF
                  onChange={showHelpCardInfo}
                  name="mutual_fund_knowledge"
                  options={questionReska.answers}
                />
              </div>

              {showStepAccountError && (
                <div className="registration-page__error-msg">
                  {showStepAccountError}
                </div>
              )}

              <div className="buttons-part">
                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    title={t('common.back')}
                    buttonClass="button back"
                    handleClick={() => goBack(REGISTRATION_PERSONAL_ADDRESS)}
                  />
                </div>

                <div className="form-field">
                  <DeprecatedButton
                    type="submit"
                    withLoader={showLoader}
                    title={t('common.next')}
                    buttonClass="button button--submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

Information.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showStepAccountError: PropTypes.string,
  showLoader: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  showHelpCardInfo: PropTypes.func.isRequired,
};

Information.defaultProps = {
  showStepAccountError: '',
  showLoader: false,
};
