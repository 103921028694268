import styled from '@emotion/styled';

export const Amount = styled.span`
  color: ${({ theme }) => theme.colors.niagara};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['3md']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-left: 16px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  min-width: 110px;
  text-align: left;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  margin-bottom: 8px;
  text-align: left;
  height: 20px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.athensGray};
  padding: 16px 24px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  :last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
`;

export const Avatar = styled.div`
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.alto};
  border-radius: 22px;
  margin-right: 12px;

  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};

  color: ${({ theme }) => theme.colors.mirage};

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    display: none;
  }
`;
