import styled from '@emotion/styled';
import Modal from 'react-modal';

import closeBlack from 'assets/images/icons/close-black.svg';

export const GlobalInfoModalWrapper = styled(Modal)<{
  size: number;
  customStyles?: string;
}>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  margin: 240px auto;
  border-radius: 6px;
  padding: 30px 50px 16px 50px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  overflow: auto;
  max-width: ${({ size }) => size}px;
  min-height: auto;
  position: relative;
  :focus {
    outline: 0;
  }
  ${({ customStyles }) => customStyles};
`;

export const GlobalInfoModalButtonClose = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  background-image: url(${`${closeBlack}`});
  background-repeat: no-repeat;
  background-size: 19px;
  width: 19px;
  height: 19px;
  position: absolute;
  right: 25px;
  top: 33px;
  border-radius: 0;
`;

export const GlobalInfoModalTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  color: ${({ theme }) => theme.colors.mirage};
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
`;

export const GlobalInfoModalContent = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  color: ${({ theme }) => theme.colors.mirage};
  width: 100%;
  text-align: center;
`;

export const GlobalInfoModalAcceptButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export const GlobalInfoRejectButton = styled.button`
  width: 100%;
  background: none;
`;

export const GlobalInfoModalButtonsWrapper = styled.div<{
  isButtonHorizontal?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isButtonHorizontal }) =>
    isButtonHorizontal ? 'row-reverse' : 'column'};
  gap: 8px;
  margin-top: 16px;
`;
