import 'styles/elements/dots-loader.scss';
import PropTypes from 'prop-types';

import { DotsLoaderWrapper, DotsLoaderItem } from './styles';

const DOTS_LOADER_ID = 'dots-loader';

export default function DotsLoader({ size, gap }) {
  const defaultGap = size / 3;

  return (
    <DotsLoaderWrapper data-testid={DOTS_LOADER_ID}>
      {[...Array(3)].map((e, index) => (
        <DotsLoaderItem
          key={`dots-loader-${index + 1}`}
          index={index}
          size={size}
          gap={gap || defaultGap}
        />
      ))}
    </DotsLoaderWrapper>
  );
}

DotsLoader.defaultProps = {
  size: 18,
  gap: 0,
};

DotsLoader.propTypes = {
  size: PropTypes.number,
  gap: PropTypes.number,
};
