import styled from '@emotion/styled';

import placeholderFund from 'assets/images/icons/placeholder-fund.svg';

export const TopHoldingsItemsWrapper = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
`;

export const TopHoldingsItemWrapper = styled.div`
  padding: 18px 0 18px 16px;
  line-height: ${({ theme }) => theme.lineHeights.length['3xl']};
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const TopHoldingsItemLabel = styled.div`
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  float: left;
  margin-top: -10px;
  line-height: ${({ theme }) => theme.lineHeights.length['3lg']};
`;

export const TopHoldingsItemSymbol = styled.div`
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
`;

export const TopHoldingsItemImage = styled.div`
  padding: 16px;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat left center / contain`
      : `url(${placeholderFund}) no-repeat center center / auto`};
  float: left;
  margin-right: 20px;
`;

export const TopHoldingsItemValue = styled.div`
  text-align: right;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  padding-right: 16px;
`;
