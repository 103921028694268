import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enAu from './locales/en-au.json';
import enMs from './locales/en-ms.json';
import msMs from './locales/ms-ms.json';
import { LANGUAGES, options } from './consts';
import { customLanguageDetector } from './utils';

export const resources = {
  [LANGUAGES.enAu]: {
    translation: enAu,
  },
  [LANGUAGES.enMs]: {
    translation: enMs,
  },
  [LANGUAGES.msMs]: {
    translation: msMs,
  },
} as const;

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'customDetector',
  lookup: customLanguageDetector,
});

export const defaultNS = 'translation';

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    detection: options,
    fallbackLng: LANGUAGES.enAu,
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      defaultTransParent: 'span',
    },
  });

export default i18next;
