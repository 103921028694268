import React from 'react';
import PropTypes from 'prop-types';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function PDFDocumentUploaderPreview({ files, meta }) {
  return (
    <label className="dzu-inputLabel">
      <div className="registration-page__dropzone-content -preview">
        <DeprecatedButton
          buttonClass="button__close"
          handleClick={() => {
            files[0].remove();
          }}
        />

        <i className="registration-page__dropzone-icon -pdf-upload -preview" />

        <div className="registration-page__dropzone-subtitle -preview">
          {meta.name}
        </div>
      </div>
    </label>
  );
}

PDFDocumentUploaderPreview.propTypes = {
  files: PropTypes.arrayOf().isRequired,
  meta: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default PDFDocumentUploaderPreview;
