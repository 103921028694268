import { RefObject } from 'react';
import Chart from 'react-apexcharts';

import { CHART_BACKGROUND, PRO_PORTFOLIO_CHART_SIZE } from '../consts';

import { IChartData } from './types';

interface IProPortfolioChartProps {
  activeFundTags: {
    name: string;
    percentage: number;
    allocation: number;
  }[];
  chartRef: RefObject<Chart>;
}

export default function ProPortfolioChart({
  activeFundTags,
  chartRef,
}: IProPortfolioChartProps) {
  const chartData = activeFundTags.reduce(
    (funds: IChartData, fund) =>
      fund?.name
        ? {
            labels: [...funds.labels, fund?.name],
            values: [
              ...funds.values,
              fund?.percentage || fund?.allocation || 0,
            ],
          }
        : funds,
    { labels: [], values: [] },
  );

  return (
    <Chart
      options={{
        labels: chartData.labels,
        legend: {
          show: false,
        },
        tooltip: {
          custom: ({ series, seriesIndex }) =>
            `${chartData.labels[seriesIndex]}: ${series[seriesIndex]}%`,
        },
        colors: CHART_BACKGROUND,
      }}
      series={chartData.values}
      type="pie"
      width={PRO_PORTFOLIO_CHART_SIZE}
      height={PRO_PORTFOLIO_CHART_SIZE}
      ref={chartRef}
    />
  );
}
