import { useRef } from 'react';
import ApexChart from 'react-apexcharts';

import { usePerformanceChartData } from '../hooks';

import { ChartLoader, ChartWrapper, PERFORMANCE_CHART_HEIGHT } from './styles';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

interface IChartProps {
  id: string;
  numberOfDays: number;
}

export default function Chart({ id, numberOfDays }: IChartProps) {
  const chartRef = useRef<HTMLDivElement>(null);
  const performanceChartWidth = chartRef?.current?.offsetWidth;

  const { chartData, performanceChartConfig, isLoading } =
    usePerformanceChartData({ id, performanceChartWidth, numberOfDays });

  return (
    <ChartWrapper ref={chartRef}>
      {isLoading ? (
        <ChartLoader>
          <DotsLoader />
        </ChartLoader>
      ) : (
        <ApexChart
          options={performanceChartConfig}
          series={chartData}
          type="area"
          height={PERFORMANCE_CHART_HEIGHT}
        />
      )}
    </ChartWrapper>
  );
}
