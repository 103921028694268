import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import { MY_APP } from '../../../constants/localeConfigs';
import { MULTI_LANG } from '../../../constants/togglesFunctions';
import { BAHASA, ENGLISH } from '../../../constants/comonConstants';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function PageView({
  children,
  contentClass,
  header,
  menu,
  showMainLoader,
  globalErrorMessage,
  handleCloseModal,
  isShowExpirateModal,
  timeLeft,
  stayHere,
  logoutUser,
  showLangChange,
  history,
  user,
}) {
  const { t } = useTranslation();

  function handleOnClick() {
    const mainMenu =
      document.body.querySelector('.main-menu.-mobile').classList;

    const bodyElement = document.querySelector('body').classList;

    mainMenu.remove('-open');

    bodyElement.remove('-open-menu');
  }

  function toggleLang(lang) {
    localStorage.setItem('lang', lang);

    window.location.reload();
  }

  const internetConnection = navigator.onLine;
  const currentLang = localStorage.getItem('lang');

  let appClassName = 'App';

  if (MY_APP) {
    appClassName += ' app-mys';
  }

  if (!header) appClassName += ' -wide-content';

  return (
    <div className={appClassName}>
      {header && !showMainLoader && (
        <Header
          home={t('layout.header.home')}
          about={t('layout.header.about')}
          message={t('layout.header.welcome')}
          login={t('layout.header.login')}
          registration={t('layout.header.registration')}
          history={history}
        />
      )}

      {menu && !showMainLoader && <Menu user={user} />}

      {header}

      <div className={`main-container ${contentClass}`}>
        {MULTI_LANG && showLangChange && (
          <div className="header__lang">
            <DeprecatedButton
              buttonClass={`header__lang-icon -eng ${
                currentLang === BAHASA ? '-active' : '-disable'
              }`}
              handleClick={() => toggleLang('english')}
              title=""
            />

            <div className="header__lang-divider" />

            <DeprecatedButton
              buttonClass={`header__lang-icon -mys ${
                !currentLang || currentLang === ENGLISH ? '-active' : '-disable'
              }`}
              handleClick={() => toggleLang('bahasa')}
              title=""
            />
          </div>
        )}

        <div
          className={`justify-content-md-center ${
            header ? '-with-header' : ''
          } row`}
        >
          {children}
        </div>
      </div>

      <div
        className="backdrop"
        onClick={() => handleOnClick()}
        role="presentation"
      />

      {globalErrorMessage !== '' && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            {internetConnection ? (
              <div className="alert-text">{globalErrorMessage}</div>
            ) : (
              <div className="alert-text">
                {t('layout.globalError.internetConnection')}
              </div>
            )}

            <div className="error-buttons">
              <DeprecatedButton
                title={t('common.ok')}
                type="button"
                handleClick={handleCloseModal}
              />
            </div>
          </div>
        </Modal>
      )}

      {isShowExpirateModal && (
        <Modal
          isOpen={isShowExpirateModal}
          onRequestClose={stayHere}
          contentLabel="logout-modal"
          shouldCloseOnOverlayClick
          className="modal-basic logout-modal"
        >
          <DeprecatedButton
            buttonClass="button__close -grey"
            handleClick={stayHere}
          />

          <div className="logout-modal__title">
            {t('layout.expireModal.title')}
          </div>

          <div className="logout-modal__info">
            {t('layout.expireModal.description.yourSession')}
          </div>

          <div className="logout-modal__info">
            <span>
              {`${dayjs.duration(timeLeft, 'seconds').format('m')} `}

              {t('layout.expireModal.description.min')}
            </span>

            <span>
              {` ${dayjs.duration(timeLeft, 'seconds').format('ss')} `}

              {t('layout.expireModal.description.sec')}
            </span>
          </div>

          <div className="logout-modal__footer">
            <Button label={t('common.continue')} onClick={stayHere} />

            <DeprecatedButton
              title={t('common.logout')}
              buttonClass="button__basic-fill -white -grey-border"
              handleClick={logoutUser}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

PageView.propTypes = {
  children: PropTypes.element.isRequired,
  contentClass: PropTypes.string.isRequired,
  header: PropTypes.bool.isRequired,
  menu: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  globalErrorMessage: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isShowExpirateModal: PropTypes.bool.isRequired,
  timeLeft: PropTypes.number.isRequired,
  stayHere: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  showLangChange: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
};
