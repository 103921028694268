import React from 'react';
import PropTypes from 'prop-types';

import {
  getIsRoundUpsTemplateDark,
  getRoundUpsTransactionBackground,
} from '../utils/utils';

import RoundUpsMultiplier from './RoundUpsMultiplier';
import {
  AddTransactionCheckbox,
  AddTransactionWrapper,
  RoundUpsActionsWrapper,
  RoundUpsAmountChange,
  RoundUpsAmountOriginalChange,
  RoundUpsAmountWrapper,
  RoundUpsInfo,
  RoundUpsInfoAmount,
  RoundUpsInfoDescription,
  RoundUpsTransactionItem,
  CheckboxRaiz,
} from './styles';

import { AU_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

function RoundUpsElement({ info, handleSelectCheckbox }) {
  const background = getRoundUpsTransactionBackground(info);
  const isRoundUpsTemplateDark = getIsRoundUpsTemplateDark(background);

  return (
    <RoundUpsTransactionItem background={background} key={info.id}>
      <div>
        <RoundUpsAmountWrapper>
          <RoundUpsAmountChange isRoundUpsTemplateDark={isRoundUpsTemplateDark}>
            {formatNumber({
              value: info?.change,
            })}
          </RoundUpsAmountChange>

          <RoundUpsAmountOriginalChange
            isRoundUpsTemplateDark={isRoundUpsTemplateDark}
          >
            {AU_APP &&
              formatNumber({
                value: info?.original_change,
                maximumFractionDigits: 2,
              })}
          </RoundUpsAmountOriginalChange>
        </RoundUpsAmountWrapper>

        <RoundUpsInfo>
          <RoundUpsInfoAmount isRoundUpsTemplateDark={isRoundUpsTemplateDark}>
            {formatNumber({
              value: info?.amount,
            })}
          </RoundUpsInfoAmount>

          <RoundUpsInfoDescription
            isRoundUpsTemplateDark={isRoundUpsTemplateDark}
          >
            {info.merchant}
          </RoundUpsInfoDescription>
        </RoundUpsInfo>
      </div>

      <RoundUpsActionsWrapper>
        <RoundUpsMultiplier
          isRoundUpsTemplateDark={isRoundUpsTemplateDark}
          multiplier={info.multiplier}
        />

        {AU_APP &&
          (info.approved_at ? (
            <CheckboxRaiz />
          ) : (
            <AddTransactionWrapper
              isRoundUpsTemplateDark={isRoundUpsTemplateDark}
            >
              +
              <AddTransactionCheckbox
                type="checkbox"
                name={info.id}
                onChange={(e) => handleSelectCheckbox(e, info)}
              />
            </AddTransactionWrapper>
          ))}
      </RoundUpsActionsWrapper>
    </RoundUpsTransactionItem>
  );
}

RoundUpsElement.defaultProps = {
  info: {},
};

RoundUpsElement.propTypes = {
  info: PropTypes.shape({
    autoroundup_eligible: PropTypes.bool,
    raiz_rdv: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
    change: PropTypes.number,
    amount: PropTypes.number,
    merchant: PropTypes.string,
    approved_at: PropTypes.string,
    multiplier: PropTypes.number,
    original_change: PropTypes.number,
  }),
  handleSelectCheckbox: PropTypes.func.isRequired,
};

export default RoundUpsElement;
