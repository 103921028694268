import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import StatementsSkeleton from '../../Statements/components/StatementsSkeleton/StatementsSkeleton';

export default function InsurancesSkeleton({ areCertificatesLoading }) {
  return areCertificatesLoading
    ? [...Array(10)].map(() => (
        <StatementsSkeleton key={window.crypto.randomUUID()} />
      ))
    : '';
}

InsurancesSkeleton.propTypes = {
  areCertificatesLoading: PropTypes.bool.isRequired,
};
