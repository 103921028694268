import { useTranslation, Trans } from 'react-i18next';

import {
  BodyBackground,
  InfoPanelContentTop,
  InfoPanelContentWrapper,
  InfoPanelText,
  InfoPanelWrapper,
} from './styles';

import Logo from 'pages/AccountMigrationPassword/components/Logo/Logo';

export default function InfoPanel() {
  const { t } = useTranslation();

  return (
    <InfoPanelWrapper>
      <InfoPanelContentWrapper>
        <InfoPanelContentTop>
          <Logo />

          <BodyBackground />

          <InfoPanelText>
            <Trans t={t} i18nKey="registrationPage.leftBottom.investInfo" />
          </InfoPanelText>
        </InfoPanelContentTop>
      </InfoPanelContentWrapper>
    </InfoPanelWrapper>
  );
}
