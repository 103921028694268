export const SHOW_MODAL = 'SHOW_MODAL';
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const GET_PRESENT_SUCCESS = 'GET_PRESENT_SUCCESS';
export const GET_SECURITIES_PRICES_SUCCESS = 'GET_SECURITIES_PRICES_SUCCESS';
export const GET_ALLOCATION_PROFILES_SUCCESS =
  'GET_ALLOCATION_PROFILES_SUCCESS';
export const GET_SECURITIES_FUNDAMENTALS_SUCCESS =
  'GET_SECURITIES_FUNDAMENTALS_SUCCESS';
export const GET_MARKET_STATUS_SUCCESS = 'GET_MARKET_STATUS_SUCCESS';
export const PERFOMANCE_UNMOUNT = 'PERFOMANCE_UNMOUNT';
export const SHOW_ERROR_MARKET_STATUS = 'SHOW_ERROR_MARKET_STATUS';

export const GET_SECURITIES_LOADING = 'GET_SECURITIES_LOADING';
