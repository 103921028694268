import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { MIN_INVEST } from 'constants/comonConstants';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

const MinimumInvestmentModal = ({
  isOpenMinimumInvestmentModal,
  openMinimumInvestmentModal,
  errorMessage,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const isBeforeRequest = !errorMessage;

  const modalClassName = `modal-basic time-investment__modal time-investment__modal-quick
  ${isBeforeRequest ? '' : '-left-align'}`;

  const handleCloseModal = () => openMinimumInvestmentModal(false);

  const handleInvest = () => openMinimumInvestmentModal(false, MIN_INVEST);

  const navigateToAddFundingAccount = () =>
    navigate('/settings/funding-account');

  return (
    <div>
      <Modal
        isOpen={isOpenMinimumInvestmentModal}
        onRequestClose={handleCloseModal}
        contentLabel="time-investment-modal"
        shouldCloseOnOverlayClick
        className={modalClassName}
      >
        <div className="time-investment__modal-title">
          <Trans
            i18nKey={
              isBeforeRequest
                ? 'oneTimeInvestment.minimumInvestmentModal.beforeRequest.title'
                : 'oneTimeInvestment.minimumInvestmentModal.title'
            }
            components={{
              minimum: formatNumber({ value: MIN_INVEST }),
            }}
          />
        </div>

        <div className="time-investment__modal-content">
          {isBeforeRequest ? (
            <Trans
              i18nKey="oneTimeInvestment.minimumInvestmentModal.beforeRequest.content"
              components={{
                minimum: formatNumber({ value: MIN_INVEST }),
              }}
            />
          ) : (
            errorMessage
          )}
        </div>

        <div className="time-investment__modal-footer">
          <DeprecatedButton
            title={
              isBeforeRequest
                ? t('common.cancel')
                : t('oneTimeInvestment.minimumInvestmentModal.cancel')
            }
            buttonClass="button__basic-transparent -black"
            handleClick={handleCloseModal}
          />

          <Button
            label={
              <Trans
                i18nKey={
                  isBeforeRequest
                    ? 'oneTimeInvestment.minimumInvestmentModal.beforeRequest.ok'
                    : 'oneTimeInvestment.minimumInvestmentModal.ok'
                }
                components={{
                  minimum: formatNumber({ value: MIN_INVEST }),
                }}
              />
            }
            onClick={
              isBeforeRequest ? handleInvest : navigateToAddFundingAccount
            }
          />
        </div>
      </Modal>
    </div>
  );
};

MinimumInvestmentModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  isOpenMinimumInvestmentModal: PropTypes.bool.isRequired,
  openMinimumInvestmentModal: PropTypes.func.isRequired,
};

export default MinimumInvestmentModal;
