import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/logout.scss';
import { CURRENCY_LOCALE, CURRENCY_NAME } from 'constants/localeConfigs';
import { Button } from 'components/elements/Button';

export default function LumpSumNoteModal({
  isOpenInvestLumpSumInfoModal,
  showLoader,
  openInvestLumpSumInfoModal,
  lumpSumInvest,
  lumpSumValue,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpenInvestLumpSumInfoModal && !showLoader}
      onRequestClose={() => openInvestLumpSumInfoModal(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <div className="time-investment__modal-content">
        <Trans
          i18nKey="superPage.lumpSumNoteModal.content"
          values={{
            amount: new Intl.NumberFormat(CURRENCY_LOCALE, {
              style: 'currency',
              currency: CURRENCY_NAME,
            }).format(lumpSumValue),
          }}
          t={t}
        />
      </div>

      <div className="time-investment__modal-footer">
        <DeprecatedButton
          title={<Trans i18nKey="common.cancel" t={t} />}
          buttonClass="button__basic-transparent -black"
          handleClick={() => openInvestLumpSumInfoModal(false)}
        />

        <Button
          label={<Trans i18nKey="common.ok" t={t} />}
          onClick={() => lumpSumInvest()}
        />
      </div>
    </Modal>
  );
}

LumpSumNoteModal.propTypes = {
  isOpenInvestLumpSumInfoModal: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  openInvestLumpSumInfoModal: PropTypes.func.isRequired,
  lumpSumInvest: PropTypes.func.isRequired,
  lumpSumValue: PropTypes.number.isRequired,
};
