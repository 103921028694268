import { useTranslation } from 'react-i18next';

import { Item } from '../Item';
import { NoItem } from '../NoItem';

import { Header, ListHeader } from './styles';

import { IReferralsListGroupedData } from 'store/referrals/types';
import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

interface IListProps {
  items: IReferralsListGroupedData[];
  isLoading: boolean;
  isSuccess: boolean;
}

export const List = ({ items, isLoading, isSuccess }: IListProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <DotsLoader />;
  }

  if (isSuccess && !items?.length) {
    return <NoItem />;
  }

  return (
    <>
      <ListHeader>{t('referralsPage.listHeader')}</ListHeader>

      {items?.map?.((item) =>
        item.type === 'header' ? (
          <Header key={item.id}>{item.data}</Header>
        ) : (
          <Item key={item.id} item={item.data} />
        ),
      )}
    </>
  );
};
