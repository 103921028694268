import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import '../../../styles/layout/withdraw.scss';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import FinalFormInput from '../../elements/FinalFormInput/FinalFormInput';
import { REQUIRED } from '../../../constants/errors';
import Loader from '../../elements/Loader/Loader';

import BankErrorModal from './BankErrorModal';
import WaitSkipModal from './WaitSkipModal';
import { BankSignInButton } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

Modal.setAppElement('#root');

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    let valuesValidate = {};

    valuesValidate = values;

    const errors = {};

    if (!valuesValidate.bsb) {
      errors.bsb = REQUIRED;
    }

    if (!valuesValidate.accountNumber) {
      errors.accountNumber = REQUIRED;
    } else if (valuesValidate.accountNumber.length < 6) {
      errors.accountNumber = t('fields.validation.minCharacters', {
        number: '6',
      });
    }

    if (!valuesValidate.accountNumberConfirm) {
      errors.accountNumberConfirm = REQUIRED;
    } else if (valuesValidate.accountNumberConfirm.length < 6) {
      errors.accountNumberConfirm = t('fields.validation.minCharacters', {
        number: '6',
      });
    }

    if (valuesValidate.accountNumberConfirm) {
      valuesValidate.accountNumberConfirm =
        valuesValidate.accountNumberConfirm.replace(/\D/g, '');
    }

    if (valuesValidate.accountNumber) {
      valuesValidate.accountNumber = valuesValidate.accountNumber.replace(
        /\D/g,
        '',
      );
    }

    if (
      valuesValidate.accountNumber &&
      valuesValidate.accountNumberConfirm &&
      valuesValidate.accountNumber !== valuesValidate.accountNumberConfirm
    ) {
      errors.accountNumber = t('fields.validation.notMatching', {
        value: t('fields.accountNumber.label'),
      });

      errors.accountNumberConfirm = t('fields.validation.notMatching', {
        value: t('fields.cardNumber.label'),
      });
    }

    return errors;
  };

  return { validate };
};

export default function BankSignInDifferentModal({
  isShowBankModal,
  handleCloseModal,
  bank,
  handleSubmit,
  credentials,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  showLoader,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) =>
        validate(values, bank && bank.login_form ? bank.login_form : [])
      }
      render={({ form, values, hasValidationErrors }) => (
        <div>
          {errorMessage && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowBankModalError}
              errorTitle={errorTitle}
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
              shouldCloseOnOverlayClick
            />
          )}

          <Modal
            isOpen={isShowBankModal}
            onRequestClose={handleCloseModal}
            contentLabel="withdraw-modal"
            shouldCloseOnOverlayClick
            className="modal-basic bank__modal"
          >
            {showLoader && (
              <div className="bank-loader">
                <div className="bank__modal-content">
                  <DeprecatedButton
                    title={t('common.space')}
                    buttonClass="button__close -black"
                    handleClick={handleCloseModal}
                  />

                  <Loader additionalClass="-absolute -top" />

                  <div className="title">{t('common.pleaseWait')}</div>

                  <div className="sub-title">
                    {t('settingsPage.dataBeingProcessedMessage')}
                  </div>
                </div>
              </div>
            )}

            {!showLoader && (
              <div>
                <WaitSkipModal
                  hasBackdrop={false}
                  isVisible={false}
                  isOpen={isShowBankModalAlert}
                  waitHandleClick={bankLoginProcessWait}
                  skipHandleClick={handleStopBankAccountLinking}
                />

                <div className="bank__modal-different">
                  <DeprecatedButton
                    title={t('common.space')}
                    buttonClass="button__close -black"
                    handleClick={handleCloseModal}
                  />

                  <div className="bank__modal-title">
                    {t('settingsPage.fundingAccount.title')}
                  </div>

                  <div className="bank__modal-sub">
                    <i className="bank__modal-secured-icon" />

                    {t('common.secured')}
                  </div>

                  <div className="bank__modal-description">
                    {t(
                      'settingsPage.bankSignInModal.differentSignInModal.connectFundingMessage',
                    )}
                  </div>

                  {bank && bank.name && (
                    <div className="bank__modal-name">
                      <img
                        alt={bank.name}
                        className="bank__modal-icon"
                        src={bank.icon}
                      />

                      <span>{bank.name}</span>
                    </div>
                  )}

                  {(!bank || !bank.name) && (
                    <div className="bank__modal-empty" />
                  )}

                  <div className="bank__modal-row">
                    <form
                      autoComplete="off"
                      className="bank__form"
                      onSubmit={(e) =>
                        handleSubmit(e, values, form, hasValidationErrors)
                      }
                    >
                      <div className="icon">
                        <i className="bank__modal-bsb-icon" />
                      </div>

                      <div className="bank__modal__container-item">
                        <FinalFormInput
                          name="bsb"
                          defaultValue={credentials.bsb}
                          maxLength="6"
                          isNumberOnly
                          label={t(
                            'settingsPage.bankSignInModal.differentSignInModal.bsbLabel',
                          )}
                        />
                      </div>

                      <div className="icon">
                        <i className="bank__modal-bsb-number-icon" />
                      </div>

                      <div className="bank__modal__container-item">
                        <FinalFormInput
                          name="accountNumber"
                          defaultValue={credentials.accountNumber}
                          maxLength="10"
                          isNumberOnly
                          label={t(
                            'settingsPage.bankSignInModal.differentSignInModal.accountNumberFields.label',
                          )}
                        />
                      </div>

                      <div className="icon">
                        <i className="bank__modal-bsb-number-icon" />
                      </div>

                      <div className="bank__modal__container-item">
                        <FinalFormInput
                          name="accountNumberConfirm"
                          defaultValue={credentials.accountNumberConfirm}
                          maxLength="10"
                          isNumberOnly
                          label={t(
                            'settingsPage.bankSignInModal.differentSignInModal.accountNumberFields.confirmationLabel',
                          )}
                        />
                      </div>

                      <BankSignInButton
                        label={t('common.connect')}
                        type="submit"
                      />
                    </form>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      )}
    />
  );
}

BankSignInDifferentModal.defaultProps = {
  isShowBankModal: false,
};

BankSignInDifferentModal.propTypes = {
  isShowBankModal: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  credentials: PropTypes.shape({
    bsb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    accountNumber: PropTypes.string,
    accountNumberConfirm: PropTypes.string,
  }).isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
};
