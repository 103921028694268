import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsSuperUserAlertOpen } from 'store/user/selectors';
import { toggleInfoModal } from 'store/modals/actions';
import { ALERT_TYPES } from 'constants/alerts';
import { PATHS } from 'constants/paths';
import { SuperAccountErrorModal } from 'components/layout/Modals/SuperAccountErrorModal';

export const useSuperAccountErrorModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSuperAccountError = useAppSelector(
    selectIsSuperUserAlertOpen(ALERT_TYPES.portfolio.superAccountError),
  );

  const onModalClose = useCallback(() => {
    navigate(PATHS.root);
  }, [navigate]);

  useEffect(() => {
    if (isSuperAccountError) {
      dispatch(
        toggleInfoModal({
          isInfoModalVisible: isSuperAccountError,
          config: {
            title: t('superAccountModal.errorModal.title'),
            description: <SuperAccountErrorModal />,
            acceptButtonText: null,
            onAccept: onModalClose,
            onReject: onModalClose,
          },
        }),
      );
    }
  }, [dispatch, isSuperAccountError, onModalClose, t]);
};
