export const ALERT_TYPES = {
  depositsSuspensionRemoval: 'DEPOSITS_SUSPENSION_REMOVAL',
  registration: {
    incompleteWithdrawalAccount: 'REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT',
    incompleteFundingAccount: 'REGISTRATION_INCOMPLETE_LINKFUNDING',
    consentPending: 'CONSENT_PENDING',
  },
  idUploadErrors: {
    errorUploadInformation: 'ERRUPLOADINFORMATION',
    myKadFrontUploadMissing: 'MYKAD_FRONT_UPLOAD_MISSING',
    myKadBackUploadMissing: 'MYKAD_BACK_UPLOAD_MISSING',
    bankStatementUploadMissing: 'BANK_STATEMANT_UPLOAD_MISSING',
    additionalUploadMissing: 'ADDITIONAL_UPLOAD_MISSING',
    ktpUploadMissing: 'KTP_UPLOAD_MISSING',
    selfieUploadMissing: 'SELFIE_UPLOAD_MISSING',
    fsChangeUploadMissing: 'FS_CHANGE_UPLOAD_MISSING',
    fsChangeUploadMissingId: 'FS_CHANGE_UPLOAD_MISSING_ID',
    fsChangeUploadMissingSelfieWithId:
      'FS_CHANGE_UPLOAD_MISSING_SELFIE_WITH_ID',
    fsChangeUploadMissingBankStatements:
      'FS_CHANGE_UPLOAD_MISSING_BANK_STATEMENTS',
    fsChangeUploadMissingIdBack: 'FS_CHANGE_UPLOAD_MISSING_ID_BACK',
    idBackUploadMissing: 'ID_BACK_UPLOAD_MISSING',
    fsChangeUploadMissingProofOfAddress:
      'FS_CHANGE_UPLOAD_MISSING_PROOF_OF_ADDRESS',
  },
  portfolio: {
    superAccountError: 'ACCOUNT_ERROR',
  },
  profileReviewRequested: 'PROFILE_REVIEW_REQUESTED',
  autoVerification: 'REQUIRED_AUTO_VERIFICATION',
};
