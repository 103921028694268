import { CLOSE_MODAL } from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { createRequest, createOutRequest } from '../user/actions';
import { showErrorModal } from '../history/actions';
import { PERIOD_DAYS_NUMBER } from '../../pages/PerfomanceDetails/consts';

import {
  SHOW_MODAL,
  GET_SUMMARY_SUCCESS,
  GET_PRESENT_SUCCESS,
  GET_SECURITIES_PRICES_SUCCESS,
  GET_ALLOCATION_PROFILES_SUCCESS,
  GET_SECURITIES_FUNDAMENTALS_SUCCESS,
  GET_MARKET_STATUS_SUCCESS,
  PERFOMANCE_UNMOUNT,
  SHOW_ERROR_MARKET_STATUS,
  GET_SECURITIES_LOADING,
} from './types';
import getSecuritiesRequestParams from './utils/getSecuritiesRequestParams';

import { api } from 'services/api';

export function getSummarySuccess(summary, isDashboard) {
  return { type: GET_SUMMARY_SUCCESS, summary, isDashboard };
}

export function getPresentSuccess(present) {
  return { type: GET_PRESENT_SUCCESS, present };
}

export function getAllocationProfilesSuccess(allocationProfiles) {
  return { type: GET_ALLOCATION_PROFILES_SUCCESS, allocationProfiles };
}

export function showErrorMarketStatus(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_MARKET_STATUS, errorMessage, errorTitle };
}

export function getMarketStatusSuccess(status) {
  return { type: GET_MARKET_STATUS_SUCCESS, status };
}

export function getSecuritiesPricesSuccess({ securitiesPrices, numberOfDays }) {
  return {
    type: GET_SECURITIES_PRICES_SUCCESS,
    securitiesPrices,
    numberOfDays,
  };
}

export function getSecuritiesFundamentalsSuccess(securitiesFundamentals) {
  return { type: GET_SECURITIES_FUNDAMENTALS_SUCCESS, securitiesFundamentals };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function showModal() {
  return { type: SHOW_MODAL };
}

export function perfomanceUnmount() {
  return { type: PERFOMANCE_UNMOUNT };
}

export function getSecuritiesLoading() {
  return { type: GET_SECURITIES_LOADING };
}

export function getSummary(period, isSuper, isDashboard) {
  let superParams = '';
  let params = `summary?period_days_requested=${period}`;

  if (period === '1') {
    params = 'present?max_points=100';
  }

  if (period === 'all') {
    params = 'summary?all_data=1';
  }

  if (isSuper) {
    superParams = 'super_annuation/user/';
  }

  return (dispatch) =>
    createRequest(null, 'GET', `${superParams}investments/${params}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else if (period === '1') {
          dispatch(getPresentSuccess(response));
        } else {
          dispatch(getSummarySuccess(response, isDashboard));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSummaryNew({
  numberOfDays,
  isSuper,
  isDashboard,
  isEditKids,
  dependentId,
}) {
  let apiVersion = process.env.REACT_APP_API_VERSION_V2;
  let url = `${process.env.REACT_APP_API_URL}/${apiVersion}/investments/data/summary`;

  if (isSuper) {
    apiVersion = process.env.REACT_APP_API_VERSION;

    url = `${process.env.REACT_APP_API_URL}/super_annuation/${apiVersion}/investments/summary`;
  } else if (isEditKids) {
    apiVersion = process.env.REACT_APP_API_VERSION;

    url = `${process.env.REACT_APP_API_URL}/dependency_users/${apiVersion}/investments/summary`;
  }

  let params = isEditKids
    ? `?requested_days=${numberOfDays}&dependent_user_id=${dependentId}`
    : `?requested_days=${numberOfDays}`;

  if (numberOfDays === PERIOD_DAYS_NUMBER.oneDay) {
    params = isEditKids
      ? `present?max_points=100&dependent_user_id=${dependentId}`
      : 'present?max_points=100';

    if (isSuper) {
      url = `${process.env.REACT_APP_API_URL}/${apiVersion}/super_annuation/user/investments/present`;

      params = '';
    } else {
      url = isEditKids
        ? `${process.env.REACT_APP_API_URL}/dependency_users/${process.env.REACT_APP_API_VERSION}/investments`
        : `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/investments`;
    }
  }

  if (!numberOfDays) {
    params = isEditKids ? `?dependent_user_id=${dependentId}` : '';
  }

  return (dispatch) =>
    createOutRequest(null, 'GET', `${url}/${params}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else if (numberOfDays === PERIOD_DAYS_NUMBER.oneDay) {
          dispatch(getPresentSuccess(response));
        } else {
          dispatch(getSummarySuccess(response, isDashboard));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAllocationProfiles(
  isSuper = false,
  isEditKids = false,
  childId,
) {
  let params = 'allocation_profiles';
  let url = '';

  if (isSuper) {
    params = 'super_annuation/user/allocation_profiles';
  } else if (isEditKids) {
    params = `allocation_profiles?dependent_user_id=${childId}`;

    url = `${process.env.REACT_APP_API_URL}/dependency_users/v1/`;
  }

  if (isEditKids) {
    return (dispatch) =>
      createOutRequest(null, 'GET', `${url}/${params}`)
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            dispatch(showErrorModal(response.error));
          } else {
            dispatch(getAllocationProfilesSuccess(response));
          }
        })
        .catch((error) => dispatch(showGlobalError(error)));
  }

  return (dispatch) =>
    createRequest(null, 'GET', params)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorModal(response.error));
        } else {
          dispatch(getAllocationProfilesSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getMarketStatus() {
  return (dispatch) =>
    createRequest(null, 'GET', '/market/status')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorMarketStatus(response.error));
        } else {
          dispatch(getMarketStatusSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getFundamentals({ securitiesId }) {
  return async (dispatch) => {
    try {
      const response = await api.get(`securities/${securitiesId}`);

      if (response.error) {
        dispatch(showErrorModal(response.error));
      }

      dispatch(getSecuritiesFundamentalsSuccess(response?.fundamentals));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getSecurities({ securitiesId, numberOfDays, maxPoints }) {
  const url = `securities/${securitiesId}/prices?${getSecuritiesRequestParams(
    numberOfDays,
    maxPoints,
  )}`;

  return async (dispatch) => {
    try {
      dispatch(getSecuritiesLoading());

      const response = await api.get(url);

      if (response.error) {
        dispatch(showErrorModal(response.error));
      } else {
        dispatch(
          getSecuritiesPricesSuccess({
            securitiesPrices: response,
            numberOfDays,
          }),
        );

        dispatch(getFundamentals({ securitiesId }));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}
