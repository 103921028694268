import { PORTFOLIO_CUSTOMIZATION_TABS } from '../../consts';
import { usePortfolioCustomizationRoute } from '../../hooks';

import PortfolioCustomizationTabButton from './PortfolioCustomizationTabButton';
import { CustomizationTabsWrapper } from './styles';

export default function PortfolioCustomizationTabs() {
  const { isCustomizationTabsVisible } = usePortfolioCustomizationRoute();

  if (isCustomizationTabsVisible) {
    return null;
  }

  return (
    <CustomizationTabsWrapper>
      {Object.keys(PORTFOLIO_CUSTOMIZATION_TABS).map((tab) => (
        <PortfolioCustomizationTabButton key={tab} tab={tab} />
      ))}
    </CustomizationTabsWrapper>
  );
}
