import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'react-dropzone-uploader/dist/styles.css';

import {
  DOCUMENT_TYPES,
  REJECTED_FILE_TYPE,
  REJECTED_MAX_FILES,
  REMOVED,
} from '../../../constants/comonConstants';
import { MY_APP } from '../../../constants/localeConfigs';

import UploadIdSuccessModal from './UploadIdSuccessModal/UploadIdSuccessModal';
import { IdDocumentUploader } from './UploadId/components/IdDocumentUploader';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { KIDS_STATUS } from 'constants/kidsConstants';
import { REGISTRATION_PERSONAL_INFO } from 'store/commonTypes';
import { selectDependentUserAccess } from 'store/user/selectors';
import { setCurrentRegistrationStep } from 'store/user/actions';

function UploadID({
  showLoader,
  submitDocument,
  errorMessageKadBack,
  errorMessageKadFront,
  errorMessageKadSelfie,
  isShowMyKadModal,
  goToAccountFunding,
  updateErrorsBack,
  updateErrorsFront,
  updateErrorsSelfie,
}) {
  const dispatch = useAppDispatch();
  const dependentUserAccess = useAppSelector(selectDependentUserAccess);
  const dependentUserAccessStatus = dependentUserAccess?.status;

  const { t } = useTranslation();

  const [documentFront, setDocumentFront] = useState({});
  const [documentBack, setDocumentBack] = useState({});
  const [selfiePhoto, setSelfiePhoto] = useState({});
  const [document, setDocument] = useState({});

  useEffect(() => {
    updateErrorsFront(false);

    updateErrorsBack(false);

    updateErrorsSelfie(false);
  }, [updateErrorsFront, updateErrorsBack, updateErrorsSelfie]);

  const handleChangeStatus = ({ file, status, side }) => {
    if (status !== REJECTED_MAX_FILES) {
      const updatedDocumentFile = [REMOVED, REJECTED_FILE_TYPE].includes(status)
        ? {}
        : file;

      switch (side) {
        case DOCUMENT_TYPES.myKadFront:
          updateErrorsFront(false);

          setDocumentFront(updatedDocumentFile);

          break;
        case DOCUMENT_TYPES.myKadBack:
          updateErrorsBack(false);

          setDocumentBack(updatedDocumentFile);

          break;
        case DOCUMENT_TYPES.idDocument:
          setDocument(updatedDocumentFile);

          break;
        case DOCUMENT_TYPES.selfiePhoto:
          updateErrorsSelfie(false);

          setSelfiePhoto(updatedDocumentFile);

          break;
        default:
          break;
      }
    }
  };

  const handleUploadIdSuccessModalConfirmation = useCallback(() => {
    if (
      MY_APP &&
      dependentUserAccessStatus === KIDS_STATUS.pending_converting
    ) {
      dispatch(setCurrentRegistrationStep(REGISTRATION_PERSONAL_INFO));
    } else {
      goToAccountFunding();
    }
  }, [dependentUserAccessStatus, dispatch, goToAccountFunding]);

  return (
    <form className="form">
      <div className="content-fill">
        <h1>{t('registrationPage.uploadID.header')}</h1>
      </div>

      <div className="registration-page__dropzone-container">
        <div className="registration-page__dropzone-col">
          <IdDocumentUploader
            type={DOCUMENT_TYPES.myKadFront}
            handleChangeStatus={handleChangeStatus}
          />

          {errorMessageKadFront && (
            <div className="error-message">
              {t('fields.validation.required')}
            </div>
          )}

          {MY_APP && (
            <IdDocumentUploader
              type={DOCUMENT_TYPES.myKadBack}
              handleChangeStatus={handleChangeStatus}
            />
          )}

          {errorMessageKadBack && (
            <div className="error-message">
              {t('fields.validation.required')}
            </div>
          )}

          {MY_APP && (
            <IdDocumentUploader
              type={DOCUMENT_TYPES.selfiePhoto}
              handleChangeStatus={handleChangeStatus}
            />
          )}

          {errorMessageKadSelfie && (
            <div className="error-message">
              {t('fields.validation.required')}
            </div>
          )}
        </div>

        <div className="registration-page__dropzone-col -limit-size">
          <div className="registration-page__dropzone-info-title">
            {t('registrationPage.uploadID.myKad.uploadChecklist.title')}
          </div>

          <div className="registration-page__dropzone-info-sub">
            {t('registrationPage.uploadID.myKad.uploadChecklist.description')}
          </div>

          <ul className="registration-page__dropzone-info-list">
            <li>
              {t('registrationPage.uploadID.myKad.uploadChecklist.notBlurry')}
            </li>

            <li>
              {t(
                'registrationPage.uploadID.myKad.uploadChecklist.visibleCorners',
              )}
            </li>

            <li>
              {t(
                'registrationPage.uploadID.myKad.uploadChecklist.notManipulated',
              )}
            </li>
          </ul>
        </div>
      </div>

      {!showLoader && isShowMyKadModal && (
        <UploadIdSuccessModal
          onClickSuccessModal={handleUploadIdSuccessModalConfirmation}
        />
      )}

      <div className="buttons-part">
        <div className="buttons-part">
          <div className="form-field">
            <DeprecatedButton
              withLoader={showLoader}
              title={t('common.next')}
              buttonClass="button button--submit"
              handleClick={() =>
                submitDocument({
                  documentFront,
                  documentBack,
                  selfiePhoto,
                  document,
                })
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default UploadID;

UploadID.propTypes = {
  showLoader: PropTypes.bool,
  errorMessageKadBack: PropTypes.bool.isRequired,
  errorMessageKadFront: PropTypes.bool.isRequired,
  errorMessageKadSelfie: PropTypes.bool.isRequired,
  submitDocument: PropTypes.func.isRequired,
  updateErrorsFront: PropTypes.func.isRequired,
  updateErrorsBack: PropTypes.func.isRequired,
  updateErrorsSelfie: PropTypes.func.isRequired,
  goToAccountFunding: PropTypes.func.isRequired,
  isShowMyKadModal: PropTypes.bool,
};

UploadID.defaultProps = {
  showLoader: false,
  isShowMyKadModal: false,
};
