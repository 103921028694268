import styled from '@emotion/styled';
import { FunctionComponent, SVGAttributes } from 'react';

import { iconset, IIconSet } from './iconset';

interface IIconProps {
  size?: number;
  iconName?: IIconSet;
  icon?: FunctionComponent<SVGAttributes<SVGElement>>;
}

const DEFAULT_ICONS_SIZE = 32;

export const Icon = styled.div<IIconProps>`
  background: url(${({ icon, iconName }) =>
      `${iconName ? iconset[iconName] : icon}`})
    no-repeat center center;
  background-size: contain;
  display: inline-block;
  height: ${({ size = DEFAULT_ICONS_SIZE }) => size}px;
  width: ${({ size = DEFAULT_ICONS_SIZE }) => size}px;
`;
