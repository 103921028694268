import { useMatch, useNavigate } from 'react-router';

import { PATHS } from 'constants/paths';
import { setCurrentRegistrationStep } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { REGISTRATION_ACCOUNT_PORTFOLIO } from 'store/commonTypes';

export const useRegistrationFinancialPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isRegistrationFinancialPage = !!useMatch(
    PATHS.registration.account.other,
  );

  const navigateToNextStep = () => {
    dispatch(setCurrentRegistrationStep(REGISTRATION_ACCOUNT_PORTFOLIO));

    navigate(REGISTRATION_ACCOUNT_PORTFOLIO);
  };

  return { isRegistrationFinancialPage, navigateToNextStep };
};
