import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import SelectReact from 'react-dropdown-select';

/**
 * The change in react-dropdown-select responsible for the case is
 * https://github.com/sanusart/react-dropdown-select/commit/86c716ab6f47882755024d436136281f2ed79f97
 * which make the default value not correctly set
 */
const SelectComponent = ({
  input,
  meta: { touched, error },
  onChange,
  onDropdownOpen,
  options,
  readOnly,
  disabled,
  ...props
}) => {
  const handleChange = useCallback(
    (value) => {
      if (disabled) return;

      onChange(value);

      input.onChange(value);
    },
    [disabled, input, onChange],
  );

  const className = useMemo(() => {
    if (disabled) return `${props.className} disabled`;

    return props.className;
  }, [disabled, props.className]);

  return (
    <div className="relative">
      <SelectReact
        {...props}
        values={input.value}
        searchable={false}
        options={options}
        onChange={handleChange}
        onDropdownOpen={onDropdownOpen}
        disabled={disabled || readOnly}
        className={className}
      />

      {touched && error && <div className="-error">{touched && error}</div>}
    </div>
  );
};

SelectComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.string })
    .isRequired,
  onChange: PropTypes.func.isRequired,
  onDropdownOpen: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

/**
 * SelectField
 * this component use final form value as it source of value instead of passing and mapping value manually
 * so the component behavior is consistent.
 */
export default function SelectField({
  className,
  clearable,
  defaultValue,
  labelField,
  name,
  onChange,
  onDropdownOpen,
  options,
  placeholder,
  valueField,
  disabled,
}) {
  return (
    <Field
      className={className}
      clearable={clearable}
      component={SelectComponent}
      defaultValue={defaultValue}
      labelField={labelField}
      name={name}
      onChange={onChange}
      onDropdownOpen={onDropdownOpen}
      options={options}
      placeholder={placeholder}
      valueField={valueField}
      disabled={disabled}
    />
  );
}

SelectField.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.shape({})),
  labelField: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDropdownOpen: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  valueField: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  className: 'dropdown__basic',
  clearable: false,
  defaultValue: null,
  labelField: 'label',
  onChange: () => {},
  onDropdownOpen: () => {},
  placeholder: '',
  valueField: 'value',
  disabled: false,
};
