import { ThemeProvider } from '@emotion/react';

import { TooltipWrapper } from './styles';

import { MAXIMUM_FRACTION_DIGITS } from 'utils/formatters/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import theme from 'theme';

interface ITooltipProps {
  amount: number;
  date: number;
  isEtf: boolean;
  isOneDayChart: boolean;
}

export const Tooltip = ({
  amount,
  date,
  isEtf,
  isOneDayChart,
}: ITooltipProps) => (
  <ThemeProvider theme={theme}>
    <TooltipWrapper>
      {isEtf && !isOneDayChart && (
        <div>
          {formatNumber({
            value: amount,
            minimumFractionDigits: MAXIMUM_FRACTION_DIGITS,
            maximumFractionDigits: 4,
          })}
        </div>
      )}

      <div>{date}</div>
    </TooltipWrapper>
  </ThemeProvider>
);
