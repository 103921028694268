import { ALERT_TYPES } from 'constants/alerts';
import { DOCUMENT_TYPES } from 'constants/comonConstants';

export const getTitleFromDocumentType = ({
  documentType,
  uploadErrorType,
  t,
}) => {
  switch (documentType || uploadErrorType) {
    case DOCUMENT_TYPES.myKadBack:
      return t('registrationPage.uploadID.myKad.back');
    case DOCUMENT_TYPES.myKadFront:
      return t('registrationPage.uploadID.myKad.front');
    case DOCUMENT_TYPES.idDocument:
      return t('registrationPage.uploadID.governmentId');
    case DOCUMENT_TYPES.selfiePhoto:
      return t('registrationPage.uploadID.selfie');
    case DOCUMENT_TYPES.idFront:
      return t('registrationPage.uploadID.front');
    case DOCUMENT_TYPES.idBack:
      return t('registrationPage.uploadID.back');
    case ALERT_TYPES.idUploadErrors.ktpUploadMissing:
      return t('registrationPage.uploadID.governmentId');
    case ALERT_TYPES.idUploadErrors.bankStatementUploadMissing:
      return t('alertsPage.uploadId.bankStatements');
    case ALERT_TYPES.idUploadErrors.additionalUploadMissing:
      return t('alertsPage.uploadId.additionalDocuments');
    default:
      return '';
  }
};
