import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';
import { formatNumber } from 'utils/formatters/formatNumber';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function CloseAccountKidsModal({
  toggleCloseAccount,
  child,
  closeAccount,
  showLoader,
}) {
  const { t } = useTranslation();

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(new Date(child.date_of_birth));

  return (
    <Modal
      isOpen
      onRequestClose={() => toggleCloseAccount(false)}
      contentLabel="kids-modal"
      shouldCloseOnOverlayClick
      className="modal-basic raiz-kids__link-sent"
    >
      <img
        src={child.icon_url}
        alt=""
        width="52"
        className="raiz-kids__card-img"
      />

      <div className="raiz-kids__menu-name">
        {t('raizKidsEditPage.age', { name: child.name, count: age })}
      </div>

      <div className="raiz-kids__menu-value">
        {formatNumber({
          value: child.current_amount,
        })}
      </div>

      <div className="raiz-kids__link-sent-info">
        <Trans
          t={t}
          i18nKey="raizKidsEditPage.closeKidsAccountModal.description"
        />
      </div>

      <DeprecatedButton
        buttonClass="button__close -black"
        handleClick={() => toggleCloseAccount(false)}
      />

      <div className="raiz-kids__link-sent-footer">
        <Button
          isLoading={showLoader}
          label={t('raizKidsEditPage.closeKidsAccountModal.submitButton')}
          onClick={() => closeAccount(child.id)}
        />

        <DeprecatedButton
          title={t('common.cancel')}
          buttonClass="button__basic-wtbackground -black"
          handleClick={() => toggleCloseAccount(false)}
        />
      </div>
    </Modal>
  );
}

CloseAccountKidsModal.propTypes = {
  toggleCloseAccount: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  child: PropTypes.shape({
    icon_url: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    date_of_birth: PropTypes.string,
    current_amount: PropTypes.string,
  }).isRequired,
};
