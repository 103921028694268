import { useTranslation } from 'react-i18next';

import {
  PortfolioBackButtonIcon,
  PortfolioBackButtonTitle,
  PortfolioBackButtonWrapper,
} from './styles';
import { usePortfolioBackButtonHandler } from './hooks';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioLocation } from 'store/portfolio/selectors';

export default function PortfolioBackButton() {
  const { t } = useTranslation();
  const { handleGoBack } = usePortfolioBackButtonHandler();

  const { isRegistration, isChangeBasePortfolioPage } = useAppSelector(
    selectPortfolioLocation,
  );

  const isRegistrationBasePortfolio =
    isRegistration && isChangeBasePortfolioPage;

  // TODO: https://acornsau.atlassian.net/browse/RAIZ-5680
  return (
    <PortfolioBackButtonWrapper onClick={handleGoBack} className="-exclude">
      <PortfolioBackButtonIcon
        isRegistrationBasePortfolio={isRegistrationBasePortfolio}
      />

      <PortfolioBackButtonTitle
        isRegistrationBasePortfolio={isRegistrationBasePortfolio}
      >
        {t('common.back')}
      </PortfolioBackButtonTitle>
    </PortfolioBackButtonWrapper>
  );
}
