import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioParams } from 'store/portfolio/selectors';
import { getSuperUserData, getUserData } from 'store/user/actions';
import { getDependencyUser } from 'store/dependentUser/actions';

const usePortfolioUserData = ({ onSuccess, isEditKids, isRegistration }) => {
  const dispatch = useDispatch();
  const { isSuper } = useAppSelector(selectPortfolioParams);

  const { userAllocationProfileId, child } = useSelector((state) => ({
    userAllocationProfileId: state.user.user.allocation_profile_id,
    child: state.dependencyUsers.child,
  }));

  const [isFetched, setIsFetched] = useState(false);

  const fetchPortfolioUserData = useCallback(async () => {
    if (!isEditKids || isRegistration) {
      if (isSuper) {
        await dispatch(getSuperUserData());
      } else {
        await dispatch(getUserData());
      }

      onSuccess(userAllocationProfileId);
    }

    if (isEditKids && !isRegistration) {
      await dispatch(getDependencyUser());

      const profileId =
        child?.dependency_user?.portfolio?.id || userAllocationProfileId;

      onSuccess(profileId);
    }
  }, [
    child,
    dispatch,
    isEditKids,
    isRegistration,
    onSuccess,
    userAllocationProfileId,
    isSuper,
  ]);

  useEffect(() => {
    if (isFetched) return;

    fetchPortfolioUserData();

    setIsFetched(true);
  }, [fetchPortfolioUserData, isFetched]);
};

export default usePortfolioUserData;
