import { useFormContext } from 'react-hook-form';

import { IUseDeleteInvestmentGoal } from './types';
import { VIEW_MODE } from './consts';
import { useNavigateToRecurringInvestment } from './hooks/useNavigateToRecurringInvestment';
import { useRecurringInvestmentForm } from './useRecurringInvestmentForm';

import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectChild,
  selectDependencyUsersLoading,
  selectInvestmentGoal,
} from 'store/dependentUser/selectors';
import { stopRecurringInvestment } from 'store/dependentUser/actions';
import { useDeleteInvestmentGoalMutation } from 'store/dependentUser/api';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const useDeleteInvestmentGoal = ({
  investmentGoalKey,
}: IUseDeleteInvestmentGoal) => {
  const { id } = useAppSelector(selectChild);
  const [deleteGoal, { isLoading }] = useDeleteInvestmentGoalMutation();

  const handleDeleteInvestmentGoal = async () => {
    if (!investmentGoalKey) {
      return;
    }

    const deleteGoalData = {
      dependent_user_id: id,
      additional_params: {
        key: investmentGoalKey,
      },
    };

    await deleteGoal(deleteGoalData);
  };

  return { handleDeleteInvestmentGoal, isDeleteGoalLoading: isLoading };
};

export const useStopRecurringInvestment = () => {
  const dispatch = useAppDispatch();
  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const investmentGoalKey = investmentGoal?.key;

  const { handleDeleteInvestmentGoal } = useDeleteInvestmentGoal({
    investmentGoalKey,
  });

  const { setCurrentFrequency } = useRecurringInvestmentForm();
  const { reset, watch } = useFormContext() || {};
  const { id } = useAppSelector(selectChild);
  const isDependencyUsersLoading = useAppSelector(selectDependencyUsersLoading);
  const navigateToRecurringInvestment = useNavigateToRecurringInvestment();

  const isDeleteRecurringInvestmentsSelected = watch?.(
    'isDeleteRecurringInvestments',
  );

  const handleStopRecurringInvestment = () => {
    dispatch(stopRecurringInvestment(id)).then((isInvestmentStopped) => {
      if (isInvestmentStopped) {
        reset({ recurringValue: 0 });

        setCurrentFrequency(null);

        if (isDeleteRecurringInvestmentsSelected && investmentGoalKey) {
          handleDeleteInvestmentGoal();
        }

        navigateToRecurringInvestment({ viewMode: VIEW_MODE.edit });
      }
    });
  };

  return {
    handleStopRecurringInvestment,
    isDependencyUsersLoading,
  };
};

export const useInvestmentGoalProgress = () => {
  const { current_balance } = useAppSelector(selectChild);

  const investmentGoal = useAppSelector(selectInvestmentGoal);

  const { target_amount } = investmentGoal || {};

  return target_amount && current_balance
    ? Math.round((current_balance * 100) / target_amount)
    : 0;
};
