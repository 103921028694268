import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IChartDataYears } from './type';

const useSeries = (chartDataYears: IChartDataYears) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      chartDataYears?.Mean?.length
        ? [
            {
              name: 'LB99',
              data: chartDataYears.LB99,
            },
            {
              name: 'LB95',
              data: chartDataYears.LB95,
            },
            {
              name: 'LB68',
              data: chartDataYears.LB68,
            },
            {
              name: t('changePortfolioPage.projected'),
              data: chartDataYears.Mean,
              position: 'front',
              tooltip: {
                shared: false,
                position: 'front',
                intersect: true,
              },
            },
            {
              name: 'UB68',
              data: chartDataYears.UB68,
            },
            {
              name: 'UB95',
              data: chartDataYears.UB95,
            },
            {
              name: 'UB99',
              data: chartDataYears.UB99,
            },
          ].reverse()
        : [],
    [chartDataYears, t],
  );
};

export default useSeries;
