import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import Skeleton from 'react-skeleton-loader';

export default function HistoryLoader({ isSuper }) {
  return (
    <div className="history__element">
      <div className="history__left-part">
        {[...Array(10)].map((e, index) => (
          <div key={`${index * 2}`}>
            <div className="history__item-date">
              <Skeleton />
            </div>

            <div className="history__item">
              <div className="history__item__content">
                <div className="history__item-skeleton">
                  <Skeleton />
                </div>

                <div className="history__item-info">
                  <div className="history__item-type">
                    <Skeleton />
                  </div>

                  <div className="history__item-processing">
                    <Skeleton width="75px" />

                    <div className="history__item-processing-status">
                      <Skeleton />
                    </div>
                  </div>
                </div>

                <div className="history__item-skeleton-right">
                  <Skeleton width="75px" height="25px" />

                  {!isSuper && <Skeleton width="75px" height="25px" />}

                  {!isSuper && <Skeleton width="15px" height="25px" />}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

HistoryLoader.defaultProps = {
  isSuper: false,
};

HistoryLoader.propTypes = {
  isSuper: PropTypes.bool,
};
