import { PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS } from '../subpages/PortfolioCustomization/consts';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioLocation } from 'store/portfolio/selectors';
import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import { PATHS } from 'constants/paths';

export const usePortfolioNavigationParams = () => {
  const { isRegistration, isKids } = useAppSelector(selectPortfolioLocation);

  const isSuperRegistration = useIsSuperRegistration();

  const customizationTagsPath = PATHS.portfolio.customization.tags;

  const registrationCustomizationTags = isSuperRegistration
    ? customizationTagsPath
    : PATHS.registration.customization.tags;

  const registrationCustomizationTagsPath = isKids
    ? PATHS.kids.registration.customization.tags
    : registrationCustomizationTags;

  const pathParams = isKids
    ? PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS.kids
    : PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS.regularUser;

  if (isRegistration) {
    return {
      customizationTagsPath: registrationCustomizationTagsPath,
      pathParams,
    };
  }

  return {
    customizationTagsPath,
    pathParams,
  };
};
