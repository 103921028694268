import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';

import { ICloseAccountData, IFormValue, IReason } from '../../types';
import { ProfileField } from '../ProfileField';

import { useRequestFormSchema } from './useRequestFormSchema';
import { FormStyled, SubmitButton } from './styles';

import { MY_APP } from 'constants/localeConfigs';
import { closeAccount } from 'store/user/actions';
import { selectCloseAccountData } from 'store/user/selectors';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { SelectField } from 'components/form/select';
import { InputField } from 'components/form/input';
import { toggleInfoModal } from 'store/modals/actions';

const emptyReasonOption: IReason = {
  value: '',
  label: '-',
  options: [],
  placeholder: '',
};

interface IRequestFormProps {
  closeRequestModal: () => void;
}

export const RequestForm = ({ closeRequestModal }: IRequestFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { userName, userEmail, isCloseAccountLoading, questions } =
    useAppSelector<ICloseAccountData>(selectCloseAccountData);

  const onAcceptCloseAccount = ({
    primaryReason,
    secondaryReason,
    answer,
    comment,
  }: IFormValue) => {
    dispatch(
      closeAccount({
        primaryReason: primaryReason?.[0]?.value || 0,
        secondaryReason: secondaryReason?.[0]?.value || 0,
        answer,
        comment,
      }),
    );
  };

  const handleCloseAccount = ({
    primaryReason,
    secondaryReason,
    answer,
    comment,
  }: IFormValue) => {
    if (MY_APP) {
      onAcceptCloseAccount({
        primaryReason,
        secondaryReason,
        answer,
        comment,
      });

      return;
    }

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('closeAccountModal.confirmModal.title'),
          description: t('closeAccountModal.confirmModal.description'),
          acceptButtonText: t('closeAccountModal.confirmModal.button.submit'),
          rejectButtonText: t('closeAccountModal.confirmModal.button.cancel'),
          onAccept: () =>
            onAcceptCloseAccount({
              primaryReason,
              secondaryReason,
              answer,
              comment,
            }),
          onReject: closeRequestModal,
        },
      }),
    );
  };

  const schema = useRequestFormSchema();

  const form = useForm<IFormValue>({
    defaultValues: {
      primaryReason: [emptyReasonOption],
      secondaryReason: [emptyReasonOption],
      answer: '',
      comment: '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
  } = form;

  const onSubmit = handleSubmit(handleCloseAccount);

  const primaryOptions = [emptyReasonOption, ...questions];

  const secondaryOptions = watch('primaryReason', [emptyReasonOption]);

  const secondaryValues = watch('secondaryReason', [emptyReasonOption]);

  const answerPlaceholder = secondaryValues[0].placeholder;

  const secondaryValue = secondaryOptions[0].value;

  useEffect(() => {
    if (secondaryValue) {
      setValue('secondaryReason', [emptyReasonOption]);
    }
  }, [setValue, secondaryValue]);

  return (
    <FormProvider {...form}>
      <FormStyled onSubmit={onSubmit}>
        <ProfileField value={userName} label={t('fields.name.label')} />

        <ProfileField value={userEmail} label={t('fields.email.label')} />

        <SelectField
          name="primaryReason"
          options={primaryOptions}
          label={t('closeAccountModal.fields.closingReason.placeholder')}
        />

        <SelectField
          name="secondaryReason"
          options={[emptyReasonOption, ...secondaryOptions[0].options]}
          label={t('closeAccountModal.choseSecondary')}
        />

        {answerPlaceholder && (
          <InputField name="answer" label={answerPlaceholder} />
        )}

        <InputField
          name="comment"
          label={t('closeAccountModal.fields.additionalComment.placeholder')}
        />

        <SubmitButton
          backgroundColor="grenadier"
          type="submit"
          isLoading={isCloseAccountLoading}
          label={t('closeAccountModal.closeMyAccount')}
          isDisabled={!isValid}
        />
      </FormStyled>
    </FormProvider>
  );
};
