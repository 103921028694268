import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import FinalFormInput from 'components/elements/FinalFormInput/FinalFormInput';

export const PortfolioThemeEditTitle = styled.div`
  margin-top: 10px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
`;

export const PortfolioThemeEditButtons = styled.div`
  margin-top: 14px;
`;

export const PortfolioThemeEditSubmitButton = styled(Button)`
  background: ${({ theme }) => theme.colors.whiteTransparent};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  color: ${({ theme }) => theme.colors.white};
`;

export const PortfolioThemeEditCancelButton = styled(Button)`
  background: unset;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  color: ${({ theme }) => theme.colors.white};
  margin-top: 10px;
`;

export const PortfolioThemeEditCloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: unset;
`;

export const TextField = styled(FinalFormInput)`
  &.MuiFormControl-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
  }

  & .MuiInput-formControl {
    margin-top: 32px;
    color: ${({ theme }) => theme.colors.white};
  }

  & .MuiInputLabel-formControl {
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.white} !important;
  }

  & .MuiInputLabel-shrink {
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  }
`;

export const PortfolioThemeEditColorWrapper = styled.div`
  margin-top: 24px;
`;

export const PortfolioThemeEditGradientTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

export const PortfolioThemeEditColorContent = styled.div`
  background: ${({ theme }) => theme.colors.whiteTransparentDark};
  border-radius: 12px;
  padding: 16px 40px;
  display: grid;
  grid-template-columns: repeat(5, 62px);
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 16px 14px;
  }
`;

export const PortfolioThemeEditColorItemWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: unset;
  background-color: unset;
  height: unset;
  border: unset;
  padding: 6px;
`;

export const PortfolioThemeEditColorItemContent = styled.div`
  width: 49px;
  height: 49px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  background: linear-gradient(${({ gradient }) => gradient});
`;

export const PortfolioThemeEditColorItemSelectedWrapper = styled.div`
  width: 49px;
  height: 49px;
  background: ${({ theme, gradient }) => `linear-gradient(
    ${theme.colors.darkGrey},
    ${theme.colors.darkGrey}
  ) padding-box,  linear-gradient(${gradient}) border-box;`}
  border-radius: 50%;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PortfolioThemeEditColorItemSelectedContent = styled.div`
  width: 30px;
  height: 30px;
  background: linear-gradient(${({ gradient }) => gradient});
  border-radius: 50%;
`;
