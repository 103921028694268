import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { IUseStatementsSelectionProps } from '../types';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import { KIDS_TRADE_CONFIRMATION } from 'constants/kidsConstants';

export const useStatementsSelection = ({
  currentType,
}: IUseStatementsSelectionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isCheckboxesVisible, setIsCheckboxesVisible] = useState(false);
  const [selectedStatements, setSelectedStatements] = useState<string[]>([]);

  const onStatementSelect = ({ id }: { id: string }) => {
    if (selectedStatements.includes(id)) {
      setSelectedStatements(
        selectedStatements.filter((selectedId) => selectedId !== id),
      );
    } else {
      setSelectedStatements([...selectedStatements, id]);
    }
  };

  const toggleSelectStatements = () => {
    setSelectedStatements([]);

    setIsCheckboxesVisible(!isCheckboxesVisible);
  };

  const onSendStatementsSuccess = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t(
            `statements.sendStatementsSuccessModal.title.${
              currentType === KIDS_TRADE_CONFIRMATION
                ? 'tradeConfirmation'
                : 'statement'
            }`,
          ),
        },
      }),
    );

    setSelectedStatements([]);

    setIsCheckboxesVisible(false);
  };

  return {
    toggleSelectStatements,
    isCheckboxesVisible,
    onStatementSelect,
    selectedStatements,
    setSelectedStatements,
    onSendStatementsSuccess,
  };
};
