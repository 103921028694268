import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useSuperUserAccountsQuery,
  useSuperUserQuery,
} from 'store/superAnnuation/superAnnuation';

export const useSuperAccountInfoItems = () => {
  const { t } = useTranslation();

  const superUserQuery = useSuperUserQuery();
  const superUserAccounts = useSuperUserAccountsQuery();
  const { member_number } = superUserAccounts?.data || {};
  const { first_name, last_name, bpay } = superUserQuery?.data || {};

  const superInfoItems = useMemo(() => {
    const {
      raiz_name,
      raiz_usi,
      raiz_abn,
      raiz_address1,
      raiz_address2,
      raiz_address3,
    } = bpay || {};

    return [
      {
        label: t('superPage.superInfo.memberName'),
        value: t('superPage.superInfo.firstLastNameValue', {
          firstName: first_name,
          lastName: last_name,
        }),
      },
      {
        label: t('superPage.superInfo.membershipNumber'),
        value: member_number,
      },
      {
        label: t('superPage.superInfo.fundName'),
        value: raiz_name,
      },
      {
        label: t('superPage.superInfo.fundUSI'),
        value: raiz_usi,
      },
      {
        label: t('superPage.superInfo.fundABN'),
        value: raiz_abn,
      },
      {
        label: t('superPage.superInfo.fundAddress'),
        value: t('superPage.superInfo.fundAddressValue', {
          address1: raiz_address1,
          address2: raiz_address2,
          address3: raiz_address3,
        }),
      },
    ];
  }, [t, member_number, first_name, last_name, bpay]);

  return {
    superInfoItems,
  };
};
