import styled from '@emotion/styled';

import { Button } from 'components/elements/Button';
import VisaSvg from 'assets/images/icons/spending-visa.svg';

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  text-align: center;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-top: 12px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  text-align: center;
`;

export const Footer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-top: 15px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  text-align: center;
`;

export const Secured = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  gap: 10px;
  justify-content: center;
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  margin-top: 20px;
  text-align: center;
`;

export const SignInButton = styled(Button)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-top: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.niagaraHover};
  }
`;

export const VisaLogo = styled.div`
  align-self: flex-start;
  background-size: contain;
  background: url(${`${VisaSvg}`}) no-repeat left center;
  height: 20px;
  margin-top: 24px;
  padding: 0;
  width: 100%;
`;

export const InputMaskWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 32px;
`;
