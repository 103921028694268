import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BaseButton } from 'components/elements/Deprecated__Button';
import { Button } from 'components/elements/IconButton/styles';

const SEE_DETAILS_BUTTON_SIZE = 240;

const PROGRESS_SIZE = 760;

export const BannerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BannerContentRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
`;

export const BannerContentItemWrapper = styled.div`
  flex: 0.5;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
`;

export const BannerContentLabel = styled.div`
  ${({ theme }) => `
    opacity: ${theme.opacity.semiTransparent};
    font-size: ${theme.fontSizes['6sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length.md};
  `};
  text-align: center;
  padding-bottom: 6px;
`;

export const BannerContentValue = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes['5md']};
    line-height: ${theme.lineHeights.length.lg};
    font-weight: ${theme.fontWeights.semibold};
  `};
`;

export const IconCustomButton = styled(Button)`
  margin-left: 8px;
  vertical-align: sub;
`;

export const helpModalStyle = css`
  padding: 30px 24px 24px;
`;
export const HelpModalContentWrapper = styled.div`
  margin-bottom: 10px;
`;

export const HelpModalContentItemWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.athensGray};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  :nth-of-type(2n) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const HelpModalContentLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
`;

export const AccountValue = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes['4xl']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.wide};
    font-family: ${theme.fontFamily.regular};
  `};
  text-align: center;
`;

export const SeeDetailsButton = styled(BaseButton)`
  width: ${SEE_DETAILS_BUTTON_SIZE}px;
  margin-top: 14px;
  ${({ theme }) => `
    color: ${theme.colors.white};
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.white};
  `};
`;

export const HomeProgressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${PROGRESS_SIZE}px;
  margin: 28px auto 0;
  padding: 32px 0 10px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.blackTransparentMiddle};
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    margin: 28px 20px 0;
  }
`;
