import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import NumberFormat from 'react-number-format';

import {
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  THOUSAND_SEPARATOR,
} from '../../../constants/localeConfigs';
import Time from '../../../components/elements/Time/Time';

export default function RewardsHistoryAmount({ amount, createdAt }) {
  return (
    <div className="rewards__history-amount">
      <NumberFormat
        value={amount < 0 ? Math.abs(amount) : amount}
        displayType="text"
        thousandSeparator={THOUSAND_SEPARATOR}
        decimalSeparator={DECIMAL_SEPARATOR}
        fixedDecimalScale
        prefix={CURRENCY_SYMBOL}
        decimalScale={2}
        className={amount < 0 ? 'negative' : ''}
      />

      <div className="purchase">
        <Time format="DD MMMM YYYY">{createdAt}</Time>
      </div>
    </div>
  );
}

RewardsHistoryAmount.propTypes = {
  amount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
};
