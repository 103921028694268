import { defineMessages } from 'react-intl';

import { ROUND_UPS as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  page: {
    id: `${idPrefix}.page`,
    defaultMessage: 'Round-Ups',
  },
  investedFrom: {
    id: `${idPrefix}.investedFrom`,
    defaultMessage: 'Invested from round-ups',
  },
  enableAutoRound: {
    id: `${idPrefix}.enableAutoRound`,
    defaultMessage: 'Enable auto round-ups',
  },
  autoRound: {
    id: `${idPrefix}.autoRound`,
    defaultMessage: 'Auto round-ups',
  },
  manualRound: {
    id: `${idPrefix}.manualRound`,
    defaultMessage: 'Manual round-ups',
  },
  tapToInvest: {
    id: `${idPrefix}.tapToInvest`,
    defaultMessage: 'tap to invest',
  },
  all: {
    id: `${idPrefix}.all`,
    defaultMessage: 'All',
  },
  invested: {
    id: `${idPrefix}.invested`,
    defaultMessage: 'Invested',
  },
  invest: {
    id: `${idPrefix}.invest`,
    defaultMessage: 'Invest {value}',
  },
  available: {
    id: `${idPrefix}.available`,
    defaultMessage: 'Available',
  },
  cancel: {
    id: `${idPrefix}.cancel`,
    defaultMessage: 'Cancel',
  },
  investNow: {
    id: `${idPrefix}.investNow`,
    defaultMessage: 'Invest Now',
  },
  until: {
    id: `${idPrefix}.until`,
    defaultMessage: '{start} until {end}',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'Ok',
  },
  wantToInvestThreshold: {
    id: `${idPrefix}.wantToInvestThreshold`,
    defaultMessage:
      'Do you want to invest {waiting} now to reach the {invest} threshold?<br>' +
      'It may take 1-5 business days for the transfer to process. Generally, deposits are invested ' +
      'the next business day.',
  },
  helpInfo: {
    id: `${idPrefix}.helpInfo`,
    defaultMessage:
      'Kamu dapat langsung membulatkan saldo Round-Ups ke {automaticThreshold} untuk diinvestasikan. ' +
      'Saldo ' +
      'Round-Ups akan kembali lagi ke {waiting}',
  },
  notFoundAll: {
    id: `${idPrefix}.notFoundAll`,
    defaultMessage: 'You don`t have any spending yet.',
  },
  notFoundInvested: {
    id: `${idPrefix}.notFoundInvested`,
    defaultMessage: 'You have no approved spending.',
  },
  notFoundAvailable: {
    id: `${idPrefix}.notFoundAvailable`,
    defaultMessage: 'You have no new spending.',
  },
});

export default messages;
