import { useSelector } from 'react-redux';

import { usePortfolioFundsTags } from '../../../hooks';

import BaseFundTag from './BaseFundTag';
import FundsTagItem from './FundsTagItem';
import { TagsWrapper } from './styles';

import { selectIsProPortfolioLoading } from 'store/portfolio/selectors';
import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

export default function FundsTags() {
  const { fundsTags, isFundsTagLoading } = usePortfolioFundsTags();

  const isProPortfolioLoading = useSelector(selectIsProPortfolioLoading);

  if (isFundsTagLoading || isProPortfolioLoading) return <DotsLoader />;

  return (
    <TagsWrapper>
      <BaseFundTag />

      {fundsTags?.map((tag) => (
        <FundsTagItem key={tag.key} tag={tag} />
      ))}
    </TagsWrapper>
  );
}
