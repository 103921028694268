import styled from '@emotion/styled';
import Modal from 'react-modal';

export const ModalWrapper = styled(Modal)`
  width: 455px;
  min-height: auto;
  max-height: 80%;
  margin: 7% auto 0;
  padding: 22px 32px;
  border-radius: 6px;
  background: ${({ color, theme }) => color || theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  overflow: auto;
  position: relative;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
    padding: 22px 16px;
  }
`;
