export const FASTLINK_CDN_URL =
  'https://cdn.yodlee.com/fastlink/v4/initialize.js';

export const FASTLINK_FLOWS = {
  add: 'add',
  edit: 'edit',
  refresh: 'refresh',
  manageConsent: 'manageConsent',
} as const;

export const FASTLINK_CONTAINER_MAX_HEIGHT = 500;

export const FASTLINK_CONTAINER_ID = 'container-fastlink';

export const ACCOUNT_CONNECTION_INTERVAL = 3000;
