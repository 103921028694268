import styled from '@emotion/styled';
import Modal from 'react-modal';

export const RewardsErrorModalWrapper = styled(Modal)`
  background: ${({ theme }) => theme.colors.white};
  display: block;
  margin: 7% auto 0;
  border-radius: 6px;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  overflow: auto;
  max-height: 90%;
  padding: 10px;
  max-width: 300px;
  min-width: 300px;
  min-height: auto;
`;

export const RewardsErrorModalTitle = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.number['2xl']};
`;

export const RewardsErrorModalContent = styled.div`
  margin-top: 10px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamily.medium};
`;

export const RewardsErrorModalFooter = styled.div`
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
`;

export const RewardDetailsOfferStatusWrapper = styled.button`
  position: absolute;
  bottom: 5px;
  right: 0;
  height: unset;
  background: none;
`;

export const RewardSearchItem = styled.button`
  background-color: transparent;
  width: 100%;
  height: unset;
  border-radius: unset;
`;
