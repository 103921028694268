export const MIGRATION_SOURCES = {
  superEstate: 'superEstate',
  elevateMoney: 'elevate_money',
  employmentHero: 'employment_hero',
} as const;

export const MIGRATION_URL_PARAMS = {
  token: 'token',
  source: 'source',
} as const;

export const MIGRATION = {
  token: 'migrationToken',
  source: 'migrationSource',
};

export const MIGRATION_FLOW = {
  standard: 'standard',
  superEstate: 'superEstate',
} as const;
