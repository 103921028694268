import { useMatch, generatePath } from 'react-router';
import { useParams } from 'react-router-dom';

import { PATHS } from 'constants/paths';

export const useIsRecurringZakatPayment = () => {
  const { viewMode } = useParams();

  return !!useMatch(
    generatePath(PATHS.rewards.recurringZakatPayment, {
      viewMode: viewMode || null,
    }),
  );
};
