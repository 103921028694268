import React from 'react';

import { FundsWrapper } from '../styles';

import SkeletonItem from './SkeletonItem';

function FundsListSkeleton() {
  return (
    <FundsWrapper>
      {[...Array(8)].map((e, index) => (
        <SkeletonItem key={`${index * 2}`} index={index} />
      ))}
    </FundsWrapper>
  );
}

export default FundsListSkeleton;
