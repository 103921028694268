/* eslint-disable no-useless-concat */
import { defineMessages } from 'react-intl';

import { MODAL as idPrefix } from '../../../constants/translates';
import { CURRENCY_SYMBOL, MY_APP } from '../../../constants/localeConfigs';
import { PLATFORM_LINK_MY } from '../../../constants/comonConstants';

const auMessages = {
  welcome: {
    id: `${idPrefix}.welcome`,
    defaultMessage: 'welcome',
  },
  withdraw: {
    id: `${idPrefix}.withdraw`,
    defaultMessage: 'Withdraw',
  },
  cancel: {
    id: `${idPrefix}.cancel`,
    defaultMessage: 'Cancel',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'OK',
  },
  available: {
    id: `${idPrefix}.available`,
    defaultMessage: 'Available',
  },
  availableBalance: {
    id: `${idPrefix}.availableBalance`,
    defaultMessage: 'Available',
  },
  invest: {
    id: `${idPrefix}.invest`,
    defaultMessage: 'Invest',
  },
  shareInviteGet: {
    id: `${idPrefix}.shareInviteGet`,
    defaultMessage: 'Share Stories.<br>' + 'Invite Friends.<br>' + 'Get Cash.',
  },
  shareRaizStory: {
    id: `${idPrefix}.shareRaizStory`,
    defaultMessage:
      'Share the Raiz story to invite friends to join Raiz, and you’ll ' +
      'each get <span>{inviteAmount}</span> ' +
      'when your friend is approved.',
  },
  email: {
    id: `${idPrefix}.email`,
    defaultMessage: 'Email',
  },
  other: {
    id: `${idPrefix}.other`,
    defaultMessage: 'Other',
  },
  twitter: {
    id: `${idPrefix}.twitter`,
    defaultMessage: 'Twitter',
  },
  facebook: {
    id: `${idPrefix}.facebook`,
    defaultMessage: 'Facebook',
  },
  confirm: {
    id: `${idPrefix}.confirm`,
    defaultMessage: 'Confirm',
  },
  myFundingAccount: {
    id: `${idPrefix}.myFundingAccount`,
    defaultMessage: 'My Funding Account',
  },
  mySuperFund: {
    id: `${idPrefix}.mySuperFund`,
    defaultMessage: 'My Super Fund',
  },
  sunSuper: {
    id: `${idPrefix}.sunSuper`,
    defaultMessage: 'Sun Super',
  },
  whereInvest: {
    id: `${idPrefix}.whereInvest`,
    defaultMessage: 'Where would you like to transfer your {value}?',
  },
  rememberTransferDays: {
    id: `${idPrefix}.whereInvest`,
    defaultMessage:
      'Remember, transfers take up to 5 business days.<br>' +
      'If you can avoid tapping into your investments, they get a chance to grow.',
  },
  roundUpsSettings: {
    id: `${idPrefix}.roundUpsSettings`,
    defaultMessage: 'Round-ups settings',
  },
  autoRoundUps: {
    id: `${idPrefix}.autoRoundUps`,
    defaultMessage: 'Auto Round-ups',
  },
  automaticallyApproved: {
    id: `${idPrefix}.automaticallyApproved`,
    defaultMessage:
      'Your round-ups will be automatically <br> approved and invested',
  },
  smartRoundUps: {
    id: `${idPrefix}.smartRoundUps`,
    defaultMessage: 'Smart Round-ups',
  },
  choosingAmountOverride: {
    id: `${idPrefix}.choosingAmountOverride`,
    defaultMessage:
      'Choosing an amount below will override the amount <br> determined by Raiz.',
  },
  wholeDollar: {
    id: `${idPrefix}.wholeDollar`,
    defaultMessage: 'Whole Dollar Round-ups',
  },
  investText: {
    id: `${idPrefix}.investText`,
    defaultMessage:
      'I am micro-investing with Raiz. If you join using this link, ' +
      'we will both get {rewardAmount} when you use my invite code: {rewardUrl}',
  },
  startInvesting: {
    id: `${idPrefix}.startInvesting`,
    defaultMessage:
      'Start investing with Raiz today! Use my invite code to sign up: {rewardUrl}',
  },
  tipsOnGrowing: {
    id: `${idPrefix}.tipsOnGrowing`,
    defaultMessage:
      'Tips on growing your financial potential with micro-investing!',
  },
  sureToWithdraw: {
    id: `${idPrefix}.sureToWithdraw`,
    defaultMessage:
      'Are you sure you want to withdraw {value} to your Funding Account?',
  },
  sureToWithdrawSuperFund: {
    id: `${idPrefix}.sureToWithdrawSuperFund`,
    defaultMessage:
      'Are you sure you want to withdraw {value} to your super fund?',
  },
  note: {
    id: `${idPrefix}.note`,
    defaultMessage:
      'Note: Super is intended to fund your retirement so you can generally only access it or make a ' +
      'withdrawal once you have retired from working or have met a condition of release. Refer FAQs for more' +
      ' information.',
  },
  minimum: {
    id: `${idPrefix}.minimum`,
    defaultMessage: '{value} Minimum',
  },
  youMustWithdrawAtLeast: {
    id: `${idPrefix}.youMustWithdrawAtLeast`,
    defaultMessage:
      'You must withdraw at least {value}. And please remember, Raiz invests your money and has to sell' +
      'your shares to make this withdrawal, it can take up to 5 business days for the money to appear in ' +
      'your bank account.<br><br>Do you want to withdraw {value}?',
  },
  withdrawValue: {
    id: `${idPrefix}.withdrawValue`,
    defaultMessage: 'Withdraw {value}',
  },
  withdrawRequestWasReceived: {
    id: `${idPrefix}.withdrawRequestWasReceived`,
    defaultMessage:
      'Your withdrawal request was received. It may take up to 5 business days for the funds to ' +
      'be processed.',
  },
  withdrawalConfirmed: {
    id: `${idPrefix}.withdrawalConfirmed`,
    defaultMessage: 'Withdrawal Confirmed',
  },
  logout: {
    id: `${idPrefix}.logout`,
    defaultMessage: 'Log out',
  },
  areYouSureLogout: {
    id: `${idPrefix}.areYouSureLogout`,
    defaultMessage: 'Are you sure you want to Log Out?',
  },

  watchNow: {
    id: `${idPrefix}.watchNow`,
    defaultMessage: 'Watch Now',
  },
  investNow: {
    id: `${idPrefix}.investNow`,
    defaultMessage: 'Invest Now',
  },
  explore: {
    id: `${idPrefix}.explore`,
    defaultMessage: 'Explore',
  },
  goToHistory: {
    id: `${idPrefix}.goToHistory`,
    defaultMessage: 'Go to History',
  },
  weMakeItEasyToInvest: {
    id: `${idPrefix}.weMakeItEasyToInvest`,
    defaultMessage:
      'We make it easy to invest in your future with spare change and small amounts that grow over time.',
  },
  tentangReksaDana: {
    id: `${idPrefix}.tentangReksaDana`,
    defaultMessage: 'Yuk Tonton Video tentang Reksa Dana',
  },
  reksaDanaInfo: {
    id: `${idPrefix}.reksaDanaInfo`,
    defaultMessage:
      'Reksa Dana merupakan platform investasi mikro yang mudah dan aman bagi semua kalangan investor.',
  },
  reksaDanaBtn: {
    id: `${idPrefix}.reksaDanaBtn`,
    defaultMessage: 'Tonton sekarang',
  },
  navigateToHistory: {
    id: `${idPrefix}.navigateToHistory`,
    defaultMessage:
      'Just navigate to the History screen from the Menu to see transactions, rewards and more.',
  },
  nowBrands: {
    id: `${idPrefix}.nowBrands`,
    defaultMessage:
      'Now brands are investing in your future. Shop with our brand partners, ' +
      'and they’ll automatically invest money into Raiz account.',
  },
  easyToInvest: {
    id: `${idPrefix}.easyToInvest`,
    defaultMessage:
      'It’s easy to invest or withdraw money at any time. Investments take 1-5 business days and, ' +
      'since we have to sell your shares, withdrawals take up to 5 business days.',
  },
  personalFinanceConcierge: {
    id: `${idPrefix}.personalFinanceConcierge`,
    defaultMessage:
      'My Finance is your personal finance concierge. It allows you to gain a personalised overview of ' +
      'your personal finances, forecast future cashflow, and receive tips & insights regarding your spending ' +
      'and savings.',
  },
  scheduling: {
    id: `${idPrefix}.scheduling`,
    defaultMessage:
      'If your recurring investment is scheduling for a weekend or holiday, it will be processed on ' +
      'the next business day.',
  },
  transferingMoney: {
    id: `${idPrefix}.transferingMoney`,
    defaultMessage:
      'Investments take 1-5 business days to complete, and since we have to sell your shares, ' +
      'withdrawals take up to 5 business days.',
  },
  next: {
    id: `${idPrefix}.next`,
    defaultMessage: 'Next',
  },
  nextNewRewards: {
    id: `${idPrefix}.nextNewRewards`,
    defaultMessage: 'Next',
  },
  whereYouSpend: {
    id: `${idPrefix}.whereYouSpend`,
    defaultMessage:
      'Analyse how you are spending your money each month. You might find opportunities to invest!',
  },
  spendingCategories: {
    id: `${idPrefix}.spendingCategories`,
    defaultMessage:
      'See your top categories of spending and compare this with the average amount spent by others.',
  },
  editCategories: {
    id: `${idPrefix}.editCategories`,
    defaultMessage: 'Edit Categories',
  },
  viewYourSpending: {
    id: `${idPrefix}.viewYourSpending`,
    defaultMessage: 'View your spending',
  },
  relink: {
    id: `${idPrefix}.relink`,
    defaultMessage: 'Re-link Existing Account',
  },
  linkNew: {
    id: `${idPrefix}.linkNew`,
    defaultMessage: 'Link New Account',
  },
  removeDeposit: {
    id: `${idPrefix}.removeDeposit`,
    defaultMessage: 'Remove Deposit Suspension',
  },
  startNow: {
    id: `${idPrefix}.startNow`,
    defaultMessage: 'Start Now',
  },
  depositSuspension: {
    id: `${idPrefix}.depositSuspension`,
    defaultMessage:
      'Deposit Suspension has been activated because we detected that your account may have insufficient ' +
      'funds and we did not want to create unnecessary bank fees. Please click here to remove Deposit Suspension. ' +
      'If it occurs again please contact Support.',
  },
  setupFundingAccount: {
    id: `${idPrefix}.setupFundingAccount`,
    defaultMessage:
      'Your Funding Account has been unlinked due to insufficient funds - choose and option below to ' +
      'relink the previous account or setup a new account.',
  },
  welcomeRaizRewards: {
    id: `${idPrefix}.welcomeRaizRewards`,
    defaultMessage:
      'Shop with hundreds of partnered brands online and ' +
      'in-store to earn cash rewards invested back into your Raiz account.',
  },
  howSeeRewards: {
    id: `${idPrefix}.howSeeRewards`,
    defaultMessage:
      'Raiz Rewards will appear on your “Past” ' +
      'screen after we have received your money from the brands you have purchased from.',
  },
  useLinkCard: {
    id: `${idPrefix}.useLinkCard`,
    defaultMessage:
      'Use the cards linked to your account to earn rewards from in-store partners. Make sure you ' +
      'activate the in-store offer first to be eligible.',
  },
  useLinkCardRewards: {
    id: `${idPrefix}.useLinkCard`,
    defaultMessage:
      'Link your Visa/Mastercard credit or debit card or EFTPOS card to activate in-store rewards.' +
      ' Earn cash rewards every time you shop with participating retailers using a linked card.',
  },
  viewYourCards: {
    id: `${idPrefix}.viewYourCards`,
    defaultMessage: 'View Your Cards',
  },
  noCardLinked: {
    id: `${idPrefix}.noCardLinked`,
    defaultMessage:
      'Link the debit credit cards you use most so you can earn money in your Raiz account when you ' +
      'spend with a Raiz Rewards partner.',
  },
  noCardLinkedWarn: {
    id: `${idPrefix}.noCardLinkedWarn`,
    defaultMessage:
      'It looks like there’s an issue with a card linked to your Raiz account. Some Raiz Rewards ' +
      'partners require you to link a spending account in order to track purchases and reward you. ',
  },
  linkCard: {
    id: `${idPrefix}.linkCard`,
    defaultMessage: 'Link a Card',
  },
  exploreEtfs: {
    id: `${idPrefix}.exploreEtfs`,
    defaultMessage:
      'Select an assets class for more information on the associated exchange-traded fund (ETF)' +
      ' and holdings.',
  },
  portfolioDeversification: {
    id: `${idPrefix}.portfolioDeversification`,
    defaultMessage:
      'If you shop with our brand partners, they’ll invest money into future via Raiz.',
  },
  potentialInvestmentProjection: {
    id: `${idPrefix}.potentialInvestmentProjection`,
    defaultMessage:
      'Drag left and right on the graph to see the future expected value and gain. ' +
      'Drag up and down to change monthly contribution.',
  },
  automaticallyAdjust: {
    id: `${idPrefix}.automaticallyAdjust`,
    defaultMessage:
      'Raiz will automatically adjust the round-up limit that needs to be reached before the funds are ' +
      'withdrawn from your bank and invested into your Raiz account.',
  },
  wholeDollarInfo: {
    id: `${idPrefix}.wholeDollarInfo`,
    defaultMessage:
      'Whole dollar Round-ups is a great way to invest more, but not everyone is comfortable with ' +
      "investing this much. So we've made it easy to control how much to invest for whole dollar transactions.",
  },
  showMe: {
    id: `${idPrefix}.showMe`,
    defaultMessage: 'Show Me',
  },
  view: {
    id: `${idPrefix}.view`,
    defaultMessage: 'View',
  },
  futureInfo: {
    id: `${idPrefix}.futureInfo`,
    defaultMessage:
      'The projected values shown are hypothetical in nature and should not be relied on. They may be ' +
      'affected by inaccurate assumptions or risks and uncertainties and will differ materially from results ' +
      'ultimately achieved. To calculate the expected return for each asset class, the investment team first ' +
      'researched the historical total returns of the index or similar asset class underlying the ETFs (fee and ' +
      'currency adjusted). Where possible up to 30 years of historical data was used to calculate the returns, ' +
      'however for most historic returns less than 10 years of data was used.  For Bitcoin there is a zero-return ' +
      'assumption. An interest rate of 2.5% and inflation rate of 2% were also assumed. All returns are reduced for ' +
      'inflation. Factors that can cause significant variation from the historic risks are outlined in the product ' +
      'disclosure statement. The white centre line on the charts is the most likely outcome based on the data. The ' +
      'shaded envelope around the centre line is the risk envelopes to a 95% confidence interval, based on the ' +
      'historical data and illustrates the risks in a graphical format of achieving an expected portfolio value. ' +
      'However, this calculation should not be relied on to make your decisions in deciding which portfolio to ' +
      'choose to achieve your investing goal.',
  },
  kidsInfo: {
    id: `${idPrefix}.kidsInfo`,
    defaultMessage:
      'The projected future values shown for the Raiz Kids Accounts are hypothetical in nature and ' +
      'should not be relied on. They may be affected by inaccurate market return assumptions or market risks and ' +
      'uncertainties and will differ materially from results ultimately achieved. In order to calculate the ' +
      'expected return for each asset class, the investment team first researched the historical total returns ' +
      'of the index or similar asset class underlying the ETFs (fee and currency adjusted). For the IVV, IEU, 30 ' +
      'years of historical data was used to calculate the return. For the STW 20 years of historical data was used ' +
      'for the IAA, IAF, RCB 10 years of historic information was used. An interest rate of 2.5% and inflation ' +
      'rate of 2% were assumed. For RARI and ETHI their was no historic information available and the investment ' +
      'team made an educated guess based on similar asset classes. All returns are deflated for inflation. Factors ' +
      'that can cause significant variation from the historic risks are outlined in the product disclosure statement.',
  },
  categoryExpensesAlert: {
    id: `${idPrefix}.categoryExpensesAlert`,
    defaultMessage:
      'Your {description} expenses for this month have reached {currentMonth} and have ' +
      "exceeded your last month's expenses of {previousMonth} by more than {percentage}%",
  },
  estimatedFreeCash: {
    id: `${idPrefix}.estimatedFreeCash`,
    defaultMessage:
      'Congratulations, based on your recurring transactions patterns, we have estimated that ' +
      'you will have free cash at the end of the month.',
  },
  insufficientFunds: {
    id: `${idPrefix}.insufficientFunds`,
    defaultMessage: 'Insufficient Funds',
  },
  cannotBeWithdrawal: {
    id: `${idPrefix}.cannotBeWithdrawal`,
    defaultMessage:
      '{withdrawValue} cannot be withdrawn because your current account ' +
      'balance is only {availableBalance}',
  },
  viewChangeSuperFund: {
    id: `${idPrefix}.viewChangeSuperFund`,
    defaultMessage: 'View or change Super Fund',
  },
  withdrawBalance: {
    id: `${idPrefix}.withdrawBalance`,
    defaultMessage: 'Withdraw My Balance',
  },
  withdrawBalanceInfo: {
    id: `${idPrefix}.withdrawBalanceInfo`,
    defaultMessage:
      'Are you sure you want us to sell all of your investments?  Withdrawals can take up to 5 business ' +
      'days to complete. The market may change, so the final amount may vary from your current balance. You may also ' +
      'miss out on investment returns or compounding benefits when you pull money from your account.',
  },
  withdrawBalanceMaximum: {
    id: `${idPrefix}.withdrawBalanceMaximum`,
    defaultMessage:
      'As your withdrawal is for 95% or more of your account balance, your full balance will be ' +
      'withdrawn. Withdrawals take up to 5 business days to complete. The market may change, so the final amount ' +
      'may vary from your current balance. You may also miss out on investment returns or compounding benefits ' +
      'when you pull money from your account',
  },
  withdrawBalanceMoreMax: {
    id: `${idPrefix}.withdrawBalanceMoreMax`,
    defaultMessage:
      'Sorry, you cannot withdraw an amount higher than what is in your current balance. Please ' +
      'enter a number up to your current balance.',
  },
  setupSuperFund: {
    id: `${idPrefix}.setupSuperFund`,
    defaultMessage: 'Setup Super Fund',
  },
  superFunds: {
    id: `${idPrefix}.superFunds`,
    defaultMessage: 'Super Funds',
  },
  yourSuperFund: {
    id: `${idPrefix}.yourSuperFund`,
    defaultMessage: 'This is your Linked Super Funds',
  },
  chooseSuperFund: {
    id: `${idPrefix}.chooseSuperFund`,
    defaultMessage:
      'You can make a voluntary super contribution directly from your Raiz account by ' +
      'choosing «My Super Fund» when you withdraw.',
  },
  memberReferenceNumber: {
    id: `${idPrefix}.memberReferenceNumber`,
    defaultMessage: 'Member Reference Number',
  },
  unlink: {
    id: `${idPrefix}.unlink`,
    defaultMessage: 'Unlink',
  },
  change: {
    id: `${idPrefix}.change`,
    defaultMessage: 'Change',
  },
  areYouSure: {
    id: `${idPrefix}.areYouSure`,
    defaultMessage: 'Are you sure?',
  },
  confirmUnlink: {
    id: `${idPrefix}.confirmUnlink`,
    defaultMessage:
      'Please confirm that you really want to unlink your billing account?',
  },
  discard: {
    id: `${idPrefix}.discard`,
    defaultMessage: 'Discard',
  },
  selectSuperFund: {
    id: `${idPrefix}.selectSuperFund`,
    defaultMessage:
      'Select your super fund and provide your BPAY member reference number to make a voluntary ' +
      'super contribution directly from Raiz account.',
  },
  enterBPAY: {
    id: `${idPrefix}.enterBPAY`,
    defaultMessage:
      'Enter the BPAY member reference number provided to you by your Super Funds for making ' +
      'voluntary contributions.',
  },
  save: {
    id: `${idPrefix}.save`,
    defaultMessage: 'Save',
  },
  back: {
    id: `${idPrefix}.back`,
    defaultMessage: 'Back',
  },
  confirmMemberReferenceNumber: {
    id: `${idPrefix}.confirmMemberReferenceNumber`,
    defaultMessage: 'Confirm your Member Reference Number',
  },
  continue: {
    id: `${idPrefix}.continue`,
    defaultMessage: 'Continue',
  },
  pleaseEnsure: {
    id: `${idPrefix}.pleaseEnsure`,
    defaultMessage:
      'Please ensure you have provided the BPAY member reference number provide to you by your ' +
      'Super Fund for making voluntary contributions.',
  },
  requestFailed: {
    id: `${idPrefix}.requestFailed`,
    defaultMessage: 'Request Failed',
  },
  whatAreRound: {
    id: `${idPrefix}.whatAreRound`,
    defaultMessage: 'What are Round-ups?',
  },
  whatAreRoundInfo: {
    id: `${idPrefix}.whatAreRoundInfo`,
    defaultMessage:
      'When you swipe a linked debit or credit card, we invest the spare change from the transaction. ' +
      `For example, if you buy a coffee for ${CURRENCY_SYMBOL}2.45, Raiz will invest ` +
      `the ${CURRENCY_SYMBOL}0.55 for you.`,
  },
  linkFunding: {
    id: `${idPrefix}.linkFunding`,
    defaultMessage: 'Link a Funding Account Now',
  },
  linkFundingSpending: {
    id: `${idPrefix}.linkFundingSpending`,
    defaultMessage: 'Link a Debit or Credit Card Now',
  },
  linkDebitCard: {
    id: `${idPrefix}.linkDebitCard`,
    defaultMessage: 'Link a Debit Card Now',
  },
  roundUps: {
    id: `${idPrefix}.roundUps`,
    defaultMessage: 'Round-Ups',
  },
  konservatif: {
    id: `${idPrefix}.konservatif`,
    defaultMessage: 'Konservatif',
  },
  moderat: {
    id: `${idPrefix}.moderat`,
    defaultMessage: 'Moderat',
  },
  agresif: {
    id: `${idPrefix}.agresif`,
    defaultMessage: 'Agresif',
  },
  unitDisbursed: {
    id: `${idPrefix}.unitDisbursed`,
    defaultMessage: 'Unit disbursed:',
  },
  navUnit: {
    id: `${idPrefix}.navUnit`,
    defaultMessage: 'Projections based on NAV / Unit: {date}',
  },
  confirmWithdrawal: {
    id: `${idPrefix}.confirmWithdrawal`,
    defaultMessage: 'Confirm Withdrawal',
  },
  noteTime: {
    id: `${idPrefix}.noteTime`,
    defaultMessage:
      'Batas waktu (cut-off time) untuk transaksi ini adalah pukul 11:00 WIB setiap hari bursa. ' +
      'Transaksi yang dilikukan di luar bats waktu tersebut akan diproses menggunakan harga NAB/Unit pada ' +
      'hari kerja berikutnya.',
  },
  noteDays: {
    id: `${idPrefix}.noteDays`,
    defaultMessage:
      'Proses Pencairan Dana mencapai paling lambat 7 hari kerja sejak transaksi pencairan diproses.',
  },
  noteWallet: {
    id: `${idPrefix}.noteWallet`,
    defaultMessage:
      'Pastikan Rekening Bank yang Anda daftarkan bukan rekening E-Wallet/Rekening Ponsel. Rekening ' +
      'Bank yang terdaftar harussamadengan nama pemilik akun Raiz ini.',
  },
  sayaTelah: {
    id: `${idPrefix}.sayaTelah`,
    defaultMessage:
      'Saya telah menyetujui ' +
      '<a href="{link}" target="_blank">Syarat dan Kententuan atas transaksi ini</a>',
  },
  whatsapp: {
    id: `${idPrefix}.whatsapp`,
    defaultMessage: 'WhatsApp',
  },
  withdrawError: {
    id: `${idPrefix}.withdrawError`,
    defaultMessage: 'Withdraw Error',
  },
  investmentErrorType: {
    id: `${idPrefix}.investmentErrorType`,
    defaultMessage:
      'Sorry, you cannot input a new transaction until the pending transaction is complete.',
  },
  withdrawBtn: {
    id: `${idPrefix}.withdrawBtn`,
    defaultMessage: 'Withdraw',
  },
  investmentFundsWithdrawal: {
    id: `${idPrefix}.investmentFundsWithdrawal`,
    defaultMessage: 'Investment Funds Withdrawal',
  },
  investmentDisbursementLimits: {
    id: `${idPrefix}.investmentDisbursementLimits`,
    defaultMessage: 'Investment Disbursement Limits',
  },
  paymentFailed: {
    id: `${idPrefix}.paymentFailed`,
    defaultMessage:
      'Telah terjadi kesalahan pada transaksi Anda. Silakan klik panah di atas untuk mengulangi.',
  },
  paymentSuccess: {
    id: `${idPrefix}.paymentSuccess`,
    defaultMessage:
      'Terima kasih atas pembayaran Anda. Silakan klik panah di atas untuk kembali.',
  },
  amanah: {
    id: `${idPrefix}.amanah`,
    defaultMessage: 'Amanah',
  },
  berkah: {
    id: `${idPrefix}.berkah`,
    defaultMessage: 'Berkah',
  },
  yourInviteCode: {
    id: `${idPrefix}.yourInviteCode`,
    defaultMessage: 'Your invite code: <span class="-code">{code}</span>',
  },
  pointCamera: {
    id: `${idPrefix}.pointCamera`,
    defaultMessage:
      'Point the camera at the QR code <br>' +
      'so that it gets into the frame.',
  },

  howRaizWorks: {
    id: `${idPrefix}.howRaizWorks`,
    defaultMessage: 'How Raiz Works',
  },
  myFinance: {
    id: `${idPrefix}.myFinance`,
    defaultMessage: 'My Finance',
  },
  foodDining: {
    id: `${idPrefix}.foodDining`,
    defaultMessage: 'Food & Dining',
  },
  healthFitness: {
    id: `${idPrefix}.healthFitness`,
    defaultMessage: 'Health & Fitness',
  },
  cash: {
    id: `${idPrefix}.cash`,
    defaultMessage: 'Cash',
  },
  raizRewards: {
    id: `${idPrefix}.raizRewards`,
    defaultMessage: 'Raiz Rewards',
  },
  welcomeToRaizRewards: {
    id: `${idPrefix}.welcomeToRaizRewards`,
    defaultMessage: 'Welcome To Raiz Rewards',
  },
  transferringMoney: {
    id: `${idPrefix}.transferringMoney`,
    defaultMessage: 'Transferring Money',
  },
  wantToSeeActivity: {
    id: `${idPrefix}.wantToSeeActivity`,
    defaultMessage: 'Want to See Account Activity',
  },
  oneTimeInvest: {
    id: `${idPrefix}.oneTimeInvest`,
    defaultMessage: 'One-Time Investment',
  },
  withdrawTitle: {
    id: `${idPrefix}.withdrawTitle`,
    defaultMessage: 'Withdraw',
  },
  schedulingTitle: {
    id: `${idPrefix}.schedulingTitle`,
    defaultMessage: 'Scheduling',
  },
  daily: {
    id: `${idPrefix}.daily`,
    defaultMessage: 'Daily',
  },
  weekly: {
    id: `${idPrefix}.weekly`,
    defaultMessage: 'Weekly',
  },
  monthly: {
    id: `${idPrefix}.monthly`,
    defaultMessage: 'Monthly',
  },
  spendingCategoriesTitle: {
    id: `${idPrefix}.spendingCategoriesTitle`,
    defaultMessage: 'Spending Categories',
  },
  noCardLinkedTitle: {
    id: `${idPrefix}.noCardLinkedTitle`,
    defaultMessage: 'No Card Linked',
  },
  howSeeRewardsTitle: {
    id: `${idPrefix}.howSeeRewardsTitle`,
    defaultMessage: 'How can I See My Raiz Rewards?',
  },
  investment: {
    id: `${idPrefix}.investment`,
    defaultMessage: 'Investment',
  },
  depositSuspensionTitle: {
    id: `${idPrefix}.depositSuspensionTitle`,
    defaultMessage: 'Deposit Suspension',
  },
  useLinkCardTitle: {
    id: `${idPrefix}.useLinkCardTitle`,
    defaultMessage: 'Use your linked cards for<br>in-store rewards',
  },
  mastercard: {
    id: `${idPrefix}.mastercard`,
    defaultMessage: 'Mastercard Diamond 3704',
  },
  exploreEtfsTitle: {
    id: `${idPrefix}.exploreEtfsTitle`,
    defaultMessage: 'Explore the ETFs',
  },
  potentialInvestmentProjectionTitle: {
    id: `${idPrefix}.potentialInvestmentProjectionTitle`,
    defaultMessage: 'Potential Investment Projection',
  },
  konservatifTitle: {
    id: `${idPrefix}.konservatifTitle`,
    defaultMessage: 'Konservatif',
  },
  moderatTitle: {
    id: `${idPrefix}.moderatTitle`,
    defaultMessage: 'Moderat',
  },
  agresifTitle: {
    id: `${idPrefix}.agresifTitle`,
    defaultMessage: 'Agresif',
  },
  amanahTitle: {
    id: `${idPrefix}.amanahTitle`,
    defaultMessage: 'Amanah',
  },
  berkahTitle: {
    id: `${idPrefix}.berkahTitle`,
    defaultMessage: 'Sukuk',
  },
  setupFundingAccountTitle: {
    id: `${idPrefix}.setupFundingAccountTitle`,
    defaultMessage: 'Setup your Funding Account',
  },
  whereYouSpendTitle: {
    id: `${idPrefix}.whereYouSpendTitle`,
    defaultMessage: 'Where You Spend?',
  },
  avg: {
    id: `${idPrefix}.avg`,
    defaultMessage: 'AVG:<br>$4476',
  },
  chartA: {
    id: `${idPrefix}.chartA`,
    defaultMessage: '$3 843',
  },
  chartB: {
    id: `${idPrefix}.chartB`,
    defaultMessage: '$11 348',
  },
  chartC: {
    id: `${idPrefix}.chartC`,
    defaultMessage: '$10 237',
  },
  informasiPenting: {
    id: `${idPrefix}.informasiPenting`,
    defaultMessage: 'Informasi Penting!',
  },
  perjalananProses: {
    id: `${idPrefix}.perjalananProses`,
    defaultMessage: 'Perjalanan Proses Penarikan Saldo Investasi',
  },
  setelahKamu: {
    id: `${idPrefix}.setelahKamu`,
    defaultMessage:
      'Setelah kamu melanjutkan instruksi penarikan saldo sebelum batas waktu pada pukul 11:00 WIB,' +
      ' kamu akan menerima saldo Investasi dalam 2-5 hari kerja. Apabila kamu melakukan transaksi setelah batas ' +
      'waktu (Pukul 11:00 WIB) maka penarikan saldo kamu akan diproses pada hari kerja selanjutnya.',
  },
  hariLibur: {
    id: `${idPrefix}.hariLibur`,
    defaultMessage:
      ' (Hari libur nasional, hari libur bursa, Sabtu, dan Minggu tidak termasuk hari kerja)',
  },
  pastikanKembali: {
    id: `${idPrefix}.pastikanKembali`,
    defaultMessage: 'Pastikan Kembali Rekening Bank Terdaftar:',
  },
  saldoInvestasi: {
    id: `${idPrefix}.saldoInvestasi`,
    defaultMessage: 'Saldo Investasi kamu akan masuk kepada REKENING BANK ',
  },
  bukanReken: {
    id: `${idPrefix}.bukanReken`,
    defaultMessage: '(BUKAN REKENING E-WALLET atau REKENING VIRTUAL ACCOUNT) ',
  },
  atasNama: {
    id: `${idPrefix}.atasNama`,
    defaultMessage: ' atas nama KTP terdaftar.',
  },
  alasanKamu: {
    id: `${idPrefix}.alasanKamu`,
    defaultMessage:
      'Alasan Kamu tidak Menerima Saldo Investasi Setelah 5 Hari Kerja ',
  },
  hariLiburNasional: {
    id: `${idPrefix}.hariLiburNasional`,
    defaultMessage:
      '(Hari libur nasional, hari libur bursa, Sabtu, dan Minggu tidak termasuk hari kerja)',
  },
  akunBank: {
    id: `${idPrefix}.akunBank`,
    defaultMessage:
      'Akun Bank terdaftar tidak sesuai dengan nama KTP terdaftar',
  },
  mendaftarkanRekening: {
    id: `${idPrefix}.mendaftarkanRekening`,
    defaultMessage:
      'Mendaftarkan rekening E-Wallet atau Rekening Virtual Account',
  },
  nomorRekening: {
    id: `${idPrefix}.nomorRekening`,
    defaultMessage: 'Nomor Rekening Bank terdaftar salah',
  },
  customerSupport: {
    id: `${idPrefix}.customerSupport`,
    defaultMessage: 'Customer Support',
  },
  apabilaKamu: {
    id: `${idPrefix}.apabilaKamu`,
    defaultMessage:
      'Apabila kamu tidak menerima saldo dalam 5 hari kerja walaupun rekening Akun Bank terdaftar sudah' +
      ' benar, langsung saja hubungi Customer Support kami untuk menyelesaikan masalah ini:',
  },
  whatsappModal: {
    id: `${idPrefix}.whatsappModal`,
    defaultMessage: 'Whatsapp: ',
  },
  emailModal: {
    id: `${idPrefix}.emailModal`,
    defaultMessage: 'Email: ',
  },
  telephone: {
    id: `${idPrefix}.telephone`,
    defaultMessage: 'Telephone: ',
  },
  perbaiki: {
    id: `${idPrefix}.perbaiki`,
    defaultMessage: 'Perbaiki',
  },
  xenditContinue: {
    id: `${idPrefix}.xenditContinue`,
    defaultMessage: 'Please continue payment in a new window',
  },
  danaText: {
    id: `${idPrefix}.danaText`,
    defaultMessage:
      'Aktifkan Akun DANA untuk pembayaran \n' + 'lebih mudah dan cepat',
  },
  aktifkan: {
    id: `${idPrefix}.aktifkan`,
    defaultMessage: 'Aktifkan',
  },
  aktifkanDana: {
    id: `${idPrefix}.aktifkanDana`,
    defaultMessage: 'Aktifkan DANA',
  },
  tutup: {
    id: `${idPrefix}.tutup`,
    defaultMessage: 'Tutup',
  },
  saldoRound: {
    id: `${idPrefix}.saldoRound`,
  },
  roundUpsInfo: {
    id: `${idPrefix}.roundUpsInfo`,
    defaultMessage:
      'Tonton video ini untuk cara menyambungkan akun pengeluaran kamu dan ' +
      'mengaktifkan fitur pembulatan ',
  },
  tontonSekarang: {
    id: `${idPrefix}.tontonSekarang`,
    defaultMessage: 'Tonton sekarang',
  },
  welcomeRaizRewardsNew: {
    id: `${idPrefix}.welcomeRaizRewards`,
    defaultMessage:
      'Shop with hundreds of partnered brands online and In-Store to earn cash rewards invested ' +
      'back into your Raiz account.',
  },
  rewardsWill: {
    id: `${idPrefix}.rewardsWill`,
    defaultMessage:
      'Rewards will automatically be invested within 90 days from the date of purchase. All pending ' +
      'and invested rewards can be seen in Rewards History.',
  },
  raizRewardsHistory: {
    id: `${idPrefix}.raizRewardsHistory`,
    defaultMessage: 'Raiz Rewards History',
  },
  theGoodGuys: {
    id: `${idPrefix}.theGoodGuys`,
    defaultMessage: 'The Good Guys',
  },
  asos: {
    id: `${idPrefix}.asos`,
    defaultMessage: 'ASOS',
  },
  booking: {
    id: `${idPrefix}.booking`,
    defaultMessage: 'Booking.com',
  },
  welcomeToRaizKids: {
    id: `${idPrefix}.welcomeToRaizKids`,
    defaultMessage: 'Welcome to Raiz Kids!',
  },
  kidsInfoNew: {
    id: `${idPrefix}.kidsInfoNew`,
    defaultMessage:
      'Raiz kids lets you setup an account on behalf of ' +
      "anyone under the age of 18. It's an easy way to save and invest " +
      'for your children and only takes a few minutes to setup.',
  },
  nextNewKids: {
    id: `${idPrefix}.nextNewKids`,
    defaultMessage: 'Next',
  },
  nextNewKidsClose: {
    id: `${idPrefix}.nextNewKidsClose`,
    defaultMessage: 'Close',
  },
  raizKidsAccess: {
    id: `${idPrefix}.raizKidsAccess`,
    defaultMessage: 'Choose your Kid’s Access',
  },
  raizKidsAccessInfo: {
    id: `${idPrefix}.raizKidsAccessInfo`,
    defaultMessage:
      'Teach your kid savings skills by setting permissions ' +
      'which allow each child to manage their own account. These can be changed ' +
      'at any time and allow your children to login and partially operate their own Raiz account.',
  },
  raizKidsInvest: {
    id: `${idPrefix}.raizKidsInvest`,
    defaultMessage: 'Track and Invest',
  },
  raizKidsInvestInfo: {
    id: `${idPrefix}.raizKidsInvestInfo`,
    defaultMessage:
      'You can make investments, monitor performance, and make changes to each of ' +
      'your children’s accounts at any time from the Raiz Kids menu.',
  },
  raizKidsAdult: {
    id: `${idPrefix}.raizKidsAdult`,
    defaultMessage: 'Convert to a full account when they turn 18',
  },
  raizKidsAdultInfo: {
    id: `${idPrefix}.raizKidsAdultInfo`,
    defaultMessage:
      'When your child turns 18, you’ll have the option to convert your kid’s account ' +
      'into a fully functional Raiz account.',
  },
  akunGoPay: {
    id: `${idPrefix}.akunGoPay`,
    defaultMessage:
      'Aktifkan Akun GoPay untuk pembayaran \n' + 'lebih mudah dan cepat',
  },
  verifyGoPay: {
    id: `${idPrefix}.verifyGoPay`,
    defaultMessage:
      'Akun GoPay kamu sedang dalam proses verifikasi. Kamu bisa melakukan proses verifikasi sekarang.',
  },
  verifikasi: {
    id: `${idPrefix}.verifikasi`,
    defaultMessage: 'Verifikasi',
  },
  akunXendit: {
    id: `${idPrefix}.akunGoPay`,
    defaultMessage:
      'Aktifkan Akun {serviceName} untuk pembayaran \n' +
      'lebih mudah dan cepat',
  },
  letYourFriendsKnow: {
    id: `${idPrefix}.letYourFriendsKnow`,
    defaultMessage: 'Let Your Friends Know',
  },
  justInvested: {
    id: `${idPrefix}.justInvested`,
    defaultMessage:
      '{rewardName} just invested in my future through my @raiz account. Cash forward is the new cash ' +
      'back. {siteUrl}',
  },
  yes: {
    id: `${idPrefix}.yes`,
    defaultMessage: 'Yes',
  },
};

const myMessages = {
  navigateToHistory: {
    id: `${idPrefix}.navigateToHistory`,
    defaultMessage:
      'Just navigate to the History screen from the Menu to see your account activity.',
  },
  wholeDollar: {
    id: `${idPrefix}.wholeDollar`,
    defaultMessage: 'Whole RM Round-ups',
  },
  wholeDollarInfo: {
    id: `${idPrefix}.wholeDollarInfo`,
    defaultMessage:
      'Whole RM Round-ups is a great way to invest more, but not everyone is comfortable with ' +
      "investing this much. So we've made it easy to control how much to invest for whole RM transactions.",
  },
  whatAreRoundInfo: {
    id: `${idPrefix}.whatAreRoundInfo`,
    defaultMessage:
      'When you purchase using your bank cards or bank accounts that you have linked, we will round-up that transaction ' +
      'and invest the spare change automatically. For example, you buy a coffee for RM9.60. Raiz will invest the 40 ' +
      'cents for you. Easy!',
  },
  automaticallyAdjust: {
    id: `${idPrefix}.automaticallyAdjust`,
    defaultMessage:
      'Raiz will automatically adjust the round-up limit that needs to be reached before the ' +
      'funds are invested into your Raiz account.',
  },
  withdrawBalanceInfo: {
    id: `${idPrefix}.withdrawBalanceInfo`,
    defaultMessage:
      'Are you sure you want us to sell all of your investments?  Withdrawals can take 4 business ' +
      'days to complete. The market may change, so the final amount may vary from your current balance.',
  },
  youMustWithdrawAtLeast: {
    id: `${idPrefix}.youMustWithdrawAtLeast`,
    defaultMessage:
      'Withdrawal must be at least {value}. We will sell your units to make this withdrawal, and the money will normally ' +
      'reach your bank account in the next 3-4 working days.<br><br>Do you want to withdraw {value}?',
  },
  withdrawRequestWasReceived: {
    id: `${idPrefix}.withdrawRequestWasReceived`,
    defaultMessage:
      'Your withdrawal request is received. Your money will normally reach your bank account in the ' +
      'next 3-4 working days.',
  },
  cannotBeWithdrawal: {
    id: `${idPrefix}.cannotBeWithdrawal`,
    defaultMessage:
      '{withdrawValue} cannot be withdrawn because your current account ' +
      'balance is {availableBalance}',
  },
  easyToInvest: {
    id: `${idPrefix}.easyToInvest`,
    defaultMessage:
      'It’s easy to invest or withdraw money at any time. Investments take 1-4 business days and, ' +
      'since we have redeem your units, withdrawals take up to the next 4 business days.',
  },
  automaticallyApproved: {
    id: `${idPrefix}.automaticallyApproved`,
    defaultMessage: 'Enable Round-ups to invest your spare change',
  },
  choosingAmountOverride: {
    id: `${idPrefix}.choosingAmountOverride`,
    defaultMessage:
      'Your spare change will be invested once it reaches your selected threshold',
  },
  confirmWithdrawal: {
    id: `${idPrefix}.confirmWithdrawal`,
    defaultMessage: 'Confirm Withdrawal',
  },
  sureToWithdraw: {
    id: `${idPrefix}.sureToWithdraw`,
    defaultMessage:
      'Are you sure you want to withdraw {value} to your bank account?',
  },
  shareRaizStory: {
    id: `${idPrefix}.shareRaizStory`,
    defaultMessage:
      "When a friend invests with Raiz, you'll both get <span>{inviteAmount}</span>." +
      ` Learn how it works <a target="_blank" href="${PLATFORM_LINK_MY}">here</a>.`,
  },
  kidsInfo: {
    id: `${idPrefix}.kidsInfo`,
    defaultMessage:
      'The projected values shown are hypothetical in nature and should not be relied on. They may' +
      'be affected by inaccurate assumptions or risks and uncertainties and will differ materially from results ' +
      'ultimately achieved. In order to calculate the expected return for each portfolio, the investment team first ' +
      'researched the historical total returns of the index or similar asset class.<br><br>' +
      'An interest rate of 2.5% and inflation rate of 2% were assumed. All returns are deflated for inflation. ' +
      'Factors that can cause significant variation from the historic risks are outlined in the Terms & Conditions. ' +
      'Past performance is not a reliable indicator of future performance.' +
      'The white centre line on the charts is the most likely outcome based on the data. The shaded envelope ' +
      'around the centre line is the risk envelopes to a 95% confidence interval, based on the historical data and ' +
      'illustrates the risks in a graphical format of achieving an expected portfolio value.',
  },
  avg: {
    id: `${idPrefix}.avg`,
    defaultMessage: 'AVG:<br>RM4476',
  },
  chartA: {
    id: `${idPrefix}.chartA`,
    defaultMessage: 'RM3 843',
  },
  chartB: {
    id: `${idPrefix}.chartB`,
    defaultMessage: 'RM11 348',
  },
  chartC: {
    id: `${idPrefix}.chartC`,
    defaultMessage: 'RM10 237',
  },
  investText: {
    id: `${idPrefix}.investText`,
    defaultMessage:
      'I am micro-investing with Raiz. We will both get {rewardAmount} when you ' +
      'use my invite code: {rewardUrl}',
  },
  welcomeRaizRewards: {
    id: `${idPrefix}.welcomeRaizRewards`,
    defaultMessage:
      'Shop with hundreds of partnered brands online and ' +
      'In-Store to earn cash rewards invested back into your Raiz account.',
  },
  welcomeRaizRewardsMYS: {
    id: `${idPrefix}.welcomeRaizRewardsMYS`,
    defaultMessage:
      'Shop with hundreds of partnered brands online and in-store to earn cash rewards invested back' +
      ' into your Raiz account.',
  },
  linkFunding: {
    id: `${idPrefix}.linkFunding`,
    defaultMessage: 'Link Your Card Now',
  },
};

const messages = MY_APP ? { ...auMessages, ...myMessages } : auMessages;

export default defineMessages(messages);
