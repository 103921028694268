import React from 'react';
import Skeleton from 'react-skeleton-loader';

import {
  SkeletonCheckboxWrapper,
  SkeletonCheckBox,
  SkeletonCheckBoxLabel,
} from './styles';

function CheckboxSkeleton() {
  return (
    <SkeletonCheckboxWrapper>
      <SkeletonCheckBox>
        <Skeleton borderRadius="100%" width="100%" />
      </SkeletonCheckBox>

      <SkeletonCheckBoxLabel>
        <Skeleton />
      </SkeletonCheckBoxLabel>
    </SkeletonCheckboxWrapper>
  );
}

export default CheckboxSkeleton;
