import { t } from 'i18next';

import { showGlobalError } from '../errors/actions';
import { toggleInfoModal } from '../modals/actions';

import { MAX_ALLOCATION_STATUS_CODE } from './consts';

export const handleAllocationExceededError = (dispatch, error) => {
  if (error?.response?.status === MAX_ALLOCATION_STATUS_CODE) {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('portfolioCustomization.maxAllocationsModal.title'),
          description: t('portfolioCustomization.maxAllocationsModal.label'),
        },
      }),
    );
  } else {
    dispatch(showGlobalError(error));
  }
};
