import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  SmartRoundUpsSubTitle,
  SmartRoundUpsTitle,
  SmartRoundUpsWrapper,
} from './styles';
import AvailableThresholds from './AvailableThresholds';
import { IRoundUpsSettings, ISmartRoundUps } from './types';

import RoundUpsHelpCard from 'pages/RoundUps/components/RoundUpsHelpCard';
import { IconButton } from 'components/elements/IconButton';

export default function SmartRoundUps({
  roundUpsSettings,
  handleChangeThreshold,
}: ISmartRoundUps) {
  const { t } = useTranslation();

  const [isHelpCardOpen, setIsHelpCardOpen] = useState(false);

  const { automatic_roundup: isSmartRoundUpsVisible }: IRoundUpsSettings =
    roundUpsSettings;

  if (!isSmartRoundUpsVisible) {
    return null;
  }

  return (
    <SmartRoundUpsWrapper>
      <SmartRoundUpsTitle>
        {t('roundUps.modal.smartTitle')}

        <IconButton
          iconName="info"
          size={24}
          onClick={() => setIsHelpCardOpen(true)}
        />
      </SmartRoundUpsTitle>

      <SmartRoundUpsSubTitle>
        <Trans t={t} i18nKey="roundUps.modal.choosingAmountOverride" />
      </SmartRoundUpsSubTitle>

      <AvailableThresholds
        roundUpsSettings={roundUpsSettings}
        handleChangeThreshold={handleChangeThreshold}
      />

      <RoundUpsHelpCard
        isHelpCardOpen={isHelpCardOpen}
        setIsHelpCardOpen={setIsHelpCardOpen}
      />
    </SmartRoundUpsWrapper>
  );
}
