import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Animate } from 'react-show';
import Geocoder from 'react-mapbox-gl-geocoder';
import { useTranslation, Trans } from 'react-i18next';

import { REGISTRATION_PERSONAL_INFO } from '../../../store/commonTypes';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import SelectFF from '../../../components/elements/SelectFF/SelectFF';
import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import KTPInfoModal from '../../../components/layout/Modals/KTPInfoModal';
import RecurringModal from '../../../components/layout/Modals/RecurringModal';
import { useAddressValidation } from '../utils/utils';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useGetCountryStatesQuery } from 'store/assets/assets';

const mapAccess = {
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN,
};

const queryParams = {
  country: MY_APP ? 'my' : 'au',
};

export default function Address({
  errorMessage,
  show,
  toggleShow,
  viewport,
  mapOnSelected,
  addressOnChange,
  goBack,
  handleSubmit,
  showLoader,
  user,
  addressData,
  showResidentInfoModal,
  isShowResidentInfoModal,
  toggleKTPInfo,
  showKTPInfo,
}) {
  const { t } = useTranslation();
  const { validate } = useAddressValidation();

  const getGetCountryStatesQuery = useGetCountryStatesQuery();

  const initialValues = {};
  const zipLength = MY_APP ? '5' : '4';
  const showAddressFull = MY_APP ? true : show;

  useEffect(() => {
    toggleShow(false);
  }, [toggleShow]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) =>
        validate({
          values,
          showResidentInfoModal,
        })
      }
      initialValues={initialValues}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className="text-left">
            {t('registrationPage.addressForm.description')}
          </div>

          {AU_APP && (
            <div className="geocoder" onChange={addressOnChange}>
              <div className="geocoder-label">
                {t('registrationPage.addressForm.yourAddress')}
              </div>

              <Geocoder
                {...mapAccess}
                onSelected={mapOnSelected}
                viewport={viewport}
                queryParams={queryParams}
                hideOnSelect
                updateInputOnSelect
              />

              <DeprecatedButton
                title={t('registrationPage.addressForm.addressManually')}
                buttonClass="button-link"
                handleClick={() => toggleShow()}
              />
            </div>
          )}

          {showAddressFull && (
            <Animate
              show={showAddressFull}
              start={{
                height: 0,
              }}
            >
              <div className={'"form-field radio'}>
                <div className="form-field__sub-input-link">
                  {AU_APP && (
                    <FinalFormInput
                      name="street_number"
                      defaultValue={
                        addressData.street_number || user.user.street_number
                      }
                      label={t('registrationPage.addressForm.streetNumber')}
                    />
                  )}

                  <FinalFormInput
                    name="address1"
                    defaultValue={addressData.address1 || user.user.address1}
                    label={t('registrationPage.addressForm.streetName')}
                  />

                  {(AU_APP || MY_APP) && (
                    <FinalFormInput
                      name="city"
                      defaultValue={addressData.city || user.user.city}
                      label={t('registrationPage.addressForm.suburb')}
                    />
                  )}

                  {(AU_APP || MY_APP) &&
                    !getGetCountryStatesQuery?.isLoading && (
                      <div className="select-first select-inside">
                        <div className="profile-content__container-label">
                          {t('registrationPage.addressForm.state')}
                        </div>

                        <SelectFF
                          name="state"
                          defaultValue={addressData.state || user.user.state}
                          options={getGetCountryStatesQuery.data}
                        />
                      </div>
                    )}

                  {(AU_APP || MY_APP) && (
                    <FinalFormInput
                      name="zip"
                      defaultValue={addressData.zip || user.user.zip}
                      label={t('fields.postCode.label')}
                      maxLength={zipLength}
                      isNumberOnly
                    />
                  )}

                  {MY_APP && (
                    <CheckboxFF
                      name="us_citizen"
                      className="us-resident us-resident__checkbox"
                      defaultChecked={values.us_citizen}
                      type="checkbox"
                      label={
                        <Trans
                          i18nKey="registrationPage.addressForm.usResidentQuestion"
                          t={t}
                        />
                      }
                    />
                  )}

                  {AU_APP && (
                    <CheckboxFF
                      name="us_citizen"
                      className="us-resident"
                      defaultChecked={values.us_citizen}
                      type="checkbox"
                      label={
                        <Trans
                          i18nKey="registrationPage.addressForm.usCitizen"
                          t={t}
                        />
                      }
                    />
                  )}

                  <div className="hide">{errorMessage}</div>
                </div>
              </div>
            </Animate>
          )}

          <div className="buttons-part">
            <div className="form-field">
              <DeprecatedButton
                withLoader={showLoader}
                title={t('common.back')}
                buttonClass="button back"
                handleClick={() => goBack(REGISTRATION_PERSONAL_INFO)}
              />
            </div>

            <div className="form-field">
              <DeprecatedButton
                withLoader={showLoader}
                type="submit"
                title={t('common.next')}
                buttonClass="button button--submit"
              />
            </div>
          </div>

          {isShowResidentInfoModal && (
            <RecurringModal
              isOpen={isShowResidentInfoModal}
              onRequestClose={() => showResidentInfoModal(false)}
              handleClick={() => showResidentInfoModal(false)}
              modalContent={t(
                'registrationPage.addressForm.applicationNotCompliant',
              )}
              modalClassName="time-investment__modal"
              modalFooterClassName="-single-btn"
            />
          )}

          {showKTPInfo && (
            <KTPInfoModal
              showKTPInfo={showKTPInfo}
              toggleKTPInfo={toggleKTPInfo}
            />
          )}
        </form>
      )}
    />
  );
}

Address.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  toggleShow: PropTypes.func,
  addressOnChange: PropTypes.func,
  goBack: PropTypes.func.isRequired,
  showResidentInfoModal: PropTypes.func.isRequired,
  toggleKTPInfo: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showKTPInfo: PropTypes.bool.isRequired,
  isShowResidentInfoModal: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  viewport: PropTypes.shape({}).isRequired,
  mapOnSelected: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      address1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      street_number: PropTypes.string,
    }),
  }).isRequired,
  addressData: PropTypes.shape({
    street_number: PropTypes.string,
    address1: PropTypes.string,
    same_address: PropTypes.bool,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  changeAutocompleteCity: PropTypes.func.isRequired,
};

Address.defaultProps = {
  errorMessage: '',
  showLoader: false,
  toggleShow: {},
  addressOnChange: {},
};
