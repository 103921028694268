import React from 'react';
import { useTranslation } from 'react-i18next';

import { MarketValueLabel, MarketValueWrapper } from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectSummaryByIndex } from 'store/perfomance/selectors';
import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

interface IMarketValueProps {
  marketChange: number;
  marketPercent: number;
  dataPointIndex: number;
  isDashboard: boolean;
  isEtf: boolean;
}

export const MarketValue = ({
  marketChange,
  marketPercent,
  dataPointIndex,
  isDashboard,
  isEtf,
}: IMarketValueProps) => {
  const { t } = useTranslation();

  const { market_return, market_return_percent } = useAppSelector(
    selectSummaryByIndex(dataPointIndex),
  );

  const amount =
    AU_APP || isEtf
      ? marketChange
      : formatNumber({
          value: market_return,
          isWithSign: true,
        });

  const marketReturnPercent =
    AU_APP || isEtf ? marketPercent : market_return_percent;

  return (
    <>
      {MY_APP && isEtf && (
        <MarketValueLabel>
          {t('performanceDetailsPage.changeInValueLabel')}
        </MarketValueLabel>
      )}

      <MarketValueWrapper
        t={t}
        i18nKey="performanceDetailsPage.accountChangeInValue"
        components={{
          span: <span />,
        }}
        values={{
          amount,
          percentage: formatNumber({
            value: marketReturnPercent,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            type: NUMBER_FORMATS.percent,
            isWithSign: true,
          }),
        }}
        isDashboard={isDashboard}
      />
    </>
  );
};
