import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';
import { initiateCheckIsAccountLinkable } from 'store/fundingAccount/fundingAccount';
import { ICheckIsAccountLinkableError } from 'store/fundingAccount/types';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { toggleInfoModal } from 'store/modals/actions';

interface IUseCheckIsAccountLinkable {
  signInBank: (id: string) => void;
}

export const useCheckIsAccountLinkable = ({
  signInBank,
}: IUseCheckIsAccountLinkable) => {
  const dispatch = useAppDispatch();
  const isYodleeFastlinkEnabled = useAppSelector(selectIsYodleeFastlinkEnabled);

  const handleSignInBank = (id: string) => {
    if (isYodleeFastlinkEnabled) {
      dispatch(initiateCheckIsAccountLinkable({ id })).then((response) => {
        const error = response?.error as ICheckIsAccountLinkableError;

        if (error?.data?.message) {
          dispatch(
            toggleInfoModal({
              isInfoModalVisible: true,
              config: {
                title: error.data.message.title,
                description: error.data.message.description,
              },
            }),
          );

          return;
        }

        signInBank(id);
      });

      return;
    }

    signInBank(id);
  };

  return {
    handleSignInBank,
  };
};
