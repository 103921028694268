import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ALERT_TYPES } from '../../../../../constants/alerts';
import getAlertLink from '../../utils';

function FundingAlertsModalItem({
  alert,
  isFunding,
  currentIndex,
  length,
  toggleAlertInfoModal,
  setIsModalAlertsOpen,
  handleVerificationModal,
}) {
  const link = getAlertLink(alert.type);
  const defaultLink = isFunding ? '/' : '/alerts';

  if (alert.type === ALERT_TYPES.depositsSuspensionRemoval) return null;

  const defaultHandler = () =>
    link ? setIsModalAlertsOpen(false) : toggleAlertInfoModal(true, alert);

  const autoVerificationHandler = (e) => {
    e.preventDefault();

    handleVerificationModal();
  };

  const alertHandler =
    alert.type === ALERT_TYPES.autoVerification
      ? autoVerificationHandler
      : defaultHandler;

  // eslint-disable-next-line consistent-return
  return (
    <Link
      onClick={alertHandler}
      to={link || defaultLink}
      className="header__alert-item"
    >
      <div className="button__alert header__alert-icon" />

      <div className="header__alert-title">{alert.title}</div>

      {!isFunding && length - 1 !== currentIndex && <hr />}
    </Link>
  );
}

FundingAlertsModalItem.propTypes = {
  alert: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  length: PropTypes.number,
  currentIndex: PropTypes.number,
  isFunding: PropTypes.bool,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  setIsModalAlertsOpen: PropTypes.func.isRequired,
  handleVerificationModal: PropTypes.func,
};

FundingAlertsModalItem.defaultProps = {
  length: 0,
  currentIndex: 0,
  isFunding: false,
  handleVerificationModal: () => {},
};

export default FundingAlertsModalItem;
