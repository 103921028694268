import { sortBy } from 'lodash';

import { IRoundUpsSettingsResponse } from 'components/layout/Modals/RoundUpsModal/components/types';
import { roundUpsSettingsInitialData } from 'store/roundUpsSettings/consts';

const formatUserSettings = (settings?: IRoundUpsSettingsResponse) => ({
  ...roundUpsSettingsInitialData,
  ...settings,
  default_amount: Number(settings?.default_amount || 0),
  available_thresholds: sortBy(settings?.available_thresholds || [], ['lower']),
  automatic_lower_threshold: Number(settings?.automatic_lower_threshold || 0),
  automatic_upper_threshold: Number(settings?.automatic_upper_threshold || 0),
});

export default formatUserSettings;
