import styled from '@emotion/styled';

import { STATEMENTS_FOOTER_BUTTONS_WIDTH } from './consts';

import { Button } from 'components/elements/Button';

export const StatementsFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border-radius: 0 0 10px 10px;
  padding: 18px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  position: sticky;
  bottom: 0;
`;

export const StatementsFooterConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.niagara};
  color: ${({ theme }) => theme.colors.white};
  width: ${STATEMENTS_FOOTER_BUTTONS_WIDTH}px;
`;

export const StatementsFooterCancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.mirage};
`;
