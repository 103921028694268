export const GO_TO_LOGIN = 'GO_TO_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_USER_STORE = 'GET_USER_STORE';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';
export const GO_TO_REGISTRATION = 'GO_TO_REGISTRATION';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const VERIFICATION_DEVICE = 'VERIFICATION_DEVICE';
export const SEND_INVESTS_SUCCESS = 'SEND_INVESTS_SUCCESS';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const SHOW_ERROR_GET_TRANSACTIONS = 'SHOW_ERROR_GET_TRANSACTIONS';
export const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
export const CLOSE_ACCOUNT_LOADING = 'CLOSE_ACCOUNT_LOADING';
export const VERIFY_QUESTION_ERROR = 'VERIFY_QUESTION_ERROR';
export const VERIFY_PASSWORD_ERROR = 'VERIFY_PASSWORD_ERROR';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_PORTFOLIOS_SUCCESS = 'GET_PORTFOLIOS_SUCCESS';
export const REGISTRATION_STEP_AUTH = '/registration';
export const SHOW_ERROR_SEND_INVESTS = 'SHOW_ERROR_SEND_INVESTS';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const SAVE_SAVINGS_RATE_SUCCESS = 'SAVE_SAVINGS_RATE_SUCCESS';
export const SHOW_ERROR_CLOSE_QUESTIONS = 'SHOW_ERROR_CLOSE_QUESTIONS';
export const GET_CLOSE_QUESTIONS_SUCCESS = 'GET_CLOSE_QUESTIONS_SUCCESS';
export const GET_REWARDS_SUMMARY_SUCCESS = 'GET_REWARDS_SUMMARY_SUCCESS';
export const GET_USER_INVITATION_SUCCESS = 'GET_USER_INVITATION_SUCCESS';
export const GET_ACCOUNT_STRENGTH_SUCCESS = 'GET_ACCOUNT_STRENGTH_SUCCESS';
export const REGISTRATION_PERSONAL_ADDRESS = '/registration/personal/address';
export const REGISTRATION_ACCOUNT_QUESTIONS =
  '/registration/personal/account-questions';
export const REGISTRATION_BANK_ACCOUNT_BANKS =
  '/registration/bankAccount/banks';
export const GET_SUPER_USER_ACCOUNTS_SUCCESS =
  'GET_SUPER_USER_ACCOUNTS_SUCCESS';
export const GET_ACCOUNT_SUMMARY_ROUND_SUCCESS =
  'GET_ACCOUNT_SUMMARY_ROUND_SUCCESS';
export const REGISTRATION_INVESTMENTS_INVESTMENT =
  '/registration/investments/investment';
export const REGISTRATION_ACCOUNT_AGEREEMENT_FORM =
  'REGISTRATION_ACCOUNT_AGEREEMENT_FORM';
export const GO_TO_TERMS = 'GO_TO_TERMS';
export const ERROR_CREATE_USER_ON_REGISTRATION =
  'ERROR_CREATE_USER_ON_REGISTRATION';
export const CHANGE_ADDRESS_DATA = 'CHANGE_ADDRESS_DATA';
export const GET_REQUIRED_DOCUMENTS_SUCCESS = 'GET_REQUIRED_DOCUMENTS_SUCCESS';
export const REGISTRATION_INVESTING_ERROR = 'REGISTRATION_INVESTING_ERROR';
export const LINK_FUNDING_NOW = 'LINK_FUNDING_NOW';
export const REGISTRATION_PERSONAL_TAXRESIDENT =
  '/registration/personal/tax-resident';
export const REGISTRATION_ACCOUNT_MYKAD = '/registration/account/uploadId';
export const GO_TO_FUNDING = 'GO_TO_FUNDING';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const SET_PORTFOLIO_TYPE = 'SET_PORTFOLIO_TYPE';
export const PORTFOLIO_SAVE_ERROR = 'PORTFOLIO_SAVE_ERROR';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const CREATE_IDENTIFIER_SUCCESS = 'CREATE_IDENTIFIER_SUCCESS';
export const GET_IDENTIFIERS_SUCCESS = 'GET_IDENTIFIERS_SUCCESS';
export const DELETE_IDENTIFIER_SUCCESS = 'DELETE_IDENTIFIER_SUCCESS';
export const SHOW_ADD_TAX = 'SHOW_ADD_TAX';
export const GET_SIGNUP_PROGRESS_SUCCESS = 'GET_SIGNUP_PROGRESS_SUCCESS';
export const GET_SECURITY_QUESTION_SUCCESS = 'GET_SECURITY_QUESTION_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';
export const REGISTRATION_ACCOUNT_OTHER = '/registration/account/other';
export const GET_BLOG_POSTS_SUCCESS = 'GET_BLOG_POSTS_SUCCESS';
export const HIDE_BLOG = 'HIDE_BLOG';
export const WITHDRAW_ALL_MONEY = 'WITHDRAW_ALL_MONEY';
export const GET_PORTFOLIO_FUNDS = 'GET_PORTFOLIO_FUNDS';
export const GET_CUSTOM_PORTFOLIO_SUCCESS = 'GET_CUSTOM_PORTFOLIO_SUCCESS';
export const GET_NEW_PORTFOLIO_ID = 'GET_NEW_PORTFOLIO_ID';
export const GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS =
  'GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS';
export const CUSTOM_PORTFOLIO_ERROR = 'CUSTOM_PORTFOLIO_ERROR';
export const REGISTRATION_SUPERFUND_INFO =
  '/registration/super-fund/information';
export const GET_SUPER_FUND_DETAILS_SUCCESS = 'GET_SUPER_FUND_DETAILS_SUCCESS';
export const GET_SUPER_FUND_MEMBERS_SUCCESS = 'GET_SUPER_FUND_MEMBERS_SUCCESS';
export const REGISTRATION_SUPERFUND_MEMBERS =
  '/registration/super-fund/members';
export const REGISTRATION_CREATE_MEMBERS_SUCCESS =
  'REGISTRATION_CREATE_MEMBERS_SUCCESS';
export const SET_SMSF_USER_TYPE_SUCCESS = 'SET_SMSF_USER_TYPE_SUCCESS';
export const SHOW_WITHDRAWAL = 'SHOW_WITHDRAWAL';
export const LINK_WITHDRAWAL_NOW = 'LINK_WITHDRAWAL_NOW';
export const OPEN_PORTFOLIO = 'OPEN_PORTFOLIO';
export const GET_FEATURES_SUCCESS = 'GET_TOGGLES_SUCCESS';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const CLOSE_VERIFICATION_MODAL = 'CLOSE_VERIFICATION_MODAL';
export const START_BANK_VERIFICATION = 'START_BANK_VERIFICATION';
export const RE_SEND_INVEST = 'RE_SEND_INVEST';
export const GET_CPF_SUCCESS = 'GET_CPF_SUCCESS';
export const APPLY_CPF_SUCCESS = 'APPLY_CPF_SUCCESS';
export const SHOW_RDV_MODAL = 'SHOW_RDV_MODAL';
export const RDV_ERROR = 'RDV_ERROR';
export const CLOSE_RDV_MODAL_ERROR = 'CLOSE_RDV_MODAL_ERROR';
export const RDV_VERIFICATION_SUCCESS = 'RDV_VERIFICATION_SUCCESS';
export const SHOW_PURE_PROFILE_ERROR = 'SHOW_PURE_PROFILE_ERROR';
export const HOME = '/';
export const SKIP_BANK = 'SKIP_BANK';
export const GET_MORE_BANKS = 'GET_MORE_BANKS';
export const REGISTRATION_ACCOUNT_AGEREEMENT =
  '/registration/account/agreement';
export const REGISTRATION_BANKS_SPENDING_ACCOUNT =
  '/registration/banks/spendingAccount';
export const GET_DEPENDENT_USER_ACCESS_SUCCESS =
  'GET_DEPENDENT_USER_ACCESS_SUCCESS';
export const ERROR_CREATE_USER_ON_KID_REGISTRATION =
  'ERROR_CREATE_USER_ON_KID_REGISTRATION';
export const REGISTRATION_STEP_SPENDING_ACCOUNT_SUCCESS =
  'spendingAccount-success';
export const CUSTOM_PORTFOLIO = 'custom';
export const MODERATE_PLUS = 'moderate_plus';
export const REGISTRATION_INVESTMENT_RECURRING =
  '/raiz-registration/investment/recurring';
export const REMITTANCES = 'REMITTANCES';
export const FUNDING_ERROR = 'FUNDING_ERROR';
export const SET_CLOSE_ACCOUNT_STATUS = 'SET_CLOSE_ACCOUNT_STATUS';
export const SET_ZAKAT_TYPES = 'SET_ZAKAT_TYPES';
export const GET_BLOG_POSTS_LOADING = 'GET_BLOG_POSTS_LOADING';
export const SET_SECURITY_QUESTIONS = 'SET_SECURITY_QUESTIONS';
export const GET_USER_DATA_LOADING = 'GET_USER_DATA_LOADING';
export const GET_PORTFOLIO_CATEGORIES_SUCCESS =
  'GET_PORTFOLIO_CATEGORIES_SUCCESS';

export const ACCEPTANCE_DOCUMENTS = {
  CUSTOMER_AGREEMENT: 'customer_agreement',
};
export const GET_PORTFOLIOS_LOADING = 'GET_PORTFOLIOS_LOADING';
export const SET_IS_USER_LOADING = 'SET_IS_USER_LOADING';

export const SET_CURRENT_REGISTRATION_STEP = 'SET_CURRENT_REGISTRATION_STEP';
export const SET_IS_SECURITY_QUESTIONS_LOADING =
  'SET_IS_SECURITY_QUESTIONS_LOADING';
