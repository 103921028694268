/* layout translates */

/* pages translates */
import Modal from './layout/Modal/Modal.ms.i18n';
import HistoryDetails from './pages/HistoryDetails/HistoryDetails.ms.i18n';
import Login from './pages/Login/Login.ms.i18n';
import Blog from './layout/Widgets/Blog/Blog.ms.i18n';
import Statements from './pages/Statements/Statements.ms.i18n';
import RoundUps from './pages/RoundUps/RoundUps.ms.i18n';
import RecurringInvestment from './pages/RecurringInvestment/RecurringInvestment.ms.i18n';
import RecurringWidget from './layout/Widgets/RecurringWidget/RecurringWidget.ms.i18n';
import PotentialFeeCash from './pages/MyFinance/PotentialFeeCash.ms.i18n';

export default {
  ...Modal,
  ...HistoryDetails,
  ...Blog,
  ...Login,
  ...Statements,
  ...RecurringInvestment,
  ...RoundUps,
  ...RecurringWidget,
  ...PotentialFeeCash,
};
