import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  MIN_PASSWORD_LENGTH,
  PATTERN_CAPITAL,
  PATTERN_LOWER,
  PATTERN_NUMBER,
} from 'constants/validation';

export const useNewPasswordFormSchema = () => {
  const { t } = useTranslation();

  return yup
    .object({
      password: yup
        .string()
        .required(t('fields.validation.required'))
        .min(MIN_PASSWORD_LENGTH, t('fields.validation.minimum8Characters'))
        .test('capitalCase', t('fields.validation.shouldContain'), (value) =>
          PATTERN_CAPITAL.test(value),
        )
        .test(
          'lowerCase',
          t('fields.validation.shouldContainLetter'),
          (value) => PATTERN_LOWER.test(value),
        )
        .test('number', t('fields.validation.shouldContainOne'), (value) =>
          PATTERN_NUMBER.test(value),
        ),
      confirmPassword: yup
        .string()
        .required(t('fields.validation.required'))
        .oneOf([yup.ref('password')], t('fields.validation.passwordMatch')),
    })
    .required();
};
