import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import NumberFormat from 'react-number-format';
import Firebase from 'firebase/app';
import { Trans, withTranslation } from 'react-i18next';

import VerificationModal from '../VerificationModal';
import WithdrawErrorModal from '../WithdrawErrorModal';
import WithdrawAcceptModal from '../WithdrawAcceptModal';

import { getBalanceLabelTranslationKey } from './utils';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import 'styles/layout/withdraw.scss';
import * as userActions from 'store/user/actions';
import * as investActions from 'store/invest/actions';
import * as fundingAccountAction from 'store/fundingAccount/actions';
import {
  MIN_INVEST,
  DEBIT,
  SUPER_FUND_DEBIT,
  AUS,
  MYS,
} from 'constants/comonConstants';
import Loader from 'components/elements/Loader/Loader';
import {
  AU_APP,
  COUNTRY,
  CURRENCY_SYMBOL,
  DECIMAL_SEPARATOR,
  MY_APP,
  THOUSAND_SEPARATOR,
} from 'constants/localeConfigs';
import { FUNDING_PAGE } from 'store/fundingAccount/consts';
import { selectIsBankVerificationEnabled } from 'store/firebaseConfig/selectors';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Button } from 'components/elements/Button';

class WithdrawModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      memberNumberValue: '',
      withdrawValue: 0,
      investmentType: '',
      selectedFund: {},
      showFundStep: false,
      showRequestFailed: false,
      isOpenMaximumModal: false,
      showWithdrawSelect: false,
      isOpenMinimumModal: false,
      isOpenAvailableModal: false,
      changeFundStep: false,
      fundAddStep: false,
      showLoader: false,
      isOpenConfirmUnlinkModal: false,
      isOpenWithdrawConfirmed: false,
      isOpenConfirmChangeFundModal: false,
      isWithdrawErrorModalOpen: false,
      isOpenWithdrawAcceptModal: false,
      isReSendInvests: false,
    };

    this.toggleConfirmChangeFundModal =
      this.toggleConfirmChangeFundModal.bind(this);

    this.openChangeFundModal = this.openChangeFundModal.bind(this);

    this.showLoader = this.showLoader.bind(this);

    this.unlinkSuperFund = this.unlinkSuperFund.bind(this);

    this.toggleRequestFailed = this.toggleRequestFailed.bind(this);

    this.toggleFundAddStep = this.toggleFundAddStep.bind(this);

    this.toggleFundsStep = this.toggleFundsStep.bind(this);

    this.changeStep = this.changeStep.bind(this);

    this.changeWithdrawValue = this.changeWithdrawValue.bind(this);

    this.sendInvestment = this.sendInvestment.bind(this);

    this.toggleWithdrawSteps = this.toggleWithdrawSteps.bind(this);

    this.toggleConfirmUnlinkModal = this.toggleConfirmUnlinkModal.bind(this);

    this.handleChangeInvestmentType =
      this.handleChangeInvestmentType.bind(this);

    this.openWithdrawAcceptModal = this.openWithdrawAcceptModal.bind(this);

    this.toggleMaximumModal = this.toggleMaximumModal.bind(this);

    this.toggleWithdrawConfirmed = this.toggleWithdrawConfirmed.bind(this);

    this.openWithdrawErrorModal = this.openWithdrawErrorModal.bind(this);

    this.closeVerificationModal = this.closeVerificationModal.bind(this);

    this.setupFunding = this.setupFunding.bind(this);
  }

  componentDidMount() {
    const { actionsInvest, actionsFunding } = this.props;

    actionsInvest.getBillingAccounts();

    actionsFunding.getFundingAccount();

    if (MY_APP) {
      this.handleChangeInvestmentType(DEBIT);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { withdrawValue, isReSendInvests } = this.state;
    const { user } = this.props;

    if (newProps.withdrawAllMoney && withdrawValue === '') {
      this.setState({ withdrawValue: user.current_balance });
    }

    if (newProps.isReSendInvests && !isReSendInvests) {
      this.setState({ isReSendInvests: true });

      this.sendInvestment();
    }
  }

  handleChange(e) {
    this.setState({ memberNumberValue: e.target.value });
  }

  handleChangeInvestmentType(type) {
    this.setState({ investmentType: type });
  }

  setSuperFund() {
    const { actionsInvest } = this.props;
    const { selectedFund, memberNumberValue } = this.state;

    this.showLoader(true);

    actionsInvest
      .setSuperFund({
        member_id: memberNumberValue,
        super_fund_id: selectedFund.id,
      })
      .then(() => {
        const { investErrorMessage } = this.props;

        if (!investErrorMessage) {
          actionsInvest.getBillingAccounts().then(() => {
            this.toggleConfirmChangeFundModal(false);

            this.setState({ fundAddStep: false });

            this.showLoader(false);

            this.toggleFundsStep(true);
          });
        } else {
          this.toggleConfirmChangeFundModal(false);

          this.showLoader(false);

          this.toggleRequestFailed(true);
        }
      });
  }

  setupFunding() {
    const { history } = this.props;

    this.setState({ isOpenWithdrawAcceptModal: false, showLoader: false });

    history.push(FUNDING_PAGE);
  }

  sendInvestment() {
    const { actionsUser, isKidsEdit, childId, bankVerificationOn } = this.props;
    const { withdrawValue } = this.state;

    this.showLoader(true);

    Firebase.analytics().logEvent('Deposit_Withdraw', {
      eventKey: 'Withdraw',
      label: 'amount',
      value: withdrawValue,
      investmentType: DEBIT,
    });

    const requestData = isKidsEdit
      ? {
          amount: withdrawValue,
          type: DEBIT,
          dependent_user_id: childId,
        }
      : {
          investment: {
            amount: withdrawValue,
            type: DEBIT,
          },
        };

    actionsUser.sendInvests(requestData, isKidsEdit).then((statusCode) => {
      if ((statusCode !== 406 && bankVerificationOn) || !bankVerificationOn) {
        const { errorMessage } = this.props;

        this.showLoader(false);

        if (errorMessage) {
          this.openWithdrawErrorModal({
            isWithdrawErrorModalOpen: true,
            errorMessage,
          });
        } else {
          Firebase.analytics().logEvent('WithdrawConfirmed');

          this.toggleWithdrawConfirmed(true);

          this.openWithdrawAcceptModal(false);

          if (!MY_APP) {
            this.toggleWithdrawSteps(false);
          }
        }
      }
    });
  }

  unlinkSuperFund() {
    const { actionsInvest } = this.props;

    this.showLoader(true);

    actionsInvest.deleteBillingAccount().then(() => {
      this.toggleConfirmUnlinkModal(false);

      this.setState({
        showFundStep: false,
        showWithdrawSelect: true,
      });

      actionsInvest.getBillingAccounts().then(() => {
        this.showLoader(false);
      });
    });
  }
  /* TODO: Refactor toggling */

  toggleRequestFailed(condition) {
    this.setState({ showRequestFailed: condition });
  }

  showLoader(condition) {
    this.setState({ showLoader: condition });
  }

  toggleWithdrawConfirmed(condition) {
    const { toggleWithdrawModal } = this.props;

    this.setState({ isOpenWithdrawConfirmed: condition });

    if (!condition) {
      toggleWithdrawModal(false);
    }
  }

  openWithdrawErrorModal({
    isWithdrawErrorModalOpen,
    errorMessage,
    isAddWithdrawal = false,
  }) {
    const { history } = this.props;

    this.setState({ isWithdrawErrorModalOpen, errorMessage });

    this.openWithdrawAcceptModal(false);

    if (isAddWithdrawal) {
      history.push('/settings/withdrawal-account');
    }
  }

  changeWithdrawValue(value) {
    this.setState({ withdrawValue: value || 0.0 });
  }

  toggleWithdrawSteps(isWithdrawConfirmationModalVisible) {
    Firebase.analytics().logEvent('Withdraw');

    if (AU_APP) {
      this.openWithdrawAcceptModal(true);
    } else {
      this.setState({
        isOpenWithdrawAcceptModal: isWithdrawConfirmationModalVisible,
      });
    }
  }

  changeStep(condition) {
    this.setState({ showWithdrawSelect: condition });

    this.toggleMaximumModal(false);
  }

  toggleMaximumModal(condition, roundToMaximumBalance = false) {
    this.setState({ isOpenMaximumModal: condition, roundToMaximumBalance });
  }

  toggleConfirmChangeFundModal(condition) {
    this.setState({ isOpenConfirmChangeFundModal: condition });
  }

  toggleFundsStep(condition) {
    this.setState({ showFundStep: condition, showWithdrawSelect: !condition });
  }

  toggleAvailableModal(condition) {
    this.setState({ isOpenAvailableModal: condition });
  }

  toggleMinimumModal(condition) {
    if (!condition) {
      Firebase.analytics().logEvent('WithdrawCancel');
    }

    this.setState({ isOpenMinimumModal: condition });
  }

  openWithdrawAcceptModal(condition) {
    this.setState({ isOpenWithdrawAcceptModal: condition });
  }

  toggleConfirmUnlinkModal(condition) {
    this.setState({ isOpenConfirmUnlinkModal: condition });
  }

  openChangeFundModal(condition) {
    this.setState({ changeFundStep: condition });

    const { actionsInvest } = this.props;

    this.showLoader(true);

    actionsInvest.getSuperFundsList().then(() => {
      this.showLoader(false);
    });
  }

  toggleFundAddStep(condition, selectedFund) {
    const { changeFundStep } = this.state;

    this.setState({
      showFundStep: false,
      fundAddStep: condition,
      changeFundStep: !changeFundStep,
      selectedFund: selectedFund || {},
      memberNumberValue: '',
    });
  }

  closeVerificationModal() {
    const { actionsUser } = this.props;

    this.setState({ isOpenWithdrawAcceptModal: false, showLoader: false });

    actionsUser.closeVerificationModal();
  }

  minimumModal() {
    const { t } = this.props;
    const { isOpenMinimumModal } = this.state;

    return (
      <Modal
        isOpen={isOpenMinimumModal}
        onRequestClose={() => this.toggleMinimumModal(false)}
        contentLabel="time-investment-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.minimumModal.title', {
            value: formatNumber({ value: MIN_INVEST }),
          })}
        </div>

        <div className="time-investment__modal-content">
          <Trans
            i18nKey="withdrawModal.minimumModal.description"
            t={t}
            components={{
              value: formatNumber({ value: MIN_INVEST }),
            }}
          />
        </div>

        <div className="time-investment__modal-footer">
          <DeprecatedButton
            title={t('common.cancel')}
            buttonClass="button__basic-transparent -black"
            handleClick={() => this.toggleMinimumModal(false)}
          />

          <Button
            label={t('withdrawModal.minimumModal.withdrawValueButton', {
              value: formatNumber({ value: MIN_INVEST }),
            })}
            onClick={() => this.toggleWithdrawSteps(true)}
          />
        </div>
      </Modal>
    );
  }

  availableModal() {
    const { isOpenAvailableModal, withdrawValue } = this.state;
    const { availableBalance, t } = this.props;

    return (
      <Modal
        isOpen={isOpenAvailableModal}
        onRequestClose={() => this.toggleAvailableModal(false)}
        contentLabel="available-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick -left-align"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.availableModal.title')}
        </div>

        <div className="time-investment__modal-content">
          <Trans
            i18nKey="withdrawModal.availableModal.description"
            t={t}
            components={{
              withdrawValue: formatNumber({ value: withdrawValue }),
              availableBalance: formatNumber({ value: availableBalance }),
            }}
          />
        </div>

        <div className="time-investment__modal-footer -single-btn">
          <Button
            label={t('common.ok')}
            onClick={() => this.toggleAvailableModal(false)}
          />
        </div>
      </Modal>
    );
  }

  maximumModal() {
    const { t } = this.props;
    const { isOpenMaximumModal, roundToMaximumBalance } = this.state;

    const modalTitle = {
      [AUS]: t('withdrawModal.maximumModal.title.withdrawBalance'),
      [MYS]: t('withdrawModal.maximumModal.title.withdrawBalance'),
    };

    const modalContent = {
      [AUS]: roundToMaximumBalance
        ? t('withdrawModal.maximumModal.description.withdrawBalanceMaximum')
        : t('withdrawModal.maximumModal.description.withdrawBalanceMoreMax'),

      [MYS]: t('withdrawModal.maximumModal.description.withdrawBalanceInfo'),
    };

    return (
      <Modal
        isOpen={isOpenMaximumModal}
        onRequestClose={() => this.toggleMaximumModal(false)}
        contentLabel="available-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {modalTitle[COUNTRY]}
        </div>

        <div className="time-investment__modal-content">
          {modalContent[COUNTRY]}
        </div>

        {(!AU_APP || roundToMaximumBalance) && (
          <div className="time-investment__modal-footer">
            <DeprecatedButton
              title={t('common.cancel')}
              buttonClass="button__basic-transparent -black"
              handleClick={() => this.toggleMaximumModal(false)}
            />

            <Button
              label={t('common.withdraw')}
              onClick={() => {
                if (MY_APP) {
                  this.sendInvestment();

                  this.openWithdrawAcceptModal(false);

                  this.toggleMaximumModal(false);
                } else {
                  this.changeStep(true);
                }
              }}
            />
          </div>
        )}

        {AU_APP && !roundToMaximumBalance && (
          <div className="time-investment__modal-footer -single-btn">
            <Button
              label={t('common.ok')}
              onClick={() => this.toggleMaximumModal(false)}
            />
          </div>
        )}
      </Modal>
    );
  }

  requestFailedModal() {
    const { showRequestFailed } = this.state;
    const { investErrorMessage, t } = this.props;

    return (
      <Modal
        isOpen={showRequestFailed}
        onRequestClose={() => this.toggleRequestFailed(false)}
        contentLabel="available-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.requestFailedModal.title')}
        </div>

        <div className="time-investment__modal-content">
          {investErrorMessage}
        </div>

        <div className="time-investment__modal-footer -single-btn">
          <Button
            label={t('common.ok')}
            onClick={() => this.toggleRequestFailed(false)}
          />
        </div>
      </Modal>
    );
  }

  confirmUnlinkModal() {
    const { isOpenConfirmUnlinkModal, showLoader } = this.state;
    const { t } = this.props;

    return (
      <Modal
        isOpen={isOpenConfirmUnlinkModal}
        onRequestClose={() => this.toggleConfirmUnlinkModal(false)}
        contentLabel="confirm-unlink-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.confirmUnlinkModal.title')}
        </div>

        <div className="time-investment__modal-content">
          {t('withdrawModal.confirmUnlinkModal.description')}
        </div>

        <div className="time-investment__modal-footer">
          <DeprecatedButton
            withLoader={showLoader}
            title={t('common.discard')}
            buttonClass="button__basic-transparent -black"
            handleClick={() => this.toggleConfirmUnlinkModal(false)}
          />

          <Button
            isLoading={showLoader}
            label={t('common.confirm')}
            onClick={() => this.unlinkSuperFund(true)}
          />
        </div>
      </Modal>
    );
  }

  confirmChangeFundModal() {
    const { isOpenConfirmChangeFundModal, showLoader } = this.state;
    const { t } = this.props;

    return (
      <Modal
        isOpen={isOpenConfirmChangeFundModal}
        onRequestClose={() => this.toggleConfirmChangeFundModal(false)}
        contentLabel="confirm-change-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.confirmChangeFundModal.title')}
        </div>

        <div className="time-investment__modal-content">
          {t('withdrawModal.confirmChangeFundModal.description')}
        </div>

        <div className="time-investment__modal-footer">
          <DeprecatedButton
            withLoader={showLoader}
            title={t('common.back')}
            buttonClass="button__basic-transparent -black"
            handleClick={() => this.toggleConfirmChangeFundModal(false)}
          />

          <Button
            isLoading={showLoader}
            label={t('common.continue')}
            onClick={() => this.setSuperFund()}
          />
        </div>
      </Modal>
    );
  }

  withdrawConfirmed() {
    const { isOpenWithdrawConfirmed } = this.state;
    const { t } = this.props;

    return (
      <Modal
        isOpen={isOpenWithdrawConfirmed}
        onRequestClose={() => this.toggleWithdrawConfirmed(false)}
        contentLabel="time-investment-modal"
        shouldCloseOnOverlayClick
        className="modal-basic time-investment__modal time-investment__modal-quick"
      >
        <div className="time-investment__modal-title">
          {t('withdrawModal.withdrawConfirmed.title')}
        </div>

        <div className="time-investment__modal-content">
          {t('withdrawModal.withdrawConfirmed.description')}
        </div>

        <div className="time-investment__modal-footer -single-btn">
          <Button
            label={t('common.ok')}
            onClick={() => this.toggleWithdrawConfirmed(false)}
          />
        </div>
      </Modal>
    );
  }

  renderNextStep() {
    const { withdrawValue, investmentType, showLoader } = this.state;

    const {
      toggleWithdrawModal,
      billingAccounts,
      fundingAccount,
      isKidsEdit,
      t,
    } = this.props;

    Firebase.analytics().logEvent('WithdrawBank');

    return (
      <div>
        <div className="withdraw__modal-content">
          <DeprecatedButton
            buttonClass="button__close -black"
            handleClick={() => {
              toggleWithdrawModal(false);

              this.toggleWithdrawSteps(false);
            }}
          />

          <DeprecatedButton
            buttonClass="button__back"
            handleClick={() => this.changeStep(false)}
          />

          <div className="withdraw__modal-title">{t('common.withdraw')}</div>

          <div className="withdraw__modal-sub">
            <Trans
              i18nKey="withdrawModal.renderNextStep.subTitle"
              t={t}
              components={{
                value: formatNumber({ value: withdrawValue }),
              }}
            />
          </div>

          <div className="withdraw__modal-row">
            <div
              className={`withdraw__modal-item ${
                investmentType === DEBIT ? '-active' : ''
              }`}
              onClick={() => this.handleChangeInvestmentType(DEBIT)}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              <div className="withdraw__modal-item-title">
                {t('withdrawModal.renderNextStep.title.myFundingAccount')}
              </div>

              {fundingAccount.funding && (
                <div>
                  {/* eslint-disable-next-line react/jsx-newline */}
                  {fundingAccount.funding.name} ({fundingAccount.funding.last_4}
                  )
                </div>
              )}
            </div>

            {!MY_APP && !isKidsEdit && (
              <div
                className={`withdraw__modal-item ${
                  investmentType === SUPER_FUND_DEBIT ? '-active' : ''
                }`}
                onClick={() =>
                  billingAccounts.billing_account
                    ? this.handleChangeInvestmentType(SUPER_FUND_DEBIT)
                    : ''
                }
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              >
                <div className="withdraw__modal-item-title">
                  {t('withdrawModal.renderNextStep.title.mySuperFund')}
                </div>

                {billingAccounts.billing_account && (
                  <div>{billingAccounts.billing_account.name}</div>
                )}
              </div>
            )}
          </div>

          <div className="withdraw__modal-info">
            <Trans i18nKey="withdrawModal.renderNextStep.description" t={t} />
          </div>

          <Button
            isLoading={showLoader}
            label={t('common.confirm')}
            isDisabled={!investmentType}
            onClick={() => this.openWithdrawAcceptModal(true)}
          />

          {!MY_APP &&
            !isKidsEdit &&
            (billingAccounts.billing_account && !showLoader ? (
              <DeprecatedButton
                title={t(
                  'withdrawModal.renderNextStep.button.viewChangeSuperFund',
                )}
                buttonClass="button__basic-wtbackground -wide"
                handleClick={() => this.toggleFundsStep(true)}
              />
            ) : (
              <DeprecatedButton
                title={t('withdrawModal.renderNextStep.button.setupSuperFund')}
                buttonClass="button__basic-wtbackground -wide"
                handleClick={() => this.openChangeFundModal(true)}
              />
            ))}
        </div>
      </div>
    );
  }

  renderFirstStep() {
    const {
      toggleWithdrawModal,
      availableBalance,
      isStartBankVerification,
      t,
    } = this.props;

    const { withdrawValue } = this.state;

    return (
      <div>
        <div className="modal-basic__content">
          {!isStartBankVerification && (
            <DeprecatedButton
              buttonClass="button__close"
              handleClick={() => toggleWithdrawModal(false)}
            />
          )}

          <div className="modal-basic__title">{t('common.withdraw')}</div>

          <div className="modal-basic__info -top-info">
            <span className="-label">
              {t(getBalanceLabelTranslationKey())}

              {': '}
            </span>

            {formatNumber({ value: availableBalance })}
          </div>

          <div className="modal-basic__value">
            <div
              className={`modal-basic__input withdraw-modal__input ${
                withdrawValue >= MIN_INVEST ? '-active' : ''
              }`}
            >
              <NumberFormat
                value={withdrawValue}
                decimalScale={2}
                displayType="input"
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                allowNegative={false}
                placeholder={`${CURRENCY_SYMBOL}0.00`}
                prefix={CURRENCY_SYMBOL}
                onValueChange={(values) => {
                  this.changeWithdrawValue(values.floatValue);
                }}
              />
            </div>
          </div>
        </div>

        <div className="modal-basic__footer">
          <Button
            label={t('common.withdraw')}
            onClick={() => this.toggleWithdrawSteps(true)}
            isDisabled={!withdrawValue}
          />
        </div>
      </div>
    );
  }

  renderViewFundStep() {
    const { billingAccounts, t } = this.props;
    const { showLoader } = this.state;

    return (
      <div>
        <div className="withdraw__modal-content -wout-padding">
          <DeprecatedButton
            buttonClass="button__back"
            handleClick={() => this.toggleFundsStep(false)}
          />

          <div className="withdraw__modal-title">
            {t('withdrawModal.title')}
          </div>

          <div className="withdraw__fund-content">
            <div className="withdraw__fund-shadow" />

            <div className="withdraw__fund-title">
              {t('withdrawModal.renderViewFundStep.title')}
            </div>

            <div className="withdraw__fund-info">
              {t('withdrawModal.renderViewFundStep.description')}
            </div>

            <div className="withdraw__fund-details">
              <div className="withdraw__fund-container">
                <img
                  className="withdraw__fund-logo"
                  src={billingAccounts.billing_account.super_fund_logo_url}
                  alt={billingAccounts.billing_account.name}
                />
              </div>

              <div className="withdraw__fund-name">
                {billingAccounts.billing_account.name}
              </div>
            </div>

            <div className="withdraw__fund-details -fixed-hight">
              <div className="withdraw__fund-field">
                <div className="withdraw__fund-label">
                  {t('withdrawModal.fields.memberReferenceNumber.label')}
                </div>

                <input
                  disabled
                  type="text"
                  className="withdraw__fund-val"
                  value={billingAccounts.billing_account.member_id}
                />
              </div>
            </div>

            <div className="withdraw__fund-footer">
              <Button
                isLoading={showLoader}
                label={t('common.change')}
                onClick={() => this.openChangeFundModal(true)}
              />

              <DeprecatedButton
                withLoader={showLoader}
                title={t('common.unlink')}
                buttonClass="button__basic-wtbackground -wide"
                handleClick={() => this.toggleConfirmUnlinkModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChangeFundStep() {
    const { fundsList, billingAccounts, t } = this.props;
    const { showLoader } = this.state;

    return (
      <div>
        <div className="withdraw__modal-content">
          <DeprecatedButton
            buttonClass="button__back"
            handleClick={() => {
              this.openChangeFundModal(false);

              if (billingAccounts && billingAccounts.billing_account) {
                this.toggleFundsStep(true);
              } else {
                this.changeStep(true);
              }
            }}
          />

          <div className="withdraw__modal-title">
            {t('withdrawModal.title')}
          </div>

          <div className="withdraw__fund-content">
            <div className="withdraw__fund-shadow" />

            <div className="withdraw__fund-info">
              {t('withdrawModal.renderChangeFundStep.description')}
            </div>

            {showLoader ? (
              <div className="withdraw__fund-details">
                <Loader additionalClass=" -absolute -center" />
              </div>
            ) : (
              <div className="withdraw__fund-details">
                {fundsList && fundsList.super_funds
                  ? fundsList.super_funds.map((fund) => (
                      <div
                        key={fund.id}
                        role="button"
                        tabIndex="0"
                        className="withdraw__fund-item"
                        onKeyPress={() => {}}
                        onClick={() => this.toggleFundAddStep(true, fund)}
                      >
                        <div className="withdraw__fund-item-logo">
                          <img
                            src={fund.logo_url}
                            alt={fund.name}
                            className="withdraw__fund-item-img"
                          />
                        </div>

                        <div className="withdraw__fund-item-name">
                          {fund.name}
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderFundAddStep() {
    const { selectedFund, memberNumberValue } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="withdraw__modal-content">
          <DeprecatedButton
            buttonClass="button__back"
            handleClick={() => {
              this.toggleFundAddStep(false);
            }}
          />

          <div className="withdraw__modal-title">
            {t('withdrawModal.title')}
          </div>

          <div className="withdraw__fund-content">
            <div className="withdraw__fund-shadow" />

            <div className="withdraw__fund-title">{selectedFund.name}</div>

            <div className="withdraw__fund-info">
              {t('withdrawModal.renderFundAddStep.description')}
            </div>

            <div className="withdraw__fund-details">
              <div className="withdraw__fund-item">
                <div className="withdraw__fund-item-logo">
                  <img
                    src={selectedFund.logo_url}
                    alt={selectedFund.name}
                    className="withdraw__fund-item-img"
                  />
                </div>

                <div className="withdraw__fund-item-name">
                  {selectedFund.name}
                </div>
              </div>

              <div className="withdraw__fund-field">
                <div className="withdraw__fund-label">
                  {t('withdrawModal.fields.memberReferenceNumber.label')}
                </div>

                <input
                  type="text"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  className="withdraw__fund-val"
                  value={memberNumberValue}
                />
              </div>
            </div>

            <div className="withdraw__fund-footer">
              <Button
                label={t('common.save')}
                onClick={() => this.toggleConfirmChangeFundModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      isOpenWithdrawModal,
      toggleWithdrawModal,
      isShowVerificationModal,
      isStartBankVerification,
      titleVerification,
    } = this.props;

    const {
      showWithdrawSelect,
      withdrawValue,
      isOpenWithdrawAcceptModal,
      investmentType,
      isWithdrawErrorModalOpen,
      errorMessage,
      isOpenMinimumModal,
      isOpenWithdrawConfirmed,
      isOpenAvailableModal,
      isOpenMaximumModal,
      showFundStep,
      showLoader,
      changeFundStep,
      isOpenConfirmUnlinkModal,
      fundAddStep,
      isOpenConfirmChangeFundModal,
      showRequestFailed,
    } = this.state;

    const isOpenSuperFund = showFundStep || changeFundStep || fundAddStep;

    return (
      <div>
        <Modal
          isOpen={isOpenWithdrawModal}
          onRequestClose={() =>
            !isStartBankVerification ? toggleWithdrawModal(false) : {}
          }
          contentLabel="withdraw-modal"
          shouldCloseOnOverlayClick
          className="modal-basic withdraw__modal"
        >
          {!showWithdrawSelect && !isOpenSuperFund && this.renderFirstStep()}

          {showWithdrawSelect && !isOpenSuperFund && this.renderNextStep()}

          {showFundStep && !changeFundStep && this.renderViewFundStep()}

          {changeFundStep && this.renderChangeFundStep()}

          {fundAddStep && this.renderFundAddStep()}
        </Modal>

        <WithdrawAcceptModal
          showLoader={showLoader}
          withdrawValue={withdrawValue}
          isOpenWithdrawAcceptModal={isOpenWithdrawAcceptModal}
          openWithdrawAcceptModal={this.openWithdrawAcceptModal}
          isSuperFund={investmentType === SUPER_FUND_DEBIT}
          sendInvestment={this.sendInvestment}
        />

        <WithdrawErrorModal
          errorMessage={errorMessage}
          isWithdrawErrorModalOpen={isWithdrawErrorModalOpen}
          openWithdrawErrorModal={this.openWithdrawErrorModal}
        />

        <VerificationModal
          isShowVerificationModal={isShowVerificationModal}
          titleVerification={titleVerification}
          closeVerificationModal={this.closeVerificationModal}
          setupFunding={this.setupFunding}
        />

        {isOpenMinimumModal && this.minimumModal()}

        {isOpenWithdrawConfirmed && this.withdrawConfirmed()}

        {isOpenAvailableModal && this.availableModal()}

        {isOpenMaximumModal && this.maximumModal()}

        {isOpenConfirmUnlinkModal && this.confirmUnlinkModal()}

        {isOpenConfirmChangeFundModal && this.confirmChangeFundModal()}

        {showRequestFailed && this.requestFailedModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  errorMessage: state.user.errorMessage,
  investErrorMessage: state.invest.errorMessage,
  billingAccounts: state.invest.billingAccounts,
  fundsList: state.invest.fundsList,
  fundingAccount: state.fundingAccount.accountData,
  withdrawAllMoney: state.invest.withdrawAllMoney,
  isShowVerificationModal: state.user.isShowVerificationModal,
  titleVerification: state.user.titleVerification,
  isStartBankVerification: state.user.isStartBankVerification,
  isReSendInvests: state.user.isReSendInvests,
  bankVerificationOn: selectIsBankVerificationEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actionsInvest: bindActionCreators(investActions, dispatch),
  actionsFunding: bindActionCreators(fundingAccountAction, dispatch),
  actionsUser: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(WithdrawModal));

WithdrawModal.defaultProps = {
  actionsInvest: {},
  actionsFunding: {},
  actionsUser: {},
  fundsList: {},
  user: {},
  fundingAccount: {},
  billingAccounts: {},
  errorMessage: '',
  investErrorMessage: '',
  isShowVerificationModal: false,
  titleVerification: '',
  childId: '',
  isKidsEdit: false,
  isStartBankVerification: false,
  history: {},
  bankVerificationOn: false,
};

WithdrawModal.propTypes = {
  actionsInvest: PropTypes.shape({
    getBillingAccounts: PropTypes.func,
    getAllocationProfile: PropTypes.func,
    setSuperFund: PropTypes.func,
    getSuperFundsList: PropTypes.func,
    deleteBillingAccount: PropTypes.func,
  }),
  fundsList: PropTypes.shape({
    super_funds: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fundingAccount: PropTypes.shape({
    funding: PropTypes.shape({
      name: PropTypes.string,
      last_4: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    current_balance: PropTypes.number,
  }),
  actionsUser: PropTypes.shape({
    sendInvests: PropTypes.func,
    closeVerificationModal: PropTypes.func,
  }),
  actionsFunding: PropTypes.shape({
    getFundingAccount: PropTypes.func,
  }),
  billingAccounts: PropTypes.shape({
    billing_account: PropTypes.shape({
      name: PropTypes.string,
      member_id: PropTypes.string,
      super_fund_logo_url: PropTypes.string,
    }),
  }),
  availableBalance: PropTypes.number.isRequired,
  isOpenWithdrawModal: PropTypes.bool.isRequired,
  isKidsEdit: PropTypes.bool,
  toggleWithdrawModal: PropTypes.func.isRequired,
  investErrorMessage: PropTypes.string,
  childId: PropTypes.string,
  errorMessage: PropTypes.string,
  isShowVerificationModal: PropTypes.bool,
  isStartBankVerification: PropTypes.bool,
  titleVerification: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  t: PropTypes.func.isRequired,
  bankVerificationOn: PropTypes.bool,
};
