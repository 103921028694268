// TODO: https://acornsau.atlassian.net/browse/RAIZ-7027
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../styles/pages/invest.scss';
import { Perfomance } from '../../components/layout/Perfomance/Perfomance';
import Portfolio from '../../components/layout/Portfolio/Portfolio';
import OneTimeInvestment from '../../components/layout/OneTimeInvestment/OneTimeInvestment';
import RoundUpsInfo from '../../components/layout/RoundUps/RoundUpsInfo';
import RecurringWidget from '../../components/layout/Widgets/RecurringWidget/RecurringWidget';
import WithdrawModal from '../../components/layout/Modals/WithdrawModal/WithdrawModal';
import { MY_APP } from '../../constants/localeConfigs';
import Loader from '../../components/elements/Loader/Loader';
import { SUPER_ON } from '../../constants/togglesFunctions';
import KidsAccessRejectionModal from '../../components/layout/Modals/KidsAccessRejectionModal/KidsAccessRejectionModal';
import { checkIsDependentUser, checkIsSmsfUser } from '../../utils/user';

import { BannerInfo } from './components/BannerInfo';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import SuperWidget from 'components/layout/Widgets/SuperWidget/SuperWidget';
import KidsWidget from 'components/layout/Widgets/KidsWidget/KidsWidget';
import { selectIsDividendsEnabled } from 'store/firebaseConfig/selectors';
import { KIDS_ACCESS_REJECTION_SOURCE } from 'components/layout/Modals/KidsAccessRejectionModal/consts';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { DividendsWidget } from 'components/layout/Widgets/DividendsWidget/DividendsWidget';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function InvestView({
  user,
  history,
  isOpenInvestmentModal,
  isOpenWithdrawModal,
  handleOpenModal,
  toggleWithdrawModal,
  isRejectionModalOpen,
  toggleKidsAccessRejectionModal,
}) {
  let accessInvesting = true;
  const { t } = useTranslation();

  const isDividendsEnabled = useAppSelector(selectIsDividendsEnabled);

  if (checkIsDependentUser(user)) {
    const access = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
    );

    if (access && !access.investing) {
      accessInvesting = false;
    }
  }

  return (
    <div className="super__content page-content">
      {!user && !user.email && <Loader additionalClass="-absolute -top" />}

      {user && user.email && (
        <div className="page-content__full">
          <div className="banner-background__container active">
            <div className="invest__banner page-content__banner">
              {!MY_APP && (
                <div>
                  <div className="page-content__banner-title">
                    {t('investPage.investCard.title')}
                  </div>

                  <div className="page-content__banner-label">
                    {t('investPage.investCard.label')}
                  </div>

                  <div className="page-content__banner-value">
                    {formatNumber({
                      value: user.current_balance || 0,
                    })}
                  </div>
                </div>
              )}

              <BannerInfo />

              <DeprecatedButton
                title={
                  <span>
                    <i className="page-content__banner-icon -deposit" />

                    {t('investPage.investCard.button.deposit')}
                  </span>
                }
                buttonClass="button__basic-white"
                handleClick={() => handleOpenModal(true)}
              />

              {!checkIsDependentUser(user) && (
                <DeprecatedButton
                  title={
                    <span>
                      <i className="page-content__banner-icon -withdraw" />

                      {t('investPage.investCard.button.withdraw')}
                    </span>
                  }
                  buttonClass="button__basic-white"
                  handleClick={() => toggleWithdrawModal(true)}
                />
              )}
            </div>
          </div>

          <div className="page-content__dashboard">
            <RoundUpsInfo
              toggleKidsAccessRejectionModal={toggleKidsAccessRejectionModal}
              accessInvesting={accessInvesting}
            />

            <Perfomance />
          </div>

          <div className="page-content__dashboard">
            <OneTimeInvestment
              isOpenInvestmentModal={isOpenInvestmentModal}
              handleChangeOpenInvestmentModal={handleOpenModal}
              history={history}
            />

            <Portfolio />
          </div>

          <div className="page-content__dashboard">
            <div className="page-content__dashboard-col">
              <RecurringWidget />

              {isDividendsEnabled && <DividendsWidget />}
            </div>

            {!checkIsDependentUser(user) && (
              <div className="page-content__dashboard-col">
                {SUPER_ON &&
                  !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
                  user &&
                  !user.super_annuation_user && <SuperWidget />}

                {!checkIsSmsfUser(user) && <KidsWidget />}
              </div>
            )}
          </div>
        </div>
      )}

      {isOpenWithdrawModal && (
        <WithdrawModal
          isOpenWithdrawModal={isOpenWithdrawModal}
          toggleWithdrawModal={toggleWithdrawModal}
          availableBalance={user.current_balance || 0}
          history={history}
        />
      )}

      {isRejectionModalOpen && (
        <KidsAccessRejectionModal
          isRejectionModalOpen={isRejectionModalOpen}
          toggleKidsAccessRejectionModal={toggleKidsAccessRejectionModal}
          rejectionSource={KIDS_ACCESS_REJECTION_SOURCE.invest}
        />
      )}
    </div>
  );
}

InvestView.defaultProps = {
  isOpenInvestmentModal: false,
  isRejectionModalOpen: false,
  user: {},
};

InvestView.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  isRejectionModalOpen: PropTypes.bool,
  isOpenInvestmentModal: PropTypes.bool,
  isOpenWithdrawModal: PropTypes.bool.isRequired,
  toggleKidsAccessRejectionModal: PropTypes.func.isRequired,
  toggleWithdrawModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
    email: PropTypes.string,
    current_balance: PropTypes.number,
    account_pending_balance: PropTypes.number,
    super_annuation_user: PropTypes.bool,
  }),
};
