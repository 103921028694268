import React from 'react';
import '../../styles/pages/history.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CancelTransactionModal from '../../components/layout/Modals/CancelTransactionModal';
import BankErrorModal from '../../components/layout/Modals/BankErrorModal';
import RecurringModal from '../../components/layout/Modals/RecurringModal';
import InviteModal from '../../components/layout/Modals/InviteModal';

import SuperHistoryBanner from './components/SuperHistoryBanner';
import HistoryInfoPanel from './components/HistoryInfoPanel';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function HistoryDetailsView({
  isLoading,
  toggleShowFailedTransactions,
  currentTransaction,
  areFailedTransactionsVisible,
  transactions,
  isCancelTransactionModalOpen,
  isCancelTransactionLoading,
  toggleInfoModal,
  showTransactionDetail,
  displayCancelTransactionConfirmationAlert,
  handleCloseModal,
  isSuper,
  isRecurringModalOpen,
  currentInfo,
  isShareRewardModalOpen,
  toggleShareRewardModal,
  checkIsLastTransactionInView,
}) {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.historyPreviusData, '');

  const { transactionDetails, isShowErrorModal, errorMessage } = useSelector(
    (state) => ({
      transactionDetails: state.history.transactionDetails || {},
      isShowErrorModal: state.history.isShowErrorModal,
      errorMessage: state.history.errorMessage,
    }),
  );

  const { id, type } = transactionDetails;
  const { t } = useTranslation();
  const rewardName = id ? type.replace(': Raiz Reward', '') : { offer: {} };

  return (
    <div className={isSuper ? 'super' : ''}>
      {isSuper && <SuperHistoryBanner toggleInfoModal={toggleInfoModal} />}

      <HistoryInfoPanel
        isSuper={isSuper}
        areFailedTransactionsVisible={areFailedTransactionsVisible}
        toggleShowFailedTransactions={toggleShowFailedTransactions}
        isLoading={isLoading}
        transactions={transactions}
        showTransactionDetail={showTransactionDetail}
        transactionDetails={transactionDetails}
        displayCancelTransactionConfirmationAlert={
          displayCancelTransactionConfirmationAlert
        }
        toggleShareRewardModal={toggleShareRewardModal}
        checkIsLastTransactionInView={checkIsLastTransactionInView}
      />

      {isShowErrorModal && errorMessage && (
        <BankErrorModal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowErrorModal}
          shouldCloseOnOverlayClick
          errorMessage={errorMessage}
          handleClick={handleCloseModal}
        />
      )}

      {isRecurringModalOpen && (
        <RecurringModal
          isOpen={isRecurringModalOpen}
          onRequestClose={() =>
            toggleInfoModal({
              shouldOpenRecurringModal: false,
            })
          }
          handleClick={() =>
            toggleInfoModal({
              shouldOpenRecurringModal: false,
            })
          }
          modalClassName="time-investment__modal"
          modalTitle={currentInfo.title ? t(`${currentInfo.title}`) : null}
          modalContent={t(`${currentInfo.msg}`)}
          modalFooterClassName="-single-btn"
        />
      )}

      {isCancelTransactionModalOpen && (
        <CancelTransactionModal
          isCancelTransactionModalOpen={isCancelTransactionModalOpen}
          displayCancelTransactionConfirmationAlert={
            displayCancelTransactionConfirmationAlert
          }
          isCancelTransactionLoading={isCancelTransactionLoading}
          isSuper={isSuper}
          currentTransaction={currentTransaction}
        />
      )}

      {isShareRewardModalOpen && (
        <InviteModal
          showModal={isShareRewardModalOpen}
          isRewardShareModalVisible={isShareRewardModalOpen}
          handleCloseModal={toggleShareRewardModal}
          rewardName={rewardName}
        />
      )}
    </div>
  );
}

HistoryDetailsView.defaultProps = {
  currentTransaction: {},
};

HistoryDetailsView.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTransactionDetail: PropTypes.func.isRequired,
  displayCancelTransactionConfirmationAlert: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isCancelTransactionLoading: PropTypes.bool.isRequired,
  isCancelTransactionModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleInfoModal: PropTypes.func.isRequired,
  isSuper: PropTypes.bool,
  isRecurringModalOpen: PropTypes.bool.isRequired,
  currentInfo: PropTypes.shape({
    title: PropTypes.string,
    msg: PropTypes.string,
  }).isRequired,
  currentTransaction: PropTypes.shape({}),
  toggleShowFailedTransactions: PropTypes.func.isRequired,
  areFailedTransactionsVisible: PropTypes.bool.isRequired,
  isShareRewardModalOpen: PropTypes.bool.isRequired,
  toggleShareRewardModal: PropTypes.func.isRequired,
  checkIsLastTransactionInView: PropTypes.func.isRequired,
};

HistoryDetailsView.defaultProps = {
  currentTransaction: {},
  isSuper: false,
};
