import { useEffect } from 'react';

export const useExternalScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;

    script.async = true;

    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
