import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentContainer,
  ContentInfo,
  ContentTitle,
  ContentWrapper,
  FooterInfo,
  FooterTitle,
  FooterWrapper,
  HeaderWrapper,
  InfoIcon,
  SecuredIcon,
  Title,
} from './styles';
import { IContentProps } from './types';
import { CheckboxComponent } from './Checkbox';

export const SuperRewardContent = ({
  showLoader,
  transferRewards,
  togglePersonalContribution,
}: IContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ContentWrapper>
        <HeaderWrapper>
          <Title>{t('superPage.superRewardContribution.title')}</Title>
        </HeaderWrapper>

        <ContentContainer>
          <ContentTitle>
            <SecuredIcon iconName="secured" size={24} />

            {t('superPage.superRewardContribution.securedLabel')}
          </ContentTitle>

          <ContentInfo>
            {t('superPage.superRewardContribution.chooseToTransfer')}
          </ContentInfo>

          <CheckboxComponent
            showLoader={showLoader}
            transferRewards={transferRewards}
            togglePersonalContribution={togglePersonalContribution}
          />
        </ContentContainer>
      </ContentWrapper>

      <FooterWrapper>
        <InfoIcon iconName="infoOutlined" size={24} />

        <div>
          <FooterTitle>
            {t('superPage.superRewardContribution.footerTitle')}
          </FooterTitle>

          <FooterInfo>
            {t('superPage.superRewardContribution.footerInfo')}
          </FooterInfo>
        </div>
      </FooterWrapper>
    </>
  );
};
