import styled from '@emotion/styled';

export const BannerInfoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 25px;
`;

export const BannerInfoLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-top: 12px;
`;

export const BannerInfoValue = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
