import styled from '@emotion/styled';

export const BannerValue = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fontFamily.regular};
    font-size: ${theme.fontSizes['4xl']};
    font-weight: ${theme.fontWeights.normal};
    letter-spacing: ${theme.letterSpacing.tighter};
    line-height: ${theme.lineHeights.number.lg};
    margin-top: 20px;
  `}
`;

export const BannerRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0 30px;
`;

export const BannerCol = styled.div`
  align-items: center;
  display: flex;
  margin-left: 27px;
  margin-right: 27px;
`;
