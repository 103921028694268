import styled from '@emotion/styled';

import { colorKey } from 'theme/colors';
import { Button } from 'components/elements/Button';

export const FormStyled = styled.form`
  padding-top: 24px;
  width: 100%;
`;

export const SubmitButton = styled(Button)<{ backgroundColor: colorKey }>`
  margin-top: 20px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
`;
