import { IFastlinkContentProps } from '../types';
import { useFastlinkContent } from '../hooks/useFastlinkContent';
import { FASTLINK_CONTAINER_ID } from '../consts';

import { Container } from './styles';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';

export const FastlinkContent = ({
  toggleFastlinkModal,
  type,
}: IFastlinkContentProps) => {
  const { isFetching } = useFastlinkContent({
    toggleFastlinkModal,
    type,
  });

  if (isFetching) return <DotsLoader />;

  return <Container id={FASTLINK_CONTAINER_ID} />;
};
