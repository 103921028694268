import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  RewardsErrorModalFooter,
  RewardsErrorModalContent,
  RewardsErrorModalTitle,
  RewardsErrorModalWrapper,
} from './styles';

import { setRewardErrorMessage } from 'store/rewards/actions';
import { Button } from 'components/elements/Button';

function RewardsErrorModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rewardErrorMessage } = useSelector((state) => ({
    rewardErrorMessage: state.rewards.rewardErrorMessage,
  }));

  const closeRewardsErrorModal = () => {
    dispatch(setRewardErrorMessage(''));

    navigate('/');
  };

  return (
    <RewardsErrorModalWrapper
      isOpen={rewardErrorMessage}
      onRequestClose={closeRewardsErrorModal}
      shouldCloseOnOverlayClick
    >
      <RewardsErrorModalTitle>{t('common.oops')}</RewardsErrorModalTitle>

      <RewardsErrorModalContent>{rewardErrorMessage}</RewardsErrorModalContent>

      <RewardsErrorModalFooter>
        <Button label={t('common.ok')} onClick={closeRewardsErrorModal} />
      </RewardsErrorModalFooter>
    </RewardsErrorModalWrapper>
  );
}

export default RewardsErrorModal;
