import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ProPortfolioEditIcon,
  ProPortfolioName,
  ProPortfolioTitleWrapper,
} from '../styles';

import { usePortfolioName } from 'pages/Portfolio/hooks';

export default function ProPortfolioTitle({ setIsThemeEditModalOpen }) {
  const { t } = useTranslation();
  const portfolioName = usePortfolioName();

  return (
    <ProPortfolioTitleWrapper>
      <ProPortfolioName>
        {portfolioName || t('portfolio.defaultTitle')}
      </ProPortfolioName>

      <ProPortfolioEditIcon onClick={() => setIsThemeEditModalOpen(true)} />
    </ProPortfolioTitleWrapper>
  );
}

ProPortfolioTitle.propTypes = {
  setIsThemeEditModalOpen: PropTypes.func.isRequired,
};
