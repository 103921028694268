import React from 'react';

import { fundDetails } from '../../../../../mocks';

import { TopHoldingsItemsWrapper } from './styles';
import TopHoldingsItem from './TopHoldingsItem';

export default function TopHoldingsItems() {
  const topHoldings = fundDetails.top_holdings;

  return (
    <TopHoldingsItemsWrapper>
      {topHoldings.map((topHolding) => (
        <TopHoldingsItem
          key={window.crypto.randomUUID()}
          topHolding={topHolding}
        />
      ))}
    </TopHoldingsItemsWrapper>
  );
}
