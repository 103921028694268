import { Outlet } from 'react-router-dom';

import { useUserData } from '../hooks/useUserData';

import { AccountMigrationContentWrapper } from './styles';
import { useMigrationAccountSteps } from './consts';

import Loader from 'components/elements/Loader/Loader';
import { Stepper } from 'components/elements/Stepper';

export default function AccountMigrationContent() {
  const { isLoading } = useUserData();
  const steps = useMigrationAccountSteps();

  return (
    <AccountMigrationContentWrapper>
      <Stepper steps={steps} />

      {isLoading ? <Loader /> : <Outlet />}
    </AccountMigrationContentWrapper>
  );
}
