import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/elements/Button';

function RoundUpsHelpCard({ isHelpCardOpen, setIsHelpCardOpen }) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isHelpCardOpen}
      onRequestClose={() => setIsHelpCardOpen(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick  -help"
    >
      <div className="time-investment__modal-content">
        {t('roundUps.helpCard.description')}
      </div>

      <div className="time-investment__modal-footer -single-btn">
        <Button
          label={t('common.ok')}
          onClick={() => setIsHelpCardOpen(false)}
        />
      </div>
    </Modal>
  );
}

RoundUpsHelpCard.defaultProps = {
  isHelpCardOpen: false,
  setIsHelpCardOpen: () => {},
};

RoundUpsHelpCard.propTypes = {
  isHelpCardOpen: PropTypes.bool,
  setIsHelpCardOpen: PropTypes.func,
};

export default RoundUpsHelpCard;
