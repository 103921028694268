import { useTranslation, Trans } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  UploadButton,
  CloseButton,
  Description,
  ExternalLinkStyled,
  Header,
  ModalWrapper,
  Title,
  FileUploadFieldWrapper,
} from './styles';

import { useUploadBirthCertificateMutation } from 'store/dependentUser/mutations/uploadBirthCertificate';
import { FileUploadField } from 'components/form/fileUpload';
import { PRIVACY_POLICY } from 'constants/localeConfigs';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

interface IBirthCertificateUploadModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  size?: number;
}

export const DEFAULT_UPLOAD_MODAL_SIZE = 455;

export const UploadBirthCertificateModal = ({
  isOpen,
  onRequestClose = () => {},
  size = DEFAULT_UPLOAD_MODAL_SIZE,
}: IBirthCertificateUploadModalProps) => {
  const { t } = useTranslation();

  const [upload, { isLoading }] = useUploadBirthCertificateMutation();

  const form = useForm<{ file: File | null }>({
    defaultValues: { file: null },
  });

  const { handleSubmit, watch, reset } = form;

  const dependent_user_id = useParams().childId || '';

  const handleClose = () => {
    reset();

    onRequestClose();
  };

  const dispatch = useAppDispatch();

  const showSuccessModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('raizKidsEditPage.uploadBirthCertificateSuccessModal.title'),
          description: t(
            'raizKidsEditPage.uploadBirthCertificateSuccessModal.description',
          ),
        },
      }),
    );
  };

  const onClick = handleSubmit(({ file }) => {
    if (file === null) {
      return;
    }

    upload({
      file,
      dependentUserId: dependent_user_id,
    })
      .unwrap()
      .then(showSuccessModal)
      .finally(handleClose);
  });

  const file = watch('file');

  return (
    <FormProvider {...form}>
      <ModalWrapper isOpen={isOpen} onRequestClose={handleClose} size={size}>
        <Header>
          <Title>
            {t('raizKidsEditPage.uploadBirthCertificateModal.title')}
          </Title>

          <CloseButton onClick={handleClose} />
        </Header>

        <Description>
          <Trans
            t={t}
            i18nKey="raizKidsEditPage.uploadBirthCertificateModal.description"
            components={{
              link: (
                <ExternalLinkStyled
                  href={PRIVACY_POLICY}
                  title={t('common.privacyPolicy')}
                />
              ),
            }}
          />
        </Description>

        <FileUploadFieldWrapper>
          <FileUploadField
            name="file"
            label={t(
              'raizKidsEditPage.uploadBirthCertificateModal.fileUpload.label',
            )}
          />
        </FileUploadFieldWrapper>

        <UploadButton
          onClick={onClick}
          type="button"
          label={t('raizKidsEditPage.uploadBirthCertificateModal.button')}
          isDisabled={!file}
          isLoading={isLoading}
        />
      </ModalWrapper>
    </FormProvider>
  );
};
