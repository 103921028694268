import { useTranslation } from 'react-i18next';

import { StopInvestmentButtonWrapper } from './styles';
import { useStopRecurringInvestment } from './hooks';
import { useStopInvestmentConfirmModal } from './hooks/useStopInvestmentConfirmModal';
import { useRecurringInvestment } from './hooks/useRecurringInvestment';
import { useRecurringInvestmentFrequency } from './utils';

export default function StopInvestmentButton() {
  const { t } = useTranslation();

  const { recurring } = useRecurringInvestment();
  const { frequency_identifier } = recurring || {};

  const recurringInvestmentFrequency =
    useRecurringInvestmentFrequency(frequency_identifier);

  const { isDependencyUsersLoading } = useStopRecurringInvestment();

  const handleStopInvestmentConfirmModal = useStopInvestmentConfirmModal();

  return (
    <StopInvestmentButtonWrapper
      isLoading={isDependencyUsersLoading}
      onClick={handleStopInvestmentConfirmModal}
      label={t('raizKidsEditPage.recurringInvestmentForm.stopInvestment', {
        frequency: recurringInvestmentFrequency,
      })}
      variant="initial"
    />
  );
}
