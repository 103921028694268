import { defineMessages } from 'react-intl';

import { HISTORY as idPrefix } from '../../constants/translates';

const messages = defineMessages({
  page: {
    id: `${idPrefix}.page`,
    defaultMessage: 'History Details',
  },
  history: {
    id: `${idPrefix}.history`,
    defaultMessage: 'History',
  },
  netGainLoss: {
    id: `${idPrefix}.history`,
    defaultMessage: 'Net Gain / Loss',
  },
  totalInvested: {
    id: `${idPrefix}.totalInvested`,
    defaultMessage: 'Total Invested',
  },
  invested: {
    id: `${idPrefix}.invested`,
    defaultMessage: 'Invested',
  },
  pending: {
    id: `${idPrefix}.pending`,
    defaultMessage: 'Pending',
  },
  cancel: {
    id: `${idPrefix}.cancel`,
    defaultMessage: 'Cancel',
  },
  empty: {
    id: `${idPrefix}.empty`,
    defaultMessage: ' ',
  },
  ok: {
    id: `${idPrefix}.ok`,
    defaultMessage: 'Ok',
  },
  totalDividends: {
    id: `${idPrefix}.totalDividends`,
    defaultMessage: 'Total Dividends',
  },
  totalWithdrawn: {
    id: `${idPrefix}.totalWithdrawn`,
    defaultMessage: 'Total Withdrawn',
  },
  totalFees: {
    id: `${idPrefix}.totalFees`,
    defaultMessage: 'Total Fees',
  },
  symbol: {
    id: `${idPrefix}.symbol`,
    defaultMessage: 'Symbol',
  },
  shares: {
    id: `${idPrefix}.shares`,
    defaultMessage: 'Shares',
  },
  amount: {
    id: `${idPrefix}.amount`,
    defaultMessage: 'Amount',
  },
  buy: {
    id: `${idPrefix}.buy`,
    defaultMessage: 'Buy',
  },
  sell: {
    id: `${idPrefix}.sell`,
    defaultMessage: 'Sell',
  },
  rebalanced: {
    id: `${idPrefix}.rebalanced`,
    defaultMessage: 'Your Account has been rebalanced',
  },
  rebalancedProcess: {
    id: `${idPrefix}.rebalancedProcess`,
    defaultMessage: 'Your account is in the process of being rebalanced',
  },
  raizInvestSuper: {
    id: `${idPrefix}.raizInvestSuper`,
    defaultMessage: 'Raiz Invest Super',
  },
  employerContributions: {
    id: `${idPrefix}.employerContributions`,
    defaultMessage: 'Employer Contributions',
  },
  employerContributionsHelp: {
    id: `${idPrefix}.employerContributionsHelp`,
    defaultMessage:
      'This is the amount your employer(s) have contributed to your Raiz Invest Super.\n' +
      '\n' +
      'Your employer must contribute money to your Super fund on your behalf. This obligation is imposed ' +
      'on employers under the Super Guarantee (SG) laws. Compulsory employer contributions are often called ' +
      'SG contributions, where employers are to make Superannuation contributions for you on top of your wages ' +
      'and salaries. If you would like Raiz Invest Super to receive your employer contributions, you can email ' +
      'them the choice form.',
  },
  yourContributions: {
    id: `${idPrefix}.yourContributions`,
    defaultMessage: 'Your Contributions',
  },
  yourContributionsHelp: {
    id: `${idPrefix}.yourContributionsHelp`,
    defaultMessage:
      'This is the amount you have contributed to your Raiz Invest Super from your ' +
      'after-tax income (that is, from your take-home pay)\n' +
      '\n' +
      'These contributions are the contribution you make in addition to the compulsory super contributions ' +
      'your employer makes.\n' +
      '\n' +
      'One way to boost your balance within the Raiz App, is to make voluntary contributions to your ' +
      'Raiz Invest Super by linking your BPAY details to your Raiz account. Once linked, any withdrawals ' +
      'you make from Raiz will give you the option to make a voluntary contribution to your Super Fund, ' +
      'which may be tax deductible.',
  },
  reinvestedDividends: {
    id: `${idPrefix}.reinvestedDividends`,
    defaultMessage: 'Reinvested Dividends',
  },
  reinvestedDividendsHelp: {
    id: `${idPrefix}.reinvestedDividendsHelp`,
    defaultMessage:
      'Dividends represent a portion of profits in the form of cash paid to investors. Raiz Invest ' +
      'Super automatically reinvests this cash, helping your portfolio grow.',
  },
  marketReturns: {
    id: `${idPrefix}.marketReturns`,
    defaultMessage: 'Market Returns',
  },
  marketReturnsHelp: {
    id: `${idPrefix}.marketReturnsHelp`,
    defaultMessage:
      'Total gain/loss shows how well your investments have performed. It includes the money your ' +
      'investments have earned or lost in the market, dividends (a portion of company earnings paid to you for ' +
      'owning their stock), minus fees and costs.',
  },
  rollover: {
    id: `${idPrefix}.rollover`,
    defaultMessage: 'Rollover',
  },
  noPendingTransactions: {
    id: `${idPrefix}.noPendingTransactions`,
    defaultMessage: 'There are no investments at the moment!',
  },
  yes: {
    id: `${idPrefix}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${idPrefix}.no`,
    defaultMessage: 'No',
  },
  rolloverHelp: {
    id: `${idPrefix}.rolloverHelp`,
    defaultMessage:
      'This is the amount of superannuation you transferred into your Raiz Invest ' +
      'Super account from other Super Funds.\n' +
      ' \n' +
      'Consolidating your Super can help you keep track of your retirement savings and can help in ' +
      'avoiding multiple costs including fees and insurance. You can request a rollover through the Raiz App in ' +
      'just a couple of clicks, it may be a good way to consolidate multiple super accounts.',
  },
  bayar: {
    id: `${idPrefix}.bayar`,
  },
  gagal: {
    id: `${idPrefix}.gagal`,
  },
  failed: {
    id: `${idPrefix}.failed`,
    defaultMessage: 'Failed',
  },
  processing: {
    id: `${idPrefix}.processing`,
    defaultMessage: 'Processing',
  },
  hideFailed: {
    id: `${idPrefix}.hideFailed`,
    defaultMessage: 'Hide failed',
  },
  viewFailed: {
    id: `${idPrefix}.viewFailed`,
    defaultMessage: 'View failed',
  },
  cancelConfirm: {
    id: `${idPrefix}.cancelConfirm`,
    defaultMessage: 'Cancel',
  },
  dalamProcess: {
    id: `${idPrefix}.dalamProcess`,
    defaultMessage: 'Dalam Proses',
  },
  detailPembelian: {
    id: `${idPrefix}.detailPembelian`,
    defaultMessage: 'Detail Pembelian',
  },
  jumlahInvestasi: {
    id: `${idPrefix}.jumlahInvestasi`,
    defaultMessage: 'Jumlah Investasi',
  },
  jumlahDividen: {
    id: `${idPrefix}.jumlahDividen`,
    defaultMessage: 'Jumlah Dividen',
  },
  nav: {
    id: `${idPrefix}.nav`,
    defaultMessage: 'NAV',
  },
  jumlahUnit: {
    id: `${idPrefix}.jumlahUnit`,
    defaultMessage: 'Jumlah Unit',
  },
  noOrder: {
    id: `${idPrefix}.noOrder`,
    defaultMessage: 'No.Order:',
  },
  obigasi: {
    id: `${idPrefix}.obigasi`,
    defaultMessage: 'Obigasi',
  },
  nomorRekening: {
    id: `${idPrefix}.nomorRekening`,
    defaultMessage: 'Nomor Rekening Penerima Dana',
  },
  nilaiJual: {
    id: `${idPrefix}.nilaiJual`,
    defaultMessage: 'Nilai Jual',
  },
  pencairanDana: {
    id: `${idPrefix}.pencairanDana`,
    defaultMessage:
      'Pencairan dana akan ditransfer ke rekening kamu dalam 7 hari kerja.',
  },
  rebalanceTitle: {
    id: `${idPrefix}.rebalanceTitle`,
    defaultMessage: 'Rebalance',
  },
  reward: {
    id: `${idPrefix}.reward`,
    defaultMessage: 'Reward',
  },
  investedByYouModal: {
    id: `${idPrefix}.investedByYouModal`,
    defaultMessage: 'investedByYouModal',
  },
  referralsModal: {
    id: `${idPrefix}.referralsModal`,
    defaultMessage: 'referralsModal',
  },
  marketReturnsModal: {
    id: `${idPrefix}.marketReturnsModal`,
    defaultMessage: 'marketReturnsModal',
  },
  withdrawnModal: {
    id: `${idPrefix}.withdrawnModal`,
    defaultMessage: 'withdrawnModal',
  },
  price: {
    id: `${idPrefix}.price`,
    defaultMessage: 'Price',
  },
  alfamart: {
    id: `${idPrefix}.alfamart`,
    defaultMessage: 'Alfamart',
  },
  code: {
    id: `${idPrefix}.code`,
    defaultMessage: 'Code',
  },
  jumlahHarus: {
    id: `${idPrefix}.jumlahHarus`,
    defaultMessage: 'Jumlah harus Dibayar',
  },
  jumlahHarusFee: {
    id: `${idPrefix}.jumlahHarusFee`,
    defaultMessage: 'Jumlah harus Dibayar (termasuk biaya)',
  },
  raizRewardsInfoText: {
    id: `${idPrefix}.raizRewardsInfoText`,
    defaultMessage: 'raizRewardsInfoText',
  },
  letYourFriends: {
    id: `${idPrefix}.letYourFriends`,
    defaultMessage: 'Let Your Friends Know',
  },
});

export default messages;
