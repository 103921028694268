import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RESET_PASSWORD_TOKEN } from './consts';
import {
  ConfirmButton,
  Content,
  ContactUsLink,
  Footer,
  FooterIcon,
  Header,
  HelpIcon,
  HelpText,
  Logo,
  Title,
  Wrapper,
  TokenErrorMessage,
} from './styles';
import { useNewPasswordFormSchema } from './useNewPasswordSchema';

import { InputField } from 'components/form/input';
import { SUPPORT } from 'constants/localeConfigs';
import { useCreateNewPasswordMutation } from 'store/user/mutations';
import { PATHS } from 'constants/paths';

export default function NewPassword() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const schema = useNewPasswordFormSchema();

  const resetPasswordToken = searchParams.get(RESET_PASSWORD_TOKEN);

  const isTokenExpired = resetPasswordToken === null;

  const form = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });

  const { handleSubmit } = form;

  const [newPassword, { isLoading, error }] = useCreateNewPasswordMutation();

  const isError = isTokenExpired || !!error;

  const onSubmit = handleSubmit((formData) => {
    const requestData = {
      user: {
        confirmation_password: formData.confirmPassword,
        password: formData.password,
        reset_password_token: resetPasswordToken,
      },
    };

    newPassword(requestData)
      .unwrap()
      .then(() => navigate(PATHS.login.index));
  });

  return (
    <FormProvider {...form}>
      <Wrapper>
        <Header>
          <Title>{t('newPasswordPage.getStarted')}</Title>

          <Logo />
        </Header>

        <Content onSubmit={onSubmit}>
          <InputField
            name="password"
            label={t('newPasswordPage.fields.newPassword')}
            isPassword
          />

          <InputField
            name="confirmPassword"
            label={t('newPasswordPage.fields.confirmNewPassword')}
            isPassword
          />

          <HelpText>
            <HelpIcon />

            {t('newPasswordPage.rule')}
          </HelpText>

          {isError && (
            <TokenErrorMessage>
              {t('newPasswordPage.tokenExpired')}
            </TokenErrorMessage>
          )}

          <ConfirmButton
            isLoading={isLoading}
            isDisabled={isTokenExpired}
            type="submit"
            label={t('newPasswordPage.confirmButton')}
          />
        </Content>

        <Footer>
          <FooterIcon />

          <ContactUsLink href={SUPPORT}>
            {t('newPasswordPage.link.contactUs')}
          </ContactUsLink>
        </Footer>
      </Wrapper>
    </FormProvider>
  );
}
