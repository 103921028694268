// TODO: https://acornsau.atlassian.net/browse/RAIZ-6859
export const GET_REWARDS_OFFERS_SUCCESS = 'GET_REWARDS_OFFERS_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_BRAND_SINGLE_SUCCESS = 'GET_BRAND_SINGLE_SUCCESS';
export const GET_BRANDS_SLIDER_SUCCESS = 'GET_BRANDS_SLIDER_SUCCESS';
export const LOADING = 'LOADING';
export const GET_MONITORED_ACCOUNTS_SUCCESS = 'GET_MONITORED_ACCOUNTS_SUCCESS';
export const JOIN_OFFSETTERS_SUCCESS = 'JOIN_OFFSETTERS_SUCCESS';
export const GET_CASHBACK_SUCCESS = 'GET_CASHBACK_SUCCESS';
export const GET_ONLINE_OFFERS_SUCCESS = 'GET_ONLINE_OFFERS_SUCCESS';
export const GET_FEATURED_OFFERS_SUCCESS = 'GET_FEATURED_OFFERS_SUCCESS';
export const GET_ONLINE_OFFERS_BY_ID_SUCCESS =
  'GET_ONLINE_OFFERS_BY_ID_SUCCESS';
export const GET_INSTORE_OFFERS_SUCCESS = 'GET_INSTORE_OFFERS_SUCCESS';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_FAVOURITES_SUCCESS = 'GET_FAVOURITES_SUCCESS';
export const SET_LOCATION_SUCCESS = 'SET_LOCATION_SUCCESS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_LINK_CARD_URL_SUCCESS = 'GET_LINK_CARD_URL_SUCCESS';
export const GET_OFFSETTERS_SUCCESS = 'GET_OFFSETTERS_SUCCESS';
export const GET_REWARDS_TOTALS_SUCCESS = 'GET_REWARDS_TOTALS_SUCCESS';
export const GET_REWARDS_HISTORY_SUCCESS = 'GET_REWARDS_HISTORY_SUCCESS';
export const GET_INSURANCE_CERTIFICATES_SUCCESS =
  'GET_INSURANCE_CERTIFICATES_SUCCESS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const SET_REWARD_ERROR_MESSAGE = 'SET_REWARD_ERROR_MESSAGE';

export const ZAKAT_SOURCES = {
  ppz: 'ppz',
  lzs: 'lzs',
} as const;

export const ZAKAT_TYPES = {
  wakaf: 'ppz_wakaf',
  fitrah: 'lzs_fitrah',
} as const;

export type TZakatSource = (typeof ZAKAT_SOURCES)[keyof typeof ZAKAT_SOURCES];
