import { useTranslation } from 'react-i18next';

import { INSURANCE_STATUS, INSURANCE_STATUS_TRANSLATE_KEYS } from '../consts';

import { useSuperUserQuery } from 'store/superAnnuation/superAnnuation';

export const useInsuranceStatus = () => {
  const { t } = useTranslation();

  const { data } = useSuperUserQuery();

  const { insurance_state } = data || {};

  const insuranceTranslateKey =
    insurance_state && INSURANCE_STATUS_TRANSLATE_KEYS?.[insurance_state];

  const isOptedIn = insurance_state === INSURANCE_STATUS.opted_in;

  const isRequestedOptedIn =
    insurance_state === INSURANCE_STATUS.requested_opted_in;

  const isChangedInsuranceDisabled =
    insurance_state === INSURANCE_STATUS.requested_opted_in ||
    insurance_state === INSURANCE_STATUS.requested_opted_out;

  const statusName = insuranceTranslateKey
    ? t(
        `superInsurancePage.insuranceInfo.statusRow.status.${insuranceTranslateKey}`,
      )
    : '';

  return {
    isOptedIn,
    isRequestedOptedIn,
    statusName,
    isChangedInsuranceDisabled,
  };
};
