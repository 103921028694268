import styled from '@emotion/styled';

import bannerBackground from 'assets/images/icons/referrals/banner.svg';

export const Title = styled.h1`
  height: 24px;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  align-items: center;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  margin-bottom: 24px;

  color: ${({ theme }) => theme.colors.white};
`;

export const SubTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  margin-bottom: 20px;

  color: ${({ theme }) => theme.colors.white};
`;

export const Background = styled.div`
  width: 100%;
  display: flex;
  margin-top: 22px;
  padding: 16px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(${`${bannerBackground}`}) left 10px top -10px no-repeat,
    ${({ theme }) => theme.gradients.emeraldEnchantment};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
