import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  CustomizationFooterButton,
  CustomizationFooterWrapper,
} from '../PortfolioCustomizationContent/FundsList/styles';

import { useUpdateFunds } from './hooks';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectIsBasePortfolioFullyAllocated,
  selectPortfolioLocation,
} from 'store/portfolio/selectors';

export default function CustomizationFooter() {
  const { t } = useTranslation();

  const { isUpdateFundsLoading, handleUpdateFunds } = useUpdateFunds({});

  const { isRegistration } = useAppSelector(selectPortfolioLocation);

  const { isFundsDraft } = useSelector((state) => ({
    isFundsDraft: !!state.portfolio?.fundsToUpdate?.length,
  }));

  const isBasePortfolioFullyAllocated = useAppSelector(
    selectIsBasePortfolioFullyAllocated,
  );

  if (!isFundsDraft && !isBasePortfolioFullyAllocated) {
    return null;
  }

  const title = isUpdateFundsLoading ? (
    <DotsLoader />
  ) : (
    t('portfolioCustomization.saveChangesButton')
  );

  return (
    <CustomizationFooterWrapper isRegistration={isRegistration}>
      <CustomizationFooterButton
        isDisabled={isUpdateFundsLoading}
        label={title}
        onClick={handleUpdateFunds}
      />
    </CustomizationFooterWrapper>
  );
}
