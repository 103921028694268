import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { PERSONAL_TERMS } from '../../../store/dependentUser/types';
import { REQUIRED } from '../../../constants/errors';
import TermsAndConditionsLink from '../../../components/layout/Links/TermsAndConditionsLink/TermsAndConditionsLink';
import {
  CHECKBOX_KEY,
  IS_EXTENDED_TERMS_LIST,
  NUMBER_OF_TERMS_QUESTIONS,
  TERM_KEY,
} from '../consts';

import TermsCheckbox from './TermsCheckbox';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

const validate = (values) => {
  const errors = {};

  if (!values.checkboxes.termsQuestions_1) {
    errors.termsQuestions_1 = REQUIRED;
  }

  if (!values.checkboxes.termsQuestions_2) {
    errors.termsQuestions_2 = REQUIRED;
  }

  if (IS_EXTENDED_TERMS_LIST) {
    if (!values.checkboxes.termsQuestions_3) {
      errors.termsQuestions_3 = REQUIRED;
    }

    if (!values.checkboxes.termsQuestions_4) {
      errors.termsQuestions_4 = REQUIRED;
    }

    if (!values.checkboxes.termsQuestions_5) {
      errors.termsQuestions_5 = REQUIRED;
    }
  }

  return errors;
};

export default function Terms({
  messages,
  showLoader,
  goBack,
  handleSubmit,
  errorMessage,
}) {
  const { t } = useTranslation();

  const selectAll = (args, state, utils) => {
    Object.keys(state?.formState.values.checkboxes).forEach((fieldName) => {
      utils.changeValue(
        state,
        CHECKBOX_KEY + fieldName,
        () => !state.formState.values.selectAll,
      );
    });
  };

  const questionsArray = [...Array(NUMBER_OF_TERMS_QUESTIONS)];

  const initialValues = {
    checkboxes: questionsArray.reduce(
      (curr, next, index) => ({
        ...curr,
        [TERM_KEY + (index + 1)]: false,
      }),
      {},
    ),
    selectAll: false,
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(e) => handleSubmit(e)}
      validate={(values) => validate(values, messages)}
      mutators={{
        selectAll,
      }}
      render={({ values, hasValidationErrors, form }) => (
        <form
          className="form"
          onSubmit={(e) =>
            handleSubmit(e, values, form, hasValidationErrors, PERSONAL_TERMS)
          }
        >
          <div className="text-left">
            <Trans
              t={t}
              i18nKey="raizKidsRegistrationPage.terms.termsAndConditions"
              components={{
                link: <TermsAndConditionsLink />,
              }}
            />
          </div>

          <div className={'"form-field radio'}>
            <div className="form-field__sub-input-link -limit">
              <div className="radio-grouped">
                {questionsArray.map((e, index) => (
                  // TODO: use new Select input once migrating to react-hook-forms
                  // eslint-disable-next-line react/jsx-key
                  <TermsCheckbox
                    index={index}
                    values={values}
                    change={form.change}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="hide">{errorMessage}</div>

          <div className="buttons-part">
            <div className="form-field">
              <DeprecatedButton
                withLoader={showLoader}
                title={t('common.back')}
                buttonClass="button back"
                handleClick={() => goBack({ path: '/raiz-kids' })}
              />
            </div>

            <div className="form-field">
              <DeprecatedButton
                withLoader={showLoader}
                disabled={
                  !values.checkboxes.termsQuestions_1 ||
                  !values.checkboxes.termsQuestions_2 ||
                  (IS_EXTENDED_TERMS_LIST &&
                    (!values.checkboxes.termsQuestions_3 ||
                      !values.checkboxes.termsQuestions_4 ||
                      !values.checkboxes.termsQuestions_5))
                }
                type="submit"
                title={t('common.next')}
                buttonClass="button button--submit"
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

Terms.propTypes = {
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

Terms.defaultProps = {
  errorMessage: '',
};
