import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Loader extends Component {
  render() {
    const { additionalClass } = this.props;

    return <div className={`shapeshifter play ${additionalClass}`} />;
  }
}

Loader.defaultProps = {
  additionalClass: '',
};

Loader.propTypes = {
  additionalClass: PropTypes.string,
};
