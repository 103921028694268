import { AllocationItemsLabel, AllocationItemsWrapper } from './styles';

import { IAllocation } from 'store/history/types';
import { MY_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

interface IAllocationItemsProps {
  allocation: IAllocation;
}
export const AllocationItems = ({ allocation }: IAllocationItemsProps) => (
  <AllocationItemsWrapper>
    <AllocationItemsLabel>{allocation.symbol}</AllocationItemsLabel>

    {MY_APP && (
      <AllocationItemsLabel>
        {formatNumber({
          value: allocation.shares_price,
          minimumFractionDigits: 4,
        })}
      </AllocationItemsLabel>
    )}

    <AllocationItemsLabel>
      {formatNumber({
        value: allocation.shares,
        type: NUMBER_FORMATS.decimal,
      })}
    </AllocationItemsLabel>

    <AllocationItemsLabel>
      {formatNumber({
        value: allocation.amount,
      })}
    </AllocationItemsLabel>
  </AllocationItemsWrapper>
);
