import React from 'react';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';
import '../../../styles/layout/withdraw.scss';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Form } from 'react-final-form';

import PrivacyPolicyLink from '../Links/PrivacyPolicyLink/PrivacyPolicyLink';

import BankErrorModal from './BankErrorModal';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsAddCardModalErrorOpen } from 'store/fundingAccount/selectors';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export const CARD_NUMBER_MASK = '4999 9999 9999 9999';

export default function AddCardModal({
  isShowBankModal,
  handleCloseModal,
  handleSubmit,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  showLoader,
  handleChangeCardNumber,
  cardNumberError,
}) {
  const { t } = useTranslation();
  const isShowAddCardModalError = useAppSelector(selectIsAddCardModalErrorOpen);

  return (
    <Form
      onSubmit={(e, values, form, hasValidationErrors) =>
        handleSubmit({ e, values, form, hasValidationErrors })
      }
      render={({ form, values, hasValidationErrors }) => (
        <div>
          {errorMessage && (
            <BankErrorModal
              hasBackdrop={false}
              isVisible={false}
              isOpen={isShowAddCardModalError}
              errorTitle={errorTitle}
              errorMessage={errorMessage}
              handleClick={handleCloseModalError}
              shouldCloseOnOverlayClick
              modalClassName="bank-sign"
            />
          )}

          <Modal
            isOpen={isShowBankModal}
            onRequestClose={handleCloseModal}
            contentLabel="withdraw-modal"
            shouldCloseOnOverlayClick
            className="modal-basic bank__modal bank-sign visa-link"
          >
            <div>
              <div className="bank__modal-content">
                <DeprecatedButton
                  title={t('common.space')}
                  buttonClass="button__close -black"
                  handleClick={handleCloseModal}
                />

                <div className="bank__modal-title">
                  {t('settingsPage.linkRoundUp')}
                </div>

                <div className="bank__modal-description">
                  {t('settingsPage.insertYourVisaCardDetails')}
                </div>

                <div className="bank__modal-sub">
                  <i className="bank__modal-secured-icon" />

                  {t('settingsPage.secured')}
                </div>

                <div className="bank__modal-name">
                  <div className="visa-img" />
                </div>

                <div className="bank__modal-row">
                  <form
                    className="form"
                    onSubmit={(e) =>
                      handleSubmit({ e, values, form, hasValidationErrors })
                    }
                  >
                    <div className="bank__modal__container-item">
                      <div className="label -float-label">
                        {t('fields.cardNumber.label')}
                      </div>

                      <InputMask
                        name="cardNumber"
                        mask={CARD_NUMBER_MASK}
                        alwaysShowMask
                        permanents={[2, 5]}
                        onChange={handleChangeCardNumber}
                        maskChar=""
                        className="-float-input"
                        formatChars={{
                          8: '[23478]',
                          9: '[0-9]',
                        }}
                      />

                      {cardNumberError && (
                        <div className="error-message">{cardNumberError}</div>
                      )}
                    </div>

                    <div className="bank__modal__container-note">
                      <div className="bank__modal__container-comment-sub">
                        <Trans
                          t={t}
                          i18nKey="settingsPage.agreePolicy"
                          components={{
                            link: <PrivacyPolicyLink />,
                          }}
                        />
                      </div>
                    </div>

                    <Button
                      isLoading={showLoader}
                      label={t('settingsPage.signIn')}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    />
  );
}

AddCardModal.defaultProps = {
  isShowBankModal: false,
  showLoader: false,
};

AddCardModal.propTypes = {
  isShowBankModal: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  handleChangeCardNumber: PropTypes.func.isRequired,
  cardNumberError: PropTypes.string.isRequired,
};
