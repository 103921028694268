import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';

import PDFDocumentUploaderPreview from './components/PDFDocumentUploaderPreview';
import PDFDocumentUploaderContent from './components/PDFDocumentUploaderContent';

export const MissingDocumentUploader = ({
  type,
  handleChangeStatusStatements,
  documentType,
}) => (
  <Dropzone
    onChangeStatus={({ file }, status) => {
      handleChangeStatusStatements({ file }, status, type);
    }}
    accept="application/pdf"
    maxFiles={1}
    multiple={false}
    classNames={{ dropzone: 'registration-page__dropzone -full' }}
    canCancel
    PreviewComponent={PDFDocumentUploaderPreview}
    inputContent={
      <PDFDocumentUploaderContent documentType={documentType} type={type} />
    }
    canRemove
  />
);

MissingDocumentUploader.propTypes = {
  type: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  handleChangeStatusStatements: PropTypes.func.isRequired,
};
