import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useInvestingAccessSchema } from './useInvestingAccessSchema';
import {
  InvestingAccessFormWrapper,
  InvestmentLimitWrapper,
  SaveChangesButton,
} from './styles';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { IRadioOption, RadioField } from 'components/form/radio/RadioField';
import { NumberField } from 'components/form/number/NumberField';
import { updateInvestmentAccess } from 'store/dependentUser/actions';
import { INVESTMENT_TYPES } from 'constants/kidsConstants';
import {
  selectChild,
  selectDependencyUsersLoading,
} from 'store/dependentUser/selectors';

export interface IInvestingAccessForm {
  defaultValues: {
    investmentAccessType: string;
    investing_weekly_limit: number;
  };
}

export default function InvestingAccessForm({
  defaultValues,
}: IInvestingAccessForm) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const dependencyUser = useAppSelector(selectChild);
  const isLoading = useAppSelector(selectDependencyUsersLoading);
  const { account_access, id } = dependencyUser;

  const schema = useInvestingAccessSchema();

  const [isWeeklyLimitEnabled, setIsWeeklyLimitEnabled] = useState(false);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: {
      isWeeklyLimitEnabled,
    },
  });

  const options: IRadioOption[] = [
    {
      label: t('raizKidsEditPage.investingAccessType.unlimited'),
      value: INVESTMENT_TYPES.allow,
    },
    {
      label: t('raizKidsEditPage.investingAccessType.none'),
      value: INVESTMENT_TYPES.notAllow,
    },
    {
      label: t('raizKidsEditPage.investingAccessType.weeklyLimit'),
      value: INVESTMENT_TYPES.allowWeekly,
    },
  ];

  const { handleSubmit, watch } = form;
  const investmentAccessType = watch('investmentAccessType');

  const onSubmit = handleSubmit((values) => {
    dispatch(
      updateInvestmentAccess({
        data: values,
        dependentUserId: id,
      }),
    );
  });

  useEffect(() => {
    setIsWeeklyLimitEnabled(
      investmentAccessType === INVESTMENT_TYPES.allowWeekly,
    );
  }, [investmentAccessType]);

  return (
    <FormProvider {...form}>
      <InvestingAccessFormWrapper onSubmit={onSubmit}>
        <RadioField
          name="investmentAccessType"
          isDisabled={!account_access}
          options={options}
        />

        {isWeeklyLimitEnabled && (
          <InvestmentLimitWrapper>
            <NumberField
              name="investing_weekly_limit"
              label={t('raizKidsEditPage.weeklyLimitLabel')}
            />
          </InvestmentLimitWrapper>
        )}

        <SaveChangesButton
          isLoading={isLoading}
          type="submit"
          label={t('common.save')}
        />
      </InvestingAccessFormWrapper>
    </FormProvider>
  );
}
