import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';

import { formatNumber } from 'utils/formatters/formatNumber';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function ReactivateAccountModal({
  toggleModal,
  kid,
  activateAccount,
  showLoader,
}) {
  const { t } = useTranslation();

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(new Date(kid.date_of_birth));

  return (
    <Modal
      isOpen
      onRequestClose={() => toggleModal(false)}
      contentLabel="kids-modal"
      shouldCloseOnOverlayClick
      className="modal-basic raiz-kids__link-sent"
    >
      <img
        src={kid.icon_url}
        alt={kid.name}
        width="52"
        className="raiz-kids__card-img"
      />

      <div className="raiz-kids__menu-name">
        {t('raizKidsEditPage.age', { count: age, name: kid.name })}
      </div>

      <div className="raiz-kids__menu-value">
        {formatNumber({ value: kid.current_amount })}
      </div>

      <div className="raiz-kids__link-sent-info">
        {t('raizKidsEditPage.reActivateAccountModal.description')}
      </div>

      <DeprecatedButton
        buttonClass="button__close -black"
        handleClick={() => toggleModal(false)}
      />

      <div className="raiz-kids__link-sent-footer">
        <Button
          isLoading={showLoader}
          label={t('raizKidsEditPage.reActivateAccountModal.confirmButton')}
          onClick={() => activateAccount(kid.id)}
        />

        <DeprecatedButton
          title={t('common.cancel')}
          buttonClass="button__basic-wtbackground -black"
          handleClick={() => toggleModal(false)}
        />
      </div>
    </Modal>
  );
}

ReactivateAccountModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  activateAccount: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  kid: PropTypes.shape({
    icon_url: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    date_of_birth: PropTypes.string,
    current_amount: PropTypes.string,
  }).isRequired,
};
