import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { getPostCodeLength } from 'utils/formatters/getPostCodeLength';
import { MIN_SELECTED_VALUES } from 'constants/validation';

export const useAddressSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    street_number: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.addressForm.streetNumber'),
      }),
    ),
    address1: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.addressForm.streetName'),
      }),
    ),
    city: yup.string().required(
      t('fields.validation.requiredField', {
        fieldName: t('registrationPage.addressForm.suburb'),
      }),
    ),
    state: yup
      .array()
      .min(
        MIN_SELECTED_VALUES,
        t('fields.validation.requiredField', {
          fieldName: t('registrationPage.addressForm.state'),
        }),
      )
      .required(
        t('fields.validation.requiredField', {
          fieldName: t('registrationPage.addressForm.state'),
        }),
      ),
    zip: yup
      .number()
      .typeError(
        t('fields.validation.pleaseEnterDigits', {
          number: getPostCodeLength(),
        }),
      )
      .test(
        'min-length',
        t('fields.validation.pleaseEnterDigits', {
          number: getPostCodeLength(),
        }),
        (value) => String(value).length === getPostCodeLength(),
      ),
  });
};
