import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  IFormattedSuitability,
  TSuitabilityNameKey,
  TWatchValues,
} from '../types';
import getSuitabilityDefaultValues from '../utils/getSuitabilityDefaultValues';
import getSuitabilityRequestData from '../utils/getSuitabilityRequestData';
import { FinancialFormContent } from '../styles';
import { getAdditionalSuitabilityFields } from '../utils/getAdditionalSuitabilityFields';
import { useRecommendedPortfolioModal } from '../hooks/useRecommendedPortfolioModal';
import { useProfilePage } from '../hooks/useProfilePage';
import { useRegistrationFinancialPage } from '../hooks/useRegistrationFinancialPage';
import { useInviteCode } from '../hooks/useInviteCode';

import FinancialFormItem from './FinancialFormItem';
import { useFinancialFormSchema } from './useFinancialFormSchema';
import { FinancialFormButtons } from './FinancialFormButtons';
import { InviteCode } from './InviteCode';
import { INVITE_CODE_FIELD_NAME } from './const';

import { SUITABILITIES_PICKER_ID } from 'pages/Settings/consts';
import { SUITABILITY_NAMES } from 'constants/suitabilityConstants';
import { LinkWithdrawalAccountModal } from 'pages/Registration/components/LinkWithdrawalAccountModal/LinkWithdrawalAccountModal';
import { RecommendedPortfolioModal } from 'components/layout/Modals/RecommendedPortfolioModal/RecommendedPortfolioModal';
import {
  selectIsLinkWithdrawalAccountModalOpen,
  selectUser,
} from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useFormattedSuitabilities } from 'hooks/useFormattedSuitabilities';
import { useUpdateSuitabilityMutation } from 'store/user/mutations';
import { getUserData } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export default function FinancialForm() {
  const [updateSuitability, { isLoading }] = useUpdateSuitabilityMutation();

  const isLinkWithdrawalAccountModalOpen = useAppSelector(
    selectIsLinkWithdrawalAccountModalOpen,
  );

  const { isRegistrationFinancialPage, navigateToNextStep } =
    useRegistrationFinancialPage();

  const { isUpdateProfilePage } = useProfilePage();

  const {
    isRecommendedPortfolioModalVisible,
    handleOpenRecommendedPortfolioModal,
    updatePortfolio,
  } = useRecommendedPortfolioModal();

  const { formattedSuitabilities } = useFormattedSuitabilities();

  const dispatch = useAppDispatch();

  const [additionalSuitabilityFields, setAdditionalSuitabilityFields] =
    useState({});

  const [financialValues, setFinancialValues] = useState({});

  const schema = useFinancialFormSchema({
    additionalSuitabilityFields,
    financialValues,
  });

  const user = useAppSelector(selectUser);

  const defaultValues = getSuitabilityDefaultValues({
    formattedSuitabilities,
    user,
    isUpdateProfilePage: !!isUpdateProfilePage,
    financialValues,
    isRegistrationFinancialPage,
  });

  const { isSignUpPromoEnabled, inviteCode } = useInviteCode();

  const registrationDefaultValues =
    isSignUpPromoEnabled && inviteCode
      ? { ...defaultValues, [INVITE_CODE_FIELD_NAME]: inviteCode }
      : defaultValues;

  const form = useForm({
    defaultValues: isRegistrationFinancialPage
      ? registrationDefaultValues
      : defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  const watchValues: TWatchValues = watch();

  const isWatchValuesSelected = Object.keys(watchValues).length > 0;
  const isFinancialValuesSelected = Object.keys(financialValues).length > 0;

  useEffect(() => {
    if (isDirty || (isWatchValuesSelected && !isFinancialValuesSelected)) {
      setFinancialValues(watchValues);

      form.reset(watchValues);
    }
  }, [
    isDirty,
    isWatchValuesSelected,
    isFinancialValuesSelected,
    watchValues,
    setFinancialValues,
    form,
  ]);

  useEffect(() => {
    if (isDirty) {
      const watchValuesKeys = Object.keys(watchValues) as TSuitabilityNameKey[];

      const isIndustryWithAdditionalField =
        watchValues?.[SUITABILITIES_PICKER_ID.industry]?.[0]?.textInput;

      const isSourceOfIncomeWithAdditionalField =
        watchValues?.[SUITABILITIES_PICKER_ID.sourceOfIncome]?.[0]?.textInput;

      watchValuesKeys.reduce((acc, key) => {
        if (key) {
          const isTextInput = watchValues?.[key]?.[0]?.textInput;

          const isСommonAdditionalFields =
            key === SUITABILITIES_PICKER_ID.sourceOfIncome ||
            key === SUITABILITIES_PICKER_ID.industry;

          if (!isTextInput) {
            const isDeleteAdditionalField =
              (isСommonAdditionalFields &&
                !isSourceOfIncomeWithAdditionalField &&
                !isIndustryWithAdditionalField) ||
              !isСommonAdditionalFields;

            if (isDeleteAdditionalField) {
              delete watchValues[SUITABILITY_NAMES[key]];
            }
          } else if (!watchValues[SUITABILITY_NAMES[key]]) {
            watchValues[SUITABILITY_NAMES[key]] = null;
          }
        }

        return watchValues;
      }, {});

      setAdditionalSuitabilityFields(
        getAdditionalSuitabilityFields(watchValues),
      );

      form.reset(watchValues);
    }
  }, [watchValues, setAdditionalSuitabilityFields, isDirty, form]);

  const onSubmit = handleSubmit((values) => {
    const data = getSuitabilityRequestData(values);

    updateSuitability(data)
      .unwrap()
      .then(() => {
        dispatch(getUserData());

        if (isRegistrationFinancialPage && !isLinkWithdrawalAccountModalOpen) {
          navigateToNextStep();
        }

        handleOpenRecommendedPortfolioModal();
      });
  });

  if (!formattedSuitabilities) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <FinancialFormContent
          isRegistrationFinancialPage={isRegistrationFinancialPage}
        >
          {formattedSuitabilities?.map((item: IFormattedSuitability) => (
            <FinancialFormItem key={item.label} item={item} />
          ))}

          <InviteCode />
        </FinancialFormContent>

        <FinancialFormButtons isLoading={isLoading} />
      </form>

      {isRecommendedPortfolioModalVisible &&
        !isLinkWithdrawalAccountModalOpen && (
          <RecommendedPortfolioModal
            handleOpenRecommendedPortfolioModal={
              handleOpenRecommendedPortfolioModal
            }
            savePortfolio={updatePortfolio}
          />
        )}

      {isLinkWithdrawalAccountModalOpen && isSubmitSuccessful && (
        <LinkWithdrawalAccountModal />
      )}
    </FormProvider>
  );
}
