import { useForm, FormProvider } from 'react-hook-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CloseButton,
  Content,
  Footer,
  Header,
  Logo,
  LogoWrapper,
  NextButton,
  Title,
} from './styles';
import { RadioLabel } from './RadioLabel';

import { IBank, IRoundupSources } from 'store/banks';
import { RadioField } from 'components/form/radio/RadioField';

interface IBankDetailProps {
  bank: IBank;
  onRequestClose: () => void;
  onShowModal: (bank: IBank, source: IRoundupSources) => void;
}

interface IFormValues {
  source: IRoundupSources | null;
}

export const BankDetails = ({
  bank,
  onRequestClose,
  onShowModal,
}: IBankDetailProps) => {
  const roundupSources = bank.roundup_sources;

  const form = useForm<IFormValues>({
    defaultValues: {
      source: roundupSources.length > 1 ? null : roundupSources[0],
    },
  });

  const { t } = useTranslation();

  const { handleSubmit, watch } = form;

  const sourceValue = watch('source');

  const onSubmit = ({ source }: IFormValues) => {
    if (source !== null) {
      onShowModal(bank, source);
    }
  };

  const sourcesOption = useMemo(
    () =>
      roundupSources.map((source) => ({
        value: source,
        label: <RadioLabel source={source} />,
      })),
    [roundupSources],
  );

  return (
    <FormProvider {...form}>
      <Card>
        <Header>
          <LogoWrapper>
            <Logo source={bank.icon_url} />
          </LogoWrapper>

          <Title>{bank.bank_name}</Title>

          <CloseButton onClick={onRequestClose} className="-exclude" />
        </Header>

        <Content>
          <RadioField options={sourcesOption} name="source" />
        </Content>
      </Card>

      <Footer>
        <NextButton
          label={t('common.next')}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!sourceValue}
          className="-exclude"
        />
      </Footer>
    </FormProvider>
  );
};
