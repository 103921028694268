import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import { AU_APP, COUNTRY } from '../../../constants/localeConfigs';
import BankErrorModal from '../../../components/layout/Modals/BankErrorModal';
import { AUS, MYS } from '../../../constants/comonConstants';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function Agreement({
  handleSubmit,
  errorMessage,
  handleChangeAgreement,
  yearsOld,
  handleDsagree,
  handleCloseModal,
  isShowModal,
  showLoader,
  agreementDisabled,
  superFund,
  showSuperFundUser,
  openChooseModal,
  isOpenChooseModal,
  showCheckboxes,
}) {
  const { t } = useTranslation();

  const ausBtn = showCheckboxes ? (
    <DeprecatedButton
      withLoader={showCheckboxes && showLoader}
      type="submit"
      title={t('common.agree')}
      buttonClass="button button--submit"
      disabled={agreementDisabled}
    />
  ) : (
    ''
  );

  const agreementBtn = {
    [AUS]:
      showSuperFundUser && showCheckboxes ? (
        <DeprecatedButton
          withLoader={showCheckboxes && showLoader}
          title={t('common.agree')}
          buttonClass="button button--submit"
          handleClick={() => openChooseModal(true)}
        />
      ) : (
        ausBtn
      ),
    [MYS]: showCheckboxes ? (
      <DeprecatedButton
        withLoader={showCheckboxes && showLoader}
        type="submit"
        title={t('common.agree')}
        buttonClass="button button--submit"
        disabled={agreementDisabled}
      />
    ) : (
      ''
    ),
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onChange={handleChangeAgreement}
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <div className={'"form-field checkbox'}>
            <div className="form-field__sub-input-link">
              {((AU_APP && !showSuperFundUser && showCheckboxes) ||
                !AU_APP) && (
                <div
                  className={`years-old ${
                    AU_APP && showSuperFundUser ? '-flex' : ''
                  }
              ${AU_APP && !showSuperFundUser ? '-single' : ''}`}
                >
                  <CheckboxFF
                    name="yearsOld"
                    classList="checkbox__list"
                    defaultChecked={yearsOld}
                    label={t('registrationPage.agreement.yearsOld')}
                  />
                </div>
              )}

              <div
                className={`disagree ${
                  AU_APP && showSuperFundUser ? '-reverse' : ''
                }`}
              >
                {showCheckboxes && (
                  <DeprecatedButton
                    title={t('common.disagree')}
                    buttonClass="button-clear"
                    handleClick={handleDsagree}
                  />
                )}

                <div className="form-field">{agreementBtn[COUNTRY]}</div>
              </div>

              <BankErrorModal
                isOpen={isShowModal}
                hasBackdrop={false}
                isVisible={false}
                shouldCloseOnOverlayClick
                errorTitle=""
                errorMessage={t('registrationPage.agreement.programAgreement')}
                handleClick={handleCloseModal}
              />
            </div>
          </div>

          <div className="hide">{errorMessage}</div>

          {isOpenChooseModal && (
            <Modal
              hasBackdrop={false}
              shouldCloseOnOverlayClick={false}
              isOpen={isOpenChooseModal}
              onRequestClose={() => openChooseModal(false)}
              className="modal-basic -choose-type"
            >
              <DeprecatedButton
                buttonClass="button__close -black"
                handleClick={() => openChooseModal(false)}
              />

              <i className="raiz-logo" />

              <div className="-choose-type__title">
                {t('registrationPage.agreement.choose')}
              </div>

              <button
                type="button"
                className={`-choose-type__item ${yearsOld ? '-active' : ''}`}
                onClick={() => handleChangeAgreement('yearsOld')}
              >
                {t('registrationPage.agreement.iAmIndividual')}

                <span className="-choose-type__item-subtitle">
                  {t('registrationPage.agreement.yearsOld')}
                </span>
              </button>

              <DeprecatedButton
                title={t('registrationPage.agreement.iAmTrustee')}
                buttonClass={`-choose-type__item ${superFund ? '-active' : ''}`}
                handleClick={() => handleChangeAgreement('superFund')}
              />

              <DeprecatedButton
                type="submit"
                disabled={agreementDisabled}
                title={t('registrationPage.agreement.continueRegistration')}
                buttonClass="button button--submit"
                handleClick={handleSubmit}
              />
            </Modal>
          )}
        </form>
      )}
    />
  );
}

Agreement.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openChooseModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  yearsOld: PropTypes.bool,
  superFund: PropTypes.bool,
  handleChangeAgreement: PropTypes.func.isRequired,
  handleDsagree: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showCheckboxes: PropTypes.bool.isRequired,
  isShowModal: PropTypes.bool.isRequired,
  isOpenChooseModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  agreementDisabled: PropTypes.bool,
  showSuperFundUser: PropTypes.bool,
};

Agreement.defaultProps = {
  errorMessage: '',
  yearsOld: false,
  superFund: false,
  agreementDisabled: true,
  showSuperFundUser: false,
};
