import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function FundingConnectConfirmModal({
  accountName,
  connectLoader,
  handleCloseModal,
  isShowConfirmationModal,
  connectCredentials,
  handleSubAccount,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirmationModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">
          {t('settingsPage.fundingConnectConfirmModal.title', { accountName })}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            withLoader={connectLoader}
            title={t('common.cancel')}
            type="button"
            buttonClass="wait"
            handleClick={handleCloseModal}
          />

          <DeprecatedButton
            withLoader={connectLoader}
            title={t('common.connect')}
            type="button"
            buttonClass="skip"
            handleClick={() => handleSubAccount(connectCredentials)}
          />
        </div>
      </div>
    </Modal>
  );
}

FundingConnectConfirmModal.propTypes = {
  connectCredentials: PropTypes.shape({
    linked: PropTypes.bool,
  }).isRequired,
  accountName: PropTypes.string.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
  connectLoader: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubAccount: PropTypes.func.isRequired,
};
