import { useNavigate } from 'react-router-dom';
import { useMatch } from 'react-router';
import React, { useCallback, useState } from 'react';

import {
  VERIFICATION_STATUS_REQUEST_TIMEOUT,
  VERIFICATION_STATUS,
  VERIFICATION_TARGETS,
} from '../consts';
import { WaitingModalContent } from '../VerificationForm/components/WaitingModalContent';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  useCreateIdentityVerificationAutoMutation,
  useIdentityVerificationsStatusMutation,
} from 'store/identityVerification/api';
import { IVerificationSource } from 'store/identityVerification/types';
import { toggleInfoModal } from 'store/modals/actions';
import { PATHS } from 'constants/paths';

interface IUseIdentityVerificationAutoProps {
  isUserTarget?: boolean;
  closeRequest: () => void;
  resetMainForm: () => void;
}

interface IHandleAutoVerifyIdentityProps {
  verificationData: IVerificationSource;
}

interface IHandleVerificationsStatusProps {
  id: number;
  description: string;
}

export const useIdentityVerificationAuto = ({
  isUserTarget,
  closeRequest,
  resetMainForm,
}: IUseIdentityVerificationAutoProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [createIdentityVerificationAuto, { isLoading }] =
    useCreateIdentityVerificationAutoMutation();

  const isFundingPage = useMatch(PATHS.settings.fundingAccount.index);

  const handleModalAccept = useCallback(() => {
    closeRequest();

    const redirectPage = isFundingPage ? PATHS.alerts.index : PATHS.root;

    navigate(redirectPage);

    setTimeout(() => {
      sessionStorage.removeItem(
        SESSION_STORAGE_KEYS.isAutoVerificationModalOpen,
      );
    }, VERIFICATION_STATUS_REQUEST_TIMEOUT);
  }, [isFundingPage, navigate, closeRequest]);

  const [identityVerificationsStatus] =
    useIdentityVerificationsStatusMutation();

  const [isPendingStatus, setIsPendingStatus] = useState(false);

  const handleVerificationsStatus = useCallback(
    async ({ id, description }: IHandleVerificationsStatusProps) => {
      await identityVerificationsStatus({
        id,
      })
        .unwrap()
        .then((payload) => {
          switch (payload?.status) {
            case VERIFICATION_STATUS.pending:
              if (!isPendingStatus) {
                dispatch(
                  toggleInfoModal({
                    isInfoModalVisible: true,
                    config: {
                      description: (
                        <WaitingModalContent description={description} />
                      ),
                      acceptButtonText: '',
                    },
                  }),
                );
              }

              setTimeout(() => {
                handleVerificationsStatus({ id, description });
              }, VERIFICATION_STATUS_REQUEST_TIMEOUT);

              return;
            case VERIFICATION_STATUS.failed:
              setIsPendingStatus(false);

              resetMainForm();

              dispatch(
                toggleInfoModal({
                  isInfoModalVisible: false,
                }),
              );

              dispatch(
                toggleInfoModal({
                  isInfoModalVisible: true,
                  config: {
                    description: payload.message.description,
                    title: payload.message.title,
                    ...(!payload?.has_available_attempts && {
                      onAccept: handleModalAccept,
                      onReject: handleModalAccept,
                    }),
                  },
                }),
              );

              return;
            default:
              handleModalAccept();
          }
        });
    },
    [
      identityVerificationsStatus,
      isPendingStatus,
      dispatch,
      resetMainForm,
      handleModalAccept,
    ],
  );

  const handleAutoVerifyIdentity = ({
    verificationData,
  }: IHandleAutoVerifyIdentityProps) => {
    const formattedVerificationData = {
      inputs: verificationData,
      target: isUserTarget
        ? VERIFICATION_TARGETS.user
        : VERIFICATION_TARGETS.fundingSourceChangeRequest,
    };

    setIsPendingStatus(true);

    return createIdentityVerificationAuto(formattedVerificationData)
      .unwrap()
      .then((payload) => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.isAutoVerificationModalOpen,
          'true',
        );

        if (payload?.verification_id) {
          handleVerificationsStatus({
            id: payload.verification_id,
            description: payload.message,
          });
        } else {
          dispatch(
            toggleInfoModal({
              isInfoModalVisible: true,
              config: {
                description: payload.message,
                onAccept: handleModalAccept,
              },
            }),
          );
        }
      });
  };

  return { handleAutoVerifyIdentity, isLoading };
};
