import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './baseQuery';
import { API_TAGS } from './consts';

export const api = createApi({
  baseQuery,
  endpoints: () => ({}),
  tagTypes: Object.keys(API_TAGS),
});
