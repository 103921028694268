import React from 'react';

import { WidgetsWrapper } from './styles';
import Statistics from './Statistics/Statistics';
import TopHoldings from './TopHoldings/TopHoldings';
import PerformanceWidget from './PerformanceWidget/PerformanceWidget';

export default function Widgets() {
  return (
    <WidgetsWrapper>
      <PerformanceWidget />

      <TopHoldings />

      <Statistics />
    </WidgetsWrapper>
  );
}
