import { useTranslation } from 'react-i18next';

import { INavigationItem } from './components/types';
import { usePortfolioPath } from './usePortfolioPath';

import { PATHS } from 'constants/paths';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsSuperDividendsEnabled } from 'store/firebaseConfig/selectors';

const useSuperNavigationMenu = () => {
  const { t } = useTranslation();
  const { portfolioPath } = usePortfolioPath();

  const isDividendsEnabled = useAppSelector(selectIsSuperDividendsEnabled);

  const superNavigationMenu: INavigationItem[] = [
    {
      path: PATHS.super.index,
      icon: 'super',
      message: t('super.raizIvestSuper'),
    },
    {
      icon: 'moneyCircle',
      message: t('super.invest'),
      isButton: true,
    },
    {
      path: PATHS.super.insurance,
      icon: 'superInsurance',
      message: t('super.insurance'),
    },
    {
      path: PATHS.super.info,
      icon: 'person',
      message: t('super.superInfo'),
    },
    {
      path: PATHS.super.rollovers,
      icon: 'rollArrow',
      message: t('super.rolloverSuper'),
    },
    {
      path: portfolioPath,
      icon: 'portfolio',
      message: t('super.portfolio'),
    },
    ...(isDividendsEnabled
      ? [
          {
            path: PATHS.dividends.super,
            icon: 'dividends' as const,
            message: t('super.dividends'),
          },
        ]
      : []),
    {
      path: PATHS.super.rewardContribution,
      icon: 'reward',
      message: t('super.rewardContribution'),
    },
    {
      path: PATHS.super.recurringContribution,
      icon: 'recurring',
      message: t('super.recurringContribution'),
    },
    {
      path: PATHS.super.statements,
      icon: 'statements',
      message: t('statements.title'),
    },
  ];

  return superNavigationMenu;
};

export default useSuperNavigationMenu;
