import {
  PERIOD_PARAMS,
  PERIOD_DAYS_NUMBER,
} from '../../../pages/PerfomanceDetails/consts';

const getPeriodTextValue = (numberOfDays) => {
  switch (numberOfDays) {
    case PERIOD_DAYS_NUMBER.oneDay:
      return PERIOD_PARAMS.day;
    case PERIOD_DAYS_NUMBER.oneWeek:
      return PERIOD_PARAMS.week;
    case PERIOD_DAYS_NUMBER.oneYear:
      return PERIOD_PARAMS.year;
    case PERIOD_DAYS_NUMBER.oneMonth:
    case PERIOD_DAYS_NUMBER.halfYear:
    case PERIOD_DAYS_NUMBER.threeMonths:
      return PERIOD_PARAMS.month;
    default:
      return PERIOD_PARAMS.all;
  }
};

const getTimePeriodValueInMonths = (numberOfDays) => {
  if (numberOfDays === PERIOD_DAYS_NUMBER.halfYear) {
    return 6;
  }

  if (numberOfDays === PERIOD_DAYS_NUMBER.threeMonths) {
    return 3;
  }

  return 1;
};

const getSecuritiesRequestParams = (numberOfDays, maxPoints) => {
  const params = {
    'time_period[max_points]': maxPoints,
    'time_period[time]': getPeriodTextValue(numberOfDays),
  };

  const timePeriod = {
    'time_period[value]': getTimePeriodValueInMonths(numberOfDays),
  };

  return new URLSearchParams(
    numberOfDays ? { ...params, ...timePeriod } : params,
  ).toString();
};

export default getSecuritiesRequestParams;
