import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import dateArray from 'moment-array-dates';
import dayjs from 'dayjs';

import * as spendingAnalyticsActions from '../../store/spendingAnalytics/actions';
import { DEBIT } from '../../constants/comonConstants';
import { CURRENCY_LOCALE, CURRENCY_NAME } from '../../constants/localeConfigs';

import PotentialFreeCashView from './PotentialFreeCashView';

class PotentialFreeCash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        markers: {
          colors: '#ffffff',
        },
        xaxis: {
          tickPlacement: 'on',
          axisBorder: {
            show: true,
            color: '#78909C',
          },
          axisTicks: {
            show: true,
            color: '#78909C',
          },
          crosshairs: {
            show: true,
            position: 'back',
            stroke: {
              color: '#e2e2e2',
              width: 1,
              dashArray: 0,
            },
          },
          labels: {
            minHeight: 20,
            maxHeight: 20,
            rotate: -45,
            rotateAlways: true,
            style: {
              colors: 'rgba(255,255,255,0.7)',
              fontSize: '12px',
              cssClass: 'my-finance__chart-label',
            },
            format: 'dd/MM',
          },
          type: 'datetime',
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ['#ffffff'],
          curve: 'straight',
        },
        fill: {
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.1,
            gradientToColors: '#ffffff',
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0],
            colorStops: [
              {
                offset: 0,
                color: '#ffffff',
                opacity: 0.1,
              },
              {
                offset: 100,
                color: '#ffffff',
                opacity: 0.3,
              },
            ],
          },
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              new Intl.NumberFormat(CURRENCY_LOCALE, {
                style: 'currency',
                currency: CURRENCY_NAME,
              }).format(value),
            style: {
              colors: ['#ffffff'],
              fontSize: '12px',
              cssClass: 'my-finance__chart-label',
            },
          },
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: false,
          },
          y: {
            show: false,
          },
        },
        grid: {
          borderColor: 'rgba(255,255,255, 0.3)',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            bottom: 20,
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  }

  componentDidMount() {
    const { actionsSpendingAnalytics } = this.props;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    actionsSpendingAnalytics.getUpcomingTransactions().then(() => {
      const { upcomingTransactions } = this.props;
      const series = [];
      const infoForChart = [];
      let spareSum = 0;

      if (
        upcomingTransactions &&
        upcomingTransactions.payload &&
        upcomingTransactions.payload.length > 0
      ) {
        upcomingTransactions.payload.map((object, index) => {
          const transactionsSum = object.transactions
            .map((transaction) => {
              if (transaction.movement === DEBIT) {
                return transaction.amount * -1;
              }

              return transaction.amount;
            })
            .reduce(reducer);

          spareSum += transactionsSum;

          const previousSeries = parseFloat(series[index - 1]);
          const currentDate = new Date(object.date);

          const previousDate = previousSeries
            ? series[index - 1][0]
            : undefined;

          const start = dayjs(currentDate).format('MM/DD/YYYY');
          const end = dayjs(previousDate).format('MM/DD/YYYY');
          let diffArray = [];

          if (previousDate) {
            diffArray = dateArray.range(end, start, 'MMM DD YYYY', true);

            diffArray.shift();

            diffArray.pop();
          }

          const diffArrayWithVal = [];
          const daysDiff = diffArray.length;
          const parts = transactionsSum / daysDiff;

          diffArray.map((date, i) => {
            diffArrayWithVal.push([
              dayjs(date).startOf('day'),
              spareSum - parts * (daysDiff - i),
            ]);

            return diffArrayWithVal;
          });

          diffArrayWithVal.push([
            dayjs(currentDate).startOf('day'),
            spareSum.toFixed(2),
          ]);

          series.push([currentDate, spareSum.toFixed(2)]);

          infoForChart.push(diffArrayWithVal);

          return series;
        });
      }

      this.setState({
        series: [
          { data: infoForChart.reduce((acc, val) => acc.concat(val), []) },
        ],
      });
    });

    window.scrollTo(0, 0);
  }

  render() {
    const { upcomingTransactions } = this.props;
    const { options, series } = this.state;

    return (
      <PotentialFreeCashView
        options={options}
        series={series}
        upcomingTransactions={
          upcomingTransactions && upcomingTransactions.payload
            ? upcomingTransactions.payload
            : []
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  upcomingTransactions: state.spendingAnalytics.upcomingTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PotentialFreeCash);

PotentialFreeCash.defaultProps = {
  actionsSpendingAnalytics: {},
  upcomingTransactions: { payload: [] },
};

PotentialFreeCash.propTypes = {
  actionsSpendingAnalytics: PropTypes.shape({
    getUpcomingTransactions: PropTypes.func,
  }),
  upcomingTransactions: PropTypes.shape({
    payload: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
