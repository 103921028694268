import { useTranslation } from 'react-i18next';

import Loader from '../../../../components/elements/Loader/Loader';
import KidCard from '../KidCard/KidCard';

import {
  RaizKidsEmptyListIcon,
  RaizKidsEmptyListTitle,
  RaizKidsEmptyListWrapper,
  RaizKidsListWrapper,
} from './styles';
import { RaizKidsContentProps } from './types';

export const RaizKidsContent = ({
  isActiveListOpen,
  kidsList,
  isLoading,
  toggleInvestNow,
}: RaizKidsContentProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loader />;
  }

  if (kidsList?.length === 0) {
    return (
      <RaizKidsEmptyListWrapper>
        <RaizKidsEmptyListIcon />

        <RaizKidsEmptyListTitle>
          {isActiveListOpen
            ? t('raizKidsPage.noActiveAccounts')
            : t('raizKidsPage.noClosedAccounts')}
        </RaizKidsEmptyListTitle>
      </RaizKidsEmptyListWrapper>
    );
  }

  return (
    <RaizKidsListWrapper isActiveListOpen={isActiveListOpen}>
      {kidsList.map((kid) => (
        <KidCard key={kid.id} kid={kid} toggleInvestNow={toggleInvestNow} />
      ))}
    </RaizKidsListWrapper>
  );
};
