import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterItem from './FilterItem';
import FiltersCategoryLabel from './FiltersCategoryLabel';

export default function FiltersCategory({ filterCategory }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { title, tags } = filterCategory || {};

  return (
    <>
      <FiltersCategoryLabel
        setIsExpanded={setIsExpanded}
        title={title}
        isExpanded={isExpanded}
      />

      {isExpanded &&
        tags?.map((filter) => (
          <FilterItem key={filter.key} filter={filter} categoryName={title} />
        ))}
    </>
  );
}

FiltersCategory.propTypes = {
  filterCategory: PropTypes.shape({}).isRequired,
};
