import React from 'react';
import DOMPurify from 'dompurify';

import { DisclaimerWrapper } from '../../styles';

import { ISourceField } from 'store/identityVerification/types';

interface IDisclaimerProps {
  field: ISourceField;
}

export const Disclaimer = ({ field }: IDisclaimerProps) => {
  const { text, links } = field;

  const formattingText = links?.reduce((disclaimerText, link) => {
    const { link_text, link_url } = link;
    const replaceableLink = `<a target="_blank" href=${link_url}>${link_text}</a>`;

    return disclaimerText?.replace(link_text, replaceableLink);
  }, text);

  if (!formattingText) {
    return null;
  }

  return (
    <DisclaimerWrapper
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(formattingText, { ADD_ATTR: ['target'] }),
      }}
    />
  );
};
