import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function BankInformationModal({
  isShowBankInformationModal,
  closeBankInfoModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowBankInformationModal}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__modal info-modal"
    >
      <div className="bank__modal-content">
        <DeprecatedButton
          title={t('common.space')}
          buttonClass="button__close -black"
          handleClick={closeBankInfoModal}
        />

        <div className="bank__modal-title">
          {t(
            'settingsPage.bankInformationModal.importanceOfRegisteringBankMessage',
          )}
        </div>

        <div className="alert-text -bold">
          {t('settingsPage.bankInformationModal.balanceDisbursementPurpose')}
        </div>

        <div className="alert-text">
          {t(
            'settingsPage.bankInformationModal.bankNameAndIdCardReminderMessage',
          )}
        </div>

        <div className="alert-text -bold">
          {t('settingsPage.bankInformationModal.yourBalanceSafe')}
        </div>

        <div className="alert-text">
          {t('settingsPage.bankInformationModal.balanceDisbursementTrip')}
        </div>

        <div className="alert-text -bold">
          {t('settingsPage.bankInformationModal.balanceDisbursementTrip')}
        </div>

        <div className="alert-text">
          {t(
            'settingsPage.bankInformationModal.balanceWithrawingDurationMessage',
          )}
        </div>

        <div className="alert-text -bold">
          {t('settingsPage.bankInformationModal.notAnEWalletReminder')}
        </div>

        <div className="alert-text">
          {t(
            'settingsPage.bankInformationModal.preventFailureWithdrawingMessage',
          )}
        </div>

        <div className="error-buttons">
          <Button
            label={t('common.close')}
            type="button"
            onClick={closeBankInfoModal}
          />
        </div>
      </div>
    </Modal>
  );
}

BankInformationModal.propTypes = {
  isShowBankInformationModal: PropTypes.bool.isRequired,
  closeBankInfoModal: PropTypes.func.isRequired,
};
