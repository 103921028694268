import { ICON_BUTTON_SIZE } from './consts';
import { SendMailWrapper } from './styles';

import Checkbox from 'components/elements/Checkbox/Checkbox';
import { IconButton } from 'components/elements/IconButton';
import { IStatementsSendMailProps } from 'pages/Statements/types';

const StatementsSendMail = ({
  statement,
  isCheckboxesVisible,
  onStatementSelect,
  isChecked,
  sendStatements,
}: IStatementsSendMailProps) => {
  if (isCheckboxesVisible) {
    return (
      <Checkbox
        isChecked={isChecked}
        onClick={() => onStatementSelect({ id: statement.uuid })}
      />
    );
  }

  const handleSendClick = () => {
    sendStatements({
      statementId: statement.uuid,
    });
  };

  return (
    <SendMailWrapper>
      <IconButton
        size={ICON_BUTTON_SIZE}
        onClick={handleSendClick}
        iconName="mail"
      />
    </SendMailWrapper>
  );
};

export default StatementsSendMail;
