import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useRecurringInvestment } from '../hooks/useRecurringInvestment';

import {
  InvestmentPreviewTitle,
  InvestmentPreviewFrequency,
  InvestmentPreviewRowWrapper,
  InvestmentAmountPreviewValue,
  InvestmentPreviewValue,
} from './styles';

import { useInvestmentFrequencyText } from 'pages/RaizKidsEdit/hooks/useInvestmentFrequencyText';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectZakatTypeLabel } from 'store/rewards/selectors';
import { DATE_FORMAT_DAY_NAME_DAY_NUMBER_MONTH } from 'config/date';
import { formatNumber } from 'utils/formatters/formatNumber';

const RecurringPreview = () => {
  const { t } = useTranslation();

  const { recurring, isRecurringZakatPayment } = useRecurringInvestment();
  const zakatTypeLabel = useAppSelector(selectZakatTypeLabel);

  const { amount, next_investment_at } = recurring || {};

  const frequencyText = useInvestmentFrequencyText({
    recurring,
  });

  return (
    <>
      <InvestmentPreviewRowWrapper>
        <InvestmentPreviewTitle>
          {isRecurringZakatPayment
            ? t(
                'recurringZakatPaymentPage.recurringZakatPaymentForm.paymentAmountLabel',
              )
            : t(
                'raizKidsEditPage.recurringInvestmentPreview.labels.recurringInvestmentAmount',
              )}
        </InvestmentPreviewTitle>

        <InvestmentAmountPreviewValue>
          {formatNumber({ value: amount })}
        </InvestmentAmountPreviewValue>
      </InvestmentPreviewRowWrapper>

      <InvestmentPreviewRowWrapper>
        <InvestmentPreviewTitle>
          {t('raizKidsEditPage.recurringInvestmentForm.frequency')}
        </InvestmentPreviewTitle>

        <InvestmentPreviewFrequency>{frequencyText}</InvestmentPreviewFrequency>
      </InvestmentPreviewRowWrapper>

      <InvestmentPreviewRowWrapper>
        <InvestmentPreviewTitle>
          {isRecurringZakatPayment
            ? t(
                'recurringZakatPaymentPage.recurringZakatPaymentPreview.nextPaymentLabel',
              )
            : t('recurringInvestmentPage.nextInvestmentLabel')}
        </InvestmentPreviewTitle>

        <InvestmentPreviewValue>
          {dayjs(next_investment_at)
            .tz('Australia/Sydney')
            .format(DATE_FORMAT_DAY_NAME_DAY_NUMBER_MONTH)}
        </InvestmentPreviewValue>
      </InvestmentPreviewRowWrapper>

      {isRecurringZakatPayment && (
        <InvestmentPreviewRowWrapper>
          <InvestmentPreviewTitle>
            {t(
              'recurringZakatPaymentPage.recurringZakatPaymentPreview.paymentCategory',
            )}
          </InvestmentPreviewTitle>

          <InvestmentPreviewValue>{zakatTypeLabel}</InvestmentPreviewValue>
        </InvestmentPreviewRowWrapper>
      )}
    </>
  );
};

export default RecurringPreview;
