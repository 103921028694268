import {
  ACCESS_SELECTED,
  GO_BACK,
  INVESTMENT_INITIAL,
  INVESTMENT_RECURRING,
  OTHER_NOTIFICATION,
  PERSONAL_INFO,
  PERSONAL_TERMS,
  PORTFOLIO_SELECTED,
  CLOSE_ACCOUNT_KIDS_SUCCESS,
  CLOSE_MODAL,
  CONVERT_FULL_SUCCESS,
  DEPENDENCY_USER_INVESTMENT_SUCCESS,
  DEPENDENCY_USER_PORTFOLIO_SUCCESS,
  DEPENDENCY_USER_RECCURRING_SUCCESS,
  GET_DEPENDENCY_USER_SUCCESS,
  GET_DEPENDENCY_USER_SUMMARY_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_SECURITY_SUCCESS,
  RESENT_LINK_SUCCESS,
  SHOW_ERROR_CREATE_USER,
  SHOW_ERROR_MODAL,
  UPDATE_DEPENDENCY_USER_SUCCESS,
  UPDATE_NOTIFICATIONS_SUCCESS,
  GET_DEPENDENT_ACCESS_SUCCESS,
  TRANSFER_IN_SUCCESS,
  ACTIVATE_ACCOUNT_KIDS_SUCCESS,
  GET_DEPENDENCY_USER_LOADING,
  IRootDependencyUsersState,
} from './types';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const initialState = {
  dependencyUsers: {
    child: {
      dependency_user: {
        id: '',
        account_access: false,
        investing: false,
        investing_weekly_limit: 0,
        current_balance: 0,
        portfolio: {
          id: '',
        },
        recurring: {
          day: 0,
          amount: 0,
          frequency_identifier: null,
          frequency: '',
          start_date: '',
          next_investment_at: '',
        },
        icon_url: '',
        name: '',
        summary: {
          deposits: {
            value: 0,
            currency: '',
          },
          withdrawals: {
            value: 0,
            currency: '',
          },
          reinvested_dividends: {
            value: 0,
            currency: '',
          },
          total_found_money_rewarded: {
            value: 0,
            currency: '',
          },
          total_referrals_rewarded: {
            value: 0,
            currency: '',
          },
          other_rewards_rewarded: {
            value: 0,
            currency: '',
          },
          lump_sum: {
            value: 0,
            currency: '',
          },
          recurring: {
            value: 0,
            currency: '',
          },
        },
      },
    },
  },
};

const dependencyUsers: IRootDependencyUsersState = (
  state = initialState, // eslint-disable-line
  action,
  // eslint-disable-next-line consistent-return
) => {
  const accountInfo = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration) || '{}',
  );

  const isAccountAccess = accountInfo
    ? accountInfo?.dependency_user?.account_access
    : null;

  switch (action.type) {
    case SHOW_ERROR_CREATE_USER:
      return {
        ...state,
        type: action.type,
        globalErrorMessage: action.errorMessage,
      };
    case PERSONAL_TERMS:
      return {
        ...state,
        currentStep: '/raiz-registration/personal/info',
      };
    case CLOSE_MODAL:
      return {
        ...state,
        globalErrorMessage: '',
      };
    case PERSONAL_INFO:
      return {
        ...state,
        child: action.response,
        currentStep: '/raiz-registration/investment/initial',
      };
    case DEPENDENCY_USER_INVESTMENT_SUCCESS:
    case INVESTMENT_INITIAL:
      return {
        ...state,
        errorMessage: '',
        currentStep: '/raiz-registration/investment/recurring',
      };
    case DEPENDENCY_USER_RECCURRING_SUCCESS:
    case INVESTMENT_RECURRING:
      return {
        ...state,
        errorMessage: '',
        currentStep: '/raiz-registration/portfolio/select',
      };
    case PORTFOLIO_SELECTED:
      // TODO: RAIZ-4116 make sure it works correctly
      break;

    case DEPENDENCY_USER_PORTFOLIO_SUCCESS: {
      if (isAccountAccess) {
        return { ...state, currentStep: '/raiz-registration/other/access' };
      }

      return { ...state, currentStep: '/raiz-registration/other/transfer-in' };
    }

    case ACCESS_SELECTED:
      return {
        ...state,
        currentStep: isAccountAccess
          ? '/raiz-registration/other/notification'
          : '/raiz-registration/other/transfer-in',
      };
    case OTHER_NOTIFICATION:
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        currentStep: '/raiz-registration/other/transfer-in',
      };
    case GO_BACK:
      return {
        ...state,
        currentStep: action.step,
      };
    case GET_DEPENDENCY_USER_SUMMARY_SUCCESS:
      return {
        ...state,
        dependency_users: action.res.dependency_users,
      };
    case GET_DEPENDENCY_USER_SUCCESS:
    case UPDATE_DEPENDENCY_USER_SUCCESS:
      return {
        ...state,
        child: action.res,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.res,
      };
    case RESENT_LINK_SUCCESS:
    case CLOSE_ACCOUNT_KIDS_SUCCESS:
    case ACTIVATE_ACCOUNT_KIDS_SUCCESS:
    case CONVERT_FULL_SUCCESS:
    case TRANSFER_IN_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case SHOW_ERROR_MODAL:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case GET_SECURITY_SUCCESS:
      return {
        ...state,
        registrationSecurityQuestion: action.res,
      };
    case GET_DEPENDENT_ACCESS_SUCCESS:
      return {
        ...state,
        dependentAccess: action.dependentAccess,
      };
    case GET_DEPENDENCY_USER_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default dependencyUsers;
