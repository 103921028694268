import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { HOME_ACTIVE_TAB } from 'constants/comonConstants';

export default function HomeTabItem({ tab }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const activeTab = searchParams.get(HOME_ACTIVE_TAB);
  const isActiveTab = tab === activeTab;

  return (
    <div
      className={`home__switcher-item ${isActiveTab ? '-active' : ''}`}
      onClick={() => setSearchParams({ [HOME_ACTIVE_TAB]: tab })}
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
    >
      {t(`homePage.homeSwitcher.${tab}`)}
    </div>
  );
}

HomeTabItem.propTypes = {
  tab: PropTypes.string.isRequired,
};
