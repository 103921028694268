import React from 'react';
import { useTranslation } from 'react-i18next';

import { PRIVACY_POLICY } from 'constants/localeConfigs';

function PrivacyPolicyLink() {
  const { t } = useTranslation();

  return <a href={`${PRIVACY_POLICY}`}>{t('common.privacyPolicy')}</a>;
}

export default PrivacyPolicyLink;
