import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import 'styles/layout/close-account.scss';
import { MenuButton, ArrowIcon } from './styles';
import { RequestModal } from './components/RequestModal';
import { ICloseAccountData } from './types';

import { CLOSE } from 'constants/comonConstants';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { toggleInfoModal } from 'store/modals/actions';
import {
  getCloseAccountRequestStatus,
  getCloseQuestions,
  getUserData,
} from 'store/user/actions';
import { selectCloseAccountData } from 'store/user/selectors';
import { selectInfoModal } from 'store/modals/selectors';

interface ICloseAccountModalProps {
  supportStep: string;
}

export const CloseAccountModal = ({ supportStep }: ICloseAccountModalProps) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const closeAccountData = useAppSelector<ICloseAccountData>(
    selectCloseAccountData,
  );

  const { isInfoModalVisible } = useAppSelector(selectInfoModal);

  const {
    errorMessage,
    isCloseAccountRequestCreated,
    isFundingAccountLinked,
    questions,
  } = closeAccountData;

  const [isRequestModalVisible, setIsRequestModalVisible] = useState(
    supportStep === CLOSE,
  );

  const closeRequestModal = () => setIsRequestModalVisible(false);

  const openErrorModal = useCallback(() => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('closeAccountModal.errorModal.title'),
          description: t('closeAccountModal.errorModal.description'),
          onAccept: closeRequestModal,
          size: 350,
        },
      }),
    );
  }, [dispatch, t]);

  const getCloseAccountData = useCallback(async () => {
    await dispatch(getUserData());

    await dispatch(getCloseAccountRequestStatus());

    await dispatch(getCloseQuestions());
  }, [dispatch]);

  const handleClickCloseAccount = useCallback(() => {
    if (questions === undefined) return;

    if (isCloseAccountRequestCreated && !isFundingAccountLinked) {
      openErrorModal();
    } else {
      setIsRequestModalVisible(true);
    }
  }, [
    isCloseAccountRequestCreated,
    isFundingAccountLinked,
    openErrorModal,
    questions,
  ]);

  useEffect(() => {
    getCloseAccountData();
  }, [getCloseAccountData]);

  // TODO: https://acornsau.atlassian.net/browse/RAIZ-6073
  useEffect(() => {
    if (isCloseAccountRequestCreated && errorMessage) {
      dispatch(getUserData()).then(() => {
        openErrorModal();
      });
    }
  }, [dispatch, errorMessage, isCloseAccountRequestCreated, openErrorModal]);

  return (
    <>
      <MenuButton
        onClick={(e) => {
          e.preventDefault();

          handleClickCloseAccount();
        }}
      >
        {t('closeAccountModal.closeAccountButton')}

        <ArrowIcon />
      </MenuButton>

      <RequestModal
        isErrorModalVisible={isInfoModalVisible}
        isRequestModalVisible={isRequestModalVisible}
        closeRequestModal={closeRequestModal}
      />
    </>
  );
};
