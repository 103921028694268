import React from 'react';
import Skeleton from 'react-skeleton-loader';

import { BLOG_POSTS_COUNT } from '../consts';

import {
  BlogPostRow,
  BlogPostTitle,
  BlogPostImage,
} from './BlogPostItem/styles';

export default function BlogPostsSkeleton() {
  return (
    <>
      {[...Array(BLOG_POSTS_COUNT)].map((e, index) => (
        <BlogPostRow
          // eslint-disable-next-line react/no-array-index-key
          key={`blog-${index}`}
          className="history__item blog-row skeleton"
        >
          <BlogPostImage>
            <Skeleton width="100%" height="62px" />
          </BlogPostImage>

          <BlogPostTitle>
            <Skeleton width="100%" />
          </BlogPostTitle>
        </BlogPostRow>
      ))}
    </>
  );
}
