import styled from '@emotion/styled';

export const ListHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px 20px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Header = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length.sm};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  padding: 5px 20px;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.athensGray};
`;
