import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import kidsBanner from 'assets/images/kids-banner.png';
import addKid from 'assets/images/icons/add_kid.svg';

export const RaizKidsBannerWrapper = styled.div`
  height: 255px;
  padding: 30px 0 20px;
  margin-bottom: 16px;
  background: url(${kidsBanner}), ${({ theme }) => theme.gradients.green};
  background-size: 100%;
  position: relative;
  width: 100%;
`;

export const RaizKidsBannerHelpCardButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const RaizKidsBannerTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['4lg']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tighter};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
`;

export const RaizKidsBannerDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  color: ${({ theme }) => theme.colors.white};
  margin-top: 30px;
`;

export const RaizKidsBannerButtonIcon = styled.div`
  background: url(${addKid}) no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-right: 20px;
`;

export const RaizKidsBannerButton = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.md};
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.whiteTransparent};
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-radius: 40px;
  margin-top: 50px;
  display: flex;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  min-height: 40px;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
