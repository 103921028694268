import React from 'react';
import PropTypes from 'prop-types';

import { WidgetLinkButtonWrapper } from './styles';

export function WidgetButton({ href, onClick, text, background, hasBorder }) {
  return (
    <WidgetLinkButtonWrapper
      to={href}
      onClick={onClick}
      background={background}
      hasBorder={hasBorder}
    >
      {text}
    </WidgetLinkButtonWrapper>
  );
}

WidgetButton.defaultProps = {
  background: '',
  hasBorder: false,
  onClick() {},
};

WidgetButton.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  background: PropTypes.string,
  hasBorder: PropTypes.bool,
};
