import Skeleton from 'react-skeleton-loader';

import { VerificationSkeletonWrapper } from './styles';

export const VerificationSkeleton = () => (
  <VerificationSkeletonWrapper>
    <Skeleton height="12px" />

    <Skeleton height="46px" width="100%" />
  </VerificationSkeletonWrapper>
);
