import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { ErrorLabel } from '../styles';
import { IFieldProps } from '../types';

import { Check, CheckboxWrapper, Input, InputWrapper, Label } from './styles';

interface ICheckBoxFieldProps<T extends FieldValues> extends IFieldProps<T> {
  size?: number;
}

export const CheckBoxField = <T extends FieldValues>({
  name,
  label,
  className,
  size = 32,
}: ICheckBoxFieldProps<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CheckboxWrapper className={className}>
          <InputWrapper>
            <Check checked={field.value} size={size}>
              <Input id={name} type="checkbox" {...field} />
            </Check>

            <Label htmlFor={name}>{label}</Label>
          </InputWrapper>

          {!!error?.message && <ErrorLabel>{error?.message}</ErrorLabel>}
        </CheckboxWrapper>
      )}
    />
  );
};
