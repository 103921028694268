// TODO: https://acornsau.atlassian.net/browse/RAIZ-6931
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withCookies } from 'react-cookie';

import * as historyAction from '../../store/history/actions';
import * as rewardsActions from '../../store/rewards/actions';
import * as userAction from '../../store/user/actions';
import * as rewardsSelectors from '../../store/rewards/reducers';
import { AU_APP } from '../../constants/localeConfigs';

import {
  DEFAULT_LOCATION,
  FAVOURITE_CATEGORY_ID,
  DEFAULT_FAVOURITE_CATEGORY,
  OFFER_TYPES,
} from './consts';
import NewRewardsView from './NewRewardsView';
import NewRewardInternalView from './components/NewRewardInternalView';
import InStoreRewardsView from './components/InStoreRewardsView';
import RewardsSearchView from './components/RewardsSearchView';
import RewardsHistoryView from './components/RewardsHistoryView';
import CardsView from './components/CardsView';
import checkIsFavouriteCategory from './utils/checkIsFavouriteCategory';

import { selectIsNewRewardsEnabled } from 'store/firebaseConfig/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class NewRewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMainLoader: true,
      isOpenHelpCards: false,
      rewardId: null,
      isMap: false,
      currentInstorePage: 1,
      isInstore: false,
      categoryId: null,
      categoryName: '',
      isAll: true,
      isInvested: false,
      isPending: false,
      isShowChangeLocation: false,
      isShowConfirmationModal: false,
      removeCardId: null,
      isShowMoreLocation: false,
      isGiftCardModalVisible: false,
      queryValue: '',
      wasInScroll: false,
      currentSearchList: OFFER_TYPES.online,
    };

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.openInstore = this.openInstore.bind(this);

    this.toggleMapList = this.toggleMapList.bind(this);

    this.trackScrolling = this.trackScrolling.bind(this);

    this.filterCategory = this.filterCategory.bind(this);

    this.changeSearchList = this.changeSearchList.bind(this);

    this.toggleLikeOffer = this.toggleLikeOffer.bind(this);

    this.toggleHistory = this.toggleHistory.bind(this);

    this.openChangeLocation = this.openChangeLocation.bind(this);

    this.pureProfileLogin = this.pureProfileLogin.bind(this);

    this.setDefaultCard = this.setDefaultCard.bind(this);

    this.removeCard = this.removeCard.bind(this);

    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);

    this.showMoreLocation = this.showMoreLocation.bind(this);

    this.giftCardModalToggle = this.giftCardModalToggle.bind(this);

    this.setInView = this.setInView.bind(this);

    this.getInstoreData = this.getInstoreData.bind(this);

    this.toggleCategory = this.toggleCategory.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      match,
      isInStoreRewards,
      isOnline,
      isSearchRewards,
      isCards,
      isHistory,
      history,
      actionsHistory,
      isNewRewards,
      latitude,
      longitude,
    } = this.props;

    if (AU_APP) {
      actions.getGiftCardToken().then((giftToken) => {
        const { cookies } = this.props;

        cookies.set('tr-app-auth-token', giftToken, { path: '/' });
      });
    }

    if (!isNewRewards) {
      history.push('/');
    }

    const { id } = match.params;

    this.setState({ rewardId: id });

    if (id && !isOnline) {
      actions.getInstoreOffersById({
        id,
        latitude,
        longitude,
      });
    }

    if (!id && !isHistory && !isCards) {
      actions.getAllCategories();
    }

    if (
      !id &&
      !isInStoreRewards &&
      !isSearchRewards &&
      !isHistory &&
      !isCards
    ) {
      actions.getCashback();
    }

    if (isHistory) {
      actions.getCashback();
    }

    if (isInStoreRewards) {
      this.getInstoreData();
    }

    if (isCards) {
      actions.getLinkCardUrl();
    }

    actions.getCards();

    actionsHistory.getAccountSummary();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      match,
      actions,
      location,
      categories,
      isInStoreRewards,
      isCards,
      latitude,
      longitude,
    } = this.props;

    const { categoryId, showMainLoader } = this.state;
    const { id, catId } = match.params;

    if (newProps.isInStoreRewards && !isInStoreRewards) {
      this.getInstoreData();
    }

    if (newProps.isCards && !isCards) {
      actions.getLinkCardUrl();
    }

    const rewardId = newProps?.match?.params?.id;

    this.setState({ rewardId });

    if (rewardId && rewardId !== id) {
      if (!newProps?.isOnline) {
        actions.getInstoreOffersById({
          id: rewardId,
          latitude,
          longitude,
        });
      }
    }

    if (newProps.addListener) {
      document.addEventListener('scroll', this.trackScrolling);
    }

    if (
      newProps.showMainLoader !== undefined &&
      showMainLoader !== newProps.showMainLoader
    ) {
      this.setState({ showMainLoader: newProps.showMainLoader });
    }

    if (
      !categoryId &&
      !catId &&
      location &&
      location.categoryParam &&
      location.categoryParam.categoryId &&
      location.categoryParam.categoryId !== categoryId &&
      location.categoryParam.categoryName
    ) {
      this.setState({
        showMainLoader: true,
        categoryId: location.categoryParam.categoryId,
        categoryName: location.categoryParam.categoryName,
        isInstore: false,
      });
    } else if (
      !categoryId &&
      catId &&
      categories &&
      categories.categories &&
      categories.categories.length !== 0
    ) {
      const currentCategory = categories.categories.find(
        (category) => category.id === catId * 1,
      );

      let name =
        currentCategory && currentCategory.name ? currentCategory.name : '';

      if (catId === DEFAULT_FAVOURITE_CATEGORY.id) {
        name = DEFAULT_FAVOURITE_CATEGORY.name;

        actions.getfavourites();
      }

      this.setState({
        showMainLoader: true,
        categoryId: catId,
        categoryName: name,
        isInstore: false,
      });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetProps();

    this.setState({ currentInstorePage: 1 });

    document.removeEventListener('scroll', this.trackScrolling, false);
  }

  getInstoreData() {
    const { actions, latitude, longitude } = this.props;
    const { categoryId, currentInstorePage } = this.state;

    const instoreParams = {
      page: currentInstorePage,
      categoryId,
      latitude,
      longitude,
    };

    actions.getInstoreOffers(instoreParams);
  }

  setDefaultCard(id, isDefault) {
    const { actions } = this.props;

    actions.setDefaultCard(id, isDefault);
  }

  setInView(inView) {
    const { wasInScroll } = this.state;

    if (inView && !wasInScroll) {
      this.trackScrolling({ inView });
    }
  }

  saveInvested = (investedValue) => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.investedValue, investedValue);
  };

  trackScrolling = (trackScrollingData) => {
    const { actions, addListener, instoreOffers } = this.props;
    const { queryValue } = this.state;
    const { inView } = trackScrollingData;
    const { currentInstorePage, categoryId } = this.state;

    const wrappedElementOnline = document.getElementById(
      'rewards-online-items',
    );

    const wrappedElementInstore = document.getElementById(
      'rewards-instore-items',
    );

    const isOnlineBottom = this.isBottom(wrappedElementOnline) && addListener;
    const isInstoreBottom = this.isBottom(wrappedElementInstore) && addListener;
    const isOnlineRewardsInView = wrappedElementOnline && inView;
    const isInstoreRewardsInView = wrappedElementInstore && inView;

    if (categoryId === FAVOURITE_CATEGORY_ID) {
      return;
    }

    if (
      (isOnlineBottom || isInstoreBottom) &&
      !isOnlineRewardsInView &&
      !isInstoreRewardsInView
    ) {
      this.setState({
        wasInScroll: true,
      });
    }

    if (isOnlineBottom || isOnlineRewardsInView) {
      document.removeEventListener('scroll', this.trackScrolling);
    } else if (isInstoreBottom || isInstoreRewardsInView) {
      document.removeEventListener('scroll', this.trackScrolling);

      if (
        instoreOffers.totalPages &&
        instoreOffers.totalPages > currentInstorePage
      ) {
        this.setState({ currentInstorePage: currentInstorePage + 1 });

        const instoreParams = {
          page: currentInstorePage + 1,
          categoryId,
          query: queryValue,
        };

        actions.getInstoreOffers(instoreParams);
      }
    }
  };

  toggleHelpCard(condition) {
    this.setState({ isOpenHelpCards: condition });
  }

  toggleMapList(condition) {
    this.setState({ isMap: condition });
  }

  openInstore() {
    const { history, actions } = this.props;

    actions.getInstoreOffers({});

    this.setState({ categoryId: '', categoryName: '' });

    history.push('/rewards/in-store');
  }

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    return false;
  }

  filterCategory({ name, id, isSearchFromMainPage = false }) {
    const { actions, isInStoreRewards } = this.props;
    const { categoryId } = this.state;

    const isCategoryAlreadyActive =
      categoryId === Number(id) || checkIsFavouriteCategory(categoryId, id);

    this.setState({
      categoryName: isCategoryAlreadyActive ? '' : name,
      categoryId: isCategoryAlreadyActive ? null : Number(id),
      showMainLoader: true,
      currentInstorePage: 1,
    });

    const activeCategoryId = isCategoryAlreadyActive ? null : id;

    if (id === DEFAULT_FAVOURITE_CATEGORY.id && !isCategoryAlreadyActive) {
      actions.getfavourites();

      this.setState({ isInstore: false, categoryId: FAVOURITE_CATEGORY_ID });
    } else if (isInStoreRewards && !isSearchFromMainPage) {
      const instoreParams = {
        categoryId: activeCategoryId,
        isRefresh: true,
      };

      actions.getInstoreOffers(instoreParams);
    }
  }

  changeSearchList(searchList) {
    const { actions } = this.props;
    const { currentInstorePage, categoryId } = this.state;

    this.setState({
      currentSearchList: searchList,
    });

    if (searchList === OFFER_TYPES.inStore) {
      const instoreParams = {
        page: currentInstorePage,
        categoryId,
      };

      actions.getInstoreOffers(instoreParams);
    }
  }

  toggleLikeOffer({
    isFavourite,
    id,
    isInstore = false,
    isInternal = false,
    isShowFavourite = false,
  }) {
    const { actions } = this.props;
    const { offer } = this.props;

    if (isInternal) {
      offer.isFavourite = !isFavourite;

      actions.changeFavourite(id, isInstore, isFavourite, isInternal, offer);
    } else {
      actions.changeFavourite(
        id,
        isInstore,
        isFavourite,
        isInternal,
        {},
        isShowFavourite,
      );
    }
  }

  toggleHistory(isAll = false, isInvested = false, isPending = false) {
    this.setState({ isAll, isInvested, isPending });
  }

  openChangeLocation(isShowChangeLocation) {
    this.setState({
      isShowChangeLocation,
    });
  }

  pureProfileLogin() {
    const { actionsUser } = this.props;

    actionsUser.pureProfileLogin();
  }

  removeCard(id = null) {
    const { actions } = this.props;
    const { isShowConfirmationModal, removeCardId } = this.state;

    if (!isShowConfirmationModal) {
      this.setState({ isShowConfirmationModal: true, removeCardId: id });
    } else if (removeCardId && isShowConfirmationModal) {
      actions.removeCard(removeCardId);

      this.setState({ isShowConfirmationModal: false, removeCardId: null });
    }
  }

  closeConfirmationModal() {
    this.setState({ isShowConfirmationModal: false, removeCardId: null });
  }

  showMoreLocation() {
    this.setState({
      isShowMoreLocation: true,
    });
  }

  giftCardModalToggle() {
    const { isGiftCardModalVisible } = this.state;

    this.setState({
      isGiftCardModalVisible: !isGiftCardModalVisible,
    });
  }

  toggleCategory({ name, id }) {
    const { categoryId } = this.state;

    const isCategoryAlreadyActive =
      categoryId === Number(id) || checkIsFavouriteCategory(categoryId, id);

    const newCategoryId =
      id === DEFAULT_FAVOURITE_CATEGORY.id ? FAVOURITE_CATEGORY_ID : Number(id);

    this.setState({
      categoryName: isCategoryAlreadyActive ? null : name,
      categoryId: isCategoryAlreadyActive ? null : newCategoryId,
    });
  }

  render() {
    const {
      categories,
      type,
      cashback,
      featuredOffers,
      offer,
      isInStoreRewards,
      instoreOffers,
      isOnline,
      isSearchRewards,
      favourites,
      isShowFavourite,
      isHistory,
      address,
      isCards,
      cards,
      linkCardUrl,
      isFeatured,
      isRecommended,
      accountSummary,
      user,
    } = this.props;

    const {
      isOpenHelpCards,
      showMainLoader,
      rewardId,
      isMap,
      isInstore,
      categoryId,
      categoryName,
      isAll,
      isInvested,
      isPending,
      isShowChangeLocation,
      isShowConfirmationModal,
      isShowMoreLocation,
      isGiftCardModalVisible,
      currentSearchList,
    } = this.state;

    if (rewardId) {
      return (
        <NewRewardInternalView
          offer={offer}
          isOnline={isOnline}
          toggleLikeOffer={this.toggleLikeOffer}
          pureProfileLogin={this.pureProfileLogin}
          isShowMoreLocation={isShowMoreLocation}
          showMoreLocation={this.showMoreLocation}
          isFeatured={isFeatured}
          isRecommended={isRecommended}
          isGiftCardModalVisible={isGiftCardModalVisible}
          giftCardModalToggle={this.giftCardModalToggle}
          user={user}
        />
      );
    }

    if (isInStoreRewards) {
      return (
        <InStoreRewardsView
          isMap={isMap}
          toggleMapList={this.toggleMapList}
          instoreOffers={instoreOffers}
          categories={categories}
          filterCategory={this.filterCategory}
          toggleLikeOffer={this.toggleLikeOffer}
          showMainLoader={showMainLoader}
          address={address}
          isShowChangeLocation={isShowChangeLocation}
          openChangeLocation={this.openChangeLocation}
          categoryName={categoryName}
          categoryId={categoryId}
          favourites={favourites}
        />
      );
    }

    if (isSearchRewards) {
      return (
        <RewardsSearchView
          isMap={isMap}
          changeSearchList={this.changeSearchList}
          instoreOffers={instoreOffers}
          categories={categories}
          filterCategory={this.filterCategory}
          isInstore={isInstore}
          categoryId={categoryId}
          categoryName={categoryName}
          toggleLikeOffer={this.toggleLikeOffer}
          favourites={favourites}
          isShowFavourite={isShowFavourite}
          showMainLoader={showMainLoader}
          isInStoreRewards={isInStoreRewards}
          address={address}
          openChangeLocation={this.openChangeLocation}
          isShowChangeLocation={isShowChangeLocation}
          setInView={this.setInView}
          currentSearchList={currentSearchList}
          toggleCategory={this.toggleCategory}
        />
      );
    }

    if (isHistory) {
      return (
        <RewardsHistoryView
          isAll={isAll}
          isInvested={isInvested}
          isPending={isPending}
          toggleHistory={this.toggleHistory}
          cashback={cashback}
          showMainLoader={showMainLoader}
        />
      );
    }

    if (isCards) {
      return (
        <CardsView
          cards={cards}
          linkCardUrl={linkCardUrl}
          setDefaultCard={this.setDefaultCard}
          removeCard={this.removeCard}
          isShowConfirmationModal={isShowConfirmationModal}
          closeConfirmationModal={this.closeConfirmationModal}
        />
      );
    }

    return (
      <div>
        <NewRewardsView
          type={type}
          showMainLoader={showMainLoader}
          saveInvested={this.saveInvested}
          isOpenHelpCards={isOpenHelpCards}
          toggleHelpCard={this.toggleHelpCard}
          cashback={cashback}
          featuredOffers={featuredOffers}
          openInstore={this.openInstore}
          categories={categories}
          isHaveCard={!!(cards && cards.cards && cards.cards.length !== 0)}
          accountSummary={accountSummary}
          categoryName={categoryName}
          categoryId={categoryId}
          toggleCategory={this.toggleCategory}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rewards: state.rewards,
  type: state.rewards.type,
  monitoredAccounts: state.rewards.monitoredAccounts,
  cashback: state.rewards.cashback,
  featuredOffers: state.rewards.featuredOffers,
  offer: state.rewards.offer,
  instoreOffers: state.rewards.instoreOffers,
  categories: state.rewards.categories,
  addListener: state.rewards.addListener,
  favourites: rewardsSelectors.sortFavoritesOffers(state.rewards.favourites),
  isShowFavourite: state.rewards.isShowFavourite,
  showMainLoader: state.rewards.showMainLoader,
  address: state.rewards.address,
  latitude: state.rewards.latitude,
  longitude: state.rewards.longitude,
  user: state.user.user,
  cards: state.rewards.cards,
  linkCardUrl: state.rewards.linkCardUrl,
  accountSummary: state.history.accountSummary,
  state,
  isNewRewards: selectIsNewRewardsEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rewardsActions, dispatch),
  actionsUser: bindActionCreators(userAction, dispatch),
  actionsHistory: bindActionCreators(historyAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(NewRewards));

NewRewards.defaultProps = {
  type: '',
  categories: {},
  cashback: {},
  match: {},
  offer: {},
  featuredOffers: {},
  isInStoreRewards: false,
  instoreOffers: {},
  isOnline: true,
  addListener: false,
  isSearchRewards: false,
  location: {},
  favourites: {},
  isShowFavourite: false,
  isHistory: false,
  address: DEFAULT_LOCATION.address,
  latitude: DEFAULT_LOCATION.latitude,
  longitude: DEFAULT_LOCATION.longitude,
  user: {},
  isCards: false,
  cards: {},
  linkCardUrl: '',
  isFeatured: false,
  isRecommended: false,
  accountSummary: {},
  isNewRewards: false,
};

NewRewards.propTypes = {
  type: PropTypes.string,
  actions: PropTypes.shape({
    getInstoreOffers: PropTypes.func,
    getInstoreOffersById: PropTypes.func,
    getAllCategories: PropTypes.func,
    getCashback: PropTypes.func,
    getFeaturedOffers: PropTypes.func,
    getLinkCardUrl: PropTypes.func,
    getCards: PropTypes.func,
    getfavourites: PropTypes.func,
    setDefaultCard: PropTypes.func,
    resetProps: PropTypes.func,
    removeCard: PropTypes.func,
    getAccountSummary: PropTypes.func,
    changeFavourite: PropTypes.func,
    getGiftCardToken: PropTypes.func,
  }).isRequired,
  rewards: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  cashback: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      catId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  offer: PropTypes.shape({
    isFavourite: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    advertiserId: PropTypes.number,
  }),
  featuredOffers: PropTypes.shape({
    recommendedOffers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isInStoreRewards: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  instoreOffers: PropTypes.shape({
    totalPages: PropTypes.number,
  }),
  isOnline: PropTypes.bool,
  addListener: PropTypes.bool,
  isSearchRewards: PropTypes.bool,
  location: PropTypes.shape({
    categoryParam: PropTypes.shape({
      categoryId: PropTypes.string,
      categoryName: PropTypes.string,
    }),
  }),
  favourites: PropTypes.shape({}),
  isShowFavourite: PropTypes.bool,
  isHistory: PropTypes.bool,
  address: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  actionsUser: PropTypes.shape({
    pureProfileLogin: PropTypes.func,
  }).isRequired,
  isCards: PropTypes.bool,
  cards: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  linkCardUrl: PropTypes.string,
  isFeatured: PropTypes.bool,
  isRecommended: PropTypes.bool,
  accountSummary: PropTypes.shape({}),
  actionsHistory: PropTypes.shape({
    getAccountSummary: PropTypes.func,
  }).isRequired,
  cookies: PropTypes.shape({
    set: PropTypes.func,
  }).isRequired,
  isNewRewards: PropTypes.bool,
};
