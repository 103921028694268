import {
  ConnectionStatusCompleted,
  ConnectionStatusLoading,
  ConnectionStatusLoadingInside,
  ConnectionStatusNotStarted,
} from '../styles';

interface IBankAccountConnectionStatusIconProps {
  indexOfCurrentState: number;
  index: number;
  isLinked: boolean;
}

const BankAccountConnectionStatusIcon = ({
  indexOfCurrentState,
  index,
  isLinked,
}: IBankAccountConnectionStatusIconProps) => {
  const isLoading =
    (index === indexOfCurrentState ||
      (indexOfCurrentState === -1 && index === 0)) &&
    !isLinked;

  if (isLoading)
    return (
      <ConnectionStatusLoading>
        <ConnectionStatusLoadingInside />
      </ConnectionStatusLoading>
    );

  if (index > indexOfCurrentState) return <ConnectionStatusNotStarted />;

  return <ConnectionStatusCompleted />;
};

export default BankAccountConnectionStatusIcon;
