import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MyFinanceBannerItem, MyFinanceBannerItemTitle } from './styles';

import { CURRENCY_SYMBOL } from 'constants/localeConfigs';

export default function MyFinanceWidgetBannerItem({
  type,
  percent,
  isActive,
  amount,
}) {
  const { t } = useTranslation();

  return (
    <MyFinanceBannerItem isActive={isActive}>
      <MyFinanceBannerItemTitle>
        {t(`myFinanceWidget.bannerTypes.${type}`)}
      </MyFinanceBannerItemTitle>

      <div>{percent}%</div>

      <div>{`${CURRENCY_SYMBOL}${amount}`}</div>
    </MyFinanceBannerItem>
  );
}

MyFinanceWidgetBannerItem.defaultProps = {
  isActive: false,
};

MyFinanceWidgetBannerItem.propTypes = {
  type: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  amount: PropTypes.number.isRequired,
};
