import React from 'react';
import Switch from 'react-switch';
import { Trans, useTranslation } from 'react-i18next';

import {
  ButtonContainerItem,
  ButtonSubValue,
  ButtonSubLabel,
  ButtonLabel,
} from './styles';

import ProgressInfo from 'components/elements/ProgressInfo/ProgressInfo';
import colors from 'theme/colors';
import { AU_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IRoundUpsAutoButtonProps {
  toggleRoundUps: () => void;
  isAutomaticRoundup: boolean;
  roundUpsSummary?: {
    automatic: {
      waiting: number;
    };
  };
  automaticThreshold?: string;
}

function RoundUpsAutoButton({
  toggleRoundUps,
  isAutomaticRoundup,
  roundUpsSummary = {
    automatic: {
      waiting: 0,
    },
  },
  automaticThreshold = '0',
}: IRoundUpsAutoButtonProps) {
  const { t } = useTranslation();

  return (
    <div>
      {AU_APP && (
        <ButtonLabel>{t('roundUps.automaticRoundUps.title')}</ButtonLabel>
      )}

      <ButtonContainerItem type="button">
        <ProgressInfo
          value={isAutomaticRoundup ? roundUpsSummary.automatic.waiting : 0}
          maxValue={Number(automaticThreshold)}
        >
          {isAutomaticRoundup ? (
            <>
              <ButtonSubValue>
                {formatNumber({
                  value: roundUpsSummary?.automatic.waiting,
                })}
              </ButtonSubValue>

              <ButtonSubLabel>
                <Trans
                  t={t}
                  i18nKey="roundUps.until"
                  values={{
                    start: formatNumber({
                      value:
                        Number(automaticThreshold) -
                        roundUpsSummary.automatic.waiting,
                    }),
                    end: formatNumber({
                      value: Number(automaticThreshold),
                    }),
                  }}
                />
              </ButtonSubLabel>
            </>
          ) : (
            <ButtonSubLabel>
              {t('roundUps.enableAutoRound')}

              <Switch
                onChange={toggleRoundUps}
                checked={isAutomaticRoundup}
                onColor={colors.lighterGreen}
                offColor={colors.lightGreen}
                onHandleColor={colors.white}
                handleDiameter={26}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0"
                activeBoxShadow="0"
                height={18}
                width={45}
                className="react-switch"
                id="material-switch"
              />
            </ButtonSubLabel>
          )}
        </ProgressInfo>
      </ButtonContainerItem>
    </div>
  );
}

export default RoundUpsAutoButton;
