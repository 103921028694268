import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Date, StatusDateWrapper } from './styles';

import { DATE_FORMAT } from 'config/date';
import { useVerificationStatusQuery } from 'store/fundingAccount/fundingAccount';
import { VERIFICATION_STATUS } from 'store/fundingAccount/consts';

export const StatusDate = () => {
  const { t } = useTranslation();
  const { data } = useVerificationStatusQuery();

  const { status, handled_at } = data || {};

  if (status === VERIFICATION_STATUS.created) {
    return null;
  }

  return (
    <StatusDateWrapper>
      {status === VERIFICATION_STATUS.canceled
        ? t('settingsPage.fundingAccountPage.canceledDate')
        : t('settingsPage.fundingAccountPage.completedDate')}

      <Date>{dayjs(handled_at).format(DATE_FORMAT)}</Date>
    </StatusDateWrapper>
  );
};
