import React from 'react';
import { useTranslation } from 'react-i18next';

import 'styles/pages/raiz-kids.scss';
import {
  WidgetWrapper,
  WidgetTitle,
  WidgetText,
  WidgetHeader,
} from '../styles';
import { WidgetButton } from '../components/WidgetButton';
import { SUPER_PAGE } from '../../../../constants/comonConstants';

import { SuperWidgetWrapper } from './styles';

import theme from 'theme';

export default function SuperWidget() {
  const { t } = useTranslation();

  return (
    <WidgetWrapper>
      <WidgetTitle>{t('superWidget.title')}</WidgetTitle>

      <SuperWidgetWrapper>
        <WidgetHeader>{t('superWidget.header')}</WidgetHeader>

        <WidgetText>{t('superWidget.connectEmployerLabel')}</WidgetText>

        <WidgetButton
          href={SUPER_PAGE}
          text={t('superWidget.button')}
          background={theme.colors.whiteTransparent}
          hasBorder
        />
      </SuperWidgetWrapper>
    </WidgetWrapper>
  );
}
