import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { updateLocation } from '../../../store/rewards/actions';
import { DEFAULT_LOCATION, GOOGLE_MAPS_CONFIG } from '../consts';
import getGoogleMapsUrl from '../../Rewards/utils/getGoogleMapsUrl';

import MarkerDistance from './MarkerDistance';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function RewardsMap({ offers }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { longitude, latitude, currentMarker } = useSelector((state) => ({
    longitude: state.rewards.longitude || DEFAULT_LOCATION.longitude,
    latitude: state.rewards.latitude || DEFAULT_LOCATION.latitude,
    currentMarker: state.rewards.currentMarker || {},
  }));

  const { locations, logo, id, advertiser, title } = currentMarker;

  const currentLocation = { lat: latitude, lng: longitude };
  const [markerDistance, setMarkerDistance] = useState(0);
  const googleMapsUrl = getGoogleMapsUrl({ latitude, longitude, locations });

  useEffect(() => {
    if (locations?.[0]?.distance) {
      setMarkerDistance(locations[0].distance);
    }
  }, [locations]);

  const onMarkerClick = (marker, markerDetails) => {
    const {
      position: { lat, lng },
    } = marker;

    const newLocation = { latitude: lat, longitude: lng };

    dispatch(updateLocation(newLocation, markerDetails));
  };

  const { google } = window;

  if (!google) return null;

  return (
    <div className="map">
      {logo && markerDistance && googleMapsUrl && (
        <div className="map__popup">
          <Link to={`/rewards/in-store/${id}`} key={id}>
            <div
              className="map__popup__img"
              style={{
                background: `url(${logo}) no-repeat`,
              }}
            />

            <div className="map__popup__name">{advertiser}</div>

            <div className="map__popup__title">{title}</div>

            {markerDistance && (
              <div className="map__popup__distance">
                <MarkerDistance markerDistance={markerDistance} />
              </div>
            )}
          </Link>

          <div className="buttons-part">
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
              <DeprecatedButton
                title={<span>{t('rewardsMapPage.directions')}</span>}
                buttonClass="button button--submit"
              />
            </a>
          </div>
        </div>
      )}

      <div className="map__container" id="map">
        {latitude && longitude && (
          <Map
            google={google}
            zoom={GOOGLE_MAPS_CONFIG.zoom}
            initialCenter={currentLocation}
            center={currentLocation}
            defaultOptions={{
              fullscreenControl: false,
            }}
          >
            {offers?.length > 0 &&
              offers.map((offer) =>
                offer.locations.map((location) => (
                  <Marker
                    key={`${offer.offerId}-${location.latitude}-${location.longitude}`}
                    onClick={(marker) => onMarkerClick(marker, offer)}
                    position={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                    iconName={{
                      url:
                        id === offer.id
                          ? offer.mapMarker.selected_marker_url
                          : offer.mapMarker.marker_url,
                      scaledSize:
                        id === offer.id
                          ? new google.maps.Size(40, 53)
                          : new google.maps.Size(30, 40),
                    }}
                  />
                )),
              )}
          </Map>
        )}
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(RewardsMap);

RewardsMap.defaultProps = {
  offers: {},
};

RewardsMap.propTypes = {
  offers: PropTypes.shape(PropTypes.arrayOf(PropTypes.shape({}))),
};
