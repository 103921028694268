import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  LOGIN_URL,
  FUNDING_RDV,
  DEPOSITS_SUSPENSION_REMOVAL,
  HOME_ACTIVE_TAB,
  HOME_TABS,
  HOMEPAGE,
} from '../../constants/comonConstants';
import { AU_APP, MY_APP } from '../../constants/localeConfigs';
import { getAccountSummary } from '../../store/history/actions';
import { getOffsetters } from '../../store/rewards/actions';
import {
  getUserData,
  getSignupProgress,
  showRDVModal,
} from '../../store/user/actions';
import { getTipsByPattern } from '../../store/spendingAnalytics/actions';
import { logFirebaseEvent } from '../../utils/logFirebaseEvent';
import { FIREBASE_EVENTS } from '../../constants/firebaseEvents';

import { selectAlert } from './selectors';
import '../../styles/pages/home.scss';
import { HomeContentStyled, HomeWrapper } from './styles';
import HomeBanner from './components/HomeBanner';
import HomeModals from './components/HomeModals';
import HomeContent from './components/HomeContent';
import { useActiveHelpCard } from './components/useActiveHelpCard';

import Loader from 'components/elements/Loader/Loader';

export default function Home({ isNewRewards }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { previousStep, isFundingRDVAlert, isDepositSuspensionAlert, user } =
    useSelector((state) => ({
      user: state.user.user,
      previousStep: state.user.previousStep,
      isFundingRDVAlert: selectAlert(state, FUNDING_RDV),
      isDepositSuspensionAlert: selectAlert(state, DEPOSITS_SUSPENSION_REMOVAL),
    }));

  const { toggleHelpCard } = useActiveHelpCard(HOMEPAGE);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [currentInfo, setCurrentInfo] = useState({ title: '', msg: '' });
  const [isSuspensionSuccessOpen, setIsSuspensionSuccessOpen] = useState(false);
  const { isWithdrawal } = location?.state?.isWithdrawal || {};
  const activeTab = searchParams.get(HOME_ACTIVE_TAB);

  const isUserLoaded = user?.email;

  useEffect(() => {
    logFirebaseEvent(FIREBASE_EVENTS.homePageClicked);

    dispatch(getAccountSummary());

    dispatch(getTipsByPattern());

    if (AU_APP) {
      dispatch(getOffsetters());
    }

    if (MY_APP) {
      dispatch(getSignupProgress());
    }

    if (isWithdrawal) {
      setSearchParams({ [HOME_ACTIVE_TAB]: HOME_TABS.past });
    }

    if (!activeTab) {
      setSearchParams({ [HOME_ACTIVE_TAB]: HOME_TABS.today });
    }

    window.scrollTo(0, 0);
  }, [dispatch, isWithdrawal, setSearchParams, activeTab]);

  useEffect(() => {
    if (isFundingRDVAlert) {
      dispatch(showRDVModal(alert.title, alert.description));
    }
  }, [dispatch, isFundingRDVAlert]);

  useEffect(() => {
    if (previousStep === LOGIN_URL && isDepositSuspensionAlert) {
      toggleHelpCard(true);
    }
  }, [dispatch, previousStep, isDepositSuspensionAlert, toggleHelpCard]);

  const toggleRecurringModal = ({
    isReccuringModalOpen = false,
    title = '',
    msg = '',
  }) => {
    setIsInfoOpen(isReccuringModalOpen);

    setCurrentInfo({ title, msg });
  };

  const toggleSuspensionSuccess = (toggleSuspension) => {
    setIsSuspensionSuccessOpen(toggleSuspension);

    if (toggleSuspension) {
      dispatch(getUserData());
    }
  };

  if (!isUserLoaded) {
    return (
      <HomeWrapper>
        <Loader additionalClass="-absolute -top" />
      </HomeWrapper>
    );
  }

  return (
    <HomeWrapper>
      <HomeContentStyled>
        <HomeBanner />

        <HomeContent isNewRewards />

        <HomeModals
          isSuspensionSuccessOpen={isSuspensionSuccessOpen}
          toggleSuspensionSuccess={toggleSuspensionSuccess}
          isInfoOpen={isInfoOpen}
          toggleRecurringModal={toggleRecurringModal}
          currentInfo={currentInfo}
          isNewRewards={isNewRewards}
        />
      </HomeContentStyled>
    </HomeWrapper>
  );
}

Home.defaultProps = {
  isNewRewards: false,
};

Home.propTypes = {
  isNewRewards: PropTypes.bool,
};
