import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountMigrationFooter from '../components/AccountMigrationFooter';
import { Body, Content, Title } from '../styles';
import { useMigrationAccountSteps } from '../consts';

import { useAddressSchema } from './useAddressSchema';
import { AddressForm } from './styles';

import { IAddressDetails } from 'store/migration/types';
import { useGetCountryStatesQuery } from 'store/assets/assets';
import { useStepper } from 'hooks/useStepper';
import { InputField } from 'components/form/input/InputField';
import { saveUserData } from 'store/user/actions';
import { selectUserAddress } from 'store/user/selectors';
import { getPostCodeLength } from 'utils/formatters/getPostCodeLength';
import { SelectField } from 'components/form/select/SelectField';

export default function AccountMigrationAddress() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getGetCountryStatesQuery = useGetCountryStatesQuery();
  const steps = useMigrationAccountSteps();
  const { goNext } = useStepper({ steps });
  const address: IAddressDetails = useSelector(selectUserAddress);

  const schema = useAddressSchema();

  const form = useForm({
    defaultValues: address,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((values) => {
    dispatch(
      saveUserData({
        ...values,
        state: values.state?.[0]?.value,
      }),
    );

    goNext();
  });

  return (
    <Content>
      <Title>{t('registrationPage.accountMigration.addressPage.title')}</Title>

      <Body>
        <FormProvider {...form}>
          <AddressForm onSubmit={onSubmit}>
            <InputField
              name="street_number"
              label={t('registrationPage.addressForm.streetNumber')}
            />

            <InputField
              name="address1"
              label={t('registrationPage.addressForm.streetName')}
            />

            <InputField
              name="city"
              label={t('registrationPage.addressForm.suburb')}
            />

            {getGetCountryStatesQuery?.data && (
              <SelectField
                name="state"
                label={t('registrationPage.addressForm.state')}
                options={getGetCountryStatesQuery?.data}
              />
            )}

            <InputField
              name="zip"
              label={t('fields.postCode.label')}
              inputProps={{ maxLength: getPostCodeLength() }}
            />

            <AccountMigrationFooter />
          </AddressForm>
        </FormProvider>
      </Body>
    </Content>
  );
}
