import { RadioGroup } from '@material-ui/core';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { Label } from '../checkbox/styles';
import { ErrorLabel } from '../styles';
import { IFieldProps } from '../types';

import { RadioButton, RadioInput, RadioWrapper } from './styles';

export interface IRadioOption {
  value: string;
  label: React.ReactNode;
}
export interface IRadioFieldProps<T extends FieldValues>
  extends IFieldProps<T> {
  options: IRadioOption[];
}

export const RadioField = <T extends FieldValues>({
  name,
  options,
  isDisabled,
  onChange,
}: IRadioFieldProps<T>) => {
  const { control, setValue, register } = useFormContext();

  register(name, {
    onChange: (e) => {
      setValue(name, e?.target?.value);

      onChange?.(e);
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <RadioGroup {...field} value={field.value || ''}>
          {options.map(({ value, label }) => (
            <RadioWrapper key={value}>
              <RadioButton checked={field.value === value}>
                <RadioInput
                  disabled={isDisabled}
                  type="radio"
                  {...register(name)}
                  {...field}
                  value={value}
                />
              </RadioButton>

              <Label htmlFor={value}>{label}</Label>
            </RadioWrapper>
          ))}

          {!!error?.message && <ErrorLabel>{error?.message}</ErrorLabel>}
        </RadioGroup>
      )}
    />
  );
};
