import styled from '@emotion/styled';

import { WidgetContent } from '../styles';

import superBanner from 'assets/images/super-banner.png';

export const SuperWidgetWrapper = styled(WidgetContent)`
  background-image: url(${superBanner}),
    ${({ theme }) => theme.gradients.purple};
  background-size: 100%;
  width: 100%;
`;
