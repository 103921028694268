import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import InputMask from 'react-input-mask';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import { useTranslation, Trans } from 'react-i18next';
import Firebase from 'firebase/app';

import { getDobFromMyKadNumber, formatDobFromServer } from '../utils';

import { MyKadFieldUpdater } from './MyKadFieldUpdater';
import { FieldErrorMessage } from './FieldErrorMessage';

import { MY_KAD_REGEX } from 'constants/validation';
import {
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
} from 'store/commonTypes';
import FinalFormInput from 'components/elements/FinalFormInput/FinalFormInput';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';
import { APP_DATE_LOCALE, AU_APP, MY_APP } from 'constants/localeConfigs';
import { GENDER_LIST } from 'constants/comonConstants';
import SelectField from 'components/elements/SelectFF/SelectField';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import PertanyaanInfoModal from 'components/layout/Modals/PertanyaanInfoModal';
import { getScurityQuestion, getUserData } from 'store/user/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectSecurityQuestions } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const validate = (values, t) => {
  const errors = {};

  const required = t('fields.validation.required');

  if (!values.first_name) {
    errors.first_name = required;
  }

  if (AU_APP && !values.last_name) {
    errors.last_name = required;
  }

  if (!values.answer1) {
    errors.answer1 = required;
  }

  if (!values.question1) {
    errors.question1 = required;
  }

  if (MY_APP) {
    const isMyKadValid = MY_KAD_REGEX.test(values.my_kad);

    if (!values.my_kad) errors.my_kad = required;

    const dob = getDobFromMyKadNumber(values.my_kad);

    if (!values.dob || !dob.isValid || !isMyKadValid) {
      errors.my_kad = t('fields.validation.invalid');
    }

    if (dob.isUnderAge && dob.isValid) {
      errors.dob = t('registrationPage.personal.underage');
    }
  }

  return errors;
};

const onKeyDown = (e) => {
  e.stopPropagation();

  e.preventDefault();
};

const useSelectFieldDefaultValue = (options, value) =>
  useMemo(
    () =>
      options.filter((option) => {
        const defaultValue = value || options[0].value;

        return option.value === defaultValue;
      }),
    [options, value],
  );

export default function Info({
  handleSubmitInfo,
  errorMessage,
  goBack,
  handleChangeInfo,
  errorMessagePhone,
  showLoader,
  user,
  userFormData,
  dob,
  handleChangeDate,
  dobError,
  togglePertanyaanInfo,
  showPertanyaanInfo,
  isCalendarActive,
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const maxDate = dayjs().subtract(18, 'year').toDate();

  const securityQuestions = useAppSelector(selectSecurityQuestions);

  const userUUID = user.user.uuid;

  useEffect(() => {
    dispatch(getScurityQuestion());
  }, [dispatch]);

  const getUserInfo = useCallback(() => {
    dispatch(getUserData(false));
  }, [dispatch]);

  useEffect(() => {
    getUserInfo();

    if (AU_APP && userUUID) {
      Firebase.analytics().setUserId(userUUID);
    }
  }, [getUserInfo, userUUID]);

  const question1DefaultValue = useSelectFieldDefaultValue(
    securityQuestions,
    user.user.question1,
  );

  const genderDefaultValueMys = useSelectFieldDefaultValue(
    GENDER_LIST,
    user?.user?.gender || userFormData.gender,
  );

  return (
    <Form
      onSubmit={handleSubmitInfo}
      validate={(values) => validate(values, t)}
      initialValues={{
        first_name:
          user.user && user.user.first_name ? user.user.first_name : '',
        last_name: user.user && user.user.last_name ? user.user.last_name : '',
        phone_number:
          user.user && user.user.phone_number ? user.user.phone_number : '',
      }}
      render={({ values, submitting, handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          {MY_APP && <MyKadFieldUpdater />}

          <div className={'"form-field radio'}>
            <div className="form-field__sub-input-link">
              {MY_APP && (
                <FinalFormInput
                  name="first_name"
                  defaultValue={user.user.first_name}
                  label={t('registrationPage.personal.name')}
                />
              )}

              {MY_APP && (
                <div className="float-label phone-number my-kad">
                  <div className="label">
                    {t('registrationPage.personal.identityNumber')}
                  </div>

                  <div>
                    <Field
                      name="my_kad"
                      defaultValue={user.user.my_kad || ''}
                      subscription={{ value: true }}
                      render={({ input }) => (
                        <InputMask
                          name="my_kad"
                          value={input.value || ''}
                          mask="999999-99-9999"
                          maskChar={null}
                          placeholder="______-__-____"
                          onChange={input.onChange}
                        />
                      )}
                    />

                    <FieldErrorMessage
                      className="error-message"
                      name="my_kad"
                    />
                  </div>
                </div>
              )}

              {MY_APP && (
                <>
                  <FinalFormInput
                    name="dob"
                    readOnly
                    disabled
                    autoFillField
                    defaultValue={formatDobFromServer(user?.user?.dob)}
                    label={t('fields.dateOfBirth.label')}
                  />

                  <FieldErrorMessage
                    className="error-message-mykad"
                    name="dob"
                  />
                </>
              )}

              {MY_APP && (
                <div className="select-first -gender">
                  <div className="profile-content__container-label">
                    {t('registrationPage.personal.gender')}
                  </div>

                  <SelectField
                    name="gender_name"
                    placeholder=""
                    defaultValue={genderDefaultValueMys}
                    options={GENDER_LIST}
                    disabled
                  />
                </div>
              )}

              {AU_APP && (
                <FinalFormInput
                  name="first_name"
                  defaultValue={user.user.first_name}
                  label={t('registrationPage.personal.firstName')}
                />
              )}

              {AU_APP && (
                <FinalFormInput
                  name="last_name"
                  defaultValue={user.user.last_name}
                  label={t('registrationPage.personal.lastName')}
                />
              )}

              <div className="float-label phone-number">
                <div className="label">{t('fields.phoneNumber.label')}</div>

                <div>
                  {AU_APP && (
                    <InputMask
                      name="phone_number"
                      defaultValue={user.user.phone_number}
                      mask="0499 999 999"
                      alwaysShowMask
                      placeholder={t('fields.phoneNumber.label')}
                      permanents={[2, 5]}
                      onChange={handleChangeInfo}
                      formatChars={{
                        8: '[23478]',
                        9: '[0-9]',
                      }}
                    />
                  )}

                  {MY_APP && (
                    <InputMask
                      name="phone_number"
                      defaultValue={user.user.phone_number}
                      mask="019-99999999"
                      maskChar={null}
                      placeholder="01_-_______"
                      permanents={[0, 1, 3]}
                      onChange={handleChangeInfo}
                      formatChars={{
                        9: '[0-9]',
                      }}
                    />
                  )}

                  {errorMessagePhone && (
                    <div className="error-message">{errorMessagePhone}</div>
                  )}
                </div>
              </div>

              {AU_APP && (
                <div
                  className="MuiFormControl-root MuiTextField-root without-underline MuiFormControl-fullWidth
                  relative form-field birthday"
                >
                  <label
                    className="MuiFormLabel-root MuiInputLabel-shrink"
                    htmlFor="birthday"
                  >
                    {t('fields.dateOfBirth.label')}
                  </label>

                  <div className=" relative form-field">
                    <DatePicker
                      maxDate={maxDate}
                      onCalendarOpen={() => handleChangeDate(maxDate, true)}
                      className={`raiz-datepicker ${
                        isCalendarActive ? 'active' : ''
                      }`}
                      name="birthday"
                      value={dayjs(dob).isValid() ? dob : ''}
                      onChange={handleChangeDate}
                      format="dd MMM yyyy"
                      clearIcon={false}
                      locale={APP_DATE_LOCALE}
                      monthPlaceholder="---"
                      calendarIcon={false}
                      onKeyDown={(e) => onKeyDown(e)}
                      onKeyPress={(e) => onKeyDown(e)}
                    />

                    {dobError && submitting && (
                      <div className="error-message">
                        {t('fields.validation.required')}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {AU_APP && (
                <CheckboxFF
                  name="usResident"
                  className="us-resident"
                  defaultChecked={values.usResident}
                  type="checkbox"
                  label={
                    <Trans
                      i18nKey="registrationPage.personal.usResident"
                      t={t}
                    />
                  }
                />
              )}

              <div />

              <hr className={`-spacer ${AU_APP ? '-hide' : ''}`} />

              <div className="select-first -margin-s">
                <div className="profile-content__container-label">
                  {t('registrationPage.personal.securityQuestion')}
                </div>

                {securityQuestions[0].value && (
                  <SelectField
                    name="question1"
                    options={securityQuestions}
                    defaultValue={question1DefaultValue}
                  />
                )}
              </div>

              <FinalFormInput
                name="answer1"
                label={t('registrationPage.personal.securityQuestionAnswer')}
              />

              {showPertanyaanInfo && (
                <PertanyaanInfoModal
                  showPertanyaanInfo={showPertanyaanInfo}
                  togglePertanyaanInfo={togglePertanyaanInfo}
                />
              )}

              <div className="hide">{errorMessage}</div>

              <div className="buttons-part">
                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    title={t('common.back')}
                    buttonClass="button back"
                    handleClick={() =>
                      goBack(
                        MY_APP
                          ? REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT
                          : REGISTRATION_BANKS_FUNDING_ACCOUNT,
                      )
                    }
                  />
                </div>

                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    type="submit"
                    title={t('common.next')}
                    buttonClass="button button--submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

Info.propTypes = {
  showLoader: PropTypes.bool,
  handleSubmitInfo: PropTypes.func.isRequired,
  userFormData: PropTypes.objectOf(PropTypes.string),
  errorMessage: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  handleChangeInfo: PropTypes.func.isRequired,
  errorMessagePhone: PropTypes.string.isRequired,
  user: PropTypes.shape({
    user: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  dob: PropTypes.instanceOf(Date).isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  togglePertanyaanInfo: PropTypes.func.isRequired,
  dobError: PropTypes.bool.isRequired,
  showPertanyaanInfo: PropTypes.bool,
  isCalendarActive: PropTypes.bool.isRequired,
};

Info.defaultProps = {
  errorMessage: '',
  userFormData: { my_kad: '' },
  showLoader: false,
  showPertanyaanInfo: false,
};
