import { IFormattedSuitabilityOption } from '../types';
import { INVITE_CODE_FIELD_NAME } from '../components/const';

import { IUpdateSuitabilityData } from 'store/user/types';

export interface IGetSuitabilityRequestData {
  [key: string]: IFormattedSuitabilityOption[] | string;
}

const getSuitabilityRequestData = (values: IGetSuitabilityRequestData) =>
  Object.entries(values)?.reduce(
    (suitabilityRequestData: IUpdateSuitabilityData, [name, question]) => {
      if (typeof question === 'string') {
        if (name === INVITE_CODE_FIELD_NAME) {
          return {
            ...suitabilityRequestData,
            invitation_code: question,
          };
        }

        return {
          ...suitabilityRequestData,
          user: {
            profile: {
              suitability: suitabilityRequestData.user.profile.suitability,
              [name]: question,
            },
          },
        };
      }

      const { value, question_id } = question?.[0] || {};

      if (value) {
        const suitability = [
          ...suitabilityRequestData.user.profile.suitability,
          { answer_id: value, question_id },
        ];

        return {
          user: {
            profile: {
              ...suitabilityRequestData.user.profile,
              suitability,
            },
          },
        };
      }

      return suitabilityRequestData;
    },
    { user: { profile: { suitability: [] } } },
  );

export default getSuitabilityRequestData;
