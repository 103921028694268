import {
  ERRLINKING_FUNDING_ACCOUNT,
  ERRLINKING_SPENDING_ACCOUNT,
  ERRPERSONALPROFILE,
  NO_SUBACCOUNT,
  PENDING_INVESTMENT_WITH_PAYMENT_CODE,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
  REGISTRATION_INCOMPLETE_LINKSPENDING,
  REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT,
  STATUS_LOGIN_FAILED,
  UNANSWERED_SUITABILITY_QUESTIONS,
} from '../../../constants/comonConstants';
import { ALERT_TYPES } from '../../../constants/alerts';

import { PATHS } from 'constants/paths';

const getAlertLink = (alertType) => {
  switch (alertType) {
    case ERRLINKING_SPENDING_ACCOUNT:
    case REGISTRATION_INCOMPLETE_LINKSPENDING:
    case STATUS_LOGIN_FAILED:
    case NO_SUBACCOUNT:
      return '/settings/spending-account';
    case ERRPERSONALPROFILE:
      return '/settings';
    case REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT:
      return '/settings/withdrawal-account';
    case ALERT_TYPES.idUploadErrors.errorUploadInformation:
    case ALERT_TYPES.idUploadErrors.bankStatementUploadMissing:
    case ALERT_TYPES.idUploadErrors.additionalUploadMissing:
    case ALERT_TYPES.idUploadErrors.ktpUploadMissing:
    case ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing:
    case ALERT_TYPES.idUploadErrors.myKadBackUploadMissing:
    case PENDING_INVESTMENT_WITH_PAYMENT_CODE:
    case ALERT_TYPES.idUploadErrors.fsChangeUploadMissing:
    case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId:
    case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId:
    case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingBankStatements:
    case ALERT_TYPES.autoVerification:
      return '';
    case REGISTRATION_INCOMPLETE_LINKFUNDING:
    case ERRLINKING_FUNDING_ACCOUNT:
      return '/settings/funding-account';
    case UNANSWERED_SUITABILITY_QUESTIONS:
      return '/settings?type=financial';
    case ALERT_TYPES.profileReviewRequested:
      return PATHS.settings.updateProfile;
    default:
      return false;
  }
};

export default getAlertLink;
