import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

import * as fundingAccountAction from '../../../store/fundingAccount/actions';
import * as actionsUser from '../../../store/user/actions';
import { MY_APP } from '../../../constants/localeConfigs';
import { REQUIRED } from '../../../constants/errors';
import {
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
} from '../../../store/commonTypes';
import { REGISTRATION_STEP_SPENDING_ACCOUNT_SUCCESS } from '../../../store/user/consts';
import {
  checkIsDependentUser,
  checkIsDependentUserRegistration,
} from '../../../utils/user';
import { getLeftPartIconClassName } from '../utils/utils';

import ProgressBar from './ProgressBar';
import Banks from './Banks';

import { SEARCH_PARAMS } from 'constants/searchParams';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import {
  selectIsSpendingInstitutionsEnabled,
  selectIsVisaEnabled,
} from 'store/firebaseConfig/selectors';
import { selectDependentUserAccess } from 'store/user/selectors';
import {
  selectIsAddCardModalErrorOpen,
  selectIsBankAccountLinkingStep,
} from 'store/fundingAccount/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { FASTLINK_FLOWS } from 'components/modals/Fastlink/consts';
import { checkIsEligibleForFastlink } from 'utils/checkIsEligibleForFastlink';

// TODO: https://acornsau.atlassian.net/browse/RAIZ-6448
class BankAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowBankModal: false,
      isShowBankModalError: false,
      bank: {},
      bankCurrentTarget: 'LOGIN',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      isShowPassword: true,
      cardNumber: '',
      cardNumberError: '',
      isChangeBankCard: false,
      isShowBankCardModal: false,
      isShowWithdrawalSignIn: false,
      showMainLoader: true,
      isShowModalError: false,
      roundUpsCheck: true,
      searchStart: false,
      isShowLinkFunding: false,
      isCardSelect: false,
      linkCardId: '',
      isShowConfirmationConnect: false,
      bankData: {},
      isNeedLoadWithdrawalBanks: false,
      isShowBankInformationModal: false,
      isBankCardModalOpen: false,
      isAllVisaBanksDisplayed: false,
      isFastlinkModalOpen: false,
      searchValue: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.handleStopBankAccountLinking =
      this.handleStopBankAccountLinking.bind(this);

    this.bankLoginProcessWait = this.bankLoginProcessWait.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);

    this.openDifferentBank = this.openDifferentBank.bind(this);

    this.showMoreBanks = this.showMoreBanks.bind(this);

    this.searchBanks = this.searchBanks.bind(this);

    this.skip = this.skip.bind(this);

    this.goBackFromMoreBanks = this.goBackFromMoreBanks.bind(this);

    this.linkAnFunding = this.linkAnFunding.bind(this);

    this.trackScrolling = this.trackScrolling.bind(this);

    this.handleSubAccount = this.handleSubAccount.bind(this);

    this.changeAutoRoundUps = this.changeAutoRoundUps.bind(this);

    this.handleSubAccountFunding = this.handleSubAccountFunding.bind(this);

    this.clearSearch = this.clearSearch.bind(this);

    this.confirmationConnect = this.confirmationConnect.bind(this);

    this.showBanks = this.showBanks.bind(this);

    this.closeBankInfoModal = this.closeBankInfoModal.bind(this);

    this.addCard = this.addCard.bind(this);

    this.openAddCardModal = this.openAddCardModal.bind(this);

    this.displayAllVisaBanks = this.displayAllVisaBanks.bind(this);

    this.connectionStatusBack = this.connectionStatusBack.bind(this);

    this.toggleFastlinkModal = this.toggleFastlinkModal.bind(this);

    this.changeSearchValue = this.changeSearchValue.bind(this);
  }

  componentDidMount() {
    const { actions, stepName, isForLinkFunding, userActions } = this.props;

    actions
      .getFundingAccount(
        true,
        false,
        !!MY_APP && !isForLinkFunding,
        false,
        isForLinkFunding,
      )
      .then(() => {
        if (MY_APP) {
          actions.getPaymentCard().then(() => {
            this.setState({ showMainLoader: false });
          });

          if (
            stepName === REGISTRATION_STEP_SPENDING_ACCOUNT_SUCCESS ||
            stepName === 'fundingAccount'
          ) {
            // actions.getPaymentCard('spending', true)
            //   .then(() => {
            //     this.setState({ showMainLoader: false });
            //   });
          }
        } else {
          actions.getBanks({
            isSpending: true,
            isShowBank: false,
          });

          this.setState({ showMainLoader: false });
        }
      });

    if (checkIsDependentUserRegistration()) {
      userActions.getDependentAccess();
    }
  }

  // TODO: Check new "UNSAFE_componentWillReceiveProps" => "componentDidUpdate + getDerivedStateFromProps"
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const newData = {};
  //   if (nextProps.isPaymentFailedProps !== prevState.isPaymentFailedProps) {
  //     newData.isPaymentFailedProps = nextProps.isPaymentFailedProps;
  //   }
  //   if (nextProps.currentStepProps !== prevState.currentStepProps) {
  //     newData.currentStepProps = nextProps.currentStepProps;
  //   }
  //   if (nextProps.isForBankLinkFundingProps !== prevState.isForBankLinkFundingProps) {
  //     newData.isForBankLinkFundingProps = nextProps.isForBankLinkFundingProps;
  //   }
  //   if (nextProps.isPaymentSuccessProps !== prevState.isPaymentSuccessProps) {
  //     newData.isPaymentSuccessProps = nextProps.isPaymentSuccessProps;
  //   }
  //   if (nextProps.switchCardSuccess !== prevState.switchCardSuccess) {
  //     newData.switchCardSuccess = nextProps.switchCardSuccess;
  //   }
  //   if (nextProps.isFromLinkFundingProps !== prevState.isFromLinkFundingProps) {
  //     newData.isFromLinkFundingProps = nextProps.isFromLinkFundingProps;
  //   }
  //   if (nextProps.skipBankLoginProps !== prevState.skipBankLoginProps) {
  //     newData.skipBankLoginProps = nextProps.skipBankLoginProps;
  //   }
  //   if (nextProps.paymentCard !== prevState.paymentCard) {
  //     newData.paymentCard = nextProps.paymentCard;
  //   }
  //   if (nextProps.isShowCreditCardError !== prevState.isShowCreditCardError) {
  //     newData.isShowCreditCardError = nextProps.isShowCreditCardError;
  //   }
  //   if (nextProps.isShowDifferentBankModal !== prevState.isShowDifferentBankModal) {
  //     newData.isShowDifferentBankModal = nextProps.isShowDifferentBankModal;
  //   }
  //   if (nextProps.errorMessageProps !== prevState.errorMessageProps) {
  //     newData.errorMessageProps = nextProps.errorMessageProps;
  //   }
  //   if (nextProps.isShowLinkCardError !== prevState.isShowLinkCardError) {
  //     newData.isShowLinkCardError = nextProps.isShowLinkCardError;
  //   }
  //   return newData;
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const {
  //     skipBankLogin, errorMessage, isPaymentFailed, isPaymentSuccess, stepName, history, isForBankLinkFunding,
  //     actions, isFromLinkFunding, currentStep
  //   } = this.props;
  //   const {
  //     errorMessageProps, isShowDifferentBankModal, isShowCreditCardError, paymentCard, skipBankLoginProps,
  //     isFromLinkFundingProps, switchCardSuccess, isPaymentSuccessProps, isForBankLinkFundingProps, currentStepProps,
  //     isPaymentFailedProps, isShowLinkCardError
  //   } = this.state;
  // if (isPaymentFailedProps && prevState.isPaymentFailed !== isPaymentFailedProps
  //   && isPaymentFailedProps !== isPaymentFailed) {
  //   this.setState({ isPaymentFailed: true });
  // }
  // if (MY_APP && currentStep && prevState.currentStepProps !== currentStepProps && currentStepProps !== currentStep
  //   && currentStep === REGISTRATION_BANKS_FUNDING_ACCOUNT) {
  //   actions.getPaymentCard('spending', true)
  //     .then(() => {
  //       this.setState({ showMainLoader: false, isShowLinkFunding: true });
  //     });
  // }
  //
  // if (MY_APP && stepName === REGISTRATION_STEP_SPENDING_ACCOUNT_SUCCESS) {
  //   this.setState({ showMainLoader: true });
  // }

  // if (MY_APP && (isForBankLinkFundingProps || isForBankLinkFunding)
  //   && isForBankLinkFunding !== isForBankLinkFundingProps) {
  //   this.setState({ isShowLinkFunding: isForBankLinkFundingProps, isPaymentSuccess: false });
  // }

  // if (isPaymentSuccessProps && prevState.isPaymentSuccessProps !== isPaymentSuccessProps
  //   && isPaymentSuccessProps !== isPaymentSuccess
  //   && stepName !== REGISTRATION_STEP_SPENDING_ACCOUNT_SUCCESS) {
  //   this.setState({ isPaymentSuccess: true });
  // }
  // if (switchCardSuccess) {
  //   this.setState({ isChangeBankCard: false });
  //   if (MY_APP && isFromLinkFundingProps && prevState.isFromLinkFundingProps !== isFromLinkFundingProps
  //     && isFromLinkFundingProps !== isFromLinkFunding) {
  //     actions.getPaymentCard().then(() => {
  //       this.setState({ isShowLinkFunding: false, showMainLoader: false });
  //       history.push(REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT);
  //     });
  //   }
  // }
  // if (skipBankLoginProps && prevState.skipBankLoginProps !== skipBankLoginProps
  //   && skipBankLoginProps !== skipBankLogin) {
  //   this.setState({
  //     isShowBankModal: false
  //   });
  // }
  // if (paymentCard && paymentCard.request_url) {
  //   setTimeout(() => {
  //     document.infinitium.submit();
  //   }, 2000);
  // }
  // if (isShowCreditCardError || isShowLinkCardError) {
  //   this.setState({
  //     isShowBankModalError: true
  //   });
  // }
  // if (skipBankLoginProps && prevState.skipBankLoginProps !== skipBankLoginProps
  //   && skipBankLoginProps !== skipBankLogin) {
  //   this.setState({
  //     isShowBankModal: false
  //   });
  // }
  // const stopBankLoginRequest = parseInt(sessionStorage.getItem('stopBankLoginRequest'));
  // if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps && errorMessageProps !== errorMessage
  //   && (!stopBankLoginRequest || isShowDifferentBankModal))) {
  //   this.setState({
  //     isShowBankModalError: true
  //   });
  //   sessionStorage.setItem('stopBankLoginRequest', 1);
  // }
  // }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      skipBankLogin,
      errorMessage,
      stepName,
      history,
      isForBankLinkFunding,
      actions,
      isFromLinkFunding,
      currentStep,
      monitoredInstitutions,
    } = this.props;

    const { isNeedLoadWithdrawalBanks } = this.state;

    if (
      stepName === 'spendingAccount' &&
      newProps.stepName === 'fundingAccount'
    ) {
      if (MY_APP) {
        this.setState({ showMainLoader: true });

        actions
          .getBanks({
            isSpending: newProps.isSpending,
          })
          .then(() => {
            setTimeout(() => {
              this.setState({ showMainLoader: false });
            }, 1000);
          });
      } else {
        actions.getBanks({
          isSpending: true,
          isShowBank: false,
          isReconectForFunding: true,
        });
      }
    }

    if (
      MY_APP &&
      stepName === 'fundingAccount' &&
      newProps.stepName === 'withdrawalAccount'
    ) {
      this.setState({ showMainLoader: true });

      actions
        .getBanks({
          isWithdrawal: true,
        })
        .then(() => {
          setTimeout(() => {
            this.setState({ showMainLoader: false });
          }, 1000);
        });
    }

    const { subaccounts, linked, plaid } =
      newProps.monitoredInstitutions?.[0] || {};

    if (
      stepName === 'fundingAccount' &&
      (!monitoredInstitutions || monitoredInstitutions.length === 0) &&
      newProps.monitoredInstitutions?.length !== 0 &&
      subaccounts?.length !== 0 &&
      linked &&
      plaid
    ) {
      actions.showConnectScreen(
        newProps.monitoredInstitutions,
        true,
        false,
        false,
        true,
      );
    }

    if (
      MY_APP &&
      currentStep &&
      newProps.currentStep !== currentStep &&
      newProps.currentStep === REGISTRATION_BANKS_FUNDING_ACCOUNT
    ) {
      actions.getPaymentCard('spending', true).then(() => {
        this.setState({ showMainLoader: false, isShowLinkFunding: true });
      });
    }

    if (
      MY_APP &&
      newProps.stepName === 'withdrawalAccount' &&
      newProps.isFromMyKad &&
      !isNeedLoadWithdrawalBanks
    ) {
      this.setState({ isNeedLoadWithdrawalBanks: true });

      actions
        .getBanks({
          isWithdrawal: true,
        })
        .then(() => {
          setTimeout(() => {
            this.setState({ showMainLoader: false });
          }, 1000);
        });
    }

    if (
      MY_APP &&
      (newProps.isForBankLinkFunding || isForBankLinkFunding) &&
      isForBankLinkFunding !== newProps.isForBankLinkFunding
    ) {
      this.setState({
        isShowLinkFunding: newProps.isForBankLinkFunding,
      });
    }

    if (newProps.switchCardSuccess) {
      this.setState({ isChangeBankCard: false });

      if (
        MY_APP &&
        newProps.isFromLinkFunding &&
        newProps.isFromLinkFunding !== isFromLinkFunding
      ) {
        actions.getPaymentCard().then(() => {
          this.setState({ isShowLinkFunding: false, showMainLoader: false });

          history.push(REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT);
        });
      }
    }

    if (newProps.skipBankLogin && newProps.skipBankLogin !== skipBankLogin) {
      this.setState({
        isShowBankModal: false,
      });
    }

    if (newProps.paymentCard && newProps.paymentCard.request_url) {
      setTimeout(() => {
        document.infinitium.submit();
      }, 2000);
    }

    if (newProps.isShowCreditCardError || newProps.isShowLinkCardError) {
      this.setState({
        isShowBankModalError: true,
      });
    }

    if (newProps.skipBankLogin && newProps.skipBankLogin !== skipBankLogin) {
      this.setState({
        isShowBankModal: false,
      });
    }

    const stopBankLoginRequest = parseInt(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLoginRequest),
    );

    if (
      newProps.errorMessage &&
      newProps.errorMessage !== errorMessage &&
      (!stopBankLoginRequest || newProps.isShowDifferentBankModal)
    ) {
      this.setState({
        isShowBankModalError: true,
      });

      sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 1);
    }
  }

  componentDidUpdate(_, prevState) {
    const { searchValue } = this.state;

    if (
      typeof searchValue === 'string' &&
      prevState.searchValue !== searchValue
    ) {
      this.searchBanks();
    }
  }

  handleCloseModal(e, isConnect = false) {
    const { actions } = this.props;

    actions.closeModal();

    actions.showWaitPopUpBankLogin(false);

    this.setState({
      isShowBankModal: false,
      isShowBankCardModal: false,
      isShowWithdrawalSignIn: false,
      isShowConfirmationConnect: false,
      isBankCardModalOpen: false,
      isAllVisaBanksDisplayed: false,
    });

    if (isConnect) {
      actions.closeConnectScreen();
    }
  }

  handleCloseModalError(e, isLinkCard = false, isEmptySubaccount = false) {
    const { actions } = this.props;

    if (isLinkCard) {
      this.setState({ isShowLinkFunding: false });
    } else {
      actions.stopLoginRequest();

      this.setState({
        isShowBankModalError: false,
        isShowWithdrawalSignIn: false,
        isShowModalError: false,
      });

      if (isEmptySubaccount) {
        this.setState({
          isShowBankModal: false,
        });

        actions.closeConnectScreen();
      }
    }
  }

  handleStopBankAccountLinking() {
    const { actions } = this.props;

    // TODO: https://acornsau.atlassian.net/browse/RAIZ-6447
    actions.showWaitPopUpBankLogin(false);

    this.skip();

    sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 1);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('bankSearch');

    const { actions, addListener, isWaitingUpdate, isSpending } = this.props;

    if (addListener && this.isBottom(wrappedElement) && !isWaitingUpdate) {
      actions.waitingBanks();

      actions.getBanksList({
        isMoreBanks: true,
        isOffset: true,
        isSpending,
      });
    }
  };

  signInBank = (bankId, e) => {
    if (e) {
      e.preventDefault();
    }

    if (MY_APP) {
      const { state } = this.props;

      if (checkIsDependentUserRegistration()) {
        const bank = state.fundingAccount.banksInstitutions.institutions.find(
          (banks) => banks.institution_id === bankId,
        );

        if (bank) {
          this.setState({
            bank,
          });
        }

        this.setState({
          isShowBankModal: true,
        });
      } else {
        const bank = state.fundingAccount.banks.banks.find(
          (banks) => banks.id === bankId,
        );

        if (bank) {
          this.setState({
            bank,
          });
        }

        this.setState({
          isShowWithdrawalSignIn: true,
        });
      }
    } else {
      const { state, actions } = this.props;

      state.fundingAccount.credentials.LOGIN = '';

      state.fundingAccount.credentials.PASSWORD = '';

      let bank = state.user.banks.institutions.find(
        (banks) => banks.institution_id === bankId,
      );

      if (!bank || bank.length === 0) {
        bank = state.user.moreBanks.find(
          (banks) => banks.institution_id === bankId,
        );
      }

      if (bank) {
        this.setState({
          bank,
        });
      }

      const isEligibleForFastlink = checkIsEligibleForFastlink({
        provider: bank.provider,
      });

      if (isEligibleForFastlink) {
        actions.setCurrentBank(bank);

        actions.setFastlinkFlow(FASTLINK_FLOWS.add);

        this.setState({
          isFastlinkModalOpen: true,
        });

        return;
      }

      this.setState({
        isShowBankModal: true,
      });
    }
  };

  searchBanks = () => {
    const { actions, isSpending } = this.props;
    const { searchValue } = this.state;

    this.setState({
      searchStart: true,
    });

    actions.getBanksList({
      isMoreBanks: true,
      name: searchValue,
      isSpending,
    });
  };

  bankInSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const {
          actions,
          userActions,
          isSpending,
          isFromInvestments,
          user,
          dependentAccess,
        } = this.props;

        const { bank, roundUpsCheck } = this.state;

        userActions.showLoader();

        let isKidsRegistrationHome = false;
        let isKidsRegistrationPortfolio = false;

        if (checkIsDependentUser(user)) {
          if (dependentAccess && dependentAccess.manage_portfolio) {
            isKidsRegistrationPortfolio = true;
          } else {
            isKidsRegistrationHome = true;
          }
        }

        const bankData = {
          formData: values,
          bank,
          isSpending,
          isRegistration: true,
          isFromInvestments,
          roundUpsCheck,
          isMfa: false,
          isKidsRegistrationPortfolio,
          isKidsRegistrationHome,
          isReConnectAccount: false,
        };

        actions.bankSignIn(bankData);
      }
    }
  };

  bankInSubmitWithdrawal = (e, values, form, hasValidationErrors) => {
    const { bank } = this.state;
    const { actions, userActions, isFromMyKad } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const data = {
          account_number: values.accountNumber,
          bank_id: bank.id,
        };

        userActions.showLoader();

        actions.bankSignDifferentIn(data, true, false, true, isFromMyKad);
      }
    }
  };

  bankInDifferentSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      form.resumeValidation();

      e.persist();

      e.preventDefault();

      form.submit();

      let valuesValidate = {};

      valuesValidate = values;

      if (!hasValidationErrors) {
        valuesValidate.accountNumber = valuesValidate.accountNumber.replace(
          /\D/g,
          '',
        );

        valuesValidate.accountNumberConfirm =
          valuesValidate.accountNumberConfirm.replace(/\D/g, '');

        const { actions, isFromInvestments, userActions } = this.props;

        userActions.showLoader();

        actions.bankSignDifferentIn(valuesValidate, true, isFromInvestments);
      }
    }
  };

  handleChangeBank = (e) => {
    const { state } = this.props;

    this.setState({ bankCurrentTarget: e.target.name });

    if (state.form.BANK_SIGN_IN && state.form.BANK_SIGN_IN.initial) {
      state.form.BANK_SIGN_IN.initial[e.target.name] = e.target.value;
    }

    if (
      state.form.BANK_SIGN_IN_DIFFERENT &&
      state.form.BANK_SIGN_IN_DIFFERENT.initial
    ) {
      state.form.BANK_SIGN_IN_DIFFERENT.initial[e.target.name] = e.target.value;
    }
  };

  linkCard = (e, values, form, hasValidationErrors) => {
    const { cardNumber, bank } = this.state;

    const { actions, userActions, isSpending, t, isFromInvestments } =
      this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (cardNumber === '') {
          this.setState({
            cardNumberError: REQUIRED,
          });
        }

        if (cardNumber.replace(/\s/g, '').length !== 16) {
          this.setState({
            cardNumberError: t('fields.validation.wrongValue', {
              value: t('common.cardNumber'),
            }),
          });
        } else {
          const data = {
            number: cardNumber.replace(/\s/g, ''),
            cvv: values.cvvCode,
            expiration_year: values.cardYear[0].value,
            expiration_month: values.cardMonth[0].value,
            spending: isSpending,
            funding: !isSpending,
            bank_id: bank.id,
            holder_name: values.holderName,
          };

          const isFromInvestmentsParam = isFromInvestments
            ? `?${createSearchParams({
                [SEARCH_PARAMS.isFromInvestments]: isFromInvestments,
              }).toString()}`
            : '';

          const redirectData = {
            failed_url: `${window.location.href}-failed${isFromInvestmentsParam}`,
            success_url: `${window.location.href}-success${isFromInvestmentsParam}`,
          };

          userActions.showLoader();

          actions.addPaymentCard(data, redirectData);
        }
      }
    }
  };

  handleChangeCardNumber = (e) => {
    this.setState({ cardNumber: e.target.value, cardNumberError: '' });
  };

  openBankCardModal = (bankId) => {
    const { state } = this.props;

    const bank = state.fundingAccount.banks.banks.filter(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    this.setState({
      isShowBankCardModal: true,
    });
  };

  handleSubAccount = (e, accountId, monitoredId, linked) => {
    const { actions } = this.props;

    e.target.parentElement.classList.toggle('checkbox__check');

    this.setState({
      bankData: {
        accountId,
        monitoredId,
        linked,
      },
    });

    actions.subAccountLinkUnlink(accountId, monitoredId, linked);
  };

  handleSubAccountFunding = (
    e,
    accountId,
    monitoredId,
    linked,
    accountNickname = '',
    isConfirmConnect = false,
    bankId,
  ) => {
    const { actions, userActions, isSpending } = this.props;

    userActions.showLoader();

    if (isConfirmConnect) {
      if (isSpending) {
        actions.getBanks().then(() => {
          this.setState({
            isShowConfirmationConnect: false,
          });

          const { monitoredInstitutions } = this.props;

          actions.showConnectScreen(
            monitoredInstitutions,
            true,
            false,
            false,
            false,
            monitoredInstitutions?.[0].linked &&
              monitoredInstitutions?.[0].plaid,
          );
        });
      } else {
        actions.addAccountForInvestments(
          accountId,
          monitoredId,
          bankId,
          true,
          linked,
        );
      }
    } else {
      this.setState({
        bankData: {
          accountId,
          monitoredId,
          linked,
          accountNickname,
        },
        isShowConfirmationConnect: true,
      });
    }
  };

  selectLinkCard = (e, cardId) => {
    if (
      e &&
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.classList
    ) {
      if (e.target.parentElement.classList.contains('checkbox__check')) {
        e.target.parentElement.className = 'checkbox__plus';

        this.setState({
          isCardSelect: false,
          linkCardId: '',
        });
      } else {
        this.setState({
          isCardSelect: true,
          linkCardId: cardId,
        });

        e.target.parentElement.className = 'checkbox__plus checkbox__check';
      }
    }
  };

  linkCardForFunding = () => {
    const { actions } = this.props;
    const { isCardSelect, linkCardId, t } = this.state;

    if (isCardSelect && linkCardId !== '') {
      actions.unlinkSpendingCard(linkCardId, true, true, true);
    } else {
      actions.showErrorUnlinkCard(
        t('settings.registrationBankAccount.selectConnectedCardMessage'),
      );
    }
  };

  showBanks = () => {
    const { actions } = this.props;

    if (MY_APP) {
      this.setState({ showMainLoader: true });

      actions.getBanks().then(() => {
        actions.closeConnectScreen();

        setTimeout(() => {
          this.setState({ showMainLoader: false });
        }, 1000);
      });
    }
  };

  connectionStatusBack() {
    const { actions } = this.props;

    actions.showWaitPopUpBankLogin(false);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 1);
  }

  bankLoginProcessWait() {
    const { actions } = this.props;

    actions.waitingInProcess();
  }

  showMoreBanks() {
    const { actions, isSpending } = this.props;

    actions.getBanksList({
      isMoreBanks: true,
      isSpending,
    });
  }

  skip() {
    const {
      actions,
      state,
      isSpending,
      isFromInvestments,
      isWithdrawal,
      user,
      dependentAccess,
    } = this.props;

    this.setState({
      searchStart: false,
    });

    actions.resetProps();

    let isKidsRegistrationHome = false;
    let isKidsRegistrationPortfolio = false;

    if (checkIsDependentUser(user)) {
      if (dependentAccess && dependentAccess.manage_portfolio) {
        isKidsRegistrationPortfolio = true;
      } else {
        isKidsRegistrationHome = true;
      }
    }

    if (MY_APP && isWithdrawal) {
      actions.skipBank(state.user, false, false, true);
    } else {
      actions.skipBank(
        state.user,
        isSpending,
        isFromInvestments,
        false,
        isKidsRegistrationHome,
        isKidsRegistrationPortfolio,
      );
    }
  }

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    return false;
  }

  openDifferentBank() {
    const { actions } = this.props;

    this.setState({
      bank: {},
    });

    actions.showDifferetnBank();

    actions.closeConnectScreen();
  }

  goBackFromMoreBanks() {
    const { actions, isSpending } = this.props;

    this.changeSearchValue(null);

    this.setState({
      searchStart: false,
    });

    actions.getBanksList({
      isMoreBanks: false,
      isSpending,
    });

    actions.backFromMoreBanks();

    actions.resetProps();
  }

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  changeDate(value, type) {
    if (type === 'month') {
      this.setState({
        cardMonth: value,
      });
    } else {
      this.setState({
        cardYear: value,
      });
    }
  }

  linkAnFunding() {
    const { userActions } = this.props;

    userActions.goToFundingAccount();
  }

  changeAutoRoundUps(e) {
    this.setState({
      roundUpsCheck: e.target.checked,
    });
  }

  clearSearch() {
    const { actions } = this.props;
    const searchEl = document.getElementById('search-bank');

    searchEl.value = '';

    this.setState({
      searchStart: false,
    });

    actions.resetProps();
  }

  confirmationConnect() {
    const { actions } = this.props;

    this.setState({
      isShowConfirmationConnect: true,
    });

    actions.setBankAccountData(null);
  }

  closeBankInfoModal() {
    this.setState({ isShowBankInformationModal: false });
  }

  addCard({ e, form, hasValidationErrors }) {
    const { cardNumber } = this.state;
    const { actions, userActions, t, dependentAccess } = this.props;

    const normalizedCardNumber = cardNumber.replace(/\s/g, '');

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (hasValidationErrors) return;

      if (!normalizedCardNumber.length) {
        this.setState({
          cardNumberError: t('fields.validation.required'),
        });

        return;
      }

      if (normalizedCardNumber.length !== 16) {
        this.setState({
          cardNumberError: t('fields.validation.wrongValue', {
            value: t('common.cardNumber'),
          }),
        });

        return;
      }

      const data = {
        number: normalizedCardNumber,
      };

      userActions.showLoader();

      actions
        .addVisaCard({
          data,
          isKidWithPortfolioAccess: dependentAccess?.manage_portfolio,
        })
        .then(() => {
          const { isShowAddCardModalError } = this.props;

          if (!isShowAddCardModalError) {
            this.setState({
              isBankCardModalOpen: false,
              showMainLoader: false,
              cardNumber: '',
              cardNumberError: '',
            });

            actions.closeModal();

            actions.getVisaPaymentCard();
          }
        });
    }
  }

  openAddCardModal(bankId) {
    const { state } = this.props;

    this.setState({ isBankCardModalOpen: true });

    const bank = state.fundingAccount.banks.banks.find(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({ bank });
    }
  }

  displayAllVisaBanks(e) {
    e.persist();

    e.preventDefault();

    this.setState({
      isAllVisaBanksDisplayed: true,
    });
  }

  toggleFastlinkModal() {
    const { isFastlinkModalOpen } = this.state;
    const { actions } = this.props;

    actions.setFastlinkFlow(FASTLINK_FLOWS.add);

    this.setState({
      isFastlinkModalOpen: !isFastlinkModalOpen,
    });
  }

  changeSearchValue(searchValue) {
    this.setState({
      searchValue,
    });
  }

  render() {
    const {
      isShowBankModal,
      isShowBankModalError,
      bank,
      bankCurrentTarget,
      isShowBankCardModal,
      cardMonth,
      cardYear,
      cardCvv,
      isShowPassword,
      cardNumberError,
      isChangeBankCard,
      isShowWithdrawalSignIn,
      showMainLoader,
      isShowModalError,
      roundUpsCheck,
      searchStart,
      isShowLinkFunding,
      isShowConfirmationConnect,
      bankData,
      isShowBankInformationModal,
      isBankCardModalOpen,
      isAllVisaBanksDisplayed,
      isFastlinkModalOpen,
      searchValue,
    } = this.state;

    const {
      errorMessage,
      handleClick,
      goBack,
      banks,
      state,
      isShowBankModalAlert,
      isWithdrawal,
      errorTitle,
      isShowMoreBanks,
      moreBanks,
      isSpending,
      isShowDifferentBankModal,
      isFromInvestments,
      showLoaderFunding,
      paymentCard,
      fundingPaymentCards,
      spendingPaymentCards,
      isShowConnectModal,
      monitoredInstitutions,
      isReconectForFunding,
      isSpendingWithdrawal,
      isFromMyKad,
      isBankAccountLinkingStep,
      t,
      visaEnabled,
      isInstitutionsApiEnabled,
      user,
    } = this.props;

    let fundingClass = '';
    let spendingClass = '';
    const isKidsSign = checkIsDependentUserRegistration();

    if (!isSpending && !isWithdrawal) {
      fundingClass = 'active';
    }

    if (isSpending && isWithdrawal) {
      fundingClass = 'already';
    }

    if (isSpending && !isWithdrawal) {
      spendingClass = 'active';
    }

    if (isWithdrawal) {
      spendingClass = 'already';

      fundingClass = 'already';
    }

    if (!isSpending && !isWithdrawal) {
      spendingClass = 'already';
    }

    const rightPart = (
      <div>
        {!isKidsSign && (
          <ProgressBar
            isFromInvestments={isFromInvestments}
            spendingClass={spendingClass}
            fundingClass={fundingClass}
            isWithdrawal={isWithdrawal}
            isFromMyKad={isFromMyKad}
          />
        )}

        <div className="bottom-part">
          <Banks
            onSubmit={this.bankInSubmit}
            showLoader={showLoaderFunding}
            handleClick={handleClick}
            goBack={goBack}
            banks={banks}
            signInBank={this.signInBank}
            openDifferentBank={this.openDifferentBank}
            isShowBankModal={isShowBankModal}
            isShowBankModalError={isShowBankModalError}
            bank={bank}
            credentials={state.fundingAccount.credentials}
            handleCloseModal={this.handleCloseModal}
            handleCloseModalError={this.handleCloseModalError}
            handleChangeBank={this.handleChangeBank}
            handleStopBankAccountLinking={this.handleStopBankAccountLinking}
            isShowBankModalAlert={isShowBankModalAlert}
            bankLoginProcessWait={this.bankLoginProcessWait}
            errorMessage={errorMessage}
            errorTitle={errorTitle}
            bankInSubmit={this.bankInSubmit}
            isShowMoreBanks={isShowMoreBanks}
            showMoreBanks={this.showMoreBanks}
            moreBanks={moreBanks}
            skip={this.skip}
            isSpending={
              isSpendingWithdrawal && !(MY_APP && checkIsDependentUser(user))
                ? false
                : isSpending
            }
            isShowDifferentBankModal={isShowDifferentBankModal}
            bankInDifferentSubmit={this.bankInDifferentSubmit}
            bankCurrentTarget={bankCurrentTarget}
            isFromInvestments={isFromInvestments}
            isShowBankCardModal={isShowBankCardModal}
            openBankCardModal={this.openBankCardModal}
            cardMonth={cardMonth}
            cardYear={cardYear}
            cardCvv={cardCvv}
            changeDate={this.changeDate}
            linkCard={this.linkCard}
            toggleShowPassword={this.toggleShowPassword}
            isShowPassword={isShowPassword}
            handleChangeCardNumber={this.handleChangeCardNumber}
            cardNumberError={cardNumberError}
            paymentCard={paymentCard}
            fundingPaymentCards={fundingPaymentCards}
            isChangeBankCard={isChangeBankCard}
            spendingPaymentCards={spendingPaymentCards}
            isWithdrawal={isWithdrawal}
            isShowWithdrawalSignIn={isShowWithdrawalSignIn}
            showMainLoader={showMainLoader}
            linkAnFunding={this.linkAnFunding}
            bankInSubmitWithdrawal={this.bankInSubmitWithdrawal}
            isShowConnectModal={isShowConnectModal}
            monitoredInstitutions={monitoredInstitutions}
            handleSubAccount={this.handleSubAccount}
            isShowModalError={isShowModalError}
            changeAutoRoundUps={this.changeAutoRoundUps}
            roundUpsCheck={roundUpsCheck}
            handleSubAccountFunding={this.handleSubAccountFunding}
            clearSearch={this.clearSearch}
            searchStart={searchStart}
            isShowLinkFunding={isShowLinkFunding}
            linkCardForFunding={this.linkCardForFunding}
            selectLinkCard={this.selectLinkCard}
            isReconectForFunding={isReconectForFunding}
            confirmationConnect={this.confirmationConnect}
            isShowConfirmationConnect={isShowConfirmationConnect}
            bankData={bankData}
            showBanks={this.showBanks}
            isFromMyKad={isFromMyKad}
            isShowBankInformationModal={isShowBankInformationModal}
            closeBankInfoModal={this.closeBankInfoModal}
            visaEnabled={MY_APP && checkIsDependentUser(user) && visaEnabled}
            isInstitutionsApiEnabled={isInstitutionsApiEnabled}
            isBankCardModalOpen={isBankCardModalOpen}
            addCard={this.addCard}
            openAddCardModal={this.openAddCardModal}
            isAllVisaBanksDisplayed={isAllVisaBanksDisplayed}
            displayAllVisaBanks={this.displayAllVisaBanks}
            isBankAccountLinkingStep={isBankAccountLinkingStep}
            connectionStatusBack={this.connectionStatusBack}
            toggleFastlinkModal={this.toggleFastlinkModal}
            isFastlinkModalOpen={isFastlinkModalOpen}
            changeSearchValue={this.changeSearchValue}
            searchValue={searchValue}
          />
        </div>
      </div>
    );

    return (
      <div
        className={`registration-page bank-page ${isKidsSign ? '-kids' : ''}`}
      >
        <div className="step-banks form-investment">
          <div className="left-part">
            <div className="part-content">
              <div className="logo" />

              <div className="title" />

              <div className={getLeftPartIconClassName(user)} />

              <div className="bottom-text">
                {isKidsSign
                  ? t('registrationPage.leftBottom.kids')
                  : t('registrationPage.leftBottom.four')}
              </div>
            </div>
          </div>

          <div className={`right-part ${isKidsSign ? '-align-top' : ''}`}>
            {isShowMoreBanks && (
              <Scrollbars
                // className="terms-scroll"
                style={{
                  width: '100%',
                  height: 'calc(100vh - 220px)',
                  overflow: 'hidden',
                }}
                onScroll={this.trackScrolling}
              >
                {rightPart}
              </Scrollbars>
            )}

            {isShowMoreBanks && !isBankAccountLinkingStep && (
              <div className="form-field bank-skip">
                <DeprecatedButton
                  title={t('common.back')}
                  buttonClass="button back small"
                  handleClick={() => this.goBackFromMoreBanks()}
                />

                <DeprecatedButton
                  type="submit"
                  title={t('common.skip')}
                  buttonClass="button back small"
                  handleClick={this.skip}
                />
              </div>
            )}

            {!isShowMoreBanks && <div>{rightPart}</div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  banks: state.user.banks,
  credentials: state.fundingAccount.credentials,
  isShowBankModalAlert: state.fundingAccount.isShowBankModalAlert,
  errorMessage: state.fundingAccount.errorMessage,
  errorTitle: state.fundingAccount.errorTitle,
  skipBankLogin: state.fundingAccount.skipBankLogin,
  isShowMoreBanks: state.fundingAccount.isShowMoreBanks,
  moreBanks: state.fundingAccount.moreBanks,
  isShowDifferentBankModal: state.fundingAccount.isShowDifferentBankModal,
  isFromInvestments: state.user.isFromInvestments,
  showLoaderFunding: state.user.showLoaderFunding,
  isShowCreditCardError: state.fundingAccount.isShowCreditCardError,
  paymentCard: state.fundingAccount.paymentCard,
  fundingPaymentCards: state.fundingAccount.fundingPaymentCards,
  spendingPaymentCards: state.fundingAccount.spendingPaymentCards,
  addListener: state.fundingAccount.addListener,
  isWaitingUpdate: state.fundingAccount.isWaitingUpdate,
  monitoredInstitutions: state.fundingAccount.monitoredInstitutions,
  isShowConnectModal: state.fundingAccount.isShowConnectModal,
  isForBankLinkFunding: state.fundingAccount.isForBankLinkFunding,
  isFromLinkFunding: state.fundingAccount.isFromLinkFunding,
  switchCardSuccess: state.fundingAccount.switchCardSuccess,
  currentStep: state.user.currentStep,
  isShowLinkCardError: state.fundingAccount.isShowLinkCardError,
  isReconectForFunding: state.fundingAccount.isReconectForFunding,
  isSpendingWithdrawal: state.fundingAccount.isSpendingWithdrawal,
  isFromMyKad: state.user.isFromMyKad,
  isForLinkFunding: state.user.isForLinkFunding,
  dependentAccess: selectDependentUserAccess(state),
  visaEnabled: selectIsVisaEnabled(state),
  isInstitutionsApiEnabled: selectIsSpendingInstitutionsEnabled(state),
  isShowAddCardModalError: selectIsAddCardModalErrorOpen(state),
  isBankAccountLinkingStep: selectIsBankAccountLinkingStep(state),
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(fundingAccountAction, dispatch),
  userActions: bindActionCreators(actionsUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BankAccount));

BankAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  stepName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  handleClick: PropTypes.func,
  goBack: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    getFundingAccount: PropTypes.func,
    skipBank: PropTypes.func,
    getPaymentCard: PropTypes.func,
    getBanks: PropTypes.func,
    backFromMoreBanks: PropTypes.func,
    waitingBanks: PropTypes.func,
    getBanksList: PropTypes.func,
    getMoreBanksSuccess: PropTypes.func,
    bankSignIn: PropTypes.func,
    bankSignDifferentIn: PropTypes.func,
    addPaymentCard: PropTypes.func,
    showConnectScreen: PropTypes.func,
    closeConnectScreen: PropTypes.func,
    showWaitPopUpBankLogin: PropTypes.func,
    resetProps: PropTypes.func,
    closeModal: PropTypes.func,
    showDifferetnBank: PropTypes.func,
    subAccountLinkUnlink: PropTypes.func,
    addAccountForInvestments: PropTypes.func,
    stopLoginRequest: PropTypes.func,
    unlinkSpendingCard: PropTypes.func,
    showErrorUnlinkCard: PropTypes.func,
    waitingInProcess: PropTypes.func,
    getVisaPaymentCard: PropTypes.func,
    addVisaCard: PropTypes.func,
    setCurrentBank: PropTypes.func,
    setFastlinkFlow: PropTypes.func,
    setBankAccountData: PropTypes.func,
  }).isRequired,
  banks: PropTypes.shape({}),
  isShowBankModalAlert: PropTypes.bool,
  errorTitle: PropTypes.string,
  state: PropTypes.shape({
    form: PropTypes.shape({
      BANK_SIGN_IN: PropTypes.string,
      BANK_SIGN_IN_DIFFERENT: PropTypes.string,
    }),
    fundingAccount: PropTypes.shape({
      credentials: PropTypes.shape({
        LOGIN: PropTypes.string,
        PASSWORD: PropTypes.string,
      }),
      banks: PropTypes.shape({
        banks: PropTypes.arrayOf(PropTypes.shape({})),
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      banksInstitutions: PropTypes.shape({
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    user: PropTypes.shape({
      moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
      banks: PropTypes.shape({
        banks: PropTypes.arrayOf(PropTypes.shape({})),
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
  skipBankLogin: PropTypes.bool,
  isShowMoreBanks: PropTypes.bool,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
  isSpending: PropTypes.bool,
  isWithdrawal: PropTypes.bool,
  isShowDifferentBankModal: PropTypes.bool,
  isFromInvestments: PropTypes.bool,
  showLoaderFunding: PropTypes.bool,
  userActions: PropTypes.shape({
    goToFundingAccount: PropTypes.func,
    getDependentAccess: PropTypes.func,
    showLoader: PropTypes.func,
  }).isRequired,
  paymentCard: PropTypes.shape({}),
  fundingPaymentCards: PropTypes.shape({}),
  spendingPaymentCards: PropTypes.shape({}),
  addListener: PropTypes.bool,
  isWaitingUpdate: PropTypes.bool,
  isShowConnectModal: PropTypes.bool,
  monitoredInstitutions: PropTypes.arrayOf(
    PropTypes.shape({
      linked: PropTypes.bool,
      plaid: PropTypes.bool,
    }),
  ),
  isForBankLinkFunding: PropTypes.bool,
  isFromLinkFunding: PropTypes.bool,
  currentStep: PropTypes.string,
  isReconectForFunding: PropTypes.bool,
  isSpendingWithdrawal: PropTypes.bool,
  isFromMyKad: PropTypes.bool,
  isForLinkFunding: PropTypes.bool,
  user: PropTypes.shape({
    user: PropTypes.shape({
      user_type: PropTypes.string,
    }),
  }),
  dependentAccess: PropTypes.shape({
    manage_portfolio: PropTypes.bool,
  }),
  t: PropTypes.func.isRequired,
  visaEnabled: PropTypes.bool,
  isInstitutionsApiEnabled: PropTypes.bool,
  isShowAddCardModalError: PropTypes.bool,
  isBankAccountLinkingStep: PropTypes.bool.isRequired,
};

BankAccount.defaultProps = {
  errorMessage: '',
  handleClick: {},
  banks: {},
  isShowBankModalAlert: false,
  errorTitle: '',
  state: {},
  skipBankLogin: false,
  isWithdrawal: false,
  showLoaderFunding: false,
  isShowMoreBanks: false,
  moreBanks: [],
  isSpending: false,
  isShowDifferentBankModal: false,
  isFromInvestments: false,
  paymentCard: {},
  fundingPaymentCards: {},
  spendingPaymentCards: {},
  addListener: false,
  isWaitingUpdate: true,
  isShowConnectModal: false,
  monitoredInstitutions: [],
  isForBankLinkFunding: false,
  isFromLinkFunding: false,
  currentStep: '',
  isReconectForFunding: false,
  isSpendingWithdrawal: false,
  isFromMyKad: false,
  isForLinkFunding: false,
  user: {},
  dependentAccess: {},
  visaEnabled: false,
  isInstitutionsApiEnabled: false,
  isShowAddCardModalError: false,
};
