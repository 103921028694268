import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SettingsView from './SettingsView';

export default class Settings extends Component {
  render() {
    const { history, supportStep, isNewRewards, match } = this.props;
    const { step } = match.params;

    return (
      <SettingsView
        step={step || ''}
        history={history}
        supportStep={supportStep}
        isNewRewards={isNewRewards}
      />
    );
  }
}

Settings.defaultProps = {
  match: {},
  history: {},
  supportStep: '',
  isNewRewards: false,
};

Settings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({}),
  supportStep: PropTypes.string,
  isNewRewards: PropTypes.bool,
};
