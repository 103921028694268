import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Input(props) {
  const {
    className,
    input,
    defaultValue,
    label,
    subLabel,
    type,
    meta: { touched, error, active },
    classList,
    placeholder,
    onClick,
    id,
    onChange,
    defaultChecked,
    name,
    autoFocus,
  } = props;

  const { t } = useTranslation();
  let beforeInput = '';
  let afterInput = '';

  if (type === 'checkbox' || type === 'radio') {
    afterInput = <div className="form-field__label">{label}</div>;
  } else {
    beforeInput = <div className="form-field__label">{label}</div>;
  }

  return (
    <div className={`form-field  ${className}`}>
      <div
        className={`form-field__input
        ${touched && error ? ' form-field__input--error' : ''}
        ${
          active || (input && input.value) ? ' form-field__input--active' : ''
        } ${classList}`}
      >
        {beforeInput}

        {defaultChecked && (
          <input
            onClick={onClick}
            onChange={onChange}
            defaultChecked={defaultChecked}
            id={id}
            {...input}
            type={type}
            placeholder={placeholder}
            name={name}
            autoFocus={autoFocus}
          />
        )}

        {defaultValue && (
          <input
            onClick={onClick}
            onChange={onChange}
            id={id}
            {...input}
            defaultValue={defaultValue}
            type={type}
            placeholder={placeholder}
            name={name}
            autoFocus={autoFocus}
          />
        )}

        {!defaultValue && !defaultChecked && (
          <input
            onClick={onClick}
            onChange={onChange}
            id={id}
            {...input}
            type={type}
            placeholder={placeholder}
            name={name}
            autoFocus={autoFocus}
          />
        )}

        {afterInput}

        {touched && error && (
          <span className="text-danger">{t(`fields.validation.${error}`)}</span>
        )}

        {subLabel && (
          <div className="form-field__sub-input-text">{subLabel}</div>
        )}
      </div>
    </div>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.element,
  }),
  label: PropTypes.element,
  subLabel: PropTypes.element,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.element,
    active: PropTypes.bool,
  }),
  classList: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  name: PropTypes.string,
  autoFocus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Input.defaultProps = {
  subLabel: null,
  input: {},
  meta: {},
  classList: '',
  className: '',
  placeholder: '',
  value: '',
  label: null,
  defaultValue: null,
  onClick: null,
  onChange: null,
  id: null,
  type: 'text',
  defaultChecked: null,
  name: null,
  autoFocus: '',
};
