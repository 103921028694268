import {
  IS_DEPENDENT_USER_REGISTRATION,
  USER_TYPES,
} from '../constants/comonConstants';

export const checkIsSmsfUser = (userData) => {
  const userType = userData?.user_type || userData?.user?.user_type;

  return userType === USER_TYPES.smsf;
};

export const checkIsDependentUser = (userData) => {
  const userType = userData?.user_type || userData?.user?.user_type;

  return userType === USER_TYPES.dependent;
};

export const checkIsDependentUserRegistration = () =>
  sessionStorage.getItem(IS_DEPENDENT_USER_REGISTRATION);
