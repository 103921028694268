import '@emotion/react';

import colors from './colors';
import gradients from './gradients';
import shadows from './shadows';
import fontFamily from './fontFamily';
import { breakpoints, mediaQuery } from './mediaQuery';
import {
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
} from './typography';
import zIndexes from './zIndexes';
import opacity from './opacity';

const theme = {
  colors,
  gradients,
  breakpoints,
  fontFamily,
  fontWeights,
  fontSizes,
  lineHeights,
  mediaQuery,
  zIndexes,
  shadows,
  letterSpacing,
  opacity,
} as const;

export type RaizTheme = typeof theme;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends RaizTheme {}
}

export default theme;
