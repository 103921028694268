import '../../styles/pages/change-portfolio.scss';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-modal';

import ChangePortfolioCustomView from './components/ChangePortfolioCustomView';
import { BottomChart } from './components/BottomChart';
import { getPortfolioPath, getPortfolioPreviousPage } from './utils';
import PortfolioTitle from './PortfolioTitle';
import { PortfolioChart } from './components/PortfolioChart';
import { InvestmentField } from './components/InvestmentField';
import { SwitcherTitle, SwitcherItem } from './components/Switcher';
import { SwitcherWrapper } from './styles';

import { RecommendedPortfolioModal } from 'components/layout/Modals/RecommendedPortfolioModal/RecommendedPortfolioModal';
import { PORTFOLIO } from 'constants/comonConstants';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { CUSTOM_PORTFOLIO } from 'store/user/consts';
import { MY_APP, AU_APP } from 'constants/localeConfigs';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import InvestmentObjectiveModal from 'components/layout/Modals/InvestmentObjectiveModal';
import ChangePortfolioConfirmModal from 'components/layout/Modals/ChangePortfolioConfirmModal';
import { checkIsDependentUser } from 'utils/user';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export default function ChangePortfolioView({
  originalBalance,
  toggleShow,
  portfolioType,
  chartDataYears,
  handleChange,
  onClick,
  monthly,
  portfolioETF,
  activeEtf,
  ratio,
  portfolios,
  isDashboard,
  isOpenHelpCards,
  toggleHelpCard,
  savePortfolio,
  isSuper,
  isShowPortfolioStrategy,
  strategyImage,
  showPortfolioStrategyImg,
  handleCloseModal,
  showLoader,
  isFuture,
  isRegistration,
  goBack,
  showBtnLoader,
  etfs,
  isShowRecommendedPortfolio,
  savedPortfolioType,
  errorMessage,
  isShowErrorModal,
  isShowConfirm,
  currentPortfolioName,
  portfolioFunds,
  customPortfolio,
  isCustom,
  activeFunds,
  clickOnFund,
  changeFund,
  onChangeComplete,
  isEditCustom,
  customEtfs,
  customEditToggle,
  customSave,
  customName,
  customNameChange,
  isEmptyCustomPortfolio,
  errorMessageCustom,
  customPortfolioId,
  activePortfolioId,
  isShowModalAllocation,
  changeModalAllocation,
  dontShowModalAllocation,
  customEnabled,
  customBeta,
  balancingEtf,
  isSuperRegistration,
  moderatePlusOn,
  isKidsRegistration,
  user,
  isEditKids,
  onChangeEtf,
  isProPortfolioEnabled,
  isSuperPlusPortfolioEnabled,
}) {
  useSuperAccountErrorModal();

  const { t } = useTranslation();

  const previousPage = getPortfolioPreviousPage(isKidsRegistration);

  const isPortfolioTypeVisible = !isDashboard && !isFuture;

  const portfolioTypeClass = isPortfolioTypeVisible ? portfolioType : '';

  const isChromium = !!window.chrome;
  let accessChangePortfolio = true;

  if (checkIsDependentUser(user)) {
    const access = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
    );

    if (access && !access.manage_portfolio) {
      accessChangePortfolio = false;
    }
  }

  const isEditPortfolioDependent =
    checkIsDependentUser(user) && !accessChangePortfolio;

  const isBottomChartVisible =
    !isDashboard &&
    !isFuture &&
    ((MY_APP && !isShowRecommendedPortfolio) || !MY_APP);

  const portfolioPath = getPortfolioPath(isProPortfolioEnabled);

  const isInvestmentFieldVisible =
    !isSuper &&
    !isRegistration &&
    (!isCustom || isFuture || (!isEditKids && isKidsRegistration));

  const isPortfolioChartVisible =
    !isSuper && !isRegistration && !isEditKids && !isKidsRegistration;

  const isPortfolioAndBottomChartVisible =
    (chartDataYears?.Mean?.length || isSuper) &&
    (!isCustom || isFuture || isDashboard);

  const isPlusPortfolioVisible =
    !isProPortfolioEnabled || (isSuper && !isSuperPlusPortfolioEnabled);

  return (
    <div
      className={`portfolio-page ${isSuper ? '-super' : ''} ${
        isChromium && isRegistration ? 'chrome' : ''
      }
      ${!isDashboard ? '-min-height' : ''}`}
    >
      <div
        className={
          MY_APP
            ? `portfolio my-portfolio ${portfolioTypeClass}`
            : `portfolio ${portfolioTypeClass} ${
                isProPortfolioEnabled && 'pro-portfolio'
              }`
        }
      >
        {portfolios.length !== 0 && !showLoader ? (
          <div className={isRegistration ? 'registration-portfolio' : ''}>
            {!isDashboard && (
              <div>
                {!isFuture &&
                  ((MY_APP && !isShowRecommendedPortfolio && isRegistration) ||
                    !MY_APP ||
                    !isFuture) && (
                    <div>
                      {!isRegistration &&
                        isCustom &&
                        !isEditCustom &&
                        accessChangePortfolio && (
                          <DeprecatedButton
                            title={t('changePortfolioPage.editPortfolio')}
                            handleClick={customEditToggle}
                            buttonClass="button custom-btn"
                          />
                        )}

                      {!isRegistration && !MY_APP && !isProPortfolioEnabled && (
                        <DeprecatedButton
                          buttonClass="rewards__banner-info my-finance__banner-info"
                          handleClick={() => toggleHelpCard(true)}
                        />
                      )}

                      <div className="page-content__banner-title">
                        {!isRegistration &&
                          !isProPortfolioEnabled &&
                          t('changePortfolioPage.portfolios')}

                        {isRegistration &&
                          ((!AU_APP && !MY_APP) || !isCustom) &&
                          currentPortfolioName}

                        {isRegistration && isCustom && (
                          <SwitcherTitle
                            isCustom={isCustom}
                            portfolioType={portfolioType}
                            isEditCustom={isEditCustom}
                            customNameChange={customNameChange}
                            customName={customName}
                            portfolioName=""
                            isRegistration={isRegistration}
                            isEmptyCustomPortfolio
                            portfolioKey={null}
                            savedPortfolioType={null}
                            customBeta={customBeta}
                            accessChangePortfolio={accessChangePortfolio}
                          />
                        )}
                      </div>

                      <SwitcherWrapper isRegistration={isRegistration}>
                        {portfolios.map((portfolio) => (
                          <SwitcherItem
                            key={portfolio.id}
                            portfolio={portfolio}
                            portfolioType={portfolio.identifier_key}
                            toggleShow={toggleShow}
                            isRegistration={isRegistration}
                            savedPortfolioType={savedPortfolioType}
                            isEditCustom={isEditCustom}
                            customName={customName}
                            customNameChange={customNameChange}
                            isEmptyCustomPortfolio={false}
                            activePortfolioId={activePortfolioId}
                            customBeta={customBeta}
                            moderatePlusOn={moderatePlusOn}
                          />
                        ))}

                        {!isSuper && customEnabled && (
                          <SwitcherItem
                            portfolio={{
                              id: customPortfolioId,
                              name_key: CUSTOM_PORTFOLIO,
                              name:
                                customName && customName !== ''
                                  ? customName
                                  : 'Custom',
                            }}
                            portfolioType={portfolioType}
                            toggleShow={toggleShow}
                            isRegistration={isRegistration}
                            savedPortfolioType={savedPortfolioType}
                            isEditCustom={isEditCustom}
                            customName={customName}
                            customNameChange={customNameChange}
                            isEmptyCustomPortfolio={isEmptyCustomPortfolio}
                            activePortfolioId={activePortfolioId}
                            customBeta={customBeta}
                            accessChangePortfolio={accessChangePortfolio}
                          />
                        )}
                      </SwitcherWrapper>
                    </div>
                  )}

                {isInvestmentFieldVisible && (
                  <InvestmentField
                    onChange={handleChange}
                    onClick={onClick}
                    monthly={monthly}
                  />
                )}
              </div>
            )}

            {isPortfolioAndBottomChartVisible && (
              <div
                className={isSuper ? 'chart-content super' : 'chart-content'}
              >
                {isPortfolioChartVisible && (
                  <PortfolioChart
                    isDashboard={isDashboard}
                    isFuture={isFuture}
                    originalBalance={originalBalance}
                    chartDataYears={chartDataYears}
                  />
                )}

                {isBottomChartVisible && (
                  <BottomChart
                    activeEtf={activeEtf}
                    etfs={etfs}
                    isDashboard={isDashboard}
                    isFuture={isFuture}
                    isShowRecommendedPortfolio={isShowRecommendedPortfolio}
                    portfolioETF={portfolioETF}
                    portfolios={portfolios}
                    portfolioType={portfolioType}
                    ratio={ratio}
                    onChangeEtf={onChangeEtf}
                    isRegistration={isRegistration}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <DeprecatedDotsLoader />
        )}

        {isOpenHelpCards && (
          <HelpCardsModalAU
            isOpenHelpCards={isOpenHelpCards}
            currentPage={PORTFOLIO}
            toggleHelpCard={toggleHelpCard}
            moderatePlusOn={moderatePlusOn}
          />
        )}

        {isShowPortfolioStrategy && (
          <InvestmentObjectiveModal
            isShowPortfolioStrategy={isShowPortfolioStrategy}
            handleCloseModal={handleCloseModal}
            strategyImage={strategyImage}
          />
        )}

        {isRegistration && isShowRecommendedPortfolio && (
          <RecommendedPortfolioModal
            handleOpenRecommendedPortfolioModal={handleCloseModal}
            savePortfolio={savePortfolio}
          />
        )}

        {isFuture && (
          <div className="bottom-future">
            <div className="title">
              <PortfolioTitle portfolioType={portfolioType} />
            </div>

            <div className="btn">
              <DeprecatedButton
                isLink
                to={portfolioPath}
                buttonClass="button__basic-fill -white-inverse -link raiz-kids__dashboard-btn"
                title={t('changePortfolioPage.goToPortfolio')}
              />
            </div>
          </div>
        )}

        {!isDashboard && !isFuture && isPlusPortfolioVisible && (
          <div className="buttons-part">
            {isRegistration && (
              <div className="form-field">
                <DeprecatedButton
                  withLoader={showBtnLoader}
                  buttonClass="button back"
                  title={t('common.back')}
                  handleClick={() => goBack(previousPage)}
                />
              </div>
            )}

            <div
              className={
                isEditCustom ? 'form-field form-field-custom' : 'form-field'
              }
            >
              {(portfolioType !== savedPortfolioType ||
                isSuperRegistration ||
                isRegistration) &&
                !isEditCustom &&
                !isEditPortfolioDependent &&
                (!isProPortfolioEnabled || isSuper) && (
                  <DeprecatedButton
                    withLoader={showBtnLoader}
                    handleClick={savePortfolio}
                    buttonClass="button button--submit"
                    title={
                      <Trans
                        t={t}
                        i18nKey={
                          (isRegistration &&
                            !isSuperRegistration &&
                            'changePortfolioPage.saveChanges') ||
                          (isSuperRegistration &&
                            'changePortfolioPage.selectPortfolio') ||
                          (!isRegistration &&
                            !isSuperRegistration &&
                            'changePortfolioPage.changePortfolio') ||
                          'common.space'
                        }
                      />
                    }
                  />
                )}

              {isEditCustom && isCustom && accessChangePortfolio && (
                <div className="custom-buttons-part">
                  {!isRegistration && !isEmptyCustomPortfolio && (
                    <DeprecatedButton
                      title={
                        <Trans i18nKey="changePortfolioPage.cancelEdit" t={t} />
                      }
                      handleClick={customEditToggle}
                      buttonClass="button button--submit wait"
                    />
                  )}

                  <DeprecatedButton
                    withLoader={showLoader}
                    title={
                      <Trans
                        i18nKey={
                          isRegistration
                            ? 'changePortfolioPage.saveChanges'
                            : 'changePortfolioPage.saveAllocation'
                        }
                        t={t}
                      />
                    }
                    handleClick={customSave}
                    buttonClass="button button--submit"
                  />
                </div>
              )}
            </div>

            {isSuper && !isRegistration && (
              <div className="form-field">
                <DeprecatedButton
                  buttonClass="button button-link"
                  handleClick={showPortfolioStrategyImg}
                  title={
                    <Trans
                      i18nKey="changePortfolioPage.investmentObjective"
                      t={t}
                    />
                  }
                />
              </div>
            )}
          </div>
        )}

        {isCustom && !isDashboard && !isSuper && !isFuture && (
          <ChangePortfolioCustomView
            toggleShow={toggleShow}
            portfolioType={portfolioType}
            portfolios={portfolios}
            isDashboard={isDashboard}
            showLoader={showLoader}
            isFuture={isFuture}
            isRegistration={isRegistration}
            isShowRecommendedPortfolio={isShowRecommendedPortfolio}
            portfolioFunds={portfolioFunds}
            customPortfolio={customPortfolio}
            activeFunds={activeFunds}
            clickOnFund={clickOnFund}
            changeFund={changeFund}
            onChangeComplete={onChangeComplete}
            isEditCustom={isEditCustom}
            customEtfs={customEtfs}
            errorMessageCustom={errorMessageCustom}
            handleCloseModal={handleCloseModal}
            isShowModalAllocation={isShowModalAllocation}
            changeModalAllocation={changeModalAllocation}
            dontShowModalAllocation={dontShowModalAllocation}
            balancingEtf={balancingEtf}
          />
        )}
      </div>

      {isShowErrorModal && errorMessage !== '' && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowErrorModal}
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            <div className="alert-text">{errorMessage}</div>

            <div className="error-buttons">
              <DeprecatedButton
                title={t('common.ok')}
                type="button"
                handleClick={handleCloseModal}
              />
            </div>
          </div>
        </Modal>
      )}

      {isShowConfirm && (
        <ChangePortfolioConfirmModal
          isShowConfirm={isShowConfirm}
          showBtnLoader={showBtnLoader}
          savePortfolio={savePortfolio}
          handleCloseModal={handleCloseModal}
          currentPortfolioName={currentPortfolioName}
        />
      )}
    </div>
  );
}

ChangePortfolioView.defaultProps = {
  ratio: null,
  showLoader: true,
  showBtnLoader: false,
  currentPortfolioName: '',
  customPortfolioId: '',
  activePortfolioId: '',
};

ChangePortfolioView.propTypes = {
  originalBalance: PropTypes.number.isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
  }).isRequired,
  toggleShow: PropTypes.func.isRequired,
  portfolioType: PropTypes.string.isRequired,
  chartDataYears: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  monthly: PropTypes.string.isRequired,
  portfolioETF: PropTypes.shape({
    aULargeCap: PropTypes.shape({
      ratios: PropTypes.shape({}),
    }),
  }).isRequired,
  activeEtf: PropTypes.string.isRequired,
  showBtnLoader: PropTypes.bool,
  ratio: PropTypes.number,
  portfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDashboard: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  toggleHelpCard: PropTypes.func.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  savePortfolio: PropTypes.func.isRequired,
  isSuper: PropTypes.bool.isRequired,
  isShowPortfolioStrategy: PropTypes.bool.isRequired,
  strategyImage: PropTypes.string.isRequired,
  showPortfolioStrategyImg: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isFuture: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  etfs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isKidsRegistration: PropTypes.bool.isRequired,
  isShowRecommendedPortfolio: PropTypes.bool.isRequired,
  savedPortfolioType: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isShowErrorModal: PropTypes.bool.isRequired,
  isShowConfirm: PropTypes.bool.isRequired,
  currentPortfolioName: PropTypes.string,
  portfolioFunds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customPortfolio: PropTypes.shape({}).isRequired,
  isCustom: PropTypes.bool.isRequired,
  activeFunds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clickOnFund: PropTypes.func.isRequired,
  changeFund: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
  isEditCustom: PropTypes.bool.isRequired,
  customEtfs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customEditToggle: PropTypes.func.isRequired,
  customSave: PropTypes.func.isRequired,
  customName: PropTypes.string.isRequired,
  customNameChange: PropTypes.func.isRequired,
  isEmptyCustomPortfolio: PropTypes.bool.isRequired,
  errorMessageCustom: PropTypes.string.isRequired,
  customPortfolioId: PropTypes.string,
  activePortfolioId: PropTypes.string,
  isShowModalAllocation: PropTypes.bool.isRequired,
  changeModalAllocation: PropTypes.func.isRequired,
  dontShowModalAllocation: PropTypes.bool.isRequired,
  customEnabled: PropTypes.bool.isRequired,
  customBeta: PropTypes.bool.isRequired,
  balancingEtf: PropTypes.string.isRequired,
  isSuperRegistration: PropTypes.bool.isRequired,
  moderatePlusOn: PropTypes.bool.isRequired,
  isEditKids: PropTypes.bool.isRequired,
  onChangeEtf: PropTypes.func.isRequired,
  isProPortfolioEnabled: PropTypes.bool.isRequired,
  isSuperPlusPortfolioEnabled: PropTypes.bool.isRequired,
};
