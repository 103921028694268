import { ApexOptions } from 'apexcharts';

import { formatNumber } from 'utils/formatters/formatNumber';
import { CURRENCY_SYMBOL } from 'constants/localeConfigs';
import theme from 'theme';

const getPerformanceChartConfig = (): ApexOptions => ({
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    offsetX: 10,
    parentHeightOffset: 0,
  },
  grid: {
    show: true,
    padding: {
      left: 18,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    onItemClick: {
      toggleDataSeries: true,
    },
    show: false,
    position: 'top',
    markers: {
      width: 0,
    },
  },
  markers: {
    strokeWidth: 0,
    hover: {
      size: 6,
    },
  },
  tooltip: {
    custom({ ctx, dataPointIndex }) {
      const { data } = ctx;

      const formattedAmount = formatNumber({
        value: data.twoDSeries[dataPointIndex],
      });

      const date = data.twoDSeriesX[dataPointIndex];

      return `<div>${formattedAmount}</div><div>${date}</div>`;
    },
  },
  fill: {
    colors: [theme.colors.white],
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      gradientToColors: [theme.colors.purple],
      opacityFrom: [0.9],
      opacityTo: [0],
    },
  },
  xaxis: {
    type: 'numeric',
    labels: {
      showDuplicates: true,
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      width: 1,
      position: 'back',
      stroke: {
        color: theme.colors.dustyGray,
        width: 2,
        dashArray: 0,
      },
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
      offsetX: -2,
      style: {
        colors: [theme.colors.bluishPurpleTransparent],
        fontSize: '14px',
        fontWeight: 400,
      },
      formatter: (value) => `${CURRENCY_SYMBOL}${value.toFixed(2)}`,
    },
  },
  stroke: {
    width: 2,
    colors: [theme.colors.purpleBlue],
    dashArray: 99999,
  },
  colors: [theme.colors.purpleBlue],
});

export default getPerformanceChartConfig;
