import { addLocaleData } from 'react-intl';
import intlEN from 'react-intl/locale-data/en';
import intlID from 'react-intl/locale-data/id';
import intlMS from 'react-intl/locale-data/ms';

import { BAHASA, ENGLISH } from '../constants/comonConstants';

import en from './en';
import ms from './ms';

addLocaleData([...intlEN, ...intlID, ...intlMS]);

const lang = localStorage.getItem('lang');
const currentURL = new URLSearchParams(window.location.search);
const isWebView = !!currentURL.get('webView');
const langFromUrl = currentURL.get('lang') || 'en';

let locale = '';

if (process.env.REACT_APP_CODE_BASE_VERSION === 'aus') {
  locale = 'en';
} else if (process.env.REACT_APP_CODE_BASE_VERSION === 'mys' && isWebView) {
  locale = langFromUrl;
} else if (
  process.env.REACT_APP_CODE_BASE_VERSION === 'mys' &&
  lang === BAHASA
) {
  locale = 'ms';
} else if (
  process.env.REACT_APP_CODE_BASE_VERSION === 'mys' &&
  (lang === ENGLISH || !lang)
) {
  locale = 'en';
} else {
  locale = process.env.REACT_APP_CODE_BASE_VERSION;
}

export default {
  locale,
  messages: {
    en,
    ms,
  },
};
