export const DEFAULT_SPENDING_SKIP_DELAY = 10;
export const LOADING_START_TIME = 'loadingStartTime';

export const FUNDING_PAGE = '/settings/funding-account';
export const CONNECT_ACCOUNT = 'CONNECT_ACCOUNT';
export const SKIP_BANK_LOGIN = 'SKIP_BANK_LOGIN';
export const SHOW_DIFFERENT_BANK = 'SHOW_DIFFERENT_BANK';
export const STOP_BANK_LOGIN_REQUEST = 'STOP_BANK_LOGIN_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS';
export const ADD_PAYMENT_CARD_SUCCESS = 'ADD_PAYMENT_CARD_SUCCESS';
export const GET_SPENDING_PAYMENT_CARD_SUCCESS =
  'GET_SPENDING_PAYMENT_CARD_SUCCESS';
export const SHOW_ERROR_SWITHC_PAYMENT_CARD = 'SHOW_ERROR_SWITHC_PAYMENT_CARD';
export const SWITHC_CARD_OTHER_ACCOUNT_SUCCESS =
  'SWITHC_CARD_OTHER_ACCOUNT_SUCCESS';
export const SHOW_ERROR_UNLINK_CARD = 'SHOW_ERROR_UNLINK_CARD';
export const WATING_BANKS = 'WATING_BANKS';
export const SHOW_WITHDRAWAL_ERROR = 'SHOW_WITHDRAWAL_ERROR';
export const FUNDING_SOURCE_ERROR = 'FUNDING_SOURCE_ERROR';
export const SHOW_WITHDRAWAL = 'SHOW_WITHDRAWAL';
export const RESET_MFA_FIELD = 'RESET_MFA_FIELD';
export const SHOW_FUNDING_SECURITY_MODAL = 'SHOW_FUNDING_SECURITY_MODAL';
export const REMOVE_SUSPENSION_SUCCESS = 'REMOVE_SUSPENSION_SUCCESS';
export const CLOSE_BANK_CONNECT_MODAL = 'CLOSE_BANK_CONNECT_MODAL';
export const START_CONNECT_TO_BANK = 'START_CONNECT_TO_BANK';
export const GET_VISA_PAYMENT_CARD_SUCCESS = 'GET_VISA_PAYMENT_CARD_SUCCESS';
export const DELETE_VISA_CARD_SUCCESS = 'DELETE_VISA_CARD_SUCCESS';
export const ADD_VISA_CARD_SUCCESS = 'ADD_VISA_CARD_SUCCESS';
export const REFRESH_ROUNDUP = 'REFRESH_ROUNDUP';
export const REFRESH_ROUNDUP_SUCCESS = 'REFRESH_ROUNDUP_SUCCESS';
export const GET_BANK_INSTITUTIONS_SUCCESS = 'GET_BANK_INSTITUTIONS_SUCCESS';
export const SET_IS_CONNECTION_STEP = 'SET_IS_CONNECTION_STEP';
export const SET_CONNECTION_STATUS_DATA = 'SET_CONNECTION_STATUS_DATA';
export const SET_BANK_ACCOUNT_DATA = 'SET_BANK_ACCOUNT_DATA';
export const SET_CURRENT_BANK = 'SET_CURRENT_BANK';
export const SET_FASTLINK_FLOW = 'SET_FASTLINK_FLOW';
export const SET_IS_SHOW_CONNECT_MODAL = 'SET_IS_SHOW_CONNECT_MODAL';
export const SET_IS_SHOW_CONNECT_STEP = 'SET_IS_SHOW_CONNECT_STEP';
export const SET_IS_LINK_CARD_LOADING = 'SET_IS_LINK_CARD_LOADING';

export const BANK_TYPES = {
  plaid: 'plaid',
  funding: 'funding',
};

export const VERIFICATION_STATUS = {
  created: 'created',
  canceled: 'canceled',
  handled: 'handled',
};

export const BANK_PROVIDERS = {
  yodleeFastlink: 'YodleeFastlink',
  yodlee: 'Yodlee',
};

export const KEEP_CACHED_DATA_FOR_INSTITUTIONS_BY_ID_IN_SECONDS = 10;
