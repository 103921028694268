import styled from '@emotion/styled';

import closeIcon from 'assets/images/icons/close.svg';

export const ContentWrapper = styled.div`
  background-image: ${({ theme }) => theme.gradients.lushMeadow};
  border-radius: 6px 6px 0 0;
  padding: 33px 24px 28px;
  text-align: center;
`;

export const FooterWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100px;
  padding: 0 32px;
`;

export const CloseIconButton = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  background-image: url(${`${closeIcon}`});
  background-repeat: no-repeat;
  background-size: 19px;
  border-radius: 0;
  height: 19px;
  position: absolute;
  right: 25px;
  top: 33px;
  width: 19px;
`;
