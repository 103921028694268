import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  StatusButtonsWrapper,
  StatusCancelButton,
  StatusViewButton,
} from './styles';

import { PATHS } from 'constants/paths';
import {
  useVerificationStatusQuery,
  useCancelVerificationMutation,
} from 'store/fundingAccount/fundingAccount';
import { VERIFICATION_STATUS } from 'store/fundingAccount/consts';

export const StatusButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useVerificationStatusQuery();
  const [cancelVerification, { isLoading }] = useCancelVerificationMutation();

  const status = data?.status;

  if (status !== VERIFICATION_STATUS.created) {
    return null;
  }

  return (
    <StatusButtonsWrapper>
      <StatusViewButton
        title={t('common.view')}
        handleClick={() => {
          navigate(PATHS.alerts.index);
        }}
        withLoader={isLoading}
      />

      <StatusCancelButton
        title={t('common.cancel')}
        handleClick={cancelVerification}
        withLoader={isLoading}
      />
    </StatusButtonsWrapper>
  );
};
