import { useTranslation } from 'react-i18next';

import {
  StatementsFooterConfirmButton,
  StatementsFooterWrapper,
  StatementsFooterCancelButton,
} from './styles';

import { IStatementsFooterProps } from 'pages/Statements/types';

export default function StatementsFooter({
  clearSelectedStatements,
  selectedStatements,
  sendStatements,
}: IStatementsFooterProps) {
  const { t } = useTranslation();

  const isSomeStatementSelected = selectedStatements.length > 0;

  return (
    <StatementsFooterWrapper>
      <div>
        <StatementsFooterCancelButton
          isDisabled={!isSomeStatementSelected}
          label={t('statements.statementsFooter.button.cancel')}
          onClick={clearSelectedStatements}
          variant="initial"
        />

        {isSomeStatementSelected &&
          t('statements.statementsFooter.countOfItems', {
            count: selectedStatements.length,
          })}
      </div>

      <StatementsFooterConfirmButton
        isDisabled={!isSomeStatementSelected}
        label={t('statements.statementsFooter.button.confirm')}
        onClick={sendStatements}
      />
    </StatementsFooterWrapper>
  );
}
