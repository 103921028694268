import { SuperInsuranceWrapper } from './components/styles';
import { SuperInsuranceBanner } from './components/SuperInsuranceBanner';
import { SuperInsuranceContent } from './components/SuperInsuranceContent';

export const SuperInsurance = () => (
  <SuperInsuranceWrapper>
    <SuperInsuranceBanner />

    <SuperInsuranceContent />
  </SuperInsuranceWrapper>
);
