import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import dayjs from 'dayjs';

import Time from '../../../components/elements/Time/Time';
import { REWARDS_FILTER_VALUE } from '../consts';

import RewardsHistoryStatus from './RewardsHistoryStatus';
import RewardsHistoryAmount from './RewardsHistoryAmount';

import { DATE_FORMAT, ISO_DATE_FORMAT } from 'config/date';

export default function HistoryTransactions({
  transaction,
  previousDate,
  activeTab,
}) {
  const {
    amount,
    other_title,
    offer,
    status,
    conversion_date,
    origination_date,
  } = transaction;

  const date =
    status === REWARDS_FILTER_VALUE.pending
      ? conversion_date
      : origination_date;

  const { campaign_owner_name, campaign_owner_image_url } = offer || {};
  const currentDate = dayjs(date).format(ISO_DATE_FORMAT);
  const previousDateFormating = dayjs(previousDate).format(ISO_DATE_FORMAT);

  if (activeTab !== status && activeTab !== REWARDS_FILTER_VALUE.all) {
    return <div />;
  }

  return (
    <div>
      {(!dayjs(previousDateFormating).isValid() ||
        previousDateFormating !== currentDate) && (
        <div className="rewards__history-item-date">
          {date && <Time format={DATE_FORMAT}>{date}</Time>}
        </div>
      )}

      <div className="rewards__history-item">
        <div
          className={`rewards__history-icon-placeholder ${
            campaign_owner_image_url ? '-has-image' : ''
          }`}
        >
          <div
            className="rewards__history-icon"
            style={{
              background: `url(${campaign_owner_image_url}) no-repeat`,
              backgroundSize: 'contain',
            }}
          />
        </div>

        <div className="rewards__history-content">
          <div className="rewards__history-name">
            {campaign_owner_name || other_title}
          </div>

          <div className="rewards__history-progress">
            <RewardsHistoryStatus status={status} />
          </div>
        </div>

        <RewardsHistoryAmount amount={amount} createdAt={date} />
      </div>
    </div>
  );
}

HistoryTransactions.defaultProps = {
  transaction: { credit_investment: {} },
  previousDate: '',
};

HistoryTransactions.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    credit_investment: PropTypes.shape({
      campaign_owner_name: PropTypes.string,
      campaign_owner_img_url: PropTypes.string,
      pending: PropTypes.bool,
      amount: PropTypes.number,
    }),
    offer: PropTypes.shape({
      campaign_owner_name: PropTypes.string,
      campaign_owner_img_url: PropTypes.string,
      pending: PropTypes.bool,
      amount: PropTypes.number,
    }),
    amount: PropTypes.number,
    other_title: PropTypes.string,
    origination_date: PropTypes.string,
    conversion_date: PropTypes.string,
    status: PropTypes.string,
  }),
  activeTab: PropTypes.string.isRequired,
  previousDate: PropTypes.string,
};
