import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ChartDetailsModal from '../ChartDetailsModal/ChartDetailsModal';
import EditPortfolioThemeModal from '../EditPortfolioThemeModal/EditPortfolioThemeModal';
import { ProPortfolioLoader } from '../styles';

import ProPortfolioContent from './components/ProPortfolioContent';
import ProPortfolioTitle from './components/ProPortfolioTitle';
import { ProPortfolioWrapper } from './styles';

import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { usePortfolioColor, useProPortfolio } from 'pages/Portfolio/hooks';
import { usePortfolioFundsTags } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';
import { updateProPortfolio } from 'store/portfolio/actions';
import { toggleInfoModal } from 'store/modals/actions';

export default function ProPortfolio() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { basePortfolio, isProPortfolioLoading, proPortfolio } =
    useProPortfolio();

  const { portfolioColor, isProPortfolioColorLoading, savedPortfolioColor } =
    usePortfolioColor();

  const { activeFundTags, isFundsTagsLoading } = usePortfolioFundsTags();

  const [isThemeEditModalOpen, setIsThemeEditModalOpen] = useState(false);
  const [isChartDetailsModalOpen, setIsChartDetailsModalOpen] = useState(false);

  const updatePortfolioTheme = ({ e, values, updatedPortfolioColor }) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      if (!values.name) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title: t('common.oops'),
              description: t('portfolio.errorModal.description'),
            },
          }),
        );
      } else {
        dispatch(
          updateProPortfolio({
            values,
            updatedPortfolioColor,
            basePortfolio,
          }),
        );

        setIsThemeEditModalOpen(false);
      }
    }
  };

  const isLoading =
    isProPortfolioLoading || isProPortfolioColorLoading || isFundsTagsLoading;

  if (isLoading) {
    return (
      <ProPortfolioLoader>
        <DotsLoader />
      </ProPortfolioLoader>
    );
  }

  return (
    <ProPortfolioWrapper>
      <ProPortfolioTitle setIsThemeEditModalOpen={setIsThemeEditModalOpen} />

      <ProPortfolioContent
        setIsChartDetailsModalOpen={setIsChartDetailsModalOpen}
        activeFundTags={activeFundTags}
        isProPortfolioConfigured={!!proPortfolio}
      />

      <EditPortfolioThemeModal
        isThemeEditModalOpen={isThemeEditModalOpen}
        setIsThemeEditModalOpen={setIsThemeEditModalOpen}
        updatePortfolioTheme={updatePortfolioTheme}
        savedPortfolioColor={savedPortfolioColor}
      />

      <ChartDetailsModal
        isChartDetailsModalOpen={isChartDetailsModalOpen}
        setIsChartDetailsModalOpen={setIsChartDetailsModalOpen}
        portfolioColor={portfolioColor}
        activeFundTags={activeFundTags}
      />
    </ProPortfolioWrapper>
  );
}
