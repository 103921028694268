import { useState } from 'react';
import { useMatch } from 'react-router';

import useSuperNavigationMenu from './useSuperNavigationMenu';
import { SuperMenuWrapper } from './components/styles';
import { SuperMenuItem } from './components/SuperMenuItem';
import { usePortfolioPath } from './usePortfolioPath';
import { useDividendsPath } from './useDividendsPath';

import { PATHS } from 'constants/paths';
import InvestLumpSumModal from 'components/layout/Modals/InvestLumpSumModal';

export type ISuperMenuProps = {
  toggleMenuItem: () => void;
};

export const SuperMenu = ({ toggleMenuItem }: ISuperMenuProps) => {
  const [isInvestLumpSumModalOpen, setIsInvestLumpSumModalOpen] =
    useState(false);

  const { isSuperPortfolioActive, isSuperPortfolioPath } = usePortfolioPath();

  const { isSuperDividendsPath } = useDividendsPath();

  const superNavigationMenu = useSuperNavigationMenu();

  const openInvestLumpSumModal = (isOpen: boolean) => {
    setIsInvestLumpSumModalOpen(isOpen);
  };

  const isActive =
    !!useMatch(`${PATHS.super.index}*`) ||
    !!(isSuperPortfolioPath && isSuperPortfolioActive) ||
    isSuperDividendsPath;

  return (
    <SuperMenuWrapper isActive={isActive}>
      {superNavigationMenu.map((navigationItem) => (
        <SuperMenuItem
          key={window.crypto.randomUUID()}
          navigationItem={navigationItem}
          openInvestLumpSumModal={openInvestLumpSumModal}
          isInvestLumpSumModalOpen={isInvestLumpSumModalOpen}
          toggleMenuItem={toggleMenuItem}
        />
      ))}

      {isInvestLumpSumModalOpen && (
        <InvestLumpSumModal
          isOpenInvestLumpSumModal={isInvestLumpSumModalOpen}
          openInvestLumpSumModal={openInvestLumpSumModal}
        />
      )}
    </SuperMenuWrapper>
  );
};
