import { useTranslation } from 'react-i18next';

import { useOpenBankGridView } from '../AccountList/useOpenBankGridView';

import { AddButton } from './styles';

import { useQueryState } from 'store/banks';

export const AddRoundUpAccountButton = () => {
  const { t } = useTranslation();

  const { isLoading, isFetching } = useQueryState();

  const openBankGridView = useOpenBankGridView();

  return (
    <AddButton
      label={t('settingsPage.addRoundUpAccountButton')}
      onClick={() => openBankGridView({})}
      isLoading={isLoading || isFetching}
    />
  );
};
