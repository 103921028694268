import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function OffsettersUnsubscribeModal({
  isShowConfirmation,
  handleCloseModalError,
  offsettersUnsubscribe,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirmation}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-title">
          {t('settingsPage.offsettersUnsubscribeModal.title')}
        </div>

        <div className="alert-text">
          {t(
            'settingsPage.offsettersUnsubscribeModal.unsubscribeConfirmationMessage',
          )}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            title={t('common.cancel')}
            type="button"
            buttonClass="wait"
            handleClick={handleCloseModalError}
          />

          <DeprecatedButton
            title={t('common.yes')}
            type="button"
            buttonClass="skip"
            handleClick={offsettersUnsubscribe}
          />
        </div>
      </div>
    </Modal>
  );
}

OffsettersUnsubscribeModal.propTypes = {
  isShowConfirmation: PropTypes.bool.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  offsettersUnsubscribe: PropTypes.func.isRequired,
};
