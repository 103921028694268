import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import selectKidsList from './components/selectors';
import { NUMBER_OF_KIDS } from './consts';

import { getDependencyUsersSummary } from 'store/dependentUser/actions';

export const useKids = () => {
  const dispatch = useDispatch();

  const kidsList = useSelector((state) =>
    selectKidsList(state, NUMBER_OF_KIDS),
  );

  const [isLoading, setIsLoading] = useState(true);
  const isNewChild = kidsList?.length === 0;

  const getKidsList = useCallback(() => {
    dispatch(getDependencyUsersSummary()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);

    getKidsList();
  }, [setIsLoading, getKidsList]);

  return {
    isLoading,
    isNewChild,
    kidsList,
  };
};
