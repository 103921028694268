import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

import * as rewardsActions from '../../store/rewards/actions';
import { JOIN_OFFSETTERS_SUCCESS } from '../../store/rewards/types';

import OffsettersDetailsView from './OffsettersDetailsView';

class OffsettersDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCongrats: false,
    };

    this.joinOffsetters = this.joinOffsetters.bind(this);

    this.toggleCongrats = this.toggleCongrats.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getOffsetters();
  }

  joinOffsetters() {
    const { actions } = this.props;

    actions.joinOffsetters().then(() => {
      const { type } = this.props;

      if (type === JOIN_OFFSETTERS_SUCCESS) {
        this.toggleCongrats(true);
      }
    });
  }

  toggleCongrats(condition) {
    this.setState({
      showCongrats: condition,
    });
  }

  render() {
    const { showCongrats } = this.state;
    const { offsetters } = this.props;

    return (
      <OffsettersDetailsView
        showCongrats={showCongrats}
        joinOffsetters={this.joinOffsetters}
        toggleCongrats={this.toggleCongrats}
        offsetters={offsetters}
      />
    );
  }
}

OffsettersDetails.defaultProps = {
  user: {},
  type: '',
  actions: {},
  offsetters: {},
};

OffsettersDetails.propTypes = {
  user: PropTypes.shape({}),
  actions: PropTypes.shape({
    getOffsetters: PropTypes.func,
    joinOffsetters: PropTypes.func,
  }),
  type: PropTypes.string,
  offsetters: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  type: state.rewards.type,
  offsetters: state.rewards.offsetters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rewardsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffsettersDetails);
