import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import { useTranslation } from 'react-i18next';

import { AUS, MYS } from '../../../constants/comonConstants';
import { TRANSACTION_STATUSES } from '../consts';

import { Amount } from './styles';

import { AU_APP, COUNTRY, MY_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function HistoryTransactionItemBody({
  transaction,
  showTransactionDetail,
  displayCancelTransactionConfirmationAlert,
  isSuper,
  isRebalance,
  noBorder,
  isItemPending,
  hisoryIcon,
}) {
  const {
    pending,
    grouped_status,
    amount,
    campaign_owner_img_url,
    reversible,
    title,
  } = transaction;

  const { t } = useTranslation();

  const processingStatus = {
    [AUS]: grouped_status,
    [MYS]: t('common.status.processing'),
  };

  const isSellTransaction = amount < 0;

  return (
    <div
      onClick={() => showTransactionDetail(transaction)}
      role="button"
      tabIndex={0}
      onKeyPress={() => showTransactionDetail(transaction)}
      className={
        isRebalance
          ? `history__item history__item-rebalans ${noBorder}`
          : `history__item ${noBorder} ${isItemPending}`
      }
    >
      <div className="history__item__content">
        {AU_APP && campaign_owner_img_url !== '' && (
          <div
            className={hisoryIcon}
            style={{
              background: `url(${campaign_owner_img_url}) no-repeat`,
              backgroundSize: 'contain',
            }}
          />
        )}

        {(!AU_APP || !campaign_owner_img_url) && <div className={hisoryIcon} />}

        <div className="history__item-info">
          <div className="history__item-type">
            {title}

            {isRebalance && (
              <div className="rebalansed-text">
                {MY_APP &&
                  pending &&
                  t(
                    'historyDetailsPage.historyTransactionItem.rebalancedProcess',
                  )}

                {((MY_APP && !pending) || !MY_APP) &&
                  t('historyDetailsPage.historyTransactionItem.rebalanced')}
              </div>
            )}
          </div>

          {!isSuper && grouped_status === TRANSACTION_STATUSES.pending && (
            <div className="history__item-processing">
              <div className="history__item-processing-item -active" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-status">
                {t('common.status.pending')}
              </div>
            </div>
          )}

          {!isSuper &&
            grouped_status === TRANSACTION_STATUSES.transferring &&
            !isRebalance && (
              <div className="history__item-processing">
                <div className="history__item-processing-item  -complete" />

                <div className="history__item-processing-item  -active" />

                <div className="history__item-processing-item" />

                <div className="history__item-processing-status">
                  {processingStatus[COUNTRY]}
                </div>
              </div>
            )}

          {isSuper && !isRebalance && pending && (
            <div className="history__item-processing">
              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-item" />

              <div className="history__item-processing-status">&nbsp;</div>
            </div>
          )}

          {!AU_APP && grouped_status === TRANSACTION_STATUSES.failed && (
            <div className="history__item-processing gagal">
              <div>{t('common.status.failed')}</div>
            </div>
          )}
        </div>

        <Amount isSellTransaction={isSellTransaction}>
          {formatNumber({ value: amount })}
        </Amount>
      </div>

      <div>
        {((!isSuper && reversible) || (isSuper && pending && reversible)) && (
          <DeprecatedButton
            title={t('common.cancel')}
            buttonClass="button__basic-fill -grey -cancel"
            handleClick={() =>
              displayCancelTransactionConfirmationAlert({
                isTransactionModalOpen: true,
                transaction,
              })
            }
          />
        )}
      </div>

      {!isSuper && <div className="history__item-arrow" />}
    </div>
  );
}

HistoryTransactionItemBody.defaultProps = {
  isRebalance: false,
  isSuper: false,
};

HistoryTransactionItemBody.propTypes = {
  showTransactionDetail: PropTypes.func.isRequired,
  displayCancelTransactionConfirmationAlert: PropTypes.func.isRequired,
  isSuper: PropTypes.bool,
  transaction: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    pending: PropTypes.bool,
    grouped_status: PropTypes.string,
    campaign_owner_img_url: PropTypes.string,
    reversible: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  isRebalance: PropTypes.bool,
  noBorder: PropTypes.string.isRequired,
  isItemPending: PropTypes.string.isRequired,
  hisoryIcon: PropTypes.string.isRequired,
};
