import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IBankAccountConnectionStatusProps } from '../types';
import { BANK_LINKING_STATUS } from '../consts';

import { useConnectionStatusTitle } from './useConnectionStatusTitle';

import {
  useInstitutionByIdQuery,
  useGetLinkStatesQuery,
} from 'store/fundingAccount/fundingAccount';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getBanks,
  resetProps,
  setIsBankAccountLinkingStep,
  setIsShowConnectModal,
  setIsShowConnectStep,
} from 'store/fundingAccount/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectBankAccountData } from 'store/fundingAccount/selectors';
import { ACCOUNT_CONNECTION_INTERVAL } from 'components/modals/Fastlink/consts';
import { toggleInfoModal } from 'store/modals/actions';

export const useBankAccountLinkingStatus = ({
  onUnmount,
  isSpending,
  isRegistration,
}: Pick<
  IBankAccountConnectionStatusProps,
  'onUnmount' | 'isSpending' | 'isRegistration'
>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: institutionsLinkStates = [] } = useGetLinkStatesQuery();

  const bankAccountData = useAppSelector(selectBankAccountData);

  const params = {
    ...bankAccountData,
    ...(!isSpending && {
      additionalParams: {
        funding_source: 1,
      },
    }),
  };

  const { data } = useInstitutionByIdQuery(params, {
    pollingInterval: ACCOUNT_CONNECTION_INTERVAL,
  });

  const title = useConnectionStatusTitle({
    isSpending,
    provider: data?.monitored_institution?.provider || '',
  });

  const currentStatusData = data?.monitored_institution?.link_state;

  const indexOfCurrentState = useMemo(
    () =>
      institutionsLinkStates.findIndex(
        ({ state }) => state === currentStatusData?.status,
      ),
    [currentStatusData?.status, institutionsLinkStates],
  );

  const isLinked = currentStatusData?.status === BANK_LINKING_STATUS.completed;

  const isFailed = currentStatusData?.status === BANK_LINKING_STATUS.failed;

  useEffect(() => {
    if (isLinked) {
      dispatch(setIsBankAccountLinkingStep(false));

      if (
        !isSpending &&
        data?.monitored_institution?.subaccounts?.length === 0
      ) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title: t(
                'registrationPage.bankAccountConnectionStatus.errorModal.title',
              ),
              description: t(
                'registrationPage.bankAccountConnectionStatus.errorModal.description',
                {
                  bankName: data?.monitored_institution?.name,
                },
              ),
            },
          }),
        );
      } else if (isRegistration) {
        dispatch(
          setIsShowConnectModal({ isShowConnectModal: true, isSpending }),
        );
      } else {
        dispatch(setIsShowConnectStep(true));

        dispatch(resetProps());
      }
    }

    if (isFailed) {
      const handleStopLinkingProcess = () => {
        dispatch(toggleInfoModal({ isInfoModalVisible: false }));

        dispatch(setIsBankAccountLinkingStep(false));
      };

      dispatch(
        toggleInfoModal({
          isInfoModalVisible: true,
          config: {
            title: t('common.oops'),
            description: currentStatusData?.message,
            onAccept: handleStopLinkingProcess,
            onReject: handleStopLinkingProcess,
          },
        }),
      );
    }
  }, [
    dispatch,
    isLinked,
    isSpending,
    isRegistration,
    data?.monitored_institution,
    isFailed,
    currentStatusData?.message,
    t,
  ]);

  useEffect(
    () => () => {
      if (!isRegistration) {
        dispatch(getBanks({ isSpending }));
      }

      onUnmount();
    },
    [dispatch, isRegistration, isSpending, onUnmount],
  );

  return {
    indexOfCurrentState,
    institutionsLinkStates,
    isLinked,
    title,
  };
};
