import React, { useState } from 'react';

import { BankGridItem } from '../BankGridItem';
import { AddRoundUpAccountButton } from '../AddRoundUpAccountButton';
import { BankGridHeader } from '../BankGridHeader';
import { BankListHeader } from '../BankListHeader';
import { BankDetails } from '../BankDetails';
import { VisaCardModal } from '../VisaCardModal';
import { AccountList } from '../AccountList';
import { IOpenFixAccountModal } from '../AccountList/types';
import { BankGridHeaderRegistration } from '../BankGridHeader/BankGridHeaderRegistration';

import { AddRoundUpAccountButtonWrapper, BankGrid, Card } from './styles';

import Loader from 'components/elements/Loader/Loader';
import { selectIsLinkCardLoading } from 'store/fundingAccount/selectors';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getBankInstitutions } from 'store/fundingAccount/actions';
import {
  banksApi,
  useBankQueryState,
  IBank,
  ROUNDUP_SOURCES,
  IRoundupSources,
} from 'store/banks';
import { spendingAccountsApi } from 'store/spendingAccounts';
import { API_TAGS } from 'store/consts';

interface IGroupedBankList {
  openFixAccountModal?: IOpenFixAccountModal;
  onSelectDebitCard: (id: number) => void;
  onSelectOnlineBanking: (institutionId: string | null) => void;
  openSettingsModal?: () => void;
  isRegistration?: boolean;
}

export const GroupedBankList = ({
  openFixAccountModal = () => {},
  onSelectDebitCard,
  onSelectOnlineBanking,
  openSettingsModal = () => {},
  isRegistration,
}: IGroupedBankList) => {
  const { data, isSuccess, isLoading, isFetching, isUninitialized } =
    useBankQueryState();

  const dispatch = useAppDispatch();

  const isLinkCardLoading = useAppSelector(selectIsLinkCardLoading);

  const [isVisaModalVisible, setIsVisaModalVisible] = useState(false);

  const [selectedBank, setSelectedBank] = useState<IBank | null>(null);

  const isShowBanks = useAppSelector(
    (state) => state.fundingAccount.isShowBanks,
  );

  const handleSelectBank = (bank: IBank | null = null) => {
    setSelectedBank(bank);
  };

  const handleShowModal = (bank: IBank, source: IRoundupSources) => {
    switch (source) {
      case ROUNDUP_SOURCES.onlineBanking:
        dispatch(getBankInstitutions()).then(() => {
          onSelectOnlineBanking(bank.institution_id);
        });

        break;
      case ROUNDUP_SOURCES.debitCards:
        onSelectDebitCard(bank.id);

        break;
      case ROUNDUP_SOURCES.visa:
        setIsVisaModalVisible(true);

        break;
      default:
        break;
    }
  };

  const resetGroupedBank = () => {
    dispatch(banksApi.util.resetApiState());

    dispatch(spendingAccountsApi.util.invalidateTags([API_TAGS.banks]));

    handleSelectBank();
  };

  if (selectedBank) {
    return (
      <>
        {isRegistration && (
          <BankGridHeaderRegistration selectedBank={selectedBank} />
        )}

        <VisaCardModal
          bank={selectedBank}
          isOpen={isVisaModalVisible}
          onRequestClose={() => {
            setIsVisaModalVisible(false);

            resetGroupedBank();
          }}
        />

        <BankDetails
          bank={selectedBank}
          onRequestClose={resetGroupedBank}
          onShowModal={handleShowModal}
        />

        {isLinkCardLoading && <Loader />}
      </>
    );
  }

  return (
    <>
      {isShowBanks && isSuccess && (
        <>
          <BankGridHeader
            openSettingsModal={openSettingsModal}
            isRegistration={isRegistration}
            selectedBank={selectedBank}
          />

          <Card>
            <BankGrid>
              {data?.banks.map((bank) => (
                <BankGridItem
                  bank={bank}
                  key={bank.id}
                  onClick={handleSelectBank}
                />
              ))}
            </BankGrid>
          </Card>
        </>
      )}

      {(isLoading || isFetching || isUninitialized || !isShowBanks) && (
        <>
          <BankListHeader openSettingsModal={openSettingsModal} />

          <AccountList openFixAccountModal={openFixAccountModal} />

          <AddRoundUpAccountButtonWrapper>
            <AddRoundUpAccountButton />
          </AddRoundUpAccountButtonWrapper>
        </>
      )}
    </>
  );
};
