import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

export default function ModalComponent(props) {
  const {
    isOpen,
    onRequestClose,
    modalClass,
    modalTitle,
    modalContent,
    submitButtonAction,
    submitButtonTitle,
    cancelButtonTitle,
    cancelButtonAction,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="modal-component"
      shouldCloseOnOverlayClick
      className={modalClass}
    >
      {modalTitle && (
        <div className="time-investment__modal-title">{modalTitle}</div>
      )}

      {modalContent && (
        <div className="time-investment__modal-content">{modalContent}</div>
      )}

      <div className="time-investment__modal-footer -single-btn">
        <DeprecatedButton
          title={cancelButtonTitle}
          buttonClass="button__basic-transparent -black"
          handleClick={cancelButtonAction}
        />

        <Button label={submitButtonTitle} onClick={submitButtonAction} />
      </div>
    </Modal>
  );
}

ModalComponent.propTypes = {
  modalContent: PropTypes.shape({}),
  submitButtonTitle: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  modalTitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  cancelButtonTitle: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  modalClass: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  submitButtonAction: PropTypes.func,
  cancelButtonAction: PropTypes.func,
};

ModalComponent.defaultProps = {
  isOpen: false,
  modalClass: '',
  submitButtonTitle: '',
  cancelButtonTitle: '',
  modalTitle: {},
  modalContent: {},
  onRequestClose() {},
  submitButtonAction() {},
  cancelButtonAction() {},
};
