import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone-uploader';

import {
  DOCUMENT_TYPES,
  FILE_STATUS,
} from '../../../../constants/comonConstants';
import { AU_APP } from '../../../../constants/localeConfigs';
import UploadIdContent from '../../../../pages/Registration/components/UploadId/components/UploadIdContent/UploadIdContent';
import { ALERT_TYPES } from '../../../../constants/alerts';

import UploadDocumentAlertDescription from './components/UploadDocumentAlertDescription';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { ALLOWED_UPLOAD_IMAGES_EXTENSIONS } from 'constants/fileExtensions';
import { Button } from 'components/elements/Button';

function UploaderDocumentsModal({
  currentAlert,
  isUploadInfoAlertOpen,
  toggleAlertInfoModal,
  isLoader,
  submitDocument,
  setDocument,
}) {
  const { t } = useTranslation();
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);

  const handleChangeUploadedDocumentStatus = ({ file }, status) => {
    const dropzone = document.querySelector('.registration-page__dropzone');
    const dropzoneCLassList = dropzone ? dropzone.classList : '';

    if (file.type === 'application/pdf' && status !== FILE_STATUS.removed) {
      if (dropzoneCLassList) {
        dropzoneCLassList.add('-pdf');
      }
    } else {
      dropzoneCLassList.remove('-pdf');
    }

    if (status !== FILE_STATUS.error.maxFilesExceeded) {
      setDocument(
        status === FILE_STATUS.removed ||
          status === FILE_STATUS.error.incorrectFileType
          ? {}
          : file,
      );
    }

    setIsUploadButtonDisabled(status !== FILE_STATUS.done);
  };

  const getModalTitle = (alertType) => {
    switch (alertType) {
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissing:
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingId:
      case ALERT_TYPES.idUploadErrors.fsChangeUploadMissingSelfieWithId:
        return t('alertsPage.uploadId.documents.title');
      case ALERT_TYPES.idUploadErrors.errorUploadInformation:
      case ALERT_TYPES.idUploadErrors.ktpUploadMissing:
      case ALERT_TYPES.idUploadErrors.additionalUploadMissing:
        return t('alertsPage.uploadId.pictureVerification');
      default:
        return '';
    }
  };

  return (
    <Modal
      isOpen={isUploadInfoAlertOpen}
      onRequestClose={() => toggleAlertInfoModal(false, currentAlert)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic modal-alert -white"
    >
      <div className="modal-basic__header">
        <DeprecatedButton
          buttonClass="button__close -black"
          handleClick={() => toggleAlertInfoModal(false, currentAlert)}
        />

        {getModalTitle(currentAlert.type)}
      </div>

      <div className="modal-basic__modal-description">
        <UploadDocumentAlertDescription alertType={currentAlert.type} />
      </div>

      <Dropzone
        onChangeStatus={handleChangeUploadedDocumentStatus}
        accept={
          AU_APP
            ? `${ALLOWED_UPLOAD_IMAGES_EXTENSIONS}, application/pdf`
            : ALLOWED_UPLOAD_IMAGES_EXTENSIONS
        }
        maxFiles={1}
        multiple={false}
        classNames={{ dropzone: 'registration-page__dropzone -centered' }}
        canCancel
        inputContent={
          <UploadIdContent
            documentType={
              currentAlert.type ===
              ALERT_TYPES.idUploadErrors.errorUploadInformation
                ? DOCUMENT_TYPES.idDocument
                : ''
            }
            uploadErrorType={
              currentAlert.type !==
              ALERT_TYPES.idUploadErrors.errorUploadInformation
                ? currentAlert.type
                : ''
            }
          />
        }
        canRemove
      />

      <Button
        isLoading={isLoader}
        isDisabled={isUploadButtonDisabled}
        label={t('common.submit')}
        onClick={() => submitDocument()}
      />
    </Modal>
  );
}

UploaderDocumentsModal.propTypes = {
  isUploadInfoAlertOpen: PropTypes.bool.isRequired,
  toggleAlertInfoModal: PropTypes.func.isRequired,
  setDocument: PropTypes.func.isRequired,
  submitDocument: PropTypes.func.isRequired,
  isLoader: PropTypes.bool.isRequired,
  currentAlert: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default UploaderDocumentsModal;
