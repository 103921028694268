import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';

interface IUseUnlinkConfirmationModalParams {
  bankName: string | null;
  onAccept: () => void;
}

export const useUnlinkConfirmationModal = ({
  bankName = null,
  onAccept = () => {},
}: IUseUnlinkConfirmationModalParams) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('settingsPage.unlinkConfirmationModal.title'),
          description: t('settingsPage.unlinkConfirmationModal.description', {
            bankName: bankName || t('settingsPage.source.visa.title'),
          }),
          rejectButtonText: t('common.cancel'),
          onAccept,
          isButtonHorizontal: true,
        },
      }),
    );
  };
};
