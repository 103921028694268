import styled from '@emotion/styled';

export const PERFORMANCE_CHART_HEIGHT = '300px';

export const InfoPanelWrapper = styled.div`
  padding-top: 16px;
`;

export const CurrentPrice = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['2lg']};
`;

export const MarketChanges = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const MarketPercentage = styled.span`
  padding-left: 8px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
`;

export const SwitcherWrapper = styled.div`
  display: inline-flex;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.blackTransparent};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 4px;
  margin-bottom: 24px;
`;

export const NumberOfDaysSwitcherItemWrapper = styled.div<{
  isActive: boolean;
}>`
  padding: 3px 23px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 3px 12px;
  }
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.transparent};
  ${({ isActive }) => isActive && 'border-radius: 15px;'}
`;

export const ChartButton = styled.button<{
  isActive: boolean;
}>`
  background: ${({ theme }) => theme.colors.transparent};
  height: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black : theme.colors.white};
`;

export const ChartLoader = styled.div`
  min-height: ${PERFORMANCE_CHART_HEIGHT};
  display: flex;
  align-items: center;
`;
