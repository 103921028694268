import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { HOME_WIDGETS } from '../../consts';
import { useActiveHelpCard } from '../useActiveHelpCard';

import { RaizRewardsWidget } from 'components/layout/Widgets/RaizRewardsWidget/RaizRewardsWidget';
import { Perfomance } from 'components/layout/Perfomance/Perfomance';
import KidsWidget from 'components/layout/Widgets/KidsWidget/KidsWidget';
import BlogWidget from 'components/layout/Widgets/BlogWidget/BlogWidget';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import { HOMEPAGE_MONEY_THOR } from 'constants/comonConstants';
import MyFinanceWidget from 'components/layout/Widgets/MyFinanceWidget/MyFinanceWidget';
import SuperWidget from 'components/layout/Widgets/SuperWidget/SuperWidget';
import OffsettersWidget from 'components/layout/Widgets/OffsettersWidget/OffsettersWidget';
import OneTimeInvestment from 'components/layout/OneTimeInvestment/OneTimeInvestment';

export default function HomeWidgetItem({ widgetName, isNewRewards }) {
  const { user, offsetters, tips } = useSelector((state) => ({
    user: state.user.user || {},
    offsetters: state.rewards.offsetters || {},
    tips: state.spendingAnalytics.tips || [],
  }));

  const { toggleHelpCard } = useActiveHelpCard(HOMEPAGE_MONEY_THOR);

  const { super_annuation_user } = user;

  switch (widgetName) {
    case HOME_WIDGETS.perfomance:
      return <Perfomance />;
    case HOME_WIDGETS.raizKids:
      return <KidsWidget />;
    case HOME_WIDGETS.blogs:
      return <BlogWidget />;
    case HOME_WIDGETS.oneTimeInvestments:
      return <OneTimeInvestment />;
    case HOME_WIDGETS.mtCards:
      return (
        <HelpCardsModalAU
          currentPage={HOMEPAGE_MONEY_THOR}
          isWidget
          isOpenHelpCards={false}
          tips={tips}
          isNewRewards={isNewRewards}
          toggleHelpCard={toggleHelpCard}
        />
      );
    case HOME_WIDGETS.offsetters:
      return <OffsettersWidget offsetters={offsetters} />;
    case HOME_WIDGETS.raizSuper:
      return <SuperWidget />;
    case HOME_WIDGETS.myFinance:
      return <MyFinanceWidget isSingle={super_annuation_user} />;
    case HOME_WIDGETS.raizRewards:
      return <RaizRewardsWidget />;
    default:
      break;
  }
}

HomeWidgetItem.propTypes = {
  widgetName: PropTypes.string.isRequired,
  isNewRewards: PropTypes.bool.isRequired,
};
