import React from 'react';
import PropTypes from 'prop-types';

import { FiltersCategoriesLabelWrapper, Arrow, Label } from './styles';

export default function FiltersCategoryLabel({
  title,
  isExpanded,
  setIsExpanded,
}) {
  return (
    <FiltersCategoriesLabelWrapper
      onClick={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
    >
      <Label>{title}</Label>

      <Arrow isExpanded={isExpanded} />
    </FiltersCategoriesLabelWrapper>
  );
}

FiltersCategoryLabel.propTypes = {
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
};
