const getCarouselSettings = () => {
  const carouselSettings = {
    responsive: {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1200, min: 768 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
      },
    },
    slidesToSlide: 3,
  };

  if (window.innerWidth < 1200) {
    const slidesToSlide = window.innerWidth > 768 ? 2 : 1;

    return {
      ...carouselSettings,
      slidesToSlide,
    };
  }

  return carouselSettings;
};

export default getCarouselSettings;
