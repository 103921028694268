import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { HOME_ACTIVE_TAB, HOME_TABS } from '../../../constants/comonConstants';

import HomePastBanner from './HomePastBanner';
import HomeTodayBanner from './HomeTodayBanner';

export default function HomeBannerContent() {
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get(HOME_ACTIVE_TAB);

  switch (activeTab) {
    case HOME_TABS.today:
      return <HomeTodayBanner />;

    case HOME_TABS.past:
      return <HomePastBanner />;

    default:
      return null;
  }
}
