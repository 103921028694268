import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CloseIconButton, ContentWrapper, FooterWrapper } from '../styles';

import {
  CloseButton,
  SuccessViewFooter,
  LogoutButton,
  SuccessViewButtonsWrapper,
  SuccessTitle,
} from './styles';

import { openLogoutModal } from 'store/user/actions';

interface ICloseAccountSuccessModalProps {
  closeRequestModal: () => void;
}

export const SuccessView = ({
  closeRequestModal,
}: ICloseAccountSuccessModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(openLogoutModal());

    closeRequestModal();
  };

  return (
    <div>
      <CloseIconButton onClick={closeRequestModal} />

      <ContentWrapper>
        <SuccessTitle>
          {t('closeAccountModal.successModal.request')}
        </SuccessTitle>
      </ContentWrapper>

      <FooterWrapper>
        <SuccessViewFooter>
          {t('closeAccountModal.successModal.footer')}

          <SuccessViewButtonsWrapper>
            <LogoutButton
              onClick={logout}
              label={t('common.logout')}
              variant="text"
            />

            <CloseButton onClick={closeRequestModal} label={t('common.ok')} />
          </SuccessViewButtonsWrapper>
        </SuccessViewFooter>
      </FooterWrapper>
    </div>
  );
};
