import styled from '@emotion/styled';

import notFoundIcon from 'assets/images/icons/round-report_problem.svg';

export const Container = styled.div`
  margin-top: 16px;
`;

export const Icon = styled.div`
  background: url(${`${notFoundIcon}`}) no-repeat;
  margin: 0 auto;
  width: 36px;
  padding: 36px;
  margin-bottom: 20px;
`;
