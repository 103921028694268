import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Card,
  CardExpiration,
  CardName,
  CardNumber,
  Header,
  LeftPart,
} from './styles';
import { REWARD_CARD_ISSUERS } from './consts';

import { Icon } from 'components/elements/Icon';
import { IconButton } from 'components/elements/IconButton';
import { ICard } from 'store/rewards/api/types';

interface IExpiredCardProps {
  card: ICard;
  onRemoveIconClick: () => void;
}

export const ExpiredCard = ({ card, onRemoveIconClick }: IExpiredCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Header>
        <LeftPart>
          <Icon
            iconName={
              card.issuer === REWARD_CARD_ISSUERS.visa
                ? 'visaCard'
                : 'masterCard'
            }
            size={77}
          />

          <Badge>
            <Icon iconName="cardWarning" size={20} />

            {t('newRewardsPage.cards.expiredCard')}
          </Badge>
        </LeftPart>

        <IconButton iconName="trash" onClick={onRemoveIconClick} size={40} />
      </Header>

      <CardNumber>{card.cardMasked.replaceAll('-', ' ')}</CardNumber>

      <CardExpiration>{dayjs(card.expiry).format('MM/YY')}</CardExpiration>

      <CardName>{card.provider}</CardName>
    </Card>
  );
};
