import { useTranslation } from 'react-i18next';

import { IBankAccountConnectionStatusProps } from '../types';

import { setIsBankAccountLinkingStep } from 'store/fundingAccount/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const useLinkAccountLaterModalConfig = ({
  linkAccountLater,
}: Pick<IBankAccountConnectionStatusProps, 'linkAccountLater'>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleAccept = () => {
    linkAccountLater?.();

    dispatch(setIsBankAccountLinkingStep(false));
  };

  return {
    isInfoModalVisible: true,
    config: {
      title: t(
        'registrationPage.bankAccountConnectionStatus.linkAccountLaterModal.title',
      ),
      description: t(
        'registrationPage.bankAccountConnectionStatus.linkAccountLaterModal.description',
      ),
      acceptButtonText: t(
        'registrationPage.bankAccountConnectionStatus.linkAccountLaterModal.buttons.accept',
      ),
      rejectButtonText: t(
        'registrationPage.bankAccountConnectionStatus.linkAccountLaterModal.buttons.reject',
      ),
      onAccept: handleAccept,
    },
  };
};
