import React from 'react';
import '../../../../styles/pages/history.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DISLIKE, LIKE } from '../../../../constants/comonConstants';

import { Button } from 'components/elements/Button';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function MoneyThorView({
  content,
  toggleRating,
  toggleHelpCard,
  showLoader,
  count,
}) {
  const { t } = useTranslation();

  return (
    <div className="info-panel history-widget__panel widget-money-thor">
      <div className="info-panel__title">
        {content.data ? content.data.title || content.title : ''}

        {count && count > 1 && (
          <DeprecatedButton
            title={t('common.seeMore')}
            buttonClass="widget-money-thor__seemore"
            handleClick={() => {
              toggleHelpCard(true);
            }}
          />
        )}
      </div>

      <div className="widget-money-thor__info">
        <div
          className="widget-money-thor__img"
          style={{ background: `url(${content.data.image_url})` }}
        />

        <div className="widget-money-thor__description">
          {content.message && (
            <span dangerouslySetInnerHTML={{ __html: content.message }} />
          )}
        </div>
      </div>

      <div className="widget-money-thor__actions">
        <section>
          <i
            className={`help-cards__modal-rating -like ${
              content.reaction === LIKE ? '-active' : ''
            }`}
            onClick={() => toggleRating(LIKE, content.tip_key, 0, true)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          />

          <i
            className={`help-cards__modal-rating -dislike ${
              content.reaction === DISLIKE ? '-active' : ''
            }`}
            onClick={() => toggleRating(DISLIKE, content.tip_key, 0, true)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          />
        </section>

        <Button
          label={content.greenBtn ? content.greenBtn.message : ''}
          isLoading={showLoader}
          onClick={() => {
            content.clickEvent();
          }}
        />
      </div>
    </div>
  );
}

MoneyThorView.defaultProps = {
  content: {},
  showLoader: false,
  count: 0,
  toggleHelpCard: () => {},
};

MoneyThorView.propTypes = {
  content: PropTypes.shape({
    clickEvent: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string,
    reaction: PropTypes.string,
    tip_key: PropTypes.string,
    greenBtn: PropTypes.shape({
      message: PropTypes.string,
    }),
    data: PropTypes.shape({
      title: PropTypes.string,
      image_url: PropTypes.string,
    }),
  }),
  count: PropTypes.number,
  toggleRating: PropTypes.func.isRequired,
  toggleHelpCard: PropTypes.func,
  showLoader: PropTypes.bool,
};
