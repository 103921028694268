export const FIREBASE_EVENTS = {
  homePageClicked: 'Home',
  QRISDepositClicked: 'QRISDeposit',
  blogLinkClicked: 'RaizBlogPanel',
  roundUpsAutoOffClicked: 'RoundUpsAutoOff',
  roundUpsAutoOnClicked: 'RoundUpsAutoOn',
  sendMyEmployerChoiceButtonClicked: 'SuperEmployerChoiceForm',
  sendSuperEmployerChoiceFormConfirmed: 'SuperEmployerChoiceFormConfirmed',
  sendSuperEmployerChoiceFormCancel: 'SuperEmployerChoiceFormCancel',
};
