import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../../styles/layout/logout.scss';

import { Button } from 'components/elements/Button';

export default function ConfirmationModal({
  isConfirmationModalOpen,
  closeConfirmationModal,
  confirmAction,
  confirmText,
  confirmInfo,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isConfirmationModalOpen}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick"
    >
      <h3 className="time-investment__modal-title">{confirmText}</h3>

      <span className="time-investment__modal-content">{confirmInfo}</span>

      <div className="time-investment__modal-footer">
        <Button
          label={t('common.cancel')}
          type="button"
          onClick={closeConfirmationModal}
          variant="textBlack"
        />

        <Button label={t('common.yes')} type="button" onClick={confirmAction} />
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  confirmInfo: PropTypes.string.isRequired,
  closeConfirmationModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
};
