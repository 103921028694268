import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MISSING_DOCUMENT_TYPE } from '../../DocumentMissingAlertModal/consts';

import { ALERT_TYPES } from 'constants/alerts';

function PDFDocumentUploaderContent({ documentType, type }) {
  const { t } = useTranslation();

  const pdfHelpText =
    type === MISSING_DOCUMENT_TYPE.FIRST
      ? t('alertsPage.uploadId.pdfContainer.previous')
      : t('alertsPage.uploadId.pdfContainer.new');

  return (
    <div className="registration-page__dropzone-content" key={documentType}>
      <i className="registration-page__dropzone-icon -pdf-upload" />

      <div className="registration-page__dropzone-subtitle">
        <i className="registration-page__dropzone-icon -pdf" />

        {documentType ===
        ALERT_TYPES.idUploadErrors.fsChangeUploadMissingProofOfAddress
          ? t('alertsPage.uploadId.pdfContainer.proofOfAddressHelpText')
          : pdfHelpText}
      </div>
    </div>
  );
}

PDFDocumentUploaderContent.propTypes = {
  documentType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default PDFDocumentUploaderContent;
