import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Button } from 'components/elements/Button';

export interface IFinancialFormContentProps {
  isRegistrationFinancialPage: boolean;
}

export const FinancialFormContent = styled.div<IFinancialFormContentProps>`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ isRegistrationFinancialPage, theme }) =>
    isRegistrationFinancialPage ? 'none' : theme.shadows.primary};
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  text-align: left;
`;

export const FinancialFormButton = styled(Button)`
  width: auto;
  padding: 0 36px;
`;

export const ProfileFormContent = styled(FinancialFormContent)`
  padding: 0 20px;
`;

export const ProfileFormContentRow = styled.div`
  padding: 14px 16px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury}!important;
  flex-direction: column;
`;

export const ProfileFormContentTFN = styled(ProfileFormContentRow)`
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  span:first-child {
    color: ${({ theme }) => theme.colors.mirage};
    font-size: ${({ theme }) => theme.fontSizes['4sm']};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  }
  span:last-of-type {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const TaxFileNumberRow = styled(ProfileFormContentRow)`
  flex-direction: column;
  margin-bottom: 8px;
  padding: 14px 16px 22px;
`;

export const TaxFileNumberTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TaxFileNumberDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  padding-top: 4px;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  max-width: 470px;
`;

export const TaxFileNumberCheckboxWrapper = styled.div`
  padding: 14px 10px 0;
  input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.silver};
    max-width: 300px;
  }
`;

export const TaxFileNumberCheckboxLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: 0 16px 10px;
`;

export const CRSLink = styled(Link)`
  text-align: left;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes['5sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-family: ${({ theme }) => theme.fontFamily.textRegular};
  color: ${({ theme }) => theme.colors.mirage};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: underline;
  padding: 14px 0 12px;
  :hover {
    color: ${({ theme }) => theme.colors.mirage};
    text-decoration: underline;
  }
`;

export const UsCitizenWrapper = styled(ProfileFormContentRow)`
  flex-direction: row;
`;

export const ConfirmButton = styled(Button)`
  width: 223px;
`;

export const ProfileContentButton = styled(Button)`
  margin-top: 20px;
  padding-left: 55px;
  padding-right: 64px;
  width: auto;
`;
