import { CURRENCY_SYMBOL } from '../../../constants/localeConfigs';

import { ROUND_UP_MARKS, CREDIT } from 'constants/comonConstants';
import gradients from 'theme/gradients';
import colors from 'theme/colors';

export const calculateInvestmentValue = ({ isChecked, investSum, change }) => {
  if (Number.isNaN(investSum) || Number.isNaN(change)) {
    return NaN;
  }

  const value = isChecked ? investSum + change : investSum - change;

  return Number(value.toFixed(2));
};

export const formatRoundUpValue = (value) => {
  const index = ROUND_UP_MARKS.findIndex((mark) => mark.value === value);

  return index !== -1
    ? ROUND_UP_MARKS[index].label
    : `${CURRENCY_SYMBOL}${value}`;
};

export const getRoundUpsTransactionBackground = (info) => {
  const { autoroundup_eligible, raiz_rdv, type } = info;

  if (autoroundup_eligible) {
    return colors.yellowishGreen;
  }

  if (raiz_rdv) {
    return gradients.purple;
  }

  if (type === CREDIT) {
    return gradients.lushMeadow;
  }

  return '';
};

export const getIsRoundUpsTemplateDark = (background) =>
  !background || background === colors.yellowishGreen;

export const generateKeyForTransactionsGroup = (transactionGroup) => {
  if (!transactionGroup?.transactions?.length) return null;

  return transactionGroup.transactions.reduce(
    (prev, current) => `${prev}_${current.id}`,
    transactionGroup.date,
  );
};
