import {
  Check,
  CheckboxLabel,
  CheckboxWrapper,
  Input,
  InputWrapper,
} from './styles';

interface IStatementsCheckboxProps {
  onClick: () => void;
  isChecked: boolean;
  label?: React.ReactNode;
  id?: string;
}

export default function Checkbox({
  onClick,
  isChecked,
  label,
  id,
}: IStatementsCheckboxProps) {
  return (
    <CheckboxWrapper>
      <InputWrapper>
        <Check isChecked={isChecked}>
          <Input id={id} type="checkbox" onClick={onClick} />
        </Check>

        {label && <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>}
      </InputWrapper>
    </CheckboxWrapper>
  );
}
