import { useSelector } from 'react-redux';

import { getPortfolioPath } from 'pages/ChangePortfolio/utils';
import { selectIsProPortfolioEnabled } from 'store/firebaseConfig/selectors';

export const usePortfolioPath = () => {
  const isProPortfolioEnabled = useSelector(selectIsProPortfolioEnabled);

  const portfolioPath = getPortfolioPath(isProPortfolioEnabled);

  return portfolioPath;
};
