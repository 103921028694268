/* eslint-disable no-useless-concat */
import { defineMessages } from 'react-intl';

import { MODAL as idPrefix } from '../../../constants/translates';
import { PLATFORM_LINK_MY } from '../../../constants/comonConstants';
// import { CURRENCY_SYMBOL } from '../../../constants/localeConfigs';

const messages = defineMessages({
  [`${idPrefix}.confirm`]: 'Sahkan',
  [`${idPrefix}.watchNow`]: 'Tonton Sekarang',
  [`${idPrefix}.viewYourSpending`]: 'Lihat perbelanjaan anda',
  [`${idPrefix}.myFinance`]: 'My Finance',
  [`${idPrefix}.whereYouSpend`]:
    'Analisa bagaimana anda berbelanja setiap bulan. Anda mungkin mendapat peluang ' +
    'untuk melabur!',
  [`${idPrefix}.avg`]: 'PRT:<br>RM4476',
  [`${idPrefix}.whereYouSpendTitle`]: 'Di Mana Anda Berbelanja?',
  [`${idPrefix}.editCategories`]: 'Edit kategori',
  [`${idPrefix}.spendingCategoriesTitle`]: 'Kategori Perbelanjaan',
  [`${idPrefix}.spendingCategories`]:
    'Lihat kategori perbelanjaan utama anda dan bandingkan dengan jumlah purata ' +
    'perbelanjaan orang lain.',
  [`${idPrefix}.transferringMoney`]: 'Pengeluaran sedang diproses',
  [`${idPrefix}.foodDining`]: 'Makanan',
  [`${idPrefix}.withdrawTitle`]: 'Keluar',
  [`${idPrefix}.oneTimeInvest`]: 'Pelaburan Sekali Gus',
  [`${idPrefix}.wantToSeeActivity`]: 'Ingin melihat Aktiviti Akaun?',
  [`${idPrefix}.explore`]: 'Lebih Lanjut',
  [`${idPrefix}.healthFitness`]: 'Kesihatan & Kecergasan',
  [`${idPrefix}.cash`]: 'Tunai',
  [`${idPrefix}.howRaizWorks`]: 'Bagaimana Raiz berfungsi',
  [`${idPrefix}.exploreEtfs`]:
    'Pilih kelas aset untuk maklumat lebih lanjut tentang dana yang berkaitan ' +
    'berserta pegangannya.',
  [`${idPrefix}.portfolioDeversification`]:
    'Sekiranya anda berbelanja dengan rakan jenama kami, mereka akan ' +
    'melaburkan wang untuk masa depan anda melalui Raiz.',
  [`${idPrefix}.potentialInvestmentProjection`]:
    'Gerak ke kiri dan ke kanan pada graf untuk melihat anggaran ' +
    'nilai pada masa hadapan beserta keuntungannya. Gerak ke atas dan ke bawah untuk menukar simpanan bulanan.',
  [`${idPrefix}.change`]: 'Tukar',
  [`${idPrefix}.continue`]: 'Teruskan',
  [`${idPrefix}.welcome`]: 'Selamat Datang',
  [`${idPrefix}.showMe`]: 'Paparkan',
  [`${idPrefix}.view`]: 'Lihat',
  [`${idPrefix}.next`]: 'Seterusnya',
  [`${idPrefix}.yourInviteCode`]:
    'Kod jemputan anda: <span class="-code">{code}</span>',
  [`${idPrefix}.startInvesting`]:
    'Mula melabur dengan Raiz hari ini! Gunakan kod jemputan saya untuk ' +
    'mendaftar: {rewardUrl}',
  [`${idPrefix}.investText`]:
    'Saya melabur secara mikro dengan Raiz. Kita berdua akan mendapat ganjaran {rewardAmount}' +
    ' setiap seorang apabila anda memuat turun dan mendaftar menggunakan pautan rujukan saya: {rewardUrl}',
  [`${idPrefix}.shareRaizStory`]:
    'Apabila rakan melabur dengan Raiz, anda berdua akan ' +
    'menerima <span>{inviteAmount}</span>. Ketahui bagaimana ia berfungsi ' +
    `<a target="_blank" href="${PLATFORM_LINK_MY}">di sini.</a>`,
  [`${idPrefix}.shareInviteGet`]:
    'Kongsi Cerita.<br>' + 'Jemput Rakan Anda.<br>' + 'Dapatkan Wang Ganjaran.',
  [`${idPrefix}.unlink`]: 'Nyahpaut',
  [`${idPrefix}.logout`]: 'Log Keluar',
  [`${idPrefix}.availableBalance`]: 'Baki Terkini: ',
  [`${idPrefix}.available`]: 'Baki',
  [`${idPrefix}.minimum`]: 'Minimum {value}',
  [`${idPrefix}.schedulingTitle`]: 'Jadual',
  [`${idPrefix}.investNow`]: 'Tutup',
  [`${idPrefix}.other`]: 'Yang lain',
  [`${idPrefix}.tipsOnGrowing`]:
    'Daftar sekarang dan dapatkan bonus untuk pelaburan pertama anda',
  [`${idPrefix}.scheduling`]:
    'Sekiranya pelaburan berkala anda dijadualkan pada hujung minggu atau hari ' +
    'cuti, pelaburan akan diproses pada hari bekerja berikutnya.',
  [`${idPrefix}.kidsInfo`]:
    'Nilai unjuran yang ditunjukkan bersifat hipotetik dan tidak boleh dipercayai. Ia ' +
    'mungkin dipengaruhi oleh andaian atau risiko dan ketidakpastian yang tidak tepat dan akan berbeza secara ' +
    'material dari hasil yang akhirnya dicapai. Untuk mengira jangkaan pulangan bagi setiap portfolio, pasukan ' +
    'pelaburan meneliti jumlah pulangan sejarah indeks atau kelas aset yang serupa.<br><br>nKadar faedah 2.5% dan ' +
    'kadar inflasi 2% diandaikan. Semua pulangan mengalami deflasi kerana inflasi. Faktor-faktor yang boleh ' +
    'menyebabkan perubahan ketara dari risiko bersejarah digariskan dalam Terma & Syarat. Prestasi masa lalu ' +
    'bukan penunjuk prestasi masa depan yang boleh dipercayai. Garis tengah putih pada carta adalah hasil yang ' +
    'terdekat berdasarkan data. kawasan berlindung di sekitar garis tengah adalah risiko merangkumi selang ' +
    'keyakinan 95%, berdasarkan data sejarah dan menggambarkan risiko dalam format graf untuk mencapai nilai ' +
    'portfolio yang diharapkan.',
  [`${idPrefix}.easyToInvest`]:
    'Pengeluaran mengambil masa sehingga 4 hari bekerja kerana ia melibatkan penjualan ' +
    'unit anda dan pemindahan wang kepada ’Custodian’ sebelum sampai ke akaun bank anda.',
  [`${idPrefix}.navigateToHistory`]:
    'Sila ke skrin Lepas dari Menu untuk melihat aktiviti akaun anda.',
  [`${idPrefix}.depositSuspension`]:
    'Penangguhan Deposit telah diaktifkan kerana kami mengesan bahawa akaun anda ' +
    'mungkin mempunyai dana yang tidak mencukupi dan kami tidak mahu membuat yuran bank yang tidak perlu. Klik di ' +
    'sini untuk membuang Penangguhan Deposit. Sekiranya ia berlaku lagi, sila hubungi Khidmat Pelanggan kami.',
  [`${idPrefix}.linkNew`]: 'Hubungkan Akaun Baru',
  [`${idPrefix}.setupFundingAccount`]:
    'Akaun pembiayaan anda telah diputuskan tautannya kerana dana tidak ' +
    'mencukupi - sila pilih pilihan di bawah untuk menyambung semula akaun sebelumnya atau menyediakan akaun baru.',
  [`${idPrefix}.goToHistory`]: 'Pergi ke halaman Lepas',
  [`${idPrefix}.weMakeItEasyToInvest`]:
    'Kami memudahkan anda untuk melabur dengan amaun yang kecil menggunakan ' +
    'duit baki anda. Lihat ianya bertambah dari semasa ke semasa.',
  [`${idPrefix}.whereInvest`]:
    'Diingatkan, pemindahan wang mengambil masa sehingga 4 hari bekerja.<br>Sekiranya ' +
    'anda dapat mengelak untuk mengeluarkan pelaburan anda, ia akan mendapat peluang untuk berkembang.',
  [`${idPrefix}.withdrawRequestWasReceived`]:
    'Permintaan pengeluaran anda telah diterima. Wang anda biasanya akan ' +
    'masuk ke dalam akaun bank anda dalam 3-4 hari bekerja berikutnya.',
  [`${idPrefix}.withdrawalConfirmed`]: 'Pengeluaran Disahkan',
  [`${idPrefix}.youMustWithdrawAtLeast`]:
    'Pengeluaran mestilah sekurang-kurangnya {value}. Kami akan ' +
    'menjual unit-unit anda untuk melakukan pengeluaran ini, dan duit biasanya akan masuk ke ' +
    'dalam akaun bank anda dalam 3-4 hari bekerja berikutnya.  <br><br>Adakah anda ' +
    'mahu mengeluarkan {value}?',
  [`${idPrefix}.withdrawBalance`]: 'Keluarkan Baki Saya',
  [`${idPrefix}.withdrawBalanceInfo`]:
    'Adakah anda pasti mahu menjual semua pelaburan anda? Pengeluaran boleh ' +
    'mengambil masa sehingga 4 hari bekerja untuk diselesaikan. Pasaran mungkin berubah, jadi jumlah akhir mungkin ' +
    'berbeza dari baki semasa anda.',
  [`${idPrefix}.choosingAmountOverride`]:
    'Duit baki anda akan dilaburkan setelah mencapai had pilihan anda',
  [`${idPrefix}.automaticallyApproved`]:
    'Aktifkan pembundaran  untuk melabur  duit baki anda',
  [`${idPrefix}.wholeDollarInfo`]:
    'Jumlah bulat ringgit adalah kaedah terbaik untuk melabur lebih banyak, tetapi ' +
    'tidak semua orang merasa selesa dengan pelaburan sebanyak ini. Oleh itu, kami memudahkan untuk mengawal ' +
    'jumlah pelaburan untuk transaksi keseluruhan ringgit.',
  [`${idPrefix}.automaticallyAdjust`]:
    'Raiz akan menyesuaikan had pembundaran secara automatik sebelum duit baki ' +
    'anda dilaburkan.',
  [`${idPrefix}.roundUpsSettings`]: 'Tetapan bundaran',
  [`${idPrefix}.linkDebitCard`]: 'Set Kad Debit Sekarang',
  [`${idPrefix}.linkFunding`]: 'Set Kad Anda Sekarang',
  [`${idPrefix}.ok`]: 'OK',
  [`${idPrefix}.whatAreRoundInfo`]:
    'Apabila anda membuat pembelian menggunakan kad bank yang telah anda setkan, ' +
    'Raiz akan membundarkan transaksi tersebut dan seterusnya menyimpan dan melabur duit baki yang tersedia – secara ' +
    'automatik! Contohnya, anda membeli kopi dengan harga RM9.60. Raiz akan menyimpan dan melaburkan duit baki 40 sen ' +
    'yang tersedia itu untuk anda. Mudah!',
  [`${idPrefix}.whatAreRound`]: 'Apa itu Auto-bundar?',
  [`${idPrefix}.smartRoundUps`]: 'Pembundaran Pintar',
  [`${idPrefix}.autoRoundUps`]: 'Pembundaran automatik',
  [`${idPrefix}.roundUps`]: 'Auto-bundar',
  [`${idPrefix}.cancel`]: 'Batal',
  [`${idPrefix}.invest`]: 'Labur',
  [`${idPrefix}.withdraw`]: 'Pengeluaran',
  [`${idPrefix}.withdrawBtn`]: 'Pengeluaran',
  [`${idPrefix}.twitter`]: 'Twitter',
  [`${idPrefix}.facebook`]: 'Facebook',
  [`${idPrefix}.whatsapp`]: 'WhatsApp',
  [`${idPrefix}.email`]: 'Email',
  [`${idPrefix}.confirmWithdrawal`]: 'Sahkan Pengeluaran',
  [`${idPrefix}.sureToWithdraw`]:
    'Adakah anda pasti anda ingin mengeluarkan {value} ke akaun bank anda?',

  [`${idPrefix}.insufficientFunds`]: 'Baki Terkini tidak mencukupi',
  [`${idPrefix}.linkFundingSpending`]: 'Set Kad Debit Sekarang',
  [`${idPrefix}.personalFinanceConcierge`]:
    'Keuanganku memantau kewangan peribadi anda. Ia membolehkan ' +
    'anda mendapatkan gambaran keseluruhan kewangan peribadi anda, meramalkan aliran tunai masa depan dan ' +
    'menerima tips & pandangan mengenai perbelanjaan dan simpanan anda.',
  [`${idPrefix}.cannotBeWithdrawal`]:
    '{withdrawValue} tidak dapat dikeluarkan kerana baki akaun semasa anda ' +
    'hanya {availableBalance}',
  [`${idPrefix}.areYouSureLogout`]: 'Adakah anda pasti anda ingin Log Keluar?',
  [`${idPrefix}.uploadMyKad`]: 'Muat naik foto MyKad anda',
  [`${idPrefix}.transferingMoney`]:
    'Pengeluaran mengambil masa sehingga 4 hari bekerja kerana ia melibatkan ' +
    'penjualan unit anda dan pemindahan wang kepada `Custodian` sebelum sampai ke akaun bank anda.` ',
  [`${idPrefix}.withdrawValue`]: ' Keluarkan {value}',
  [`${idPrefix}.nowBrands`]:
    ' Kini, rakan jenama kami turut melabur untuk masa depan anda. Berbelanja dengan ' +
    'rakan jenama kami, dan mereka akan melaburkan wang ke akaun Raiz anda secara automatik.',
  [`${idPrefix}.noCardLinked`]:
    'Hubungkan kad debit  yang sering anda gunakan untuk menjana wang di akaun Raiz anda ' +
    'apabila anda berbelanja dengan rakan Raiz Rewards.',

  [`${idPrefix}.useLinkCard`]:
    'Gunakan kad yang telah dihubungkan untuk menerima. Untuk kelayakan, sila pastikan ' +
    'anda telah mengaktifkan promosi kedai tersebut.',

  [`${idPrefix}.noCardLinkedWarn`]:
    'Terdapat masalah dengan kad yang dihubungkan ke akaun Raiz anda. Beberapa rakan' +
    ' Raiz Rewards memerlukan anda menghubungkan Akaun Perbelanjaan untuk mengesan pembelian dan memberi ganjaran' +
    ' kepada anda.',

  [`${idPrefix}.portfolioDeversification`]:
    'Sekiranya anda berbelanja dengan rakan jenama kami, mereka akan turut ' +
    'melabur untuk masa depan anda melalui Raiz.',
  [`${idPrefix}.welcomeRaizRewards`]:
    'Sekiranya anda berbelanja dengan rakan jenama kami mereka ' +
    'akan melaburkan wang untuk masa depan anda melalui Raiz.',
  [`${idPrefix}.howSeeRewards`]:
    'Anda akan dapat melihat Raiz rewards anda di skrin “Lepas” ' +
    'setelah kami menerima wang anda daripada jenama yang telah dibeli.',
  [`${idPrefix}.startNow`]: 'Mula Sekarang',
  [`${idPrefix}.welcomeToRaizRewards`]: 'Selamat Datang ke Raiz Rewards',
  [`${idPrefix}.noCardLinkedTitle`]: 'Tiada Kad Dihubungkan',
  [`${idPrefix}.linkCard`]: 'Hubungkan kad',
  [`${idPrefix}.howSeeRewardsTitle`]:
    'Bagaimana boleh saya lihat Raiz Rewards saya?',
  [`${idPrefix}.investment`]: 'Pelaburan',
  [`${idPrefix}.useLinkCardTitle`]:
    'Gunakan kad yang telah<br>dihubungkan untuk ganjaran.',
  [`${idPrefix}.viewYourCards`]: 'Lihat Senarai Kad',
  [`${idPrefix}.futureInfo`]:
    'Nilai unjuran yang ditunjukkan bersifat hipotetik dan tidak boleh dipercayai. ' +
    'Ia mungkin dipengaruhi oleh andaian atau risiko dan ketidakpastian yang tidak tepat dan ' +
    'akan berbeza secara material dari hasil yang akhirnya dicapai. Untuk mengira jangkaan pulangan bagi ' +
    'setiap portfolio, pasukan pelaburan meneliti jumlah pulangan sejarah indeks atau kelas aset yang serupa.' +
    '\n' +
    'Kadar faedah 2.5% dan kadar inflasi 2% diandaikan. Semua pulangan mengalami deflasi kerana inflasi. ' +
    'Faktor-faktor yang boleh menyebabkan perubahan ketara dari risiko bersejarah digariskan dalam Terma & Syarat. ' +
    'Prestasi masa lalu bukan penunjuk prestasi masa depan yang boleh dipercayai.' +
    '\n' +
    'Garis tengah putih pada carta adalah hasil yang terdekat berdasarkan data. ' +
    'kawasan berlindung di sekitar garis tengah adalah risiko merangkumi selang keyakinan 95%, ' +
    'berdasarkan data sejarah dan menggambarkan risiko dalam format graf untuk mencapai nilai ' +
    'portfolio yang diharapkan.',
  [`${idPrefix}.welcomeRaizRewardsMYS`]:
    'Sekiranya anda berbelanja dengan rakan jenama kami mereka akan melaburkan ' +
    'wang untuk masa depan anda melalui Raiz.',
  [`${idPrefix}.letYourFriendsKnow`]: 'Kongsi Bersama Rakan Anda',
  // TODO: https://acornsau.atlassian.net/browse/RAIZ-5061
  [`${idPrefix}.welcomeToRaizKids`]: 'Selamat Datang ke Raiz Kids!',
  [`${idPrefix}.kidsInfoNew`]:
    'Raiz Kids membolehkan anda untuk membuka akaun untuk si kecil anda (dibawah 18 tahun). Lanya cara mudah untuk menabung dan melabur untuk si kecil dan hanya mengambil masa beberapa minit untuk dibuka.',
  [`${idPrefix}.raizKidsAccess`]: 'Pilih Akses untuk Raiz Kids anda',
  [`${idPrefix}.raizKidsAccessInfo`]:
    'Anda boleh memberi akses kepada si kecil anda untuk menguruskan tabungan sendiri. Anda boleh set aturan yang dikehendaki dan boleh menukarnya bila-bila masa.',
  [`${idPrefix}.raizKidsInvest`]: 'Pantau dan Labur',
  [`${idPrefix}.raizKidsInvestInfo`]:
    'Anda boleh melabur, memantau dan membuat pertukaran setiap akaun si kecil anda bila-bila mas daripada menu Raiz Kids.',
  [`${idPrefix}.nextNewKids`]: 'Seterusnya',
  [`${idPrefix}.raizKidsAdult`]:
    'Tukar kepada Akaun Raiz dewasa apabila mereka mencapai umur 18',
  [`${idPrefix}.raizKidsAdultInfo`]:
    'Apabila mereka mencapai umur 18, anda mempunyai pilihan untuk menukar akaun Raiz Kids anda kepada Akaun Raiz dewasa sepenuhnya.',
});

export default messages;
