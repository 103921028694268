import { FUND_TAGS_KEY } from '../consts';

import { getFundsFilters } from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundsList/Filters/utils/utils';

const createTagsParams = ({ activeFundTag, searchParams }) => {
  const fundsFilters = getFundsFilters({ searchParams });

  const mappedFilters = Object.keys(fundsFilters || {}).reduce(
    (activeFilters, context) => [
      ...activeFilters,
      ...(fundsFilters?.[context].map((key) => ({ context, key })) || []),
    ],
    [],
  );

  return activeFundTag
    ? [{ context: FUND_TAGS_KEY, key: activeFundTag }, ...mappedFilters]
    : mappedFilters;
};

export default createTagsParams;
