import React from 'react';
import PropTypes from 'prop-types';

import {
  PortfolioThemeEditColorItemContent,
  PortfolioThemeEditColorItemSelectedContent,
  PortfolioThemeEditColorItemSelectedWrapper,
  PortfolioThemeEditColorItemWrapper,
} from './styles';

export default function EditPortfolioThemeColorItem({
  gradient,
  isSelected,
  onClick,
}) {
  return (
    <PortfolioThemeEditColorItemWrapper onClick={onClick} type="button">
      {isSelected ? (
        <PortfolioThemeEditColorItemSelectedWrapper gradient={gradient}>
          <PortfolioThemeEditColorItemSelectedContent gradient={gradient} />
        </PortfolioThemeEditColorItemSelectedWrapper>
      ) : (
        <PortfolioThemeEditColorItemContent gradient={gradient} />
      )}
    </PortfolioThemeEditColorItemWrapper>
  );
}

EditPortfolioThemeColorItem.propTypes = {
  gradient: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
