import React, { Component } from 'react';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'whatwg-fetch';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Firebase from 'firebase/app';
import { Notifier } from '@airbrake/browser';
import ReactPixel from 'react-facebook-pixel';
import dayjs from 'dayjs';
import { createSearchParams } from 'react-router-dom';

import '../../../styles/layout/main.scss';
import '../../../styles/layout/header.scss';
import * as userActions from '../../../store/user/actions';
import * as dependencyActions from '../../../store/dependentUser/actions';
import Loader from '../../elements/Loader/Loader';
import { DATE_FORMAT_WITH_TIME } from '../../../config/date';
import {
  LOGIN,
  RECOVER_PASSWORD,
  REGISTRATION_SPENDING_ACCOUNT_SUCCESS,
  REGISTRATION_FUNDING_ACCOUNT_FAILED,
  REGISTRATION_FUNDING_ACCOUNT_SUCCESS,
  REGISTRATION_OLD_APP,
  REGISTRATION_OLD_APP_FULL,
  AUTH_OLD_APP,
  AUTH_OLD_APP_FULL,
  RESET_PIN,
  IN_STORE_PAGE,
  REWARDS_ONLINE_PAGE,
  REWARDS_HISTORY_PAGE,
  ROUNDUP_ACCOUNT_LINKED,
} from '../../../constants/comonConstants';
import {
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
  REGISTRATION_PERSONAL_INFO,
  REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT,
  REGISTRATION_ACCOUNT_INFORMATION,
} from '../../../store/commonTypes';
import {
  REGISTRATION_BANKS_SPENDING_ACCOUNT,
  REGISTRATION_STEP_AUTH,
  HOME,
  REGISTRATION_ACCOUNT_OTHER,
  REGISTRATION_SUPERFUND_INFO,
  REGISTRATION_SUPERFUND_MEMBERS,
  REGISTRATION_ACCOUNT_QUESTIONS,
  REGISTRATION_ACCOUNT_MYKAD,
} from '../../../store/user/consts';
import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import FailedPage from '../../../pages/FailedPage/FailedPage';
import {
  KIDS_SIGNUP,
  KIDS_SIGNUP_BANKS,
} from '../../../store/dependentUser/types';
import withRouter from '../../../routes/withRouter';
import * as actions from '../../../store/translates/actions';
import {
  checkIsDependentUser,
  checkIsDependentUserRegistration,
  checkIsSmsfUser,
} from '../../../utils/user';

import PageView from './PageView';
import { USER_SOURCES } from './consts';

import { KIDS_STATUS } from 'constants/kidsConstants';
import { PATHS } from 'constants/paths';
import {
  MIGRATION_SOURCES,
  MIGRATION_URL_PARAMS,
} from 'constants/migrationConstants';
import { selectDependentUserAccess } from 'store/user/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const TIME_LEFT_LOGOUT_MODAL = 480;
const INITIAL_TIME_LEFT = 120;
const WAITING_TIME_LIMIT = 600;

// Initialize Firebase
Firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

if (AU_APP) {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_CODE, {}, options);
}

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMainLoader: true,
      alredyRedirectToRegistration: false,
      isShowExpirateModal: false,
      timeLeft: INITIAL_TIME_LEFT,
      intervalId: 0,
      checkExpirateintervalId: 0,
      isStartCheckExpirate: false,
      hasError: false,
    };

    if (process.env.REACT_APP_AIRBRAKE_IS_ENABLED === 'true') {
      this.airbrake = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
      });
    }

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.checkAlertsOnRegistrations =
      this.checkAlertsOnRegistrations.bind(this);

    this.tick = this.tick.bind(this);

    this.stayHere = this.stayHere.bind(this);

    this.logoutUser = this.logoutUser.bind(this);

    this.checkExpirateTime = this.checkExpirateTime.bind(this);

    this.checkShouldFetchRemoteConfig =
      this.checkShouldFetchRemoteConfig.bind(this);
  }

  componentDidMount() {
    const { actionsUser, history, location } = this.props;
    const { pathname } = location;

    actionsUser.getUserData().then(() => {
      const { alredyRedirectToRegistration } = this.state;
      const { user } = this.props;

      this.getFeatures();

      if (
        user &&
        user.user &&
        user.user.email &&
        !user.user.registration_complete &&
        !alredyRedirectToRegistration
      ) {
        if (
          pathname !== LOGIN &&
          pathname !== AUTH_OLD_APP &&
          pathname !== AUTH_OLD_APP_FULL &&
          pathname !== RESET_PIN &&
          pathname !== RECOVER_PASSWORD &&
          pathname !== REGISTRATION_STEP_AUTH &&
          pathname !== REGISTRATION_OLD_APP &&
          pathname !== REGISTRATION_OLD_APP_FULL
        ) {
          if (user.user.alerts && user.user.alerts.length !== 0) {
            this.checkAlertsOnRegistrations(user.user.alerts, pathname);
          } else {
            this.setState({
              alredyRedirectToRegistration: true,
              showMainLoader: false,
            });

            history.push(HOME);
          }
        } else {
          this.setState({ showMainLoader: false });
        }
      } else {
        this.setState({ showMainLoader: false });
      }

      if (checkIsDependentUser(user)) {
        actionsUser.getDependentAccess();
      }
    });

    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser)) {
      actionsUser.getSMSFDetails();
    }

    const lastActivity = dayjs().format(DATE_FORMAT_WITH_TIME);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.lastActivity, lastActivity);

    this.setState({ isStartCheckExpirate: false });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { history, location, showMainLoader, goToLogin, actionsUser } =
      this.props;

    if (newProps.goToLogin && newProps.goToLogin !== goToLogin) {
      history.push(LOGIN);
    }

    const { alredyRedirectToRegistration, isStartCheckExpirate } = this.state;
    const { pathname } = location;

    this.checkConvertKidsRequest(newProps);

    if (newProps.user && newProps.user.user && newProps.user.user.email) {
      const { user } = this.props;

      if (user && user.user && !user.user.email) {
        const remoteConfig = Firebase.remoteConfig();

        remoteConfig.settings = {
          minimumFetchIntervalMillis: 60000,
          fetchTimeoutMillis: 60000,
        };

        this.getFeatures();
      }
    }

    if (
      pathname !== LOGIN &&
      pathname !== AUTH_OLD_APP &&
      pathname !== AUTH_OLD_APP_FULL &&
      pathname !== RESET_PIN &&
      pathname !== RECOVER_PASSWORD &&
      pathname !== REGISTRATION_STEP_AUTH &&
      pathname !== REGISTRATION_OLD_APP &&
      pathname !== REGISTRATION_OLD_APP_FULL &&
      !isStartCheckExpirate
    ) {
      const lastActivity = dayjs().format(DATE_FORMAT_WITH_TIME);

      sessionStorage.setItem(SESSION_STORAGE_KEYS.lastActivity, lastActivity);

      const checkExpirateintervalId = window.setInterval(
        this.checkExpirateTime,
        10000,
      );

      this.setState({ checkExpirateintervalId });
    }

    if (
      newProps.isNeedCheckRedirect &&
      newProps.user &&
      newProps.user.user &&
      newProps.user.user.email &&
      !newProps.user.user.registration_complete &&
      !alredyRedirectToRegistration
    ) {
      this.setState({ showMainLoader: true });

      if (
        pathname !== LOGIN &&
        pathname !== AUTH_OLD_APP &&
        pathname !== AUTH_OLD_APP_FULL &&
        pathname !== RESET_PIN &&
        pathname !== RECOVER_PASSWORD &&
        pathname !== REGISTRATION_STEP_AUTH &&
        pathname !== REGISTRATION_OLD_APP &&
        pathname !== REGISTRATION_OLD_APP_FULL
      ) {
        if (
          newProps.user.user.alerts &&
          newProps.user.user.alerts.length !== 0
        ) {
          this.checkAlertsOnRegistrations(newProps.user.user.alerts, pathname);
        } else {
          this.setState({ alredyRedirectToRegistration: true });

          history.push(HOME);
        }
      } else {
        this.setState({ showMainLoader: false });
      }
    }

    if (
      newProps.isNeedCheckRedirect &&
      newProps.showMainLoader &&
      newProps.showMainLoader !== showMainLoader
    ) {
      this.setState({ showMainLoader: true });

      actionsUser.getUserData().then(() => {
        const { user } = this.props;

        if (
          user &&
          user.user &&
          user.user.email &&
          !user.user.registration_complete &&
          !alredyRedirectToRegistration
        ) {
          if (user.user.alerts && user.user.alerts.length !== 0) {
            this.checkAlertsOnRegistrations(user.user.alerts, pathname);
          } else {
            this.setState({
              alredyRedirectToRegistration: true,
              showMainLoader: false,
            });

            history.push(HOME);
          }
        } else {
          this.setState({ showMainLoader: false });
        }
      });
    }
  }

  componentDidUpdate() {
    const lastActivity = dayjs().format(DATE_FORMAT_WITH_TIME);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.lastActivity, lastActivity);
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_AIRBRAKE_IS_ENABLED === 'true') {
      this.setState({ hasError: true });

      this.airbrake.notify({
        error,
        params: { info },
      });
    }
  }

  handleCloseModal() {
    const { actionsUser, actionsDependency } = this.props;

    actionsUser.closeModal();

    actionsDependency.closeModal();
  }

  getFeatures() {
    const shouldFetchRemoteConfig = this.checkShouldFetchRemoteConfig();

    if (shouldFetchRemoteConfig) {
      const { actionsUser } = this.props;
      const remoteConfig = Firebase.remoteConfig();

      remoteConfig.settings = {
        minimumFetchIntervalMillis: 60000,
        fetchTimeoutMillis: 60000,
      };

      remoteConfig
        .fetchAndActivate()
        .then(() => {
          let features = remoteConfig.getValue('features');
          const hasFeatures = features?._value;

          if (hasFeatures) {
            features = JSON.parse(hasFeatures);

            const remoteSupportLinks = remoteConfig.getValue('support');

            const supportLinks = remoteSupportLinks?._value
              ? JSON.parse(remoteSupportLinks._value)
              : {};

            actionsUser.getFeaturesSuccess(features, supportLinks);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  tick() {
    const { timeLeft } = this.state;

    if (timeLeft <= 0) {
      this.logoutUser();
    } else {
      this.setState({
        timeLeft: timeLeft - 1,
      });
    }
  }

  checkExpirateTime() {
    const { location } = this.props;
    const { pathname } = location;
    const { isShowExpirateModal, isStartCheckExpirate } = this.state;

    if (
      pathname !== LOGIN &&
      pathname !== AUTH_OLD_APP &&
      pathname !== AUTH_OLD_APP_FULL &&
      pathname !== RESET_PIN &&
      pathname !== RECOVER_PASSWORD &&
      pathname !== REGISTRATION_STEP_AUTH &&
      pathname !== REGISTRATION_OLD_APP &&
      pathname !== REGISTRATION_OLD_APP_FULL &&
      !isStartCheckExpirate
    ) {
      const lastActivity = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.lastActivity,
      );

      const nonActivityTime = dayjs().diff(lastActivity, 'seconds');

      if (nonActivityTime >= TIME_LEFT_LOGOUT_MODAL && !isShowExpirateModal) {
        const intervalId = window.setInterval(this.tick, 1000);

        this.setState({
          isShowExpirateModal: true,
          intervalId,
          isStartCheckExpirate: true,
        });
      }

      if (nonActivityTime >= WAITING_TIME_LIMIT) {
        this.logoutUser();
      }
    }
  }

  logoutUser() {
    const { actionsUser } = this.props;
    const { checkExpirateintervalId } = this.state;

    this.setState({
      showMainLoader: true,
      isShowExpirateModal: false,
      isStartCheckExpirate: false,
    });

    clearInterval(checkExpirateintervalId);

    this.stayHere();

    actionsUser.logout().then(() => {
      this.setState({ showMainLoader: false });
    });
  }

  stayHere() {
    const { intervalId } = this.state;
    const lastActivity = dayjs().format(DATE_FORMAT_WITH_TIME);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.lastActivity, lastActivity);

    clearInterval(intervalId);

    this.setState({
      isShowExpirateModal: false,
      timeLeft: INITIAL_TIME_LEFT,
      isStartCheckExpirate: false,
    });

    const checkExpirateintervalId = window.setInterval(
      this.checkExpirateTime,
      1000,
    );

    this.setState({ checkExpirateintervalId });
  }

  checkConvertKidsRequest(newProps) {
    if (!MY_APP) return;

    const { history, location, actionsUser, unauthorized } = this.props;

    const { alredyRedirectToRegistration } = this.state;

    const { pathname } = location;

    const newUserProps = newProps?.user?.user;

    const isKidsAccount = newUserProps?.user_type === 'dependent';

    const isRegistrationComplete = !!newUserProps?.registration_complete;

    const hasErrorUploadInformationAlert = !!newUserProps.alerts?.find(
      (alert) => alert.type === 'ERRUPLOADINFORMATION',
    );

    const dependentAccessStatus = newProps?.dependentUserAccess?.status;

    if (
      alredyRedirectToRegistration &&
      !unauthorized &&
      newProps.unauthorized
    ) {
      this.setState({
        alredyRedirectToRegistration: false,
      });
    }

    if (
      !alredyRedirectToRegistration &&
      isKidsAccount &&
      !isRegistrationComplete &&
      dependentAccessStatus === undefined &&
      !unauthorized
    ) {
      this.setState({
        alredyRedirectToRegistration: true,
        showMainLoader: true,
      });
    }

    if (
      alredyRedirectToRegistration &&
      isKidsAccount &&
      !isRegistrationComplete &&
      dependentAccessStatus !== undefined
    ) {
      this.setState({
        showMainLoader: false,
      });

      if (dependentAccessStatus === KIDS_STATUS.pending_converting) {
        const { user } = this.props;

        const isRedirectToUploadDocuments =
          pathname === HOME &&
          hasErrorUploadInformationAlert &&
          !isRegistrationComplete &&
          user?.previousStep !== PATHS.registration.portfolio.index;

        if (isRedirectToUploadDocuments) {
          actionsUser.setCurrentRegistrationStep(REGISTRATION_ACCOUNT_MYKAD);

          history.push(REGISTRATION_ACCOUNT_MYKAD);
        }

        const { profile } = user.user;

        if (
          pathname !== REGISTRATION_PERSONAL_INFO &&
          !hasErrorUploadInformationAlert &&
          Object.keys(profile).length === 0 &&
          pathname !== PATHS.registration.personal.address &&
          pathname !== PATHS.registration.personal.taxResidents &&
          pathname !== PATHS.registration.personal.accountQuestions &&
          pathname !== PATHS.registration.account.other &&
          pathname !== PATHS.registration.portfolio.index &&
          !(
            newProps.user?.currentStep === PATHS.root &&
            newProps.user?.previousStep === PATHS.registration.portfolio.index
          )
        ) {
          actionsUser.setCurrentRegistrationStep(REGISTRATION_PERSONAL_INFO);

          history.push(REGISTRATION_PERSONAL_INFO);
        }

        if (
          pathname === REGISTRATION_ACCOUNT_MYKAD &&
          !hasErrorUploadInformationAlert
        ) {
          actionsUser.setCurrentRegistrationStep(REGISTRATION_PERSONAL_INFO);

          history.push(REGISTRATION_PERSONAL_INFO);
        }
      }
    }
  }

  checkAlertsOnRegistrations(alerts, pathname) {
    const { alredyRedirectToRegistration } = this.state;
    const { actionsUser } = this.props;

    if (!alredyRedirectToRegistration) {
      let isSMSFDetailsLink = true;
      let isSMSFMembersLink = true;
      let isSpendingLink = true;
      let isFundingLink = true;
      let isWithdrawalLink = true;
      let isMyKad = true;

      alerts.map((alert) => {
        if (alert.type === 'REGISTRATION_INCOMPLETE_LINKFUNDING') {
          isFundingLink = false;
        }

        if (alert.type === 'REGISTRATION_INCOMPLETE_LINKSPENDING') {
          isSpendingLink = false;
        }

        if (alert.type === 'REGISTRATION_INCOMPLETE_WITHDRAWALACCOUNT') {
          isWithdrawalLink = false;
        }

        if (alert.type === 'REGISTRATION_INCOMPLETE_SMSF_DETAILS') {
          isSMSFDetailsLink = false;
        }

        if (alert.type === 'REGISTRATION_INCOMPLETE_SMSF_MEMBERS') {
          isSMSFMembersLink = false;
        }

        if (
          alert.type === 'MYKAD_FRONT_UPLOAD_MISSING' ||
          alert.type === 'MYKAD_BACK_UPLOAD_MISSING' ||
          alert.type === 'ERRUPLOADINFORMATION'
        ) {
          isMyKad = false;
        }

        return alert;
      });

      if (isFundingLink) {
        isSpendingLink = true;
      }

      const { history, user } = this.props;

      const isRedirectPartnerIncompleteMigration =
        AU_APP &&
        !user.user?.registration_complete &&
        user.user?.source !== USER_SOURCES.raiz;

      this.setState({ alredyRedirectToRegistration: true });

      if (
        user.user.source === MIGRATION_SOURCES.superEstate &&
        !user.user.registration_complete
      ) {
        history.push(PATHS.migration.account.pds);
      } else if (isRedirectPartnerIncompleteMigration) {
        const searchParam = createSearchParams({
          [MIGRATION_URL_PARAMS.source]: user.user?.source,
        }).toString();

        history.push(`${PATHS.migration.account.pds}?${searchParam}`);
      } else if (!isSMSFMembersLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_SUPERFUND_MEMBERS;
        } else {
          history.push(REGISTRATION_SUPERFUND_MEMBERS);
        }
      } else if (!isSMSFDetailsLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_SUPERFUND_INFO;
        } else {
          history.push(REGISTRATION_SUPERFUND_INFO);
        }
      } else if (MY_APP && !isMyKad && !isFundingLink && !isWithdrawalLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_ACCOUNT_MYKAD;
        } else {
          history.push(REGISTRATION_ACCOUNT_MYKAD);
        }
      } else if (!isSpendingLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_BANKS_SPENDING_ACCOUNT;
        } else if (
          history.location.pathname !==
            PATHS.registration.spendingAccount.status.failure &&
          history.location.pathname !==
            PATHS.registration.spendingAccount.status.success &&
          pathname !== PATHS.registration.fundingAccount.status.failure &&
          pathname !== PATHS.registration.fundingAccount.status.success
        ) {
          if (MY_APP) {
            history.push(PATHS.registration.fundingAccount.index);
          } else {
            history.push(REGISTRATION_BANKS_SPENDING_ACCOUNT);
          }
        }
      } else if (!isFundingLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_BANKS_FUNDING_ACCOUNT;
        } else {
          history.push(REGISTRATION_BANKS_FUNDING_ACCOUNT);
        }
      } else if (
        pathname === LOGIN ||
        pathname === AUTH_OLD_APP ||
        pathname === AUTH_OLD_APP_FULL ||
        pathname === HOME ||
        pathname === RESET_PIN
      ) {
        if (!checkIsDependentUserRegistration()) {
          window.location.href = checkIsSmsfUser(user)
            ? REGISTRATION_ACCOUNT_QUESTIONS
            : REGISTRATION_PERSONAL_INFO;
        }
      } else if (pathname === REGISTRATION_SPENDING_ACCOUNT_SUCCESS) {
        history.push(REGISTRATION_BANKS_FUNDING_ACCOUNT);
      } else if (pathname === REGISTRATION_FUNDING_ACCOUNT_SUCCESS) {
        if (!isWithdrawalLink) {
          actionsUser.showWithdrawal();
        }

        history.push(REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT);
      } else if (!isWithdrawalLink) {
        if (
          pathname === LOGIN ||
          pathname === AUTH_OLD_APP ||
          pathname === AUTH_OLD_APP_FULL ||
          pathname === HOME ||
          pathname === RESET_PIN
        ) {
          window.location.href = REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT;
        } else {
          actionsUser.showWithdrawal();

          history.push(REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT);
        }
      } else {
        history.push(
          checkIsSmsfUser(user)
            ? REGISTRATION_ACCOUNT_QUESTIONS
            : REGISTRATION_PERSONAL_INFO,
        );
      }

      setTimeout(() => {
        this.setState({ showMainLoader: false });
      }, 1000);
    }
  }

  checkShouldFetchRemoteConfig() {
    const { location } = this.props;
    const { pathname } = location;

    const pathnamesToCheck = [
      IN_STORE_PAGE,
      REWARDS_ONLINE_PAGE,
      REWARDS_HISTORY_PAGE,
      ROUNDUP_ACCOUNT_LINKED,
    ];

    const isSupportedPage = !pathnamesToCheck.includes(pathname);

    if (isSupportedPage) {
      return true;
    }

    return false;
  }

  render() {
    const {
      children,
      locale,
      messages,
      location,
      unauthorized,
      history,
      globalErrorMessage,
      user,
    } = this.props;

    const {
      alredyRedirectToRegistration,
      isShowExpirateModal,
      timeLeft,
      hasError,
    } = this.state;

    if (hasError) {
      return <FailedPage />;
    }

    const { showMainLoader } = this.state;
    const { pathname } = location;
    const fullPath = `${location.pathname}${location.search}`;
    let header = false;
    let menu = false;
    let contentClass = '';
    const invitePattern = /^\/invite\/[a-zA-Z0-9]{3,6}$/;

    if (
      pathname === '/login' ||
      pathname === AUTH_OLD_APP ||
      pathname === AUTH_OLD_APP_FULL ||
      pathname === RESET_PIN ||
      pathname === '/registration' ||
      pathname === REGISTRATION_OLD_APP ||
      pathname === REGISTRATION_OLD_APP_FULL ||
      pathname === RECOVER_PASSWORD ||
      pathname === '/users/password/edit' ||
      pathname === '/login/' ||
      pathname === '/registration/' ||
      pathname === RECOVER_PASSWORD ||
      pathname === '/users/password/edit/'
    ) {
      if (
        pathname !== '/users/password/edit' &&
        pathname !== '/registration' &&
        pathname !== '/registration/' &&
        pathname !== '/login/' &&
        pathname !== '/login' &&
        pathname !== AUTH_OLD_APP &&
        pathname !== RESET_PIN &&
        pathname !== AUTH_OLD_APP_FULL &&
        pathname !== REGISTRATION_OLD_APP &&
        pathname !== REGISTRATION_OLD_APP_FULL
      ) {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.token);
      }

      contentClass = 'container login-content';
    } else if (
      pathname === '/registration/account/agreement' ||
      pathname === '/registration/personal/account-questions' ||
      pathname === '/registration/personal/birthday' ||
      pathname === '/registration/personal/security-question' ||
      pathname === '/registration/personal/info' ||
      pathname === '/registration/personal/address' ||
      pathname === '/registration/personal/address' ||
      pathname === '/registration/investments/investment' ||
      pathname === '/registration/bankAccount/banks' ||
      pathname === '/registration/banks/spendingAccount' ||
      pathname === '/registration/banks/spendingAccount-failed' ||
      pathname === '/registration/banks/fundingAccount' ||
      pathname === '/registration/account/portfolio' ||
      pathname === REGISTRATION_SPENDING_ACCOUNT_SUCCESS ||
      pathname === REGISTRATION_FUNDING_ACCOUNT_FAILED ||
      pathname === REGISTRATION_FUNDING_ACCOUNT_SUCCESS ||
      pathname === '/registration/account/uploadId' ||
      pathname === REGISTRATION_ACCOUNT_INFORMATION ||
      pathname === REGISTRATION_ACCOUNT_OTHER ||
      pathname === REGISTRATION_SUPERFUND_INFO ||
      pathname === REGISTRATION_SUPERFUND_MEMBERS ||
      pathname === '/registration/personal/tax-resident' ||
      pathname === REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT ||
      pathname.includes(PATHS.registration.portfolio.index)
    ) {
      contentClass = `container registration-content ${
        pathname === '/registration/account/agreement' ? '-agreement' : ''
      }`;

      if (
        user &&
        user.user &&
        user.user.registration_complete &&
        !alredyRedirectToRegistration &&
        !pathname.includes(PATHS.kids.registration.index)
      ) {
        this.setState({ alredyRedirectToRegistration: true });
      } else if (
        user &&
        user.user &&
        !user.user.registration_complete &&
        user.user.alerts &&
        user.user.alerts.length !== 0 &&
        !alredyRedirectToRegistration &&
        pathname !== RECOVER_PASSWORD
      ) {
        if (checkIsSmsfUser(user)) {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.agreementType,
            'superFund',
          );
        }

        this.checkAlertsOnRegistrations(user.user.alerts, pathname);
      }
    } else if (pathname === '/not-found') {
      contentClass = 'container not-found-content';
    } else if (invitePattern.test(pathname)) {
      contentClass = 'container invite-content';
    } else if (
      pathname === '/raiz-registration/personal/terms' ||
      pathname === '/raiz-registration/personal/info' ||
      pathname === '/raiz-registration/portfolio/select' ||
      pathname === '/raiz-registration/investment/initial' ||
      pathname === '/raiz-registration/investment/recurring' ||
      pathname === '/raiz-registration/investment/portfolio' ||
      pathname === '/raiz-registration/other/notification' ||
      pathname === '/raiz-registration/other/access' ||
      pathname === '/raiz-registration/other/transfer-in' ||
      pathname === KIDS_SIGNUP ||
      pathname === KIDS_SIGNUP_BANKS ||
      pathname.includes(PATHS.kids.registration.index)
    ) {
      contentClass = 'container registration-content';
    } else {
      header = true;

      menu = true;
    }

    if (
      user &&
      user.user &&
      !user.user.registration_complete &&
      user.user.alerts &&
      user.user.alerts.length !== 0 &&
      !alredyRedirectToRegistration &&
      pathname !== '/login' &&
      pathname !== AUTH_OLD_APP &&
      pathname !== RESET_PIN &&
      pathname !== AUTH_OLD_APP_FULL &&
      pathname !== REGISTRATION_STEP_AUTH &&
      pathname !== REGISTRATION_OLD_APP &&
      pathname !== REGISTRATION_OLD_APP_FULL &&
      pathname !== RECOVER_PASSWORD
    ) {
      this.checkAlertsOnRegistrations(user.user.alerts, pathname);
    } else if (!user && !user.user && !user.user.email) {
      history.push(LOGIN);
    }

    if (
      pathname === '/registration/banks/spendingAccount' ||
      pathname === '/registration/banks/spendingAccount-failed' ||
      pathname === '/registration/banks/fundingAccount' ||
      pathname === REGISTRATION_SPENDING_ACCOUNT_SUCCESS ||
      pathname === REGISTRATION_FUNDING_ACCOUNT_FAILED ||
      pathname === REGISTRATION_FUNDING_ACCOUNT_SUCCESS ||
      pathname === REGISTRATION_BANKS_WITHDRAWAL_ACCOUNT
    ) {
      contentClass = 'container registration-content bank-content';
    }

    const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

    if (
      (unauthorized || !token) &&
      pathname !== '/login' &&
      pathname !== AUTH_OLD_APP &&
      pathname !== RESET_PIN &&
      pathname !== AUTH_OLD_APP_FULL &&
      pathname !== '/registration' &&
      pathname !== REGISTRATION_OLD_APP &&
      pathname !== REGISTRATION_OLD_APP_FULL &&
      pathname !== '/registration/account/agreement' &&
      pathname !== RECOVER_PASSWORD &&
      pathname !== '/users/password/edit' &&
      !invitePattern.test(pathname) &&
      pathname !== '/kids-signup/' &&
      pathname !== '/kids-signup/banks/'
    ) {
      if (!pathname.includes(PATHS.registration.index)) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.lastPathName, fullPath);
      }

      history.push('/login', { state: {}, replace: true });

      return <div />;
    }

    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {showMainLoader ? (
          <div>
            <Loader additionalClass="-absolute -top" />
          </div>
        ) : (
          <PageView
            showMainLoader={showMainLoader}
            contentClass={contentClass}
            header={header}
            menu={menu}
            user={user}
            globalErrorMessage={globalErrorMessage}
            handleCloseModal={this.handleCloseModal}
            isShowExpirateModal={isShowExpirateModal}
            timeLeft={timeLeft}
            showLangChange={
              MY_APP && (pathname === '/registration' || pathname === '/login')
            }
            stayHere={this.stayHere}
            logoutUser={this.logoutUser}
            history={history}
          >
            {children}
          </PageView>
        )}
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.translates.locale,
  messages: state.translates.messages,
  unauthorized: state.user.unauthorized,
  globalErrorMessage:
    state.user.globalErrorMessage ||
    state.spendingAnalytics.globalErrorMessage ||
    state.dependencyUsers.globalErrorMessage,
  user: state.user,
  showMainLoader: state.user.showMainLoader,
  goToLogin: state.user.goToLogin,
  dependentUserAccess: selectDependentUserAccess(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  actionsUser: bindActionCreators(userActions, dispatch),
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
});

/**
 * @deprecated Do not extend this Components
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));

Page.defaultProps = {
  unauthorized: null,
  actionsUser: {},
  actionsDependency: {},
  user: {},
  globalErrorMessage: '',
  showMainLoader: false,
  goToLogin: false,
  dependentUserAccess: {},
};

Page.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  unauthorized: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  actionsDependency: PropTypes.shape({
    closeModal: PropTypes.func,
  }),
  actionsUser: PropTypes.shape({
    getDependentAccess: PropTypes.func,
    getSMSFDetails: PropTypes.func,
    getUserData: PropTypes.func,
    getFeaturesSuccess: PropTypes.func,
    logout: PropTypes.func,
    showWithdrawal: PropTypes.func,
    closeModal: PropTypes.func,
    setCurrentRegistrationStep: PropTypes.func,
  }),
  user: PropTypes.shape({
    user: PropTypes.shape({
      registration_complete: PropTypes.bool,
      email: PropTypes.string,
      user_type: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
      source: PropTypes.string,
      profile: PropTypes.shape({}),
    }),
    previousStep: PropTypes.string,
  }),
  globalErrorMessage: PropTypes.string,
  showMainLoader: PropTypes.bool,
  goToLogin: PropTypes.bool,
  dependentUserAccess: PropTypes.shape({
    manage_portfolio: PropTypes.bool,
    status: PropTypes.string,
    investing: PropTypes.bool,
  }),
};
