import { useTranslation } from 'react-i18next';

import { AU_APP, MY_APP } from 'constants/localeConfigs';
import validatePostalCode from 'utils/validators/validatePostalCode';
import { getPostCodeLength } from 'utils/formatters/getPostCodeLength';
import {
  checkIsDependentUser,
  checkIsDependentUserRegistration,
} from 'utils/user';

export const useAddressValidation = () => {
  const { t } = useTranslation();
  const requiredText = t('fields.validation.required');

  const validate = ({ values, showResidentInfoModal }) => {
    const errors = {};
    const postalCodeLength = getPostCodeLength();

    if (AU_APP && !values.street_number) {
      errors.street_number = requiredText;
    }

    if (!values.address1) {
      errors.address1 = requiredText;
    }

    if ((AU_APP || MY_APP) && !values.city) {
      errors.city = requiredText;
    }

    if ((AU_APP || MY_APP) && (!values.state || values.state.length === 0)) {
      errors.state = requiredText;
    }

    if (MY_APP && values.us_citizen) {
      showResidentInfoModal(true);
    }

    if (
      (AU_APP || MY_APP) &&
      !validatePostalCode({
        postalCode: values.zip,
        requiredNumberOfDigits: postalCodeLength,
      })
    ) {
      errors.zip = t('fields.validation.pleaseEnterDigits', {
        number: postalCodeLength,
      });
    }

    return errors;
  };

  return { validate };
};

export const getLeftPartIconClassName = (user) => {
  const isDependentUser =
    checkIsDependentUser(user) || checkIsDependentUserRegistration();

  if (isDependentUser && MY_APP) {
    return 'kids-mys-icon';
  }

  return 'body-text';
};
