import { Dispatch } from 'redux';

import {
  GET_REFERRALS_SUMMARY_ERROR,
  GET_REFERRALS_SUMMARY_LOADING,
  GET_REFERRALS_SUMMARY_SUCCESS,
  GET_REFERRALS_LIST_ERROR,
  GET_REFERRALS_LIST_LOADING,
  GET_REFERRALS_LIST_SUCCESS,
  RESET_REFERRALS,
  RESET_REFERRALS_SUMMARY,
  RESET_REFERRALS_LIST,
  REFERRALS_LIST_LIMIT,
} from './consts';
import { IReferralsList, IReferralsSummary } from './types';

import { api } from 'services/api';

export const getReferralsListSuccess = (data: IReferralsList) => ({
  type: GET_REFERRALS_LIST_SUCCESS,
  data,
});

export const getReferralsListError = (error: unknown) => ({
  type: GET_REFERRALS_LIST_ERROR,
  error,
});

export const getReferralsListLoading = () => ({
  type: GET_REFERRALS_LIST_LOADING,
});

export const getReferralsSummarySuccess = (data: IReferralsSummary) => ({
  type: GET_REFERRALS_SUMMARY_SUCCESS,
  data,
});

export const getReferralsSummaryError = (error: unknown) => ({
  type: GET_REFERRALS_SUMMARY_ERROR,
  error,
});

export const getReferralsSummaryLoading = () => ({
  type: GET_REFERRALS_SUMMARY_LOADING,
});

export const resetReferrals = () => ({
  type: RESET_REFERRALS,
});

export const resetReferralsSummary = () => ({
  type: RESET_REFERRALS_SUMMARY,
});

export const resetReferralsList = () => ({
  type: RESET_REFERRALS_LIST,
});

export const getReferralsSummary = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getReferralsSummaryLoading());

    const result = await api.get<IReferralsSummary, IReferralsSummary>(
      'referrals/summary',
    );

    dispatch(getReferralsSummarySuccess(result));
  } catch (e) {
    dispatch(getReferralsSummaryError(e));
  }
};

export const getReferralsList = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getReferralsListLoading());

    const result = await api.get<IReferralsList, IReferralsList>('referrals', {
      params: { limit: REFERRALS_LIST_LIMIT },
    });

    dispatch(getReferralsListSuccess(result));
  } catch (e) {
    dispatch(getReferralsListError(e));
  }
};
