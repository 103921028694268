import React from 'react';

import { WidgetButton } from '../../components/WidgetButton';
import 'styles/layout/time-investment.scss';
import { WidgetContent, WidgetHeader, WidgetText } from '../../styles';
import { OffsettersWrapper } from '../styles';
import { useOffsetters } from '../hooks';

import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import { OFFSETTERS_PAGE } from 'constants/comonConstants';
import theme from 'theme';

export default function OffsettersWidgetContent() {
  const { isLoading, offsetters } = useOffsetters();
  const { backgroundUrl, title, description, homeButtonTitle } = offsetters;

  if (isLoading) {
    return <DeprecatedDotsLoader additionalClass="green" />;
  }

  return (
    <WidgetContent background={backgroundUrl}>
      <OffsettersWrapper>
        <WidgetHeader>{title}</WidgetHeader>

        <WidgetText>{description}</WidgetText>

        <WidgetButton
          href={OFFSETTERS_PAGE}
          text={homeButtonTitle || ''}
          background={theme.gradients.orange}
        />
      </OffsettersWrapper>
    </WidgetContent>
  );
}
