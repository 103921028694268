import styled from '@emotion/styled';

export const HomeWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const HomeContentStyled = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const FutureTabWrapper = styled.div`
  overflow: hidden;
  div:first-of-type {
    div:first-of-type {
      border-radius: 0;
      margin-top: 0;
    }
  }
`;
