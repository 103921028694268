import { useTranslation } from 'react-i18next';

import { WidgetContent, WidgetTitle } from '../styles';

import { useTopHoldings } from './hooks';
import TopHoldingsItems from './TopHoldingsItems';

export default function TopHoldings() {
  const { topHoldings } = useTopHoldings();
  const { t } = useTranslation();

  if (!topHoldings?.length) {
    return null;
  }

  return (
    <WidgetContent>
      <WidgetTitle>
        {t('portfolioCustomization.fundDetails.topHoldings.title')}
      </WidgetTitle>

      <TopHoldingsItems />
    </WidgetContent>
  );
}
