export const FUNDS_TAGS = {
  etf: 'etf',
  stock: 'stock',
  btc: 'btc',
  rpf: 'rpf',
  rrpf: 'rrpf',
  basePortfolio: 'basePortfolio',
};

export const PORTFOLIO_CUSTOMIZATION_TABS = {
  preview: 'preview',
  explore: 'explore',
};

export const PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS = {
  regularUser: {
    stepType: 'account',
    stepName: 'portfolio',
  },
  kids: {
    stepType: 'portfolio',
    stepName: 'select',
  },
};
