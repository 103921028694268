import React from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetTitle, WidgetWrapper } from '../styles';
import { WidgetButton } from '../components/WidgetButton';

import { KidsWidgetWrapper } from './components/styles';
import { useKids } from './hooks';
import KidsContent from './components/KidsContent';

import theme from 'theme';
import { KIDS_PAGE } from 'constants/comonConstants';

export default function KidsWidget() {
  const { t } = useTranslation();

  const { isLoading, isNewChild } = useKids();

  return (
    <WidgetWrapper>
      <WidgetTitle>
        {isNewChild || isLoading
          ? t('kidsWidget.title')
          : t('kidsWidget.balanceTitle')}
      </WidgetTitle>

      <KidsWidgetWrapper>
        <KidsContent />

        {!isLoading && (
          <WidgetButton
            href={KIDS_PAGE}
            text={
              isNewChild
                ? t('kidsWidget.buttons.addNewChild')
                : t('kidsWidget.buttons.checkYourKids')
            }
            background={theme.colors.whiteTransparent}
            hasBorder
          />
        )}
      </KidsWidgetWrapper>
    </WidgetWrapper>
  );
}
