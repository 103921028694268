import { PORTFOLIO_ACTIONS } from './consts';
import { IPortfolioState, IPortfolioActions } from './types';

export const initialState: IPortfolioState = {
  isProPortfolioLoading: true,
  isFundDetailsLoading: true,
  isFundsLoading: true,
  funds: [],
  proPortfolio: {
    id: '',
    name: '',
    structure: {
      color_set: {
        id: '',
      },
      funds_tags: [],
      portfolio: {
        allocation: 0,
        id: '',
      },
    },
  },
  fundsTags: [],
  isFundsTagsLoading: false,
  isProPortfolioColorLoading: false,
  isFilterModalVisible: false,
  isUpdateFundsLoading: false,
  isResidentialPropertyFundsLoading: false,
  proPortfolioColors: [],
  residentialPropertyFunds: [],
  fundDetails: {
    allocation: 0,
    balancing: false,
    category: '',
    colour_background: '',
    colour_gradient: '',
    current_price: 0,
    description: '',
    feature_holdings: [''],
    id: '',
    image_url: '',
    initial_allocation: 0,
    label: '',
    max_allocation: 0,
    name: '',
    risk: '',
    symbol: '',
    tags: [''],
    top_holdings: [''],
    ytd_return: 0,
  },
  activePortfolioId: '',
  fundsToUpdate: [],
  sorting: '',
  fundStatistics: {
    prices: {
      current: 0,
    },
  },
  isFundStatisticsLoading: false,
  isPortfolioLoading: false,
  isBasePortfolioFullyAllocated: false,
  isParentProPortfolioPreview: false,
};

const portfolio = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IPortfolioState = initialState,
  action: IPortfolioActions,
): IPortfolioState => {
  switch (action.type) {
    case PORTFOLIO_ACTIONS.GET_FUNDS_TAGS_LOADING:
      return {
        ...state,
        isFundsTagsLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_FUNDS_TAGS_SUCCESS:
      return {
        ...state,
        fundsTags: action.fundsTags ?? [],
        isFundsTagsLoading: false,
      };

    case PORTFOLIO_ACTIONS.GET_FUNDS_LOADING:
      return {
        ...state,
        isFundsLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_FUNDS_SUCCESS:
      return {
        ...state,
        funds: action.funds,
        isFundsLoading: false,
        isFilterModalVisible: false,
      };

    case PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_LOADING:
      return {
        ...state,
        isProPortfolioLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        proPortfolio: action.proPortfolio,
        isProPortfolioLoading: false,
        isUpdateFundsLoading: false,
      };

    case PORTFOLIO_ACTIONS.GET_FUND_DETAILS_SUCCESS:
      return {
        ...state,
        fundDetails: action.fundDetails?.fund,
        isFundDetailsLoading: false,
      };
    case PORTFOLIO_ACTIONS.GET_FUND_DETAILS_LOADING:
      return {
        ...state,
        isFundDetailsLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_COLORS_SUCCESS:
      return {
        ...state,
        proPortfolioColors: action.proPortfolioColors,
        isProPortfolioColorLoading: false,
      };
    case PORTFOLIO_ACTIONS.GET_RESIDENTIAL_PROPERTY_FUNDS_LOADING:
      return {
        ...state,
        isResidentialPropertyFundsLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_RESIDENTIAL_PROPERTY_FUNDS_SUCCESS:
      return {
        ...state,
        residentialPropertyFunds: action.residentialPropertyFunds,
        isResidentialPropertyFundsLoading: false,
      };
    case PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_COLORS_LOADING:
      return {
        ...state,
        isProPortfolioColorLoading: true,
      };
    case PORTFOLIO_ACTIONS.SET_IS_UPDATE_FUNDS_LOADING:
      return {
        ...state,
        isUpdateFundsLoading: action.isLoading,
      };
    case PORTFOLIO_ACTIONS.SET_ACTIVE_PORTFOLIO_ID:
      return {
        ...state,
        activePortfolioId: action.activePortfolioId ?? '',
      };
    case PORTFOLIO_ACTIONS.SET_FILTER_MODAL_VISIBLE:
      return {
        ...state,
        isFilterModalVisible: action.isFilterModalVisible,
      };
    case PORTFOLIO_ACTIONS.SET_FUNDS_TO_UPDATE:
      return {
        ...state,
        fundsToUpdate: action.fundsToUpdate,
      };
    case PORTFOLIO_ACTIONS.FUNDS_SORTING:
      return {
        ...state,
        sorting: action.sorting,
      };
    case PORTFOLIO_ACTIONS.GET_FUND_STATISTICS_SUCCESS:
      return {
        ...state,
        fundStatistics: action.fundStatistics ?? {},
        isFundStatisticsLoading: true,
      };
    case PORTFOLIO_ACTIONS.GET_FUND_STATISTICS_LOADING:
      return {
        ...state,
        isFundStatisticsLoading: false,
      };
    case PORTFOLIO_ACTIONS.SET_IS_BASE_PORTFOLIO_FULLY_ALLOCATED:
      return {
        ...state,
        isBasePortfolioFullyAllocated: action.isBasePortfolioFullyAllocated,
      };
    case PORTFOLIO_ACTIONS.SET_IS_PARENT_PRO_PORTFOLIO_PREVIEW:
      return {
        ...state,
        isParentProPortfolioPreview: action.isParentProPortfolioPreview,
      };
    case PORTFOLIO_ACTIONS.UPDATE_PORTFOLIO_PARAMS:
      return {
        ...state,
        portfolioParams: action.portfolioParams,
      };
    case PORTFOLIO_ACTIONS.UPDATE_PORTFOLIO_LOCATION:
      return {
        ...state,
        portfolioLocation: action.portfolioLocation,
      };
    default:
      return state;
  }
};

export default portfolio;
