export const KIDS_ACCESS_STEP = {
  investing: 'investing',
} as const;

export const KIDS_EDIT_STEPS = {
  settings: 'settings',
  profile: 'profile',
  recurring: 'recurring',
  invest: 'invest',
  access: 'access',
  investingAccess: 'investingAccess',
  allowedAccess: 'allowedAccess',
  notification: 'notification',
  'transfer-out': 'transfer-out',
  'transfer-in': 'transfer-in',
} as const;

export const ADULT_AGE = 18 as const;

export const REQUESTED_DOCUMENT = {
  birthCertificate: 'bc',
};

export const CHILD_ID_SEARCH_PARAM = 'childId' as const;

export const FREQUENCY_LAST_DAY = -1;
