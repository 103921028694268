import { useTranslation } from 'react-i18next';

import {
  PortfolioPlanFooterBackButton,
  PortfolioPlanFooterButton,
  PortfolioPlanFooterWrapper,
} from './styles';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioLocation } from 'store/portfolio/selectors';
import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import { getPortfolioPreviousPage } from 'pages/ChangePortfolio/utils';

interface IPortfolioPlanFooterProps {
  isActivePortfolio: boolean;
  handleOpenChangePortfolioModal: () => void;
  handleGoBack?: (previousPage: string) => void;
}

export default function PortfolioPlanFooter({
  isActivePortfolio,
  handleOpenChangePortfolioModal,
  handleGoBack,
}: IPortfolioPlanFooterProps) {
  const { t } = useTranslation();

  const { isRegistration, isKids } = useAppSelector(selectPortfolioLocation);

  const isSuperRegistration = useIsSuperRegistration();

  const isButtonDisabled =
    isActivePortfolio && !isRegistration && !isSuperRegistration;

  const buttonText = isButtonDisabled ? 'buttonDisabled' : 'button';

  const previousPage = getPortfolioPreviousPage(isKids);

  return (
    <PortfolioPlanFooterWrapper isRegistration={isRegistration}>
      {isRegistration && (
        <PortfolioPlanFooterBackButton
          className="-exclude"
          title={t('common.back')}
          handleClick={() => handleGoBack?.(previousPage)}
        />
      )}

      <PortfolioPlanFooterButton
        className="-exclude"
        title={
          isKids ? t('common.next') : t(`portfolio.planFooter.${buttonText}`)
        }
        disabled={isButtonDisabled}
        handleClick={handleOpenChangePortfolioModal}
      />
    </PortfolioPlanFooterWrapper>
  );
}
