import dayjs from 'dayjs';

import {
  SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS,
  SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS,
} from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { createRequest } from '../user/actions';
import { AU_APP, COUNTRY } from '../../constants/localeConfigs';
import { MY_APP } from '../../constants/togglesFunctions';
import { api, getHeaders } from '../../services/api';

import {
  GET_GOALS_SUCCESS,
  CREATE_GOAL_SUCCESS,
  UPDATE_GOAL_SUCCESS,
  SHOW_ERROR_GET_GOALS,
  DELETE_GOALS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  TRACK_EVENTS_SUCCESS,
  SHOW_ERROR_UPDATE_GOAL,
  SHOW_ERROR_CREATE_GOAL,
  SHOW_ERROR_DELETE_GOALS,
  STOP_INVESTMENT_SUCCESS,
  SHOW_ERROR_TRACK_EVENTS,
  UPDATE_SETTINGS_SUCCESS,
  SHOW_ERROR_GET_SETTINGS,
  SHOW_ERROR_UPDATE_SETTINGS,
  GET_TIPS_BY_PATTERN_SUCCESS,
  GET_INVEST_CATEGORIES_SUCCESS,
  SHOW_ERROR_GET_TIPS_BY_PATTERN,
  GET_TOTALS_BY_CATEGORY_SUCCESS,
  GET_CATEGORIES_AVERAGES_SUCCESS,
  UPDATE_TRANSACTION_TYPE_SUCCESS,
  SHOW_ERROR_GET_TOTALS_BY_CATEGORY,
  GET_UPCOMING_TRANSACTIONS_SUCCESS,
  SHOW_ERROR_UPDATE_TRANSACTION_TYPE,
  SHOW_ERROR_GET_CATEGORIES_AVERAGES,
  SHOW_ERROR_GET_UPCOMING_TRANSACTIONS,
  GET_TRANSACTION_CATEGORISATION_SUCCESS,
  GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
  CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
  SHOW_ERROR_GET_TRANSACTIONS_CATEGORISATION,
  SHOW_PAYMENT_CHOICE_RECURRING_MODAL,
  GET_CATEGORIES_LIST_SUCCESS,
} from './types';

import {
  AMOUNT,
  DATE,
  CUSTOMER_DESCRIPTION,
  CUSTOMER,
  OVERRIDE_SIMILAR,
  UPCOMING_TRANSACTION_DAYS,
  TIP,
  BAHASA,
  AUS,
  MYS,
  FILES_BASE_URL,
} from 'constants/comonConstants';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function getGoalsSuccess(goals) {
  return { type: GET_GOALS_SUCCESS, goals };
}

export function getTotalsByCategorySuccess(categoryTotals) {
  return { type: GET_TOTALS_BY_CATEGORY_SUCCESS, categoryTotals };
}

export function getTransactionsCategorisationSuccess(
  transactionCategorisation,
  addListener,
) {
  return {
    type: GET_TRANSACTION_CATEGORISATION_SUCCESS,
    transactionCategorisation,
    addListener,
  };
}

export function getRecurringDepositSettingsSuccess(recurringDepositSettings) {
  return {
    type: GET_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
    recurringDepositSettings,
  };
}

export function getInvestCategoriesSuccess(categories) {
  return { type: GET_INVEST_CATEGORIES_SUCCESS, categories };
}

export function getUpcomingTransactionsSuccess(upcomingTransactions) {
  return { type: GET_UPCOMING_TRANSACTIONS_SUCCESS, upcomingTransactions };
}

export function getCategoriesAveragesSuccess(categoriesAverages) {
  return { type: GET_CATEGORIES_AVERAGES_SUCCESS, categoriesAverages };
}

export function getSettingsSuccess(settings) {
  return { type: GET_SETTINGS_SUCCESS, settings };
}

export function getCategoriesSuccess(categoriesList) {
  return { type: GET_CATEGORIES_LIST_SUCCESS, categoriesList };
}

export function getTipsByPatternSuccess(tips) {
  return { type: GET_TIPS_BY_PATTERN_SUCCESS, tips };
}

export function trackEventsSuccess() {
  return { type: TRACK_EVENTS_SUCCESS };
}

export function deleteGoalsSuccess() {
  return { type: DELETE_GOALS_SUCCESS };
}

export function updateTransactionTypeSuccess() {
  return { type: UPDATE_TRANSACTION_TYPE_SUCCESS };
}

export function updateSettingsSuccess() {
  return { type: UPDATE_SETTINGS_SUCCESS };
}

export function createGoalSuccess() {
  return { type: CREATE_GOAL_SUCCESS };
}

export function updateGoalSuccess() {
  return { type: UPDATE_GOAL_SUCCESS };
}

export function stopInvestmentSuccess() {
  return { type: STOP_INVESTMENT_SUCCESS };
}

export function showErrorUpdateTransactionType(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_UPDATE_TRANSACTION_TYPE, errorMessage, errorTitle };
}

export function showErrorcreateRecurringDepositSettings(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_CREATE_RECURRING_DEPOSIT_SETTINGS,
    errorMessage,
    errorTitle,
  };
}

export function showErrorUpdateGoal(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_UPDATE_GOAL, errorMessage, errorTitle };
}

export function showErrorUpdateSettings(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_UPDATE_SETTINGS, errorMessage, errorTitle };
}

export function showErrorGetTipsByPattern(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_TIPS_BY_PATTERN, errorMessage, errorTitle };
}

export function showErrorTrackEvents(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_TRACK_EVENTS, errorMessage, errorTitle };
}

export function showErrorGetGoals(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_GOALS, errorMessage, errorTitle };
}

export function showErrorCreateGoal(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_CREATE_GOAL, errorMessage, errorTitle };
}

export function showErrorGetRecurringDepositSettings(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_GET_RECURRING_DEPOSIT_SETTINGS,
    errorMessage,
    errorTitle,
  };
}

export function showErrorDeleteGoals(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_DELETE_GOALS, errorMessage, errorTitle };
}

export function showErrorGetTotalsByCategory(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_TOTALS_BY_CATEGORY, errorMessage, errorTitle };
}

export function showErrorGetTransactionsCategorisation(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_GET_TRANSACTIONS_CATEGORISATION,
    errorMessage,
    errorTitle,
  };
}

export function showErrorGetCategoriesAverages(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_CATEGORIES_AVERAGES, errorMessage, errorTitle };
}

export function showErrorGetSettings(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_SETTINGS, errorMessage, errorTitle };
}

export function showErrorGetUpcomingTransactions(
  errorMessage,
  errorTitle = '',
) {
  return {
    type: SHOW_ERROR_GET_UPCOMING_TRANSACTIONS,
    errorMessage,
    errorTitle,
  };
}

export function createRecurringDepositSettingsSuccess(
  recurringDepositSettings,
  checkoutUrl = '',
) {
  return {
    type: CREATE_RECURRING_DEPOSIT_SETTINGS_SUCCESS,
    recurringDepositSettings,
    checkoutUrl,
  };
}

export function showRecurringPayment() {
  return { type: SHOW_PAYMENT_CHOICE_RECURRING_MODAL };
}

export function getGoals() {
  let responseStatus = '';

  return (dispatch) =>
    createRequest(null, 'GET', 'spending_analytics/getgoals')
      .then((response) => {
        responseStatus = response.status;

        return response.json();
      })
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetGoals(response.error));
        } else if (responseStatus === 500 && response.message) {
          dispatch(showGlobalError(response.message, false));
        } else {
          dispatch(getGoalsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createGoal(data) {
  const requestData = JSON.stringify({ additional_params: data });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'spending_analytics/creategoal')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorCreateGoal(response.error));
        } else {
          dispatch(createGoalSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateGoal(data) {
  const requestData = JSON.stringify({ additional_params: data });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'spending_analytics/updategoal')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorUpdateGoal(response.error));
        } else {
          dispatch(updateGoalSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRecurringDepositSettings() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/` +
        `${
          AU_APP || MY_APP
            ? process.env.REACT_APP_API_VERSION
            : process.env.REACT_APP_API_VERSION_V2
        }/recurring_deposit_settings`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetRecurringDepositSettings(response.error));
        } else {
          dispatch(getRecurringDepositSettingsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createRecurringDepositSettings(data) {
  const requestData = JSON.stringify(data);

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/` +
        `${
          AU_APP || MY_APP
            ? process.env.REACT_APP_API_VERSION
            : process.env.REACT_APP_API_VERSION_V2
        }/recurring_deposit_settings`,
      {
        method: 'PUT',
        mode: 'cors',
        body: requestData,
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorcreateRecurringDepositSettings(response.error));
        } else {
          dispatch(createRecurringDepositSettingsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function deleteGoals(key) {
  const requestData = JSON.stringify({ additional_params: { key } });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'spending_analytics/deletegoal')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorDeleteGoals(response.error));
        } else {
          dispatch(deleteGoalsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function stopInvestment() {
  return (dispatch) =>
    createRequest(null, 'DELETE', 'recurring_deposit_settings')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorcreateRecurringDepositSettings(response.error));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getTotalsByCategory(data) {
  const { withMinDate } = data || {};

  const minDate = dayjs()
    .subtract(3, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  const minDateParam = withMinDate ? `&min_date=${minDate}` : '';

  const params = {
    additional_params: `movement=debit${minDateParam}`,
  };

  return async (dispatch) => {
    try {
      const response = await api
        .get('/spending_analytics/gettotalsbycategory', { params })
        .catch((error) => {
          const errorMessage = error?.response?.data?.error;

          if (errorMessage) {
            dispatch(showErrorGetTotalsByCategory(errorMessage));
          }
        });

      if (response.error) {
        dispatch(showErrorGetTotalsByCategory(response.error));
      } else if (response.message) {
        dispatch(showErrorGetTotalsByCategory(response.message));
      } else {
        dispatch(getTotalsByCategorySuccess(response));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getTransactionCategorisation(
  additional,
  sort = AMOUNT,
  count = 50,
  page = 0,
) {
  const query = additional.uncategorised
    ? 'query%3Dnot%28custom_description%3A%27%23%27%29%26'
    : '';

  const sortQuery = sort === DATE ? '' : `%26sort%3D${AMOUNT}`;
  const additionalParams = `${query}count%3D${count}${sortQuery}%26page%3D${page}`;

  return (dispatch) =>
    createRequest(
      null,
      'GET',
      `spending_analytics/searchtransactions?additional_params=${additionalParams}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetTransactionsCategorisation(response.error));
        } else {
          dispatch(getTransactionsCategorisationSuccess(response, true));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateTransactionType(data) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const requestObject = {
    token,
    additional_params: {
      key: data.key,
      custom_fields: [
        {
          name: CUSTOMER_DESCRIPTION,
          value: data.type,
        },
      ],
    },
  };

  if (data.type) {
    requestObject.additional_params.mode = {
      type: OVERRIDE_SIMILAR,
      scope: CUSTOMER,
    };
  }

  const requestData = JSON.stringify(requestObject);

  return (dispatch) =>
    createRequest(requestData, 'POST', 'spending_analytics/synccustomfields')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorUpdateTransactionType(response.error));
        } else {
          dispatch(updateTransactionTypeSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getUpcomingTransactions() {
  const additionalParams = `days=${UPCOMING_TRANSACTION_DAYS}`;

  return (dispatch) =>
    createRequest(
      null,
      'GET',
      `spending_analytics/getupcomingtransactions?additional_params=${additionalParams}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetUpcomingTransactions(response.error));
        } else {
          dispatch(getUpcomingTransactionsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCategoriesAverages() {
  return (dispatch) =>
    createRequest(
      null,
      'GET',
      'spending_analytics/categories_averages?groups%5B%5D=age&groups%5B%5D=gender',
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetCategoriesAverages(response.error));
        } else {
          dispatch(getCategoriesAveragesSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSettings() {
  return (dispatch) =>
    createRequest(null, 'GET', 'spending_analytics/settings')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetSettings(response.error));
        } else {
          dispatch(getSettingsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateSettings(tipsEnabled) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const requestData = JSON.stringify({
    token,
    settings: {
      tips_enabled: tipsEnabled,
    },
  });

  return (dispatch) =>
    createRequest(requestData, 'PUT', 'spending_analytics/settings')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorUpdateSettings(response.error));
        } else {
          dispatch(updateSettingsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getTipsByPattern() {
  const tipsUrl = 'tips';

  const sessionTripsResponse = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.sessionTripsResponse,
  );

  if ((AU_APP || MY_APP) && sessionTripsResponse !== null) {
    return getTipsByPatternSuccess(JSON.parse(sessionTripsResponse));
  }

  return (dispatch) =>
    createRequest(null, 'GET', tipsUrl)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetTipsByPattern(response.error));
        } else {
          if (AU_APP) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.sessionTripsResponse,
              JSON.stringify(response),
            );
          }

          dispatch(getTipsByPatternSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function trackEvents(event) {
  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  const eventData = {
    action: event.action,
    label: event.tipKey,
    category: TIP,
  };

  if (event.value) {
    eventData.value = event.value;
  }

  const requestData = JSON.stringify({
    additional_params: {
      events: [eventData],
    },
    token,
  });

  return (dispatch) =>
    createRequest(requestData, 'POST', 'spending_analytics/trackevents')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorTrackEvents(response.error));
        } else {
          if (AU_APP) {
            sessionStorage.removeItem(
              SESSION_STORAGE_KEYS.sessionTripsResponse,
            );
          }

          dispatch(trackEventsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCategories() {
  const currentLang = localStorage.getItem('lang');

  const countryMt = {
    [AUS]: `${FILES_BASE_URL}/mt/categories.json`,
    [MYS]:
      currentLang === BAHASA
        ? `${process.env.REACT_APP_API_ASSETS}/mt/categories-ms.json`
        : `${process.env.REACT_APP_API_ASSETS}/mt/categories.json`,
  };

  return (dispatch) =>
    fetch(countryMt[COUNTRY], {
      method: 'GET',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorGetSettings(response.error));
        } else {
          dispatch(getCategoriesSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
