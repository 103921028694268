import React from 'react';

import BlogPostItem from './BlogPostItem/BlogPostItem';
import BlogPostsSkeleton from './BlogPostsSkeleton';
import { IBlogPost } from './BlogPostItem/types';

interface IBlogPosts {
  isLoading: boolean;
  posts: IBlogPost[];
}

export const BlogPosts = ({ isLoading, posts }: IBlogPosts) => {
  if (isLoading) {
    return <BlogPostsSkeleton />;
  }

  return (
    <>
      {posts.map((post) => (
        <BlogPostItem key={`blog-post-item_${post.id}`} post={post} />
      ))}
    </>
  );
};
