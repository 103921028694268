import styled from '@emotion/styled';
import InputMask from 'react-input-mask';

import { FieldWrapper } from '../styles';

export const InputMaskWrapper = styled(FieldWrapper)`
  margin: 15px 0;
`;

export const InputMaskStyled = styled(InputMask)`
  width: 100%;
  padding-top: 0;
  height: 55px;
  font-weight: bold;
`;
