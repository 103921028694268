import { useTranslation } from 'react-i18next';

import {
  InfoButton,
  Title,
  Sublabel,
  Switcher,
  SwitcherItem,
  Wrapper,
} from '../styles';

import RecurringBannerInfo from './RecurringBannerInfo';

import { FREQUENCIES } from 'constants/comonConstants';
import { IconButton } from 'components/elements/IconButton';

interface IRecurringDepositSettings {
  amount?: number;
  frequency?: string;
  frequency_identifier?: string;
  next_investment_at?: string;
}

interface IRecurringInvestmentBannerProps {
  showFrequencyChangeState: boolean;
  isSuper: boolean;
  currentFrequency: string;
  recurringDepositSettings: IRecurringDepositSettings;
  toggleHelpCard: (value: boolean) => void;
  changeCurrentFrequency: (value: string, isRecurring: boolean) => void;
  invAmount?: number | null;
  startDate?: Date | null;
  frequencyDay?: number | null;
  currentBalance?: number;
}

type FrequenciesKeys = keyof typeof FREQUENCIES;

function RecurringInvestmentBanner({
  showFrequencyChangeState,
  isSuper,
  toggleHelpCard,
  invAmount = null,
  startDate = null,
  frequencyDay = null,
  currentBalance = 0,
  currentFrequency,
  recurringDepositSettings,
  changeCurrentFrequency,
}: IRecurringInvestmentBannerProps) {
  const { t } = useTranslation();

  const isActiveMonthly = currentFrequency === FREQUENCIES.monthly;

  const isCurrentFrequencySelected = currentFrequency !== '';

  const frequenciesKeys = Object.keys(FREQUENCIES);

  return (
    <Wrapper isWithSwitcher={showFrequencyChangeState} isSuper={isSuper}>
      <InfoButton>
        <IconButton
          iconName="helpWhite"
          size={28}
          onClick={() => toggleHelpCard(true)}
        />
      </InfoButton>

      <Title>
        {showFrequencyChangeState
          ? t(
              'recurringInvestmentPage.recurringInvestmentBanner.title.investmentFrequency',
            )
          : t(
              'recurringInvestmentPage.recurringInvestmentBanner.title.recurringInvestment',
            )}
      </Title>

      <RecurringBannerInfo
        invAmount={invAmount}
        startDate={startDate}
        frequencyDay={frequencyDay}
        currentBalance={currentBalance}
        currentFrequency={currentFrequency}
        recurringDepositSettings={recurringDepositSettings}
        showFrequencyChangeState={showFrequencyChangeState}
      />

      {showFrequencyChangeState && (
        <Switcher>
          {frequenciesKeys.map((frequency) => (
            <SwitcherItem
              key={frequency}
              role="button"
              tabIndex={0}
              onKeyPress={() => {}}
              onClick={() =>
                changeCurrentFrequency(
                  FREQUENCIES[frequency as FrequenciesKeys],
                  true,
                )
              }
              isActive={
                frequency === FREQUENCIES.daily || !isCurrentFrequencySelected
                  ? currentFrequency === FREQUENCIES.daily
                  : currentFrequency ===
                    FREQUENCIES[frequency as FrequenciesKeys]
              }
            >
              {t(`common.frequencies.${frequency as FrequenciesKeys}`)}
            </SwitcherItem>
          ))}
        </Switcher>
      )}

      {showFrequencyChangeState && isActiveMonthly && (
        <Sublabel>
          {t(
            'recurringInvestmentPage.recurringInvestmentBanner.label.chooseDay',
          )}
        </Sublabel>
      )}
    </Wrapper>
  );
}

export default RecurringInvestmentBanner;
