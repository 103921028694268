import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HistoryDetails from '../HistoryDetails/HistoryDetails';
import HomePastBanner from '../Home/components/HomePastBanner';

import RecurringModal from 'components/layout/Modals/RecurringModal';

function RaizKidsEditHistoryView({ childId }) {
  const { t } = useTranslation();

  const [isRecurringModalOpen, setIsRecurringModalOpen] = useState(false);

  return (
    <div className="page-content">
      <div className="page-content__full">
        <div className="page-content__banner home__banner -kids-history">
          <div className="page-content__banner-title">
            {t('raizKidsEditHistoryPage.title')}
          </div>

          <HomePastBanner />
        </div>

        <div>
          <HistoryDetails isKidsHistory childId={childId} />
        </div>
      </div>

      <RecurringModal
        isOpen={isRecurringModalOpen}
        onRequestClose={() => setIsRecurringModalOpen(false)}
        handleClick={() => setIsRecurringModalOpen(false)}
        modalClassName="time-investment__modal"
        modalFooterClassName="-single-btn"
      />
    </div>
  );
}

export default RaizKidsEditHistoryView;

RaizKidsEditHistoryView.defaultProps = {
  child: {},
  childId: '',
};

RaizKidsEditHistoryView.propTypes = {
  child: PropTypes.shape({
    summary: PropTypes.shape({
      invested_by_you: PropTypes.shape({
        value: PropTypes.number,
      }),
      reinvested_dividends: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_referrals_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      gain_loss: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_found_money_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      withdrawals: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
  }),
  childId: PropTypes.string,
};
