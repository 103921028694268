import styled from '@emotion/styled';

export const Button = styled.button<{ size: number }>`
  border-radius: 0;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  background: transparent;
  margin: 0;
  padding: 0;
`;
