import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function OfferDistance({ locations }) {
  const { t } = useTranslation();
  const firstLocationDistance = locations?.[0].distance;

  if (firstLocationDistance) {
    if (firstLocationDistance < 1) {
      return (
        <span>
          {Number.parseInt(firstLocationDistance * 1000)}

          {t('common.metric.meterShort')}
        </span>
      );
    }

    return (
      <span>
        {Number.parseFloat(firstLocationDistance).toFixed(1)}

        {t('common.metric.kilometerShort')}
      </span>
    );
  }

  return <span />;
}

OfferDistance.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      distance: PropTypes.number,
    }),
  ).isRequired,
};
