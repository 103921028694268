import React, { useEffect, useState } from 'react';

import {
  IHandleSubAccountProps,
  IShowConfirmModalProps,
  ISubaccount,
} from './types';
import {
  Checkbox,
  CheckboxWrapper,
  CheckedIcon,
  Title,
  Wrapper,
} from './styles';

interface IAccountProps {
  subaccount: ISubaccount;
  handleSubAccount: (e: IHandleSubAccountProps) => void;
  monitoredId: number;
  isSpending: boolean;
  showConfirmModal: (e: IShowConfirmModalProps) => void;
}
const PLUS = '+';

export const Account = ({
  subaccount,
  handleSubAccount,
  monitoredId,
  isSpending,
  showConfirmModal,
}: IAccountProps) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isSpending) {
      if (subaccount.linked) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  }, [isSpending, subaccount]);

  const functionParameters = {
    accountId: subaccount.account_id,
    monitoredId,
    linked: subaccount.linked,
    bankId: subaccount.bank_id,
    accountName: subaccount.account_name,
  };

  return (
    <Wrapper key={subaccount.account_id}>
      <Title>{subaccount.account_nickname}</Title>

      <CheckboxWrapper checked={isChecked}>
        {PLUS}

        <Checkbox
          type="checkbox"
          onChange={(e) => {
            const params = { target: e.target, ...functionParameters };

            return !isSpending
              ? showConfirmModal(params)
              : handleSubAccount(params);
          }}
        />

        {isChecked && <CheckedIcon iconName="checkboxCheck" size={20} />}
      </CheckboxWrapper>
    </Wrapper>
  );
};
