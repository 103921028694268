import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

export const Content = styled.div<{ isActive?: boolean }>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.greenLight : theme.colors.mercury};
  padding: 3px 10px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  gap: 6px;
`;

export const Status = styled.div<{ isActive?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.green : theme.colors.black};
`;
