import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Code, Icon, CopyButtonStyled } from './styles';

import { selectUserInvitation } from 'store/user/selectors';
import { UserInvitationInvitation, IRootUserState } from 'store/user/types';

export const CopyButton = () => {
  const invitation = useSelector<IRootUserState, UserInvitationInvitation>(
    selectUserInvitation,
  );

  const { t } = useTranslation();

  return (
    <CopyButtonStyled copyValue={invitation?.url || ''}>
      <Trans
        i18nKey="referralsPage.buttonCopy"
        t={t}
        values={{
          code: invitation?.code || '',
        }}
        components={{
          bold: <Code />,
        }}
      />

      <Icon />
    </CopyButtonStyled>
  );
};
