import { useTranslation } from 'react-i18next';

import { useRegistrationFinancialPage } from '../hooks/useRegistrationFinancialPage';

import { FinancialAdditionalFieldWrapper } from './styles';

import { AdditionalFieldWrapper } from 'pages/Registration/components/styles';
import { SUITABILITY_TRANSLATION_KEYS } from 'constants/suitabilityConstants';
import { MY_APP } from 'constants/localeConfigs';
import { InputField } from 'components/form/input/InputField';

export interface IAdditionalField {
  name: string;
}

type TLabelTranslationKey =
  (typeof SUITABILITY_TRANSLATION_KEYS)[keyof typeof SUITABILITY_TRANSLATION_KEYS];

export default function AdditionalField({ name }: IAdditionalField) {
  const { t } = useTranslation();

  const { isRegistrationFinancialPage } = useRegistrationFinancialPage();

  const labelTranslationKey: TLabelTranslationKey =
    SUITABILITY_TRANSLATION_KEYS[name];

  if (isRegistrationFinancialPage) {
    return (
      <AdditionalFieldWrapper isUppercase={MY_APP}>
        <InputField
          name={name}
          label={t(`registrationPage.other.${labelTranslationKey}`)}
        />
      </AdditionalFieldWrapper>
    );
  }

  return (
    <FinancialAdditionalFieldWrapper isUppercase={MY_APP}>
      <InputField
        name={name}
        label={t(`registrationPage.other.${labelTranslationKey}`)}
      />
    </FinancialAdditionalFieldWrapper>
  );
}
