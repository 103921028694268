import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getReferralsList, resetReferralsList } from 'store/referrals/actions';
import { selectReferralsListGroupedData } from 'store/referrals/selectors';

export const useFetchReferralList = () => {
  const dispatch = useDispatch();

  const { isSettled, isLoading, isSuccess, data } = useSelector(
    selectReferralsListGroupedData,
  );

  useEffect(() => {
    if (!isLoading && !isSettled) {
      dispatch(getReferralsList());
    }

    return () => {
      if (isSettled) {
        dispatch(resetReferralsList());
      }
    };
  }, [dispatch, isLoading, isSettled]);

  return {
    isLoading,
    isSuccess,
    groupedReferralList: data,
  };
};
