import { defineMessages } from 'react-intl';

import { STATEMENTS as idPrefix } from '../../../constants/translates';

const messages = defineMessages({
  [`${idPrefix}.statements`]: 'Penyata',
  [`${idPrefix}.confirmation`]: 'Pengesahan',
  [`${idPrefix}.taxReports`]: 'Laporan Cukai',
  [`${idPrefix}.monthlyStatements`]: 'Bulanan',
  [`${idPrefix}.accountValue`]: 'Nilai Akaun',
  [`${idPrefix}.emptyConfirmation`]: 'Tiada Pengesahan',
  [`${idPrefix}.emptyMonthlyStatements`]: 'Tiada Penyata Bulanan',
  [`${idPrefix}.openPDF`]: 'Buka PDF',
  [`${idPrefix}.downloadPDF`]: 'Muat turun PDF',
  [`${idPrefix}.emptyStatements`]: 'Tiada sebarang penyata buat masa ini!',
  [`${idPrefix}.ok`]: 'OK',
});

export default messages;
