import { useState } from 'react';

import { Button } from './styles';

import InviteModal from 'components/layout/Modals/InviteModal';

export const InviteButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => setIsModalVisible((previous) => !previous);

  return (
    <>
      <Button onClick={toggleModal} />

      <InviteModal showModal={isModalVisible} handleCloseModal={toggleModal} />
    </>
  );
};
