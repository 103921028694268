import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { RaizTheme } from 'theme';
import { Button } from 'components/elements/Button';

const MENU_MAX_HEIGHT = 500;

interface ISuperMenuWrapperProps {
  isActive: boolean;
}

interface IMenuItemBaseProps {
  isActive: boolean;
  theme: RaizTheme;
}

interface IActiveMenuItemStylesProps {
  theme: RaizTheme;
}

interface IItemProps extends Omit<IMenuItemBaseProps, 'theme'> {
  theme?: RaizTheme;
}
export const SuperMenuWrapper = styled.div<ISuperMenuWrapperProps>`
  text-align: left;
  width: 100%;
  padding-left: 30px;
  background: ${({ theme }) => theme.colors.white};
  margin: -10px 0 10px;
  max-height: ${({ isActive }) => (isActive ? `${MENU_MAX_HEIGHT}px` : '0')};
  transition: max-height 0.5s linear, border 0s;
  overflow: hidden;
  border-top: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.colors.mercury}` : '0'};
`;

const activeMenuItemStyles = ({ theme }: IActiveMenuItemStylesProps) => css`
  color: ${theme.colors.royalBlue};
  font-family: ${theme.fontFamily.medium};
  font-weight: ${theme.fontWeights.semibold};
  :hover {
    color: ${theme.colors.royalBlue};
  }
`;

const customMenuIcon = css`
  div {
    margin-right: 12px;
    margin-left: 0;
  }
`;

export const menuItemBase = ({ theme, isActive }: IMenuItemBaseProps) => css`
  font-size: ${theme.fontSizes['6sm']};
  padding: 10px 0;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;
  font-family: ${theme.fontFamily.regular};
  border-radius: 0;
  background: ${theme.colors.white};
  color: ${theme.colors.boulder};
  border-bottom: 1px solid ${theme.colors.mercury};
  font-weight: ${theme.fontWeights.medium};

  ${isActive && activeMenuItemStyles({ theme })}
  ${customMenuIcon}
`;

export const MenuButton = styled(Button)<IItemProps>`
  ${menuItemBase}
`;

export const Item = styled(Button)<IItemProps>`
  :hover {
    color: ${({ theme }) => theme.colors.boulder};
  }
  :last-of-type {
    border: none;
  }
  ${menuItemBase}
`;
