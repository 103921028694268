import { useTranslation } from 'react-i18next';

import {
  ProPortfolioLabelContent,
  ProPortfolioLabelPercent,
  ProPortfolioLabelTitle,
  ProPortfolioNewBadge,
  ProPortfolioArrow,
  ProPortfolioLabelText,
  ProPortfolioPlus,
  BadgesWrapper,
} from '../styles';

import { NUMBER_FORMATS } from 'utils/formatters/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import { Row } from 'components/layout/row';

interface IProPortfolioLabelProps {
  name: string;
  value: number;
  isActive?: boolean;
  isModal?: boolean;
  isExpanded?: boolean;
  onClick?: () => void;
  isExpandable?: boolean;
  isBaseFund?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  tags?: {
    color: string;
    label: string;
  }[];
}

export default function ProPortfolioLabel({
  name,
  value,
  isModal,
  isExpanded,
  onClick,
  isActive = true,
  isExpandable = true,
  isBaseFund,
  onMouseOver,
  onMouseOut,
  tags,
}: IProPortfolioLabelProps) {
  const { t } = useTranslation();

  return (
    <ProPortfolioLabelContent
      isModal={isModal}
      isActive={isActive}
      onClick={isActive ? onClick : () => {}}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Row>
        <Row>
          <ProPortfolioLabelTitle>
            {isBaseFund ? t('portfolio.portfolioName', { name }) : name}
          </ProPortfolioLabelTitle>

          {!isModal && (
            <BadgesWrapper>
              {tags?.map(
                ({ color, label }) =>
                  label && (
                    <ProPortfolioNewBadge key={label} color={color}>
                      {label}
                    </ProPortfolioNewBadge>
                  ),
              )}
            </BadgesWrapper>
          )}
        </Row>

        <Row>
          {value || isBaseFund ? (
            <>
              <ProPortfolioLabelPercent isModal={isModal}>
                {formatNumber({
                  value,
                  type: NUMBER_FORMATS.percent,
                  minimumFractionDigits: 1,
                })}
              </ProPortfolioLabelPercent>

              {isExpandable && <ProPortfolioArrow isExpanded={isExpanded} />}
            </>
          ) : (
            <>
              <ProPortfolioLabelText>{t('common.add')}</ProPortfolioLabelText>

              <ProPortfolioPlus />
            </>
          )}
        </Row>
      </Row>
    </ProPortfolioLabelContent>
  );
}
