import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../../styles/pages/recurring-investment.scss';
import { RECURRINGINVESTMENT } from '../../constants/comonConstants';
import DeprecatedDotsLoader from '../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import Loader from '../../components/elements/Loader/Loader';
import RecurringNoteModal from '../../components/layout/Modals/RecurringNoteModal';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';
import { CURRENCY_LOCALE, CURRENCY_NAME } from '../../constants/localeConfigs';
import FundingModal from '../../components/layout/Modals/FundingModal';
import RecurringModal from '../../components/layout/Modals/RecurringModal';
import { checkIsDependentUser } from '../../utils/user';

import RecurringInvestmentBanner from './components/RecurringInvestmentBanner';
import GoalNameChange from './components/GoalNameChange';
import FrequencyChange from './components/FrequencyChange';
import GoalSetTable from './components/GoalSetTable';
import EmptyRecurring from './components/EmptyRecurring';
import FrequencySetTable from './components/FrequencySetTable';
import { recurringButtonStyles } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useSuperAccountErrorModal } from 'store/user/hooks/useSuperAccountErrorModal';
import { Button } from 'components/elements/Button';

const changeState = (path) => {
  window.location.href = path;
};

const renderBtnContainer = ({
  showMainLoader,
  isRecurringEmpty,
  changePlan,
  showFrequencyChangeState,
  showChangeGoalNameState,
  showLoader,
  isSuper,
  currentFrequency,
  createRecurringDepositSettings,
  updateGoal,
  editGoal,
  hasRecurringDeposit,
  t,
}) => {
  if (
    !showMainLoader &&
    (isRecurringEmpty || changePlan) &&
    !showFrequencyChangeState &&
    !showChangeGoalNameState
  ) {
    if (showLoader) {
      return (
        <DeprecatedButton
          title={<DeprecatedDotsLoader additionalClass="btn" />}
          buttonClass={`button__basic-fill -dark-grey ${
            isSuper ? '-super-btn' : ''
          }`}
          handleClick={() => {}}
        />
      );
    }

    const title = t(
      'recurringInvestmentPage.renderBtnContainer.setInvestmentButton',
      {
        frequency: currentFrequency
          ? t(`common.frequencies.${currentFrequency}`)
          : '',
      },
    );

    return (
      <DeprecatedButton
        title={title}
        buttonClass={`button__basic-fill -green ${isSuper ? '-super-btn' : ''}`}
        handleClick={() => {
          createRecurringDepositSettings();
        }}
      />
    );
  }

  if (
    !showMainLoader &&
    ((!isRecurringEmpty && !hasRecurringDeposit) || editGoal) &&
    !showFrequencyChangeState &&
    !showChangeGoalNameState
  ) {
    return (
      <Button
        label={t(
          'recurringInvestmentPage.renderBtnContainer.setSavingGoalButton',
        )}
        onClick={updateGoal}
        customStyles={recurringButtonStyles.styles}
      />
    );
  }

  return '';
};

function RecurringInvestmentView({
  recurringDepositSettings,
  changeInvAmount,
  isRecurringEmpty,
  hasRecurringDeposit,
  hasGoals,
  currentGoal,
  setEditGoal,
  editGoal,
  invAmount,
  setChangePlan,
  changePlan,
  deleteSavingGoal,
  stopInvestment,
  toggleChangeFrequencyState,
  handleChangeSavingGoal,
  savingGoalCondition,
  showFrequencyChangeState,
  changeCurrentFrequency,
  currentFrequency,
  frequencyDay,
  changeFrequencyDay,
  changeStartDate,
  startDate,
  toggleChangeGoalState,
  showChangeGoalNameState,
  changeTargetAmount,
  targetAmount,
  changeGoalName,
  currentGoalName,
  currentBalance,
  isOpenHelpCards,
  toggleHelpCard,
  isOpenErrorModal,
  isSuper,
  isOpenInfoModal,
  toggleStopModal,
  isOpenStopModal,
  showLoader,
  showMainLoader,
  isOpenNoteModal,
  user,
  createRecurringDepositSettings,
  toggleErrorModal,
  currentErrorMessage,
  toggleNoteModal,
  toggleInfoModal,
  showFundingModal,
  toggleFundingModal,
  updateGoal,
}) {
  useSuperAccountErrorModal();

  const { t } = useTranslation();

  const showEmptyRecurringInvestment =
    !showMainLoader &&
    (isRecurringEmpty || editGoal || changePlan) &&
    !showFrequencyChangeState &&
    !showChangeGoalNameState;

  const showFrequencySetTable =
    !showMainLoader &&
    hasRecurringDeposit &&
    (!hasGoals || isSuper) &&
    !changePlan &&
    !showFrequencyChangeState &&
    !showChangeGoalNameState;

  const showGoalSetTable =
    !showMainLoader &&
    hasRecurringDeposit &&
    hasGoals &&
    !editGoal &&
    !showFrequencyChangeState &&
    !showChangeGoalNameState &&
    !isSuper;

  return (
    <div className="recurring-investment">
      <RecurringInvestmentBanner
        showFrequencyChangeState={showFrequencyChangeState}
        isSuper={isSuper}
        toggleHelpCard={toggleHelpCard}
        invAmount={invAmount}
        startDate={startDate}
        currentGoal={currentGoal}
        frequencyDay={frequencyDay}
        currentBalance={currentBalance}
        currentFrequency={currentFrequency}
        recurringDepositSettings={recurringDepositSettings}
        changeCurrentFrequency={changeCurrentFrequency}
      />

      {showEmptyRecurringInvestment && (
        <EmptyRecurring
          isDependentUser={checkIsDependentUser(user)}
          isSuper={isSuper}
          hasGoals={hasGoals}
          editGoal={editGoal}
          invAmount={invAmount}
          changePlan={changePlan}
          currentGoal={currentGoal}
          targetAmount={targetAmount}
          changeGoalName={changeGoalName}
          currentGoalName={currentGoalName}
          changeInvAmount={changeInvAmount}
          currentFrequency={currentFrequency}
          isRecurringEmpty={isRecurringEmpty}
          changeTargetAmount={changeTargetAmount}
          savingGoalCondition={savingGoalCondition}
          toggleChangeGoalState={toggleChangeGoalState}
          handleChangeSavingGoal={handleChangeSavingGoal}
          showFrequencyChangeState={showFrequencyChangeState}
          toggleChangeFrequencyState={toggleChangeFrequencyState}
        />
      )}

      {showFrequencySetTable && (
        <FrequencySetTable
          isSuper={isSuper}
          showLoader={showLoader}
          setChangePlan={setChangePlan}
          stopInvestment={stopInvestment}
          toggleStopModal={toggleStopModal}
          recurringDepositSettings={recurringDepositSettings}
        />
      )}

      {showGoalSetTable && (
        <GoalSetTable
          user={user}
          currentGoal={currentGoal}
          setEditGoal={setEditGoal}
          deleteSavingGoal={deleteSavingGoal}
          recurringDepositSettings={recurringDepositSettings}
        />
      )}

      {!showMainLoader && showFrequencyChangeState && (
        <FrequencyChange
          startDate={startDate}
          frequencyDay={frequencyDay}
          changeStartDate={changeStartDate}
          currentFrequency={currentFrequency}
          changeFrequencyDay={changeFrequencyDay}
          changeCurrentFrequency={changeCurrentFrequency}
        />
      )}

      {!showMainLoader && showChangeGoalNameState && (
        <GoalNameChange
          changeGoalName={changeGoalName}
          currentGoalName={currentGoalName}
          toggleChangeGoalState={toggleChangeGoalState}
        />
      )}

      {renderBtnContainer({
        showMainLoader,
        isRecurringEmpty,
        changePlan,
        showFrequencyChangeState,
        showChangeGoalNameState,
        showLoader,
        isSuper,
        currentFrequency,
        createRecurringDepositSettings,
        updateGoal,
        editGoal,
        hasRecurringDeposit,
        t,
      })}

      {!showMainLoader && isOpenErrorModal && (
        <RecurringModal
          isOpen={isOpenErrorModal}
          onRequestClose={() => toggleErrorModal(false)}
          handleClick={() => toggleErrorModal(false)}
          modalFooterClassName="-single-btn"
          modalTitle={
            currentFrequency && frequencyDay ? t('common.oops') : null
          }
          modalContent={
            currentErrorMessage.charAt(0).toUpperCase() +
            currentErrorMessage.slice(1)
          }
        />
      )}

      {!showMainLoader && isOpenInfoModal && (
        <RecurringModal
          isOpen={isOpenInfoModal}
          onRequestClose={() => toggleInfoModal(false)}
          handleClick={createRecurringDepositSettings}
          modalContent={t(
            'recurringInvestmentPage.recurringModal.description.noteImportant',
          )}
          modalFooter={
            <DeprecatedButton
              title={t('common.cancel')}
              buttonClass="button__basic-transparent -black"
              handleClick={() => toggleInfoModal(false)}
            />
          }
          modalClassName="time-investment__modal"
        />
      )}

      {!showMainLoader && isOpenStopModal && (
        <RecurringModal
          isOpen={isOpenStopModal && !showLoader}
          onRequestClose={() => toggleStopModal(false)}
          handleClick={stopInvestment}
          modalTitle={t('recurringInvestmentPage.recurringModal.stopRecurring')}
          modalContent={t(
            'recurringInvestmentPage.recurringModal.description.sureWantInvest',
            {
              amount: new Intl.NumberFormat(CURRENCY_LOCALE, {
                style: 'currency',
                currency: CURRENCY_NAME,
              }).format(invAmount),
              day: t(`common.frequencies.${currentFrequency}`),
            },
          )}
          modalFooter={
            <DeprecatedButton
              handleClick={() => toggleStopModal(false)}
              buttonClass="button__basic-transparent -black"
              title={t('common.cancel')}
            />
          }
          modalClassName="time-investment__modal"
        />
      )}

      {!showMainLoader && isOpenNoteModal ? (
        <RecurringNoteModal
          isOpenNoteModal={isOpenNoteModal}
          showLoader={showLoader}
          toggleNoteModal={toggleNoteModal}
          changeState={changeState}
          isSuper={isSuper}
        />
      ) : null}

      {!showMainLoader && isOpenHelpCards && (
        <HelpCardsModalAU
          toggleHelpCard={toggleHelpCard}
          isOpenHelpCards={isOpenHelpCards}
          currentPage={RECURRINGINVESTMENT}
        />
      )}

      {!showMainLoader && showFundingModal && (
        <FundingModal
          showFundingModal={showFundingModal}
          toggleFundingModal={toggleFundingModal}
        />
      )}

      {showMainLoader && <Loader />}
    </div>
  );
}

RecurringInvestmentView.defaultProps = {
  currentGoal: {},
  frequencyDay: null,
  currentBalance: 0,
  invAmount: null,
  startDate: null,
  isOpenNoteModal: false,
  isOpenErrorModal: false,
};

RecurringInvestmentView.propTypes = {
  frequencyDay: PropTypes.number,
  isOpenNoteModal: PropTypes.bool,
  currentGoal: PropTypes.shape({}),
  isOpenErrorModal: PropTypes.bool,
  currentBalance: PropTypes.number,
  invAmount: PropTypes.number,
  isSuper: PropTypes.bool.isRequired,
  editGoal: PropTypes.bool.isRequired,
  hasGoals: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date),
  changePlan: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  updateGoal: PropTypes.func.isRequired,
  setEditGoal: PropTypes.func.isRequired,
  setChangePlan: PropTypes.func.isRequired,
  stopInvestment: PropTypes.func.isRequired,
  changeGoalName: PropTypes.func.isRequired,
  targetAmount: PropTypes.number.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  isOpenInfoModal: PropTypes.bool.isRequired,
  changeInvAmount: PropTypes.func.isRequired,
  toggleNoteModal: PropTypes.func.isRequired,
  toggleInfoModal: PropTypes.func.isRequired,
  toggleStopModal: PropTypes.func.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  isOpenStopModal: PropTypes.bool.isRequired,
  changeStartDate: PropTypes.func.isRequired,
  toggleErrorModal: PropTypes.func.isRequired,
  deleteSavingGoal: PropTypes.func.isRequired,
  isRecurringEmpty: PropTypes.bool.isRequired,
  currentGoalName: PropTypes.string.isRequired,
  changeFrequencyDay: PropTypes.func.isRequired,
  changeTargetAmount: PropTypes.func.isRequired,
  currentFrequency: PropTypes.string.isRequired,
  hasRecurringDeposit: PropTypes.bool.isRequired,
  savingGoalCondition: PropTypes.bool.isRequired,
  showChangeGoalNameState: PropTypes.bool.isRequired,
  toggleChangeGoalState: PropTypes.func.isRequired,
  currentErrorMessage: PropTypes.string.isRequired,
  changeCurrentFrequency: PropTypes.func.isRequired,
  handleChangeSavingGoal: PropTypes.func.isRequired,
  showFrequencyChangeState: PropTypes.bool.isRequired,
  toggleChangeFrequencyState: PropTypes.func.isRequired,
  recurringDepositSettings: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
  }).isRequired,
  createRecurringDepositSettings: PropTypes.func.isRequired,
  showFundingModal: PropTypes.bool.isRequired,
  toggleFundingModal: PropTypes.func.isRequired,
};

export default RecurringInvestmentView;
