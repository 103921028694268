import styled from '@emotion/styled';

import { BUTTON_SIZE } from 'pages/HistoryDetails/components/styles';
import { BaseButton } from 'components/elements/Deprecated__Button';

interface ISkipButtonProps {
  isButtonVisible: boolean;
}
export const SkipButton = styled(BaseButton)<ISkipButtonProps>`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.mirage};
    border: 2px solid ${theme.colors.alto};
    font-family: ${theme.fontFamily.medium};
  `}
  width: ${BUTTON_SIZE}px;
  text-transform: capitalize;
  margin: 0 auto;
  display: ${({ isButtonVisible }) => (isButtonVisible ? 'block' : 'none')};
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  padding: 30px 0 30px;
`;

export const LoadingModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.dustyGray};
  padding: 14px 0 0 14px;
`;
