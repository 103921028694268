import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import translates from './translates/reducers';
import user from './user/reducers';
import rewards from './rewards/reducers';
import fundingAccount from './fundingAccount/reducers';
import history from './history/reducers';
import invest from './invest/reducers';
import spendingAnalytics from './spendingAnalytics/reducers';
import gifts from './gifts/reducers';
import perfomance from './perfomance/reducers';
import superAnnuation from './superAnnuation/reducers';
import dependencyUsers from './dependentUser/reducers';
import portfolio from './portfolio/reducers';
import modals from './modals/reducers';
import referrals from './referrals/reducers';
import home from './home/reducers';
import { api } from './api';
import migration from './migration/reducers';
import firebaseConfig from './firebaseConfig/reducers';
import dividends from './dividends/reducers';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  form: formReducer,
  translates,
  user,
  rewards,
  fundingAccount,
  history,
  invest,
  spendingAnalytics,
  gifts,
  superAnnuation,
  perfomance,
  dependencyUsers,
  portfolio,
  modals,
  referrals,
  home,
  migration,
  firebaseConfig,
  dividends,
});

export default rootReducer;
