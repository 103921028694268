import { Component } from 'react';
import PropTypes from 'prop-types';

import SuperInformationView from './SuperInformationView';

export default class SuperInformation extends Component {
  render() {
    return <SuperInformationView />;
  }
}

SuperInformation.defaultProps = {
  history: {},
};

SuperInformation.propTypes = {
  history: PropTypes.shape({}),
};
