import { IUseStatementsContentProps } from '../types';

import { useIsStatementsNested } from 'pages/Statements/hooks/useIsStatementsNested';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectStatementType, selectStatements } from 'store/invest/selectors';

export const useStatementsContent = ({
  currentType,
}: IUseStatementsContentProps) => {
  const statementType = useAppSelector(selectStatementType);

  const isStatementsNested = useIsStatementsNested({
    currentType,
  });

  const statements =
    useAppSelector(selectStatements({ isStatementsNested })) || [];

  return {
    statementType,
    statements,
  };
};
