import styled from '@emotion/styled';

import { ChangeStatusWrapper, StatusWrapper } from '../styles';

export const ChangeStatusSkeletonWrapper = styled(ChangeStatusWrapper)`
  padding-bottom: 20px;
`;

export const StatusSkeletonWrapper = styled(StatusWrapper)`
  padding-top: 4px;
`;
