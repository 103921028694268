import React from 'react';
import PropTypes from 'prop-types';
import 'react-rangeslider/lib/index.css';

import KidCardClosed from './KidCardClosed';
import KidCardOpened from './KidCardOpened';
import { KidCardWrapper } from './styles';

function KidCard({ kid, toggleInvestNow }) {
  return (
    <KidCardWrapper
      to={`/raiz-kids/edit/profile/${kid.id}`}
      isClosed={kid.closed}
      isInvestingEligible={false}
      key={kid.id}
    >
      {kid.closed ? (
        <KidCardClosed kid={kid} />
      ) : (
        <KidCardOpened kid={kid} toggleInvestNow={toggleInvestNow} />
      )}
    </KidCardWrapper>
  );
}

export default KidCard;

KidCard.defaultProps = {
  kid: {},
};

KidCard.propTypes = {
  kid: PropTypes.shape({
    id: PropTypes.string,
    closed: PropTypes.bool,
    investing_eligible: PropTypes.bool,
    date_of_birth: PropTypes.string,
    icon_url: PropTypes.string,
    name: PropTypes.string,
    current_balance: PropTypes.number,
    portfolio: PropTypes.shape({
      name: PropTypes.string,
    }),
    summary: PropTypes.shape({
      invested_by_you: PropTypes.shape({
        value: PropTypes.number,
      }),
      reinvested_dividends: PropTypes.shape({
        value: PropTypes.number,
      }),
      gain_loss: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_found_money_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      total_referrals_rewarded: PropTypes.shape({
        value: PropTypes.number,
      }),
      withdrawals: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    recurring: PropTypes.shape({
      frequency: PropTypes.string,
      amount: PropTypes.number,
      day: PropTypes.string,
    }),
  }),
  toggleInvestNow: PropTypes.func.isRequired,
};
