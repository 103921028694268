import { useTranslation } from 'react-i18next';

import { Title, WrapperRegistration, SubtitleRegistration } from './styles';

import { IBank } from 'store/banks';

interface IBankGridHeaderRegistrationProps {
  selectedBank: IBank | null;
}

export const BankGridHeaderRegistration = ({
  selectedBank,
}: IBankGridHeaderRegistrationProps) => {
  const { t } = useTranslation();

  return (
    <WrapperRegistration>
      <Title>{t('settingsPage.bankGridHeader.title')}</Title>

      {!selectedBank && (
        <SubtitleRegistration>
          {t('settingsPage.bankGridHeader.subTitle')}
        </SubtitleRegistration>
      )}
    </WrapperRegistration>
  );
};
