import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import {
  ACCOUNT_REGISTRATION,
  INVESTMENTS_REGISTRATION,
  PERSONAL_REGISTRATION,
  SUPER_FUND_REGISTRATION,
} from '../../../constants/comonConstants';

import { KIDS_STATUS } from 'constants/kidsConstants';
import { selectDependentUserAccess } from 'store/user/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

// TODO: Replace Deprecated__ProgressBar with new Stepper - https://acornsau.atlassian.net/browse/RAIZ-5616

/**
 * @deprecated Use components/elements/Stepper
 */

class Deprecated__ProgressBar extends Component {
  componentDidMount() {
    const progressBarQuery = document.querySelector('.progress-state');

    const progressFirstElement = document.querySelector(
      '.progress-state > div span',
    );

    const progressFirstElementLeft = progressFirstElement
      ? progressFirstElement.offsetLeft
      : 0;

    if (progressFirstElementLeft) {
      progressBarQuery.style.marginLeft = `-${progressFirstElementLeft}px`;
    }
  }

  render() {
    const {
      activeStep,
      isFromInvestments,
      isWithdrawal,
      spendingClass,
      fundingClass,
      stepName,
      isFromMyKad,
      t,
      dependentUserAccess,
    } = this.props;

    const isWithdraw = isWithdrawal ? 'active' : '';

    const isFromInvesting =
      isFromInvestments || activeStep === INVESTMENTS_REGISTRATION
        ? 'already'
        : '';

    const isFromStep =
      isFromMyKad ||
      isFromInvestments ||
      (MY_APP &&
        activeStep === ACCOUNT_REGISTRATION &&
        stepName !== 'uploadId') ||
      (!MY_APP && activeStep === ACCOUNT_REGISTRATION) ||
      activeStep === INVESTMENTS_REGISTRATION
        ? 'already'
        : '';

    const isPendingConverting =
      dependentUserAccess?.status === KIDS_STATUS.pending_converting;

    const isFundingVisible = !MY_APP || !isPendingConverting;

    const isWithdrawalVisible = MY_APP && !isPendingConverting;

    return (
      <div className="top-part">
        <div
          className={`progress-state ${MY_APP ? '-my-progress' : ''} ${
            MY_APP && isPendingConverting ? 'pending-converting' : ''
          }`}
        >
          {AU_APP &&
            sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
              'superFund' && (
              <div
                className={`${
                  activeStep === SUPER_FUND_REGISTRATION ? 'active' : 'already'
                }`}
              >
                {t('registrationPage.progressBar.superFundAccount')}

                <div className="dot">
                  <div className="dot-line -left" />

                  <div className="dot-line -right" />
                </div>
              </div>
            )}

          {!MY_APP && isFromInvestments && (
            <div className="already">
              {t('registrationPage.progressBar.spendingAccount')}

              <div className="dot">
                <div className="dot-line -left" />

                <div className="dot-line -right" />
              </div>
            </div>
          )}

          {!MY_APP && !isFromInvestments && (
            <div
              className={
                activeStep === PERSONAL_REGISTRATION ||
                activeStep === ACCOUNT_REGISTRATION ||
                activeStep === INVESTMENTS_REGISTRATION
                  ? 'already'
                  : spendingClass
              }
            >
              {t('registrationPage.progressBar.roundUp')}

              <div className="dot">
                <div className="dot-line -left" />

                <div className="dot-line -right" />
              </div>
            </div>
          )}

          {isFundingVisible && (
            <div
              className={
                isFromMyKad ||
                isFromInvestments ||
                activeStep === PERSONAL_REGISTRATION ||
                (MY_APP &&
                  activeStep === ACCOUNT_REGISTRATION &&
                  stepName !== 'uploadId') ||
                (!MY_APP && activeStep === ACCOUNT_REGISTRATION) ||
                activeStep === INVESTMENTS_REGISTRATION
                  ? 'already'
                  : fundingClass
              }
            >
              {t('registrationPage.progressBar.fundingAccount')}

              <div className="dot">
                <div className="dot-line -left" />

                <div className="dot-line -right" />
              </div>
            </div>
          )}

          {isWithdrawalVisible && (
            <div
              className={
                isFromMyKad ||
                isFromInvestments ||
                activeStep === PERSONAL_REGISTRATION ||
                (MY_APP &&
                  activeStep === ACCOUNT_REGISTRATION &&
                  stepName !== 'uploadId') ||
                (!MY_APP && activeStep === ACCOUNT_REGISTRATION) ||
                activeStep === INVESTMENTS_REGISTRATION
                  ? 'already'
                  : isWithdraw
              }
            >
              {t('registrationPage.progressBar.withdrawal')}

              <div className="dot">
                <div className="dot-line -left" />

                <div className="dot-line -right" />
              </div>
            </div>
          )}

          {((AU_APP &&
            sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) !==
              'superFund') ||
            !AU_APP) && (
            <div
              className={`${
                activeStep === PERSONAL_REGISTRATION
                  ? `active ${
                      stepName === 'address' ||
                      stepName === 'account-questions' ||
                      stepName === 'tax-resident'
                        ? '-in-progress'
                        : ''
                    }`
                  : isFromStep
              }`}
            >
              {t('registrationPage.progressBar.personal')}

              <div className="dot">
                <div className="dot-line -left" />

                <div className="dot-line -right" />
              </div>
            </div>
          )}

          <div
            className={`${
              (MY_APP &&
                activeStep === ACCOUNT_REGISTRATION &&
                stepName !== 'uploadId') ||
              (!MY_APP && activeStep === ACCOUNT_REGISTRATION) ||
              (sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
                'superFund' &&
                stepName === 'account-questions') ||
              (isFromMyKad && isWithdrawal)
                ? 'active'
                : isFromInvesting
            }`}
          >
            {t('common.other')}

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>

          <div
            className={`${
              (isFromInvestments && !isWithdrawal) ||
              activeStep === INVESTMENTS_REGISTRATION
                ? 'active'
                : ''
            }`}
          >
            {t('registrationPage.progressBar.investment')}

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dependentUserAccess: selectDependentUserAccess(state),
});

/**
 * @deprecated Use components/elements/Stepper
 */

export default connect(mapStateToProps)(
  withTranslation()(Deprecated__ProgressBar),
);

Deprecated__ProgressBar.propTypes = {
  activeStep: PropTypes.string,
  isFromInvestments: PropTypes.bool,
  isWithdrawal: PropTypes.bool,
  spendingClass: PropTypes.string,
  fundingClass: PropTypes.string,
  stepName: PropTypes.string,
  isFromMyKad: PropTypes.bool,
  t: PropTypes.func.isRequired,
  dependentUserAccess: PropTypes.shape({
    status: PropTypes.string,
  }),
};

Deprecated__ProgressBar.defaultProps = {
  activeStep: '',
  fundingClass: '',
  spendingClass: '',
  stepName: '',
  isFromInvestments: false,
  isWithdrawal: false,
  isFromMyKad: false,
  dependentUserAccess: {},
};
