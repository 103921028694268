import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';

import { REWARDS_ADDITIONAL_PAGES } from '../consts';

import RewardsMenuLinks from './RewardsMenuLinks';

export default function RewardsMenu({ checkToggle }) {
  const isRewardsActivePath = (activePath) => {
    const path = window.location.pathname;

    const isMainPageMatch = REWARDS_ADDITIONAL_PAGES.some(
      (additionalPagePath) => matchPath(additionalPagePath, path),
    );

    if (isMainPageMatch && activePath === '/rewards') {
      return isMainPageMatch;
    }

    if (activePath) {
      return path === activePath;
    }

    return matchPath('rewards/*', path);
  };

  return (
    <div
      className={`page-menu -sub-menu -orange ${
        isRewardsActivePath() ? '-active' : ''
      }`}
    >
      <RewardsMenuLinks
        checkToggle={checkToggle}
        isRewardsActivePath={isRewardsActivePath}
      />
    </div>
  );
}

RewardsMenu.propTypes = {
  checkToggle: PropTypes.func.isRequired,
};
