import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';

import { useSuperPdsDocumentQuery } from 'store/acceptanceDocumentsApi/acceptanceDocumentsApi';
import { PATHS } from 'constants/paths';
import { selectTermsDocument } from 'store/user/selectors';

export const useTermsFileUrl = () => {
  const termsDocument = useSelector(selectTermsDocument);
  const isSuperPds = useMatch(PATHS.migration.account.superPds);

  const { data } = useSuperPdsDocumentQuery(undefined, {
    skip: !isSuperPds,
  });

  const { url } = termsDocument;

  return isSuperPds ? data?.acceptance_document?.url : url;
};
