import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INVESTMENT_REGISTRATION } from '../../../store/dependentUser/types';
import RecurringInvestment from '../../RaizKidsEdit/components/RecurringInvestment/RecurringInvestment';
import { getLeftPartIconClassName } from '../utils';

import KidsInitialInvestment from './KidsInitialInvestment';
import ProgressBar from './ProgressBar';

function RaizInvestment({
  stepName,
  showLoader,
  goBack,
  handleSubmit,
  changeOneTimeInvestmentValue,
  oneTimeInvestmentValue,
  allowTypeInvestment,
  changeAccessInvestment,
  changeUnlimitedAmount,
  unlimAmount,
}) {
  const { t } = useTranslation();
  let question = '';
  let stepClass = 'step-one';
  let contentFillTitle = '';

  switch (stepName) {
    case 'initial':
      stepClass = 'step-account form-info';

      contentFillTitle = t('raizKidsRegistrationPage.title');

      question = (
        <KidsInitialInvestment
          isOpenInvestment
          allowType={allowTypeInvestment}
          changeAccessInvestment={changeAccessInvestment}
          changeOneTimeInvestmentValue={changeOneTimeInvestmentValue}
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          showLoader={showLoader}
          unlimAmount={unlimAmount}
          changeUnlimitedAmount={changeUnlimitedAmount}
          handleSubmit={handleSubmit}
          goBack={goBack}
          isKidsRegistration
        />
      );

      break;
    case 'recurring':
      stepClass = 'step-account form-info';

      contentFillTitle = t(
        'raizKidsRegistrationPage.stepTitle.recurringInvestment',
      );

      question = <RecurringInvestment />;

      break;
    default:
  }

  return (
    <div className="registration-page raiz-kids__registration -kids">
      <div className={stepClass}>
        <div className="left-part">
          <div className="part-content">
            <div className="logo -kids" />

            <div className="title" />

            <div className={getLeftPartIconClassName()} />

            <div className="bottom-text">
              {t('raizKidsRegistrationPage.kidsSimplyWay')}
            </div>
          </div>
        </div>

        <div className="right-part">
          <ProgressBar
            stepName={stepName}
            activeStep={INVESTMENT_REGISTRATION}
          />

          <div className="bottom-part">
            <div className="content-fill">
              <h1>{contentFillTitle}</h1>
            </div>

            {question}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaizInvestment;

RaizInvestment.propTypes = {
  stepName: PropTypes.string.isRequired,
  showLoader: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeOneTimeInvestmentValue: PropTypes.func.isRequired,
  oneTimeInvestmentValue: PropTypes.number.isRequired,
  unlimAmount: PropTypes.number.isRequired,
  allowTypeInvestment: PropTypes.string.isRequired,
  changeAccessInvestment: PropTypes.func.isRequired,
  changeUnlimitedAmount: PropTypes.func.isRequired,
};
