import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Firebase from 'firebase/app';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../../store/user/actions';
import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import Loader from '../../../components/elements/Loader/Loader';

import ProfileForm from './Forms/ProfileForm';
import FinancialSuitability from './Forms/FinancialSuitability';
import ProfileHeader from './Forms/components/ProfileHeader';

import { getMinPhoneNumberLenght } from 'utils/formatters/getMinPhoneNumberLength';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class Profile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showNetworthInfo: false,
      showMore: false,
      phoneNumber: '',
      isDisabledTfn: false,
      isShowModalError: false,
      isShowModalSuccess: false,
    };

    this.showLoader = this.showLoader.bind(this);

    this.toggleModalError = this.toggleModalError.bind(this);

    this.toggleSuccessModal = this.toggleSuccessModal.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    Firebase.analytics().logEvent('Account_Profile');

    const { actions } = this.props;

    actions.getUserData().then(() => {
      if (this._isMounted) {
        this.setUserDefaults();
      }
    });

    if (AU_APP || MY_APP) {
      actions.getQuestions();
    }

    if (AU_APP && sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser)) {
      actions.getSMSFDetails();

      actions.getSMSFMembers();
    }

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { errorMessage } = this.props;

    if (prevState.errorMessageState !== errorMessage) {
      this.toggleModalError(true);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errorMessage !== prevState.errorMessage) {
      return { errorMessageState: nextProps.errorMessage };
    }

    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCloseModalError() {
    const { actions } = this.props;

    actions.resetProps();

    this.setState({ isShowModalError: false });
  }

  setUserDefaults() {
    const { user } = this.props;

    if (user && user.phone_number) {
      this.setState({ phoneNumber: user.phone_number });
    }
  }

  handleChange = (e) => {
    const { user } = this.props;

    if (e.target.type === 'checkbox') {
      user.us_citizen = e.target.checked;
    } else if (e.target.name === 'phone_number') {
      this.setState({ phoneNumber: e.target.value });
    }
  };

  handleSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      const { actions, t } = this.props;
      const { phoneNumber } = this.state;
      const formData = values;
      const minPhoneNumberLength = !AU_APP ? getMinPhoneNumberLenght() : 0;

      formData.phone_number = phoneNumber.replace('-', '');

      if (phoneNumber === '') {
        this.setState({ errorMessagePhone: t('fields.validation.required') });
      } else if (
        (AU_APP && phoneNumber.includes('_')) ||
        (MY_APP && phoneNumber.length <= minPhoneNumberLength)
      ) {
        this.setState({ errorMessagePhone: t('fields.validation.invalid') });
      } else {
        this.setState({ errorMessagePhone: '' });

        if (!hasValidationErrors) {
          this.showLoader(true);

          if (formData.exempt) {
            delete formData.ssn;

            delete formData.exempt;

            formData.tfn_status = 'exempt';

            this.setState({ isDisabledTfn: false });
          }

          actions.updateProfile(formData).then(() => {
            this.showLoader(false);

            this.toggleSuccessModal(true);
          });
        }
      }
    }
  };

  clickOnTfnExemption = (e) => {
    this.setState({ isDisabledTfn: e.target.checked });
  };

  showLoader = (condition) => {
    this.setState({ showLoader: condition });
  };

  toggleNetworthInfo = (condition) => {
    this.setState({
      showNetworthInfo: condition,
    });
  };

  toggleModalError(condition) {
    this.setState({
      isShowModalError: condition,
    });
  }

  toggleSuccessModal(condition) {
    this.setState({
      isShowModalSuccess: condition,
    });
  }

  render() {
    const { user, errorMessage } = this.props;

    const {
      showLoader,
      errorMessagePhone,
      showMore,
      isDisabledTfn,
      isShowModalError,
      showNetworthInfo,
      isShowModalSuccess,
    } = this.state;

    if (!user?.email) {
      return <Loader />;
    }

    return (
      <div>
        <ProfileHeader />

        <FinancialSuitability />

        <ProfileForm
          toggleNetworthInfo={this.toggleNetworthInfo}
          showNetworthInfo={showNetworthInfo}
          user={user}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          errorMessage={errorMessage}
          errorMessagePhone={errorMessagePhone}
          showLoader={showLoader}
          showMore={showMore}
          clickOnTfnExemption={this.clickOnTfnExemption}
          isDisabledTfn={isDisabledTfn}
          isShowModalError={isShowModalError}
          isShowModalSuccess={isShowModalSuccess}
          toggleSuccessModal={this.toggleSuccessModal}
          handleCloseModalError={this.handleCloseModalError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  errorMessage: state.user.errorMessage,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Profile));

Profile.defaultProps = {
  user: {},
  history: {},
  state: {},
  errorMessage: '',
};

Profile.propTypes = {
  actions: PropTypes.shape({
    getUserData: PropTypes.func,
    getQuestions: PropTypes.func,
    getScurityQuestion: PropTypes.func,
    getSMSFDetails: PropTypes.func,
    getSMSFMembers: PropTypes.func,
    resetProps: PropTypes.func,
    updateProfile: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
    phone_number: PropTypes.string,
    email: PropTypes.string,
    city: PropTypes.string,
    us_citizen: PropTypes.bool,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  state: PropTypes.shape({}),
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};
