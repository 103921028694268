import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

Modal.setAppElement('#root');

function WaitSkipModal({
  hasBackdrop,
  isVisible,
  isOpen,
  shouldCloseOnOverlayClick,
  waitHandleClick,
  skipHandleClick,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={hasBackdrop}
      isVisible={isVisible}
      isOpen={isOpen}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-text">
          {t('settingsPage.waitSkipModal.message')}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            title={t('common.wait')}
            type="button"
            buttonClass="wait"
            handleClick={waitHandleClick}
          />

          <DeprecatedButton
            title={t('common.skip')}
            type="button"
            buttonClass="skip"
            handleClick={skipHandleClick}
          />
        </div>
      </div>
    </Modal>
  );
}

WaitSkipModal.propTypes = {
  hasBackdrop: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool.isRequired,
  waitHandleClick: PropTypes.func.isRequired,
  skipHandleClick: PropTypes.func.isRequired,
};

export default WaitSkipModal;
