import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import StepAuth from './components/StepAuth';
import Account from './components/Account';
import Investments from './components/Investments';
import Personal from './components/Personal';
import BankAccount from './components/BankAccount';
import SuperFundAccount from './components/SMSFAccount';
import KidsSign from './components/KidsSign';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { selectPortfolioLocation } from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function RegistrationView({
  login,
  errorMessage,
  stepType,
  stepName,
  user,
  stepAccount,
  handleClick,
  showActiveInput,
  show,
  toggleShow,
  viewport,
  mapOnSelected,
  addressOnChange,
  addressMapBox,
  goBack,
  loginData,
  handleChangeLogin,
  handleChangeAgreement,
  toggleShowPassword,
  isShowPassword,
  handleChangeInfo,
  firstName,
  lastName,
  phoneNumber,
  activeFieldInfo,
  handleSubmitInfo,
  errorMessagePhone,
  goToRegistration,
  goToLogin,
  showLoader,
  isShowErrorModal,
  isSpending,
  showDotsLoader,
  addressData,
  validEmail,
  validConfirmEmail,
  isShowErrorInvestingModal,
  compleeteRegistration,
  isFromInvestments,
  handleCloseModal,
  isShowBankModalError,
  handleSubmitAddress,
  handleSubmitAccountQuestion,
  handleSubmitAgreement,
  history,
  userFormData,
  errorMessageMyKad,
  onChangeGender,
  showResidentInfoModal,
  isShowResidentInfoModal,
  handleSubmitTaxResident,
  taxResidentData,
  countryList,
  isWithdrawal,
  dob,
  handleChangeDate,
  dobError,
  validPassword,
  isInvite,
  currentInviteCode,
  showStepAccountError,
  stepInvestments,
  linkFunding,
  stateMessages,
  showSignUpPromo,
  togglePertanyaanInfo,
  showPertanyaanInfo,
  toggleKTPInfo,
  showKTPInfo,
  isKidsRegistration,
  isCalendarActive,
  otherInvestmentValue,
  isLinkWithdrawalAccountModalOpen,
  isProPortfolioEnabled,
}) {
  let registrationStep = '';
  const { t } = useTranslation();

  const { isPortfolioCustomization } = useAppSelector(selectPortfolioLocation);

  switch (stepType) {
    case 'account':
      registrationStep = (
        <Account
          isLinkWithdrawalAccountModalOpen={isLinkWithdrawalAccountModalOpen}
          handleSubmit={stepAccount}
          showStepAccountError={showStepAccountError}
          showLoader={showLoader}
          stepName={stepName}
          errorMessage={errorMessage}
          handleClick={handleClick}
          showActiveInput={showActiveInput}
          goBack={goBack}
          handleChangeAgreement={handleChangeAgreement}
          handleSubmitAgreement={handleSubmitAgreement}
          isPortfolioCustomization={isPortfolioCustomization}
          isProPortfolioEnabled={isProPortfolioEnabled}
        />
      );

      break;
    case 'personal':
      registrationStep = (
        <Personal
          togglePertanyaanInfo={togglePertanyaanInfo}
          toggleKTPInfo={toggleKTPInfo}
          showPertanyaanInfo={showPertanyaanInfo}
          showKTPInfo={showKTPInfo}
          showSignUpPromo={showSignUpPromo}
          countryList={countryList}
          taxResidentData={taxResidentData}
          isShowResidentInfoModal={isShowResidentInfoModal}
          showResidentInfoModal={showResidentInfoModal}
          userFormData={userFormData}
          onChangeGender={onChangeGender}
          showLoader={showLoader}
          stepName={stepName}
          errorMessage={errorMessage}
          show={show}
          toggleShow={toggleShow}
          viewport={viewport}
          mapOnSelected={mapOnSelected}
          addressOnChange={addressOnChange}
          addressMapBox={addressMapBox}
          goBack={goBack}
          handleChangeInfo={handleChangeInfo}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          activeFieldInfo={activeFieldInfo}
          handleSubmitInfo={handleSubmitInfo}
          errorMessagePhone={errorMessagePhone}
          errorMessageMyKad={errorMessageMyKad}
          addressData={addressData}
          handleSubmitTaxResident={handleSubmitTaxResident}
          handleSubmitAddress={handleSubmitAddress}
          handleSubmitAccountQuestion={handleSubmitAccountQuestion}
          user={user}
          dob={dob}
          handleChangeDate={handleChangeDate}
          dobError={dobError}
          isCalendarActive={isCalendarActive}
        />
      );

      break;
    case 'investments':
      registrationStep = (
        <Investments
          handleSubmit={stepInvestments}
          stepName={stepName}
          showLoader={showLoader}
          errorMessage={errorMessage}
          handleClick={handleClick}
          showActiveInput={showActiveInput}
          goBack={goBack}
          showStepAccountError={showStepAccountError}
          isShowErrorInvestingModal={isShowErrorInvestingModal}
          handleCloseModal={handleCloseModal}
          compleeteRegistration={compleeteRegistration}
          linkFunding={linkFunding}
          otherInvestmentValue={otherInvestmentValue}
        />
      );

      break;
    case 'banks':
      registrationStep = (
        <BankAccount
          stepName={stepName}
          errorMessage={errorMessage}
          handleClick={handleClick}
          goBack={goBack}
          isSpending={isSpending}
          isFromInvestments={isFromInvestments}
          history={history}
          isWithdrawal={isWithdrawal}
        />
      );

      break;
    case 'super-fund':
      registrationStep = (
        <SuperFundAccount
          handleSubmit={stepAccount}
          stepName={stepName}
          goBack={goBack}
          showLoader={showLoader}
          errorMessage={errorMessage}
        />
      );

      break;
    default:
      registrationStep = isKidsRegistration ? (
        <KidsSign isKidsRegistration={isKidsRegistration} />
      ) : (
        <StepAuth
          isInvite={isInvite}
          currentInviteCode={currentInviteCode}
          validEmail={validEmail}
          validConfirmEmail={validConfirmEmail}
          validPassword={validPassword}
          login={login}
          showLoader={showLoader}
          showDotsLoader={showDotsLoader}
          errorMessage={errorMessage}
          user={user}
          activeFieldInfo={activeFieldInfo}
          loginData={loginData}
          handleChangeLogin={handleChangeLogin}
          toggleShowPassword={toggleShowPassword}
          isShowPassword={isShowPassword}
          goToRegistration={goToRegistration}
          goToLogin={goToLogin}
          stateMessages={stateMessages}
        />
      );
  }

  registrationStep = (
    <div>
      {registrationStep}

      {errorMessage !== '' && !isShowBankModalError && (
        <Modal
          hasBackdrop={false}
          isVisible={false}
          isOpen={isShowErrorModal}
          contentLabel="modal-alert"
          shouldCloseOnOverlayClick
          className="modal-basic bank__alert"
        >
          <div className="modal-alert-content">
            {errorMessage !== '' && (
              <div className="alert-text">{errorMessage}</div>
            )}

            {!isShowErrorInvestingModal && (
              <div className="error-buttons">
                <DeprecatedButton
                  title={t('common.ok')}
                  type="button"
                  handleClick={handleCloseModal}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );

  return registrationStep;
}

RegistrationView.defaultProps = {
  errorMessage: '',
  validEmail: false,
  validConfirmEmail: false,
  validPassword: false,
  otherInvestmentValue: '',
};

RegistrationView.propTypes = {
  login: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  stepType: PropTypes.string,
  stepName: PropTypes.string,
  stepAccount: PropTypes.func.isRequired,
  stepInvestments: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  onChangeQuestions: PropTypes.func,
  toggleShow: PropTypes.func,
  addressOnChange: PropTypes.func,
  addressMapBox: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  loginData: PropTypes.shape({}).isRequired,
  taxResidentData: PropTypes.shape({}).isRequired,
  handleChangeLogin: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showDotsLoader: PropTypes.bool.isRequired,
  isInvite: PropTypes.bool.isRequired,
  validEmail: PropTypes.bool,
  validConfirmEmail: PropTypes.bool,
  validPassword: PropTypes.bool,
  toggleShowPassword: PropTypes.func.isRequired,
  handleChangeInfo: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  activeFieldInfo: PropTypes.string.isRequired,
  handleSubmitInfo: PropTypes.func.isRequired,
  errorMessagePhone: PropTypes.string.isRequired,
  currentInviteCode: PropTypes.string.isRequired,
  errorMessageMyKad: PropTypes.string.isRequired,
  goToRegistration: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
  handleSubmitTaxResident: PropTypes.func.isRequired,
  handleSubmitAddress: PropTypes.func.isRequired,
  userFormData: PropTypes.shape({}).isRequired,
  addressData: PropTypes.shape({}).isRequired,
  compleeteRegistration: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  showPertanyaanInfo: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  handleSubmitAccountQuestion: PropTypes.func.isRequired,
  handleSubmitAgreement: PropTypes.func.isRequired,
  togglePertanyaanInfo: PropTypes.func.isRequired,
  toggleKTPInfo: PropTypes.func.isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dob: PropTypes.instanceOf(Date).isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  dobError: PropTypes.bool.isRequired,
  linkFunding: PropTypes.func.isRequired,
  stateMessages: PropTypes.shape({}).isRequired,
  isCalendarActive: PropTypes.bool.isRequired,
  otherInvestmentValue: PropTypes.string,
};
