import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  margin-bottom: 32px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 25px 20px 17px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.mirage};
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: ${({ theme }) => theme.fontSizes['5md']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0;
`;

export const Divider = styled.hr`
  height: 1px;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.mirage};
  font-family: ${({ theme }) => theme.fontFamily.textMedium};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin: 0;
  opacity: ${({ theme }) => theme.opacity.mediumTransparent};
  padding: 14px 16px;
`;

export const SettingsButton = styled.div`
  margin-left: auto;
`;
