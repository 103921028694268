/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Modal from 'react-modal';
import { useTranslation, Trans } from 'react-i18next';

import { REGISTRATION_ACCOUNT_PORTFOLIO } from '../../../store/commonTypes';
import { REQUIRED } from '../../../constants/errors';
import {
  CURRENCY_SYMBOL,
  MY_APP,
  AU_APP,
} from '../../../constants/localeConfigs';
import CheckboxFF from '../../../components/elements/CheckboxFF/CheckboxFF';
import FinalFormInput from '../../../components/elements/FinalFormInput/FinalFormInput';
import { MYS_MIN_INVESTMENT_AMOUNT } from '../const';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';
import { BAHASA } from 'constants/comonConstants';

const MIN_INVESTMENT_AMOUNT = MY_APP ? MYS_MIN_INVESTMENT_AMOUNT : 0;

const useValidate = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.investmentname) {
      errors.investmentname = REQUIRED;
    }

    if (Number(values['investment-other']) < MIN_INVESTMENT_AMOUNT) {
      errors['investment-other'] = t('fields.validation.minAmount', {
        amount: formatNumber({
          value: MIN_INVESTMENT_AMOUNT,
        }),
      });
    }

    return errors;
  };

  return { validate };
};

const currentLang = localStorage.getItem('lang');

const choices = [
  { id: '5000', text: `${CURRENCY_SYMBOL}5,000` },
  { id: '1000', text: `${CURRENCY_SYMBOL}1,000` },
  { id: '100', text: `${CURRENCY_SYMBOL}100` },
  { id: '50', text: `${CURRENCY_SYMBOL}50` },
  { id: '20', text: `${CURRENCY_SYMBOL}20` },
  {
    id: 'other',
    text:
      MY_APP && currentLang === BAHASA ? 'Masukkan Jumlah' : 'Enter an Amount',
    textInput: true,
    textPlaceholder: '',
  },
];

const Fields = ({
  choice,
  handleClick,
  showActiveInput,
  otherInvestmentValue,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <CheckboxFF
        name="investmentname"
        className=""
        id={choice.id}
        defaultChecked={choice.id === showActiveInput}
        type="radio"
        label={
          choice.id === 'other' ? (
            <Trans i18nKey="common.other" t={t} />
          ) : (
            <span>{choice.text}</span>
          )
        }
        onClick={handleClick}
        value={choice.id}
      />

      {showActiveInput === choice.id && choice.textInput && (
        <FinalFormInput
          defaultValue={otherInvestmentValue}
          isNumberOnly
          name="investment-other"
          label={choice.text || ''}
        />
      )}
    </div>
  );
};

export default function Investment({
  handleSubmit,
  errorMessage,
  handleClick,
  showActiveInput,
  goBack,
  showLoader,
  isShowErrorInvestingModal,
  handleCloseModal,
  compleeteRegistration,
  showStepAccountError,
  linkFunding,
  otherInvestmentValue,
}) {
  const { t } = useTranslation();
  const { validate } = useValidate();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
          onChange={(e) => handleClick(e)}
        >
          <div className={'"form-field radio'}>
            <div className="form-field__sub-input-link">
              <div className="radio-grouped">
                {choices.map((choice) => (
                  <Fields
                    key={choice.id}
                    choice={choice}
                    handleClick={handleClick}
                    showActiveInput={showActiveInput}
                    otherInvestmentValue={otherInvestmentValue}
                  />
                ))}

                <div className="hide">{errorMessage}</div>

                {showStepAccountError && (
                  <div className="registration-page__error-msg">
                    {showStepAccountError}
                  </div>
                )}
              </div>

              {AU_APP && errorMessage !== '' && isShowErrorInvestingModal && (
                <Modal
                  hasBackdrop={false}
                  isVisible={false}
                  isOpen={isShowErrorInvestingModal}
                  contentLabel="modal-alert"
                  shouldCloseOnOverlayClick
                  className="modal-basic bank__alert"
                >
                  <div className="modal-alert-content">
                    {errorMessage !== '' && (
                      <div className="alert-text">{errorMessage}</div>
                    )}

                    <div className="error-buttons">
                      <DeprecatedButton
                        title={t('common.ok')}
                        type="button"
                        handleClick={handleCloseModal}
                      />
                    </div>
                  </div>
                </Modal>
              )}

              {MY_APP && errorMessage !== '' && isShowErrorInvestingModal && (
                <Modal
                  hasBackdrop={false}
                  isVisible={false}
                  isOpen={isShowErrorInvestingModal}
                  contentLabel="modal-alert"
                  shouldCloseOnOverlayClick
                  className="modal-basic bank__alert"
                >
                  <div className="modal-alert-content">
                    <div className="alert-title">
                      {t('registrationPage.investment.depositFailed')}
                    </div>

                    <div className="alert-text">
                      {errorMessage !== '' && (
                        <div className="alert-text">{errorMessage}</div>
                      )}
                    </div>

                    <div className="alert-buttons">
                      <DeprecatedButton
                        title={t('common.skip')}
                        type="button"
                        buttonClass="wait"
                        handleClick={compleeteRegistration}
                      />

                      <DeprecatedButton
                        title={t('registrationPage.investment.linkAnAccount')}
                        type="button"
                        buttonClass="skip"
                        handleClick={linkFunding}
                      />
                    </div>
                  </div>
                </Modal>
              )}

              <div className="buttons-part">
                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    title={t('common.back')}
                    buttonClass="button back"
                    handleClick={() => goBack(REGISTRATION_ACCOUNT_PORTFOLIO)}
                  />
                </div>

                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    title={t('common.skip')}
                    buttonClass="button back"
                    handleClick={compleeteRegistration}
                  />
                </div>

                <div className="form-field">
                  <DeprecatedButton
                    withLoader={showLoader}
                    type="submit"
                    title={t('common.next')}
                    buttonClass="button button--submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

Investment.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleClick: PropTypes.func,
  showLoader: PropTypes.bool,
  showStepAccountError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showActiveInput: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  isShowErrorInvestingModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  compleeteRegistration: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    .isRequired,
  linkFunding: PropTypes.func.isRequired,
  otherInvestmentValue: PropTypes.string,
};

Investment.defaultProps = {
  errorMessage: '',
  handleClick: {},
  showLoader: false,
  showStepAccountError: false,
  showActiveInput: '',
  otherInvestmentValue: '',
};
