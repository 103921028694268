import { useParams } from 'react-router';

import {
  FundItemCategory,
  FundItemSuperTitleName,
  FundItemTitleWrapper,
  FundItemSuperTitleWrapper,
} from './styles';

import { FUNDS_TAGS } from 'pages/Portfolio/subpages/PortfolioCustomization/consts';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPortfolioParams } from 'store/portfolio/selectors';

interface IFundItemTitleProps {
  name: string;
  category: string;
  openDetailsPage: () => void;
}

export default function FundItemTitle({
  name,
  category,
  openDetailsPage,
}: IFundItemTitleProps) {
  const { fundTag } = useParams();

  const { isSuper } = useAppSelector(selectPortfolioParams);

  if (fundTag === FUNDS_TAGS.stock) {
    return (
      <FundItemTitleWrapper onClick={openDetailsPage}>
        {name}

        <FundItemCategory>{category}</FundItemCategory>
      </FundItemTitleWrapper>
    );
  }

  if (isSuper) {
    return (
      <FundItemSuperTitleWrapper>
        <FundItemSuperTitleName onClick={openDetailsPage}>
          {name}
        </FundItemSuperTitleName>
      </FundItemSuperTitleWrapper>
    );
  }

  return (
    <FundItemTitleWrapper onClick={openDetailsPage}>
      {name}
    </FundItemTitleWrapper>
  );
}
