import styled from '@emotion/styled';

const WIDGETS_GAP = '20px';

export const HomeWidgetsWrapper = styled.div`
  display: flex;
  gap: ${WIDGETS_GAP};
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    display: inline;
  }
`;

export const HomeWidgetsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${WIDGETS_GAP};
  width: calc(50% - (${WIDGETS_GAP} / 2));

  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    width: 100%;
  }
`;
