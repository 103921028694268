import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/elements/Button';

function RoundUpsExtendedHelpCard({
  isExtendedHelpCardOpen,
  setIsExtendedHelpCardOpen,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isExtendedHelpCardOpen}
      onRequestClose={() => setIsExtendedHelpCardOpen(false)}
      contentLabel="time-investment-modal"
      shouldCloseOnOverlayClick
      className="modal-basic time-investment__modal time-investment__modal-quick  -help"
    >
      <div className="time-investment__modal-content">
        {t('roundUps.helpCard.wholeDollarDescription')}
      </div>

      <div className="time-investment__modal-footer -single-btn">
        <Button
          label={t('common.ok')}
          onClick={() => setIsExtendedHelpCardOpen(false)}
        />
      </div>
    </Modal>
  );
}

RoundUpsExtendedHelpCard.defaultProps = {
  isExtendedHelpCardOpen: false,
  setIsExtendedHelpCardOpen: () => {},
};

RoundUpsExtendedHelpCard.propTypes = {
  isExtendedHelpCardOpen: PropTypes.bool,
  setIsExtendedHelpCardOpen: PropTypes.func,
};

export default RoundUpsExtendedHelpCard;
