import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import type { SubmitErrorHandler } from 'react-hook-form';

import { useRecurringInvestmentSchema } from './useRecurringInvestmentSchema';
import { GOAL_NAME, INVESTMENT_GOAL_ENABLED_NAME } from './components/consts';
import { VIEW_MODE } from './consts';
import { useNavigateToRecurringInvestment } from './hooks/useNavigateToRecurringInvestment';
import { useInvestmentFrequency } from './hooks/useInvestmentFrequency';

import { displayErrorModal } from 'store/modals/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectChild,
  selectDependencyUsersLoading,
  selectInvestmentGoal,
} from 'store/dependentUser/selectors';
import {
  useCreateInvestmentGoalMutation,
  useUpdateInvestmentGoalMutation,
} from 'store/dependentUser/api';
import { dependencyUserRecurring } from 'store/dependentUser/actions';
import {
  CURRENCY,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
} from 'constants/comonConstants';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectUser } from 'store/user/selectors';

interface FormValues {
  [GOAL_NAME]: string;
}

export const useRecurringInvestmentForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { id, recurring, current_balance } = useAppSelector(selectChild);
  const user = useAppSelector(selectUser);
  const { amount, next_investment_at } = recurring || {};
  const isLoading = useAppSelector(selectDependencyUsersLoading);
  const schema = useRecurringInvestmentSchema();
  const navigateToRecurringInvestment = useNavigateToRecurringInvestment();

  const {
    currentFrequency,
    setCurrentFrequency,
    isFrequencyModalVisible,
    startDate,
    frequencyDay,
    toggleFrequencyModal,
  } = useInvestmentFrequency();

  const form = useForm({
    defaultValues: {
      recurringValue: amount,
      isInvestmentGoalEnabled: !!investmentGoal?.key,
      targetAmount: investmentGoal?.target_amount || 0,
      goalName: investmentGoal?.name || '',
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, watch, clearErrors } = form;

  const isInvestmentGoalEnabled = watch(INVESTMENT_GOAL_ENABLED_NAME);
  const [createGoal] = useCreateInvestmentGoalMutation();
  const [updateGoal] = useUpdateInvestmentGoalMutation();

  const onError: SubmitErrorHandler<FormValues> = (error) => {
    const errorMessage = error?.[GOAL_NAME]?.message || '';

    if (errorMessage) {
      dispatch(
        displayErrorModal({
          errorMessage,
        }),
      );

      clearErrors([GOAL_NAME]);
    }
  };

  const navigateToRecurringInvestmentPreview = () => {
    navigateToRecurringInvestment({ viewMode: VIEW_MODE.preview });
  };

  const onSubmit = handleSubmit((values) => {
    const recurringData = {
      recurringValue: values.recurringValue,
      frequency: currentFrequency,
      day: frequencyDay,
      start_date: startDate,
      userId: id,
    };

    if (isInvestmentGoalEnabled && values?.targetAmount < current_balance) {
      dispatch(
        displayErrorModal({
          errorMessage: t(
            'raizKidsEditPage.investmentGoal.validation.amountLessThanCurrentBalance',
          ),
        }),
      );

      return;
    }

    if (
      user?.alerts?.find(
        ({ type }: { type: string }) =>
          type === REGISTRATION_INCOMPLETE_LINKFUNDING,
      )
    ) {
      dispatch(
        displayErrorModal({
          errorMessage: t('recurringInvestmentPage.errors.linkFunding'),
        }),
      );

      return;
    }

    dispatch(dependencyUserRecurring(recurringData)).then(async () => {
      if (!currentFrequency) {
        dispatch(
          displayErrorModal({
            errorMessage: t('recurringInvestmentPage.errors.noFrequency'),
          }),
        );

        return;
      }

      if (isInvestmentGoalEnabled && values?.targetAmount) {
        if (investmentGoal?.key) {
          const updateGoalData = {
            dependent_user_id: id,
            additional_params: {
              target_amount: values.targetAmount,
              name: values.goalName,
              target_contribution: values.recurringValue,
              key: investmentGoal.key,
              frequency: currentFrequency,
            },
          };

          await updateGoal(updateGoalData)
            .unwrap()
            .then(() => {
              navigateToRecurringInvestmentPreview();
            });
        } else {
          const createGoalData = {
            dependent_user_id: id,
            additional_params: {
              target_contribution: values.recurringValue,
              frequency: currentFrequency,
              start_date: dayjs().format('YYYY-MM-DD'),
              target_amount: values.targetAmount,
              name: values.goalName,
              currency: CURRENCY,
              start_amount: current_balance,
            },
          };

          await createGoal(createGoalData)
            .unwrap()
            .then(() => {
              navigateToRecurringInvestmentPreview();
            });
        }
      } else {
        navigateToRecurringInvestmentPreview();
      }
    });
  }, onError);

  return {
    form,
    amount,
    startDate,
    frequencyDay,
    isFrequencyModalVisible,
    currentFrequency,
    onSubmit,
    isLoading,
    toggleFrequencyModal,
    setCurrentFrequency,
    nextInvestmentAt: next_investment_at,
  };
};
