import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import { Icon } from '../Icon';

import { BUTTON_VARIANTS } from './consts';

import { RaizTheme } from 'theme';

export type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'text'
  | 'textBlack'
  | 'initial';

interface IStyledButton {
  variant: TButtonVariant;
  iconPosition?: 'left' | 'right';
  customStyles?: string;
}

interface IStyledLink {
  $variant: TButtonVariant;
}

interface IThemeProps {
  theme: RaizTheme;
}

const defaultButtonStyles = ({ theme }: IThemeProps) => css`
  font-family: ${theme.fontFamily.textRegular};
  font-size: ${theme.fontSizes['5sm']};
  font-weight: ${theme.fontWeights.semibold};
  letter-spacing: ${theme.letterSpacing.tighter};
  line-height: ${theme.lineHeights.length.md};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 21px;
  border: 0;
  padding: 12px 20px;
`;

const getButtonVariantStyles = (variant: TButtonVariant, theme: RaizTheme) => {
  switch (variant) {
    case BUTTON_VARIANTS.primary:
      return `
        background-color: ${theme.colors.niagaraLight};
        color: ${theme.colors.white};

        &:hover {
          color: ${theme.colors.white};
          opacity: ${theme.opacity.almostOpaque};
        }
      `;
    case BUTTON_VARIANTS.secondary:
      return `
        background-color: ${theme.colors.white};
        color: ${theme.colors.niagaraLight};
        border: 1px solid ${theme.colors.lightGrey};

        &:hover {
          color: ${theme.colors.niagaraLight};
        }
      `;
    case BUTTON_VARIANTS.transparent:
      return `
        background-color: ${theme.colors.whiteTransparent};
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.white};

        &:hover {
          color: ${theme.colors.gray};

          &:disabled {
            color: ${theme.colors.white};
          }
        }
      `;
    case BUTTON_VARIANTS.text:
      return `
        background-color: ${theme.colors.transparent};
        color: ${theme.colors.niagara};

        &:hover {
          color: ${theme.colors.niagaraHover};

          &:disabled {
            color: ${theme.colors.niagara};
          }
        }
      `;
    case BUTTON_VARIANTS.textBlack:
      return `
        background-color: ${theme.colors.transparent};
        color: ${theme.colors.mirage};

        &:hover {
          opacity: ${theme.opacity.almostOpaque};
        }
      `;
    case BUTTON_VARIANTS.initial:
      return `
        background-color: initial;
        color: initial;

        &:hover {
          opacity: ${theme.opacity.opaque};

          &:disabled {
            color: initial;
          }
        }
      `;
    default:
      return null;
  }
};

export const StyledButton = styled.button<IStyledButton>`
  flex-direction: ${({ iconPosition }) =>
    iconPosition === 'right' ? 'row-reverse' : 'row'};

  ${defaultButtonStyles}

  ${({ variant, theme }) => getButtonVariantStyles(variant, theme)}

  ${({ customStyles }) => customStyles};
`;

export const StyledIcon = styled(Icon)`
  margin: 0 4px;
`;

export const StyledLink = styled(Link)<IStyledLink>`
  ${defaultButtonStyles}

  ${({ $variant, theme }) => getButtonVariantStyles($variant, theme)}
`;
