import { WidgetContent } from '../styles';

import PerformanceChart from 'components/PerformanceChart/PerformanceChart';
import { useFundId } from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundDetails/hooks';

export default function PerformanceWidget() {
  const id = useFundId();

  if (!id) {
    return null;
  }

  return (
    <WidgetContent>
      <PerformanceChart id={id} />
    </WidgetContent>
  );
}
