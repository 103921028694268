import React from 'react';
import Skeleton from 'react-skeleton-loader';

import {
  ResidentialPropertyFundItemWrapper,
  ResidentialPropertyFundAddress,
  ResidentialPropertyFundLocation,
  ResidentialPropertyFundDescription,
} from '../styles';

import {
  ImageSkeleton,
  ComfortWrapperSkeleton,
  BottomWrapperSkeleton,
  PriceSkeleton,
} from './styles';

function ResidentialPropertyFundsItemSkeleton() {
  return (
    <ResidentialPropertyFundItemWrapper>
      <ResidentialPropertyFundAddress>
        <Skeleton />
      </ResidentialPropertyFundAddress>

      <ResidentialPropertyFundLocation>
        <Skeleton />
      </ResidentialPropertyFundLocation>

      <ImageSkeleton>
        <Skeleton borderRadius="14px" />
      </ImageSkeleton>

      <ComfortWrapperSkeleton>
        <Skeleton width="52px" borderRadius="40px" />

        <Skeleton width="52px" borderRadius="40px" />

        <Skeleton width="52px" borderRadius="40px" />
      </ComfortWrapperSkeleton>

      <BottomWrapperSkeleton>
        <PriceSkeleton>
          <Skeleton />
        </PriceSkeleton>

        <ResidentialPropertyFundDescription>
          <Skeleton />
        </ResidentialPropertyFundDescription>
      </BottomWrapperSkeleton>
    </ResidentialPropertyFundItemWrapper>
  );
}

export default ResidentialPropertyFundsItemSkeleton;
