import { useDispatch } from 'react-redux';

import { HOMEPAGE } from 'constants/comonConstants';
import { FIREBASE_EVENTS } from 'constants/firebaseEvents';
import { setActiveHelpCard } from 'store/home/actions';
import { type IHelpCardTypes } from 'store/home/types';
import { logFirebaseEvent } from 'utils/logFirebaseEvent';

export const useActiveHelpCard = (helpCardType: IHelpCardTypes) => {
  const dispatch = useDispatch();

  const toggleHelpCard = (isHelpCardOpen: boolean) => {
    if (isHelpCardOpen) {
      dispatch(setActiveHelpCard(helpCardType));

      if (helpCardType === HOMEPAGE) {
        logFirebaseEvent(FIREBASE_EVENTS.homePageClicked, {
          eventKey: 'Tapped Home Help Icon',
        });
      }
    } else {
      dispatch(setActiveHelpCard(null));
    }
  };

  return { toggleHelpCard };
};
