import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import * as userActions from '../../../store/user/actions';
import { LOGIN } from '../../../constants/comonConstants';

import ChangePasswordForm from './Forms/ChangePasswordForm';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      isShowOldPassword: false,
      isShowNewPassword: false,
      isShowConfirmPassword: false,
    };

    this.toggleShowPasswordOld = this.toggleShowPasswordOld.bind(this);

    this.toggleShowPasswordNew = this.toggleShowPasswordNew.bind(this);

    this.toggleShowPasswordConfirm = this.toggleShowPasswordConfirm.bind(this);

    this.redirectToLogin = this.redirectToLogin.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getScurityQuestion();

    actions.getUserData();
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetProps();
  }

  handleSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const { actions, user } = this.props;

        this.setState({ showLoader: true });

        actions.verifyPasswords(values, user.question1).then(() => {
          this.setState({ showLoader: false });
        });
      }
    }
  };

  toggleShowPasswordOld() {
    const { isShowOldPassword } = this.state;

    this.setState({ isShowOldPassword: !isShowOldPassword });
  }

  toggleShowPasswordNew() {
    const { isShowNewPassword } = this.state;

    this.setState({ isShowNewPassword: !isShowNewPassword });
  }

  toggleShowPasswordConfirm() {
    const { isShowConfirmPassword } = this.state;

    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  }

  redirectToLogin() {
    window.location.href = LOGIN;
  }

  render() {
    const { user, errorMessage, changePasswordSuccess, isShowSuccessModal } =
      this.props;

    const {
      isShowOldPassword,
      isShowNewPassword,
      isShowConfirmPassword,
      showLoader,
    } = this.state;

    return (
      <div>
        <ChangePasswordForm
          showLoader={showLoader}
          handleSubmit={this.handleSubmit}
          errorMessage={errorMessage}
          user={user}
          changePasswordSuccess={changePasswordSuccess}
          toggleShowPasswordOld={this.toggleShowPasswordOld}
          toggleShowPasswordNew={this.toggleShowPasswordNew}
          toggleShowPasswordConfirm={this.toggleShowPasswordConfirm}
          isShowOldPassword={isShowOldPassword}
          isShowNewPassword={isShowNewPassword}
          isShowConfirmPassword={isShowConfirmPassword}
          isShowSuccessModal={isShowSuccessModal}
          redirectToLogin={this.redirectToLogin}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  errorMessage: state.user.errorMessage,
  changePassword: state.user.changePassword,
  changePasswordSuccess: state.user.changePasswordSuccess,
  isShowSuccessModal: state.user.isShowSuccessModal,
  state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

ChangePassword.defaultProps = {
  user: {},
  changePassword: {},
  state: {},
  errorMessage: '',
  changePasswordSuccess: false,
  isShowSuccessModal: false,
};

ChangePassword.propTypes = {
  actions: PropTypes.shape({
    resetProps: PropTypes.func,
    getScurityQuestion: PropTypes.func,
    verifyPasswords: PropTypes.func,
    getUserData: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    question1_value: PropTypes.string,
    question1: PropTypes.string,
  }),
  changePassword: PropTypes.shape({}),
  state: PropTypes.shape({}),
  errorMessage: PropTypes.string,
  changePasswordSuccess: PropTypes.bool,
  isShowSuccessModal: PropTypes.bool,
};
