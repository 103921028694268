import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { withTranslation } from 'react-i18next';

import messages from '../../../pages/RecurringInvestment/RecurringInvestment.i18n';
import {
  BAHASA,
  CURRENT_LANG,
  FREQUENCY,
  FRIDAY,
  ISNIN,
  JUMAAT,
  KHAMIS,
  MONDAY,
  RABU,
  SELASA,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from '../../../constants/comonConstants';
import FrequencyChange from '../../../pages/RecurringInvestment/components/FrequencyChange';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { MY_APP } from 'constants/localeConfigs';
import { MonthlyFrequencyLabel } from 'pages/RewardDetails/subpages/RecurringZakatPayment/components/styles';
import { formatNumber } from 'utils/formatters/formatNumber';

dayjs.extend(advancedFormat);

class FrequencyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFrequency: FREQUENCY[props.currentFrequency] || FREQUENCY.daily,
      frequencyDay: props.frequencyDay || null,
      startDate: props.startDate || null,
      isShowError: false,
    };

    this.changeCurrentFrequency = this.changeCurrentFrequency.bind(this);

    this.changeFrequencyDay = this.changeFrequencyDay.bind(this);

    this.changeStartDate = this.changeStartDate.bind(this);
  }

  changeCurrentFrequency(frequency, switcherChange = false) {
    const { toggleModal } = this.props;
    const { currentFrequency, frequencyDay, startDate } = this.state;

    if (!switcherChange) {
      this.setState({
        currentFrequency: frequency,
      });

      if (
        (frequency === FREQUENCY.monthly && frequencyDay) ||
        (frequency === FREQUENCY.fortnightly && startDate) ||
        (frequency === FREQUENCY.weekly && frequencyDay) ||
        frequency === FREQUENCY.daily
      ) {
        this.setState({
          isShowError: false,
        });

        toggleModal(false, currentFrequency, frequencyDay, startDate);
      } else {
        this.setState({
          isShowError: true,
        });
      }
    } else {
      this.setState({
        currentFrequency: frequency,
        frequencyDay: null,
        startDate: null,
      });
    }
  }

  changeFrequencyDay(day) {
    this.setState({ frequencyDay: day });
  }

  changeStartDate(date) {
    this.setState({ startDate: date });
  }

  render() {
    const {
      toggleModal,
      isOpenModal,
      recurringValue,
      t,
      isRecurringZakatPayment,
    } = this.props;

    const { currentFrequency, frequencyDay, startDate, isShowError } =
      this.state;

    const week =
      MY_APP && CURRENT_LANG === BAHASA
        ? [ISNIN, SELASA, RABU, KHAMIS, JUMAAT]
        : [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY];

    const tBasePath = isRecurringZakatPayment
      ? 'recurringZakatPaymentPage.recurringZakatPaymentForm'
      : 'raizKidsEditPage';

    return (
      <div>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => toggleModal(false)}
          contentLabel="time-investment-modal"
          shouldCloseOnOverlayClick
          className="modal-basic frequency-modal"
        >
          <DeprecatedButton
            buttonClass="button__close"
            handleClick={() => toggleModal(false)}
          />

          <div className="frequency-modal__header">
            <div className="frequency-modal__header-title">
              {t(`${tBasePath}.frequencyModal.title`)}
            </div>

            <div className="frequency-modal__header-sub">
              {currentFrequency === FREQUENCY.monthly &&
                frequencyDay &&
                t('raizKidsEditPage.frequencyModal.monthlyOn', {
                  day:
                    frequencyDay === -1
                      ? t('common.frequencies.lastDayOfMonth')
                      : dayjs().date(frequencyDay, 'Do').format('Do'),
                })}

              {currentFrequency === FREQUENCY.fortnightly &&
                startDate &&
                t('raizKidsEditPage.frequencyModal.fortnightlyOn', {
                  day: dayjs(startDate).format('dddd'),
                })}

              {currentFrequency === FREQUENCY.weekly &&
                frequencyDay &&
                t('common.frequencies.every', {
                  day: week[frequencyDay - 2],
                })}

              {currentFrequency === FREQUENCY.daily &&
                t('common.frequencies.everyDay')}

              {!startDate &&
                !frequencyDay &&
                currentFrequency !== FREQUENCY.daily &&
                t(`${tBasePath}.frequencyModal.description`)}
            </div>

            <div className="frequency-modal__header-sub-value">
              {formatNumber({
                value: recurringValue,
              })}
            </div>

            <div className="home__switcher recurring-investment__switcher">
              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() =>
                  this.changeCurrentFrequency(FREQUENCY.daily, true)
                }
                className={`home__switcher-item ${
                  currentFrequency === FREQUENCY.daily ? '-active' : ''
                }`}
              >
                {t('common.frequencies.daily')}
              </div>

              <div
                tabIndex="0"
                role="button"
                onKeyPress={() => {}}
                onClick={() =>
                  this.changeCurrentFrequency(FREQUENCY.weekly, true)
                }
                className={`home__switcher-item ${
                  currentFrequency === FREQUENCY.weekly ? '-active' : ''
                }`}
              >
                {t('common.frequencies.weekly')}
              </div>

              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() =>
                  this.changeCurrentFrequency(FREQUENCY.fortnightly, true)
                }
                className={`home__switcher-item ${
                  currentFrequency === FREQUENCY.fortnightly ? '-active' : ''
                }`}
              >
                {t('common.frequencies.fortnightly')}
              </div>

              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                onClick={() =>
                  this.changeCurrentFrequency(FREQUENCY.monthly, true)
                }
                className={`home__switcher-item ${
                  currentFrequency === FREQUENCY.monthly ? '-active' : ''
                }`}
              >
                {t('common.frequencies.monthly')}
              </div>
            </div>

            {isShowError && (
              <div className="frequency-modal__error">
                {t('raizKidsEditPage.frequencyModal.chooseFrequency')}
              </div>
            )}

            {isRecurringZakatPayment &&
              currentFrequency === FREQUENCY.monthly && (
                <MonthlyFrequencyLabel>
                  {t(
                    'recurringZakatPaymentPage.recurringZakatPaymentForm.frequencyModal.chooseDayOfTheMonth',
                  )}
                </MonthlyFrequencyLabel>
              )}
          </div>

          <div
            className={`frequency-modal__content ${
              currentFrequency === FREQUENCY.daily && '-without-content'
            }`}
          >
            <FrequencyChange
              currentFrequency={currentFrequency}
              messages={messages}
              changeCurrentFrequency={this.changeCurrentFrequency}
              frequencyDay={frequencyDay}
              changeFrequencyDay={this.changeFrequencyDay}
              changeStartDate={this.changeStartDate}
              startDate={startDate}
              isModal
            />
          </div>
        </Modal>
      </div>
    );
  }
}

FrequencyModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  recurringValue: PropTypes.number.isRequired,
  currentFrequency: PropTypes.string,
  startDate: PropTypes.string,
  frequencyDay: PropTypes.number,
  t: PropTypes.func.isRequired,
  isRecurringZakatPayment: PropTypes.bool,
};

FrequencyModal.defaultProps = {
  currentFrequency: '',
  startDate: '',
  frequencyDay: 0,
  isRecurringZakatPayment: false,
};

export default withTranslation()(FrequencyModal);
