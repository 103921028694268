import { getMinimumFractionDigits } from './getMinimumFractionDigits';

import {
  MAXIMUM_FRACTION_DIGITS,
  MINIMUM_FRACTION_DIGITS,
  MINIMUM_DISPLAY_VALUE,
  NUMBER_FORMATS,
} from 'utils/formatters/consts';
import { CURRENCY_LOCALE, CURRENCY_NAME } from 'constants/localeConfigs';

interface IFormatNumberProps {
  value?: number;
  type?: keyof typeof NUMBER_FORMATS;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  isWithSign?: boolean;
  isMinimumFractionDigitsForIntegerSkipped?: boolean;
}

export const formatNumber = ({
  value = 0,
  type = NUMBER_FORMATS.currency,
  minimumFractionDigits = MINIMUM_FRACTION_DIGITS,
  maximumFractionDigits = MAXIMUM_FRACTION_DIGITS,
  isWithSign,
  isMinimumFractionDigitsForIntegerSkipped,
}: IFormatNumberProps) => {
  const signDisplay = isWithSign ? 'exceptZero' : 'auto';

  if (type === NUMBER_FORMATS.percent) {
    return new Intl.NumberFormat(CURRENCY_LOCALE, {
      style: type,
      minimumFractionDigits: value === 0 ? 0 : minimumFractionDigits,
      maximumFractionDigits: value === 0 ? 0 : maximumFractionDigits,
      signDisplay,
    }).format(value / 100);
  }

  if (type === NUMBER_FORMATS.decimal) {
    return new Intl.NumberFormat(CURRENCY_LOCALE, {
      style: type,
      minimumFractionDigits,
      maximumFractionDigits,
      signDisplay,
    }).format(value);
  }

  if (
    type === NUMBER_FORMATS.currency &&
    value > 0 &&
    value < MINIMUM_DISPLAY_VALUE
  ) {
    const formattedValue = new Intl.NumberFormat(CURRENCY_LOCALE, {
      style: type,
      currency: CURRENCY_NAME,
      minimumFractionDigits: getMinimumFractionDigits({
        value,
        minimumFractionDigits,
        maximumFractionDigits,
        isMinimumFractionDigitsForIntegerSkipped,
      }),
      maximumFractionDigits,
    }).format(MINIMUM_DISPLAY_VALUE);

    return `< ${formattedValue}`;
  }

  return new Intl.NumberFormat(CURRENCY_LOCALE, {
    style: type,
    currency: CURRENCY_NAME,
    minimumFractionDigits: getMinimumFractionDigits({
      value,
      minimumFractionDigits,
      maximumFractionDigits,
      isMinimumFractionDigitsForIntegerSkipped,
    }),
    maximumFractionDigits,
    signDisplay,
  }).format(value);
};
