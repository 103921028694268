import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@emotion/react';

import { MOCK_SERVICE_WORKER_ENABLED } from './constants/comonConstants';
import configureAppStore from './store/configureStore';
import './config/date';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import './i18n';
import theme from './theme';
import { GlobalStyle } from './styles';

if (MOCK_SERVICE_WORKER_ENABLED && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { initMockServiceWorker } = require('./mocks/worker');

  initMockServiceWorker();
} else {
  // NOTE: Unregister all previously registered service workers to fix problems with caching
  window?.navigator?.serviceWorker?.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
  });
}

const store = configureAppStore();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <MetaTags>
        <meta property="cache-control" content="no-cache" />

        <meta property="expires" content="0" />

        <meta property="pragma" content="no-cache" />
      </MetaTags>

      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <App />
      </ThemeProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root'),
);
