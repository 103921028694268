import { useTranslation } from 'react-i18next';
import Skeleton from 'react-skeleton-loader';

import {
  ChangeStatusContent,
  StatusButtonsWrapper,
  Title,
  STATUS_BUTTON_SIZE,
} from '../styles';
import { StatusDate } from '../StatusDate';

import { ChangeStatusSkeletonWrapper, StatusSkeletonWrapper } from './styles';

export const StatusContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <ChangeStatusSkeletonWrapper>
      <ChangeStatusContent>
        <Title>{t('settingsPage.fundingAccountPage.changeStatus.title')}</Title>

        <StatusSkeletonWrapper>
          <Skeleton width="84px" height="30px" borderRadius="40px" />
        </StatusSkeletonWrapper>
      </ChangeStatusContent>

      <StatusButtonsWrapper>
        <Skeleton
          width={`${STATUS_BUTTON_SIZE}px`}
          height="44px"
          borderRadius="40px"
        />

        <Skeleton
          width={`${STATUS_BUTTON_SIZE}px`}
          height="44px"
          borderRadius="40px"
        />
      </StatusButtonsWrapper>

      <StatusDate />
    </ChangeStatusSkeletonWrapper>
  );
};
