import { useTranslation } from 'react-i18next';

import { HelpModalContentWrapper } from './styles';
import { HelpModalContentItem } from './HelpModalContentItem';

import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserAccountSummary } from 'store/history/selectors';

interface IWithdrawnContentProps {
  isKids: boolean;
}

export const WithdrawnContent = ({ isKids }: IWithdrawnContentProps) => {
  const { manual_withdrawals, transferred_out } = useAppSelector(
    selectUserAccountSummary({ isKids }),
  );

  const { t } = useTranslation();

  return (
    <HelpModalContentWrapper>
      <HelpModalContentItem
        label={t('homePage.homePastBanner.withdrawnByYou')}
        value={manual_withdrawals?.value}
      />

      <HelpModalContentItem
        label={t('homePage.homePastBanner.transferOut')}
        value={transferred_out?.value}
      />
    </HelpModalContentWrapper>
  );
};
