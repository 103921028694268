import { Controller, useFormContext } from 'react-hook-form';

import { EmojiWrapper, PromotedFiltersItem } from './styles';

import { IFundTag } from 'store/portfolio/types';

interface IFilterItemProps {
  filter: IFundTag;
}

export default function FilterItemPromoted({ filter }: IFilterItemProps) {
  const { control } = useFormContext();
  const { name, key, emoji } = filter;

  return (
    <Controller
      name={key}
      control={control}
      render={({ field }) => (
        <PromotedFiltersItem
          onClick={() => field.onChange(!field.value)}
          isSelected={field.value}
          {...field}
        >
          {emoji && <EmojiWrapper>{emoji}</EmojiWrapper>}

          {name}
        </PromotedFiltersItem>
      )}
    />
  );
}
