import HistoryDetails from '../pages/HistoryDetails/HistoryDetails.i18n';
import RoundUps from '../pages/RoundUps/RoundUps.i18n';
import RecurringWidget from '../components/layout/Widgets/RecurringWidget/RecurringWidget.i18n';
import RecurringInvestment from '../pages/RecurringInvestment/RecurringInvestment.i18n';
import modal from '../components/layout/Modals/Modal.i18n';

export default {
  ...modal,
  ...RoundUps,
  ...HistoryDetails,
  ...RecurringWidget,
  ...RecurringInvestment,
};
