export const fundsMock = [
  {
    id: 'bc54dee6-6690-4e10-8ca7-8c3567d5558f',
    name: 'AGL Energy Limited',
    symbol: 'AGL',
    initial_allocation: 0,
    max_allocation: 100,
    image_url:
      'https://img.freepik.com/premium-vector/bank-building-money-bank-financing-money-exchange-financial-services-atm-giving-out-money_625536-194.jpg?w=2000',
    current_price: 8.14,
    allocation: 0.5,
  },
  {
    id: '3943cce1-8f32-41c4-9ff9-0f8737e00090',
    name: 'BetaShares Global Cybersecurity',
    symbol: 'HACK',
    label: 'BetaShares Global Cybersecurity ETF',
    initial_allocation: 0,
    max_allocation: 100,
    image_url:
      'https://img.freepik.com/premium-vector/bank-building-money-bank-financing-money-exchange-financial-services-atm-giving-out-money_625536-194.jpg?w=2000',
    current_price: 7.93,
    allocation: 4.5,
  },
  {
    id: 'c9c694d2-ac5f-4921-8869-1deddd7f99a1',
    name: 'BetaShares Gold Bullion - Currency Hedged',
    symbol: 'QAU',
    initial_allocation: 0,
    max_allocation: 100,
    image_url:
      'https://img.freepik.com/premium-vector/bank-building-money-bank-financing-money-exchange-financial-services-atm-giving-out-money_625536-194.jpg?w=2000',
    current_price: 15.71,
    allocation: 8,
  },
];

export const fundDetails = {
  id: '6c3b13a0-20a6-438e-bb03-09be47d7ec2d',
  name: 'Betashares Aust High Int. Cash',
  symbol: 'AAA',
  description:
    'Roughly 0.1$ of every dollar invested will purchase the Betashares Aust High Int. Cash ETF (AAA). This ETF provides investors with exposure to bank deposits, providing regular income and a high level of capital security.',
  category: 'Cash',
  risk: 'Low',
  colour_background: '#2d89bd',
  colour_gradient: '#3399cc',
  label: 'Betashares Australian High Interest Cash',
  feature_holdings: [],
  initial_allocation: 100,
  max_allocation: 100,
  balancing: true,
  image_url:
    'https://acorns-au-uploads-development.s3.ap-southeast-2.amazonaws.com/uploads/6c3b13a0-20a6-438e-bb03-09be47d7ec2d-20221127_104726?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPDANCOVQQ76ES6O%2F20230116%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20230116T121456Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=352e75974a7fc90c56c13a464a1a39d6f87b80afeadd7c45ca39b6bc8d007df4',
  ytd_return: 0,
  current_price: 50.14,
  tags: ['etf'],
  top_holdings: [
    {
      percentage: 4,
      name: 'Forest',
      symbol: 'QWE',
      image_url:
        'https://acorns-au-uploads-development.s3.ap-southeast-2.amazonaws.com/uploads/d1140595-a3fc-4e2f-8e26-882da3503e41-20221129_193948?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWPDANCOVQQ76ES6O%2F20230116%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20230116T121456Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=69dfa6b135a3edde5a8ebaea5e9e5e57626bcd00e9360365a27adeaf79d17a91',
    },
  ],
  allocation: 0.5,
};

export const statistics = {
  prices: {
    current: 50.14,
    previous_close: 50.14,
    open: 50.14,
    day_change_amount: 0,
    day_change_percentage: 0,
    low: 50.14,
    high: 50.15,
  },
  volume: 115463,
  market_capital: null,
  net_assets: null,
  ytd_return: 0,
  wk_range: '-',
  dividend_yield: 0,
  dividend_pay_date: '',
};

export const fundProperties = {
  fund_properties: [
    {
      id: 'de31aa7b-6697-4b7e-9514-04bffc993160',
      address: 'Punchbowl Park, 49 Viola St, Punchbowl',
      city: 'Sydney',
      state: 'NSW',
      description:
        'Characterful freestanding house located in a suburb renowned for its convenience and proximity to all major amenities.',
      number_of_rooms: 9,
      number_of_bathrooms: 7,
      garage_capacity: 3,
      image_url:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBPgLMa6uz482uZn0593pFoMuW5lfwYLCnMBFJpY_BE0APUNPxrVB1o4DfHnkla-qsiWA&usqp=CAU',
      price: 45000.0,
    },
    {
      id: '7dcb5a1a-4191-4bd3-9abc-695fad28d90e',
      address: '63 Stafford Street',
      city: 'Stanmore',
      state: 'NSW',
      description:
        'A characterful freestanding house located in a suburb renowned for its convenience and proximity to all major amenities.',
      number_of_rooms: 3,
      number_of_bathrooms: 3,
      garage_capacity: 1,
      image_url:
        'https://images.squarespace-cdn.com/content/v1/587c13e903596eda8ac42980/1484529127618-PY3F3W1J5O87XLYJHX2D/Venture-Capital-1920x1080.jpg?format=2500w',
      price: 1755000.0,
    },
  ],
};

export const filtersCategories = {
  regions: [
    {
      name: 'Africa',
      key: 'africa',
      promoted: true,
      emoji: '',
    },
    {
      name: 'Asia',
      key: 'asia',
      promoted: false,
      emoji: '',
    },
    {
      name: 'Europe',
      key: 'europe',
      promoted: false,
      emoji: '',
    },
  ],
  sectors: [
    {
      name: 'Energy',
      key: 'energy',
      promoted: false,
      emoji: '',
    },
    {
      name: 'Industrials',
      key: 'industrials',
      promoted: false,
      emoji: '🥶',
    },
    {
      name: 'Materials',
      key: 'materials',
      promoted: false,
      emoji: '',
    },
    {
      name: 'Technology',
      key: 'technology',
      promoted: true,
      emoji: '',
    },
    {
      name: 'Telecoms',
      key: 'telecoms',
      promoted: false,
      emoji: '',
    },
  ],
  themes: [
    {
      name: 'Aggressive',
      key: 'aggressive',
      promoted: false,
      emoji: '',
    },
    {
      name: 'Defensive',
      key: 'defensive',
      promoted: true,
      emoji: '🤣',
    },
    {
      name: 'Growth',
      key: 'growth',
      promoted: false,
      emoji: '',
    },
    {
      name: 'Income',
      key: 'income',
      promoted: false,
      emoji: '😀',
    },
  ],
};
