import {
  CurrentPrice,
  InfoPanelWrapper,
  MarketChanges,
  MarketPercentage,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { selectPerformanceMarketChanges } from 'store/perfomance/selectors';
import { selectFundStatistics } from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export default function InfoPanel() {
  const marketChanges = useAppSelector(selectPerformanceMarketChanges);
  const { prices } = useAppSelector(selectFundStatistics);

  const { amount, percentage }: { amount: number; percentage: number } =
    marketChanges;

  return (
    <InfoPanelWrapper>
      <CurrentPrice>{formatNumber({ value: prices?.current })}</CurrentPrice>

      <MarketChanges>
        {formatNumber({
          value: amount,
          isWithSign: true,
          minimumFractionDigits: 2,
        })}

        <MarketPercentage>
          (
          {formatNumber({
            value: percentage,
            type: 'percent',
            isWithSign: true,
          })}
          )
        </MarketPercentage>
      </MarketChanges>
    </InfoPanelWrapper>
  );
}
