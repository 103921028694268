const gradients = {
  orange: 'linear-gradient(315deg, #F0B043 0%, #D89B26 100%)',
  dodgerBlue: 'linear-gradient(-49deg, #284ed8 0%, #141c56 100%)',
  green: 'linear-gradient(146.14deg, #d1d514 -11.46%, #10bfb5 100%)',
  portfolio: {
    pro: 'linear-gradient(136.81deg, #54606D -0.79%, #6D7680 44.91%, #1D2126 101.49%)',
  },
  greenishCyan: 'linear-gradient(116.57deg, #63de76 0%, #29ae87 100%)',
  lushMeadow: 'linear-gradient(117deg, #42ca58 0%, #29ae87 100%)',
  vibrantVerdant: 'linear-gradient(116.57deg, #42ca58 0%, #29ae87 100%)',
  fierySunshine: 'linear-gradient(135deg, #B92808 0%, #F4BC2C 100%)',
  neonLemonade: 'linear-gradient(198.23deg, #07c371 15.89%, #fff06c 104.23%)',
  purple: 'linear-gradient(-45deg, #c91f7e 0%, #634de2 100%)',
  niagaraConic:
    'conic-gradient(from 180deg at 50% 50%, #0AAD77 0deg, transparent 360deg)',
  purpleBlue: 'linear-gradient(135deg, #5f1493, #634de2)',
  sunsetBlaze: 'linear-gradient(135deg, #9F1100 0%, #FEB70C 100%)',
  mysticSunrise: 'linear-gradient(-45deg, #813cef 0%, #07c2ce 100%)',
  magentaNebula: 'linear-gradient(135deg, #c91f7e 0%, #634de2 100%)',
  goldenRuby: 'linear-gradient(#e7c14d, #ca535f)',
  azureNightfall: 'linear-gradient(#53aee4, #1018a7)',
  magentaMaroon: 'linear-gradient(#d65f88, #821955)',
  verdantOasis: 'linear-gradient(#cdf891, #3d8645)',
  crimsonMidnight: 'linear-gradient(#ae3a59, #1113a4)',
  emeraldEnchantment: 'linear-gradient(267.11deg, #2fb57c 0%, #3fc65f 106.53%)',
  lightGray: 'linear-gradient(142.39deg, #fff 0%, #888491 100%)',
  orangePurple: 'linear-gradient(136.81deg, #E79B55 -0.79%, #8779EC 101.49%)',
} as const;

export default gradients;
