import React from 'react';
import '../../styles/pages/settings.scss';
import PropTypes from 'prop-types';

import ProfileContent from './components/ProfileContent';

export default function SettingsView({
  history,
  supportStep,
  isNewRewards,
  step,
}) {
  return (
    <div className="settings__content">
      <ProfileContent
        step={step}
        history={history}
        supportStep={supportStep}
        isNewRewards={isNewRewards}
      />
    </div>
  );
}

SettingsView.propTypes = {
  step: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  supportStep: PropTypes.string.isRequired,
  isNewRewards: PropTypes.bool.isRequired,
};
