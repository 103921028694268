import { useTranslation } from 'react-i18next';

import {
  FundTotalWeightingWrapper,
  MaxWeightingLabel,
} from '../FundsList/styles';

import {
  FundMaxWeightingValue,
  FundTotalWeightingDetailsWrapper,
} from './styles';
import FundSuperTotal from './FundSuperTotal';

import { NUMBER_FORMATS } from 'utils/formatters/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectFundById,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

interface IFundMaxWeightingProps {
  id: string;
  isDetailsPage?: boolean;
}

export default function FundMaxWeighting({
  id,
  isDetailsPage,
}: IFundMaxWeightingProps) {
  const { t } = useTranslation();

  const { isSuper } = useAppSelector(selectPortfolioParams);

  const { max_allocation } =
    useAppSelector(selectFundById({ id, isSuper })) || {};

  if (!isSuper) {
    return null;
  }

  if (isDetailsPage) {
    return (
      <FundTotalWeightingDetailsWrapper>
        {t('portfolioCustomization.fundDetails.maxWeightingTitle')}

        <FundMaxWeightingValue>
          {formatNumber({
            value: max_allocation,
            type: NUMBER_FORMATS.percent,
          })}
        </FundMaxWeightingValue>
      </FundTotalWeightingDetailsWrapper>
    );
  }

  return (
    <FundTotalWeightingWrapper>
      <MaxWeightingLabel>
        {t('portfolioCustomization.fundTotalWeighting.maxWeightingLabel', {
          value: formatNumber({
            value: max_allocation,
            type: NUMBER_FORMATS.percent,
          }),
        })}
      </MaxWeightingLabel>

      <FundSuperTotal id={id} />
    </FundTotalWeightingWrapper>
  );
}
