import { useTranslation } from 'react-i18next';

import {
  AGGRESSIVE,
  BAHASA,
  CONSERVATIVE,
  MODERATE,
} from 'constants/comonConstants';
import { MY_APP } from 'constants/localeConfigs';

const PortfolioTitle = ({ portfolioType }) => {
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('lang');

  const isMyMy = MY_APP && currentLang === BAHASA;

  if (!isMyMy) return t('changePortfolioPage.thisPortfolio');

  switch (portfolioType) {
    case CONSERVATIVE:
      return t('changePortfolioPage.conservativePortfolio');
    case AGGRESSIVE:
      return t('changePortfolioPage.aggressivePortfolio');
    case MODERATE:
      return t('changePortfolioPage.moderatePortfolio');
    default:
      return t('changePortfolioPage.thisPortfolio');
  }
};

export default PortfolioTitle;
