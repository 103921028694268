import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Animate } from 'react-show';
import PropTypes from 'prop-types';
import '../../../styles/pages/invest.scss';
import Sticky from 'react-sticky-el';
import { useSelector } from 'react-redux';

import '../../../styles/layout/statements.scss';
import DeprecatedDotsLoader from '../../../components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import Time from '../../../components/elements/Time/Time';
import { HISTORY_SECTIONS } from '../consts';

import { AllocationItems } from './AllocationItems';
import selectRewardById from './selectors';
import {
  TransactionContent,
  LetYourFriendsButton,
  TransactionContentTitle,
  RebalanceAmount,
  TransactionTotal,
} from './styles';
import { Allocation } from './Allocation';
import { Reward } from './Reward';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { MY_APP } from 'constants/localeConfigs';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function TransactionItem({
  transactionDetails,
  isLoading,
  toggleShareRewardModal,
}) {
  const {
    id,
    type,
    allocations,
    related_rebalance,
    amount,
    created_at,
    shares_amount,
    type_help,
    title,
  } = transactionDetails;

  const reward = useSelector((state) => selectRewardById(state, id));
  const { offer, fulfillment_date } = reward || {};
  const { campaign_owner_name, description } = offer || '';
  const { t } = useTranslation();
  const [isRewardShareModalType, setIsRewardShareModalType] = useState(false);
  const [activeSection, setActiveSection] = useState(HISTORY_SECTIONS.buy);
  const isBuySectionActive = activeSection === HISTORY_SECTIONS.buy;
  const isSellSectionActive = activeSection === HISTORY_SECTIONS.sell;

  const buySharesAmount = amount === 0 ? shares_amount : amount;

  const sellSharesAmount =
    amount === 0 ? related_rebalance?.shares_amount : amount;

  const buyAmount = MY_APP ? related_rebalance?.shares_amount : buySharesAmount;
  const sellAmount = MY_APP ? amount : sellSharesAmount;

  useEffect(() => {
    setIsRewardShareModalType(type?.match(/reward|rewards/i));
  }, [type]);

  return (
    <div className="history-details">
      <div
        className={
          allocations?.length > 0
            ? 'history__right-part'
            : 'history__right-part history__right-part-single'
        }
      >
        {isLoading && <DeprecatedDotsLoader additionalClass="green" />}

        {id && !isLoading && (
          <Sticky
            className={
              related_rebalance && allocations?.length > 0 ? 'rebalans' : ''
            }
            topOffset={-65}
          >
            <TransactionContent>
              <TransactionContentTitle>
                {campaign_owner_name || title}
              </TransactionContentTitle>

              <div className="history__right-part-total">
                {formatNumber({
                  value: reward?.amount || amount,
                })}
              </div>

              <div className="history__right-part-date">
                <Time>{fulfillment_date || created_at}</Time>
              </div>

              {(allocations?.length > 0 ||
                related_rebalance?.allocations?.length > 0) && (
                <div>
                  {related_rebalance && id && (
                    <div className="table">
                      <div className="navigation">
                        <DeprecatedButton
                          title={t('common.buy')}
                          buttonClass={isBuySectionActive ? 'active' : ''}
                          handleClick={() =>
                            setActiveSection(HISTORY_SECTIONS.buy)
                          }
                        />

                        <DeprecatedButton
                          title={t('common.sell')}
                          buttonClass={isSellSectionActive ? 'active' : ''}
                          handleClick={() =>
                            setActiveSection(HISTORY_SECTIONS.sell)
                          }
                        />
                      </div>

                      <Animate
                        show={isBuySectionActive}
                        start={{
                          display: 'none',
                        }}
                        style={{
                          display: 'inline',
                        }}
                      >
                        <TransactionTotal>
                          <Trans
                            i18nKey="historyDetailsPage.transactionItem.buyAmount"
                            t={t}
                            values={{
                              amount: formatNumber({
                                value: buyAmount,
                              }),
                            }}
                            components={{ span: <RebalanceAmount /> }}
                          />
                        </TransactionTotal>

                        {!MY_APP && (
                          <div className="item table-header">
                            <div>
                              {t('historyDetailsPage.transactionItem.symbol')}
                            </div>

                            <div>
                              {t('historyDetailsPage.transactionItem.shares')}
                            </div>

                            <div>
                              {t('historyDetailsPage.transactionItem.amount')}
                            </div>
                          </div>
                        )}

                        {MY_APP &&
                          transactionDetails &&
                          related_rebalance?.allocations?.length > 0 && (
                            <div className="item table-header">
                              <div>
                                {t('historyDetailsPage.transactionItem.symbol')}
                              </div>

                              <div>{t('common.price')}</div>

                              <div>
                                {t('historyDetailsPage.transactionItem.shares')}
                              </div>

                              <div>
                                {t('historyDetailsPage.transactionItem.amount')}
                              </div>
                            </div>
                          )}

                        {!MY_APP &&
                          allocations.map((allocation) => (
                            <AllocationItems
                              key={allocation.symbol}
                              allocation={allocation}
                            />
                          ))}

                        {MY_APP &&
                          related_rebalance?.allocations.map((allocation) => (
                            <AllocationItems
                              key={allocation.symbol}
                              allocation={allocation}
                            />
                          ))}
                      </Animate>

                      <Animate
                        show={isSellSectionActive}
                        start={{
                          display: 'none',
                        }}
                        style={{
                          display: 'inline',
                        }}
                      >
                        <TransactionTotal>
                          <Trans
                            i18nKey="historyDetailsPage.transactionItem.sellAmount"
                            t={t}
                            values={{
                              amount: formatNumber({
                                value: sellAmount,
                              }),
                            }}
                            components={{ span: <RebalanceAmount /> }}
                          />
                        </TransactionTotal>

                        {!MY_APP && (
                          <div className="item table-header">
                            <div>
                              {t('historyDetailsPage.transactionItem.symbol')}
                            </div>

                            <div>
                              {t('historyDetailsPage.transactionItem.shares')}
                            </div>

                            <div>
                              {t('historyDetailsPage.transactionItem.amount')}
                            </div>
                          </div>
                        )}

                        {MY_APP && allocations?.length > 0 && (
                          <div className="item table-header">
                            <div>
                              {t('historyDetailsPage.transactionItem.symbol')}
                            </div>

                            <div>{t('common.price')}</div>

                            <div>
                              {t('historyDetailsPage.transactionItem.shares')}
                            </div>

                            <div>
                              {t('historyDetailsPage.transactionItem.amount')}
                            </div>
                          </div>
                        )}

                        {!MY_APP &&
                          related_rebalance?.allocations.map((allocation) => (
                            <AllocationItems
                              key={allocation.symbol}
                              allocation={allocation}
                            />
                          ))}

                        {MY_APP &&
                          allocations?.map((allocation) => (
                            <AllocationItems
                              key={allocation.symbol}
                              allocation={allocation}
                            />
                          ))}
                      </Animate>
                    </div>
                  )}

                  {related_rebalance === null && (
                    <>
                      <Reward />

                      <Allocation />
                    </>
                  )}
                </div>
              )}

              {description && (
                <div className="history__right-part-description">
                  {description}
                </div>
              )}

              {type_help && !campaign_owner_name && (
                <div className="history__right-part-description">
                  {type_help}
                </div>
              )}

              {isRewardShareModalType && (
                <LetYourFriendsButton
                  title={t('historyDetailsPage.transactionItem.letYourFriends')}
                  handleClick={toggleShareRewardModal}
                />
              )}
            </TransactionContent>
          </Sticky>
        )}
      </div>
    </div>
  );
}

TransactionItem.propTypes = {
  transactionDetails: PropTypes.shape({
    allocations: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    type: PropTypes.string,
    related_rebalance: PropTypes.number,
    amount: PropTypes.number,
    created_at: PropTypes.string,
    shares_amount: PropTypes.number,
    type_help: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleShareRewardModal: PropTypes.func.isRequired,
};
