import styled from '@emotion/styled';

import BankIcon from 'assets/images/icons/bank-icon.svg';

export const Card = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  width: 100%;
`;

export const Logo = styled.div<{
  source: React.FunctionComponent | string;
}>`
  background-image: url(${({ source }) => `${source || BankIcon}`});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  height: 100px;
  padding: 20px;
  width: 100%;
`;

export const Divider = styled.hr`
  background: ${({ theme }) => theme.colors.mercury};
  border: 0 none;
  box-shadow: ${({ theme }) => theme.shadows.none};
  height: 1px;
  margin: 0;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.codGray};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeights.length.lg};
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  overflow: hidden;
  padding: 8px 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
