import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import {
  OTHER_REGISTRATION,
  DEPENDENT,
  TRANSFER_IN_SUCCESS,
} from '../../../store/dependentUser/types';
import * as dependencyActions from '../../../store/dependentUser/actions';
import SuccessModal from '../../../components/layout/Modals/SuccessModal';
import { getLeftPartIconClassName } from '../utils';

import ProgressBar from './ProgressBar';
import KidsNotification from './KidsNotification';
import KidsAccess from './KidsAccess';
import TransferIn from './TransferIn';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class RaizOther extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      currentNotifications: [],
      manage: {
        manage_recurring_and_goals: false,
        manage_portfolio: false,
        rewards: false,
      },
      errorMessage: '',
      showCongratsModal: false,
    };

    this.handleChangeAccess = this.handleChangeAccess.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleSubmitAccess = this.handleSubmitAccess.bind(this);

    this.handleSubmitTransfer = this.handleSubmitTransfer.bind(this);

    this.toggleCongrantsModal = this.toggleCongrantsModal.bind(this);
  }

  componentDidMount() {
    const { actionsDependency } = this.props;

    const user = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
    );

    const userId = user.dependency_user.id;

    actionsDependency.getNotifications(userId).then(() => {
      const { notifications } = this.props;

      this.setState({
        currentNotifications: notifications,
      });
    });
  }

  handleChange(condition, key) {
    const { currentNotifications } = this.state;

    const index = currentNotifications.findIndex(
      (notification) => notification.key === key,
    );

    this.setState({
      currentNotifications: [
        ...currentNotifications.slice(0, index),
        { ...currentNotifications[index], value: condition },
        ...currentNotifications.slice(index + 1),
      ],
    });
  }

  handleChangeAccess(condition, key) {
    const { manage } = this.state;
    const manageProperty = { ...manage };

    manageProperty[key] = condition;

    this.setState({ manage: manageProperty });
  }

  handleSubmit(e) {
    const { actionsDependency } = this.props;
    const { currentNotifications } = this.state;
    const requestDate = {};

    currentNotifications.map((notification) => {
      requestDate[notification.key] = notification.value;

      return requestDate;
    });

    if (e.persist) {
      e.persist();

      e.preventDefault();

      const user = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
      );

      const userId = user.dependency_user.id;

      actionsDependency.updateNotifications(requestDate, userId);
    }
  }

  handleSubmitAccess(e, formObj) {
    const { actionsDependency } = this.props;
    const { manage } = this.state;

    const user = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
    );

    const userId = user.dependency_user.id;

    const requestData = {
      dependent_user_id: userId,
      dependent_user: manage,
    };

    if (e.persist) {
      e.persist();

      e.preventDefault();

      this.setState({ showLoader: true });

      actionsDependency
        .updateDependencyUser({ from: formObj }, requestData)
        .then(() => {
          this.setState({ showLoader: false });
        });
    }
  }

  handleSubmitTransfer(e, transferAmount) {
    const { actionsDependency, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      if (!transferAmount) {
        this.setState({
          errorMessage: t('raizKidsEditPage.transfer.error'),
        });
      } else {
        const user = JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsRegistration),
        );

        const userId = user.dependency_user.id;

        this.setState({ errorMessage: '' });

        const requestData = {
          dependent_user_id: userId,
          amount: transferAmount,
          target: DEPENDENT,
        };

        actionsDependency.transferIn(requestData).then(() => {
          const { type } = this.props;

          this.setState({ showLoader: false });

          if (type === TRANSFER_IN_SUCCESS) {
            this.toggleCongrantsModal(true);
          }
        });
      }
    }
  }

  toggleCongrantsModal(isOpen) {
    const { history } = this.props;

    this.setState({
      showCongratsModal: isOpen,
    });

    if (!isOpen) {
      history.push('/raiz-kids');
    }
  }

  render() {
    const { stepName, messages, goBack, user, t } = this.props;

    const {
      currentNotifications,
      showLoader,
      manage,
      errorMessage,
      showCongratsModal,
    } = this.state;

    let question = '';
    let stepClass = 'step-one';
    let contentFillTitle = '';

    switch (stepName) {
      case 'notification':
        stepClass = 'step-account form-info';

        contentFillTitle = t(
          'raizKidsRegistrationPage.other.notifications.title',
        );

        question = (
          <KidsNotification
            notifications={currentNotifications}
            handleChange={this.handleChange}
            showLoader={showLoader}
            handleSubmit={this.handleSubmit}
            goBack={goBack}
          />
        );

        break;
      case 'access':
        stepClass = 'step-account form-info';

        contentFillTitle = t('raizKidsRegistrationPage.other.access.title');

        question = (
          <KidsAccess
            manage={manage}
            handleChange={this.handleChangeAccess}
            showLoader={showLoader}
            handleSubmit={this.handleSubmitAccess}
            goBack={goBack}
          />
        );

        break;
      case 'transfer-in':
        stepClass = 'step-account form-info';

        contentFillTitle = t('raizKidsRegistrationPage.other.transferIn.title');

        question = (
          <TransferIn
            user={user}
            showLoader={showLoader}
            handleSubmit={this.handleSubmitTransfer}
            goBack={goBack}
            errorMessage={errorMessage}
          />
        );

        break;
      default:
    }

    return (
      <div className="registration-page raiz-kids__registration -kids">
        <div className={stepClass}>
          <div className="left-part">
            <div className="part-content">
              <div className="logo -kids" />

              <div className="title" />

              <div className={getLeftPartIconClassName(user)} />

              <div className="bottom-text">
                {t('raizKidsRegistrationPage.kidsSimplyWay')}
              </div>
            </div>
          </div>

          <div className="right-part">
            <ProgressBar stepName={stepName} activeStep={OTHER_REGISTRATION} />

            <div className="bottom-part">
              <div className="content-fill">
                <h1>{contentFillTitle}</h1>
              </div>

              {question}
            </div>
          </div>
        </div>

        {showCongratsModal && (
          <SuccessModal
            messages={messages}
            modalMessage={t(
              'raizKidsRegistrationPage.investmentSuccess.description',
            )}
            modalTitle={t('raizKidsRegistrationPage.investmentSuccess.title')}
            isOpenModal={showCongratsModal}
            toggleModal={this.toggleCongrantsModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  notifications: state.dependencyUsers.notifications,
  user: state.user.user,
  type: state.dependencyUsers.type,
});

const mapDispatchToProps = (dispatch) => ({
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RaizOther));

RaizOther.propTypes = {
  stepName: PropTypes.string.isRequired,
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  goBack: PropTypes.func.isRequired,
  actionsDependency: PropTypes.objectOf(PropTypes.func),
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
};

RaizOther.defaultProps = {
  actionsDependency: {},
  notifications: [],
  user: {},
  history: {},
  type: '',
};
