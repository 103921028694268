import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import withRouter from '../../../../routes/withRouter';
import * as actionsUser from '../../../../store/user/actions';
import * as spendingAnalyticsActions from '../../../../store/spendingAnalytics/actions';
import {
  ERRLINKING_FUNDING_ACCOUNT,
  REGISTRATION_INCOMPLETE_LINKFUNDING,
} from '../../../../constants/comonConstants';
import { checkIsDependentUser } from '../../../../utils/user';

import RecurringWidgetView from './RecurringWidgetView';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class RecurringWidget extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      invAmount: 0,
      targetAmount: 0,
      showFundingModal: false,
      isRejectionModalOpen: false,
    };

    this.openRecurringPage = this.openRecurringPage.bind(this);

    this.toggleFundingModal = this.toggleFundingModal.bind(this);

    this.toggleKidsAccessRejectionModal =
      this.toggleKidsAccessRejectionModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { userActions, actions } = this.props;

    userActions.getUserData();

    actions.getGoals().then(() => {
      if (this._isMounted) {
        const { goals } = this.props;

        if (goals.payload && goals.payload[0]) {
          const currentGoal = goals.payload[0].goal;

          this.setState({ targetAmount: currentGoal.target_amount });
        }
      }
    });

    actions.getRecurringDepositSettings().then(() => {
      if (this._isMounted) {
        const { recurringDepositSettings } = this.props;

        this.setState({ invAmount: recurringDepositSettings.amount });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleKidsAccessRejectionModal(condition) {
    this.setState({ isRejectionModalOpen: condition });
  }

  openRecurringPage() {
    const { history, user } = this.props;

    const hasAccess = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.kidsAccess),
    );

    if (
      checkIsDependentUser(user) &&
      hasAccess &&
      !hasAccess.manage_recurring_and_goals
    ) {
      this.setState({ isRejectionModalOpen: true });
    } else if (
      (user && !user.alerts) ||
      (user &&
        user.alerts &&
        !user.alerts.find(
          (alertMsg) =>
            alertMsg.type === REGISTRATION_INCOMPLETE_LINKFUNDING ||
            alertMsg.type === ERRLINKING_FUNDING_ACCOUNT,
        ))
    ) {
      history.push('/recurring-investment');
    } else {
      this.toggleFundingModal(true);
    }
  }

  toggleFundingModal(condition) {
    this.setState({ showFundingModal: condition });
  }

  render() {
    const { user } = this.props;

    const { targetAmount, invAmount, showFundingModal, isRejectionModalOpen } =
      this.state;

    return (
      <RecurringWidgetView
        isDependentUser={checkIsDependentUser(user)}
        toggleFundingModal={this.toggleFundingModal}
        showFundingModal={showFundingModal}
        isRejectionModalOpen={isRejectionModalOpen}
        openRecurringPage={this.openRecurringPage}
        toggleKidsAccessRejectionModal={this.toggleKidsAccessRejectionModal}
        targetAmount={targetAmount}
        invAmount={invAmount}
        currentBalance={user.current_balance || 0}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  goals: state.spendingAnalytics.goals,
  fundingAccount: state.fundingAccount.accountData,
  errorMessage: state.spendingAnalytics.errorMessage,
  recurringDepositSettings: state.spendingAnalytics.recurringDepositSettings,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(actionsUser, dispatch),
  actions: bindActionCreators(spendingAnalyticsActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecurringWidget),
);

RecurringWidget.defaultProps = {
  user: {},
  goals: {},
  history: {},
  actions: {},
  userActions: {},
  fundingActions: {},
  fundingAccount: {},
  recurringDepositSettings: {},
};

RecurringWidget.propTypes = {
  actions: PropTypes.shape({
    getGoals: PropTypes.func,
    getRecurringDepositSettings: PropTypes.func,
  }),
  fundingAccount: PropTypes.shape({}),
  fundingActions: PropTypes.shape({}),
  goals: PropTypes.shape({
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        goal: PropTypes.shape({
          target_amount: PropTypes.number,
        }),
      }),
    ),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  recurringDepositSettings: PropTypes.shape({
    amount: PropTypes.number,
  }),
  userActions: PropTypes.shape({
    getUserData: PropTypes.func,
  }),
  user: PropTypes.shape({
    current_balance: PropTypes.number,
    user_type: PropTypes.string,
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
