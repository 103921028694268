import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SuperInsuranceBannerWrapper,
  SuperInsuranceBannerTitle,
  SuperInsuranceHeaderBackgroundImage,
} from './styles';

export const SuperInsuranceBanner = () => {
  const { t } = useTranslation();

  return (
    <SuperInsuranceBannerWrapper>
      <SuperInsuranceHeaderBackgroundImage />

      <SuperInsuranceBannerTitle>
        {t('superInsurancePage.banner.title')}
      </SuperInsuranceBannerTitle>
    </SuperInsuranceBannerWrapper>
  );
};
