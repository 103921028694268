import styled from '@emotion/styled';

export const ImageSkeleton = styled.div`
  display: flex;
  line-height: ${({ theme }) => theme.lineHeights.length.xxl};
  justify-content: center;
  margin: 8px 14px 8px 14px;
  .react-skeleton-load {
    min-width: 280px;
  }
`;

export const ComfortWrapperSkeleton = styled.div`
  padding: 8px 16px 12px 18px;
  display: flex;
  span {
    margin-right: 4px;
  }
`;

export const BottomWrapperSkeleton = styled.div`
  padding: 0 16px;
`;

export const PriceSkeleton = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin: 8px 0 8px 0;
`;
