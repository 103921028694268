import { IRootInvestState } from './types';

export const selectStatements =
  ({ isStatementsNested }: { isStatementsNested: boolean }) =>
  (state: IRootInvestState) =>
    isStatementsNested
      ? state.invest.statements?.statements
      : state.invest.statements;

export const selectInvestErrorMessage = (state: IRootInvestState) =>
  state.invest.errorMessage;

export const selectStatementsUrl = (state: IRootInvestState) =>
  state.invest.statementsUrl;

export const selectStatementType = (state: IRootInvestState) =>
  state.invest.type;

export const selectStatementsTotalPages = (state: IRootInvestState) =>
  state.invest.statements?.total_pages;
