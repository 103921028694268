import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import * as spendingAnalyticsActions from '../../store/spendingAnalytics/actions';
import { AMOUNT, UNCATEGORISED } from '../../constants/comonConstants';

import TransactionCategorisationView from './TransactionCategorisationView';

class TransactionCategorisation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: UNCATEGORISED,
      page: 0,
      showCategorisationInfo: false,
      transactionAmount: 0,
      transactionDescription: '',
      transactionName: '',
      transactionMovement: '',
      transactionCategory: '',
      transaction: {},
      sortType: AMOUNT,
      isOpenHelpCards: false,
      showLoader: true,
      categoriesList: [],
      transactionCategorisationList: [],
    };

    this.toggleSort = this.toggleSort.bind(this);

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.changeStatus = this.changeStatus.bind(this);

    this.trackScrolling = this.trackScrolling.bind(this);

    this.toggleCategorisationInfo = this.toggleCategorisationInfo.bind(this);
  }

  componentDidMount() {
    const { actionsSpendingAnalytics } = this.props;
    const { status } = this.state;

    this.setState({ showLoader: true });

    actionsSpendingAnalytics.getCategories().then(() => {
      const { categoriesList } = this.props;

      this.setState({ categoriesList });

      actionsSpendingAnalytics
        .getTransactionCategorisation({ uncategorised: status })
        .then(() => {
          const { transactionCategorisation } = this.props;

          this.setState({
            transactionCategorisationList: transactionCategorisation.payload,
            showLoader: false,
          });
        });
    });

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.addListener) {
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling() {
    const { addListener } = this.props;
    const { transactionCategorisationList } = this.state;
    const el = document.querySelector('.info-panel');
    const windowScroll = window.scrollY;

    if (
      transactionCategorisationList &&
      Number.isInteger(transactionCategorisationList.length / 51) &&
      addListener &&
      el.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      document.removeEventListener('scroll', this.trackScrolling);

      const { actionsSpendingAnalytics } = this.props;
      const { status, sortType, page } = this.state;

      this.setState({ page: page + 1 });

      actionsSpendingAnalytics
        .getTransactionCategorisation(
          {
            uncategorised: status === UNCATEGORISED,
          },
          sortType,
          50,
          page + 1,
        )
        .then(() => {
          const { transactionCategorisation } = this.props;

          this.setState({
            transactionCategorisationList: transactionCategorisationList.concat(
              transactionCategorisation.payload,
            ),
          });

          window.scrollY = windowScroll - 100;
        });
    }
  }

  changeStatus(status) {
    this.setState({ status, showLoader: true });

    const { actionsSpendingAnalytics } = this.props;

    actionsSpendingAnalytics
      .getTransactionCategorisation({
        uncategorised: status === UNCATEGORISED,
      })
      .then(() => {
        const { transactionCategorisation } = this.props;

        this.setState({
          transactionCategorisationList: transactionCategorisation.payload,
          showLoader: false,
        });
      });
  }

  toggleCategorisationInfo({
    condition,
    transactionAmount,
    transactionDescription,
    transactionMovement,
    transactionName,
    transactionCategory,
    transaction,
  }) {
    const { actionsSpendingAnalytics } = this.props;
    const { status, sortType } = this.state;

    this.setState({
      showCategorisationInfo: condition,
    });

    if (condition) {
      this.setState({
        transactionAmount,
        transactionDescription,
        transactionMovement,
        transactionName,
        transactionCategory,
        transaction,
      });
    } else {
      this.setState({ status, showLoader: true });

      actionsSpendingAnalytics
        .getTransactionCategorisation(
          {
            uncategorised: status === UNCATEGORISED,
          },
          sortType,
        )
        .then(() => {
          const { transactionCategorisation } = this.props;

          this.setState({
            transactionCategorisationList: transactionCategorisation.payload,
            showLoader: false,
          });
        });
    }
  }

  toggleSort(sortType) {
    const { status } = this.state;
    const { actionsSpendingAnalytics } = this.props;

    this.setState({ sortType, showLoader: true });

    actionsSpendingAnalytics
      .getTransactionCategorisation(
        {
          uncategorised: status === UNCATEGORISED,
        },
        sortType,
      )
      .then(() => {
        const { transactionCategorisation } = this.props;

        this.setState({
          transactionCategorisationList: transactionCategorisation.payload,
          showLoader: false,
        });
      });
  }

  toggleHelpCard(condition) {
    this.setState({ isOpenHelpCards: condition });
  }

  render() {
    const {
      status,
      showCategorisationInfo,
      transactionDescription,
      transactionAmount,
      transactionMovement,
      transactionName,
      sortType,
      transactionCategory,
      transaction,
      isOpenHelpCards,
      showLoader,
      categoriesList,
      transactionCategorisationList,
    } = this.state;

    const types = categoriesList;
    const subcategories = [];

    types.forEach((type) => {
      let subcategoriesArray = type.subcategories;

      if (subcategoriesArray) {
        subcategoriesArray = subcategoriesArray.map((el) => {
          const o = { ...el };

          o.parentCategory = type.id;

          return o;
        });

        subcategories.push(subcategoriesArray);
      }
    });

    const allCategoryDetails = types
      .concat(subcategories)
      .reduce((acc, val) => acc.concat(val), []);

    return (
      <TransactionCategorisationView
        status={status}
        showLoader={showLoader}
        types={types}
        transaction={transaction}
        allCategoryDetails={allCategoryDetails}
        transactionName={transactionName}
        transactionCategory={transactionCategory}
        transactionAmount={transactionAmount}
        transactionMovement={transactionMovement}
        transactionDescription={transactionDescription}
        toggleSort={this.toggleSort}
        sortType={sortType}
        changeStatus={this.changeStatus}
        showCategorisationInfo={showCategorisationInfo}
        toggleCategorisationInfo={this.toggleCategorisationInfo}
        transactionCategorisation={transactionCategorisationList}
        toggleHelpCard={this.toggleHelpCard}
        isOpenHelpCards={isOpenHelpCards}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  transactionCategorisation: state.spendingAnalytics.transactionCategorisation,
  addListener: state.spendingAnalytics.addListener,
  categoriesList: state.spendingAnalytics.categoriesList,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionCategorisation);

TransactionCategorisation.defaultProps = {
  actionsSpendingAnalytics: {},
  categoriesList: [],
  addListener: false,
  transactionCategorisation: { payload: [] },
};

TransactionCategorisation.propTypes = {
  categoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  actionsSpendingAnalytics: PropTypes.shape({
    getCategories: PropTypes.func,
    getTransactionCategorisation: PropTypes.func,
  }),
  addListener: PropTypes.bool,
  transactionCategorisation: PropTypes.shape({
    payload: PropTypes.arrayOf(),
  }),
};
