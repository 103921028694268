import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { HomeProgressWrapper } from './styles';

import {
  HOME_HISTORY_TAB,
  HOME_ACTIVE_TAB,
  INVEST_PAGE,
  HOME_TABS,
} from 'constants/comonConstants';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function HomeProgress() {
  const { t } = useTranslation();

  const { progress } = useSelector((state) => ({
    progress: state.user.progress || {},
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { step, message, title } = progress;
  const isFirstStep = step === 1;
  const isSecondStep = step === 2;
  const isLastStep = step === 3;
  const isHistory = isLastStep && message?.match(/history/i);

  if (!step) {
    return null;
  }

  return (
    step && (
      <HomeProgressWrapper>
        <div className="page-content__banner-steps">
          <div
            className={`page-content__banner-step
              ${isFirstStep ? '-active' : '-done'}`}
          />

          <div
            className={`page-content__banner-step-divider ${
              !isFirstStep ? '-active' : ''
            }`}
          />

          <div
            className={`page-content__banner-step
              ${isSecondStep ? '-active' : ''} ${isLastStep ? '-done' : ''}`}
          />

          <div
            className={`page-content__banner-step-divider ${
              isLastStep ? '-active' : ''
            }`}
          />

          <div
            className={`page-content__banner-step ${
              isLastStep ? '-active' : ''
            }`}
          />
        </div>

        <div className="page-content__banner-step-title">{title}</div>

        <div className="page-content__banner-step-message">{message}</div>

        {isLastStep && (
          <DeprecatedButton
            isLink
            title={
              isHistory
                ? t('homePage.homeProgress.goToHistory')
                : t('homePage.homeProgress.goToInvest')
            }
            to={isHistory ? HOME_HISTORY_TAB : INVEST_PAGE}
            buttonClass="button__basic-fill -white-inverse -link"
            handleClick={() => {
              if (isHistory) {
                setSearchParams({ [HOME_ACTIVE_TAB]: HOME_TABS.past });
              }
            }}
          />
        )}
      </HomeProgressWrapper>
    )
  );
}
