import { useTranslation } from 'react-i18next';

import { Multiplier, RoundUpsMultiplierWrapper } from './styles';

import { DISABLED_MULTIPLIER_VALUE } from 'components/layout/Modals/RoundUpsModal/components/consts';

export default function RoundUpsMultiplier({
  isRoundUpsTemplateDark,
  multiplier,
}: {
  isRoundUpsTemplateDark: boolean;
  multiplier: number;
}) {
  const { t } = useTranslation();

  if (!multiplier || multiplier <= DISABLED_MULTIPLIER_VALUE) {
    return null;
  }

  return (
    <RoundUpsMultiplierWrapper isRoundUpsTemplateDark={isRoundUpsTemplateDark}>
      <Multiplier isRoundUpsTemplateDark={isRoundUpsTemplateDark}>
        {t('roundUps.multiplier', { multiplier })}
      </Multiplier>
    </RoundUpsMultiplierWrapper>
  );
}
