import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import * as userActions from '../../../store/user/actions';
import Menu from '../Menu/Menu';
import LogoutModal from '../Modals/LogoutModal';
import AccountStrength from '../AccountStrength/AccountStrength';
import Alerts from '../Alerts/Alerts';
import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import {
  BAHASA,
  DEPOSITS_SUSPENSION_REMOVAL,
  ENGLISH,
} from '../../../constants/comonConstants';
import { MULTI_LANG } from '../../../constants/togglesFunctions';
import { checkIsDependentUser, checkIsSmsfUser } from '../../../utils/user';
import { ALERT_TYPES } from '../../../constants/alerts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
  }

  toggleLogoutModal(isModalOpen) {
    const { actions } = this.props;

    if (isModalOpen) {
      actions.openLogoutModal();
    } else {
      actions.closeLogoutModal();
    }
  }

  toggleMobileMenu(toggleValue) {
    const mainMenuHtml = document.body.querySelector('.main-menu.-mobile');
    const mainMenu = mainMenuHtml ? mainMenuHtml.classList : '';
    const bodyElement = document.querySelector('body').classList;

    if (toggleValue && mainMenu) {
      mainMenu.add('-open');

      bodyElement.add('-open-menu');
    } else if (mainMenu) {
      mainMenu.remove('-open');

      bodyElement.remove('-open-menu');
    }
  }

  isMobile() {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1 ||
      window.innerWidth < 998
    );
  }

  toggleLang(lang) {
    const { actions } = this.props;

    localStorage.setItem('lang', lang);

    let local = 'en';

    if (lang === BAHASA) {
      local = 'ms';
    }

    actions.updateUserLocale(local).then(() => {
      window.location.reload();
    });
  }

  render() {
    let { user } = this.props;
    const { smsfDetails, history, isLogoutModalOpen } = this.props;

    const storageUser = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.user),
    );

    if (!user.first_name && storageUser) {
      ({ user } = storageUser);
    }

    let fullName = '';
    let initials = ' ';

    if (user && user.first_name && user.last_name && !checkIsSmsfUser(user)) {
      fullName = `${user.first_name} ${user.last_name}`;

      initials = fullName
        ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
        : '';
    }

    const currentLang = localStorage.getItem('lang');

    if (MY_APP && user && user.first_name) {
      initials = `${user.first_name.charAt(0)}`;
    }

    if (
      AU_APP &&
      checkIsSmsfUser(user) &&
      smsfDetails.details.display_fund_name
    ) {
      fullName = smsfDetails.details.display_fund_name;

      initials = smsfDetails.details.display_fund_name.charAt(0);
    }

    if (AU_APP && checkIsDependentUser(user)) {
      initials = `${user.first_name.charAt(0)}`;
    }

    const existAlerts = user && user.alerts && user.alerts.length;

    const extraDepositAlert =
      existAlerts &&
      user.alerts.length === 1 &&
      user.alerts.find((alert) => alert.type === DEPOSITS_SUSPENSION_REMOVAL);

    const existUploadAlert = existAlerts
      ? user.alerts.find((alert) =>
          [
            ALERT_TYPES.idUploadErrors.errorUploadInformation,
            ALERT_TYPES.idUploadErrors.additionalUploadMissing,
            ALERT_TYPES.idUploadErrors.bankStatementUploadMissing,
            ALERT_TYPES.idUploadErrors.ktpUploadMissing,
            ALERT_TYPES.idUploadErrors.myKadFrontUploadMissing,
            ALERT_TYPES.idUploadErrors.myKadBackUploadMissing,
            ALERT_TYPES.idUploadErrors.idBackUploadMissing,
          ].includes(alert.type),
        )
      : false;

    return (
      <header className="App-header header__outer">
        <div className="header">
          <div
            className="header__menu"
            onClick={() => this.toggleMobileMenu(true)}
            onKeyPress={() => {}}
            role="button"
            tabIndex={-1}
          />

          <Link className="header__logo" to="/" />

          {this.isMobile() && (
            <Menu
              user={user}
              menuClass="-mobile"
              fullName={fullName}
              initials={initials}
              toggleMobileMenu={this.toggleMobileMenu}
            />
          )}

          {initials.length ? (
            <div className="header__user">
              {existAlerts && !existUploadAlert && !extraDepositAlert && (
                <Alerts history={history} isHeader />
              )}

              {existAlerts && existUploadAlert && (
                <Alerts openUploadModal history={history} isHeader />
              )}

              {!checkIsDependentUser(user) && <AccountStrength />}

              <div className="header__user-photo">{initials}</div>

              <div className="header__user-name">{fullName}</div>

              {MULTI_LANG && (
                <div className="header__lang">
                  <DeprecatedButton
                    title=""
                    handleClick={() => this.toggleLang('english')}
                    buttonClass={`header__lang-icon -eng ${
                      currentLang === BAHASA ? '-active' : '-disable'
                    }`}
                  />

                  <div className="header__lang-divider" />

                  <DeprecatedButton
                    title=""
                    handleClick={() => this.toggleLang('bahasa')}
                    buttonClass={`header__lang-icon -mys
                  ${
                    !currentLang || currentLang === ENGLISH
                      ? '-active'
                      : '-disable'
                  }`}
                  />
                </div>
              )}

              <DeprecatedButton
                buttonClass="button__basic-transparent -logout"
                handleClick={() => this.toggleLogoutModal(true)}
              />
            </div>
          ) : null}

          <LogoutModal
            isOpenLogout={isLogoutModalOpen}
            toggleLogoutModal={this.toggleLogoutModal}
          />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  user: state.user.user,
  isLogoutModalOpen: state.user.isLogoutModalOpen,
  smsfDetails: state.user.smsfDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.defaultProps = {
  user: {},
  isLogoutModalOpen: false,
  history: {},
};

Header.propTypes = {
  actions: PropTypes.shape({
    updateUserLocale: PropTypes.func,
    closeLogoutModal: PropTypes.func,
    openLogoutModal: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    user_type: PropTypes.string,
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  smsfDetails: PropTypes.shape({
    details: PropTypes.shape({
      display_fund_name: PropTypes.string,
    }),
  }).isRequired,
  isLogoutModalOpen: PropTypes.bool,
  history: PropTypes.shape({}),
};
