import { investmentGoal } from './api';
import { IDependencyUsersState } from './types';

import { RootState } from 'store/configureStore';

export const selectChild = (state: IDependencyUsersState) =>
  state?.dependencyUsers?.child?.dependency_user || {};

export const selectChildSummary = (state: IDependencyUsersState) =>
  state?.dependencyUsers?.child?.dependency_user?.summary || {};

export const selectDependentUserId = (state: IDependencyUsersState) =>
  state?.dependencyUsers?.child?.dependency_user?.id;

export const selectDependencyUsersLoading = (state: IDependencyUsersState) =>
  state?.dependencyUsers?.isLoading || false;

export const selectDependencyUserPortfolioId = (state: IDependencyUsersState) =>
  state.dependencyUsers?.child?.dependency_user?.portfolio?.id;

export const selectInvestmentGoal = (state: RootState) => {
  const dependent_user_id = selectDependentUserId(state);

  const investmentGoalQuery = investmentGoal.select({ dependent_user_id })(
    state,
  );

  return investmentGoalQuery.data?.goal;
};
