import React, { lazy, Suspense } from 'react';

interface ILoadableOptions {
  fallback: React.ReactNode | null;
}

const loadable = (
  importFunc: () => Promise<{
    default: React.ComponentType<Record<string, unknown>>;
  }>,
  options: ILoadableOptions = {
    fallback: null,
  },
) => {
  const LazyComponent = lazy(importFunc);

  return (props: React.ComponentProps<typeof LazyComponent>) => (
    <Suspense fallback={options.fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
