import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import '../../../styles/layout/logout.scss';

export default function SpendingConnectConfirmModal({
  isShowConfirmation,
  handleCloseModalError,
  unlinkSpendingCard,
  cardNumberSuffix,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      hasBackdrop={false}
      isVisible={false}
      isOpen={isShowConfirmation}
      contentLabel="modal-alert"
      shouldCloseOnOverlayClick
      className="modal-basic bank__alert"
    >
      <div className="modal-alert-content">
        <div className="alert-title">
          {t('settingsPage.fundingConnectConfirmModal.title')}
        </div>

        <div className="alert-text">
          {cardNumberSuffix ? (
            <Trans
              i18nKey="spendingConnectConfirmModal.description.unlinkAccount"
              t={t}
              values={{ last4: cardNumberSuffix }}
            />
          ) : (
            t(
              'spendingConnectConfirmModal.description.unlinkAccountConfirmation',
            )
          )}
        </div>

        <div className="alert-buttons">
          <DeprecatedButton
            title={t('common.cancel')}
            type="button"
            buttonClass="wait"
            handleClick={handleCloseModalError}
          />

          <DeprecatedButton
            title={t('common.unlink')}
            type="button"
            buttonClass="skip"
            handleClick={unlinkSpendingCard}
          />
        </div>
      </div>
    </Modal>
  );
}

SpendingConnectConfirmModal.propTypes = {
  isShowConfirmation: PropTypes.bool.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  unlinkSpendingCard: PropTypes.func.isRequired,
  cardNumberSuffix: PropTypes.string.isRequired,
};
