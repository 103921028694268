import styled from '@emotion/styled';

import { TCompanyLogo } from './types';

interface ILogoProps {
  image: TCompanyLogo;
}

export const LogoWrapper = styled.div<ILogoProps>`
  ${({ image }) => image && `background-image: url(${image});`}
  background-position: center;
  background-repeat: no-repeat;
  padding: 25px;
`;
