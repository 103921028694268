import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterCloseButton, FiltersTitle, FiltersWrapper } from './styles';
import FiltersCategories from './FiltersCategories';

import { IconButton } from 'components/elements/IconButton';

interface IFiltersProps {
  closeFilterModal: () => void;
}

export default function Filters({ closeFilterModal }: IFiltersProps) {
  const { t } = useTranslation();

  return (
    <FiltersWrapper>
      <FiltersTitle>{t('portfolioCustomization.filters.title')}</FiltersTitle>

      <FilterCloseButton>
        <IconButton
          iconName="close"
          onClick={closeFilterModal}
          // TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548
          className="-exclude"
          size={19}
        />
      </FilterCloseButton>

      <FiltersCategories />
    </FiltersWrapper>
  );
}
