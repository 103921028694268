import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { SEARCH_QUERY_PARAM } from './consts';

import { usePortfolioCustomizationRoute } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';
import { useActiveTab } from 'hooks/useActiveTab';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/comonConstants';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getFunds } from 'store/portfolio/actions';
import {
  selectFundSorting,
  selectPortfolioId,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

export const useSearchFunds = () => {
  const dispatch = useDispatch();
  const { fundTag } = usePortfolioCustomizationRoute();
  const { childId, isParamsLoaded } = useAppSelector(selectPortfolioParams);
  const { activeTab } = useActiveTab();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get(SEARCH_QUERY_PARAM);
  const sorting = useSelector(selectFundSorting);
  const portfolioId = useAppSelector(selectPortfolioId);

  useEffect(() => {
    if (isParamsLoaded) {
      dispatch(
        getFunds({
          portfolioId,
          activeFundTag: fundTag,
          sorting,
          searchValue,
          searchParams,
        }),
      );
    }
  }, [
    searchValue,
    dispatch,
    portfolioId,
    fundTag,
    sorting,
    searchParams,
    isParamsLoaded,
  ]);

  return debounce((value) => {
    const filtersSearchParams = {
      ...Object.fromEntries(searchParams),
      ...{ [SEARCH_QUERY_PARAM]: value },
    };

    setSearchParams({
      ...(searchParams && searchParams),
      ...(childId && { childId }),
      activeTab,
      ...filtersSearchParams,
    });
  }, DEFAULT_DEBOUNCE_TIME);
};
