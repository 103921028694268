import styled from '@emotion/styled';

import { RecurringInvestment } from '../styles';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

export const SkeletonRecurringInvestmentContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

export const SkeletonButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SkeletonButton = styled.div`
  max-width: 240px;
  margin-top: 24px;
  flex: 1;
`;

export const SkeletonRemoveButton = styled.div`
  max-width: 140px;
  margin: 34px 0 0 28px;
  flex: 1;
`;

export const SkeletonRecurringInvestmentWrapper = styled(RecurringInvestment)`
  padding: 4px 16px 18px 16px;
`;

export const SkeletonInvestmentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

export const SkeletonInvestmentAmountTitle = styled.div`
  align-items: center;
  flex: 0.7;
  padding-top: 10px;
`;

export const SkeletonInvestmentAmount = styled.div`
  align-items: center;
  flex: 0.3;
  text-align: right;
  padding-top: 10px;
`;
