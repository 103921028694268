import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { SaveChangesButton, ButtonsWrapper } from '../styles';
import StopInvestmentButton from '../StopInvestmentButton';
import { useRecurringInvestmentForm } from '../useRecurringInvestmentForm';
import { VIEW_MODE } from '../consts';
import { useNavigateToRecurringInvestment } from '../hooks/useNavigateToRecurringInvestment';

import DeleteInvestmentGoalButton from './StopInvestmentGoalButton';
import RecurringInvestmentRegistrationButtons from './RecurringInvestmentRegistrationButtons';
import { INVESTMENT_GOAL_ENABLED_NAME } from './consts';

import { selectInvestmentGoal } from 'store/dependentUser/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { PATHS } from 'constants/paths';

export interface IRecurringInvestmentForm {
  recurringInvestmentFrequency?: string | null;
}

export default function RecurringInvestmentButtons({
  recurringInvestmentFrequency,
}: IRecurringInvestmentForm) {
  const { t } = useTranslation();

  const isRegistrationPage = useMatch(
    PATHS.kids.registration.investment.recurring,
  );

  const investmentGoal = useAppSelector(selectInvestmentGoal);
  const { amount, isLoading } = useRecurringInvestmentForm();
  const { viewMode } = useParams();
  const navigateToRecurringInvestment = useNavigateToRecurringInvestment();

  const investmentGoalKey = investmentGoal?.key;
  const { watch } = useFormContext();
  const isInvestmentGoalEnabled = watch(INVESTMENT_GOAL_ENABLED_NAME);

  if (isRegistrationPage) {
    return <RecurringInvestmentRegistrationButtons isLoading={isLoading} />;
  }

  return (
    <ButtonsWrapper>
      {viewMode === VIEW_MODE.preview ? (
        <SaveChangesButton
          isLoading={isLoading}
          type="button"
          onClick={() =>
            navigateToRecurringInvestment({ viewMode: VIEW_MODE.edit })
          }
          label={
            investmentGoalKey
              ? t('recurringInvestmentPage.editSavingGoal')
              : t(
                  'raizKidsEditPage.recurringInvestmentPreview.changePlanButton',
                )
          }
        />
      ) : (
        <SaveChangesButton
          isLoading={isLoading}
          type="submit"
          label={
            isInvestmentGoalEnabled
              ? t('raizKidsEditPage.investmentGoal.setSavingsGoalButton')
              : t(
                  'raizKidsEditPage.recurringInvestmentForm.setInvestmentButton',
                  {
                    frequency: recurringInvestmentFrequency,
                  },
                )
          }
        />
      )}

      {amount &&
        (investmentGoalKey ? (
          <DeleteInvestmentGoalButton investmentGoalKey={investmentGoalKey} />
        ) : (
          <StopInvestmentButton />
        ))}
    </ButtonsWrapper>
  );
}
