import React from 'react';
import PropTypes from 'prop-types';

import {
  ChildWrapper,
  ChildImage,
  ChildName,
  ChildImageWrapper,
  ChildAmount,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';

export default function KidsWidgetItem(props) {
  const {
    child: { icon_url, name, current_balance },
  } = props;

  return (
    <ChildWrapper>
      <ChildImageWrapper>
        <ChildImage src={icon_url} alt={name} />
      </ChildImageWrapper>

      <ChildName>{name}</ChildName>

      <ChildAmount>
        {formatNumber({
          value: current_balance,
        })}
      </ChildAmount>
    </ChildWrapper>
  );
}

KidsWidgetItem.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.string,
    icon_url: PropTypes.string,
    name: PropTypes.string,
    current_balance: PropTypes.number,
  }).isRequired,
};
