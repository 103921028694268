import { useTranslation } from 'react-i18next';

import { AllocationItems } from './AllocationItems';
import { AllocationWrapper, Header, HeaderLabel } from './styles';

import { selectTransactionDetails } from 'store/history/selectors';
import { MY_APP } from 'constants/localeConfigs';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { ITransactionDetails } from 'store/history/types';

export const Allocation = () => {
  const { t } = useTranslation();

  const { allocations }: ITransactionDetails = useAppSelector(
    selectTransactionDetails,
  );

  if (!allocations?.length) {
    return null;
  }

  return (
    <AllocationWrapper>
      <Header>
        <HeaderLabel>
          {t('historyDetailsPage.transactionItem.symbol')}
        </HeaderLabel>

        {MY_APP && <HeaderLabel>{t('common.price')}</HeaderLabel>}

        <HeaderLabel>
          {t('historyDetailsPage.transactionItem.shares')}
        </HeaderLabel>

        <HeaderLabel>
          {t('historyDetailsPage.transactionItem.amount')}
        </HeaderLabel>
      </Header>

      {allocations?.map((allocation) => (
        <AllocationItems key={allocation.symbol} allocation={allocation} />
      ))}
    </AllocationWrapper>
  );
};
